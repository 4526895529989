
'use strict';

export const $clientPGQueryBuilderService  = function (ClientPGQueryBuilder) {
    return {
      getRecords: function(client, query, paginationData) {
        return ClientPGQueryBuilder.getRecords({
          id: client._id || client,
          controller: 'records',
          query: query,
          page: paginationData && paginationData.page,
          pageSize: paginationData && paginationData.pageSize
        }).$promise;
      }
    };
  }
// Dependency Injection


// Dependency Injection
$clientPGQueryBuilderService.$inject = ['ClientPGQueryBuilder'];
