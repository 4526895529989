'use strict';


 export const $updateSCSSVariablesService = function ($auth) {
        return function (accessibility) {
            // let accessibility = $auth.getCurrentUser();
            var fontSize = '14px';
            if (!accessibility) {
                accessibility = $auth.getCurrentUser().settings.accessibility;
            }
            if (accessibility.fontSize) {
                fontSize = (accessibility.fontSize == 'S') ? '0.8em' :
                    accessibility.fontSize == 'M' ? '1em' :
                        accessibility.fontSize == 'L' ? '1.2em' : '14px';
            }
            document.documentElement.style.setProperty('--app-font-header-size', `calc(${fontSize} + 3px) `)
            document.documentElement.style.setProperty('--app-font-subheader-size', `calc(${fontSize} + 2px) `)
            document.documentElement.style.setProperty('--app-font-size', `calc(${fontSize} + 0.5px) `)
            // return null;
        }
    }
// Dependency Injection
$updateSCSSVariablesService.$inject = ["$auth"];
