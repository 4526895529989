
'use strict';

export const $clientSwitcherService  = function ($q, $rootScope, $client, $clientFeature, $clientSwitcherStorage) {
      var
      eventNs = 'client-switcher',
      loading = false,
      hasLoaded = false,
      loadedClients = [],
      currentClient = $clientSwitcherStorage.getCurrent();

    var loadingAbilitiesFor;


    return {
      clearAvailable: function () {
        currentClient = undefined;
        loadedClients.splice(0, loadedClients.length);
        return this;
      },
      reset: function () {
        hasLoaded = false;
        this.clearAvailable();
        // $clientSwitcherStorage.clear();
        $rootScope.$broadcast(eventNs + '-reset');
      },
      reloadAvailable: function () {
        loading = true;
        var
          lCurrentClient = this.getCurrentClientId(),
          setCurrent = this.setCurrentClient.bind(this),
          broadcastLoaded = function () {
            $rootScope.$broadcast(eventNs + '-load', loadedClients, currentClient);
            hasLoaded = true;
          };

        this.clearAvailable();

        return $client.listAll()
          .then((function (res) {

            res.forEach(function (cli) {
              if (lCurrentClient && (lCurrentClient === cli._id)) {
                currentClient = cli;
              }

              loadedClients.push(cli);
            });

            if (!currentClient) {
              var clientInCookie = $clientSwitcherStorage.getCurrent();

              if(loadedClients) {
                var index = -1;

                if(clientInCookie) {
                  for (var i=0; i<loadedClients.length; i++) {
                    if(loadedClients[i]._id === clientInCookie) {
                      index = i;
                      break;
                    }
                  }
                }

                index = index > -1 ? index : 0;
                setCurrent(loadedClients[index]);
              }
            }

            return this.reloadClientAbilities()
              .then(function () {
                broadcastLoaded();
                return loadedClients;
              });
          }).bind(this))
          .finally(function () {
            loading = false;
          });
      },
      reloadClientAbilities: function () {
        var clientId = this.getCurrentClientId();
        if (!clientId) return $q.when(false);
        loadingAbilitiesFor = clientId;
        return $client.userAbilities(clientId)
          .then(function (abilities) {
            currentClient.features = abilities;
            return currentClient;
          })
          .finally(function () {
            loadingAbilitiesFor = false;
          });
      },
      whenLoaded: function (cb) {
        if (hasLoaded) {
          return cb(loadedClients, currentClient);
        }

        var once = $rootScope.$on(eventNs + '-load', function (evt, all, current) {
          cb(all, current);
          return once();
        });
      },
      isLoading: function () {
        return loading;
      },
      getLoaded: function () {
        return loadedClients;
      },
      getAccessLevelGlyph: function (aL) {
        switch (true) {
          case this.isOwner(aL):
            return 'glyphicon-star';
          case this.isOperator(aL):
            return 'glyphicon-star-empty';
          case this.isLimited(aL):
            return 'glyphicon-eye-close';
        }
      },
      getAccessLevel: function (cli) {
        if (!cli && !currentClient) return false;
        return (cli || currentClient).accessLevel;
      },
      isOwner: function (aL) {
        return (aL || this.getAccessLevel()) === 1;
      },
      isOperator: function (aL) {
        return (aL || this.getAccessLevel()) === 2;
      },
      isLimited: function (aL) {
        return (aL || this.getAccessLevel()) === 3;
      },
      hasAbility: function (ability, cli) {
        var feat = this.getFeatures(cli);
        if (!feat || !feat.abilities || !feat.abilities.length) return false;
        return feat.abilities.indexOf(ability) > -1;
      },
      getRelationship: function (cli) {
        var aL = this.getAccessLevel(cli);

        if (this.isOwner(aL)) return 'owner';
        if (this.isOperator(aL)) return 'operator';
        if (this.isLimited(aL)) return 'limited';
      },
      getFeatures: function (cli) {
        if (!cli && !currentClient) return false;
        return (cli || currentClient).features ? (cli || currentClient).features : false;
      },
      getFeatureOptions: function (feature, cli) {
        var feat = this.getFeatures(cli);
        if (!feat || !feat.options || feat.options[feature] === undefined) return false;
        return feat.options[feature];
      },
      getFeatureTabs: function (cli, accessLevel) {
        var feat = this.getFeatures(cli) || {}, hasAccessLevel = !isNaN(accessLevel);

        return Object.keys(feat.options || {})
          .filter(function (o) {
            var options = feat.options[o];
            return options.actAsTab && options.allowTab && (!hasAccessLevel || options.requireLevel >= accessLevel);
          })
          .sort(function (a, b) {
            var
              aSort = feat.options[a].tabSort,
              bSort = feat.options[b].tabSort;
            return aSort - bSort;
          })
          .map(function (o) {
            var options = feat.options[o];
            return {
              id: o,
              title: options.tabTitle,
              state: options.tabState,
              icon: options.tabIcon,
              disabled: !!options.tabDisabled,
              permission: options.tabPermission ? options.tabPermission : 'NONE'
            };
          });
      },
      getFeaturePhone: function (cli, accessLevel) {
        var feat = this.getFeatures(cli) || {}, hasAccessLevel = !isNaN(accessLevel);
        return Object.keys(feat.options || {})
          .filter(function (o) {
            var options = feat.options[o];
            return options.actAsPhone && options.allow && (!hasAccessLevel || options.requireLevel >= accessLevel);
          })
          .map(function (o) {
            var options = feat.options[o];
            return {
              id: o,
              title: options.title,
              options: options
            };
          });
      },
      getCurrentClient: function () {
        return currentClient;
      },
      getCurrentClientId: function () {
        if (!currentClient) return false;
        return (currentClient._id || currentClient);
      },
      setCurrentClient: function (c) {
        $clientSwitcherStorage.setCurrent(currentClient = c);

        // load features for this client:
        // this.reloadClientAbilities()
          // .finally(function () {
            $rootScope.$broadcast(eventNs + '-switch', c);
          // });
      }
    };
  }
// Dependency Injection


// Dependency Injection
$clientSwitcherService.$inject = ['$q', '$rootScope', '$client', '$clientFeature', '$clientSwitcherStorage'];
