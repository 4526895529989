'use strict';

export const OnDemandContactConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard.on-demand-contact', {
        url: '/on-demand-contact',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/on-demand-contact/on-demand-contact.html', '755fe18a'); }],
        controller: 'DashboardOnDemandContactCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/on-demand-contact/on-demand-contact.controller.js', 'DashboardOnDemandContactCtrl'); }]
                      },
        data: {
          ability: 'client.on-demand-contact',
          permission: 'ON_DEMAND_READ'
        }
      });
  }
// Dependency Injection
OnDemandContactConfig.$inject = ["$stateProvider"];
