'use strict';

export const OfferingManagementConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard.settings.offering-management', {
        url: '/offering-management',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/offering-management/offering-management.html', '892be18a'); }],
        controller: 'DashboardSettingsOffManageCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/offering-management/offering-management.controller.js', 'DashboardSettingsOffManageCtrl'); }]
                      },
        data: {
          ability: 'manage.client.users'
        }
      })
      .state('app.account.dashboard.settings.offering-management.index', {
        url: '/index',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/offering-management/index/index.html', 'a155778a'); }],
        controller: 'DashboardSettingsOffManageIndexCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/offering-management/index/index.controller.js', 'DashboardSettingsOffManageIndexCtrl'); }]
                      },
        data: {
          ability: 'manage.client.users'
        }
      })
      .state('app.account.dashboard.settings.offering-management.create', {
        url: '/create',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/offering-management/create/create.html', 'e6f137ea'); }],
        controller: 'DashboardSettingsOffManageCreateCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/offering-management/create/create.controller.js', 'DashboardSettingsOffManageCreateCtrl'); }]
                      },
        data: {
          ability: 'manage.client.users'
        }
      })
      .state('app.account.dashboard.settings.offering-management.update', {
        url: '/update/:id',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/offering-management/update/update.html', 'f56ad24a'); }],
        controller: 'DashboardSettingsOffManageUpdateCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/offering-management/update/update.controller.js', 'DashboardSettingsOffManageUpdateCtrl'); }],
                          '$selectedRule': ['$stateParams', '$offeringRules', '$clientSwitcher', function ($stateParams, $offeringRules, $clientSwitcher) {
                            return $offeringRules.getRuleById($clientSwitcher.getCurrentClient(), $stateParams.id);
                          }]
                      },
        data: {
          ability: 'manage.client.users'
        },
      })
      .state('app.account.dashboard.settings.offering-management.woRestriction', {
        url: '/logs',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/offering-management/wo-restriction/wo-restriction.html', 'e24801ca'); }],
        controller: 'DashboardSettingsOffManageWoRestrictionCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/offering-management/wo-restriction/wo-restriction.controller.js', 'DashboardSettingsOffManageWoRestrictionCtrl'); }]
                      },
        data: {
          ability: 'manage.client.users'
        }
      });
  }
// Dependency Injection
OfferingManagementConfig.$inject = ["$stateProvider"];
