
'use strict';

export const uasTaskDirective  = function () { // task
  return {
    restrict: 'A',
    require: ['uasTask'],
    controller: 'UasTaskCtrl as $uasTask',
    link: function(scope, el, attrs, ctrls) {
      var
      ctrl = ctrls[0];
    }
  };
} 
 
