'use strict';

  // .filter('loginDevice', function() {
  //   var isMobile = function(loginSource) {
  //     var mobileDevices = ['Android', 'iOS'];

  //     for(var j=0; j<mobileDevices.length; j++) {
  //       if(loginSource.indexOf(mobileDevices[j]) > -1) {
  //         return true;
  //       }
  //     }

  //     return false;
  //   };

  //   return function(users, selectedDeviceType) {
  //     if(!selectedDeviceType || !users) {
  //       return users;
  //     }
  //     var filteredUsers = [];

  //     for(var i=0; i<users.length; i++) {
  //       var user = users[i];

  //       if(user.loginSource) {
  //         var isMobileUser = isMobile(user.loginSource);
  //         if((isMobileUser && selectedDeviceType === 'mobile') || (!isMobileUser && selectedDeviceType === 'web') || user.roles.includes(selectedDeviceType)) {
  //           filteredUsers.push(user);
  //         }
  //       }
  //     }

  //     return filteredUsers;
  //   };
  // }
 export const usersWithRoleFilter = function () {
    return function (users, selectedRole) {
      if (!selectedRole) {
        return users;
      }

      var filteredUsers = [];
      for (var i = 0; i < users.length; i++) {
        var user = users[i];

        if (user.roles.length) {
          user.roles.map(function (obj) {
            if (obj._id == selectedRole) {
              filteredUsers.push(user);
            }
          })
        }
      }
      return filteredUsers;
    };
  }
 export const usersWithStatusFilter = function () {
    return function (users, status) {
      if (status) {
        return users.filter((user) => user.isEnabled == status);
      } else {
        return users;
      }
    };
  }
