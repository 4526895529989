
'use strict';

export const $activeClientSettingsService  = function ($rootScope, $activeClient, $clientSetting) {
  var
  lastClient         = false,
  lastClientID       = false,
  lastClientSettings = false,
  callScope = $rootScope,
  rootEvent = 'client-load-settings';

  function broadcast(client, settings) {
    $rootScope.$broadcast(rootEvent, client||lastClient, settings||lastClientSettings);
  }

  function setActiveClientSettings (client, settings) {

    if(!client) { // clean up left over settings

      lastClient = false;
      lastClientID = false;
      lastClientSettings = false;

      return broadcast();
    }

    var
    clientId = client._id || false;

    if(clientId === lastClientID) { // no actual change
      return;
    }

    lastClient         = client;
    lastClientID       = clientId;
    lastClientSettings = settings;

    broadcast();
  }

  function reloadSettings (client) {

    if(!client) {
      return setActiveClientSettings (false);
    }

    lastClientSettings = false; // clear previous results (indicate load/switch is occuring)

    return $clientSetting.fetch(client)
      .then(function (data) {
        setActiveClientSettings (client, data.toJSON());
        return data;
      });
  }

  // $rootScope.$on('$destroy', $activeClient(reloadSettings));
  $activeClient(reloadSettings);

  return function (cb) {
    if(!angular.isFunction(cb)) {
      return false;
    }

    cb.call(callScope, lastClient, lastClientSettings); // call immediate with last client and settings

    return $rootScope.$on(rootEvent, function (evt, client, settings) {
      cb.call(callScope, client, settings);
    });
  };
}
// Dependency Injection


// Dependency Injection
$activeClientSettingsService.$inject = ['$rootScope', '$activeClient', '$clientSetting'];
