
'use strict';

export const $interpretersWOAvailabilityService  = function (InterpretersWOAvailability, $http) {
        return {
            InterpretersWOAvailability: function (client, filter) {
                return InterpretersWOAvailability.getOpenWOCount({
                    id: client._id || client,
                    filterBy: filter
                }).$promise;
            },
            getAvailability: function (client, filter) {
                return InterpretersWOAvailability.getAvailability({
                    _id: client._id || client,
                    filterBy: filter
                }).$promise;
            },
            getInterpreterAvailabilityCount: function (client, filter) {
                return InterpretersWOAvailability.getInterpreterAvailabilityCount({
                    _id: client._id || client,
                    filterBy: filter
                }).$promise;
            },
            getInterpreterWOAvailabilityByDate: function (client, filter) {
                return InterpretersWOAvailability.getInterpreterWOAvailabilityByDate({
                    _id: client._id || client,
                    filterBy: filter
                }).$promise;
            },
            getAvailabilityDetail: function (client, filter) {
                return InterpretersWOAvailability.getAvailabilityDetail({
                    id: client._id || client,
                    filter
                }).$promise;
            },
            getFilterRecords: function (client, filter) {
                return InterpretersWOAvailability.getFilterRecords({
                    _id: client._id || client,
                    filterBy: filter
                }).$promise;
            },
            createAvailability: function (client, data) {
                return InterpretersWOAvailability.createAvailability({
                    _id: client._id || client,
                    data
                }).$promise;
            },
            exportInterpreterAvailability: function (client, filter) {
                return $http({
                    url: '/api/interpreter-availability/' + (client._id || client) + '/export-interpreter-wo-availability',
                    method: "POST",
                    params: {
                        filter
                    },
                    responseType: "arraybuffer"
                });
            },
        };
    }
// Dependency Injection


// Dependency Injection
$interpretersWOAvailabilityService.$inject = ['InterpretersWOAvailability', '$http'];
