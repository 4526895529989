export const selectionFilter = function () {
  return function (arr, selectedDateData, toggle) {
      if (selectedDateData.selectedCourt) {
          if (toggle.openWOToggle1 == 'Court') {
              let a = arr.findIndex((obj) => obj.hrgloc == selectedDateData.selectedCourt)
              if (a >= 0) {
                  return arr;
              }
          } else {
              let b = arr.findIndex((obj) => obj.langcode == selectedDateData.selectedCourt)
              if (b >= 0) {
                  return arr;
              }
          }
      } else {
          return arr;
      }
  };
}
