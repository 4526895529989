


'use strict';

export const TaskReportsManagerFactory  = function ($resource, appConfig) {
    return $resource(appConfig.apiUrl + '/api/reports/:id/:controller', {
      id: '@_id'
    },
    {
      stats: {
        method: 'POST',
        params: {
          controller: 'tasks',
          id: '@id'
        },
        isArray: false
      },
      managerStats: {
        method: 'GET',
        params: {
          controller: 'manager-stats'
        },
        isArray: false
      }
    });
  } 
 

// Dependency Injection
TaskReportsManagerFactory.$inject = ['$resource', 'appConfig'];
