'use strict';

export const DashboardSettingsLogsIndexCtrl = function ($rootScope, $scope, $activeClient, $clientSystemLogs, $timeout, $clientUser) {
        $scope.client = {};
        $scope.loading = false;
        $scope.eventTypes = [];
        $scope.pagination = {
            currentPage: 1,
            page: 1,
            total: 0,
            pageLimit: 20,
            itemsPerPage: 20
        };
        $scope.allValue = 'all';
        $scope.search = {
            text: '',
            searchText: null,
            // eventType: '',
            selectedEventType: null,
            filterDate: {
                startDate: moment().subtract(6, 'days').startOf('day'),
                endDate: moment().endOf('day')
            },
            logLevel: $scope.allValue
        };
        $scope.error = null;
        $scope.logLevels = [{
            label: 'Info',
            value: 'info'
        },
        {
            label: 'Error',
            value: 'error'
        }]
        $scope.logsDataSet = [];
        $scope.systemLogs = [];
        $scope.arrUsers = [];
        let isPageLoad = true;

        function getUsers(client) {
            $scope.arrUsers = [];
            $clientUser.list(client)
                .then(function (data) {
                    if (data && data.length) {
                        data.forEach((obj) => {
                            if (obj.isEnabled) {
                                $scope.arrUsers.push({
                                    label: obj.name,
                                    value: obj.userId
                                });
                            }
                        })
                    }
                });
        }

        var init = function () {
            $activeClient(function (client) {
                if (!client) {
                    return;
                }

                $scope.client = client;
                $scope.fetchLogs();
                getUsers(client);
                setTimeout(() => {
                    initDateRangePicker()
                }, 1000)
            });
        };

        $scope.getDistinctValues = function () {
            $clientSystemLogs.getDistinctValues($scope.client)
                .then(function (res) {
                    if (res.status) {
                        var eventTypes = res.data.map((distinctValue) => {
                            return {
                                label: distinctValue.replace(/_/g, ' ').toUpperCase(),
                                value: distinctValue
                            }
                        });
                        $scope.eventTypes = _.sortBy(eventTypes, ['label']);
                    }
                    else {
                        console.error(res.reason);
                    }
                })
                .catch((err) => {
                    let errMessage = err.message || err;
                    if (err && err.data && err.data.message) errMessage = err.data.message;
                    $scope.error = errMessage;
                })
                .finally(() => {
                    isPageLoad = false;
                });
        }

        $scope.onChangeFilter = function (e) {
            if (isPageLoad) return;
            if (e) {
                isPageLoad = false;
                $scope.pagination.currentPage = 1;
                $scope.pagination.page = 1;
                $scope.fetchLogs();
            }
        }
        $scope.fetchLogs = function () {
            $scope.isLoading = true;
            $scope.systemLogs = [];
            $scope.logsDataSet = [];
            $scope.error = null;
            // if ($scope.search.selectedEventType == $scope.allValue) {
            //     $scope.search.eventType = '';
            // } else {
            // $scope.search.eventType = $scope.search.selectedEventType;
            // }
            $scope.search.page = $scope.pagination.page;
            $scope.search.pageLimit = $scope.pagination.pageLimit;
            $clientSystemLogs.getLogs($scope.client, $scope.search)
                .then(function (res) {
                    if (res.status) {
                        var logs = res.data.logs;
                        for (var i = 0; i < logs.length; i++) {
                            logs[i].eventType = logs[i].eventType.replace(/_/g, ' ');
                            if (logs[i].extra) {
                                logs[i].extraDetails = Object.entries(logs[i].extra).map((entry) => {
                                    return {
                                        key: entry[0],
                                        value: typeof entry[1] === 'object' ? JSON.stringify(entry[1]) : entry[1]
                                    };
                                });
                            }
                            $scope.logsDataSet.push(logs[i]);
                        }
                        $scope.pagination.total = res.data.totalCount;
                        // paginator();
                        $scope.systemLogs = $scope.logsDataSet //.slice($scope.begin, $scope.end);
                        if ($scope.systemLogs && $scope.systemLogs.length == 0) {
                            $scope.error = 'No Logs Found!';
                        }
                    }
                    else {
                        console.log(res.reason);
                        $scope.error = res.reason || 'No Logs Found!';
                    }
                })
                .catch((err) => {
                    console.log(err)
                    let errMessage = err.message || err;
                    if (err && err.data && err.data.message) errMessage = err.data.message;
                    $scope.error = errMessage;
                })
                .finally(() => {
                    $scope.isLoading = false;
                    if (isPageLoad) {
                        $scope.getDistinctValues();
                    } else {
                        isPageLoad = false;
                    }
                });
        };

        function paginator() {
            $scope.begin = (($scope.pagination.currentPage - 1) * $scope.pagination.itemsPerPage);
            $scope.end = $scope.begin + $scope.pagination.itemsPerPage;
            $scope.systemLogs = $scope.logsDataSet.slice($scope.begin, $scope.end);
        }

        $scope.loadData = function () {
            // $scope.begin = (($scope.pagination.page - 1) * $scope.pagination.itemsPerPage);
            // $scope.end = $scope.begin + $scope.pagination.itemsPerPage;
            // $scope.systemLogs = $scope.logsDataSet.slice($scope.begin, $scope.end);
        }

        $scope.isTakenMoreTime = function(log) {
            if (log.extra && log.extra.jobEndAt && log.extra.jobStartAt) {
                let ended = moment.utc(log.extra.jobEndAt),
                startAt = moment.utc(log.extra.jobStartAt);
                // console.warn(ended, startAt, moment(ended).diff(startAt, 'seconds'))
                return moment(ended).diff(startAt, 'minutes') >= 5
            }
            return false;

        }
        function initDateRangePicker() {
            function cb(start, end) {
                $timeout(function () {
                    if (!start || !end) {
                        return;
                    }
                    $('#logDateFilter span').html(start.format('MM/DD/YYYY') + ' - ' + end.format('MM/DD/YYYY'));
                    $scope.search.filterDate.startDate = start.format();
                    $scope.search.filterDate.endDate = end.format();
                    $scope.pagination.currentPage = 1;
                    $scope.pagination.page = 1;
                    $scope.fetchLogs();
                }, 0);
            }

            $('#logDateFilter').daterangepicker({
                startDate: moment($scope.search.filterDate.startDate).format('MM/DD/YYYY'),
                endDate: moment($scope.search.filterDate.endDate).format('MM/DD/YYYY'),
                opens: 'right',
                ranges: {
                    'Today': [moment().startOf('day'), moment().endOf('day')],
                    'Yesterday': [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
                    'Last 7 Days': [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],
                    'Last 30 Days': [moment().subtract(29, 'days').startOf('day'), moment().endOf('day')],
                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                }
            }, cb);

            var text = moment($scope.search.filterDate.startDate).format('MM/DD/YYYY') + ' to ' + moment($scope.search.filterDate.endDate).format('MM/DD/YYYY');
            $("#logDateFilter span").html(text);
        }

        init();
    }
// Dependency Injection
DashboardSettingsLogsIndexCtrl.$inject = ["$rootScope","$scope","$activeClient","$clientSystemLogs","$timeout","$clientUser"];

// angular.module('coordinateApp').controller('DashboardSettingsLogsIndexCtrl', DashboardSettingsLogsIndexCtrl);
