


'use strict';

export const ClientDailyWoReportsFactory  = function (appConfig, $resource) {
    return $resource(appConfig.apiUrl + '/api/wo-daily-reports/:id/:controller', {
      id: '@_id'
    },
      {
        getWoDailyReports: {
          method: 'GET',
          params: {
            id: '@id'
          }
        }
      });
  } 
 

// Dependency Injection
ClientDailyWoReportsFactory.$inject = ['appConfig', '$resource'];
