'use strict';

export const interpreterCourtDistanceDirective = function ($rootScope, $q, $clientSwitcher, ngToast, $activeClient, $clientInterpreterCourtDistance) {
        return {
            restrict: 'E',
            templateUrl: 'app/account/dashboard/main-dashboard/interpreter-resource/tabs/profile/tabs/court-distance/ic-court-distance.html',

            scope: {
                interpreterDetails: '=interpreterDetails',
                isInterpreter: '=isInterpreter',
            },
            link: function ($scope) {
                let killClientWatch, currentCli = $clientSwitcher.getCurrentClient();
                $scope.intLangsList = [];
                $scope.InterpreterName = `${$scope.interpreterDetails.firstname} ${$scope.interpreterDetails.middlename ? $scope.interpreterDetails.middlename : ''} ${$scope.interpreterDetails.lastname}`;
                $scope.permissionsObj = {}
                $scope.pagination = {
                    currentPage: 1,
                    page: 1,
                    total: 0,
                    pageSize: 10,
                    itemsPerPage: 10
                };
                $scope.filter = {searchText:''};
                function init() {
                    killClientWatch = $activeClient(function (client) {
                        if (!client) {
                            return;
                        }
                        currentCli = client;
                        // $scope.loadClientOffices(currentCli);
                        loadInterpreterCourtDistance()
                    });
                }

                function loadInterpreterCourtDistance() {
                    if ($scope.isLoading) return;
                    $scope.error = null;
                    $scope.interpreterCourtDistances = [];
                    $scope.total = 0;
                    $scope.isLoading = true;
                    let filter = {
                        vendorid: $scope.interpreterDetails.vendorid,
                        // page: $scope.pagination.page,
                        // pageSize: $scope.pagination.pageSize
                    }
                    $clientInterpreterCourtDistance.interpreterCourtDistance(currentCli, filter)
                        .then((res) => {
                            if (res.status) {
                                if (res.result && res.result.length) {
                                    $scope.interpreterCourtDistances = res.result;
                                    $scope.total = res.result.length;
                                } else {
                                    $scope.error = 'No Record Found.';
                                }
                            }
                        })
                        .catch((err) => {
                            let errMessage = err.message || err;
                            if (err && err.data && err.data.message) {
                                errMessage = err.data.message;
                            } else if (err && err.data && err.data.reason) {
                                errMessage = err.data.reason;
                            }
                            $scope.error = errMessage;
                        })
                        .finally(() => {
                            $scope.isLoading = false;
                        })

                }

                $scope.onSearch = () => {
                    $scope.pagination.page = 1
                }
                $scope.onPageChange = () => {
                }

                $scope.searchFilter = function(item) {
                    let searchText = $scope.filter.searchText && $scope.filter.searchText.length ? $scope.filter.searchText.toLowerCase() : null ;
                    if (!searchText || (item.court_code.toLowerCase().includes(searchText)) || (item.court_name.toLowerCase().includes(searchText)) ){
                        return true;
                    }
                    return false;
                };
                $scope.$on('$destroy', function () {
                    if (killClientWatch) {
                        killClientWatch();
                    }
                });
                init();
            }
        }
    }
// Dependency Injection
interpreterCourtDistanceDirective.$inject = ["$rootScope","$q","$clientSwitcher","ngToast","$activeClient","$clientInterpreterCourtDistance"];
