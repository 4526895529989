'use strict';

export const DashboardSettingsProgramIndexCtrl = function ($state, $scope, $clientSwitcher, $activeClient, $programs, $auth, ngToast, Modal) {
        let currentCli = null;
        $scope.loading = false;
        $scope.hasPermission = {};
        $scope.allPrograms = [];
        $scope.sortingOrders = [{
            label: 'A-Z',
            value: 'true'
        }, {
            label: 'Z-A',
            value: 'false'
        }];
        $scope.data = {
            isLoading: false,
            error: null
        }
        $scope.search = {
            searchText: '',
            orderVal: 'true'
        };

        function getPrograms(isPageLoading) {
            $scope.data.error = null;
            if (isPageLoading) $scope.data.isLoading = true;
            $programs.getPrograms(currentCli)
                .then(({ programList, status }) => {
                    if (status) {
                        if (programList && programList.length) {
                            $scope.allPrograms = programList;
                        } else {
                            $scope.data.error = 'No program found!';
                        }
                    }
                    $scope.hasPermission.allowEditProgram = $auth.getCurrentUser().permissions.includes('SETTING_PROGRAM_EDIT');
                    $scope.hasPermission.allowDeleteProgram = $auth.getCurrentUser().permissions.includes('SETTING_PROGRAM_DELETE');
                })
                .catch((err) => {
                    let errMessage = err.message || err;
                    if (err && err.data && err.data.message) {
                        errMessage = err.data.message;
                    } else if (err && err.data && err.data.reason) {
                        errMessage = err.data.reason;
                    }
                    $scope.data.error = errMessage;
                })
                .finally(() => $scope.data.isLoading = false);
        }

        // listen for client switching
        $scope.$on('$destroy', $activeClient(function (client) {
            if (!client) return;
            currentCli = client;
            getPrograms(true);
        }));

        $scope.onDelete = function (program) {
            var modalTitle = 'Delete program';
            var modalBody = "Are you sure you want to delete <strong>" + (program.name) + "</strong> ?";
            var notificationContent = 'Program deleted successfully';
            console.log(program)
            Modal.confirm.show(modalTitle, modalBody, "Yes", "No", function () {
                $programs.delete(currentCli, program._id).then(function (result) {
                    if (result.status) {
                        ngToast.create({
                            className: 'success',
                            content: notificationContent
                        });
                        getPrograms(false);
                    } else {
                        ngToast.create({
                            className: 'danger',
                            content: 'Error' + result.reason
                        });
                    }
                });
            });
        }
    }
// Dependency Injection
DashboardSettingsProgramIndexCtrl.$inject = ["$state","$scope","$clientSwitcher","$activeClient","$programs","$auth","ngToast","Modal"];

// angular.module('coordinateApp').controller('DashboardSettingsProgramIndexCtrl', DashboardSettingsProgramIndexCtrl);
