'use strict';

export const RejectConfig = function ($stateProvider) {
    $stateProvider
      .state('app.external.reject', {
        url: '/reject',
        data: {
          roles: []
        },
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./external/reject/reject.html', '497998a'); }],
        controller: 'ExternalTaskRejectCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./external/reject/reject.controller.js', 'ExternalTaskRejectCtrl'); }]
                      }
      });
  }

// Dependency Injection
RejectConfig.$inject = ["$stateProvider"];
