import './signup.scss';
'use strict';

export const SignupConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.services.signup', {
        url: '/signup',
        data: {
          roles: []
        },
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/services/signup/signup.html', 'caa3a1aa'); }],
        controller: 'ServiceSignupCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/services/signup/signup.controller.js', 'ServiceSignupCtrl'); }]
                      }
      })
  }
// Dependency Injection
SignupConfig.$inject = ["$stateProvider"];
