
'use strict';

export const $offeringRulesService  = function (OfferingRules) {
        return {
            getOfferingRules: function (client) {
                return OfferingRules.getOfferingRules({
                    id: client._id || client
                }).$promise;
            },
            getRuleById: function (client, ruleId) {
                return OfferingRules.getRuleById({
                    id: client._id || client,
                    controller: ruleId
                }).$promise;
            },
            create: function (client, data) {
                return OfferingRules.create({
                    _id: client._id || client,
                    data
                }).$promise;
            },
            update: function (client, data) {
                return OfferingRules.update({
                    _id: client._id || client,
                    data
                }).$promise;
            },
            toggleAccess: function (ruleId, client) {
                return OfferingRules.toggleAccess({
                    ruleId,
                    _id: client._id || client
                }).$promise;
            },
            delete: function (client, ruleId) {
                return OfferingRules.delete({
                    _id: client._id || client,
                    ruleId
                }).$promise;
            },
            updateRuleOrder: function (client, data) {
                return OfferingRules.updateRuleOrder({
                    _id: client._id || client,
                    data
                }).$promise;
            },
        };
    }
// Dependency Injection


// Dependency Injection
$offeringRulesService.$inject = ['OfferingRules'];
