'use strict';

export const DashboardSettingsNotificationManageIndexCtrl = function ($scope, $activeClient, ngToast, $notificationManagement, Modal, $attributes, $events, $clientRoles, $auth, System_Event, WO_Event,Service_Event, Service_Portal_Event) {
        let currentCli = null
        $scope.notificationEvents = [];
        $scope.allRoles = [];
        $scope.eventsArr = $events && $events.length ? JSON.parse(JSON.stringify($events)) : $events;
        $scope.notificationEventsArr = $events && $events.length ? JSON.parse(JSON.stringify($events)) : $events;
        $scope.activeNotification = 0;
        $scope.search = {
            searchText: '',
            orderVal: 'true'
        };
        $scope.sortingOrders = [{
            label: 'A-Z',
            value: 'true'
        }, {
            label: 'Z-A',
            value: 'false'
        }];
        $scope.formData = {};
        $scope.woEventKey = WO_Event;
        $scope.systemEventKey = System_Event;
        $scope.servicePortalKey = Service_Portal_Event;
        $scope.selectedTab = 'All Events';
        $scope.tabs = [{
            label: 'all_event',
            value: 'All Events',
            position: 1,
            activeCount: 0,
            inActiveCount: 0
        }, {
            label: 'wo_event',
            value: WO_Event,
            position: 2,
            activeCount: 0,
            inActiveCount: 0
        }, {
            label: 'system_event',
            value: System_Event,
            position: 3,
            activeCount: 0,
            inActiveCount: 0
        }, {
            label: 'service_portal_event',
            value: Service_Portal_Event,
            position: 4,
            activeCount: 0,
            inActiveCount: 0
        }, {
            label: 'service_event',
            value: Service_Event,
            position: 5,
            activeCount: 0,
            inActiveCount: 0
        }];

        $scope.data = {
            isLoading: false,
            error: null,
            addEventNotificationSettings: false
        }
        let notificationSettings = {
            event: null,
            description: null,
            isEnabled: true,
            roles: [{
                roleId: null,
                roleType: null,
                isEnabled: true,
                onlyWOAssociatedUser: false,
                subject: null,
                attributes: [{
                    name: null,
                    variables: [{
                        name: null
                    }],
                    attributesArr: $attributes
                }]
            }],
            events: $events
        }
        $scope.strSettings = function (str) {
            return {
                selectedStr: ' ',
                noSelection: 'No ' + str + ' Selected',
                allLabel: 'All ' + str
            };
        };

        // delete events;
        $scope.notificationSettings = JSON.parse(JSON.stringify(notificationSettings));
        $scope.hasPermission = {};
        $scope.hasPermission.allowAddNotificationEvent = $auth.getCurrentUser().permissions.includes('SETTING_ADD_NOTIFICATION_EVENT');
        $scope.hasPermission.allowDeleteNotificationEvent = $auth.getCurrentUser().permissions.includes('SETTING_DELETE_NOTIFICATION_EVENT');

        function loadNotificationEvents(currentCli, isSilentLoading, updatedIndex) {
            $scope.data.error = null;
            if (!isSilentLoading) {
                $scope.data.isLoading = true;
            }
            let filter = {};
            if ($scope.data.event) {
                filter.event = $scope.data.event;
            }
            $scope.notificationEvents = [];
            $notificationManagement.getNotificationEvents(currentCli, filter)
                .then((res) => {
                    if (res && res.status) {
                        if (res.notificationEvents && res.notificationEvents.length) {
                            $scope.notificationEvents = res.notificationEvents;
                            let usedEvent = [];
                            $scope.notificationEvents.forEach((obj) => {
                                usedEvent.push(obj.event);
                            });
                            $scope.activeNotification = res.notificationEvents.filter(x => x.isEnabled).length;
                            $scope.notificationSettings.events = [];
                            $scope.notificationEvents.map((obj) => {
                                obj.roles.map((role) => {
                                    let usedAttributes = [];
                                    role.attributes.forEach((obj) => { usedAttributes.push(obj.name) });
                                    role.attributes.map((attribute) => {
                                        attribute.attributesArr = $attributes && $attributes.length ? JSON.parse(JSON.stringify($attributes)) : $attributes;
                                        usedAttributes.forEach((Att) => {
                                            attribute.attributesArr.forEach((e, i) => {
                                                if (attribute.name != e.value) {
                                                    if (e.value == Att) {
                                                        attribute.attributesArr.splice(i, 1)
                                                    }
                                                }
                                            })
                                        })
                                        let findIndex = $attributes.findIndex((obj) => obj.value == attribute.name);
                                        if (findIndex >= 0) {
                                            attribute.variablesArr = $attributes[findIndex].child;
                                        }
                                        onVariableChange(attribute);
                                        return attribute;
                                    });
                                    return role;
                                });
                                obj.events = [];
                                if (usedEvent) {
                                    $scope.eventsArr.forEach((e) => {
                                        if (usedEvent.indexOf(e.value) < 0) {
                                            obj.events.push(e);
                                        }
                                        if (obj.event == e.value) {
                                            obj.events.push(e);
                                        }
                                    })
                                } else {
                                    obj.events = $events;
                                }
                                return obj;
                            })
                            $scope.eventsArr.forEach((e) => {
                                if (usedEvent.indexOf(e.value) < 0) {
                                    $scope.notificationSettings.events.push(e);
                                }
                            })
                            if (updatedIndex >= 0) {
                                $scope.notificationEvents[updatedIndex].open = true;
                            }
                        } else {
                            $scope.data.error = 'No notification events found.';
                        }
                        $scope.calculateCount()
                    } else {
                        $scope.data.error = res.message;
                    }
                })
                .catch((err) => {
                    let errMessage = err.message || err;
                    if (err && err.data && err.data.message) errMessage = err.data.message;
                    $scope.data.error = errMessage;
                })
                .finally(() => {
                    $scope.data.isLoading = false;

                })
        };

        $scope.calculateCount = function () {
            $scope.tabs[0].activeCount = $scope.notificationEvents.filter(x => x.isEnabled).length;
            $scope.tabs[0].inActiveCount = $scope.notificationEvents.filter(x => !x.isEnabled).length;
            $scope.tabs[1].activeCount = $scope.notificationEvents.filter(x => x.isEnabled && x.eventType == WO_Event).length;
            $scope.tabs[1].inActiveCount = $scope.notificationEvents.filter(x => !x.isEnabled && x.eventType == WO_Event).length;
            $scope.tabs[2].activeCount = $scope.notificationEvents.filter(x => x.isEnabled && x.eventType == System_Event).length;
            $scope.tabs[2].inActiveCount = $scope.notificationEvents.filter(x => !x.isEnabled && x.eventType == System_Event).length;
            $scope.tabs[3].activeCount = $scope.notificationEvents.filter(x => x.isEnabled && x.eventType == Service_Portal_Event).length;
            $scope.tabs[3].inActiveCount = $scope.notificationEvents.filter(x => !x.isEnabled && x.eventType == Service_Portal_Event).length;
            $scope.tabs[4].activeCount = $scope.notificationEvents.filter(x => x.isEnabled && x.eventType == Service_Event).length;
            $scope.tabs[4].inActiveCount = $scope.notificationEvents.filter(x => !x.isEnabled && x.eventType == Service_Event).length;
        }

        function onVariableChange(attribute) {
            let usedVariables = [];
            attribute.variables.forEach((obj) => {
                if (obj.name) {
                    usedVariables.push(obj.name)
                }
            });
            attribute.variables.map((obj) => {
                let findIndex = $attributes.findIndex((obj) => obj.value == attribute.name);
                if (findIndex >= 0) {
                    obj.variablesArr = JSON.parse(JSON.stringify($attributes[findIndex].child));
                }
                usedVariables.forEach((Att) => {
                    obj.variablesArr.forEach((e, i) => {
                        if (obj.name != e.value) {
                            if (e.value == Att) {
                                obj.variablesArr.splice(i, 1)
                            }
                        }
                    })
                })
                return obj;
            });
            return attribute;
        }

        $scope.$on('$destroy', $activeClient(function (client) {
            if (!client) {
                return;
            }

            $scope.client = client;
            currentCli = client;
            // Load settings
            loadNotificationEvents(currentCli);
            loadRoles(currentCli);
            $scope.notificationEventsArr = $scope.notificationEventsArr.map((obj) => {
                obj.showIf = function () {
                    return $scope.selectedTab == 'All Events' ? true : obj.type == $scope.selectedTab;
                }
                return obj;
            })
        }));

        $scope.onSubmit = function (form, isUpdate, notificationSettings) {
            if (form.$invalid || !currentCli) return;

            if (notificationSettings.eventType == System_Event) {
                notificationSettings.roles.map((role) => {
                    role.attributes = [];
                    return role;
                })
            }
            let requestData  = _.pick(notificationSettings, ['_id', 'updatedAt', 'createdAt', 'client', 'createdBy', 'event', 'description', 'eventType', 'updateBy', 'roles', 'isEnabled', 'open']) ;
            if (isUpdate) {
                $scope.onUpdate(form, requestData);
            } else {
                $notificationManagement.create(currentCli, requestData)
                    .then((result) => {
                        if (result.status) {
                            $scope.resetEvent();
                            loadNotificationEvents(currentCli);
                            return result;
                        } else {
                            ngToast.create({
                                className: 'danger',
                                content: result.message || 'something went wrong!'
                            })
                        }
                    }).catch((err) => {
                        let errMessage = err.message || err;
                        if (err && err.data && err.data.message) errMessage = err.data.message;
                        ngToast.create({
                            className: 'danger',
                            content: errMessage
                        })
                    })
            }
        };

        $scope.onUpdate = function (form, updatedNotificationSettings) {
            if (form.$invalid || !currentCli) return;
            let requestData  = _.pick(updatedNotificationSettings, ['_id', 'updatedAt', 'createdAt', 'client', 'createdBy', 'event', 'description', 'eventType', 'updateBy', 'roles', 'isEnabled', 'open']) ;
            $notificationManagement.update(currentCli, requestData)
                .then((result) => {
                    if (result && result.status) {
                        $scope.data.addEventNotificationSettings = false;
                        let updatedIndex = $scope.notificationEvents.findIndex((obj) => obj._id == updatedNotificationSettings._id);
                        // $scope.notificationEvents[updatedIndex] = result.notificationEvent;
                        // $scope.notificationEvents[updatedIndex].open = true;
                        ngToast.create({
                            className: 'success',
                            content: result.message
                        })
                        loadNotificationEvents(currentCli, true, updatedIndex);
                        return result;
                    } else {
                        ngToast.create({
                            className: 'danger',
                            content: result.message || 'something went wrong!'
                        })
                    }
                }).catch((err) => {
                    let errMessage = err.message || err;
                    if (err && err.data && err.data.message) errMessage = err.data.message;
                    ngToast.create({
                        className: 'danger',
                        content: errMessage
                    })
                })
        };

        $scope.onDelete = function (e, notificationEvent) {
            e.stopPropagation();
            e.preventDefault();
            var modalTitle = 'Delete notification event';
            var modalBody = "Are you sure you want to delete <strong>" + (notificationEvent.event) + "</strong>?";
            var notificationContent = 'Notification event deleted successfully';

            Modal.confirm.show(modalTitle, modalBody, "Yes", "No", function () {
                $notificationManagement.delete(currentCli, notificationEvent._id).then(function (result) {
                    if (result.status) {
                        ngToast.create({
                            className: 'success',
                            content: notificationContent
                        });
                        loadNotificationEvents(currentCli);
                    } else {
                        ngToast.create({
                            className: 'danger',
                            content: 'Error' + result.reason
                        });
                    }
                });
            });
        }

        $scope.resetEvent = function () {
            $scope.data.addEventNotificationSettings = false;
            $scope.notificationSettings = JSON.parse(JSON.stringify(notificationSettings));
        }

        $scope.onEventSelection = function () {
            // loadNotificationEvents(currentCli);
        }

        function loadRoles(cli) {
            $clientRoles.getRoles(cli)
                .then(({ data }) => {
                    $scope.allRoles = [];
                    data.forEach(({ name, _id, code, isEnabled }) => {
                        if (isEnabled) {
                            $scope.allRoles.push({ label: name, value: _id, code })
                        }
                    });
                })
        }

        $scope.tabSelected = function (tabName, selectedData) {
            $scope.selectedTab = tabName;
            if ($scope.data.event) {
                $scope.data.event = null;
                loadNotificationEvents(currentCli);
            }
        };
        $scope.isTabSelected = function (tabName) {
            return $scope.selectedTab === tabName;
        };

        $scope.eventTypeFilter = function (event) {
            if ($scope.selectedTab == 'All Events') return true;

            if ($scope.selectedTab == WO_Event) {
                return event.eventType == WO_Event;
            }

            if ($scope.selectedTab == System_Event) {
                return event.eventType == System_Event;
            }

            if ($scope.selectedTab == Service_Portal_Event) {
                return event.eventType == Service_Portal_Event;
            }

            if ($scope.selectedTab == Service_Event) {
                return event.eventType == Service_Event;
            }
        }
    }

// Dependency Injection
DashboardSettingsNotificationManageIndexCtrl.$inject = ["$scope","$activeClient","ngToast","$notificationManagement","Modal","$attributes","$events","$clientRoles","$auth","System_Event","WO_Event","Service_Event","Service_Portal_Event"];


// angular.module('coordinateApp').controller('DashboardSettingsNotificationManageIndexCtrl', DashboardSettingsNotificationManageIndexCtrl);
