


'use strict';

export const ProgramsFactory  = function ($resource, appConfig) {
        return $resource(appConfig.apiUrl + '/api/program/:id/:controller', {
            id: '@_id'
        },
            {
                getPrograms: {
                    method: 'GET',
                    params: {
                        controller: 'get-programs'
                    }
                },
                getProgramById: {
                    method: 'GET',
                    params: {
                        id: '@id'
                    }
                },
                create: {
                    method: 'POST',
                    params: {
                        controller: 'create'
                    }
                },
                update: {
                    method: 'POST',
                    params: {
                        controller: 'update'
                    }
                },
                delete: {
                    method: 'POST',
                    params: {
                        controller: 'delete',
                    }
                }
            });
    } 
 

// Dependency Injection
ProgramsFactory.$inject = ['$resource', 'appConfig'];
