'use strict';

export const DashboardSettingsDevicesIndexCtrl = function ($state, $scope, $clientSwitcher, $devicesManager, Modal, ngToast) {
		var client = $clientSwitcher.getCurrentClient();
		$scope.clientName = client.name;
		$scope.devices = [];
		$scope.loading = true;

		var getDevices = function() {
			$devicesManager.getDevices(client._id).then(function(devices) {
			    $scope.devices = devices;
			})
			.finally(function(){
				$scope.loading = false;
			});
		};

		$scope.toggleDeviceState = function(device) {
			var message = device.enabled ? 'Device '+device.name+ ' disabled' : 'Device '+device.name+ ' enabled';
			if(device.enabled) {
				Modal.confirm.show('Disable Device', 'Are you sure you want to disable <b>' + device.name + '</b>?', 'Yes', 'No', function(){
			      $devicesManager.toggleState(client._id, device).then(function(result) {
						if(result && result.status) {
							getDevices();
							ngToast.create({
					          className: 'success',
					          content: message
					        });
						}
					});
			    });
			} else {
				$devicesManager.toggleState(client._id, device).then(function(result) {
					if(result && result.status) {
						getDevices();
						ngToast.create({
				          className: 'success',
				          content: message
				        });
					}
				});
			}
		};

		$scope.deleteDevice = function(device) {
			Modal.confirm.show('Remove Device', 'Are you sure you want to remove <b>' + device.name + '</b>?', 'Yes', 'No', function(){
		       $devicesManager.deleteDevice(client._id, device).then(function(result) {
					if(result && result.status) {
						getDevices();
					}
				});
		    });
		};

		$scope.editDevice = function(device) {
			$state.go('app.account.dashboard.settings.devices.update', {id: device._id});
		};

		getDevices();
    }
// Dependency Injection
DashboardSettingsDevicesIndexCtrl.$inject = ["$state","$scope","$clientSwitcher","$devicesManager","Modal","ngToast"];

// angular.module('coordinateApp').controller('DashboardSettingsDevicesIndexCtrl', DashboardSettingsDevicesIndexCtrl);
