


'use strict';

export const ClientQueryBuilderFactory  = function ($resource, appConfig) {
    return $resource(appConfig.apiUrl + '/api/query-builder/:id/:controller', {
      id: '@_id'
    },
    {
      getColumnNames: {
        method: 'GET',
        params: {
          id: '@id'
        }
      },
      getRecords: {
        method: 'GET',
        params: {
          id: '@id'
        }
      },
      getRecordsWithPagination: {
        method: 'GET',
        params: {
          id: '@id'
        }
      }
    });
  } 
 

// Dependency Injection
ClientQueryBuilderFactory.$inject = ['$resource', 'appConfig'];
