import './office-operations.scss';
'use strict';

export const OfficeOperationsConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard.officeOperations', {
        url: '/office-operations',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/office-operations/office-operations.html', '3ef64f8a'); }],
        controller: 'DashboardOfficeOperationsCtrl',
        resolve: {
            lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/office-operations/office-operations.controller.js', 'DashboardOfficeOperationsCtrl'); }],
            '$userPermissions': ['$clientUser', '$clientSwitcher', function($clientUser, $clientSwitcher) {
              return $clientUser.getUserPermissions($clientSwitcher.getCurrentClient());
            }]
        },
        data: {
          ability: 'client.officeOperations',
          permission: 'COURT_OPS_READ'
        },
      });
  }
// Dependency Injection
OfficeOperationsConfig.$inject = ["$stateProvider"];
