'use strict';

export const InventorysConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard.settings.inventorys', {
        url: '/inventories',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/inventorys/inventorys.html', 'ae1a03ca'); }],
        controller: 'DashboardSettingsInventorysCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/inventorys/inventorys.controller.js', 'DashboardSettingsInventorysCtrl'); }]
                      },
      })
      .state('app.account.dashboard.settings.inventorys.index', {
        url: '/inventories',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/inventorys/index/index.html', '2d24f7ca'); }],
        controller: 'DashboardSettingsInventorysIndexCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/inventorys/index/index.controller.js', 'DashboardSettingsInventorysIndexCtrl'); }]
                      }
      })
      .state('app.account.dashboard.settings.inventorys.update', {
        url: '/update/:id',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/inventorys/update/update.html', '6213b28a'); }],
        controller: 'DashboardSettingsInventorysUpdateCtrl',
        resolve: {
            lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/inventorys/update/update.controller.js', 'DashboardSettingsInventorysUpdateCtrl'); }],
            '$selectedInventory': ['$stateParams','$clientInventory', '$clientSwitcher', function ($stateParams, $clientInventory, $clientSwitcher) {
              return $clientInventory.detail($clientSwitcher.getCurrentClient(), $stateParams.id).then(function(res) {
                if(res.status) {
                  return res.data;
                }
                else {
                  throw new Error(res.reason);
                }
              });
            }]
        },
      })
      .state('app.account.dashboard.settings.inventorys.create', {
        url: '/create',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/inventorys/create/create.html', '9b59122a'); }],
        controller: 'DashboardSettingsInventorysCreateCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/inventorys/create/create.controller.js', 'DashboardSettingsInventorysCreateCtrl'); }]
                      },
      });
  }
// Dependency Injection
InventorysConfig.$inject = ["$stateProvider"];
