'use strict';

export const LanguagesConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard.settings.languages', {
        url: '/languages',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/languages/languages.html', 'aca9efca'); }],
        controller: 'DashboardSettingsLanguagesCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/languages/languages.controller.js', 'DashboardSettingsLanguagesCtrl'); }]
                      }
      })
      .state('app.account.dashboard.settings.languages.index', {
        url: '/index',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/languages/index/index.html', 'b05c4d2a'); }],
        controller: 'DashboardSettingsLanguagesIndexCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/languages/index/index.controller.js', 'DashboardSettingsLanguagesIndexCtrl'); }]
                      }
      })
      .state('app.account.dashboard.settings.languages.create', {
        url: '/create',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/languages/create/create.html', 'e900560a'); }],
        controller: 'DashboardSettingsLanguagesCreateCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/languages/create/create.controller.js', 'DashboardSettingsLanguagesCreateCtrl'); }]
                      }
    })
    .state('app.account.dashboard.settings.languages.update', {
        url: '/update/:id',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/languages/update/update.html', '62b0e6ea'); }],
        controller: 'DashboardSettingsLanguagesUpdateCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/languages/update/update.controller.js', 'DashboardSettingsLanguagesUpdateCtrl'); }],
                          '$selectedLanguage': ['$stateParams', '$clientLanguages', '$clientSwitcher', function($stateParams, $clientLanguages, $clientSwitcher) {
                              return $clientLanguages.getLanguageById($clientSwitcher.getCurrentClient(), $stateParams.id);
                            }]
                      },

    });
  }
// Dependency Injection
LanguagesConfig.$inject = ["$stateProvider"];
