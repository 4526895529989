'use strict';

 export const $afterCognitoAuthLoginService = function (appConfig, $http) {
    return function (obj) {
      // console.log('==== $afterCognitoAuthLogin', obj)
      $http.post(appConfig.apiUrl + '/auth/login', obj)
        .success(function (data) {
          return data;
        })
        .error(function (err) {
          console.log(err)
          return err;
        });
    }
  }
// Dependency Injection
$afterCognitoAuthLoginService.$inject = ["appConfig","$http"];
