'use strict';

export const DashboardSettingsRulesIndexCtrl = function ($state, $scope, $clientSwitcher, Modal, $rulesManager, ngToast) {
		$scope.client = $clientSwitcher.getCurrentClient();
		$scope.clientName = $scope.client.name;
		$scope.rules = [];
		$scope.loading = true;

		var getRules = function() {
			$rulesManager.getRules($scope.client._id).then(function(rules) {
			    $scope.rules = rules;
			})
			.finally(function() {
					$scope.loading = false;
			});
		};

		$scope.deleteRule = function(rule) {
			Modal.confirm.show('Remove Rule', 'Are you sure you want to remove <b>' + rule.title + '</b>?', 'Yes', 'No', function(){
		      $rulesManager.deleteRule($scope.client, rule).then(function(data){
		        if(data.status){
		          $scope.rules.splice( $scope.rules.indexOf(rule) , 1);
		        }else{
		          $scope.message = data.reason;
		        }
		        $scope.userEmail = '';
		      });
		    });
		};

		$scope.toggleRule = function(rule) {
			var message = rule.isRuleActive ? 'Rule '+rule.title+' disabled' : 'Rule '+rule.title+' enabled';
			if(rule.isRuleActive) {
				Modal.confirm.show('Disable Rule', 'Are you sure you want to disable rule <b>' + rule.title + '</b>?', 'Yes', 'No', function(){
			        $rulesManager.toggleRuleState($clientSwitcher.getCurrentClient(), rule).then(function(result) {
			          if(result.status) {
			          	rule.isRuleActive = !rule.isRuleActive;
			            ngToast.create({
			              className: 'success',
			              content: message
			            });
			          }
			        });
			    });
			} else {
				$rulesManager.toggleRuleState($clientSwitcher.getCurrentClient(), rule).then(function(result) {
		          if(result.status) {
		          	rule.isRuleActive = !rule.isRuleActive;
		            ngToast.create({
		              className: 'success',
		              content: message
		            });
		          }
		        });
			}
		};

		$scope.editRule = function(rule) {
			$state.go('app.account.dashboard.settings.rules.update', {id: rule._id});
		};

		getRules();
    }
// Dependency Injection
DashboardSettingsRulesIndexCtrl.$inject = ["$state","$scope","$clientSwitcher","Modal","$rulesManager","ngToast"];

// angular.module('coordinateApp').controller('DashboardSettingsRulesIndexCtrl', DashboardSettingsRulesIndexCtrl);
