'use strict';

export const SystemConfig = function ($stateProvider) {
        $stateProvider
            .state('app.account.dashboard.settings.system', {
                url: '/system',
                templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/system/system.html', '8b6da8ca'); }],
                controller: 'SystemCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/system/system.controller.js', 'SystemCtrl'); }]
                      }
            })
    }
// Dependency Injection
SystemConfig.$inject = ["$stateProvider"];
