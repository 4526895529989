
'use strict';

export const $userNotificationService  = function (UserNotification) {
        return {
            getList: function (client, paginationData, filter) {
                return UserNotification.getList({
                    id: client._id || client,
                    page: paginationData && paginationData.page,
                    pageSize: paginationData && paginationData.pageLimit,
                    filter: filter || {}
                }).$promise;
            },
            update: function (client, data, eventType) {
                return UserNotification.update({
                    _id: client._id || client,
                    data,
                    eventType
                }).$promise;
            },
        };
    }
// Dependency Injection


// Dependency Injection
$userNotificationService.$inject = ['UserNotification'];
