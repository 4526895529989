'use strict';


export const SatisfactionContainerCtrl = function ($scope) {
}
 export const satisfactionContainerDirective = function () {
  return {
    controller: 'SatisfactionContainerCtrl as $satisfactionContainerCtrl',
    templateUrl: 'app/account/dashboard/satisfaction/satisfaction-container.html',
    transclude: true,
    link: function (scope, el, attr, ctrl) {
    }
  };
}
 export const SatisfactionEntryCtrl = function ($scope) {
}
 export const satisfactionEntryDirective = function () {
  return {
    controller: 'SatisfactionEntryCtrl as $satisfactionEntryCtrl',
    templateUrl: 'app/account/dashboard/satisfaction/satisfaction-entry.html',
    link: function (scope, el, attr, ctrl) {
    }
  };
}
// Dependency Injection
SatisfactionContainerCtrl.$inject = ["$scope"];
SatisfactionEntryCtrl.$inject = ["$scope"];
