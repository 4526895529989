'use strict';


export const formDivisionDirective = function ($rootScope, $q, $auth, $clientSwitcher, $clientDivision) {
    return {
      restrict: 'E',
      templateUrl: 'components/forms/division.html',
      scope: {
        division: '=ngModel',
        onSubmit: '=formOnSubmit',
        onReset: '=formOnReset'
      },
      link: function(scope, el, attrs) {
        scope.clientSettings = function(){ return $clientSwitcher.getFeatureOptions('client.settings'); };
      }
    };
  }
// Dependency Injection
formDivisionDirective.$inject = ["$rootScope","$q","$auth","$clientSwitcher","$clientDivision"];
