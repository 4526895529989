'use strict';

export const ServicePanelIndexCtrl = function ($rootScope, $scope, $state, $activeClient, Modal, $clientSetting, $auth, $clientSwitcher, $servicePanel, $timeout,  LazyLoadService ) {

  const lazyDirectives = [{"name":"projectHistoryDirective","path":import("@app/account/services/projects/project-history/project-history.directive.js")}];
  $scope.lazyDirectivesLoaded = false;
  Promise.all(lazyDirectives.map(directive=>LazyLoadService.loadDirective(directive.path, directive.name))).then(() => {
          $timeout(function() {
            $scope.lazyDirectivesLoaded = true;

                    $scope.data = {
            counts: {}
        };
        $scope.data.isLoading = true;
        let killClientWatch, currentCli = $clientSwitcher.getCurrentClient();
        function init() {
            killClientWatch = $activeClient(function (client) {
                if (!client) {
                    $scope.data.isLoading = false;
                    return;
                }
                currentCli = client;
                let userPermissions = $auth.getCurrentUser();
                $scope.data.userPermissions = userPermissions.permissions || [];
                $scope.hasServicePanelPermissions = $scope.data.userPermissions.includes('VIEW_SERVICE_PANEL') || false;
                // $scope.getTypeCount();
            });
        }
        $scope.getTypeCount = function () {
            $scope.data.isLoading = true;
            $servicePanel.getServiceCount(currentCli).then((res) => {
                if (res.status) {
                    $scope.data.counts = res.result;
                }
                $scope.data.isLoading = false;
            }, err => {
                $scope.data.isLoading = false;
            })
        }

        $scope.showServiceDetail = function (serviceType) {
            Modal.servicePanel.showServiceDetail({ serviceType }, function () {

            })()
        }

        $scope.$on('$destroy', function () {
            if (killClientWatch) {
                killClientWatch();
            }
        });

        init()
    })
});
}
//Dependency Injection
ServicePanelIndexCtrl.$inject = ["$rootScope","$scope","$state","$activeClient","Modal","$clientSetting","$auth","$clientSwitcher","$servicePanel", "$timeout",  "LazyLoadService"];

// angular.module('coordinateApp').controller('ServicePanelIndexCtrl', ServicePanelIndexCtrl);
