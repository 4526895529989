
'use strict';

export const $clientPermissionTypesService  = function () {
    return _.sortBy([
      // {
      //   label: 'REPORT_BUCKET',
      //   value: 'REPORT_BUCKET',
      //   name: 'Report Bucket',
      //   permission: 'REPORT_BUCKETS_VIEW'
      // },
      {
        label: 'REPORTS_LIBRARY',
        value: 'REPORTS_LIBRARY',
        name: 'Reports Library',
        permission: 'REPORTS_LIBRARY_TAB'
      },
      // {
      //   label: 'WORK_ORDERS',
      //   value: 'WORK_ORDERS',
      //   name: 'Work Orders',
      // permission: ''
      // },
      {
        label: 'FULFILLMENT_OPERATIONS',
        value: 'FULFILLMENT_OPERATIONS',
        name: 'Fulfillment Operations',
        permission: 'WORK_ORDER_READ'
      },
      {
        label: 'COURT_OPERATIONS',
        value: 'COURT_OPERATIONS',
        name: 'Court Operations',
        permission: 'COURT_OPS_READ'
      },
      {
        label: 'INTERPRETER_RESOURCES',
        value: 'INTERPRETER_RESOURCES',
        name: 'Interpreter Resources',
        permission: 'INTERPRETER_RESOURCES_CENTER_READ'
      },
      {
        label: 'RESOURCES',
        value: 'RESOURCES',
        name: 'Resources',
        permission: 'RESOURCE_OPS_READ'
      },
      {
        label: 'LIVE_REPORT',
        value: 'LIVE_REPORT',
        name: 'Live Report',
        permission: 'LIVE_REPORTS_READ'
      },
      {
        label: 'REPORTING_COURT_INFORMATION',
        value: 'REPORTING_COURT_INFORMATION',
        name: 'Reporting Court Information',
        permission: 'REPORTING_COURT_INFO_READ'
      },
      // {
      //   label: 'TRAINING',
      //   value: 'TRAINING',
      //   name: 'Training',
      //   permission: 'TRAINING_READ'
      // },
      // {
      //   label: 'GENERAL',
      //   value: 'GENERAL',
      //   name: 'General',
      // permission: ''
      // },
      {
        label: 'SOSI_ONE_PAGES',
        value: 'SOSI_ONE_PAGES',
        name: 'SOSI-One Pages',
        permission: ''
      },
      {
        label: 'SETTINGS',
        value: 'SETTINGS',
        name: 'Settings',
        permission: 'SETTINGS_READ'
      },
      {
        label: 'SERVICE_PORTAL',
        value: 'SERVICE_PORTAL',
        name: 'Service Portal',
        permission: ''
      }
    ], ['label']);
  }
// Dependency Injection

