
export const pluginsFactory = function (){
  return {
      list: function(scope){
        return [
          // {name: 'clientContacts', title: 'Contacts', id: 'client.contacts'},
          // {name: 'clientAdHoc', title: 'Ad Hoc', id: 'client.AdHoc'},
          // {name: 'clientTaskHistory', title: 'Work Order History', id: 'client.TaskHistory'},
          // {name: 'clientAnalysis', title: 'Analysis', id: 'client.Analysis'},
          // {name: 'clientScheduleTasks', title: 'Schedule Tasks', id: 'client.ScheduleTask'},
          // {name: 'clientTraining', title: 'Training', id: 'client.training'},
          // {name: 'clientTaskCreateRules', title: 'Task Create Rules', id: 'client.task-create-rules'},
          {name: 'clientNotificationLogs', title: 'Notification Logs', id: 'client.notificationLogs'},
          // {name: 'clientTimeProductionReporting', title: 'Time/Production Reporting', id: 'client.TimeProductionReporting'},
          // {name: 'clientTimeProductionReportingLog', title: 'Time/Production Reporting Log', id: 'client.TimeProductionReportingLog'},
          // {name: 'OtcAnyWhere', title: 'OTC AnyWhere', id: 'client.OtcAnyWhere'},
          // {name: 'reports', title: 'Reports', id: 'client.Reports'},
          {name: 'clientOfficeInformation', title: scope.clientStrings().officesPCase + ' Information', id: 'client.officeInformation'},
          {name: 'clientPrograms', title: 'Launch Page', id: 'client.programs'},
          // {name: 'clientOfficeOperations', title: 'Court Operations', id: 'client.officeOperations'},
          {name: 'clientRemoteWork', title: 'Remote Work', id: 'client.remoteWork'},
          {name: 'clientWorkflow', title: 'SOSi Workflow', id: 'client.workflow'},
          // {name: 'clientPatientContact', title: 'Patient Contact', id: 'client.PatientContact'},
          {name: 'clientKiosk', title: 'SOSi Kiosk', id: 'client.kiosk'},
          // {name: 'clientLiveReports', title: 'Live Reports', id: 'client.live-reports'},
          // {name: 'clientInterpreterResources', title: 'Interpreter Resources', id: 'client.interpreterResources'},
          // {name: 'clientSosEmailInformation', title: 'SOS Email Information', id: 'client.sosEmailInformation'},
          // {name: 'clientPermissionMatrix', title: 'Permission Matrix', id: 'client.permissionMatrix'},
          {name: 'clientMainDashboard', title: 'Main Dashboard', id: 'client.mainDashboard'},
          {name: 'clientUploadInterpreterPics', title: 'Upload Interpreter Pics', id: 'client.uploadInterpreterPics'},
          // {name: 'clientServiceList', title: 'Service History', id: 'client.serviceList'},
          {name: 'clientServiceBillingMethod', title: 'Billing Method', id: 'client.serviceBillingMethod'},
          {name: 'clientServiceProjects', title: 'Service Projects', id: 'client.serviceProjects'},
          {name: 'clientServicePanel', title: 'Service Portal', id: 'client.servicePanel'},
          {name: 'clientServiceInvoices', title: 'Service Portal Invoices', id: 'client.serviceInvoices'}
        ];
      }
  }
}
