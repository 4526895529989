'use strict';

export const CampaignsConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard.settings.campaigns', {
        url: '/campaigns',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/campaigns/campaigns.html', '9fc0f64a'); }],
        controller: 'DashboardSettingsCampaignsCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/campaigns/campaigns.controller.js', 'DashboardSettingsCampaignsCtrl'); }]
                      },
        data: {
          ability: 'manage.client.campaigns'
        }
      })
      .state('app.account.dashboard.settings.campaigns.index', {
        url: '/campaigns',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/campaigns/index/index.html', '6a9f522a'); }],
        controller: 'DashboardSettingsCampaignsIndexCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/campaigns/index/index.controller.js', 'DashboardSettingsCampaignsIndexCtrl'); }]
                      },
        data: {
          ability: 'manage.client.campaigns'
        }
      })
      .state('app.account.dashboard.settings.campaigns.create', {
        url: '/create',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/campaigns/create/create.html', '40189b0a'); }],
        controller: 'DashboardSettingsCampaignsCreateCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/campaigns/create/create.controller.js', 'DashboardSettingsCampaignsCreateCtrl'); }]
                      },
        data: {
          ability: 'manage.client.campaigns'
        }
      })
      .state('app.account.dashboard.settings.campaigns.update', {
        url: '/update/:id',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/campaigns/update/update.html', 'b9c92bea'); }],
        controller: 'DashboardSettingsCampaignsUpdateCtrl',
        resolve: {
            lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/campaigns/update/update.controller.js', 'DashboardSettingsCampaignsUpdateCtrl'); }],
            '$selectedCampaign': ['$stateParams','$clientCampaign', '$clientSwitcher', function ($stateParams, $clientCampaign, $clientSwitcher) {
              return $clientCampaign.detail($clientSwitcher.getCurrentClient(), $stateParams.id);
            }]
        },
        data: {
          ability: 'manage.client.campaigns'
        },
      });
  }
// Dependency Injection
CampaignsConfig.$inject = ["$stateProvider"];
