
'use strict';

export const courttimeDirective  = function ($q, $timeout) {
        return {
            require: 'ngModel',
            link: function (scope, elm, attrs, ngModel) {
                let forecast = JSON.parse(attrs.woData)
                let key = attrs.key;
                let _formNumber = attrs.formNumber;
                ngModel.$validators.courttime = function (modelValue, viewValue) {
                    if (ngModel.$isEmpty(modelValue)) {
                        return false;
                    }
                    function convertH2M(timeInHour) {
                        var timeParts = timeInHour.split(":");
                        return Number(timeParts[0]) * 60 + Number(timeParts[1]);
                    }

                    let error = '';
                    let courtTime = moment(modelValue).utcOffset(forecast.courtTimeZoneObj.offset).format('HH:mm');
                    let newForecastTime = convertH2M(courtTime)
                    if (forecast.courtStartTime && forecast.courtStartTime.enabled) {
                        // console.log(newForecastTime, forecast.courtStartTime.min, forecast.courtStartTime.max)
                        if (newForecastTime >= forecast.courtStartTime.min && newForecastTime <= forecast.courtStartTime.max) {
                            if (scope.isBulkWOForecast) {
                                scope.woBulkForecast[_formNumber].error = null;
                            } else {
                                scope.selectedDateData.data.collection[key][_formNumber].error = null;
                            }
                            return true
                        } else {
                            error = `The Set Work Order Time is Outside of the Court's Business Hours.`
                        }
                    } else {
                        if (scope.isBulkWOForecast) {
                            error = `Court is closed on`;
                        } else {
                            error = `Court is closed on ${moment(scope.selectedDateData.formatedDate).format('dddd')}!`;
                        }
                    }
                    if (scope.isBulkWOForecast) {
                        scope.woBulkForecast[_formNumber].error = null;
                    } else {
                        scope.selectedDateData.data.collection[key][_formNumber].error = error;
                    }
                    return !error

                }
            }
        };
    }
// Dependency Injection
// angular.module('coordinateApp').directive('woForecasting', woForecastingDirective)
// angular.module('coordinateApp').directive('courttime', courttimeDirective)


// Dependency Injection
courttimeDirective.$inject = ['$q', '$timeout'];
