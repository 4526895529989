


'use strict';

export const ClientSurveyFactory  = function ($resource, appConfig) {
    return $resource(appConfig.apiUrl + '/api/client-surveys/:id/:controller', {
      id: '@_id'
    },
    { });
  } 
 

// Dependency Injection
ClientSurveyFactory.$inject = ['$resource', 'appConfig'];
