
'use strict';

export const $rulesLogsManagerService  = function (RulesLogsManager) {
    return {
      getLogs: function(rule, sortBy) {
        return RulesLogsManager.getLogs({
          id: rule.client._id || rule.client,
          rule: rule._id || rule          
        }).$promise;
      }
    };
  }
// Dependency Injection


// Dependency Injection
$rulesLogsManagerService.$inject = ['RulesLogsManager'];
