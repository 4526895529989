import './permission-matrix.scss';
'use strict';

export const PermissionMatrixConfig = function ($stateProvider) {
        $stateProvider
        .state('app.account.dashboard.settings.permission-matrix', {
            url: '/permission-matrix',
            templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/permission-matrix/permission-matrix.html', '5752058a'); }],
            controller: 'PermissionMatrixCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/permission-matrix/permission-matrix.controller.js', 'PermissionMatrixCtrl'); }]
                      },
            // data: {
            //   ability: 'client.permissionMatrix',
            //   permission: 'PERMISSION_MATRIX_READ'
            // }
          })
    }
// Dependency Injection
PermissionMatrixConfig.$inject = ["$stateProvider"];
