export const activityFilter = function () {
  return function (activity) {
      if (!activity) {
          return false;
      }
      return activity.filter(function (element, index, array) {
          return ['Release', 'Extend'].indexOf(element.state) > -1;
      });
  };
}
