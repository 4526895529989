'use strict';


export const formEmployeeDirective = function (Modal, $rootScope, $q, $auth, $clientSwitcher, $clientUser, $clientEmployee, $clientRegion, $clientOffice, $activeClientSettings) {
    return {
      restrict: 'E',
      templateUrl: 'components/forms/employees.html',
      scope: {
        employee: '=ngModel',
        onSubmit: '=formOnSubmit',
        onReset: '=formOnReset'
      },
      link: function(scope) {
        var client = $clientSwitcher.getCurrentClient();
        scope.clientSettings = function(){ return $clientSwitcher.getFeatureOptions('client.settings'); };
        scope.clientStrings = function(){ return $clientSwitcher.getFeatureOptions('client.strings'); };
        scope.userDetail =  $auth.getCurrentUser();
        scope.users = [];
        scope.employees = [];
        scope.loading = false;
        scope.allValue = 'all';
        scope.taskDateFormat = 'MM/dd/yyyy';
        
        scope.paymentTypes = [
          {label: 'Salary', value: 'salary'},
          {label: 'Hourly', value: 'hourly'}
        ];
        scope.employeeTypes = [
          {label: 'Full-Time', value: 'FullTime'},
          {label: 'Part-Time', value: 'PartTime'},
          {label: 'Seasonal',  value: 'Seasonal'},
        ];

        scope.timezones = [
          { label: "Atlantic Standard/Daylight Time", value: "AST4ADT" },
          { label: "Eastern Standard/DaylightTime", value: "EST5EDT" },
          { label: "Central Standard/Daylight Time", value: "CST6CDT" },
          { label: "Mountain Standard/Daylight Time", value: "MST7MDT" },
          { label: "Pacific Standard/Daylight Time", value: "PST8PDT" },
          { label: "Alaska Standard/Daylight Time", value: "AKST9AKDT" },
          { label: "Hawaii-Aleutian Standard/Daylight Time", value: "HAST10HADT" },
          { label: "Eastern Standard Time", value: "EST" }]

        //sort them alphabetically
        scope.employees.timeZones = _.sortBy(scope.timezones, 'label');
        var allRegions = [], allLocations = [];
        scope.regionItems = [];
        scope.locationItems = [];
        scope.locationManagerItems = [];

        var isLocationVisible = function(location) {
          var locationObj = null;

          if(location.region) {
            locationObj = location;
          }
          else {
            var locationId = location._id || location;

            locationObj = allLocations.find(function(locationCandidate) {
              return locationCandidate._id === locationId;
            });
          }

          return ( scope.employee.regions === scope.allValue ) || 
            scope.employee.regions.indexOf(locationObj.region) > -1;
        };

        var getAllVisibleRegions = function() {
          return allRegions;
        };

        var getAllVisibleLocations = function() {
          return allLocations
            .filter(function(location) {
              return isLocationVisible(location);
            });
        };

        scope.submit = function(form) {

          if(scope.employee.regions === scope.allValue) {
            scope.employee.regions = getAllVisibleRegions()
              .map(function(region) {
                return region._id;
              });
          }

          if(scope.employee.locations === scope.allValue) {
            scope.employee.locations = getAllVisibleLocations()
              .map(function(filteredLocation) {
                return filteredLocation._id;
              });
          }
          else { // remove previously selected locations whose regions are now removed
            scope.employee.locations = scope.employee.locations
              .filter(function(location) {
                return isLocationVisible(location);
              });
          }

          if(scope.employee.linkedUsers === scope.allValue) {
            scope.employee.linkedUsers = scope.alertItemUsers.map(function(user) {
              return user.value;
            });
          }

          if(scope.employee.shareTree === scope.allValue) {
            scope.employee.shareTree = scope.shareUsers.map(function(user) {
              return user.value;
            });
          }

          if(scope.employee.locationsManager === scope.allValue) {
            scope.employee.locationsManager = scope.locationManagerItems
              .map(function(locationsManager) {
                return locationsManager.value;
              });
          }

          scope.onSubmit(form);
        };

        var toggleEmployee = function() {
          $clientEmployee.toggleEmployee(client, scope.employee)
          .then(function(res) {
            if(res.status) {
              scope.employee.isEnabled = !scope.employee.isEnabled;
              if(!scope.employee.isEnabled) {
                scope.employee.disabledAt = new Date();
              }
            }
            else {
              console.error(res.reason);
            }
          })
          .catch(function(err) {
            console.error(err);
          });
        };

        scope.toggle = function() {
          if(!scope.employee._id) { //create employee mode so we cant call api as _id is not available
            return;
          }

          scope.employee.isEnabled = !scope.employee.isEnabled;

          if(scope.employee.isEnabled) {
            Modal.confirm.show('Disable Employee', 'Are you sure you want to disable employee <b>' + (scope.employee.firstName || '') + '</b>?', 'Yes', 'No', function(){
              toggleEmployee();
            });
          } else {
            toggleEmployee();
          }
        };

        var loadData = function() {
          scope.employee.birthDate = scope.employee.birthDate ? moment(scope.employee.birthDate).toDate() : null;
          scope.employee.hireDate = scope.employee.hireDate ? moment(scope.employee.hireDate).toDate() : null;
          scope.employee.terminationDate = scope.employee.terminationDate ? moment(scope.employee.terminationDate).toDate() : null;
          if(scope.employee.notes){
            scope.employee.notes = scope.employee.notes.map(function(note){
              note.user = scope.userDetail._id,
              note.userName = scope.userDetail.name
              return note;
            })
          }

          scope.loading = true;
          
          var fetchUsers = function(employees) {
            employees = (employees && employees.length) ? employees : [];

            // list all userIds which are not linked yet + [in case of update] user linked with selected employee (if any)
            var linkedUserIds = employees
              .filter(function(employee) {
                return employee.user && employee.user._id !== scope.employee.user;
              })
              .map(function(linkedEmployee) {
                return linkedEmployee.user._id;
              });

            return $clientUser.getUserChainOfCommand(client)
              .then(function (chainOfCommand) {
                scope.alertItemUsers = chainOfCommand.delegates
                  .map(function(delegateUser) {
                    return {
                      value: delegateUser.userId, 
                      label: delegateUser.name
                    };
                  });

                scope.users = scope.alertItemUsers.slice();
                scope.users.push({
                  value: chainOfCommand.me._id, 
                  label: chainOfCommand.me.name
                });

                scope.shareUsers = scope.alertItemUsers.slice();
                scope.shareUsers.push({
                  value: chainOfCommand.me._id, 
                  label: chainOfCommand.me.name
                });

                scope.users = scope.users
                  .filter(function(user) {
                    return linkedUserIds.indexOf(user.value) === -1;
                  });
              });
          };

          var fetchEmployees = $clientEmployee.getEmployees(client)
            .then(function (res) {
              if(res.status) {
                var allEmployees = res.data.slice();

                if(scope.employee.firstName) { //we're updating an existing employee'
                  scope.employees = res.data
                  .filter(function(empObj) {
                    return empObj._id !== scope.employee._id;
                  });
                }
                else {
                  scope.employees = res.data;
                }

                scope.employees = scope.employees.map(function(employee) {
                  return {
                    label: employee.firstName + ' ' + employee.lastName,
                    value: employee._id
                  };
                });
                
                return fetchUsers(allEmployees);
              }
            });

          var fetchRegions = $clientRegion.listAll(client)
            .then(function(regions) {
              allRegions = regions.slice();

              scope.regionItems = regions.map(function(region) {
                return {
                  label: region.name,
                  value: region._id
                };
              });
            });

          var fetchOffices = $clientOffice.listAll(client)
            .then(function(offices) {
              allLocations = offices.slice();

              scope.locationItems = offices.map(function(location) {
                return {
                  label: location.name,
                  value: location._id,
                  showIf: function() {
                    return isLocationVisible(location);
                  }
                };
              });
            });

          $q.all([fetchEmployees, fetchRegions, fetchOffices])
          .finally(function() {
            scope.loading = false;

            if(scope.employee.hireDate) {
              scope.employee.hireDate = new Date(scope.employee.hireDate);
            }

            if(scope.employee.terminationDate) {
              scope.employee.terminationDate = new Date(scope.employee.terminationDate);
            }

            if(scope.employee.regions) {
              if(scope.employee.regions.length === getAllVisibleRegions().length) {
                scope.employee.regions = scope.allValue;
              }
            }
            else {
              scope.employee.regions = [];
            }

            if(scope.employee.locations) {
              if(scope.employee.regions.length && scope.employee.locations.length === getAllVisibleLocations().length) {
                scope.employee.locations = scope.allValue;
              }
            }
            else {
              scope.employee.locations = [];
            }

            if(scope.employee.locationsManager) {
              if(scope.employee.regions.length && scope.employee.locationsManager.length === getAllVisibleLocations().length) {
                scope.employee.locationsManager = scope.allValue;
              }
            }
            else {
              scope.employee.locationsManager = [];
            }

            if(scope.employee.linkedUsers) {
              scope.employee.linkedUsers = scope.employee.linkedUsers.map(function(user) {
                return user._id;
              });
            }
            else {
              scope.employee.linkedUsers = [];
            }
        
            if(typeof scope.employee.isEnabled === 'undefined') {
              scope.employee.isEnabled = true;
            }
          });
        };
        loadData();

				scope.$watch(function() {
					return scope.employee.locations
				}, function(nv, ov) {
					if(nv && nv !== ov) {
            if(nv == scope.allValue){
              scope.locationManagerItems = scope.locationItems;
            }else{
             $clientOffice.listAll(client)
                .then(function (offices) {
                  var locationManagerItems = offices.map(function (location) {
                    if (nv.indexOf(location._id) > -1) {
                      return {
                        label: location.name,
                        value: location._id,
                        showIf: function () {
                          return isLocationVisible(location);
                        }
                      };
                    }
                  });
                  scope.locationManagerItems = _.compact(locationManagerItems)
                });
            }
					}
        });
        
        $activeClientSettings(function (client, settings) {
          scope.supportsIVR = settings.supportsIVR;
        });

        scope.addEmployeeNote = function(){
          scope.employee.notes.push({
            note: null,
            user: scope.userDetail._id,
            created: moment().toDate(),
            userName: scope.userDetail.name
          })
        }
        scope.removeEmployeeNote = (function (index) {
          scope.employee.notes.splice(index, 1);
        })
      }
    };
  }
// Dependency Injection
formEmployeeDirective.$inject = ["Modal","$rootScope","$q","$auth","$clientSwitcher","$clientUser","$clientEmployee","$clientRegion","$clientOffice","$activeClientSettings"];
