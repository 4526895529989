import './covi.scss';
'use strict';

export const CoviConfig = function ($stateProvider) {
    $stateProvider
      .state('app.print.covi', {
        url: '/office-operations/covi',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./print/office-operations/covi/covi.html', '89fbed0a'); }],
        controller: 'PrintCOVICtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./print/office-operations/covi/covi.controller.js', 'PrintCOVICtrl'); }]
                      }
      });
  }
// Dependency Injection
CoviConfig.$inject = ["$stateProvider"];
