
'use strict';

export const sentimentFilter  = function () {
    return function (inputArray, filterSentiment) {
      if (inputArray && filterSentiment && filterSentiment.length > 0) {
        return inputArray.filter(function (entry) {
          return this.indexOf(entry.sentiment) !== -1;
        }, filterSentiment);
      }
      else {
        return inputArray;
      }
    };
  }
// Dependency Injection

