'use strict';

export const InvoicesPanelConfig = function ($stateProvider) {
        $stateProvider
            .state('app.account.services.invoicesPanel', {
                url: '/invoices',
                templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/services/service-panel/invoices-panel/invoices-panel.html', '9ac94003'); }],
                controller: 'ServiceInvoicesPanelCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/services/service-panel/invoices-panel/invoices-panel.controller.js', 'ServiceInvoicesPanelCtrl'); }]
                      },
            })
    }
// Dependency Injection
InvoicesPanelConfig.$inject = ["$stateProvider"];
