'use strict';


export const formSurveyTaskDirective = function ($q, $rootScope, $authStorage, $clientSwitcher, $clientTask, $clientUser, $clientOffice, $clientCampaign, $clientManager, FileUploader, $clientExternalUsers, ngToast) {
  return {
    restrict: 'E',
templateUrl: 'components/forms/survey/task.html',
    scope: {
      model: '=ngModel',
      defaultPriority: '@',
      defaultBucket: '@',
      defaultBucketType: '@',
      defaultDueDate: '@',
      isSubmitting: '=',
      message: '=',
      onSubmit: '=',
      onReset: '='
    },
    link: function(scope, el, attrs) {
      var lastClient = false;

      scope.taskOptions = function () { return $clientSwitcher.getFeatureOptions('client.tasks'); };
      scope.clientStrings = function(){ return $clientSwitcher.getFeatureOptions('client.strings'); };
      scope.surveyOptions = function () { return $clientSwitcher.getFeatureOptions('client.satisfaction'); };

      scope.slider = {
        maxMinutes: 1435, //23:55 in minutes
        dueValueInMin: 0,
        fallbackDueValueInMin: 0
      };

      scope.priorities = [
        ['high',    'High'],
        ['med-high','Medium-high'],
        ['med-low', 'Medium-low'],
        ['low',     'Low']
      ];

      if(scope.taskOptions().allowTaskDueDate){
        scope.model.due = new Date();
      }

      //create relation between dueValueInMin and model.due
      scope.$watch(function() { return scope.model.due; }, function(nv) {
        if(scope.model.due) {
          scope.slider.dueValueInMin = scope.model.due.getHours()*60 + scope.model.due.getMinutes();
        }
      });

      scope.$watch(function() { return scope.slider.dueValueInMin; }, function(nv) {
        if(scope.model.due) {
          scope.model.due = new Date(scope.model.due.setHours(scope.slider.dueValueInMin / 60, scope.slider.dueValueInMin % 60));
        }
      });

      //create relation between fallbackDueValueInMin and model.onExpireDue
      scope.$watch(function() { return scope.model.onExpireDue; }, function(nv) {
        if(scope.model.onExpireDue) {
          scope.slider.fallbackDueValueInMin = scope.model.onExpireDue.getHours()*60 + scope.model.onExpireDue.getMinutes();
        }
      });

      scope.$watch(function() { return scope.slider.fallbackDueValueInMin; }, function(nv) {
        if(scope.model.onExpireDue) {
          scope.model.onExpireDue = new Date(scope.model.onExpireDue.setHours(scope.slider.fallbackDueValueInMin / 60, scope.slider.fallbackDueValueInMin % 60));
        }
      });

      scope.taskDateOptions = {
        startingDay: 1
      };
      scope.taskDateOpened = false;
      scope.taskDateFormat = 'MM/dd/yyyy';
      scope.taskDateStepH  = 1;
      scope.taskDateStepM  = 1;
      scope.taskDateAMPM   = true;
      scope.nowDate = function () {
        var
        now = Date.now(),
        nextMinute = now % 60000;
        return now - nextMinute;
      };
      scope.taskDateDisabled = function (date, mode) {
        var now = Date.now(), offset = (now % 8.64e7);
        return date <= (now - offset);
      };

      scope.clearDueDate = function (){
        if(!scope.model) return;
        delete scope.model.due;
      };

      scope.clearOnExpireDueDate = function (){
        if(!scope.model) return;
        delete scope.model.onExpireDue;
      };

      // scope.surveyTypes = scope.surveyOptions()

      scope.loadSurveyBuckets = function () {
        var so = scope.surveyOptions();

        delete scope.surveyTypes;

        if(!so.myBuckets) return;

        scope.surveyTypes = so.myBuckets.reduce(function(p, c, i) {
          p.push([c.typeCode, c.label, c]);
          return p;
        }, []);
      };

      scope.ensureSurveyData = function () {
        if(!scope.model) return;

        if(!scope.model.hasOwnProperty('surveyData')) {
          scope.model.surveyData = {};
        }

        if(!scope.model.surveyData.hasOwnProperty('manager')) {
          scope.model.surveyData.manager = null;
        }

        if(!scope.model.surveyData.hasOwnProperty('campaign')) {
          scope.model.surveyData.campaign = null;
        }

        if(!scope.model.surveyData.hasOwnProperty('typeCode')) {
          scope.model.surveyData.typeCode = null;
        }

        if(!scope.model.surveyData.hasOwnProperty('data')) {
          scope.model.surveyData.data = {};
        }

        if(!scope.model.surveyData.data.hasOwnProperty('questions') || !angular.isArray(scope.model.surveyData.data.questions)) {
          scope.model.surveyData.data.questions = [];
        }

        if(!scope.model.surveyData.hasOwnProperty('customer')) {
          scope.model.surveyData.customer = {};
        }

        if(!scope.model.surveyData.customer.hasOwnProperty('name')) {
          scope.model.surveyData.customer.name = null;
        }

        if(!scope.model.surveyData.customer.hasOwnProperty('account')) {
          scope.model.surveyData.customer.account = null;
        }

        if(!scope.model.surveyData.customer.hasOwnProperty('telephone')) {
          scope.model.surveyData.customer.telephone = null;
        }

        if(!scope.model.surveyData.customer.hasOwnProperty('email')) {
          scope.model.surveyData.customer.email = null;
        }
      };

      scope.addSurveyRow = function (defaultOpts) {
        defaultOpts = defaultOpts || {};

        scope.ensureSurveyData();

        scope.model.surveyData.data.questions.push({
          question: "",
          answered: "",
          score:    0,
          failed:   false,
        });


        return defaultOpts;
      };

      scope.reloadOffices = function (client) {
        delete scope.offices;
        return $clientOffice.listAll(client)
          .then(function (offices) {
            scope.offices = offices.map(function (office) {
              return [office._id, office.name, office];
            });
          });
      };

      scope.reloadCampaigns = function (client) {
        delete scope.campaigns;
        return $clientCampaign.listAll(client)
          .then(function (campaigns) {
            scope.campaigns = campaigns.map(function (campaign) {
              return [campaign._id, campaign.name, campaign];
            });
          });
      };

      scope.reloadManagers = function (client) {
        delete scope.managers;
        return $clientManager.listAll(client)
          .then(function (managers) {
            scope.managers = managers.map(function (manager) {
              return [manager._id, manager.name, manager];
            });
          });
      };

      scope.reloadUsers = function (client, store, bucket) {
        delete scope.users;
        
        return $clientUser.getUserChainOfCommand(client, store, bucket)
        .then(function (chainOfCommand) {
          scope.users = chainOfCommand;
          return scope.users;
        });
      };

      scope.reloadCollections = function (client) {
        scope.collections = false;
        return $clientTask.getCollections(client, true)
          .then(function (collections) {
            scope.collections = collections.map(function (collection) {
              return [collection._id, collection.name, collection.types.map(function (v) {
                return [v, v];
              })];
            });

            if(scope.model && scope.model.taskCollection) {
              scope.setCurrentCollection(scope.model.taskCollection);
            }
          });
      };

      scope.setCurrentCollection = function (toCollection) {

        var
        prevType = false,
        hasModel = !!scope.model;

        delete scope.collectionTypes;

        if(hasModel) {
          prevType = scope.model.taskType;
        }

        var collection;

        scope.collections.every(function (col) {
          if(col[0] !== toCollection) return true;
          collection = col;
          return false;
        });

        if(!collection) {
          if(hasModel) {
            delete scope.model.taskType;
          }

          return;
        }

        scope.collectionTypes = collection[2];

        if(hasModel) {
          var
          setTo = collection[2][0][0];

          if(prevType) {
            var foundIndex = -1;

            scope.collectionTypes.every(function (typeDef, index) {
              if(typeDef[0] === prevType) {
                foundIndex = index;
              }
              return foundIndex === -1;
            });

            if(foundIndex > -1) {
              setTo = scope.collectionTypes[foundIndex][0];
            }
          }

          scope.model.taskType = setTo;
        }
      };

      scope.reload = function (client) {
        var master = angular.copy(scope.model);
        lastClient = client;
        return $q.all([
          scope.loadSurveyBuckets(),
          scope.reloadCollections(client),
          scope.reloadUsers(client),
          scope.reloadCampaigns(client),
          scope.reloadManagers(client),
          scope.reloadOffices(client)
        ]);
      };

      ///
      /// For debugging, remove this on release.
      ///
      // scope.prefillTask = function () {
      //   var
      //   model = scope.model,
      //   title = 'Test ' + Date.now(),
      //   description = 'This is a test task.',
      //   location = scope.offices[0],
      //   collection = scope.collections[0],
      //   priority = scope.priorities[0],
      //   assignTo = scope.users[0],
      //   assignToOnExpire = scope.users[1],
      //   due = 8.64e7;

      //   model.title = title;
      //   model.description = description;
      //   if(location) model.location = location[0];

      //   if(collection) {
      //     model.taskCollection = collection[0];
      //   }
      //   if(due) model.due = scope.offsetDate(due);
      //   if(priority) model.priority = priority[0];
      //   if(assignTo) model.assignTo = assignTo[0];
      //   if(assignToOnExpire) model.assignToOnExpire = assignToOnExpire[0];
      // };

      scope.$watch('model', function (nV) {
        if(!nV) return;

        if(nV.priority === undefined && scope.defaultPriority) {
          nV.priority = scope.defaultPriority;
        }

        if(nV.taskCollection === undefined && scope.defaultBucket) {
          nV.taskCollection = scope.defaultBucket;
        }

        if(nV.taskType === undefined && scope.defaultBucketType) {
          nV.taskType = scope.defaultBucketType;
        }

        if(nV.due === undefined && scope.defaultDueDate) {
          nV.due = scope.defaultDueDate;
        }
      });

      scope.$watch('model.taskCollection', function (nV) {
        if(!nV || !scope.collections) return;

        scope.setCurrentCollection(nV);
      });

      scope.$watch(function () {
        var model = scope.model;

        if(!model.assignTo && !model.assignToEmail) {
          return true;
        }

        return false;
      }, function (assignEmpty) {
        if(assignEmpty) {
          delete scope.model.assignToOnExpire;
          delete scope.model.assignToEmailOnExpire;
        }
      });

      scope.$watch(function () {
        var model = scope.model;

        if(!model.assignToOnExpire && !model.assignToEmailOnExpire) {
          return true;
        }

        return false;
      }, function (fallbackEmpty) {
        if(fallbackEmpty) {
          delete scope.model.onExpireDue;
          delete scope.model.onExpirePriority;
        }
      });

      scope.$watch(function () {
        var model = scope.model;
        if(!model || !lastClient || !model.location || !model.taskCollection) {
          return 'false';
        }

        var
        locationId = (model.location._id || model.location),
        bucketId   = (model.taskCollection._id || model.taskCollection);

        return JSON.stringify([locationId, bucketId]);
      }, function (json) {
        var parsed = JSON.parse(json);
        if(!parsed) return;

        scope.reloadUsers(lastClient, parsed[0], parsed[1]);
      });

      $clientSwitcher.whenLoaded(function (all, current) { // listen for client switching
        var
        killCsLoad,
        killCsSwitch;

        scope.reload(current);

        if(scope.model) {
          scope.model.uploader = scope.uploader = new FileUploader({
            url: appConfig.apiUrl + '/api/client-uploads/'+current._id+'?access_token='+$authStorage.getToken(),
            alias: 'upload',
            filters: [
              {
                name: 'extensionFilter',
                fn: function(item) {
                  var extension = item.name.substr(item.name.lastIndexOf('.')+1);
                  var invalidExtensions = ['exe', 'bin'];
                  if(invalidExtensions.indexOf(extension) > -1) {
                    ngToast.create({
                      className: 'danger',
                      content: 'Files with extensions '+invalidExtensions+' are not allowed'
                    });
                    return false;
                  } else {
                    return true;
                  }
                }
              }
            ]
          });
        }

        killCsLoad = $rootScope.$on('client-switcher-load', function (evt, all, current) { // listen for reloads too
          scope.reload(current);
        });
        killCsSwitch = $rootScope.$on('client-switcher-switch', function (evt, client) {
          scope.reload(client);
        });

        scope.$on('$destroy', function(){
          killCsLoad();
          killCsSwitch();
        });
      });
    }
  };
}
// Dependency Injection
formSurveyTaskDirective.$inject = ["$q","$rootScope","$authStorage","$clientSwitcher","$clientTask","$clientUser","$clientOffice","$clientCampaign","$clientManager","FileUploader","$clientExternalUsers","ngToast"];
