'use strict';

export const courtInterpreterDistanceDirective = function ($auth, $q, $clientSwitcher, $clientInterpreter, $clientInterpreterCourtDistance, Modal, ngToast) {
        return {
            restrict: 'E',
            templateUrl: 'app/account/dashboard/settings/interpreter-court-distances/court-interpreter-distance/court-interpreter-distance.html',
            scope: {

            },
            link: function ($scope) {
                $scope.data = {
                    isLoading: false,
                    interpreters: [],
                    error: null,
                    totalActiveOffice: 0,
                    totalOffices: 0,
                    permissions: {}
                }

                $scope.error = null;
                $scope.oneAtATime = true;
                $scope.filter = {
                    searchText: null
                };
                $scope.userPermissions = null;

                $scope.currentCli = $clientSwitcher.getCurrentClient();
                $scope.pagination = {
                    currentPage: 1,
                    page: 1,
                    total: 0,
                    pageLimit: 20,
                    itemsPerPage: 20
                };

                function init() {
                    $scope.loadCourts()
                    $scope.loadInterpreterUsers();
                    $scope.userPermissions = $auth.getCurrentUser().permissions;
                    $scope.data.permissions = {
                        allowSync: $scope.userPermissions.includes('SETTING_SYNC_INTERPRETER_COURT_DISTANCE'),
                    }
                }

                $scope.applySearch = _.debounce(function (cv) {
                    reset()
                    $scope.loadCourts()
                }, 0);

                $scope.onSearch = () => {
                    $scope.applySearch();
                }

                function reset() {
                    $scope.pagination = {
                        currentPage: 1,
                        page: 1,
                        total: 0,
                        pageLimit: 20,
                        itemsPerPage: 20
                    };
                }

                $scope.loadCourts = function () {
                    $scope.data.courts = [];
                    $scope.pagination.total = 0;

                    if ($scope.data.isLoading || !$scope.filter.searchText) return;
                    let filter = {
                        search: $scope.filter.searchText || '',
                        page: $scope.pagination.page,
                        pageSize: $scope.pagination.pageLimit
                    }
                    $scope.data.isLoading = true;
                    $clientInterpreterCourtDistance.courtInterpreterDistance($scope.currentCli, filter)
                        .then((res) => {
                            if (res.status) {
                                $scope.data.courts = res.result;
                                $scope.pagination.total = res.totalCourts;
                            }
                            $scope.data.isLoading = false;
                        })
                        .catch(err => {
                            console.log(err)
                            $scope.data.isLoading = false;
                        })
                }
                $scope.loadInterpreterUsers = function () {
                    $clientInterpreter.getInterpreterUsers($scope.currentCli)
                        .then((res) => {
                            if (res.status) {
                                $scope.data.allInterpreterUsers = res.result;
                                // $scope.data.totalActiveOffice = res.officeList.length;
                                $scope.data.totalInterpreterUsers = res.result.length;
                            }
                        })
                }

                $scope.getDistanceCount = (distances) => {
                    if (distances && distances.length) {
                        let validDistance = distances.filter((item) => item.distance > 0);
                        return validDistance.length
                    } else {
                        return 0
                    }
                }

                $scope.showDistanceModal = (court) => {
                    // console.log($scope.data.allCourts)
                    Modal.interpreterProfileActions.interpreterDistance(court, $scope.data.allInterpreterUsers, function (res) {
                        // console.log(res)
                    })();
                }

                $scope.syncDistance = (courtObj) => {
                    courtObj.distanceSync = true;
                    // let allOfficesGroup = _.groupBy($scope.data.allCourts, 'internalId');


                    $clientInterpreterCourtDistance.syncDistanceByCourtCode($scope.currentCli, courtObj.internalId)
                        .then((res) => {
                            if (res.status) {
                                ngToast.create({
                                    content: res.message,
                                    className: 'success'
                                });
                                $scope.loadCourts();
                            } else {
                                ngToast.create({
                                    content: res.message,
                                    className: 'danger'
                                });
                            }
                        })
                        .catch(err => {
                            ngToast.create({
                                content: err && err.data && err.data.reason ? err.data.reason : 'something went wrong please try again',
                                className: 'danger'
                            });
                            console.error(err);
                        })
                        .finally(() => {
                            courtObj.distanceSync = false;
                        })
                }
                init()

            }
        }
    }
// Dependency Injection
courtInterpreterDistanceDirective.$inject = ["$auth","$q","$clientSwitcher","$clientInterpreter","$clientInterpreterCourtDistance","Modal","ngToast"];
