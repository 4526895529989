
'use strict';

export const labourCategoryFilter  = function ($timezones) {
    return function (val) {
      if (val == 1) return `${val} - Certified`;
      else if (val == 2) return `${val} - Qualified`;
      else if (val == 3) return `${val} - Skilled`;
      return val;
    }
  }
// Dependency Injection


// Dependency Injection
labourCategoryFilter.$inject = ['$timezones'];
