'use strict';

export const liveReportsDirective = function ($rootScope, $stateParams, $timeout,  LazyLoadService ) {
                  return {
            restrict: 'E',
            templateUrl: 'app/account/dashboard/main-dashboard/sosi-one-reporting/tabs/live-reports/live-reports.html',
            scope: {
                userPermissions: '=userPermissions',
                childTabs: '=childTabs',
            },
            link: function(scope) {

  const lazyDirectives = [{"name":"piplineDirective","path":import("@app/account/dashboard/main-dashboard/pipline-operations/tabs/pipline/pipline.directive.js")},{"name":"interpreterMatrixDirective","path":import("@app/account/dashboard/main-dashboard/sosi-one-reporting/tabs/interpreter-matrix/interpreter-matrix.directive.js")},{"name":"dqReportDirective","path":import("@app/account/dashboard/main-dashboard/sosi-one-reporting/tabs/dq-report/dq-report.directive.js")},{"name":"sosEmailInformationDirective","path":import("@app/account/dashboard/main-dashboard/work-orders/tabs/sos-email-information/sos-email-information.directive.js")},{"name":"dashboardNewDirective","path":import("@app/account/dashboard/main-dashboard/work-orders/tabs/dashboard-new/dashboard-new.directive.js")},{"name":"dailyReportDirective","path":import("@app/account/dashboard/live-reports/tabs/daily-report/daily-report.directive.js")},{"name":"dynamicQueryDirective","path":import("@app/account/dashboard/live-reports/tabs/dynamic-query/dynamic-query.directive.js")},{"name":"invoiceDirective","path":import("@app/account/dashboard/main-dashboard/sosi-one-reporting/tabs/invoice/invoice.directive.js")},{"name":"assignmentReportDirective","path":import("@app/account/dashboard/live-reports/tabs/assignment-report/assignment-report.directive.js")}];
  scope.lazyDirectivesLoaded = false;
  Promise.all(lazyDirectives.map(directive=>LazyLoadService.loadDirective(directive.path, directive.name))).then(() => {
          $timeout(function() {
            scope.lazyDirectivesLoaded = true;


                scope.IcTabs = [];
                var init = function () {
                  scope.tabs()
                }
                let selectedTab = ''; //'resource-ops-dashboard';
                scope.tabs = function () {
                  let tabs = scope.childTabs.subChild;
                  scope.IcTabs = tabs;
                  if ($stateParams.child == 'live_reports' &&  $stateParams.sub_child) {
                    selectedTab = $stateParams.sub_child;
                  } else {
                    selectedTab = scope.IcTabs.length ? scope.IcTabs[0].value : null;
                  }
                }

                scope.permissions = {}
                if (scope.userPermissions && scope.userPermissions.length) {
                  scope.permissions = {
                    hasDynamicQueryViewPermission: scope.userPermissions.includes('LR_DYNAMIC_QUERY_VIEW'),
                    hasDailyReportViewPermission: scope.userPermissions.includes('LR_DAILY_REPORT_VIEW'),
                    hasAssignmentReportViewPermission: scope.userPermissions.includes('LR_ASSIGNMENT_REPORT_VIEW'),
                    hasMonthlyReportViewPermission: scope.userPermissions.includes('LR_MONTHLY_REPORT_VIEW'),
                    hasMiscViewPermission: scope.userPermissions.includes('LR_MISC_VIEW'),
                    hasMonthlyAStatusReportViewPermission: scope.userPermissions.includes('LR_MONTHLY_ASSIGNMENT_STATUS_REPORT_VIEW'),
                    hasEOIRICMatrixViewPermission: scope.userPermissions.includes('LR_EOIR_INTERPRETER_MATRIX_VIEW'),
                    hasResourceOpsDashboardViewPermission: scope.userPermissions.includes('LR_RESOURCE_OPS_DASHBOARD_TAB'),
                    hasFulfillmentOpsDashboardViewPermission: scope.userPermissions.includes('LR_FULFILLMENT_OPS_DASHBOARD_TAB'),
                    hasICMatrixViewPermission: scope.userPermissions.includes('LR_INTERPRETER_MATRIX_TAB'),
                    hasDQReportViewPermission: scope.userPermissions.includes('LR_DQ_REPORT_TAB'),
                    hasInvoiceViewPermission: scope.userPermissions.includes('LR_INVOICE_TAB'),
                    hasRecruitingReportViewPermission: scope.userPermissions.includes('LR_RECRUITING_REPORT_TAB'),
                    hasSOSEmailInfoViewPermission: scope.userPermissions.includes('LR_SOS_EMAILS_TAB')
                  };
                }

                scope.tabSelected = function (tabName) {
                  selectedTab = tabName;
                  $rootScope.$broadcast('onTabChange', 'live_reports' ,selectedTab);
                };

                scope.isTabSelected = function (tabName) {
                  return selectedTab === tabName;
                };

                let childSelectedTab = 'assignment-status-report';
                scope.childTabSelected = function (tabName) {
                  if (selectedTab == 'monthly-reports') {
                    childSelectedTab = tabName;
                  }
                };

                scope.isChildTabSelected = function (tabName) {
                  if (selectedTab == 'monthly-reports') {
                    return childSelectedTab === tabName;
                  }
                  return false;
                };

                init();


    })
});
}
}
}
//Dependency Injection
liveReportsDirective.$inject = ["$rootScope","$stateParams", "$timeout",  "LazyLoadService"];
