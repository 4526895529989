
'use strict';

export const $nationalitiesService  = function (Nationalities) {
    return {
      getNationalitiesList: function (client, paginationData, filter) {
        if (!filter) { filter = {} };
        return Nationalities.getNationalitiesList({
          id: client._id || client,
          page: paginationData && paginationData.page,
          pageSize: paginationData && paginationData.pageSize,
          searchKey: filter.searchKey || '',
          orderBy: filter.orderVal || ''
        }).$promise;
      },
      getById: function (client, languageId) {
        return Nationalities.getById({
          id: client._id || client,
          controller: languageId
        }).$promise;
      },
      create: function (client, data) {
        return Nationalities.create({
          _id: client._id || client,
          id: data.id,
          description: data.description,
        }).$promise;
      },
      update: function (client, data) {
        return Nationalities.update({
          _id: client._id || client,
          data: {
            id: data.id,
            _id: data._id,
            description: data.description,
          }
        }).$promise;
      }
    };
  }
// Dependency Injection


// Dependency Injection
$nationalitiesService.$inject = ['Nationalities'];
