'use strict';

export const RegionsConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard.settings.regions.divisions', {
        url: '/divisions',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/regions/division/divisions.html', 'd3511df9'); }],
        controller: 'DashboardSettingsDivisionsCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/regions/division/divisions.controller.js', 'DashboardSettingsDivisionsCtrl'); }]
                      },
        data: {
          ability: 'manage.client.regions'
        }
      })
      .state('app.account.dashboard.settings.regions.divisions_create', {
        url: '/divisions_create',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/regions/division/create.html', '4fb8847'); }],
        controller: 'DashboardSettingsDivisionCreateCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/regions/division/create.controller.js', 'DashboardSettingsDivisionCreateCtrl'); }]
                      },
        data: {
          ability: 'manage.client.regions'
        }
      })
      .state('app.account.dashboard.settings.regions.divisions_edit', {
        url: '/divisions_edit/:id',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/regions/division/edit.html', 'dab1399f'); }],
        controller: 'DashboardSettingsDivisionEditCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/regions/division/edit.controller.js', 'DashboardSettingsDivisionEditCtrl'); }],
                          '$selectedDivision': ['$stateParams','$clientDivision', '$clientSwitcher', function ($stateParams, $clientDivision, $clientSwitcher) {
                            return $clientDivision.detail($clientSwitcher.getCurrentClient(), $stateParams.id);
                          }]
                      },
        data: {
          ability: 'manage.client.regions'
        },
      })
      .state('app.account.dashboard.settings.regions', {
        url: '/regions',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/regions/regions.html', '48ee65ca'); }],
        controller: 'DashboardSettingsRegionsCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/regions/regions.controller.js', 'DashboardSettingsRegionsCtrl'); }]
                      },
        data: {
          ability: 'manage.client.regions'
        }
      })
      .state('app.account.dashboard.settings.regions.index', {
        url: '/regions',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/regions/index/index.html', '2e69a9ea'); }],
        controller: 'DashboardSettingsRegionsIndexCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/regions/index/index.controller.js', 'DashboardSettingsRegionsIndexCtrl'); }]
                      },
        data: {
          ability: 'manage.client.regions'
        }
      })
      .state('app.account.dashboard.settings.regions.create', {
        url: '/create',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/regions/create/create.html', '539b4dca'); }],
        controller: 'DashboardSettingsRegionsCreateCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/regions/create/create.controller.js', 'DashboardSettingsRegionsCreateCtrl'); }]
                      },
        data: {
          ability: 'manage.client.regions'
        }
      })
      .state('app.account.dashboard.settings.regions.update', {
        url: '/update/:id',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/regions/update/update.html', 'd62277aa'); }],
        controller: 'DashboardSettingsRegionsUpdateCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/regions/update/update.controller.js', 'DashboardSettingsRegionsUpdateCtrl'); }],
                          '$selectedRegion': ['$stateParams','$clientRegion', '$clientSwitcher', function ($stateParams, $clientRegion, $clientSwitcher) {
                            return $clientRegion.detail($clientSwitcher.getCurrentClient(), $stateParams.id);
                          }]
                      },
        data: {
          ability: 'manage.client.regions'
        },
      });
  }
// Dependency Injection
RegionsConfig.$inject = ["$stateProvider"];
