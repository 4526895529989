
'use strict';

export const $taskActionsLogsManagerService  = function (TaskActionsLogsManager) {
    return {
      getLogs: function(client, interval) {
        return TaskActionsLogsManager.getLogs({
          id: client._id || client,
          interval: interval
        }).$promise;
      },

      getTaskLog: function(client, taskId) {
        return TaskActionsLogsManager.getTaskLog({
          id: client._id || client,
          taskId: taskId
        }).$promise;
      }
    };
  }
// Dependency Injection


// Dependency Injection
$taskActionsLogsManagerService.$inject = ['TaskActionsLogsManager'];
