'use strict';


export const DraggableCtrl = function ($scope, $document) {

  var
  eventName = 'mousemove',
  dragging  = false,
  dragEl    = null,
  sx = 0, sy = 0, ox = 0, oy = 0,
  moveHandler = function (event) {
    this.dragMove(event.screenX, event.screenY);
  }.bind(this);

  this.dragStart = function (initX, initY) {
    if(!dragEl) return;
    dragging = true;
    sx = initX - ox;
    sy = initY - oy;
    $document.on(eventName, moveHandler);
  };

  this.dragMove = function (mouseX, mouseY) {
    if(!dragging || !dragEl) return;

    ox = (mouseX - sx);
    oy = (mouseY - sy);

    dragEl.css({
      top: oy + 'px',
      left: ox + 'px'
    });
  };

  this.dragStop = function () {
    if(!dragEl) return;
    dragging = false;
    sx = 0; sy = 0;

    $document.unbind(eventName, moveHandler);
  };

  this.setElement = function (el) {
    dragEl = el;
  };
}
 export const draggableDirective = function ($document) {
  return {
    controller: 'DraggableCtrl as $draggable',
    link: function (scope, el, attr, ctrl) {
      ctrl.setElement(el);
    }
  };
}
 export const draggableTriggerDirective = function ($document) {

  function once(emitter, event, fn) { // TODO: make this a service
    var callback = function () { fn.apply(this, arguments); emitter.unbind(event, callback); };
    emitter.on(event, callback);
    return emitter;
  }


  function preventDef(cb) { // TODO: ditto
    return function (event) {
      event.preventDefault();
      return cb.apply(this, arguments);
    };
  }

  return {
    restrict: 'CA',
    require: '^draggable',
    link: function (scope, el, attr, ctrl) {
      el.on('mousedown', preventDef(function (event) {
        ctrl.dragStart(event.screenX, event.screenY);
        once($document, 'mouseup', preventDef(ctrl.dragStop.bind(ctrl)));
      }));
    }
  };
}
// Dependency Injection
DraggableCtrl.$inject = ["$scope","$document"];
draggableDirective.$inject = ["$document"];
draggableTriggerDirective.$inject = ["$document"];
