
'use strict';

export const $clientResolveCodesService  = function (ClientResolveCodes) {
    return {
      updateResolveCode: function(client, resolveCode) {
        return ClientResolveCodes.updateResolveCode({
          _id: client._id || client,
          data: {
            code: resolveCode.code,
            description: resolveCode.description
          },
          resolveCode: resolveCode._id
        }).$promise;
      },
      saveResolveCode: function(client, resolveCode) {
        return ClientResolveCodes.saveResolveCode({
          _id: client._id || client,
          code: resolveCode.code,
          description: resolveCode.description,
        }).$promise;
      },
      activate: function(client, resolveCode) {
        return ClientResolveCodes.activate({
          _id: client._id || client,
          resolveCode: resolveCode._id
        }).$promise;
      },
      suspend: function(client, resolveCode) {
        return ClientResolveCodes.suspend({
          _id: client._id || client,
          resolveCode: resolveCode._id
        }).$promise;
      },
      getResolveCodes: function(client, includeSuspended) {
        return ClientResolveCodes.getResolveCodes({
          id: client._id || client,
          filter: {includeSuspended: !!includeSuspended}
        }).$promise;
      },
      getResolveCode: function(client, resolveCodeId) {
        return ClientResolveCodes.getResolveCode({
          id: client._id || client,
          controller: resolveCodeId
        }).$promise;
      }
    };
  }
// Dependency Injection


// Dependency Injection
$clientResolveCodesService.$inject = ['ClientResolveCodes'];
