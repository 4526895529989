
'use strict';

export const $languageTypesService  =  function () {
    let languageTypes = [{
      label: 'Common',
      value: 'COM'
    },
    {
      label: 'Uncommon',
      value: 'UNC'
    },
    {
      label: 'Spanish',
      value: 'SP'
    }];
    return languageTypes;
  }
// Dependency Injection

