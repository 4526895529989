import './training.scss';
'use strict';

export const trainingDirective = function (Modal, $clientTrainingManager, $clientSwitcher, $window, $authStorage, $activeClient, ngToast) {
        return {
            restrict: 'E',
            templateUrl: 'app/account/dashboard/main-dashboard/interpreter-resource/tabs/training/training.html',
            scope: {
                userPermissions: '=userPermissions',
            },
            link: function (scope) {
                scope.trainingRecords = [];
                scope.loading = false;
                scope.message = '';
                scope.permissions = {
                    allowTrainingRecordEdit: false
                };

                // scope.trainingVideos = [{
                //     name: 'Module 1',
                //     description: 'EOIR Contract Interpreter Program Overview',
                //     link: 'https://my.visme.co/projects/w4yzg6qo-onz2g0opzdg05e6p#s1',
                //     img: '/assets/images/module1.png'
                // },
                // {
                //     name: 'Module 2',
                //     description: 'Code of Professional Responsibility',
                //     link: 'https://my.visme.co/projects/ep8y41jx-owplnmnx39j72zd6#s1',
                //     img: '/assets/images/module2.png'
                // },
                // {
                //     name: 'Module 3',
                //     description: 'EOIR Court Requirements & Procedures',
                //     link: 'https://my.visme.co/projects/rx1eo9o6-voql9kgem0o82x1w#s1',
                //     img: '/assets/images/module3.png'
                // }            ,
                // {
                //     name: 'Module 4',
                //     description: 'DAR Equipments',
                //     link: 'https://my.visme.co/projects/kkzynjv3-pmjlj0nzpzev2z39#s1',
                //     img: '/assets/images/module4.png'
                // },
                // {
                //     name: 'Module 5',
                //     description: 'EOIR Interpretation Standards',
                //     link: 'https://my.visme.co/projects/ep8y4rz1-6ep5dwo8ymzk2dz3#s1',
                //     img: '/assets/images/module5.png'
                // },
                // {
                //     name: 'Module 6',
                //     description: 'Immigration Terminology',
                //     link: 'https://my.visme.co/projects/8ry0p6qk-d3o26vz1y3g7lgxw#s1',
                //     img: '/assets/images/module6.png'
                // }];

                var loadTrainingRecords = function (client, index) {
                    if (!(index >= 0))scope.loading = true;
                    $clientTrainingManager.getTrainingRecords(scope.client).then(function (res) {
                        if (res.status) {
                            if (index >= 0) {
                                let editedRecordId = scope.trainingRecords[index]._id;
                                let updatedIndex = res.data.findIndex(function(obj) {
                                    return obj._id == editedRecordId
                                })
                                // scope.trainingRecords[index] = res.data[updatedIndex];
                                scope.trainingRecords = res.data;
                            } else {
                                scope.trainingRecords = res.data;
                            }
                            if (!scope.trainingRecords.length) {
                                scope.message = 'No Training Records found';
                            }
                        }
                        else {
                            scope.message = res.reason;
                        }
                    }).catch(function (err) {

                    })
                    .finally(function (){
                        scope.loading = false;
                    });
                };

                scope.createTrainingRecord = function () {
                    Modal.training.createTrainingRecord(function (trainingRecord) {
                        $clientTrainingManager.createTrainingRecord(scope.client, trainingRecord).then(function () {
                            loadTrainingRecords(scope.client);
                        });
                    })();
                };

                scope.showUpload = function (uploadList, index, trainingObj) {
                    if (uploadList && uploadList.length) {
                        uploadList[index].description = trainingObj.description;
                        uploadList[index].title = trainingObj.title;
                        Modal.info.previewUpload(function (result) {
                            console.log('modal preview result:', result);
                        })(scope.client, uploadList, index);
                    } else {
                        ngToast.create({
                            className: 'danger',
                            content: 'Image/Video not found!'
                        });
                    }
                };

                // scope.download = function (upload) {
                //     $window.location = '/api/client-uploads/' + (scope.client._id || scope.client) + '/' + upload._id + '?access_token=' + $authStorage.getToken();
                // };

                scope.getFilePath = function (upload) {
                    return 'uploads/'+ upload.path;
                    // return '/api/client-uploads/' + (scope.client._id || scope.client) + '/' + upload._id + '?preview=1&access_token=' + $authStorage.getToken();
                };

                // scope.getImageFilePath = function (upload) {
                //     let downloadUrl  = '/attachment/'+(scope.client._id || scope.client)+'/'+upload._id;
                //     return downloadUrl+'?preview=0';
                // };

                scope.getSize = function (upload) {
                    var inBytes = upload.size;
                    var inKiloBytes = inBytes / 1024;
                    if (inKiloBytes < 1) {
                        return inBytes + ' B';
                    }
                    else {
                        var inMegaBytes = inKiloBytes / 1024;
                        if (inMegaBytes < 1) {
                            return Math.round(inKiloBytes) + ' KB';
                        }
                        else {
                            return Math.round(inMegaBytes) + ' MB';
                        }
                    }
                };

                scope.editTrainingRecord = function (trainingRecord, index) {
                    Modal.training.createTrainingRecord(function (trainingRecord) {
                        trainingRecord = _.omit(trainingRecord, ['__v', 'client', 'createdAt', 'createdBy']);
                        if (trainingRecord)
                        trainingRecord.uploads = trainingRecord.uploads.map(function (trainingRecord) {
                            return trainingRecord._id ? trainingRecord._id : trainingRecord;
                        });

                        $clientTrainingManager.updateTrainingRecord(scope.client, trainingRecord._id, _.omit(trainingRecord, ['_id']))
                            .then(function () {
                                loadTrainingRecords(scope.client, index);
                            });
                    })(angular.copy(trainingRecord));
                };

                scope.deleteTrainingRecord = function (trainingRecord, index) {
                    Modal.confirm.show(
                        "Confirm Delete",
                        "Are you sure you want to delete <strong>" + trainingRecord.title + "</strong>?",
                        "Delete", "Cancel",
                        function () {
                            scope.trainingRecords.splice(index, 1)
                            $clientTrainingManager.deleteTrainingRecord(scope.client, trainingRecord)
                                .then(function () {
                                    // loadTrainingRecords(scope.client);
                                });
                        });
                };

                scope.$on('$destroy', $activeClient(function (client) {
                    if (!client) {
                        return;
                    }
                    scope.client = client;
                    scope.permissions.allowTrainingRecordCreate = scope.userPermissions.includes('INTERPRETER_RESOURCES_TRAINING_CREATE');
                    scope.permissions.allowTrainingRecordEdit = scope.userPermissions.includes('INTERPRETER_RESOURCES_TRAINING_EDIT');
                    scope.permissions.allowTrainingRecordRemove = scope.userPermissions.includes('INTERPRETER_RESOURCES_TRAINING_DELETE');
                    loadTrainingRecords();
                }));

                scope.openTrainingVideo = (trainingVideoLink) => {
                    if (trainingVideoLink.attachmentType == 'video') {
                        scope.showUpload(trainingVideoLink.videoUploads, 0, trainingVideoLink)
                    } else {
                        window.open(trainingVideoLink.link, '_blank');
                    }
                }
            }
        }
    }
// Dependency Injection
trainingDirective.$inject = ["Modal","$clientTrainingManager","$clientSwitcher","$window","$authStorage","$activeClient","ngToast"];
