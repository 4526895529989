'use strict';

export const TablesConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard.settings.tables', {
        url: '/tables',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/tables/tables.html', '4d4eae4a'); }],
        controller: 'DashboardSettingsTablesCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/tables/tables.controller.js', 'DashboardSettingsTablesCtrl'); }]
                      }
      })
      .state('app.account.dashboard.settings.tables.index', {
        url: '/index',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/tables/index/index.html', '698896ca'); }],
        controller: 'DashboardSettingsTablesIndexCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/tables/index/index.controller.js', 'DashboardSettingsTablesIndexCtrl'); }]
                      }
      })
      .state('app.account.dashboard.settings.tables.create', {
        url: '/create',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/tables/create/create.html', '7f20712a'); }],
        controller: 'DashboardSettingsTablesCreateCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/tables/create/create.controller.js', 'DashboardSettingsTablesCreateCtrl'); }]
                      }
      })
      .state('app.account.dashboard.settings.tables.update', {
        url: '/update/:id',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/tables/update/update.html', '45db118a'); }],
        controller: 'DashboardSettingsTablesUpdateCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/tables/update/update.controller.js', 'DashboardSettingsTablesUpdateCtrl'); }],
                          '$selectedTable': ['$stateParams','$tablesManager', '$clientSwitcher', function ($stateParams, $tablesManager, $clientSwitcher) {
                            return $tablesManager.getTableDetails($clientSwitcher.getCurrentClient(), $stateParams.id)
                            .then(function(res) {
                              if(res.status) {
                                return res.data;
                              }
                            });
                          }]
                      },

      });
  }
// Dependency Injection
TablesConfig.$inject = ["$stateProvider"];
