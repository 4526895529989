


'use strict';

export const LinguistFactory  = function ($resource, appConfig) {
        return $resource(appConfig.apiUrl + '/api/linguist-user/:id/:controller', {
            id: '@_id'
        },
            {
                create: {
                    method: 'POST',
                    params: {}
                },
                userList: {
                    method: 'GET',
                    params: {
                    }
                }
            }
        );
    } 
 

// Dependency Injection
LinguistFactory.$inject = ['$resource', 'appConfig'];
