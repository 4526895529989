'use strict';

export const DashboardSettingsRolesIndexCtrl = function ($rootScope, $scope, $activeClient, $clientRoles,  $auth) {

    let currentCli = null;
    $scope.loading = false;
    $scope.allowEditRole = false;

    function getRoles(client) {
      $scope.loading = true;

      $clientRoles.getRoles(client)
        .then(({ data, status }) => {
          if (status) {
            $scope.roles = data;
            $scope.activeRoles = data.filter(x => x.isEnabled).length;
          }
          $scope.allowEditRole = $auth.getCurrentUser().permissions.includes('SETTING_ROLE_EDIT');
        })
        .finally(() => $scope.loading = false)
    }

    $scope.orderVal = 'true';
    $scope.sortingOrders = [{
      label:'A-Z',
      value: 'true'
    }, {
      label: 'Z-A',
      value: 'false'
    }];
    // listen for client switching
    $scope.$on('$destroy', $activeClient(function(client) {
      if (!client) return;
      currentCli = client;
      getRoles(currentCli)
    }));
  }
// Dependency Injection
DashboardSettingsRolesIndexCtrl.$inject = ["$rootScope","$scope","$activeClient","$clientRoles","$auth"];

// angular.module('coordinateApp').controller('DashboardSettingsRolesIndexCtrl', DashboardSettingsRolesIndexCtrl);
