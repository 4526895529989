


'use strict';

export const CasesFactory  = function ($resource, appConfig) {
        return $resource(appConfig.apiUrl + '/api/cases/:id/:controller', {
            id: '@_id'
        },
            {
                getCasesList: {
                    method: 'GET',
                    params: {
                        controller: 'cases-list'
                    }
                },
                getCasesStatusCount: {
                    method: 'GET',
                    params: {
                        controller: 'get-cases-status-count'
                    }
                },
                getCasesStatusCountDiff: {
                    method: 'GET',
                    params: {
                        controller: 'get-cases-status-count-diff'
                    }
                },
                getAuditLogs: {
                    method: 'GET',
                    params: {
                        controller: 'audit-log'
                    }
                },
                forceSync: {
                    method: 'POST',
                    params: {
                      controller: 'force-sync'
                    }
                },
                syncTable: {
                    method: 'POST',
                    params: {
                        controller:'sync-table'
                    }
                },
                forceCasesSync: {
                    method: 'POST',
                    params: {
                      controller: 'force-cases-sync'
                    }
                }
            });
    } 
 

// Dependency Injection
CasesFactory.$inject = ['$resource', 'appConfig'];
