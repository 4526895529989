
'use strict';

export const $listsService  = function (Lists) {
        return {
            getLists: function (client, paginationData) {
                return Lists.getLists({
                    id: client._id || client,
                    page: paginationData && paginationData.page,
                    pageSize: paginationData && paginationData.pageSize
                }).$promise;
            },
        };
    }
// Dependency Injection


// Dependency Injection
$listsService.$inject = ['Lists'];
