
'use strict';

export const uasExternalUsersDirective  = function (){
  return {
    restrict: 'A',
    require: ['uasExternalUsers'],
    controller: 'UasExternalUsersCtrl as $uasExternalUsers',
    link: function(scope, el, attrs, ctrls) {
      // var ctrl = ctrls[0];
      // scope.$watch(attrs.uasExternalUsers, ctrl.bindItems.bind(ctrl));
    }
  };        
} 
 
