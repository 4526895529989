'use strict';


export const scheduleTaskDirective = function ($clientSwitcher, $clientOffice, $clientExternalUsers, $clientTask, $clientUser, $timeout) {
        return {
            restrict: 'E',
templateUrl: 'components/forms/schedule-task.html',
            scope: {
                taskSchedule: '=ngModel',
                onSubmit: '=',
                onCancel: '='
            },
            link: function (scope, el, attrs) {
                var client;
                scope.init = function () {
                    scope.isValid = false;
                    scope.model = scope.taskSchedule;

                    scope.defaultStatus = 'active';
                    scope.statuses = [
                        {value: 'active', label: 'Active'},
                        {value: 'suspended', label: 'Suspended'}
                    ];

                    scope.dueDateIn = {
                        assignTo: 'hours',
                        assignToOnExpire: 'hours'
                    };

                    scope.slider = {
                        hours: {
                            max: 1440, //23:55 in minutes
                            step: 15
                        },
                        days: {
                            max: 43200,
                            step: 1440
                        }
                    };

                    scope.relativeDates = {
                        hours: [
                            { label: '30 Min', value: 30 },
                            { label: '1 Hrs', value: 60 },
                            { label: '2 Hrs', value: 120 },
                            { label: '3 Hrs', value: 180 },
                            { label: '24 Hrs', value: 1440 }
                        ],
                        days: [
                            { label: '1 Day', value: 1440 },
                            { label: '2 Days', value: 2880 },
                            { label: '3 Days', value: 4320 },
                            { label: '1 Week', value: 10080 },
                            { label: '2 Weeks', value: 20160 }
                        ]
                    };

                    scope.taskPriorities = [
                        {value: 'high', label: 'High'},
                        {value: 'med-high', label: 'Medium-high'},
                        {value: 'med-low', label: 'Medium-low'},
                        {value: 'low', label: 'Low'}
                    ];

                    scope.repeatModes = [
                        { label: 'Every', value: 'every' },
                        { label: 'Every Other', value: 'every-other' },
                        { label: 'First of Month', value: 'first-of-month' }
                    ];
                    scope.repeatOptions = [{ label: 'Never', value: '' }, { label:  'Days', value: 'days' }, { label:  'Dates', value: 'dates' }];

                    scope.weekDays = [
                        { label: 'Mon', value: 0 },
                        { label: 'Tue', value: 1 },
                        { label: 'Wed', value: 2 },
                        { label: 'Thurs', value: 3 },
                        { label: 'Fri', value: 4 },
                        { label: 'Sat', value: 5 },
                        { label: 'Sun', value: 6 }
                    ];

                    scope.selected = {
                        dateRange: ''
                    };

                    scope.possibleAssignableUsers = [];
                    scope.assignableUsers = [];

                    client = $clientSwitcher.getCurrentClient()._id;

                    scope.reloadOffices(client);
                    scope.reloadCollections(client);
                    scope.fetchAvailableUsers();


                    $timeout(function(){
                      $('#scheduler-execution-date').daterangepicker({
                          autoApply: true,
                          minDate: moment().startOf('month'),
                          maxDate: moment().endOf('month'),
                      }, dateRangePickerCallback);

                      var picker = $('#scheduler-execution-date').data('daterangepicker');
                      if(scope.model.window.repeat === 'dates') {
                          var dates = scope.model.window.selectedDates;
                          var startDate = moment().date(dates[0]);
                          var endDate = moment().date(dates[dates.length - 1]);
                          picker.setStartDate(startDate);
                          picker.setEndDate(endDate);
                          dateRangePickerCallback(startDate, endDate);
                      }
                      picker.container.find('.range_inputs').hide();
                      picker.container.find('.calendar.left').hide();
                    }, 1000);
                };

                var dateRangePickerCallback = function (start, stop) {
                    $timeout(function() {
                        scope.model.window.selectedDates = [];
                        for (var i = start._d.getDate(); i <= stop._d.getDate(); i++) {
                            scope.model.window.selectedDates.push(i);
                        }

                        scope.selected.dateRange = moment(start).format('DD');
                        if (scope.selected.dateRange !== moment(stop).format('DD')) {
                            scope.selected.dateRange += ' to ' + moment(stop).format('DD');
                        }

                    }, 100);
                };

                scope.reloadOffices = function (client) {
                    return $clientOffice.listAll(client)
                        .then(function (offices) {
                            scope.offices = offices.map(function (office) {
                                return {
                                    label: office.name,
                                    value: office._id
                                };
                            });
                        });
                };

                scope.reloadCollections = function (client) {
                    scope.collections = false;
                    return $clientTask.getCollections(client, true)
                        .then(function (collections) {
                            scope.collections = collections.map(function (collection) {
                                return {
                                    label: collection.name,
                                    value: collection._id,
                                    taskTypes: collection.types.map(function(taskType) {
                                        return {
                                            label: taskType,
                                            value: taskType
                                        };
                                    })
                                };
                            });

                            if (scope.model.taskDetails && scope.model.taskDetails.taskCollection) {
                                scope.setCurrentCollection(scope.model.taskDetails.taskCollection);
                            }
                        });
                };

                scope.setCurrentCollection = function (toCollection) {
                    var prevType = false,
                        hasModel = !!scope.model;

                    delete scope.collectionTypes;

                    if (hasModel) {
                        prevType = scope.model.taskDetails.taskType;
                    }

                    var collection;

                    scope.collections.every(function (col) {
                        if (col.value !== toCollection) return true;
                        collection = col;
                        return false;
                    });

                    if (!collection) {
                        if (hasModel) {
                            delete scope.model.taskDetails.taskType;
                        }

                        return;
                    }

                    scope.collectionTypes = collection.taskTypes;

                    if (hasModel) {
                        var setTo = scope.collectionTypes[0].value;

                        if (prevType) {
                            scope.collectionTypes.every(function (typeDef) {
                                if (typeDef[0] === prevType) {
                                    setTo = typeDef.value;
                                    return false;
                                }
                                
                                return true;
                            });
                        }

                        scope.model.taskDetails.taskType = setTo;
                    }
                };

                scope.taskCollectionChanged = function (toCollection) {
                    scope.setCurrentCollection(toCollection);
                    scope.fetchAvailableUsers();
                };

                scope.fetchAvailableUsers = function () {
                    if (!scope.model.taskDetails.location || !scope.model.taskDetails.taskCollection) {
                        return;
                    } else {
                        scope.reloadAvailableUsers(client);
                    }
                };

                scope.reloadAvailableUsers = function (client) {
                    if (!scope.model.taskDetails || !scope.model.taskDetails.taskCollection || !scope.model.taskDetails.location) {
                        return;
                    }

                    return $clientUser.getUserChainOfCommand(client, scope.model.location, scope.model.taskDetails.taskCollection)
                        .then(function (chainOfCommand) {
                            scope.assignableUsers = chainOfCommand;
                            var chainOfCommandCopy = angular.copy(chainOfCommand);
                            scope.possibleAssignableUsers = chainOfCommandCopy.delegates;

                            scope.possibleAssignableUsers = scope.possibleAssignableUsers.map(function (user) {
                                return {
                                    label: user.name,
                                    value: user.userId
                                };
                            });

                            return scope.possibleAssignableUsers;
                        });
                };

                scope.toggleDay = function (day) {
                    var index = scope.model.window.selectedDays.indexOf(day.value);
                    if (index > -1) {
                        scope.model.window.selectedDays.splice(index, 1);
                    }
                    else {
                        scope.model.window.selectedDays.push(day.value);
                    }
                };

                scope.isDaySelected = function (day) {
                    return scope.model.window && scope.model.window.selectedDays && scope.model.window.selectedDays.indexOf(day.value) > -1;
                };

                scope.$watch(function () { return scope.formScheduleTask.$valid; }, function (nv) {
                    scope.isValid = nv;
                });

                scope.init();
            }
        };
    }
// Dependency Injection
scheduleTaskDirective.$inject = ["$clientSwitcher","$clientOffice","$clientExternalUsers","$clientTask","$clientUser","$timeout"];
