
'use strict';

export const $LinguistService  = function (Linguist) {
        return {
            getServiceLanguages: function name() {
                return [{
                    label: 'On Site Interpretation',
                    value: 'on_site_interpretation'
                }, {
                    label: 'Telephonic Interpretation',
                    value: 'telephonic_interpretation'
                }, {
                    label: 'Localization',
                    value: 'localization'
                }, {
                    label: 'Translation',
                    value: 'translation'
                }, {
                    label: 'Transcription',
                    value: 'transcription'
                }, {
                    label: 'Captioning',
                    value: 'captioning'
                }]
            },
            create: function (client, data) {
                return Linguist.create({
                    _id: client._id || client,
                    data
                }).$promise;
            },
            userList: function (client, page, pageSize) {
                return Linguist.userList({ id: client._id, page, pageSize }).$promise;
            }
        };
    }
// Dependency Injection


// Dependency Injection
$LinguistService.$inject = ['Linguist'];
