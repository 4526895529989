


'use strict';

export const ClientCampaignFactory  = function ($resource, appConfig) {
    return $resource(appConfig.apiUrl + '/api/client-campaigns/:id/:controller', {
      id: '@_id'
    },
    {
      detail: {
        method: 'GET',
        params: {
          controller: 'detail'
        }
      }
    });
  } 
 

// Dependency Injection
ClientCampaignFactory.$inject = ['$resource', 'appConfig'];
