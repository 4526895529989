
'use strict';

export const $clientOfficeInformationService  = function (ClientOfficeInformation) {
        return {
            createOfficeInfoRecord: function (client, officeInfoData) {
                return ClientOfficeInformation.createOfficeInfoRecord({
                    _id: client._id || client,
                    data: officeInfoData
                }).$promise;
            },
            updateOfficeInfoRecord: function (client, officeInfoId, officeInfoData) {
                return ClientOfficeInformation.updateOfficeInfoRecord({
                    _id: client._id || client,
                    officeInfo: officeInfoId,
                    data: officeInfoData
                }).$promise;
            },
            deleteOfficeInfoRecord: function (client, officeInfoData) {
                return ClientOfficeInformation.deleteOfficeInfoRecord({
                    _id: client._id || client,
                    officeInfo: officeInfoData._id || officeInfoData
                }).$promise;
            },
            getOfficeInfoRecords: function (client, search) {
                return ClientOfficeInformation.getOfficeInfoRecords({
                    id: client._id || client,
                    search: search
                }).$promise;
            },
            removeAttachments: function (client, officeInfoRecord, attachmentIds) {
                return ClientOfficeInformation.removeAttachments({
                    _id: client._id || client,
                    officeInfoId: officeInfoRecord._id || officeInfoRecord,
                    data: attachmentIds
                }).$promise;
            }
        };
    }
// Dependency Injection


// Dependency Injection
$clientOfficeInformationService.$inject = ['ClientOfficeInformation'];
