
'use strict';

export const $rulesExecutionLogsManagerService  = function (RulesExecutionLogsManager) {
    return {
      getLogs: function(client, sortBy) {
        return RulesExecutionLogsManager.getLogs({
          id: client._id || client,
          sortBy: sortBy
        }).$promise;
      }
    };
  } 
 

// Dependency Injection
$rulesExecutionLogsManagerService.$inject = ['RulesExecutionLogsManager'];
