'use strict';

export const UsersSessionConfig = function ($stateProvider) {
        $stateProvider
        .state('app.account.dashboard.settings.users-session', {
            url: '/users-session',
            templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/users-session/users-session.html', '4279684a'); }],
            controller: 'UsersSessionCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/users-session/users-session.controller.js', 'UsersSessionCtrl'); }]
                      }
          })
    }
// Dependency Injection
UsersSessionConfig.$inject = ["$stateProvider"];
