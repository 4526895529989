
'use strict';

export const $ServicePortalService  = function (ServicePortal) {
    return {
      getPendingApprovalList: function (client, search) {
        return ServicePortal.getPendingApprovalList({
          id: client._id || client,
          search: search
        }).$promise;
      },
      getPendingApprovalListCount: function (client, search) {
        return ServicePortal.getPendingApprovalListCount({
          id: client._id || client,
          search: search
        }).$promise;
      },
      create: function (client, data) {
        return ServicePortal.create({
          _id: client._id || client,
          data
        }).$promise;
      },
      verifyEmail: function (client, data) {
        return ServicePortal.verifyEmail({
          _id: client._id || client,
          data
        }).$promise;
      },
      userToggle: function (client, users) {
        return ServicePortal.toggleUser({
          _id: client._id || client,
          data: users
        }).$promise;
      }
    };
  }
// Dependency Injection


// Dependency Injection
$ServicePortalService.$inject = ['ServicePortal'];
