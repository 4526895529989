import './modal.scss';
'use strict';



export const ModalFactory = function ($rootScope, $uibModal, $auth, $filter, $window, $authStorage, $clientTask, AudioPlayList, FileUploader, $q, $officeOperations, $timeout, OSI, OPI, VRI, INTERPRETER_DISPUTE, COURT_DECLINE, SOSI_DISPUTE, COURT_APPROVE, INTERPRETER_APPROVE, MY_RESOURCES, $timezones, $courtOperations, LazyLoadService) {
  let modals = {};
  let operationActions;

  const loadOperationActionsModule = ()=>{
    if(operationActions) return operationActions;
    return import('./modals/operationActions').then(({ default: operationActions })=>{
      const officeOperationActionsModal = operationActions($rootScope, $uibModal, $auth, $filter, $window, $authStorage, $clientTask, AudioPlayList, FileUploader, $q, $officeOperations, $timeout, OSI, OPI, VRI, INTERPRETER_DISPUTE, COURT_DECLINE, SOSI_DISPUTE, COURT_APPROVE, INTERPRETER_APPROVE, MY_RESOURCES, $timezones, $courtOperations, openModal, dateFilter, modals)
      operationActions = officeOperationActionsModal
      return officeOperationActionsModal

    })
  }
    const focusDelay = 300;
    var earlyFINSecond = 7200,
    dateFilter = $filter('date');
    var earlyTimeInSecond = 10500; // (Time 2:55)
    var timeSch = [
      {
        label: '1 hour',
        value: 60
      },
      {
        label: '2 hours',
        value: 120
      },
      {
        label: '3 hours',
        value: 180
      }
    ];
    /**
     * Opens a modal
     * @param  {Object} scope      - an object to be merged with modal's scope
     * @param  {String} modalClass - (optional) class(es) to be applied to the modal
     * @return {Object}            - the instance $modal.open() {
     * returns
     *}
     */
    function openModal(scope, modalClass, size, keyboard, autoClose) {
      var modalScope = $rootScope.$new();
      scope = scope || {};
      modalClass = modalClass || 'modal-default';

      if(!scope.controller) {
        scope.controller = [function () {
        }];
      }

      angular.extend(modalScope, scope);

      return $uibModal.open({
        templateUrl: 'components/modal/modal.html',
        windowTemplateUrl: 'components/modal/modal-window.html',
        windowClass: modalClass,
        scope: modalScope,
        size: size || null,
        keyboard: keyboard ? false : true,
        backdrop: autoClose ? 'static' : 'true',
      });
    }

    function recordPlaylist(record) {
      if(!record || !record.raw) {
        return false;
      }

      var recordings = (angular.isArray(record.raw.recording) ? record.raw.recording : []);

      return new AudioPlayList({
        tracks: recordings.map(function (r) {
          r.title = 'Recording From ' + dateFilter(r.created, 'short');
          return r;
        })
      });
    }

    function calculateWOTime(caseData, woUnAlt) {

      const defaultState = {
        startTime: 'N/A',
        totalLunchTime: 'N/A',
        lateTime: 'N/A',
        releasedTime: 'N/A',
        endTime: 'N/A',
        totalWOTime: 'N/A',
        totalWoPay: 'N/A',
        extendTime: 'N/A'
      };

      if (!caseData) {
        return defaultState;
      }

      // const caseData = record;
      /**
       * BillType values equals
       *  sched when FIN < SCHED < JIN
       *  jin when FIN < JIN < SCHED
       *  fin when SCHED < FIN
       */
      const getStartTime = (schedTime, finTime, firstJinTime) => {
        if (caseData.sertype == OPI || caseData.sertype == VRI) {
          if (moment(firstJinTime).isAfter(schedTime)) {
            return schedTime;
          }
          return firstJinTime;
        }
        if (firstJinTime && moment(schedTime).isBetween(finTime, firstJinTime)) {
          return schedTime;
        }
        else if (firstJinTime && moment(firstJinTime).isBetween(finTime, schedDate)) {
          return firstJinTime;
        }
        else if (moment(schedTime).isBefore(finTime)) {
          return finTime;
        }
        else {
          return finTime;
        }
      }
      const calculateLateTime = (scheduleDate, firstJinDate) => {

        const firstJin = moment(firstJinDate);
        var lateJudgeIn = moment.duration(firstJin.diff(scheduleDate));
        const hours = parseInt(lateJudgeIn.asHours());
        const minutes = parseInt(lateJudgeIn.asMinutes()) % 60;

        return (hours > 0 || minutes > 0) ? (hours ? hours + ' hours ' : '') + minutes + ' minutes' : 0;
      }
      // const calculateJudgeTime = (judgedInOut) => {
      //   let totalTime = 0;
      //   for (let i = 0; i < judgedInOut.length; i += 2) {
      //     const jin = moment(judgedInOut[i].date);
      //     const jout = moment(judgedInOut[i + 1].date);
      //     totalTime += moment.duration(jout.diff(jin))
      //   }
      //   return totalTime;
      // }
      const calculateLunchTime = (caseData) => {
        if (caseData.lunchOut && caseData.lunchIn) {
          const lout = moment(caseData.lunchOut.date);
          const lin = moment(caseData.lunchIn.date);
          const duration = moment.duration(lin.diff(lout));
          // const hours = parseInt(duration.asHours());
          // const minutes = parseInt(duration.asMinutes())%60;
          // const defaultLunch = 7200000
          return duration > 7200000 ? 7200000 : duration;
        }
        return 0;
      }
      const calculateExtTime = (extTime) => {
        let totalTime = 0;
        for (let i = 0; i < extTime.length; i++) {
          const start = moment(extTime[i].date);
          const end = moment(extTime[i].nextHourTime ? extTime[i].nextHourTime : extTime[i].extendTime);
          totalTime += moment.duration(end.diff(start))
        }
        return totalTime;
      }

      let woDisplay, totalWoPay, lunchTime, extendTime;
      const schedDate = moment.utc(caseData.deldate).format('MM-DD-YYYY') + ' ' + caseData.scheduleTime;
      const hasJin = caseData.judgedInOut && caseData.judgedInOut.length && caseData.judgedInOut[0].date;
      const hasFin = caseData.checkedIn && caseData.checkedIn.date;
      // if(!hasFin || !hasJin) {
      //   return {
      //   startTime: 'N/A',
      //   totalLunchTime: 'N/A',
      //   lateTime: 'N/A',
      //   releasedTime: 'N/A',
      //   endTime: 'N/A',
      //   totalWOTime: 'N/A',
      //   totalWoPay: 'N/A'
      //   };
      // }
      let foutTime = caseData.checkedOut && caseData.checkedOut.date ? moment(caseData.checkedOut.date) : null;
      const firstJin = hasJin ? caseData.judgedInOut[0].date : null;
      const startTime = getStartTime(schedDate, hasFin ? caseData.checkedIn.date : null, hasJin ? caseData.judgedInOut[0].date : null)

      if (!startTime) return defaultState;

      const minWorkOrder = 10800000;
      const releasedTime = caseData.release && caseData.release.date ? moment(caseData.release.date).format('hh:mm A') : null;
      let endTime = caseData.checkedOut && caseData.checkedOut.date ? moment(caseData.checkedOut.date).format('hh:mm A') : null;
      if (caseData.sertype == OPI || caseData.sertype == VRI) {
        if (caseData.judgedInOut && caseData.judgedInOut.length > 0) {
          var lastJudgeInOut = caseData.judgedInOut[caseData.judgedInOut.length - 1];
          if (lastJudgeInOut && lastJudgeInOut.event == 'Judge OUT') {
            endTime = moment(lastJudgeInOut.date).format('hh:mm A');
            foutTime = moment(lastJudgeInOut.date);
          } else {
            endTime = null;
            foutTime = null;
          }
        }
      }
      const totalLunchTime = calculateLunchTime(caseData);
      const totalWOTime = !startTime ? minWorkOrder : foutTime ? moment.duration(foutTime.diff(startTime)) : moment.duration(moment().diff(startTime));
      const totalJobTime = totalWOTime ? (totalWOTime - totalLunchTime) / (1000 * 60 * 60) : 0;

      if (caseData.extendIC && caseData.extendIC.length) {
        // const initExtTime = moment(caseData.extendIC.date);
        // const extendedTime = moment(caseData.extendIC.nextHourTime);
        const duration = calculateExtTime(caseData.extendIC);
        const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((duration / (1000 * 60)) % 60);

        extendTime = hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0');
      }
      // const totalJobTime = totalWOTime ? totalWOTime - totalLunchTime : null;
      if (totalLunchTime) {
        const hours = Math.floor((totalLunchTime / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((totalLunchTime / (1000 * 60)) % 60);
        lunchTime = (hours > 0 || minutes > 0) ? (hours ? hours + ' hours ' : '') + minutes + ' minutes' : 0
      }

      if (totalJobTime) {
        const hours = Math.floor(totalJobTime);
        const minutes = Math.floor((totalJobTime * 60) % 60);
        woDisplay = woUnAlt ? totalJobTime : hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0');
        totalWoPay = Math.ceil(totalJobTime).toString().padStart(2, '0') + ':00';
      }

      return {
        startTime: startTime,
        totalLunchTime: totalLunchTime ? lunchTime : 'N/A',
        lateTime: hasJin ? calculateLateTime(schedDate, firstJin) : 'N/A',
        releasedTime: releasedTime ? releasedTime : 'N/A',
        endTime: endTime ? endTime : 'N/A',
        totalWOTime: totalJobTime ? woDisplay : 'N/A',
        totalWoPay: totalJobTime ? totalWoPay : 'N/A',
        extendTime: extendTime ? extendTime : 'N/A'
      };
    }

    function previewQueryResult(client, scratchPad, pageData) {
      var defer = $q.defer();
      var allValue = 'all';
      var filter = {
        startDate: scratchPad.date,
        endDate: scratchPad.date,
        // searchValue: data.searchValue ? data.searchValue.toLowerCase() : data.searchValue,
        // coi: scratchPad.ecoi ? scratchPad.ecoi : allValue,
        // hrgCourt: scratchPad.hrgCourt ? scratchPad.hrgCourt : allValue,
        reportingCourt: scratchPad.reportingCourt ? scratchPad.reportingCourt : allValue,
        // judge: data.judge ? data.judge : allValue,
        // interPreter: scratchPad.interPreter ? scratchPad.interPreter : allValue,
        // langName: data.langName ? data.langName : allValue,
        // alien: data.alien ? data.alien : allValue
      }
      // $officeOperations.filterCourtOperationsDetails(client, filter, pageData)
      $courtOperations.courtOpsCases(client, filter, pageData)
        .then(function (res) {
          if (res.status) {
            if (res.data.statusWorkOrder) {
              defer.resolve(res.data.statusWorkOrder);
            } else {
              defer.reject('No records found');
            }
          } else {
            defer.reject('No Connection to Database - Please Try Again');
          }
        })
        .catch(function (err) {
          defer.reject(err.message || err);
        })
      return defer.promise
    }

    function manuallyInterpreter(client, scratchPad, pageData) {
      var defer = $q.defer();
      previewQueryResult(client, scratchPad, pageData)
      .then(function (res) {
        var interpreterDataSets = [];
        const sortedInterpreterDataSets = _.sortBy(res.pending, ['interprername', 'sched']);

        sortedInterpreterDataSets.forEach(function(record) {
          interpreterDataSets.push({
            label: record.interprername + ' | ' + record.coi + ' | ' + moment(record.sched, ["h:mm A"]).format("hh:mm A"),
            value: record.coi,
            vendorId: record.vendorid
          })
        });

        defer.resolve(_.uniq(interpreterDataSets, 'label'));
      })
      .catch(function (err) {
        defer.reject(err.message || err);
      })
      return defer.promise
    }

    function getECOIForRCourt(client, scratchPad, pageData) {
      var defer = $q.defer();
      previewQueryResult(client, scratchPad, pageData)
      .then(function (res) {
        defer.resolve(res)
      })
      .catch(function (err) {
        defer.reject(err.message || err);
      })
      return defer.promise
    }

    function getECOITimezone (timezone){
      var index = _.findIndex($timezones, function (x) {
        return x.label == timezone;
      })
      return $timezones[index] ? $timezones[index].offset : moment().format('ZZ');
    }

    // Public API here
    modals = {

      /* Confirmation modals */
      confirm: {

        show: function(title, message, confirmButton, cancelButton, cb, confirmButtonColor, cancelButtonColor, modalColor){
            var modal = openModal({
              modal: {
                dismissable: true,
                title: title,
                html: message,
                buttons: [{
                  classes: confirmButtonColor || 'btn-danger',
                  text: confirmButton,
                  click: function(e) {
                    cb();
                    modal.dismiss(e);
                  }
                }, {
                  classes: cancelButtonColor || 'btn-default',
                  text: cancelButton,
                  click: function(e) {
                    modal.dismiss(e);
                  }
                }]
              }
            }, modalColor || 'modal-danger');
        },
        showWithOutCancelBtn: function (title, message, confirmButton, cb, modalSettings, confirmButtonColor, modalColor) {
          var modal = openModal({
            modal: {
              dismissable: modalSettings.dismissable || false,
              title: title,
              html: message,
              buttons: [{
                classes: confirmButtonColor || 'btn-danger',
                text: confirmButton,
                click: function (e) {
                  cb();
                  modal.dismiss(e);
                }
              }],
            }
          }, modalColor || 'modal-danger', modalSettings.size, modalSettings.keyboard, modalSettings.autoClose);
        },
        showWithCancelCB: function(title, message, confirmButton, cancelButton, cb, confirmButtonColor, cancelButtonColor, modalColor){
          var modal = openModal({
            modal: {
              dismissable: true,
              title: title,
              html: message,
              buttons: [{
                classes: confirmButtonColor || 'btn-danger',
                text: confirmButton,
                click: function(e) {
                  cb(true);
                  modal.dismiss(e);
                }
              }, {
                classes: cancelButtonColor || 'btn-default',
                text: cancelButton,
                click: function(e) {
                  cb(false);
                  modal.dismiss(e);
                }
              }]
            }
          }, modalColor || 'modal-danger');
        },
        /**
         * Create a function to open a delete confirmation modal (ex. ng-click='myModalFn(name, arg1, arg2...)')
         * @param  {Function} del - callback, ran when delete is confirmed
         * @return {Function}     - the function to open the modal (ex. myModalFn)
         */
        delete: function(del) {
          del = del || angular.noop;

          /**
           * Open a delete confirmation modal
           * @param  {String} name   - name or info to show on modal
           * @param  {All}           - any additional args are passed straight to del callback
           */
          return function() {
            var args = Array.prototype.slice.call(arguments),
                name = args.shift(),
                deleteModal;

            deleteModal = openModal({
              modal: {
                dismissable: true,
                title: 'Confirm Delete',
                html: '<p>Are you sure you want to delete <strong>' + name + '</strong> ?</p>',
                buttons: [{
                  classes: 'btn-danger',
                  text: 'Delete',
                  click: function(e) {
                    deleteModal.close(e);
                  }
                }, {
                  classes: 'btn-default',
                  text: 'Cancel',
                  click: function(e) {
                    deleteModal.dismiss(e);
                  }
                }]
              }
            }, 'modal-danger');

            deleteModal.result.then(function(event) {
              del.apply(event, args);
            });
          };
        }
      },
      info: {
        comingSoon: function(finish) {
          finish = finish || angular.noop;
          return function () {
            var args = Array.prototype.slice.call(arguments),
                feature = args.shift(),
                cls  = args.shift(),
                infoModal;

            infoModal = openModal({
              modal: {
                dismissable: true,
                title: feature||'Information',
                html: '<p>This feature'+(!!feature ? ' ('+feature+')': '')+' is still in development.</p>',
                buttons: [{
                  classes: 'btn-default',
                  text: 'Close',
                  click: function(e) {
                    infoModal.dismiss(e);
                  }
                }]
              }
            }, cls||'modal-danger');

            infoModal.result.then(function(event) {
              finish.call(event);
            });
          };
        },
        previewUpload: function (finish) {
          finish = finish || angular.noop;
          return function (client, uploadList, startingIndex) {

            var
            // downloadUrl = '/api/client-uploads/'+(client._id||client)+'/'+upload[startingIndex]._id + '?access_token='+$authStorage.getToken(),
            scratchPad = {
            },
            modalWin = openModal({
              modal: {
                dismissable: true,
                title: 'Attachments', //upload,name
                template: 'components/modal/tpl/preview-upload.html',
                // buttons: [{
                //   classes: 'btn-primary',
                //   text: 'Download'
                // }]
              },
              controller: ['$scope', '$timeout', '$authStorage', '$attachment', '$sce', 'appConfig', function ($scope, $timeout, $authStorage, $attachment, $sce, appConfig) {
                $scope.isViewLoading = false;
                $scope.isImageLoading = false;
                if(uploadList.length < 2) {
                  $scope.hasMultipleAttachments = false;
                } else {
                  $scope.hasMultipleAttachments = true;
                }

                $scope.currentIndex = startingIndex;
                $scope.next = function() {
                  $timeout(function() {
                    $scope.currentIndex = ($scope.currentIndex + 1) % uploadList.length;
                  }, 50);
                };

                $scope.previous = function() {
                  $timeout(function() {
                    if($scope.currentIndex - 1 === -1) {
                      $scope.currentIndex = uploadList.length - 1;
                    }
                    else {
                      $scope.currentIndex--;
                    }
                  }, 50);
                };

                $scope.isFirst = function(){
                  return $scope.currentIndex === 0;
                };

                $scope.isLast = function(){
                  return $scope.currentIndex === (uploadList.length - 1);
                };

                $scope.$watch(function() { return $scope.currentIndex; }, function(newValue) {
                  var newUpload = uploadList[newValue];
                  $scope.upload = newUpload;
                  $scope.uploadName = newUpload.name;
                  $scope.previewUrl = null;
                  $scope.isImageLoading = true;
                  // $scope.downloadUrl  = '/attachment/'+(client._id||client)+'/'+newUpload._id;
                  $attachment.download((client._id||client), newUpload._id)
                    .then((res) => {
                      if (res && res.status && res.url) {
                        $scope.downloadUrl  = $sce.trustAsResourceUrl(res.url);
                        if (['image/jpeg', 'image/png', 'image/gif'].indexOf(newUpload.mimeType) >= 0 ) {
                          $scope.previewUrl = $scope.downloadUrl;
                        } else {
                          $scope.previewUrl = appConfig.apiUrl + '/api/client-uploads/' + (client._id || client) + '/' + newUpload._id + '?preview=1&access_token=' + $authStorage.getToken();
                        }
                      }
                    })
                    .catch((err) => {
                      console.log(err)
                    })
                    .finally(() => {
                      $scope.isImageLoading = false;
                    });

                  $scope.pdfUrl = appConfig.apiUrl + '/api/client-uploads/'+(client._id||client)+'/'+newUpload._id + '?preview=1&access_token='+$authStorage.getToken();
                  $scope.scratchPad = scratchPad;
                  // $scope.previewUrl = $scope.downloadUrl+'?preview=0';
                  $scope.description = newUpload.description || null;
                  $scope.title = newUpload.title || null;

                  if(newUpload.mimeType.indexOf('video') > -1) {
                    $scope.type='video';
                  }
                  else if(newUpload.mimeType.indexOf('audio') > -1)  {
                    $scope.type = 'audio';
                  }
                  else if(newUpload.mimeType.indexOf('text/csv') > -1)  {
                    $scope.type = 'text/csv';
                  }
                  else if(newUpload.mimeType.indexOf('application/pdf') > -1)  {
                    $scope.type = 'application/pdf';
                  }
                  else {
                    $scope.type = newUpload.mimeType;
                  }
                });

                $scope.buttonClick = function () {
                  // $window.location = $scope.downloadUrl;
                  window.open($scope.downloadUrl, '_blank');
                  // modalWin.dismiss();
                };
                $scope.onView = function () {
                  $scope.isViewLoading = true;
                  $attachment.download((client._id || client), $scope.upload._id, true)
                    .then((res) => {
                      if (res && res.status && res.url) {
                        window.open(res.url, '_blank');
                        // modalWin.dismiss();
                      }
                    })
                    .catch((err) => {
                      console.log(err)
                    })
                    .finally(() => {
                      $scope.isViewLoading = false;
                    })
                };

                $scope.isViewBtnShow = function () {
                  let viewSupportedType = ['image/jpeg', 'image/png', 'image/gif', 'text/xml', 'application/pdf', 'video', 'audio'];
                  if (viewSupportedType.indexOf($scope.type) >= 0) return true;
                  return false;
                }
              }]
            }, 'modal-info');

            modalWin.result.then(function (event) {
              finish.call(event, scratchPad);
            });
          };
        },
        showPreTextContent: function (title, content, finish) {

          finish = finish || angular.noop;
          return function () {
            var scratchPad = {
              isEnabled: false,
              content: content
            };
            var modal = openModal({
              controller: ['$scope', function ($scope) {
                $scope.scratchPad = scratchPad;
              }],
              modal: {
                dismissable: true,
                title:title,
                template: 'components/modal/tpl/pre-text-template.html',
                buttons: [{
                  classes: 'btn-default',
                  text: 'Close',
                  click: function (e) {
                    modal.close(e);
                  }
                }]
              }
            }, 'modal-success', 'lg');
          };
        },
      },
      select: {
        taskCollection: function (finish, opts) {
          finish = finish || angular.noop;
          opts = opts || {};

          function objectId(o) {
            if(!o) {
              return false;
            }
            return o._id || o;
          }


          return function (task, title) {
            if(!task) {
              return;
            }

            var
            scratchPad = {
              collection: null,
              type:       null
            },
            modal = openModal({
              controller: ['$scope','$clientSwitcher','$clientTask', function ($scope, $clientSwitcher, $clientTask) {

                $scope.scratchPad = scratchPad;

                function findCollectionIndex(collection) {
                  var found = -1, id = objectId(collection);
                  if(!$scope.collections || !id) {
                    return found;
                  }
                  $scope.collections.every(function (col, index) {
                    if(col.value === id) {
                      found = index;
                    }

                    return found === -1;
                  });

                  return found;
                }

                function findTypeIndex(collection, type) {
                  var found = -1;
                  if(!collection || !collection.types) {
                    return found;
                  }
                  collection.types.every(function(t, index) {
                    if(t.value === type) {
                      found = index;
                    }

                    return found === -1;
                  });
                  return found;
                }

                $scope.reloadCollections = function () {

                  delete $scope.collections;

                  return $clientTask.getCollections($clientSwitcher.getCurrentClientId(), true)
                    .then(function (collections) {
                      $scope.collections = collections.map(function(collection) {
                        return {
                          label: collection.name,
                          value: collection._id,
                          types: collection.types.map(function(type) {
                            return {
                              label: type,
                              value: type
                            };
                          })
                        };
                      });
                      return collections;
                    });
                };

                $scope.collectionSelected = function() {
                  var collection = $scope.collections[findCollectionIndex(scratchPad.collection)];
                  $scope.types = collection.types;
                };

                $scope.resetFromTask = function () {
                  if(!$scope.collections) {
                    return;
                  }
                  var
                  collection = $scope.collections[findCollectionIndex(task.taskCollection)],
                  typeIndex  = findTypeIndex(collection, task.taskType);

                  if(!collection) {
                    return;
                  }
                  scratchPad.collection = collection.value;
                  $scope.types = collection.types;

                  scratchPad.type       = collection.types[typeIndex].value;
                };

                // pull current value from task
                $scope.reloadCollections().then($scope.resetFromTask);
              }],
              modal: {
                dismissable: true,
                title: title||'Select Task Bucket',
                template: 'components/modal/tpl/bucket-select.html',
                buttons: [{
                  classes: 'btn-danger',
                  text: 'Cancel',
                  click: function() {
                    modal.dismiss();
                  }
                }, {
                  classes: 'btn-primary',
                  text: 'Select',
                  click: function(e) {
                    $clientTask.updateCollection(objectId(task), objectId(scratchPad.collection), scratchPad.type)
                    .then(function () {
                      return modal.close(scratchPad);
                    })
                    .catch(function (err) {
                      scratchPad.updateErrors = err;
                      return err;
                    });
                  }
                }]
              }
            }, 'modal-success');

            modal.result.then(finish);
          };
        }
      },
      taskActions: {

        createTask: function (finish, title) {
          finish = finish || angular.noop;

          return function (client, bucket) {
            var
            scratchPad = {
              task: {
              }
            };

            if(bucket) {
              scratchPad.task.taskCollection = bucket.id;
            }

            var
            modal = openModal({
              controller: ['$scope', '$clientTask', '$q', function ($scope, $clientTask, $q) {
                $scope.newTask = angular.copy(scratchPad.task);
                $scope.isSubmitting = false;

                $scope.uploadFiles = function () {

                  var
                  task = $scope.newTask,
                  uploader = !!task ? task.uploader : false,
                  results = [],
                  errors = [];

                  if(uploader && uploader.queue && uploader.queue.length) {
                    var defer = $q.defer();

                    uploader.onSuccessItem = function(fileItem, response, status, headers) {
                      Array.prototype.push.apply(results, response);
                    };

                    uploader.onErrorItem = function(fileItem, response, status, headers) {
                      errors.push(new Error('Failed to upload file: ' + fileItem.file.name));
                    };

                    uploader.onCompleteAll = function() {
                      if(errors.length > 0) { // fail on any errors
                        defer.reject(errors);
                        return;
                      }

                      defer.resolve(results);
                    };

                    uploader.uploadAll();

                    return defer.promise;
                  }

                  return $q.when(false);
                };

                $scope.readTask = function () {
                  var task = $scope.newTask;
                  return Object.keys(task)
                    .filter(function (key) { // filter out certain keys
                      // return ['uploader'].indexOf(key) === -1;
                      return key !== 'uploader';
                    })
                    .reduce(function (p, key) {
                      p[key] = task[key];
                      return p;
                    }, {});
                };

                $scope.submitTask = function () {
                  $scope.formError = null;

                  if($scope.newTask.onExpireDue && $scope.newTask.due > $scope.newTask.onExpireDue){
                    $scope.message = 'Please set the fallback due date and time ahead of the primary due date and time.';
                    return;
                  }
                  $scope.message = '';
                  $scope.isSubmitting = true;

                  return $scope.uploadFiles()
                    .then(function (uploaded) {
                      if(uploaded && uploaded.length) { // add these to the model so when it creates the task, it will associate these items.
                        $scope.newTask.uploads = uploaded.map(function (upload) {
                          return upload._id;
                        });
                      }

                      return $clientTask.createTask(client, $scope.readTask()) // prevents uploader from going over the wire.
                        .then(function (result) {
                          scratchPad.task = $scope.newTask;
                          modal.close(scratchPad);
                          return result;
                        });
                    })
                    .catch(function (err) {
                      console.log('error:', err.stack || err);
                      $scope.formError = err;
                    })
                    .finally(function () {
                      $scope.isSubmitting = false;
                    });
                };
              }],
              modal: {
                dismissable: true,
                title: title||'Create Task',
                template: 'components/modal/tpl/task-create.html'
              }
            }, 'modal-success');

            modal.result.then(function(event) {
              finish.call(event, scratchPad);
            });
          };
        },

        addAttachments: function(finish) {
          finish = finish || angular.noop;

          return function (task, droppedFiles) {
            if(!task) {
              return;
            }
            let uploadResult = [];
            var
            callModal = openModal({

              controller: ['$scope', '$clientSwitcher', '$clientTask', '$authStorage', '$q', 'ngToast', '$uploadS3Attachment', function ($scope, $clientSwitcher, $clientTask, $authStorage, $q, ngToast, $uploadS3Attachment) {

                $scope.currentClient = $clientSwitcher.getCurrentClient();
                task.uploader = [];
                // task.uploader = new FileUploader({
                //   url: '/api/client-uploads/'+$scope.currentClient._id+'?access_token='+$authStorage.getToken(),
                //   alias: 'upload',
                //   filters: [
                //     {
                //       name: 'extensionFilter',
                //       fn: function(item) {
                //         var extension = item.name.substr(item.name.lastIndexOf('.')+1);
                //         var invalidExtensions = ['exe', 'bin'];
                //         if(invalidExtensions.indexOf(extension) > -1) {
                //           ngToast.create({
                //             className: 'danger',
                //             content: 'Files with extensions '+invalidExtensions+' are not allowed'
                //           });
                //           return false;
                //         } else {
                //           return true;
                //         }
                //       }
                //     }
                //   ]
                // });
                if(droppedFiles && droppedFiles.length) {
                  task.uploader.addToQueue(droppedFiles);
                }

                // $scope.uploadFiles = function () {
                //   var
                //   uploader = !!task ? task.uploader : false,
                //   results = [],
                //   errors = [];

                //   if(uploader && uploader.queue && uploader.queue.length) {
                //     var defer = $q.defer();

                //     uploader.onSuccessItem = function(fileItem, response, status, headers) {
                //       Array.prototype.push.apply(results, response);
                //     };

                //     uploader.onErrorItem = function(fileItem, response, status, headers) {
                //       errors.push(new Error('Failed to upload file: ' + fileItem.file.name));
                //     };

                //     uploader.onCompleteAll = function() {
                //       if(errors.length > 0) { // fail on any errors
                //         defer.reject(errors);
                //         return;
                //       }

                //       defer.resolve(results);
                //     };

                //     uploader.uploadAll();

                //     return defer.promise;
                //   }
                // };

                $scope.task = task;

                $scope.buttonClick = function(buttonText) {
                  if (task.uploader && task.uploader.queue && task.uploader.queue.length) {
                    $uploadS3Attachment.upload($scope.currentClient, task.uploader, $scope, 'control1')
                      .then((imageRes) => {
                        task.uploads = [];
                        if (imageRes && imageRes.length) {
                          imageRes.forEach(function (upload) {
                            if (upload.status) {
                              task.uploads.push(upload.clientUpload._id);
                            }
                          });
                          $clientTask.updateAttachments(_.omit(task, ['uploader', 'bucket']))
                            .then(function (result) {
                              callModal.close();
                              // $rootScope.$broadcast('taskUpdated');
                              uploadResult = result;
                              finish.call(this);
                            })
                            .catch(function (err) {
                              console.log(err);
                              uploadResult = err;
                              //incomplete
                              ngToast.create({
                                content: err.message || err,
                                className: 'danger'
                              });
                            });
                        }
                      })
                  } else {
                    ngToast.create({
                      content: 'Please select file',
                      className: 'danger'
                    });
                  }
                };
              }],

              modal: {
                dismissable: false,
                title: 'Add Attachments',
                template: 'components/modal/tpl/add-attachments.html',

                buttons: [{
                  classes: 'btn-primary',
                  text: 'Add',
                },{
                  classes: 'btn-default',
                  text: 'Close',
                  click: function(e) {
                    callModal.close(e);
                  }
                }]
              }
            }, 'modal-success');

            callModal.result.then(function(event) {
              finish.call(event, task, uploadResult);
            });
          };
        },

        forwardTaskEmail: function(finish) {
          finish = finish || angular.noop;

          var scratchPad = {
            forwardTo: null,
            forwardToEmail: null
          };

          return function (task) {
            var
            callModal = openModal({
              controller: ['$scope', '$clientSwitcher', '$clientExternalUsers', '$clientUser', function ($scope, $clientSwitcher, $clientExternalUsers, $clientUser) {
                $scope.model = scratchPad;
                $scope.task = task;

                $scope.data = {};

                var init = function() {
                  var client = $clientSwitcher.getCurrentClient();
                  return $clientUser.getUserChainOfCommand(client, task.location._id, task.bucket.id)
                    .then(function (chainOfCommand) {
                      $scope.data.users = chainOfCommand;
                      return $scope.data.users;
                    });
                };
                init();

              }],
              modal: {
                dismissable: true,
                title: 'Forward Task Email',
                template: 'components/modal/tpl/forward-task-email.html',
                buttons: [{
                  classes: 'btn-primary',
                  text: 'Forward',
                  click: function(e) {
                    callModal.close(e);
                  }
                },{
                  classes: 'btn-default',
                  text: 'Close',
                  click: function(e) {
                    callModal.dismiss(e);
                  }
                }]
              }
            }, 'modal-success');

            callModal.result.then(function(event) {
              finish(scratchPad);
            });
          };
        },
        disputeWorkOrder: function (record, finish) {
          finish = finish || angular.noop;

          return function () {
            var scratchPad = {
              error: null,
              success: null
            };
            var modal = openModal({
              controller: ['$scope', '$clientSwitcher', '$clientTask', function ($scope, $clientSwitcher, $clientTask) {
                var client = $clientSwitcher.getCurrentClient();
                $scope.model = {
                  comment: ''
                };

                $scope.submit = function (e) {
                  const data = {
                    taskId: record.taskId,
                    caseDataId: record.caseDataId,
                    extra: {
                      comment: $scope.model.comment
                    },
                    disputeType: record.TYPE
                  };

                  if(record.currentFlagEventData){
                    data.disputeFlagEvent = record.currentFlagEventData;
                  }

                  $clientTask.disputeEvent(client, data)
                    .then(res => {
                      finish.call(e, {
                        status: true,
                        data: res
                      });
                      modal.close(e);
                    });
                };

                $scope.onClose = function (e) {
                  finish.call(e, {
                    status: false
                  });
                  modal.close(e);
                }
              }],
              modal: {
                dismissable: false,
                title: 'Dispute Time/ Rate',
                template: 'components/modal/tpl/dispute-work-order.html'
              }
            }, 'modal-success', null, true, true);
          };
        },
        icReleaseRequestWO: function (record, finish) {
          finish = finish || angular.noop;

          return function () {
            var scratchPad = {
              error: null,
              success: null
            };
            var modal = openModal({
              controller: ['$scope', '$clientSwitcher', '$clientTask', function ($scope, $clientSwitcher, $clientTask) {
                var client = $clientSwitcher.getCurrentClient();
                $scope.type = record.type;
                $scope.model = {
                  comment: ''
                };

                $scope.submit = function (e) {
                  const data = {
                    caseDataId: record.caseDataId,
                    extra: {
                      comment: $scope.model.comment
                    },
                    type: record.type,
                    taskId: record.taskId,
                    activity: record.activity
                  };

                  $clientTask.icReleaseRequestEvent(client, data)
                    .then(res => {
                      finish.call(e, {
                        status: true,
                        data: res
                      });
                      modal.close(e);
                    });
                };

                $scope.onClose = function (e) {
                  finish.call(e, {
                    status: false
                  });
                  modal.close(e);
                }

              }],
              modal: {
                dismissable: true,
                title: record.type == 'DENY_RELEASE' ? 'Deny Release' : 'Release',
                template: 'components/modal/tpl/ic-release-request.html'
              }
            }, 'modal-success');
          };
        },
        assignInterpreterInWO: function (task, finish) {
          finish = finish || angular.noop;
          return function () {
            var scratchPad = {
              isLoading: false,
              error: null,
              comment: null,
              interpreter: [],
              interpreterApplied: null
            };
            const modal = openModal({
              controller: ['$scope', '$clientSwitcher', 'ngToast', '$q', '$clientInterpreterResources', '$clientTask', '$clientInterpreter', '$openWorkOrders', function ($scope, $clientSwitcher, ngToast, $q, $clientInterpreterResources, $clientTask, $clientInterpreter, $openWorkOrders) {
                const client = $clientSwitcher.getCurrentClient();
                $scope.scratchPad = scratchPad;
                $scope.model = task;
                $scope.allValue = 'all';
                $scope.pageLimitForDD = 20;
                $scope.interpretersSets = [];
                $scope.interpreterData = [];
                $scope.interpreterDataCount = 0;
                $scope.appliedInterpreters = []
                $scope.isAppliedView =  $scope.model.status == "Applied";
                if ($scope.isAppliedView) {
                  $scope.scratchPad.interpreterType = 'interpreterApllied'
                } else {
                  $scope.scratchPad.interpreterType = 'allInterpreters'
                }
                $scope.reloadInterpreters = function (filter) {
                  if (!$scope.readOnly) {
                    if (!filter) {
                      $scope.interpreterData = [];
                      filter = {
                        page: 1,
                        pageSize: $scope.pageLimitForDD,
                        searchKey: null,
                      }
                    }
                    filter.isActive = true;
                    if (task.caseData) {
                      filter.lang = task.caseDetail.langcode;
                      filter.replocid = task.caseData.replocid;
                      filter.selectedIc = parseInt(task.caseData.vendorId) ? [parseInt(task.caseData.vendorId)] : null;
                    }
                    filter.isDistance = true;
                    $scope.scratchPad.isLoading = true;
                    $clientInterpreter.getAllInterpreters(client, filter)
                      .then((res) => {
                        if (res.status && res.records && res.records.length) {
                          $scope.interpreterData = ($scope.interpreterData && $scope.interpreterData.length) ?
                            _.uniq($scope.interpreterData.concat(res.records), 'value')
                            : res.records;

                          $scope.interpreterDataCount = res.totalCount ; //$scope.interpreterDataCount == 0 ? res.totalCount : ($scope.interpreterDataCount != res.totalCount) ? $scope.interpreterDataCount : res.totalCount;
                          if (task && task.caseData && task.caseData.vendorId) {
                            $scope.scratchPad.interpreter = task.caseData.vendorId;
                            if (res.status && res.selectedInterpreter && res.selectedInterpreter.length) {
                              let isExistVendorIdIndex = res.records.findIndex((obj) => obj.vendorid == task.caseData.vendorId);
                              if (isExistVendorIdIndex >= 0) {
                                setTimeout(() => {
                                  $scope.scratchPad.interpreter = $scope.interpreterData[isExistVendorIdIndex].value;
                                  $scope.interpreterData.slice(isExistVendorIdIndex, 1);
                                  $scope.interpreterData.unshift(res.selectedInterpreter[0]);
                                  $scope.interpreterData = _.uniq($scope.interpreterData, 'value');
                                }, 10);
                              } else {
                                if (res.selectedInterpreter && res.selectedInterpreter.length) {
                                  let isExistVendorIdInIntArrIndex = $scope.interpreterData.findIndex((obj) => obj.vendorid == task.caseData.vendorId);
                                  if (isExistVendorIdInIntArrIndex < 0) {
                                    $scope.interpreterData.unshift(res.selectedInterpreter[0]);
                                  }
                                  if (filter.searchKey) $scope.interpreterDataCount = $scope.interpreterDataCount + 1;
                                }
                              }
                            } else {
                              let isExistVendorId = $scope.interpreterData.findIndex((obj) => obj.vendorid == task.caseData.vendorId);
                              if (isExistVendorId < 0) {
                                let interpreter = {
                                  vendorid: task.caseData.vendorId,
                                  label: `${task.caseData.interprerName} | ${task.caseData.vendorId}`,
                                  value: task.caseData.vendorId,
                                  zipcode: ''
                                }
                                $scope.interpreterData.unshift(interpreter);
                                $scope.interpreterDataCount = $scope.interpreterDataCount + 1;
                              }
                            }
                          }
                        } else {
                          ngToast.create({
                            content: 'Interpreter Not Found.',
                            className: 'danger'
                          });
                          $scope.interpreterDataCount = 0;
                        }
                      })
                      .catch((err) => {
                        let errMessage = err.message || err;
                        if (err && err.data && err.data.message) {
                          errMessage = err.data.message;
                        } else if (err && err.data && err.data.reason) {
                          errMessage = err.data.reason;
                        }
                        console.log(errMessage);
                        ngToast.create({
                          content: errMessage,
                          className: 'danger'
                        });
                        $scope.interpreterDataCount = 0;
                      })
                      .finally(function () {
                        $scope.ddLoading = false;
                        $scope.scratchPad.isLoading = false;
                      })
                  }
                }

                $scope.loadPaginateData = function (searchKey, page, type) {
                  $scope.ddLoading = true;
                  let filter = {
                    page: page,
                    pageSize: $scope.pageLimitForDD
                  }
                  switch (type) {
                    case 'interpreters':
                      if (page == 1) {
                        $scope.interpreterData = [];
                      }
                      filter.searchKey = (searchKey).toLowerCase();
                      $scope.loadRTWLangInterpreter(filter);
                      break;
                    default:
                      break;
                  }
                }

                let selectedItem = null;
                $scope.loadRTWLangInterpreter = (filter) => {
                  if (!filter) {
                    $scope.interpreterData = [];
                    filter = {
                      page: 1,
                      pageSize: $scope.pageLimitForDD,
                      "searchKey": "",
                    }
                  }
                  filter.isActive = true;
                  filter.isDistance = false;
                  if (task.caseDetail) {
                    filter.lang = task.caseDetail.langcode;
                    filter.court = task.caseDetail.replocid;
                    filter.coi = task.caseDetail.coi;
                    filter.langName = task.caseDetail.langname;
                    filter.serviceType = task.caseDetail.sertype;
                    filter.selectedIc = parseInt(task.caseData.vendorId) ? [parseInt(task.caseData.vendorId)] : null;
                  }
                  // filter.isActive = true;
                  // filter.lang = task.caseDetail.langcode;
                  // filter.replocid = task.caseData.replocid;

                  // filter.langName = $scope.scratchPad.wo.langname;
                  // filter.serviceType = $scope.scratchPad.wo.sertype;
                  $scope.scratchPad.isLoading = true;
                  $scope.ddLoading = true
                  $clientInterpreter.getRTWLangInterpreter(client, filter).then(res => {
                    if (res && res.status) {
                      let interpreterList = res.result || [];
                      if (res.selectedInterpreter && res.selectedInterpreter.vendorid && filter.page == 1) {
                        selectedItem = {
                          label: res.selectedInterpreter && res.selectedInterpreter.distance ? `${res.selectedInterpreter.label} | ${res.selectedInterpreter.distance} Miles` : res.selectedInterpreter.label,
                          value: res.selectedInterpreter.vendorid
                        }
                        $scope.scratchPad.interpreter = res.selectedInterpreter.vendorid;
                      }
                      if (filter.page > 1) {
                        let interpreterData = interpreterList.map(item => {
                          return {
                            label: item.distances && item.distances.distance ? `${item.label} | ${item.distances.distance} Miles` : item.label,
                            value: item.vendorid
                          }
                        });
                        if (selectedItem && selectedItem.value) {
                          let icIndex = interpreterData.findIndex(item => item.vendorid == selectedItem.value);
                          if (icIndex >= 0) {
                            interpreterData.splice(icIndex, 1)
                          }
                        }
                        $scope.interpreterData = $scope.interpreterData.concat(interpreterData)
                      } else {
                        $scope.interpreterDataCount = res.total;
                        if (selectedItem) {
                          let icIndex = interpreterList.findIndex(item => item.vendorid == selectedItem.value);
                          $scope.scratchPad.interpreter =[selectedItem.value];
                          if (0 > icIndex && res.selectedInterpreter) {
                            $scope.interpreterData = [];
                            interpreterList.unshift(res.selectedInterpreter)
                          }
                        }
                        $scope.interpreterData = interpreterList.map(item => {
                          if (item) {
                            return {
                              label: item.distances && item.distances.distance ? `${item.label} | ${item.distances.distance} Miles` : item.label,
                              value: item.vendorid
                            }
                          }
                        });
                      }
                    }
                    $scope.scratchPad.isLoading = false;
                  })
                    .catch(err => {
                      console.log(err)
                      $scope.scratchPad.isLoading = false;
                    })
                    .finally(() => {
                      $scope.scratchPad.isLoading = false;
                      $scope.ddLoading = false
                    })
                }

                $scope.resetAllowedFilter = () => {
                  $scope.scratchPad.interpreterApplied = null;
                   $scope.scratchPad.interpreter = null;
                }

                $scope.loadAppliedInterpreter = function() {
                  if ($scope.scratchPad.isAppliedListLoading ) return;
                  $scope.scratchPad.isAppliedListLoading = true;
                  $clientTask.getTaskAppliedInterpreter(client, $scope.model.importedRecordId)
                  .then((res) => {
                      if (res.status && res.result && res.result.length ) {
                        $scope.appliedInterpreters = res.result
                      }
                  })
                  .catch((err) => {
                    console.log(err)
                  })
                  .finally(() => {
                    $scope.scratchPad.isAppliedListLoading = false
                  })

                }
                function init() {
                  if ($scope.model.status == 'Applied') {
                    $scope.loadAppliedInterpreter()
                  }

                  $scope.loadRTWLangInterpreter();
                }
                init();


                $scope.onAppliedIcChange = function(name, isApplied, e) {
                  console.log(name, e)
                  if (e) {
                    e.preventDefault();
                    e.stopPropagation()
                    if(isApplied){
                      $scope.scratchPad.interpreter = null;
                    } else {
                      $scope.scratchPad.interpreterApplied = null;
                    }
                    $scope.icAppliedEvent = null
                    $scope.icEvent = null
                  }
                }
                $scope.buttonClick = function (e, status, OpenWOStatus) {
                  let selectedId = null;
                  if ($scope.scratchPad.interpreterType == 'interpreterApllied') {
                   selectedId = $scope.scratchPad.interpreterApplied ;
                  } else {
                    selectedId =  $scope.scratchPad.interpreter;
                    if ($scope.scratchPad.interpreter && Array.isArray($scope.scratchPad.interpreter) && $scope.scratchPad.interpreter.length) {
                      selectedId =  $scope.scratchPad.interpreter[0];
                    }
                  }
                  if (!selectedId) {
                    let errMessage = 'Please select the interpreter';
                    e.preventDefault()
                    e.stopPropagation()
                    ngToast.create({
                      content: errMessage,
                      className: 'danger'
                    });
                    return errMessage;
                  }

                  $scope.scratchPad.isLoading = true;
                  let data = {
                    // interpreter: $scope.scratchPad.interpreter,
                    // coi: task.caseData.coi,
                    // status
                    taskId: task._id || task.id,
                    coi : task.caseData.coi,
                    status: OpenWOStatus,
                    vendorId: selectedId
                  };
                  let method = null;
                  if (OpenWOStatus == 'ASSIGN') {
                    method = $openWorkOrders.openWOAssignInterpreter(client, data);
                  } else if (OpenWOStatus == 'CONFIRM') {
                    method = $openWorkOrders.openWOConfirmInterpreter (client, data);
                  }
                  // $clientTask.assignInterpreter(client, data)
                  method.then((res) => {
                      if (res && res.status) {
                        ngToast.create({
                          content: res.message || res,
                          className: 'success'
                        });
                        finish.call(e, res);
                        modal.close(e);
                      } else {
                        $scope.scratchPad.isLoading = false;
                        ngToast.create({
                          content: res.message || res,
                          className: 'danger'
                        });
                      }
                    })
                    .catch(err => {
                      let errMessage = err.message || err;
                      if (err && err.data && err.data.message) {
                        errMessage = err.data.message;
                      } else if (err && err.data && err.data.reason) {
                        errMessage = err.data.reason;
                      }
                      ngToast.create({
                        content: errMessage,
                        className: 'danger'
                      });
                      $scope.scratchPad.isLoading = false;
                    })
                }

                $scope.close = function (e) {
                  finish.call(e, false);
                  modal.close(e);
                }
              }],
              modal: {
                dismissable: true,
                title: 'Assign / Confirm Interpreter',
                template: 'components/modal/tpl/assign-interpreter.html'
              }
            }, 'modal-success');
          }
        },
        updateWOStatus: function (task, finish) {
          finish = finish || angular.noop;
          return function () {
            var scratchPad = {
              isLoading: false,
              error: null,
              comment: null,
              interpreter: null
            };
            const modal = openModal({
              controller: ['$scope', '$clientSwitcher', 'ngToast', '$q', '$clientInterpreterResources', '$clientTask', '$clientInterpreter', '$woStatus', function ($scope, $clientSwitcher, ngToast, $q, $clientInterpreterResources, $clientTask, $clientInterpreter, $woStatus) {
                const client = $clientSwitcher.getCurrentClient();
                $scope.scratchPad = scratchPad;
                $scope.model = task;
                $scope.allValue = 'all';
                $scope.pageLimitForDD = 20;
                $scope.interpretersSets = [];
                $scope.interpreterData = [];
                $scope.interpreterDataCount = 0;
                $scope.woStatus = $woStatus.map((obj) => {
                  obj.showIf = function () {
                    if (task && task.taskState) {
                      var isVisible = obj.allowStatus.indexOf(task.taskState) > -1;
                      return isVisible;
                    } else {
                      return false;
                    }
                  }
                  return obj;
                });

                $scope.showInterpreter = function () {
                  if (['Locating', 'Assigned', 'Confirmed'].indexOf($scope.scratchPad.status) > -1) {
                    if ($scope.scratchPad.status == 'Locating') {
                      if (task.caseDetail.vendorid) return true
                      else return false;
                    }
                    return true;
                  }
                  return false;
                }

                $scope.strSettings = function (str) {
                  return {
                    selectedStr: ' ',
                    noSelection: 'No ' + str + ' Selected',
                    allLabel: 'All ' + str
                  };
                };

                $scope.reloadInterpreters = function (filter) {
                  if (!$scope.readOnly) {
                    if (!filter) {
                      $scope.interpreterData = [];
                      filter = {
                        page: 1,
                        pageSize: $scope.pageLimitForDD,
                        searchKey: null,
                      }
                    }
                    filter.isActive = true;
                    if ( task.caseDetail) {
                      filter.lang = task.caseDetail.langcode;
                      filter.replocid = task.caseData.replocid;
                      filter.selectedIc = [parseInt(task.caseData.vendorId)];
                    }
                    filter.isDistance = false;
                    $scope.scratchPad.isLoading = true;
                    $clientInterpreter.getAllInterpreters(client, filter)
                      .then((res) => {
                        if (res.status && res.records && res.records.length) {
                          $scope.interpreterData = ($scope.interpreterData && $scope.interpreterData.length) ?
                            _.uniq($scope.interpreterData.concat(res.records), 'value')
                            : res.records;

                          $scope.interpreterDataCount = $scope.interpreterDataCount == 0 ? res.totalCount : ($scope.interpreterDataCount != res.totalCount) ? $scope.interpreterDataCount : res.totalCount;
                          if (task && task.caseData && task.caseData.vendorId) {
                            $scope.scratchPad.interpreter = task.caseData.vendorId;
                            if (res.status && res.selectedInterpreter && res.selectedInterpreter.length) {
                              let isExistVendorIdIndex = res.records.findIndex((obj) => obj.vendorid == task.caseData.vendorId);
                              if (isExistVendorIdIndex >= 0) {
                                setTimeout(() => {
                                  $scope.scratchPad.interpreter = $scope.interpreterData[isExistVendorIdIndex].value;
                                  $scope.interpreterData.slice(isExistVendorIdIndex, 1);
                                  $scope.interpreterData.unshift(res.selectedInterpreter[0]);
                                  $scope.interpreterData = _.uniq($scope.interpreterData, 'value');
                                }, 10);
                              } else {
                                if (res.selectedInterpreter && res.selectedInterpreter.length) {
                                  let isExistVendorIdInIntArrIndex = $scope.interpreterData.findIndex((obj) => obj.vendorid == task.caseData.vendorId);
                                  if (isExistVendorIdInIntArrIndex < 0) {
                                    $scope.interpreterData.unshift(res.selectedInterpreter[0]);
                                  }
                                  if (filter.searchKey) $scope.interpreterDataCount = $scope.interpreterDataCount + 1;
                                }
                              }
                            } else {
                              let isExistVendorId = $scope.interpreterData.findIndex((obj) => obj.vendorid == task.caseData.vendorId);
                              if (isExistVendorId < 0) {
                                let interpreter = {
                                  vendorid: task.caseData.vendorId,
                                  label: `${task.caseData.interprerName} | ${task.caseData.vendorId}`,
                                  value: task.caseData.vendorId,
                                  zipcode: ''
                                }
                                $scope.interpreterData.unshift(interpreter);
                                $scope.interpreterDataCount = $scope.interpreterDataCount + 1;
                              }
                            }
                          }

                        } else {
                          // ngToast.create({
                          //   content: 'Interpreter Not Found.',
                          //   className: 'danger'
                          // });
                          $scope.interpreterDataCount = 0;
                        }
                      })
                      .catch((err) => {
                        let errMessage = err.message || err;
                        if (err && err.data && err.data.message) {
                          errMessage = err.data.message;
                        } else if (err && err.data && err.data.reason) {
                          errMessage = err.data.reason;
                        }
                        console.log(errMessage);
                        ngToast.create({
                          content: errMessage,
                          className: 'danger'
                        });
                        $scope.interpreterDataCount = 0;
                      })
                      .finally(function () {
                        $scope.ddLoading = false;
                        $scope.scratchPad.isLoading = false;
                      })
                  }
                }

                $scope.loadPaginateData = function (searchKey, page, type) {
                  $scope.ddLoading = true;
                  let filter = {
                    page: page,
                    pageSize: $scope.pageLimitForDD
                  }
                  switch (type) {
                    case 'interpreters':
                      if (page == 1) {
                        $scope.interpreterData = [];
                      }
                      filter.searchKey = (searchKey).toLowerCase();
                      $scope.reloadInterpreters(filter);
                      break;
                    default:
                      break;
                  }
                }

                function init() {
                  $scope.reloadInterpreters();
                }
                init();

                $scope.buttonClick = function (e) {
                  $scope.scratchPad.error = null;
                  let data = {
                    interpreter: Array.isArray($scope.scratchPad.interpreter) ? $scope.scratchPad.interpreter[0] : $scope.scratchPad.interpreter,
                    taskId: task._id,
                    coi: task.caseData.coi,
                    status: $scope.scratchPad.status
                  };
                  if (['Locating', 'Assigned', 'Confirmed'].indexOf($scope.scratchPad.status) > -1) {
                    if ($scope.scratchPad.status == 'Locating') {
                      if (data.interpreter) {
                        $scope.scratchPad.error = "Please unassign interpreter."
                        return true;
                      }
                    } else {
                      if (!data.interpreter) {
                        $scope.scratchPad.error = "Please assign interpreter."
                        return true;
                      }
                    }
                  }
                  $scope.scratchPad.isLoading = true;
                  // console.log(data)
                  $clientTask.updateWOStatus(client, data)
                    .then((res) => {
                      if (res && res.status) {
                        ngToast.create({
                          content: res.message || res,
                          className: 'success'
                        });
                        finish.call(e, res);
                        modal.close(e);
                      } else {
                        $scope.scratchPad.isLoading = false;
                        ngToast.create({
                          content: res.message || res,
                          className: 'danger'
                        });
                      }
                    })
                    .catch(err => {
                      ngToast.create({
                        content: err.message || err,
                        className: 'danger'
                      });
                      $scope.scratchPad.isLoading = false;
                    })
                }

                $scope.close = function (e) {
                  finish.call(e, false);
                  modal.close(e);
                }
              }],
              modal: {
                dismissable: true,
                title: 'Update Work Order Status',
                template: 'components/modal/tpl/update-wo-status.html'
              }
            }, 'modal-success');
          }
        },
        showTaskEvent: function (coi, coiDetail, finish) {
          finish = finish || angular.noop;
          return function () {
            var scratchPad = {
              isLoading: false,
              error: null,
             coi: coi,
             coiDetail: coiDetail
            };
            const modal = openModal({
              controller: ['$scope', '$clientSwitcher', 'ngToast', '$autoAssigned', '$clientTask', '$autoTimezoneFormat', function ($scope, $clientSwitcher, ngToast, $autoAssigned, $clientTask, $autoTimezoneFormat) {
                const client = $clientSwitcher.getCurrentClient();
                $scope.scratchPad = scratchPad;
                // $scope.model = task;
                $scope.taskService = $clientTask;
                $scope.getCOIEvents = function() {
                  $scope.scratchPad.isLoading = true;
                  $scope.scratchPad.error = null;
                  $autoAssigned.getTaskCOIEvents(client, $scope.scratchPad.coi)
                  .then((res) => {
                    if (res.status) {
                      $scope.scratchPad.coiEvents = res.events.coiEvents
                    }
                  })
                  .catch((err) => {
                    console.log(err)
                    $scope.scratchPad.error= err.message || 'Something went worng, please try again.';
                    ngToast.create({
                      content: err.message || 'Something went worng, please try again.',
                      className: 'danger'
                    });
                  })
                  .finally(() => {
                    $scope.scratchPad.isLoading = false;
                  })
                }
                function init() {
                  $scope.getCOIEvents();
                }

                $scope.getTimeWithFormted = function(actionDate, courtTimezone) {
                  if (!courtTimezone) {
                    courtTimezone =  moment.tz.guess()
                  }
                  try {
                    let date = moment.utc(actionDate).format('YYYY-MM-DD'),
                    time = moment.utc(actionDate).format('HH:mm:00');
                    let timeDetail = $autoTimezoneFormat.getAllDetail(date, time, courtTimezone);
                    let _d = moment.utc(actionDate).utcOffset(timeDetail.coiTimezoneOffset).format('YYYY-MM-DD hh:mm A')
                    return _d + ' ' + timeDetail.coiTimezone
                  } catch (error) {
                    console.log(error)
                    return moment(actionDate).format('YYYY-MM-DD hh:mm z')
                  }
                }
                init();
                $scope.buttonClick = function (e) {}
                $scope.close = function (e) {
                  finish.call(e, false);
                  modal.close(e);
                }
              }],
              modal: {
                dismissable: true,
                title: 'COI Events',
                template: 'components/modal/tpl/coi-events.html'
              }
            }, 'modal-success', 'lg');
          }
        }
      },
      alertActions: {
        recordDetail: function (finish) {
          finish = finish || angular.noop;
          return function (record, bucket, lastClient) {
            if(!record) {
              return;
            }

            // console.log('record:', record);

            var
            callModal = openModal({

              controller: ['$scope', '$clientSwitcher', '$clientUser', 'appConfig', function ($scope, $clientSwitcher, $clientUser, appConfig) {
                $scope.clientStrings = function(){ return $clientSwitcher.getFeatureOptions('client.strings'); };
              }],

              modal: {
                dismissable: false,
                title: 'Record Details',
                template: 'components/modal/tpl/record-focused.html',

                buttons: [{
                  classes: 'btn-default',
                  text: 'Close',
                  click: function(e) {
                    callModal.close(e);
                  }
                }]
              },
              record: record,
              bucket: bucket,
              client: lastClient
            }, 'modal-success');

            callModal.result.then(function () {
              finish.call(this, record);
            }, function () {
              finish.call(this, record);
            });
          };
        },
        callRecord: function (finish) {
          finish = finish || angular.noop;
          return function (record) {
            if(!record) {
              return;
            }

            var
            userName = record.raw.customer.name,
            userPhoneNumber = record.raw.customer.telephone,
            callModal = openModal({
              modal: {
                dismissable: true,
                title: 'Call Record: ' + userName,
                html: '<div class="text-center"><img src="assets/images/telephone_ani.gif" /></div><p class="text-center">Calling '+userPhoneNumber+', please wait..</p>',
                buttons: [{
                  classes: 'btn-default',
                  text: 'Close',
                  click: function(e) {
                    callModal.dismiss(e);
                  }
                }]
              }
            }, 'modal-success');

            callModal.result.then(function(event) {
              finish.apply(event, args);
            });
          };
        },
        listenToAudio: function (finish) {
          finish = finish || angular.noop;

          return function (record) {
            if(!record) {
              return;
            }

            var
            audioListenModal = openModal({
              controller: ['$scope', function ($scope) {
                $scope.playlist = recordPlaylist(record);
              }],
              modal: {
                dismissable: true,
                title: 'Audio Recording: ' + record.raw.customer.name,
                template: 'components/modal/tpl/audio-player.html',
                buttons: [{
                  classes: 'btn-default',
                  text: 'Close',
                  click: function(e) {
                    audioListenModal.dismiss(e);
                  }
                }]
              }
            }, 'modal-success');

            audioListenModal.result.then(function(event) {
              finish.apply(event, args);
            });
          };
        },
        scoreRecord: function (finish) {
          finish = finish || angular.noop;
          return function (record, bucket, client) {
            if(!record) {
              return;
            }

            var
            scratchPad = {
            },
            scoreModal = openModal({
              controller: ['$scope', '$clientSwitcher', function ($scope, $clientSwitcher) {
                $scope.entryQuestionnaire = record.raw.data;
                $scope.entryRecord        = record.raw;
                $scope.surveyQA           = record.raw.data.questions;

                $scope.clientStrings = function(){ return $clientSwitcher.getFeatureOptions('client.strings'); };

                function shutdownDlg() {
                  scoreModal.close(scratchPad);
                }

                $scope.listenToAudio = function () {
                  scratchPad.openAudio = true;
                  shutdownDlg();
                };
                $scope.recordForward = function () {
                  scratchPad.assignRecord = true;
                  shutdownDlg();
                };
                $scope.recordResolve = function () {
                  scratchPad.resolveRecord = true;
                  shutdownDlg();
                };
              }],
              modal: {
                dismissable: true,
                title: 'Score Record',
                template: 'components/modal/tpl/record-score.html'
              },
              record: record,
              bucket: bucket,
              client: client
            }, 'modal-success');

            scoreModal.result.then(function(event) {
              finish.call(event, scratchPad, record);
            });
          };
        },
        transcribeAudio: function (finish) {
          finish = finish || angular.noop;
          return function (record) {
            if(!record) {
              return;
            }

            var
            scratchPad = {},
            modal = openModal({
              user: $auth.getCurrentUser(),
              controller: ['$scope', function ($scope) {
                $scope.scratchPad = scratchPad;
                $scope.playlist = recordPlaylist(record);

                if($scope.playlist.length > 0) { // set to the first track automatically
                  scratchPad.track = $scope.playlist.tracks[0];
                }

                $scope.playlist.tracks = $scope.playlist.tracks.map(function(trackRecord) {
                  return {
                    label: trackRecord.title,
                    value: trackRecord
                  };
                });

                $scope.$watch('scratchPad.track', function (nV) {
                  scratchPad.transcription = nV.transcription;
                });
              }],
              modal: {
                dismissable: true,
                title: 'Transcribe Audio: ' + record.raw.customer.name,
                template: 'components/modal/tpl/transcribe.html',
                buttons: [{
                  classes: 'btn-primary',
                  text: 'Save',
                  click: function(e) {
                    modal.close(e);
                  }
                }, {
                  classes: 'btn-default',
                  text: 'Cancel',
                  click: function(e) {
                    modal.dismiss(e);
                  }
                }]
              }
            }, 'modal-success');

            modal.result.then(function(event) {
              finish.call(event, scratchPad, record);
            });
          };
        },
        forwardRecord: function (finish) {
          finish = finish || angular.noop;
          return function (record) {
            if(!record) {
              return;
            }
            var
            scratchPad,
            forwardModal = openModal({
              user: $auth.getCurrentUser(),
              controller: ['$scope', '$clientSwitcher', '$clientUser', function ($scope, $clientSwitcher, $clientUser) {
                $scope.entryRecord = record.raw;
                $scope.currentUser = $auth.getCurrentUser();
                $scope.forward = scratchPad = {
                  comment: '',
                  originalRecord: record.raw
                };
                $scope.clientStrings = function(){ return $clientSwitcher.getFeatureOptions('client.strings'); };

                ($scope.reloadChainOfCommand = function reloadChainOfCommand() {
                  return $clientUser.getUserChainOfCommand(record.raw.client)
                    .then(function (forwardables) {

                      var
                      hasEscalates   = (!!forwardables.escalates && !!forwardables.escalates.length),
                      hasDelegates   = (!!forwardables.delegates && !!forwardables.delegates.length),
                      labelNotSet    = '-- Not Set --',
                      labelSelectOne = '(select one)';

                      if(hasEscalates) {
                        forwardables.escalates.push({
                          _id: undefined,
                          name: !!hasDelegates ? labelNotSet : labelSelectOne
                        });
                      }
                      if(hasDelegates) {
                        forwardables.delegates.push({
                          _id: undefined,
                          name: !!hasEscalates ? labelNotSet : labelSelectOne
                        });
                      }

                      $scope.forwardables = forwardables;
                      return forwardables;
                    })
                    .catch(function (err) {
                      console.log('forwardable error:', err);
                      return err;
                    });
                })();
              }],
              modal: {
                dismissable: true,
                title: 'Assign Record',
                template: 'components/modal/tpl/record-forward.html',
                buttons: [{
                  classes: 'btn-primary',
                  text: 'Send',
                  click: function(e) {
                    forwardModal.close(e);
                  }
                }, {
                  classes: 'btn-default',
                  text: 'Cancel',
                  click: function(e) {
                    forwardModal.dismiss(e);
                  }
                }]
              }
            }, 'modal-success');

            forwardModal.result.then(function(event) {
              delete scratchPad.originalRecord;
              finish.call(event, scratchPad, record.raw);
            });
          };
        },
        removeTask: function(finish, title){
          finish = finish || angular.noop;
          return function (record) {
            if(!record) {
              return;
            }
            var modal = openModal({
              task: record,
              modal: {
                dismissable: true,
                title: title||'Remove Task',
                template: 'components/modal/tpl/record-remove.html',
                buttons: [{
                  classes: 'btn-default',
                  text: 'Leave as is',
                  click: function(e) {
                    modal.dismiss(e);
                  }
                },{
                  classes: 'btn-danger',
                  text: 'Remove',
                  click: function(e) {
                    $clientTask.removeTask(record).then(function(){
                      modal.close(e);
                    });
                  }
                }]
              }
            }, 'modal-success');

            modal.result.then(function(event) {
              finish.call(event, record);
            });
          };
        },
        reopenTask: function(finish, title){
          finish = finish || angular.noop;

          return function (task) {
            if(!task) {
              return;
            }

            function pushScratchPad(sp, to) {
              if(!sp || !to) {
                return false;
              }

              var
              assignTo      = sp.assignTo,
              assignToEmail = sp.assignToEmail,
              due           = sp.due,
              comment       = sp.comment;

              if(sp.assignedToMe) {
                assignTo = $auth.getCurrentUser()._id;
              }
              else if(sp.delegateTo) {
                assignTo = sp.delegateTo;
              }
              else if(sp.escalateTo) {
                assignTo = sp.escalateTo;
              }

              to.assignTo      = assignTo;
              to.assignToEmail = assignToEmail;
              to.due           = due;
              to.comment       = comment;

              return to;
            }

            var
            scratchPad = {
              priority: task.priority,
              due: !!task.due ? new Date(task.due) : new Date(),
              assignTo: (!!task.assignTo ? task.assignTo._id : null),
              assignToEmail: (!!task.assignToEmail ? task.assignToEmail : null),
              assignToOnExpire: (!!task.assignToOnExpire ? task.assignToOnExpire._id : null),
              assignToEmailOnExpire: (!!task.assignToEmailOnExpire ? task.assignToEmailOnExpire : null),
              onExpireDue: !!task.onExpireDue ? new Date(task.onExpireDue) : new Date(),
              onExpirePriority: (!!task.onExpirePriority ? task.onExpirePriority : null)
            },
            assignResult,
            assignModal = openModal({
              controller: ['$scope', '$clientSwitcher', '$clientUser','$clientTask', '$q', '$clientExternalUsers', 'ngToast', '$activeClientSettings', 'appConfig', function ($scope, $clientSwitcher, $clientUser, $clientTask, $q, $clientExternalUsers, ngToast, $activeClientSettings, appConfig) {
                $scope.currentUser = $auth.getCurrentUser();
                $scope.currentClient = $clientSwitcher.getCurrentClient();
                $scope.scratchPad = scratchPad;
                $scope.entryTask = task;

                $scope.slider = {
                  max: 1435,
                  dueValueInMin: 0,
                  fallbackDueValueInMin: 0
                };

                var getCOBD = function() {
                  var defer = $q.defer();

                  $activeClientSettings(function(client, settings) {
                    var date = new Date();
                    var currentDay = date.getDay();
                    currentDay = (currentDay+6)%7; //converting from sun=0 ... sat=6 to mon=0 ... sun=6

                    if(settings.businessDays && settings.businessDays[currentDay] && settings.businessDays[currentDay].enabled) {
                      var COBDInMinutes = settings.businessDays[currentDay].max;
                      var hours = Math.floor(COBDInMinutes/60);
                      var minutes = COBDInMinutes%60;

                      date.setHours(hours);
                      date.setMinutes(minutes);

                      return defer.resolve(date);
                    }

                    return defer.resolve(null);
                  });

                  return defer.promise;
                };

                getCOBD()
                .then(function(date) {
                  if(date) {
                    $scope.scratchPad.due = date;
                  }
                  else {
                    $scope.scratchPad.due = new Date();
                  }
                })
                .catch(function(err) {
                  console.log(err);
                });

                $scope.$watch(function() { return $scope.scratchPad.due; }, function(nv) {
                  if($scope.scratchPad.due) {
                    $scope.slider.dueValueInMin = $scope.scratchPad.due.getHours()*60 + $scope.scratchPad.due.getMinutes();
                  }
                });

                $scope.$watch(function() { return $scope.slider.dueValueInMin; }, function(nv) {
                  if($scope.scratchPad.due) {
                    $scope.scratchPad.due = new Date($scope.scratchPad.due.setHours($scope.slider.dueValueInMin / 60, $scope.slider.dueValueInMin % 60));
                  }
                });

                //create relation between fallbackDueValueInMin and model.onExpireDue
                $scope.$watch(function() { return $scope.scratchPad.onExpireDue; }, function(nv) {
                  if($scope.scratchPad.onExpireDue) {
                    $scope.slider.fallbackDueValueInMin = $scope.scratchPad.onExpireDue.getHours()*60 + $scope.scratchPad.onExpireDue.getMinutes();
                  }
                });

                $scope.$watch(function() { return $scope.slider.fallbackDueValueInMin; }, function(nv) {
                  if($scope.scratchPad.onExpireDue) {
                    $scope.scratchPad.onExpireDue = new Date($scope.scratchPad.onExpireDue.setHours($scope.slider.fallbackDueValueInMin / 60, $scope.slider.fallbackDueValueInMin % 60));
                  }
                });

                $scope.taskDateFormat = 'MM/dd/yyyy';
                $scope.taskDateStepH  = 1;
                $scope.taskDateStepM  = 1;
                $scope.taskDateAMPM   = true;
                $scope.nowDate = function () {
                  var
                  now = Date.now(),
                  nextMinute = now % 60000;
                  return now - nextMinute;
                };
                $scope.taskDateDisabled = function (date, mode) {
                  var now = Date.now(), offset = (now % 8.64e7);
                  return date <= (now - offset);
                };

                $scope.buttonClick = function(buttonText) {
                  $scope.message = '';

                  if(!scratchPad.due) {
                    $scope.message = 'Please set the Due date.';
                    return;
                  }

                  // Fallback Due has to be greater than Primary Due
                  if(scratchPad.onExpireDue && scratchPad.due > scratchPad.onExpireDue){
                    $scope.message = 'Please set the fallback due date and time ahead of the primary due date and time.';
                    return;
                  }

                  return $scope.uploadFiles()
                    .then(function (uploaded) {
                      if(uploaded && uploaded.length) { // add these to the model so when it creates the task, it will associate these items.
                        task.uploads = uploaded.map(function (upload) {
                          return upload._id;
                        });
                      }

                      // omit is done as task contains bucket.records which is again the task itself therefore making it a circular structure
                      return $clientTask.reopenTask(
                        _.omit(task, ['bucket']),
                        scratchPad.priority,
                        scratchPad.assignTo,
                        scratchPad.assignToEmail,
                        scratchPad.due,
                        scratchPad.onExpirePriority,
                        scratchPad.assignToOnExpire,
                        scratchPad.assignToEmailOnExpire,
                        scratchPad.onExpireDue,
                        scratchPad.comment
                      ) // prevents uploader from going over the wire.
                      .then(function (result) {
                        assignModal.close();
                        $rootScope.$broadcast('taskAssigned');
                        assignResult = result;
                      })
                      .catch(function(err) {
                        assignResult = err;
                      });
                    })
                    .catch(function (err) {
                      console.log('error:', err.stack || err);
                      $scope.formError = err;
                    })
                    .finally(function () {
                      $scope.isSubmitting = false;
                    });
                };

                $scope.uploader = new FileUploader({
                  url: appConfig.apiUrl + '/api/client-uploads/'+$scope.currentClient._id+'?access_token='+$authStorage.getToken(),
                  alias: 'upload',
                  filters: [
                    {
                      name: 'extensionFilter',
                      fn: function(item) {
                        var extension = item.name.substr(item.name.lastIndexOf('.')+1);
                        var invalidExtensions = ['exe', 'bin'];
                        if(invalidExtensions.indexOf(extension) > -1) {
                          ngToast.create({
                            className: 'danger',
                            content: 'Files with extensions '+invalidExtensions+' are not allowed'
                          });
                          return false;
                        } else {
                          return true;
                        }
                      }
                    }
                  ]
                });

                $scope.allPriorities = [
                  {value :'high',     label: 'High'},
                  {value :'med-high', label: 'Medium High'},
                  {value :'med-low',  label: 'Medium Low'},
                  {value :'low',      label: 'Low'}
                ];

                this.uploadFiles = function () {

                  var
                  task = $scope.entryTask,
                  uploader = !!task ? $scope.uploader : false,
                  results  = [],
                  errors   = [];

                  if(uploader && uploader.queue && uploader.queue.length) {
                    var defer = $q.defer();

                    uploader.onSuccessItem = function(fileItem, response, status, headers) {
                      Array.prototype.push.apply(results, response);
                    };

                    uploader.onErrorItem = function(fileItem, response, status, headers) {
                      errors.push(new Error('Failed to upload file: ' + fileItem.file.name));
                    };

                    uploader.onCompleteAll = function() {
                      if(errors.length > 0) { // fail on any errors
                        defer.reject(errors);
                        return;
                      }

                      defer.resolve(results);
                    };

                    uploader.uploadAll();

                    return defer.promise;
                  }

                  return $q.when(false);
                };

                $scope.uploadFiles = this.uploadFiles;

                ($scope.reloadAvailableUsers = function (store, bucket) {
                  delete $scope.assignableUsers;

                  return $clientUser.getUserChainOfCommand($scope.currentClient, store, bucket)
                    .then(function (chainOfCommand) {
                      $scope.assignableUsers = chainOfCommand;
                      return $scope.assignableUsers;
                  });
                })(task.location, task.taskCollection.id);

                $scope.clientStrings = function(){
                  return $clientSwitcher.getFeatureOptions('client.strings');
                };

                $scope.taskOptions = function () {
                  return $clientSwitcher.getFeatureOptions('client.tasks');
                };

                $scope.$watch(function () {
                  var model = $scope.scratchPad;

                  if(!model.assignTo && !model.assignToEmail) {
                    return true;
                  }

                  return false;
                }, function (assignEmpty) {
                  if(assignEmpty) {
                    delete $scope.scratchPad.assignToOnExpire;
                    delete $scope.scratchPad.assignToEmailOnExpire;
                  }
                });

                $scope.$watch(function () {
                  var model = $scope.scratchPad;

                  if(!model.assignToOnExpire && !model.assignToEmailOnExpire) {
                    return true;
                  }

                  return false;
                }, function (fallbackEmpty) {
                  if(fallbackEmpty) {
                    delete $scope.scratchPad.onExpireDue;
                    delete $scope.scratchPad.onExpirePriority;
                  }
                });
              }],
              modal: {
                dismissable: true,
                title: title||'Reopen Task',
                template: 'components/modal/tpl/record-reopen.html',
                buttons: [{
                  classes: 'btn-primary',
                  text: 'Reopen',
                }, {
                  classes: 'btn-default',
                  text: 'Cancel',
                  click: function(e) {
                    assignModal.dismiss(e);
                  }
                }]
              }
            }, 'modal-success');

            assignModal.result.then(function(event) {
              finish.call(event, scratchPad, task, assignResult);
            });
          };
        },
        assignTask: function (finish, title) {

          finish = finish || angular.noop;

          return function (task) {
            if(!task) {
            return;
          }

            function pushScratchPad(sp, to) {
              if(!sp || !to) {
                return false;
              }

              var
              assignTo      = sp.assignTo,
              assignToEmail = sp.assignToEmail,
              due           = sp.due,
              comment       = sp.comment;

              if(sp.assignedToMe) {
                assignTo = $auth.getCurrentUser()._id;
              }
              else if(sp.delegateTo) {
                assignTo = sp.delegateTo;
              }
              else if(sp.escalateTo) {
                assignTo = sp.escalateTo;
              }

              to.assignTo      = assignTo;
              to.assignToEmail = assignToEmail;
              to.due           = due;
              to.comment       = comment;

              return to;
            }

            var
            scratchPad = {
              priority: task.priority,
              due: !!task.due ? new Date(task.due) : null,
              assignTo: (!!task.assignTo ? task.assignTo._id : null),
              assignToEmail: (!!task.assignToEmail ? task.assignToEmail : null),
              assignToOnExpire: (!!task.assignToOnExpire ? task.assignToOnExpire._id : null),
              assignToEmailOnExpire: (!!task.assignToEmailOnExpire ? task.assignToEmailOnExpire : null),
              onExpireDue: !!task.onExpireDue ? new Date(task.onExpireDue) : new Date(),
              onExpirePriority: (!!task.onExpirePriority ? task.onExpirePriority : null)
            },
            assignResult,
            assignModal = openModal({
              controller: ['$scope', '$clientSwitcher', '$clientUser','$clientTask', '$q', '$clientExternalUsers', 'ngToast', '$activeClientSettings', '$clientHolidays', 'appConfig',
              function ($scope, $clientSwitcher, $clientUser, $clientTask, $q, $clientExternalUsers, ngToast, $activeClientSettings, $clientHolidays, appConfig) {
                $scope.currentUser = $auth.getCurrentUser();
                $scope.currentClient = $clientSwitcher.getCurrentClient();
                $scope.scratchPad = scratchPad;
                $scope.entryTask = task;

                var clientSettings = null;
                var holidays = null;

                $scope.slider = {
                  max: 1435,
                  dueValueInMin: 0,
                  fallbackDueValueInMin: 0
                };

                $scope.$watch(function() { return $scope.scratchPad.due; }, function(nv) {
                  if($scope.scratchPad.due) {
                    $scope.slider.dueValueInMin = $scope.scratchPad.due.getHours()*60 + $scope.scratchPad.due.getMinutes();
                  }
                });

                $scope.$watch(function() { return $scope.slider.dueValueInMin; }, function(nv) {
                  if($scope.scratchPad.due) {
                    $scope.scratchPad.due = new Date($scope.scratchPad.due.setHours($scope.slider.dueValueInMin / 60, $scope.slider.dueValueInMin % 60));
                  }
                });

                //create relation between fallbackDueValueInMin and model.onExpireDue
                $scope.$watch(function() { return $scope.scratchPad.onExpireDue; }, function(nv) {
                  if($scope.scratchPad.onExpireDue) {
                    $scope.slider.fallbackDueValueInMin = $scope.scratchPad.onExpireDue.getHours()*60 + $scope.scratchPad.onExpireDue.getMinutes();
                  }
                });

                $scope.$watch(function() { return $scope.slider.fallbackDueValueInMin; }, function(nv) {
                  if($scope.scratchPad.onExpireDue) {
                    $scope.scratchPad.onExpireDue = new Date($scope.scratchPad.onExpireDue.setHours($scope.slider.fallbackDueValueInMin / 60, $scope.slider.fallbackDueValueInMin % 60));
                  }
                });

                $scope.taskDateFormat = 'MM/dd/yyyy';
                $scope.taskDateStepH  = 1;
                $scope.taskDateStepM  = 1;
                $scope.taskDateAMPM   = true;
                $scope.nowDate = function () {
                  var
                  now = Date.now(),
                  nextMinute = now % 60000;
                  return now - nextMinute;
                };
                $scope.taskDateDisabled = function (date, mode) {
                  var now = Date.now(), offset = (now % 8.64e7);
                  return date <= (now - offset);
                };

                $scope.buttonClick = function(buttonText) {
                  $scope.message = '';

                  if(!scratchPad.due) {
                    $scope.message = 'Please set the Due date.';
                    return;
                  }

                  // Fallback Due has to be greater than Primary Due
                  if(scratchPad.onExpireDue && scratchPad.due > scratchPad.onExpireDue){
                    $scope.message = 'Please set the fallback due date and time ahead of the primary due date and time.';
                    return;
                  }

                  return $scope.uploadFiles()
                    .then(function (uploaded) {
                      if(uploaded && uploaded.length) { // add these to the model so when it creates the task, it will associate these items.
                        task.uploads = uploaded.map(function (upload) {
                          return upload._id;
                        });
                      }

                      // omit is done as task contains bucket.records which is again the task itself therefore making it a circular structure
                      return $clientTask.assignTask(
                        _.omit(task, ['bucket']),
                        scratchPad.priority,
                        scratchPad.assignTo,
                        scratchPad.assignToEmail,
                        scratchPad.due,
                        scratchPad.onExpirePriority,
                        scratchPad.assignToOnExpire,
                        scratchPad.assignToEmailOnExpire,
                        scratchPad.onExpireDue,
                        scratchPad.comment
                      ) // prevents uploader from going over the wire.
                      .then(function (result) {
                        assignModal.close();
                        $rootScope.$broadcast('taskAssigned');
                        assignResult = result;
                      })
                      .catch(function(err) {
                        assignResult = err;
                      });
                    })
                    .catch(function (err) {
                      console.log('error:', err.stack || err);
                      $scope.formError = err;
                    })
                    .finally(function () {
                      $scope.isSubmitting = false;
                    });
                };

                $scope.uploader = new FileUploader({
                  url: appConfig.apiUrl + '/api/client-uploads/'+$scope.currentClient._id+'?access_token='+$authStorage.getToken(),
                  alias: 'upload',
                  filters: [
                    {
                      name: 'extensionFilter',
                      fn: function(item) {
                        var extension = item.name.substr(item.name.lastIndexOf('.')+1);
                        var invalidExtensions = ['exe', 'bin'];
                        if(invalidExtensions.indexOf(extension) > -1) {
                          ngToast.create({
                            className: 'danger',
                            content: 'Files with extensions '+invalidExtensions+' are not allowed'
                          });
                          return false;
                        } else {
                          return true;
                        }
                      }
                    }
                  ]
                });

                $scope.allPriorities = [
                  {value: 'high',     label: 'High'},
                  {value: 'med-high', label: 'Medium High'},
                  {value: 'med-low',  label: 'Medium Low'},
                  {value: 'low',      label: 'Low'}
                ];

                this.uploadFiles = function () {

                  var
                  task = $scope.entryTask,
                  uploader = !!task ? $scope.uploader : false,
                  results  = [],
                  errors   = [];

                  if(uploader && uploader.queue && uploader.queue.length) {
                    var defer = $q.defer();

                    uploader.onSuccessItem = function(fileItem, response, status, headers) {
                      Array.prototype.push.apply(results, response);
                    };

                    uploader.onErrorItem = function(fileItem, response, status, headers) {
                      errors.push(new Error('Failed to upload file: ' + fileItem.file.name));
                    };

                    uploader.onCompleteAll = function() {
                      if(errors.length > 0) { // fail on any errors
                        defer.reject(errors);
                        return;
                      }

                      defer.resolve(results);
                    };

                    uploader.uploadAll();

                    return defer.promise;
                  }

                  return $q.when(false);
                };

                $scope.uploadFiles = this.uploadFiles;

                ($scope.reloadAvailableUsers = function (store, bucket) {
                  delete $scope.assignableUsers;

                  return $clientUser.getUserChainOfCommand($scope.currentClient, store, bucket)
                    .then(function (chainOfCommand) {
                      $scope.assignableUsers = chainOfCommand;
                      return $scope.assignableUsers;
                  });
                })(task.location, task.taskCollection.id);

                $scope.clientStrings = function(){
                  return $clientSwitcher.getFeatureOptions('client.strings');
                };

                $scope.taskOptions = function () {
                  return $clientSwitcher.getFeatureOptions('client.tasks');
                };

                $scope.$watch(function () {
                  var model = $scope.scratchPad;

                  if(!model.assignTo && !model.assignToEmail) {
                    return true;
                  }

                  return false;
                }, function (assignEmpty) {
                  if(assignEmpty) {
                    delete $scope.scratchPad.assignToOnExpire;
                    delete $scope.scratchPad.assignToEmailOnExpire;
                  }
                });

                $scope.$watch(function () {
                  var model = $scope.scratchPad;

                  if(!model.assignToOnExpire && !model.assignToEmailOnExpire) {
                    return true;
                  }

                  return false;
                }, function (fallbackEmpty) {
                  if(fallbackEmpty) {
                    delete $scope.scratchPad.onExpireDue;
                    delete $scope.scratchPad.onExpirePriority;
                  }
                });
              }],
              modal: {
                dismissable: true,
                title: title||'Assign Task',
                template: 'components/modal/tpl/record-assign.html',
                buttons: [{
                  classes: 'btn-primary',
                  text: 'Assign',
                }, {
                  classes: 'btn-default',
                  text: 'Cancel',
                  click: function(e) {
                    assignModal.dismiss(e);
                  }
                }]
              }
            }, 'modal-success');

            assignModal.result.then(function(event) {
              finish.call(event, scratchPad, task, assignResult);
            });
          };
        },
        resolveRecord: function (finish, title) {
          finish = finish || angular.noop;
          return function (record) {
            if(!record) {
            return;
          }

            var
            scratchPad = {
              date: new Date(),
              message: null,
              previousNotes: record.resolve.notes
            },
            resolveResult,
            callModal = openModal({
              controller: ['$scope', '$clientSwitcher', '$clientUser','$clientTask', '$q', '$activeClientSettings', 'ngToast', 'TASK_STATE_PAUSED', 'TASK_STATE_STARTED', '$clientResolveCodes', '$uploadS3Attachment',
              function ($scope, $clientSwitcher, $clientUser, $clientTask, $q, $activeClientSettings, ngToast, TASK_STATE_PAUSED, TASK_STATE_STARTED, $clientResolveCodes, $uploadS3Attachment) {
                $scope.currentUser = $auth.getCurrentUser();
                $scope.currentClient = $clientSwitcher.getCurrentClient();
                $scope.entryTask = record;
                var DEFAULT_CLIENT_CRITERIA = {
                  startBeforeResolve: false,
                  minStartTime: 5
                };
                var clientResolveCriteria;

                // $activeClientSettings(function(client, clientSettings) {
                //   clientResolveCriteria = clientSettings.resolveCriteria || DEFAULT_CLIENT_CRITERIA;
                //   $scope.clientSupportsResolveCodes = clientSettings.supportedTaskAttributes.indexOf('resolveCodes') > -1;

                //   if($scope.clientSupportsResolveCodes) {
                //     $clientResolveCodes.getResolveCodes(client).then(function(response) {
                //       $scope.resolveCodes = response.map(function(resolveCode) {
                //         return {
                //           label: resolveCode.text,
                //           value: resolveCode._id
                //         };
                //       });
                //     });
                //   }

                // });

                $scope.uploader = [];

                // var getTotalTimeStartedMS = function() {
                //   var totalTimeStartedMS = 0;

                //   if(record.stateActivity && record.stateActivity.length) {
                //     for (var i=0; i<record.stateActivity.length-1; i++) {
                //       if(record.stateActivity[i].state === TASK_STATE_STARTED && record.stateActivity[i+1].state === TASK_STATE_PAUSED) {
                //         totalTimeStartedMS += moment(record.stateActivity[i+1].date).diff(moment(record.stateActivity[i].date));
                //         i++;
                //       }
                //     }

                //     // if task is currently in 'started' state then add that time in totalTimeStartedMS
                //     if(record.stateActivity[record.stateActivity.length - 1].state === TASK_STATE_STARTED) {
                //       var lastStartedAt = new Date(record.stateActivity[record.stateActivity.length - 1].date);
                //       totalTimeStartedMS += Date.now() - lastStartedAt.getTime();
                //     }
                //   }

                //   return totalTimeStartedMS;
                // };

                $scope.buttonClick = function(buttonText) {
                  var markClosed = false;
                  var leaveOpen = true;

                  // if(buttonText === 'Fully Completed') {
                  //   markClosed = true;
                  // } else if(buttonText === 'Complete Note'){
                  //   leaveOpen = true;
                  // }

                  // markClosed && clientResolveCriteria.startBeforeResolve &&
                  // if(record.status !== 'pending') {
                  //   if(!record.stateActivity.length) {
                  //     return ngToast.create({
                  //       className: 'danger',
                  //       content: 'You are required to start the task before you can complete it. Please press the start button to start the task'
                  //     });
                  //   }

                  //   if(clientResolveCriteria.minStartTime) {
                  //     var totalTimeStartedMS = getTotalTimeStartedMS();

                  //     if(totalTimeStartedMS < clientResolveCriteria.minStartTime*60000) {
                  //       var difference = clientResolveCriteria.minStartTime*60000 - totalTimeStartedMS;
                  //       difference /= 1000;

                  //       var remainingTime = {
                  //         getString: function() {
                  //           var min = this.minutes < 10 ? '0'+this.minutes : this.minutes;
                  //           var sec = this.seconds < 10 ? '0'+this.seconds : this.seconds;
                  //           return min+':'+sec;
                  //         }
                  //       };
                  //       remainingTime.minutes = Math.floor(difference/60);
                  //       remainingTime.seconds = Math.floor(difference%60);

                  //       return ngToast.create({
                  //         className: 'danger',
                  //         content: 'The task has not reached the minimum working time to be completed. There\'s still '+remainingTime.getString()+' amount of time remaining before the task can be completed'
                  //       });
                  //     }
                  //   }
                  // }

                  if ($scope.uploader && $scope.uploader.queue && $scope.uploader.queue.length) {
                    $uploadS3Attachment.upload($scope.currentClient, $scope.uploader, $scope, 'control1')
                      .then((imageRes) => {
                        record.uploads = [];
                        if (imageRes && imageRes.length) {
                          imageRes.forEach(function (upload) {
                            if (upload.status) {
                              record.uploads.push(upload.clientUpload._id);
                            }
                          });
                          return $clientTask.resolveTask(_.omit(record, ['bucket']), scratchPad.message, markClosed, leaveOpen, scratchPad.resolveCode) // prevents uploader from going over the wire.
                            .then(function (result) {
                              callModal.close(result);
                              // $rootScope.$broadcast('taskResolved');
                              resolveResult = result;
                            })
                            .catch(function (err) {
                              resolveResult = err;
                            })
                        }
                      })
                      .catch(function (err) {
                        console.log('error:', err.stack || err);
                        $scope.formError = err;
                      })
                      .finally(function () {
                        $scope.isSubmitting = false;
                      });
                  } else {
                    return $clientTask.resolveTask(_.omit(record, ['bucket']), scratchPad.message, markClosed, leaveOpen, scratchPad.resolveCode) // prevents uploader from going over the wire.
                      .then(function (result) {
                        callModal.close(result);
                        // $rootScope.$broadcast('taskResolved');
                        resolveResult = result;
                      })
                      .catch(function (err) {
                        resolveResult = err;
                      })
                      .finally(function () {
                        $scope.isSubmitting = false;
                      });
                  }
                };
              }],
              modal: {
                dismissable: true,
                title: 'Add Comment',
                template: 'components/modal/tpl/record-resolve.html',
                buttons: [{
                  classes: 'btn-success',
                  text: 'Save'
                },{
                  classes: 'btn-danger',
                  text: 'Close',
                  click: function(e) {
                    callModal.dismiss(e);
                  }
                }]
              },
              user: $auth.getCurrentUser(),
              resolve: scratchPad
            }, 'modal-success');

            callModal.result.then(function(event) {
              finish.call(event, scratchPad, record, resolveResult);
            });
          };
        },
        viewResolveHistory: function (finish) {
          finish = finish || angular.noop;
          return function (record) {
            if(!record) {
            return;
          }
            var
            scratchPad = {
              date: new Date(),
              message: null,
              previousNotes: record.resolve.notes
            },
            callModal = openModal({
              modal: {
                dismissable: true,
                title: 'Resolution History',
                template: 'components/modal/tpl/record-resolved-history.html',
                buttons: []
              },
              resolve: scratchPad
            }, 'modal-success');

            callModal.result.then(function(event) {
              finish.call(event, scratchPad, record);
            });
          };
        },
        viewTaskHistory: function (finish) {
          finish = finish || angular.noop;
          return function (record) {
            if(!record) {
            return;
          }
            var
            scratchPad = {
              date: new Date(),
              message: null,
              history: [],
              loading: true,
              task: record
            },
            callModal = openModal({
              controller: ['$scope', '$q', 'ngToast', '$taskActionsLogsManager', '$clientSwitcher', function ($scope, $q, ngToast, $taskActionsLogsManager, $clientSwitcher) {
                var clientId = $clientSwitcher.getCurrentClient()._id;
                var taskId = $scope.resolve.task.id;

                var colors = ['#efefef', '#eaf3f9', '#f4eaf9', '#eaf9f9', '#fff'];
                var historyColor = {'': '#fff0f0'};
                $scope.clientStrings = function(){ return $clientSwitcher.getFeatureOptions('client.strings'); };
                $taskActionsLogsManager.getTaskLog(clientId, taskId).then(function(data){
                  var resolveActions = ['partially-resolved', 'resolve-note', 'resolved'];
                  var noteIndex = 0;

                  $scope.resolve.history = data.map(function(taskAction) {
                    var indexOfColon = taskAction.extra ? taskAction.extra.indexOf(':') : -1;

                    if(resolveActions.indexOf(taskAction.action) > -1) {
                      taskAction.resolveCode = record.resolve.notes[noteIndex++].resolveCode;
                    }
                    if(indexOfColon > -1) {
                      taskAction.extra = taskAction.extra.substr(indexOfColon+1);
                    }

                    return taskAction;
                  });

                  $scope.resolve.loading = false;
                });

                $scope.getActionLog = function(state){
                  var str = null;
                  if(state.action === 'partially-resolved' || state.action === 'resolve-note'){
                    str = $scope.clientStrings().resolvePCase + ' Note';
                  }
                  else if(state.action === 'resolved') {
                    str = 'Task ' + $scope.clientStrings().resolvedPCase;
                  }
                  else if(state.action === 'edited'){
                    str = 'Task Updated';
                  }
                  else if(state.action === 'unresolved'){
                    str = 'Task ' + $scope.clientStrings().unResolvedPCase;
                  }
                  else if(state.action === 'reassigned') {
                    str = 'Task Reassigned';
                  }
                  else if(state.action === 'archived') {
                    str = 'Task Archived';
                  }
                  else if(state.action === 'reopened') {
                    str = 'Task Reopened';
                  }
                  else if(state.action === 'created') {
                    str = 'Task Created';
                  }
                  else if (state.action === 'assigned') {
                    str = 'Task Assigned';
                  }
                  else if (state.action === 'accepted') {
                    str = 'Task Accepted';
                  }
                  else if (state.action === 'rejected') {
                    str = 'Task Rejected';
                  }
                  else if (state.action === 'forwarded') {
                    str = 'Task Forwarded';
                  }
                  else {
                    str = state.action;
                  }

                  if(state && state.resolveCode) {
                    str += ' : ' + state.resolveCode.code + ' - ' + state.resolveCode.description;
                  }

                  // if(str && state.extra){
                  //   if(!state.extra.startsWith(str)){
                  //     str += ' : ' + state.extra;
                  //   }
                  //   if(state.extra.indexOf(":") > 0){
                  //     str = state.extra;
                  //   }
                  // }else if(state.extra){
                  //   str = state.extra;
                  // }else{
                  //   str = state.action;
                  // }

                  return str;
                };

                $scope.getBackground = function(state){
                  if(state.user && !historyColor[state.user] && colors.length > 0){
                    historyColor[state.user] = colors.pop();
                  }
                  return historyColor[state.user || ''];
                };
              }],
              modal: {
                dismissable: true,
                title: 'Task History',
                template: 'components/modal/tpl/record-history.html',
                buttons: []
              },
              resolve: scratchPad
            }, 'modal-success');

            callModal.result.then(function(event) {
              finish.call(event, scratchPad, record);
            });
          };
        },
        archiveRecord: function (finish, title) {
          finish = finish || angular.noop;
          return function (record) {
            if(!record) {
            return;
          }
            var modal = openModal({
              task: record,
              modal: {
                dismissable: true,
                title: title||'Archive Task',
                template: 'components/modal/tpl/task-archive.html',
                buttons: [{
                  classes: 'btn-default',
                  text: 'Leave as is',
                  click: function(e) {
                    modal.dismiss(e);
                  }
                },{
                  classes: 'btn-danger',
                  text: 'Archive',
                  click: function(e) {
                    modal.close(e);
                  }
                }]
              }
            }, 'modal-success');

            modal.result.then(function(event) {
              finish.call(event, record);
            });
          };
        },
        performActionOnExternalTask: function(finish, record, title, button, nextStatus, theme) {
          finish = finish || angular.noop;

          return function () {
            var
            scratchPad = {
              date: new Date(),
              message: null,
            },
            callModal = openModal({
              controller: ['$scope', '$q', 'ngToast', 'appConfig', function ($scope, $q, ngToast, appConfig) {
                $scope.title = title; //used in perform-action.html
                $scope.user = {name: record.associatedEmail};
                $scope.isUpdatingStatus = false;

                $scope.uploader = new FileUploader({
                  url: appConfig.apiUrl + '/api/client-uploads/'+record.client+'?token='+record.token,
                  alias: 'upload',
                  filters: [
                    {
                      name: 'extensionFilter',
                      fn: function(item) {
                        var extension = item.name.substr(item.name.lastIndexOf('.')+1);
                        var invalidExtensions = ['exe', 'bin'];
                        if(invalidExtensions.indexOf(extension) > -1) {
                          ngToast.create({
                            className: 'danger',
                            content: 'Files with extensions '+invalidExtensions+' are not allowed'
                          });
                          return false;
                        } else {
                          return true;
                        }
                      }
                    }
                  ]
                });

                this.uploadFiles = function () {

                  var
                  uploader = $scope.uploader,
                  results = [],
                  errors = [];

                  if(uploader && uploader.queue && uploader.queue.length) {
                    var defer = $q.defer();

                    uploader.onSuccessItem = function(fileItem, response, status, headers) {
                      Array.prototype.push.apply(results, response);
                    };

                    uploader.onErrorItem = function(fileItem, response, status, headers) {
                      errors.push(new Error('Failed to upload file: ' + fileItem.file.name));
                    };

                    uploader.onCompleteAll = function() {
                      if(errors.length > 0) { // fail on any errors
                        defer.reject(errors);
                        return;
                      }

                      defer.resolve(results);
                    };

                    uploader.uploadAll();

                    return defer.promise;
                  }

                  return $q.when(false);
                };

                $scope.uploadFiles = this.uploadFiles;

                $scope.buttonClick = function(buttonText) {
                  var markClosed = false;
                  var leaveOpen = false;
                  $scope.isUpdatingStatus = true;

                  if(buttonText === 'Fully Resolved') {
                    markClosed = true;
                  } else if(buttonText === 'Leave Open'){
                    leaveOpen = true;
                  }

                  return $scope.uploadFiles()
                    .then(function (uploaded) {
                      var uploads = [];
                      if(uploaded && uploaded.length) { // add these to the model so when it creates the task, it will associate these items.
                        uploads = uploaded.map(function (upload) {
                          return upload._id;
                        });
                      }

                      finish(scratchPad.message, uploads).then(function(result) {
                        if(result.status) {
                          $scope.isUpdatingStatus = false;

                          if(nextStatus) { //submitting contact form doesn't send any next status
                            record.status = nextStatus;
                          }

                          // console.log(record);
                          callModal.dismiss();
                        }
                      });
                    })
                    .catch(function (err) {
                      console.log('error:', err.stack || err);
                      $scope.formError = err;
                    })
                    .finally(function () {
                      $scope.isSubmitting = false;
                    });
                };
              }],
              modal: {
                dismissable: true,
                title: title||'External Task',
                template: 'components/modal/tpl/perform-action.html',
                buttons: [button]
              },
              resolve: scratchPad
            }, theme);
          };
        },
        resolveExternalTask: function (finish, record, title) {
          finish = finish || angular.noop;
          return function (task) {
            if(!task) {
            return;
          }

            var
            scratchPad = {
              date: new Date(),
              message: null,
              previousNotes: task.resolve.notes
            },
            callModal = openModal({
              controller: ['$scope', '$externalTask', '$q', 'ngToast', 'appConfig', function ($scope, $externalTask, $q, ngToast, appConfig) {
                $scope.entryTask = task;
                $scope.user = {name: record.associatedEmail};

                $scope.uploader = new FileUploader({
                  url: appConfig.apiUrl + '/api/client-uploads/'+record.client+'?token='+record.token,
                  alias: 'upload',
                  filters: [
                    {
                      name: 'extensionFilter',
                      fn: function(item) {
                        var extension = item.name.substr(item.name.lastIndexOf('.')+1);
                        var invalidExtensions = ['exe', 'bin'];
                        if(invalidExtensions.indexOf(extension) > -1) {
                          ngToast.create({
                            className: 'danger',
                            content: 'Files with extensions '+invalidExtensions+' are not allowed'
                          });
                          return false;
                        } else {
                          return true;
                        }
                      }
                    }
                  ]
                });

                this.uploadFiles = function () {

                  var
                  uploader = $scope.uploader,
                  results = [],
                  errors = [];

                  if(uploader && uploader.queue && uploader.queue.length) {
                    var defer = $q.defer();

                    uploader.onSuccessItem = function(fileItem, response, status, headers) {
                      Array.prototype.push.apply(results, response);
                    };

                    uploader.onErrorItem = function(fileItem, response, status, headers) {
                      errors.push(new Error('Failed to upload file: ' + fileItem.file.name));
                    };

                    uploader.onCompleteAll = function() {
                      if(errors.length > 0) { // fail on any errors
                        defer.reject(errors);
                        return;
                      }

                      defer.resolve(results);
                    };

                    uploader.uploadAll();

                    return defer.promise;
                  }

                  return $q.when(false);
                };

                $scope.uploadFiles = this.uploadFiles;

                $scope.buttonClick = function(buttonText) {
                  var markClosed = false;
                  var leaveOpen = false;

                  if(buttonText === 'Fully Resolved') {
                    markClosed = true;
                  } else if(buttonText === 'Resolve Note'){
                    leaveOpen = true;
                  }

                  return $scope.uploadFiles()
                    .then(function (uploaded) {
                      var uploads = [];
                      if(uploaded && uploaded.length) { // add these to the model so when it creates the task, it will associate these items.
                        uploads = uploaded.map(function (upload) {
                          return upload._id;
                        });
                      }

                      callModal.dismiss();
                      return $externalTask.resolveExternalTask(record.token, scratchPad.message, markClosed, leaveOpen, uploads) // prevents uploader from going over the wire.
                      .then(function (result) {
                        // console.log(result);
                        if(result.status) {
                          record.status = result.newStatus;
                        }
                        callModal.close();
                      })
                      .catch(function (err) {
                        console.log('error:', err.stack || err);
                        $scope.formError = err;
                      })
                      .finally(function () {
                        $scope.isSubmitting = false;
                        finish.call(event);
                      });
                    })
                    .catch(function (err) {
                      console.log('error:', err.stack || err);
                      $scope.formError = err;
                    })
                    .finally(function () {
                      $scope.isSubmitting = false;
                    });
                };
              }],
              modal: {
                dismissable: true,
                title: title||'Resolve Task',
                template: 'components/modal/tpl/record-resolve.html',
                buttons: [{
                  classes: 'btn-danger',
                  text: 'Close',
                  click: function(e) {
                    callModal.dismiss(e);
                  }
                },{
                  classes: 'btn-primary',
                  text: 'Resolve Note'
                },{
                  classes: 'btn-success',
                  text: 'Fully Resolved'
                }]
              },
              resolve: scratchPad
            }, 'modal-success');
          };
        },
        editDueDate: function(due, finish, title) {
          finish = finish || angular.noop;
          var
          scratchPad = {
            date: due ? due : 'Please Select'
          },
          callModal = openModal({
            controller: ['$scope', function ($scope) {
              $scope.scratchPad = scratchPad;

              $scope.slider = {
                maxMinutes: 1435,
                dueValueInMinutes: 1100
              };

              // create relation between dueValueInMin and scratchPad.date
              $scope.$watch(function() { return $scope.scratchPad.date; }, function(nv) {
                if($scope.scratchPad.date && typeof $scope.scratchPad.date !== 'string') {
                  $scope.slider.dueValueInMinutes = $scope.scratchPad.date.getHours()*60 + $scope.scratchPad.date.getMinutes();
                }
              });

              $scope.$watch(function() { return $scope.slider.dueValueInMinutes; }, function(nv) {
                if($scope.scratchPad.date && typeof $scope.scratchPad.date !== 'string') {
                  $scope.scratchPad.date = new Date($scope.scratchPad.date.setHours($scope.slider.dueValueInMinutes / 60, $scope.slider.dueValueInMinutes % 60));
                }
              });

              $scope.taskDateFormat = 'MM/dd/yyyy';
              $scope.taskDateStepH  = 1;
              $scope.taskDateStepM  = 1;
              $scope.taskDateAMPM   = true;
              $scope.nowDate = function () {
                var
                now = Date.now(),
                nextMinute = now % 60000;
                return now - nextMinute;
              };
              $scope.taskDateDisabled = function (date, mode) {
                var now = Date.now(), offset = (now % 8.64e7);
                return date <= (now - offset);
              };

              $scope.clearDueDate = function (){
                if($scope.scratchPad && !$scope.scratchPad.date) {
                return;
              }
                delete $scope.scratchPad.date;
              };

              $scope.presetOffsets = null; // use defaults
            }],
            modal: {
              dismissable: true,
              title: title||'Edit Due Date',
              template: 'components/modal/tpl/edit-due.html',
              buttons: [{
                classes: 'btn-default',
                text: 'Cancel',
                click: function(e) {
                  callModal.dismiss(e);
                }
              },{
                classes: 'btn-success',
                text: 'Edit',
                click: function(e) {
                  callModal.close(e);
                }
              }]
            },
            resolve: scratchPad
          }, 'modal-success');

          callModal.result.then(function(event) {
            finish.call(event, scratchPad);
          });
        },
        recordPause: function(finish) {
          finish = finish || angular.noop;
          return function(task) {
            if(!task) {
              return;
            }

            var scratchPad = {
            };
            var modal = openModal({
              controller: ['$scope', function ($scope) {
                $scope.task = task;
                $scope.scratchPad = scratchPad;
                $scope.user = $auth.getCurrentUser();
                $scope.date = new Date();
              }],
              modal: {
                dismissable: true,
                title: 'Pause Task',
                template: 'components/modal/tpl/record-pause.html',
                buttons: [{
                  classes: 'btn-danger',
                  text: 'Pause',
                  click: function(e) {
                    if(scratchPad.message) {
                      modal.close(e);
                    }
                  }
                }, {
                  classes: 'btn-default',
                  text: 'Cancel',
                  click: function(e) {
                    modal.dismiss(e);
                  }
                }]
              }
            }, 'modal-danger');

            modal.result.then(function(event) {
              finish.call(event, scratchPad);
            });
          };
        }
      },
      ruleActions: {
        updateRule: function (finish) {
          finish = finish || angular.noop;
          return function (rule) {
            if(!rule) {
            return;
          }
            var modal = openModal({
              controller: ['$scope', '$clientSwitcher', '$rulesManager', function ($scope, $clientSwitcher, $rulesManager) {
                $scope.allValue = 'all';
                $scope.rule = rule;

                var lastClient = $clientSwitcher.getCurrentClient()._id;

                $scope.updateRule = function() {
                  $rulesManager.updateRule(lastClient, $scope.rule).then(function() {
                    modal.close();
                  });
                };
              }],
              modal: {
                dismissable: true,
                title: 'Edit Rule',
                template: 'components/modal/tpl/edit-rule.html'
              }
            }, 'modal-success');

            modal.result.then(function(event) {
              finish.call(event, rule);
            });
          };
        }
      },
      scheduleTasks: {
        createTaskSchedule: function(finish) {
          finish = finish || angular.noop;
          return function () {
            var scratchPad = {
              taskDetails: {},
              window: {
                executionTime: {
                  stop: 0,
                  temp: 0
                },
                selectedDays: []
              }
            };

            var modal = openModal({
              controller: ['$scope', '$clientSwitcher', '$clientOffice', '$clientUser', '$timeout', function ($scope, $clientSwitcher, $clientOffice, $clientUser, $timeout) {
                $scope.scratchPad = scratchPad;
                $scope.onCreate = function(e){
                  modal.close(e);
                };

                $scope.onCancel = function(e) {
                  modal.dismiss(e);
                };
              }],
              modal: {
                dismissable: true,
                title: 'Create Task Schedule',
                template: 'components/modal/tpl/schedule-create.html'
              }
            }, 'modal-success');

            modal.result.then(function(event) {
              finish.call(event, scratchPad);
            });
          };
        },
        editTaskSchedule: function(finish) {
          finish = finish || angular.noop;
          return function(scheduleData) {
            var scratchPad = angular.copy(scheduleData);
            scratchPad.window.executionTime = {
              stop: scheduleData.window.executionTime,
              temp: 0
            };

            scratchPad.status = scheduleData.isActive ? 'active': 'suspended';

            scratchPad.taskDetails.location = scratchPad.taskDetails.location._id;
            scratchPad.taskDetails.taskCollection = scratchPad.taskDetails.taskCollection._id;
            scratchPad.taskDetails.assignTo = scratchPad.taskDetails.assignTo ? scratchPad.taskDetails.assignTo._id : null;
            scratchPad.taskDetails.assignToOnExpire = scratchPad.taskDetails.assignToOnExpire ? scratchPad.taskDetails.assignToOnExpire._id : null;

            var modal = openModal({
              controller: ['$scope', '$clientSwitcher', '$clientOffice', '$clientUser', '$timeout', function ($scope, $clientSwitcher, $clientOffice, $clientUser, $timeout) {
                $scope.scratchPad = scratchPad;
                $scope.onUpdate = function(e){
                  modal.close(e);
                };

                $scope.onCancel = function(e) {
                  modal.dismiss(e);
                };
              }],
              modal: {
                dismissable: true,
                title: 'Update Task Schedule',
                template: 'components/modal/tpl/schedule-update.html'
              }
            }, 'modal-success');

            modal.result.then(function(event) {
              finish.call(event, scratchPad);
            });
          };
        }
      },
      training: {
        createTrainingRecord: function (finish) {
          finish = finish || angular.noop;
          return function (trainingRecord) {
            var scratchPad = trainingRecord;
            var modal = openModal({
              controller: ['$scope', '$clientSwitcher', 'ngToast', '$q', '$uploadS3Attachment', function ($scope, $clientSwitcher, ngToast, $q, $uploadS3Attachment) {
                $scope.model = scratchPad || {
                  title: '',
                  description: '',
                  uploads: [],
                  link: '',
                  attachmentType: 'image',
                  videoUploads: []
                };
                const prevType = $scope.model.attachmentType;
                var isUpdateOperation = !!$scope.model.title;

                $scope.currentClient = $clientSwitcher.getCurrentClient();
                $scope.errors = [];
                $scope.uploader = [];
                $scope.videoUploader = [];

                function toastMsg(message, type) {
                  ngToast.create({
                    content: message,
                    className: type
                  });
                }

                $scope.createTrainingRecord = function () {
                  if ($scope.uploader && $scope.uploader.queue && $scope.uploader.queue.length) {
                    var imageValidExtensions = ['image/png', 'image/jpg', 'image/jpeg'];
                    var videoValidExtensions = ['video/mp4', 'video/flv', 'video/mkv', 'video/3gp'];
                    var isNextProcessToUpload = true;
                    if ($scope.model.attachmentType == 'video' && $scope.videoUploader && $scope.videoUploader.queue && $scope.videoUploader.queue.length) {
                      if (videoValidExtensions.indexOf($scope.videoUploader.queue[0].type) > -1) {
                        isNextProcessToUpload = true;
                      } else {
                        toastMsg(`Files with video attachment only ${videoValidExtensions} extensions are allowed`, 'danger');
                        isNextProcessToUpload = false;
                        return false;
                      }
                    }
                    if (isNextProcessToUpload) {
                      if (imageValidExtensions.indexOf($scope.uploader.queue[0].type) > -1) {
                        $uploadS3Attachment.upload($scope.currentClient, $scope.uploader, $scope, 'control1')
                          .then((imageRes) => {
                            $scope.model.uploads = [];
                            $scope.model.videoUploads = [];
                            if (imageRes && imageRes.length) {
                              imageRes.forEach(function (upload) {
                                if (upload.status) {
                                  $scope.model.uploads.push(upload.clientUpload._id);
                                }
                              });
                            }

                            if ($scope.model.attachmentType == 'video' && $scope.videoUploader && $scope.videoUploader.queue && $scope.videoUploader.queue.length) {
                              $uploadS3Attachment.upload($scope.currentClient, $scope.videoUploader, $scope, 'control2')
                                .then((videoRes) => {
                                  if (videoRes && videoRes.length) {
                                    videoRes.forEach(function (upload) {
                                      if (upload.status) {
                                        $scope.model.videoUploads.push(upload.clientUpload._id);
                                      }
                                    });
                                  }
                                  if (videoRes || isUpdateOperation) {
                                    modal.close($scope.model);
                                  }
                                })
                                .catch((err) => {
                                  toastMsg(err.message || err, 'danger');
                                })
                            } else {
                              if ((imageRes && imageRes.length) || isUpdateOperation) {
                                modal.close($scope.model);
                              }
                            }
                          })
                          .catch((err) => {
                            toastMsg(err.message || err, 'danger');
                          })
                      } else {
                        toastMsg(`Files with only ${imageValidExtensions} extensions are allowed`, 'danger');
                      }
                    }
                  } else {
                    if (this.model && this.model._id ) {
                      modal.close($scope.model);
                    } else {
                      toastMsg('Please select custom thumbnail', 'danger');
                    }
                  }
                };

              }],
              modal: {
                dismissable: true,
                title: 'Upload Training Record',
                template: 'components/modal/tpl/record-training.html'
              }
            }, 'modal-success');

            modal.result.then(function (trainingRecord) {
              finish(trainingRecord);
            });
          };
        }
      },
      taskCreateRuleActions: {
        createRule: function(finish) {
          finish = finish || angular.noop;
          var rule = {
            status: 'active',
            taskDetails: {},
            window: {
              executionTime: {
                stop: 0,
                temp: 0
              },
              selectedDays: []
            }
          };
          return function(tables) {

            var modal = openModal({
              controller: ['$scope', function ($scope) {
                $scope.rule = rule;
                $scope.tables = tables;

                $scope.onSubmit = function() {
                  modal.close();
                };
              }],
              modal: {
                dismissable: true,
                title: 'Create Rule',
                template: 'components/modal/tpl/task-create-rule.html'
              }
            }, 'modal-success', 'lg');

            modal.result.then(function(event) {
              finish.call(event, rule);
            });
          };
        },
        editRule: function(finish) {
          finish = finish || angular.noop;

          return function(tables, rule) {

            rule.window.executionTime = {
              stop: rule.window.executionTime,
              temp: 0
            };

            var modal = openModal({
              controller: ['$scope', function ($scope) {
                $scope.rule = rule;
                $scope.tables = tables;

                $scope.onSubmit = function() {
                  modal.close();
                };
              }],
              modal: {
                dismissable: true,
                title: 'Edit Rule',
                template: 'components/modal/tpl/task-create-rule.html'
              }
            }, 'modal-success', 'lg');

            modal.result.then(function(event) {
              finish.call(event, rule);
            });
          };
        },
        previewQueryResult: function() {
          return function(query, connectionType) {
            var modal = openModal({
              controller: ['$scope' ,'$clientQueryBuilder', '$clientSwitcher', function ($scope, $clientQueryBuilder, $clientSwitcher) {
                $scope.data = {
                  loading: true,
                  records: [],
                  error: null,
                  previewFields: []
                };

                var client = $clientSwitcher.getCurrentClient();

                $clientQueryBuilder.getRecords(client, query, connectionType).then(function(res) {
                  $scope.data.loading = false;
                  if(res.status) {
                    if(res.data.length) {
                      $scope.data.records = res.data;
                      if($scope.data.records.length) {
                        $scope.data.previewFields = Object.keys($scope.data.records[0]);
                      }
                    }
                    else {
                      $scope.data.error = 'Query returned 0 records';
                    }
                  }
                  else {
                    $scope.data.error = res.reason;
                  }
                });
              }],
              modal: {
                dismissable: true,
                title: 'Preview',
                template: 'components/modal/tpl/preview-query-results.html',
                buttons: [{
                  classes: 'btn-default',
                  text: 'Close',
                  click: function(e) {
                    modal.close(e);
                  }
                }]
              }
            }, 'modal-success', 'lg');
          };
        }
      },
      clientActions: {
        createClient: function(finish) {
          return function() {
            var scratchPad = {};
            var modal = openModal({
              controller: ['$scope', function ($scope) {
                $scope.scratchPad = scratchPad;
                $scope.showInfo = true;
                $scope.submit = function(e) {
                  finish.call(e, scratchPad);
                  $scope.close(e);
                };

                $scope.close = function(e) {
                  modal.dismiss(e);
                };
              }],
              modal: {
                dismissable: true,
                title: 'Create Client',
                template: 'components/modal/tpl/client-create.html'
              }
            }, 'modal-success');
          };
        },
        editClient: function(finish) {
          return function(client) {
            var scratchPad = client;
            var modal = openModal({
              controller: ['$scope', function ($scope) {
                $scope.scratchPad = scratchPad;

                $scope.submit = function(e) {
                  finish.call(e, scratchPad);
                  $scope.close(e);
                };

                $scope.close = function(e) {
                  modal.dismiss(e);
                };
              }],
              modal: {
                dismissable: true,
                title: 'Edit Client',
                template: 'components/modal/tpl/client-create.html'
              }
            }, 'modal-success');
          };
        }
      },
      timeProductionReporting: {
        addTimeReport: function(title, employeeId, reportingType) {
          var clearField = function($scope, fieldExists, fieldToClear) {
            if($scope.scratchPad.show[fieldExists] && $scope.scratchPad.model[fieldToClear]) {
              delete $scope.scratchPad.model[fieldToClear];
              delete $scope.scratchPad.show[fieldToClear];
            }
          };

          var timeReportFields = {
            'work': [
              {label: 'Clock In', field: 'CIShift', displayFlag: true},
              {label: 'Break Out', field: 'COBreak1', displayFlag: false},
              {label: 'Break In', field: 'CIBreak1', displayFlag: false},
              {label: 'Lunch Out', field: 'COLunch', displayFlag: false},
              {label: 'Lunch In', field: 'CILunch', displayFlag: false},
              {label: 'Break Out', field: 'COBreak0', displayFlag: false},
              {label: 'Break In', field: 'CIBreak0', displayFlag: false},
              {label: 'Clock Out', field: 'COShift', displayFlag: false}
            ],
            travel: [
              {label: 'Start Travel', field: 'StartTravel',displayFlag: true},
              {
                label: 'Delay Travel',
                field: 'DelayTravel',
                displayFlag: true,
                onToggle: function($scope) {
                  clearField($scope, 'DelayTravel', 'EndTravel');
                }
              },
              {
                label: 'End Travel',
                field: 'EndTravel',
                displayFlag: true,
                onToggle: function($scope) {
                  clearField($scope, 'EndTravel', 'DelayTravel');
                }
              }
            ],
            leave: [
              {
                label: 'Worker\'s Comp',
                field: 'WC',
                displayFlag: true,
                onToggle: function($scope) {
                  var clearFields = ['Late', 'PTO'];

                  clearFields.forEach(function(field) {
                    clearField($scope, 'WC', field);
                  });
                }
              },
              {
                label: 'Late',
                field: 'Late',
                displayFlag: true,
                onToggle: function($scope) {
                  var clearFields = ['WC', 'PTO'];

                  clearFields.forEach(function(field) {
                    clearField($scope, 'Late', field);
                  });
                }
              },
              {
                label: 'PTO',
                field: 'PTO',
                displayFlag: true,
                onToggle: function($scope) {
                  var clearFields = ['WC', 'Late'];

                  clearFields.forEach(function(field) {
                    clearField($scope, 'PTO', field);
                  });
                }
              }
            ]
          };

          var timeFields = timeReportFields[reportingType || 'work'];
          var scratchPad = {
            shouldShowEmployeeDropdown: !employeeId,
            reportingType: reportingType,
            model: {
              isReportValid: null,
              shiftDate: moment().toDate(),
              destination: null, //will be used only if reportingType is 'work'
              date: moment().toDate()
            },
            employee: null,
            timeFields: timeFields,
            loading: false,
            verifyingReport: false,
            locations: [],
            show: {},
            displayReportDate:{}
          };
          var shiftId = '';
          return function(finish) {
            var cb = finish || angular.noop;

            var modal = openModal({
              controller: ['$scope', '$clientEmployee', '$clientTimeProductionReporting', '$clientSwitcher', '$clientOffice', '$q', 'ngToast', '$convertDate', '$auth', function ($scope, $clientEmployee, $clientTimeProductionReporting, $clientSwitcher, $clientOffice, $q, ngToast, $convertDate) {
                $scope.scratchPad = scratchPad;
                $scope.scratchPad.client = $clientSwitcher.getCurrentClient();
                $scope.dateFormat = 'MM/dd/yyyy';
                $scope.allLocations = [];
                $scope.clientStrings = function(){ return $clientSwitcher.getFeatureOptions('client.strings'); };

                $scope.verifyReport = function(fieldChanged) {
                  var report = $scope.scratchPad.model;
                  shiftId = '';
                  if(!report.locationId || !report.date || !$scope.scratchPad.employee) {
                    return;
                  }

                  if(fieldChanged === 'date') {
                    timeFields.forEach(function(timeField) {
                      var isShown = $scope.scratchPad.show[timeField.field];
                      $scope.timeFieldToggled(isShown, timeField);
                    });
                  }

                  $scope.scratchPad.verifyingReport = true;
                  $clientTimeProductionReporting.getRawRecords($scope.scratchPad.client, {
                    employeeId: $scope.scratchPad.employee.employeeId,
                    locationId: $scope.scratchPad.model.locationId,
                    date: $convertDate.process($scope.scratchPad.model.shiftDate),
                    destLocId: $scope.scratchPad.model.destination,
                    reportType: {
                      $in:  timeFields.map(function(timeField) {
                        return timeField.field;
                      })
                    }
                  })
                  .then(function(res) {
                    if(res.status) {
                      if(reportingType == 'work'){
                        var isValid = !res.data.totalCount;
                        $scope.scratchPad.model.isReportValid = isValid;

                        if(!isValid) {
                          timeFields.forEach(function(timeField) {
                            $scope.scratchPad.show[timeField.field] = false;
                            $scope.scratchPad.model[timeField.field] = null;
                          });
                        }
                      }
                      else if(reportingType == 'travel'){
                        var types = ["StartTravel", "DelayTravel", "EndTravel"];
                        if(res.data.totalCount == 1 ){
                          shiftId = res.data.records[0].shiftId;
                          types.forEach(function(type){
                              $scope.scratchPad.timeFields[types.indexOf(type)].displayFlag = true;
                          })
                          res.data.records.forEach(function(travelRecord){
                            if(types.indexOf(travelRecord.reportType) > -1){
                              $scope.scratchPad.timeFields[types.indexOf(travelRecord.reportType)].displayFlag = false;
                            }else{
                              $scope.scratchPad.timeFields[types.indexOf(travelRecord.reportType)].displayFlag = true;
                            }
                          })
                        }else{
                          types.forEach(function(type){
                            if(type == 'StartTravel'){
                              $scope.scratchPad.timeFields[types.indexOf(type)].displayFlag = true;
                            }else{
                              $scope.scratchPad.timeFields[types.indexOf(type)].displayFlag = false;
                            }
                          })
                        }
                        $scope.scratchPad.model.isReportValid = true;
                      }
                      else{
                        $scope.scratchPad.model.isReportValid = true;
                      }
                    }
                    else {
                      throw new Error(res.reason);
                    }
                  })
                  .catch(function(err) {
                    console.log(err);
                    $scope.scratchPad.model.isReportValid = false;
                  })
                  .finally(function() {
                    $scope.scratchPad.verifyingReport = false;
                  });
                };

                $scope.timeFieldToggled = function(newValue, timeField) {
                  if(timeField.onToggle) {
                    timeField.onToggle($scope);
                  }

                  if(timeField.field === 'CIShift'){
                    timeFields.forEach(function (timeField) {
                      timeField.displayFlag = newValue;
                    });
                    timeFields[0].displayFlag = true;
                    if(!newValue){
                      $scope.scratchPad.show['COShift'] = false;
                      $scope.scratchPad.model['COShift'] = null;
                    }
                  }

                  if(timeField.field === 'StartTravel'){
                    timeFields.forEach(function (timeField) {
                      timeField.displayFlag = newValue;
                    });
                    timeFields[0].displayFlag = true;
                  }

                  $scope.scratchPad.model[timeField.field] = newValue ?
                    moment($scope.scratchPad.model.date).toDate() :
                    null;
                };

                $scope.cancel = function() {
                  modal.dismiss();
                };

                $scope.submit = function() {
                  $scope.scratchPad.loading = true;



                  //discuss this
                  // if($scope.scratchPad.model.CIShift && $scope.scratchPad.model.COShift && $scope.scratchPad.model.CIShift > $scope.scratchPad.model.COShift) {
                  //   $scope.scratchPad.model.COShift = moment($scope.scratchPad.model.COShift).add(1, 'day').toDate();
                  // }

                  $scope.scratchPad.records = $scope.scratchPad.timeFields
                    .map(function(timeField) {
                      var reportType = timeField.field;
                      var reportTime = $scope.scratchPad.model[reportType];
                      var locationId = $scope.scratchPad.model.locationId;

                      if(['COBreak0', 'COBreak1'].indexOf(reportType) > -1) {
                        reportType = 'COBreak';
                      }
                      if(['CIBreak0', 'CIBreak1'].indexOf(reportType) > -1) {
                        reportType = 'CIBreak';
                      }

                      var shiftDate = moment($scope.scratchPad.model.shiftDate);
                      if(reportTime){
                        if(reportType  == 'COShift' && $scope.scratchPad.displayReportDate.COShift){
                          reportTime = moment(reportTime).toDate();
                        }else{
                          // shiftDate = moment(shiftDate).hour(moment(reportTime).hour()).minute(moment(reportTime).minute());
                          reportTime = moment(reportTime).year(shiftDate.year()).month(shiftDate.month()).date( shiftDate.date() ).toDate();
                        }
                      }
                      var record = {
                        employeeId: $scope.scratchPad.employee.employeeId,
                        shiftDate: $convertDate.process(shiftDate.toDate()),
                        reportTime: $convertDate.process(reportTime),
                        locationId: locationId,
                        reportType: reportType,
                        destination: $scope.scratchPad.model.destination,
                        duration: $scope.scratchPad.model.duration,
                        employeeTimezone: $scope.scratchPad.employee.timeZone
                      }
                      if(['StartTravel', 'DelayTravel', 'EndTravel'].indexOf(reportType) > -1){
                        if(shiftId !== ''){
                          record.shiftId = shiftId;
                        }
                      }
                      return reportTime ? record : null;
                    })
                    .filter(function(record) {
                      return !!record;
                    });

                  $clientTimeProductionReporting.createRecords($scope.scratchPad.client, $scope.scratchPad.records)
                    .then(function(res) {
                      if(res.status) {
                        ngToast.create({
                          content: 'Report created successfully',
                          className: 'success'
                        });

                        modal.close($scope.scratchPad);
                      }
                      else {
                        ngToast.create({
                          content: res.reason,
                          className: 'danger'
                        });
                      }
                    })
                    .catch(function(err) {
                      ngToast.create({
                        content: 'Something went worng, please try again.',
                        className: 'danger'
                      });
                    })
                    .finally(function() {
                      $scope.scratchPad.loading = false;
                    });
                };

                $scope.selectEmployee = function(employeeId) {
                  $scope.scratchPad.locations = [];
                  $scope.scratchPad.employee = null;

                  if(!employeeId) {
                    return;
                  }

                  var employee = _.find($scope.allEmployees, function(employeeObj) {
                    return  employeeObj.data.employeeId === employeeId;
                  });

                  if(!employee) {
                    return console.error('employee with employeeId ' + employeeId + ' not found');
                  }

                  employee = employee.data;

                  var homeLocation = employee.homeLocation;

                  if(homeLocation && homeLocation.internalId) {
                    $scope.scratchPad.locations.push({
                      label: homeLocation.name,
                      value: homeLocation.internalId
                    });
                  }

                  $scope.scratchPad.employee = employee;
                  $scope.scratchPad.employee.locations.forEach(function(location) {
                    if(
                      homeLocation &&
                      homeLocation.internalId &&
                      location.internalId &&
                      location.internalId !== homeLocation.internalId
                    ) {
                      $scope.scratchPad.locations.push({
                        label: location.name,
                        value: location.internalId
                      });
                    }else if(!homeLocation){
                      $scope.scratchPad.locations.push({
                        label: location.name,
                        value: location.internalId
                      });
                    }
                  });
                };

                function init() {
                  $scope.scratchPad.loading = true;
                  if(timeFields){
                    timeFields.forEach(function(timeField) {
                      $scope.scratchPad.show[timeField.field] = false;
                    });
                  }
                  $q.when()
                  .then(function() {
                    return $clientEmployee.getEmployees($scope.scratchPad.client, {})
                      .then(function(res) {

                        if(res.status) {
                          $scope.allEmployees = res.data.map(function(employee) {
                            var
                            label = employee.firstName + ' ' + employee.lastName;

                            return {
                              label: label,
                              value: employee.employeeId,
                              data: employee
                            };
                          });

                          //pre select first employee
                          return res.data[0];
                        }
                        else {
                          throw new Error(res.reason);
                        }
                      });
                  })
                  .then(function(employee) {

                    if($scope.scratchPad.shouldShowEmployeeDropdown) {
                      return $scope.allEmployees && $scope.allEmployees[0] && $scope.allEmployees[0].data;
                    }
                    else {
                      return $clientEmployee.detail($scope.scratchPad.client, employeeId)
                      .then(function(res) {
                        if(res.status) {
                          return res.data;
                        }
                        else {
                          throw new Error(res.reason);
                        }
                      });
                    }
                  })
                  .then(function(employee) {
                    if(!$scope.scratchPad.shouldShowEmployeeDropdown) {
                      $scope.scratchPad.employeeId = employee.employeeId;

                      $scope.selectEmployee(employee.employeeId);
                    }
                    return $clientOffice.listAll($scope.scratchPad.client);
                  })
                  .then(function(result) {
                    $scope.allLocations = result.map(function(location) {
                      return {
                        label: location.name,
                        value: location.internalId
                      };
                    });
                  })
                  .finally(function() {
                    $scope.scratchPad.loading = false;
                  });
                }

                init();
              }],
              modal: {
                dismissable: true,
                title: title,
                template: 'components/modal/tpl/add-time-reporting-record.html'
              }
            }, 'modal-success');

            modal.result
            .then(function(event) {
              cb.call(event, scratchPad);
            });
          };
        },
        addProductionReport: function(employeeId, recordToUpdate) {
          var units = ['Quarters','Dimes','Nickels','Pennies','Dollars','HalfDollars','IKES'];
          var noUnitsSupported = ['Verifying', 'NoProduction', 'RollCracking', 'Conversions'];
          var productionTypes = {
            'Boxes': ['FullBoxes', 'HalfBoxes', '10RollBoxes', '30CountBoxes', 'ChangeOvers'],
            'Bags': ['FullBags', 'HalfBags', '10RollBags', '30CountBags'],
            'Verifying': ['Bags', 'Bins', 'SortEnvlopes', 'RollCrackVerify', 'BagsWeighed', 'BagsOutOfTolerance'],
            'RollCracking': ['MachineCrack', 'HandCrack', 'Bags1_50', 'Bags50Plus'],
            'ChangeOvers': [],
            'BagsWeighed': [],
            'NoProduction': []
          };
          var notAvailable = 'N/A';
          var scratchPad = {
            shouldShowEmployeeDropdown: !employeeId,
            isUpdateOperation: !!recordToUpdate,
            model: {
              recordType: 'production',
              employeeId: null,
              locationId: recordToUpdate ? recordToUpdate.locationId : null,
              reportTime: new Date(),
              prodType: null,
              prodSize: null,
              prodCurrency: null,
              prodAmount: null,
              tolerance: 0,
              shiftDate: recordToUpdate? recordToUpdate.shiftDate : new Date()
            },
            employee: null,
            loading: false,
            locations: []
          };

          // if(recordToUpdate) {
          //   scratchPad.model = _.merge(scratchPad.model, recordToUpdate);
          // }

          return function(finish) {
            var cb = finish || angular.noop;

            var modal = openModal({
              controller: ['$scope', '$clientEmployee', '$clientTimeProductionReporting', '$clientSwitcher', '$q', '$convertDate', function ($scope, $clientEmployee, $clientTimeProductionReporting, $clientSwitcher, $q, $convertDate) {
                $scope.scratchPad = scratchPad;
                $scope.scratchPad.client = $clientSwitcher.getCurrentClient();
                $scope.dateFormat = 'MM/dd/yyyy';

                $scope.alertItemsUnit = units.map(function(unit) {
                  return {
                    label: unit,
                    value: unit
                  };
                });
                $scope.alertItemsProduction = Object.keys(productionTypes).map(function(type) {
                  return {
                    label: type,
                    value: type
                  };
                });

                $scope.productionTypeChanged = function(prodType) {
                  if(prodType === 'NoProduction') {
                    $scope.scratchPad.model.prodAmount = undefined;
                  }
                  if(['Bags', 'Boxes'].indexOf(prodType) === -1) {
                    delete $scope.scratchPad.model.prodCurrency;
                  }
                  if(['BagsWeighed', 'ChangeOvers'].indexOf(prodType) > -1) {
                    delete $scope.scratchPad.model.prodSize;
                  }

                  if(noUnitsSupported.indexOf(prodType) > -1) {
                    $scope.scratchPad.model.prodCurrency = notAvailable;
                    $scope.alertItemsUnit = [{
                      label: notAvailable,
                      value: notAvailable,
                      disableIf: function() {
                        return true;
                      }
                    }];
                  }
                  else {
                    $scope.alertItemsUnit = units.map(function(unit){
                      return {
                        label: unit,
                        value: unit,
                      };
                    });
                  }

                  $scope.alertItemsType = productionTypes[prodType].map(function(val) {
                    return {
                      label: val,
                      value: val
                    };
                  });
                };
                $scope.clientStrings = function(){ return $clientSwitcher.getFeatureOptions('client.strings'); };

                var getEmployee = function() {
                  if($scope.scratchPad.shouldShowEmployeeDropdown) {
                    return $scope.allEmployees && $scope.allEmployees[0] && $scope.allEmployees[0].data;
                  }
                  else {

                    return $clientEmployee.detail($scope.scratchPad.client, employeeId)
                    .then(function(res) {
                      if(res.status) {
                        return res.data;
                      }
                      else {
                        throw new Error(res.reason);
                      }
                    });
                  }
                };

                $scope.cancel = function() {
                  modal.dismiss();
                };

                $scope.submit = function() {
                  var dateFields = ['shiftDate', 'reportTime'];

                  dateFields.forEach(function(dateField) {
                    $scope.scratchPad.model[dateField] = $convertDate.process($scope.scratchPad.model[dateField]);
                  });
                  $scope.scratchPad.model['shiftDate'] = $convertDate.process(moment($scope.scratchPad.model.shiftDate).startOf('day')._d);
                  modal.close($scope.scratchPad);
                };

                $scope.selectEmployee = function(employeeId) {
                  $scope.scratchPad.locations = [];
                  $scope.scratchPad.employee = null;

                  if(!employeeId) {
                    return;
                  }

                  var employee = _.find($scope.allEmployees, function(employeeObj) {
                    return  employeeObj.data.employeeId === employeeId;
                  });

                  if(!employee) {
                    return console.error('employee with employeeId ' + employeeId + ' not found');
                  }

                  employee = employee.data;

                  $scope.scratchPad.employee = employee;
                  $scope.scratchPad.model.employeeId = employeeId;
                  var homeLocation = employee.homeLocation;

                  if(homeLocation && homeLocation.internalId) {
                    $scope.scratchPad.locations.push({
                      label: homeLocation.name,
                      value: homeLocation.internalId
                    });
                  }

                  employee.locations.forEach(function(location) {
                    if(
                      location.internalId &&
                      (!homeLocation || !homeLocation.internalId || location.internalId !== homeLocation.internalId)
                    ) {
                    $scope.scratchPad.locations.push({
                        label: location.name,
                        value: location.internalId
                      });
                    }
                  });
                };

                function init() {
                  $scope.scratchPad.loading = true;
                  if(recordToUpdate && recordToUpdate.shiftDate) {
                    $scope.scratchPad.model.shiftDate = moment(recordToUpdate.shiftDate).startOf('day')._d;
                  }
                  else {
                    $scope.scratchPad.model.shiftDate = moment().startOf('day')._d;//moment().toDate();
                  }

                  $q.when()
                  .then(function() {
                    return $clientEmployee.getEmployees($scope.scratchPad.client, {})
                      .then(function(res) {

                        if(res.status) {
                          $scope.allEmployees = res.data.map(function(employee) {
                            var
                            label = employee.firstName + ' ' + employee.lastName;

                            return {
                              label: label,
                              value: employee.employeeId,
                              data: employee
                            };
                          });

                          //pre select first employee
                          return res.data[0];
                        }
                        else {
                          throw new Error(res.reason);
                        }
                      });
                  })
                  .then(function() {
                    return getEmployee()
                  })
                  .then(function(employee) {
                    $scope.selectEmployee(employee.employeeId);
                  })
                  .finally(function() {
                    $scope.scratchPad.loading = false;
                  });
                }

                init();
              }],
              modal: {
                dismissable: true,
                title: 'Add Production Report',
                template: 'components/modal/tpl/production-report.html'
              }
            }, 'modal-success');

            modal.result.then(function(event) {
              if(scratchPad.model.prodCurrency === notAvailable) {
                scratchPad.model.prodCurrency = undefined;
              }
              cb.call(event, scratchPad);
            });
          };
        },
        workOvertimeReport: function (record) {
          return function () {
            var modal = openModal({
              controller: ['$scope', '$clientTimeProductionReporting', '$q', function ($scope, $clientTimeProductionReporting, $q) {
                var clientId = record.client;
                var employeeId = record.employeeId;
                var scratchPad = {
                  loading: false,
                  rowColumnChangeFlage: false
                }
                $scope.scratchPad = scratchPad
                $scope.currentWeek = function () {
                  scratchPad.rowColumnChangeFlage = true;
                  init();
                }

                $scope.next = function () {
                  scratchPad.rowColumnChangeFlage = true;
                  nextWeek($scope.currentWeekWithEndDate)
                }

                $scope.previous = function () {
                  scratchPad.rowColumnChangeFlage = true;
                  previousWeek($scope.currentWeekWithStartDate)
                }

                $scope.minuteToHoursConvert = function(mins){
                  if(mins == 0){
                    return mins;
                  }
                  var h = Math.floor(mins / 60);
                  var m = mins % 60;
                  h = h < 10 ? '0' + h : h;
                  m = m < 10 ? '0' + m : m;
                  return h + ":" +m;
                }

                function previousWeek(currentWeekStartDate) {
                  var lastWeekStartOf = moment(moment(currentWeekStartDate).subtract(1, 'days')).startOf('week').isoWeekday("Sunday")._d;
                  var lastWeekEndOf = moment(moment(currentWeekStartDate).subtract(1, 'days')).endOf('week').isoWeekday("Saturday")._d;
                  console.log(lastWeekStartOf + " - " + lastWeekEndOf)
                  $scope.currentWeekWithStartDate = lastWeekStartOf;
                  $scope.currentWeekWithEndDate = lastWeekEndOf;
                  getOverTimeRecords();
                }

                function nextWeek(currentWeekEndDate) {
                  var nextWeekStartOf = moment(moment(currentWeekEndDate).add(1, 'days')).startOf('week').isoWeekday("Sunday")._d;
                  var nextWeekEndOf = moment(moment(currentWeekEndDate).add(1, 'days')).endOf('week').isoWeekday("Saturday")._d;
                  console.log(nextWeekStartOf + " - " + nextWeekEndOf)
                  $scope.currentWeekWithStartDate = nextWeekStartOf;
                  $scope.currentWeekWithEndDate = nextWeekEndOf;
                  getOverTimeRecords();

                }

                function init() {
                  var startWeekDate = moment().startOf('week').isoWeekday("Sunday")._d
                  var endWeekDate = moment().endOf('week').isoWeekday("Saturday")._d
                  console.log(startWeekDate + " - " + endWeekDate)
                  $scope.currentWeekWithStartDate = startWeekDate;
                  $scope.currentWeekWithEndDate = endWeekDate;
                  getOverTimeRecords();
                }

                function getOverTimeRecords() {
                  return $clientTimeProductionReporting.getWeekOvertimeReport(clientId, employeeId, {
                    startWeekDate: $scope.currentWeekWithStartDate,
                    endWeekDate: $scope.currentWeekWithEndDate
                  })
                    .then(function (res) {
                      if (res.status) {
                        $scope.overtimeRecords = res.data.records.overtimeRecord;
                        $scope.overtimeTotalRecords = res.data.records.overtimeTotalRecord;
                        if(!scratchPad.rowColumnChangeFlage){
                          scratchPad.rowColumnChangeFlage = true;
                          scratchPad.loading = true;
                          setTimeout(function () {
                            $("#test").each(function () {
                              var $this = $(this);
                              var newrows = [];
                              $this.find("tr").each(function () {
                                var i = 0;
                                $(this).find("td").each(function () {
                                  i++;
                                  if (newrows[i] === undefined) { newrows[i] = $("<tr></tr>"); }
                                  newrows[i].append($(this));
                                });
                              });
                              $this.find("tr").remove();
                              $.each(newrows, function () {
                                $this.append(this);
                              });
                            });

                          }, 100);
                        }
                      } else {
                        throw new Error(res.reason);
                      }
                    })
                }

                init();
              }],
              modal: {
                dismissable: true,
                title: record.firstName + " " + record.lastName + " - Time Report",
                template: 'components/modal/tpl/work-overtime-report.html'
              }
            }, 'modal-success', 'lg');
          }
        },
        moveProductionReport: function (group, record) {
          return function (finish) {
            var cb = finish || angular.noop;
            var scratchPad = {
              model: {
                recordType: 'production',
                employeeId: null,
                oldLocationId: record.locationId,
                locationId: null,
                shiftDate: new Date(),
                shiftId : record.shiftId
              }
            }
            var modal = openModal({
              controller: ['$scope', '$clientTimeProductionReporting', '$q', 'ngToast', '$clientSwitcher', '$clientEmployee', function ($scope, $clientTimeProductionReporting, $q, ngToast, $clientSwitcher, $clientEmployee) {
                $scope.scratchPad = scratchPad;
                $scope.scratchPad.client = $clientSwitcher.getCurrentClient();
                $scope.clientStrings = function(){ return $clientSwitcher.getFeatureOptions('client.strings'); };

                $scope.selectEmployee = function(employeeId) {
                  $scope.scratchPad.locations = [];
                  $scope.scratchPad.employee = null;
                  if(!employeeId) {
                    return;
                  }

                  var employee = _.find($scope.allEmployees, function(employeeObj) {
                    return  employeeObj.data.employeeId === employeeId;
                  });

                  if(!employee) {
                    return console.error('employee with employeeId ' + employeeId + ' not found');
                  }

                  employee = employee.data;
                  $scope.scratchPad.employee = employee;
                  $scope.scratchPad.model.employeeId = employeeId;
                  var homeLocation = employee.homeLocation;

                  if(homeLocation && homeLocation.internalId) {
                    $scope.scratchPad.locations.push({
                      label: homeLocation.name,
                      value: homeLocation.internalId
                    });
                  }

                  employee.locations.forEach(function(location) {
                    if(
                      location.internalId &&
                      (!homeLocation || !homeLocation.internalId || location.internalId !== homeLocation.internalId)
                    ) {
                    $scope.scratchPad.locations.push({
                        label: location.name,
                        value: location.internalId
                      });
                    }
                  });
                };

                var getEmployee = function() {
                  if($scope.scratchPad.shouldShowEmployeeDropdown) {
                    return $scope.allEmployees && $scope.allEmployees[0] && $scope.allEmployees[0].data;
                  }
                  else {

                    return $clientEmployee.detail($scope.scratchPad.client, record.employeeId)
                    .then(function(res) {
                      if(res.status) {
                        return res.data;
                      }
                      else {
                        throw new Error(res.reason);
                      }
                    });
                  }
                };

                $scope.submit = function() {
                  var message = "Are you sure you want to move production records?";
                  modals.confirm.show("Confirm Move Records", message, "Yes", "Cancel", function(){
                        modal.close($scope.scratchPad);
                  });
                }

                $scope.cancel = function() {
                  modal.dismiss();
                };

                function init() {
                  $scope.scratchPad.loading = true;
                  if(record && record.shiftDate) {
                    $scope.scratchPad.model.shiftDate = moment(record.shiftDate).startOf('day')._d;
                  }
                  else {
                    $scope.scratchPad.model.shiftDate = moment().startOf('day')._d;//moment().toDate();
                  }

                  $q.when()
                  .then(function() {
                    return $clientEmployee.getEmployees($scope.scratchPad.client, {})
                      .then(function(res) {

                        if(res.status) {
                          $scope.allEmployees = res.data.map(function(employee) {
                            var
                            label = employee.firstName + ' ' + employee.lastName;

                            return {
                              label: label,
                              value: employee.employeeId,
                              data: employee
                            };
                          });

                          //pre select first employee
                          return res.data[0];
                        }
                        else {
                          throw new Error(res.reason);
                        }
                      });
                  })
                  .then(function() {
                    return getEmployee()
                  })
                  .then(function(employee) {
                    $scope.selectEmployee(employee.employeeId);
                  })
                  .finally(function() {
                    $scope.scratchPad.loading = false;
                  });
                }

                init();
              }],
              modal: {
                dismissable: true,
                title: record.firstName + " " + record.lastName + " - Move Production Report",
                template: 'components/modal/tpl/move-time-reporting-record.html'
              }
            }, 'modal-success');
            modal.result.then(function(event) {
              cb.call(event, scratchPad);
            });
          }
        },
        addShiftDetailsInReport: function(group, record){
          return function (finish) {
            var cb = finish || angular.noop;
            var scratchPad = {
              model: {
                employeeId: record.employeeId,
                locationId: record.locationId,
                shiftDate: record.reportDate || new Date(),
                employeeName: record.firstName +" "+ record.lastName + " | "+ record.employeeId,
                shiftId : record.shiftId,
                holiday: record.shiftDetails ? record.shiftDetails.holiday : null,
                kyvw: record.shiftDetails ? record.shiftDetails.kyvw : null,
                pioEntered: record.shiftDetails ? record.shiftDetails.pioEntered : null,
                bereavement: record.shiftDetails ? record.shiftDetails.bereavement : null,
                notes: record.shiftDetails ? record.shiftDetails.notes : null,
              }
            }
            var modal = openModal({
              controller: ['$scope', '$clientTimeProductionReporting', '$q', 'ngToast', '$clientSwitcher', '$clientEmployee', function ($scope, $clientTimeProductionReporting, $q, ngToast, $clientSwitcher, $clientEmployee) {
                $scope.scratchPad = scratchPad;
                $scope.scratchPad.client = $clientSwitcher.getCurrentClient();
                $scope.clientStrings = function(){ return $clientSwitcher.getFeatureOptions('client.strings'); };
                $scope.dateFormat = 'MM/dd/yyyy';
                $scope.submit = function() {
                  modal.close($scope.scratchPad);
                }

                $scope.cancel = function() {
                  modal.dismiss();
                }

              }],
              modal: {
                dismissable: true,
                title: 'Add Shift Details',
                template: 'components/modal/tpl/add-shift-details.html'
              }
            }, 'modal-success');
            modal.result.then(function(event) {
              cb.call(event, scratchPad);
            });
          }
        }
      },
      holidayActions: {
        addHoliday: function(finish) {
          return function() {
            var scratchPad = {};

            var modal = openModal({
              controller: ['$scope', '$timeout', function ($scope, $timeout) {
                $scope.scratchPad = scratchPad;

                $scope.submit = function(e) {
                  finish.call(e, scratchPad);
                  $scope.close(e);
                };

                $scope.close = function(e) {
                  modal.dismiss(e);
                };

                function initDateRangePicker() {
                  function cb(start, end) {
                    $timeout(function() {
                      if (!start || !end) {
                        return;
                      }

                      $('#holidayrange span').html(start.format('MM/DD/YYYY') + ' to ' + end.format('MM/DD/YYYY'));
                      $scope.scratchPad.start = start.hour(0).minute(0).seconds(0).toDate();
                      $scope.scratchPad.end = end.hour(23).minute(59).seconds(59).toDate();
                    }, 0);
                  }

                  $('#holidayrange').daterangepicker({
                    startDate: moment($scope.scratchPad.start),
                    endDate: moment($scope.scratchPad.end),
                    opens: 'right'
                  }, cb);

                  $('#holidayrange span').html(moment($scope.scratchPad.start).format('MM/DD/YYYY') + ' to ' + moment($scope.scratchPad.end).format('MM/DD/YYYY'));
                }
                modal.rendered.then(function() {
                  $timeout(function() {
                    initDateRangePicker();
                  }, 100);
                });
              }],
              modal: {
                dismissable: true,
                title: 'Add Holiday',
                template: 'components/modal/tpl/add-holiday.html'
              }
            }, 'modal-success');
          };
        },
        editHoliday: function(finish) {
          return function(holiday) {
            var scratchPad = angular.copy(holiday);

            var modal = openModal({
              controller: ['$scope', '$timeout', function ($scope, $timeout) {
                $scope.scratchPad = scratchPad;

                $scope.update = function(e) {
                  scratchPad.event = 'update';

                  finish.call(e, scratchPad);
                  $scope.close(e);
                };

                $scope.close = function(e) {
                  modal.dismiss(e);
                };

                $scope.delete = function(e) {
                  modals.confirm.delete(function() {
                    scratchPad.event = 'delete';

                    finish.call(e, scratchPad);
                    $scope.close(e);
                  })(scratchPad.title);
                };

                function initDateRangePicker() {
                  function cb(start, end) {
                    $timeout(function() {
                      if (!start || !end) {
                        return;
                      }

                      $('#holidayrange span').html(start.format('MM/DD/YYYY') + ' to ' + end.format('MM/DD/YYYY'));
                      $scope.scratchPad.start = start.hour(0).minute(0).seconds(0).toDate();
                      $scope.scratchPad.end = end.hour(23).minute(59).seconds(59).toDate();
                    }, 0);
                  }

                  $('#holidayrange').daterangepicker({
                    startDate: moment($scope.scratchPad.start),
                    endDate: moment($scope.scratchPad.end),
                    opens: 'right'
                  }, cb);

                  $('#holidayrange span').html(moment($scope.scratchPad.start).format('MM/DD/YYYY') + ' to ' + moment($scope.scratchPad.end).format('MM/DD/YYYY'));
                }
                modal.rendered.then(function() {
                  $timeout(function() {
                    initDateRangePicker();
                  }, 100);
                });
              }],
              modal: {
                dismissable: true,
                title: 'Edit Holiday',
                template: 'components/modal/tpl/edit-holiday.html'
              }
            }, 'modal-success');
          };
        }
      },
      otcAnyWhereActions: {
        closeLock: function () {
          return function (finish) {
            var cb = finish || angular.noop;
            var modal;
            var scratchPad = {
              closeCode:''
            };
            var modal = openModal({
              controller: ['$scope',  function ($scope) {
                $scope.scratchPad = scratchPad;
                $scope.submit = function() {
                  modal.close($scope.scratchPad);
                }
                $scope.cancel = function() {
                  modal.dismiss();
                };
              }],
              modal: {
                dismissable: true,
                title: 'Close Lock',
                template: 'components/modal/tpl/otc-any-where.html'
              }
            }, 'modal-success');
            modal.result
            .then(function(event) {
              cb.call(event, scratchPad);
            });
          };
        },
      },
      reportBuckets: {
        bucketInAttachments: function(bucket, interpreterDataSets, finish){
          finish = finish || angular.noop;
          return function () {
            if(!bucket) {
              return;
            }

            var
            callModal = openModal({

              controller: ['$scope', '$clientSwitcher', '$clientTask', '$authStorage', '$q', 'ngToast', '$clientOffice', 'appConfig', function ($scope, $clientSwitcher, $clientTask, $authStorage, $q, ngToast, $clientOffice, appConfig) {
                $scope.interpreterDataSets = interpreterDataSets;
                $scope.allValue = 'all';
                $scope.model = {
                  interpreter: null, //$scope.allValue
                  selectedItem: 'all',
                  selectedOffice: null
                }
                $scope.currentClient = $clientSwitcher.getCurrentClient();
                $scope.alertItemsOffice = []; // Office/Location as courts for SOSI client
                $scope.coiDataSets = [];
                $scope.clientStrings = function(){ return $clientSwitcher.getFeatureOptions('client.strings'); };

                // Office/Location as courts for SOSI client
                function fetchOffices() {
                  $clientOffice.listAll($scope.currentClient)
                    .then(function (offices) {
                      $scope.alertItemsOffice = offices.map(function (location) {
                        return {
                          label: location.name,
                          value: location.internalId,
                          id: location._id
                        };
                      });
                    });
                }

                fetchOffices();

                $scope.getRecordForReportingCourt = function(bucket) {
                  if ($scope.model.selectedOffice != null) {
                      if ($scope.model.selectedOffice == 'all') {
                          return;
                      }
                      if ($scope.model.selectedOffice.length > 0) {
                          var filter = {
                              startDate: moment(bucket.attachmentDate).format('YYYY-MM-DD'),
                              endDate: moment(bucket.attachmentDate).format('YYYY-MM-DD'),
                              reportingCourt: [$scope.model.selectedOffice]
                          }

                          $officeOperations.getRecordForReportingCourt($scope.currentClient, filter)
                              .then(function (results) {
                                  if (results.status) {
                                      var coiDataSets = [];
                                      results.data.forEach(function (result) {
                                          coiDataSets.push({
                                              label: result.coi,
                                              value: result.coi
                                          })
                                      })
                                      $scope.coiDataSets = _.sortBy(_.uniq(coiDataSets, 'label'), ['label']);
                                  }
                              })
                      } else {
                        $scope.coiDataSets = [];
                      }
                  }
              }

                bucket.uploader = new FileUploader({
                  url: appConfig.apiUrl + '/api/client-uploads/'+$scope.currentClient._id+'?access_token='+$authStorage.getToken(),
                  alias: 'upload',
                  filters: [
                    {
                      name: 'extensionFilter',
                      fn: function(item) {
                        var extension = item.name.substr(item.name.lastIndexOf('.')+1);
                        var invalidExtensions = ['exe', 'bin'];
                        if(invalidExtensions.indexOf(extension) > -1) {
                          ngToast.create({
                            className: 'danger',
                            content: 'Files with extensions '+invalidExtensions+' are not allowed'
                          });
                          return false;
                        } else {
                          return true;
                        }
                      }
                    }
                  ]
                });

                $scope.uploadFiles = function () {
                  var
                  uploader = !!bucket ? bucket.uploader : false,
                  results = [],
                  errors = [];

                  if(uploader && uploader.queue && uploader.queue.length) {
                    var defer = $q.defer();

                    uploader.onSuccessItem = function(fileItem, response, status, headers) {
                      Array.prototype.push.apply(results, response);
                    };

                    uploader.onErrorItem = function(fileItem, response, status, headers) {
                      errors.push(new Error('Failed to upload file: ' + fileItem.file.name));
                    };

                    uploader.onCompleteAll = function() {
                      if(errors.length > 0) { // fail on any errors
                        defer.reject(errors);
                        return;
                      }

                      defer.resolve(results);
                    };

                    uploader.uploadAll();

                    return defer.promise;
                  }
                };

                $scope.bucket = bucket;

                $scope.buttonClick = function(buttonText) {
                  if($scope.model.interpreter){
                    bucket.interpreter = {
                      vendorid: $scope.model.interpreter,
                      name: $scope.model.selectedItem.name.toString()
                    };
                  }

                  if($scope.model.selectedOffice){
                    var selectedOffice = _.find($scope.alertItemsOffice, function(office) {
                      return office.value == $scope.model.selectedOffice;
                    });
                    bucket.selectedOffice = selectedOffice.id;
                  }

                  if($scope.uploadFiles()){
                    return $scope.uploadFiles()
                    .then(function (uploaded) {
                      if(uploaded && uploaded.length) { // add these to the model so when it creates the task, it will associate these items.
                        bucket.uploads = uploaded.map(function (upload) {
                          return upload._id;
                        });
                        if(bucket){
                          callModal.close();
                          finish(bucket);
                        }
                      }
                    });
                  }
                };

                $scope.close = function(e){
                  callModal.close(e);
                }

              }],
              modal: {
                dismissable: true,
                title: 'Add Attachments',
                template: 'components/modal/tpl/bucket-in-attachments.html',

                // buttons: [{
                //   classes: 'btn-primary',
                //   text: 'Add',
                // },{
                //   classes: 'btn-default',
                //   text: 'Close',
                //   click: function(e) {
                //     callModal.close(e);
                //   }
                // }]
              }
            }, 'modal-success');
          }
        },
        addOrUpdateReportsFolder: function (reportsLibrary, modalLabel, finish) {
          finish = finish || angular.noop;
          return function () {
            var scratchPad = {
              model: {
                name: null
              },
              isLoading: false,
              error: null
            };
            var modal = openModal({
              controller: ['$scope', '$clientSwitcher', '$clientReportBuckets', function ($scope, $clientSwitcher, $clientReportBuckets) {
                $scope.scratchPad = scratchPad;
                const client = $clientSwitcher.getCurrentClient();
                $scope.scratchPad.model.name = reportsLibrary && reportsLibrary.name ? reportsLibrary.name : null;

                $scope.buttonClick = function (e) {
                  $scope.scratchPad.isLoading = true;
                  if (reportsLibrary && reportsLibrary.name) {
                    reportsLibrary.name = $scope.scratchPad.model.name
                    $clientReportBuckets.update(client, reportsLibrary)
                      .then((res) => {
                        if (res && res.status) {
                          modal.dismiss();
                          finish.call(e, true);
                        }
                      })
                      .catch((err) => {
                        let errMessage = err.message || err;
                        if (err && err.data && err.data.message) errMessage = err.data.message;
                        $scope.scratchPad.error = errMessage;
                      })
                      .finally(() => {
                        $scope.scratchPad.isLoading = false;
                      })
                  } else {
                    $clientReportBuckets.create(client, $scope.scratchPad.model)
                      .then((res) => {
                        if (res && res.message) {
                          $scope.scratchPad.error = res.message;
                        } else {
                          modal.dismiss();
                          finish.call(e, true);
                        }
                      })
                      .catch((err) => {
                        let errMessage = err.message || err;
                        if (err && err.data && err.data.message) errMessage = err.data.message;
                        $scope.scratchPad.error = errMessage;
                      })
                      .finally(() => {
                        $scope.scratchPad.isLoading = false;
                      })
                  }
                };

                $scope.cancel = function (e) {
                  modal.dismiss();
                  finish.call(e, false);
                }
              }],
              modal: {
                dismissable: true,
                title: modalLabel,
                template: 'components/modal/tpl/add-knowledge-folder.html'
              }
            }, 'modal-success');
          };
        },
        reportLibraryFileUpload: function (data, finish) {
          finish = finish || angular.noop;

          return function () {
            const modal = openModal({
              controller: ['$scope', '$clientReportBucketsUpload', '$clientSwitcher', 'ngToast', '$q', '$uploadS3Attachment', function ($scope, $clientReportBucketsUpload, $clientSwitcher, ngToast, $q, $uploadS3Attachment) {
                const client = $clientSwitcher.getCurrentClient();
                $scope.dateLabel = 'Date Tag';
                $scope.validateFileOverwrite = true;
                $scope.isFileUploading = false;
                $scope.model = {
                  bucket: data.bucketId,
                  uploadType: 'FILE',
                  isShowAttachmentDate: true
                }
                $scope.model.uploader = [];
                $scope.validateForFolder = {
                  view: 'RL',
                  bucket: data.bucketId,
                  uploadType: 'FILE',
                };
                if(data.parentFolderId) {
                  $scope.validateForFolder.parentFolderId = data.parentFolderId;
                }

                $scope.$watch(function() {
                  return JSON.stringify({isOverwrite: $scope.model.isOverwrite});
                }, function(nv, ov) {
                  if(nv && nv !== ov) {
                    if($scope.model.isOverwrite) {
                      $scope.buttonClick();
                    } else {
                      finish.call(false);
                      modal.close();
                    }
                  }
                });

                $scope.buttonClick = function (e) {
                  $scope.isFileUploading = true;
                  $scope.error = null;
                  if ($scope.model.uploader && $scope.model.uploader.queue && $scope.model.uploader.queue.length) {
                    $uploadS3Attachment.upload(client, $scope.model.uploader, $scope, 'control1')
                      .then((imageRes) => {
                        $scope.model.attachmentFiles = [];
                        if (imageRes && imageRes.length) {
                          imageRes.forEach(function (upload) {
                            if (upload.status) {
                              $scope.model.attachmentFiles.push(upload.clientUpload._id);
                            }
                          });
                          let obj = {
                            attachmentFiles: $scope.model.attachmentFiles,
                            bucket: $scope.model.bucket,
                            uploadType: $scope.model.uploadType,
                            attachmentDate: $scope.model.attachmentDate
                          }
                          if (data.parentFolderId) obj.parentFolder = data.parentFolderId;
                          if ($scope.model.isOverwrite) {
                            obj.isOverwrite = $scope.model.isOverwrite;
                            obj.currentFileObjId = $scope.model.currentFileObjId;

                            return $clientReportBucketsUpload.updateExistFile(client, obj)
                              .then((result) => {
                                if (result && !result.status) {
                                  return ngToast.create({
                                    content: result.message,
                                    className: 'danger'
                                  });
                                } else {
                                  return result.data
                                }
                              })
                              .catch((err) => {
                                let errMessage = err.message || err;
                                if (err && err.data && err.data.message) errMessage = err.data.message;
                                return ngToast.create({
                                  content: errMessage,
                                  className: 'danger'
                                });
                              });
                          } else {
                            return $clientReportBucketsUpload.createFilesOrFolder(client, obj);
                          }
                        }
                      })
                      .then(function (data) {
                        $scope.isFileUploading = false;
                        if (data) {
                          finish.call(e, data);
                          modal.close(e);
                          ngToast.create({
                            content: 'File added successfully.',
                            className: 'success'
                          });
                        }
                      });
                  } else {
                    $scope.isFileUploading = false;
                    ngToast.create({
                      content: 'Please select file',
                      className: 'danger'
                    });
                  }
                }

                $scope.close = function (e) {
                  finish.call(e, false);
                  modal.close(e);
                }

              }],
              modal: {
                dismissable: false,
                title: 'Reports Library Attachment Upload',
                template: 'components/modal/tpl/knowledge-library-upload.html'
              }
            }, 'modal-success', null, true, true);
          }
        },
        createParentFolder: function (data, finish) {
          finish = finish || angular.noop;

          return function () {
            var scratchPad = {
              model: {
                name: null,
                bucket: data.bucketId,
                uploadType: 'FOLDER'
              },
              isLoading: false,
              error: null
            };
            const modal = openModal({
              controller: ['$scope', '$clientReportBucketsUpload', '$clientSwitcher', 'ngToast', '$q', function ($scope, $clientReportBucketsUpload, $clientSwitcher, ngToast, $q) {
                const client = $clientSwitcher.getCurrentClient();
                $scope.scratchPad = scratchPad;

                $scope.buttonClick = function (e) {
                  $scope.scratchPad.error = null;
                  $scope.scratchPad.isLoading = true;

                  let obj = {
                    bucket: $scope.scratchPad.model.bucket,
                    uploadType: $scope.scratchPad.model.uploadType,
                    folderName: $scope.scratchPad.model.name,
                  }
                  if (data.parentFolderId) {
                    obj.parentFolder = data.parentFolderId;
                  }

                  $clientReportBucketsUpload.createFilesOrFolder(client, obj)
                    .then(function (data) {
                      if (data && data.status) {
                        finish.call(e, true);
                        modal.close(e);
                      } else {
                        $scope.scratchPad.error = data.message;
                      }
                    })
                    .catch((err) => {
                      let errMessage = err.message || err;
                      if (err && err.data && err.data.message) errMessage = err.data.message;
                      $scope.scratchPad.error = errMessage;
                    })
                    .finally(() => {
                      $scope.scratchPad.isLoading = false;
                    })
                }

                $scope.close = function (e) {
                  finish.call(e, false);
                  modal.close(e);
                }

              }],
              modal: {
                dismissable: true,
                title: 'Create Folder',
                template: 'components/modal/tpl/add-kl-parent-folder.html'
              }
            }, 'modal-success');
          }
        },
        updateParentFolder: function (data, finish) {
          finish = finish || angular.noop;

          return function () {
            var scratchPad = {
              model: {
                name: data.folderName || null,
                folderId: data._id
              },
              isLoading: false,
              error: null
            };
            const modal = openModal({
              controller: ['$scope', '$clientReportBucketsUpload', '$clientSwitcher', 'ngToast', '$q', function ($scope, $clientReportBucketsUpload, $clientSwitcher, ngToast, $q) {
                const client = $clientSwitcher.getCurrentClient();
                $scope.scratchPad = scratchPad;

                $scope.buttonClick = function (e) {
                  $scope.scratchPad.error = null;
                  $scope.scratchPad.isLoading = true;
                  $clientReportBucketsUpload.updateFolder(client, $scope.scratchPad.model)
                    .then(function (data) {
                      finish.call(e, true);
                      modal.close(e);
                    })
                    .catch((err) => {
                      let errMessage = err.message || err;
                      if (err && err.data && err.data.message) errMessage = err.data.message;
                      $scope.scratchPad.error = errMessage;
                    })
                    .finally(() => {
                      $scope.scratchPad.isLoading = false;
                    })
                }

                $scope.close = function (e) {
                  finish.call(e, false);
                  modal.close(e);
                }

              }],
              modal: {
                dismissable: true,
                title: 'Edit Folder',
                template: 'components/modal/tpl/add-kl-parent-folder.html'
              }
            }, 'modal-success');
          }
        },
        reportLibraryFiles: function (filter, permissions, finish) {
          finish = finish || angular.noop;
          return function () {
            var scratchPad = {
              error: null,
              success: null,
              filter,
              permissions
            };
            var modal = openModal({
              controller: ['$scope', '$clientSwitcher', '$authStorage', '$clientReportBucketsUpload', '$downloadAttachment', function ($scope, $clientSwitcher, $authStorage, $clientReportBucketsUpload, $downloadAttachment) {
                var client = $clientSwitcher.getCurrentClient();
                $scope.scratchPad = scratchPad;
                $scope.attachmentList = [];
                $scope.user = $auth.getCurrentUser();
                // $scope.getFilePath = function (attachment) {
                //   return '/api/client-uploads/' + (client._id || client) + '/' + attachment._id + '?preview=1&access_token=' + $authStorage.getToken();
                // };

                function init() {
                  $scope.isLoading = true;
                  $clientReportBucketsUpload.getReportsFolderDetails(client, filter)
                    .then(function (result) {
                      if (result && result.data) {
                        $scope.attachmentList = result.data
                      }
                    })
                    .finally(function () {
                      $scope.isLoading = false;
                    })
                }

                $scope.showAttachment = function (attachment, index) {
                  // $scope.downloadUrl = '/attachment/' + (client._id || client) + '/' + attachment._id;
                  // window.location = $scope.downloadUrl;
                  $downloadAttachment.download((client._id || client), attachment._id);
                };
                init()
              }],
              modal: {
                dismissable: true,
                title: 'Report Library Search Results',
                template: 'components/modal/tpl/reports-library-files.html',
                buttons: [{
                  classes: 'btn-default',
                  text: 'Close',
                  click: function (e) {
                    modal.close(e);
                  }
                }]
              }
            }, 'modal-success', 'lg');
          };
        }
      },
      officeInformationActions: {
        createOfficeInformationRecord: function(title, finish) {
          finish = finish || angular.noop;
          return function (officeInfoRecords) {
            var scratchPad = officeInfoRecords;
            var modal = openModal({
              controller: ['$scope', '$clientSwitcher', 'ngToast', '$q', '$clientRegion', '$clientOffice', 'appConfig', function ($scope, $clientSwitcher, ngToast, $q, $clientRegion, $clientOffice, appConfig) {
                $scope.model = scratchPad || {
                  title: '',
                  description: '',
                  uploads: []
                };
                $scope.alertItemsOffice = [];
                $scope.alertItemsRegion = [];
                $scope.clientStrings = function(){ return $clientSwitcher.getFeatureOptions('client.strings'); };
                var isUpdateOperation = !!$scope.model.title;
                var allOffices = [];
                $scope.currentClient = $clientSwitcher.getCurrentClient();
                $scope.errors = [];

                // Region as location for SOSI client
                $scope.loadClientRegions = function (client) {
                  $scope.alertItemsRegion = [];
                  $clientRegion.listAll(client)
                    .then(function (regions) {
                      _.each(regions, function (region) {
                        $scope.alertItemsRegion.push({
                          value: region._id,
                          label: region.name
                        });
                      });
                    });
                };

                // Office/Location as courts for SOSI client
                $scope.loadClientStores = function (client) {
                  $clientOffice.listAll(client).then(function (data) {
                    allOffices = data;
                  });
                };

                $scope.loadClientRegions($scope.currentClient);
                $scope.loadClientStores($scope.currentClient);

                $scope.regionChanged = function() {
                  $scope.alertItemsOffice = [];
                  $scope.alertItemsOffice = allOffices
                  .filter(function(office) {
                    return office.region === $scope.model.region;
                  })
                  .map(function (office) {
                    return {
                      value: office._id,
                      label: office.name
                    };
                  });
                };

                $scope.uploader = new FileUploader({
                  url: appConfig.apiUrl + '/api/client-uploads/'+$scope.currentClient._id+'?access_token='+$authStorage.getToken(),
                  alias: 'upload',
                  filters: [
                    {
                      name: 'extensionFilter',
                      fn: function(item) {
                        var extension = item.name.substr(item.name.lastIndexOf('.')+1);
                        var validExtensions = ['exe', 'bin'];
                        if(validExtensions.indexOf(extension) > -1) {
                          ngToast.create({
                            className: 'danger',
                            content: 'Files with only '+validExtensions+' extensions are allowed'
                          });
                          return false;
                        } else {
                          return true;
                        }
                      }
                    }
                  ]
                });

                $scope.uploadFiles = function () {

                  var
                  results = [],
                  errors = [];
                  if($scope.uploader && $scope.uploader.queue) {
                    // if($scope.uploader.queue.length > 1) {
                    //   if(!isUpdateOperation) {
                    //     ngToast.create({
                    //       className: 'danger',
                    //       content: 'Allow only single record'
                    //     });
                    //   }
                    //   return $q.when(false);
                    // }

                    var defer = $q.defer();

                    $scope.uploader.onSuccessItem = function(fileItem, response, status, headers) {
                      Array.prototype.push.apply(results, response);
                    };

                    $scope.uploader.onErrorItem = function(fileItem, response, status, headers) {
                      errors.push(new Error('Failed to upload file: ' + fileItem.file.name));
                    };

                    $scope.uploader.onCompleteAll = function() {
                      if(errors.length > 0) { // fail on any errors
                        defer.reject(errors);
                        return;
                      }

                      defer.resolve(results);
                    };

                    $scope.uploader.uploadAll();

                    return defer.promise;
                  }

                  return $q.when(false);
                };

                $scope.createOfficeInfoRecord = function() {
                  if($scope.uploader && $scope.uploader.queue && $scope.uploader.queue.length > 0){
                    $scope.uploadFiles().then(function(uploaded) {
                      if(uploaded && uploaded.length) {
                        $scope.model.uploads = uploaded.map(function(upload) {
                          return upload._id;
                        });
                      }
                      if(uploaded || isUpdateOperation) {
                        modal.close($scope.model);
                      }
                    });
                  }else{
                    modal.close($scope.model);
                  }
                };

              }],
              modal: {
                dismissable: true,
                title: 'Upload ' + title + ' Information',
                template: 'components/modal/tpl/office-information.html'
              }
            }, 'modal-success');

            modal.result.then(function(officeInfoRecords) {
              finish(officeInfoRecords);
            });
          }
        }
      },
      officeOperationActions: function(){
        return loadOperationActionsModule()
      },
      // officeOperationActions: {
      //   checkInFacility: function (timezones, dataSets, clientBusinessDays, finish) {
      //     finish = finish || angular.noop;
      //     return function () {
      //       var scratchPad = {
      //         error: null,
      //         success: null,
      //         reportingCourt: dataSets.reportingCourt || null,
      //         date: dataSets.date || null,
      //         isLoading: false
      //       };
      //       var modal = openModal({
      //         controller: ['$scope', '$clientQueryBuilder', '$clientSwitcher', '$clientTask', '$officeOperations', '$clientOffice', '$courtOperations', '$autoTimezoneFormat', function ($scope, $clientQueryBuilder, $clientSwitcher, $clientTask, $officeOperations, $clientOffice, $courtOperations, $autoTimezoneFormat) {
      //           $scope.scratchPad = scratchPad;
      //           $scope.user = $auth.getCurrentUser();
      //           $scope.date = new Date();
      //           var client = $clientSwitcher.getCurrentClient();
      //           var pageData = {
      //             page: 1,
      //             pageSize: 100
      //           };

      //           function init() {
      //             if($scope.scratchPad.reportingCourt.length > 0 && $scope.scratchPad.reportingCourt != 'all'){
      //               manuallyInterpreter(client, $scope.scratchPad, pageData)
      //               .then(function (res) {
      //                 $scope.interpreterDataSets = [];
      //                 $scope.interpreterDataSets = res;
      //               })
      //               .catch(function (err) {
      //                 $scope.scratchPad.error = err;
      //               })
      //             }
      //           }

      //           function lateTime(record) {
      //             var index = timezones.findIndex(function (x) {
      //               return x.label === record.timezonename;
      //             })
      //             var timezoneOffset = record.shortTZ && record.shortTZ.offset ? record.shortTZ && record.shortTZ.offset : timezones[index] ? timezones[index].offset : moment().format('ZZ');
      //             var startTime = moment().utcOffset(timezoneOffset).format();
      //             var endTime = moment(record.sched, "HH:mm:ss").format('Y-MM-DDTHH:mm:ss') + timezoneOffset;
      //             var duration = moment(startTime).diff(moment(endTime)) / 1000;
      //             if (duration > 0) {
      //                 return duration;
      //             }
      //             return 0;
      //           }

      //           function earlyFINOrNot(record) {
      //             var index = timezones.findIndex(function (x) {
      //               return x.label === record.timezonename;
      //             })
      //             var timezoneOffset = record.shortTZ && record.shortTZ.offset ? record.shortTZ && record.shortTZ.offset : timezones[index] ? timezones[index].offset : moment().format('ZZ');
      //             var startTime = moment(record.sched, "HH:mm:ss").format('Y-MM-DDTHH:mm:ss') + timezoneOffset;
      //             var endTime = moment().utcOffset(timezoneOffset).format();
      //             var duration = moment(startTime).diff(moment(endTime)) / 1000;
      //             if (duration > 0) {
      //                 return duration;
      //             }
      //             return 0;
      //           }

      //           function checkBusinessHours(record, businessDays) {
      //             var index = timezones.findIndex(function (x) {
      //               return x.label === record.timezonename;
      //             })
      //             var timezoneOffset = record.shortTZ && record.shortTZ.offset ? record.shortTZ && record.shortTZ.offset : timezones[index] ? timezones[index].offset : moment().format('ZZ');
      //             var startTime = moment().utcOffset(timezoneOffset).startOf('day').format();
      //             var endTime = moment().utcOffset(timezoneOffset).format();
      //             var duration = moment(endTime).diff(moment(startTime)) / 1000;
      //             if (duration > 0) {
      //               var timeDiff = Math.floor(duration / 60);
      //               var dateIndex = record.deldate ? moment(record.deldate.split('T')[0]).day() : moment().day();
      //               // var clientBusinessDay = clientBusinessDays[dateIndex - 1];
      //               var rcBusinessDay = businessDays.data && businessDays.data.businessDays && businessDays.data.businessDays[dateIndex == 0 ? dateIndex : dateIndex - 1];

      //               if (rcBusinessDay && rcBusinessDay.enabled) {
      //                 if (timeDiff > rcBusinessDay.max) {
      //                   return 'notset';
      //                 }
      //                 return 'allow';
      //               } else {
      //                 return 'notset';
      //               }
      //             }
      //             return 'allow';
      //           }

      //           init();

      //           $scope.setEcoi = function () {
      //             $scope.scratchPad.ecoi = $scope.scratchPad.interPreter;
      //           }

      //           $scope.buttonClick = function (e) {
      //             $scope.scratchPad.isLoading = true;
      //             var ecoi = $scope.scratchPad.ecoi;
      //             if($scope.scratchPad.interPreter){
      //               ecoi = $scope.scratchPad.interPreter;
      //             }
      //             $scope.scratchPad.interPreter = ecoi;
      //             // $officeOperations.checkInterpreterOnWOById(client, ecoi, dataSets)
      //             $courtOperations.getCasesDetails(client, ecoi, dataSets)
      //             .then(function (res) {

      //               if(res.status) {
      //                 if(res.data.records.length) {
      //                   const record = res.data.records[0];
      //                   if (record && !record.vendorid) {
      //                     $scope.scratchPad.error = 'Performed ECOI in did not assign an interpreter!';
      //                     reset();
      //                     return;
      //                   }
      //                   const officeCode = record.replocid;
      //                   return Promise.all([$clientOffice.getBusinessHours(client, officeCode), Promise.resolve(res), $clientOffice.getOfficeWoControls(client, officeCode)])
      //                 } else {
      //                   $scope.scratchPad.error = 'No Record Found For Selected Date';
      //                   reset();
      //                 }
      //               } else {
      //                 $scope.scratchPad.error = res.reason;
      //                 reset();
      //               }

      //             })
      //             .then(function (res) {
      //               if(!res) return;
      //               const rcWoControls = res[2].data.woControls && res[2].data.woControls.earlyFinLimit
      //               ? res[2].data.woControls
      //               : { maxLunchTime: 120, minWoTime: 180, earlyFinLimit: 120 };
      //               if (res[1].status) {
      //                 if (res[1].data.records.length > 0) {
      //                   var record = res[1].data.records[0];
      //                   // If ecoi inperson false(OPI) then not allow to FIN
      //                   // if(!record.inperson){
      //                   //   $scope.scratchPad.error = 'Phone Based Interpretation Work Order. Please Judge In to Start.';
      //                   //   reset();
      //                   //   return;
      //                   // }
      //                   var taskDetails = record.taskDetails;
      //                   if (taskDetails && taskDetails.caseData && taskDetails.caseData.checkedOut && taskDetails.caseData.checkedOut.date) {
      //                     $scope.scratchPad.error = 'Interpreter already completed Facility OUT';
      //                     reset();
      //                     return;
      //                   }
      //                   if (taskDetails && taskDetails.caseData && taskDetails.caseData.checkedIn && taskDetails.caseData.checkedIn.date) {
      //                     $scope.scratchPad.error = 'Interpreter already completed Facility IN';
      //                     reset();
      //                   } else {
      //                     var scannedRecord = res[1].data.records[0];
      //                     scannedRecord.lateTime = lateTime(scannedRecord);
      //                     var earlyFIN = earlyFINOrNot(scannedRecord);
      //                     if (earlyFIN > (rcWoControls.earlyFinLimit * 60)) {
      //                       var momentObj = moment(scannedRecord.sched, ["h:mm A"])
      //                       if (scannedRecord.courtTimezone) {
      //                         let userLocalTime = $autoTimezoneFormat.getAllDetail(moment.utc(scannedRecord.deldate).format('YYYY-MM-DD'), scannedRecord.sched, scannedRecord.courtTimezone)
      //                         if (userLocalTime.userLocale &&  userLocalTime.userLocale.caseDisplayDateTime) {
      //                           momentObj = moment(userLocalTime.userLocale.caseDisplayDateTime)
      //                         }
      //                       }
      //                       $scope.scratchPad.error = `Can't Facility IN before ${rcWoControls.earlyFinLimit / 60} Hour of Scheduled Time. Your scheduled time is ${momentObj.format("hh:mm A")}`;
      //                       if(rcWoControls.earlyFinLimit / 60 > 1){
      //                         $scope.scratchPad.error = `Can't Facility IN before ${rcWoControls.earlyFinLimit / 60} Hours of Scheduled Time. Your scheduled time is ${momentObj.format("hh:mm A")}`;
      //                       }
      //                       reset();
      //                     } else {
      //                       const checkFinAllowHours = checkBusinessHours(scannedRecord, res[0]);
      //                       if (checkFinAllowHours === 'allow') {
      //                         $clientTask.clientCheckInFacility(client, scannedRecord).then(function (result) {
      //                           if (!result.status) {
      //                             $scope.scratchPad.error = result.reason;
      //                             reset();
      //                           } else {
      //                             modal.close(e);
      //                             finish.call(e, true);
      //                             $scope.scratchPad.ecoi = null;
      //                             $scope.scratchPad.error = null;
      //                             $scope.scratchPad.interPreter = null;
      //                             if (result.isUpdated) {
      //                               $scope.scratchPad.success = 'ECOI ' + ecoi + ' updated successful';
      //                             } else {
      //                               $scope.scratchPad.success = 'ECOI ' + ecoi + ' Facility IN successful';
      //                             }
      //                           }
      //                         });
      //                       } else if(checkFinAllowHours === 'notset') {
      //                         $scope.scratchPad.error = "Cannot perform event outside of court working hours.";
      //                         reset();
      //                       } else {
      //                         var momentObj = moment(scannedRecord.sched, ["h:mm A"])
      //                         $scope.scratchPad.error = "Can't Facility IN outside of the Court Hours";
      //                         reset();
      //                       }
      //                     }
      //                   }
      //                 }
      //                 else {
      //                   $scope.scratchPad.error = 'No Record Found';
      //                   reset();
      //                 }
      //               }
      //               else {
      //                 $scope.scratchPad.error = res[1].reason;
      //                 reset();
      //               }
      //             });
      //           };

      //           function reset() {
      //             $scope.scratchPad.ecoi = null;
      //             $scope.scratchPad.success = null;
      //             $scope.scratchPad.interPreter = null;
      //             $scope.scratchPad.isLoading = false;
      //           }
      //           $scope.cancel = function(e) {
      //             finish.call(e, false);
      //             modal.dismiss();
      //           }
      //         }],
      //         modal: {
      //           dismissable: false,
      //           title: 'Facility IN',
      //           template: 'components/modal/tpl/check-in-facility.html',
      //           // buttons: [{
      //           //   classes: 'btn-primary',
      //           //   text: 'Submit',
      //           // }, {
      //           //   classes: 'btn-default',
      //           //   text: 'Close',
      //           //   click: function (e) {
      //           //     finish.call(e, false);
      //           //     modal.close(e);
      //           //   }
      //           // }]
      //         }
      //       }, 'modal-success', null, true, true);

      //       modal
      //         .rendered
      //         .then(() => $timeout(() => $window.document.getElementById('checkInEcoiId').focus(), focusDelay));
      //     };
      //   },
      //   checkOutFacility: function(timezones, dataSets, finish) {
      //     finish = finish || angular.noop;
      //     return function () {
      //       var scratchPad = {
      //         error: null,
      //         success: null,
      //         reportingCourt: dataSets.reportingCourt || null,
      //         date: dataSets.date || null,
      //         isAllowExtend: false,
      //         isCheckJudgeOrNot: false,
      //         isAllowRelease: false,
      //         isLoading: false
      //       };
      //       var modal = openModal({
      //         controller: ['$scope', '$clientQueryBuilder', '$clientSwitcher', 'ngToast', '$clientTask', '$clientOffice', '$courtOperations', function ($scope, $clientQueryBuilder, $clientSwitcher, ngToast, $clientTask, $clientOffice, $courtOperations) {
      //           $scope.scratchPad = scratchPad;
      //           $scope.user = $auth.getCurrentUser();
      //           $scope.date = new Date();
      //           var client = $clientSwitcher.getCurrentClient();
      //           var pageData = {
      //             page: 1,
      //             pageSize: 100
      //           };

      //           function init() {
      //             if($scope.scratchPad.reportingCourt.length > 0 && $scope.scratchPad.reportingCourt != 'all'){
      //               getECOIForRCourt(client, $scope.scratchPad, pageData)
      //                 .then(function (res) {
      //                   //Interpreter
      //                   var interpreterDataSets = [];
      //                   const sortedInterpreterDataSets = _.sortBy(res.released, ['interprername', 'sched']);
      //                   sortedInterpreterDataSets.forEach(function (record) {
      //                     interpreterDataSets.push({
      //                       label: record.interprername + ' | ' + record.coi + ' | ' + moment(record.sched, ["h:mm A"]).format("hh:mm A"),
      //                       value: record.coi,
      //                       vendorId: record.vendorid,
      //                       ijcode: record.ijcode
      //                     })
      //                   });
      //                   $scope.interpreterDataSets = [];
      //                   $scope.interpreterDataSets = _.uniq(interpreterDataSets, 'label');
      //                 })
      //                 .catch(function (err) {
      //                   $scope.scratchPad.error = err;
      //                 })
      //             }
      //           }

      //           init();

      //           function diffBetweenTime(record) {
      //             var index = timezones.findIndex(function (x) {
      //               return x.label === record.timezonename;
      //             })
      //             var timezoneOffset = record.shortTZ && record.shortTZ.offset ? record.shortTZ && record.shortTZ.offset : timezones[index] ? timezones[index].offset : moment().format('ZZ');
      //             var startTime = moment().utcOffset(timezoneOffset).format();
      //             var endTime = moment(record.sched, "HH:mm:ss").format('Y-MM-DDTHH:mm:ss') + timezoneOffset;
      //             var taskDetails = record.taskDetails;
      //             if (taskDetails && taskDetails.caseData && taskDetails.caseData.judgedInOut && taskDetails.caseData.judgedInOut.length > 0) {
      //               var lastRecordFromJudgeInOut = taskDetails.caseData.judgedInOut[0];
      //               if (lastRecordFromJudgeInOut.event == 'Judge IN') {
      //                 var judgeIN = lastRecordFromJudgeInOut.date;
      //                 endTime = moment(judgeIN).format();
      //               }
      //             }
      //             return moment(startTime).diff(moment(endTime)) / 1000;
      //           }

      //           $scope.buttonClick = function (e) {
      //             $scope.scratchPad.isLoading = true;
      //             var ecoi = $scope.scratchPad.ecoi;
      //             if ($scope.scratchPad.interPreter) {
      //               ecoi = $scope.scratchPad.interPreter;
      //             }
      //             $scope.scratchPad.interPreter = ecoi;
      //             // $officeOperations.getEcoiById(client, ecoi)
      //             $courtOperations.getCasesDetails(client, ecoi, {})
      //               .then(function (res) {
      //                 if (res.status) {
      //                   if (res.data.records.length) {
      //                     const record = res.data.records[0];
      //                     const officeCode = record.replocid;
      //                     return Promise.all([$clientOffice.getOfficeWoControls(client, officeCode), Promise.resolve(res)])
      //                   } else {
      //                     $scope.scratchPad.error = 'No Record Found';
      //                     reset();
      //                   }
      //                 } else {
      //                   $scope.scratchPad.error = res.reason;
      //                   reset();
      //                 }

      //               })
      //               .then(function (res) {
      //                 let rcWoControls = {
      //                   maxLunchTime: res[0].data.woControls && res[0].data.woControls.maxLunchTime ? res[0].data.woControls.maxLunchTime : 120,
      //                   minWoTime: res[0].data.woControls && res[0].data.woControls.minWoTime ? res[0].data.woControls.minWoTime : 180,
      //                   minWoOPITime: res[0].data.woControls && res[0].data.woControls.minWoOPITime ? res[0].data.woControls.minWoOPITime : 60,
      //                   minWoVRITime: res[0].data.woControls && res[0].data.woControls.minWoVRITime ? res[0].data.woControls.minWoVRITime : 60
      //                 }
      //                 if (res[1].status) {
      //                   if (res[1].data.records.length) {
      //                     var record = res[1].data.records[0];
      //                     var taskDetails = record.taskDetails;
      //                     $scope.scratchPad.record = record;

      //                     if (taskDetails && taskDetails.caseData && ((taskDetails.caseData.checkedIn && taskDetails.caseData.checkedIn.date))) {
      //                       if (taskDetails && taskDetails.caseData && taskDetails.caseData.checkedOut && taskDetails.caseData.checkedOut.date) {
      //                         $scope.scratchPad.error = 'Interpreter already completed Facility OUT';
      //                         reset();
      //                       } else {
      //                         if (taskDetails && taskDetails.caseData && taskDetails.caseData.lunchOut && taskDetails.caseData.lunchOut.date) {
      //                           if (taskDetails && taskDetails.caseData && taskDetails.caseData.lunchIn && taskDetails.caseData.lunchIn.date) {
      //                           } else {
      //                             $scope.scratchPad.error = 'Please Lunch IN before attempting to Facility OUT';
      //                             reset();
      //                             return;
      //                           }
      //                         }
      //                         if (taskDetails && taskDetails.caseData && taskDetails.caseData.judgedInOut && taskDetails.caseData.judgedInOut.length > 0) {
      //                           var lastRecordFromJudgeInOut = taskDetails.caseData.judgedInOut[taskDetails.caseData.judgedInOut.length - 1];
      //                           if (lastRecordFromJudgeInOut.event == 'Judge IN') {
      //                             $scope.scratchPad.error = 'Please Judge OUT before attempting to Facility OUT';
      //                             reset();
      //                             return;
      //                           }
      //                         }

      //                         let minWoTime = rcWoControls.minWoTime || 180;
      //                         if (taskDetails.caseData.sertype == OPI) {
      //                           minWoTime = rcWoControls.minWoOPITime || 60;
      //                         } else if (taskDetails.caseData.sertype == VRI) {
      //                           minWoTime = rcWoControls.minWoOPITime || 60;
      //                         }
      //                         var lastEvent = taskDetails.stateActivity[taskDetails.stateActivity.length - 1];
      //                         if (['Release'].indexOf(lastEvent.state) <= -1) {
      //                           const woCalculations = calculateWOTime(record.taskDetails.caseData, true);
      //                           const totalworkTime = woCalculations.totalWOTime;
      //                           const isWoTimeLess = (totalworkTime * 3600000) < (minWoTime * 60000);

      //                           if (isWoTimeLess) {
      //                             const earlyTime = (minWoTime * 60000) - (totalworkTime * 3600000);
      //                             if (taskDetails && taskDetails.caseData && taskDetails.caseData.judgedInOut && taskDetails.caseData.judgedInOut.length == 0) {
      //                               // Interpreter Appeared but not Used
      //                               modals.officeOperationActions.ICAppearedButNotUse(record, function (result) {
      //                                 if (result.denyFout) {
      //                                   finish.call(e, true);
      //                                   $scope.scratchPad.success = 'ECOI ' + ecoi + ' Facility OUT Deny Successful';
      //                                   $scope.scratchPad.ecoi = null;
      //                                   $scope.scratchPad.error = null;
      //                                   $scope.scratchPad.interPreter = null;
      //                                   $scope.scratchPad.isLoading = false;
      //                                   // $scope.scratchPad.isAllowExtend = false;
      //                                 } else {
      //                                   record.icAppearedButNotUsed = result.reason;
      //                                   earlyCheckout(earlyTime, record, totalworkTime, e)
      //                                   return;
      //                                 }
      //                               })();
      //                               return;
      //                             } else {
      //                               earlyCheckout(earlyTime, record, totalworkTime, e)
      //                               return;
      //                             }
      //                           } else {
      //                             if (taskDetails && taskDetails.caseData && taskDetails.caseData.judgedInOut && taskDetails.caseData.judgedInOut.length == 0) {
      //                               // Interpreter Appeared but not Used
      //                               modals.officeOperationActions.ICAppearedButNotUse(record, function (result) {
      //                                 if (result.denyFout) {
      //                                   finish.call(e, true);
      //                                   $scope.scratchPad.success = 'ECOI ' + ecoi + ' Facility OUT Deny Successful';
      //                                   $scope.scratchPad.ecoi = null;
      //                                   $scope.scratchPad.error = null;
      //                                   $scope.scratchPad.interPreter = null;
      //                                   $scope.scratchPad.isLoading = false;
      //                                   // $scope.scratchPad.isAllowExtend = false;
      //                                 } else {
      //                                   record.icAppearedButNotUsed = result.reason;
      //                                   $scope.release = {
      //                                     releaseType: 'now',
      //                                     reason: 'No Additional Requirements',
      //                                     comments: null
      //                                   };
      //                                   var records = [{
      //                                     coi: record.coi,
      //                                     task: record.taskDetails._id,
      //                                     caseDataId: record.taskDetails.caseData._id,
      //                                     release: $scope.release,
      //                                     releaseEvent: null
      //                                   }];
      //                                   $clientTask.releaseInterpreter(client, records)
      //                                     .then(function (result) {
      //                                       facilityOut(ecoi, record, taskDetails, finish, e);
      //                                     })
      //                                     .then(function (result) {
      //                                       finish.call(e, result);
      //                                       modal.close(e);
      //                                     });
      //                                   return;
      //                                 }
      //                               })();
      //                               return;
      //                             }
      //                           }

      //                           // $scope.scratchPad.isAllowRelease = true;
      //                           $scope.scratchPad.success = null;
      //                           $scope.scratchPad.isLoading = false;
      //                           // if (!taskDetails.caseData.releaseEvent) {
      //                           //   $scope.scratchPad.error = 'Interpreter Not Released. Please Release Interpreter to Facility OUT';
      //                           //   return;
      //                           // }
      //                           $scope.scratchPad.error = 'ECOI ' + ecoi + ' has not been Release yet';
      //                           return;
      //                         }

      //                         // $scope.scratchPad.isAllowExtend = true;
      //                         // $scope.scratchPad.isAllowRelease = true;
      //                         // if(taskDetails && taskDetails.caseData && taskDetails.caseData.release && !taskDetails.caseData.release.releaseType){
      //                         //   $scope.scratchPad.isAllowRelease = true;
      //                         // }
      //                         // if (!$scope.scratchPad.isCheckJudgeOrNot && $scope.scratchPad.ecoi) {
      //                         //   $scope.scratchPad.isCheckJudgeOrNot = !$scope.scratchPad.isCheckJudgeOrNot;
      //                         //   return;
      //                         // } else {
      //                         //   $scope.scratchPad.isCheckJudgeOrNot = false;
      //                         // }
      //                         // if (taskDetails && taskDetails.caseData && !taskDetails.caseData.releaseIC.releaseOption) {
      //                         //   var lastRecordOfExtend = taskDetails.caseData.extendIC[taskDetails.caseData.extendIC.length - 1];
      //                         //   if (lastRecordOfExtend && lastRecordOfExtend.untilNextJudgeOut) {
      //                         //     // Extension Override Needed
      //                         //     modals.officeOperationActions.extensionOverride(record, function (result) {
      //                         //       if (result.denyRelease) {
      //                         //         record.extensionOverride = false;
      //                         //         $scope.scratchPad.isCheckJudgeOrNot = true;
      //                         //         var scannedRecord = {
      //                         //           coi: record.coi,
      //                         //           task: taskDetails._id,
      //                         //           caseDataId: record.taskDetails.caseData._id,
      //                         //           updateData: {
      //                         //             extensionOverride: false
      //                         //           }
      //                         //         };
      //                         //         $clientTask.updateCaseData(client, scannedRecord).then(function (result) {
      //                         //           if (!result.status) {
      //                         //             $scope.scratchPad.error = result.reason;
      //                         //             reset();
      //                         //           } else {
      //                         //             return;
      //                         //           }
      //                         //         });
      //                         //       } else {
      //                         //         record.extensionOverride = true;
      //                         //         checkICAppearedButNotUse(ecoi, record, taskDetails, finish, e);
      //                         //       }
      //                         //     })();
      //                         //     return;
      //                         //   }
      //                         // }
      //                         checkICAppearedButNotUse(ecoi, record, taskDetails, finish, e);
      //                       }
      //                     } else {
      //                       $scope.scratchPad.error = 'Please Facility IN before attempting to Facility OUT';
      //                       reset();
      //                     }
      //                   }
      //                   else {
      //                     $scope.scratchPad.error = 'No Record Found';
      //                     reset();
      //                   }
      //                 }
      //                 else {
      //                   $scope.scratchPad.error = res[1].reason;
      //                   reset();
      //                 }
      //               });
      //           };

      //           function earlyCheckout(earlyTime, record, totalworkTime, e) {
      //             modals.officeOperationActions.earlyCheckOut(earlyTime, record, totalworkTime, function (result) {
      //               if (!result.status) {
      //                 $scope.scratchPad.error = result.reason;
      //                 reset();
      //               } else {
      //                 $scope.scratchPad.success = 'ECOI ' + record.coi + ' Facility OUT Successful';
      //                 if(result.denyFout){
      //                   $scope.scratchPad.success = 'ECOI ' + record.coi + ' Facility OUT Deny Successful';
      //                 }
      //                 modal.close(e);
      //                 finish.call(e, true);
      //                 $scope.scratchPad.ecoi = null;
      //                 $scope.scratchPad.error = null;
      //                 $scope.scratchPad.interPreter = null;
      //                 // $scope.scratchPad.isAllowExtend = false;
      //               }
      //             })();
      //           }

      //           function checkICAppearedButNotUse(ecoi, record, taskDetails, finish, e){
      //             if (taskDetails && taskDetails.caseData && taskDetails.caseData.judgedInOut && taskDetails.caseData.judgedInOut.length == 0) {
      //               // Interpreter Appeared but not Used
      //               modals.officeOperationActions.ICAppearedButNotUse(record, function (result) {
      //                 if (result.denyFout) {
      //                   modal.close(e);
      //                   finish.call(e, true);
      //                   $scope.scratchPad.success = 'ECOI ' + ecoi + ' Facility OUT Deny Successful';
      //                   $scope.scratchPad.ecoi = null;
      //                   $scope.scratchPad.error = null;
      //                   $scope.scratchPad.interPreter = null;
      //                   // $scope.scratchPad.isAllowExtend = false;
      //                 } else {
      //                   record.icAppearedButNotUsed = result.reason;
      //                   facilityOut(ecoi, record, taskDetails, finish, e);
      //                 }
      //               })();
      //             } else{
      //               facilityOut(ecoi, record, taskDetails, finish, e);
      //             }
      //           }

      //           function facilityOut(ecoi, record, taskDetails, finish, e) {
      //             var scannedRecord = {
      //               coi: record.coi,
      //               task: taskDetails._id,
      //               caseData: record.taskDetails.caseData,
      //               icAppearedButNotUsed: record.icAppearedButNotUsed || null,
      //               // icNotUsedDenyRelease: record.icNotUsedDenyRelease || null,
      //               extensionOverride: record.extensionOverride || null
      //             };

      //             $clientTask.clientCheckOutFacility(client, scannedRecord).then(function (result) {
      //               if (!result.status) {
      //                 $scope.scratchPad.error = result.reason;
      //                 reset();
      //               } else {
      //                 $scope.scratchPad.success = 'ECOI ' + ecoi + ' Facility OUT successful';
      //                 modal.close(e);
      //                 finish.call(e, true);
      //                 $scope.scratchPad.ecoi = null;
      //                 $scope.scratchPad.error = null;
      //                 $scope.scratchPad.interPreter = null;
      //                 // $scope.scratchPad.isAllowExtend = false;
      //               }
      //             });

      //             // var durationInSeconds = diffBetweenTime(record);
      //             // if (durationInSeconds < earlyTimeInSecond) {
      //             //   Early Facility Out (if FACILITY OUT before sched+2:55)
      //             //   modals.officeOperationActions.earlyCheckOut(durationInSeconds, record, function (result) {
      //             //     if (!result.status) {
      //             //       $scope.scratchPad.error = result.reason;
      //             //       reset();
      //             //     } else {
      //             //       finish.call(e, true);
      //             //       $scope.scratchPad.success = 'ECOI ' + ecoi + ' Facility OUT successful';
      //             //       $scope.scratchPad.ecoi = null;
      //             //       $scope.scratchPad.error = null;
      //             //       $scope.scratchPad.interPreter = null;
      //             //       $scope.scratchPad.isAllowExtend = false;
      //             //     }
      //             //   })();
      //             // } else {
      //             //   var scannedRecord = {
      //             //     coi: record.coi,
      //             //     task: taskDetails._id,
      //             //     caseData: record.taskDetails.caseData,
      //             //     icAppearedButNotUsed: record.icAppearedButNotUsed || null,
      //             //     icNotUsedDenyRelease: record.icNotUsedDenyRelease || null,
      //             //     extensionOverride: record.extensionOverride || null
      //             //   };
      //             //   $clientTask.clientCheckOutFacility(client, scannedRecord).then(function (result) {
      //             //     if (!result.status) {
      //             //       $scope.scratchPad.error = result.reason;
      //             //       reset();
      //             //     } else {
      //             //       $scope.scratchPad.success = 'ECOI ' + ecoi + ' Facility OUT successful';
      //             //       finish.call(e, true);
      //             //       $scope.scratchPad.ecoi = null;
      //             //       $scope.scratchPad.error = null;
      //             //       $scope.scratchPad.interPreter = null;
      //             //       $scope.scratchPad.isAllowExtend = false;
      //             //     }
      //             //   });
      //             // }
      //           }

      //           function reset() {
      //             $scope.scratchPad.ecoi = null;
      //             $scope.scratchPad.success = null;
      //             $scope.scratchPad.interPreter = null;
      //             // $scope.scratchPad.isAllowExtend = false;
      //             // $scope.scratchPad.isAllowRelease = false;
      //             $scope.scratchPad.isCheckJudgeOrNot = false;
      //             $scope.scratchPad.isLoading = false;
      //           }

      //           $scope.onInterpreterChange = () => {
      //             if($scope.scratchPad.interPreter){
      //               // $scope.scratchPad.isAllowExtend = true;

      //               var ecoi = $scope.scratchPad.ecoi;
      //               if($scope.scratchPad.interPreter){
      //                 ecoi = $scope.scratchPad.interPreter;
      //               }
      //               $scope.scratchPad.ecoi = $scope.scratchPad.interPreter;
      //               // $officeOperations.getEcoiById(client, ecoi)
      //               $courtOperations.getCasesDetails(client, ecoi, {})
      //               .then(function (res) {
      //                 if (res.status) {
      //                   if (res.data.records.length) {
      //                     var record = res.data.records[0];
      //                     $scope.scratchPad.record = record;
      //                     // $scope.scratchPad.isAllowRelease = false;
      //                     // $scope.scratchPad.isCheckJudgeOrNot = true;
      //                     // if(record.taskDetails && record.taskDetails.caseData){
      //                     //   if (record.taskDetails.caseData.release && !record.taskDetails.caseData.release.releaseType && !record.taskDetails.caseData.releaseEvent) {
      //                     //     $scope.scratchPad.isAllowRelease = true;
      //                     //     $scope.scratchPad.error = 'Interpreter Not Released. Please Release Interpreter to Facility OUT';
      //                     //     $scope.scratchPad.success = null;
      //                     //   }
      //                     //   if(record.taskDetails.caseData.release && !record.taskDetails.caseData.release.releaseType){
      //                     //     $scope.scratchPad.isAllowRelease = true;
      //                     //   }
      //                     // }
      //                   } else {
      //                     // $scope.scratchPad.isAllowExtend = false;
      //                     // $scope.scratchPad.isAllowRelease = false;
      //                     $scope.scratchPad.error = null;
      //                   }
      //                 } else {
      //                   // $scope.scratchPad.isAllowExtend = false;
      //                   // $scope.scratchPad.isAllowRelease = false;
      //                 }
      //               })
      //             } else{
      //               // $scope.scratchPad.isAllowExtend = false;
      //               $scope.scratchPad.ecoi = null;
      //               // $scope.scratchPad.isCheckJudgeOrNot = false;
      //             }
      //           }

      //           $scope.onExtend = (e) => {
      //             var ecoi = $scope.scratchPad.ecoi;
      //             modals.officeOperationActions.extendInterpreter($scope.scratchPad.record, function (result) {
      //               if (!result.status) {
      //                 $scope.scratchPad.error = result.reason;
      //                 reset();
      //               } else {
      //                 init();
      //                 modal.close(e);
      //                 finish.call(e, true);
      //                 $scope.scratchPad.success = 'ECOI ' + ecoi + ' Extend Interpreter successful';
      //                 $scope.scratchPad.ecoi = null;
      //                 $scope.scratchPad.error = null;
      //                 $scope.scratchPad.interPreter = null;
      //                 $scope.scratchPad.isAllowExtend = false;
      //                 $scope.scratchPad.isAllowRelease = false;
      //                 $scope.scratchPad.isCheckJudgeOrNot = false;
      //               }
      //             })();
      //           }

      //           $scope.onRelease = (e) => {
      //             var taskDetails = $scope.scratchPad.record.taskDetails;
      //             if(taskDetails.caseData.releaseEvent && taskDetails.caseData.releaseEvent.value && (moment().format() < moment(taskDetails.caseData.releaseEvent.value).format())){
      //               modals.confirm.show('Release', 'Before Authorize Interpreter Release Time. Please Release Now to Facility OUT', 'Release Now', 'Cancel', function () {
      //                 // Release Now
      //                 $scope.release = {
      //                   releaseType: 'now',
      //                   comments: ''
      //                 };
      //                 $scope.releaseEvent = null;
      //                 var records = [{
      //                   coi: $scope.scratchPad.record.coi,
      //                   task: $scope.scratchPad.record.taskDetails._id,
      //                   caseDataId: $scope.scratchPad.record.taskDetails.caseData._id,
      //                   release: $scope.release,
      //                   releaseEvent: $scope.releaseEvent
      //                 }];

      //                 $scope.validatePassword(records, e)

      //               }, 'btn-success', null, 'modal-success');
      //             } else {
      //               modals.officeOperationActions.releaseInterpreter($scope.scratchPad.record, function (result) {
      //                 if (!result.status) {
      //                   $scope.scratchPad.error = result.reason;
      //                   reset();
      //                 } else {
      //                   init();
      //                   modal.close(e);
      //                   finish.call(e, true);
      //                   $scope.scratchPad.success = 'ECOI ' + $scope.scratchPad.record.coi + ' Release successful';
      //                   $scope.scratchPad.ecoi = null;
      //                   $scope.scratchPad.error = null;
      //                   $scope.scratchPad.interPreter = null;
      //                   $scope.scratchPad.isAllowExtend = false;
      //                   $scope.scratchPad.isAllowRelease = false;
      //                   $scope.scratchPad.isCheckJudgeOrNot = false;
      //                 }
      //               })();
      //             }
      //           }

      //           $scope.validatePassword = function(data, e) {

      //             const userEmail = $scope.user.email;

      //             modals.officeOperationActions.validatePassword(userEmail, function(res) {
      //               //Returns true if password is correct
      //               if (res) {
      //                 $clientTask.releaseInterpreter(client, data).then(function (result) {
      //                     finish.call(e, result);
      //                     modal.close(e);

      //                 });
      //               }
      //             })();
      //           }

      //           $scope.cancel = function(e) {
      //             finish.call(e, false);
      //             modal.dismiss();
      //           }
      //         }],
      //         modal: {
      //           dismissable: false,
      //           title: 'Facility OUT',
      //           template: 'components/modal/tpl/check-out-facility.html',
      //           // buttons: [{
      //           //   classes: 'btn-primary',
      //           //   text: 'Facility OUT',
      //           // }, {
      //           //   classes: 'btn-default',
      //           //   text: 'Close',
      //           //   click: function (e) {
      //           //     finish.call(e, false);
      //           //     modal.close(e);
      //           //   }
      //           // }]
      //         }
      //       }, 'modal-success', null, true, true);

      //       modal
      //         .rendered
      //         .then(() => $timeout(() => $window.document.getElementById('checkOutEcoiId').focus(), focusDelay));
      //     };
      //   },
      //   lunchOutInFacility: function (dataSets, finish) {
      //     finish = finish || angular.noop;
      //     return function () {
      //       var scratchPad = {
      //         error: null,
      //         success: null,
      //         reportingCourt: dataSets.reportingCourt || null,
      //         date: dataSets.date || null,
      //         isAllowJudge: false,
      //         isCheckJudgeOrNot: false,
      //         isLoading: false
      //       };
      //       var modal = openModal({
      //         controller: ['$scope', '$clientSwitcher', '$clientTask', '$clientQueryBuilder', '$clientOffice', '$officeOperations', '$courtOperations', function ($scope, $clientSwitcher, $clientTask, $clientQueryBuilder, $clientOffice, $officeOperations, $courtOperations) {
      //           var client = $clientSwitcher.getCurrentClient();
      //           $scope.scratchPad = scratchPad;
      //           $scope.user = $auth.getCurrentUser();
      //           $scope.date = new Date();
      //           const isCourtJudge = ($scope.user.settings.roles.findIndex(x => x.code === 'COURT_JUDGE') >= 0);
      //           var pageData = {
      //             page: 1,
      //             pageSize: 100
      //           };
      //           var sortedJudgeDataSets, oldJudgeNameCode;
      //           var inJudgeArr = [], inFacilityArr = [];
      //           var rcWoControls;

      //           function init() {
      //             if ($scope.scratchPad.reportingCourt.length > 0 && $scope.scratchPad.reportingCourt != 'all') {
      //               getECOIForRCourt(client, $scope.scratchPad, pageData)
      //                 .then(function (res) {
      //                   //Interpreter
      //                   var interpreterDataSets = [];
      //                   const sortedInterpreterDataSets = _.sortBy(res.checkedIn, ['interprername', 'sched']);
      //                   sortedInterpreterDataSets.forEach(function (record) {
      //                     interpreterDataSets.push({
      //                       label: record.interprername + ' | ' + record.coi + ' | ' + moment(record.sched, ["h:mm A"]).format("hh:mm A"),
      //                       value: record.coi,
      //                       vendorId: record.vendorid,
      //                       ijcode: record.ijcode
      //                     })
      //                   });
      //                   $scope.interpreterDataSets = [];
      //                   $scope.interpreterDataSets = _.uniq(interpreterDataSets, 'label');
      //                   // Judge
      //                   inJudgeArr = res.inJudge;
      //                   inFacilityArr = res.interPreterWO;
      //                   // var judgeDataSets = [];
      //                   // sortedJudgeDataSets = _.sortBy(res.interPreterWO, ['ijname', 'sched']);
      //                   // sortedJudgeDataSets.forEach(function (record) {
      //                   //   judgeDataSets.push({
      //                   //     label: record.ijcode + ' | ' + record.ijname,
      //                   //     value: record.ijcode,
      //                   //     ijcode: record.ijcode,
      //                   //     ijname: record.ijname
      //                   //   })
      //                   // });
      //                   // $scope.judgeDataSets = [];
      //                   // $scope.judgeDataSets = _.uniq(judgeDataSets, 'label');

      //                 })
      //                 .catch(function (err) {
      //                   $scope.scratchPad.error = err;
      //                 })
      //             }
      //           }

      //           init();

      //           $scope.onAutoJudgeOutIN = function (ecoi) {
      //             var ecoi = $scope.scratchPad.ecoi;
      //             if($scope.scratchPad.interPreter){
      //               ecoi = $scope.scratchPad.interPreter;
      //             }
      //             setJudgeDataset(ecoi);
      //           }

      //           $scope.getICLanguage = function () {
      //             if($scope.scratchPad.interPreter){
      //               var interpreterData = _.find($scope.interpreterDataSets, function (interpreter) {
      //                 return interpreter.value == $scope.scratchPad.interPreter;
      //               });
      //               $scope.scratchPad.ecoi = null;
      //               $scope.scratchPad.success = null;
      //               $scope.scratchPad.error = null;
      //               if(interpreterData){
      //                 $scope.scratchPad.judge = interpreterData.ijcode;
      //                 if($scope.user && $scope.user.settings && $scope.user.settings.internalId){
      //                   if($scope.user.settings.internalId == interpreterData.ijcode){
      //                     $scope.scratchPad.isAllowJudge = false;
      //                   } else{
      //                     $scope.scratchPad.isAllowJudge = true;
      //                   }
      //                 } else{
      //                   $scope.scratchPad.isAllowJudge = true;
      //                 }
      //               }
      //               // Judge
      //               $scope.scratchPad.ecoi = $scope.scratchPad.interPreter;
      //               setJudgeDataset($scope.scratchPad.interPreter);
      //             } else{
      //               $scope.scratchPad.judge = null;
      //             }
      //           }

      //           function setJudgeDataset(ecoi) {
      //             // Judge
      //             var judgeDataSets = [];
      //             var judge = _.find(inFacilityArr, function (judge) {
      //               return judge.coi == ecoi;
      //             });

      //             if (judge && judge.taskDetails && judge.taskDetails.caseData) {
      //               if ($scope.scratchPad.autoJudgeOutIN) {
      //                 $scope.scratchPad.isAllowJudge = true;
      //                 if (!judge.taskDetails.caseData.lunchOut.date) {
      //                   if (judge.taskDetails.caseData.judgedInOut && judge.taskDetails.caseData.judgedInOut.length > 0) {
      //                     var lastRecordJIN = judge.taskDetails.caseData.judgedInOut[judge.taskDetails.caseData.judgedInOut.length - 1];
      //                     if (lastRecordJIN.event == 'Judge IN') {
      //                       if (lastRecordJIN.judge && lastRecordJIN.judge.ijcode) {
      //                         judgeDataSets.push({
      //                           label: lastRecordJIN.judge.ijcode + ' | ' + lastRecordJIN.judge.ijname,
      //                           value: lastRecordJIN.judge.ijcode,
      //                           ijcode: lastRecordJIN.judge.ijcode,
      //                           ijname: lastRecordJIN.judge.ijname
      //                         })
      //                         $scope.judgeDataSets = [];
      //                         $scope.judgeDataSets = _.uniq(judgeDataSets, 'label');
      //                         $scope.scratchPad.judge = lastRecordJIN.judge.ijcode;
      //                       } else {
      //                         setJudgeArr([judge]);
      //                       }
      //                     }
      //                   } else {
      //                     setJudgeArr(inFacilityArr);
      //                   }
      //                 } else {
      //                   if(isCourtJudge) {
      //                     const ijCode = $scope.user.settings.internalId;
      //                     const ijName = $scope.user.name;

      //                     $scope.judgeDataSets = [{
      //                       label: ijCode + ' | ' + ijName,
      //                       value: ijCode,
      //                       ijcode: ijCode,
      //                       ijname: ijName
      //                     }];
      //                     $scope.scratchPad.judge = ijCode;
      //                     return;
      //                   }
      //                   setJudgeArr(inFacilityArr);
      //                 }
      //               } else {
      //                 if (judge.taskDetails && judge.taskDetails.caseData && judge.taskDetails.caseData.judgedInOut && judge.taskDetails.caseData.judgedInOut.length > 0) {
      //                   var lastRecordFromJudgeInOut = judge.taskDetails.caseData.judgedInOut[judge.taskDetails.caseData.judgedInOut.length - 1];
      //                   if (lastRecordFromJudgeInOut.event == 'Judge IN') {
      //                     if(isCourtJudge) {
      //                       if($scope.user.settings.internalId !== judge.taskDetails.caseData.judge.ijcode){
      //                         if(lastRecordFromJudgeInOut.judge && $scope.user.settings.internalId !== lastRecordFromJudgeInOut.judge.ijcode){
      //                           $scope.scratchPad.error = 'Please Judge OUT before attempting to Lunch OUT';
      //                           reset();
      //                           return;
      //                         }
      //                       }
      //                     } else {
      //                       $scope.scratchPad.error = 'Please Judge OUT before attempting to Lunch OUT';
      //                       reset();
      //                       return;
      //                     }
      //                   }
      //                 }

      //                 if (judge.taskDetails.caseData.lunchOut && judge.taskDetails.caseData.lunchOut.date) {
      //                   $scope.scratchPad.isAllowJudge = false;
      //                   setJudgeArr(inFacilityArr);
      //                 } else {
      //                   setJudgeArr(inFacilityArr);
      //                   if (judge.taskDetails.caseData.judgedInOut && judge.taskDetails.caseData.judgedInOut.length > 0) {
      //                     var lastRecordJIN = judge.taskDetails.caseData.judgedInOut[judge.taskDetails.caseData.judgedInOut.length - 1];
      //                     if (lastRecordJIN.event == 'Judge IN') {
      //                       if (lastRecordJIN.judge && lastRecordJIN.judge.ijcode) {
      //                         $scope.scratchPad.judge = lastRecordJIN.judge.ijcode;
      //                       }
      //                     }
      //                   }
      //                 }
      //               }
      //             }
      //             // var judge = _.find(sortedJudgeDataSets, function(judge) {
      //             //   return judge.coi == ecoi;
      //             // });
      //             // if (judge) {
      //             //   if (judge.taskDetails && judge.taskDetails.caseData && judge.taskDetails.caseData.lunchOut && judge.taskDetails.caseData.lunchOut.date) {
      //             //     var lastLunchOut = judge.taskDetails.caseData.lunchOut
      //             //     if (lastLunchOut.judge && lastLunchOut.judge.ijcode) {
      //             //       $scope.scratchPad.judge = lastLunchOut.judge.ijcode;
      //             //     }
      //             //   }
      //             //   // if (judge.taskDetails && judge.taskDetails.caseData && judge.taskDetails.caseData.judgedInOut && judge.taskDetails.caseData.judgedInOut.length > 0) {
      //             //   //   var lastRecordJIN = judge.taskDetails.caseData.judgedInOut[judge.taskDetails.caseData.judgedInOut.length - 1];
      //             //   //   if (lastRecordJIN.event == 'Judge IN') {
      //             //   //     if (lastRecordJIN.judge && lastRecordJIN.judge.ijcode) {
      //             //   //       $scope.scratchPad.judge = lastRecordJIN.judge.ijcode;
      //             //   //     }
      //             //   //   }
      //             //   // }
      //             // }
      //           }

      //           function setJudgeArr(coiArr) {
      //             var judgeDataSets = [];
      //             sortedJudgeDataSets = _.sortBy(coiArr, ['ijname', 'sched']);
      //             sortedJudgeDataSets.forEach(function (record) {
      //               judgeDataSets.push({
      //                 label: record.ijcode + ' | ' + record.ijname,
      //                 value: record.ijcode,
      //                 ijcode: record.ijcode,
      //                 ijname: record.ijname
      //               })
      //             });
      //             $scope.judgeDataSets = [];
      //             $scope.judgeDataSets = _.uniq(judgeDataSets, 'label');
      //           }

      //           function checkJudgeWithSelectedJudge(ecoi) {
      //             var judge = _.find(sortedJudgeDataSets, function(judge) {
      //               return judge.coi == ecoi;
      //             });
      //             if (judge) {
      //               if (judge.taskDetails && judge.taskDetails.caseData && judge.taskDetails.caseData.judgedInOut && judge.taskDetails.caseData.judgedInOut.length > 0) {
      //                 var lastRecordJIN = judge.taskDetails.caseData.judgedInOut[judge.taskDetails.caseData.judgedInOut.length - 1];
      //                 if (lastRecordJIN.event == 'Judge IN') {
      //                   if (lastRecordJIN.judge && lastRecordJIN.judge.ijcode) {
      //                     oldJudgeNameCode = lastRecordJIN.judge.ijcode;
      //                     if ($scope.scratchPad.judge != lastRecordJIN.judge.ijcode) {
      //                       // Open modal
      //                       return true;
      //                     }
      //                   }
      //                 }
      //               }
      //             }
      //             return false;
      //           }

      //           $scope.buttonClick = function (e) {
      //             $scope.scratchPad.isLoading = true;
      //             $scope.scratchPad.success = null;
      //             $scope.scratchPad.error = null;
      //             $scope.scratchPad.isCourtJudge = false;
      //             var ecoi = $scope.scratchPad.ecoi;
      //             if($scope.scratchPad.interPreter){
      //               ecoi = $scope.scratchPad.interPreter;
      //             }
      //             $scope.scratchPad.interPreter = ecoi;
      //             // $officeOperations.getEcoiById(client, ecoi)
      //             $courtOperations.getCasesDetails(client, ecoi, {})
      //             .then(function (res) {
      //               if (res.status) {
      //                 if (res.data.records.length) {
      //                   const record = res.data.records[0];
      //                   const officeCode = record.replocid;
      //                   return Promise.all([$clientOffice.getOfficeWoControls(client, officeCode), Promise.resolve(res)])
      //                 } else {
      //                   $scope.scratchPad.error = 'No Record Found';
      //                   reset();
      //                 }
      //               } else {
      //                 $scope.scratchPad.error = res.reason;
      //                 reset();
      //               }

      //             })
      //             .then(function (res) {
      //               rcWoControls = res[0].data.woControls
      //               rcWoControls.lunchStartTime = rcWoControls.lunchStartTime || 660;
      //               rcWoControls.lunchEndTime = rcWoControls.lunchEndTime || 840;

      //               if (res[1].status) {
      //                 if (res[1].data.records.length) {
      //                   var record = res[1].data.records[0];
      //                   var taskDetails = record.taskDetails;
      //                   if (taskDetails && taskDetails.caseData && taskDetails.caseData.checkedOut && taskDetails.caseData.checkedOut.date) {
      //                     $scope.scratchPad.error = 'Interpreter already completed Facility OUT';
      //                     reset();
      //                   } else {
      //                     // if (taskDetails && taskDetails.caseData && taskDetails.caseData.judgedInOut && taskDetails.caseData.judgedInOut.length > 0) {
      //                     //   var lastRecordFromJudgeInOut = taskDetails.caseData.judgedInOut[taskDetails.caseData.judgedInOut.length - 1];
      //                     //   if (lastRecordFromJudgeInOut.event == 'Judge OUT') {
      //                     //     $scope.scratchPad.error = 'Please Judge IN before attempting to Lunch OUT';
      //                     //     reset();
      //                     //     return;
      //                     //   }
      //                     // } else{
      //                     //   $scope.scratchPad.error = 'Please Judge IN before attempting to Lunch OUT';
      //                     //   reset();
      //                     //   return;
      //                     // }

      //                     if (record.sertype == OPI || record.sertype == VRI) {
      //                       $scope.scratchPad.error = `Lunch Not Permitted on a ${record.sertype} Work Order`;
      //                       reset();
      //                       return;
      //                     }

      //                     if (!taskDetails && record.sertype == OSI) {
      //                       $scope.scratchPad.error = 'Please Facility IN before attempting to Lunch IN/OUT';
      //                       reset();
      //                       return;
      //                     }

      //                     if (taskDetails && taskDetails.caseData && taskDetails.caseData.lunchOut.date && taskDetails.caseData.lunchIn.date) {
      //                       $scope.scratchPad.error = 'Already completed Lunch OUT/IN';
      //                       reset();
      //                       return;
      //                     }

      //                     if (!$scope.scratchPad.autoJudgeOutIN && taskDetails && taskDetails.caseData && taskDetails.caseData.judgedInOut && taskDetails.caseData.judgedInOut.length > 0) {
      //                       var lastRecordFromJudgeInOut = taskDetails.caseData.judgedInOut[taskDetails.caseData.judgedInOut.length - 1];
      //                       if (lastRecordFromJudgeInOut.event == 'Judge IN') {
      //                         if (isCourtJudge) {
      //                           if ($scope.user.settings.internalId !== record.ijcode) {
      //                             if (lastRecordFromJudgeInOut.judge && $scope.user.settings.internalId === lastRecordFromJudgeInOut.judge.ijcode) {
      //                               $scope.scratchPad.isAllowJudge = false;
      //                               // Direct lunch out/in
      //                               earlyOrLateLunchIN(ecoi, record, taskDetails, finish, e);
      //                               return;
      //                             } else {
      //                               $scope.scratchPad.error = 'Please Judge OUT before attempting to Lunch OUT';
      //                               reset();
      //                               return;
      //                             }
      //                           }
      //                         } else {
      //                           $scope.scratchPad.error = 'Please Judge OUT before attempting to Lunch OUT';
      //                           reset();
      //                           return;
      //                         }
      //                       }
      //                     }

      //                     $scope.scratchPad.isCourtJudge = ($scope.user.settings.roles.findIndex(x => x.code === 'COURT_JUDGE') >= 0);
      //                     if(taskDetails && taskDetails.caseData && taskDetails.caseData.judgedInOut && taskDetails.caseData.judgedInOut.length > 0) {
      //                       $scope.scratchPad.isJin = taskDetails.caseData.judgedInOut[taskDetails.caseData.judgedInOut.length - 1].event === 'Judge OUT';
      //                       $scope.scratchPad.isAutoJin = !!$scope.scratchPad.autoJudgeOutIN && $scope.scratchPad.isJin;
      //                       if($scope.scratchPad.isCourtJudge && $scope.scratchPad.isAutoJin) {
      //                         $scope.scratchPad.isAllowJudge = false;
      //                       }

      //                     }
      //                     $scope.scratchPad.isLoading = false;
      //                     // $scope.scratchPad.judge = record.ijcode;
      //                     if(scratchPad.reportingCourt.length > 0 && scratchPad.reportingCourt != 'all'){
      //                       if(taskDetails && taskDetails.caseData && taskDetails.caseData.judgedInOut && taskDetails.caseData.judgedInOut.length > 0) {
      //                         if($scope.scratchPad.isCourtJudge && $scope.scratchPad.isAutoJin) {
      //                           $scope.scratchPad.isAllowJudge = false;
      //                           $scope.scratchPad.showComment = true;
      //                         }
      //                       }
      //                       if($scope.user && $scope.user.settings && $scope.user.settings.internalId){
      //                         if($scope.user.settings.internalId == record.ijcode){
      //                           $scope.scratchPad.isAllowJudge = false;
      //                           if($scope.scratchPad.autoJudgeOutIN){
      //                             $scope.scratchPad.isAllowJudge = true;
      //                             $scope.scratchPad.showComment = true;
      //                           } else {
      //                             // Direct lunch out/in
      //                             earlyOrLateLunchIN(ecoi, record, taskDetails, finish, e);
      //                             // autoJudgeOutIN(ecoi, record, taskDetails, finish, e);
      //                             return;
      //                           }
      //                         } else if ((!$scope.scratchPad.isAutoJin) || (!$scope.scratchPad.isCourtJudge && $scope.scratchPad.isAutoJin)) {
      //                           $scope.scratchPad.isAllowJudge = true;
      //                           $scope.scratchPad.showComment = true;
      //                         }
      //                       } else {
      //                         $scope.scratchPad.isAllowJudge = true;
      //                         $scope.scratchPad.showComment = true;
      //                       }
      //                       if(!$scope.scratchPad.isCheckJudgeOrNot && $scope.scratchPad.ecoi){
      //                         $scope.scratchPad.judge = record.ijcode;
      //                         $scope.scratchPad.isCheckJudgeOrNot = !$scope.scratchPad.isCheckJudgeOrNot;
      //                         // Judge
      //                         setJudgeDataset(ecoi);
      //                         return;
      //                       } else{
      //                         $scope.scratchPad.isCheckJudgeOrNot = false;
      //                       }
      //                     }
      //                     earlyOrLateLunchIN(ecoi, record, taskDetails, finish, e);
      //                     // autoJudgeOutIN(ecoi, record, taskDetails, finish, e);
      //                   }
      //                 }
      //                 else {
      //                   $scope.scratchPad.error = 'No Record Found';
      //                   reset();
      //                 }
      //               }
      //               else {
      //                 $scope.scratchPad.error = res.reason;
      //                 reset();
      //               }
      //             });
      //           };

      //           function lunchOutIn(ecoi, record, taskDetails, finish, e, judgeData, flag) {
      //             if (!judgeData) {
      //               judgeData = {
      //                 ijcode: record.ijcode,
      //                 ijname: record.ijname
      //               }
      //             }
      //             if (judgeData) judgeData.comment = $scope.scratchPad.comment;
      //             var scannedRecord = {
      //               coi: record.coi,
      //               task: taskDetails._id,
      //               caseData: record.taskDetails.caseData._id,
      //               judge: judgeData || null
      //             };
      //             if(flag && flag == 'earlyLunchOut'){
      //               scannedRecord.earlyLunchOut = true;
      //             }

      //             if(flag && flag == 'lateLunchIn'){
      //               scannedRecord.lateLunchIn = true;
      //             }
      //             $clientTask.clientLunchOutInFacility(client, scannedRecord).then(function (result) {
      //               if (!result.status) {
      //                 $scope.scratchPad.error = result.reason;
      //                 reset();
      //               } else {
      //                 var message = 'ECOI ' + ecoi + ' ' + result.event + ' successful';
      //                 $scope.scratchPad.showComment = false;
      //                 success(message);
      //                 modal.close(e);
      //                 finish.call(e, record);
      //               }
      //             });
      //           }

      //           function earlyOrLateLunchIN(ecoi, record, taskDetails, finish, e) {
      //             $scope.scratchPad.isLoading = true;
      //             var ecoiTimeZone = $timezones.find(x => x.label === taskDetails.caseData.timezone);
      //             var m = moment(new Date()).utcOffset(ecoiTimeZone.offset);
      //             var currentDayMinutes = (m.hour()*60) + m.minute();
      //             if (taskDetails && taskDetails.caseData && !taskDetails.caseData.lunchOut.date) {
      //               if (currentDayMinutes < rcWoControls.lunchStartTime) {
      //                 var displayMsg = `You Are Lunching Out <b>${record.interprername} | ${ecoi}</b> Before Lunch Start Time set for the Court. This will Flag the Work Order for Review and Approval.`
      //                 modals.confirm.showWithCancelCB('Lunch OUT', displayMsg, 'Confirm', 'Cancel', function (callBack) {
      //                   if (callBack) {
      //                     autoJudgeOutIN(ecoi, record, taskDetails, finish, e, 'earlyLunchOut');
      //                   } else {
      //                     $scope.scratchPad.isLoading = false;
      //                   }
      //                 }, 'btn-success', null, 'modal-success');
      //                 return;
      //               }
      //             } else {
      //               if (currentDayMinutes > rcWoControls.lunchEndTime) {
      //                 var displayMsg = `This Lunch In action for <b>${record.interprername} | ${ecoi}</b> is occuring after court lunch hours. Confirmation of lunch time is subject to review and approval.`
      //                 modals.confirm.showWithCancelCB('Lunch IN', displayMsg, 'Confirm', 'Cancel', function (callBack) {
      //                   if (callBack) {
      //                     autoJudgeOutIN(ecoi, record, taskDetails, finish, e, 'lateLunchIn');
      //                   } else {
      //                     $scope.scratchPad.isLoading = false;
      //                   }
      //                 }, 'btn-success', null, 'modal-success');
      //                 return;
      //               }
      //             }

      //             autoJudgeOutIN(ecoi, record, taskDetails, finish, e);
      //           }

      //           function autoJudgeOutIN(ecoi, record, taskDetails, finish, e, flag) {
      //             $scope.scratchPad.isLoading = true;
      //             if ($scope.scratchPad.autoJudgeOutIN) {
      //               var judgeData = _.find($scope.judgeDataSets, function (judge) {
      //                 return judge.value == $scope.scratchPad.judge;
      //               });
      //               if ($scope.scratchPad.isCourtJudge && $scope.scratchPad.isAutoJin) {
      //                 const ijCode = $scope.user.settings.internalId;
      //                 const ijName = $scope.user.name;
      //                 judgeData = {
      //                   ijcode: ijCode,
      //                   ijname: ijName
      //                 };
      //               }
      //               if (taskDetails && taskDetails.caseData && !taskDetails.caseData.lunchOut.date) {
      //                 // LunchOUT
      //                 if (taskDetails && taskDetails.caseData && taskDetails.caseData.judgedInOut && taskDetails.caseData.judgedInOut.length > 0) {
      //                   var lastRecordFromJudgeInOut = taskDetails.caseData.judgedInOut[taskDetails.caseData.judgedInOut.length - 1];
      //                   if (lastRecordFromJudgeInOut.event == 'Judge IN') {
      //                     //Judge OUT
      //                     var judgeOutScope = {
      //                       judgeData: judgeData || null,
      //                       autoJudgeOutIn: $scope.scratchPad.autoJudgeOutIN
      //                     }
      //                     modals.officeOperationActions.judgeOutInterpreter(record, judgeOutScope, function (result) {
      //                       if (!result.status) {
      //                         $scope.scratchPad.error = result.reason;
      //                         reset();
      //                       } else {
      //                         $scope.scratchPad.success = 'ECOI ' + ecoi + ' Judge OUT successful';
      //                         lunchOutIn(ecoi, record, taskDetails, finish, e, judgeData, flag);
      //                       }
      //                     })();

      //                   } else {
      //                     lunchOutIn(ecoi, record, taskDetails, finish, e, judgeData, flag)
      //                   }
      //                 } else {
      //                   lunchOutIn(ecoi, record, taskDetails, finish, e, judgeData, flag)
      //                 }
      //               } else {
      //                 // Lunch IN
      //                 if (!judgeData) {
      //                   judgeData = {
      //                     ijcode: record.ijcode,
      //                     ijname: record.ijname
      //                   }
      //                 }
      //                 if (judgeData) judgeData.comment = $scope.scratchPad.comment;
      //                 var scannedRecord = {
      //                   coi: record.coi,
      //                   task: taskDetails._id,
      //                   caseData: record.taskDetails.caseData._id,
      //                   judge: judgeData || null
      //                 };

      //                 if(flag && flag == 'earlyLunchOut'){
      //                   scannedRecord.earlyLunchOut = true;
      //                 }

      //                 if(flag && flag == 'lateLunchIn'){
      //                   scannedRecord.lateLunchIn = true;
      //                 }

      //                 $clientTask.clientLunchOutInFacility(client, scannedRecord).then(function (lunchResult) {
      //                   if (!lunchResult.status) {
      //                     $scope.scratchPad.error = lunchResult.reason;
      //                     reset();
      //                   } else {
      //                     if (judgeData) judgeData.comment = null;
      //                     var scannedRecord = {
      //                       coi: record.coi,
      //                       task: taskDetails._id,
      //                       caseData: taskDetails.caseData,
      //                       icLanguages: null,
      //                       judge: judgeData || null,
      //                       autoJudgeOutIn: $scope.scratchPad.autoJudgeOutIN
      //                     };
      //                     if (taskDetails && taskDetails.caseData && taskDetails.caseData.judgedInOut && taskDetails.caseData.judgedInOut.length > 0) {
      //                       var lastRecordFromJudgeInOut = taskDetails.caseData.judgedInOut[taskDetails.caseData.judgedInOut.length - 1];
      //                       if (lastRecordFromJudgeInOut.event == 'Judge OUT') {
      //                         //Judge IN
      //                         $clientTask.clientJudgeInFacility(client, scannedRecord).then(function (result) {
      //                           if (!result.status) {
      //                             $scope.scratchPad.error = result.reason;
      //                             reset();
      //                           } else {
      //                             var message = 'ECOI ' + ecoi + ' Auto Judge IN successful';
      //                             $scope.scratchPad.showComment = false;
      //                             success(message);
      //                             modal.close(e);
      //                             finish.call(e, record);
      //                           }
      //                         });
      //                       } else {
      //                         var message = 'ECOI ' + ecoi + ' ' + lunchResult.event + ' successful';
      //                         $scope.scratchPad.showComment = false;
      //                         success(message);
      //                         modal.close(e);
      //                         finish.call(e, record);
      //                       }
      //                     } else {
      //                       //Judge IN
      //                       $clientTask.clientJudgeInFacility(client, scannedRecord).then(function (result) {
      //                         if (!result.status) {
      //                           $scope.scratchPad.error = result.reason;
      //                           reset();
      //                         } else {
      //                           var message = 'ECOI ' + ecoi + ' Auto Judge IN successful';
      //                           $scope.scratchPad.showComment = false;
      //                           success(message);
      //                           modal.close(e);
      //                           finish.call(e, record);
      //                         }
      //                       });
      //                     }
      //                   }
      //                 });
      //               }
      //             } else {
      //               // Lunch OUT/IN
      //               var judgeData = _.find($scope.judgeDataSets, function (judge) {
      //                 return judge.value == $scope.scratchPad.judge;
      //               });
      //               if (!judgeData) {
      //                 judgeData = {
      //                   ijcode: record.ijcode,
      //                   ijname: record.ijname
      //                 }
      //               }
      //               if (judgeData) judgeData.comment = $scope.scratchPad.comment;
      //               var scannedRecord = {
      //                 coi: record.coi,
      //                 task: taskDetails._id,
      //                 caseData: record.taskDetails.caseData._id,
      //                 judge: judgeData || null
      //               };

      //               if(flag && flag == 'earlyLunchOut'){
      //                 scannedRecord.earlyLunchOut = true;
      //               }

      //               if(flag && flag == 'lateLunchIn'){
      //                 scannedRecord.lateLunchIn = true;
      //               }

      //               $clientTask.clientLunchOutInFacility(client, scannedRecord).then(function (result) {
      //                 if (!result.status) {
      //                   $scope.scratchPad.error = result.reason;
      //                   reset();
      //                 } else {
      //                   var message = 'ECOI ' + ecoi + ' ' + result.event + ' successful';
      //                   $scope.scratchPad.showComment = false;
      //                   success(message);
      //                   modal.close(e);
      //                   finish.call(e, record);
      //                 }
      //               });
      //             }
      //           }

      //           // Direct old judge out and new judge IN
      //           function directJOUTAndJIN(ecoi, record, taskDetails, finish, e, oldJudgeData, newJudgeData) {
      //             // Old judge OUT
      //             var data = {
      //               coi: record.coi,
      //               task: record.taskDetails._id,
      //               caseData: record.taskDetails.caseData,
      //               updateData: null,
      //               autoFacilityOut: null,
      //               judge: oldJudgeData || null,
      //               additionalLanguages: null
      //             };
      //             $clientTask.clientJudgeOutFacility(client, data).then(function (result) {
      //               if (result.status) {
      //                 // New judge IN
      //                 var scannedRecord = {
      //                   coi: record.coi,
      //                   task: taskDetails._id,
      //                   caseData: record.taskDetails.caseData,
      //                   icLanguages: null,
      //                   judge: newJudgeData || null
      //                 };
      //                 $clientTask.clientJudgeInFacility(client, scannedRecord).then(function (result) {
      //                   if (!result.status) {
      //                     $scope.scratchPad.error = result.reason;
      //                     reset();
      //                   } else {
      //                     finish.call(e, true);
      //                     $scope.scratchPad.success = 'ECOI ' + ecoi + ' Judge IN successful';
      //                     $scope.scratchPad.ecoi = null;
      //                     $scope.scratchPad.error = null;
      //                     $scope.scratchPad.interPreter = null;
      //                     $scope.scratchPad.judge = null;
      //                     $scope.scratchPad.isAllowJudge = false;
      //                     $scope.scratchPad.comment = null;
      //                   }
      //                 });
      //               } else {
      //                 $scope.scratchPad.error = result.reason;
      //                 reset();
      //               }
      //             });
      //           }

      //           function success(message) {
      //             $scope.scratchPad.success = message;
      //             $scope.scratchPad.ecoi = null;
      //             $scope.scratchPad.error = null;
      //             $scope.scratchPad.interPreter = null;
      //             $scope.scratchPad.judge = null;
      //             $scope.scratchPad.isAllowJudge = false;
      //             $scope.scratchPad.comment = null;
      //             $scope.scratchPad.autoJudgeOutIN = false;
      //             $scope.scratchPad.isLoading = false;
      //           }

      //           function reset() {
      //             $scope.scratchPad.ecoi = null;
      //             $scope.scratchPad.success = null;
      //             $scope.scratchPad.interPreter = null;
      //             $scope.scratchPad.judge = null;
      //             $scope.scratchPad.isAllowJudge = false;
      //             $scope.scratchPad.comment = null;
      //             $scope.scratchPad.autoJudgeOutIN = false;
      //             $scope.scratchPad.isLoading = false;
      //           }
      //           $scope.cancel = function() {
      //             modal.dismiss();
      //           }
      //         }],
      //         modal: {
      //           dismissable: false,
      //           title: 'Lunch OUT/IN',
      //           template: 'components/modal/tpl/lunch-in-out-facility.html',
      //           // buttons: [{
      //           //   classes: 'btn-primary',
      //           //   text: 'Submit',
      //           // }, {
      //           //   classes: 'btn-default',
      //           //   text: 'Close',
      //           //   click: function (e) {
      //           //     finish.call(e, false);
      //           //     modal.close(e);
      //           //   }
      //           // }]
      //         }
      //       }, 'modal-success', null, true, true);

      //       modal
      //         .rendered
      //         .then(() => $timeout(() => $window.document.getElementById('lunchOutInEcoiId').focus(), focusDelay));
      //     };
      //   },
      //   judgeInFacility: function (timezones, dataSets, finish) {
      //     finish = finish || angular.noop;
      //     return function () {
      //       var scratchPad = {
      //         error: null,
      //         success: null,
      //         reportingCourt: dataSets.reportingCourt || null,
      //         date: dataSets.date || null,
      //         icLanguages: [],
      //         isAllowJudge: false,
      //         isCheckJudgeOrNot: false,
      //         isLoading: false,
      //         alien: null,
      //         isAllowAlien: false,
      //         maskAlienVal: null,
      //         ecoi: dataSets && dataSets.coi ? dataSets && dataSets.coi : null
      //       };
      //       var modal = openModal({
      //         controller: ['$scope', '$clientQueryBuilder', '$clientSwitcher', 'ngToast', '$clientTask', '$setMaskInAlien', 'CalculateTime', '$clientOffice', '$courtOperations', function ($scope, $clientQueryBuilder, $clientSwitcher, ngToast, $clientTask, $setMaskInAlien, CalculateTime, $clientOffice, $courtOperations) {
      //           $scope.scratchPad = scratchPad;
      //           $scope.user = $auth.getCurrentUser();
      //           $scope.date = new Date();
      //           $scope.data = {
      //             toggleMaskFlag : false
      //           };
      //           var client = $clientSwitcher.getCurrentClient();
      //           const isCourtJudge = ($scope.user.settings.roles.findIndex(x => x.code === 'COURT_JUDGE') >= 0);
      //           var pageData = {
      //             page: 1,
      //             pageSize: 100
      //           };
      //           var rcWoControls;

      //           function init() {
      //             if($scope.scratchPad.reportingCourt.length > 0 && $scope.scratchPad.reportingCourt != 'all'){
      //               getECOIForRCourt(client, $scope.scratchPad, pageData)
      //               .then(function (res) {
      //                 //Interpreter
      //                 var interpreterDataSets = [];
      //                 const sortedInterpreterDataSets = _.sortBy(res.checkedIn, ['interprername', 'sched']);
      //                 sortedInterpreterDataSets.forEach(function (record) {
      //                   if(record.taskDetails.caseData && record.taskDetails.caseData.judgedInOut && record.taskDetails.caseData.judgedInOut.length > 0){
      //                     var lastRecordFromJudgeInOut = record.taskDetails.caseData.judgedInOut[record.taskDetails.caseData.judgedInOut.length - 1];
      //                     if (lastRecordFromJudgeInOut.event == 'Judge OUT') {
      //                       interpreterDataSets.push({
      //                         label: record.interprername + ' | ' + record.coi + ' | ' + moment(record.sched, ["h:mm A"]).format("hh:mm A"),
      //                         value: record.coi.toString(),
      //                         vendorId: record.vendorid,
      //                         ijcode: record.ijcode,
      //                         alien: record.anumb,
      //                         taskDetails: record.taskDetails
      //                       })
      //                     }
      //                   } else{
      //                     interpreterDataSets.push({
      //                       label: record.interprername + ' | ' + record.coi + ' | ' + moment(record.sched, ["h:mm A"]).format("hh:mm A"),
      //                       value: record.coi.toString(),
      //                       vendorId: record.vendorid,
      //                       ijcode: record.ijcode,
      //                       alien: record.anumb,
      //                       taskDetails: record.taskDetails
      //                     })
      //                   }
      //                 });
      //                 $scope.interpreterDataSets = [];
      //                 $scope.interpreterDataSets = _.uniq(interpreterDataSets, 'label');
      //                 // Judge
      //                 var judgeDataSets = [];
      //                 const sortedJudgeDataSets = _.sortBy(res.interPreterWO, ['ijname', 'sched']);
      //                 sortedJudgeDataSets.forEach(function (record) {
      //                   judgeDataSets.push({
      //                     label: record.ijcode + ' | ' + record.ijname,
      //                     value: record.ijcode,
      //                     ijcode: record.ijcode,
      //                     ijname: record.ijname
      //                   })
      //                 });
      //                 $scope.judgeDataSets = [];
      //                 $scope.judgeDataSets = _.uniq(judgeDataSets, 'label');

      //               })
      //               .catch(function (err) {
      //                 $scope.scratchPad.error = err;
      //               })
      //             }
      //           }

      //           init();

      //           $scope.getICLanguage = function () {
      //             var connectionType = 'postgresql';
      //             if($scope.scratchPad.interPreter){
      //               var interpreterData = _.find($scope.interpreterDataSets, function (interpreter) {
      //                 return interpreter.value == $scope.scratchPad.interPreter;
      //               });
      //               $scope.scratchPad.ecoi = null;
      //               $scope.scratchPad.success = null;
      //               $scope.scratchPad.error = null;
      //               $scope.icLangDataSets = [];
      //               $scope.scratchPad.icLanguages = [];
      //               if(interpreterData){
      //                 $scope.scratchPad.judge = interpreterData.ijcode;
      //                 if (interpreterData.taskDetails && interpreterData.taskDetails.caseData && interpreterData.taskDetails.caseData.judgedInOut && interpreterData.taskDetails.caseData.judgedInOut.length == 0) {
      //                   $scope.scratchPad.alien = null;
      //                   $scope.alienMask(interpreterData.alien, $scope.data.toggleMaskFlag);
      //                 }
      //                 if($scope.user && $scope.user.settings && $scope.user.settings.internalId){
      //                   if(isCourtJudge) {
      //                     const ijCode = $scope.user.settings.internalId;
      //                     const ijName = $scope.user.name;

      //                     $scope.scratchPad.showComment = false;
      //                     $scope.scratchPad.isAllowJudge = false;

      //                     $scope.judgeDataSets = [{
      //                       label: ijCode + ' | ' + ijName,
      //                       value: ijCode,
      //                       ijcode: ijCode,
      //                       ijname: ijName
      //                     }];

      //                   }
      //                   if($scope.user.settings.internalId == interpreterData.ijcode){
      //                     $scope.scratchPad.isAllowJudge = false;
      //                     $scope.scratchPad.isAllowAlien = true;
      //                   } else if(isCourtJudge && $scope.user.settings.internalId !== interpreterData.ijcode){
      //                     $scope.scratchPad.judge = $scope.user.settings.internalId;
      //                     $scope.scratchPad.isAllowJudge = false;
      //                     $scope.scratchPad.showComment = false;
      //                   } else{
      //                     $scope.scratchPad.isAllowJudge = true;
      //                     $scope.scratchPad.showComment = true;
      //                     $scope.scratchPad.isAllowAlien = true;
      //                   }
      //                 } else{
      //                   $scope.scratchPad.isAllowJudge = true;
      //                   $scope.scratchPad.showComment = true;
      //                   $scope.scratchPad.isAllowAlien = true;
      //                 }
      //                 $scope.scratchPad.ecoi = $scope.scratchPad.interPreter;
      //                 $scope.scratchPad.isCheckJudgeOrNot = true;
      //               }
      //             }
      //           }

      //           function isAfterTime(record) {
      //             let CalculateWOTime = CalculateTime(record.taskDetails.caseData, rcWoControls);
      //             var index = timezones.findIndex(function (x) {
      //               return x.label === record.timezonename;
      //             })
      //             var timezoneOffset = record.shortTZ && record.shortTZ.offset ? record.shortTZ && record.shortTZ.offset : timezones[index] ? timezones[index].offset : moment().format('ZZ');
      //             var startTime = moment().utcOffset(timezoneOffset).format();
      //             var taskDetails = record.taskDetails;
      //             if (taskDetails && taskDetails.caseData && taskDetails.caseData.judgedInOut && taskDetails.caseData.judgedInOut.length > 0) {
      //               var lastRecordFromJudgeInOut = taskDetails.caseData.judgedInOut[0];
      //               if (lastRecordFromJudgeInOut.event == 'Judge IN') {
      //                 var judgeIN = lastRecordFromJudgeInOut.date;
      //                 startTime = moment(judgeIN).format();
      //               }
      //             }
      //             var endTime = CalculateWOTime.schedDateWithTZ != 'N/A' ? CalculateWOTime.schedDateWithTZ : moment(record.sched, "HH:mm:ss").format('Y-MM-DDTHH:mm:ss') + timezoneOffset;
      //             return moment(startTime).diff(moment(endTime)) / 1000;
      //           }

      //           $scope.buttonClick = function (e) {
      //             $scope.scratchPad.isLoading = true;
      //             // $scope.scratchPad.isAllowJudge = !($scope.user.settings.roles.findIndex(x => x.code === 'COURT_JUDGE') >= 0);
      //             $scope.scratchPad.success = null;
      //             $scope.scratchPad.error = null;
      //             var ecoi = $scope.scratchPad.ecoi;
      //             if ($scope.scratchPad.interPreter) {
      //               ecoi = $scope.scratchPad.interPreter;
      //             }
      //             $scope.scratchPad.interPreter = ecoi;
      //             // $officeOperations.getEcoiById(client, ecoi)
      //             $courtOperations.getCasesDetails(client, ecoi, {})
      //             .then(function (res) {
      //               if (res.status) {
      //                 if (res.data.records.length) {
      //                   const record = res.data.records[0];
      //                   const officeCode = record.replocid;
      //                   return Promise.all([$clientOffice.getOfficeWoControls(client, officeCode), Promise.resolve(res)])
      //                 } else {
      //                   $scope.scratchPad.error = 'No Record Found';
      //                   reset();
      //                 }
      //               } else {
      //                 $scope.scratchPad.error = res.reason;
      //                 reset();
      //               }

      //             })
      //             .then(function (res) {
      //               if(!res) return;
      //               rcWoControls = res[0].data.woControls
      //               if (res[1].status) {
      //                 if (res[1].data.records.length > 0) {
      //                   var record = res[1].data.records[0];
      //                   var taskDetails = record.taskDetails;
      //                   if (taskDetails && taskDetails.caseData && taskDetails.caseData.checkedOut && taskDetails.caseData.checkedOut.date) {
      //                     $scope.scratchPad.error = 'Interpreter already completed Facility OUT';
      //                     reset();
      //                   } else {
      //                     // If inpersion true then OSI and false then OPI
      //                     if (!taskDetails) {
      //                       $scope.scratchPad.error = 'Please Facility IN before attempting to Judge IN';
      //                       reset();
      //                       return;
      //                     }

      //                     // if (taskDetails && taskDetails.caseData && taskDetails.caseData.checkedIn && taskDetails.caseData.checkedIn.date) {
      //                     if (taskDetails && taskDetails.caseData && taskDetails.caseData.judgedInOut && taskDetails.caseData.judgedInOut.length > 0) {
      //                       var lastRecordFromJudgeInOut = taskDetails.caseData.judgedInOut[taskDetails.caseData.judgedInOut.length - 1];
      //                       if (lastRecordFromJudgeInOut.event == 'Judge IN') {
      //                         $scope.scratchPad.error = 'Interpreter already completed Judge IN';
      //                         reset();
      //                         return;
      //                       }
      //                     }

      //                     if (taskDetails && taskDetails.caseData && taskDetails.caseData.lunchOut && taskDetails.caseData.lunchOut.date) {
      //                       if (taskDetails && taskDetails.caseData && taskDetails.caseData.lunchIn && taskDetails.caseData.lunchIn.date) {
      //                       } else {
      //                         $scope.scratchPad.error = 'Please Lunch IN before attempting to Judge IN';
      //                         reset();
      //                         return;
      //                       }
      //                     }
      //                     $scope.scratchPad.allowComment = true;
      //                     $scope.scratchPad.isLoading = false;
      //                     // $scope.scratchPad.judge = record.ijcode;
      //                     if (scratchPad.reportingCourt.length > 0 && scratchPad.reportingCourt != 'all') {
      //                       if ($scope.user && $scope.user.settings && $scope.user.settings.internalId) {
      //                         if (isCourtJudge) {
      //                           const ijCode = $scope.user.settings.internalId;
      //                           const ijName = $scope.user.name;

      //                           $scope.scratchPad.showComment = false;

      //                           $scope.judgeDataSets = [{
      //                             label: ijCode + ' | ' + ijName,
      //                             value: ijCode,
      //                             ijcode: ijCode,
      //                             ijname: ijName
      //                           }];

      //                         }
      //                         if ($scope.user.settings.internalId == record.ijcode) {
      //                           $scope.scratchPad.isAllowJudge = false;
      //                           $scope.scratchPad.isCheckJudgeOrNot = false;
      //                           $scope.scratchPad.isAllowAlien = true;
      //                           // Dieract judge in
      //                           // judgeIn(ecoi, record, taskDetails, finish, e);
      //                         } else if (isCourtJudge && $scope.user.settings.internalId !== record.ijcode) {
      //                           $scope.scratchPad.judge = $scope.user.settings.internalId;
      //                           $scope.scratchPad.isAllowJudge = false;
      //                           $scope.scratchPad.showComment = false;
      //                           $scope.scratchPad.isCheckJudgeOrNot = true;
      //                         } else {
      //                           $scope.scratchPad.isAllowJudge = true;
      //                           $scope.scratchPad.showComment = true;
      //                           $scope.scratchPad.isAllowAlien = true;
      //                         }
      //                       } else {
      //                         $scope.scratchPad.isAllowJudge = true;
      //                         $scope.scratchPad.showComment = true;
      //                         $scope.scratchPad.isAllowAlien = true;
      //                       }
      //                       if ($scope.scratchPad.isAllowJudge === false && $scope.scratchPad.doJin) {
      //                         $scope.scratchPad.judge = null;
      //                         judgeIn(ecoi, record, taskDetails, finish, e);
      //                         return;
      //                       }
      //                       $scope.scratchPad.doJin = true;

      //                       if (!$scope.scratchPad.isCheckJudgeOrNot && $scope.scratchPad.ecoi) {
      //                         $scope.scratchPad.judge = record.ijcode;
      //                         if (isCourtJudge && $scope.user.settings.internalId !== record.ijcode) {
      //                           $scope.scratchPad.judge = $scope.user.settings.internalId;
      //                         }
      //                         if (taskDetails && taskDetails.caseData && taskDetails.caseData.judgedInOut && taskDetails.caseData.judgedInOut.length == 0) {
      //                           $scope.scratchPad.alien = null;
      //                           $scope.alienMask(record.anumb, $scope.data.toggleMaskFlag);
      //                         }
      //                         $scope.scratchPad.isCheckJudgeOrNot = !$scope.scratchPad.isCheckJudgeOrNot;
      //                         return;
      //                       } else {
      //                         $scope.scratchPad.isCheckJudgeOrNot = false;
      //                       }

      //                     }

      //                     judgeIn(ecoi, record, taskDetails, finish, e);
      //                     // } else {
      //                     //   $scope.scratchPad.error = 'Please Facility IN before attempting to Judge IN';
      //                     //   reset();
      //                     // }
      //                   }
      //                 }
      //                 else {
      //                   $scope.scratchPad.error = 'No Record Found';
      //                   reset();
      //                 }
      //               }
      //               else {
      //                 $scope.scratchPad.error = res.reason;
      //                 reset();
      //               }
      //             });
      //           };

      //           function judgeIn(ecoi, record, taskDetails, finish, e){
      //             $scope.scratchPad.isLoading = true;
      //             var judgeData = _.find($scope.judgeDataSets, function (judge) {
      //               return judge.value == $scope.scratchPad.judge;
      //             });
      //             if (isCourtJudge) {
      //               const ijCode = $scope.user.settings.internalId;
      //               const ijName = $scope.user.name;
      //               judgeData = {
      //                 ijcode: ijCode,
      //                 ijname: ijName
      //               };
      //             }
      //             if (!judgeData) {
      //               judgeData = {
      //                 ijcode: record.ijcode,
      //                 ijname: record.ijname
      //               };
      //             }
      //             judgeData.comment = $scope.scratchPad.comment;
      //             var scannedRecord = {
      //               coi: record.coi,
      //               task: taskDetails ? taskDetails._id : null,
      //               caseData: taskDetails ? taskDetails.caseData : record,
      //               icLanguages: $scope.scratchPad.icLanguages,
      //               judge: judgeData || null,
      //               alien: $scope.scratchPad.alien
      //             };
      //             if (isAfterTime(record) > 0) {
      //               // If inpersion true then OSI and false then OPI
      //               // if(record.inperson){
      //               if (taskDetails && taskDetails.caseData && taskDetails.caseData.judgedInOut && taskDetails.caseData.judgedInOut.length == 0) {
      //                 lateJudgeIn(ecoi, record, judgeData, finish, e);
      //                 return;
      //               }
      //               // } else {
      //               //   // Service type OPI then open late judge in popup
      //               //   if (!taskDetails) {
      //               //     lateJudgeIn(ecoi, record, judgeData, finish, e);
      //               //     return;
      //               //   }
      //               // }
      //               successCallback(ecoi, scannedRecord, finish, e);
      //             } else if (isAfterTime(record) < 0) {
      //               if (taskDetails && taskDetails.caseData && taskDetails.caseData.judgedInOut && taskDetails.caseData.judgedInOut.length == 0) {
      //                 earlyJudgeIn(ecoi, record, judgeData, finish, e);
      //                 return;
      //               }
      //               successCallback(ecoi, scannedRecord, finish, e);
      //             } else {
      //               successCallback(ecoi, scannedRecord, finish, e);
      //             }
      //           }

      //           function earlyJudgeIn(ecoi, record, judgeData, finish, e){
      //             var earltTime = isAfterTime(record);
      //             // If Judge IN early the open comment form
      //             modals.officeOperationActions.earlyJudgeIn(Math.abs(earltTime), record, $scope.scratchPad.icLanguages, judgeData, $scope.scratchPad, function (result) {
      //               if (!result.status) {
      //                 $scope.scratchPad.error = result.reason;
      //                 reset();
      //               } else {
      //                 modal.close(e);
      //                 finish.call(e, true);
      //                 $scope.scratchPad.success = 'ECOI ' + ecoi + ' Judge IN successful';
      //                 $scope.scratchPad.ecoi = null;
      //                 $scope.scratchPad.error = null;
      //                 $scope.scratchPad.interPreter = null;
      //                 $scope.scratchPad.isAllowJudge = false;
      //                 $scope.scratchPad.judge = null;
      //                 $scope.scratchPad.comment = null;
      //                 $scope.scratchPad.alien = null;
      //                 $scope.scratchPad.isAllowAlien = false;
      //                 $scope.scratchPad.isLoading = false;
      //               }
      //             })();
      //           }

      //           function lateJudgeIn(ecoi, record, judgeData, finish, e){
      //             var lateTime = isAfterTime(record);
      //             // If Judge IN Late the open reason form
      //             modals.officeOperationActions.lateJudgeIn(lateTime, record, $scope.scratchPad.icLanguages, judgeData, $scope.scratchPad, function (result) {
      //               if (!result.status) {
      //                 $scope.scratchPad.error = result.reason;
      //                 reset();
      //               } else {
      //                 modal.close(e);
      //                 finish.call(e, true);
      //                 $scope.scratchPad.success = 'ECOI ' + ecoi + ' Judge IN successful';
      //                 $scope.scratchPad.ecoi = null;
      //                 $scope.scratchPad.error = null;
      //                 $scope.scratchPad.interPreter = null;
      //                 $scope.scratchPad.isAllowJudge = false;
      //                 $scope.scratchPad.judge = null;
      //                 $scope.scratchPad.comment = null;
      //                 $scope.scratchPad.alien = null;
      //                 $scope.scratchPad.isAllowAlien = false;
      //                 $scope.scratchPad.isLoading = false;
      //               }
      //             })();
      //           }
      //           function successCallback(ecoi, scannedRecord, finish, e){
      //             $clientTask.clientJudgeInFacility(client, scannedRecord).then(function (result) {
      //               if (!result.status) {
      //                 $scope.scratchPad.error = result.reason;
      //                 reset();
      //               } else {
      //                 modal.close(e);
      //                 finish.call(e, true);
      //                 $scope.scratchPad.success = 'ECOI ' + ecoi + ' Judge IN successful';
      //                 $scope.scratchPad.ecoi = null;
      //                 $scope.scratchPad.error = null;
      //                 $scope.scratchPad.interPreter = null;
      //                 $scope.scratchPad.isAllowJudge = false;
      //                 $scope.scratchPad.judge = null;
      //                 $scope.scratchPad.comment = null;
      //                 $scope.scratchPad.alien = null;
      //                 $scope.scratchPad.isAllowAlien = false;
      //                 $scope.scratchPad.isLoading = false;
      //               }
      //             });
      //           }

      //           function reset() {
      //             $scope.scratchPad.ecoi = null;
      //             $scope.scratchPad.success = null;
      //             $scope.scratchPad.interPreter = null;
      //             $scope.scratchPad.judge = null;
      //             $scope.scratchPad.comment = null;
      //             $scope.scratchPad.alien = null;
      //             $scope.scratchPad.isAllowAlien = false;
      //             $scope.scratchPad.isLoading = false;
      //           }

      //           $scope.cancel = function(e) {
      //             modal.dismiss();
      //             finish.call(e, false);
      //           }

      //           $scope.alienMask = function (alien, toggleMaskStatus) {
      //             // console.log(toggleMaskStatus, $scope.scratchPad.alien, alien )
      //             // console.log(alien.replace(/\d(?=\d{4})/g, "*"));

      //             // if (alien.length && !toggleMaskStatus) {
      //                 // $scope.scratchPad.alien = $scope.scratchPad.alien ? ($scope.scratchPad.alien).toString() + (alien[alien.length - 1]).toString() : alien;
      //                 // var last4Digits = alien.slice(6);
      //                 // var maskedNumber = last4Digits.padStart(alien.length, '*');
      //                 // $scope.scratchPad.maskAlienVal = maskedNumber;
      //             // } else {
      //               $scope.scratchPad.alien = alien //.replace(/\d(?=\d{4})/g, "*")

      //             // }
      //           }
      //           $scope.onAlienChange = function () {
      //             $scope.alienMask($scope.scratchPad.alienVal, $scope.data.toggleMaskFlag)
      //           }

      //           $scope.toggleMask = function () {
      //             $scope.data.toggleMaskFlag = !$scope.data.toggleMaskFlag;
      //             // $scope.alienMask($scope.scratchPad.alien, $scope.data.toggleMaskFlag)
      //           };

      //         }],
      //         modal: {
      //           dismissable: false,
      //           title: 'Judge IN',
      //           template: 'components/modal/tpl/judge-in.html',
      //           // buttons: [{
      //           //   classes: 'btn-primary',
      //           //   text: 'Submit',
      //           // }, {
      //           //   classes: 'btn-default',
      //           //   text: 'Close',
      //           //   click: function (e) {
      //           //     finish.call(e, false);
      //           //     modal.close(e);
      //           //   }
      //           // }]
      //         }
      //       }, 'modal-success', null, true, true);

      //       modal
      //         .rendered
      //         .then(() => $timeout(() => $window.document.getElementById('judgeInEcoiId').focus(), focusDelay));
      //     };
      //   },
      //   judgeOutFacility: function (dataSets, permissions, finish) {
      //     finish = finish || angular.noop;
      //     return function () {
      //       var scratchPad = {
      //         error: null,
      //         success: null,
      //         reportingCourt: dataSets.reportingCourt || null,
      //         date: dataSets.date || null,
      //         autoFacilityOut: {},
      //         isAllowJudge: false,
      //         isCheckJudgeOrNot: false,
      //         isAllowRelease: true,
      //         showAutoLunchOut: false,
      //         hasServiceTypeOPI: false,
      //         ecoi: dataSets && dataSets.coi ? dataSets && dataSets.coi : null
      //       };
      //       var modal = openModal({
      //         controller: ['$scope', '$clientQueryBuilder', '$clientSwitcher', 'ngToast', '$clientTask', '$officeOperations', '$courtOperations', function ($scope, $clientQueryBuilder, $clientSwitcher, ngToast, $clientTask, $officeOperations, $courtOperations) {
      //           $scope.scratchPad = scratchPad;
      //           $scope.user = $auth.getCurrentUser();
      //           $scope.date = new Date();
      //           var client = $clientSwitcher.getCurrentClient();
      //           const isCourtJudge = ($scope.user.settings.roles.findIndex(x => x.code === 'COURT_JUDGE') >= 0);
      //           const ijCode = $scope.user.settings.internalId;
      //           var pageData = {
      //             page: 1,
      //             pageSize: 100
      //           };

      //           $scope.permissions = {
      //             hasAutoFoutPermission: permissions.includes('COURT_OPS_AUTO_FOUT'),
      //             hasAutoLoutPermission: permissions.includes('COURT_OPS_AUTO_LOUT')
      //           }

      //           var sortedJudgeDataSets = [];
      //           function init() {
      //             if($scope.scratchPad.reportingCourt.length > 0 && $scope.scratchPad.reportingCourt != 'all'){
      //               getECOIForRCourt(client, $scope.scratchPad, pageData)
      //               .then(function (res) {
      //                 //Interpreter
      //                 var interpreterDataSets = [];
      //                 let sortedInterpreterDataSets = _.sortBy(res.inJudge, ['interprername', 'sched']);

      //                 if (isCourtJudge) {
      //                   interpreterDataSets = sortedInterpreterDataSets.map(record => {
      //                     const jData = record.taskDetails.caseData.judgedInOut;
      //                     if (jData[jData.length - 1].judge && jData[jData.length - 1].judge.ijcode === ijCode) {
      //                       return {
      //                         label: record.interprername + ' | ' + record.coi + ' | ' + moment(record.sched, ["h:mm A"]).format("hh:mm A"),
      //                         value: record.coi.toString(),
      //                         vendorId: record.vendorid,
      //                         ijcode: record.ijcode
      //                       }
      //                     } else if (!jData[jData.length - 1].judge && record.ijcode === ijCode) {
      //                       return {
      //                         label: record.interprername + ' | ' + record.coi + ' | ' + moment(record.sched, ["h:mm A"]).format("hh:mm A"),
      //                         value: record.coi.toString(),
      //                         vendorId: record.vendorid,
      //                         ijcode: record.ijcode
      //                       }
      //                     }
      //                   }).filter(Boolean);
      //                 } else {
      //                   sortedInterpreterDataSets.forEach(function (record) {
      //                     interpreterDataSets.push({
      //                       label: record.interprername + ' | ' + record.coi + ' | ' + moment(record.sched, ["h:mm A"]).format("hh:mm A"),
      //                       value: record.coi.toString(),
      //                       vendorId: record.vendorid,
      //                       ijcode: record.ijcode
      //                     })
      //                   });
      //                 }
      //                 $scope.interpreterDataSets = [];
      //                 $scope.interpreterDataSets = _.uniq(interpreterDataSets, 'label');
      //                 // Judge
      //                 // var judgeDataSets = [];
      //                 sortedJudgeDataSets = _.sortBy(res.interPreterWO, ['ijname', 'sched']);
      //                 // sortedJudgeDataSets.forEach(function (record) {
      //                 //   judgeDataSets.push({
      //                 //     label: record.ijcode + ' | ' + record.ijname,
      //                 //     value: record.ijcode,
      //                 //     ijcode: record.ijcode,
      //                 //     ijname: record.ijname
      //                 //   })
      //                 // });
      //                 // $scope.judgeDataSets = [];
      //                 // $scope.judgeDataSets = _.uniq(judgeDataSets, 'label');

      //               })
      //               .catch(function (err) {
      //                 $scope.scratchPad.error = err;
      //               })
      //             }
      //           }

      //           init();

      //           $scope.getICLanguage = function () {
      //             if($scope.scratchPad.interPreter){
      //               var interpreterData = _.find($scope.interpreterDataSets, function (interpreter) {
      //                 return interpreter.value == $scope.scratchPad.interPreter;
      //               });
      //               $scope.scratchPad.ecoi = null;
      //               $scope.scratchPad.success = null;
      //               $scope.scratchPad.error = null;
      //               if(interpreterData){
      //                 $scope.scratchPad.judge = interpreterData.ijcode;
      //                 if($scope.user && $scope.user.settings && $scope.user.settings.internalId){
      //                   if($scope.user.settings.internalId == interpreterData.ijcode){
      //                     $scope.scratchPad.isAllowJudge = false;
      //                   } else if(isCourtJudge && $scope.user.settings.internalId !== interpreterData.ijcode){
      //                     $scope.scratchPad.judge = $scope.user.settings.internalId;
      //                     $scope.scratchPad.isAllowJudge = false;
      //                   } else{
      //                     $scope.scratchPad.isAllowJudge = true;
      //                   }
      //                 } else{
      //                   $scope.scratchPad.isAllowJudge = true;
      //                 }
      //               }
      //               // Judge
      //               $scope.scratchPad.ecoi = $scope.scratchPad.interPreter;
      //               setJudgeDataset($scope.scratchPad.interPreter);
      //             } else{
      //               $scope.scratchPad.judge = null;
      //             }
      //           }

      //           function setJudgeDataset(ecoi) {
      //             var judgeDataSets = [];
      //             sortedJudgeDataSets.forEach((judge) => {
      //               if (judge.coi == ecoi) {
      //                 if (judge.taskDetails && judge.taskDetails.caseData && judge.taskDetails.caseData.judgedInOut && judge.taskDetails.caseData.judgedInOut.length > 0) {
      //                   var lastRecordJIN = judge.taskDetails.caseData.judgedInOut[judge.taskDetails.caseData.judgedInOut.length - 1];
      //                   if (lastRecordJIN.event == 'Judge IN') {
      //                     if(lastRecordJIN.judge && lastRecordJIN.judge.ijcode){
      //                       $scope.scratchPad.judge = lastRecordJIN.judge.ijcode;
      //                       judgeDataSets.push({
      //                         label: lastRecordJIN.judge.ijcode + ' | ' + lastRecordJIN.judge.ijname,
      //                         value: lastRecordJIN.judge.ijcode,
      //                         ijcode: lastRecordJIN.judge.ijcode,
      //                         ijname: lastRecordJIN.judge.ijname
      //                       })
      //                     }
      //                   }
      //                 }
      //                 if (judge.sertype == OSI) {
      //                   $scope.scratchPad.showAutoLunchOut = true;
      //                   $scope.scratchPad.hasServiceTypeOPI = true;
      //                 } else {
      //                   $scope.scratchPad.showAutoLunchOut = false;
      //                   $scope.scratchPad.hasServiceTypeOPI = false;
      //                 }
      //               }
      //             })
      //             $scope.judgeDataSets = [];
      //             $scope.judgeDataSets = _.uniq(judgeDataSets, 'label');
      //           }

      //           $scope.onAutoFacilityOUTChanges = function(){
      //             if($scope.scratchPad.autoFacilityOut.isAutoFOut){
      //               $scope.scratchPad.autoLunchOut = false;
      //             }
      //           }

      //           $scope.onAutoLunchOUTChanges = function(){
      //             if($scope.scratchPad.autoLunchOut){
      //               $scope.scratchPad.autoFacilityOut.isAutoFOut = false;
      //             }
      //           }

      //           $scope.buttonClick = function (e) {
      //             $scope.scratchPad.success = null;
      //             $scope.scratchPad.error = null;
      //             var ecoi = $scope.scratchPad.ecoi;
      //             if ($scope.scratchPad.interPreter) {
      //               ecoi = $scope.scratchPad.interPreter;
      //             }
      //             if ($scope.scratchPad.autoFacilityOut && $scope.scratchPad.autoFacilityOut.isAutoFOut) {
      //               if (!$scope.scratchPad.autoFacilityOut.reason) {
      //                 $scope.scratchPad.error = 'Please enter reason';
      //                 return;
      //               }
      //             }

      //             // $officeOperations.getEcoiById(client, ecoi)
      //             $courtOperations.getCasesDetails(client, ecoi, {})
      //             .then(function (res) {
      //               if (res.status) {
      //                 if (res.data.records.length) {
      //                   var record = res.data.records[0];
      //                   var taskDetails = record.taskDetails;
      //                   $scope.scratchPad.record = record;
      //                   $scope.scratchPad.interPreter = ecoi;
      //                   if (record.sertype == OSI) {
      //                     $scope.scratchPad.showAutoLunchOut = true;
      //                     $scope.scratchPad.hasServiceTypeOPI = true;
      //                   } else {
      //                     $scope.scratchPad.showAutoLunchOut = false;
      //                     $scope.scratchPad.hasServiceTypeOPI = false;
      //                   }
      //                   if (taskDetails && taskDetails.caseData && taskDetails.caseData.checkedOut && taskDetails.caseData.checkedOut.date) {
      //                     $scope.scratchPad.error = 'Interpreter already completed Facility OUT';
      //                     reset();
      //                   } else {
      //                     if (taskDetails && taskDetails.caseData && taskDetails.caseData.judgedInOut && taskDetails.caseData.judgedInOut.length > 0) {
      //                       var lastRecordFromJudgeInOut = taskDetails.caseData.judgedInOut[taskDetails.caseData.judgedInOut.length - 1];
      //                       if (lastRecordFromJudgeInOut.event == 'Judge OUT') {
      //                         $scope.scratchPad.error = 'Interpreter already completed Judge OUT';
      //                         reset();
      //                         return;
      //                       }
      //                       if (taskDetails && taskDetails.caseData && taskDetails.caseData.lunchOut && taskDetails.caseData.lunchOut.date) {
      //                         $scope.scratchPad.showAutoLunchOut = false;
      //                         if (taskDetails && taskDetails.caseData && taskDetails.caseData.lunchIn && taskDetails.caseData.lunchIn.date) {
      //                         } else {
      //                           $scope.scratchPad.error = 'Please Lunch IN before attempting to Judge OUT';
      //                           reset();
      //                           return;
      //                         }
      //                       }

      //                       // $scope.scratchPad.judge = record.ijcode;
      //                       if (scratchPad.reportingCourt.length > 0 && scratchPad.reportingCourt != 'all') {
      //                         if ($scope.user && $scope.user.settings && $scope.user.settings.internalId) {
      //                           if ($scope.user.settings.internalId == record.ijcode) {
      //                             $scope.scratchPad.isAllowJudge = false;
      //                             // Direct judge out
      //                             judgeOut (ecoi, record, taskDetails, finish, e);
      //                             return;
      //                           } else if (isCourtJudge && $scope.user.settings.internalId !== record.ijcode){
      //                             $scope.scratchPad.judge = $scope.user.settings.internalId;
      //                             $scope.scratchPad.isAllowJudge = false;
      //                             judgeOut(ecoi, record, taskDetails, finish, e)
      //                             return;
      //                           } else {
      //                             $scope.scratchPad.isAllowJudge = true;
      //                           }
      //                         } else {
      //                           $scope.scratchPad.isAllowJudge = true;
      //                         }
      //                         if (!$scope.scratchPad.isCheckJudgeOrNot && $scope.scratchPad.ecoi) {
      //                           $scope.scratchPad.judge = record.ijcode;
      //                           $scope.scratchPad.isCheckJudgeOrNot = !$scope.scratchPad.isCheckJudgeOrNot;
      //                           // Judge
      //                           setJudgeDataset(ecoi);
      //                           return;
      //                         } else {
      //                           $scope.scratchPad.isCheckJudgeOrNot = false;
      //                         }
      //                       }
      //                       judgeOut (ecoi, record, taskDetails, finish, e);
      //                     } else {
      //                       $scope.scratchPad.error = 'Please Judge IN before attempting to Judge OUT';
      //                       reset();
      //                     }
      //                   }
      //                 }
      //                 else {
      //                   $scope.scratchPad.error = 'No Record Found';
      //                   reset();
      //                 }
      //               }
      //               else {
      //                 $scope.scratchPad.error = res.reason;
      //                 reset();
      //               }
      //             });
      //           };

      //           function judgeOut(ecoi, record, taskDetails, finish, e) {
      //             var judgeData = _.find($scope.judgeDataSets, function (judge) {
      //               return judge.value == $scope.scratchPad.judge;
      //             });
      //             if (isCourtJudge) {
      //               const ijCode = $scope.user.settings.internalId;
      //               const ijName = $scope.user.name;
      //               judgeData = {
      //                 ijcode: ijCode,
      //                 ijname: ijName
      //               };
      //             }
      //             if (!judgeData) {
      //               judgeData = {
      //                 ijcode: record.ijcode,
      //                 ijname: record.ijname
      //               };
      //             }
      //             $scope.scratchPad.judgeData = judgeData;
      //             if (judgeData) $scope.scratchPad.judgeData.comment = $scope.scratchPad.comment;
      //             // Open judge out rating form
      //             modals.officeOperationActions.judgeOutInterpreter(record, $scope.scratchPad, function (result) {
      //               if (!result.status) {
      //                 $scope.scratchPad.error = result.reason;
      //                 reset();
      //               } else {
      //                 $scope.scratchPad.success = 'ECOI ' + ecoi + ' Judge OUT successful';
      //                 // Facility out is user select auto facility out
      //                 if ($scope.scratchPad.autoFacilityOut && $scope.scratchPad.autoFacilityOut.isAutoFOut) {
      //                   var scannedRecord = {
      //                     coi: record.coi,
      //                     task: taskDetails._id,
      //                     caseData: taskDetails.caseData
      //                   };
      //                   $clientTask.clientCheckOutFacility(client, scannedRecord).then(function (result) {
      //                     if (!result.status) {
      //                       $scope.scratchPad.error = result.reason;
      //                       reset();
      //                     } else {
      //                       $scope.scratchPad.success = 'ECOI ' + ecoi + ' Auto Facility OUT successful';
      //                       modal.close(e);
      //                       finish.call(e, true);
      //                       resetError();
      //                     }
      //                   });
      //                 } else if ($scope.scratchPad.autoLunchOut) {
      //                   // Auto Lunch OUT
      //                   if (!judgeData) {
      //                     judgeData = {
      //                       ijcode: record.ijcode,
      //                       ijname: record.ijname
      //                     }
      //                   }
      //                   if (judgeData) judgeData.comment = null;
      //                   var scannedRecord = {
      //                     coi: record.coi,
      //                     task: taskDetails._id,
      //                     caseData: record.taskDetails.caseData._id,
      //                     judge: judgeData || null,
      //                     autoLunchOut: $scope.scratchPad.autoLunchOut
      //                   };
      //                   $clientTask.clientLunchOutInFacility(client, scannedRecord).then(function (result) {
      //                     if (!result.status) {
      //                       $scope.scratchPad.error = result.reason;
      //                       reset();
      //                     } else {
      //                       $scope.scratchPad.success = 'ECOI ' + ecoi + ' Auto ' + result.event + ' successful';
      //                       resetError();
      //                       modal.close(e);
      //                       finish.call(e, record);
      //                     }
      //                   });
      //                 } else {
      //                   finish.call(e, true);
      //                   resetError();
      //                   modal.close(e);
      //                 }
      //               }
      //             })();
      //           }

      //           function resetError(){
      //             $scope.scratchPad.ecoi = null;
      //             $scope.scratchPad.error = null;
      //             $scope.scratchPad.interPreter = null;
      //             $scope.scratchPad.autoFacilityOut = null;
      //             $scope.scratchPad.judge = null;
      //             $scope.scratchPad.isAllowJudge = false;
      //             $scope.scratchPad.comment = null;
      //             $scope.scratchPad.autoOption = null;
      //             $scope.scratchPad.showAutoLunchOut = true;
      //             $scope.scratchPad.autoFacilityOut = {};
      //           }

      //           function reset() {
      //             $scope.scratchPad.ecoi = null;
      //             $scope.scratchPad.success = null;
      //             $scope.scratchPad.interPreter = null;
      //             $scope.scratchPad.autoFacilityOut = null;
      //             $scope.scratchPad.judge = null;
      //             $scope.scratchPad.isAllowJudge = false;
      //             $scope.scratchPad.comment = null;
      //             $scope.scratchPad.autoOption = null;
      //             $scope.scratchPad.showAutoLunchOut = true;
      //             $scope.scratchPad.autoFacilityOut = {};
      //           }

      //           $scope.onRelease = (e) => {
      //             var ecoi = $scope.scratchPad.ecoi;
      //             modals.officeOperationActions.releaseInterpreter($scope.scratchPad.record, function (result) {
      //               if (!result.status) {
      //                 $scope.scratchPad.error = result.reason;
      //                 reset();
      //               } else {
      //                 finish.call(e, true);
      //                 modal.close(e);
      //                 $scope.scratchPad.success = 'ECOI ' + $scope.scratchPad.record.coi + ' Release Interpreter successful';
      //                 $scope.scratchPad.ecoi = null;
      //                 $scope.scratchPad.error = null;
      //                 $scope.scratchPad.interPreter = null;
      //                 $scope.scratchPad.isAllowRelease = false;
      //               }
      //             })();
      //           }

      //           $scope.cancel = function(e) {
      //             finish.call(e, false);
      //             modal.dismiss();
      //           }
      //         }],
      //         modal: {
      //           dismissable: false,
      //           title: 'Judge OUT',
      //           template: 'components/modal/tpl/judge-out.html',
      //           // buttons: [{
      //           //   classes: 'btn-primary',
      //           //   text: 'Submit',
      //           // }, {
      //           //   classes: 'btn-default',
      //           //   text: 'Close',
      //           //   click: function (e) {
      //           //     finish.call(e, false);
      //           //     modal.close(e);
      //           //   }
      //           // }]
      //         }
      //       }, 'modal-success', null, true, true);

      //       modal
      //         .rendered
      //         .then(() => $timeout(() => $window.document.getElementById('judgeOutEcoiId').focus(), focusDelay));
      //     };
      //   },
      //   validatePassword: function(userEmail, finish) {
      //     finish = finish || angular.noop;

      //     return function() {
      //       const modal = openModal({
      //         controller: ['$scope', '$cognito', function($scope,  $cognito) {

      //           $scope.isEmailRequired = !userEmail;
      //           $scope.isLoading = false;
      //           $scope.user = {
      //             email: userEmail ? userEmail : '',
      //             password: null
      //           };

      //           $scope.error = null;

      //           $scope.buttonClick = function(e) {
      //             $scope.error = null;
      //             $scope.isLoading = true;
      //             let user = Object.assign($scope.user, {rejectSessionCount: false})
      //             $cognito.authenticateUser(user)
      //             // $auth.validatePassword($scope.user)
      //               .then(data => {
      //                 $scope.isLoading = false;
      //                 // if (!data.status) {
      //                 //   $scope.error = data.error;
      //                 //   return false;
      //                 // }
      //                 finish.call(e, true);
      //                 modal.close(e);
      //               })
      //               .catch((err) => {
      //                 $scope.isLoading = false;
      //                 $scope.error = err.message;
      //                 $scope.$apply()
      //               });
      //           }

      //           $scope.close = function(e) {
      //             finish.call(e, false);
      //             modal.close(e);
      //           }
      //         }],

      //         modal: {
      //           dismissable: false,
      //           title: 'Electronic Signature | Validate Password',
      //           template: 'components/modal/tpl/validate-password.html'
      //         }
      //       }, 'modal-success', null, true, true);

      //       modal
      //         .rendered
      //         .then(() => $timeout(() => $window.document.getElementById('userPassword').focus(), focusDelay));
      //     }
      //   },
      //   judgeOutInterpreter: function (record, judgeOutScope, finish) {
      //     finish = finish || angular.noop;
      //     return function () {
      //       var scratchPad = {
      //         error: null,
      //         success: null,
      //         ecoi: record.coi || null,
      //         interpreter: "Interpreter | "+ record.interprername || null,
      //         interPreCounselled: false,
      //         reqInterPreCounselling: false,
      //         description: null,
      //         langName: record.langname,
      //         judgeIn: null,
      //         lunchOut: null,
      //         lunchIn: null,
      //         isRatingEnabled: true
      //       };
      //       var modal = openModal({
      //         controller: ['$scope', '$clientSwitcher', '$clientTask', '$clientLanguages', 'Modal', '$officeOperations', function($scope, $clientSwitcher, $clientTask, $clientLanguages, Modal, $officeOperations) {
      //           var client = $clientSwitcher.getCurrentClient();
      //           $scope.scratchPad = scratchPad;
      //           $scope.user = $auth.getCurrentUser();
      //           $scope.date = new Date();
      //           $scope.rating = 0;
      //           $scope.ratings = [{
      //             current: 0,
      //             max: 5,
      //             name: 'Professionalism'
      //           },
      //           {
      //             current: 0,
      //             max: 5,
      //             name: 'Performance'
      //           },
      //           {
      //             current: 0,
      //             max: 5,
      //             name: 'Procedures'
      //           }];
      //           $scope.model = {
      //             additionalLanguagesUsed: false,
      //             additionalLanguages: []
      //           }

      //           function init(){
      //             var taskDetails = record.taskDetails

      //             if (taskDetails && taskDetails.caseData && taskDetails.caseData.judgedInOut && taskDetails.caseData.judgedInOut.length > 0) {
      //               var lastRecordFromJudgeInOut = taskDetails.caseData.judgedInOut[taskDetails.caseData.judgedInOut.length - 1];
      //               if(lastRecordFromJudgeInOut.event == 'Judge IN'){
      //                 $scope.scratchPad.judgeIn = moment(lastRecordFromJudgeInOut.date).format('MM/DD/YYYY hh:mm A');
      //               }
      //               if(taskDetails.caseData.judgedInOut.length <= 2){
      //                 if (taskDetails && taskDetails.caseData && taskDetails.caseData.lunchOut.date) {
      //                   $scope.scratchPad.lunchOut = moment(taskDetails.caseData.lunchOut.date).format('MM/DD/YYYY hh:mm A');
      //                 }
      //                 if (taskDetails && taskDetails.caseData && taskDetails.caseData.lunchIn && taskDetails.caseData.lunchIn.date) {
      //                   $scope.scratchPad.lunchIn = moment(taskDetails.caseData.lunchIn.date).format('MM/DD/YYYY hh:mm A');
      //                 }
      //               }
      //             }

      //             // $clientLanguages.getLanguages(client)
      //             //   .then(data => {
      //             //     data = data.data.filter(x => x.isEnabled);
      //             //     data = data.map(lang => ({label: `${lang.code} | ${lang.name}`, value: lang._id}))
      //             //     $scope.additionalLanguagesItems = data;
      //             //   })
      //             //   .catch(err => {
      //             //     console.log('Error getting languages: ', err);
      //             //   });

      //               $scope.additionalLanguagesItems = [];
      //               $officeOperations.getInterpreterLangs(client, {
      //                 vendorId: record.vendorid
      //               })
      //               .then((res) => {
      //                 if(res.status && res.data.length > 0){
      //                   $scope.additionalLanguagesItems = [];
      //                   res.data.map((lang) => {
      //                     if($scope.scratchPad.langName != lang.name){
      //                       $scope.additionalLanguagesItems.push({
      //                         label: `${lang.code} | ${lang.name}`,
      //                         value: lang.code
      //                       })
      //                     }
      //                   }).filter(Boolean);
      //                 }
      //               })

      //           }

      //           $scope.validatePassword = function(data, e) {

      //             const userEmail = $scope.user.email;

      //             Modal.officeOperationActions.validatePassword(userEmail, function(res) {
      //               //Returns true if password is correct
      //               if (res) {
      //                 $clientTask.clientJudgeOutFacility(client, data).then(function(result) {
      //                   finish.call(e, result);
      //                   modal.close(e);
      //                 });
      //               }
      //             })();
      //           }

      //           init()
      //           $scope.buttonClick = function (e) {
      //             // if($scope.scratchPad.isRatingEnabled){
      //             //   if($scope.ratings[0].current > 0 && $scope.ratings[1].current > 0 && $scope.ratings[2].current > 0){
      //             //   } else {
      //             //     $scope.scratchPad.error = "Please provide ratings";
      //             //     return;
      //             //   }
      //             // }
      //             $scope.scratchPad.error = null;
      //             $scope.scratchPad.professionalism = $scope.ratings[0].current;
      //             $scope.scratchPad.performance = $scope.ratings[1].current;
      //             $scope.scratchPad.procedures = $scope.ratings[2].current;

      //             var data = {
      //               coi: record.coi,
      //               task: record.taskDetails._id,
      //               caseData: record.taskDetails.caseData,
      //               updateData: $scope.scratchPad,
      //               autoFacilityOut: judgeOutScope.autoFacilityOut,
      //               judge: judgeOutScope.judgeData || null,
      //               additionalLanguages: $scope.model.additionalLanguages.filter(x => x !== record.langcode),
      //               autoJudgeOutIn: judgeOutScope.autoJudgeOutIn || false
      //             };

      //             $scope.validatePassword(data, e);
      //           };

      //           $scope.toggleChange = function() {
      //             if(!scratchPad.isRatingEnabled){
      //               $scope.ratings[0].current = 0;
      //               $scope.ratings[1].current = 0;
      //               $scope.ratings[2].current = 0;
      //             }
      //           }

      //           $scope.reset = function () {
      //             $scope.ratings = [{
      //               current: 0,
      //               max: 5,
      //               name: 'Professionalism'
      //             },
      //             {
      //               current: 0,
      //               max: 5,
      //               name: 'Performance'
      //             },
      //             {
      //               current: 0,
      //               max: 5,
      //               name: 'Procedures'
      //             }];
      //             $scope.scratchPad.interPreCounselled = false;
      //             $scope.scratchPad.reqInterPreCounselling = false;
      //             $scope.scratchPad.description = null;
      //           }
      //         }],
      //         modal: {
      //           dismissable: true,
      //           title: 'Judge Check Out | Interpreter',
      //           template: 'components/modal/tpl/judge-out-interpreter.html',
      //         }
      //       }, 'modal-success', null, true, true);
      //     };
      //   },
      //   lateJudgeIn: function (lateTime, record, icLanguages, judgeData, judgeInScope, finish) {
      //     finish = finish || angular.noop;
      //     return function () {
      //       var scratchPad = {
      //         error: null,
      //         success: null,
      //         isLoading: false
      //       };
      //       var modal = openModal({
      //         controller: ['$scope', '$clientSwitcher', '$clientTask', function ($scope, $clientSwitcher, $clientTask) {
      //           var client = $clientSwitcher.getCurrentClient();
      //           $scope.scratchPad = scratchPad;
      //           $scope.user = $auth.getCurrentUser();
      //           $scope.date = new Date();
      //           $scope.reasons=[{
      //             label: 'Delayed Start',
      //             value: 'Delayed Start'
      //           },
      //           {
      //             label: 'Interpreter Late',
      //             value: 'Interpreter Late'
      //           },
      //           {
      //             label: 'Other',
      //             value: 'Other'
      //           }]

      //           $scope.buttonClick = function (e) {
      //             $scope.scratchPad.isLoading = true;
      //             var scannedRecord = {
      //               coi: record.coi,
      //               task: record.taskDetails ? record.taskDetails._id : null,
      //               caseData: record.taskDetails? record.taskDetails.caseData : record,
      //               lateData: $scope.scratchPad,
      //               lateTime: lateTime,
      //               icLanguages: icLanguages,
      //               judge: judgeData,
      //               alien: judgeInScope.alien
      //             };
      //             $clientTask.clientJudgeInFacility(client, scannedRecord).then(function (result) {
      //               $scope.scratchPad.isLoading = false;
      //               finish.call(e, result);
      //               modal.close(e);
      //             });
      //           };

      //         }],
      //         modal: {
      //           dismissable: false,
      //           title: 'Reason | Late Hearing Start',
      //           template: 'components/modal/tpl/late-judge-in.html',
      //           // buttons: [{
      //           //   classes: 'btn-primary',
      //           //   text: 'Submit',
      //           // }]
      //         }
      //       }, 'modal-success', null, true, true);
      //     };
      //   },

      //   earlyJudgeIn: function (earlyTime, record, icLanguages, judgeData, judgeInScope, finish) {
      //     finish = finish || angular.noop;
      //     return function () {
      //       var scratchPad = {
      //         error: null,
      //         success: null,
      //         isLoading: false
      //       };
      //       var modal = openModal({
      //         controller: ['$scope', '$clientSwitcher', '$clientTask', function ($scope, $clientSwitcher, $clientTask) {
      //           var client = $clientSwitcher.getCurrentClient();
      //           $scope.scratchPad = scratchPad;
      //           $scope.user = $auth.getCurrentUser();
      //           $scope.date = new Date();

      //           $scope.submitComment = function (e) {
      //             $scope.scratchPad.isLoading = true;
      //             var scannedRecord = {
      //               coi: record.coi,
      //               task: record.taskDetails ? record.taskDetails._id : null,
      //               caseData: record.taskDetails? record.taskDetails.caseData : record,
      //               earlyData: {
      //                 'comment': $scope.scratchPad.comments
      //               },
      //               earlyTime: earlyTime,
      //               icLanguages: icLanguages,
      //               judge: judgeData,
      //               alien: judgeInScope.alien
      //             };
      //             $clientTask.clientJudgeInFacility(client, scannedRecord).then(function (result) {
      //               $scope.scratchPad.isLoading = false;
      //               finish.call(e, result);
      //               modal.close(e);
      //             });
      //           };

      //         }],
      //         modal: {
      //           dismissable: false,
      //           title: 'Early Start Reason',
      //           template: 'components/modal/tpl/early-judge-in.html',
      //           // buttons: [{
      //           //   classes: 'btn-primary',
      //           //   text: 'Submit',
      //           // }]
      //         }
      //       }, 'modal-success', null, true, true);
      //     };
      //   },

      //   ecoi: function (dataSets, finish) {
      //     finish = finish || angular.noop;
      //     return function () {
      //       var scratchPad = {
      //         error: null,
      //         success: null,
      //         ecoi: null,
      //         interpreter: null,
      //         reportingCourt: dataSets.reportingCourt || null,
      //         date: dataSets.date || null,
      //         dateDay: moment(dataSets.date).format('dddd'),
      //         isLoading: false,
      //         records: null
      //       };
      //       var modal = openModal({
      //         controller: ['$scope', '$clientQueryBuilder', '$clientSwitcher', '$q', '$officeOperations', '$printCoiCoti', '$courtOperations', function ($scope, $clientQueryBuilder, $clientSwitcher, $q, $officeOperations, $printCoiCoti, $courtOperations) {
      //           var currentCli = $clientSwitcher.getCurrentClient();
      //           $scope.scratchPad = scratchPad;
      //           $scope.user = $auth.getCurrentUser();
      //           $scope.allValue = 'all';
      //           var pageData = {
      //             page: 1,
      //             pageSize: 100
      //           };

      //           $scope.interpreterSettings = function () {
      //             var str = 'Interpreter';
      //             return {
      //               selectedStr: 'Selected ' + str + ': ',
      //               noSelection: 'No ' + str + ' Selected',
      //               allLabel: 'All ' + str
      //             };
      //           };

      //           $scope.coiSettings = function () {
      //             var str = 'COI';
      //             return {
      //               selectedStr: 'Selected ' + str + ': ',
      //               noSelection: 'No ' + str + ' Selected',
      //               allLabel: 'All ' + str
      //             };
      //           };

      //           $scope.filterChange = function (filterName) {
      //             if ($scope.scratchPad.coi || $scope.scratchPad.interPreter) {
      //               // Interpreter
      //               if (filterName == 'interPreter') {
      //                 $scope.scratchPad.isLoading = true;
      //                 if ($scope.scratchPad.interPreter != 'all') {
      //                   previewQueryResult()
      //                     .then(function (res) {
      //                       var woRecords = [];
      //                       res.interPreterWO.forEach(function (record) {
      //                         // if (record.taskDetails) {
      //                           woRecords.push(record)
      //                         // }
      //                       })

      //                       $scope.scratchPad.records = manipulateArray(woRecords); //woRecords;
      //                       $scope.scratchPad.error = null;
      //                       // if ($scope.scratchPad.records.length == 0) {
      //                       //   // $scope.scratchPad.error = 'No records found';
      //                       // }
      //                       setFilterDatas(res.interPreterWO);
      //                     })
      //                     .catch(function (err) {
      //                       $scope.scratchPad.error = err;
      //                     })
      //                 } else {
      //                   $scope.coiDataSets = [];
      //                 }
      //                 $scope.scratchPad.isLoading = false;
      //               }

      //               // ECOI
      //               if (filterName == 'ecoi') {
      //                 $scope.scratchPad.isLoading = true;
      //                 previewQueryResult()
      //                   .then(function (res) {
      //                     var woRecords = [];
      //                     res.interPreterWO.forEach(function (record) {
      //                       // if (record.taskDetails) {
      //                         woRecords.push(record)
      //                       // }
      //                     })
      //                     $scope.scratchPad.records = manipulateArray(woRecords); //woRecords;
      //                     $scope.scratchPad.error = null;
      //                     // if ($scope.scratchPad.records.length == 0) {
      //                     //   $scope.scratchPad.error = 'No records found';
      //                     // }
      //                     $scope.scratchPad.isLoading = false;
      //                   })
      //                   .catch(function (err) {
      //                     $scope.scratchPad.error = err;

      //                   })
      //               }
      //             }
      //           }

      //           function previewQueryResult() {
      //             var defer = $q.defer();
      //             var filter = {
      //               startDate: $scope.scratchPad.date,
      //               endDate: $scope.scratchPad.date,
      //               // searchValue: $scope.data.searchValue ? $scope.data.searchValue.toLowerCase() : $scope.data.searchValue,
      //               coi: $scope.scratchPad.ecoi ? [$scope.scratchPad.ecoi] : $scope.allValue,
      //               // hrgCourt: $scope.scratchPad.hrgCourt ? $scope.scratchPad.hrgCourt : $scope.allValue,
      //               // reportingCourt: $scope.scratchPad.reportingCourt ? $scope.scratchPad.reportingCourt : $scope.allValue,
      //               // judge: $scope.data.judge ? $scope.data.judge : $scope.allValue,
      //               interPreter: $scope.scratchPad.interPreter ? [$scope.scratchPad.interPreter] : $scope.allValue,
      //               // langName: $scope.data.langName ? $scope.data.langName : $scope.allValue,
      //               // alien: $scope.data.alien ? $scope.data.alien : $scope.allValue
      //             }
      //             // $officeOperations.filterCourtOperationsDetails(currentCli, filter, pageData)
      //             $courtOperations.courtOpsCases(currentCli, filter, pageData)
      //               .then(function (res) {
      //                 if (res.status) {
      //                   defer.resolve(res.data.statusWorkOrder);
      //                   // if (res.data.workOrder.interPreterWO.length > 0) {
      //                   // } else {
      //                   //   defer.reject('No records found');
      //                   // }
      //                 } else {
      //                   defer.reject('No Connection to Database - Please Try Again');
      //                 }
      //               })
      //               .catch(function (err) {
      //                 defer.reject(err.message || err);
      //               })
      //             return defer.promise
      //           }

      //           function manipulateArray(woRecords) {
      //             var workOrderRecords;
      //             workOrderRecords = woRecords.map(function (woRecord) {
      //               if (woRecord && woRecord.taskDetails && woRecord.taskDetails.caseData) {
      //                 var judgedInOuts = woRecord.taskDetails.caseData.judgedInOut;
      //                 if (judgedInOuts.length % 2 != 0) {
      //                   judgedInOuts.push("N/A")
      //                 }
      //                 var arrJudgeInOut = [];
      //                 var IN, OUT;
      //                 judgedInOuts.forEach(function (judgedInOut, index) {
      //                   if (index % 2 == 0) {
      //                     IN = judgedInOut;
      //                   } else {
      //                     OUT = judgedInOut;
      //                     arrJudgeInOut.push({
      //                       'IN' : IN,
      //                       'OUT' : OUT
      //                     });
      //                   }
      //                 })
      //                 woRecord.judgedInOuts = arrJudgeInOut;
      //               }
      //               return woRecord;
      //             })
      //             return(workOrderRecords);
      //           }

      //           function init() {
      //             // previewQueryResult()
      //             //   .then(function (res) {
      //             //     $scope.interpreterDataSets = [];
      //             //     var interpreterDataSets = [];
      //             //     res.interPreterWO.forEach(function (record) {
      //             //       interpreterDataSets.push({
      //             //         label: record.interprername,
      //             //         value: record.interprername
      //             //       })
      //             //     });

      //             //     $scope.interpreterDataSets = _.sortBy(_.uniq(interpreterDataSets, 'label'), ['label']);
      //             //   })
      //             //   .catch(function (err) {
      //             //     $scope.scratchPad.error = err;
      //             //   })
      //             $scope.interpreterDataSets = _.sortBy(_.uniq(dataSets.interpreterDataSets, 'label'), ['label']);
      //           }

      //           function setFilterDatas(records) {
      //             $scope.coiDataSets = [];
      //             var coiDataSets = [];
      //             records.forEach(function (record) {
      //               coiDataSets.push({
      //                 label: record.coi,
      //                 value: record.coi
      //               })
      //             });

      //             $scope.coiDataSets = _.sortBy(_.uniq(coiDataSets, 'label'), ['label']);
      //           }

      //           $scope.checkInValue = function (record) {
      //             var taskDetails = record.taskDetails;
      //             if (taskDetails && taskDetails.caseData && taskDetails.caseData.checkedIn && taskDetails.caseData.checkedIn.date) {
      //               return moment(record.taskDetails.caseData.checkedIn.date).format('hh:mm a')
      //             } else {
      //               return 'N/A';
      //             }
      //           }

      //           $scope.checkOutValue = function (record) {
      //             var taskDetails = record.taskDetails;
      //             if (taskDetails && taskDetails.caseData && taskDetails.caseData.checkedOut && taskDetails.caseData.checkedOut.date) {
      //               return moment(record.taskDetails.caseData.checkedOut.date).format('hh:mm a')
      //             } else {
      //               return 'N/A';
      //             }
      //           }

      //           $scope.lunchInValue = function (record) {
      //             var taskDetails = record.taskDetails;
      //             if (taskDetails && taskDetails.caseData && taskDetails.caseData.lunchIn && taskDetails.caseData.lunchIn.date) {
      //               return moment(record.taskDetails.caseData.lunchIn.date).format('hh:mm a')
      //             } else {
      //               return 'N/A';
      //             }
      //           }

      //           $scope.lunchOutValue = function (record) {
      //             var taskDetails = record.taskDetails;
      //             if (taskDetails && taskDetails.caseData && taskDetails.caseData.lunchOut && taskDetails.caseData.lunchOut.date) {
      //               return moment(record.taskDetails.caseData.lunchOut.date).format('hh:mm a')
      //             } else {
      //               return 'N/A';
      //             }
      //           }

      //           $scope.judgeInValue = function (judgeRecord) {
      //             if (judgeRecord) {
      //               return moment(judgeRecord.date).format('hh:mm a')
      //             } else {
      //               return 'N/A';
      //             }
      //           }

      //           $scope.judgeOutValue = function (judgeRecord) {
      //             if (judgeRecord != 'N/A') {
      //               return moment(judgeRecord.date).format('hh:mm a')
      //             } else {
      //               return 'N/A';
      //             }
      //           }

      //           $scope.reportingCourtValue = function (value) {
      //             return value.toString();
      //           }

      //           $scope.judgeNameValue = (judgeRecord, record) => {
      //             if (judgeRecord && judgeRecord.user) {
      //                 return judgeRecord.user.name
      //             } else {
      //                 return record.ijname;
      //             }
      //           }

      //           $scope.lunchJudgeNameValue = (record) => {
      //               var taskDetails = record.taskDetails;
      //               if (taskDetails && taskDetails.caseData && taskDetails.caseData.lunchOut && taskDetails.caseData.lunchOut.user) {
      //                   return taskDetails.caseData.lunchOut.user.name
      //               } else {
      //                   return record.ijname;
      //               }
      //           }

      //           $scope.printEcoiIndividual = function(record) {
      //             $printCoiCoti.print(record);
      //           }

      //           init();
      //           $scope.buttonClick = function (e) {
      //             sessionStorage.coiRecord = JSON.stringify({
      //               record: $scope.scratchPad.records,
      //               individual: false
      //           });
      //             window.w = window.open('/print/office-operations/ecoi', '_blank');
      //             window.w.coiRecord = {
      //               record: $scope.scratchPad.records,
      //               individual: false
      //             };
      //             // setTimeout(function () {
      //             //   window.w.print();
      //             // }, 2000);
      //             finish.call(e, true);
      //           };

      //         }],
      //         modal: {
      //           dismissable: false,
      //           title: 'ECOI',
      //           template: 'components/modal/tpl/ecoi.html',
      //           buttons: [{
      //             classes: 'btn-default',
      //             text: 'Close',
      //             click: function (e) {
      //               finish.call(e, false);
      //               modal.close(e);
      //             }
      //           }]
      //         }
      //       }, 'modal-success', 'lg');
      //     };
      //   },
      //   earlyCheckOut: function (durationInSeconds, record, totalworkTime, finish) {
      //     finish = finish || angular.noop;
      //     return function () {
      //       var scratchPad = {
      //         error: null,
      //         success: null
      //       };
      //       var modal = openModal({
      //         controller: ['$scope', '$clientSwitcher', '$clientTask', function ($scope, $clientSwitcher, $clientTask) {
      //           var client = $clientSwitcher.getCurrentClient();
      //           $scope.scratchPad = scratchPad;
      //           $scope.user = $auth.getCurrentUser();
      //           const userEmail = $scope.user.email;
      //           $scope.date = new Date();
      //           $scope.interPreter = record.interprername;
      //           $scope.woNumber = record.coi;
      //           $scope.reasons = [{
      //             label: 'No Additional Requirements',
      //             value: 'No Additional Requirements'
      //           },
      //           {
      //             label: 'Respondent Did Not Appear',
      //             value: 'Respondent Did Not Appear'
      //           },
      //           {
      //             label: 'Judge Did Not Appear',
      //             value: 'Judge Did Not Appear'
      //           },
      //           {
      //             label: 'Interpreter End Time Request',
      //             value: 'Interpreter End Time Request'
      //           },
      //           {
      //             label: 'Interpreter Sick',
      //             value: 'Interpreter Sick'
      //           },
      //           {
      //             label: 'Other Reason',
      //             value: 'Other Reason'
      //           }];

      //           // $scope.minCheckoutTime = function () {
      //           //   var taskDetails = record.taskDetails;
      //           //   var judgeInTime = moment(record.sched, "HH:mm:ss");
      //           //   if (taskDetails && taskDetails.caseData && taskDetails.caseData.judgedInOut && taskDetails.caseData.judgedInOut.length > 0) {
      //           //     var lastRecordFromJudgeInOut = taskDetails.caseData.judgedInOut[0];
      //           //     if (lastRecordFromJudgeInOut.event == 'Judge IN') {
      //           //       var judgeIN = lastRecordFromJudgeInOut.date;
      //           //       judgeInTime = moment(judgeIN);
      //           //     }
      //           //   }
      //           //   return judgeInTime.add(earlyTimeInSecond, 'seconds').format('hh:mm A');
      //           // }

      //           $scope.woTotalTime = () => {
      //             if (totalworkTime) {
      //               const hours = Math.floor(totalworkTime);
      //               const minutes = Math.floor((totalworkTime * 60) % 60);
      //               return hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0');
      //               }
      //           }

      //           $scope.buttonClick = function (e) {
      //             var scannedRecord = {
      //               coi: record.coi,
      //               task: record.taskDetails._id,
      //               caseData: record.taskDetails.caseData,
      //               earlyData: $scope.scratchPad,
      //               earlyCheckOutTime: durationInSeconds / 60000,
      //               icAppearedButNotUsed: record.icAppearedButNotUsed || null,
      //               icNotUsedDenyRelease: record.icNotUsedDenyRelease || null,
      //               extensionOverride: record.extensionOverride || null
      //             };

      //             $scope.release = {
      //               releaseType: 'now',
      //               comments: $scope.scratchPad.comment ? $scope.scratchPad.comment : ''
      //             };
      //             $scope.releaseEvent = null;
      //             var records = [{
      //               coi: record.coi,
      //               task: record.taskDetails._id,
      //               caseDataId: record.taskDetails.caseData._id,
      //               release: $scope.release,
      //               releaseEvent: null
      //             }];

      //             modals.officeOperationActions.validatePassword(userEmail, function (res) {
      //               //Returns true if password is correct
      //               if (res) {
      //                 $clientTask.releaseInterpreter(client, records)
      //                 .then(function (result) {
      //                   return $clientTask.clientCheckOutFacility(client, scannedRecord);
      //                 })
      //                 .then(function (result) {
      //                   finish.call(e, result);
      //                   modal.close(e);
      //                 });
      //               }
      //             })();


      //           };

      //           $scope.denyRelease = function (e) {
      //             $clientTask.earlyFOUTDenyRelease(client)
      //               .then((result) => {
      //                 modal.close(e);
      //               });
      //           }

      //           $scope.denyFout = function (e) {
      //             modals.officeOperationActions.validatePassword(userEmail, function (res) {
      //               //Returns true if password is correct
      //               if (res) {
      //                 const data = {
      //                   coi: record.coi,
      //                   task: record.taskDetails._id,
      //                   caseDataId: record.taskDetails.caseData._id,
      //                   denyFoutComment: $scope.scratchPad.comment ? $scope.scratchPad.comment : '',
      //                   reason: $scope.scratchPad.reason
      //                 };
      //                 $clientTask.denyFacilityOut(client, data)
      //                   .then(result => {
      //                     finish.call(e, { status: true, denyFout: true });
      //                     modal.close(e);
      //                   });
      //               }
      //             })();
      //           }

      //           $scope.onClose = function (e) {
      //             finish.call(e, {
      //               status: false
      //             });
      //             modal.close(e);
      //           }

      //         }],
      //         modal: {
      //           dismissable: false,
      //           title: 'Early Facility OUT/ Not Released',
      //           template: 'components/modal/tpl/early-check-out-facility.html'
      //         }
      //       }, 'modal-success', null, false, true);
      //     };
      //   },
      //   ICAppearedButNotUse: function (record, finish) {
      //     finish = finish || angular.noop;
      //     return function () {
      //       var scratchPad = {
      //         error: null,
      //         success: null
      //       };
      //       var modal = openModal({
      //         controller: ['$scope', '$clientSwitcher', '$clientTask', function ($scope, $clientSwitcher, $clientTask) {
      //           var client = $clientSwitcher.getCurrentClient();
      //           $scope.scratchPad = scratchPad;
      //           $scope.user = $auth.getCurrentUser();
      //           $scope.date = new Date();
      //           const userEmail = $scope.user.email;

      //           $scope.release = function (e) {
      //             modals.officeOperationActions.validatePassword(userEmail, function(res) {
      //               //Returns true if password is correct
      //               if (res) {
      //                 finish.call(e, {
      //                   denyRelease: false,
      //                   reason: $scope.scratchPad.icAppearedButNotUsed
      //                 });
      //                 modal.close(e);
      //               }
      //             })();
      //           };

      //           $scope.denyFout = function (e) {
      //             modals.officeOperationActions.validatePassword(userEmail, function (res) {
      //               //Returns true if password is correct
      //               if (res) {
      //                 const data = {
      //                   coi: record.coi,
      //                   task: record.taskDetails._id,
      //                   caseDataId: record.taskDetails.caseData._id,
      //                   denyFoutComment: $scope.scratchPad.icAppearedButNotUsed
      //                 };
      //                 $clientTask.denyFacilityOut(client, data)
      //                   .then(result => {
      //                     finish.call(e, { denyFout: true });
      //                     modal.close(e);
      //                   });
      //               }
      //             })();
      //           }
      //         }],
      //         modal: {
      //           dismissable: false,
      //           title: 'Interpreter Appeared but not Used',
      //           template: 'components/modal/tpl/ic-appeared-but-not-use.html'
      //         }
      //       }, 'modal-success', null, true, true);
      //     };
      //   },
      //   extendInterpreter: function (record, finish) {
      //     finish = finish || angular.noop;
      //     return function () {
      //       var scratchPad = {
      //         error: null,
      //         success: null,
      //         totalLunch: 'N/A',
      //         extendTimeInMinute: null,
      //         extendIC: [],
      //         businessDay: null,
      //         ecoiStatus: null,
      //         nextHourTime: null,
      //         extendTime: null,
      //         isCheckBoxRequire: true
      //       };
      //       var modal = openModal({
      //         controller: ['$scope', '$clientSwitcher', '$clientTask', '$clientOffice', '$timezones', 'CalculateWorkOrderTime', function ($scope, $clientSwitcher, $clientTask, $clientOffice, $timezones, CalculateWorkOrderTime) {
      //           var client = $clientSwitcher.getCurrentClient();
      //           $scope.scratchPad = scratchPad;
      //           $scope.model = {
      //             nextHourTime: null,
      //             extendTime: null
      //           };
      //           $scope.user = $auth.getCurrentUser();
      //           $scope.date = new Date();
      //           $scope.record = record;
      //           $scope.timeframes = timeSch;

      //           function convertSecondToTime(duration) {
      //             if (duration && duration > 0) {
      //               var hours = Math.floor(duration / 3600);
      //               var minutes = Math.floor((duration - hours * 3600) / 60);
      //               var seconds = Math.floor((duration - hours * 3600) % 10);
      //               return (
      //                 hours.toString().padStart(2, '0') +
      //                 ':' +
      //                 minutes.toString().padStart(2, '0')
      //               );
      //             }
      //           }
      //           let timezoneOffset = moment().format('ZZ');
      //           function init() {
      //             var taskDetails = record.taskDetails;
      //             getOfiice(taskDetails.location);
      //             CalculateWorkOrderTime(taskDetails.caseData)
      //               .then(woCalculations => {
      //                 timezoneOffset = getECOITimezone(taskDetails.caseData.timezone);
      //                 if (taskDetails && taskDetails.caseData && taskDetails.caseData.checkedIn && taskDetails.caseData.checkedIn.date) {
      //                   $scope.scratchPad.facilityIn = moment(taskDetails.caseData.checkedIn.date).utcOffset(timezoneOffset).format('hh:mm A');
      //                 }
      //                 if (taskDetails && taskDetails.caseData && taskDetails.caseData.judgedInOut && taskDetails.caseData.judgedInOut.length > 0) {
      //                   var firstJudgeIn = taskDetails.caseData.judgedInOut[0];
      //                   if (firstJudgeIn) {
      //                     $scope.scratchPad.firstJudgeIn = moment(firstJudgeIn.date).utcOffset(timezoneOffset).format('hh:mm A');
      //                     $scope.scratchPad.lateJudgeIn = woCalculations.lateTimeInNumber;
      //                   }
      //                   var lastRecordFromJudgeInOut = taskDetails.caseData.judgedInOut[taskDetails.caseData.judgedInOut.length - 1];
      //                   if (lastRecordFromJudgeInOut.event == 'Judge OUT') {
      //                     $scope.scratchPad.lastJudgeOut = moment(lastRecordFromJudgeInOut.date).utcOffset(timezoneOffset).format('hh:mm A');
      //                   }

      //                 }
      //                 if (taskDetails && taskDetails.caseData && taskDetails.caseData.lunchOut && taskDetails.caseData.lunchOut.date) {
      //                   if (taskDetails && taskDetails.caseData && taskDetails.caseData.lunchIn && taskDetails.caseData.lunchIn.date) {
      //                     var startTime = moment(taskDetails.caseData.lunchOut.date).utcOffset(timezoneOffset).format();
      //                     var endTime = moment(taskDetails.caseData.lunchIn.date).utcOffset(timezoneOffset).format();
      //                     var duration = moment(endTime).diff(moment(startTime)) / 1000;
      //                     if (duration > 0) {
      //                       $scope.scratchPad.totalLunch = convertSecondToTime(duration);
      //                     }
      //                   }
      //                 }
      //                 if (taskDetails && taskDetails.caseData && taskDetails.caseData.extendIC && taskDetails.caseData.extendIC.length > 0) {
      //                   $scope.scratchPad.extendIC = taskDetails.caseData.extendIC;
      //                 }

      //                 var startTime = woCalculations.startTime;
      //                 var minWorkOrder = woCalculations.minWorkOrder;
      //                 const totalWOTime = !startTime ? minWorkOrder : moment.duration(moment().diff(startTime));
      //                 const totalJobTime = totalWOTime - woCalculations.totalLunchTimeInDuration;
      //                 const hours = Math.floor((totalJobTime / (1000 * 60 * 60)) % 24);
      //                 const minutes = Math.floor((totalJobTime / (1000 * 60)) % 60);
      //                 const woDisplay = hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0');

      //                 $scope.scratchPad.totalWorkTime = woDisplay;
      //                 if (taskDetails && taskDetails.caseData.release && taskDetails.caseData.release.length > 0) {
      //                   var lastEvent = taskDetails.stateActivity[taskDetails.stateActivity.length - 1];
      //                   $scope.scratchPad.ecoiStatus = 'IN Facility';
      //                   if (['Extend', 'Judge IN', 'Lunch OUT', 'Judge OUT'].indexOf(lastEvent.state) <= -1) {
      //                     $scope.scratchPad.ecoiStatus = 'Released';
      //                   }
      //                 } else {
      //                   $scope.scratchPad.ecoiStatus = 'IN Facility';
      //                 }
      //               })
      //           }
      //           init();

      //           function getOfiice(officeId){
      //             $clientOffice.detail(client, officeId)
      //             .then((res) => {
      //               $scope.scratchPad.businessDay = res.businessDays;
      //             })
      //           }

      //           function getTimeFromMins(mins, timezoneOffset) {
      //             return moment().utcOffset(timezoneOffset).startOf('day').add(mins, 'minutes').format();
      //           }

      //           $scope.convertDateToTime = function(dateTime){
      //             return moment(dateTime).utcOffset(timezoneOffset).format('hh:mm A');
      //           }

      //           $scope.onNextHoursTimeChanges = function () {
      //             $scope.scratchPad.isCheckBoxRequire = true;
      //             if ($scope.scratchPad.nextHourTime) {
      //               $scope.scratchPad.isCheckBoxRequire = false;
      //               $scope.scratchPad.extendTime = false;
      //             }
      //           }

      //           $scope.onExtendTimeChanges = function () {
      //             $scope.scratchPad.isCheckBoxRequire = true;
      //             if ($scope.scratchPad.extendTime) {
      //               $scope.scratchPad.isCheckBoxRequire = false;
      //               $scope.scratchPad.nextHourTime = false
      //             } else {
      //               $scope.scratchPad.extendTimeInMinute = null;
      //             }
      //           }

      //           $scope.onExtend = function (e) {
      //             var currentTimeMinute = moment().utcOffset(timezoneOffset).format('mm');
      //             var remainingMinute = 60 - currentTimeMinute;
      //             var dateIndex = moment().day();
      //             // if(record.sertype == OPI || record.sertype == VRI){
      //             //   $scope.scratchPad.error = `Extend of a ${record.sertype} Work Order is Not Permitted. Please Judge Out the Interpreter to Complete`;
      //             //   return;
      //             // }
      //             if(!$scope.scratchPad.businessDay){
      //               scratchPad.error = 'Please set Court Working Hours';
      //               return;
      //             }
      //             var currentBusinessDay = $scope.scratchPad.businessDay[dateIndex - 1];

      //             if($scope.record && $scope.record.taskDetails && $scope.record.taskDetails.caseData){
      //               if($scope.record.taskDetails.caseData.extendIC && $scope.record.taskDetails.caseData.extendIC.length > 0){
      //                 var lastExtendIC = $scope.record.taskDetails.caseData.extendIC[$scope.record.taskDetails.caseData.extendIC.length - 1];
      //                 if(lastExtendIC && $scope.scratchPad.nextHourTime){
      //                   var totalExtendTime = moment().add(remainingMinute + 60, 'minutes').format();
      //                   if(lastExtendIC.nextHourTime){
      //                     totalExtendTime = moment(lastExtendIC.nextHourTime).add(60, 'minutes').format();
      //                   } else {
      //                     totalExtendTime = moment(lastExtendIC.extendTime).add(60, 'minutes').format();
      //                   }
      //                   $scope.model.nextHourTime = moment.utc(totalExtendTime);
      //                   $scope.model.extendTime = null;
      //                 } else {
      //                   var totalExtendTime = moment().add(remainingMinute + $scope.scratchPad.extendTimeInMinute, 'minutes').format();
      //                   if(lastExtendIC.extendTime){
      //                     totalExtendTime = moment(lastExtendIC.extendTime).add($scope.scratchPad.extendTimeInMinute, 'minutes').format();
      //                   } else {
      //                     totalExtendTime = moment(lastExtendIC.nextHourTime).add($scope.scratchPad.extendTimeInMinute, 'minutes').format();
      //                   }
      //                   $scope.model.extendTime = moment.utc(totalExtendTime);
      //                   $scope.model.nextHourTime = null;
      //                 }
      //               } else {
      //                 if($scope.scratchPad.nextHourTime){
      //                   var totalExtendTime = moment().add(remainingMinute + 60, 'minutes').format();
      //                   $scope.model.nextHourTime = moment.utc(totalExtendTime);
      //                   $scope.model.extendTime = null;
      //                 } else {
      //                   var totalExtendTime = moment().add(remainingMinute + $scope.scratchPad.extendTimeInMinute, 'minutes').format();
      //                   $scope.model.extendTime = moment.utc(totalExtendTime);
      //                   $scope.model.nextHourTime = null;
      //                 }
      //               }
      //             }
      //             var index = $timezones.findIndex(function (x) {
      //               return x.label === record.timezonename;
      //             })
      //             // var timezoneOffset = $timezones[index] ? $timezones[index].offset : moment().format('ZZ');

      //             if(moment(totalExtendTime).utcOffset(timezoneOffset).format() > moment(getTimeFromMins(currentBusinessDay.max, timezoneOffset)).utcOffset(timezoneOffset).format()){
      //               scratchPad.error = `You can not extend beyond Court Working Hours ${moment(getTimeFromMins(currentBusinessDay.min, timezoneOffset)).format('hh:mm A')} to ${moment(getTimeFromMins(currentBusinessDay.max, timezoneOffset)).format('hh:mm A')}`
      //               return;
      //             }

      //             var records = {
      //               coi: record.coi,
      //               task: record.taskDetails._id,
      //               caseDataId: record.taskDetails.caseData._id,
      //               extend: $scope.model
      //             };
      //             const userEmail = $scope.user.email;
      //             modals.officeOperationActions.validatePassword(userEmail, function(res) {
      //               //Returns true if password is correct
      //               if (res) {
      //                 $clientTask.extendInterpreter(client, records).then(function (result) {
      //                   if (!result.status) {
      //                     finish.call(e, result);
      //                     modal.close(e);
      //                   } else {
      //                     finish.call(e, result);
      //                     modal.close(e);
      //                   }
      //                 });
      //               }
      //             })();
      //           };

      //           $scope.onClose = function (e) {
      //             finish.call(e, {
      //               status: false
      //             });
      //             modal.close(e);
      //           }
      //         }],
      //         modal: {
      //           dismissable: false,
      //           title: 'Extend Interpreter',
      //           template: 'components/modal/tpl/extend-interpreter.html'
      //         }
      //       }, 'modal-success', null, true, true);
      //     };
      //   },
      //   releaseInterpreter: function (records, finish) {
      //     finish = finish || angular.noop;
      //     return function () {
      //       var scratchPad = {
      //         error: null,
      //         success: null,
      //         totalLunch: 'N/A',
      //         extendIC: [],
      //         isCheckBoxRequire: true
      //       };
      //       var modal = openModal({
      //         controller: ['$scope', '$clientSwitcher', '$clientTask', '$convertSecondToTime', 'Modal', '$q', 'CalculateWorkOrderTime', function ($scope, $clientSwitcher, $clientTask, $convertSecondToTime, Modal, $q, CalculateWorkOrderTime) {
      //           var client = $clientSwitcher.getCurrentClient();
      //           $scope.scratchPad = scratchPad;
      //           $scope.model = {
      //             type: '',
      //             comment: '',
      //             reason: 'No Additional Requirements',
      //             disabledReleaseNow: true
      //           };
      //           $scope.releaseReasons = [{
      //             label: 'No Additional Requirements',
      //             value: 'No Additional Requirements'
      //           },
      //           {
      //             label: 'Respondent Did Not Appear',
      //             value: 'Respondent Did Not Appear'
      //           },
      //           {
      //             label: 'Judge Did Not Appear',
      //             value: 'Judge Did Not Appear'
      //           },
      //           {
      //             label: 'Interpreter End Time Request',
      //             value: 'Interpreter End Time Request'
      //           },
      //           {
      //             label: 'Interpreter Sick',
      //             value: 'Interpreter Sick'
      //           },
      //           {
      //             label: 'Other Reason',
      //             value: 'Other Reason'
      //           }];
      //           $scope.releaseEvent = null;
      //           $scope.release = null;
      //           $scope.user = $auth.getCurrentUser();
      //           $scope.date = new Date();
      //           $scope.records = records;
      //           $scope.timeframes = timeSch;
      //           $scope.time = {
      //             minTime: Date.now(),
      //             maxTime: Date.now() + (60*60*1000*3)
      //           }
      //           $scope.setJudgeNameValue = function (record) {
      //             if (record && record.ijname) {
      //               if (record.taskDetails && record.taskDetails.caseData && record.taskDetails.caseData.judgedInOut.length > 0) {
      //                 var judgeData = record.taskDetails.caseData.judgedInOut;
      //                 var lastRecordFromJudgeInOut = judgeData[judgeData.length - 1];
      //                 if (lastRecordFromJudgeInOut.event == 'Judge IN') {
      //                   if (lastRecordFromJudgeInOut.judge && lastRecordFromJudgeInOut.judge.ijname) {
      //                     return lastRecordFromJudgeInOut.judge.ijname;
      //                   }
      //                   return lastRecordFromJudgeInOut.user.name;
      //                 }
      //                 return null;
      //               }
      //               return null;
      //             } else {
      //               return null;
      //             }
      //           }

      //           function init() {
      //             var defer = $q.defer();
      //             records.map((record) => {
      //               var taskDetails = record.taskDetails;
      //               CalculateWorkOrderTime(taskDetails.caseData)
      //               .then(woCalculations => {
      //                 record.reason = 'No Additional Requirements';
      //                 record.type = '';
      //                 record.comment = '';
      //                 record.disabledReleaseNow = true;
      //                 record.isCheckBoxRequire = true;
      //                 let timezoneOffset = getECOITimezone(taskDetails.caseData.timezone);

      //                 if (taskDetails && taskDetails.caseData && taskDetails.caseData.checkedIn && taskDetails.caseData.checkedIn.date) {
      //                   record.facilityIn = moment(taskDetails.caseData.checkedIn.date).utcOffset(timezoneOffset).format('hh:mm A');
      //                 }
      //                 if (taskDetails && taskDetails.caseData && taskDetails.caseData.judgedInOut && taskDetails.caseData.judgedInOut.length > 0) {
      //                   var firstJudgeIn = taskDetails.caseData.judgedInOut[0];
      //                   if (firstJudgeIn) {
      //                     record.firstJudgeIn = moment(firstJudgeIn.date).utcOffset(timezoneOffset).format('hh:mm A');
      //                     record.lateJudgeIn = woCalculations.lateTimeInNumber;
      //                   }
      //                   var lastRecordFromJudgeInOut = taskDetails.caseData.judgedInOut[taskDetails.caseData.judgedInOut.length - 1];
      //                   if (lastRecordFromJudgeInOut.event == 'Judge OUT') {
      //                     record.lastJudgeOut = moment(lastRecordFromJudgeInOut.date).utcOffset(timezoneOffset).format('hh:mm A');
      //                   }
      //                   if (lastRecordFromJudgeInOut.event == 'Judge IN') {
      //                     record.disabledReleaseNow = false;
      //                   }
      //                 }

      //                 if (taskDetails && taskDetails.caseData && taskDetails.caseData.lunchOut && taskDetails.caseData.lunchOut.date) {
      //                   if (taskDetails && taskDetails.caseData && taskDetails.caseData.lunchIn && taskDetails.caseData.lunchIn.date) {
      //                     var startTime = moment(taskDetails.caseData.lunchOut.date).format();
      //                     var endTime = moment(taskDetails.caseData.lunchIn.date).format();
      //                     var duration = moment(endTime).diff(moment(startTime)) / 1000;
      //                     if (duration > 0) {
      //                       var totalLunch = $convertSecondToTime(duration);
      //                       record.totalLunch = totalLunch.hours + ':' + totalLunch.minutes
      //                     }
      //                   }
      //                 }

      //                 if (taskDetails && taskDetails.caseData && taskDetails.caseData.lunchOut && taskDetails.caseData.lunchOut.date) {
      //                   if (taskDetails && taskDetails.caseData && taskDetails.caseData.lunchIn && taskDetails.caseData.lunchIn.date) {
      //                   } else {
      //                     record.disabledReleaseNow = false;
      //                   }
      //                 }

      //                 if (taskDetails && taskDetails.caseData && taskDetails.caseData.extendIC && taskDetails.caseData.extendIC.length > 0) {
      //                   record.extendIC = {};
      //                   record.extendIC.data = taskDetails.caseData.extendIC;
      //                   record.extendIC.timezoneOffset = timezoneOffset;
      //                 }

      //                 var startTime = woCalculations.startTime;
      //                 var minWorkOrder = woCalculations.minWorkOrder;
      //                 const totalWOTime = !startTime ? minWorkOrder : moment.duration(moment().diff(startTime));
      //                 const totalJobTime = totalWOTime - woCalculations.totalLunchTimeInDuration;
      //                 const hours = Math.floor((totalJobTime / (1000 * 60 * 60)) % 24);
      //                 const minutes = Math.floor((totalJobTime / (1000 * 60)) % 60);
      //                 const woDisplay = hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0');

      //                 record.totalWorkTime = woDisplay;
      //                 defer.resolve();
      //               })
      //               return defer.promise;
      //             })
      //           }
      //           init();

      //           $scope.convertDateToTime = function(dateTime, timezoneOffset){
      //             return moment(dateTime).utcOffset(timezoneOffset).format('hh:mm A');
      //           }

      //           // $scope.onChange = function(e){
      //           //   $scope.model.comment = '';
      //           // }

      //           $scope.onNowCBoxChanges = function(record){
      //             record.isCheckBoxRequire = true;
      //             if(record.now){
      //               record.judge_out = false;
      //               record.time = false;
      //               record.isCheckBoxRequire = false;
      //               record.isAutoFOUT = false;
      //               record.type = 'now';
      //             }
      //           }

      //           $scope.onJudgeOutCBoxChanges = function(record){
      //             record.isCheckBoxRequire = true;
      //             if(record.judge_out){
      //               record.now = false;
      //               record.time = false;
      //               record.isCheckBoxRequire = false;
      //               record.isAutoFOUT = false;
      //               record.type = 'judge_out';
      //             }
      //           }

      //           $scope.onTimeCBoxChanges = function(record){
      //             record.isCheckBoxRequire = true;
      //             if(record.time){
      //               record.now = false;
      //               record.judge_out = false;
      //               record.isCheckBoxRequire = false;
      //               record.isAutoFOUT = false
      //               record.type = 'time';
      //             }
      //           }

      //           $scope.onAutoReleaseFoutChanges = function(record){
      //             record.isCheckBoxRequire = true;
      //             if(record.isAutoFOUT){
      //               record.time = false;
      //               record.now = false;
      //               record.judge_out = false;
      //               record.isCheckBoxRequire = false;
      //               record.type = 'releaseFout';
      //             }
      //           }

      //           $scope.onRelease = function (e) {
      //             $scope.scratchPad.error = null;
      //             // let checkSerType = $scope.records.find(function(record){
      //             //   return record.sertype === OPI
      //             // })
      //             // if(checkSerType){
      //             //   $scope.scratchPad.error = 'Release of a OPI Work Order is Not Permitted. Please Judge Out the Interpreter to Complete';
      //             //   return;
      //             // }

      //             let relaseArr = [];
      //             $scope.records.map((record) => {
      //               const type = record.type;
      //               let releaseEvent = null, release = null;
      //               const icNotUsed = (!record.taskDetails.caseData.judgedInOut) || (record.taskDetails.caseData.judgedInOut && !record.taskDetails.caseData.judgedInOut.length);
      //               if (type === 'judge_out' || type === 'time') {
      //                 releaseEvent = {
      //                   releaseType: type,
      //                   value: type === 'time' ? record.value : null,
      //                   comments: `${record.reason}${record.comment ? ' | ' + record.comment : ''}`,
      //                   reason: record.reason
      //                 };
      //                 release = null;
      //               } else {
      //                 // When WO is in LOUT then it should not allow to do release
      //                 var taskDetails = record.taskDetails;
      //                 if (taskDetails && taskDetails.caseData && taskDetails.caseData.lunchOut && taskDetails.caseData.lunchOut.date) {
      //                   if (taskDetails && taskDetails.caseData && taskDetails.caseData.lunchIn && taskDetails.caseData.lunchIn.date) {
      //                   } else {
      //                     $scope.scratchPad.error = 'Please Lunch IN before attempting to Release OUT';
      //                     return;
      //                   }
      //                 }

      //                 release = {
      //                   releaseType: record.type == 'releaseFout' ? 'now' : type,
      //                   comments: `${record.reason}${record.comment ? ' | ' + record.comment : ''}`,
      //                   reason: record.reason,
      //                   icAppearedButNotUsedOnRout: icNotUsed,
      //                   isAutoFOUT: record.type == 'releaseFout' ? true : false
      //                 };
      //                 releaseEvent = null;
      //               }
      //               relaseArr.push({
      //                 coi: record.coi,
      //                 task: record.taskDetails._id,
      //                 caseDataId: record.taskDetails.caseData._id,
      //                 release: release,
      //                 releaseEvent: releaseEvent,
      //                 isSystemEvent: icNotUsed
      //               });
      //             })
      //             $scope.validatePassword(relaseArr, e)
      //           };

      //           $scope.validatePassword = function(data, e) {

      //             const userEmail = $scope.user.email;

      //             Modal.officeOperationActions.validatePassword(userEmail, function(res) {
      //               //Returns true if password is correct
      //               if (res) {
      //                 $clientTask.releaseInterpreter(client, data).then(function (result) {
      //                     finish.call(e, result);
      //                     modal.close(e);

      //                 });
      //               }
      //             })();
      //           }

      //           $scope.printEcoiIndividual = function (record) {
      //             sessionStorage.coiRecord = JSON.stringify({
      //               record: record,
      //               individual: true
      //             });
      //             window.w = window.open('/print/office-operations/ecoi', '_blank');
      //             window.w.coiRecord = {
      //               record: record,
      //               individual: true
      //             };
      //           }

      //           $scope.onReleaseNow = function (e) {
      //             finish.call(e, {
      //               status: true
      //             });
      //             modal.close(e);
      //           };

      //           $scope.onClose = function (e) {
      //             finish.call(e, {
      //               status: false
      //             });
      //             modal.close(e);
      //           }
      //         }],
      //         modal: {
      //           dismissable: false,
      //           title: 'Release',
      //           template: 'components/modal/tpl/release-interpreter.html'
      //         }
      //       }, 'modal-success', null, true, true);
      //     };
      //   },
      //   extensionOverride: function (record, finish) {
      //     finish = finish || angular.noop;
      //     return function () {
      //       var scratchPad = {
      //         error: null,
      //         success: null
      //       };
      //       var modal = openModal({
      //         controller: ['$scope', '$clientSwitcher', '$clientTask', function ($scope, $clientSwitcher, $clientTask) {
      //           var client = $clientSwitcher.getCurrentClient();
      //           $scope.scratchPad = scratchPad;
      //           $scope.user = $auth.getCurrentUser();
      //           $scope.date = new Date();

      //           $scope.release = function (e) {
      //             finish.call(e, {
      //               denyRelease: false
      //             });
      //             modal.close(e);
      //           };

      //           $scope.denyRelease = function (e) {
      //             finish.call(e, {
      //               denyRelease: true
      //             });
      //             modal.close(e);
      //           }
      //         }],
      //         modal: {
      //           dismissable: false,
      //           title: 'Extension Override Needed',
      //           template: 'components/modal/tpl/extension-override.html'
      //         }
      //       }, 'modal-success', null, true, true);
      //     };
      //   },
      //   prepareRelease: function (dataSets, finish) {
      //     finish = finish || angular.noop;
      //     return function () {
      //       var scratchPad = {
      //         error: null,
      //         success: null,
      //         reportingCourt: dataSets.reportingCourt || null,
      //         date: dataSets.date || null,
      //         isAllowJudge: false,
      //         isCheckJudgeOrNot: false,
      //         isAllowRelease: true,
      //         interPreter: [],
      //         isLoading: false
      //       };
      //       var modal = openModal({
      //         controller: ['$scope', '$clientQueryBuilder', '$clientSwitcher', 'ngToast', '$clientTask', 'TASK_STATE_IC_RELEASE_REQUEST', '$courtOperations', function ($scope, $clientQueryBuilder, $clientSwitcher, ngToast, $clientTask, TASK_STATE_IC_RELEASE_REQUEST, $courtOperations) {
      //           $scope.scratchPad = scratchPad;
      //           $scope.user = $auth.getCurrentUser();
      //           $scope.date = new Date();
      //           var client = $clientSwitcher.getCurrentClient();
      //           var pageData = {
      //             page: 1,
      //             pageSize: 100
      //           };

      //           var sortedJudgeDataSets;
      //           function init() {
      //             if($scope.scratchPad.reportingCourt.length > 0 && $scope.scratchPad.reportingCourt != 'all'){
      //               getECOIForRCourt(client, $scope.scratchPad, pageData)
      //               .then(function (res) {
      //                 //Interpreter
      //                 var interpreterDataSets = [];
      //                 const sortedInterpreterDataSets = _.sortBy(res.checkedIn, ['interprername', 'sched']);
      //                 sortedInterpreterDataSets.forEach(function (record) {
      //                   interpreterDataSets.push({
      //                     label: record.interprername + ' | ' + record.coi + ' | ' + moment(record.sched, ["h:mm A"]).format("hh:mm A"),
      //                     value: record.coi,
      //                     vendorId: record.vendorid,
      //                     ijcode: record.ijcode
      //                   })
      //                 });
      //                 $scope.interpreterDataSets = [];
      //                 $scope.interpreterDataSets = _.uniq(interpreterDataSets, 'label');

      //                 sortedJudgeDataSets = _.sortBy(res.interPreterWO, ['ijname', 'sched']);


      //               })
      //               .catch(function (err) {
      //                 $scope.scratchPad.error = err;
      //               })
      //             }
      //           }

      //           init();


      //           function setJudgeDataset(ecoi) {
      //             var judgeDataSets = [];
      //             sortedJudgeDataSets.forEach((judge) => {
      //               if (judge.coi == ecoi) {
      //                 if (judge.taskDetails && judge.taskDetails.caseData && judge.taskDetails.caseData.judgedInOut && judge.taskDetails.caseData.judgedInOut.length > 0) {
      //                   var lastRecordJIN = judge.taskDetails.caseData.judgedInOut[judge.taskDetails.caseData.judgedInOut.length - 1];
      //                   if (lastRecordJIN.event == 'Judge IN') {
      //                     if(lastRecordJIN.judge && lastRecordJIN.judge.ijcode){
      //                       $scope.scratchPad.judge = lastRecordJIN.judge.ijcode;
      //                       judgeDataSets.push({
      //                         label: lastRecordJIN.judge.ijcode + ' | ' + lastRecordJIN.judge.ijname,
      //                         value: lastRecordJIN.judge.ijcode,
      //                         ijcode: lastRecordJIN.judge.ijcode,
      //                         ijname: lastRecordJIN.judge.ijname
      //                       })
      //                     }
      //                   }
      //                 }
      //               }
      //             })
      //             $scope.judgeDataSets = [];
      //             $scope.judgeDataSets = _.uniq(judgeDataSets, 'label');
      //           }

      //           $scope.onInterpreterChange = function () {
      //             $scope.scratchPad.ecoi = $scope.scratchPad.interPreter;
      //           }

      //           $scope.buttonClick = function (e) {
      //             $scope.scratchPad.isLoading = true;
      //             $scope.scratchPad.success = null;
      //             $scope.scratchPad.error = null;
      //             var ecoi = `${$scope.scratchPad.ecoi}`;
      //             if ($scope.scratchPad.interPreter && $scope.scratchPad.interPreter.length > 0) {
      //               ecoi = $scope.scratchPad.interPreter;
      //             }
      //             // $officeOperations.getEcoiById(client, ecoi)
      //             $courtOperations.getCasesDetails(client, ecoi, {})
      //             .then(function (res) {
      //               if (res.status) {
      //                 if (res.data.records.length) {
      //                   $scope.scratchPad.record = res.data.records;
      //                   let promise = new Promise(function (resolve, reject) {
      //                     res.data.records.forEach(function (record) {
      //                       var taskDetails = record.taskDetails;
      //                       // let icRequestedReleasedEvent = taskDetails.stateActivity.filter((obj) => (obj.state == TASK_STATE_IC_RELEASE_REQUEST && obj.isICEndorse))
      //                       // let icLastEvent = icRequestedReleasedEvent[icRequestedReleasedEvent.length - 1];
      //                       // if (record.sertype == OPI || record.sertype == VRI) {
      //                       //   reject(`Release of a ${record.sertype} Work Order is Not Permitted. Please Judge Out the Interpreter to Complete ${record.coi}`);
      //                       // }
      //                       // if (taskDetails && taskDetails.caseData && taskDetails.caseData.judgedInOut && taskDetails.caseData.judgedInOut.length > 0 && taskDetails.caseData.judgedInOut[taskDetails.caseData.judgedInOut.length - 1].event === 'Judge IN') {
      //                       //   $scope.scratchPad.error = 'Please Judge OUT before attempting to Release';
      //                       //   reset();
      //                       // }
      //                       // if (taskDetails && taskDetails.caseData && taskDetails.caseData.judgedInOut && !taskDetails.caseData.judgedInOut.length) {
      //                       //   reject(`Please Judge OUT before attempting to Release ${record.coi}`);
      //                       // }
      //                       if (taskDetails && taskDetails.caseData && taskDetails.caseData.checkedOut && taskDetails.caseData.checkedOut.date) {
      //                         reject(`Interpreter already completed Facility OUT ${record.coi}`);
      //                         // reset();
      //                       }
      //                       // else if (icLastEvent && !icLastEvent.approved && !icLastEvent.isDispute) {
      //                       //   reject(`Interpreter already requests for release ${record.coi}`);
      //                       // }
      //                       // else if (taskDetails && taskDetails.caseData && ((taskDetails.caseData.release && taskDetails.caseData.release.date) || (taskDetails.caseData.releaseEvent && taskDetails.caseData.releaseEvent.date))) {
      //                       //   $scope.scratchPad.error = 'Interpreter already released';
      //                       //   reset();
      //                       // }
      //                       else if (taskDetails && taskDetails.caseData && taskDetails.caseData.checkedIn && taskDetails.caseData.checkedIn.date) {
      //                         // $scope.onRelease(e);
      //                         resolve()
      //                       } else {
      //                         if (['OSI', 'OPI'].indexOf(taskDetails.sertype)>=0) {
      //                           reject(`Please Facility IN before Release ${record.coi}`);
      //                         } else {
      //                           // allow VRI
      //                           resolve()
      //                         }
      //                         // reset();
      //                       }
      //                     });
      //                   });

      //                   promise.then(function (data) {
      //                     $scope.onRelease(e)
      //                   })
      //                     .catch(function (err) {
      //                       $scope.scratchPad.isLoading = false;
      //                       $scope.scratchPad.error = err || err.message;
      //                       $scope.$apply();
      //                       reset();
      //                     })
      //                 }
      //                 else {
      //                   $scope.scratchPad.error = 'No Record Found';
      //                   reset();
      //                 }
      //               }
      //               else {
      //                 $scope.scratchPad.error = res.reason;
      //                 reset();
      //               }
      //             });
      //           };

      //           function resetError(){
      //             $scope.scratchPad.ecoi = null;
      //             $scope.scratchPad.error = null;
      //             $scope.scratchPad.interPreter = null;
      //             $scope.scratchPad.autoFacilityOut = null;
      //             $scope.scratchPad.judge = null;
      //             $scope.scratchPad.isAllowJudge = false;
      //             $scope.scratchPad.comment = null;
      //           }

      //           function reset() {
      //             $scope.scratchPad.ecoi = null;
      //             $scope.scratchPad.success = null;
      //             $scope.scratchPad.interPreter = null;
      //             $scope.scratchPad.autoFacilityOut = null;
      //             $scope.scratchPad.judge = null;
      //             $scope.scratchPad.isAllowJudge = false;
      //             $scope.scratchPad.comment = null;
      //             $scope.scratchPad.isLoading = false;
      //           }

      //           $scope.onRelease = (e) => {
      //             modals.officeOperationActions.releaseInterpreter($scope.scratchPad.record, function (result) {
      //               if (!result.status) {
      //                 $scope.scratchPad.error = result.reason;
      //                 reset();
      //               } else {
      //                 finish.call(e, true);
      //                 modal.close(e);
      //                 $scope.scratchPad.success = 'ECOI Release Interpreter successful';
      //                 $scope.scratchPad.ecoi = null;
      //                 $scope.scratchPad.error = null;
      //                 $scope.scratchPad.interPreter = null;
      //                 $scope.scratchPad.isAllowRelease = false;
      //                 $scope.scratchPad.isLoading = false;
      //               }
      //             })();
      //           }

      //           $scope.cancel = function(e) {
      //             finish.call(e, false);
      //             modal.dismiss();
      //           }
      //         }],
      //         modal: {
      //           dismissable: false,
      //           title: 'Release',
      //           template: 'components/modal/tpl/release.html',
      //           // buttons: [{
      //           //   classes: 'btn-primary',
      //           //   text: 'Submit',
      //           // }, {
      //           //   classes: 'btn-default',
      //           //   text: 'Close',
      //           //   click: function (e) {
      //           //     finish.call(e, false);
      //           //     modal.close(e);
      //           //   }
      //           // }]
      //         }
      //       }, 'modal-success', null, true, true);

      //       modal
      //         .rendered
      //         .then(() => $timeout(() => $window.document.getElementById('releaseEcoiId').focus(), focusDelay));
      //     };
      //   },
      //   requestInterpreter: function (dataSets, finish) {
      //     finish = finish || angular.noop;
      //     return function () {
      //       var scratchPad = {
      //         error: null,
      //         success: null,
      //         reportingCourt: dataSets.reportingCourt,
      //         workOrder: dataSets.workOrder,
      //         isLoading: true
      //       };
      //       var modal = openModal({
      //         controller: ['$scope', '$clientSwitcher', '$officeOperations', '$clientRequestInterpreter', '$clientUser', function ($scope, $clientSwitcher, $officeOperations, $clientRequestInterpreter, $clientUser) {
      //           var client = $clientSwitcher.getCurrentClient();
      //           $scope.scratchPad = scratchPad;
      //           $scope.user = $auth.getCurrentUser();
      //           $scope.date = new Date();
      //           $scope.allValue = 'all';
      //           $scope.judgeDataSets = [];
      //           $scope.additionalLanguagesItems = [];
      //           $scope.model = {
      //             osiAvailable: 0,
      //             osiPending: 0,
      //             opiAvailable: 0,
      //             opiPending: 0,
      //             osiAvailableData: [],
      //             opiAvailableData: [],
      //             vriAvailableData: [],
      //             vriPending:0,
      //             vriAvailable:0
      //           }
      //           const isCourtJudge = ($scope.user.settings.roles.findIndex(x => x.code === 'COURT_JUDGE') >= 0);
      //           function setFilterDatas() {
      //             if (isCourtJudge && $scope.user.settings.internalId) {
      //               $scope.model.judge = $scope.user.settings.internalId;
      //             }
      //             $scope.judgeDataSets = [];
      //             $clientUser.list(client)
      //               .then(function (users) {
      //                 users.map((user) => {
      //                   let matchCourtRole = _.find(user.roles, function (role) {
      //                     return role.code === 'COURT_JUDGE'
      //                   })
      //                   if (matchCourtRole && user.internalId && user.isEnabled) {
      //                     $scope.judgeDataSets.push({
      //                       label: user.name,
      //                       value: user.internalId
      //                     });
      //                   }
      //                 })
      //                 $scope.judgeDataSets = _.sortBy(_.uniq($scope.judgeDataSets, 'label'), ['label']);
      //               });
      //             // $scope.langNameDataSets = [];
      //             // var langNameDataSets = [],
      //             //   judgeDataSets = [];
      //             // if ($scope.scratchPad.workOrder && $scope.scratchPad.workOrder.interPreterWO) {
      //             //   $scope.scratchPad.workOrder.interPreterWO.forEach(function (record) {
      //             //     // langNameDataSets.push({
      //             //     //   label: record.langname + ' (' + record.langcode + ')',
      //             //     //   value: record.langname
      //             //     // });
      //             //     judgeDataSets.push({
      //             //       label: record.ijname,
      //             //       value: record.ijcode
      //             //     });
      //             //   });
      //             // }

      //             // // $scope.langNameDataSets = _.sortBy(_.uniq(langNameDataSets, 'label'), ['label']);
      //             // $scope.judgeDataSets = _.sortBy(_.uniq(judgeDataSets, 'label'), ['label']);
      //             // if (isCourtJudge && $scope.user.settings.internalId) {
      //             //   $scope.model.judge = $scope.user.settings.internalId
      //             // }
      //             $scope.additionalLanguagesItems = [];
      //             $officeOperations.getInterpreterLangs(client, {})
      //               .then((res) => {
      //                 if (res.status && res.data.length > 0) {
      //                   let additionalLanguagesItems = [];
      //                   additionalLanguagesItems = res.data.map((lang) => {
      //                     return {
      //                       label: `${lang.code} | ${lang.name}`,
      //                       value: {
      //                         langname: lang.name,
      //                         langcode: lang.code
      //                       }
      //                     }
      //                   }).filter(Boolean);

      //                   $scope.additionalLanguagesItems = _.sortBy(_.uniq(additionalLanguagesItems, 'label'), ['label']);
      //                 }
      //               })
      //           }

      //           function duration() {
      //             $scope.timeframes = [];
      //             // 5 min
      //             Array.from(Array(12).keys()).map(x => {
      //               if ((x + 1) * 5 == 60) {
      //                 $scope.timeframes.push({
      //                   label: `1 hour`,
      //                   value: 60
      //                 });
      //               } else {
      //                 $scope.timeframes.push({
      //                   label: `${(x + 1) * 5} min`,
      //                   value: (x + 1) * 5
      //                 });
      //               }
      //             });
      //             // 15 min
      //             Array.from(Array(4).keys()).map(x => {
      //               if ((x + 1) * 15 == 60) {
      //                 $scope.timeframes.push({
      //                   label: `2 hour`,
      //                   value: 120
      //                 });
      //               } else {
      //                 $scope.timeframes.push({
      //                   label: `1 hour ${(x + 1) * 15} min`,
      //                   value: 60 + ((x + 1) * 15)
      //                 });
      //               }
      //             });
      //             // 30 min
      //             Array.from(Array(2).keys()).map(x => {
      //               if ((x + 1) * 30 == 60) {
      //                 $scope.timeframes.push({
      //                   label: `3 hours`,
      //                   value: 180
      //                 });
      //               } else {
      //                 $scope.timeframes.push({
      //                   label: `2 hours ${(x + 1) * 30} min`,
      //                   value: 120 + ((x + 1) * 30)
      //                 });
      //               }
      //             });
      //             // 1 hour
      //             Array.from(Array(5).keys()).map(x => {
      //               $scope.timeframes.push({
      //                 label: `${x + 4} hours`,
      //                 value: 60 * (x + 4)
      //               });
      //             });
      //           }

      //           function init() {
      //             setFilterDatas();
      //             duration();
      //             getMatchCount();
      //           }

      //           function getMatchCount() {
      //             var filter = {
      //               "language": $scope.model.langName ? $scope.model.langName.langcode : null,
      //               "reportingCourt": [$scope.scratchPad.reportingCourt.value],
      //               "startDate": dataSets.date,
      //               "endDate": dataSets.date
      //             }
      //             $officeOperations.getRequestInterpreterMatch(client, filter)
      //               .then((res) => {
      //                 if (res.status) {
      //                   $scope.model.osiAvailable = res.data.osiAvailable.length;
      //                   $scope.model.osiPending = res.data.osiPending.length;
      //                   $scope.model.osiAvailableData = res.data.osiAvailable;
      //                   $scope.model.opiAvailable = res.data.opiAvailable.length;
      //                   $scope.model.opiPending = res.data.opiPending.length;
      //                   $scope.model.opiAvailableData = res.data.opiAvailable;
      //                   $scope.model.vriAvailable = res.data.vriAvailable.length;
      //                   $scope.model.vriPending = res.data.vriPending.length;
      //                   $scope.model.vriAvailableData = res.data.vriAvailable;
      //                 }
      //                 $scope.scratchPad.isLoading = false;
      //               })
      //           }

      //           $scope.langChange = function () {
      //             getMatchCount();
      //           }

      //           $scope.requestInterpreter = function (e, serviceType) {
      //             $scope.scratchPad.isLoading = true;
      //             let data = {
      //               user: $scope.user,
      //               serviceType: serviceType,
      //               location: $scope.scratchPad.reportingCourt,
      //               duration: $scope.model.duration || null,
      //               // available: Array,
      //               // pending: Array,
      //               comment: $scope.model.comment || null
      //             }
      //             if ($scope.model.judge) {
      //               let selectedJudge = _.find($scope.judgeDataSets, (j) => j.value == $scope.model.judge);
      //               data.judge = {
      //                 ijname: selectedJudge.label,
      //                 ijcode: selectedJudge.value
      //               }
      //             }
      //             if ($scope.model.langName) {
      //               data.language = $scope.model.langName;
      //             }
      //             $clientRequestInterpreter.create(client, data)
      //               .then((res) => {
      //                 $scope.scratchPad.isLoading = false;
      //                 finish.call(e, { status: true });
      //                 modal.close(e);
      //               })
      //           }

      //           init();
      //           $scope.onClose = function (e) {
      //             finish.call(e, { status: false });
      //             modal.close(e);
      //           }
      //           $scope.showAvailableOSI = function(model) {
      //             modals.officeOperationActions.availableRequestInterpreterModel($scope.model.osiAvailableData, 'OSI',function (result) {})();
      //           }
      //           $scope.showAvailableOPI = function(model) {
      //             modals.officeOperationActions.availableRequestInterpreterModel($scope.model.opiAvailableData, 'OPI', function (result) {})();
      //           }

      //           $scope.showAvailableVRI = function(model) {
      //             modals.officeOperationActions.availableRequestInterpreterModel($scope.model.vriAvailableData, 'VRI', function (result) {})();
      //           }
      //         }],
      //         modal: {
      //           dismissable: true,
      //           title: 'Request Interpreter',
      //           template: 'components/modal/tpl/request-interpreter.html'
      //         }
      //       }, 'modal-success');
      //     };
      //   },
      //   pendingRequestIC: function (workOrder, finish) {
      //     finish = finish || angular.noop;
      //     return function () {
      //       var scratchPad = {
      //         isLoading: false,
      //         error: null,
      //         success: null,
      //         pendingRequestIC: workOrder.pendingRequestIC,

      //       };
      //       var modal = openModal({
      //         controller: ['$scope', '$clientSwitcher', '$clientRequestInterpreter', function ($scope, $clientSwitcher, $clientRequestInterpreter) {
      //           var client = $clientSwitcher.getCurrentClient();
      //           $scope.scratchPad = scratchPad;
      //           $scope.user = $auth.getCurrentUser();
      //           $scope.date = new Date();
      //           $scope.model = {
      //             interPreter: null
      //           }
      //           function timeConvert(n) {
      //             var num = n;
      //             var hours = (num / 60);
      //             var rhours = Math.floor(hours);
      //             var minutes = (hours - rhours) * 60;
      //             var rminutes = Math.round(minutes);

      //             if (num < 60) {
      //               return `${num} min`;
      //             } else if (rhours == 1 && rminutes == 0) {
      //               return `${rhours} hour`;
      //             } else if (rhours > 1 && rminutes == 0) {
      //               return `${rhours} hours`;
      //             } else if (rhours == 1 && rminutes < 60) {
      //               return `${rhours} hour ${rminutes} min`;
      //             } else {
      //               return `${rhours} hours ${rminutes} min`;
      //             }
      //           }

      //           //Interpreter
      //           var interpreterDataSets = [];
      //           const sortedInterpreterDataSets = _.sortBy(workOrder.checkedIn, ['interprername', 'sched']);
      //           sortedInterpreterDataSets.forEach(function (record) {
      //             interpreterDataSets.push({
      //               label: record.interprername,
      //               value: record.vendorid,
      //               vendorId: record.vendorid,
      //               langname: record.langname
      //             })
      //           });
      //           $scope.interpreterDataSets = [];
      //           $scope.interpreterDataSets = _.uniq(interpreterDataSets, 'label');

      //           $scope.duration = function(duration){
      //             if(!duration) return 'N/A';
      //             return timeConvert(duration);
      //           }

      //           $scope.requestInterpreter = function(record, e){
      //             $scope.scratchPad.error = null;
      //             if(!record.interPreter){
      //               $scope.scratchPad.error = 'Select interpreter';
      //               return;
      //             }

      //             modals.officeOperationActions.pendingICCommentModel(record,function (result) {
      //               finish.call(e, { status: true });
      //               modal.close(e);
      //             })();
      //           }

      //           $scope.setInterpreterDataset = function(record, index){
      //             var icDataset = [];
      //             $scope.interpreterDataSets.forEach(function (icRecord) {
      //               if(icRecord.langname == record.language.langname){
      //                 icDataset.push({
      //                   label: icRecord.label,
      //                   value: icRecord.value,
      //                   vendorId: icRecord.vendorid
      //                 })
      //               }
      //             });
      //             $scope.scratchPad.pendingRequestIC[index].interpreterDataSets = icDataset;
      //           }

      //           $scope.removeInterpreter = function (record, e, index) {
      //             modals.confirm.show('Confirm', 'Are you sure to cancel request?', 'Confirm', 'Cancel', function () {
      //               $scope.scratchPad.isLoading = true;
      //               $clientRequestInterpreter.cancel(client, record)
      //                 .then((res) => {
      //                   if (res.status) {
      //                     if ($scope.scratchPad.pendingRequestIC && $scope.scratchPad.pendingRequestIC.length === 1) {
      //                       finish.call(e, { status: true });
      //                       modal.close(e);
      //                     } else {
      //                       $scope.scratchPad.pendingRequestIC.splice(index, 1);
      //                     }
      //                   } else {
      //                     $scope.scratchPad.error = err.message || err;
      //                   }
      //                   $scope.scratchPad.isLoading = false;
      //                 })
      //                 .catch((err) => {
      //                   $scope.scratchPad.error = err.message || err;
      //                   $scope.scratchPad.isLoading = false;
      //                 })
      //             }, 'btn-warning', null, 'modal-warning');
      //           }

      //           $scope.onClose = function (e) {
      //             finish.call(e, {
      //               status: false
      //             });
      //             modal.close(e);
      //           }
      //         }],
      //         modal: {
      //           dismissable: true,
      //           title: 'Pending Request Interpreter',
      //           template: 'components/modal/tpl/pending-request-interpreter.html'
      //         }
      //       }, 'modal-success', 'lg');
      //     };
      //   },
      //   availableRequestInterpreterModel: function (data, type, finish) {
      //     finish = finish || angular.noop;
      //     return function () {
      //       var modal = openModal({
      //         controller: ['$scope', '$clientSwitcher', function ($scope, $clientSwitcher) {
      //           $scope.model = {
      //             osiAvailable: data
      //           }
      //           $scope.schedValue = function (record) {
      //             if (record && record.sched) {
      //               var momentObj = moment(record.sched, ["h:mm A"])
      //               return momentObj.format("hh:mm A");
      //             } else {
      //               return 'N/A';
      //             }
      //           }

      //           $scope.setJudgeNameValue = function (record) {
      //             if (record && record.ijname) {
      //               if (record.taskDetails && record.taskDetails.caseData && record.taskDetails.caseData.judgedInOut.length > 0) {
      //                 var judgeData = record.taskDetails.caseData.judgedInOut;
      //                 var lastRecordFromJudgeInOut = judgeData[judgeData.length - 1];
      //                 if (lastRecordFromJudgeInOut.event == 'Judge IN') {
      //                   if (lastRecordFromJudgeInOut.judge && lastRecordFromJudgeInOut.judge.ijname) {
      //                     return lastRecordFromJudgeInOut.judge.ijname;
      //                   }
      //                   return lastRecordFromJudgeInOut.user.name;
      //                 }
      //                 return record.ijname;
      //               }
      //               return record.ijname;
      //             } else {
      //               return 'N/A';
      //             }
      //           }
      //           $scope.interPreterValue = function (record) {
      //             if (record.status == 'No IA' || record.status == 'No Show') {
      //               return 'N/A';
      //             } else {
      //               return record.interprername;
      //             }
      //           }

      //           $scope.onClose = function (e) {
      //             finish.call(e, {
      //               status: false
      //             });
      //             modal.close(e);
      //           }
      //         }],
      //         modal: {
      //           dismissable: true,
      //           title: `Available ${type}`,
      //           template: 'components/modal/tpl/available-request-interpreter.html'
      //         }
      //       }, 'modal-success', 'lg');
      //     };
      //   },

      //   pendingICCommentModel: function(record, finish) {
      //     finish = finish || angular.noop;
      //     return function () {
      //       var scratchPad = {
      //         isLoading: false,
      //         error: null,
      //         success: null
      //       };
      //       var modal = openModal({
      //         controller: ['$scope', '$clientSwitcher', '$clientRequestInterpreter', function ($scope, $clientSwitcher, $clientRequestInterpreter) {
      //           var client = $clientSwitcher.getCurrentClient();
      //           $scope.scratchPad = scratchPad;
      //           $scope.model = {
      //             comment: null,
      //           }

      //           $scope.submit = function (e) {
      //             $scope.scratchPad.isLoading = true;
      //             var data = {
      //               requestId: record._id,
      //               vendorId: record.interPreter,
      //               isResolved: true,
      //               comment: $scope.model.comment || undefined
      //             }
      //             $clientRequestInterpreter.update(client, data)
      //             .then((res) => {
      //               if(res.status){
      //                 finish.call(e, { status: true });
      //                 modal.close(e);
      //               } else {
      //                 $scope.scratchPad.error = err.message || err;
      //               }
      //               $scope.scratchPad.isLoading = false;
      //             })
      //             .catch((err) => {
      //               $scope.scratchPad.error = err.message || err;
      //               $scope.scratchPad.isLoading = false;
      //             })
      //           };
      //           $scope.onClose = function (e) {
      //             modal.close(e);
      //           }
      //         }],
      //         modal: {
      //           dismissable: true,
      //           title: 'Request Interpreter',
      //           template: 'components/modal/tpl/pending-request-interpreter-comment.html'
      //         }
      //       }, 'modal-success', 'md');
      //     };
      //   },
      //   courtResources: function (officeCode, finish) {
      //     finish = finish || angular.noop;
      //     return function () {
      //       var scratchPad = {
      //         isLoading: false,
      //         error: null,
      //         success: null
      //       };
      //       var modal = openModal({
      //         controller: ['$scope', '$auth', '$clientSwitcher', '$clientOffice', '$clientUser', '$sessionItems', function ($scope, $auth, $clientSwitcher, $clientOffice, $clientUser, $sessionItems) {
      //           var client = $clientSwitcher.getCurrentClient();
      //           $scope.scratchPad = scratchPad;
      //           $scope.courtroomsManagement = [];
      //           $scope.reportingCourtDetails = {};
      //           $scope.editMode = {};
      //           $scope.invalideCourtData = false;
      //           function init() {
      //             $scope.scratchPad.isLoading = true;
      //             $scope.scratchPad.error = null;
      //             $scope.sessions = $sessionItems;
      //             const currentUser = $auth.getCurrentUser();
      //             $scope.permissions = {
      //               courtResourceEdit: currentUser.permissions.includes('COURT_RESOURCES_EDIT'),
      //             };
      //             $scope.getJudgeUser();
      //             $scope.getCodesDetails()
      //           }
      //           $scope.getJudgeUser = function() {
      //             $clientUser.getUsersByRoles(client, ['COURT_JUDGE'])
      //             .then((resultSet) => {
      //               $scope.judgeUsers = [];
      //               if (resultSet.status) {
      //                 resultSet.users.map((obj) => {
      //                   (obj.user).map(user => {
      //                     $scope.judgeUsers.push({
      //                       label: user.name,
      //                       value: user._id
      //                     })
      //                   })
      //                 })
      //                 $scope.judgeUsers = _.sortBy($scope.judgeUsers, ['label']);
      //               }
      //             })
      //             .catch((err) => {
      //               $scope.scratchPad.isLoading = false;
      //               $scope.scratchPad.error = err.message || err;
      //             })
      //           }
      //           $scope.getCodesDetails = function() {
      //             $clientOffice.getCodesDetails(client, officeCode)
      //             .then((reportingCourtDetails) => {
      //               if (reportingCourtDetails.data) {
      //                 if (reportingCourtDetails.data.courtroomManagement && reportingCourtDetails.data.courtroomManagement.length) {
      //                   $scope.reportingCourtDetails = reportingCourtDetails.data;
      //                   $scope.courtroomsManagement = reportingCourtDetails.data.courtroomManagement;
      //                   $scope.scratchPad.isLoading = false;
      //                 } else {
      //                   $scope.scratchPad.isLoading = false;
      //                   $scope.scratchPad.error = 'No Courtroom Found';
      //                 }
      //               }
      //             })
      //             .catch((err) => {
      //               $scope.scratchPad.isLoading = false;
      //               $scope.scratchPad.error = err.message || err;
      //             })
      //           }
      //           $scope.isEditable = function (index) {
      //             return $scope.editMode[index];
      //           }

      //           $scope.toggleEdit = function (index) {
      //               if ($scope.editMode[index]) {
      //                 delete $scope.editMode[index];
      //               } else {
      //                 $scope.editMode[index] = true;
      //             }
      //           }

      //           $scope.updateCourtroom = function(index) {
      //             if ($scope.courtroomsManagement.length > 1){
      //               let dublicate = _.find($scope.courtroomsManagement, function(obj, i) {
      //                 return (i !== index && obj.session == $scope.courtroomsManagement[index].session && $scope.courtroomsManagement[index].judges == obj.judges)
      //               })
      //               if (dublicate) {
      //                 $scope.invalideCourtData = true;
      //                 $scope.errorMsg = 'Judge Allready Exist';
      //                 return
      //               }

      //               let allDay = _.find($scope.courtroomsManagement, function(o, i) {
      //                 return (i !== index && o.session == 'ALL DAY' && $scope.courtroomsManagement[index].judges == o.judges)
      //               })
      //               if(allDay){
      //                 $scope.invalideCourtData = true;
      //                 $scope.errorMsg = 'Judge Already have session of ALL DAY';
      //                 return
      //               }
      //               let specificTime = _.find($scope.courtroomsManagement, function(o, i) {
      //                 // console.log(i !== index, $scope.courtroomsManagement[index].session == 'ALL DAY', $scope.courtroomsManagement[index].judges == o.judges, o.session !== $scope.courtroomsManagement[index].session)
      //                 return ((i !== index) &&
      //                 $scope.courtroomsManagement[index].session == 'ALL DAY' &&
      //                   ($scope.courtroomsManagement[index].judges == o.judges &&
      //                     o.session !== $scope.courtroomsManagement[index].session));
      //               })
      //               if (specificTime) {
      //                 $scope.invalideCourtData = true;
      //                 $scope.errorMsg = 'Judge Already have session on ' + specificTime.session;
      //                 return
      //               }
      //             }
      //             $scope.invalideCourtData = false;
      //             $scope.scratchPad.isLoading = true;
      //             $scope.reportingCourtDetails.courtroomManagement = $scope.courtroomsManagement
      //             $clientOffice.updateCourtResource(client, $scope.reportingCourtDetails)
      //             .then((result) => {
      //               // console.log(result)
      //               delete $scope.editMode[index]
      //             })
      //             .catch(err => {
      //               // console.log(err)
      //             })
      //             .finally(() => {
      //               $scope.scratchPad.isLoading = false;
      //             })
      //           }

      //           $scope.getJudgeName = function(judge) {
      //             let value = _.find($scope.judgeUsers, {value: judge})
      //             return value
      //           }
      //           init();
      //           $scope.onClose = function (e) {
      //             modal.close(e);
      //           }
      //         }],
      //         modal: {
      //           dismissable: true,
      //           title: 'Court Resources',
      //           template: 'components/modal/tpl/court-resources.html'
      //         }
      //       }, 'modal-success', 'lg');
      //     };
      //   },
      //   validateInterpreterSignature: function (task, caseDataId, TYPE, payHour, updateIndex, currentFlagEventData, finish) {
      //     finish = finish || angular.noop;
      //     return function () {
      //       const modal = openModal({
      //         controller: ['$scope', '$auth', '$autoTimezoneFormat', '$clientSwitcher', '$clientInterpreter', '$clientTask', 'COURT_APPROVE', function ($scope, $auth, $autoTimezoneFormat, $clientSwitcher, $clientInterpreter, $clientTask, COURT_APPROVE) {
      //           $scope.isLoading = true;
      //           var client = $clientSwitcher.getCurrentClient();
      //           $scope.user = $auth.getCurrentUser();
      //           $scope.username = $scope.user.name;
      //           $scope.task = task;
      //           $scope.interpreterId = $scope.task.caseData.vendorId;
      //           $scope.COURT_APPROVE = COURT_APPROVE;
      //           $scope.defaultPayRate = 30;
      //           $scope.pageData = {
      //             error: null,
      //             profile: null,
      //             isAccept: false
      //           }

      //           $scope.eadcats = {
      //             'USC': 'United States Citizen',
      //             'A1': 'Lawful Permanent Resident',
      //             'A2': 'Lawful Temporary Resident',
      //             'A5': 'Asylee (Granted Asylum)'
      //           }
      //           $scope.error = null;
      //           // {{getLocalSchTime(task.caseData.scheduleTime,
      //           //     task.caseData.deldate, task.location.timezoneid)}}
      //           $scope.showStates = [] //['Facility IN', 'Judge IN', 'Judge OUT', 'Judge IN', 'Judge OUT', 'Facility OUT']


      //           $scope.getLocalSchDateTime = function (date) {
      //             let tz = moment(date).tz(moment.tz.guess()).format('zz');
      //             return moment(date).format('MM/DD/YYYY hh:mm A') + ' ' + tz;
      //           }

      //           $scope.getLocalSchTime = function (time, date, courtTimezoneId) {
      //             let deldateArr = date.split('T');
      //             if (courtTimezoneId) {
      //               let tz = $autoTimezoneFormat.getAllDetail(deldateArr[0], time, courtTimezoneId);
      //               if (tz) return `${tz.userLocale.caseDisplayDateTime} ${tz.userLocale.tzCode}`
      //             }
      //             return ''
      //           }
      //           $scope.WODate = $scope.getLocalSchTime(task.caseData.scheduleTime, task.caseData.deldate, task.courtTimezoneId)

      //           $scope.close = function (e) {
      //             modal.close(e);
      //           }

      //           $scope.editTiming = function(e) {
      //             finish.call(e, { status: true, navigateToEdit: true });
      //             modal.close(e);
      //           }

      //           $scope.buttonClick = function (e) {
      //             $scope.pageData.isAccept = true;
      //             // $scope.approveEvent = (task, caseDataId, TYPE, payHour, updateIndex, currentFlagEventData) => {
      //             const record = {
      //               taskId: task.id,
      //               caseDataId: caseDataId,
      //               approveType: TYPE,
      //               extra: payHour ? {
      //                 payHour: payHour,
      //                 payRate: $scope.defaultPayRate
      //               } : null,
      //             };
      //             if (currentFlagEventData) {
      //               record.approveFlagEvent = currentFlagEventData;
      //             }
      //             if (updateIndex > -1) {
      //               record.updateIndex = updateIndex;
      //             }

      //             let checkCourtApproveOrNot = task.stateActivity.filter((obj) => obj.state == $scope.COURT_APPROVE)
      //             let lastEvent = checkCourtApproveOrNot[checkCourtApproveOrNot.length - 1];

      //             if (currentFlagEventData.isSosiApprovalEvent && lastEvent && lastEvent.approved) {
      //               record.courtAcceptBeforeSOSIApproval = true;
      //             }
      //             $clientTask.approveEvent(client, record)
      //               .then(data => {
      //                 finish.call(e, { status: true });
      //                 modal.close(e);
      //               })
      //           }

      //           $scope.loadinterpreterInfo = function () {
      //             $clientInterpreter.getInterpreterInfo(client, $scope.interpreterId)
      //               .then(res => {
      //                 if (res.status) {
      //                   $scope.pageData.profile = res.result.profile;
      //                 }
      //                 $scope.isLoading = false;
      //               }, err => {
      //                 $scope.pageData.error = err.message || 'somethingwent wrong! try after sometime';
      //                 $scope.isLoading = false;
      //               })
      //           }

      //           function init() {
      //             $scope.task.stateActivityEvent.map(activity => {
      //               if (!activity.isFlagEvent) {
      //                 if (!['Awaiting Interpreter Approval', 'Interpreter Approved', 'Court Approved', 'Awaiting Court Acceptance'].includes(activity.state)) {
      //                   $scope.showStates.push(activity)
      //                 }
      //               }

      //             })
      //             $scope.loadinterpreterInfo()
      //           }
      //           init()
      //         }],

      //         modal: {
      //           dismissable: false,
      //           title: 'Certify and Sign',
      //           template: 'components/modal/tpl/validate-interpreter-signature.html'
      //         }
      //       }, 'modal-success', null, true, true);
      //     }
      //   },
      //   addNewEvents: function (task, finish) {
      //     finish = finish || angular.noop;
      //     return function () {
      //       var scratchPad = {
      //         isLoading: false,
      //         error: null,
      //         success: null,
      //         events: [],
      //         selectedEvent: null,
      //         selectedDateTime: new Date(moment(moment.utc(task.caseData.deldate).format('YYYY-MM-DD')).set({hour: moment().get('hour'),  minute:moment().get('minute'), second: 0, millisecond: 0})),
      //         selectedTime: new Date(moment(moment.utc(task.caseData.deldate).format('YYYY-MM-DD')).set({hour: moment().get('hour'),  minute:moment().get('minute'), second: 0, millisecond: 0})),
      //         comment: null,
      //         showFlag: false,
      //         addEvents: [],
      //         selectedJudge: task.caseDetail && task.caseDetail.ijcode ? task.caseDetail.ijcode : null
      //         // selectedJudge: task.caseData && task.caseData.judge && task.caseData.judge.ijcode ? task.caseData.ijcode : null
      //       };
      //       var modal = openModal({
      //         controller: ['$scope', '$clientSwitcher', '$q', '$clientQueryBuilder', '$auth', '$timezones', '$clientOffice', 'CalculateTime', '$courtOperations', '$autoTimezoneFormat', '$judges', function ($scope, $clientSwitcher, $q, $clientQueryBuilder, $auth, $timezones, $clientOffice, CalculateTime, $courtOperations, $autoTimezoneFormat, $judges) {
      //           var client = $clientSwitcher.getCurrentClient();
      //           $scope.localTz = moment.tz.guess()
      //           $scope.localTzOffset = moment().utcOffset (moment.tz.guess()).format('ZZ')
      //           $scope.scratchPad = scratchPad;
      //           $(".modal-dialog").addClass("modal-dlg");
      //           $scope.user = $auth.getCurrentUser();
      //           $scope.date = new Date();
      //           const isCourtJudge = ($scope.user.settings.roles.findIndex(x => x.code === 'COURT_JUDGE') >= 0);
      //           const isInterpreter = $auth.isInterpreter() || false;// ($scope.user.settings.roles.findIndex(x => x.code === 'INTERPRETER') >= 0);
      //           let rcWoControls = {};
      //           $scope.judgesData = [];
      //           $scope.pagination = {
      //             currentPage: 1,
      //             page: 1,
      //             total: 0,
      //             pageSize: 20,
      //             itemsPerPage: 20
      //         };
      //           let events = [
      //             {
      //               label: 'Facility IN (FIN)',
      //               value: 'FIN',
      //               permissions: $scope.user.permissions.includes('FIN') || (isInterpreter && $scope.user.permissions.includes('WO_EVENT_ADD')),
      //             },
      //             {
      //               label: 'Judge IN (JIN)',
      //               value: 'JIN',
      //               permissions: $scope.user.permissions.includes('JIN') || (isInterpreter && $scope.user.permissions.includes('WO_EVENT_ADD')),
      //             },
      //             {
      //               label: 'Judge OUT (JOUT)',
      //               value: 'JOUT',
      //               permissions: $scope.user.permissions.includes('JOUT') || (isInterpreter && $scope.user.permissions.includes('WO_EVENT_ADD')),
      //             },
      //             // {
      //             //   label: 'LOUT',
      //             //   value: 'LOUT',
      //             //   permissions: $scope.user.permissions.includes('LUNCH_IN_OUT') || (isInterpreter && $scope.user.permissions.includes('WO_EVENT_ADD')),
      //             // },
      //             // {
      //             //   label: 'LIN',
      //             //   value: 'LIN',
      //             //   permissions: $scope.user.permissions.includes('LUNCH_IN_OUT') || (isInterpreter && $scope.user.permissions.includes('WO_EVENT_ADD')),
      //             // },
      //             // {
      //             //   label: 'ROUT',
      //             //   value: 'ROUT',
      //             //   permissions: $scope.user.permissions.includes('COURT_OPS_RELEASE') || (isInterpreter && $scope.user.permissions.includes('WO_EVENT_ADD')),
      //             // },
      //             {
      //               label: 'Facility OUT (FOUT)',
      //               value: 'FOUT',
      //               permissions: $scope.user.permissions.includes('FOUT') || (isInterpreter && $scope.user.permissions.includes('WO_EVENT_ADD')),
      //             }
      //           ];
      //           $scope.pageLimitForDD = 20
      //           $scope.ecoiTimeZone = {
      //             value: moment.tz(task.location.timezoneid).format('z'),
      //             offset: moment.tz(task.location.timezoneid).format('ZZ'),
      //             label: task.location.timezonename,
      //             tzName: task.location.timezoneid,
      //           }
      //           events.map((obj, index) => {
      //             if (obj.permissions) {
      //               $scope.scratchPad.events.push(obj)
      //             }
      //           })
      //           if (!task.importedRecordId) {
      //             if (task.caseData && task.caseData.coi) {
      //               task.importedRecordId = task.caseData.coi
      //             }
      //           }
      //           $scope.loadJudgeList = function (searchKey, page) {
      //             $scope.ddLoading = true;

      //             var pagination = {
      //                 page: page || 1,
      //                 pageSize: 20,

      //               }
      //               let filter = {
      //                 searchText: searchKey || '',
      //                 // orderKey: '',
      //                 // orderVal:''
      //               }
      //             $judges.getActiveList(client, pagination, filter)
      //                 .then((res) => {
      //                     if (res.total) {
      //                       if (page == 1) {
      //                         $scope.judgesData = [];
      //                     }
      //                     $scope.judgesData =($scope.judgesData.concat(res.judgesList));
      //                     $scope.totalJudge = res.total;
      //                     if ( $scope.scratchPad.selectedJudge ) {
      //                       let index = $scope.judgesData.findIndex(el => el.value == $scope.scratchPad.selectedJudge)
      //                         if (index >= 0) {
      //                           $scope.judgesData.unshift($scope.judgesData[index])
      //                         } else {
      //                           if (task.caseDetail && task.caseDetail.ijcode) {
      //                             $scope.judgesData.unshift({
      //                                   ijcode: `${ task.caseDetail.ijcode}`,
      //                                   ijname: `${ task.caseDetail.ijname}`,
      //                                   label: `${ task.caseDetail.ijcode} | ${ task.caseDetail.ijname}`,
      //                                   value: `${ task.caseDetail.ijcode}`
      //                             })
      //                           }
      //                         }
      //                       }
      //                       $scope.judgesData = _.uniq( $scope.judgesData, 'value')
      //                     }
      //                 })
      //                 .catch((err) => {
      //                     console.log(err);
      //                 })
      //                 .finally(function () {
      //                     $scope.ddLoading = false;
      //                 })
      //         }


      //           function init(){
      //             if (task.taskState == 'Confirmed') {
      //               $scope.scratchPad.selectedEvent = 'FIN'
      //             } else if (task.taskState == 'Facility IN') {
      //               $scope.scratchPad.selectedEvent = 'JIN'
      //             } else if (task.taskState == 'Judge IN') {
      //               $scope.scratchPad.selectedEvent = 'JOUT'
      //             } else if (task.taskState == 'Judge OUT') {
      //               $scope.scratchPad.selectedEvent = 'FOUT'
      //             }
      //             $clientOffice.getOfficeWoControls(client, task.caseData.replocid)
      //             .then((res) => {
      //               rcWoControls = res.data.woControls;
      //             })
      //             $scope.loadJudgeList()
      //           }
      //           init();
      //           $scope.setDateForTime = function() {
      //             $scope.scratchPad.selectedTime = $scope.scratchPad.selectedDateTime;
      //           }

      //           $scope.addEventInArr = function (e) {
      //             $scope.scratchPad.error = null;
      //             $scope.scratchPad.addEvents = [];
      //             $scope.scratchPad.selectedDateTime = $scope.scratchPad.selectedTime;
      //             if (!moment($scope.scratchPad.selectedDateTime).utcOffset($scope.ecoiTimeZone.offset).set({second:0, millisecond: 0}).isSameOrBefore(moment().utcOffset($scope.ecoiTimeZone.offset).set({second:0, millisecond: 0}).format())) {
      //               $scope.scratchPad.error = `${$scope.scratchPad.selectedEvent} time should be less then or equal current time.`;
      //               $scope.scratchPad.addEvents = [];
      //               return;
      //             }
      //             if ($scope.scratchPad.addEvents && $scope.scratchPad.addEvents.length > 0) {
      //               let lastEvent = $scope.scratchPad.addEvents[$scope.scratchPad.addEvents.length - 1];
      //               if (moment($scope.scratchPad.selectedDateTime).utcOffset($scope.ecoiTimeZone.offset).format() < moment(lastEvent.dateTime).utcOffset($scope.ecoiTimeZone.offset).format()) {
      //                 $scope.scratchPad.error = 'Please Select Valid DateTime.';
      //                 $scope.scratchPad.addEvents = [];
      //                 return;
      //               }
      //             } else {
      //               if (task.stateActivityEvent && task.stateActivityEvent.length && task.stateActivityEvent[0].date && moment($scope.scratchPad.selectedDateTime).utcOffset($scope.ecoiTimeZone.offset).format() < moment(task.stateActivityEvent[0].date).utcOffset($scope.ecoiTimeZone.offset).format()) {
      //                 $scope.scratchPad.error = `${$scope.scratchPad.selectedEvent} Must Be Greater Than FIN DateTime`;
      //                 $scope.scratchPad.addEvents = [];
      //                 return;
      //               }
      //             }

      //             if (task.stateActivity && task.stateActivity.length) {
      //               let previousEvent = task.stateActivity[task.stateActivity.length - 1];
      //               let curerntSelectedDate = moment($scope.scratchPad.selectedDateTime).utcOffset($scope.ecoiTimeZone.offset).set({second:0, millisecond: 0});
      //               if (previousEvent) {
      //                 let previousEventDate = moment(previousEvent.date).utcOffset($scope.ecoiTimeZone.offset).set({second:0, millisecond: 0});
      //                 if (curerntSelectedDate.diff(moment(previousEventDate)) < 0) {
      //                   errorMessage('Update time should be greater then last event time.');
      //                   return;
      //                 }
      //               }
      //             }

      //             // let isFout = task.stateActivity.find((obj) => obj.state == "Facility OUT");
      //             // let isFoutIndex = task.stateActivity.findIndex((obj) => obj.state == "Facility OUT");
      //             // // let isRout = task.stateActivity.filter((obj) => obj.state == "Release");
      //             // let lastEvent = task.stateActivity[isFoutIndex - 1 ]
      //             // let currentEventTime = moment.utc($scope.scratchPad.selectedDateTime)
      //             // if (lastEvent && lastEvent.date && isFout) {
      //             //   let lastEventDate = new Date( $filter('timezoneDate')(lastEvent.date, task.caseData.timezone));
      //             //   let foutDate = new Date( $filter('timezoneDate')(isFout.date, task.caseData.timezone));
      //             //   if (currentEventTime.isBefore(moment(lastEventDate))) {
      //             //     $scope.scratchPad.error = 'Event Time must be greter then of last event of ' +  lastEvent.state + ' timing'
      //             //     return;
      //             //   } else if (isFout && currentEventTime.isAfter(moment(foutDate))) {
      //             //     $scope.scratchPad.error = 'Event Time must be less then fout event FOUT';
      //             //     return;
      //             //   }
      //             // }
      //             $scope.scratchPad.addEvents.push({
      //               event: $scope.scratchPad.selectedEvent,
      //               dateTime: moment.utc($scope.scratchPad.selectedDateTime).set({ second: 0, millisecond: 0 }),
      //               comment: $scope.scratchPad.comment,
      //               jinJudge:  $scope.getSelectedJudgeDetail($scope.scratchPad.selectedJudge)
      //             });
      //             $scope.scratchPad.selectedEvent = null;
      //             // $scope.scratchPad.selectedDateTime = new Date(moment.utc(task.caseData.deldate).format('YYYY-MM-DD'));
      //             $scope.scratchPad.comment = null;
      //             $scope.scratchPad.selectedJudge = task.caseDetail && task.caseDetail.ijcode ? task.caseDetail.ijcode : null;
      //             $scope.submit(e)
      //           }

      //           $scope.getSelectedJudgeDetail = function (selectedJudge) {
      //             let judge = selectedJudge
      //             if (Array.isArray(selectedJudge) && selectedJudge.length) {
      //               judge = selectedJudge[0]
      //             }
      //             let findSelected = $scope.judgesData.filter(j => j.value == judge)
      //             return findSelected && findSelected.length ? findSelected[0] : null
      //           }

      //           $scope.formatedDateTime = function (dateTime) {
      //             // return moment(dateTime).utcOffset($scope.ecoiTimeZone.offset).format('MM/DD/YYYY hh:mm A');
      //             return moment(dateTime).utcOffset($scope.localTzOffset).format('MM/DD/YYYY hh:mm A');
      //           }

      //           $scope.removeEvent = function (index) {
      //             $scope.scratchPad.error = null;
      //             $scope.scratchPad.addEvents.splice(index, 1);
      //           }

      //           function handleReq(activityFunction, e) {
      //             activityFunction
      //             .then(function (data) {
      //               $scope.scratchPad.isLoading = false;
      //               finish.call(e, true);
      //               modal.close(e);
      //             })
      //               .catch(function (err) {
      //                 $scope.scratchPad.addEvents = [];
      //                 $scope.scratchPad.isLoading = false;
      //                 $scope.scratchPad.error = err || err.message;
      //               })
      //               .finally(() => {
      //                 $scope.$apply()
      //               })
      //           }

      //           $scope.submit = function (e) {
      //             if ($scope.scratchPad.isLoading) return;
      //             $scope.scratchPad.error = null;
      //             $scope.scratchPad.isLoading = true;
      //             let el = $scope.scratchPad.addEvents[0]
      //             new Promise(() => {
      //               switch (el.event) {
      //                 case 'FIN':
      //                   handleReq(facilityIn(el, task), e);
      //                   break;
      //                 case 'JIN':
      //                   handleReq(judgeIn(el, task), e);
      //                   break;
      //                 case 'JOUT':
      //                   handleReq(judgeOut(el, task), e);
      //                   break;
      //                 case 'LOUT':
      //                   handleReq(lunchOutIn(el, task, 'LOUT'), e);
      //                   break;
      //                 case 'LIN':
      //                   handleReq(lunchOutIn(el, task, 'LIN'), e);
      //                   break;
      //                 case 'ROUT':
      //                   handleReq(releaseOut(el, task), e);
      //                   break;
      //                 case 'FOUT':
      //                   handleReq(facilityOut(el, task), e);
      //                   break;
      //                 default:
      //                   break;
      //               }
      //             })

      //           }
      //           function getEventComment(comment) {
      //             let eventComment = null
      //             eventComment = comment ? `${comment} <br/>` : '';
      //             eventComment = isInterpreter ? `${eventComment} - Interpreter Manually Added <br/>`: `${eventComment}`
      //             return `${eventComment} - Event Added By ${$scope.user.name} at ${moment().utcOffset($scope.ecoiTimeZone.offset).format('MM/DD/YYYY hh:mm A')} ${$scope.ecoiTimeZone.value}`
      //           }

      //           function isAfterTime(record, updatedRec) {
      //             let CalculateWOTime = CalculateTime(record.taskDetails.caseData, rcWoControls);
      //             var timezoneOffset = $scope.ecoiTimeZone && $scope.ecoiTimeZone.offset ? $scope.ecoiTimeZone.offset : moment().format('ZZ');
      //             var startTime = moment(updatedRec.dateTime).utcOffset(timezoneOffset).format();
      //             var taskDetails = record.taskDetails;
      //             if (taskDetails && taskDetails.caseData && taskDetails.caseData.judgedInOut && taskDetails.caseData.judgedInOut.length > 0) {
      //               var lastRecordFromJudgeInOut = taskDetails.caseData.judgedInOut[0];
      //               if (lastRecordFromJudgeInOut.event == 'Judge IN') {
      //                 var judgeIN = lastRecordFromJudgeInOut.date;
      //                 startTime = moment(judgeIN).utcOffset(timezoneOffset).format();
      //               }
      //             }
      //             var endTime = CalculateWOTime.schedDateWithTZ != 'N/A' ? CalculateWOTime.schedDateWithTZ : moment(record.sched, "HH:mm:ss").format('Y-MM-DDTHH:mm:ss') + timezoneOffset;
      //             return moment(startTime).diff(moment(endTime)) / 1000;
      //           }

      //           function facilityIn(el, task) {
      //             return new Promise(function (resolve, reject) {
      //               function lateTime(record) {
      //                 var timezoneOffset = $scope.ecoiTimeZone && $scope.ecoiTimeZone.offset ? $scope.ecoiTimeZone.offset : moment().format('ZZ');
      //                 var startTime = moment(el.dateTime).utcOffset(timezoneOffset).format();
      //                 var endTime = moment(record.sched, "HH:mm:ss").format('Y-MM-DDTHH:mm:ss') + timezoneOffset;
      //                 var duration = moment(startTime).diff(moment(endTime)) / 1000;
      //                 if (duration > 0) {
      //                   return duration;
      //                 }
      //                 return 0;
      //               }

      //               function earlyFINOrNot(record) {
      //                 var timezoneOffset = $scope.ecoiTimeZone && $scope.ecoiTimeZone.offset ? $scope.ecoiTimeZone.offset : moment().format('ZZ');
      //                 // var startTime = moment(record.sched, "HH:mm:ss").format('Y-MM-DDTHH:mm:ss') + timezoneOffset;
      //                 var startTime = moment(moment.utc(record.deldate).format('YYYY-MM-DD') + ' '+ moment(record.sched, "HH:mm:ss").format('HH:mm:ss')).format('YYYYY-MM-DDTHH:mm:ss')
      //                 var endTime = moment(el.dateTime).utcOffset(timezoneOffset).format();
      //                 var duration = moment(startTime).diff(moment(endTime)) / 1000;
      //                 if (duration > 0) {
      //                   return duration;
      //                 }
      //                 return 0;
      //               }

      //               function checkBusinessHours(record, businessDays) {
      //                 var timezoneOffset = $scope.ecoiTimeZone && $scope.ecoiTimeZone.offset ? $scope.ecoiTimeZone.offset : moment().format('ZZ');
      //                 var startTime = moment().utcOffset(timezoneOffset).startOf('day').format();
      //                 var endTime = moment(el.dateTime).utcOffset(timezoneOffset).format();
      //                 var duration = moment(endTime).diff(moment(startTime)) / 1000;
      //                 if (duration > 0) {
      //                   var timeDiff = Math.floor(duration / 60);
      //                   var dateIndex = record.deldate ? moment(record.deldate.split('T')[0]).day() : moment().day();
      //                   // var clientBusinessDay = clientBusinessDays[dateIndex - 1];
      //                   var rcBusinessDay = businessDays.data && businessDays.data.businessDays && businessDays.data.businessDays[dateIndex == 0 ? dateIndex : dateIndex - 1];
      //                   if (rcBusinessDay && rcBusinessDay.enabled) {
      //                     if (timeDiff > rcBusinessDay.max) {
      //                       return 'notset';
      //                     }
      //                     return 'allow';
      //                   } else {
      //                     return 'notset';
      //                   }
      //                 }
      //                 return 'allow';
      //               }

      //               $courtOperations.getCasesDetails(client, task.importedRecordId, {})
      //                 .then(function (res) {
      //                   if (res.status) {
      //                     if (res.data.records.length) {
      //                       const record = res.data.records[0];
      //                       if (record && !record.vendorid) {
      //                         return reject('Performed ECOI in did not assign an interpreter!');
      //                         return;
      //                       }
      //                       const officeCode = record.replocid;
      //                       return Promise.all([$clientOffice.getBusinessHours(client, officeCode), Promise.resolve(res), $clientOffice.getOfficeWoControls(client, officeCode)])
      //                     } else {
      //                       return reject('No Record Found For Selected Date');
      //                     }
      //                   } else {
      //                     return reject(res.reason);
      //                   }

      //                 })
      //                 .then(function (res) {
      //                   if (!res) return;
      //                   const rcWoControls = res[2].data.woControls && res[2].data.woControls.earlyFinLimit ? res[2].data.woControls : { maxLunchTime: 120, minWoTime: 180, earlyFinLimit: 120 };
      //                   if (res[1].status) {
      //                     if (res[1].data.records.length > 0) {
      //                       var record = res[1].data.records[0];
      //                       var taskDetails = record.taskDetails;
      //                       if (taskDetails && taskDetails.caseData && taskDetails.caseData.checkedOut && taskDetails.caseData.checkedOut.date) {
      //                         return reject('Interpreter already completed Facility OUT');
      //                       }
      //                       if (taskDetails && taskDetails.caseData && taskDetails.caseData.checkedIn && taskDetails.caseData.checkedIn.date) {
      //                         return reject('Interpreter already completed Facility IN');
      //                       } else {
      //                         var scannedRecord = res[1].data.records[0];
      //                         scannedRecord.lateTime = lateTime(scannedRecord);
      //                         scannedRecord.updateData = {
      //                           date: el.dateTime
      //                         };
      //                         var earlyFIN = earlyFINOrNot(scannedRecord);
      //                         if (earlyFIN > (rcWoControls.earlyFinLimit * 60)) {
      //                           var momentObj = moment(scannedRecord.sched, ["h:mm A"])
      //                           if (scannedRecord.courtTimezone) {
      //                             let userLocalTime = $autoTimezoneFormat.getAllDetail(moment.utc(scannedRecord.deldate).format('YYYY-MM-DD'), scannedRecord.sched, scannedRecord.courtTimezone)
      //                             if (userLocalTime.userLocale &&  userLocalTime.userLocale.caseDisplayDateTime) {
      //                               momentObj = moment(userLocalTime.userLocale.caseDisplayDateTime)
      //                             }
      //                           }
      //                           let reason = `Can't Facility IN before ${rcWoControls.earlyFinLimit / 60} Hour of Scheduled Time. Your scheduled time is ${momentObj.format("hh:mm A")}`;
      //                           if (rcWoControls.earlyFinLimit / 60 > 1) {
      //                             reason = `Can't Facility IN before ${rcWoControls.earlyFinLimit / 60} Hours of Scheduled Time. Your scheduled time is ${momentObj.format("hh:mm A")}`;
      //                           }
      //                           return reject(reason)
      //                         } else {
      //                           const checkFinAllowHours = checkBusinessHours(scannedRecord, res[0]);
      //                           if (checkFinAllowHours === 'allow') {
      //                             if(isInterpreter) {
      //                               scannedRecord.isIcAddedWoEvent = true;
      //                               scannedRecord.isICAddedWOEvent = true;
      //                             }
      //                             scannedRecord.comment= getEventComment(el.comment)
      //                             $clientTask.clientCheckInFacility(client, scannedRecord)
      //                             .then(function (result) {
      //                               if (!result || !result.status) {
      //                                 return reject(result.reason);
      //                               } else {
      //                                 //  if (result.isUpdated) {
      //                                 //   $scope.scratchPad.success = 'ECOI ' + ecoi + ' updated successful';
      //                                 // } else {
      //                                 //   $scope.scratchPad.success = 'ECOI ' + ecoi + ' Facility IN successful';
      //                                 // }
      //                                 el.isUpdated = true;
      //                                 resolve();
      //                               }
      //                             })
      //                             .catch((err) => {
      //                               return reject(err);
      //                             });
      //                           } else if (checkFinAllowHours === 'notset') {
      //                             return reject("Cannot perform event outside of court working hours.");
      //                           } else {
      //                             var momentObj = moment(scannedRecord.sched, ["h:mm A"])
      //                             return reject("Can't Facility IN outside of the Court Hours");
      //                           }
      //                         }
      //                       }
      //                     }
      //                     else {
      //                       return reject('No Record Found');
      //                     }
      //                   }
      //                   else {
      //                     return reject(res[1].reason);
      //                   }
      //                 })
      //                 .catch(function(err) {
      //                   console.log(' FIN ERROR, ', err)
      //                   return reject(err)
      //                 });
      //             })
      //           }
      //           function judgeIn(el, task) {
      //             return new Promise(function (resolve, reject) {
      //               // $officeOperations.getEcoiById(client, task.caseData.coi)
      //               $courtOperations.getCasesDetails(client, task.caseData.coi, {})
      //               .then(function (res) {
      //                 if (res.status) {
      //                   if (res.data.records.length > 0) {
      //                     var record = res.data.records[0];
      //                     var taskDetails = record.taskDetails;

      //                     // if (taskDetails && taskDetails.caseData && taskDetails.caseData.checkedOut && taskDetails.caseData.checkedOut.date) {
      //                     //   return reject('Interpreter already completed Facility OUT');
      //                     // } else {
      //                       if (taskDetails && taskDetails.caseData && taskDetails.caseData.judgedInOut && taskDetails.caseData.judgedInOut.length > 0) {
      //                         var lastRecordFromJudgeInOut = taskDetails.caseData.judgedInOut[taskDetails.caseData.judgedInOut.length - 1];
      //                         if (lastRecordFromJudgeInOut.event == 'Judge IN') {
      //                           return reject('Interpreter already completed Judge IN');
      //                         }
      //                       }

      //                       if (taskDetails && taskDetails.caseData && taskDetails.caseData.lunchOut && taskDetails.caseData.lunchOut.date) {
      //                         if (taskDetails && taskDetails.caseData && taskDetails.caseData.lunchIn && taskDetails.caseData.lunchIn.date) {
      //                         } else {
      //                           return reject('Please Lunch IN before attempting to Judge IN');
      //                         }
      //                       }
      //                     // }
      //                     // task.caseData.judge.comment = 'From Paper COI';
      //                     var lateTime = isAfterTime(record, el);
      //                     var scannedRecord = {
      //                       coi: task.caseData.coi,
      //                       task: taskDetails ? taskDetails._id : null,
      //                       caseData: taskDetails ? taskDetails.caseData : record,
      //                       icLanguages: [],
      //                       judge:  el.jinJudge && el.jinJudge.value ? {ijcode: el.jinJudge.ijcode, ijname: el.jinJudge.ijname} : task.caseData.judge || null,
      //                       alien: task.caseData.alien,
      //                       updateData: {
      //                         date: el.dateTime
      //                       },
      //                       isNewEventAdded: true
      //                     };

      //                     if (lateTime > 0) {
      //                       if (taskDetails && taskDetails.caseData && taskDetails.caseData.judgedInOut && taskDetails.caseData.judgedInOut.length == 0) {
      //                         scannedRecord.lateData = { "reason": "Delayed Start" };
      //                         scannedRecord.lateTime = lateTime;

      //                       }
      //                     } else if (lateTime < 0) {
      //                       if (taskDetails && taskDetails.caseData && taskDetails.caseData.judgedInOut && taskDetails.caseData.judgedInOut.length == 0) {
      //                         scannedRecord.earlyData = {
      //                           'comment': ''
      //                         };
      //                         scannedRecord.earlyTime = lateTime;
      //                       }
      //                     }
      //                     if(isInterpreter) scannedRecord.isIcAddedWoEvent = true
      //                     scannedRecord.judge.comment = getEventComment(el.comment);
      //                     $clientTask.clientJudgeInFacility(client, scannedRecord)
      //                     .then(function (result) {
      //                       if (!result.status) {
      //                         return reject(result.reason);
      //                       } else {
      //                         el.isUpdated = true;
      //                         resolve();
      //                       }
      //                     })
      //                     .catch((err) => {
      //                       return reject(err);
      //                     });
      //                   } else {
      //                     return reject('No Record Found');
      //                   }
      //                 } else {
      //                   return reject(res.reason);
      //                 }
      //               })
      //               .catch(function(err) {
      //                 return reject(err)
      //               });
      //             });
      //           }

      //           function judgeOut(el, task) {
      //             return new Promise(function (resolve, reject) {
      //               // $officeOperations.getEcoiById(client, task.caseData.coi)
      //               $courtOperations.getCasesDetails(client, task.caseData.coi, {})
      //               .then(function (res) {
      //                 if (res.status) {
      //                   if (res.data.records.length > 0) {
      //                     var record = res.data.records[0];
      //                     var taskDetails = record.taskDetails;

      //                     // if (taskDetails && taskDetails.caseData && taskDetails.caseData.checkedOut && taskDetails.caseData.checkedOut.date) {
      //                     //   return reject('Interpreter already completed Facility OUT');
      //                     // } else {
      //                       if (taskDetails && taskDetails.caseData && taskDetails.caseData.judgedInOut && taskDetails.caseData.judgedInOut.length > 0) {
      //                         var lastRecordFromJudgeInOut = taskDetails.caseData.judgedInOut[taskDetails.caseData.judgedInOut.length - 1];
      //                         if (lastRecordFromJudgeInOut.event == 'Judge OUT') {
      //                           return reject('Interpreter already completed Judge OUT, Please Judge IN');
      //                         }
      //                         if (taskDetails && taskDetails.caseData && taskDetails.caseData.lunchOut && taskDetails.caseData.lunchOut.date) {
      //                           if (taskDetails && taskDetails.caseData && taskDetails.caseData.lunchIn && taskDetails.caseData.lunchIn.date) {
      //                           } else {
      //                             return reject('Please Lunch IN before attempting to Judge OUT');
      //                           }
      //                         }
      //                       } else {
      //                         return reject('Please Judge IN before attempting to Judge OUT');
      //                       }
      //                     // }

      //                     // task.caseData.judge.comment = 'From Paper COI';
      //                     let activityJudge = task.caseData.judge ;
      //                     if (taskDetails.caseData.judgedInOut [taskDetails.caseData.judgedInOut.length -1].event == "Judge IN") {
      //                       let lastJINJudge = taskDetails.caseData.judgedInOut[taskDetails.caseData.judgedInOut.length -1].judge;
      //                       if (lastJINJudge && lastJINJudge.ijcode) {
      //                         activityJudge = {ijcode: lastJINJudge.ijcode, ijname: lastJINJudge.ijname}
      //                       }
      //                     }
      //                     var data = {
      //                       coi: task.caseData.coi,
      //                       task: taskDetails ? taskDetails._id : null,
      //                       caseData: taskDetails ? taskDetails.caseData : null,
      //                       updateData: {
      //                         date: el.dateTime,
      //                         langName: taskDetails && taskDetails.caseData && taskDetails.caseData.langname ? taskDetails.caseData.langname : null,
      //                         isRatingEnabled: true,
      //                         professionalism: 0,
      //                         performance: 0,
      //                         procedures: 0
      //                       },
      //                       autoFacilityOut: false,
      //                       judge: activityJudge || null,
      //                       additionalLanguages: null,
      //                       autoJudgeOutIn: false,
      //                       isNewEventAdded: true,
      //                     };
      //                     if(isInterpreter) data.isIcAddedWoEvent = true
      //                     data.judge.comment = getEventComment(el.comment);
      //                     $clientTask.clientJudgeOutFacility(client, data).then(function (result) {
      //                       if (!result.status) {
      //                         return reject(result.reason);
      //                       } else {
      //                         el.isUpdated = true;
      //                         resolve();
      //                       }
      //                     })
      //                     .catch((err) => {
      //                       return reject(err);
      //                     });
      //                   } else {
      //                     return reject('No Record Found');
      //                   }
      //                 } else {
      //                   return reject(res.reason);
      //                 }
      //               })
      //               .catch(function(err) {
      //                 return reject(err)
      //               });
      //             });
      //           }

      //           function lunchOutIn(el, task, event) {
      //             return new Promise(function (resolve, reject) {
      //               // $officeOperations.getEcoiById(client, task.caseData.coi)
      //               $courtOperations.getCasesDetails(client, task.caseData.coi, {})
      //               .then(function (res) {
      //                 if (res.status) {
      //                   if (res.data.records.length > 0) {
      //                     var record = res.data.records[0];
      //                     var taskDetails = record.taskDetails;

      //                     // if (taskDetails && taskDetails.caseData && taskDetails.caseData.checkedOut && taskDetails.caseData.checkedOut.date) {
      //                     //   return reject('Interpreter already completed Facility OUT');
      //                     // } else {
      //                       if (record.sertype == OPI || record.sertype == VRI) {
      //                         return reject(`Lunch Not Permitted on a ${record.sertype} Work Order`);
      //                       }

      //                       if (!taskDetails && record.sertype == OSI) {
      //                         return reject('Please Facility IN before attempting to Lunch IN/OUT');
      //                       }

      //                       if (taskDetails && taskDetails.caseData && taskDetails.caseData.lunchOut.date && taskDetails.caseData.lunchIn.date) {
      //                         return reject('Already completed Lunch OUT/IN');
      //                       }

      //                       if (event == 'LIN') {
      //                         if (taskDetails && taskDetails.caseData && taskDetails.caseData.lunchOut && taskDetails.caseData.lunchOut.date) {
      //                         } else {
      //                           return reject('Please Lunch OUT before attempting to Lunch IN');
      //                         }
      //                       }

      //                       if (!$scope.scratchPad.autoJudgeOutIN && taskDetails && taskDetails.caseData && taskDetails.caseData.judgedInOut && taskDetails.caseData.judgedInOut.length > 0) {
      //                         var lastRecordFromJudgeInOut = taskDetails.caseData.judgedInOut[taskDetails.caseData.judgedInOut.length - 1];
      //                         if (lastRecordFromJudgeInOut.event == 'Judge IN') {
      //                           if (isCourtJudge) {
      //                             if ($scope.user.settings.internalId !== record.ijcode) {
      //                               if (lastRecordFromJudgeInOut.judge && $scope.user.settings.internalId === lastRecordFromJudgeInOut.judge.ijcode) {
      //                               } else {
      //                                 return reject('Please Judge OUT before attempting to Lunch OUT');
      //                               }
      //                             }
      //                           } else {
      //                             return reject('Please Judge OUT before attempting to Lunch OUT');
      //                           }
      //                         }
      //                       }
      //                     // }
      //                     var m = moment(el.dateTime).utcOffset($scope.ecoiTimeZone.offset);
      //                     var currentDayMinutes = (m.hour()*60) + m.minute();

      //                     // task.caseData.judge.comment = 'From Paper COI';
      //                     var scannedRecord = {
      //                       coi: task.caseData.coi,
      //                       task: taskDetails ? taskDetails._id : null,
      //                       caseData: taskDetails ? taskDetails.caseData._id : null,
      //                       judge: task.caseData.judge || null,
      //                       updateData: {
      //                         date: el.dateTime
      //                       },
      //                       isNewEventAdded: true
      //                     };

      //                     rcWoControls.lunchStartTime = rcWoControls.lunchStartTime || 660;
      //                     rcWoControls.lunchEndTime = rcWoControls.lunchEndTime || 840;
      //                     if (taskDetails && taskDetails.caseData && !taskDetails.caseData.lunchOut.date) {
      //                       if (currentDayMinutes < rcWoControls.lunchStartTime) {
      //                         scannedRecord.earlyLunchOut = true;
      //                       }
      //                     } else {
      //                       if (currentDayMinutes > rcWoControls.lunchEndTime) {
      //                         scannedRecord.lateLunchIn = true;
      //                       }
      //                     }

      //                     if(isInterpreter) scannedRecord.isIcAddedWoEvent = true
      //                     scannedRecord.judge.comment = getEventComment(el.comment);
      //                     $clientTask.clientLunchOutInFacility(client, scannedRecord).then(function (result) {
      //                       if (!result.status) {
      //                         return reject(result.reason);
      //                       } else {
      //                         el.isUpdated = true;
      //                         resolve();
      //                       }
      //                     })
      //                     .catch((err) => {
      //                       return reject(err);
      //                     });
      //                   } else {
      //                     return reject('No Record Found');
      //                   }
      //                 } else {
      //                   return reject(res.reason);
      //                 }
      //               })
      //               .catch(function(err) { return reject(err)});
      //             });
      //           }

      //           function releaseOut(el, task) {
      //             return new Promise(function (resolve, reject) {
      //               // $officeOperations.getEcoiById(client, task.caseData.coi)
      //               $courtOperations.getCasesDetails(client, task.caseData.coi, {})
      //               .then(function (res) {
      //                 if (res.status) {
      //                   if (res.data.records.length > 0) {
      //                     var record = res.data.records[0];
      //                     var taskDetails = record.taskDetails;

      //                     // if (taskDetails && taskDetails.caseData && taskDetails.caseData.checkedOut && taskDetails.caseData.checkedOut.date) {
      //                     //   return reject('Interpreter already completed Facility OUT');
      //                     // } else {
      //                       // if (record.sertype == OPI || record.sertype == VRI) {
      //                       //   return reject(`Release of a ${record.sertype} Work Order is Not Permitted. Please Judge Out the Interpreter to Complete`);
      //                       // }
      //                       if (taskDetails && taskDetails.caseData && taskDetails.caseData.judgedInOut && taskDetails.caseData.judgedInOut.length > 0 && taskDetails.caseData.judgedInOut[taskDetails.caseData.judgedInOut.length - 1].event === 'Judge IN') {
      //                         return reject('Please Judge OUT before attempting to Release');
      //                       }
      //                       if (taskDetails && taskDetails.caseData && taskDetails.caseData.lunchOut && taskDetails.caseData.lunchOut.date) {
      //                         if (taskDetails && taskDetails.caseData && taskDetails.caseData.lunchIn && taskDetails.caseData.lunchIn.date) {
      //                         } else {
      //                           return reject('Please Lunch IN before attempting to Release');
      //                         }
      //                       }
      //                     // }
      //                     const icNotUsed = (!taskDetails.caseData.judgedInOut) || (taskDetails.caseData.judgedInOut && !taskDetails.caseData.judgedInOut.length);
      //                     let release = {
      //                       releaseType: 'now',
      //                       comments: '',
      //                       reason: 'No Additional Requirements',
      //                       icAppearedButNotUsedOnRout: icNotUsed
      //                     };
      //                     release.comments = getEventComment(el.comment);
      //                     let releaseEvent = null;

      //                     var data = [{
      //                       coi: task.caseData.coi,
      //                       task: taskDetails ? taskDetails._id : null,
      //                       caseDataId: taskDetails.caseData._id,
      //                       release: release,
      //                       releaseEvent: releaseEvent,
      //                       isSystemEvent: icNotUsed,
      //                       updateData: {
      //                         date: el.dateTime
      //                       },
      //                       isNewEventAdded: true
      //                     }];
      //                     if(isInterpreter) data.isIcAddedWoEvent = true;
      //                     $clientTask.releaseInterpreter(client, data).then(function (result) {
      //                       if (!result.status) {
      //                         return reject(result.reason);
      //                       } else {
      //                         el.isUpdated = true;
      //                         resolve();
      //                       }
      //                     }).catch(function(err) { return reject(err)});
      //                   } else {
      //                     return reject('No Record Found');
      //                   }
      //                 } else {
      //                   return reject(res.reason);
      //                 }
      //               })
      //               .catch(function(err) { return reject(err)});
      //             });
      //           }

      //           function facilityOut(el, task) {
      //             return new Promise(function (resolve, reject) {
      //               // $officeOperations.getEcoiById(client, task.caseData.coi)
      //               $courtOperations.getCasesDetails(client, task.caseData.coi, {})
      //               .then(function (res) {
      //                 if (res.status) {
      //                   if (res.data.records.length > 0) {
      //                     var record = res.data.records[0];
      //                     var taskDetails = record.taskDetails;

      //                     if (taskDetails && taskDetails.caseData && taskDetails.caseData.checkedOut && taskDetails.caseData.checkedOut.date) {
      //                       return reject('Interpreter already completed Facility OUT');
      //                     } else {
      //                       if (taskDetails && taskDetails.caseData && taskDetails.caseData.lunchOut && taskDetails.caseData.lunchOut.date) {
      //                         if (taskDetails && taskDetails.caseData && taskDetails.caseData.lunchIn && taskDetails.caseData.lunchIn.date) {
      //                         } else {
      //                           return reject('Please Lunch IN before attempting to Facility OUT');
      //                         }
      //                       }

      //                       if (taskDetails && taskDetails.caseData && taskDetails.caseData.judgedInOut && taskDetails.caseData.judgedInOut.length > 0) {
      //                         var lastRecordFromJudgeInOut = taskDetails.caseData.judgedInOut[taskDetails.caseData.judgedInOut.length - 1];
      //                         if (lastRecordFromJudgeInOut.event == 'Judge IN') {
      //                           return reject('Please Judge OUT before attempting to Facility OUT');
      //                         }
      //                       }

      //                       // var lastEvent = taskDetails.stateActivity[taskDetails.stateActivity.length - 1];
      //                       // if (lastEvent.state != 'Release') {
      //                       //   return reject('Please Release OUT before attempting to Facility OUT');
      //                       // }
      //                     }

      //                     var scannedRecord = {
      //                       coi: task.caseData.coi,
      //                       task: taskDetails ? taskDetails._id : null,
      //                       caseData: taskDetails ? taskDetails.caseData : null,
      //                       icAppearedButNotUsed: null,
      //                       extensionOverride: null,
      //                       updateData: {
      //                         date: el.dateTime
      //                       },
      //                       isAutoRoutRequired: true,
      //                       comment: getEventComment(el.comment)
      //                     };
      //                     if(isInterpreter) scannedRecord.isIcAddedWoEvent = true;
      //                     $clientTask.clientCheckOutFacility(client, scannedRecord).then(function (result) {
      //                       if (!result.status) {
      //                         return reject(result.reason);
      //                       } else {
      //                         el.isUpdated = true;
      //                         resolve();
      //                       }
      //                     })
      //                     .catch((err) => {
      //                       return reject(err);
      //                     });
      //                   } else {
      //                     return reject('No Record Found');
      //                   }
      //                 } else {
      //                   return reject(res.reason);
      //                 }
      //               })
      //               .catch(function(err) { return reject(err)});
      //             });
      //           }
      //           function errorMessage(message) {
      //             $scope.scratchPad.error = message;
      //             $scope.scratchPad.isLoading = false;
      //           }

      //           $scope.onClose = function (e) {
      //             modal.close(e);
      //           }
      //         }],
      //         modal: {
      //           dismissable: true,
      //           title: 'Add Work Order Event',
      //           template: 'components/modal/tpl/add-work-order-event.html'
      //         }
      //       }, 'modal-success', 'lg');
      //     };
      //   },
      //   editEvents: function (task, activity, index, feature, finish) {
      //     finish = finish || angular.noop;
      //     return function () {
      //       activity = activity ? JSON.parse(JSON.stringify(activity)) : activity;
      //       var scratchPad = {
      //         isLoading: false,
      //         error: null,
      //         success: null,
      //         selectedDateTime: new Date(activity.date),
      //         selectedTime: new Date(activity.date),
      //         comment: null,
      //         showFlag: false
      //       };
      //       var modal = openModal({
      //         controller: ['$scope', '$clientSwitcher', 'ngToast', '$clientTask', '$filter', '$timezones', 'CalculateTime', '$clientOffice', function ($scope, $clientSwitcher, ngToast, $clientTask, $filter, $timezones, CalculateTime, $clientOffice) {
      //           var client = $clientSwitcher.getCurrentClient();
      //           $scope.scratchPad = scratchPad;
      //           $scope.model = {
      //             comment: null,
      //           };
      //           $scope.ecoiTimeZone = $timezones.find(x => x.label === task.caseData.timezone);
      //           let rcWoControls = {};
      //           function init(){
      //             $clientOffice.getOfficeWoControls(client, task.caseData.replocid)
      //             .then((res) => {
      //               rcWoControls = res.data.woControls;
      //             })
      //           }
      //           init();
      //           $scope.setDateForTime = function() {
      //             $scope.scratchPad.selectedTime = $scope.scratchPad.selectedDateTime;
      //           }

      //           // let tzDate = $filter('timezoneDate')(activity.date, task.caseData.timezone);
      //           // $scope.scratchPad.selectedDateTime = new Date(moment(tzDate).format());
      //           function calEarlyOrLateTime() {
      //             let CalculateWOTime = CalculateTime(task.caseData, rcWoControls);
      //             var timezoneOffset = $scope.ecoiTimeZone && $scope.ecoiTimeZone.offset ? $scope.ecoiTimeZone.offset : moment().format('ZZ');
      //             var startTime = moment(activity.date).utcOffset(timezoneOffset).format();
      //             var endTime = CalculateWOTime.schedDateWithTZ != 'N/A' ? CalculateWOTime.schedDateWithTZ : moment(task.caseData.scheduleTime, "HH:mm:ss").format('Y-MM-DDTHH:mm:ss') + timezoneOffset;
      //             return moment(startTime).diff(moment(endTime)) / 1000;
      //           }

      //           $scope.submit = function (e) {
      //             $scope.scratchPad.selectedDateTime = $scope.scratchPad.selectedTime;
      //             $scope.scratchPad.isLoading = true;
      //             $scope.scratchPad.error = null;
      //             const client = $clientSwitcher.getCurrentClient();
      //             var data = {
      //               task: task.id,
      //               activity: activity,
      //               comment: $scope.scratchPad.comment
      //             }

      //             let disputeEvent = [INTERPRETER_DISPUTE, COURT_DECLINE, SOSI_DISPUTE, COURT_APPROVE, INTERPRETER_APPROVE];
      //             if (disputeEvent.indexOf(activity.state) <= -1) {
      //               if (activity.state != 'Facility IN') {
      //                 if (task.stateActivityEvent[0].date && moment($scope.scratchPad.selectedDateTime).format('MM/DD/YYYY') < moment(task.stateActivityEvent[0].date).format('MM/DD/YYYY')) {
      //                   errorMessage('Select valid date.');
      //                   return;
      //                 }
      //               }

      //               activity.date = $scope.scratchPad.selectedDateTime;
      //               if (!moment($scope.scratchPad.selectedDateTime).utcOffset($scope.ecoiTimeZone.offset).set({second:0, millisecond: 0}).isSameOrBefore(moment().utcOffset($scope.ecoiTimeZone.offset).set({second:0, millisecond: 0}).format())) {
      //                 errorMessage( `${activity.state} time should be less then or equal current time.`);
      //                 return;
      //               }
      //               // Check Validation
      //               var currentIndex = _.findIndex(task.stateActivity, function (stateActivity) {
      //                 return stateActivity._id == activity._id;
      //               })
      //               if (currentIndex >= 0) {
      //                 let allPreEventWithoutFlag = task.stateActivity.filter((item, index) => {
      //                   if (index < currentIndex && !item.isFlagEvent) {
      //                     return item
      //                   }
      //                 })
      //                 let previousEvent = allPreEventWithoutFlag[allPreEventWithoutFlag.length - 1] //task.stateActivity[currentIndex - 1];
      //                 let curerntSelectedDate = moment( $filter('timezoneDate')(activity.date, task.caseData.timezone));
      //                 if (previousEvent) {
      //                   let previousEventDate = new Date( $filter('timezoneDate')(previousEvent.date, task.caseData.timezone));
      //                   if (curerntSelectedDate.diff(moment(previousEventDate)) < 0) {
      //                     errorMessage('Update time should be greater then last event time.');
      //                     return;
      //                   }
      //                 }

      //                 let allNextEventWithoutFlag = task.stateActivity.filter((item, index) => {
      //                   if (index > currentIndex && !item.isFlagEvent) {
      //                     return item
      //                   }
      //                 })
      //                 if (activity.state != 'Facility OUT') {
      //                   var nextEvent = allNextEventWithoutFlag[0];  //task.stateActivity[currentIndex + 1];
      //                   if (nextEvent) {
      //                     let nextEventDate = new Date( $filter('timezoneDate')(nextEvent.date, task.caseData.timezone));
      //                     if (nextEvent && curerntSelectedDate.diff(moment(nextEventDate)) > 0) {
      //                       errorMessage('Update time should be less then next event time.');
      //                       return;
      //                     }
      //                   }
      //                 }
      //               }
      //             } else {
      //               activity.date = $scope.scratchPad.selectedDateTime;
      //             }
      //             activity.date = moment.utc($scope.scratchPad.selectedDateTime);
      //             task.isLoading = true;
      //             if (activity.state == 'Judge IN' && activity.eventId && task.caseData.judgedInOut && task.caseData.judgedInOut.length && task.caseData.judgedInOut[0]._id == activity.eventId) {
      //               var lateTime = calEarlyOrLateTime();
      //               if (lateTime > 0) {
      //                 modals.officeOperationActions.lateJINEdit(function (result) {
      //                   data.late = result;
      //                   updateEvent(e, data)
      //                 })();
      //               } else if (lateTime <= 0) {
      //                 updateEvent(e, data)
      //               }
      //             } else {
      //               updateEvent(e, data)
      //             }
      //           };

      //           function updateEvent(e, data) {
      //             // Update event
      //             $clientTask.updateEvents(client, data)
      //               .then((res) => {
      //                 $scope.scratchPad.isLoading = false;
      //                 finish.call(e, res);
      //                 modal.close(e);
      //               })
      //               .catch((err) => {
      //                 errorMessage(err.message || err);
      //               })
      //           };

      //           function errorMessage(message) {
      //             $scope.scratchPad.error = message;
      //             $scope.scratchPad.isLoading = false;
      //           }

      //           $scope.onClose = function (e) {
      //             finish.call(e, { status: false });
      //             modal.close(e);
      //           }
      //         }],
      //         modal: {
      //           dismissable: true,
      //           title: 'Edit ' + activity.state + ' Event',
      //           template: 'components/modal/tpl/edit-event.html'
      //         }
      //       }, 'modal-success', 'md');
      //     };
      //   },
      //   lateJINEdit: function (finish) {
      //     finish = finish || angular.noop;
      //     return function () {
      //       var scratchPad = {
      //         error: null,
      //         success: null,
      //         isLoading: false
      //       };
      //       var modal = openModal({
      //         controller: ['$scope', '$clientSwitcher', function ($scope, $clientSwitcher) {
      //           var client = $clientSwitcher.getCurrentClient();
      //           $scope.scratchPad = scratchPad;
      //           $scope.user = $auth.getCurrentUser();
      //           $scope.date = new Date();
      //           $scope.reasons=[{
      //             label: 'Delayed Start',
      //             value: 'Delayed Start'
      //           },
      //           {
      //             label: 'Interpreter Late',
      //             value: 'Interpreter Late'
      //           },
      //           {
      //             label: 'Other',
      //             value: 'Other'
      //           }]

      //           $scope.buttonClick = function (e) {
      //             finish.call(e, {comment: $scope.scratchPad.comment || null, reason: $scope.scratchPad.reason});
      //             modal.close(e);
      //           };

      //         }],
      //         modal: {
      //           dismissable: false,
      //           title: 'Reason | Late Hearing Start',
      //           template: 'components/modal/tpl/late-judge-in.html',
      //         }
      //       }, 'modal-success', null, true, true);
      //     };
      //   },
      //   coiVRIInfo: function (record, modalLabel, finish) {
      //     finish = finish || angular.noop;
      //     return function () {
      //       var scratchPad = {
      //         model: {
      //           coi: null,
      //           link: null,
      //           otherInfo: null,
      //           serType: record.sertype,
      //           callInfo: null
      //         },
      //         record,
      //         error: null,
      //         success: null,
      //         isLoading: false
      //       };
      //       var modal = openModal({
      //         controller: ['$scope', '$clientCoiVRIInfo', '$clientSwitcher', function ($scope, $clientCoiVRIInfo, $clientSwitcher) {
      //           $scope.scratchPad = scratchPad;
      //           var client = $clientSwitcher.getCurrentClient();
      //           $scope.scratchPad.model.coi = $scope.scratchPad.record.coi;
      //           $scope.scratchPad.model.link = $scope.scratchPad.record.coiInfo && $scope.scratchPad.record.coiInfo.link ? $scope.scratchPad.record.coiInfo.link : null;
      //           $scope.scratchPad.model.callInfo = $scope.scratchPad.record.coiInfo && $scope.scratchPad.record.coiInfo.callInfo ? $scope.scratchPad.record.coiInfo.callInfo : null;
      //           $scope.scratchPad.model.otherInfo = $scope.scratchPad.record.coiInfo && $scope.scratchPad.record.coiInfo.otherInfo ? $scope.scratchPad.record.coiInfo.otherInfo : null;
      //           $scope.buttonClick = function (e) {
      //             $clientCoiVRIInfo.create(client, $scope.scratchPad.model)
      //               .then((res) => {
      //                 if (res.status) {
      //                   modal.dismiss();
      //                   finish.call(e, true);
      //                 }
      //               })
      //               .catch((err) => {
      //                 let errMessage = err.message || err;
      //                 if (err && err.data && err.data.message) errMessage = err.data.message;
      //                 $scope.scratchPad.error = errMessage;
      //               })
      //           };

      //           $scope.cancel = function (e) {
      //             modal.dismiss();
      //             finish.call(e, false);
      //           }

      //         }],
      //         modal: {
      //           dismissable: true,
      //           title: modalLabel,
      //           template: 'components/modal/tpl/coi-vri-info.html',
      //         }
      //       }, 'modal-success');
      //     };
      //   },
      //   updateComment: function (task, activity, finish) {
      //     finish = finish || angular.noop;
      //     return function () {
      //       var scratchPad = {
      //         error: null,
      //         success: null,
      //         isLoading: false,
      //         comment: null
      //       };
      //       var modal = openModal({
      //         controller: ['$scope', '$clientSwitcher', '$clientTask', function ($scope, $clientSwitcher, $clientTask) {
      //           var client = $clientSwitcher.getCurrentClient();
      //           $scope.scratchPad = scratchPad;
      //           $scope.user = $auth.getCurrentUser();
      //           $scope.date = new Date();
      //           $scope.reasons=[{
      //             label: 'Delayed Start',
      //             value: 'Delayed Start'
      //           },
      //           {
      //             label: 'Interpreter Late',
      //             value: 'Interpreter Late'
      //           },
      //           {
      //             label: 'Other',
      //             value: 'Other'
      //           }];

      //           if (activity.late && activity.late.reason) {
      //             $scope.scratchPad.comment = activity.late.comment;
      //             $scope.scratchPad.reason = activity.late.reason;
      //           } else if (activity.early) {
      //             $scope.scratchPad.comment = activity.early.comment;
      //           } else if(activity.rating){
      //             $scope.scratchPad.comment = activity.rating.description;
      //           }

      //           $scope.submitComment = function (e) {
      //             $scope.scratchPad.isLoading = true;
      //             var scannedRecord = {
      //               task: task.id,
      //               activityId: activity._id,
      //               comment: $scope.scratchPad.comment,
      //               reason: $scope.scratchPad.reason
      //             };
      //             $clientTask.updateWOComment(client, scannedRecord).then(function (result) {
      //               $scope.scratchPad.isLoading = false;
      //               finish.call(e, result);
      //               modal.close(e);
      //             });
      //           };

      //         }],
      //         modal: {
      //           dismissable: true,
      //           title: 'Update Comment',
      //           template: 'components/modal/tpl/update-wo-comment.html'
      //         }
      //       }, 'modal-success', null, false, false);
      //     };
      //   }
      // },
      idleScreenActions: {
        idleScreen: function (finish) {
          finish = finish || angular.noop;
          return function () {
            var scratchPad = {
              isEnabled: false
            };
            var modal = openModal({
              controller: ['$scope', 'IDLE_SCREEN_CONFIG', function ($scope, IDLE_SCREEN_CONFIG) {
                var idleScreenConfig = localStorage.getItem(IDLE_SCREEN_CONFIG);
                $scope.scratchPad = idleScreenConfig ? JSON.parse(idleScreenConfig) : scratchPad;
                let currentUser = $auth.getCurrentUser();
                let items = [{
                  label: 'Facility IN',
                  value: 'checkIn',
                  permission: 'FIN'
                }, {
                  label: 'Judge IN',
                  value: 'judgeIn',
                  permission: 'JIN'
                }, {
                  label: 'Judge OUT',
                  value: 'judgeOut',
                  permission: 'JOUT'
                }, {
                  label: 'Facility OUT',
                  value: 'checkOut',
                  permission: 'FOUT'
                }];
                $scope.scans = [];
                items.forEach(element => {
                  if (currentUser.permissions.includes(element.permission)) {
                    $scope.scans.push(element)
                  }
                });

                $scope.submit = function (e) {
                  finish.call(e, $scope.scratchPad);
                  $scope.close(e);
                };

                $scope.close = function (e) {
                  finish.call(e, false);
                  modal.dismiss(e);
                };

              }],
              modal: {
                dismissable: false,
                title: 'Configure Auto Scan',
                template: 'components/modal/tpl/idle-screen-config.html'
              }
            }, 'modal-success', null, true, true);
          };
        }
      },
      interpreterResourcesActions: {
        createCommunicationCenterMessage: function (finish) {
          finish = finish || angular.noop;

          return function () {
            const modal = openModal({
              controller: ['$scope', '$clientInterpreterResources', '$clientSwitcher', 'ngToast', '$q', '$clientOffice', '$auth', '$client', '$timeout', '$uploadS3Attachment', function($scope, $clientInterpreterResources, $clientSwitcher, ngToast, $q, $clientOffice, $auth, $client, $timeout, $uploadS3Attachment) {
                const client = $clientSwitcher.getCurrentClient();
                const currentUser = $auth.getCurrentUser();

                $scope.permissions = {
                  pushNotification: currentUser.permissions.includes('INTERPRETER_RESOURCES_PUSH_NOTIFICATION'),
                  systemWidePn: currentUser.permissions.includes('INTERPRETER_RESOURCES_PUSH_NOTIFICATION_ALL')
                }

                $scope.model = {
                  messageTitle: '',
                  description: '',
                  type: 'COMM_CENTER_MESSAGE',
                  interpreterVendorIds: [],
                  systemWide: false
                }
                $scope.uploader = [];
                $scope.allValues = {
                  rc: 'All Reporting Courts',
                  interpreters: 'All Interpreters'
                };
                $scope.allValue = 'all';

                $scope.data = {
                  startDate: moment().format('YYYY-MM-DD'),
                  endDate: moment().format('YYYY-MM-DD')
                };

                function initDateRangePicker() {
                  function cb(start, end) {
                    $timeout(function () {
                      if (!start || !end) {
                        return;
                      }

                      $('#delDaterange span').html(start.format('MM/DD/YYYY') + ' to ' + end.format('MM/DD/YYYY'));
                      $scope.data.startDate = start.toDate();
                      $scope.data.endDate = end.toDate();
                      $scope.getInterpreters();
                    }, 0);
                  }

                  $('#delDaterange').daterangepicker({
                    startDate: moment($scope.data.startDate),
                    endDate: moment($scope.data.endDate),
                    opens: 'right'
                  }, cb);

                  var text = moment($scope.data.startDate).format('MM/DD/YYYY') + ' to ' + moment($scope.data.endDate).format('MM/DD/YYYY');
                  $('#delDaterange span').html(text);
                  getAllowedOffices();
                }

                setTimeout(initDateRangePicker, 300)

                function getAllowedOffices() {
                  const offices = currentUser.settings.allowOffices.length ? currentUser.settings.allowOffices : 'all';
                  const defaultOffice = currentUser.settings.defaultOffice ? currentUser.settings.defaultOffice.internalId : null;
                  $client.getOffices(client)
                    .then(data => {
                      if(Array.isArray(offices)) {
                        data = data.filter(x => offices.includes(x._id))
                      }
                      $scope.reportingCourtItems = data
                        .filter(x => x.isEnabled)
                        .map(x => ({
                          label: `${x.internalId} | ${x.name}`,
                          value: x.internalId,
                          internalId: x.internalId
                        }))

                      $scope.model.reportingCourts = defaultOffice ? [defaultOffice] : [];
                    });


                }

                $scope.getInterpreters = function () {
                  if (!$scope.model.reportingCourts.length) {
                    return;
                  }
                  const filter = {
                    startDate: moment($scope.data.startDate).format('YYYY-MM-DD'),
                    endDate: moment($scope.data.endDate).format('YYYY-MM-DD'),
                    reportingCourts: $scope.model.reportingCourts
                  };

                  $clientInterpreterResources.getInterpreters(client, filter)
                    .then(data => {
                      const interpreterDataSets = data.data
                        .map(x => ({
                          label: `${x.vendorid} | ${x.interprername}`,
                          value: x.vendorid,
                          replocid: x.replocid
                        }));
                      $scope.interpreterItems = _.uniq(interpreterDataSets, 'label');
                    });
                }

                $scope.buttonClick = function (e) {
                  $scope.error = null;

                  if ($scope.model.interpreterVendorIds === 'all') {
                    $scope.model.interpreterVendorIds = $scope.interpreterItems.map(x => x.value);
                  }

                  if ($scope.model.reportingCourts === 'all') {
                    $scope.model.reportingCourts = (_.uniq($scope.interpreterItems, 'replocid')).map(x => x.replocid);
                  }

                  if ($scope.uploader && $scope.uploader.queue && $scope.uploader.queue.length) {
                    $uploadS3Attachment.upload(client, $scope.uploader, $scope, 'control1')
                      .then((imageRes) => {
                        $scope.model.attachments = [];
                        if (imageRes && imageRes.length) {
                          imageRes.forEach(function (upload) {
                            if (upload.status) {
                              $scope.model.attachments.push(upload.clientUpload._id);
                            }
                          });
                          return $clientInterpreterResources.create(client, $scope.model);
                        }
                      })
                      .then(function (data) {
                        finish.call(e, data);
                        modal.close(e);
                      });
                  } else {
                    $clientInterpreterResources.create(client, $scope.model)
                      .then(function (data) {
                        finish.call(e, data);
                        modal.close(e);
                      })
                  }
                }

                $scope.close = function(e) {
                  finish.call(e, false);
                  modal.close(e);
                }

              }],
              modal: {
                dismissable: false,
                title: 'Create Communication Center Message',
                template: 'components/modal/tpl/create-communication-center-message.html'
              }
            }, 'modal-success', null, true, true);

            modal
              .rendered
              .then(() => $timeout(() => $window.document.getElementById('messageTitle').focus(), focusDelay));

          }
        },
        knowledgeLibraryUpload: function (data, finish) {
          finish = finish || angular.noop;

          return function () {
            const modal = openModal({
              controller: ['$scope', '$clientInterpreterResources', '$clientSwitcher', 'ngToast', '$q', '$uploadS3Attachment', function($scope, $clientInterpreterResources, $clientSwitcher, ngToast, $q, $uploadS3Attachment) {
                const client = $clientSwitcher.getCurrentClient();
                $scope.dateLabel = 'Date Tag';
                $scope.validateFileOverwrite = true;
                $scope.isFileUploading = false;
                $scope.model = {
                  type: 'KNOW_LIBRARY_UPLOAD',
                  knowledgeLibraryBucketId: data.knowledgeLibraryBucketId,
                  uploadType: 'FILE',
                  isShowAttachmentDate: false
                };
                $scope.validateForFolder = {
                  view: 'KL',
                  type: 'KNOW_LIBRARY_UPLOAD',
                  knowledgeLibraryBucketId: data.knowledgeLibraryBucketId,
                  uploadType: 'FILE',
                };
                $scope.model.uploader = [];
                if(data.parentFolderId) {
                  $scope.model.parentFolderId = data.parentFolderId;
                  $scope.validateForFolder.parentFolderId = data.parentFolderId;
                }

                $scope.$watch(function() {
                  return JSON.stringify({isOverwrite: $scope.model.isOverwrite});
                }, function(nv, ov) {
                  if(nv && nv !== ov) {
                    if($scope.model.isOverwrite) {
                      $scope.buttonClick();
                    } else {
                      finish.call(false);
                      modal.close();
                    }
                  }
                });

                $scope.buttonClick = function(e) {
                  $scope.error = null;
                  $scope.isFileUploading = true;
                  if ($scope.model.uploader && $scope.model.uploader.queue && $scope.model.uploader.queue.length) {
                    $uploadS3Attachment.upload(client, $scope.model.uploader, $scope, 'control1')
                      .then((imageRes) => {
                        $scope.model.attachments = [];
                        if (imageRes && imageRes.length) {
                          imageRes.forEach(function (upload) {
                            if (upload.status) {
                              $scope.model.attachments.push(upload.clientUpload._id);
                            }
                          });
                          if ($scope.model.isOverwrite) {
                            let obj = {
                              attachments: $scope.model.attachments,
                              type: $scope.model.type,
                              knowledgeLibraryBucketId: $scope.model.knowledgeLibraryBucketId,
                              uploadType: $scope.model.uploadType,
                              parentFolder: $scope.model.parentFolderId,
                              isOverwrite: $scope.model.isOverwrite,
                              currentFileObjId: $scope.model.currentFileObjId
                            }
                            return $clientInterpreterResources.updateExistFile(client, obj)
                              .then((result) => {
                                if (result && !result.status) {
                                  return ngToast.create({
                                    content: result.message,
                                    className: 'danger'
                                  });
                                } else {
                                  return result.data
                                }
                              })
                              .catch((err) => {
                                let errMessage = err.message || err;
                                if (err && err.data && err.data.message) errMessage = err.data.message;
                                return ngToast.create({
                                  content: errMessage,
                                  className: 'danger'
                                });
                              });
                          } else {
                            return $clientInterpreterResources.create(client, $scope.model);
                          }
                        }
                      })
                      .then(function (data) {
                        $scope.isFileUploading = false;
                        if (data) {
                          finish.call(e, data);
                          modal.close(e);
                          ngToast.create({
                            content: 'File added successfully.',
                            className: 'success'
                          });
                        }
                      });
                  } else {
                    $scope.isFileUploading = false;
                    ngToast.create({
                      content: 'Please select file',
                      className: 'danger'
                    });
                  }
                }

                $scope.close = function(e) {
                  finish.call(e, false);
                  modal.close(e);
                }

              }],
              modal: {
                dismissable: false,
                title: 'Knowledge Library Attachment Upload',
                template: 'components/modal/tpl/knowledge-library-upload.html'
              }
            }, 'modal-success', null, true, true);
          }

        },
        kLCreateParentFolder: function (data, finish) {
          finish = finish || angular.noop;

          return function () {
            var scratchPad = {
              model: {
                name: null,
                type: 'KNOW_LIBRARY_UPLOAD',
                knowledgeLibraryBucketId: data.knowledgeLibraryBucketId,
                uploadType: 'FOLDER'
              },
              isLoading: false,
              error: null
            };
            const modal = openModal({
              controller: ['$scope', '$clientInterpreterResources', '$clientSwitcher', 'ngToast', '$q', function ($scope, $clientInterpreterResources, $clientSwitcher, ngToast, $q) {
                const client = $clientSwitcher.getCurrentClient();
                $scope.scratchPad = scratchPad;

                $scope.buttonClick = function (e) {
                  $scope.scratchPad.error = null;
                  $scope.scratchPad.isLoading = true;
                  if (data.parentFolderId) {
                    $scope.scratchPad.model.parentFolderId = data.parentFolderId;
                  }
                  $clientInterpreterResources.create(client, $scope.scratchPad.model)
                    .then(function (data) {
                      if (data && data.status) {
                        finish.call(e, true);
                        modal.close(e);
                        ngToast.create({
                          content: 'Folder created successfully.',
                          className: 'success'
                        });
                      } else {
                        $scope.scratchPad.error = data.message;
                      }
                    })
                    .catch((err) => {
                      let errMessage = err.message || err;
                      if (err && err.data && err.data.message) errMessage = err.data.message;
                      $scope.scratchPad.error = errMessage;
                    })
                    .finally(() => {
                      $scope.scratchPad.isLoading = false;
                    })
                }

                $scope.close = function (e) {
                  finish.call(e, false);
                  modal.close(e);
                }

              }],
              modal: {
                dismissable: true,
                title: 'Create Folder',
                template: 'components/modal/tpl/add-kl-parent-folder.html'
              }
            }, 'modal-success');
          }
        },
        kLUpdateParentFolder: function (data, finish) {
          finish = finish || angular.noop;

          return function () {
            var scratchPad = {
              model: {
                name: data.folderName || null,
                type: 'KNOW_LIBRARY_UPLOAD',
                folderId: data._id
              },
              isLoading: false,
              error: null
            };
            const modal = openModal({
              controller: ['$scope', '$clientInterpreterResources', '$clientSwitcher', 'ngToast', '$q', function ($scope, $clientInterpreterResources, $clientSwitcher, ngToast, $q) {
                const client = $clientSwitcher.getCurrentClient();
                $scope.scratchPad = scratchPad;

                $scope.buttonClick = function (e) {
                  $scope.scratchPad.error = null;
                  $scope.scratchPad.isLoading = true;
                  $clientInterpreterResources.updateFolder(client, $scope.scratchPad.model)
                    .then(function (data) {
                      finish.call(e, true);
                      modal.close(e);
                      ngToast.create({
                        content: 'Folder updated successfully.',
                        className: 'success'
                      });
                    })
                    .catch((err) => {
                      let errMessage = err.message || err;
                      if (err && err.data && err.data.message) errMessage = err.data.message;
                      $scope.scratchPad.error = errMessage;
                    })
                    .finally(() => {
                      $scope.scratchPad.isLoading = false;
                    })
                }

                $scope.close = function (e) {
                  finish.call(e, false);
                  modal.close(e);
                }

              }],
              modal: {
                dismissable: true,
                title: 'Edit Folder',
                template: 'components/modal/tpl/add-kl-parent-folder.html'
              }
            }, 'modal-success');
          }
        },

        EditFileName: function (data, finish) {
          finish = finish || angular.noop;
          let splitFileName = data.name ? data.name.split('.') : '';
          return function () {
            var scratchPad = {
              model: {
                name: splitFileName[0] || data.name || null,
                fileId: data._id,
                from: data.logFor,
                attachmentDate: null,
                docId: data.docId
              },
              isLoading: false,
              error: null
            };
            const modal = openModal({
              controller: ['$scope', '$clientInterpreterResources', '$clientSwitcher', 'ngToast', '$q', function ($scope, $clientInterpreterResources, $clientSwitcher, ngToast, $q) {
                const client = $clientSwitcher.getCurrentClient();
                $scope.scratchPad = scratchPad;

                function initPicker() {
                  $timeout(function () {
                    $('#datePicker').daterangepicker({
                      singleDatePicker: true,
                      startDate: moment(data.attachmentDate).format('MM/DD/YYYY')
                    }, function (start, end, label) {
                      if (!start || !end) {
                        return;
                      }

                      $('#datePicker span').html(start.format('MM/DD/YYYY'));
                      $scope.scratchPad.model.attachmentDate = start.format();
                    });

                    var text = moment(data.attachmentDate).format('MM/DD/YYYY');
                    $scope.scratchPad.model.attachmentDate = moment(data.attachmentDate).format();
                    $('#datePicker span').html(text);
                  }, 0);
                }
                setTimeout(() => {
                  if ($scope.scratchPad.model.from == 'REPORTS_LIBRARY_FILE_UPDATED') {
                    initPicker();
                  } else {
                    $scope.scratchPad.model.attachmentDate = data.attachmentDate ? moment(data.attachmentDate).format() : moment().format();
                  }
                }, 0);

                $scope.buttonClick = function (e) {
                  $scope.scratchPad.error = null;
                  $scope.scratchPad.isLoading = true;
                  if (splitFileName && splitFileName.length && splitFileName[1]) {
                    $scope.scratchPad.model.name = $scope.scratchPad.model.name + '.' + splitFileName[1]
                  }
                  $clientInterpreterResources.updateFile(client, $scope.scratchPad.model)
                    .then(function (data) {
                      if (data.status) {
                        finish.call(e, true);
                        modal.close(e);
                        ngToast.create({
                          content: 'File name updated successfully.',
                          className: 'success'
                        });
                      } else {
                        let errMessage = data.message || data;
                        if ( data && data.message) errMessage = data.message;
                        $scope.scratchPad.error = errMessage;
                      }
                    })
                    .catch((err) => {
                      let errMessage = err.message || err;
                      if (err && err.data && err.data.message) errMessage = err.data.message;
                      $scope.scratchPad.error = errMessage;
                    })
                    .finally(() => {
                      $scope.scratchPad.isLoading = false;
                    })
                }

                $scope.close = function (e) {
                  finish.call(e, false);
                  modal.close(e);
                }

              }],
              modal: {
                dismissable: true,
                title: 'Edit File',
                template: 'components/modal/tpl/edit-kl-filename.html'
              }
            }, 'modal-success');
          }
        },
        editCommunicationCenterMessage: function (ccMessageData, finish) {
          const data = Object.assign({}, ccMessageData);
          finish = finish || angular.noop;

          return function () {
            const modal = openModal({
              controller: ['$scope', '$clientInterpreterResources', '$clientSwitcher', 'ngToast', '$q', '$authStorage', '$uploadS3Attachment', 'appConfig', function($scope, $clientInterpreterResources, $clientSwitcher, ngToast, $q, $authStorage, $uploadS3Attachment, appConfig) {
                const client = $clientSwitcher.getCurrentClient();

                $scope.model = {
                  messageTitle: data.title,
                  description: data.description,
                  oldAttachments: data.attachments || [],
                  messageId: data._id,
                  attachments: []
                }
                $scope.uploader = [];

                $scope.buttonClick = function (e) {
                  $scope.error = null;

                  if ($scope.uploader && $scope.uploader.queue && $scope.uploader.queue.length) {
                    $uploadS3Attachment.upload(client, $scope.uploader, $scope, 'control1')
                      .then((imageRes) => {
                        $scope.model.attachments = [];
                        if (imageRes && imageRes.length) {
                          imageRes.forEach(function (upload) {
                            if (upload.status) {
                              $scope.model.attachments.push(upload.clientUpload._id);
                            }
                          });
                          $scope.model.attachments = $scope.model.attachments.concat($scope.model.oldAttachments.map(x => x._id));
                          return $clientInterpreterResources.editCCMessage(client, $scope.model);
                        }
                      })
                      .then(function (data) {
                        finish.call(e, data);
                        modal.close(e);
                      });
                  } else {
                    $scope.model.attachments = $scope.model.attachments.concat($scope.model.oldAttachments.map(x => x._id));
                    $clientInterpreterResources.editCCMessage(client, $scope.model)
                      .then(function (data) {
                        finish.call(e, data);
                        modal.close(e);
                      })
                  }
                }

                $scope.removeAttachment = function (attachmentId) {
                  $scope.model.oldAttachments = $scope.model.oldAttachments.filter(x => x._id !== attachmentId);
                }

                // $scope.getFilePath = function (attachment) {
                //   return '/api/client-uploads/' + (client._id || client) + '/' + attachment._id + '?preview=1&access_token=' + $authStorage.getToken();
                //   };
                $scope.getFilePath = function (attachment) {
                  if (['image/jpeg', 'image/png', 'image/gif'].indexOf(attachment.mimeType) >= 0 && attachment.signedUrl) {
                    return attachment.signedUrl;
                  } else {
                    return appConfig.apiUrl + '/api/client-uploads/' + (client._id || client) + '/' + attachment._id + '?preview=1&access_token=' + $authStorage.getToken();
                  }
                };

                $scope.close = function(e) {
                  finish.call(e, false);
                  modal.close(e);
                }

              }],
              modal: {
                dismissable: false,
                title: 'Edit Communication Center Message',
                template: 'components/modal/tpl/edit-communication-center-message.html'
              }
            }, 'modal-success', null, true, true);

            modal
              .rendered
              .then(() => $timeout(() => $window.document.getElementById('messageTitle').focus(), focusDelay));

          }
        },
        showCommunicationsCenterDetails: function (record, type, finish) {
          finish = finish || angular.noop;
          return function () {
            var scratchPad = {
              error: null,
              success: null,
              record,
              type
            };
            var title = '';
            if (scratchPad.type == 'Attachment') {
              title = `${scratchPad.record.title} attachments`;
            } else {
              if (scratchPad.record.recipients && scratchPad.record.recipients.length > 0) {
                title = `Recipients (${scratchPad.record.recipients.length})`;
              } else {
                title = 'Recipient';
              }
            }
            var modal = openModal({
              controller: ['$scope', '$clientSwitcher', '$authStorage', '$downloadAttachment', 'ngToast', 'appConfig', function ($scope, $clientSwitcher, $authStorage, $downloadAttachment, ngToast, appConfig) {
                var client = $clientSwitcher.getCurrentClient();
                $scope.scratchPad = scratchPad;
                $scope.user = $auth.getCurrentUser();
                $scope.getFilePath = function (attachment) {
                  if (['image/jpeg', 'image/png', 'image/gif'].indexOf(attachment.mimeType) >= 0 && attachment.signedUrl) {
                    return attachment.signedUrl;
                  } else {
                    return '/api/client-uploads/' + (client._id || client) + '/' + attachment._id + '?preview=1&access_token=' + $authStorage.getToken();
                  }
                };

                $scope.showAttachment = function (attachment, index) {
                  // $scope.downloadUrl = '/attachment/' + (client._id || client) + '/' + attachment._id;
                  // $window.location = $scope.downloadUrl;
                  $downloadAttachment.download((client._id || client), attachment._id);
                };

                $scope.getSeperated = (item) => item.map(x => x.name).join(', ');

              }],
              modal: {
                dismissable: true,
                title: title,
                template: 'components/modal/tpl/communications-center-attachment.html',
                buttons: [{
                  classes: 'btn-default',
                  text: 'Close',
                  click: function (e) {
                    modal.close(e);
                  }
                }]
              }
            }, 'modal-success');
          };
        },
        knowledgeLibraryFiles: function (filter, permissions, finish) {
          finish = finish || angular.noop;
          return function () {
            var scratchPad = {
              error: null,
              success: null,
              filter,
              permissions
            };
            var modal = openModal({
              controller: ['$scope', '$clientSwitcher', '$authStorage', '$clientInterpreterResources', '$downloadAttachment', function ($scope, $clientSwitcher, $authStorage, $clientInterpreterResources, $downloadAttachment) {
                var client = $clientSwitcher.getCurrentClient();
                $scope.scratchPad = scratchPad;
                $scope.attachmentList = [];
                $scope.user = $auth.getCurrentUser();
                // $scope.getFilePath = function (attachment) {
                //   return '/api/client-uploads/' + (client._id || client) + '/' + attachment._id + '?preview=1&access_token=' + $authStorage.getToken();
                // };

                function init() {
                  $scope.isLoading = true;
                  $clientInterpreterResources.getInterpreterResourcesData(client, filter)
                    .then(function (result) {
                      if (result && result.data) {
                        $scope.attachmentList = result.data
                      }
                    })
                    .finally(function () {
                      $scope.isLoading = false;
                    })
                }

                $scope.showAttachment = function (attachment, index) {
                  // $scope.downloadUrl = '/attachment/' + (client._id || client) + '/' + attachment._id;
                  // window.location = $scope.downloadUrl;
                  $downloadAttachment.download((client._id || client), attachment._id);
                };

                $scope.onFavorite = function (attachment, index) {
                  if (attachment.isFavoriteAll) {
                    return ngToast.create({
                      content: 'Unfavorite not allow.',
                      className: 'danger'
                    });
                  }
                  attachment.isFavorite = !attachment.isFavorite;
                  let isFavorite = attachment.isFavorite
                  $clientInterpreterResources.setAttachmentFavorite(client, {
                    ids: [attachment.docId],
                    isFavorite: isFavorite,
                    interpreters: [$scope.user._id]
                  }).then(res => {
                    init()
                  }, err => {
                    let errMessage = err.message || err;
                    if (err && err.data && err.data.message) errMessage = err.data.message;
                    ngToast.create({
                      content: errMessage,
                      className: 'danger'
                    });
                  })
                }

                $scope.isUserFavorite = function (attachment) {
                  let isFavorite = false
                  if ($scope.user && $scope.user._id) {
                      if (attachment.userFavorites && attachment.userFavorites.length && attachment.userFavorites.indexOf($scope.user._id) >= 0) {
                          isFavorite = true;
                      }
                  }
                  attachment.isFavorite = isFavorite;
                  return attachment.isFavorite;
                }

                init()
              }],
              modal: {
                dismissable: true,
                title: 'Knowledge Library Search Results',
                template: 'components/modal/tpl/knowledge-library-files.html',
                buttons: [{
                  classes: 'btn-default',
                  text: 'Close',
                  click: function (e) {
                    modal.close(e);
                  }
                }]
              }
            }, 'modal-success', 'lg');
          };
        },
        addOrUpdateKnowledgeFolder: function (knowledgeLibrary, modalLabel, finish) {
          finish = finish || angular.noop;
          return function () {
            var scratchPad = {
              model: {
                name: null
              },
              isLoading: false,
              error: null
            };
            var modal = openModal({
              controller: ['$scope', '$clientSwitcher', '$clientKnowledgeLibrary', 'ngToast', function ($scope, $clientSwitcher, $clientKnowledgeLibrary, ngToast) {
                $scope.scratchPad = scratchPad;
                const client = $clientSwitcher.getCurrentClient();
                $scope.scratchPad.model.name = knowledgeLibrary && knowledgeLibrary.name ? knowledgeLibrary.name : null;

                $scope.buttonClick = function (e) {
                  $scope.scratchPad.isLoading = true;
                  if (knowledgeLibrary && knowledgeLibrary.name) {
                    let data = {
                      folderId: knowledgeLibrary._id,
                      name: $scope.scratchPad.model.name
                    }
                    $clientKnowledgeLibrary.update(client, data)
                      .then((res) => {
                        if(res && res.status){
                          modal.dismiss();
                          finish.call(e, true);
                          ngToast.create({
                            content: 'Folder updated successfully.',
                            className: 'success'
                          });
                        }
                      })
                      .catch((err) => {
                        let errMessage = err.message || err;
                        if (err && err.data && err.data.message) errMessage = err.data.message;
                        $scope.scratchPad.error = errMessage;
                      })
                      .finally(() => {
                        $scope.scratchPad.isLoading = false;
                      })
                  } else {
                    $clientKnowledgeLibrary.create(client, $scope.scratchPad.model)
                      .then((res) => {
                        if (res && res.status) {
                          modal.dismiss();
                          finish.call(e, true);
                          ngToast.create({
                            content: 'Folder created successfully.',
                            className: 'success'
                          });
                        } else {
                          $scope.scratchPad.error = res.message;
                        }
                      })
                      .catch((err) => {
                        let errMessage = err.message || err;
                        if (err && err.data && err.data.message) errMessage = err.data.message;
                        $scope.scratchPad.error = errMessage;
                      })
                      .finally(() => {
                        $scope.scratchPad.isLoading = false;
                      })
                  }
                };

                $scope.cancel = function (e) {
                  modal.dismiss();
                  finish.call(e, false);
                }
              }],
              modal: {
                dismissable: true,
                title: modalLabel,
                template: 'components/modal/tpl/add-knowledge-folder.html'
              }
            }, 'modal-success');
          };
        },
        pinSelectedFiles: function (checkedAttachments, isSinglePin, finish) {
          finish = finish || angular.noop;
          return function () {
            var scratchPad = {
              isLoading: false,
              success: null,
              error: null,
              attachments: checkedAttachments,
              isSinglePin: isSinglePin,
              toggle1: 'Unpinned'
            };
            var modal = openModal({
              controller: ['$scope', '$clientSwitcher', '$clientInterpreter', '$clientInterpreterResources', function ($scope, $clientSwitcher, $clientInterpreter, $clientInterpreterResources) {
                $scope.scratchPad = scratchPad;
                const client = $clientSwitcher.getCurrentClient();
                $scope.totalInterpreters = 0;
                $scope.interpreterDataSets = [];
                const currentUser = $auth.getCurrentUser();
                $scope.isInterpreterPinAll = currentUser.permissions.includes('INTERPRETER_PIN_ALL') ? 'all' : '';
                $scope.pageLimitForDD = 20;
                $scope.pagination = {
                  page: 1,
                  pageLimit: 20
                };

                $scope.filter = {
                  interpreter: []
                }
                function getAllInterpreters() {
                  $scope.totalInterpreters = 0;
                  $scope.interpreterDataSets = [];
                  $scope.filter.interpreter = [];
                  let filter = {
                    page: $scope.pagination.page,
                    pageSize: $scope.pagination.pageLimit,
                    searchKey: '',
                  }
                  if ($scope.scratchPad.isSinglePin) {
                    filter.usersPin = $scope.scratchPad.attachments[0].usersPin ? $scope.scratchPad.attachments[0].usersPin.map((item) => item.vendorId) : [];
                    if ($scope.scratchPad.toggle1 == 'Unpinned') {
                      filter.isPinned = false;
                    } else if ($scope.scratchPad.toggle1 == 'Pinned') {
                      if ($scope.scratchPad.attachments[0] && $scope.scratchPad.attachments[0].usersPin.length == 0) {
                        return;
                      }
                      filter.isPinned = true;
                      // $scope.filter.interpreter = filter.usersPin;
                    }
                  }
                  filter.isActive = true;
                  $clientInterpreter.getAllInterpreters(client, filter)
                    .then((res) => {
                      if (res && res.status) {
                        $scope.interpreterDataSets = $scope.interpreterDataSets.concat(res.records);
                        $scope.totalInterpreters = res.totalCount;
                      }
                    })
                    .catch((err) => {
                      console.log(err)
                    })
                }
                getAllInterpreters();

                $scope.toggle1 = function (value) {
                  if (value == 'Unpinned') {
                    $scope.scratchPad.toggle1 = 'Unpinned';
                  } else if (value == 'Pinned') {
                    $scope.scratchPad.toggle1 = 'Pinned';
                  }
                  let title = scratchPad.toggle1 == 'Unpinned' ?  'Pin Selected Files' : 'Un-Pin Selected Files';
                  $('.modal-title').text(title)
                  getAllInterpreters();
                }

                $scope.loadPaginateData = function (searchKey, page) {
                  $scope.ddLoading = true;
                  let filter = {
                    page: page,
                    pageSize: (page !== 1) ? $scope.pagination.pageLimit : ($scope.pagination.pageLimit - $scope.filter.interpreter.length) > 0 ? $scope.pagination.pageLimit - $scope.filter.interpreter.length : $scope.pagination.pageLimit,
                    searchKey: (searchKey).toLowerCase(),
                    selectedIc: $scope.filter.interpreter
                  }
                  if ($scope.scratchPad.isSinglePin) {
                    filter.usersPin = $scope.scratchPad.attachments[0].usersPin ? $scope.scratchPad.attachments[0].usersPin.map((item) => item.vendorId) : [];
                    if ($scope.scratchPad.toggle1 == 'Unpinned') {
                      filter.isPinned = false;
                    } else if ($scope.scratchPad.toggle1 == 'Pinned') {
                      if ($scope.scratchPad.attachments[0] && $scope.scratchPad.attachments[0].usersPin.length == 0) {
                        return;
                      }
                      filter.isPinned = true;
                      $scope.filter.interpreter = filter.usersPin;
                    }
                  }

                  $clientInterpreter.getAllInterpreters(client, filter)
                    .then(function (res) {
                      if (res.status) {
                        if (page == 1) {
                          $scope.interpreterDataSets = [];
                          if (res.selectedInterpreter && res.selectedInterpreter.length) {
                            $scope.interpreterDataSets = $scope.interpreterDataSets.concat(res.selectedInterpreter)
                          }
                        }
                        $scope.interpreterDataSets = $scope.interpreterDataSets.concat(res.records);
                        $scope.totalInterpreters = res.totalCount;
                      }
                    })
                    .catch(function (err) {
                      console.log(err)
                    })
                    .finally(function () {
                      $scope.ddLoading = false;
                    })
                }

                $scope.buttonClick = function (e) {
                  let checkedItem = [];
                  $scope.scratchPad.attachments.filter((item) => {
                    if (item.isSelected) {
                      checkedItem.push(item.docId)
                    }
                  });
                  let isPin = true;
                  let removeInterpreter = [];
                  if ($scope.scratchPad.toggle1 == 'Pinned') {
                    isPin = false;
                    let ICArr = [];

                    $scope.interpreterDataSets.filter((obj) => {
                      if($scope.filter.interpreter && $scope.filter.interpreter == 'all'){
                        ICArr.push({
                          vendorId: (obj.value).toString(),
                          pinUser: currentUser.settings._id
                        })
                      } else {
                        let removeVendorIds = $scope.filter.interpreter.find((item) => {
                          return item == obj.value;
                        })
                        if( removeVendorIds){
                          ICArr.push({
                            vendorId: (obj.value).toString(),
                            pinUser: currentUser.settings._id
                          })
                        }
                      }
                    })
                    removeInterpreter = ICArr;
                  } else {
                    if($scope.filter.interpreter && $scope.filter.interpreter == 'all'){
                      removeInterpreter = $scope.filter.interpreter
                    } else {
                      $scope.filter.interpreter.forEach((obj) => {
                        removeInterpreter.push({
                          vendorId: obj,
                          pinUser: currentUser.settings._id
                        })
                      })
                    }
                  }
                  $clientInterpreterResources.setAttachmentPin(client, {
                    ids: checkedItem,
                    isPin: isPin,
                    interpreters: removeInterpreter
                  }).then(res => {
                    modal.dismiss();
                    finish.call(e, true);
                  }, err => {
                  })
                }

                $scope.cancel = function (e) {
                  modal.dismiss();
                  finish.call(e, false);
                }
              }],
              modal: {
                dismissable: true,
                title: 'Pin Selected Files',
                template: 'components/modal/tpl/pin-seleceted-files.html',
              }
            }, 'modal-success');
          };
        },
        favoriteSelectedFiles: function (checkedAttachments, isSingleFavorite, finish) {
          finish = finish || angular.noop;
          return function () {
            var scratchPad = {
              isLoading: false,
              success: null,
              error: null,
              attachments: checkedAttachments,
              isSingleFavorite: isSingleFavorite,
              toggle1: 'UnFavorite'
            };
            var modal = openModal({
              controller: ['$scope', '$clientSwitcher', '$clientInterpreter', '$clientInterpreterResources', function ($scope, $clientSwitcher, $clientInterpreter, $clientInterpreterResources) {
                $scope.scratchPad = scratchPad;
                const client = $clientSwitcher.getCurrentClient();
                $scope.totalInterpreters = 0;
                $scope.interpreterDataSets = [];
                const currentUser = $auth.getCurrentUser();
                $scope.isInterpreterPinAll = '';//currentUser.permissions.includes('INTERPRETER_PIN_ALL') ? 'all' : '';
                $scope.pageLimitForDD = 20;
                $scope.pagination = {
                  page: 1,
                  pageLimit: 20
                };

                $scope.filter = {
                  interpreter: []
                }
                function getAllInterpreters() {
                  $scope.totalInterpreters = 0;
                  $scope.interpreterDataSets = [];
                  $scope.filter.interpreter = [];
                  let filter = {
                    page: $scope.pagination.page,
                    pageSize: $scope.pagination.pageLimit,
                    searchKey: '',
                  }
                  if ($scope.scratchPad.isSingleFavorite) {
                    filter.userFavorites = $scope.scratchPad.attachments[0].userFavorites ? $scope.scratchPad.attachments[0].userFavorites : [];
                    if ($scope.scratchPad.toggle1 == 'UnFavorite') {
                      filter.isFavorite = false;
                    } else if ($scope.scratchPad.toggle1 == 'Favorite') {
                      if ($scope.scratchPad.attachments[0] && $scope.scratchPad.attachments[0].userFavorites.length == 0) {
                        return;
                      }
                      filter.isFavorite = true;
                      $scope.filter.interpreter = filter.userFavorites;
                    }
                  }

                  $clientInterpreter.getAllInterpreters(client, filter)
                    .then((res) => {
                      if (res && res.status) {
                        $scope.interpreterDataSets = $scope.interpreterDataSets.concat(res.records);
                        $scope.totalInterpreters = res.totalCount;
                      }
                    })
                    .catch((err) => {
                      console.log(err)
                    })
                }
                getAllInterpreters();

                $scope.toggle1 = function (value) {
                  if (value == 'UnFavorite') {
                    $scope.scratchPad.toggle1 = 'UnFavorite';
                  } else if (value == 'Favorite') {
                    $scope.scratchPad.toggle1 = 'Favorite';
                  }
                  getAllInterpreters();
                }

                $scope.loadPaginateData = function (searchKey, page) {
                  $scope.ddLoading = true;
                  let filter = {
                    page: page,
                    pageSize: $scope.pagination.pageLimit,
                    searchKey: (searchKey).toLowerCase(),
                  }
                  if ($scope.scratchPad.isSingleFavorite) {
                    filter.userFavorites = $scope.scratchPad.attachments[0].userFavorites ? $scope.scratchPad.attachments[0].userFavorites : [];
                    if ($scope.scratchPad.toggle1 == 'UnFavorite') {
                      filter.isFavorite = false;
                    } else if ($scope.scratchPad.toggle1 == 'Favorite') {
                      if ($scope.scratchPad.attachments[0] && $scope.scratchPad.attachments[0].userFavorites.length == 0) {
                        return;
                      }
                      filter.isFavorite = true;
                      $scope.filter.interpreter = filter.userFavorites;
                    }
                  }

                  $clientInterpreter.getAllInterpreters(client, filter)
                    .then(function (res) {
                      if (res.status) {
                        if (page == 1) {
                          $scope.interpreterDataSets = [];
                        }
                        $scope.interpreterDataSets = $scope.interpreterDataSets.concat(res.records);
                        $scope.totalInterpreters = res.totalCount;
                      }
                    })
                    .catch(function (err) {
                      console.log(err)
                    })
                    .finally(function () {
                      $scope.ddLoading = false;
                    })
                }

                $scope.buttonClick = function (e) {
                  let checkedItem = [];
                  $scope.scratchPad.attachments.filter((item) => {
                    if (item.isSelected) {
                      checkedItem.push(item.docId)
                    }
                  });
                  let isFavorite = true;
                  let removeInterpreter = $scope.filter.interpreter;
                  if ($scope.scratchPad.toggle1 == 'Favorite') {
                    isFavorite = false;
                    let ICArr = [];
                    $scope.interpreterDataSets.filter((obj) => {
                      if ($scope.filter.interpreter && $scope.filter.interpreter == 'all') {
                        ICArr.push((obj.value).toString());
                      } else {
                        if ($scope.filter.interpreter.indexOf((obj.value).toString()) >= 0) {
                          ICArr.push((obj.value).toString());
                        }
                      }
                    })
                    removeInterpreter = ICArr;
                  }
                  $clientInterpreterResources.setAttachmentFavorite(client, {
                    ids: checkedItem,
                    isFavorite: isFavorite,
                    interpreters: removeInterpreter
                  }).then(res => {
                    modal.dismiss();
                    finish.call(e, true);
                  }, err => {
                  })
                }

                $scope.cancel = function (e) {
                  modal.dismiss();
                  finish.call(e, false);
                }
              }],
              modal: {
                dismissable: true,
                title: 'Favorite Selected Files',
                template: 'components/modal/tpl/favorite-selected-files.html',
              }
            }, 'modal-success');
          };
        },
        setInterpreterAvailability: function(finish) {
          finish = finish || angular.noop;
          return function () {
            var scratchPad = {
              isLoading: false,
              success: null,
              error: null,
            };
            var modal = openModal({
              controller: ['$scope', '$clientSwitcher', '$interpretersWOAvailability', 'ngToast', function ($scope, $clientSwitcher, $interpretersWOAvailability, ngToast) {
                $scope.scratchPad = scratchPad;
                const client = $clientSwitcher.getCurrentClient();
                const currentUser = $auth.getCurrentUser();
                // console.log(currentUser)
                $scope.model = {
                  availableFrom: moment().add(1, 'day'),
                  availableTo: '',
                  type: null,
                  name: currentUser.name,
                  email: currentUser.email,
                  available: false,
                  unavailable: true,
                  interpreterTZ: '', //currentUser.timezone
                  unavailableFromAM: true,
                  unavailableFromPM: false,
                  unavailableToAM: false,
                  unavailableToPM: false,
                  unavailableToOn: false,
                  multipleDays: false,
                  serviceType: {
                    OSI: true, OPI:true, VRI: true
                  }
                }

                $scope.toggle = () => {
                  $scope.scratchPad.error  = '';
                  $scope.model.unavailable = !$scope.model.available;
                  if ($scope.model.available) {
                    $scope.model.serviceType = {
                      OSI: false, OPI:false, VRI: false
                    }
                  }
                }
                $scope.buttonClick = function (e) {
                  let data = {
                    name: currentUser.name,
                    email: currentUser.email,
                    unavailable: $scope.model.unavailable,
                    availableFrom: '',
                    availableTo: '',
                    serviceType:[]
                  };
                  $scope.scratchPad.error = '';
                  let textAvailable = $scope.model.available ? 'Availablity' : 'Unavailability'
                  if (!$scope.model.unavailableFromAM && !$scope.model.unavailableFromPM) {
                    return $scope.scratchPad.error = `Please select ${textAvailable} from time slot`;
                  } else {
                    let time = $scope.model.unavailableFromAM ? '08:00 AM' : $scope.model.unavailableFromPM ? '12:00 PM' : '';
                    data.availableFrom = $scope.model.availableFrom + ' ' + time;
                  }

                  if ($scope.model.multipleDays && !$scope.model.unavailableToAM && !$scope.model.unavailableToPM) {
                    return $scope.scratchPad.error = `Please select ${textAvailable} to time slot`;
                  }

                  if ($scope.model.available && !($scope.model.serviceType.OSI || $scope.model.serviceType.OPI || $scope.model.serviceType.VRI)){
                    return $scope.scratchPad.error = `Please select Service Type`;
                  }
                  if ($scope.model.available ) {
                    (Object.keys($scope.model.serviceType)).forEach(type => {
                      if ($scope.model.serviceType[type]) {
                        data.serviceType.push(type)
                      }
                    })
                  }

                  if (!$scope.model.multipleDays) {
                    let time = $scope.model.unavailableFromPM ? '08:00 PM' : $scope.model.unavailableFromAM ? '12:00 PM' : '';
                    data.availableTo = $scope.model.availableTo + ' ' + time;
                  }
                  if ($scope.model.multipleDays  || ($scope.model.unavailableToAM || $scope.model.unavailableToPM)) {
                    let time = $scope.model.unavailableToPM ? '08:00 PM' : $scope.model.unavailableToAM ? '12:00 PM' : '';
                    data.availableTo = $scope.model.availableTo + ' ' + time;
                  }

                  $interpretersWOAvailability.createAvailability(client, data)
                  .then((res) => {
                    // console.log(res)
                    // if (res.arr && res.arr.length) {
                    //   res.arr.forEach(item => {
                    //     console.log(moment(item.availableFrom).utcOffset('-0400').format(), moment(item.availableTo).utcOffset('-0400').format())
                    //   })
                    // }
                    if (res.status){
                      ngToast.create({
                        className: 'success',
                        content: `Interpreter ${textAvailable} set successfully;`
                      });
                      modal.dismiss();
                      finish.call(e, {status: true});
                    } else {
                      ngToast.create({
                        className: 'danger',
                        content: `Unable to set Interpreter ${textAvailable};`
                      });
                    }
                  })
                  .catch((err) => {
                    console.log(err)
                    ngToast.create({
                      className: 'danger',
                      content: `Unable to set Interpreter ${textAvailable};`
                    });
                  })
                }

                $scope.close = function (e) {
                  modal.dismiss();
                  finish.call(e, false);
                }
                $scope.setEndDate = () => {
                  let endDate = $scope.model.availableFrom ? moment($scope.model.availableFrom).add($scope.model.multipleDays ? 1 : 0, 'day') : moment().format('MM/DD/YYYY');
                  $scope.model.availableTo = endDate.format('YYYY-MM-DD');
                  $('#availableTo').daterangepicker({
                    singleDatePicker: true,
                    startDate: endDate,
                    minDate: moment(endDate).toDate() || moment().toDate(),
                  }, function (start) {
                    if (!start) return;
                    $('#availableTo span').html(start.format('MM/DD/YYYY'));
                    $scope.model.availableTo = start.format('YYYY-MM-DD');
                  });
                  if ($scope.model.availableTo) {
                    var text = moment($scope.model.availableTo).format('MM/DD/YYYY');
                    $('#availableTo span').html(text);
                  }
                }

                function initPicker() {
                  $timeout(function () {
                    let startDate = moment().add(1, 'day').format();


                    $('#availableFrom').daterangepicker({
                      singleDatePicker: true,
                      startDate: moment().add(1, 'day').format('MM/DD/YYYY'),
                      minDate: moment().add(1, 'day').toDate(),
                      // timePicker: true,
                      // timePicker24Hour: true
                    }, function (start) {
                      if (!start) {
                        return;
                      }
                      $('#availableFrom span').html(start.format('MM/DD/YYYY'));
                      $scope.model.availableFrom = start.format('YYYY-MM-DD');
                      $scope.setEndDate()

                    });
                    var text = moment(startDate).format('MM/DD/YYYY');
                    $('#availableFrom span').html(text);
                    $scope.model.availableFrom =  moment(startDate).format('YYYY-MM-DD');
                    $scope.setEndDate()
                  }, 500);
                }
                initPicker()
              }],
              modal: {
                dismissable: true,
                title: 'Interpreter Availability',
                template: 'components/modal/tpl/interpreter-availability.html',
              }
            }, 'modal-success', 'md', true, true);
          };
        }
      },
      kioskActions: {
        handleLateFout: function (record, finish) {
          finish = finish || angular.noop;

          return function () {
            const modal = openModal({
              controller: ['$scope', '$clientSwitcher', 'Modal', function($scope, $clientSwitcher, Modal) {
                const client = $clientSwitcher.getCurrentClient();

                $scope.scratchPad = {
                  lateReason: null,
                  comment: null,
                  success: null,
                  error: null
                };

                $scope.lateReasons = [{
                  label: 'Judge Required Additional Service',
                  value: 'Judge Required Additional Service'
                },
                {
                  label: 'Court Required Additional Service',
                  value: 'Court Required Additional Service'
                },
                {
                  label: 'Facility Issue',
                  value: 'Facility Issue'
                },
                {
                  label: 'System Issue',
                  value: 'System Issue'
                }
              ];


                $scope.buttonClick = function(e) {
                  $scope.error = null;

                  const scannedRecord = {
                    coi: record.coi,
                    task: record.taskDetails._id,
                    caseData: record.taskDetails.caseData,
                    kioskLateFout: {
                      reason: $scope.scratchPad.lateReason,
                      comment: $scope.scratchPad.comment
                    },
                    manual: true
                  };

                  $scope.validatePassword(scannedRecord, e);

                }

                $scope.validatePassword = function(scannedRecord, e) {

                  modals.officeOperationActions().then(ooa=>{
                    ooa.validatePassword(undefined, function(res) {
                      //Returns true if password is correct
                      if (res) {
                        $clientTask.clientCheckOutFacility(client, scannedRecord).then(function (result) {
                          if (!result.status) {
                            $scope.scratchPad.error = result.reason;
                          } else {
                            finish.call(e, {
                              submit: true,
                              success: 'ECOI ' + scannedRecord.coi + ' Facility OUT successful'
                            });
                            modal.close(e);
                          }
                        });
                      }
                    })();
                  })
                }

                $scope.close = function(e) {
                  finish.call(e, { submit: false });
                  modal.close(e);
                }

              }],
              modal: {
                dismissable: false,
                title: 'Please Select Reason for Facility OUT Late',
                template: 'components/modal/tpl/kiosk-fout-late.html'
              }
            }, 'modal-success', null, true, true);
          }
        },
        interpreterCertification: function (record, finish) {
          finish = finish || angular.noop;

          return function () {
            const modal = openModal({
              controller: ['$scope', '$clientSwitcher', '$timezones', function($scope, $clientSwitcher, $timezones) {
                const client = $clientSwitcher.getCurrentClient();
                const getTzShort = (tzname) => {
                  const short = $timezones.find(x => x.label === tzname);
                  return short ? short.value : null;
                }

                const statusText = (status) => {
                  if (status === 'A1') {
                    return 'Lawful Permanent Resident';
                  } else if (status === 'USC') {
                    return 'United States Citizen';
                  } else if (status === 'A2' || status === 'A5' || status === 'A8') {
                    return `I am not a United States Citizen nor a Lawful Permanent Resident, but I have received a waiver from EOIR to interpret for the Immigration Court. Specify Immigration Status : ${status}`;
                  } else {
                    return null;
                  }
                }

                function schedValue(time) {
                  if (time && time) {
                    var momentObj = moment(time, ["h:mm A"])
                    return momentObj.format("hh:mm A");
                  } else {
                    return 'N/A';
                  }
                }


                $scope.scratchPad = {
                  name: record.interprername,
                  status: statusText(record.eadcat),
                  court: record.reportingcourt,
                  lang: record.langname,
                  judge: record.ijname,
                  date: record.deldate,
                  time: schedValue(record.sched),
                  tz: getTzShort(record.timezonename),
                  isFti: record.langstatus === 'FTI',
                };

                $scope.buttonClick = function(e) {
                  $scope.error = null;

                  $scope.validatePassword(e);

                }

                $scope.validatePassword = function (e) {
                  modals.officeOperationActions().then(ooa=>{
                    ooa.validatePassword(undefined, function (res) {
                      //Returns true if password is correct
                      if (res) {
                        record.isDigitallySigned = true;
                        $clientTask.clientCheckInFacility(client, record).then(function (result) {
                          if (!result.status) {
                            $scope.model.error = result.reason;
                            finish.call(e, { status: false, reason: result.reason });
                            modal.close(e);
                          } else {
                            finish.call(e, { status: true, message: 'ECOI ' + record.coi + ' Facility IN successful' });
                            modal.close(e);
                          }
                        });
                      }
                    })();
                  })
                }

                $scope.close = function(e) {
                  finish.call(e, false);
                  modal.close(e);
                }

                $scope.confirm = function(e) {
                  finish.call(e, true);
                  modal.close(e);
                }



              }],
              modal: {
                dismissable: false,
                title: 'Interpreter Certification',
                template: 'components/modal/tpl/interpreter-certification.html'
              }
            }, 'modal-success', null, true, true);
          }
        }
      },
      sosWoInformationActions: {
        sosWODetails: function(record, finish){

          finish = finish || angular.noop;
          return function () {
            var scratchPad = {
              isEnabled: false,
              record: record
            };
            var modal = openModal({
              controller: ['$scope', function ($scope) {
                $scope.scratchPad = scratchPad;
              }],
              modal: {
                dismissable: true,
                title: 'SOS Email Information',
                template: 'components/modal/tpl/sos-email-information.html',
                buttons: [{
                  classes: 'btn-default',
                  text: 'Close',
                  click: function (e) {
                    modal.close(e);
                  }
                }]
              }
            }, 'modal-success', 'lg');
          };
        },
        sosWONotes: function(task, finish) {
          finish = finish || angular.loop;
          return function () {
            var scratchPad = {
              isLoading: true,
              task
            };
            var modal = openModal({
              controller: ['$scope','$clientSwitcher', '$sosnots',  function($scope, $clientSwitcher, $sosnots) {
                $scope.scratchPad = scratchPad;
                const client = $clientSwitcher.getCurrentClient();

                $scope.pagination = {
                  currentPage: 1,
                  page: 1,
                  total: 0,
                  pageLimit: 20,
                  itemsPerPage: 20
              };

                $scope.loadSOSNotes = function (recordNo) {
                  console.log(recordNo)
                  $scope.scratchPad.isLoading = true;
                  $sosnots.getSosnotsList(client, {page: 1, pageSize:20, recordNo})
                  .then((res) =>{
                    if (res.status) {
                      $scope.scratchPad.notes = res.sosnotsList;
                      $scope.pagination.total = res.totalRecords;
                    }
                  })
                  .catch((error) => {
                    // console.log(error)
                  })
                  .finally(() => {
                    $scope.scratchPad.isLoading = false;
                  })
                }

                $scope.onPageChange = () => {
                }

                $scope.loadSOSNotes(task.caseDetail.recordnumber)
              }],
              modal: {
                dismissable: true,
                title: 'SOS Notes',
                template: 'components/modal/tpl/sos-wo-notes.html',
                buttons: [{
                  classes: 'btn-default',
                  text: 'Close',
                  click: function (e) {
                    modal.close(e);
                  }
                }]
              }
            }, 'modal-success', 'lg')
          }
        }
      },
      invoice: {
        fundingAttachments: function(finish){
          finish = finish || angular.noop;

          return function () {
            const modal = openModal({
              controller: ['$scope', '$clientInterpreterResources', '$clientSwitcher', 'ngToast', '$q', 'appConfig', function($scope, $clientInterpreterResources, $clientSwitcher, ngToast, $q, appConfig) {
                const client = $clientSwitcher.getCurrentClient();
                $scope.isLoading = false;
                $scope.model = {
                  type: 'INVOICE_FUNDDING_UPLOAD',
                }

                $scope.buttonClick = function(e) {
                  $scope.error = null;
                  $scope.isLoading = true;
                  $scope.uploadFiles()
                    .then(function (data) {
                      if(data && data.length && $scope.model.uploader.queue.length) { // add these to the model so when it creates the task, it will associate these items.
                        $scope.model.attachments = data.map(function (upload) {
                          return upload;
                        });
                        return $scope.model
                        // return $clientInterpreterResources.create(client, $scope.model);
                      }
                    })
                    .then(function (data) {
                      $scope.isLoading = false;
                      finish.call(e, data);
                      modal.close(e);
                    });
                }

                $scope.model.uploader = new FileUploader({
                  url: appConfig.apiUrl + '/api/client-uploads/'+client._id+'?access_token='+$authStorage.getToken(),
                  alias: 'upload',
                  filters: [
                    {
                      name: 'extensionFilter',
                      fn: function(item) {
                        var extension = item.name.substr(item.name.lastIndexOf('.')+1);
                        var invalidExtensions = ['exe', 'bin'];
                        if(invalidExtensions.indexOf(extension) > -1) {
                          ngToast.create({
                            className: 'danger',
                            content: 'Files with extensions '+invalidExtensions+' are not allowed'
                          });
                          return false;
                        } else {
                          return true;
                        }
                      }
                    }
                  ]
                });

                $scope.uploadFiles = function () {
                  var
                  uploader = !!$scope.model ? $scope.model.uploader : false,
                  results = [],
                  errors = [];

                  if(uploader && uploader.queue && uploader.queue.length) {
                    var defer = $q.defer();

                    uploader.onSuccessItem = function(fileItem, response, status, headers) {
                      Array.prototype.push.apply(results, response);
                    };

                    uploader.onErrorItem = function(fileItem, response, status, headers) {
                      errors.push(new Error('Failed to upload file: ' + fileItem.file.name));
                    };

                    uploader.onCompleteAll = function() {
                      if(errors.length > 0) { // fail on any errors
                        defer.reject(errors);
                        return;
                      }

                      defer.resolve(results);
                    };

                    uploader.uploadAll();

                    return defer.promise;
                  }
                };


                $scope.close = function(e) {
                  finish.call(e, false);
                  modal.close(e);
                }

              }],
              modal: {
                dismissable: false,
                title: 'Fundding Event Attachment Upload',
                template: 'components/modal/tpl/fundding-attachments.html'
              }
            }, 'modal-success', null, true, true);
          }
        },

        createInvoice: function (title, invoiceObj, finish) {
          finish = finish || angular.noop;

          return function () {
            const modal = openModal({
              controller: ['$scope', '$clientSwitcher', 'ngToast', '$fundingEvents', '$invoice', 'appConfig', function ($scope, $clientSwitcher, ngToast, $fundingEvents, $invoice, appConfig) {
                const client = $clientSwitcher.getCurrentClient();

                $scope.isLoading = false;
                $scope.model = {
                  invoiceId: '',
                  startDate: moment().format('MM/DD/YYYY'),
                  endDate: moment().format('MM/DD/YYYY'),
                  description: '',
                  fundingEventId: null,
                  attachments: []
                };
                if (invoiceObj) {
                  $scope.model = {
                    _id: invoiceObj._id,
                    invoiceId: invoiceObj.invoiceId,
                    startDate: moment(invoiceObj.startDate).format('MM/DD/YYYY'),
                    endDate: moment(invoiceObj.endDate).format('MM/DD/YYYY'),
                    description: invoiceObj.description,
                    fundingEventId: invoiceObj.fundingEventId._id,
                    attachments: [],
                    oldAttachments : invoiceObj.attachments || []
                  };
                }
                $scope.fundingPagination = {
                  currentPage: 1,
                  page: 1,
                  total: 0,
                  pageLimit: 20,
                  itemsPerPage: 20
                };
                $scope.fundingEventIds = [];

                $scope.buttonClick = function (e) {
                  $scope.error = null;
                  $scope.isLoading = true;
                  $scope.uploadFiles()
                    .then(function (data) {
                      if (data && data.length && $scope.model.uploader.queue.length) { // add these to the model so when it creates the task, it will associate these items.
                        // $scope.model.attachmentsObj = []
                        $scope.model.attachments = data.map(function (upload) {
                          // $scope.model.attachmentsObj.push(upload)
                          return upload._id;
                        });
                        if ($scope.model._id && $scope.model.oldAttachments) {
                          $scope.model.oldAttachments.map(function (upload) {
                            $scope.model.attachments.push(upload._id)
                          });
                        }
                        return $scope.model
                      } else {
                        return $scope.model
                      }
                    })
                    .then(function (data) {
                      delete (data.uploader);
                      if (data && data._id) {
                        $invoice.update(client, data)
                          .then((result) => {
                            if (result && result.status) {
                              $scope.isLoading = false;
                              finish.call(e, result);
                              modal.close(e);
                            } else {
                              ngToast.create({
                                className: 'danger',
                                content: result.reason
                              });
                            }
                          })
                          .catch((err) => {
                            $scope.isLoading = false;
                            console.log(err)
                          })
                      } else {
                        $invoice.create(client, data)
                          .then((result) => {
                           if (result && result.status) {
                              $scope.isLoading = false;
                              finish.call(e, result);
                              modal.close(e);
                            } else {
                              ngToast.create({
                                className: 'danger',
                                content: result.reason
                              });
                              $scope.isLoading = false;
                            }
                          })
                          .catch((err) => {
                            $scope.isLoading = false;
                            console.log(err)
                          })
                      }
                    });
                }

                $scope.close = function (e) {
                  finish.call(e, false);
                  modal.close(e);
                }

                $scope.getFunddingIds = function (filterObj, page) {
                  $scope.isLoading = true;
                  let filter = {};
                  $fundingEvents.getFunddingList(client, filter || {}, page)
                    .then(res => {
                      let dataList = [];
                      $scope.fundingPagination.total = res.totalCount
                      res.record.map((item) => dataList.push({ label: item.referenceId, value: item._id }))
                      if ($scope.fundingEventIds && $scope.fundingEventIds.length) {
                        $scope.fundingEventIds = _.uniq($scope.fundingEventIds.concat(dataList), 'value')
                      } else {
                        $scope.fundingEventIds = dataList;
                      }
                    })
                    .catch(err => {
                      console.log(err)
                    })
                    .finally(() => {
                      $scope.isLoading = false;
                    })
                }

                $scope.loadFilterPaginateData = function (search, page, type) {
                  $scope.getFunddingIds(search, { page: page, pageLimit: 20 })
                }
                function init() {
                  setTimeout(() => initDateRangePicker(), 300)
                  $scope.getFunddingIds({}, { page: 1, pageLimit: 20 })
                }

                $scope.model.uploader = new FileUploader({
                  url: appConfig.apiUrl + '/api/client-uploads/' + client._id + '?access_token=' + $authStorage.getToken(),
                  alias: 'upload',
                  filters: [
                    {
                      name: 'extensionFilter',
                      fn: function (item) {
                        var extension = item.name.substr(item.name.lastIndexOf('.') + 1);
                        var invalidExtensions = ['exe', 'bin'];
                        if (invalidExtensions.indexOf(extension) > -1) {
                          ngToast.create({
                            className: 'danger',
                            content: 'Files with extensions ' + invalidExtensions + ' are not allowed'
                          });
                          return false;
                        } else {
                          return true;
                        }
                      }
                    }
                  ]
                });

                $scope.uploadFiles = function () {
                  var
                    uploader = !!$scope.model ? $scope.model.uploader : false,
                    results = [],
                    errors = [];

                  if (uploader && uploader.queue && uploader.queue.length) {
                    var defer = $q.defer();

                    uploader.onSuccessItem = function (fileItem, response, status, headers) {
                      Array.prototype.push.apply(results, response);
                    };

                    uploader.onErrorItem = function (fileItem, response, status, headers) {
                      errors.push(new Error('Failed to upload file: ' + fileItem.file.name));
                    };

                    uploader.onCompleteAll = function () {
                      if (errors.length > 0) { // fail on any errors
                        defer.reject(errors);
                        return;
                      }

                      defer.resolve(results);
                    };

                    uploader.uploadAll();

                    return defer.promise;
                  } else {
                    return $q.when(true);
                  }
                };

                function initDateRangePicker() {
                  function cb(start, end) {
                    $timeout(function () {
                      if (!start || !end) {
                        return;
                      }

                      $('#daterange span').html(start.format('MM/DD/YYYY') + ' to ' + end.format('MM/DD/YYYY'));
                      $scope.model.startDate = start.format('MM/DD/YYYY');
                      $scope.model.endDate = end.format('MM/DD/YYYY');
                    }, 0);
                  }

                  $('#daterange').daterangepicker({
                    startDate: moment($scope.model.startDate),
                    endDate: moment($scope.model.endDate),
                    opens: 'right'
                  }, cb);

                  var text = moment($scope.model.startDate).format('MM/DD/YYYY') + ' to ' + moment($scope.model.endDate).format('MM/DD/YYYY');
                  $('#daterange span').html(text);
                }
                init()
              }],
              modal: {
                dismissable: false,
                title: title,
                template: 'components/modal/tpl/create-invoice.html'
              }
            }, 'modal-success', null, true, true);
          }
        }
      },
      interpreterProfileActions: {
        interpreterProfile: function (interpreterId, finish) {
          finish = finish || angular.noop;
          return function () {
            var scratchPad = {};
            var modal = openModal({
              controller: ['$scope', '$rootScope', '$state', '$clientSwitcher', '$clientInterpreter', '$auth', '$q', '$clientInterpreterProfile', 'ngToast', 'appConfig',
              function ($scope, $rootScope, $state, $clientSwitcher, $clientInterpreter, $auth, $q, $clientInterpreterProfile, ngToast, appConfig) {
                $scope.scratchPad = scratchPad;
                $scope.interpreterId = interpreterId;
                $scope.uploader = null;
                $scope.defaultICProfile = null;
                $scope.file = {
                  changed: false,
                  croppedImage: '',
                  uploadedImage: ''
                };
                $scope.pageData = {
                  isLoading: false,
                  profile: {},
                  dataList: [],
                  icLanguages: [],
                  icProfile: {},
                  error: null,
                  isInterpreterUser: $auth.isInterpreter()
                }
                const client = $clientSwitcher.getCurrentClient();
                $scope.currentClientId = client._id;
                $scope.getInterpreterInfo = function() {
                  $scope.pageData.isLoading = true;
                  $scope.pageData.error = null;
                  $clientInterpreter.getInterpreterInfo(client, $scope.interpreterId)
                  .then(res => {
                    if (res.status) {
                      this.pageData.profile = res.result.profile;
                      this.pageData.dataList = res.result.dataList;
                      this.pageData.icLanguages = res.result.languages;
                      this.pageData.icProfile = res.result.icProfile;
                      $scope.defaultICProfile = (this.pageData.icProfile &&  this.pageData.icProfile.imageUploadId && this.pageData.icProfile.imageUploadId._id) ?
                      this.pageData.icProfile.signedUrl : 'assets/images/user.png';
                      let hccode = null;
                      if (this.pageData.profile.hccode && Array.isArray(this.pageData.profile.hcCodes) && this.pageData.profile.hcCodes.length) {
                        hccode = this.pageData.profile.hcCodes.concat(this.pageData.profile.hccode)
                      } else if (!this.pageData.profile.hccode) {
                        hccode = this.pageData.profile.hcCodes;
                      } else {
                        hccode = [this.pageData.profile.hccode];
                      }
                      this.pageData.profile.hccode = Array.isArray(hccode) ? (hccode).join(', ') : hccode;
                    }
                    $scope.pageData.isLoading = false;
                    $scope.showImageLoader = false;
                  }, err => {
                    this.pageData.error = err.message || 'somethingwent wrong! try after sometime';
                    $scope.pageData.isLoading = false;
                    $scope.showImageLoader = false;
                  })
                }

                // $scope.getProfileURL = function() {
                //   return '/attachment/' + $scope.currentClientId + '/' + this.pageData.icProfile.imageUploadId._id;
                // };

                var handleFileSelect = function(file) {
                  var reader = new FileReader();
                  reader.onload = function (evt) {
                    $scope.$apply(function($scope){
                      $scope.file.uploadedImage=evt.target.result;
                    });
                  };
                  reader.readAsDataURL(file);
                  setTimeout(() => {
                    $scope.uploadFiles().then(function(results) {
                      let data = {
                        vendorId : $scope.interpreterId,
                        imageUploadId: results[0]._id
                      }

                      $clientInterpreterProfile.create(client, data)
                      .then(res => {
                        ngToast.create({
                          className: 'success',
                          content: 'Interpreter Profile Update Successfully.'
                        });
                        $scope.showImageLoader = false;
                      }, err => {
                        ngToast.create({
                          className: 'danger',
                          content: err
                        });
                        $scope.showImageLoader = false;
                      })
                    })
                  },1500)
                };

                $scope.fileNameChanged = function (ele) {
                  $scope.file.changed = true;
                  $scope.showImageLoader = true;
                  $scope.uploader.queueu = [];
                  var file = ele.files[0];
                  handleFileSelect(file);
                };
                $scope.uploader = new FileUploader({
                  url: appConfig.apiUrl + '/api/client-uploads/' + $scope.currentClientId,
                  headers : {
                      'Authorization': $authStorage.getToken()
                  },
                  alias: 'upload',
                  filters: [
                    {
                      name: 'extensionFilter',
                      fn: function(item) {
                        var extension = item.name.substr(item.name.lastIndexOf('.')+1);
                        var invalidExtensions = ['exe', 'bin'];
                        if(invalidExtensions.indexOf(extension) > -1) {
                          ngToast.create({
                            className: 'danger',
                            content: 'Files with extensions '+invalidExtensions+' are not allowed'
                          });
                          return false;
                        } else {
                          return true;
                        }
                      }
                    }
                  ]
                });

                $scope.uploadFiles = function () {
                  var
                  uploader = $scope.uploader,
                  results = [],
                  errors = [];

                  if(uploader && uploader.queue && uploader.queue.length) {
                    var defer = $q.defer();

                    uploader.onSuccessItem = function(fileItem, response) {
                      Array.prototype.push.apply(results, response);
                    };

                    uploader.onErrorItem = function(fileItem) {
                      errors.push(new Error('Failed to upload file: ' + fileItem.file.name));
                    };

                    uploader.onCompleteAll = function() {
                      if(errors.length > 0) { // fail on any errors
                        defer.reject(errors);
                        return;
                      }

                      defer.resolve(results);
                    };

                    uploader.uploadAll();

                    return defer.promise;
                  }

                  return $q.when(false);
                };

                $scope.showFullProfile = function (e) {
                  // staticParams.setParams({search: $scope.pageData.profile.vendorid})
                  // $rootScope.$broadcast('onMenuChange', { childTab: 'resources', ui: 'piplineOperations', sub_child: null,  search: $scope.pageData.profile.vendorid });
                  let url = "/my/dashboard/main-dashboard?view=piplineOperations&ic=" + $scope.pageData.profile.vendorid
                  modal.close(e);
                  window.open(url,'_blank');
                }
                $scope.close = function (e) {
                  modal.close(e);
                }
                function init() {
                  $scope.showImageLoader = true;
                  $scope.getInterpreterInfo();
                  const currentUser = $auth.getCurrentUser();

                  $scope.permissions = {
                    interpreterAddressView: currentUser.permissions.includes('INTERPRETER_ADDRESS_VIEW'),
                    hasProfileChangePermission: currentUser.permissions.includes('INTERPRETER_PROFILE_UPDATE'),
                    hasResourceTabPermission:  currentUser.permissions.includes('RESOURCES_TAB'),
                    hasResourceBaseTabPermission: currentUser.permissions.includes('RESOURCES_BASE_TAB')
                  }
                }
                init();
              }],
              modal: {
                dismissable: true,
                title: 'Interpreter Profile',
                template: 'components/modal/tpl/interpreter-profile.html'
              }
            }, 'modal-success', 'lg');
          };
        },
        courtDistance: function(interpreterObj, offices, finish) {
          finish = finish || angular.noop;
          return function () {
            var scratchPad = {
              interpreterObj,
              isLoading: true,
              offices: _.groupBy(offices , 'internalId'),
              courts: [],
              isEnabled: true,
              activeCourts: [],
              inactiveCourts: []
            };
            var interpreterAddress = `${interpreterObj.streetaddressa}, ${interpreterObj.city}, ${interpreterObj.state} ${interpreterObj.zipcode}`;
            var title = `${interpreterObj.name} | ${interpreterObj.vendorid} | 
            ${interpreterAddress}`

            const modal = openModal({
              controller: ['$scope',  '$clientSwitcher', '$clientInterpreterCourtDistance', 'ngToast', function ($scope,  $clientSwitcher, $clientInterpreterCourtDistance, ngToast) {
                const client = $clientSwitcher.getCurrentClient();
                $scope.scratchPad = scratchPad;
                $scope.collections = [];
                $scope.pagination = {
                  currentPage: 1,
                  page: 1,
                  total: interpreterObj.distances.length,
                  pageLimit: 20,
                  itemsPerPage: 20
                };

                $scope.filter = {
                  searchText: '',
                  hasDistance: true
                }
                $scope.buttonClick = function (e) {
                  modal.close(e);
                }

                $scope.close = function (e) {
                  finish.call(e, false);
                  modal.close(e);
                }

                $scope.syncSingleCourtDistance = function(court, court_address) {
                  if ($scope.scratchPad.isSyncing) return
                  $scope.scratchPad.isSyncing = true;
                  let body = {
                    vendorid : interpreterObj.vendorid,
                    court_code: court,
                    court_address: court_address,
                    interpreter_address: interpreterAddress,
                  }
                  console.log(body)
                  $clientInterpreterCourtDistance.syncSingleCourtDistance(client, body)
                  .then((res) => {
                    // console.log(res)
                    $scope.scratchPad.isSyncing = false;
                    ngToast.create({
                      content: 'Interpreter court Distance Synced',
                      className: 'success'
                    });
                  })
                  .catch((err) => {
                    console.log(err)
                    $scope.scratchPad.isSyncing = false;
                    ngToast.create({
                      content: err.message || 'somethingwent wrong!',
                      className: 'error'
                    });
                  })
                }

                function setActiveInactiveCourts() {
                  let collection = [];
                  offices.map(office => {
                    let index = $scope.scratchPad.interpreterObj.distances.findIndex(item => item.court_code == office.internalId);
                    if (index >= 0) {
                      let item = Object.assign($scope.scratchPad.interpreterObj.distances[index], {
                        name: office.name,
                        hasDistance: true,
                        searchText: office.name + ' ' +   office.internalId
                      })
                      if (0 >= item.distance) {
                        item.court_address = office.address;
                        item.court_code = office.internalId;
                        item.searchText = office.name + ' ' +   office.internalId;
                        item.distance = 0;
                        item.interpreter_address ="";
                        item.vendorid = interpreterObj.vendorid;
                        item.name = office.name;
                        item.hasDistance = false;
                      }
                      collection.push(item)
                    } else {
                      let item = {
                        court_address: office.address,
                        court_code: office.internalId,
                        searchText: office.name + ' ' +   office.internalId,
                        distance: 0,
                        interpreter_address:"",
                        vendorid: interpreterObj.vendorid,
                        name: office.name,
                        hasDistance: false
                      }
                      collection.push(item)
                    }
                  });
                  $scope.collections =  _.sortBy(collection, ['distance'], ['asc']);
                  $scope.scratchPad.isLoading = false;

                }

                $scope.filterData = function (item) {
                  let searchStr = $scope.filter.searchText ? $scope.filter.searchText.toLowerCase() : '';
                  if (searchStr) {
                    return true
                  } else {
                    return ((item.hasDistance === $scope.filter.hasDistance));
                  }
                }
                function init() {
                  setActiveInactiveCourts()

                  // $scope.scratchPad.isLoading = false;
                  // $scope.scratchPad.courts = $scope.scratchPad.interpreterObj.distances.slice(0, $scope.pagination.pageLimit);
                }
                $scope.onSearch = () => {
                  $scope.pagination.page = 1
                }
                $scope.onPageChange = () => {
                }

                $scope.toggleCourts = () => {
                  // offices
                  if ($scope.scratchPad.isEnabled) {
                    $scope.scratchPad.courts = $scope.scratchPad.activeCourts.slice(0, $scope.pagination.pageLimit);
                  } else {
                    $scope.scratchPad.courts = $scope.scratchPad.activeCourts.slice(0, $scope.pagination.pageLimit);
                  }
                  $scope.scratchPad.isLoading = false;
                }
                init()
              }],
              modal: {
                dismissable: false,
                title: title ? title : 'Interpreter Court Distance',
                template: 'components/modal/tpl/interpreter-court-distance-modal.html'
              }
            }, 'modal-success', 'lg', true, true);
          }
        },
        interpreterDistance: function(officeObj, interpreters, finish) {
          finish = finish || angular.noop;
          return function () {
            var scratchPad = {
              officeObj,
              isLoading: true,
              interpreters: interpreters, //_.groupBy(interpreters , 'internalId'),
              courts: [],
              isEnabled: true,
              activeCourts: [],
              inactiveCourts: []
            };
            var courtAddress = `${officeObj.address}, ${officeObj.city}, ${officeObj.state} ${officeObj.zip}`;
            var title = `${officeObj.internalId} | ${officeObj.name} | ${courtAddress}`

            const modal = openModal({
              controller: ['$scope',  '$clientSwitcher', '$clientInterpreterCourtDistance', 'ngToast', function ($scope,  $clientSwitcher, $clientInterpreterCourtDistance, ngToast) {
                const client = $clientSwitcher.getCurrentClient();
                $scope.scratchPad = scratchPad;
                $scope.collections = [];
                $scope.pagination = {
                  currentPage: 1,
                  page: 1,
                  total: officeObj.distances && officeObj.distances.length ? officeObj.distances.length : 0,
                  pageLimit: 20,
                  itemsPerPage: 20
                };
                $scope.filter = {
                  searchText: '',
                  hasDistance: true
                }
                $scope.buttonClick = function (e) {
                  modal.close(e);
                }

                $scope.close = function (e) {
                  finish.call(e, false);
                  modal.close(e);
                }

                function setActiveInactiveCourts() {
                  let collection = [];
                  interpreters.map(ic => {
                    let index = $scope.scratchPad.officeObj.distances ? $scope.scratchPad.officeObj.distances.findIndex(item => item.vendorid == ic.vendorid) : -1;
                    let interpreterAddress = ic.streetaddressa ? `${ic.streetaddressa}, ${ic.city}, ${ic.state} ${ic.zipcode}` : '',
                    interpreterName = `${ic.firstname} ${ic.middlename ? ic.middlename : ''} ${ic.lastname}`;

                    if (index >= 0) {
                      let item = Object.assign($scope.scratchPad.officeObj.distances[index], {
                        name: `${interpreterName}` ,
                        hasDistance: true,
                        searchText: `${interpreterName} ${ic.vendorid}`
                      })

                      if (0 >= item.distance) {
                        item.court_address = courtAddress;
                        item.court_code = officeObj.internalId;
                        item.searchText = interpreterName + ' ' +   ic.vendorid;
                        item.distance = 0;
                        item.interpreter_address = interpreterAddress;
                        item.vendorid = ic.vendorid;
                        item.name = interpreterName;
                        item.hasDistance = false;
                      }
                      collection.push(item)
                    } else {
                      let item = {
                        court_address: courtAddress,
                        court_code: officeObj.internalId,
                        searchText: interpreterName + ' ' +   ic.vendorid,
                        distance: 0,
                        interpreter_address: interpreterAddress,
                        vendorid: ic.vendorid,
                        name: interpreterName,
                        hasDistance: false
                      }
                      collection.push(item)
                    }
                  });
                  $scope.collections =  _.sortBy(collection, ['distance'], ['asc']);
                  $scope.scratchPad.isLoading = false;

                }

                $scope.filterData = function (item) {
                  let searchStr = $scope.filter.searchText ? $scope.filter.searchText.toLowerCase() : '';
                  if (searchStr) {
                    return true
                  } else {
                    return ((item.hasDistance === $scope.filter.hasDistance));
                  }
                }
                function init() {
                  setActiveInactiveCourts()
                }
                $scope.onSearch = () => {
                  $scope.pagination.page = 1
                }
                $scope.onPageChange = () => {
                }

                $scope.toggleCourts = () => {
                  // offices
                  if ($scope.scratchPad.isEnabled) {
                    $scope.scratchPad.courts = $scope.scratchPad.activeCourts.slice(0, $scope.pagination.pageLimit);
                  } else {
                    $scope.scratchPad.courts = $scope.scratchPad.activeCourts.slice(0, $scope.pagination.pageLimit);
                  }
                  $scope.scratchPad.isLoading = false;
                }
                init()
              }],
              modal: {
                dismissable: false,
                title: title ? title : 'Court Interpreter Distance',
                template: 'components/modal/tpl/court-interpreter-distance-modal.html'
              }
            }, 'modal-success', 'lg', true, true);
          }
        },
        createInterpreterUsers: function (data, finish) {
          finish = finish || angular.noop;
          return function () {
            var scratchPad = {
              vendorIds: null,
              emailIds: null,
              isLoading: false,
              notCreatedEmails: [],
              error: null
            };
            var modal = openModal({
              controller: ['$scope', '$rootScope', '$state', 'staticParams', '$clientSwitcher', '$clientInterpreter', '$auth', '$q', '$clientInterpreterProfile', 'ngToast', 'appConfig',
                function ($scope, $rootScope, $state, staticParams, $clientSwitcher, $clientInterpreter, $auth, $q, $clientInterpreterProfile, ngToast, appConfig) {
                  $scope.scratchPad = scratchPad;
                  const client = $clientSwitcher.getCurrentClient();
                  $scope.currentClientId = client._id;
                  $scope.close = function (e) {
                    modal.close(e);
                  }
                  function init() {
                    const currentUser = $auth.getCurrentUser();

                    $scope.permissions = {
                      interpreterAddressView: currentUser.permissions.includes('INTERPRETER_ADDRESS_VIEW'),
                    }
                  }
                  $scope.buttonClick = function (e) {
                    // console.log(e)
                    $scope.scratchPad.isLoading = true;
                    $scope.scratchPad.notCreatedEmails = [];
                    $scope.scratchPad.error = null;

                    const emailIds = $scope.scratchPad.emailIds.split(',')
                    $clientInterpreter.createInterpreterUser(client, emailIds)
                      .then(res => {
                        console.warn(res)
                        if (res.status) {
                          if (res.notFound && res.notFound.length) {
                            const notFoundInterpreter = res.notFound.map(item => item).join(', ');
                            $scope.scratchPad.notCreatedEmails = notFoundInterpreter;
                            $scope.scratchPad.emailIds = notFoundInterpreter;
                            $scope.scratchPad.error = 'Interpreter Not Found With Provided Email ';
                          } else {
                            $scope.scratchPad.notCreatedEmails = [];
                            ngToast.create({
                              content: 'Interpreter User Created',
                              className: 'success'
                            });
                            finish.call(e, true);
                            modal.close(e);
                          }
                        } else {
                          $scope.scratchPad.error = res.message;
                        }
                      })
                      .catch(err => {
                        console.warn(err)
                      })
                      .finally(() => {
                        $scope.scratchPad.isLoading = false;
                      })
                  }
                  init();
                }],
              modal: {
                dismissable: false,
                title: 'Create Interpreter User',
                template: 'components/modal/tpl/create-interpreter-user.html'
              }
            }, 'modal-success', 'md', null, true);
          };
        }
      },
      myResourcesAction: {
        attachmentFile: function (record, finish) {
          finish = finish || angular.noop;

          return function () {
            const modal = openModal({
              controller: ['$scope', '$clientInterpreterResources', '$clientSwitcher', 'ngToast', '$q', '$uploadS3Attachment', function ($scope, $clientInterpreterResources, $clientSwitcher, ngToast, $q, $uploadS3Attachment) {
                const client = $clientSwitcher.getCurrentClient();

                $scope.model = {
                  type: MY_RESOURCES,
                  uploadType: 'FILE'
                }
                $scope.model.uploader = [];

                $scope.buttonClick = function (e) {
                  $scope.error = null;
                  if ($scope.model.uploader && $scope.model.uploader.queue && $scope.model.uploader.queue.length) {
                    $uploadS3Attachment.upload(client, $scope.model.uploader, $scope, 'control1')
                      .then((imageRes) => {
                        $scope.model.attachments = [];
                        if (imageRes && imageRes.length) {
                          imageRes.forEach(function (upload) {
                            if (upload.status) {
                              $scope.model.attachments.push(upload.clientUpload._id);
                            }
                          });
                          let obj = {
                            attachments: $scope.model.attachments,
                            type: $scope.model.type,
                            uploadType: $scope.model.uploadType,
                          }
                          if (record.parentFolderId) obj.parentFolder = record.parentFolderId;
                          return $clientInterpreterResources.myResourcesCreate(client, obj);
                        }
                      })
                      .then(function (data) {
                        if (data) {
                          finish.call(e, true);
                          modal.close(e);
                        }
                      });
                  } else {
                    ngToast.create({
                      content: 'Please select file',
                      className: 'danger'
                    });
                  }
                }

                $scope.close = function (e) {
                  finish.call(e, false);
                  modal.close(e);
                }
              }],
              modal: {
                dismissable: false,
                title: 'My Resources Attachment File',
                template: 'components/modal/tpl/knowledge-library-upload.html'
              }
            }, 'modal-success', null, true, true);
          }
        },
        createParentFolder: function (data, finish) {
          finish = finish || angular.noop;

          return function () {
            var scratchPad = {
              model: {
                name: null,
                type: MY_RESOURCES,
                uploadType: 'FOLDER'
              },
              isLoading: false,
              error: null
            };
            const modal = openModal({
              controller: ['$scope', '$clientInterpreterResources', '$clientSwitcher', 'ngToast', '$q', function ($scope, $clientInterpreterResources, $clientSwitcher, ngToast, $q) {
                const client = $clientSwitcher.getCurrentClient();
                $scope.scratchPad = scratchPad;

                $scope.buttonClick = function (e) {
                  $scope.scratchPad.error = null;
                  $scope.scratchPad.isLoading = true;
                  let obj ={
                    type: $scope.scratchPad.model.type,
                    uploadType: $scope.scratchPad.model.uploadType,
                    folderName: $scope.scratchPad.model.name,
                  }
                  if (data.parentFolderId) {
                    obj.parentFolder = data.parentFolderId;
                  }
                  $clientInterpreterResources.myResourcesCreate(client, obj)
                    .then(function (data) {
                      finish.call(e, true);
                      modal.close(e);
                    })
                    .catch((err) => {
                      let errMessage = err.message || err;
                      if (err && err.data && err.data.message) errMessage = err.data.message;
                      $scope.scratchPad.error = errMessage;
                    })
                    .finally(() => {
                      $scope.scratchPad.isLoading = false;
                    })
                }

                $scope.close = function (e) {
                  finish.call(e, false);
                  modal.close(e);
                }

              }],
              modal: {
                dismissable: true,
                title: 'Create Folder',
                template: 'components/modal/tpl/add-kl-parent-folder.html'
              }
            }, 'modal-success');
          }
        },
        updateParentFolder: function (data, finish) {
          finish = finish || angular.noop;

          return function () {
            var scratchPad = {
              model: {
                name: data.folderName || null,
                type: MY_RESOURCES,
                folderId: data._id
              },
              isLoading: false,
              error: null
            };
            const modal = openModal({
              controller: ['$scope', '$clientInterpreterResources', '$clientSwitcher', 'ngToast', '$q', function ($scope, $clientInterpreterResources, $clientSwitcher, ngToast, $q) {
                const client = $clientSwitcher.getCurrentClient();
                $scope.scratchPad = scratchPad;

                $scope.buttonClick = function (e) {
                  $scope.scratchPad.error = null;
                  $scope.scratchPad.isLoading = true;
                  $clientInterpreterResources.updateFolder(client, $scope.scratchPad.model)
                    .then(function (data) {
                      finish.call(e, true);
                      modal.close(e);
                    })
                    .catch((err) => {
                      let errMessage = err.message || err;
                      if (err && err.data && err.data.message) errMessage = err.data.message;
                      $scope.scratchPad.error = errMessage;
                    })
                    .finally(() => {
                      $scope.scratchPad.isLoading = false;
                    })
                }

                $scope.close = function (e) {
                  finish.call(e, false);
                  modal.close(e);
                }

              }],
              modal: {
                dismissable: true,
                title: 'Edit Folder',
                template: 'components/modal/tpl/add-kl-parent-folder.html'
              }
            }, 'modal-success');
          }
        }
      },
      fulfillmentOpsAction: {
        COINotes: function (COIObj, finish) {
          finish = finish || angular.noop;
          return function () {
            var scratchPad = {};
            var modal = openModal({
              controller: ['$scope', '$clientSwitcher', '$auth', '$q', 'ngToast', '$clientCOINotes', 'Modal',
              function ($scope, $clientSwitcher, $auth, $q, ngToast, $clientCOINotes, Modal) {
                $scope.scratchPad = scratchPad;
                $scope.record = COIObj
                $scope.COI = COIObj.coi;
                $scope.pageData = {
                  isLoading: false,
                  notes: [],
                  error: null
                }
                $scope.currentUser = $auth.getCurrentUser();
                const client = $clientSwitcher.getCurrentClient();
                $scope.currentClientId = client._id;
                $scope.getCOINotes = function() {
                  $scope.pageData.isLoading = true;
                  $scope.pageData.error = null;
                  $clientCOINotes.getCOINotes(client, $scope.COI)
                  .then(res => {
                    if (res.status) {
                      this.pageData.notes = res.data;
                      $scope.record.isNotes = !!res.data.length;
                    }
                    $scope.pageData.isLoading = false;
                  }, err => {
                    this.pageData.error = err.message || 'somethingwent wrong! try after sometime';
                    $scope.pageData.isLoading = false;
                    $scope.showImageLoader = false;
                  })
                }

                $scope.submit = function(e) {
                  $scope.pageData.isLoading = true;
                  $scope.pageData.error = null;
                  $clientCOINotes.create(client, {coi: $scope.COI,notes: $scope.scratchPad.notes})
                  .then(res => {
                    $scope.pageData.isLoading = false;
                    if (res.status) {
                      $scope.scratchPad.notes = null;
                      $scope.getCOINotes();
                    } else {
                      $scope.pageData.error = res.message || 'somethingwent wrong! try after sometime';
                    }
                  }, err => {
                    this.pageData.error = err.message || 'somethingwent wrong! try after sometime';
                    $scope.pageData.isLoading = false;
                  })
                }

                $scope.editNote = function(i, note) {
                  $scope.editModeIndex = i;
                  $scope.scratchPad.editMode = true;
                  $scope.scratchPad.notes = note.notes;
                  $scope.scratchPad._id = note._id;
                }

                $scope.updateNote = function(e) {
                  $scope.pageData.isLoading = true;
                  $scope.pageData.error = null;
                  $clientCOINotes.update(client, $scope.scratchPad)
                  .then(res => {
                    $scope.pageData.isLoading = false;
                    if (res.status) {
                      $scope.scratchPad.editMode = false;
                      $scope.scratchPad.notes = null;
                      $scope.editModeIndex = null;
                      $scope.getCOINotes();
                    } else {
                      $scope.pageData.error = res.message || 'somethingwent wrong! try after sometime';
                    }
                  }, err => {
                    this.pageData.error = err.message || 'somethingwent wrong! try after sometime';
                    $scope.pageData.isLoading = false;
                  })
                }
                $scope.removeNote = function(note) {
                  Modal.confirm.show(
                    "Confirm Delete",
                    "Are you sure you want to delete <strong> Note</strong>?",
                    "Delete", "Cancel",
                    function () {
                      $clientCOINotes.delete(client, note)
                      .then(res => {
                        $scope.pageData.isLoading = false;
                        if (res.status) {
                          $scope.getCOINotes();
                        } else {
                          $scope.pageData.error = res.message || 'somethingwent wrong! try after sometime';
                        }
                      }, err => {
                        this.pageData.error = err.message || 'somethingwent wrong! try after sometime';
                        $scope.pageData.isLoading = false;
                      })
                    });
                }
                function init() {
                  $scope.getCOINotes();
                  $scope.permissions = {
                    createCOINotes: $scope.currentUser.permissions.includes('COI_NOTES_CREATE'),
                    editCOINotes: $scope.currentUser.permissions.includes('COI_NOTES_UPDATE'),
                    deleteCOINotes: $scope.currentUser.permissions.includes('COI_NOTES_DELETE'),
                  };
                }
                init();
              }],
              modal: {
                dismissable: true,
                title: `COI ${COIObj.coi} | Notes`,
                template: 'components/modal/tpl/coi-notes.html',
              }
            }, 'modal-success', 'md');
          };
        },
      },
      openWorkOrders: {
        moreOpenWO: function (selectedDateWO, date, autoRefresh, finish) {
          finish = finish || angular.noop;

          return function () {
            var scratchPad = {
              selectedDateWO: JSON.parse(JSON.stringify(selectedDateWO)),
              selectedDateWOForFilter: JSON.parse(JSON.stringify(selectedDateWO)),
              isLoading: false,
              error: null,
              orderByField: '',
              orderType: 'ASC'
            };
            const modal = openModal({
              controller: ['$scope', '$clientSwitcher', 'ngToast', '$auth', '$timezones', '$openWorkOrders', 'Modal', function ($scope, $clientSwitcher, ngToast, $auth, $timezones, $openWorkOrders, Modal) {
                const client = $clientSwitcher.getCurrentClient();
                const currentUser = $auth.getCurrentUser();
                const isInterpreterRole = $auth.isInterpreter() || false;
                $scope.isInterpreterRole = isInterpreterRole;
                $(".modal-dialog").addClass("modal-xl");
                $scope.scratchPad = scratchPad;
                $scope.pagination = {
                  currentPage: 1,
                  page: 1,
                  total: 0,
                  pageLimit: 10,
                  itemsPerPage: 10
                };
                $scope.sorting = {};
                $scope.isLoading = { };
                $scope.allValue = 'all';
                $scope.filter = {
                  reportingcourt: [],
                  hrgloc: [],
                  sertype: [],
                  hearingtype: [],
                  langname: [],
                  ijname: [],
                  status: [],
                  travel: []
                }
                $scope.reportingCourtDataSets = [];
                $scope.hrglocDataSets = [];
                $scope.sertypeDataSets = [];
                $scope.hearingTypeDataSets = [];
                $scope.langnameDataSets = [];
                $scope.judgeDataSets = [];
                $scope.statusDataSets = [];
                $scope.travelDataSets = [];

                $scope.pagination.total = $scope.scratchPad.selectedDateWOForFilter.length;
                $scope.permissions = {
                  accept: currentUser && currentUser.permissions && currentUser.permissions.length && currentUser.permissions.includes('INTERPRETER_RESOURCES_OPEN_WORK_ORDERS_ACCEPT') || false,
                  assign: currentUser && currentUser.permissions && currentUser.permissions.length && currentUser.permissions.includes('INTERPRETER_RESOURCES_OPEN_WORK_ORDERS_ASSIGN') || false,
                  withdraw: currentUser && currentUser.permissions && currentUser.permissions.length && currentUser.permissions.includes('INTERPRETER_RESOURCES_OPEN_WORK_ORDERS_WITHDRAW') || false
                }
                function paginator() {
                  $scope.begin = (($scope.pagination.currentPage - 1) * $scope.pagination.itemsPerPage);
                  $scope.end = $scope.begin + $scope.pagination.itemsPerPage;
                  $scope.scratchPad.selectedDateWO = $scope.scratchPad.selectedDateWOForFilter.slice($scope.begin, $scope.end);
                }
                paginator();

                $scope.loadData = function () {
                  $scope.begin = (($scope.pagination.page - 1) * $scope.pagination.itemsPerPage);
                  $scope.end = $scope.begin + $scope.pagination.itemsPerPage;
                  $scope.scratchPad.selectedDateWO = $scope.scratchPad.selectedDateWOForFilter.slice($scope.begin, $scope.end);
                }

                $scope.travel = (str) => {
                  if (str) {
                    return 'Yes';
                  }
                  return 'No';
                }
                $scope.delDateFormat = (delDate) => {
                  return moment(delDate).format('MM/DD/YYYY')
                }

                $scope.schedValue = function (record) {
                  if (record && record.sched) {
                    var momentObj = moment(record.sched, ["h:mm A"])
                    return momentObj.format("hh:mm A");
                  } else {
                    return 'N/A';
                  }
                }

                $scope.setWOTimezone = function (record) {
                  if (record && record.timezonename) {
                    var timeZone = _.find($timezones, function (timezone) {
                      return record.timezonename === timezone.label;
                    });
                    return timeZone && timeZone.value || 'N/A';
                  } else {
                    return 'N/A';
                  }
                }

                $scope.displayAction = function (wo, status) {
                  let findIndex = _.findIndex(wo.performOpenWO, (item) => ((item.userId._id || item.userId) == currentUser._id));
                  let AssignObj = _.find(wo.performOpenWO, (obj) => obj.status == 'ASSIGN');
                  let WithdrawObj = _.filter(wo.performOpenWO, (obj) => obj.status == 'WITHDRAW');
                  if(!isInterpreterRole){
                    wo.withdrawObj = WithdrawObj;
                  }
                  if (status == 'ASSIGN') {
                    let assignFindIndex = _.findIndex(wo.performOpenWO, (item) => item.status != 'ASSIGN' && item.status != 'WITHDRAW' && item.status != 'REJECT');
                    if (wo.performOpenWO && wo.performOpenWO.length && assignFindIndex >= 0) {
                      if (wo.performOpenWO[assignFindIndex]) {
                        wo.assignedUser = wo.performOpenWO[assignFindIndex];
                      }
                      return true;
                    }
                  } else if (status == 'ACCEPT') {
                    if (findIndex < 0 && !AssignObj) return true;
                  } else if (status == 'WITHDRAW') {
                    if (findIndex >= 0 && wo.performOpenWO[findIndex].status != 'WITHDRAW' && wo.performOpenWO[findIndex].status != 'ASSIGN' && wo.performOpenWO[findIndex].status != 'REJECT') return true;
                    if (findIndex >= 0 && wo.performOpenWO[findIndex] && wo.performOpenWO[findIndex].withDrawComments) {
                      wo.withDrawComments = wo.performOpenWO[findIndex].withDrawComments;
                      wo.withdrawObj = [wo.performOpenWO[findIndex]];
                    }
                  } else if (AssignObj) {
                    wo.assignedUser = AssignObj;
                    return true;
                  }
                  return false;
                }

                $scope.woAccept = function (wo) {
                  if (!isInterpreterRole) {
                    ngToast.create({
                      content: 'Performed user is not an interpreter!',
                      className: 'danger'
                    });
                    return;
                  }
                  $scope.isLoading[`accept_${wo.coi}`] = true;
                  let obj = {
                    coi: wo.coi,
                    replocId: wo.replocid,
                    status: 'ACCEPT'
                  }
                  $openWorkOrders.create(client, obj)
                    .then((res) => {
                      if (res && res.status) {
                        wo.performOpenWO = res.createRecord;
                        ngToast.create({
                          content: 'Work Order Accept Successfully',
                          className: 'success'
                        });
                        autoRefresh();
                      } else {
                        ngToast.create({
                          content: res.message,
                          className: 'danger'
                        });
                      }

                    })
                    .catch((err) => {
                      let errMessage = err.message || err;
                      if (err && err.data && err.data.message) errMessage = err.data.message;
                      ngToast.create({
                        content: errMessage,
                        className: 'danger'
                      });
                    })
                    .finally(() => {
                      $scope.isLoading[`accept_${wo.coi}`] = false;
                    })
                }

                $scope.woStatusUpdate = function (wo, status, acceptedWO) {
                  let obj = {
                    openWOId: wo.performOpenWO._id,
                    coi: wo.coi,
                    status: status.toUpperCase()
                  }
                  if (acceptedWO && acceptedWO._id) {
                    obj.openWOId = acceptedWO._id;
                  } else {
                    let findIndex = _.findIndex(wo.performOpenWO, (item) => ((item.userId._id || item.userId) == currentUser._id));
                    if (findIndex >= 0) {
                      obj.openWOId = wo.performOpenWO[findIndex]._id;
                    }
                  }
                  if (wo.withDrawComments) {
                    obj.withDrawComments = wo.withDrawComments;
                  }
                  $openWorkOrders.update(client, obj)
                    .then((res) => {
                      if (res && res.status) {
                        wo.performOpenWO = res.updatedOpenWO;
                        ngToast.create({
                          content: `Work Order ${status.toLowerCase()} Successfully`,
                          className: 'success'
                        });
                        autoRefresh();
                      } else {
                        ngToast.create({
                          content: response.message,
                          className: 'danger'
                        });
                      }

                    })
                    .catch((err) => {
                      let errMessage = err.message || err;
                      if (err && err.data && err.data.message) errMessage = err.data.message;
                      ngToast.create({
                        content: errMessage,
                        className: 'danger'
                      });
                    })
                }

                $scope.assignedWOWithdraw = function(wo, status){
                  let obj = {
                    coi: wo.coi,
                    status: status.toUpperCase()
                  }
                  let findIndex = _.findIndex(wo.performOpenWO, (item) => item.status == 'ASSIGN');
                  if (findIndex >= 0) {
                    obj.openWOId = wo.performOpenWO[findIndex]._id;
                  }
                  $openWorkOrders.assignedWOWithdraw(client, obj)
                    .then((res) => {
                      if (res && res.status) {
                        wo.performOpenWO = res.updatedOpenWO;
                        ngToast.create({
                          content: `Work Order ${status.toLowerCase()} Successfully`,
                          className: 'success'
                        });
                        autoRefresh();
                      } else {
                        ngToast.create({
                          content: response.message,
                          className: 'danger'
                        });
                      }

                    })
                    .catch((err) => {
                      let errMessage = err.message || err;
                      if (err && err.data && err.data.message) errMessage = err.data.message;
                      ngToast.create({
                        content: errMessage,
                        className: 'danger'
                      });
                    })
                }

                $scope.appliedCount = function (wo) {
                  let appliedCount = _.filter(wo.performOpenWO, (item) => item.status == 'ACCEPT');
                  return appliedCount.length;
                }

                $scope.woAssign = function (wo) {
                  let acceptWO = _.filter(wo.performOpenWO, (item) => item.status == 'ACCEPT');
                  Modal.openWorkOrders.acceptWOInterpreter(acceptWO, wo.interpreterApplied,function (acceptedWO, status) {
                    $scope.woStatusUpdate(wo, status || 'ASSIGN', acceptedWO);
                  })()
                }

                $scope.woWithdraw = function (wo) {
                  Modal.openWorkOrders.withdrawConfirmation(function (res) {
                    if(res){
                      wo.withDrawComments = res.comments || null;
                      $scope.woStatusUpdate(wo, 'WITHDRAW');
                    }
                  })()
                }

                $scope.woInterpretersComment = function (wo) {
                  Modal.openWorkOrders.woInterpretersComment(wo, function (res) {
                  })()
                }

                $scope.sortRecord = (fieldName, e) => {
                  if ($scope.scratchPad.orderByField == fieldName && $scope.scratchPad.orderType == 'desc') {
                    $scope.scratchPad.orderType = 'asc';
                  } else {
                    $scope.scratchPad.orderType = 'desc'
                  }
                  $scope.sorting = {
                    [fieldName]: $scope.scratchPad.orderType == 'asc' ? false : true
                  }
                  $scope.scratchPad.orderByField = fieldName;
                  $scope.scratchPad.selectedDateWOForFilter = _.sortBy($scope.scratchPad.selectedDateWOForFilter, [fieldName])
                  if ($scope.scratchPad.orderType == 'desc') {
                    $scope.scratchPad.selectedDateWOForFilter = $scope.scratchPad.selectedDateWOForFilter.reverse()
                  }
                  paginator();
                }

                function setFilterDetails() {
                  let datasetArr = {
                    reportingcourt: [],
                    hrgloc: [],
                    sertype: [],
                    hearingtype: [],
                    langname: [],
                    ijname: [],
                    status: [],
                    travel: []
                  }

                  selectedDateWO.forEach((item) => {
                    datasetArr.reportingcourt.push({
                      label: item.reportingcourt,
                      value: item.reportingcourt
                    })
                    datasetArr.hrgloc.push({
                      label: item.hrgloc,
                      value: item.hrgloc
                    })
                    datasetArr.sertype.push({
                      label: item.sertype,
                      value: item.sertype
                    })
                    datasetArr.hearingtype.push({
                      label: item.hearingtype,
                      value: item.hearingtype
                    })
                    datasetArr.hearingtype.push({
                      label: item.hearingtype,
                      value: item.hearingtype
                    })
                    datasetArr.ijname.push({
                      label: item.ijname,
                      value: item.ijname
                    })
                    datasetArr.status.push({
                      label: item.status,
                      value: item.status
                    })
                    datasetArr.travel.push({
                      label: item.travel ? 'Yes' : 'No',
                      value: item.travel ? 'Yes' : 'No'
                    })
                  })

                  $scope.reportingCourtDataSets = _.sortBy(_.uniq(datasetArr.reportingcourt, 'label'), ['label']);
                  $scope.hrglocDataSets = _.sortBy(_.uniq(datasetArr.hrgloc, 'label'), ['label']);
                  $scope.sertypeDataSets = _.sortBy(_.uniq(datasetArr.sertype, 'label'), ['label']);
                  $scope.hearingTypeDataSets = _.sortBy(_.uniq(datasetArr.hearingtype, 'label'), ['label']);
                  $scope.langnameDataSets = _.sortBy(_.uniq(datasetArr.langname, 'label'), ['label']);
                  $scope.judgeDataSets = _.sortBy(_.uniq(datasetArr.ijname, 'label'), ['label']);
                  $scope.statusDataSets = _.sortBy(_.uniq(datasetArr.status, 'label'), ['label']);
                  $scope.travelDataSets = _.sortBy(_.uniq(datasetArr.travel, 'label'), ['label']);
                }
                setFilterDetails();

                $scope.dataBasedOnFilter = (key) => {
                  let filterData = selectedDateWO;
                  if ($scope.filter.reportingcourt && $scope.filter.reportingcourt.length && $scope.filter.reportingcourt != 'all') {
                    filterData = _.filter(filterData, (item) => {
                      return $scope.filter.reportingcourt.indexOf(item.reportingcourt) >= 0;
                    })
                  }
                  if ($scope.filter.hrgloc && $scope.filter.hrgloc.length && $scope.filter.hrgloc != 'all') {
                    filterData = _.filter(filterData, (item) => {
                      return $scope.filter.hrgloc.indexOf(item.hrgloc) >= 0;
                    })
                  }
                  if ($scope.filter.sertype && $scope.filter.sertype.length && $scope.filter.sertype != 'all') {
                    filterData = _.filter(filterData, (item) => {
                      return $scope.filter.sertype.indexOf(item.sertype) >= 0;
                    })
                  }
                  if ($scope.filter.hearingtype && $scope.filter.hearingtype.length && $scope.filter.hearingtype != 'all') {
                    filterData = _.filter(filterData, (item) => {
                      return $scope.filter.hearingtype.indexOf(item.hearingtype) >= 0;
                    })
                  }
                  if ($scope.filter.langname && $scope.filter.langname.length && $scope.filter.langname != 'all') {
                    filterData = _.filter(filterData, (item) => {
                      return $scope.filter.langname.indexOf(item.langname) >= 0;
                    })
                  }
                  if ($scope.filter.ijname && $scope.filter.ijname.length && $scope.filter.ijname != 'all') {
                    filterData = _.filter(filterData, (item) => {
                      return $scope.filter.ijname.indexOf(item.ijname) >= 0;
                    })
                  }
                  if ($scope.filter.status && $scope.filter.status.length && $scope.filter.status != 'all') {
                    filterData = _.filter(filterData, (item) => {
                      return $scope.filter.status.indexOf(item.status) >= 0;
                    })
                  }
                  if ($scope.filter.travel && $scope.filter.travel.length && $scope.filter.travel != 'all') {
                    filterData = _.filter(filterData, (item) => {
                      return $scope.filter.travel.indexOf(item.travel ? 'Yes' : 'No') >= 0;
                    })
                  }

                  if (filterData) {
                    $scope.scratchPad.selectedDateWOForFilter = filterData;
                    $scope.pagination.total = $scope.scratchPad.selectedDateWOForFilter.length;
                    $scope.pagination.page = 1;
                    paginator();
                  }
                }

              }],
              modal: {
                dismissable: true,
                title: `Work Order (${selectedDateWO.length}) - ${date}`,
                template: 'components/modal/tpl/more-open-work-order.html',
                buttons: [{
                  classes: 'btn-default',
                  text: 'Close',
                  click: function (e) {
                    modal.dismiss(e);
                  }
                }]
              }
            }, 'modal-success', 'lg');
          }
        },
        acceptWOInterpreter: function (wo, appliedInterpreters, finish) {
          finish = finish || angular.noop;

          return function () {
            var scratchPad = {
              acceptWO: wo.acceptWO,
              wo: wo,
              isLoading: false,
              error: null
            };
            const modal = openModal({
              controller: ['$scope', '$clientSwitcher', 'ngToast', '$q', 'Modal', '$clientInterpreter', function ($scope, $clientSwitcher, ngToast, $q, Modal,  $clientInterpreter) {
                const client = $clientSwitcher.getCurrentClient();
                $scope.scratchPad = scratchPad;
                $scope.deleteInterpreterUser = [];
                $scope.deletedCounter = 0
                if (appliedInterpreters && appliedInterpreters.length && $scope.scratchPad && $scope.scratchPad.acceptWO && $scope.scratchPad.acceptWO.length) {
                  let deletedInterpreterId = [];
                  let performedWOInterpretersIdes = [];
                  $scope.scratchPad.acceptWO.map(acceptWOItem => {
                    if (acceptWOItem.userId && acceptWOItem.userId.vendorid) {
                      performedWOInterpretersIdes.push( acceptWOItem.userId.vendorid)
                    }
                  });
                  // console.log('====', performedWOInterpretersIdes, appliedInterpreters, _.difference(appliedInterpreters, performedWOInterpretersIdes, ))
                  if (appliedInterpreters && appliedInterpreters.length) {
                    let deletedICs = _.difference(appliedInterpreters, performedWOInterpretersIdes)
                    if (deletedICs && deletedICs.length) {
                      $scope.scratchPad.isLoading = true;
                      $clientInterpreter.getInterpretersByVendorId(client, { vendorIds: deletedICs, userIds: null })
                      .then((res) => {
                        if (res.status && res.result.length) {
                          let counter =0
                          $scope.scratchPad.acceptWO.map(item => {
                            if (item && !item.userId) {
                              item.interpreterDetail =  res.result[counter];
                              counter++;
                            }
                          })
                          $scope.deleteInterpreterUser = res.result; //_.groupBy(res.result, 'vendorid');

                        }
                        $scope.scratchPad.isLoading = false;
                      }, err => {
                        console.log(err)
                      }, () => {
                        $scope.scratchPad.isLoading = false;
                      })
                    }
                  }
                }

                // $scope.getDeletedInterpreters= function() {
                //   $scope.deletedCounter++;
                //   console.log($scope.deletedCounter)
                //   let ic = $scope.deleteInterpreterUser[$scope.deletedCounter - 1];
                //   if (ic) {
                //     ic.vendorid + ' | '+ ic.firstname + ' ' + ic.middlename + ' ' + ic.lastname;
                //   }
                //   return '';
                // }
                $scope.buttonClick = function (e, acceptedWO, status) {
                  finish.call(e, acceptedWO, status);
                  modal.close(e);
                }

                $scope.interpreterProfile = function (vendorid) {
                  if (!vendorid) return;
                  modal.dismiss('dismiss');
                  Modal.interpreterProfileActions.interpreterProfile(vendorid, function (res) {
                      // console.log(res)
                  })();
                }

                $scope.openAssignModal = function(acceptWO) {
                  Modal.openWorkOrders.openWOAssignInterpreterDialog($scope.scratchPad.wo, function (res) {
                    finish.call(res);
                    modal.dismiss('dismiss');
                  })();
                }

                $scope.openConfirmModal = function(acceptWO) {
                  Modal.openWorkOrders.openWOConfirmInterpreter($scope.scratchPad.wo, function (res) {
                    finish.call(res);
                    modal.dismiss('dismiss');
                  })();
                }

              }],
              modal: {
                dismissable: true,
                title: 'Interpreters Applied',
                template: 'components/modal/tpl/accepted-interpreter.html',
                buttons: [{
                  classes: 'btn-default',
                  text: 'Cancel',
                  click: function (e) {
                    modal.dismiss(e);
                  }
                }]
              }
            }, 'modal-success', 'lg');
          }
        },
        withdrawConfirmation: function (finish) {
          finish = finish || angular.noop;

          return function () {
            var scratchPad = {
              comments: null,
              isLoading: false,
              error: null
            };
            const modal = openModal({
              controller: ['$scope', '$clientSwitcher', 'ngToast', '$q', function ($scope, $clientSwitcher, ngToast, $q) {
                const client = $clientSwitcher.getCurrentClient();
                $scope.scratchPad = scratchPad;
                $scope.buttonClick = function (e) {
                  finish.call(e, $scope.scratchPad);
                  modal.close(e);
                }

                $scope.close = function (e) {
                  finish.call(e, false);
                  modal.close(e);
                }

              }],
              modal: {
                dismissable: true,
                title: 'Withdraw Confirmation',
                template: 'components/modal/tpl/withdraw-confirmation.html'
              }
            }, 'modal-success');
          }
        },
        woInterpretersComment: function (wo, finish) {
          finish = finish || angular.noop;

          return function () {
            var scratchPad = {
              withdrawObj: wo.withdrawObj,
              isLoading: false,
              error: null
            };
            const modal = openModal({
              controller: ['$scope', '$clientSwitcher', 'ngToast', '$q', function ($scope, $clientSwitcher, ngToast, $q) {
                const client = $clientSwitcher.getCurrentClient();
                $scope.scratchPad = scratchPad;
              }],
              modal: {
                dismissable: true,
                title: 'Interpreters Comment',
                template: 'components/modal/tpl/wo-interpreters-comment.html',
                buttons: [{
                  classes: 'btn-default',
                  text: 'Cancel',
                  click: function (e) {
                    modal.dismiss(e);
                  }
                }]
              }
            }, 'modal-success', 'lg');
          }
        },
        openWOAssignInterpreter: function (wo, finish) {
          finish = finish || angular.noop;
          return function () {
            let title = `${wo.coi} | ${wo.langname} | ${wo.sertype} | ${wo.replocid}`
            var scratchPad = {
              wo:wo,
              isLoading: false,
              error: null,
            };
            const modal = openModal({
              controller: ['$scope', '$clientSwitcher', 'ngToast', '$openWorkOrders', function ($scope, $clientSwitcher, ngToast, $openWorkOrders) {
                const client = $clientSwitcher.getCurrentClient();
                $scope.scratchPad = scratchPad;
                $scope.allValue = 'all';
                $scope.pageLimitForDD = 20;
                $scope.interpretersSets = [];
                $scope.interpreterData = [];
                $scope.interpreterDataCount = 0;
                $scope.assignedInterpreter = [];
                let isMoreData = true;
                $scope.reloadInterpreters = function (filter) {
                  if (!$scope.readOnly) {
                    if (!filter) {
                      $scope.interpreterData = [];
                      filter = {
                        page: 1,
                        pageSize: $scope.pageLimitForDD,
                        searchKey: null,
                      }
                    }
                    filter.isActive = true;
                    filter.lang = $scope.scratchPad.wo.langcode;
                    filter.replocid = $scope.scratchPad.wo.replocid;
                    filter.isDistance = false;
                    filter.coi = wo.coi;
                    $scope.scratchPad.isLoading = true;

                    $openWorkOrders.interpreterListOfferingRule(client, filter)
                      .then((res) => {
                        if (isMoreData) {
                          isMoreData = res.isMoreData;
                        }
                        if (res.status && res.records && res.records.length) {
                          $scope.interpreterData = ($scope.interpreterData && $scope.interpreterData.length) ?
                            _.uniq($scope.interpreterData.concat(res.records), 'value')
                            : res.records;
                          $scope.interpreterData = _.sortBy($scope.interpreterData, ['name', 'distance'], ['asc', 'asc'])
                          $scope.interpreterDataCount = res.totalCount;
                        } else {
                          // if (!isMoreData && !res.isMoreData) {
                          //   ngToast.create({
                          //     content: 'Interpreter Not Found.',
                          //     className: 'danger'
                          //   });
                          //   $scope.interpreterDataCount = 0;
                          // }
                        }
                        // if (isMoreData) {
                        //   $scope.ddLoading = true;
                        //   let filterObj = {
                        //     page: filter.page + 1,
                        //     pageSize: $scope.pageLimitForDD
                        //   }
                        //   $scope.reloadInterpreters(filterObj);
                        // }
                      })
                      .catch((err) => {
                        let errMessage = err.message || err;
                        if (err && err.data && err.data.message) {
                          errMessage = err.data.message;
                        } else if (err && err.data && err.data.reason) {
                          errMessage = err.data.reason;
                        }
                        console.log(errMessage);
                        ngToast.create({
                          content: errMessage,
                          className: 'danger'
                        });
                        $scope.interpreterDataCount = 0;
                      })
                      .finally(function () {
                        $scope.ddLoading = false;
                        $scope.scratchPad.isLoading = false;
                      })
                  }
                }

                $scope.loadPaginateData = function (searchKey, page, type) {
                  $scope.ddLoading = true;
                  let filter = {
                    page: page,
                    pageSize: $scope.pageLimitForDD
                  }
                  switch (type) {
                    case 'interpreters':
                      if (page == 1) {
                        $scope.interpreterData = [];
                      }
                      filter.searchKey = (searchKey).toLowerCase();
                      $scope.reloadInterpreters(filter);
                      break;
                    default:
                      break;
                  }
                }

                $scope.buttonClick = function (e) {
                  isMoreData = false;
                  $scope.scratchPad.isLoading = true;
                  let data = {
                    vendorId: $scope.scratchPad.interpreter,
                    coi: wo.coi,
                    status: 'ASSIGN'
                  };
                  $openWorkOrders.openWOAssignInterpreter(client, data)
                    .then((res) => {
                      if (res && res.status) {
                        ngToast.create({
                          content: res.message || res,
                          className: 'success'
                        });
                        finish.call(e, res);
                        modal.close(e);
                      } else {
                        ngToast.create({
                          content: res.message || res,
                          className: 'danger'
                        });
                      }
                    })
                    .catch(err => {
                      let errMessage = err.message || err;
                      if (err && err.data && err.data.message) {
                        errMessage = err.data.message;
                      } else if (err && err.data && err.data.reason) {
                        errMessage = err.data.reason;
                      }
                      ngToast.create({
                        content: errMessage,
                        className: 'danger'
                      });
                    })
                    .finally(() => {
                      $scope.scratchPad.isLoading = false;
                    })
                }

                $scope.close = function (e) {
                  isMoreData = false;
                  finish.call(e, false);
                  modal.close(e);
                }

                function init() {
                  if (wo && wo.vendorid) {
                    $scope.scratchPad.assignedInterpreter = wo.vendorid;
                    $scope.assignedInterpreter.push({
                      label: wo.interprername,
                      value: wo.vendorid,
                      vendorid: wo.vendorid
                    })
                  }
                  $scope.reloadInterpreters();
                }
                init();
              }],
              modal: {
                dismissable: false,
                title: title ? `Assign Interpreter ${title}` : 'Assign Interpreter' ,
                template: 'components/modal/tpl/open-wo-assign-interpreter.html',
              }
            }, 'modal-success', 'md', null, true);
          }
        },
        openWOAssignInterpreterDialog: function (wo, finish) {
          finish = finish || angular.noop;
          return function () {
            let title = `${wo.coi} | ${wo.langname} | ${wo.sertype} | ${wo.replocid}`
            var scratchPad = {
              wo: wo,
              isLoading: false,
              error: null,
              interpreterType: 'homecourt',
              interpreterHomeCourtCount: 0,
              interpreterHomeCourtData: [],
              interpreterHomeCourtLoading: false,
              interpreterDistanceLoading: false,
              interpreterFTIDistanceLoading: false,
              interpreterAplliedCourtData: []
            };
            const modal = openModal({
              controller: ['$scope', '$clientSwitcher', 'ngToast', '$openWorkOrders', '$clientInterpreter', function ($scope, $clientSwitcher, ngToast, $openWorkOrders, $clientInterpreter) {
                const client = $clientSwitcher.getCurrentClient();
                $scope.scratchPad = scratchPad;
                $scope.allValue = 'all';
                $scope.pageLimitForDD = 20;
                // let applied = _.filter(wo.performOpenWO, (item) => item.status == 'ACCEPT')
                // console.log(applied)
                $scope.interpreterAplliedCourtData = [];
                if (wo.performOpenWO && wo.performOpenWO.length) {
                  wo.performOpenWO.forEach(item => {
                    if (item.status && item.status == 'ACCEPT') {
                      $scope.scratchPad.interpreterAplliedCourtData.push({
                        label: `${item.userId.interpreterFullName} | ${item.userId.vendorid}`,
                        value: item.userId.vendorid
                      })
                    }
                  });
                  if ($scope.scratchPad.interpreterAplliedCourtData && $scope.scratchPad.interpreterAplliedCourtData.length) {
                    scratchPad.interpreterType = 'interpreterApllied';
                    $scope.scratchPad.interpreter = $scope.scratchPad.interpreterAplliedCourtData[0].value
                  }
                }
                $scope.interpreterDistanceDataCount = 0;
                $scope.interpreterDistanceData = [];

                $scope.interpreterFTIDataCount = 0;
                $scope.interpreterFTIData = [];

                let isMoreData = true;
                // let selectedInterpreterType = $scope.scratchPad.interpreterType;

                $scope.resetFTIFilter = () => {
                  $scope.scratchPad.allowHomeFTI = false;
                   $scope.scratchPad.allowDistanceFTI = false;
                }
                $scope.interpreterTypeChange = (e, type) => {
                  // e.preventDefault();
                  $scope.interpreterData = [];
                  $scope.scratchPad.interpreter = null;
                  let filter = {
                    page: 1,
                    pageSize: $scope.pageLimitForDD,
                    searchKey: '',
                    isActive: true,
                    lang: $scope.scratchPad.wo.langcode,
                    court: $scope.scratchPad.wo.replocid,
                    courtZipCode: $scope.scratchPad.wo.zip,
                    isDistance: false,
                    coi: wo.coi,
                    allowFTI : $scope.scratchPad.allowHomeFTI || $scope.scratchPad.allowDistanceFTI
                  }

                  switch ($scope.scratchPad.interpreterType) {
                    case 'homecourt':
                      $scope.loadHomeCourtInterpreter(filter)
                      break;

                    case 'notHomecourt':
                      $scope.loadRTWCourtNearestiInterpreter(filter)
                      break;

                    case 'fti':
                      $scope.loadFTICourtNearestiInterpreter(filter)
                      break;

                    case 'RTWLang':
                      $scope.loadRTWLangInterpreter(filter)
                      break;

                    case 'interpreterApllied':
                      // console.log('interpreterApllied')
                      // $scope.loadRTWLangInterpreter(filter)
                      break;
                    default:
                      break;
                  }
                }
                $scope.reloadInterpreters = function (filter) {
                  if (!$scope.readOnly) {
                    if (!filter) {
                      $scope.interpreterData = [];
                      filter = {
                        page: 1,
                        pageSize: $scope.pageLimitForDD,
                        searchKey: '',
                      }
                    }
                    filter.isActive = true;
                    filter.lang = $scope.scratchPad.wo.langcode;
                    filter.court = $scope.scratchPad.wo.replocid;
                    filter.isDistance = false;
                    filter.coi = wo.coi;
                    $scope.scratchPad.isLoading = false;

                    $scope.loadHomeCourtInterpreter(filter)
                  }
                }

                $scope.loadHomeCourtInterpreter = (filter) => {
                  $scope.scratchPad.interpreterHomeCourtLoading = true;
                  $clientInterpreter.getHomeCourtInterpreter(client, filter).then(res => {
                    if (res && res.status) {
                      if (filter.page > 1) {
                        let interpreterHomeCourData = res.result.map(item => {
                          let distance = item.distances && item.distances.distance && item.distances.distance >= 0 ? item.distances.distance : 0
                          return {
                            label: item.distances && item.distances.distance ? `${item.label} | ${distance} Miles` : item.label,
                            value: item.vendorid
                          }
                        });
                        $scope.scratchPad.interpreterHomeCourtData = $scope.scratchPad.interpreterHomeCourData.concat(interpreterHomeCourData)
                      } else {
                        $scope.scratchPad.interpreterHomeCourtCount = res.total;
                        $scope.scratchPad.interpreterHomeCourtData = res.result.map(item => {
                          let distance = item.distances && item.distances.distance && item.distances.distance >= 0 ? item.distances.distance : 0
                          return {
                            label: item.distances && item.distances.distance ? `${item.label} | ${distance} Miles` : item.label,
                            value: item.vendorid
                          }
                        });
                      }
                    }
                    $scope.scratchPad.interpreterHomeCourtLoading = false;
                  })
                    .catch(err => {
                      console.log(err)
                      $scope.scratchPad.interpreterHomeCourtLoading = false;
                    })
                    .finally(() => {

                    })

                }

                $scope.loadRTWCourtNearestiInterpreter = (filter) => {
                  filter.langName = $scope.scratchPad.wo.langname;
                  filter.serviceType = $scope.scratchPad.wo.sertype;

                  $scope.scratchPad.interpreterDistanceLoading = true;
                  $clientInterpreter.getRTWMatchCourtInterpreter(client, filter).then(res => {
                    if (res && res.status) {
                      if (filter.page > 1) {
                        let interpreterHomeCourData = res.result.map(item => {
                          let distance = item.distances && item.distances.distance && item.distances.distance >= 0 ? item.distances.distance : 0
                          return {
                            label: item.distances && item.distances.distance ? `${item.label} | ${distance} Miles` : item.label,
                            value: item.vendorid
                          }
                        });
                        $scope.scratchPad.interpreterDistanceData = $scope.scratchPad.interpreterDistanceData.concat(interpreterHomeCourData)
                      } else {
                        $scope.scratchPad.interpreterDistanceDataCount = res.total;
                        $scope.scratchPad.interpreterDistanceData = res.result.map(item => {
                          let distance = item.distances && item.distances.distance && item.distances.distance >= 0 ? item.distances.distance : 0
                          return {
                            label: item.distances && item.distances.distance ? `${item.label} | ${distance} Miles` : item.label,
                            value: item.vendorid
                          }
                        });
                      }
                    }
                    $scope.scratchPad.interpreterDistanceLoading = false;
                  })
                    .catch(err => {
                      console.log(err)
                      $scope.scratchPad.interpreterDistanceLoading = false;
                    })
                    .finally(() => {

                    })
                }

                $scope.loadFTICourtNearestiInterpreter = (filter) => {
                  filter.langName = $scope.scratchPad.wo.langname;
                  filter.serviceType = $scope.scratchPad.wo.sertype;
                  $scope.scratchPad.interpreterFTIDistanceLoading = true;
                  $clientInterpreter.getFTIMatchCourtInterpreter(client, filter).then(res => {
                    if (res && res.status) {
                      if (filter.page > 1) {
                        let interpreterHomeCourData = res.result.map(item => {
                          let distance = item.distances && item.distances.distance && item.distances.distance >= 0 ? item.distances.distance : 0
                          return {
                            label: item.distances && item.distances.distance ? `${item.label} | ${distance} Miles` : item.label,
                            value: item.vendorid
                          }
                        });
                        $scope.scratchPad.interpreterFTIData = $scope.scratchPad.interpreterFTIData.concat(interpreterHomeCourData)
                      } else {
                        $scope.scratchPad.interpreterFTIDataCount = res.total;
                        $scope.scratchPad.interpreterFTIData = res.result.map(item => {
                          let distance = item.distances && item.distances.distance && item.distances.distance >= 0 ? item.distances.distance : 0
                          return {
                            label: item.distances && item.distances.distance ? `${item.label} | ${distance} Miles` : item.label,
                            value: item.vendorid
                          }
                        });
                      }
                    }
                    $scope.scratchPad.interpreterFTIDistanceLoading = false;
                  })
                    .catch(err => {
                      console.log(err)
                      $scope.scratchPad.interpreterFTIDistanceLoading = false;
                    })
                    .finally(() => {

                    })
                }

                $scope.loadRTWLangInterpreter = (filter) => {
                  filter.langName = $scope.scratchPad.wo.langname;
                  filter.serviceType = $scope.scratchPad.wo.sertype;
                  $scope.scratchPad.interpreterRTWLangLoading = true;
                  $clientInterpreter.getRTWLangInterpreter(client, filter).then(res => {
                    if (res && res.status) {
                      if (filter.page > 1) {
                        let interpreterRTWLangData = res.result.map(item => {
                          let distance = item.distances && item.distances.distance && item.distances.distance >= 0 ? item.distances.distance : 0
                          return {
                            label: item.distances && item.distances.distance ? `${item.label} | ${distance} Miles` : item.label,
                            value: item.vendorid
                          }
                        });
                        $scope.scratchPad.interpreterRTWLangData = $scope.scratchPad.interpreterRTWLangData.concat(interpreterRTWLangData)
                      } else {
                        $scope.scratchPad.interpreterRTWLangDataCount = res.total;
                        $scope.scratchPad.interpreterRTWLangData = res.result.map(item => {
                          let distance = item.distances && item.distances.distance && item.distances.distance >= 0 ? item.distances.distance : 0
                          return {
                            label: item.distances && item.distances.distance ? `${item.label} | ${distance} Miles` : item.label,
                            value: item.vendorid
                          }
                        });
                      }
                    }
                    $scope.scratchPad.interpreterRTWLangLoading = false;
                  })
                    .catch(err => {
                      console.log(err)
                      $scope.scratchPad.interpreterRTWLangLoading = false;
                    })
                    .finally(() => {

                    })
                }

                $scope.loadPaginateData = function (searchKey, page) {
                  $scope.ddLoading = true;

                  let filter = {
                    page,
                    pageSize: $scope.pageLimitForDD,
                    searchKey: searchKey || '',
                    isActive: true,
                    lang: $scope.scratchPad.wo.langcode,
                    court: $scope.scratchPad.wo.replocid,
                    isDistance: false,
                    coi: wo.coi,
                  }
                  switch ($scope.scratchPad.interpreterType) {
                    case 'homecourt':
                      // filter.allowFTI = $scope.scratchPad.allowHomeFTI || false
                      $scope.loadHomeCourtInterpreter(filter)
                      break;

                    case 'notHomecourt':
                      // filter.allowFTI = $scope.scratchPad.allowRTWFTI || false
                      $scope.loadRTWCourtNearestiInterpreter(filter)
                      break;

                    case 'fti':
                      $scope.loadFTICourtNearestiInterpreter(filter)
                      break;

                      case 'RTWLang':
                        $scope.loadRTWLangInterpreter(filter)
                        break;
                    default:
                      break;
                  }
                }

                $scope.buttonClick = function (e) {
                  isMoreData = false;
                  $scope.scratchPad.isLoading = true;
                  let data = {
                    vendorId: Array.isArray($scope.scratchPad.interpreter) && $scope.scratchPad.interpreter.length ? $scope.scratchPad.interpreter[0] : $scope.scratchPad.interpreter,
                    coi: wo.coi,
                    status: 'ASSIGN'
                  };
                  $openWorkOrders.openWOAssignInterpreter(client, data)
                    .then((res) => {
                      if (res && res.status) {
                        ngToast.create({
                          content: res.message || res,
                          className: 'success'
                        });
                        finish.call(e, res);
                        modal.close(e);
                      } else {
                        ngToast.create({
                          content: res.message || res,
                          className: 'danger'
                        });
                      }
                    })
                    .catch(err => {
                      let errMessage = err.message || err;
                      if (err && err.data && err.data.message) {
                        errMessage = err.data.message;
                      } else if (err && err.data && err.data.reason) {
                        errMessage = err.data.reason;
                      }
                      ngToast.create({
                        content: errMessage,
                        className: 'danger'
                      });
                    })
                    .finally(() => {
                      $scope.scratchPad.isLoading = false;
                    })
                }

                $scope.close = function (e) {
                  isMoreData = false;
                  finish.call(e, false);
                  modal.close(e);
                }

                function init() {
                  $scope.scratchPad.isLoading = true;
                  if (wo && wo.vendorid) {
                    $scope.scratchPad.assignedInterpreter = wo.vendorid;
                    if (!$scope.assignedInterpreter) {
                      $scope.assignedInterpreter = [];
                    }
                    $scope.assignedInterpreter.push({
                      label: wo.interprername,
                      value: wo.vendorid,
                      vendorid: wo.vendorid
                    })
                  }
                  $scope.reloadInterpreters();
                }
                init();
              }],
              modal: {
                dismissable: false,
                title: title ? `Assign Interpreter ${title}` : 'Assign Interpreter',
                template: 'components/modal/tpl/open-wo-assign-interpreter-dialog.html',
              }
            }, 'modal-warning', 'md', null, true);
          }
        },
        openWOConfirmInterpreter: function (wo, finish) {
          finish = finish || angular.noop;
          return function () {
            let title = `${wo.coi} | ${wo.langname} | ${wo.sertype} | ${wo.replocid}`
            var scratchPad = {
              wo: wo,
              isLoading: false,
              error: null,
              interpreterType: wo.vendorid ? 'assignedInterpreter' : 'homecourt'
            };
            const modal = openModal({
              controller: ['$scope', '$clientSwitcher', 'ngToast', '$openWorkOrders', '$clientInterpreter', function ($scope, $clientSwitcher, ngToast, $openWorkOrders, $clientInterpreter) {
                const client = $clientSwitcher.getCurrentClient();
                $scope.scratchPad = scratchPad;
                $scope.allValue = 'all';
                $scope.pageLimitForDD = 20;
                $scope.interpretersSets = [];
                $scope.interpreterData = [];
                $scope.interpreterDataCount = 0;
                $scope.assignedInterpreter = [];
                let isMoreData = true;

                $scope.interpreterAplliedCourtData = [];
                if (wo.performOpenWO && wo.performOpenWO.length && wo.status !== 'Assigned') {
                  wo.performOpenWO.forEach(item => {
                    if (item.status && item.status == 'ACCEPT') {
                      $scope.interpreterAplliedCourtData.push({
                        label: `${item.userId.interpreterFullName} | ${item.userId.vendorid} | ${item.userId.distance}`,
                        value: item.userId.vendorid
                      })
                    }
                  });
                  if ($scope.interpreterAplliedCourtData && $scope.interpreterAplliedCourtData.length) {
                    scratchPad.interpreterType = 'interpreterApllied';
                    $scope.scratchPad.interpreter = $scope.interpreterAplliedCourtData[0].value
                  }
                }

                $scope.reloadInterpreters = function (filter) {
                  if (!$scope.readOnly) {
                    if (!filter) {
                      $scope.interpreterData = [];
                      filter = {
                        page: 1,
                        pageSize: $scope.pageLimitForDD,
                        searchKey: null,
                      }
                    }
                    filter.isActive = true;
                    filter.lang = $scope.scratchPad.wo.langcode;
                    filter.court = $scope.scratchPad.wo.replocid;
                    filter.isDistance = false;
                    filter.coi = wo.coi;
                  }
                  if (!wo.vendorid) {
                    $scope.loadHomeCourtInterpreter(filter)
                  }
                }

                $scope.resetFTIFilter = () => {
                  $scope.scratchPad.allowHomeFTI = false;
                  $scope.scratchPad.allowDistanceFTI = false;
                }

                $scope.interpreterTypeChange = (e, type) => {
                  // e.preventDefault();

                  $scope.interpreterData = [];
                  $scope.scratchPad.interpreter = null;
                  let filter = {
                    page: 1,
                    pageSize: $scope.pageLimitForDD,
                    searchKey: '',
                    isActive: true,
                    lang: $scope.scratchPad.wo.langcode,
                    court: $scope.scratchPad.wo.replocid,
                    courtZipCode: $scope.scratchPad.wo.zip,
                    isDistance: false,
                    coi: wo.coi,
                    allowFTI : $scope.scratchPad.allowHomeFTI || $scope.scratchPad.allowDistanceFTI
                  }
                  switch ($scope.scratchPad.interpreterType) {
                    case 'assignedInterpreter':
                      $scope.scratchPad.assignedInterpreter = wo.vendorid;
                      break;
                    case 'homecourt':
                      $scope.loadHomeCourtInterpreter(filter)
                      break;

                    case 'notHomecourt':
                      $scope.loadRTWCourtNearestiInterpreter(filter)
                      break;

                    case 'fti':
                      $scope.loadFTICourtNearestiInterpreter(filter)
                      break;

                    case 'RTWLang':
                      $scope.loadRTWLangInterpreter(filter)
                      break;

                    default:
                      break;
                  }
                }

                $scope.loadRTWLangInterpreter = (filter) => {
                  filter.langName = $scope.scratchPad.wo.langname;
                  filter.serviceType = $scope.scratchPad.wo.sertype;
                  $scope.scratchPad.interpreterRTWLangLoading = true;
                  $clientInterpreter.getRTWLangInterpreter(client, filter).then(res => {
                    if (res && res.status) {
                      if (filter.page > 1) {
                        let interpreterRTWLangData = res.result.map(item => {
                          let distance = item.distances && item.distances.distance && item.distances.distance >= 0 ? item.distances.distance : 0
                          return {
                            label: item.distances && item.distances.distance ? `${item.label} | ${distance} Miles` : item.label,
                            value: item.vendorid
                          }
                        });
                        $scope.scratchPad.interpreterRTWLangData = $scope.scratchPad.interpreterRTWLangData.concat(interpreterRTWLangData)
                      } else {
                        $scope.scratchPad.interpreterRTWLangDataCount = res.total;
                        $scope.scratchPad.interpreterRTWLangData = res.result.map(item => {
                          let distance = item.distances && item.distances.distance && item.distances.distance >= 0 ? item.distances.distance : 0
                          return {
                            label: item.distances && item.distances.distance ? `${item.label} | ${distance} Miles` : item.label,
                            value: item.vendorid
                          }
                        });
                      }
                    }
                    $scope.scratchPad.interpreterRTWLangLoading = false;
                  })
                    .catch(err => {
                      console.log(err)
                      $scope.scratchPad.interpreterRTWLangLoading = false;
                    })
                    .finally(() => {

                    })
                }
                $scope.loadHomeCourtInterpreter = (filter) => {
                  $scope.scratchPad.interpreterHomeCourtLoading = true;
                  $clientInterpreter.getHomeCourtInterpreter(client, filter).then(res => {
                    if (res && res.status) {
                      if (filter.page > 1) {
                        let interpreterHomeCourData = res.result.map(item => {
                          let distance = item.distances && item.distances.distance && item.distances.distance >= 0 ? item.distances.distance : 0
                          return {
                            label: item.distances && item.distances.distance ? `${item.label} | ${distance} Miles` : item.label,
                            value: item.vendorid
                          }
                        });
                        $scope.scratchPad.interpreterHomeCourtData = $scope.scratchPad.interpreterHomeCourData.concat(interpreterHomeCourData)
                      } else {
                        $scope.scratchPad.interpreterHomeCourtCount = res.total;
                        $scope.scratchPad.interpreterHomeCourtData = res.result.map(item => {
                          let distance = item.distances && item.distances.distance && item.distances.distance >= 0 ? item.distances.distance : 0
                          return {
                            label: item.distances && item.distances.distance ? `${item.label} | ${distance} Miles` : item.label,
                            value: item.vendorid
                          }
                        });
                      }
                    }
                    $scope.scratchPad.interpreterHomeCourtLoading = false;
                  })
                    .catch(err => {
                      console.log(err)
                      $scope.scratchPad.interpreterHomeCourtLoading = false;
                    })
                    .finally(() => {

                    })
                }

                $scope.loadRTWCourtNearestiInterpreter = (filter) => {
                  filter.langName = $scope.scratchPad.wo.langname;
                  filter.serviceType = $scope.scratchPad.wo.sertype;

                  $scope.scratchPad.interpreterDistanceLoading = true;
                  $clientInterpreter.getRTWMatchCourtInterpreter(client, filter).then(res => {
                    if (res && res.status) {
                      if (filter.page > 1) {
                        let interpreterHomeCourData = res.result.map(item => {
                          let distance = item.distances && item.distances.distance && item.distances.distance >= 0 ? item.distances.distance : 0
                          return {
                            label: item.distances && item.distances.distance ? `${item.label} | ${distance} Miles` : item.label,
                            value: item.vendorid
                          }
                        });
                        $scope.scratchPad.interpreterDistanceData = $scope.scratchPad.interpreterDistanceData.concat(interpreterHomeCourData)
                      } else {
                        $scope.scratchPad.interpreterDistanceDataCount = res.total;
                        $scope.scratchPad.interpreterDistanceData = res.result.map(item => {
                          let distance = item.distances && item.distances.distance && item.distances.distance >= 0 ? item.distances.distance : 0
                          return {
                            label: item.distances && item.distances.distance ? `${item.label} | ${distance} Miles` : item.label,
                            value: item.vendorid
                          }
                        });
                      }
                    }
                    $scope.scratchPad.interpreterDistanceLoading = false;
                  })
                    .catch(err => {
                      console.log(err)
                      $scope.scratchPad.interpreterDistanceLoading = false;
                    })
                    .finally(() => {

                    })
                }

                $scope.loadFTICourtNearestiInterpreter = (filter) => {
                  filter.langName = $scope.scratchPad.wo.langname;
                  filter.serviceType = $scope.scratchPad.wo.sertype;
                  $scope.scratchPad.interpreterFTIDistanceLoading = true;
                  $clientInterpreter.getFTIMatchCourtInterpreter(client, filter).then(res => {
                    if (res && res.status) {
                      if (filter.page > 1) {
                        let interpreterHomeCourData = res.result.map(item => {
                          let distance = item.distances && item.distances.distance && item.distances.distance >= 0 ? item.distances.distance : 0
                          return {
                            label: item.distances && item.distances.distance ? `${item.label} | ${distance} Miles` : item.label,
                            value: item.vendorid
                          }
                        });
                        $scope.scratchPad.interpreterFTIData = $scope.scratchPad.interpreterFTIData.concat(interpreterHomeCourData)
                      } else {
                        $scope.scratchPad.interpreterFTIDataCount = res.total;
                        $scope.scratchPad.interpreterFTIData = res.result.map(item => {
                          let distance = item.distances && item.distances.distance && item.distances.distance >= 0 ? item.distances.distance : 0
                          return {
                            label: item.distances && item.distances.distance ? `${item.label} | ${distance} Miles` : item.label,
                            value: item.vendorid
                          }
                        });
                      }
                    }
                    $scope.scratchPad.interpreterFTIDistanceLoading = false;
                  })
                    .catch(err => {
                      console.log(err)
                      $scope.scratchPad.interpreterFTIDistanceLoading = false;
                    })
                    .finally(() => {

                    })
                }

                $scope.loadPaginateData = function (searchKey, page) {
                  $scope.ddLoading = true;

                  let filter = {
                    page,
                    pageSize: $scope.pageLimitForDD,
                    searchKey: searchKey || '',
                    isActive: true,
                    lang: $scope.scratchPad.wo.langcode,
                    court: $scope.scratchPad.wo.replocid,
                    isDistance: false,
                    coi: wo.coi,
                  }
                  switch ($scope.scratchPad.interpreterType) {
                    case 'homecourt':
                      $scope.loadHomeCourtInterpreter(filter)
                      break;

                    case 'notHomecourt':
                      $scope.loadRTWCourtNearestiInterpreter(filter)
                      break;

                    case 'fti':
                      $scope.loadFTICourtNearestiInterpreter(filter)
                      break;

                    case 'RTWLang':
                      $scope.loadRTWLangInterpreter(filter)
                      break;
                    default:
                      break;
                  }
                }

                $scope.buttonClick = function (e) {
                  isMoreData = false;
                  $scope.scratchPad.isLoading = true;
                  let data = {
                    vendorId: $scope.scratchPad.interpreterType == 'assignedInterpreter' ? $scope.scratchPad.assignedInterpreter : (Array.isArray($scope.scratchPad.interpreter) && $scope.scratchPad.interpreter.length ? $scope.scratchPad.interpreter[0] : $scope.scratchPad.interpreter),
                    coi: wo.coi,
                    status: 'CONFIRM'
                  };
                  $openWorkOrders.openWOConfirmInterpreter(client, data)
                    .then((res) => {
                      if (res && res.status) {
                        ngToast.create({
                          content: res.message || res,
                          className: 'success'
                        });
                        finish.call(e, res);
                        modal.close(e);
                      } else {
                        ngToast.create({
                          content: res.message || res,
                          className: 'danger'
                        });
                      }
                    })
                    .catch(err => {
                      let errMessage = err.message || err;
                      if (err && err.data && err.data.message) {
                        errMessage = err.data.message;
                      } else if (err && err.data && err.data.reason) {
                        errMessage = err.data.reason;
                      }
                      ngToast.create({
                        content: errMessage,
                        className: 'danger'
                      });
                    })
                    .finally(() => {
                      $scope.scratchPad.isLoading = false;
                    })
                }

                $scope.close = function (e) {
                  isMoreData = false;
                  finish.call(e, false);
                  modal.close(e);
                }

                function init() {
                  if (wo && wo.vendorid) {
                    $scope.scratchPad.assignedInterpreter = wo.vendorid;
                    $scope.assignedInterpreter.push({
                      label: wo.interprername,
                      value: wo.vendorid,
                      vendorid: wo.vendorid
                    })
                  }
                  $scope.reloadInterpreters();
                }
                init();
              }],
              modal: {
                dismissable: false,
                title: title ? `Confirm Interpreter ${title}` : 'Confirm Interpreter',
                template: 'components/modal/tpl/open-wo-confirm-interpreter-dialog.html',
              }
            }, 'modal-success', 'md', null, true);
          }
        },
        showInterpreterLocation: function (list, isShowCOI, finish) {
          finish = finish || angular.noop;

          return function () {
            LazyLoadService.loadGoogleMaps().then(()=>{
              var scratchPad = {
                woIC: list,
                isLoading: false,
                error: null,
              };
              let title = `${list[0].replocid} (${list[0].reportingcourt})`;
              if(list && list.length == 1 && isShowCOI){
                title = `${title} COI# ${list[0].coi}`;
              }
              const modal = openModal({
                controller: ['$scope', 'Modal', '$clientSwitcher', '$clientInterpreter', 'NgMap', '$clientOffice', '$auth', function ($scope, Modal, $clientSwitcher, $clientInterpreter, NgMap, $clientOffice, $auth) {
                $(".modal-dialog").addClass("modal-xl map-height");
                  var bounds;
                  const client = $clientSwitcher.getCurrentClient();
                  let user = $auth.getCurrentUser();
                  const isInterpreter = $auth.isInterpreter() || false; //(user.settings.roles.findIndex(x => x.code === 'INTERPRETER') >= 0);
                  var infowindow = null;
                  $scope.markerData = [];
                  $scope.cityMetaData = [];
                  $scope.map = null;
                  $scope.scratchPad = scratchPad;
                  $scope.getInterpreters = () => {
                    if ($scope.scratchPad.isLoading) return;
                    $scope.scratchPad.isLoading = true;
                    let vendorIds = [];
                    let userIds = [];
                    $scope.scratchPad.woIC.map(item => {
                      if (item.vendorid) vendorIds.push(item.vendorid);
                      if (item.performOpenWO && item.performOpenWO.length && !isInterpreter) {
                        item.performOpenWO.map(obj => {
                          if (obj.userId) userIds.push(obj.userId._id);
                          return obj;
                        })
                      }
                    })
                    // console.log(vendorIds, userIds)
                    if (vendorIds && vendorIds.length == 0 && isInterpreter) {
                      vendorIds = [user.settings.internalId]
                    }
                    if ((vendorIds && vendorIds.length) || (userIds && userIds.length)) {
                      $clientInterpreter.getInterpretersByVendorId(client, { vendorIds, userIds }).then((res) => {
                        if (res.status) {
                          $scope.interpreters = res.result;
                          $scope.getInterpreterAddressLatLng();
                        }
                      }, err => {
                        console.log('====', err)
                      })
                    }
                  }

                  $scope.getCourtLocation = () => {
                    $clientOffice.getOfficeByReplocId(client, list[0].replocid)
                      .then((res) => {
                        if (res.status && res.result) {
                          let obj = {
                            firstname: res.result.name,
                            latitude: res.result.location[1],
                            longitude: res.result.location[0],
                            streetaddressa: res.result.address,
                            zipcode: res.result.zip,
                            isCourtLoc: true
                          }
                          Object.assign(res.result, obj);
                          $scope.addressMarker(res.result);
                        }
                      }, err => {
                        console.log(err)
                      })
                  }

                  $scope.getInterpreterAddressLatLng = function () {
                    if( $scope.interpreters &&  $scope.interpreters.length) {
                      $scope.interpreters.forEach(function (interpreter, i) {
                        if (interpreter && interpreter.latitude && interpreter.longitude) {
                          $scope.cityMetaData.push(interpreter);
                          $scope.addressMarker(interpreter);
                        }
                      })
                    }
                  }

                  $scope.addressMarker = function (interpreter) {
                    var address = `${interpreter.streetaddressa} ${interpreter.zipcode}`;
                    let location = {
                      lat: interpreter.latitude,
                      lng: interpreter.longitude
                    }
                    let latLang = {
                      "title": address,
                      position: location
                    }
                    latLang = Object.assign(interpreter, latLang)
                    $scope.markerData.push(latLang);
                    bounds.extend(location);
                    $scope.setBoundry();
                  }

                  $scope.showDetails = function (e, info) {
                    if (infowindow) {
                      infowindow.close();
                    }
                    infowindow = new google.maps.InfoWindow();
                    var center = new google.maps.LatLng(info.latitude, info.longitude);
                    infowindow.setContent(
                      `<h5>${info.firstname} ${info.middlename ? info.middlename : ''} ${info.lastname ? info.lastname : ''}</h5>
                      <p>${info.streetaddressa ? info.streetaddressa + ',' : info.streetaddressa} </p>
                      <p>${info.city ? info.city + ',' : ''} ${info.state ? info.state + ',' : ''} ${info.zipcode}</p>`
                    );

                    infowindow.setPosition(center);
                    infowindow.open($scope.map);
                  };

                  $scope.setBoundry = () => {
                    $scope.map.setCenter(bounds.getCenter());
                    $scope.map.fitBounds(bounds);
                  }

                  $scope.onMapInit = () => {
                    $scope.map = null;
                    NgMap.getMap('icMap').then(function (map) {
                      $scope.map = map;
                      bounds = new google.maps.LatLngBounds();
                      $scope.getInterpreters();
                      $scope.getCourtLocation();
                    });
                  }

                  $scope.closeModal = (e) => {
                    if (infowindow) {
                      infowindow.close();
                    }
                    modal.dismiss(e);
                  }
                }],
                modal: {
                  dismissable: false,
                  title: title,
                  template: 'components/modal/tpl/open-wo-interpreter-map.html',

                }
              }, 'modal-success', 'lg');
            })
          }
        },
        showInterpreterAvailability: function (interpreterWODetail, finish) {
          finish = finish || angular.noop;

          return function () {
            var scratchPad = {
              isLoading: true,
              error: null,
              interpreterWODetail
            };
            let title = `Interpreter Availability`;
            const modal = openModal({
              controller: ['$scope', '$clientSwitcher', '$courtOperations', function (
                $scope, $clientSwitcher, $courtOperations) {

                const client = $clientSwitcher.getCurrentClient();
                // let user = $auth.getCurrentUser();
                // const isInterpreter = (user.settings.roles.findIndex(x => x.code === 'INTERPRETER') >= 0);
                $scope.scratchPad = scratchPad;
                $scope.loadCourt = function () {
                  $scope.displayData = null
                  $scope.scratchPad.isLoading = true;
                  if ($scope.scratchPad.interpreterWODetail && $scope.scratchPad.interpreterWODetail.coi) {
                    $courtOperations.getCasesDetails(client, $scope.scratchPad.interpreterWODetail.coi, {})
                    .then(function (res) {
                        if (res.status) {
                          if (res.data.records && res.data.records.length) {
                            let courtInfo = res.data.records[0]
                            $scope.displayData = {
                              coi: courtInfo.coi,
                              reportingcourt: courtInfo.reportingcourt,
                              replocid: courtInfo.replocid,
                              sertype: courtInfo.sertype,
                              langname: courtInfo.langname,
                            }
                          }
                        }
                      })
                      .catch(err => {
                        console.log(error)
                      })
                      .finally(() => {
                        $scope.scratchPad.isLoading = false;
                      })
                  } else {
                    $scope.scratchPad.isLoading = false;
                  }
                }
                $scope.loadCourt();

                $scope.closeModal = (e) => {
                  modal.dismiss(e);
                }
              }],
              modal: {
                dismissable: false,
                title: title,
                template: 'components/modal/tpl/interpreter-wo-availability.html',
                buttons: [{
                  classes: 'btn-default',
                  text: 'Close',
                  click: function (e) {
                    modal.close(e);
                  }
                }]
              }
            }, 'modal-success', 'md');
          }
        },
        showListOfAvailableWO: function (workOrders, finish) {
          finish = finish || angular.noop;

          return function () {
            var scratchPad = {
              isLoading: true,
              error: null,
              workOrders
            };
            let title = `Available Work Orders`;
            const modal = openModal({
              controller: ['$scope', '$autoTimezoneFormat', function ($scope, $autoTimezoneFormat) {
                $scope.scratchPad = scratchPad;
                $scope.pagination = {
                  currentPage: 1,
                  page: 1,
                  total: workOrders.length,
                  pageLimit: 10,
                  itemsPerPage: 10
                };
                $scope.filter = {
                  searchText: '',
                }
                $scope.schedValue = function (record) {
                  if (record && record.sched) {
                    let detail = $autoTimezoneFormat.getAllDetail(moment.utc(record.deldate).format('YYYY-MM-DD'), record.sched, record.courtTimezone);
                    return detail.caseDeldateTime
                  } else {
                    return 'N/A';
                  }
                }
                $scope.onSearch = () => {
                  $scope.pagination.page = 1
                }
                $scope.onPageChange = () => {
                }
                $scope.closeModal = (e) => {
                  modal.dismiss(e);
                }
                function init() {
                  $scope.scratchPad.isLoading = false
                }
                init()
              }],
              modal: {
                dismissable: false,
                title: title,
                template: 'components/modal/tpl/available-work-orders.html',
                buttons: [{
                  classes: 'btn-default',
                  text: 'Close',
                  click: function (e) {
                    modal.close(e);
                  }
                }]
              }
            }, 'modal-success', 'md');
          }
        }
      },
      serviceProjectAction: {
        createProject: function (data, finish) {
          finish = finish || angular.noop;
          return function () {
            var scratchPad = {
              isLoading: false,
              error: null
            };
            Object.assign(scratchPad, data);
            const modal = openModal({
              controller: ['$scope', '$clientSwitcher', 'ngToast', '$q', '$serviceRequired', '$clientLanguages', function ($scope, $clientSwitcher, ngToast, $q, $serviceRequired, $clientLanguages) {
                const client = $clientSwitcher.getCurrentClient();
                $scope.scratchPad = scratchPad;
                data.serviceRequired = data.serviceRequired && data.serviceRequired.length ? data.serviceRequired : [];
                data.targetLanguage = data.targetLanguage && data.targetLanguage.length ? data.targetLanguage : [];
                $scope.model = data;
                $scope.allValue = 'all';
                $scope.serviceRequiredDataset = $serviceRequired;
                $scope.activeLanguages = [];
                $scope.serviceRequiredSettings = function () {
                  var officeStr = 'Service Required';
                  return {
                    selectedStr: 'Selected ' + officeStr + ': ',
                    noSelection: 'No ' + officeStr + ' Selected',
                    allLabel: 'All ' + officeStr
                  };
                };

                $scope.projectTypeSettings = function () {
                  var officeStr = 'Project Type';
                  return {
                    selectedStr: 'Selected ' + officeStr + ': ',
                    noSelection: 'No ' + officeStr + ' Selected',
                    allLabel: 'All ' + officeStr
                  };
                };

                $scope.sourceLanguageSettings = function () {
                  var languages = 'Source Language';
                  return {
                    selectedStr: 'Selected ' + languages + ': ',
                    noSelection: 'No ' + languages + ' Selected',
                    allLabel: 'All ' + languages
                  };
                };

                $scope.targetLanguageSettings = function () {
                  var languages = 'Target Language';
                  return {
                    selectedStr: 'Selected ' + languages + ': ',
                    noSelection: 'No ' + languages + ' Selected',
                    allLabel: 'Select All'
                  };
                };

                $scope.initStartDate = function () {
                  $scope.projectTypes = [{ label: 'Onsite', value: 'Onsite' }, { label: 'Remote', value: 'Remote' }]
                  let startDate = $scope.model.startedOn ? moment($scope.model.startedOn).format() : moment().format();
                  $timeout(function () {
                    $('#startedOn').daterangepicker({
                      singleDatePicker: true,
                      startDate: moment(startDate).format('MM/DD/YYYY'),
                      minDate: new Date()
                    }, function (start, end, label) {
                      if (!start || !end) {
                        return;
                      }
                      $('#startedOn span').html(start.format('MM/DD/YYYY'));
                      $scope.model.startedOn = start.format();
                      $scope.model.deliveryDate = start.format();
                      $scope.initDeliveryDate();
                    });
                    var text = moment(startDate).format('MM/DD/YYYY');
                    $('#startedOn span').html(text);
                    $scope.model.startedOn = startDate;
                  }, 500);
                }

                $scope.initDeliveryDate = function () {
                  let deliveryDate = $scope.model.deliveryDate ? moment($scope.model.deliveryDate).format() : moment().format();
                  $timeout(function () {
                    $('#deliveryDate').daterangepicker({
                      singleDatePicker: true,
                      deliveryDate: moment(deliveryDate).format('MM/DD/YYYY'),
                      minDate: $scope.model && $scope.model.startedOn ? new Date($scope.model.startedOn) : new Date()
                    }, function (start, end, label) {
                      if (!start || !end) {
                        return;
                      }
                      $('#deliveryDate span').html(start.format('MM/DD/YYYY'));
                      $scope.model.deliveryDate = start.format();
                    });
                    var text = moment(deliveryDate).format('MM/DD/YYYY');
                    $('#deliveryDate span').html(text);
                    $scope.model.deliveryDate = deliveryDate;
                  }, 500);
                }

                function getLanguages() {
                  $scope.activeLanguages = [];
                  $clientLanguages.getLanguages(client)
                    .then(({ data, status }) => {
                      if (status) {
                        data.forEach((obj) => {
                          if (obj && obj.isEnabled) {
                            $scope.activeLanguages.push({
                              label: `${obj.name} (${obj.code})`,
                              value: obj._id
                            })
                          }
                        })
                      }
                    })
                }

                $scope.buttonClick = function (e) {
                  console.log($scope.model)
                  finish.call(e, $scope.model);
                  modal.close(e);
                }

                $scope.close = function (e) {
                  finish.call(e, false);
                  modal.close(e);
                }

                function init() {
                  $scope.initStartDate();
                  $scope.initDeliveryDate()
                  getLanguages();
                }

                init()
              }],
              modal: {
                dismissable: true,
                title: 'Create New Project',
                template: 'components/modal/tpl/create-service-project.html'
              }
            }, 'modal-success');
          }
        },
        createProjectComments: function (data, finish) {
          finish = finish || angular.noop;
          return function () {
            var scratchPad = {
              isLoading: false,
              error: null,
              comment: null
            };
            // Object.assign(scratchPad, data);
            const modal = openModal({
              controller: ['$scope', '$clientSwitcher', 'ngToast', '$q', '$ServiceProjectComment', function ($scope, $clientSwitcher, ngToast, $q, $ServiceProjectComment) {
                const client = $clientSwitcher.getCurrentClient();
                $scope.scratchPad = scratchPad;
                $scope.model = data;
                $scope.allValue = 'all';
                $scope.buttonClick = function (e) {
                  $ServiceProjectComment.create(client, Object.assign({}, { projectId: $scope.model._id, comment: $scope.scratchPad.comment }))
                    .then((res) => {
                      if (res && res.status) {
                        ngToast.create({
                          content: res.message || res,
                          className: 'success'
                        });
                        finish.call(e, res);
                        modal.close(e);
                      }
                    })
                    .catch(err => {
                      ngToast.create({
                        content: err.message || err,
                        className: 'danger'
                      });
                    })
                }
                $scope.close = function (e) {
                  finish.call(e, false);
                  modal.close(e);
                }
              }],
              modal: {
                dismissable: true,
                title: 'Add Comment',
                template: 'components/modal/tpl/create-service-project-comment.html'
              }
            }, 'modal-success');
          }
        }
      },
      servicePanel: {
        showServiceDetail: function (data, finish) {
          finish = finish || angular.noop;
          function humanize(str) {
            var i, frags = str.split('_');
            for (i = 0; i < frags.length; i++) {
              frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
            }
            return frags.join(' ');
          }

          let title = humanize(data.serviceType);
          title = title + ' Services';
          return function () {
            var scratchPad = {
              isLoading: false,
              error: null
            };
            Object.assign(scratchPad, data);
            const modal = openModal({
              controller: ['$scope', '$clientSwitcher', 'ngToast', '$q', '$servicePanel', '$timezones', '$servicePanelStatus', function ($scope, $clientSwitcher, ngToast, $q, $servicePanel, $timezones, $servicePanelStatus) {
                const client = $clientSwitcher.getCurrentClient();
                $(".modal-dialog").addClass("modal-dlg");
                $scope.scratchPad = scratchPad;
                $scope.pagination = {
                  currentPage: 1,
                  page: 1,
                  total: 0,
                  pageSize: 20,
                  itemsPerPage: 20
                };
                $scope.filter = {
                  serviceType: $scope.scratchPad.serviceType,
                  orderByField: '_id',
                  orderByValue: '-1'
                }
                $scope.model = data;
                $scope.loadServiceTypeDetail = function (isNotRefresh) {
                  if (!isNotRefresh) {
                    $scope.model.isLoading = true;
                    $scope.modal.serviceInfo = [];
                  }
                  let filter = {
                    limit: $scope.pagination.itemsPerPage,
                    offset: ($scope.pagination.page - 1) * $scope.pagination.itemsPerPage,
                  };
                  Object.assign(filter, $scope.filter)
                  $servicePanel.getServiceDetail(client, filter).then(res => {
                    $scope.model.isLoading = false;
                    if (res.status) {
                      $scope.model.serviceInfo = res.records;
                      $scope.pagination.total = res.counts;
                    }
                  }, err => {
                    $scope.model.isLoading = false;
                  })
                }

                $scope.filedShow = function () {
                  if (['transcription', 'translation', 'localization', 'captioning', 'telephonic_interpretation'].indexOf($scope.scratchPad.serviceType) >= 0) {
                    return true;
                  }
                  return false;
                }

                $scope.sortRecord = function (colName) {
                  if ($scope.model && $scope.model.serviceInfo && $scope.model.serviceInfo.length <= 0) return;
                  if ($scope.filter.orderByField == colName && $scope.filter.orderByValue == '-1') {
                    $scope.filter.orderByValue = '1';
                  } else {
                    $scope.filter.orderByValue = '-1'
                  }
                  $scope.sorting = {
                    [colName]: $scope.filter.orderByValue == '1' ? false : true
                  }
                  $scope.filter.orderByField = colName;

                  $scope.pagination.page = 1;
                  $scope.loadServiceTypeDetail(true);
                }
                $scope.buttonClick = function (e) {
                  console.log($scope.model)
                  finish.call(e, $scope.model);
                  modal.close(e);
                }

                $scope.close = function (e) {
                  finish.call(e, false);
                  modal.close(e);
                }

                function init() {
                  $scope.loadServiceTypeDetail();
                }

                init()
              }],
              modal: {
                dismissable: true,
                title: title || 'Service Details',
                template: 'components/modal/tpl/service-detail.html'
              }
            }, 'modal-success');
          }
        },
        serviceStatus: function (data, finish) {
          return function () {
            var scratchPad = {
              isLoading: false,
              error: null,
              status: null
            };
            Object.assign(scratchPad, data);
            const modal = openModal({
              controller: ['$scope', '$clientSwitcher', 'ngToast', '$q', '$servicePanel', '$timezones', '$servicePanelStatus', function ($scope, $clientSwitcher, ngToast, $q, $servicePanel, $timezones, $servicePanelStatus) {
                const client = $clientSwitcher.getCurrentClient();
                // $(".modal-dialog").addClass("modal-dlg");
                $scope.scratchPad = scratchPad;
                $scope.serviceDefaultStatus = [];
                let allServices = $servicePanelStatus(true);
                let currentStatus = $scope.scratchPad.bucketItem.status;

                function init() {
                  switch (currentStatus) {
                    case 'quote_approved':
                      $scope.serviceDefaultStatus = allServices.filter((obj) => obj.value == 'work_in_process');
                      break;
                    case 'work_in_process':
                      $scope.serviceDefaultStatus = allServices.filter((obj) => obj.value == 'work_completed');
                      break;
                    case 'work_completed':
                      $scope.serviceDefaultStatus = allServices.filter((obj) => obj.value == 'work_in_process');
                      break;
                    case 'work_accepted':
                      // let isPaymentDone = $scope.scratchPad.bucketItem.quoteActivity.find((obj) => ['Payment Received'].indexOf(obj.eventStatus) >= 0);
                      // $scope.serviceDefaultStatus = [];
                      // if (isPaymentDone) {
                      $scope.serviceDefaultStatus = allServices.filter((obj) => obj.value == 'service_completed');
                      // }
                      break;
                    case 'payment':
                      let isPaymentDone1 = $scope.scratchPad.bucketItem.quoteActivity.find((obj) => ['Payment Received'].indexOf(obj.eventStatus) >= 0);
                      $scope.serviceDefaultStatus = [];
                      if (isPaymentDone1) {
                        $scope.serviceDefaultStatus = allServices.filter((obj) => obj.value == 'service_completed');
                      }
                      break;
                    default:
                      $scope.serviceDefaultStatus = allServices;
                      break;
                  }
                }

                $scope.buttonClick = function (e) {
                  $scope.scratchPad.isLoading = true;
                  let data = {
                    serviceId: $scope.scratchPad.bucketItem._id,
                    status: $scope.scratchPad.status,
                    price: $scope.scratchPad.bucketItem.price || null,
                    comment: null,
                  };
                  switch ($scope.scratchPad.status) {
                    case 'work_in_process':
                      data.requestedQuoteType = "Work In Process";
                      //  "dueDate": "2021-09-17T00:00:00+05:30",
                      data.eventStatus = "Work In Process";
                      data.isShowCompileQuote = false;
                      data.isShowApproveQuote = false;
                      data.isShowRequestChange = false;
                      data.isModifyRequest = false;
                      break;
                    case 'work_completed':
                      data.requestedQuoteType = "Work Completed";
                      data.completedDate = new Date();
                      data.eventStatus = "Work Completed";
                      data.isAfterRejectedWorkComplete = false;
                      break;
                    case 'work_accepted':
                      data.requestedQuoteType = "Work Accepted";
                      data.eventStatus = "Work Accepted";
                      data.isShowAccept = false;
                      data.isShowReject = false;
                      break;
                    case 'payment':
                      data.requestedQuoteType = "Payment Received";
                      data.eventStatus = "Payment Received";
                      break;
                    case 'service_completed':
                      data.isWAcceptedAfterSCompleted = true;
                      data.requestedQuoteType = "Service Completed";
                      data.eventStatus = "Service Completed";
                      break;
                    default:
                      $scope.serviceDefaultStatus = allServices;
                      break;
                  }

                  $servicePanel.compileQuote(client, data).then(res => {
                    if (res.status) {
                      finish.call(e, { status: true });
                      modal.close(e);
                    } else {
                      finish.call(e, { status: false });
                      modal.close(e);
                    }
                    $scope.scratchPad.isLoading = false;
                  }, err => {
                    console.log(err)
                    $scope.scratchPad.error = err.message || err;
                    $scope.scratchPad.isLoading = false;
                  })
                }

                $scope.close = function (e) {
                  finish.call(e, false);
                  modal.close(e);
                }

                init()
              }],
              modal: {
                dismissable: true,
                title: 'Service Status',
                template: 'components/modal/tpl/change-service-detail-status.html'
              }
            }, 'modal-success');
          }
        },
        showServiceJobAttachment: function (record, finish) {
          finish = finish || angular.noop;
          return function () {
            var scratchPad = {
              error: null,
              success: null,
              record
            };

            var title = `${scratchPad.record.name} attachments`;
            var modal = openModal({
              controller: ['$scope', '$clientSwitcher', '$authStorage', '$downloadAttachment', 'Modal', 'appConfig', function ($scope, $clientSwitcher, $authStorage, $downloadAttachment, Modal, appConfig) {
                var client = $clientSwitcher.getCurrentClient();
                $scope.scratchPad = scratchPad;
                $scope.user = $auth.getCurrentUser();
                $scope.getFilePath = function (attachment) {
                  if (['image/jpeg', 'image/png', 'image/gif'].indexOf(attachment.mimeType) >= 0 && attachment.signedUrl) {
                    return attachment.signedUrl;
                  } else {
                    return appConfig.apiUrl + '/api/client-uploads/' + (client._id || client) + '/' + attachment._id + '?preview=1&access_token=' + $authStorage.getToken();
                  }
                };

                $scope.downloadAttachment = function (attachment, index) {
                  $downloadAttachment.download((client._id || client), attachment._id);
                };

                $scope.getSeperated = (item) => item.map(x => x.name).join(', ');

                $scope.showAttachment = function (attachment) {
                  var
                    modalPreview = Modal.info.previewUpload(function (result) {
                      console.log('modal preview result:', result);
                    });

                  var uploads = [];
                  var currerntUploadIndex;
                  _.each(record.attachments, function (jobAttachment, index) {
                    uploads.push(jobAttachment);
                    if (attachment._id === jobAttachment._id) {
                      currerntUploadIndex = index;
                    }
                  });

                  modalPreview($clientSwitcher.getCurrentClient(), uploads, currerntUploadIndex);
                };

              }],
              modal: {
                dismissable: true,
                title: title,
                template: 'components/modal/tpl/service-job-attachment.html',
                buttons: [{
                  classes: 'btn-default',
                  text: 'Close',
                  click: function (e) {
                    modal.close(e);
                  }
                }]
              }
            }, 'modal-success');
          };
        },
        addServiceJobAttachment: function (record, finish) {
          finish = finish || angular.noop;
          return function () {
            let scratchPad = {
              error: null,
              success: null,
              record
            };
            let response = {
              status: false
            }
            let title = `Add attachments`;
            let modal = openModal({
              controller: ['$scope', '$clientSwitcher', 'ngToast', '$uploadS3Attachment', '$servicePanel', function ($scope, $clientSwitcher, ngToast, $uploadS3Attachment, $servicePanel) {
                let client = $clientSwitcher.getCurrentClient();
                $scope.scratchPad = scratchPad;
                $scope.user = $auth.getCurrentUser();
                $scope.scratchPad.record.uploader = [];

                $scope.updateDetail = function () {
                  $servicePanel.update(client, _.omit($scope.scratchPad.record, ['uploader', 'bucket']))
                    .then(function (result) {
                      if (result && result.status) {
                        ngToast.create({
                          className: 'success',
                          content: 'File Uploaded Successfully!'
                        });
                        response = {
                          status: true
                        }
                        modal.close();
                      }
                    })
                    .catch((err) => {
                      let errMessage = err.message || err;
                      if (err && err.data && err.data.message) {
                        errMessage = err.data.message;
                      } else if (err && err.data && err.data.reason) {
                        errMessage = err.data.reason;
                      }
                      ngToast.create({
                        className: 'danger',
                        content: errMessage
                      });
                    })
                }

                $scope.buttonClick = function (e) {
                  if ($scope.scratchPad.record.uploader && $scope.scratchPad.record.uploader.queue && $scope.scratchPad.record.uploader.queue.length) {
                    let isExistExeFile = $scope.scratchPad.record.uploader.queue.filter((obj) => {
                      return !(/^[^.]+$|\.(?!(js|exe)$)([^.]+$)/.test(obj.name))
                    });
                    if (isExistExeFile && isExistExeFile.length > 0) {
                      ngToast.create({
                        className: 'danger',
                        content: 'Unsuccessful Uploaded: .js and .exe file type not supported'
                      });
                      return;
                    }
                    $uploadS3Attachment.upload(client, $scope.scratchPad.record.uploader, $scope, 'control1')
                      .then((imageRes) => {
                        $scope.scratchPad.record.newAttachments = [];
                        if (imageRes && imageRes.length) {
                          imageRes.forEach(function (upload) {
                            if (upload.status) {
                              $scope.scratchPad.record.newAttachments.push(upload.clientUpload._id);
                            }
                          });
                          $scope.updateDetail(e);
                        }
                      })
                      .catch((err) => {
                        let errMessage = err.message || err;
                        if (err && err.data && err.data.message) {
                          errMessage = err.data.message;
                        } else if (err && err.data && err.data.reason) {
                          errMessage = err.data.reason;
                        }
                        ngToast.create({
                          className: 'danger',
                          content: errMessage
                        });
                      })
                  } else {
                    ngToast.create({
                      content: 'Please select file',
                      className: 'danger'
                    });
                  }
                };
              }],
              modal: {
                dismissable: true,
                title: title,
                template: 'components/modal/tpl/add-service-job-attachment.html',
                buttons: [{
                  classes: 'btn-primary',
                  text: 'Add',
                }, {
                  classes: 'btn-default',
                  text: 'Close',
                  click: function (e) {
                    modal.close(e);
                  }
                }]
              }
            }, 'modal-success');
            modal.result.then(function (event) {
              finish.call(event, response);
            });
          };
        },
        quoteRequested: function (record, title, isAcceptWithComment, finish) {
          finish = finish || angular.noop;
          return function () {
            var scratchPad = {
              error: null,
              success: null,
              record,
              showRequestChange: false,
              showModifyRequest: false,
              isAcceptWithComment,
              requestedStatus: {}
            };

            var modal = openModal({
              controller: ['$scope', '$clientSwitcher', '$authStorage', 'ngToast', '$servicePanel', function ($scope, $clientSwitcher, $authStorage, ngToast, $servicePanel) {
                var client = $clientSwitcher.getCurrentClient();
                $scope.user = $auth.getCurrentUser();
                $scope.scratchPad = scratchPad;
                $scope.isDateMendatory = !(title == 'Compile Quote');
                $scope.data = {
                  serviceId: record._id,
                  price: record.price || null,
                  comment: null,
                  requestedQuoteType: title
                }
                $scope.picker = {
                  openPicker: false,
                  openTimePicker: false
                };
                let timing = record.deadline ? new Date(record.deadline) : record.dueDate ? new Date(record.dueDate) : null;
                $scope.scratchPad.selectedDate = timing;
                $scope.scratchPad.selectedTime = timing;

                $scope.setDateForTime = function () {
                  $scope.scratchPad.selectedTime = $scope.scratchPad.selectedDate;
                  $scope.onFieldChange();
                  $scope.requestedDateStatus();
                }

                function setRequestFlag() {
                  if ($scope.scratchPad.record.isShowRequestChange) {
                    $scope.scratchPad.showRequestChange = false;
                    $scope.scratchPad.showModifyRequest = true;
                  } else {
                    $scope.scratchPad.showRequestChange = true;
                    $scope.scratchPad.showModifyRequest = false;
                  }
                }

                $scope.requestedDateStatus = function () {
                  let dueDate = moment(moment($scope.scratchPad.selectedDate).format('YYYY-MM-DD') + ' ' + moment($scope.scratchPad.selectedTime).format('hh:mm:ss a')).format();
                  let requestedStatus = {
                    status: 'Same As Requested',
                    className: 'text-success'
                  };
                  if (moment($scope.scratchPad.record.persistDeadline).isSame(moment.utc(dueDate), 'YYYY-MM-DD')) {
                    requestedStatus = requestedStatus
                  } else if (moment(moment.utc(dueDate)).isBefore(moment($scope.scratchPad.record.persistDeadline), 'YYYY-MM-DD')) {
                    requestedStatus = {
                      status: 'Before Requested',
                      className: 'text-orange'
                    }
                  } else if (moment(moment.utc(dueDate)).isAfter(moment($scope.scratchPad.record.persistDeadline), 'YYYY-MM-DD')) {
                    requestedStatus = {
                      status: 'After Requested',
                      className: 'text-orange'
                    }
                  }
                  $scope.scratchPad.requestedStatus = requestedStatus;
                }

                function init() {
                  $scope.requestedDateStatus();
                }
                init();

                $scope.onFieldChange = function () {
                  if ((!$scope.scratchPad.record.isShowCompileQuote && $scope.scratchPad.record.isShowApproveQuote) || ($scope.scratchPad.record.isShowRequestChange)) {
                    if ($scope.data.price && $scope.data.price != $scope.scratchPad.record.price) {
                      setRequestFlag();
                    } else {
                      let dueDate = moment(moment($scope.scratchPad.selectedDate).format('YYYY-MM-DD') + ' ' + moment($scope.scratchPad.selectedTime).format('hh:mm:ss a')).format();
                      if ($scope.scratchPad.record.serviceType == 'on_site_interpretation') {
                        if (!moment($scope.scratchPad.record.dueDate).isSame(moment.utc(dueDate).format())) {
                          setRequestFlag();
                          return;
                        }
                      } else {
                        if ($scope.scratchPad.selectedDate && $scope.scratchPad.selectedTime) {
                          if (!moment($scope.scratchPad.record.deadline).isSame(moment(dueDate).format())) {
                            setRequestFlag();
                            return;
                          }
                        }
                      }
                      $scope.scratchPad.showRequestChange = false;
                      $scope.scratchPad.showModifyRequest = false;
                    }
                  }
                }

                $scope.buttonClick = function (e, btnType) {
                  if ($scope.scratchPad.selectedDate && !$scope.scratchPad.selectedTime) {
                    ngToast.create({
                      className: 'danger',
                      content: 'Please select due time'
                    });
                    return;
                  }
                  if ($scope.scratchPad.selectedDate && $scope.scratchPad.selectedTime) {
                    // $scope.data.dueDate = moment(moment($scope.scratchPad.selectedDate).format('YYYY-MM-DD') + ' ' + moment($scope.scratchPad.selectedTime).format('hh:mm:ss a')).format();
                    $scope.data.dueDate = moment(moment($scope.scratchPad.selectedDate).format('YYYY-MM-DD') + ' ' + moment($scope.scratchPad.selectedTime).format('hh:mm:ss a'), 'YYYY-MM-DD hh:mm:ss a').toDate();
                  }

                  $scope.scratchPad.isLoading = true;
                  if (btnType == 'Submit') {
                    $scope.data.eventStatus = 'InProcess';
                    $scope.data.status = 'quote_approved';
                    $scope.data.isShowCompileQuote = false;
                    $scope.data.isShowApproveQuote = true;
                    $scope.data.isShowRequestChange = false;
                    $scope.data.isModifyRequest = false;
                  } else if (btnType == 'Request Change') {
                    $scope.data.requestedQuoteType = 'Request Change';
                    $scope.data.status = 'quote_approved';
                    $scope.data.eventStatus = 'Modified';
                    $scope.data.isShowCompileQuote = true;
                    $scope.data.isShowApproveQuote = false;
                    $scope.data.isShowRequestChange = true;
                    $scope.data.isModifyRequest = false;
                  } else if (btnType == 'Modify Request') {
                    $scope.data.requestedQuoteType = 'Modify Request';
                    $scope.data.status = 'quote_approved';
                    $scope.data.eventStatus = 'Modified';
                    $scope.data.isShowCompileQuote = false;
                    $scope.data.isShowApproveQuote = true;
                    $scope.data.isShowRequestChange = false;
                    $scope.data.isModifyRequest = true;
                  } else if (btnType == 'Accept') {
                    $scope.data.status = 'quote_approved';
                    $scope.data.eventStatus = 'Quote Accepted';
                    $scope.data.isShowCompileQuote = false;
                    $scope.data.isShowApproveQuote = false;
                    $scope.data.isShowRequestChange = false;
                    $scope.data.isModifyRequest = false;
                  } else if (btnType == 'Work In Process') {
                    $scope.data.status = 'work_in_process';
                    $scope.data.eventStatus = 'Work In Process';
                    $scope.data.isShowCompileQuote = false;
                    $scope.data.isShowApproveQuote = false;
                    $scope.data.isShowRequestChange = false;
                    $scope.data.isModifyRequest = false;
                  }
                  $servicePanel.compileQuote(client, $scope.data)
                    .then(res => {
                      if (res.status) {
                        finish.call(e, res);
                        modal.close(e);
                      } else {
                        finish.call(e, { status: false });
                        modal.close(e);
                      }
                      $scope.scratchPad.isLoading = false;
                    }, err => {
                      let errMessage = err.message || err;
                      if (err && err.data && err.data.message) errMessage = err.data.message;
                      if (err && err.data && err.data.reason) errMessage = err.data.reason;
                      $scope.scratchPad.error = errMessage;
                      $scope.scratchPad.isLoading = false;
                    })
                }

                $scope.close = function (e) {
                  finish.call(e, false);
                  modal.close(e);
                }

              }],
              modal: {
                dismissable: true,
                title: title,
                template: 'components/modal/tpl/compile-quote.html'
              }
            }, 'modal-success');
          };
        },
        acceptWorkAction: function (record, title, finish) {
          finish = finish || angular.noop;
          return function () {
            var scratchPad = {
              error: null,
              success: null,
              record,
              btnText: title == 'Work Accepted' ? 'Accept' : 'Rejected'
            };

            var modal = openModal({
              controller: ['$scope', '$clientSwitcher', 'ngToast', '$servicePanel', function ($scope, $clientSwitcher, ngToast, $servicePanel) {
                var client = $clientSwitcher.getCurrentClient();
                $scope.user = $auth.getCurrentUser();
                $scope.scratchPad = scratchPad;
                $scope.data = {
                  serviceId: record._id,
                  comment: null,
                  requestedQuoteType: title
                }

                $scope.buttonClick = function (e) {
                  $scope.scratchPad.isLoading = true;
                  if ($scope.scratchPad.btnText == 'Accept') {
                    $scope.data.status = 'work_accepted';
                    $scope.data.eventStatus = 'Work Accepted';
                    $scope.data.isShowAccept = false;
                    $scope.data.isShowReject = false;
                  } else if ($scope.scratchPad.btnText == 'Rejected') {
                    $scope.data.eventStatus = 'Work Rejected';
                    $scope.data.isShowReject = true;
                  }
                  $servicePanel.compileQuote(client, $scope.data)
                    .then(res => {
                      if (res.status) {
                        finish.call(e, res);
                        modal.close(e);
                      } else {
                        finish.call(e, { status: false });
                        modal.close(e);
                      }
                      $scope.scratchPad.isLoading = false;
                    }, err => {
                      let errMessage = err.message || err;
                      if (err && err.data && err.data.message) errMessage = err.data.message;
                      if (err && err.data && err.data.reason) errMessage = err.data.reason;
                      $scope.scratchPad.error = errMessage;
                      $scope.scratchPad.isLoading = false;
                    })
                }

                $scope.close = function (e) {
                  finish.call(e, false);
                  modal.close(e);
                }

              }],
              modal: {
                dismissable: true,
                title: title,
                template: 'components/modal/tpl/accept-work-completed.html'
              }
            }, 'modal-success');
          };
        },
        serviceJobWorkCompleted: function (record, title, isAfterRejectedWorkComplete, finish) {
          finish = finish || angular.noop;
          return function () {
            var scratchPad = {
              error: null,
              success: null,
              record,
              selectedDate: new Date(),
              selectedTime: new Date(),
              isAfterRejectedWorkComplete
            };

            var modal = openModal({
              controller: ['$scope', '$clientSwitcher', 'ngToast', '$servicePanel', function ($scope, $clientSwitcher, ngToast, $servicePanel) {
                var client = $clientSwitcher.getCurrentClient();
                $scope.user = $auth.getCurrentUser();
                $scope.scratchPad = scratchPad;

                let find_work_in_process = record.statusActivity.find((obj) => obj.status == 'work_in_process');
                let work_in_process = find_work_in_process ? find_work_in_process.createdAt : $scope.scratchPad.record.createdAt
                $scope.data = {
                  serviceId: record._id,
                  comment: null,
                  requestedQuoteType: title,
                  isAfterRejectedWorkComplete
                };

                $scope.picker = {
                  openPicker: false,
                  openTimePicker: false,
                  timepickerOptions: {
                    min: new Date(work_in_process),
                    max: new Date()
                  },
                  datepickerOptions: {
                    minDate: new Date(work_in_process),
                    maxDate: new Date()
                  }
                };

                $scope.setDateForTime = function () {
                  $scope.scratchPad.selectedTime = $scope.scratchPad.selectedDate;
                }

                $scope.buttonClick = function (e) {
                  if ($scope.scratchPad.selectedDate && !$scope.scratchPad.selectedTime) {
                    ngToast.create({
                      className: 'danger',
                      content: 'Please select due time'
                    });
                    return;
                  }
                  if ($scope.scratchPad.selectedDate && $scope.scratchPad.selectedTime) {
                    $scope.data.completedDate = moment(moment($scope.scratchPad.selectedDate).format('YYYY-MM-DD') + ' ' + moment($scope.scratchPad.selectedTime).format('hh:mm:ss a')).format();
                  }

                  $scope.scratchPad.isLoading = true;
                  $scope.data.status = 'work_completed';
                  $scope.data.eventStatus = 'Work Completed';
                  if (isAfterRejectedWorkComplete) {
                    $scope.data.isShowAccept = true;
                  }
                  $servicePanel.compileQuote(client, $scope.data)
                    .then(res => {
                      if (res.status) {
                        finish.call(e, res);
                        modal.close(e);
                      } else {
                        finish.call(e, { status: false });
                        modal.close(e);
                      }
                      $scope.scratchPad.isLoading = false;
                    }, err => {
                      let errMessage = err.message || err;
                      if (err && err.data && err.data.message) errMessage = err.data.message;
                      if (err && err.data && err.data.reason) errMessage = err.data.reason;
                      $scope.scratchPad.error = errMessage;
                      $scope.scratchPad.isLoading = false;
                    })
                }

                $scope.close = function (e) {
                  finish.call(e, false);
                  modal.close(e);
                }

              }],
              modal: {
                dismissable: true,
                title: title,
                template: 'components/modal/tpl/service-job-work-completed.html'
              }
            }, 'modal-success');
          };
        },
        serviceJobPayment: function (record, paymentStatusObj, isInvoiceGenerated, finish) {
          finish = finish || angular.noop;
          return function () {
            let title = paymentStatusObj.title;
            var scratchPad = {
              error: null,
              success: null,
              record,
              selectedDate: new Date(),
              selectedTime: new Date(),
              isInvoiceGenerated,
              btnName: paymentStatusObj.btnName
            };

            var modal = openModal({
              controller: ['$scope', '$clientSwitcher', 'ngToast', '$servicePanel', function ($scope, $clientSwitcher, ngToast, $servicePanel) {
                var client = $clientSwitcher.getCurrentClient();
                $scope.user = $auth.getCurrentUser();
                $scope.scratchPad = scratchPad;
                $scope.data = {
                  serviceId: record._id,
                  comment: null,
                  requestedQuoteType: title
                };

                $scope.picker = {
                  openPicker: false,
                  openTimePicker: false
                };

                $scope.setDateForTime = function () {
                  $scope.scratchPad.selectedTime = $scope.scratchPad.selectedDate;
                }

                $scope.buttonClick = function (e) {
                  if ($scope.scratchPad.selectedDate && !$scope.scratchPad.selectedTime) {
                    ngToast.create({
                      className: 'danger',
                      content: 'Please select payment due time'
                    });
                    return;
                  }
                  if ($scope.scratchPad.selectedDate && $scope.scratchPad.selectedTime && title == 'Invoice Generated') {
                    $scope.data.paymentDueDate = moment(moment($scope.scratchPad.selectedDate).format('YYYY-MM-DD') + ' ' + moment($scope.scratchPad.selectedTime).format('hh:mm:ss a')).format();
                  }

                  $scope.scratchPad.isLoading = true;
                  $scope.data.status = title == 'Service Completed' ? 'service_completed' : 'payment';
                  $scope.data.eventStatus = title;
                  $servicePanel.compileQuote(client, $scope.data)
                    .then(res => {
                      if (res.status) {
                        finish.call(e, res);
                        modal.close(e);
                      } else {
                        finish.call(e, { status: false });
                        modal.close(e);
                      }
                      $scope.scratchPad.isLoading = false;
                    }, err => {
                      let errMessage = err.message || err;
                      if (err && err.data && err.data.message) errMessage = err.data.message;
                      if (err && err.data && err.data.reason) errMessage = err.data.reason;
                      $scope.scratchPad.error = errMessage;
                      $scope.scratchPad.isLoading = false;
                    })
                }

                $scope.close = function (e) {
                  finish.call(e, false);
                  modal.close(e);
                }

              }],
              modal: {
                dismissable: true,
                title: title,
                template: 'components/modal/tpl/service-job-payment.html'
              }
            }, 'modal-success');
          };
        }
      },
      resources: {
        addAssessment: function (icDetails, icLanguages, assessment, finish) {
          finish = finish || angular.noop;
          return function () {
            var scratchPad = {
              error: null,
              success: null,
              icDetails,
              icLanguages
            };

            var modal = openModal({
              controller: ['$scope', '$clientSwitcher', 'ngToast', '$assessmentTypes', '$assessmentParts', '$timeout', '$interpreterAssessments', '$assessmentYears', function ($scope, $clientSwitcher, ngToast, $assessmentTypes, $assessmentParts, $timeout, $interpreterAssessments, $assessmentYears) {
                var client = $clientSwitcher.getCurrentClient();
                $scope.user = $auth.getCurrentUser();
                $scope.scratchPad = scratchPad;
                $scope.assessmentTypes = $assessmentTypes;
                $scope.assessmentParts = $assessmentParts;
                $scope.assessment = JSON.parse(JSON.stringify(assessment));
                $scope.assessmentYears = $assessmentYears;
                $scope.data = {
                  datecomp: moment().format(),
                  datesent: moment().format(),
                  nextduedate: moment().format(),
                  assyear: parseInt(moment().format('YYYY')),
                  vendorid: icDetails.vendorid,
                  score: null
                }

                if ($scope.assessment) {
                  let language = null;
                  if ($scope.assessment.language && $scope.assessment.language.code) {
                    language = $scope.assessment.language.code;
                  }
                  $scope.data = $scope.assessment;
                  $scope.data.language = language;
                }

                function assessmentDatePicker() {
                  function cb(start, end) {
                    $timeout(function () {
                      if (!start || !end) {
                        return;
                      }

                      $('#assessmentDate span').html(start.format('MM/DD/YYYY'));
                      $scope.data.datecomp = start.format();
                    }, 0);
                  }

                  $('#assessmentDate').daterangepicker({
                    singleDatePicker: true,
                    opens: 'right',
                    startDate: new Date($scope.data.datecomp),
			              endDate: new Date($scope.data.datecomp),
                  }, cb);

                  var text = moment($scope.data.datecomp).format('MM/DD/YYYY');
                  $('#assessmentDate span').html(text);
                }

                function datesentPicker() {
                  function cb(start, end) {
                    $timeout(function () {
                      if (!start || !end) {
                        return;
                      }

                      $('#datesent span').html(start.format('MM/DD/YYYY'));
                      $scope.data.datesent = start.format();
                    }, 0);
                  }

                  $('#datesent').daterangepicker({
                    singleDatePicker: true,
                    opens: 'right',
                    startDate: new Date($scope.data.datesent),
			              endDate: new Date($scope.data.datesent),
                  }, cb);

                  var text = moment($scope.data.datesent).format('MM/DD/YYYY');
                  $('#datesent span').html(text);
                }

                function nextduedatePicker() {
                  function cb(start, end) {
                    $timeout(function () {
                      if (!start || !end) {
                        return;
                      }

                      $('#nextduedate span').html(start.format('MM/DD/YYYY'));
                      $scope.data.nextduedate = start.format();
                    }, 0);
                  }

                  $('#nextduedate').daterangepicker({
                    singleDatePicker: true,
                    opens: 'right',
                    startDate: new Date($scope.data.nextduedate),
			              endDate: new Date($scope.data.nextduedate),
                  }, cb);

                  var text = moment($scope.data.nextduedate).format('MM/DD/YYYY');
                  $('#nextduedate span').html(text);
                }

                function init() {
                  $timeout(() => {
                    assessmentDatePicker();
                    datesentPicker();
                    nextduedatePicker();
                  }, 500);
                }

                $scope.onLanguageChange = function () {
                  // if ($scope.data.language) {
                  //   let langFound = $scope.scratchPad.icLanguages.find((obj) => obj.value == $scope.data.language);
                  //   $scope.data.score = langFound.score;
                  // }
                }

                $scope.buttonClick = function (e) {
                  $scope.scratchPad.isLoading = true;
                  $interpreterAssessments.create(client, $scope.data)
                    .then((res) => {
                      if (res && res.status) {
                        ngToast.create({
                          content: 'Assessment created successfully',
                          className: 'success'
                        });
                        finish.call(e, true);
                        modal.close(e);
                      }
                    })
                    .catch((err) => {
                      let errMessage = err.message || err;
                      if (err && err.data && err.data.message) {
                        errMessage = err.data.message;
                      } else if (err && err.data && err.data.reason) {
                        errMessage = err.data.reason;
                      }
                      ngToast.create({
                        content: errMessage,
                        className: 'danger'
                      });
                    })
                    .finally(() => {
                      $scope.scratchPad.isLoading = false;
                    })
                }

                $scope.close = function (e) {
                  finish.call(e, false);
                  modal.close(e);
                }
                init();
              }],
              modal: {
                dismissable: true,
                title: `${assessment ? 'Update' : 'Add'} Assessment | ${icDetails.firstname} ${icDetails.middlename ? icDetails.middlename : ''} ${icDetails.lastname}`,
                template: 'components/modal/tpl/add-assessment.html'
              }
            }, 'modal-success', 'lg');
          };
        },
        createDocument: function (icDetails, document, finish) {
          finish = finish || angular.noop;
          return function () {
            var scratchPad = {
              error: null,
              success: null,
              icDetails
            };

            var modal = openModal({
              controller: ['$scope', '$clientSwitcher', 'ngToast', '$timeout', '$interpreterDocument', '$uploadS3Attachment', '$coordinators', function (
                $scope, $clientSwitcher, ngToast, $timeout, $interpreterDocument, $uploadS3Attachment, $coordinators) {
                var client = $clientSwitcher.getCurrentClient();
                $scope.user = $auth.getCurrentUser();
                $scope.scratchPad = scratchPad;
                $scope.data = {
                  isLoading: false
                };
                $scope.coordinators = [];
                $scope.uploader = [];
                $scope.model = {
                  title : document && document.hasOwnProperty('title') ? document.title : '',
                  executedOn : document && document.hasOwnProperty('executedOn') ?  moment(document.executedOn).format() : moment().format(),
                  executedBy : document && document.hasOwnProperty('executedBy') ? document.executedBy._id : '',
                  effectiveDate : document && document.hasOwnProperty('effectiveDate') ? moment(document.effectiveDate).format() : moment().format(),
                  endDate : document && document.hasOwnProperty('endDate') ? moment(document.endDate).format()  : moment().format(),
                  isPrivate : document && document.hasOwnProperty('isPrivate') ? document.isPrivate : '',
                  userComment:  document && document.hasOwnProperty('comments') ? document.comments : [],
                  attachments:  document && document.hasOwnProperty('attachments') ? document.attachments : [],
                  vendorid: icDetails.vendorid,
                  uploadFiles: []
                }

                if (document) {
                  $scope.model._id = document._id
                }

                function endDatePicker() {
                  function cb(start, end) {
                    $timeout(function () {
                      if (!start || !end) {
                        return;
                      }

                      $('#endDate span').html(start.format('MM/DD/YYYY'));
                      $scope.model.endDate = start.format();
                    }, 0);
                  }

                  $('#endDate').daterangepicker({
                    startDate: new Date($scope.model.endDate),
			              endDate: new Date($scope.model.endDate),
                    singleDatePicker: true,
                    opens: 'right'
                  }, cb);

                  var text = moment($scope.model.endDate).format('MM/DD/YYYY');
                  $('#endDate span').html(text);
                }

                function executedOnPicker() {
                  function cb(start, end) {
                    $timeout(function () {
                      if (!start || !end) {
                        return;
                      }
                      $('#executedOn span').html(start.format('MM/DD/YYYY'));
                      $scope.model.executedOn = start.format();
                      // console.log(start.format())
                    }, 0);
                  }

                  $('#executedOn').daterangepicker({
                    singleDatePicker: true,
                    startDate: new Date($scope.model.executedOn),
			              endDate: new Date($scope.model.executedOn),
                    opens: 'right'
                  }, cb);

                  var text = moment($scope.model.executedOn).format('MM/DD/YYYY');
                  $('#executedOn span').html(text);
                }

                function effectiveDatePicker() {
                  function cb(start, end) {
                    $timeout(function () {
                      if (!start || !end) {
                        return;
                      }

                      $('#effectiveDate span').html(start.format('MM/DD/YYYY'));
                      $scope.model.effectiveDate = start.format();
                    }, 0);
                  }

                  $('#effectiveDate').daterangepicker({
                    singleDatePicker: true,
                    opens: 'right',
                    startDate: new Date($scope.model.effectiveDate),
			              endDate: new Date($scope.model.effectiveDate),
                  }, cb);

                  var text = moment($scope.model.effectiveDate).format('MM/DD/YYYY');
                  $('#effectiveDate span').html(text);
                }

                function init() {
                  $timeout(() => {
                    endDatePicker();
                    executedOnPicker();
                    effectiveDatePicker();
                    // initPicker();
                  }, 500);
                  $scope.loadCooardinators();
                }

                $scope.loadCooardinators = function () {
                  $scope.cooardinators = [];
                  $coordinators.getCoordinatorList(client, {
                    pageSize: 2000,
                    page: 1
                  }).then(res => {
                    if (res.status) {
                      res.coordinatorsList.map((obj) => {

                        $scope.cooardinators.push({
                          label: obj.cdnrfn,
                          value: obj._id
                        })

                      })
                      $scope.cooardinators = _.sortBy($scope.cooardinators, ['label']);
                    }
                  })
                    .catch(err => {
                      ngToast.create({
                        content: 'Unable to fatch the Coordinators, please try again!',
                        className: 'danger'
                      });
                    })
                }
                $scope.buttonClick = function (e) {
                  if ($scope.uploader && $scope.uploader.queue && $scope.uploader.queue.length) {
                    $scope.scratchPad.isLoading = true;
                    $uploadS3Attachment.upload(client, $scope.uploader, $scope, 'control1')
                      .then((imageRes) => {
                        $scope.attachments = [];
                        if (imageRes && imageRes.length) {
                          imageRes.forEach(function (upload) {
                            if (upload.status) {
                              $scope.attachments.push(upload.clientUpload._id);
                            }
                          });
                          delete $scope.uploader;
                          $scope.submitDetails(e);
                        }
                      })

                      .catch((err) => {
                        let errMessage = err.message || err;
                        if (err && err.data && err.data.message) {
                          errMessage = err.data.message;
                        } else if (err && err.data && err.data.reason) {
                          errMessage = err.data.reason;
                        }
                        ngToast.create({
                          className: 'danger',
                          content: errMessage
                        });
                        // $scope.scratchPad.isLoading = false;
                      })
                  } else {
                    $scope.submitDetails();
                  }
                }
                $scope.submitDetails = function (e) {
                  if ($scope.attachments && $scope.attachments.length) {
                    $scope.model.attachments = $scope.attachments;
                  }
                  $scope.scratchPad.isLoading = true;
                  $interpreterDocument.create(client, $scope.model)
                    .then((res) => {
                      if (res && res.status) {
                        ngToast.create({
                          content: $scope.model._id ? 'Document update successfully' : 'Document created successfully',
                          className: 'success'
                        });
                        finish.call(e, res);
                        modal.close(e);
                      }
                    })
                    .catch((err) => {
                      let errMessage = err.message || err;
                      if (err && err.data && err.data.message) {
                        errMessage = err.data.message;
                      } else if (err && err.data && err.data.reason) {
                        errMessage = err.data.reason;
                      }
                      ngToast.create({
                        content: errMessage,
                        className: 'danger'
                      });
                    })
                    .finally(() => {
                      $scope.scratchPad.isLoading = false;
                    })
                }

                $scope.close = function (e) {
                  finish.call(e, false);
                  modal.close(e);
                }
                init();
              }],
              modal: {
                dismissable: true,
                title: `${document ? 'Edit' : 'Add'} Document | ${icDetails.firstname} ${icDetails.middlename ? icDetails.middlename : ''} ${icDetails.lastname}`,
                template: 'components/modal/tpl/create-document.html'
              }
            }, 'modal-success', 'lg');
          };
        },
        addDegreeAndCertificate: function (icDetails, degreeOrCertificate, finish) {
          finish = finish || angular.noop;
          return function () {
            var scratchPad = {
              error: null,
              success: null,
              icDetails
            };

            var modal = openModal({
              controller: ['$scope', '$clientSwitcher', 'ngToast', '$timeout', '$icDegreesOrCertificates', '$degreeOrCertificateType', '$degreeList', '$uploadS3Attachment', function ($scope, $clientSwitcher, ngToast, $timeout, $icDegreesOrCertificates, $degreeOrCertificateType, $degreeList, $uploadS3Attachment) {
                var client = $clientSwitcher.getCurrentClient();
                $scope.user = $auth.getCurrentUser();
                $scope.scratchPad = scratchPad;
                $scope.degreeOrCertificateType = $degreeOrCertificateType;
                $scope.degreeList = $degreeList;
                $scope.degreeOrCertificate = degreeOrCertificate ? JSON.parse(JSON.stringify(degreeOrCertificate)) : degreeOrCertificate;
                $scope.uploader = [];
                $scope.data = {
                  type: 'Degree',
                  datecomp: moment().format(),
                  vendorid: icDetails.vendorid,
                  uploadFiles: []
                }
                if ($scope.degreeOrCertificate) {
                  $scope.data = $scope.degreeOrCertificate;
                }
                function assessmentDatePicker() {
                  function cb(start, end) {
                    $timeout(function () {
                      if (!start || !end) {
                        return;
                      }

                      $('#assessmentDate span').html(start.format('MM/DD/YYYY'));
                      $scope.data.datecomp = start.format();
                    }, 0);
                  }

                  $('#assessmentDate').daterangepicker({
                    singleDatePicker: true,
                    opens: 'right',
                    startDate: new Date($scope.data.datecomp),
			              endDate: new Date($scope.data.datecomp),
                  }, cb);

                  var text = moment($scope.data.datecomp).format('MM/DD/YYYY');
                  $('#assessmentDate span').html(text);
                }

                function init() {
                  $timeout(() => {
                    assessmentDatePicker();
                  }, 500);
                }

                $scope.buttonClick = function (e) {
                  if ($scope.uploader && $scope.uploader.queue && $scope.uploader.queue.length) {
                    $scope.scratchPad.isLoading = true;
                    $uploadS3Attachment.upload(client, $scope.uploader, $scope, 'control1')
                      .then((imageRes) => {
                        $scope.attachments = [];
                        if (imageRes && imageRes.length) {
                          imageRes.forEach(function (upload) {
                            if (upload.status) {
                              $scope.attachments.push(upload.clientUpload._id);
                            }
                          });
                          delete $scope.uploader;
                          $scope.submitDetails(e);
                        }
                      })

                      .catch((err) => {
                        let errMessage = err.message || err;
                        if (err && err.data && err.data.message) {
                          errMessage = err.data.message;
                        } else if (err && err.data && err.data.reason) {
                          errMessage = err.data.reason;
                        }
                        ngToast.create({
                          className: 'danger',
                          content: errMessage
                        });
                      })
                  } else {
                    $scope.submitDetails();
                  }
                }

                $scope.submitDetails = function (e) {
                  if ($scope.attachments && $scope.attachments.length) {
                    $scope.data.attachments = $scope.attachments;
                  }
                  $scope.scratchPad.isLoading = true;
                  $icDegreesOrCertificates.createOrUpdate(client, $scope.data)
                    .then((res) => {
                      if (res && res.status) {
                        ngToast.create({
                          content: `Degree or Certificate ${$scope.degreeOrCertificate ? 'updated' : 'created'} successfully`,
                          className: 'success'
                        });
                        finish.call(e, true);
                        modal.close(e);
                      }
                    })
                    .catch((err) => {
                      let errMessage = err.message || err;
                      if (err && err.data && err.data.message) {
                        errMessage = err.data.message;
                      } else if (err && err.data && err.data.reason) {
                        errMessage = err.data.reason;
                      }
                      ngToast.create({
                        content: errMessage,
                        className: 'danger'
                      });
                    })
                    .finally(() => {
                      $scope.scratchPad.isLoading = false;
                    })
                }

                $scope.close = function (e) {
                  finish.call(e, false);
                  modal.close(e);
                }
                init();
              }],
              modal: {
                dismissable: true,
                title: `${degreeOrCertificate ? 'Update' : 'Add'} Degree or Certificate | ${icDetails.firstname} ${icDetails.middlename ? icDetails.middlename : ''} ${icDetails.lastname}`,
                template: 'components/modal/tpl/add-degree-certificate.html'
              }
            }, 'modal-success', 'lg');
          };
        },
        addOrUpdateComment: function (commentDetails, title, finish) {
          finish = finish || angular.noop;
          return function () {
            var scratchPad = {
              error: null,
              success: null,
              commentDetails
            };

            var modal = openModal({
              controller: ['$scope', '$clientSwitcher', 'ngToast', function ($scope, $clientSwitcher, ngToast) {
                var client = $clientSwitcher.getCurrentClient();
                $scope.user = $auth.getCurrentUser();
                $scope.scratchPad = scratchPad;
                $scope.commentDetails = commentDetails ? JSON.parse(JSON.stringify(commentDetails)) : commentDetails;
                $scope.data = {
                  user: _.pick($scope.user, ['_id', 'name', 'email']),
                  createdOn: moment().format()
                }
                if ($scope.commentDetails) {
                  $scope.data = $scope.commentDetails;
                }

                $scope.buttonClick = function (e) {
                  finish.call(e, $scope.data);
                  modal.close(e);
                }

                $scope.close = function (e) {
                  finish.call(e, null);
                  modal.close(e);
                }
              }],
              modal: {
                dismissable: true,
                title: title ? title : `${commentDetails ? 'Update' : 'Add'} Comment`,
                template: 'components/modal/tpl/add-or-update-comment.html'
              }
            }, 'modal-success', 'lg');
          };
        }
      },
      attachments: {
        addAttachments: function (config, finish) {
          finish = finish || angular.noop;
          return function () {
            let scratchPad = {
              error: null,
              success: null,
              record: {},
              config,
              isLoading: false
            };
            let response = {
              status: false
            }

            let modal = openModal({
              controller: ['$scope', '$clientSwitcher', 'ngToast', '$uploadS3Attachment', function ($scope, $clientSwitcher, ngToast, $uploadS3Attachment) {
                let client = $clientSwitcher.getCurrentClient();
                $scope.scratchPad = scratchPad;
                $scope.user = $auth.getCurrentUser();
                $scope.scratchPad.record.uploader = [];

                $scope.buttonClick = function (e) {
                  if($scope.scratchPad.isLoading) return true;
                  if ($scope.scratchPad.record.uploader && $scope.scratchPad.record.uploader.queue && $scope.scratchPad.record.uploader.queue.length) {
                    $scope.scratchPad.isLoading = true;
                    let isExistExeFile = $scope.scratchPad.record.uploader.queue.filter((obj) => {
                      return !(/^[^.]+$|\.(?!(js|exe)$)([^.]+$)/.test(obj.name))
                    });
                    if (isExistExeFile && isExistExeFile.length > 0) {
                      ngToast.create({
                        className: 'danger',
                        content: 'Unsuccessful Uploaded: .js and .exe file type not supported'
                      });
                      return;
                    }
                    $uploadS3Attachment.upload(client, $scope.scratchPad.record.uploader, $scope, 'control1')
                      .then((imageRes) => {
                        $scope.scratchPad.record.newAttachments = [];
                        if (imageRes && imageRes.length) {
                          imageRes.forEach(function (upload) {
                            if (upload.status) {
                              $scope.scratchPad.record.newAttachments.push(upload.clientUpload._id);
                            }
                          });
                          response.status = true;
                          response.newAttachmentIds = $scope.scratchPad.record.newAttachments;
                          response.newAttachments = $scope.scratchPad.record;
                          modal.close();
                        }
                      })
                      .catch((err) => {
                        let errMessage = err.message || err;
                        if (err && err.data && err.data.message) {
                          errMessage = err.data.message;
                        } else if (err && err.data && err.data.reason) {
                          errMessage = err.data.reason;
                        }
                        ngToast.create({
                          className: 'danger',
                          content: errMessage
                        });
                      })
                      .finally(() => {
                        $scope.scratchPad.isLoading = false;
                      })
                  } else {
                    ngToast.create({
                      content: 'Please select file',
                      className: 'danger'
                    });
                  }
                };
              }],
              modal: {
                dismissable: true,
                title: config.title,
                template: 'components/modal/tpl/generic-add-attachment.html',
                buttons: [{
                  classes: 'btn-primary',
                  text: 'Add',
                }, {
                  classes: 'btn-default',
                  text: 'Close',
                  click: function (e) {
                    modal.close(e);
                  }
                }]
              }
            }, 'modal-success');
            modal.result.then(function (event) {
              finish.call(event, response);
            });
          };
        }
      },
      woForecasting: {
        addWO: function (finish) {
          finish = finish || angular.noop;

          return function () {
            var scratchPad = {
              isLoading: false,
              error: null
            };

            const modal = openModal({
              controller: ['$scope', '$clientSwitcher', 'ngToast', '$clientLanguages', '$serviceType', '$clientOffice', '$hearingType', '$WOForecasting', function ($scope, $clientSwitcher, ngToast, $clientLanguages, $serviceType, $clientOffice, $hearingType, $WOForecasting) {
                const client = $clientSwitcher.getCurrentClient();
                const currentUser = $auth.getCurrentUser();
                $(".modal-dialog").addClass("modal-dlg");
                $scope.scratchPad = scratchPad;
                $scope.languagesData = [];
                $scope.hearingCodes = [];
                $scope.languagesDataCount = 0;
                $scope.hearingType = $hearingType;
                $scope.serviceTypes = $serviceType;
                $scope.model = {};
                $scope.strSettings = function (str) {
                  return {
                      selectedStr: ' ',
                      noSelection: 'No ' + str + ' Selected',
                      allLabel: 'All ' + str
                  };
                };

                $scope.reloadLanguages = function (pagination) {
                  if (!pagination) {
                    $scope.languagesData = [];
                    pagination = {
                      page: 1,
                      pageSize: $scope.pageLimitForDD,
                      searchKey: null,
                      orderByValue: 'asc',
                      orderByField: 'name'
                    }
                  }
                  $clientLanguages.getLanguagesList(client, pagination)
                    .then((res) => {
                      if (res.status) {
                        if (res.languagesList && res.languagesList.length) {
                          res.languagesList.forEach((obj) => {
                            if (obj && obj.isEnabled) {
                              $scope.languagesData.push({
                                label: `${obj.name} (${obj.code})`,
                                value: obj._id,
                                code: obj.code
                              })
                            }
                          })
                          $scope.languagesDataCount = res.totalRecords;
                        } else {
                          console.log('Language Not Found.');
                          $scope.languagesDataCount = 0;
                        }
                      }
                    })
                    .catch((err) => {
                      let errMessage = err.message || err;
                      if (err && err.data && err.data.message) {
                        errMessage = err.data.message;
                      } else if (err && err.data && err.data.reason) {
                        errMessage = err.data.reason;
                      }
                      console.log(errMessage);
                      $scope.languagesDataCount = 0;
                    })
                    .finally(function () {
                      $scope.ddLoading = false;
                    })
                }

                $scope.loadPaginateData = function (searchKey, page, type) {
                  $scope.ddLoading = true;
                  let pagination = {
                    page: page,
                    pageSize: $scope.pageLimitForDD
                  }
                  let search = {};
                  switch (type) {
                    case 'languages':
                      if (page == 1) {
                        $scope.languagesData = [];
                      }
                      pagination.searchKey = (searchKey).toLowerCase();
                      pagination.orderByValue = 'asc';
                      pagination.orderByField = 'name';
                      $scope.reloadLanguages(pagination);
                      break;
                    default:
                      break;
                  }
                }

                $scope.loadClientOffices = function () {
                  $scope.reportingLocations = [];
                  $clientOffice.listAll(client).then(function (data) {
                    _.each(data, function (off) {
                      $scope.reportingLocations.push({
                        value: off._id,
                        internalId: off.internalId,
                        label: `${off.name} (${off.internalId})`,
                        timezone: off.timeZone,
                        businessDays: off.businessDays
                      });
                    });
                  });
                };

                $scope.loadHearingCode = function (e, selectedCourt) {
                  $scope.hearingCodes = [];
                  $scope.scratchPad.error = null;
                  $scope.courtTimeZone = null;
                  $scope.model.selectedDate = null;
                  $scope.model.selectedTime = null;
                  $scope.model.courtTimeZone = null;
                  $scope.model.courtBusinessDays = null;
                  $scope.model.internalId = null;
                  setTimeout(() => {
                    if (e) {
                      $scope.scratchPad.isLoading = true;
                      if (selectedCourt) {
                        let selectedCourtObj = $scope.reportingLocations.filter(item => item.value == selectedCourt);
                        $scope.courtTimeZone = selectedCourtObj[0].timezone.toString();
                        $scope.model.courtTimeZone = $scope.courtTimeZone;
                        $scope.model.courtBusinessDays =  selectedCourtObj[0].businessDays;
                        $scope.model.internalId = selectedCourtObj[0].internalId;
                        $clientOffice.getOfficeByInternalId(client, selectedCourtObj[0].internalId)
                          .then(res => {
                            if (res.status) {
                              $scope.hearingCodes = res.result.map(item => ({ label: item.internalId, value: item.internalId }))
                            }
                            $scope.scratchPad.isLoading = false;
                          })
                          .catch(err => {
                            console.log(err)
                            $scope.scratchPad.isLoading = false;
                            $scope.scratchPad.error = err.message || err;
                          })
                      }
                    }
                  }, 0)
                }

                $scope.setDateForTime = function () {
                  if ($scope.model.courtBusinessDays) {
                    let courtStartTime = $scope.model.selectedDate;
                    let dayOfDate = moment($scope.model.selectedDate).day() >= 1 ? moment($scope.model.selectedDate).day() - 1 : 6 ;
                    courtStartTime = $scope.model.courtBusinessDays[dayOfDate]
                    let d =  moment($scope.model.selectedDate).set('minute', courtStartTime.min);
                    $scope.model.selectedTime =  new Date(d);
                  } else {
                    $scope.model.selectedTime =  $scope.model.selectedDate;
                  }
                }
                function init() {
                  $scope.reloadLanguages();
                  $scope.loadClientOffices();
                }

                $scope.buttonClick = function (e) {
                  if ($scope.scratchPad.isLoading) return;
                  $scope.scratchPad.isLoading = true;
                  if ($scope.model.languageId && Array.isArray($scope.model.languageId)) {
                    $scope.model.languageId = $scope.model.languageId[0];
                  }
                  let selectedLang = $scope.languagesData.filter((i) => i.value ==  $scope.model.languageId);
                  $scope.model.langCode = selectedLang[0].code;
                  $WOForecasting.create(client, $scope.model)
                    .then(res => {
                      ngToast.create({
                        className: 'success',
                        content: 'WO Forecast Created Successfully.'
                      });
                      finish.call(e, true);
                      modal.close(e);
                      $scope.scratchPad.isLoading = false;
                    })
                    .catch(err => {
                      console.log(err)
                      ngToast.create({
                        className: 'danger',
                        content: err.message || err
                      });
                      $scope.scratchPad.isLoading = false;
                    })
                }

                $scope.close = function (e) {
                  finish.call(e, false);
                  modal.close(e);
                }
                init();
              }],
              modal: {
                dismissable: true,
                title: 'Add Forecasted WO',
                template: 'components/modal/tpl/add-forecasted-wo.html'
              }
            }, 'modal-success');
          }
        }
      },
      userSession: {
        userConcurrentDetails: function (member, finish) {
          finish = finish || angular.noop;
          return function () {
            var scratchPad = {
              isEnabled: false,
              user: member
            };
            var modal = openModal({
              controller: ['$scope', '$cognito', '$showError', function ($scope, $cognito, $showError) {
                $scope.scratchPad = scratchPad;
                $scope.isLoading = false;
                $scope.userConcurrentSession = [];
                function init() {
                  $scope.isLoading = true;
                  $cognito.getUserConcurrentSession($scope.scratchPad.user.email)
                    .then((data) => {
                      if (data) {
                        $scope.userConcurrentSession = data.active_sessions;
                      }
                    })
                    .catch((err) => {
                      console.log(err)
                      $showError(err);
                    })
                    .finally(() => {
                      $scope.isLoading = false;
                      $scope.$apply();
                    })
                }

                $scope.setDateTime = function (issuedAt) {
                  return moment.unix(issuedAt).format('MM-DD-YYYY hh:mm a')
                }

                $scope.buttonClick = function (e) {
                  $scope.isLoading = true;
                  $cognito.clearUserConcurrentSession($scope.scratchPad.user.email)
                    .then((data) => {
                      finish.call(e, true);
                      modal.close(e);
                    })
                    .catch((err) => {
                      $showError(err);
                    })
                    .finally(() => {
                      $scope.isLoading = false;
                      $scope.$apply();
                    })
                }

                $scope.close = function (e) {
                  finish.call(e, false);
                  modal.close(e);
                }
                init();
              }],
              modal: {
                dismissable: true,
                title: 'User Sessions',
                template: 'components/modal/tpl/user-sessions.html',
              }
            }, 'modal-success');
          };
        }
      },
      userModals: {
        userDevice: function (client, member, finish) {
          finish = finish || angular.noop;
          return function () {
            var scratchPad = {
              client,
              isEnabled: false,
              user: member
            };
            var modal = openModal({
              controller: ['$scope', '$user', '$showError', function ($scope, $user, $showError) {
                $scope.scratchPad = scratchPad;
                $scope.isLoading = false;
                $scope.userDevices = [];

                function init() {
                  $scope.isLoading = true;
                  $user.getUserDevices($scope.scratchPad.client, $scope.scratchPad.user.userId)
                    .then((data) => {
                      if (data && data.status) {
                        $scope.userDevices = data.result;
                      }
                    })
                    .catch((err) => {
                      console.log(err)
                      $showError(err);
                    })
                    .finally(() => {
                      $scope.isLoading = false;
                    })
                }
                $scope.buttonClick = function (e) {
                  $scope.isLoading = true;
                  $user.deleteUserDevices(client,  $scope.scratchPad.user.userId)
                    .then((data) => {
                      finish.call(e, true);
                      modal.close(e);
                    })
                    .catch((err) => {
                      $showError(err);
                    })
                    .finally(() => {
                      $scope.isLoading = false;
                    })
                }

                $scope.close = function (e) {
                  finish.call(e, false);
                  modal.close(e);
                }
                init();
              }],
              modal: {
                dismissable: true,
                title: 'User Devices',
                template: 'components/modal/tpl/user-devices.html',
              }
            }, 'modal-success');
          };
        }
      },
      commonApp: {
        syncDifference: function(defaultTitle, filter, column, objectDetails, finish) {
          finish = finish || angular.noop;
          return function () {
            var scratchPad = {
              filter,
              objectDetails,
              title: defaultTitle,
              column
            };
            var title =defaultTitle || 'Sync History'
            const modal = openModal({
              controller: ['$scope',  '$clientSwitcher', 'ngToast', '$syncHistory', function ($scope,  $clientSwitcher,ngToast, $syncHistory) {
                const client = $clientSwitcher.getCurrentClient();
                $scope.scratchPad = scratchPad;
                $scope.collections = [];
                $scope.pagination = {
                  currentPage: 1,
                  page: 1,
                  total: 0,
                  pageLimit: 20,
                  itemsPerPage: 20
                };
                $scope.buttonClick = function (e) {
                  modal.close(e);
                }
                $scope.close = function (e) {
                  finish.call(e, false);
                  modal.close(e);
                }
                $scope.getSyncHistory = function () {
                  if ($scope.scratchPad.isSyncing) return
                  $scope.scratchPad.isSyncing = true;
                  $syncHistory.getLogs(client, $scope.scratchPad.filter)
                    .then((res) => {
                      if (res && res.status)  {
                        $scope.collections = res.result;
                      }
                    })
                    .catch((err) => {
                      ngToast.create({
                        content: err.message || 'somethingwent wrong!',
                        className: 'error'
                      });
                    })
                    .finally(() => {
                      $scope.scratchPad.isSyncing = false;
                    })
                }
                $scope.filterData = function (item) {
                  let searchStr = $scope.filter.searchText ? $scope.filter.searchText.toLowerCase() : '';
                  if (searchStr) {
                    return true
                  } else {
                    return ((item.hasDistance === $scope.filter.hasDistance));
                  }
                }
                function init() {
                  $scope.getSyncHistory()
                }
                $scope.getType = function (val) {
                  return typeof val
                }
                // $scope.isDate = function(val) {
                //   if(typeof val === "number" ) return false;
                //   let date = new Date(val.toString())
                //   let d = moment(val)
                //   return date instanceof Date && !isNaN(date) && d && d.isValid() && typeof date === "object";
                // }
                $scope.getValue = function(val) {
                  if(typeof val === "number"  || !val) return val;
                  let date = new Date(val.toString())
                  let d = moment(val)
                  let isDate = moment(val, moment.ISO_8601, true).isValid();
                  if(date instanceof Date && !isNaN(date) && d && d.isValid() && isDate && typeof date === "object"){
                    return moment(val).format('MM-DD-YYYY hh:mm A');
                  }
                  return val;
                }
                $scope.hasKeys = function(obj) {
                  if (obj) {
                    return !!(Object.keys(obj)).length;
                  }
                  return false
                }
                init()
              }],
              modal: {
                title: title,
                dismissable: true,
                template: 'components/modal/tpl/job-sync-history.html',
                buttons: [{
                  classes: 'btn-default',
                  text: 'Close',
                  click: function (e) {
                    modal.close(e);
                  }
                }]
              }
            }, 'modal-success', 'xl');
          }
        },
      },
    };

    return modals;
  }
// Dependency Injection
ModalFactory.$inject = ["$rootScope","$uibModal","$auth","$filter","$window","$authStorage","$clientTask","AudioPlayList","FileUploader","$q","$officeOperations","$timeout","OSI","OPI","VRI","INTERPRETER_DISPUTE","COURT_DECLINE","SOSI_DISPUTE","COURT_APPROVE","INTERPRETER_APPROVE","MY_RESOURCES","$timezones","$courtOperations", "LazyLoadService"];
