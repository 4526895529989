
'use strict';

export const TaskBucketUnresolvedFactory  = function (TaskBucket) {
  function TaskBucketUnresolved(opts) {
    TaskBucket.call(this, opts);
    this.isUnresolved = true;
    this.filterOptions.splice(1, 1);
  }

  TaskBucketUnresolved.prototype = Object.create(TaskBucket.prototype);
  TaskBucketUnresolved.prototype.constructor = TaskBucketUnresolved;

  return TaskBucketUnresolved;
}
 

// Dependency Injection
TaskBucketUnresolvedFactory.$inject = ['TaskBucket'];
