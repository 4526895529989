


'use strict';

export const serviceDetailViewDirective  = function ($rootScope, $stateParams, $state, $window, $activeClient, $auth, $clientSwitcher, $servicePanel, ngToast, $uploadS3Attachment, $serviceComments, $servicePanelStatus, Modal) {
        return {
            restrict: 'E',
            templateUrl: 'app/account/services/service-panel/service-detail/service-detail-view.html',
            scope: {

            },
            link: function (scope, element, attrs) {
                scope.data = {
                    newComment: '',
                    userProfile: 'SOSi',
                    isLoadingComments: false,
                    isLoading: false,
                    error: null,
                    isUserServicePortal: false
                };
                scope.masterService = {};
                let killClientWatch, currentCli = $clientSwitcher.getCurrentClient();
                let statusList = ['quote_requested', 'quote_approved', 'work_in_process', 'work_completed', 'work_accepted', 'payment', 'service_completed'];
                scope.commentPagination = {
                    currentPage: 1,
                    page: 1,
                    total: 0,
                    pageSize: 20,
                    itemsPerPage: 20
                };
                scope.serviceStatusList = $servicePanelStatus()//['quote_requested', 'quote_approved', 'work_in_process', 'work_completed', 'work_accepted', 'pending_payment', 'service_completed']
                scope._layout = {
                    prevState: $rootScope._previousState && ($rootScope._previousState.name == '' || $rootScope._previousState == 'app.account.services.servicePanel.serviceInvoice') ? null : $rootScope._previousState
                }
                // scope.commentPagination.total = $selectedService.totalComments
                scope.onClickStatus = null;
                scope.statusActivity = [];
                scope.hasPermission = {};
                function init() {
                    scope.data.isLoading = true;
                    scope.masterService.isLoading = true;
                    killClientWatch = $activeClient(function (client) {
                        if (!client) {
                            scope.data.isLoading = false;
                            return;
                        }
                        currentCli = client;
                        let userPermissions = $auth.getCurrentUser();
                        scope.data.userProfile = userPermissions.name;
                        scope.data.isUserServicePortal = userPermissions.isUserServicePortal;
                        scope.data.userPermissions = userPermissions.permissions || [];
                        if (scope.data.userPermissions && scope.data.userPermissions.length) {
                            scope.hasPermission.changeStatus = scope.data.userPermissions.includes('UPDATE_SERVICE_JOB_STATUS') || false;
                            scope.hasPermission.quoteServiceJob = scope.data.userPermissions.includes('QUOTE_SERVICE_JOB') || false;
                            scope.hasPermission.acceptedServiceJob = scope.data.userPermissions.includes('SERVICE_JOB_ACCEPTED') || false;
                            scope.hasPermission.completedServiceJob = scope.data.userPermissions.includes('SERVICE_JOB_COMPLETED') || false;
                        }

                        scope.getServiceById();

                        scope.paymentStatusList = [
                            // {
                            //     title: 'Invoice Accepted',
                            //     btnName: 'Accept',
                            //     isUserServicePortal: scope.data.isUserServicePortal,
                            //     status: 'Invoice Generated'
                            // },
                            {
                                title: 'Payment Sent',
                                btnName: 'View',
                                class: 'btn-secondary',
                                isUserServicePortal: scope.data.isUserServicePortal,
                                status: 'Invoice Generated',
                                isShowStatus: false
                            },
                            {
                                title: 'Payment Sent',
                                btnName: 'Pay',
                                class: 'btn-success',
                                isUserServicePortal: scope.data.isUserServicePortal,
                                status: 'Invoice Generated',
                                isShowStatus: true
                            },
                            {
                                title: 'Payment Received',
                                btnName: 'Receive',
                                class: 'btn-success',
                                isUserServicePortal: !scope.data.isUserServicePortal,
                                status: 'Payment Sent',
                                isShowStatus: true
                            }
                        ];
                        // scope.masterService.isLoading = false;
                    });

                }
                scope.pullBack = function () {
                    $window.history.back();
                }
                scope.$on('$destroy', function () {
                    if (killClientWatch) {
                        killClientWatch();
                    }
                });
                scope.getServiceById = function () {
                    scope.data.isLoading = true;
                    $servicePanel.getServiceById($clientSwitcher.getCurrentClient(), $stateParams.id).then((res) => {
                        scope.masterService = res.status ? angular.copy(res.service) : {};
                        if (!res.status) {
                            scope.data.isLoading = false;
                            scope.data.error = res.message
                        } else {
                            scope.data.error = null;
                            scope.data.serviceRefId = scope.masterService._id || null;
                            scope.setStatusActivity();
                            scope.loadComments()
                        }
                    }, err => {

                    })
                }
                scope.setStatusActivity = function () {
                    let status = ['quote_requested', 'quote_approved', 'work_in_process', 'work_completed', 'work_accepted', 'payment', 'service_completed']
                    let statusIndex = status.indexOf(scope.masterService.status);
                    scope.statusActivity = status.slice(0, (statusIndex + 1))
                    // let statusActivity = _.groupBy(scope.masterService.statusActivity, 'status');
                    // scope.statusActivity = Object.keys(statusActivity) || [];
                    let lastCompletedStatus = scope.statusActivity[scope.statusActivity.length - 1];
                    let lastCompletedStatusIndex = statusList.indexOf(lastCompletedStatus);
                    scope.onClickStatus = statusList.length == (lastCompletedStatusIndex + 1) ? null : statusList[lastCompletedStatusIndex + 1];
                }
                scope.getService = function () {
                    scope.masterService.isLoading = true;
                    scope.data.isLoading = true;
                    $servicePanel.getServiceById(currentCli, scope.masterService._id).then((res) => {
                        if (res.status) {
                            scope.masterService = angular.copy(res.service);
                            scope.masterService.userComments = [];
                            scope.masterService.isLoading = false;
                            scope.masterService.uploader = [];
                            scope.loadComments();
                        } else {

                        }
                    }, err => {
                        console.log(err)
                        // $state.go('app.account.services.servicePanel.index');
                    })
                }
                scope.updateDetail = function (isUpdateDetail) {
                    $servicePanel.update(currentCli, scope.masterService)
                        .then(function (result) {
                            if (result && result.status) {
                                ngToast.create({
                                    className: 'success',
                                    content: isUpdateDetail ? 'Service Updated Successfully' : 'File Uploaded Successfully'
                                });
                                // $state.go('app.account.services.servicePanel.index');
                                scope.getService()
                            }
                            scope.masterService.isLoading = false;
                        })
                        .catch((err) => {
                            let errMessage = err.message || err;
                            if (err && err.data && err.data.message) {
                                errMessage = err.data.message;
                            } else if (err && err.data && err.data.reason) {
                                errMessage = err.data.reason;
                            }
                            ngToast.create({
                                className: 'danger',
                                content: errMessage
                            });
                            scope.masterService.isLoading = false;
                        })
                }
                scope.submitNewComment = function () {
                    if (scope.data.isLoadingComments) return;
                    scope.data.isLoadingComments = true;
                    $serviceComments.create(currentCli, scope.data)
                        .then(res => {
                            scope.data.isLoadingComments = false;
                            scope.data.newComment = '';
                            scope.masterService.userComments = [res].concat(scope.masterService.userComments || [])
                            scope.commentPagination.total = scope.commentPagination.total + 1;
                            if (scope.masterService.userComments.length > scope.commentPagination.pageSize) {
                                scope.masterService.userComments = scope.masterService.userComments.slice(0, scope.commentPagination.pageSize)
                            }
                        }, err => {
                            scope.data.isLoadingComments = false;
                            console.log(err)
                        })
                }
                scope.onUpdate = function (form) {
                    // console.log(form)
                    if (form.$invalid || !currentCli || scope.data.isLoadingonsite) return;
                    // console.log(scope.masterService)
                    if (scope.masterService.uploader && scope.masterService.uploader.queue && scope.masterService.uploader.queue.length) {
                        let isExistExeFile = scope.masterService.uploader.queue.filter((obj) => {
                            return !(/^[^.]+$|\.(?!(js|exe)$)([^.]+$)/.test(obj.name))
                        });
                        if (isExistExeFile && isExistExeFile.length > 0) {
                            ngToast.create({
                                className: 'danger',
                                content: 'Unsuccessful Uploaded: .js and .exe file type not supported'
                            });
                            return;
                        }
                        scope.masterService.isLoading = true;
                        $uploadS3Attachment.upload(currentCli, scope.masterService.uploader, scope, 'control1')
                            .then((imageRes) => {
                                scope.masterService.newAttachments = [];
                                if (imageRes && imageRes.length) {
                                    imageRes.forEach(function (upload) {
                                        if (upload.status) {
                                            scope.masterService.newAttachments.push(upload.clientUpload._id);
                                        }
                                    });
                                    angular.forEach(
                                        angular.element("input[type='file']"),
                                        function (inputElem) {
                                            angular.element(inputElem).val(null);
                                        });
                                    scope.updateDetail();
                                }
                            })
                            .catch((err) => {
                                let errMessage = err.message || err;
                                if (err && err.data && err.data.message) {
                                    errMessage = err.data.message;
                                } else if (err && err.data && err.data.reason) {
                                    errMessage = err.data.reason;
                                }
                                ngToast.create({
                                    className: 'danger',
                                    content: errMessage
                                });
                                scope.masterService.isLoading = false;
                            })
                    } else {
                        // scope.updateDetail();
                        ngToast.create({
                            className: 'danger',
                            content: 'Please select file'
                        });
                    }
                };

                (scope.onReset = function (form) {
                    angular.forEach(
                        angular.element("input[type='file']"),
                        function (inputElem) {
                            angular.element(inputElem).val(null);
                        });
                    scope.masterService = angular.copy(scope.masterService);
                    if (form) form.$setUntouched();
                })();

                scope.loadComments = function () {
                    if (!scope.masterService._id) return;
                    scope.commentsLoading = true;
                    $serviceComments.getList(currentCli, scope.commentPagination, { serviceId: scope.masterService._id }).then(res => {
                        scope.masterService.userComments = res.result;
                        scope.commentPagination.total = res.totalRecords;
                        scope.commentsLoading = false;
                        scope.data.isLoading = false;
                    }, err => {
                        console.log(err)
                        scope.commentsLoading = false;
                        scope.data.isLoading = false;
                    })
                }

                scope.quoteRequested = function (title, isAcceptWithComment) {
                    Modal.servicePanel.quoteRequested(scope.masterService, title, isAcceptWithComment, function (res) {
                        if (res.status) {
                            scope.getServiceById();
                        }
                    })();
                }

                scope.serviceJobWorkCompleted = function (title, isAfterRejectedWorkComplete) {
                    Modal.servicePanel.serviceJobWorkCompleted(scope.masterService, title, isAfterRejectedWorkComplete, function (res) {
                        if (res.status) {
                            scope.getServiceById();
                        }
                    })();
                }

                scope.acceptWorkAction = function (title) {
                    Modal.servicePanel.acceptWorkAction(scope.masterService, title, function (res) {
                        if (res.status) {
                            scope.getServiceById();
                        }
                    })();
                }

                scope.serviceJobPayment = function (paymentStatusObj, isInvoiceGenerated) {
                    if (paymentStatusObj.btnName == 'View') {
                        if (scope.masterService && !scope.masterService.invoiceRefId && !scope.masterService.invoiceRefId._id) return;
                        if (scope.data.isUserServicePortal) {
                            $state.go('app.account.services.servicePanel.serviceInvoice', { id: scope.masterService.invoiceRefId._id })
                        } else if (!scope.data.isUserServicePortal) {
                            $state.go('app.account.dashboard.invoiceDetail', { id: scope.masterService.invoiceRefId._id })
                        }
                    } else {
                        Modal.servicePanel.serviceJobPayment(scope.masterService, paymentStatusObj, isInvoiceGenerated, function (res) {
                            if (res.status) {
                                scope.getServiceById();
                            }
                        })();
                    }
                }
                scope.selectedStatus = null;
                scope.OnStatusChange = function (status) {
                    scope.selectedStatus = status;
                    if (!scope.data.isUserServicePortal) {
                        if (status == 'work_in_process' && scope.statusActivity.indexOf(status) < 0) {
                            scope.quoteRequested('Work In Process', true);
                        } else if (status == 'work_completed' && scope.statusActivity.indexOf(status) < 0) {
                            scope.serviceJobWorkCompleted('Work Completed', false);
                        } else if (status == 'service_completed' && scope.statusActivity.indexOf(status) < 0) {
                            scope.serviceJobPayment({ title: 'Service Completed', btnName: 'Complete' });
                        }
                        else {
                            scope.onClickStatus = status;
                        }
                    } else {
                        scope.onClickStatus = status;
                    }
                }

                scope.setStatusColor = function (status, statusActivity, index) {
                    let lastCompletedStatus = statusActivity[statusActivity.length - 1];
                    let lastCompletedStatusIndex = statusList.indexOf(lastCompletedStatus);
                    if (index == (lastCompletedStatusIndex + 1)) {
                        return true;
                    }
                    // let completedStatusIndex = statusActivity.indexOf(status) >= 0;
                    // if(completedStatusIndex) return scope.statusColor = 'text-success';
                }

                scope.statusDisabled = function (status, statusActivity, index) {
                    let lastCompletedStatus = statusActivity[statusActivity.length - 1];
                    let lastCompletedStatusIndex = statusList.indexOf(lastCompletedStatus);
                    if (index == (lastCompletedStatusIndex + 1)) return true;
                    let completedStatusIndex = statusActivity.indexOf(status) >= 0;
                    return completedStatusIndex;
                }

                scope.showCompleted = function (eventStatus, status) {
                    if (['Quote Requested', 'Quote Accepted', 'Work In Process', 'Work Completed'].indexOf(eventStatus) >= 0 && ['quote_requested', 'quote_approved', 'work_in_process', 'work_completed'].indexOf(status) >= 0) {
                        return true;
                    } else if (['Work Accepted'].indexOf(eventStatus) >= 0 && ['work_accepted'].indexOf(status) >= 0) {
                        return true;
                    } else if (['Payment Received'].indexOf(eventStatus) >= 0 && ['payment'].indexOf(status) >= 0) {
                        return true;
                    } else if (['Service Completed'].indexOf(eventStatus) >= 0 && ['service_completed'].indexOf(status) >= 0) {
                        return true;
                    }
                    return false;
                }

                scope.setCompletedRejectColor = function (quoteActivity) {
                    if (['Work Rejected'].indexOf(quoteActivity.eventStatus) >= 0 && ['work_accepted'].indexOf(quoteActivity.status) >= 0) {
                        return true;
                    } else if (['Work Completed'].indexOf(quoteActivity.eventStatus) >= 0 && ['work_accepted'].indexOf(quoteActivity.status) >= 0 && quoteActivity.requestedQuoteType) {
                        return true;
                    }
                    return false;
                }

                scope.showEventStatus = function (status) {
                    if (['work_in_process', 'work_completed', 'work_accepted', 'payment', 'service_completed'].indexOf(status) >= 0) {
                        return true;
                    }
                    return false;
                }

                scope.showInvoice = function (quoteActivity) {
                    if (quoteActivity && quoteActivity.invoiceLink) {
                        window.open(quoteActivity.invoiceLink, '_blank');
                    }
                }

                scope.getStatusCount = function (status) {
                    // if (scope.statusActivity.indexOf(status) >= 0) {
                    //     return 0;
                    // }
                    let activity = scope.masterService.quoteActivity.filter((obj) => obj.status == status);
                    return activity.length;
                }
                init()
            }
        }
    } 
 

// Dependency Injection
serviceDetailViewDirective.$inject = ['$rootScope', '$stateParams', '$state', '$window', '$activeClient', '$auth', '$clientSwitcher', '$servicePanel', 'ngToast', '$uploadS3Attachment', '$serviceComments', '$servicePanelStatus', 'Modal'];

// angular.module('coordinateApp').directive('serviceDetailView', serviceDetailViewDirective);
