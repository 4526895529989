


'use strict';

export const DatabaseFactory  = function ($resource, $q, $http, appConfig) {
        return $resource(appConfig.apiUrl + '/api/admin-database-collection/:id/:controller', {id: '@_id'}, {
            databaseCollection: {
                method: 'GET',
                params: {
                    controller: 'database-Collection'
                }
            },
            exportCollectionRecord: {
                method: 'GET',
                params: {
                    id: '@_id',
                    controller: 'export-collection-record'
                }
            }
        });
    } 
 

// Dependency Injection
DatabaseFactory.$inject = ['$resource', '$q', '$http', 'appConfig'];
