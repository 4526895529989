
'use strict';

export const $clientInterpreterService  = function (ClientInterpreter, $http) {
        return {

            getInterpreterInfo: function (client, interpreterId) {
                return ClientInterpreter.getInterpreterInfo({
                    id: client._id || client,
                    controller: interpreterId
                }).$promise;
            },

            getInterpreterUsers: function(client) {
                return ClientInterpreter.getInterpreterUsers({
                    id: client._id || client,
                }).$promise;
            },
            getAllInterpreters: function (client, filter) {
                return ClientInterpreter.getAllInterpreters({
                    id: client._id || client,
                    filter
                }).$promise;
            },
            interpreterWorkReport: function(client, filter) {
                return ClientInterpreter.interpreterWorkReport({
                    id: client._id || client,
                    filter
                }).$promise;
            },
            getSignedUrl: function (client, fileName) {
                return ClientInterpreter.getSignedUrl({
                    id: client._id || client,
                    fileName
                }).$promise;
            },
            icBulkUploadingImg: function (client, data) {
                return ClientInterpreter.icBulkUploadingImg({
                    id: client._id || client,
                    data
                }).$promise;
            },
            getResourceFilter: function (client, filter) {
                return ClientInterpreter.getResourceFilter({
                    id: client._id || client,
                    filter
                }).$promise;
            },
            getInterpreterListInElisa: function (client, searchStr, paginationData) {
                return ClientInterpreter.getInterpreterListInElisa({
                    id: client._id || client,
                    searchStr: searchStr || '',
                    page: paginationData && paginationData.page,
                    pageSize: paginationData && paginationData.pageSize
                }).$promise;
            },
            updateInterpreterDetail: function (client, data) {
                return ClientInterpreter.updateInterpreterDetail({
                    id: client._id || client,
                    data
                }).$promise;
            },
            getInterpreterTabCount: function (client, data) {
                return ClientInterpreter.getInterpreterTabCount({
                    id: client._id || client,
                    data
                }).$promise;
            },
            getInterpretersByVendorId: function (client, vendorIds) {
                return ClientInterpreter.getInterpretersByVendorId({
                    id: client._id || client,
                    data: vendorIds
                }).$promise;
            },
            getHomeCourtInterpreter: (client, filter) => {
                return ClientInterpreter.getHomeCourtInterpreter({
                    id: client._id || client,
                    filter
                }).$promise;
            },
            getRTWMatchCourtInterpreter: (client, filter) => {
                return ClientInterpreter.getRTWMatchCourtInterpreter({
                    id: client._id || client,
                    filter
                }).$promise;
            },
            getFTIMatchCourtInterpreter: (client, filter) => {
                return ClientInterpreter.getFTIMatchCourtInterpreter({
                    id: client._id || client,
                    filter
                }).$promise;
            },
            getRTWLangInterpreter: (client, filter) => {
                return ClientInterpreter.getRTWLangInterpreter({
                    id: client._id || client,
                    filter
                }).$promise;
            },
            createInterpreterUser: function (client, emailIds) {
                return ClientInterpreter.createInterpreterUser({
                    id: client._id || client,
                    data: emailIds
                }).$promise;
            },
            interpreterAvailableWO: function (client, filter) {
                return ClientInterpreter.interpreterAvailableWO({
                    id: client._id || client,
                    filter
                }).$promise;
            },
            sendNextDayPushReminder: function (client, data) {
                return ClientInterpreter.sendNextDayPushReminder({
                    id: client._id || client,
                    data
                }).$promise;
            },
            
            COITimeReportExport: function(client, filter){      
                return $http({
                   url: '/api/interpreter/'+ (client._id || client) +'/export-interpreter-work-report', 
                   method: "POST",
                   params: {
                    filter
                   },
                   responseType: "arraybuffer"
                 });
            },
        };
    }
// Dependency Injection


// Dependency Injection
$clientInterpreterService.$inject = ['ClientInterpreter', '$http'];
