'use strict';


export const formLinguistUserDirective = function ($rootScope, $q, $auth, $clientSwitcher, $clientUser,
        $client, $clientUserSetting, $passwordStrengthValidator, $states, $Linguist, $timezones
    ) {
        return {
            restrict: 'E',
            templateUrl: 'components/forms/linguist-user.html',
            scope: {
                user: '=ngModel',
                onSubmit: '=formOnSubmit',
                requirePassword: '=formRequirePassword'
            },
            link: function (scope, el, attrs) {
                scope.client = $clientSwitcher.getCurrentClient();;
                scope.loadingInfo = false;
                scope.loading = true;
                scope.languageServices = $Linguist.getServiceLanguages();
                scope.timezones = [
                    { label: "Atlantic Standard/Daylight Time", value: "AST4ADT" },
                    { label: "Eastern Standard/DaylightTime", value: "EST5EDT" },
                    { label: "Central Standard/Daylight Time", value: "CST6CDT" },
                    { label: "Mountain Standard/Daylight Time", value: "MST7MDT" },
                    { label: "Pacific Standard/Daylight Time", value: "PST8PDT" },
                    { label: "Alaska Standard/Daylight Time", value: "AKST9AKDT" },
                    { label: "Hawaii-Aleutian Standard/Daylight Time", value: "HAST10HADT" },
                    { label: "Eastern Standard Time", value: "EST" }];
                console.log(scope.languageServices)
                scope.model = {

                }
                scope.states = _.sortBy($states, 'label');
            }
        }
    }
// Dependency Injection
formLinguistUserDirective.$inject = ["$rootScope","$q","$auth","$clientSwitcher","$clientUser","$client","$clientUserSetting","$passwordStrengthValidator","$states","$Linguist","$timezones"];
