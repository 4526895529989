'use strict';

export const MainConfig = function ($stateProvider) {
    $stateProvider
      .state('app.main', {
        url: '/',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./main/main.html', '36d924ca'); }],
        controller: 'MainCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./main/main.controller.js', 'MainCtrl'); }]
                      }
      });
  }
// Dependency Injection
MainConfig.$inject = ["$stateProvider"];
