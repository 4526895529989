


'use strict';

export const ClientSurveyQuestionFactory  = function ($resource, appConfig) {
    return $resource(appConfig.apiUrl + '/api/client-survey-questions/:id/:controller', {
      id: '@_id'
    },
    {
      getQuestions: {
        method: 'GET',
        params: {
          controller: ''
        } 
      },
      getQuestion: {
        method: 'GET',
        params: {
          controller: 'detail'
        } 
      },
      createQuestion: {
          method: 'POST',
          params: {
              controller: 'create'
          }
      },
      updateQuestion: {
          method: 'POST',
          params: {
              controller: 'update'
          }
      },
      deleteQuestion: {
          method: 'POST',
          params: {
              controller: 'delete'
          }
      }
    });
  } 
 

// Dependency Injection
ClientSurveyQuestionFactory.$inject = ['$resource', 'appConfig'];
