'use strict';

export const MobileAppsConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard.settings.mobileApps', {
        url: '/mobile-apps',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/mobile_apps/mobile-apps.html', '375d188a'); }],
        controller: 'DashboardSettingsMobileAppsCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/mobile_apps/mobile-apps.controller.js', 'DashboardSettingsMobileAppsCtrl'); }]
                      },
        data: {
          ability: 'update.settings'
        }
      })
      .state('app.account.dashboard.settings.mobileApps.index', {
        url: '/mobile-apps',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/mobile_apps/index/index.html', '47ed8ff8'); }],
        controller: 'DashboardSettingsMobileAppsIndexCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/mobile_apps/index/index.controller.js', 'DashboardSettingsMobileAppsIndexCtrl'); }]
                      },
        data: {
          ability: 'update.settings'
        }
      })
      .state('app.account.dashboard.settings.mobileApps.create', {
        url: '/create',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/mobile_apps/create/create.html', '3bb08f58'); }],
        controller: 'DashboardSettingsMobileAppsCreateCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/mobile_apps/create/create.controller.js', 'DashboardSettingsMobileAppsCreateCtrl'); }]
                      },
        data: {
          ability: 'update.settings'
        }
      })
      .state('app.account.dashboard.settings.mobileApps.users', {
        url: '/users',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/mobile_apps/users/users.html', '6739d20a'); }],
        controller: 'DashboardSettingsMobileAppsUsersCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/mobile_apps/users/users.controller.js', 'DashboardSettingsMobileAppsUsersCtrl'); }]
                      },
        data: {
          ability: 'update.settings'
        }
      });
  }
// Dependency Injection
MobileAppsConfig.$inject = ["$stateProvider"];
