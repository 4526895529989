
'use strict';

export const $clientReportBucketsUploadService  = function (ClientReportBucketsUpload) {
    return {
      create: function (client, data) {
        return ClientReportBucketsUpload.create({
          _id: client._id || client,
          bucket: data
        }).$promise;
      },
      getBucketUpload: function (client, bucketId, filter, paginationData) {
        return ClientReportBucketsUpload.getBucketUpload({
          id: client._id || client,
          controller: bucketId,
          filter: filter,
          page: paginationData && paginationData.page,
          pageSize: paginationData && paginationData.pageSize
        }).$promise;
      },
      reportBuckets: function (client, filter) {
        return ClientReportBucketsUpload.reportBuckets({
          _id: client._id || client,
          filter: filter
        }).$promise;
      },
      removeAttachments: function (client, bucket, attachmentIds) {
        return ClientReportBucketsUpload.removeAttachments({
          _id: client._id || client,
          bucketId: bucket._id || bucket,
          data: attachmentIds
        }).$promise;
      },
      getReportsFolderDetails: function (client, filter) {
        return ClientReportBucketsUpload.getReportsFolderDetails({
          _id: client._id || client,
          filter
        }).$promise;
      },
      createFilesOrFolder: function (client, data) {
        return ClientReportBucketsUpload.createFilesOrFolder({
          _id: client._id || client,
          data
        }).$promise;
      },
      deleteAttachment: function (client, data) {
        return ClientReportBucketsUpload.deleteAttachment({
          _id: client._id || client,
          docId: data.docId,
          attachmentId: data.attachmentId
        }).$promise;
      },
      updateFolder: function (client, data) {
        return ClientReportBucketsUpload.updateFolder({
          _id: client._id || client,
          data
        }).$promise;
      },
      deleteFolder: function (client, folderId) {
        return ClientReportBucketsUpload.deleteFolder({
          _id: client._id || client,
          folderId
        }).$promise;
      },
      updateExistFile: function (client, data) {
        return ClientReportBucketsUpload.updateExistFile({
          _id: client._id || client,
          data: data || {}
        }).$promise
      }
    };
  }
// Dependency Injection


// Dependency Injection
$clientReportBucketsUploadService.$inject = ['ClientReportBucketsUpload'];
