'use strict';


export const formTelephonicInterpretationDirective = function (Modal, $fieldOfInterpretation, $q, $timezones, $clientSwitcher, $clientLanguages, $interpretationMethods) {
        return {
            restrict: 'E',
            templateUrl: 'components/forms/telephonic-interpretation.html',
            scope: {
                telephonicInterpretation: '=ngModel',
                onSubmit: '=formOnSubmit',
                onReset: '=formOnReset',
                onUpdateDetail: '=onUpdateDetail'
            },
            link: function (scope, el, attrs) {
                // scope.clientSettings = function () { return $clientSwitcher.getFeatureOptions('client.settings'); };
                const client = $clientSwitcher.getCurrentClient();
                // console.log('------', scope.telephonicInterpretation)
                scope.data = {
                    openPicker: false,
                    openPickerTime: false,
                    date: new Date()
                };
                
                let timing = scope.telephonicInterpretation._id && scope.telephonicInterpretation.deadline ? new Date(scope.telephonicInterpretation.deadline) : null;
                scope.telephonicInterpretation.selectedDate = timing;
                scope.telephonicInterpretation.selectedTime = timing;

                scope.interpretationMethods = $interpretationMethods;
                scope.fieldOfInterpretation = $fieldOfInterpretation;
                scope.ddSettings = function (type) {
                    var officeStr = type;
                    return {
                        selectedStr: 'Selected ' + officeStr + ': ',
                        noSelection: 'No ' + officeStr + ' Selected',
                        allLabel: 'All ' + officeStr
                    };
                };

                scope.setDateForTime = function () {
                    scope.telephonicInterpretation.selectedTime = scope.telephonicInterpretation.selectedDate;
                }

                // scope.setTimeForDate = function () {
                //     scope.telephonicInterpretation.interpretationDate = scope.telephonicInterpretation.interpretationTime;
                // }

                function getLanguages() {
                    scope.activeLanguages = [];
                    $clientLanguages.getLanguages(client)
                        .then(({ data, status }) => {
                            if (status) {
                                data.forEach((obj) => {
                                    if (obj && obj.isEnabled) {
                                        scope.activeLanguages.push({
                                            label: `${obj.name} (${obj.code})`,
                                            value: obj._id
                                        })
                                    }
                                })
                            }
                        })
                }

                getLanguages();
            }
        };
    }
// Dependency Injection
formTelephonicInterpretationDirective.$inject = ["Modal","$fieldOfInterpretation","$q","$timezones","$clientSwitcher","$clientLanguages","$interpretationMethods"];
