
'use strict';

export const $clientInterpreterResourcesService  = function (ClientInterpreterResources, $http, ngToast) {
    return {
     create: function(client, data) {
        return ClientInterpreterResources.create({
          _id: client._id || client,
          title: data.messageTitle,
          description: data.description,
          attachments: data.attachments,
          type: data.type,
          knowledgeLibraryBucketId: data.knowledgeLibraryBucketId,
          interpreterVendorIds: data.interpreterVendorIds,
          systemWide: data.systemWide,
          courts: data.reportingCourts,
          uploadType: data.uploadType,
          folderName: data.name,
          parentFolder: data.parentFolderId,
          attachmentDate: data.attachmentDate
        }).$promise;
      },
      getInterpreterResourcesData: function(client, filter) {
        return ClientInterpreterResources.getInterpreterResourcesData({
          id: client._id || client,
          type: filter.type,
          limit: filter.limit,
          knowledgeLibraryBucketId: filter.knowledgeLibraryBucketId,
          page: filter.page,
          search: filter.search,
          isInternalSearch: filter.isInternalSearch || false,
          uploadType: filter.uploadType,
          parentFolderId: filter.parentFolderId,
          isSearchFiles: filter.isSearchFiles || undefined,
          isFirstLevelSearch: filter.isFirstLevelSearch || undefined,
          isRootSearch: filter.isRootSearch || undefined,
          tagDate : filter.tagDate || undefined
        }).$promise;
      },
      deleteAttachment: function(client, data) {
        return ClientInterpreterResources.deleteAttachment({
          _id: client._id || client,
          docId: data.docId,
          attachmentId: data.attachmentId,
          type: data.type || null
        }).$promise;
      },
      editCCMessage: function(client, data) {
        return ClientInterpreterResources.editCCMessage({
          _id: client._id || client,
          title: data.messageTitle,
          description: data.description,
          attachments: data.attachments,
          messageId: data.messageId
        }).$promise;
      },
      deleteCCMessage: function(client, messageId) {
        return ClientInterpreterResources.deleteCCMessage({
          _id: client._id || client,
          messageId: messageId
        }).$promise;
      },
      getCCMessagesData: function(client, filter) {
        return ClientInterpreterResources.getCCMessagesData({
          _id: client._id || client,
          type: filter.type,
          limit: filter.limit,
          page: filter.page,
          recipients: filter.recipients,
          location: filter.location || null,
          searchValue: filter.searchValue,
          returnEveryRecipient: filter.hasOwnProperty('returnEveryRecipient')? filter.returnEveryRecipient: true
        }).$promise;
      },
      getInterpreters: function(client, filter) {
        return ClientInterpreterResources.getInterpreters({
          id: client._id || client,
          filter: filter
        }).$promise;
      },
      getAllRegisteredInterpreters: function(client, fields) {
        return ClientInterpreterResources.getAllRegisteredInterpreters({
          id: client._id || client,
          fields
        }).$promise;
      },
      setAttachmentFavorite: function(client, data) {
        return ClientInterpreterResources.setAttachmentFavorite({
          _id: client._id || client,
          data
        }).$promise;
      },
      deleteFolder: function(client, folderId) {
        return ClientInterpreterResources.deleteFolder({
          _id: client._id || client,
          folderId
        }).$promise;
      },
      updateFolder: function(client, data) {
        return ClientInterpreterResources.updateFolder({
          _id: client._id || client,
          data
        }).$promise;
      },
      setAttachmentPin: function(client, data) {
        return ClientInterpreterResources.setAttachmentPin({
          _id: client._id || client,
          data
        }).$promise;
      },
      downloadAttachments: function(client, data) {
        return $http({
          url: '/api/interpreter-resources/'+ (client._id || client) +'/downloads-attachments',
          method: "POST",
          params: {
            data: JSON.stringify(data),
          }
        })
        .success(function(data, status, headers, config) {
          if(data.status === false) {
            return console.log(data.reason);
          }

          function base64toBlob(base64Data, contentType) {
            contentType = contentType || '';
            var sliceSize = 1024;
            var byteCharacters = atob(base64Data);
            var bytesLength = byteCharacters.length;
            var slicesCount = Math.ceil(bytesLength / sliceSize);
            var byteArrays = new Array(slicesCount);

            for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
                var begin = sliceIndex * sliceSize;
                var end = Math.min(begin + sliceSize, bytesLength);

                var bytes = new Array(end - begin);
                for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
                    bytes[i] = byteCharacters[offset].charCodeAt(0);
                }
                byteArrays[sliceIndex] = new Uint8Array(bytes);
            }
            return new Blob(byteArrays, { type: contentType });
          }

          var blob = base64toBlob(data, 'attachment/zip');
          var element = angular.element('<a></a>');
          element.attr({
            href: URL.createObjectURL(blob),
            target: '_blank',
            download: 'Attachments.zip'
          });
          element[0].click();
        })
        .error(function(data, status, headers, config) {
          if (data && data.message && data.message.includes('25MB')) {
            // alert(data.message);
            return ngToast.create({
              content: data.message,
              className: 'danger'
          });
          } else {
            // alert('Error while downloading the tasks');
            return ngToast.create({
              content: 'Error while downloading the file!',
              className: 'danger'
          });
          }
        });
      },
      myResourcesCreate: function(client, data) {
        return ClientInterpreterResources.myResourcesCreate({
          _id: client._id || client,
          data
        }).$promise;
      },
      getMyResourcesRecords: function(client, filter) {
        return ClientInterpreterResources.getMyResourcesRecords({
          id: client._id || client,
          filter
        }).$promise;
      },
      getUserFavorites: function (client, filter) {
        return ClientInterpreterResources.getUserFavorites({
          id: client._id || client,
          filter
        }).$promise
      },
      getUserPinned: function (client, filter) {
        return ClientInterpreterResources.getUserPinned({
          id: client._id || client,
          filter
        }).$promise
      },
      updateFile: function (client, data) {
        return ClientInterpreterResources.updateFile({
          _id: client._id || client,
          data
        }).$promise
      },
      fileExist: function (client, filter) {
        return ClientInterpreterResources.fileExist({
          _id: client._id || client,
          filter: filter || {}
        }).$promise
      },
      updateExistFile: function (client, data) {
        return ClientInterpreterResources.updateExistFile({
          _id: client._id || client,
          data: data || {}
        }).$promise
      }
    };
  }
// Dependency Injection


// Dependency Injection
$clientInterpreterResourcesService.$inject = ['ClientInterpreterResources', '$http', 'ngToast'];
