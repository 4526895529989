'use strict';

export const MissedAptsConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard.missed-apts', {
        url: '/missed-apts',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/missed-apts/missed-apts.html', 'c4276c8a'); }],
        controller: 'DashboardMissedAptsCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/missed-apts/missed-apts.controller.js', 'DashboardMissedAptsCtrl'); }]
                      },
        data: {
          ability: 'client.missed-apts',
          permission: 'MISSED_APTS_READ'
        }
      });
  }
// Dependency Injection
MissedAptsConfig.$inject = ["$stateProvider"];
