
'use strict';

export const $clientTaskService  = function ($q, ClientTask, $http, INTERPRETER_DISPUTE, COURT_APPROVE, COURT_DECLINE, TASK_STATE_CHECKEDIN, TASK_STATE_CHECKEDOUT,
  TASK_STATE_RELEASE,TASK_STATE_IC_RELEASE_REQUEST, SOSI_DISPUTE, INTERPRETER_APPROVE, SOSI_ACCEPTED, TASK_STATE_JUDGEDOUT,TASK_STATE_JUDGEDIN, TASK_STATE_EXTEND,
    TASK_STATE_OPI_FIN_PENDING_APPROVAL, TASK_STATE_VRI_FIN_PENDING_APPROVAL,TASK_STATE_RELEASE_EVENT,TASK_STATE_DENY_FOUT
  ) {
  return {
    createTask: function (client, task) {
      return ClientTask.clientTaskCreeate({ id: client._id||client, task: task }).$promise;
    },
    getCollections: function (client, activeTasksOnly) {
      return ClientTask.clientCollections({ id: client._id||client, activeTasksOnly: !!activeTasksOnly ? 1 : 0, userOnly: 1}).$promise;
    },
    getTasks: function (collection, search) {
      return ClientTask.clientCollectionTasks({ id: collection._id||collection, filterBy: search}).$promise;
    },
    filterTasks: function(client, search){
      return ClientTask.filterTasks({id: client._id || client, filterBy: search}).$promise;
    },
    taskDetail: function(task){
      return ClientTask.taskDetail({id: task.id || task}).$promise;
    },
    resolveTask: function (task, message, markClosed, leaveOpen, resolveCode) {
      return ClientTask.clientTaskResolve({
        id: task.id||task,
        task: task,
        message: message,
        markClosed: markClosed,
        leaveOpen: leaveOpen || !markClosed,
        resolveCode: resolveCode
      }).$promise;
    },
    assignTask: function (task, priority, user, email, due, fallbackPriority, fallbackUser, fallbackEmail, fallbackDue, comment) {
      return ClientTask.clientTaskAssign({
        id: task.id,
        task: task,
        priority: priority || task.priority,
        user: (!!user ? user._id||user : null),
        email: email||null,
        due: due,
        fallbackPriority: fallbackPriority,
        fallbackUser:     (!!fallbackUser ? fallbackUser._id||fallbackUser : null),
        fallbackEmail:    fallbackEmail||null,
        fallbackDue:      fallbackDue,
        comment: comment
      }).$promise;
    },
    forwardTask: function (task, user) {
      return ClientTask.clientTaskForward({
        id: task.id||task,
        user: user._id||user
      }).$promise;
    },
    forwardTaskEmail: function (task, userObj) {
      return ClientTask.forwardTaskEmail({
        id: task.id||task,
        userObj: userObj
      }).$promise;
    },
    archiveTask: function (task) {
      return ClientTask.clientTaskArchive({
        _id: task.id||task
      }).$promise;
    },
    updateTitle: function (task, title) {
      return ClientTask.updateTitle({
        _id: task.id||task,
        value: title
      }).$promise;
    },
    updateDesc: function (task, desc) {
      return ClientTask.updateDesc({
        _id: task.id||task,
        value: desc
      }).$promise;
    },
    updateDue: function (task, due) {
      return ClientTask.updateDue({
        _id: task.id||task,
        value: due
      }).$promise;
    },
    updatePriority: function (task, priority) {
      return ClientTask.updatePriority({
        _id: task.id||task,
        value: priority
      }).$promise;
    },
    updateFallbackDue: function (task, due) {
      return ClientTask.updateFallbackDue({
        _id: task.id||task,
        value: due
      }).$promise;
    },
    updateFallbackPriority: function (task, priority) {
      return ClientTask.updateFallbackPriority({
        _id: task.id||task,
        value: priority
      }).$promise;
    },
    updateLocation: function (task, location) {
      return ClientTask.updateLocation({
        _id: task.id||task,
        value: location
      }).$promise;
    },
    updateCollection: function (task, collection, type) {
      return ClientTask.updateCollection({
        _id: task.id||task,
        collection: collection,
        type: type
      }).$promise;
    },
    updateAttachments: function(task) {
      return ClientTask.updateAttachments({
        _id: task.id || task,
        task: task
      }).$promise;
    },
    removeAttachments: function(task, attachmentIds) {
      return ClientTask.removeAttachments({
        _id: task.id || task,
        data: attachmentIds
      }).$promise;

    },
    getTaskFromToken: function(token) {
      return ClientTask.getTaskFromToken({
        id: token
      }).$promise;
    },
    getRecord: function(token) {
      return ClientTask.getRecord({
        id: token
      }).$promise;
    },
    updateExternalTaskStatus: function(token, message, uploads, status) {
      return ClientTask.updateExternalTaskStatus({
        id: token,
        message: message,
        uploads: uploads,
        status: status
      }).$promise;
    },
    resolveExternalTask: function(token, message, markClosed, leaveOpen) {
      return ClientTask.resolveExternalTask({
        id: token,
        message: message,
        markClosed: markClosed,
        leaveOpen: leaveOpen || !markClosed
      }).$promise;
    },
    reopen: function(task){
      return ClientTask.reopen({
        _id: task.id || task
      }).$promise;
    },
    reopenTask: function (task, priority, user, email, due, fallbackPriority, fallbackUser, fallbackEmail, fallbackDue, comment) {
      return ClientTask.clientTaskReopen({
        id: task.id,
        task: task,
        priority: priority || task.priority,
        user: (!!user ? user._id||user : null),
        email: email||null,
        due: due,
        fallbackPriority: fallbackPriority,
        fallbackUser:     (!!fallbackUser ? fallbackUser._id||fallbackUser : null),
        fallbackEmail:    fallbackEmail||null,
        fallbackDue:      fallbackDue,
        comment: comment
      }).$promise;
    },
    removeTask: function(task){
      return ClientTask.clientTaskRemove({
        id: task.id
      }).$promise;
    },
    getAssignedTasksCount: function(client, user) {
      return ClientTask.assignedTasksCount({
        id : client._id || client,
        userId: user._id || user
      }).$promise;
    },
    taskAnalysis: function(client) {
      return ClientTask.taskAnalysis({
        id: client._id || client
      }).$promise;
    },
    taskHistory: function(client, criteria){
      return ClientTask.taskHistory({
        id: client._id || client,
        regions: criteria.regions,
        stores: criteria.stores,
        status: criteria.status,
        archive: criteria.archive,
        exportData: false
      }).$promise;
    },
    taskHistoryExport: function(client, criteria){
       return $http({
          url: '/api/client-tasks/'+ (client._id || client) +'/task-history',
          method: "GET",
          params: {
            regions: criteria.regions,
            stores: criteria.stores,
            status: criteria.status,
            archive: criteria.archive,
            exportData: true
          }
        })
        .success(function(data, status, headers, config) {
          var element = angular.element('<a></a>');

          var blob = new Blob([data], { type: "application/octet-stream" });
          var objectUrl = URL.createObjectURL(blob);

          element.attr({
            href: objectUrl,
            target: '_blank',
            download: 'tasks_' + (new Date().toISOString().substr(0,10)) + ".csv"
          });
          element[0].click();
        })
        .error(function(data, status, headers, config) {
          alert('Error while downloading the tasks');
        });
    },
    setState: function(task, state, message) {
      return ClientTask.setState({
        _id: task.id || task,
        state: state,
        message: message
      }).$promise;
    },
    updateTaskdata: function (task, taskdata) {
      return ClientTask.updateTaskdata({
        _id: task.id||task,
        value: taskdata
      }).$promise;
    },
    clientCheckInFacility: function (client, record) {
      let recordObj = { coi: record.coi }
      if (record.updateData && record.updateData.date) {
        recordObj.updateData = {
          date: record.updateData && record.updateData.date ? record.updateData.date : undefined,
          comment: record.comment,
          isIcAddedWoEvent:  record.isIcAddedWoEvent || record.isICAddedWoEvent,
          isICAddedWoEvent:  record.isICAddedWoEvent || record.isIcAddedWoEvent
        }
        recordObj.isIcAddedWoEvent =  record.isIcAddedWoEvent || false;
      }
      return ClientTask.clientCheckInFacility({
        id: client._id || client,
        record: recordObj
      }).$promise;
    },
    clientCheckOutFacility: function (client, record) {
      record.caseData = record.caseData._id || record.caseData;
      return ClientTask.clientCheckOutFacility({ id: client._id||client, record: record }).$promise;
    },
    clientJudgeInFacility: function (client, record) {
      record.caseData = record.caseData._id || record.caseData;
      return ClientTask.clientJudgeInFacility({ id: client._id||client, record: record }).$promise;
    },
    clientJudgeOutFacility: function (client, record) {
      record.caseData = record.caseData._id || record.caseData;
      record.updateData = {
        date: record.updateData.date,
        ecoi: record.updateData.ecoi,
        interpreter: record.updateData.interpreter,
        interPreCounselled: record.updateData.interPreCounselled,
        reqInterPreCounselling: record.updateData.reqInterPreCounselling,
        description: record.updateData.description,
        langName: record.updateData.langName,
        isRatingEnabled: record.updateData.isRatingEnabled,
        professionalism: record.updateData.professionalism,
        performance: record.updateData.performance,
        procedures: record.updateData.procedures
      }
      return ClientTask.clientJudgeOutFacility({ id: client._id||client, record: record }).$promise;
    },
    clientLunchOutInFacility: function (client, record) {
      return ClientTask.clientLunchOutInFacility({ id: client._id||client, record: record }).$promise;
    },
    earlyFOUTDenyRelease: function(client) {
      return ClientTask.earlyFOUTDenyRelease({ id: client._id || client }).$promise;
    },
    extendInterpreter: function(client, record) {
      return ClientTask.extendInterpreter({ id: client._id || client, record: record }).$promise;
    },
    releaseInterpreter: function(client, record) {
      return ClientTask.releaseInterpreter({ id: client._id || client, record: record }).$promise;
    },
    updateCaseData: function(client, record) {
      return ClientTask.updateCaseData({ id: client._id || client, record: record }).$promise;
    },
    denyFacilityOut: function(client, record) {
      return ClientTask.denyFacilityOut({id: client._id || client, record: record}).$promise;
    },
    approveEvent: function(client, record) {
      return ClientTask.approveEvent({id: client._id || client, record: record}).$promise;
    },
    disputeEvent: function(client, record) {
      return ClientTask.disputeEvent({id: client._id || client, record: record}).$promise;
    },
    icReleaseRequestEvent: function(client, record) {
      return ClientTask.icReleaseRequestEvent({id: client._id || client, record: record}).$promise;
    },
    updateEvents: function(client, record) {
      return ClientTask.updateEvents({id: client._id || client, record: record}).$promise;
    },
    removeStateActivity: function(client, data) {
      return ClientTask.removeStateActivity({id: client._id || client, data: data}).$promise;
    },
    exportRecords: function (task) {
      return $http({
        url: '/api/client-tasks/' + (task.id || task) + '/export-records',
        method: "GET",
        params: {
          timeOffset: new Date().getTimezoneOffset()
        }
      })
        .success(function (data, status, headers, config) {
          if (data.status === false) {
            return data;
          }
          let csvContent = "data:text/xml;charset=utf-8," + data;
          const encodedUri = encodeURI(csvContent);
          let link = document.createElement('a');
          link.setAttribute('href', encodedUri);
          link.setAttribute('download', `WorkOrder-${task.caseData.coi}.xml`);
          link.setAttribute('target', '_blank');
          document.body.appendChild(link);

          link.click();
        })
        .error(function (data, status, headers, config) {
          alert('Error while downloading the tasks');
        });
    },
    workOrderTasksCount: function(client, search){
      return ClientTask.workOrderTasksCount({id: client._id || client, filterBy: search}).$promise;
    },
    workOrderTasksDetail: function(client, search, selectForUI){
      return ClientTask.workOrderTasksDetail({id: client._id || client, filterBy: search, selectForUI}).$promise;
    },
    workOrderTaskCOIDetail: function(client, data){
      return ClientTask.workOrderTaskCOIDetail({id: client._id || client, data}).$promise;
    },
    workOrderTasksFilter: function(client, search){
      return ClientTask.workOrderTasksFilter({id: client._id || client, filterBy: search}).$promise;
    },
    getTaskHearingServiceTypeFilter: function(client, search){
      return ClientTask.getTaskHearingServiceTypeFilter({id: client._id || client, filterBy: search}).$promise;
    },
    assignInterpreter: function (client, data) {
      return ClientTask.assignInterpreter({ id: client._id || client, data }).$promise;
    },
    updateWODetails: function (client, data) {
      return ClientTask.updateWODetails({ id: client._id || client, data }).$promise;
    },
    workOrderFilterTasks: function(client, search){
      return ClientTask.workOrderFilterTasks({id: client._id || client, filterBy: search}).$promise;
    },
    downloadWorkOrderDetail: function (client, filter) {
      return $http({
        url: '/api/client-tasks/' + (client._id || client) + '/download-work-order-detail',
        method: "POST",
        params: {
          filterBy: JSON.stringify(filter),
          timeOffset: new Date().getTimezoneOffset(),
          userTimeZone: moment.tz.guess()
        },
        responseType: "arraybuffer"
      })
        .success(function (data, status, headers, config) {
          if (data.status === false) {
            return data;
          }

          saveAs(new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), `WO-${moment().format('MM/DD/YYYY HH:mm:ss')}.xlsx`);
        })
        .error(function (data, status, headers, config) {
          // alert('Error while downloading the tasks');
        });
    },
    printWorkOrderDetail: function(client, search){
      return ClientTask.printWorkOrderDetail({id: client._id || client, filterBy: search}).$promise;
    },
    cancelWO: function (client, data) {
      return ClientTask.cancelWO({ id: client._id || client, data }).$promise;
    },
    updateWOStatus: function (client, data) {
      return ClientTask.updateWOStatus({ id: client._id || client, data }).$promise;
    },
    updateWOComment: function (client, data) {
      return ClientTask.updateWOComment({ id: client._id || client, data }).$promise;
    },
    updateWOPaymentDetails: function (client, data) {
      return ClientTask.updateWOPaymentDetails({ id: client._id || client, data }).$promise;
    },
    getCOITimeReport: function(client, filter, paginationData) {
      return ClientTask.getCOITimeReport({
        id: client._id || client,
        filter,
        page: paginationData && paginationData.page,
        pageSize: paginationData && paginationData.pageSize
      }).$promise;
    },
    getTaskAppliedInterpreter: function(client, coi) {
      return ClientTask.getTaskAppliedInterpreter({
        id: client._id || client,
        coi
      }).$promise;
    },
    ReqCOITimeReportDownload: function(client, filter) {
      return ClientTask.ReqCOITimeReportDownload({
        id: client._id || client,
        filter
      }).$promise;
    },
    COITimeReportExport: function(client, filter){
      return $http({
         url: '/api/client-tasks/'+ (client._id || client) +'/download-coi-time-report',
         method: "POST",
         params: {
          filter
         },
         responseType: "arraybuffer"
       });
   },
    getJudgeName: function (activity, stateActivity) {
      if (activity && activity.isFlagEvent) return 'N/A';
      if (activity && activity.isFlagEventWithButton) return 'N/A';
      if (activity.state == TASK_STATE_CHECKEDIN || activity.state == TASK_STATE_CHECKEDOUT) {
        if (activity.state == TASK_STATE_CHECKEDOUT) {
          if (stateActivity) {
            var autoFOUT;
            stateActivity.forEach((sActivity) => {
              if (sActivity.state == TASK_STATE_JUDGEDOUT && sActivity.autoFacilityOut && sActivity.autoFacilityOut.isAutoFOut) {
                autoFOUT = sActivity;
              }
            })
            if (autoFOUT && autoFOUT.autoFacilityOut && autoFOUT.autoFacilityOut.isAutoFOut) {
              if (autoFOUT.performedJudge && autoFOUT.performedJudge.ijname) {
                return autoFOUT.performedJudge.ijname;
              }
              if (autoFOUT.user) {
                return autoFOUT.user.name || 'N/A';
              }
            }
          }
        }
        return 'N/A';
      }
      if (activity.state == TASK_STATE_RELEASE) {
        if (activity && activity.release && activity.release.judge) {
          return activity.release.judge.ijname;
        } else {
          return 'N/A';
        }
      }
      let events = [INTERPRETER_DISPUTE, COURT_DECLINE, SOSI_DISPUTE, COURT_APPROVE, INTERPRETER_APPROVE, TASK_STATE_IC_RELEASE_REQUEST, SOSI_ACCEPTED, 'Release Approved', 'Open', 'Confirmed', 'New', 'Assigned', 'Locating', 'Cancelled', 'Interpreter No Show', 'No Interpreter', 'Applied', 'Withdraw', 'Auto Withdraw'];
      if (events.indexOf(activity.state) > -1) {
        return 'N/A';
      }
      // if(activity.state == TASK_STATE_IC_RELEASE_REQUEST || activity.state == 'Release Approved'){
      //   return 'N/A';
      // }
      if (activity.state == TASK_STATE_RELEASE_EVENT && activity.releaseEvent) {
        const isCourtJudge = (currentUserData.settings.roles.findIndex(x => x.code === 'COURT_JUDGE') >= 0);
        if (!isCourtJudge) return 'N/A';
      }
      if (activity.performedJudge && activity.performedJudge.ijname) {
        return activity.performedJudge.ijname;
      }
      if (activity.user) {
        if (activity.state == TASK_STATE_LUNCHIN) return 'N/A';
        return activity.user.name || 'N/A';
      }
      return activity.message || 'N/A';
    },

    getEventUserName: function (activity) {
      if (activity.state == TASK_STATE_RELEASE) {
        if (activity && activity.release && activity.release.releaseType === 'judge_out') {
          return `System`;
        }
      }
      let flagEvents = [
        INTERPRETER_DISPUTE,
        COURT_DECLINE,
        SOSI_ACCEPTED,
        SOSI_DISPUTE,
        COURT_APPROVE,
        INTERPRETER_APPROVE];

      if (activity.isAutoApproved) {
        if (flagEvents.indexOf(activity.state) > -1) {
          if (activity && activity.isAutoApproved) {
            return `System`;
          }
        }
      }
      let events = ['Awaiting Interpreter Approval', 'Awaiting Court Acceptance', 'Awaiting SOSI Acceptance', TASK_STATE_JUDGEDOUT, TASK_STATE_RELEASE, TASK_STATE_CHECKEDOUT, TASK_STATE_OPI_FIN_PENDING_APPROVAL, TASK_STATE_VRI_FIN_PENDING_APPROVAL]
      if (events.indexOf(activity.state) > -1) {
        if (activity && activity.isFlagEventWithButton) {
          return `System`;
        } else {
          if (activity.autoJudgeOutIn) return 'System';
          else if (activity.release && activity.release.isAuto) return 'System';
          else if (activity.isAutoFout) return 'System';
        }
      }
      let eventStatus = ['Confirmed', 'New', 'Assigned', 'Locating', 'Cancelled', 'Interpreter No Show', 'No Interpreter', 'Open', 'Applied', 'Auto Withdraw', 'Withdraw'];
      if (eventStatus.indexOf(activity.state) > -1) {
        if (activity.source == 'Elisa' && !['Auto Withdraw'].includes(activity.state)) {
          return activity.user && activity.user.name
        }
        return `System`;
      }
      return activity.user && activity.user.name
    },
    getEventName: function (activity, stateActivity) {
      if (activity && activity.isFlagEvent) return activity.eventName;
      if (stateActivity) {
        if (activity.state == TASK_STATE_CHECKEDIN) {
          if (activity && activity.manual) {
            return `${activity.state} (Manual${activity.isEdited ? ', Edited' : ''})`;
          }
        }
        if (activity.state == TASK_STATE_CHECKEDOUT) {
          var autoFOUT;
          stateActivity.forEach((sActivity) => {
            if (sActivity.state == TASK_STATE_JUDGEDOUT && sActivity.autoFacilityOut && sActivity.autoFacilityOut.isAutoFOut) {
              autoFOUT = sActivity;
            }
          })
          if (autoFOUT && autoFOUT.autoFacilityOut && autoFOUT.autoFacilityOut.isAutoFOut) {
            return `${activity.state} (Auto${activity.isEdited ? ', Edited' : ''})`;
          }
          if (activity.isAutoFout) {
            return `${activity.state} (Auto)`;
          }
          if (activity.manual || activity.kioskEarlyFout) {
            if (activity.manual && activity.kioskEarlyFout)
              return `${activity.state} (Manual, Kiosk Early Fout${activity.isEdited ? ', Edited' : ''})`;
            if (activity.manual)
              return `${activity.state} (Manual${activity.isEdited ? ', Edited' : ''})`;
            if (activity.kioskEarlyFout)
              return `${activity.state} (Kiosk Early Fout${activity.isEdited ? ', Edited' : ''})`;
          };
        }
        if (activity.state == TASK_STATE_JUDGEDIN) {
          if (activity && activity.autoJudgeOutIn) {
            return `${activity.state} (Auto${activity.isEdited ? ', Edited' : ''})`;
          }
        }
        if (activity.state == TASK_STATE_JUDGEDOUT) {
          if (activity && activity.autoJudgeOutIn) {
            return `${activity.state} (Auto${activity.isEdited ? ', Edited' : ''})`;
          }
        }
        if (activity.state == TASK_STATE_LUNCHOUT) {
          if (activity && activity.autoLunchOut) {
            return `${activity.state} (Auto${activity.isEdited ? ', Edited' : ''})`;
          }
        }
        if (activity.state == TASK_STATE_EXTEND) {
          if (activity && activity.extend) {
            return `Extended till ${activity.isEdited ? '(Edited)' : ''}`;
          }
        }
        if (activity.state == TASK_STATE_RELEASE) {
          if (activity && activity.release) {
            if (activity.release.releaseType === 'now' && activity.release.isICAddedWOEvent) {
              return `Released (${activity.release && activity.release.isAuto ? 'Auto' : 'Manual'})`;
            } else if (activity.release.releaseType === 'now' && !activity.release.isAuto) {
              return `Released (Manual${activity.isEdited ? ', Edited' : ''})`;
            } else if (activity.release.releaseType === 'now' && activity.release.isAuto) {
              return `Released (Auto${activity.isEdited ? ', Edited' : ''})`;
            } else if (activity.release.releaseType === 'judge_out') {
              return `Released (on Judge OUT${activity.isEdited ? ', Edited' : ''})`;
            } else if (activity.release.releaseType === 'time') {
              return `Released (Auto${activity.isEdited ? ', Edited' : ''})`;
            }
          }
        }
        if (activity.state == TASK_STATE_DENY_FOUT && activity.denyFacilityOut) {
          return `${activity.state} ${activity.isEdited ? '(Edited)' : ''}`;
        }
        if (activity.state == TASK_STATE_RELEASE_EVENT && activity.releaseEvent) {
          if (activity.releaseEvent.releaseType === 'judge_out') {
            return `Pending Release (Next JOUT)`;
          }
          if (activity.releaseEvent.releaseType === 'time') {
            return `Pending Release (at ${moment(activity.releaseEvent.value).format('hh:mm A')})`;
          }
        }
        if (activity.state == TASK_STATE_RELEASE_APPROVED) {
          return `${activity.state} ${activity.isAutoEndroseApproved ? '(Auto)' : ''}`;
        }
      }
      return `${activity.state}${activity.isEdited ? '(Edited)' : activity.isICAddedWOEvent ? ' (Manual)' : ''}`;
    },
    getEventComment: (activity, stateActivity) => {
      if (activity && activity.isFlagEvent) return '';
      var editComment = null;
      if (activity.originalValue) {
        editComment = activity.comment || '';
        return `${editComment ? editComment : ''}`
      }
      if (activity.state == TASK_STATE_CHECKEDIN || activity.state == 'Open') {
        return activity.comment;
      }
      if (activity.state == TASK_STATE_JUDGEDIN) {
        var comment = '';
        if (activity.late) {
          comment = activity.late.comment ? activity.late.reason + ' | ' + activity.late.comment : activity.late.reason;
        }
        if (activity.early) {
          comment = activity.early.comment ? activity.early.comment : '';
        }
        if (activity.performedJudge && activity.performedJudge.comment) {
          return `${activity.performedJudge.comment} ${comment ? '<br>- ' + comment : ''}`;
        } else {
          return `${comment ? comment : ''}`;
        }
      }
      if (activity.state == TASK_STATE_CHECKEDOUT) {
        if (activity.comment && (activity.comment != '' || activity.comment != null)) {
          return activity.comment
        }
        var lateComment = '', icAppearedButNotUsed = '', icNotUsedDenyRelease = '', kioskLateFout, autoFOUTReason = '';
        if (activity.earlyOut) {
          lateComment = `${activity.earlyOut.reason} ${activity.earlyOut.comment ? ' | ' + activity.earlyOut.comment : ''}`;
        }
        if (activity.icAppearedButNotUsed) {
          icAppearedButNotUsed = activity.icAppearedButNotUsed;
        }
        if (activity.icNotUsedDenyRelease) {
          icNotUsedDenyRelease = activity.icNotUsedDenyRelease;
        }
        if (activity.kioskLateFout && activity.kioskLateFout.comment) {
          kioskLateFout = `Kiosk Late Facility OUT - Reason: ${activity.kioskLateFout.reason}. Comment: ${activity.kioskLateFout.comment}`;
        }
        var autoFOUT;
        stateActivity.forEach((sActivity) => {
          if (sActivity.state == TASK_STATE_JUDGEDOUT && sActivity.autoFacilityOut && sActivity.autoFacilityOut.isAutoFOut) {
            autoFOUT = sActivity;
          }
        })
        if (autoFOUT && autoFOUT.autoFacilityOut && autoFOUT.autoFacilityOut.isAutoFOut) {
          autoFOUTReason = autoFOUT.autoFacilityOut.reason ? autoFOUT.autoFacilityOut.reason : '';
        }
        return `${lateComment ? '- ' + lateComment + '<br>' : ''} ${icAppearedButNotUsed ? '- Interpreter appeared, but not Used(Release) | ' + icAppearedButNotUsed : ''} ${icNotUsedDenyRelease ? '<br>- Interpreter appeared, but not Used(Deny Release) | ' + icNotUsedDenyRelease : ''} ${editComment ? '- ' + editComment : ''} ${kioskLateFout ? kioskLateFout : ''} ${autoFOUTReason ? autoFOUTReason : ''}`;
      }
      if (activity.state == TASK_STATE_EXTEND) {
        if (activity && activity.extend) {
          return `${activity.extend.comments + '<br>'} ${editComment ? '- ' + editComment : ''}`;
        }
      }
      // #697 - Auto Release on Next Judge Out Event then comment display
      if (activity.state == TASK_STATE_RELEASE_EVENT) {
        if (activity && activity.releaseEvent && activity.releaseEvent.comments) {
          return `${activity.releaseEvent.comments + '<br>'} ${editComment ? '- ' + editComment : ''}`;
        }
      }
      if (activity.state == TASK_STATE_RELEASE) {
        if (activity && activity.release && activity.release.comments) {
          return `${activity.release.comments + '<br>'} ${editComment ? '- ' + editComment : ''}`;
        }
      }
      if (activity.state == TASK_STATE_DENY_FOUT && activity.denyFacilityOut) {
        if (activity.denyFacilityOut.comments) {
          return `${activity.denyFacilityOut.reason ? activity.denyFacilityOut.reason + ' | ' : ''} ${activity.denyFacilityOut.comments + '<br>'} ${editComment ? '- ' + editComment : ''}`;
        }
        return `${activity.denyFacilityOut.reason} ${editComment ? '<br>- ' + editComment : ''}`;
      }
      if (activity.state == TASK_STATE_IC_RELEASE_REQUEST) {
        return activity.releaseRequest && activity.releaseRequest.comment || '';
      }

      let disputeEvent = [INTERPRETER_DISPUTE, COURT_DECLINE, SOSI_DISPUTE];
      if (disputeEvent.indexOf(activity.state) > -1) {
        if (activity.disputeEvent && activity.disputeEvent.comments && activity.disputeEvent.comments.length > 0) {
          return activity.disputeEvent.comments[0].comment || ''
        }
      }

      return `${activity.performedJudge && activity.performedJudge.comment ? '<br>- ' + activity.performedJudge && activity.performedJudge.comment : activity.comment ? activity.comment : ''}`;
    }
  };
}
// Dependency Injection


// Dependency Injection
$clientTaskService.$inject = ['$q', 'ClientTask', '$http', 'INTERPRETER_DISPUTE', 'COURT_APPROVE', 'COURT_DECLINE', 'TASK_STATE_CHECKEDIN', 'TASK_STATE_CHECKEDOUT', 'TASK_STATE_RELEASE', 'TASK_STATE_IC_RELEASE_REQUEST', 'SOSI_DISPUTE', 'INTERPRETER_APPROVE', 'SOSI_ACCEPTED', 'TASK_STATE_JUDGEDOUT', 'TASK_STATE_JUDGEDIN', 'TASK_STATE_EXTEND', 'TASK_STATE_OPI_FIN_PENDING_APPROVAL', 'TASK_STATE_VRI_FIN_PENDING_APPROVAL', 'TASK_STATE_RELEASE_EVENT', 'TASK_STATE_DENY_FOUT'];
