'use strict';

export const AssignmentReportConfig = function ($stateProvider) {
    $stateProvider
      .state('app.print.assignment-report', {
        url: '/live-reports/assignment-report',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./print/live-reports/assignment-report/assignment-report.html', '8b95251d'); }],
        controller: 'PrintAssignmentReportCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./print/live-reports/assignment-report/assignment-report.controller.js', 'PrintAssignmentReportCtrl'); }]
                      }
      });
  }
// Dependency Injection
AssignmentReportConfig.$inject = ["$stateProvider"];
