import './recruiting-report.scss';
'use strict';

export const recruitingReportDirective = function () {
        return {
            restrict: 'E',
            templateUrl: 'app/account/dashboard/main-dashboard/sosi-one-reporting/tabs/recruiting-report/recruiting-report.html',
            scope: {},
            link: function (scope) {

                var init = function () {

                }
                init()
            }
        }
    }