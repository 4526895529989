'use strict';

export const workflowDirective = function () {
        return {
            restrict: 'E',
            templateUrl: 'app/account/dashboard/main-dashboard/pipline-operations/tabs/workflow/workflow.html',
            scope: {  },
            link: function (scope) {
                var init = function () {
                }
                init()
            }
        }
    }