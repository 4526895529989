


'use strict';

export const UserNotificationFactory  = function ($resource, appConfig) {
        return $resource(appConfig.apiUrl + '/api/user-notification/:id/:controller', {
            id: '@_id'
        },
            {
                getList: {
                    method: 'GET',
                    params: {
                        controller: ''
                    }
                },
                update: {
                    method: 'POST',
                    params: {
                        controller: 'update'
                    }
                },
            });
    } 
 

// Dependency Injection
UserNotificationFactory.$inject = ['$resource', 'appConfig'];
