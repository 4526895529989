'use strict';

export const workHistoryDirective = function ($timeout,  LazyLoadService ) {
                  return {
            restrict: 'E',
            templateUrl: 'app/account/dashboard/main-dashboard/interpreter-resource/tabs/work-history/work-history.html',
            scope: {},
            link: function(scope) {

  const lazyDirectives = [{"name":"bucketsDirective","path":import("@app/account/dashboard/tasks/tabs/buckets/buckets.directive.js")}];
  scope.lazyDirectivesLoaded = false;
  Promise.all(lazyDirectives.map(directive=>LazyLoadService.loadDirective(directive.path, directive.name))).then(() => {
          $timeout(function() {
            scope.lazyDirectivesLoaded = true;


                         scope.displayBuckets = ['Performed', 'In Facility'];
                var init = function () {
                    setTimeout(function () {
                        // $('#sectionBlock').children().attr("disabled","disabled");
                        // .block({ message: null });
                        // $("#sectionContent").block({ message: null,  overlayCSS: { backgroundColor: '#c2c2c2', cursor:'no-drop' }  });
                        // var con = document.getElementById("sectionContent");
                        // var lok = document.getElementById("sectionBlock");
                        // lok.style.height=con.clientHeight+"px";
                        // lok.style.width=con.clientWidth+"px";
                        // lok.style.display="block";

                        // $('#sectionBlock').append('<div style="position: absolute;top:0;left:0;width: 100%;height:100%;z-index:2;opacity:0.4;filter: alpha(opacity = 50)"></div>');

                    }, 150)
                }
                init()

                });
              });

            }
        }
    }
// Dependency Injection
workHistoryDirective.$inject = ["$timeout", "LazyLoadService"]
