export const $layoutItemsService = function ($auth, $activeClientSettings) {
  let showDisableTabs = false;
  $activeClientSettings(function (client, settings) {
      if (!client || !settings) { return;  }
      showDisableTabs = settings.showDisableTabs;
      // console.log('is disabled~~~~~~~~~~~~~~', settings.showDisableTabs)
  });

  return function (userPermissions, currentUser, settings) {
      if (settings) {
          // console.log('~set')
          showDisableTabs = settings && settings.showDisableTabs ? settings.showDisableTabs : false;
      }
      // $activeClientSettings(function (client, settings) {
      //     if (!client || !settings) { return;  }
      //     showDisableTabs = settings.showDisableTabs;
      //     console.log('is disabled', settings.showDisableTabs)
      // });
      // console.log('======', from,  showDisableTabs)
      let isInterpreterRole = $auth.isInterpreter();
      // if (currentUser && currentUser.settings && currentUser.settings.roles && currentUser.settings.roles.length) {
      //     isInterpreterRole = (currentUser.settings.roles).some(item => item.code == 'INTERPRETER')
      // }
      let pages = [{
          label: 'Interpreter Resources',
          value: 'interpreterResource',
          permissions: userPermissions.includes('INTERPRETER_RESOURCES_CENTER_READ'),
          icon: 'assets/images/interpreter-resources.svg',
          url: 'app.account.dashboard.mainDashboard.interpreterResource',
          position: 4,
          child: [
              {
                  value: 'interpreter_home',
                  label: 'Home',
                  permissions: userPermissions.includes('INTERPRETER_RESOURCES_HOME_TAB'),
                  isDisabled: (JSON.parse(isInterpreterRole) && (currentUser && currentUser.settings && currentUser.settings.internalId)) ? false : true,
              },
              {
                  value: 'work_history',
                  label: 'My Work Orders',
                  permissions: userPermissions.includes('INTERPRETER_RESOURCES_MY_WORK_ORDER_TAB'),
                  url: '',
                  // isDisabled: !showDisableTabs
              },
              {
                  value: 'open_work_orders',
                  label: 'Open Work Orders',
                  permissions: userPermissions.includes('INTERPRETER_RESOURCES_OPEN_WORK_ORDERS'),
                  url: ''
              },
              {
                  value: 'pay_history',
                  label: 'Pay History',
                  permissions: userPermissions.includes('PAY_HISTORY_TAB'),
                  isDisabled: !showDisableTabs
              },
              {
                  value: 'communications',
                  label: 'Communications',
                  permissions: userPermissions.includes('INTERPRETER_RESOURCES_COMMUNICATION_TAB'),
                  url: 'app.account.dashboard.mainDashboard.interpreterResource.communications',
                  isDisabled: !showDisableTabs
              },
              {
                  value: 'profile',
                  label: 'Profile',
                  permissions: userPermissions.includes('PROFILE_TAB'),
                  isDisabled: (JSON.parse(isInterpreterRole) && (currentUser && currentUser.settings && currentUser.settings.internalId)) ? false : true
              },
              {
                  value: 'contract_docs',
                  label: 'Contract Docs',
                  permissions: userPermissions.includes('CONTRACT_DOCS_TAB'),
                  isDisabled: !showDisableTabs
              },
              {
                  value: 'knowledge_library',
                  label: 'Knowledge Library',
                  permissions: userPermissions.includes('INTERPRETER_KNOWLEDGE_LIBRARY_TAB'),
                  url: ''
              },
              {
                  value: 'my_resources',
                  label: 'My Resources',
                  permissions: userPermissions.includes('INTERPRETER_MY_RESOURCES_TAB'),
                  url: ''
              },
              {
                  value: 'training',
                  label: 'Training',
                  permissions: userPermissions.includes('INTERPRETER_RESOURCES_TRAINING_TAB'),
                  url: ''
              }]
      },
      {
          label: 'Resource Operations',
          value: 'piplineOperations',
          permissions: userPermissions.includes('RESOURCE_OPS_READ'),
          icon: 'assets/images/pipeline.svg',
          position: 1,
          child: [{
              label: 'Dashboard',
              value: 'pipline',
              permissions: userPermissions.includes('RESOURCE_OPERATIONS_DASHBOARD_TAB'),
              position: 1
          },
          {
              label: 'Recruiting',
              value: 'recruiting',
              permissions: userPermissions.includes('RECRUITING_TAB'),
              position: 2,
              isDisabled: !showDisableTabs
          },
          {
              label: 'Resources',
              value: 'resources',
              permissions: userPermissions.includes('RESOURCES_TAB'),
              position: 3,
              // isDisabled: !showDisableTabs
          },
          {
              label: 'Workflow',
              value: 'workflow',
              permissions: userPermissions.includes('WORKFLOW_TAB'),
              position: 4,
              isDisabled: !showDisableTabs
          },
          {
              label: 'Analytics',
              value: 'resource-analytics',
              permissions: userPermissions.includes('RESOURCES_ANALYTICS_TAB'),
              position: 5,
              isDisabled: !showDisableTabs
          }]
      },
      {
          label: 'Fulfillment Operations',
          value: 'workOrders',
          permissions: userPermissions.includes('WORK_ORDER_READ'),
          icon: 'assets/images/work-order.svg',
          position: 2,
          child: [
          // {
          //     value: 'reporting_dashboard',
          //     label: 'Dashboard',
          //     permissions: userPermissions.includes('REPORTING_DASHBOARD'),
          //     position: 1,
          // },
          {
              value: 'dashboard_new',
              label: 'Dashboard',
              permissions: userPermissions.includes('REPORTING_DASHBOARD'),
              position: 1,
          },
          {
              value: 'assignment',
              label: 'Assignment',
              permissions: userPermissions.includes('ASSIGNMENT_TAB'),
              position: 2,
              // isDisabled: !showDisableTabs
          },
          {
              value: 'wo_status',
              label: 'WO Status',
              permissions: userPermissions.includes('WORK_ORDER_TAB'),
              position: 3,
          },
          {
              value: 'wo_forecasting',
              label: 'WO Forecasting',
              permissions: userPermissions.includes('WO_FORECASTING_TAB'),
              position: 3,
          },
          {
              value: 'travel',
              label: 'Travel',
              permissions: userPermissions.includes('TRAVEL_TAB'),
              position: 5,
              isDisabled: !showDisableTabs
          },
          {
              value: 'wo_invoices',
              label: 'WO Invoices',
              permissions: userPermissions.includes('WO_INVOICES_TAB'),
              position: 7
          },
          {
              value: 'coi_time_report',
              label: 'COI Time Report',
              permissions: userPermissions.includes('WO_INVOICES_TAB'),
              position: 8
          },
          {
              value: 'interpreter_work_report',
              label: 'Interpreter Job Report',
              permissions: userPermissions.includes('WO_INVOICES_TAB'),
              position: 9
          },
          {
              value: 'auto_assigned',
              label: 'Auto Assigned',
              permissions: userPermissions.includes('WO_INVOICES_TAB'),
              position: 10
          },
          {
              value: 'interpreter_scheduled_notifications',
              label: 'Interpreter Scheduled Notifications',
              permissions: userPermissions.includes('WO_INVOICES_TAB'),
              position: 11
          },
          {
              value: 'interpreter_next_day_pending_scheduled',
              label: 'Interpreter Next Day Pending Scheduled',
              permissions: userPermissions.includes('WO_INVOICES_TAB'),
              position: 12
          },
          // {
          //     value: 'completed_pending_payment',
          //     label: 'Completed Pending Payment',
          //     permissions: userPermissions.includes('COMPLETED_PENDING_PAYMENT_TAB'),
          // },
          // {
          //     value: 'payment_history',
          //     label: 'Payment History',
          //     permissions: userPermissions.includes('PAYMENT_HISTORY_TAB'),
          // },
          {
              value: 'sos_email_information',
              label: 'SOS Messages',
              permissions: userPermissions.includes('SOS_EMAIL_INFORMATION_TAB'),
              position: 4,
              // isDisabled: true
          }]
      },
      {
          label: 'Court Operations',
          value: 'mainCourtOperations',
          permissions: userPermissions.includes('COURT_OPS_READ'),
          icon: 'assets/images/court-operations.svg',
          position: 3,
          isDisabled: userPermissions.includes('COURT_OPS_READ_DISABLE'),
          child: [
          // {
          //     label: 'Court Operations',
          //     value: 'courtOperations',
          //     permissions: userPermissions.includes('COURT_OPS_READ'),
          //     position: 1,
          //     isDisabled: userPermissions.includes('COURT_OPS_READ_DISABLE')
          // },
          {
              label: 'Court Operations',
              value: 'courtOperationsNew',
              permissions: userPermissions.includes('COURT_OPS_READ'),
              position: 1,
              isDisabled: userPermissions.includes('COURT_OPS_READ_DISABLE')
          },  {
              label: 'VRI Work Orders',
              value: 'VRIWorkOrders',
              permissions: userPermissions.includes('VRI_WOS_READ'),
              position: 2,
              isDisabled: !showDisableTabs
          }]
      },
      {
          label: 'Reports',
          value: 'SOSIONEReporting',
          permissions: userPermissions.includes('SOSI_ONE_REPORTING_READ'),
          icon: 'assets/images/reports.svg',
          position: 5,
          child: [
              {
                  value: 'reports',
                  label: 'Reports Library (Old)',
                  permissions: userPermissions.includes('REPORTS_READ'),
              },
              {
                  value: 'reports-library',
                  label: 'Reports Library',
                  permissions: userPermissions.includes('REPORTS_LIBRARY_TAB'),
              },
              {
                  value: 'live_reports',
                  label: 'Live Reports',
                  permissions: userPermissions.includes('LIVE_REPORTS_READ'),
                  subChild: [{
                      value: 'resource-ops-dashboard',
                      label: 'Resource Ops Dashboard',
                      permissions: userPermissions.includes('LR_RESOURCE_OPS_DASHBOARD_TAB')
                  }, {
                      value: 'dashboard_new',
                      label: 'Fulfillment Ops Dashboard',
                      permissions: userPermissions.includes('LR_FULFILLMENT_OPS_DASHBOARD_TAB')
                  }, {
                      value: 'daily-report',
                      label: 'Daily Report',
                      permissions: userPermissions.includes('LR_DAILY_REPORT_VIEW')
                  }, {
                      value: 'assignment-report',
                      label: 'Assignment Report',
                      permissions: userPermissions.includes('LR_ASSIGNMENT_REPORT_VIEW'),
                      // isDisabled: true
                  }, {
                      value: 'interpreter-matrix',
                      label: 'Interpreter Matrix',
                      permissions: userPermissions.includes('LR_INTERPRETER_MATRIX_TAB')
                  }, {
                      value: 'recruiting-report',
                      label: 'Recruiting Report',
                      permissions: userPermissions.includes('LR_RECRUITING_REPORT_TAB'),
                      isDisabled: !showDisableTabs
                  }, {
                      value: 'dq-report',
                      label: 'DQ Reports',
                      permissions: userPermissions.includes('LR_DQ_REPORT_TAB')
                  }, {
                      value: 'sos_email_information',
                      label: 'SOS Emails',
                      permissions: userPermissions.includes('LR_SOS_EMAILS_TAB')
                  }, {
                      value: 'invoice',
                      label: 'Invoice',
                      permissions: userPermissions.includes('LR_INVOICE_TAB'),
                      isDisabled: !showDisableTabs
                  }]
              },
              {
                  value: 'analytics',
                  label: 'Analytics',
                  permissions: userPermissions.includes('ANALYTICS_TAB'),
                  isDisabled: !showDisableTabs
              }]
      },
      {
          label: 'Program Support',
          value: 'programSupport',
          permissions: userPermissions.includes('PROGRAM_SUPPORT_READ'),
          icon: 'assets/images/program-support.svg',
          position: 6,
          isDisabled: !showDisableTabs,
          child: [{
              label: 'Program Support',
              value: 'programSupport',
              permissions: userPermissions.includes('PROGRAM_SUPPORT_READ'),
              isDisabled: !showDisableTabs
          }]
      }];
      return pages
  }
}
$layoutItemsService.$inject = ["$auth","$activeClientSettings"];
