'use strict';

export const SurveysConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard.surveys', {
        url: '/surveys',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/surveys/surveys.html', 'ca41c3ca'); }],
        controller: 'DashboardSurveysCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/surveys/surveys.controller.js', 'DashboardSurveysCtrl'); }]
                      },
        data: {
          ability: 'client.surveys',
          permission: 'SURVEYS_READ'
        }
      });
  }
// Dependency Injection
SurveysConfig.$inject = ["$stateProvider"];
