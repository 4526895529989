
'use strict';

export const taskMapDirective  = function ($clientSwitcher, $performTaskArchive, $performTaskHistory, $performTaskCreate, $performTaskResolve, $performTaskAssign, $clientUpload, $string, Modal, $authStorage, $http, $clientOffice, $clientTask, $timeout, NgMap, $user){
  return {
    restrict: 'E',
    require: 'ngModel',
    replace: true,
    templateUrl: 'app/account/dashboard/tasks/map.html',
    scope: {
      task: '=ngModel',
      client: '=btClient'
    },
    link: function(scope, el, attr){
        var task = scope.task;
        var bounds = new google.maps.LatLngBounds();
        var createMarkersForNearbyUsers = function(lat, long, distance) {

          var mapObj = undefined;
          NgMap.getMap(task.id).then(function(map){
            mapObj = map;

            var client = $clientSwitcher.getCurrentClient();
            $user.getNearByUsers(client, lat, long, distance, task).then(function(res) {

              _.each(res, function (userData) {
                var user = userData.user;
                var marker = {
                  icon: {
                    url: '/assets/images/user-marker.png',
                    scaledSize: [20, 25]
                  },
                  id: user._id,
                  name: user.name,
                  location: [user.location[1], user.location[0]],
                  lat: user.location[1],
                  lng: user.location[0],
                  type: 'user',
                  distance: userData.distance
                };

                scope.markers.push(marker);
                var latlng = new google.maps.LatLng(user.location[1], user.location[0]);
                bounds.extend(latlng);
              });

              if(res.length <= 0){
                mapObj.setZoom(8);
              }else{
                mapObj.fitBounds(bounds);
              }

              mapObj.setCenter(bounds.getCenter());
            });
          });
        };

        scope.assign = function(id) {
          task.assignTo = {_id: id};
          $performTaskAssign().call(scope, task);
        };

        scope.infoWindow = [];

        scope.selectedUser = {};
        var client = $clientSwitcher.getCurrentClient();
        scope.options = {scrollwheel: false};
        scope.$watch('task.expand', function(nv){
          if(nv){
            $timeout(function() {
              NgMap.getMap(task.id).then(function(map) {
                scope.showCustomMarker = function(evt, clickedMarker) {
                  if(clickedMarker.marker.type === 'user') {

                    map.customMarkers.options.setVisible(true);
                    map.customMarkers.options.setPosition(new google.maps.LatLng(clickedMarker.marker.lat, clickedMarker.marker.lng));

                    // TODO: Call this from Service and not directly from here
                    $clientTask.getAssignedTasksCount(client, clickedMarker.marker.id).then(function(result) {
                      scope.selectedUser.id = clickedMarker.marker.id;
                      scope.selectedUser.name = clickedMarker.marker.name + ' (' + clickedMarker.marker.distance + ' miles)';
                      scope.selectedUser.assignedTaskCount = result.length;
                    }, function(err) {
                      // some error in fetching the data
                    });
                  }
                };

                scope.closeCustomMarker= function(evt) {
                  this.style.display = 'none';
                  map.customMarkers.options.setVisible(false);
                };

                google.maps.event.trigger(map,'resize');
                if(scope.markers && scope.markers.length > 0){
                  var storeLocation = scope.markers[0].location;
                  if(storeLocation && storeLocation[0] && storeLocation[1]){
                    map.setCenter({lat: scope.markers[0].location[0], lng: scope.markers[0].location[1]});
                  }
                }
              });

            }, 100);
          }
        });

        scope.markers = [];
        scope.events = {
          'bounds_changed': function(map, evt){
            scope.mapObj = map;
          }
        };

        if(scope.task.location && scope.task.location.location){
          var loc = scope.task.location.location;
          var marker = {
            id: scope.task.location._id,
            name: scope.task.location.name,
            icon: {
              url: '/assets/images/store-marker.png',
              scaledSize: [40, 55]
            },
            location: [loc[1], loc[0]],
            type: 'store'
          };
          scope.markers.push(marker);
          var latlng = new google.maps.LatLng(loc[1], loc[0]);
          bounds.extend(latlng);

          var NEARBY_AREA_RADIUS = 50;
          createMarkersForNearbyUsers(loc[0], loc[1], NEARBY_AREA_RADIUS);
        }
    }
  };
}

 

// Dependency Injection
taskMapDirective.$inject = ['$clientSwitcher', '$performTaskArchive', '$performTaskHistory', '$performTaskCreate', '$performTaskResolve', '$performTaskAssign', '$clientUpload', '$string', 'Modal', '$authStorage', '$http', '$clientOffice', '$clientTask', '$timeout', 'NgMap', '$user'];
