'use strict';

export const ErrorConfig = function ($stateProvider) {
    $stateProvider
      .state('app.error', {
        url: '/error',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./error/error.html', '6d13d48a'); }],
        controller: 'ErrorCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./error/error.controller.js', 'ErrorCtrl'); }]
                      }
      })
      .state('app.error.accessdenied', {
        url: '/access-denied',
        views: {
          errorType: {
            templateProvider: ['$q', '$templateCache', function($q, $templateCache) {
                  var cacheName = 'error_error_accessdeniedTemplate';
                  return $q((resolve) => {
                      if ($templateCache.get(cacheName)) {
                          resolve($templateCache.get(cacheName));
                      } else {
                          import('./error.accessdenied.html').then(template => {
                              $templateCache.put(cacheName, template.default);
                              resolve(template.default);
                          });
                      }
                  });
              }]
          }
        }
      });
  }
// Dependency Injection
ErrorConfig.$inject = ["$stateProvider"];
