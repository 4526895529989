'use strict';

angular
export const DashboardSettingsCustomersIndexCtrl = function ($rootScope, $scope, $activeClient, $clientCustomer, Modal) {

  var currentClient;
  $scope.loading = false;
  $scope.search = {
    query: ''
  };

  function reloadCustomers(client, searchText) {
    $scope.loading = true;
    return $clientCustomer.getCustomers(client, searchText)
      .then(function (res) {
        if(res.status) {
          $scope.customers = res.data;
        }
      })
      .finally(function () {
        $scope.loading = false;
      });
  }

  // listen for client switching
  $scope.$on('$destroy', $activeClient(function (client) {
    if(!client) return;

    currentClient = client;
    reloadCustomers(currentClient, $scope.search.query);
  }));

  $scope.deleteCustomer = function(customer) {
    $activeClient(function (client) {
      if(!client) {
        return;
      }

      Modal.confirm.show(
        "Confirm Delete",
        "Are you sure you want to delete <strong>"+customer.name+"</strong>",
        "Delete", "Cancel",
        function(){
        $clientCustomer.deleteCustomer(client, customer).then(function() {
          reloadCustomers(client);
        });
      });
    });
  };

  $scope.search = function() {
    reloadCustomers(currentClient, $scope.search.query)
  };
}
// Dependency Injection
DashboardSettingsCustomersIndexCtrl.$inject = ["$rootScope","$scope","$activeClient","$clientCustomer","Modal"];
