
'use strict';

export const $authService  = function ($rootScope, $http, $authStorage, $q, $user, $clientSwitcher, TASK_SEARCH_CONFIG_KEY, COURT_OPS_SEARCH_CONFIG_KEY, $clientSwitcherStorage, $uibModalStack, appConfig, $cognito) {
    var COOKIE_TOKEN = 'token';
    var currentUser = {};

    // $rootScope.$on('client-switcher-load', function(evt, clients, currentClient) {
    //   console.log('Clients loaded:', clients.map(function (v) { return v.name; }), currentClient.name);
    // });
    // $rootScope.$on('client-switcher-switch', function(evt, client) {
    //   console.log('Client switched to:', client.name);
    // });

    function reloadUser() {
      var clientId = $clientSwitcher.getCurrentClient() || $clientSwitcherStorage.getCurrent();
      return (currentUser = $user.get(clientId)).$promise
        .then(function (user) {
          if(user) {
            return $clientSwitcher.reloadAvailable()
              .then(function (loaded) {
                  return user;
              });
          }

          return user;
        });
    }

    if($authStorage.hasToken()) {
      reloadUser();
    }

    return {
      reloadUser: reloadUser,

      extendSession: function () {
        return $user.extendSession()
          .then(function (result) {
            $authStorage.setToken(result.token);
            return result;
          });
      },

      /**
       * Authenticate user and save token
       *
       * @param  {Object}   user     - login info
       * @param  {Function} callback - optional
       * @return {Promise}
       */
      login: function (user, callback) {
        var cb = callback || angular.noop;
        // var deferred = $q.defer();
        // $http.post(appConfig.apiUrl + '/auth/local', user)
        //   .success(function (data) {
        //     // Set token
        //     $authStorage.setToken(data.token);
        //     localStorage.setItem('isServicePortalUser', !!user.isServicePortal)
        //     return reloadUser()
        //       .then(function (user) {
        //         cb(user);
        //         deferred.resolve(user);
        //         return user;
        //       });
        //   })
        //   .error(function (err) {
        //     console.log('Logout in Login Error')
        //     this.logout();
        //     deferred.reject(err);
        //     return cb(err);
        //   }.bind(this));

        // return deferred.promise;
        return new Promise((resolve, reject) => {
          $cognito.authenticateUser(user)
            .then((res) => {
              $authStorage.setToken(res.idToken.jwtToken);
              $authStorage.setAccessToken(res.accessToken.jwtToken);
              $authStorage.setRefreshToken(res.refreshToken.token);
              localStorage.setItem('isServicePortalUser', !!user.isServicePortal)
              return reloadUser()
                .then(function (user) {
                  cb(user);
                  resolve(user);
                  return user;
                });
            })
            .catch((err) => {
              // console.log(err)
              // this.logout();
              reject(err);
              return cb(err);
            })
        })
      },

      /**
       * Delete access token and user info
       *
       * @param  {Function}
       */
      logout: function () {
        // console.log($uibModal, $uibModalStack)
        // $('.modal-open').toggle()
        return new Promise((resolve, reject) => {
          $cognito.globalSignOut()
            .then(() => {
              $uibModalStack.dismissAll('close');
              $authStorage.clearToken();
              localStorage.removeItem(TASK_SEARCH_CONFIG_KEY);
              localStorage.removeItem(COURT_OPS_SEARCH_CONFIG_KEY);
              localStorage.removeItem('auth-tokens');
              // localStorage.removeItem('isServicePortalUser');
              $clientSwitcher.reset();
              currentUser = {};
              return resolve();
            })
            .catch((err) => {
              $authStorage.clearToken();
              let errMessage = err.message || err;
              if (err && err.data && err.data.message) errMessage = err.data.message;
              return reject(errMessage);
            })
        })
      },

      /**
       * Get auth token
       */
      getToken: function() {
        return $authStorage.getToken();
      },

      /**
       * Gets all available info on authenticated user
       *
       * @return {Object} user
       */
      updateUserAccessibility: function(accessibility) {
        currentUser.settings.accessibility = accessibility
      },

      setCurrentUser: function(result) {
        if(currentUser.settings._id == result._id){
          currentUser.settings.allowOffices = result.allowOffices;
          currentUser.settings.allowRegions = result.allowRegions;
          currentUser.settings.allowBuckets = result.allowBuckets;
          currentUser.settings.allowLanguages = result.allowLanguages;
          currentUser.settings.allowManagers = result.allowManagers;
          currentUser.settings.defaultOffice = result.defaultOffice;
          currentUser.settings.defaultDashboardTabs = result.defaultDashboardTabs;
          currentUser.settings.defaultDashboardView = result.defaultDashboardView;
        }
        return currentUser;
      },
      
      getCurrentUser: function() {
        return currentUser;
      },

      isInterpreter: function() {
        return currentUser && currentUser.settings && currentUser.settings.roles && currentUser.settings.roles.length && 
          currentUser.settings.roles[0].code == "INTERPRETER"
      },
      isWebAdmin: function() {
        return currentUser && currentUser.settings && currentUser.settings.roles && currentUser.settings.roles.length && 
          ['COORDINATE_ADMIN', 'SOSI_ADMIN'].includes(currentUser.settings.roles[0].code)
      },
      isJudge: function() {
        return currentUser && currentUser.settings && currentUser.settings.roles && currentUser.settings.roles.length && 
          currentUser.settings.roles[0].code == "COURT_JUDGE"
      },
      /**
       * Check if a user is an admin
       *
       * @return {Boolean}
       */
      isAdmin: function() {
        return currentUser.role === 'admin';
      },

      /**
       * Check if a user is logged in
       *
       * @return {Boolean}
       */
      isLoggedIn: function() {
        return currentUser.hasOwnProperty('role');
      },

      /**
       * Waits for currentUser to resolve before checking if user is logged in
       */
      isLoggedInAsync: function(cb) {
        if(currentUser.hasOwnProperty('$promise')) {
          currentUser.$promise.then(function() {
            cb(true);
          }).catch(function() {
            cb(false);
          });
        } else if(currentUser.hasOwnProperty('role')) {
          cb(true);
        } else {
          cb(false);
        }
      },
      validatePassword: function(user) {
        return $http.post(appConfig.apiUrl + '/auth/local/validate', user)
          .then(data => data.data)
          .catch(err => err)
      },
      isServicePortalUser: function() {
        let userType = currentUser.isUserServicePortal;
        return userType
      }
    };
  } 
 

// Dependency Injection
$authService.$inject = ['$rootScope', '$http', '$authStorage', '$q', '$user', '$clientSwitcher', 'TASK_SEARCH_CONFIG_KEY', 'COURT_OPS_SEARCH_CONFIG_KEY', '$clientSwitcherStorage', '$uibModalStack', 'appConfig', '$cognito'];
