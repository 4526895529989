'use strict';


export const $randomlyService = function () {

  function randomCharacter (L) {
    var n= Math.floor(Math.random()*62);
    if(n<10) return n; //1-10
    if(n<36) return String.fromCharCode(n+55); //A-Z
    return String.fromCharCode(n+61); //a-z
  }

  function randomString(L){
    var s = '';
    while(s.length< L) s+= randomCharacter();
    return s;
  }

  function randomInt(min, max, precision) {

    var
    n1 = isNaN(min) ? Number.MIN_VALUE : min,
    n2 = isNaN(max) ? Number.MAX_VALUE : max;

    min       = Math.min(n1, n2);
    max       = Math.max(n1, n2);
    precision = isNaN(precision) ? 0 : precision;

    var f = Math.pow(10, precision);
    return Math.round((min + (Math.random() * (max - min))) * f) / f;
  }

  function randomArrayEl(arr) {
    return arr[randomInt(0, arr.length - 1)];
  }

  function offsetDate(offset, now) {
    now = now || new Date;
    offset = isNaN(offset) ? 0 : offset;
    now.setTime(now.getTime() + offset);
    return now;
  }

  function randomRecord(spec) {
    var
    isArray = false, record, keys;

    if(angular.isArray(spec)) {
      isArray = true;
      record = [];
      keys = [];
      for(var i=0; i < spec.length; i++) keys.push(i);
    }
    else if(angular.isObject(spec)) {
      record = {};
      keys   = Object.keys(spec);
    }

    keys.forEach(function (key) {
      var cspec = spec[key], fnResult = null, v;
      if(!cspec || !cspec.type) return;

      switch(cspec.type) {
        case Array:
        case Object:
        case String:

        if(angular.isFunction(cspec.fn)) {
          fnResult = cspec.fn.call(cspec);
        }

        if(angular.isArray(cspec.enum)) v = randomArrayEl(cspec.enum);
        else if(cspec.fn)               v = fnResult;
        else                            v = randomString(cspec.length || 10);

        break;
        case Boolean:
        v = (Math.random() >= 0.5);
        break;
        case Date:
        var
        offsetMin = Number.MIN_VALUE,
        offsetMax = Number.MAX_VALUE;

        if(!isNaN(cspec.min)) offsetMin = cspec.min;
        if(!isNaN(cspec.max)) offsetMax = cspec.max;

        offsetDate(randomInt(offsetMin, offsetMax), v = new Date());
        break;
      }

      if(isArray) record.push(v);
      else        record[key] = v;

    });

    return record;
  }

  return {
    randomCharacter:  randomCharacter,
    randomString:     randomString,
    randomInt:        randomInt,
    randomArrayEl:    randomArrayEl,
    randomRecord:     randomRecord,
    offsetDate:       offsetDate
  };
}