
'use strict';

export const $woStatusService  = function () {
  let status = [
    {
      label: 'Locating',
      value: 'Locating',
      allowStatus: ['New', 'Assigned', 'Confirmed', 'Cancelled', 'No Interpreter', 'Applied']
    },
    {
      label: 'Assigned',
      value: 'Assigned',
      allowStatus: ['New', 'Locating', 'Confirmed', 'Cancelled', 'No Interpreter', 'Applied']
    },
    {
      label: 'Confirmed',
      value: 'Confirmed',
      allowStatus: ['New', 'Assigned', 'Locating', 'Cancelled', 'Applied']
    }]
  return status
}
 
