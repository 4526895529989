
'use strict';

export const $mobileAppManagerService  = function (MobileAppManager) {
    return {
      save: function(client, appData) {
        return MobileAppManager.save({
          _id: client._id || client,
          version: appData.version,
          platform: appData.platform,
          downloadLink: appData.downloadLink
        }).$promise;
      },
      getApps: function(client) {
        return MobileAppManager.getApps({
          id: client._id || client
        }).$promise;
      }
    };
  }
// Dependency Injection


// Dependency Injection
$mobileAppManagerService.$inject = ['MobileAppManager'];
