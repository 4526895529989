


'use strict';

export const ClientRolesFactory  = function ($resource, appConfig) {
    return $resource(appConfig.apiUrl + '/api/roles/:id/:controller', {
      id: '@_id'
    },
      {
        create: {
          method: 'POST',
          params: {
            controller: 'create'
          }
        },
        getRoles: {
          method: 'GET',
          params: {
            id: '@id'
          }
        },
        getRoleById: {
          method: 'GET',
          params: {
            id: '@id'
          }
        },
        update: {
          method: 'POST',
          params: {
            controller: 'update'
          }
        },
        getRolePermissions: {
          method: 'GET',
          params: {
            controller: 'get-role-permissions',
            id: '@id'
          }
        }
      });
  } 
 

// Dependency Injection
ClientRolesFactory.$inject = ['$resource', 'appConfig'];
