
'use strict';

export const $clientInventoryService  = function (ClientInventory) {
        return {
            getInventorys: function (client) {
                return ClientInventory.getInventorys({
                    id: client._id || client
                }).$promise;
            },
            detail: function (client, inventory) {
                return ClientInventory.detail({
                    id: client._id || client,
                    inventory: inventory
                }).$promise;
            },
            createInventory: function (client, inventoryData) {
                return ClientInventory.createInventory({
                    _id: client._id || client,
                    data: inventoryData
                }).$promise;
            },
            updateInventory: function (client, inventoryId, inventoryData) {
                return ClientInventory.updateInventory({
                    _id: client._id || client,
                    inventory: inventoryId,
                    data: inventoryData
                }).$promise;
            },
            deleteInventory: function (client, inventory) {
                return ClientInventory.deleteInventory({
                    _id: client._id || client,
                    inventory: inventory._id
                }).$promise;
            }
        };
    }
// Dependency Injection


// Dependency Injection
$clientInventoryService.$inject = ['ClientInventory'];
