


'use strict';

export const ClientOfficeInformationFactory  = function ($resource, appConfig) {
        return $resource(appConfig.apiUrl + '/api/client-office-information/:id/:controller', {
            id: '@_id'
        },
            {
                createOfficeInfoRecord: {
                    method: 'POST',
                    params: {
                        controller: 'create'
                    }
                },
                getOfficeInfoRecords: {
                    method: 'GET',
                    params: {
                        controller: '',
                        id: '@id'
                    }
                },
                deleteOfficeInfoRecord: {
                    method: 'POST',
                    params: {
                        controller: 'delete'
                    }
                },
                updateOfficeInfoRecord: {
                    method: 'POST',
                    params: {
                        controller: 'update'
                    }
                },
                removeAttachments: {
                    method: 'POST',
                    params: {
                        controller: 'remove-attachments'
                    }
                }
            });
    } 
 

// Dependency Injection
ClientOfficeInformationFactory.$inject = ['$resource', 'appConfig'];
