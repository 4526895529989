'use strict';

export const BillingMethodConfig = function ($stateProvider) {
        $stateProvider
            .state('app.account.services.billingMethod', {
                url: '/billing',
                data: {
                    roles: []
                },
                templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/services/billing-method/billing-method.html', '39d9a9ca'); }],
                controller: 'ServiceBillingMethodCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/services/billing-method/billing-method.controller.js', 'ServiceBillingMethodCtrl'); }]
                      }
            })
    }
// Dependency Injection
BillingMethodConfig.$inject = ["$stateProvider"];
