import './elog.scss';
'use strict';

export const ElogConfig = function ($stateProvider) {
    $stateProvider
      .state('app.print.elog', {
        url: '/office-operations/elog',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./print/office-operations/elog/elog.html', '5707598a'); }],
        controller: 'PrintELOGCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./print/office-operations/elog/elog.controller.js', 'PrintELOGCtrl'); }]
                      }
      });
  }
// Dependency Injection
ElogConfig.$inject = ["$stateProvider"];
