'use strict';


export const formMobileAppDirective = function () {
    return {
      restrict: 'E',
      templateUrl: 'components/forms/mobile-app.html',
      scope: {
        model: '=ngModel',
        onSubmit: '=formOnSubmit',
      },
      link: function(scope) {
        
      }
    };
  }