'use strict';

export const interpreterDqsDirective = function ($rootScope, $q, $clientSwitcher, ngToast, $activeClient, $dqsReports, Modal, $sce) {
        return {
            restrict: 'E',
            templateUrl: 'app/account/dashboard/main-dashboard/interpreter-resource/tabs/profile/tabs/dqs/ic-dqs.html',

            scope: {
                interpreterDetails: '=interpreterDetails',
                isInterpreter: '=isInterpreter',
            },
            link: function ($scope) {
                let killClientWatch, currentCli = $clientSwitcher.getCurrentClient();
                $scope.intLangsList = [];
                $scope.InterpreterName = `${$scope.interpreterDetails.firstname} ${$scope.interpreterDetails.middlename ? $scope.interpreterDetails.middlename : ''} ${$scope.interpreterDetails.lastname}`;
                $scope.permissionsObj = {}
                $scope.pagination = {
                    currentPage: 1,
                    page: 1,
                    total: 0,
                    pageSize: 10,
                    itemsPerPage: 10
                };
                $scope.filter = { searchText: '' };
                function init() {
                    killClientWatch = $activeClient(function (client) {
                        if (!client) {
                            return;
                        }
                        currentCli = client;
                        // $scope.loadClientOffices(currentCli);
                        loadInterpreterDQs()
                    });
                }

                function loadInterpreterDQs() {
                    if ($scope.isLoading) return;
                    $scope.error = null;
                    $scope.interpreterDQs = [];
                    $scope.pagination.total = 0;
                    $scope.isLoading = true;
                    let filter = {
                        // vendorid: $scope.interpreterDetails.vendorid,
                        page: $scope.pagination.page,
                        pageSize: $scope.pagination.pageSize
                    }
                    $dqsReports.getList(currentCli, filter, $scope.interpreterDetails.vendorid)
                        .then((res) => {
                            if (res.status) {
                                if (res.dqsList && res.dqsList.length) {
                                    $scope.interpreterDQs = res.dqsList;
                                    $scope.pagination.total = res.totalRecords;
                                } else {
                                    $scope.error = 'No Record Found.';
                                }
                            }
                        })
                        .catch((err) => {
                            let errMessage = err.message || err;
                            if (err && err.data && err.data.message) {
                                errMessage = err.data.message;
                            } else if (err && err.data && err.data.reason) {
                                errMessage = err.data.reason;
                            }
                            $scope.error = errMessage;
                        })
                        .finally(() => {
                            $scope.isLoading = false;
                        })

                }

                $scope.onSearch = () => {
                    $scope.pagination.page = 1
                }
                $scope.onPageChange = () => {
                    loadInterpreterDQs()
                }
                $scope.showNotes = (dqRecord) => {
                    let title = 'DQ Notes: ' + $scope.InterpreterName;
                    Modal.info.showPreTextContent(title, dqRecord.notes, function (cb) {
                        if (cb) {
                            // loginWithUser();
                        }
                    }, 'btn-success')();
                }
                $scope.searchFilter = function (item) {
                    if (!$scope.filter.searchText ||
                        (item.court_code.toLowerCase().includes($scope.filter.searchText)) || (item.court_name.toLowerCase().includes($scope.filter.searchText.toLowerCase()))) {
                        return true;
                    }
                    return false;
                };
                $scope.$on('$destroy', function () {
                    if (killClientWatch) {
                        killClientWatch();
                    }
                });
                init();
            }
        }
    }
// Dependency Injection
interpreterDqsDirective.$inject = ["$rootScope","$q","$clientSwitcher","ngToast","$activeClient","$dqsReports","Modal","$sce"];
