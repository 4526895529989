import './client.scss';
'use strict';

export const ClientConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.clients.client', {
        url: '',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/clients/client/client.html', 'e28cb00a'); }],
        controller: 'AccountClientsCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/clients/client/client.controller.js', 'AccountClientsCtrl'); }]
                      }
      });
  }
// Dependency Injection
ClientConfig.$inject = ["$stateProvider"];
