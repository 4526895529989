
'use strict';

export const $printDateService  = function () {
        return function (date) {
            if (date) {
                return moment(date).format('dddd, MMMM D, YYYY');
            }
            return moment().format('dddd, MMMM D, YYYY');
        }
    } 
 
