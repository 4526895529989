
'use strict';

export const CalculateWorkOrderTimeFactory  = function ($clientOffice, $clientSwitcher, $timezones, OPI, OSI, VRI, CalculateTime) {

  const client = $clientSwitcher.getCurrentClient();

  return function(caseData) {
    return $clientOffice.getOfficeWoControls(client, caseData.replocid)
      .then(data => {
        let woControls = data.data && data.data.woControls;
        if (!woControls) {
          woControls = {
            minWoTime: 180,
            maxLunchTime: 120,
            minWoOPITime: 60,
            minWoVRITime: 60
          };
        }

        return CalculateTime(caseData, woControls);
      })
  };

}
 

// Dependency Injection
CalculateWorkOrderTimeFactory.$inject = ['$clientOffice', '$clientSwitcher', '$timezones', 'OPI', 'OSI', 'VRI', 'CalculateTime'];
