import './postgres_data_sync.scss';
'use strict';

export const Postgres_data_syncConfig = function ($stateProvider) {
        $stateProvider
            .state('app.account.dashboard.settings.postgres_data_sync', {
                url: '/postgres_data_sync',
                templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/postgres_data_sync/postgres_data_sync.html', '8c406aaa'); }],
                controller: 'PostgresDataSyncCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/postgres_data_sync/postgres_data_sync.controller.js', 'PostgresDataSyncCtrl'); }]
                      }
            })
            .state('app.account.dashboard.settings.postgres_data_sync.cases', {
                url: '/cases',
                templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/postgres_data_sync/cases/cases.html', '3c9e914a'); }],
                controller: 'PostgresDataSyncCasesCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/postgres_data_sync/cases/cases.controller.js', 'PostgresDataSyncCasesCtrl'); }]
                      }
            })
            .state('app.account.dashboard.settings.postgres_data_sync.interpreters', {
                url: '/interpreters',
                templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/postgres_data_sync/interpreters/interpreters.html', '90fa830a'); }],
                controller: 'PostgresDataSyncInterpretersCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/postgres_data_sync/interpreters/interpreters.controller.js', 'PostgresDataSyncInterpretersCtrl'); }]
                      }
            })
            .state('app.account.dashboard.settings.postgres_data_sync.coordinators', {
                url: '/coordinators',
                templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/postgres_data_sync/coordinators/coordinators.html', '72c52e0a'); }],
                controller: 'PostgresDataSyncCoordinatorsCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/postgres_data_sync/coordinators/coordinators.controller.js', 'PostgresDataSyncCoordinatorsCtrl'); }]
                      }
            })
            .state('app.account.dashboard.settings.postgres_data_sync.judges', {
                url: '/judges',
                templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/postgres_data_sync/judges/judges.html', '3683c02a'); }],
                controller: 'PostgresDataSyncJudgesCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/postgres_data_sync/judges/judges.controller.js', 'PostgresDataSyncJudgesCtrl'); }]
                      }
            })
            .state('app.account.dashboard.settings.postgres_data_sync.intlangs', {
                url: '/intlangs',
                templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/postgres_data_sync/intlangs/intlangs.html', '45b7106a'); }],
                controller: 'PostgresDataSyncIntLangsCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/postgres_data_sync/intlangs/intlangs.controller.js', 'PostgresDataSyncIntLangsCtrl'); }]
                      }
            })
            .state('app.account.dashboard.settings.postgres_data_sync.languages', {
                url: '/languages',
                templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/postgres_data_sync/languages/languages.html', '17e8d7ca'); }],
                controller: 'PostgresDataSyncLanguagesCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/postgres_data_sync/languages/languages.controller.js', 'PostgresDataSyncLanguagesCtrl'); }]
                      }
            })
            .state('app.account.dashboard.settings.postgres_data_sync.offices', {
                url: '/locations',
                templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/postgres_data_sync/locations/locations.html', '6ee1360a'); }],
                controller: 'PostgresDataSyncOfficesCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/postgres_data_sync/locations/locations.controller.js', 'PostgresDataSyncOfficesCtrl'); }]
                      }
            })
            .state('app.account.dashboard.settings.postgres_data_sync.nationalities', {
                url: '/nationalities',
                templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/postgres_data_sync/nationalities/nationalities.html', '13555e8a'); }],
                controller: 'PostgresDataSyncNationalitiesCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/postgres_data_sync/nationalities/nationalities.controller.js', 'PostgresDataSyncNationalitiesCtrl'); }]
                      }
            })
            .state('app.account.dashboard.settings.postgres_data_sync.sosnots', {
                url: '/sosnots',
                templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/postgres_data_sync/sosnots/sosnots.html', '6519b2ca'); }],
                controller: 'PostgresDataSyncSosnotsCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/postgres_data_sync/sosnots/sosnots.controller.js', 'PostgresDataSyncSosnotsCtrl'); }]
                      }
            })
            .state('app.account.dashboard.settings.postgres_data_sync.ica-tracking', {
                url: '/ica-tracking',
                templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/postgres_data_sync/icatracking/icatracking.html', 'ff99238a'); }],
                controller: 'PostgresDataSyncIcaTrackingCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/postgres_data_sync/icatracking/icatracking.controller.js', 'PostgresDataSyncIcaTrackingCtrl'); }]
                      }
            })
            .state('app.account.dashboard.settings.postgres_data_sync.dqs', {
                url: '/dqs',
                templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/postgres_data_sync/dqs/dqs.html', 'd2d750a'); }],
                controller: 'PostgresDataSyncDQSCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/postgres_data_sync/dqs/dqs.controller.js', 'PostgresDataSyncDQSCtrl'); }]
                      }
            })
            .state('app.account.dashboard.settings.postgres_data_sync.lists', {
                url: '/lists',
                templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/postgres_data_sync/lists/lists.html', 'e2515c4a'); }],
                controller: 'PostgresDataSyncListsCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/postgres_data_sync/lists/lists.controller.js', 'PostgresDataSyncListsCtrl'); }]
                      }
            })
            .state('app.account.dashboard.settings.postgres_data_sync.telephonics', {
                url: '/telephonics',
                templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/postgres_data_sync/telephonics/telephonics.html', '9a83458a'); }],
                controller: 'PostgresDataSyncTelephonicsCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/postgres_data_sync/telephonics/telephonics.controller.js', 'PostgresDataSyncTelephonicsCtrl'); }]
                      }
            })
            .state('app.account.dashboard.settings.postgres_data_sync.cases_status_count', {
                url: '/cases-status-counts',
                templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/postgres_data_sync/cases_status_counts/cases_status_counts.html', '4142d6ca'); }],
                controller: 'CasesStatusCountsCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/postgres_data_sync/cases_status_counts/cases_status_counts.controller.js', 'CasesStatusCountsCtrl'); }]
                      }
            })
    }
// Dependency Injection
Postgres_data_syncConfig.$inject = ["$stateProvider"];
