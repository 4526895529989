
'use strict';

export const draggableTriggerDirective  = function ($document) {

  function once(emitter, event, fn) { // TODO: make this a service
    var callback = function () { fn.apply(this, arguments); emitter.unbind(event, callback); };
    emitter.on(event, callback);
    return emitter;
  }

  function preventDef(cb) { // TODO: ditto
    return function (event) {
      event.preventDefault();
      return cb.apply(this, arguments);
    };
  }

  return {
    restrict: 'CA',
    require: '^draggable',
    link: function (scope, el, attr, ctrl) {
      el.on('mousedown', preventDef(function (event) {
        ctrl.dragStart(event.screenX, event.screenY);
        once($document, 'mouseup', preventDef(ctrl.dragStop.bind(ctrl)));
      }));
    }
  };
}
// Dependency Injection


// Dependency Injection
draggableTriggerDirective.$inject = ['$document'];
