export const phoneNumberFilter = function () {
  return function (number) {
    if (!number) { return ''; }

    var area = number.substring(0, 3);
    var front = number.substring(3, 6);
    var end = number.substring(6, 10);

    return area + '.' + front + '.' + end;
  };
}
