'use strict';

export const AnalysisConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard.analysis', {
        url: '/analysis',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/analysis/analysis.html', 'e7174f2a'); }],
        controller: 'DashboardAnalysisCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/analysis/analysis.controller.js', 'DashboardAnalysisCtrl'); }]
                      },
        data: {
          permission: 'ANALYSIS_READ'
        }
      });
  }
// Dependency Injection
AnalysisConfig.$inject = ["$stateProvider"];
