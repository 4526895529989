'use strict';


export const percentageFilter = function ($filter) {
  return function(input, precision, mul100) {
    precision = precision || 0;
    var testInput = parseFloat(input);

    if(mul100 && testInput !== NaN) {
      input = testInput * 100;
    }

    var numberFilter = $filter('number');
    return numberFilter(input, precision) + '%';
  };
}
// Dependency Injection
percentageFilter.$inject = ["$filter"];
