'use strict';



export const coiTimeReportDirective = function ($rootScope, $state, $stateParams, ngToast, $clientSwitcher, $auth, $timeout, $clientTask, socket) {
        return {
            restrict: 'E',
            templateUrl: 'app/account/dashboard/main-dashboard/work-orders/tabs/coi-time-report/coi-time-report.html',
            scope: {},
            link: function (scope) {
                const currentUser = $auth.getCurrentUser();
                const currentCli = $clientSwitcher.getCurrentClient();
                scope.userRoom = `COI_TIME_REPORT_${currentUser._id}`;
                scope.pageData = {
                    isLoading: false,
                    error: null,
                    coiReports: [],
                    isDownloadLoading: false
                }
                scope.pagination = {
                    currentPage: 1,
                    page: 1,
                    total: 0,
                    pageSize: 20,
                    itemsPerPage: 20
                };
                scope.filter = {
                    startDate: moment().subtract(15, 'days').format('YYYY-MM-DD'), //moment('01-05-2022').format('YYYY-MM-DD'),
                    endDate: moment().subtract(1, 'days').format('YYYY-MM-DD'), //moment('11-07-2022').format('YYYY-MM-DD'),
                    isDeldateFilter: true,
                    isHistorical: false,
                }
                scope.toggleView = (type) => {
                    let isChange = false;
                    if (type == 'serviceDate' && scope.filter.isDeldateFilter !== true) {
                        scope.filter.isDeldateFilter = true;
                        isChange = true;
                    } else if (scope.filter.isDeldateFilter !== false){
                        scope.filter.isDeldateFilter = false;
                        isChange = true;
                    }
                    if (isChange) {
                        scope.loadCOIReport()
                    }
                }

                scope.switchViewMode =  () => {
                    scope.filter.isHistorical = !scope.filter.isHistorical;
                    scope.loadCOIReport()
                }

                scope.loadCOIReport = function () {
                    if (scope.pageData.isLoading) return;
                    scope.pageData.isLoading = true;
                    scope.pageData.error = null;
                    scope.pageData.coiReports = [];

                    $clientTask.getCOITimeReport(currentCli, scope.filter, scope.pagination).then((res) => {
                        scope.pageData.coiReports = res.result;
                        scope.pagination.total = res.count;
                    })
                        .catch((err) => {
                            console.log(err)
                        })
                        .finally(() => {
                            scope.pageData.isLoading = false;
                        })


                }
                function initDateRangePicker() {
                    function cb(start, end) {
                        $timeout(function () {
                            if (!start || !end) {
                                return;
                            }
                            if (moment(end.format('YYYY-MM-DD')).diff(start.format('YYYY-MM-DD'), 'days') > 31) {
                                ngToast.create({
                                    content: 'Date range must be within 31 days.',
                                    className: 'danger'
                                })
                                return;
                            } else {
                                $('#reportrange span').html(start.format('MM/DD/YYYY') + ' to ' + end.format('MM/DD/YYYY'));
                                scope.filter.startDate = start.format('YYYY-MM-DD');
                                scope.filter.endDate = end.format('YYYY-MM-DD');
                                scope.$apply()
                                scope.loadCOIReport()
                            }
                        }, 500);
                    }

                    $('#reportrange').daterangepicker({
                        startDate: moment(scope.filter.startDate),
                        endDate: moment(scope.filter.endDate),
                        opens: 'right'
                    }, cb);
                    var text = moment(scope.filter.startDate).format('MM/DD/YYYY') + ' to ' + moment(scope.filter.endDate).format('MM/DD/YYYY');
                    $('#reportrange span').html(text);
                    // scope.loadCOIReport()
                }

                scope.getLocalSchDateTime = function (date) {
                    let tz = moment(date).tz(moment.tz.guess()).format('zz');
                    return moment(date).format('MM/DD/YYYY hh:mm A') + ' ' + tz;
                }

                scope.downloadCSV = function () {
                    if (scope.pageData.isDownloadLoading) return;
                    scope.pageData.isDownloadLoading = true;
                    delete scope.filter.page;
                    delete scope.filter.pageSize;
                    $clientTask.COITimeReportExport(currentCli, scope.filter)
                        .success(function (data, status, headers, config) {
                            var element = angular.element('<a></a>');
                            var blob = new Blob([data], { type: "application/octet-stream" });
                            var objectUrl = URL.createObjectURL(blob);

                            element.attr({
                                href: objectUrl,
                                target: '_blank',
                                download: `COI_Time_Report_${scope.filter.startDate}_to_${scope.filter.endDate}.csv`
                            });
                            element[0].click();
                        })
                        .error(function (err) {
                            console.warn(err)
                            alert('Error while downloading the report');
                        })
                        .finally(() => {
                            scope.pageData.isDownloadLoading = false;
                        })
                }

                scope.requestToDownload = function() {
                    if (scope.pageData.isDownloadLoading) return;
                    scope.pageData.isDownloadLoading = true;
                    delete scope.filter.page;
                    delete scope.filter.pageSize;
                    $clientTask.ReqCOITimeReportDownload(currentCli, scope.filter)
                    .then((res) => {
                        if (res && res.status) {
                            scope.pageData.isDownloadLoading = true;
                            scope.pageData.requestSubmitedInProgress = true;
                            if (res.allowDownload && res.url ) {
                                scope.downloadFileFromURL(res.document, res.url);
                                scope.pageData.isDownloadLoading = false;
                                scope.pageData.requestSubmitedInProgress = false;
                            }
                        }
                    })
                    .catch((err) => {
                        ngToast.create({
                            content: err.message || err.error || 'Somthingwent wrong, please try again!',
                            className: 'danger'
                        })
                    })
                }

                scope.downloadFileFromURL = function(id, url) {
                    var element = angular.element('<a></a>');
                    scope.pageData.isDownloadLoading = false;
                    element.attr({
                        href: url,
                        download: `COI_Time_Report_${scope.filter.startDate}_to_${scope.filter.endDate}.csv`
                    });
                    element[0].click();
                    socket.emit('TIME_REPORT_DOWNLOADED', {document: id});
                }

                scope.getRoundedTime = function(roundedTime) {
                    let value = roundedTime.split(':')[0]
                    if (value) return parseInt(value);
                    return roundedTime;
                }
                // scope.isTabActive = true;
                // document.addEventListener('visibilitychange', function() {
                //     scope.isTabActive = !document.hidden;
                //     scope.$apply(); // Apply changes to AngularJS scope
                // });
                function init() {
                    $timeout(() => {
                        initDateRangePicker();
                        socket.emit('subscribe', `COI_TIME_REPORT`);
                        try {
                            setTimeout(() => {
                                socket.forward(scope.userRoom, scope);
                            }, 1000)
                            scope.$on(`socket:${scope.userRoom}`, function (ev, data) {
                                // console.log('==== room update', data)
                                if (data && data.allowDownload && data.link) {
                                    scope.downloadFileFromURL(data.document, data.link)
                                }
                            })
                        } catch (error) {
                            console.log(error)
                        }

                    }, 1000);
                    scope.loadCOIReport()

                }
                init()
            }
        }
    }
// Dependency Injection
coiTimeReportDirective.$inject = ["$rootScope","$state","$stateParams","ngToast","$clientSwitcher","$auth","$timeout","$clientTask","socket"];
