


'use strict';

export const ClientEmployeeFactory  = function ($resource, appConfig) {
    return $resource(appConfig.apiUrl + '/api/client-employees/:id/:controller', {
      id: '@_id'
    },
    {
      detail: {
        method: 'GET',
        params: {
          controller: 'details'
        }
      },
      associatedEmployee: {
        method: 'GET',
        params: {
          controller: 'associated-employee'
        }
      },
      getEmployees: {
        method: 'GET',
        params: {
          controller: ''
        } 
      },
      getRemovedEmployees: {
        method: 'GET',
        params: {
          controller: 'removedEmployees'
        } 
      },
      createEmployee: {
          method: 'POST',
          params: {
              controller: 'create'
          }
      },
      updateEmployee: {
          method: 'POST',
          params: {
              controller: 'update'
          }
      },
      deleteEmployee: {
          method: 'POST',
          params: {
              controller: 'delete'
          }
      },
      getUserTree: {
        method: 'GET',
        params: {
          controller: 'user-tree'
        }
      },
      getLogs: {
        method: 'GET',
        params: {
          controller: 'logs'
        }
      },
      getChildrenEmployees: {
        method: 'GET',
        params: {
          controller: 'children'
        }
      },
      getEmployeeStats: {
        method: 'GET',
        params: {
          controller: 'stats'
        }
      },
      getEmployeePerformance: {
        method: 'GET',
        params: {
          controller: 'performance'
        }
      },
      getGrandParentChildren: {
        method: 'GET',
        params: {
          controller: 'grand-parent-children'
        }
      },
      makeAvailable: {
        method: 'POST',
        params: {
          controller: 'make-available'
        }
      },
      toggleEmployee: {
        method: 'POST',
        params: {
          controller: 'toggle-employee'
        }
      },
      userNotifications: {
        method: 'POST',
        params: {
          controller: 'user-notifications'
        }
      },
      ivrSync: {
        method: 'GET',
        params: {
          controller: 'ivr-sync'
        }
      },
      exportPerformanceSurveryRecords: {
        method: 'GET',
        params: {
          controller: 'export-performance-survey-records'
        }
      }
    });
  } 
 

// Dependency Injection
ClientEmployeeFactory.$inject = ['$resource', 'appConfig'];
