
'use strict';

export const $mobileAppService  = function (MobileApp) {
    return {
      getAllApps: function() {
        return MobileApp.getAllApps({}).$promise;
      }
    };
  }
// Dependency Injection


// Dependency Injection
$mobileAppService.$inject = ['MobileApp'];
