


'use strict';

export const ClientExternalUsersFactory  = function ($resource, appConfig) {
  return $resource(appConfig.apiUrl + '/api/external-users/:id/:email', {
    id: '@id',
    email: '@email'
  },
  {
    query: {
      method: 'GET',
      isArray: false      
    },
    add: {
      method: 'POST',
      isArray: false
    },
    delete: {
      method: 'DELETE',
      isArray: false
    }
  });
} 
 

// Dependency Injection
ClientExternalUsersFactory.$inject = ['$resource', 'appConfig'];
