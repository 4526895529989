'use strict';

export const ratesDirective = function ($auth, $q, $clientSwitcher, ngToast, $activeClient, $icaTracking) {
        return {
            restrict: 'E',
            templateUrl: 'app/account/dashboard/main-dashboard/interpreter-resource/tabs/profile/tabs/rates.html',
            scope: {
                interpreterDetails: '=interpreterDetails',
                isInterpreter: '=isInterpreter',
            },
            link: function ($scope) {
                let killClientWatch, currentCli = $clientSwitcher.getCurrentClient();
                $scope.rates = {};
                $scope.incentives = {};
                $scope.data = {
                    isLoading: true,
                    error: null
                }
                function init() {
                    killClientWatch = $activeClient(function (client) {
                        if (!client) {
                            return;
                        }
                        currentCli = client;
                        getInterpreterRates();
                    });
                }

                function getInterpreterRates() {
                    if ($scope.interpreterDetails && !$scope.interpreterDetails.vendorid) {
                        return ngToast.create({
                            className: 'danger',
                            content: 'User is not interpreter'
                        });
                    }
                    $scope.data.isLoading = true;
                    $icaTracking.getInterpreterRates(currentCli, $scope.interpreterDetails.vendorid)
                        .then((res) => {
                            if (res && res.status) {
                                $scope.rates = res.rates;
                                $scope.incentives = res.incentives;
                            } else {
                                $scope.data.error = res.message;
                            }
                        })
                        .catch((err) => {
                            let errMessage = err.message || err;
                            if (err && err.data && err.data.message) errMessage = err.data.message;
                            $scope.data.error = errMessage;
                        })
                        .finally(() => {
                            $scope.data.isLoading = false;
                        })
                }

                $scope.$on('$destroy', function () {
                    if (killClientWatch) {
                        killClientWatch();
                    }
                });

                init()
            }
        }
    }
// Dependency Injection
ratesDirective.$inject = ["$auth","$q","$clientSwitcher","ngToast","$activeClient","$icaTracking"];
