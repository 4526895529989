import './admin.scss';
'use strict';

export const AdminConfig = function ($stateProvider) {
    $stateProvider
      .state('app.admin', {
        url: '/admin',
        data: {
          roles: ['admin']
        },
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./admin/admin.html', '1352feca'); }],
        controller: 'AdminCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./admin/admin.controller.js', 'AdminCtrl'); }]
                      }
      });
  }
// Dependency Injection
AdminConfig.$inject = ["$stateProvider"];
