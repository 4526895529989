'use strict';

export const UnconfirmedConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard.unconfirmed', {
        url: '/unconfirmed',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/unconfirmed/unconfirmed.html', '304f114a'); }],
        controller: 'DashboardUnconfirmedCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/unconfirmed/unconfirmed.controller.js', 'DashboardUnconfirmedCtrl'); }]
                      },
        data: {
          ability: 'client.unconfirmed',
          permission: 'UNCONFIRMED_READ'
        }
      });
  }
// Dependency Injection
UnconfirmedConfig.$inject = ["$stateProvider"];
