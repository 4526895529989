'use strict';

export const piplineOperationsDirective = function ($rootScope, $stateParams, $timeout,  LazyLoadService ) {
              return {
      restrict: 'E',
      templateUrl: 'app/account/dashboard/main-dashboard/pipline-operations/pipline-operations.html',
      scope: {
        modelData: '=ngModel',
        childTabs: '=childTabs',
        layoutDefaultTabing: '&layoutDefaultTab'
      },
      link: function(scope) {

  const lazyDirectives = [{"name":"piplineDirective","path":import("@app/account/dashboard/main-dashboard/pipline-operations/tabs/pipline/pipline.directive.js")},{"name":"resourcesDirective","path":import("@app/account/dashboard/main-dashboard/pipline-operations/tabs/resources/resources.directive.js")}];
  scope.lazyDirectivesLoaded = false;
  Promise.all(lazyDirectives.map(directive=>LazyLoadService.loadDirective(directive.path, directive.name))).then(() => {
          $timeout(function() {
            scope.lazyDirectivesLoaded = true;

                 let selectedTab = null;
        scope.IcTabs = [];
        var init = function () {
          scope.tabs()
        }

        scope.tabs = function () {
          let tabs = scope.childTabs;

          let data = scope.layoutDefaultTabing({obj:{tabs, layoutOf: 'piplineOperations'}})
          scope.IcTabs = data.avtiveTabs;
          selectedTab = data.selectedTab;

          if ($stateParams.ic) {
            selectedTab = 'resources';
          }

          if ($stateParams.child) {
            selectedTab = $stateParams.child;
          }
        }

        scope.tabSelected = function (tabName) {
          selectedTab = tabName;
          $rootScope.$broadcast('onTabChange', selectedTab);
        };

        scope.isTabSelected = function (tabName) {
          return selectedTab === tabName;
        };
        init()

  })
});
}
}
}
//Dependency Injection
piplineOperationsDirective.$inject = ["$rootScope","$stateParams", "$timeout",  "LazyLoadService"];
