
'use strict';

export const CalculateTimeFactory  = function ($timezones, OPI, OSI, VRI) {
  return function (caseData, woControls) {
    const defaultState = {
      startTime: 'N/A',
      startTimeBillable: 'N/A',
      totalLunchTime: 'N/A',
      totalLunchTimeInDuration: 0,
      totalJudgeTime: 'N/A',
      totalJudgeInstance: 'N/A',
      lateTime: 'N/A',
      releasedTime: 'N/A',
      endTime: 'N/A',
      totalWOTime: 'N/A',
      totalWoPay: 'N/A',
      extendTime: 'N/A',
      totalJobTime: 'N/A',
      minWorkOrder: 'N/A',
      lateTimeInNumber: 'N/A',
      totalLunchTimeInDuration: 0,
      releasedDateTime: 'N/A',
      endDateTime: 'N/A'
    };

    if (!caseData) {
      return defaultState;
    }
    var index = _.findIndex($timezones, function (x) {
      return x.label == caseData.timezone;
    })
    var timezoneOffset = $timezones[index] ? $timezones[index].offset : moment().format('ZZ');
    // const caseData = record;
    /**
     * BillType values equals
     *  sched when FIN < SCHED < JIN
     *  jin when FIN < JIN < SCHED
     *  fin when SCHED < FIN
     */
    const getStartTime = (schedTime, finTime, firstJinTime) => {
      finTime = moment(finTime || firstJinTime).utcOffset(timezoneOffset).format();
      firstJinTime = firstJinTime ? moment(firstJinTime).utcOffset(timezoneOffset).format() : null;
      // if (caseData.sertype == OPI || caseData.sertype == VRI) {
      //   if (moment(firstJinTime).isAfter(schedTime)) {
      //     return schedTime;
      //   }
      //   return firstJinTime;
      // }
      if (firstJinTime && moment(schedTime).isBetween(finTime, firstJinTime, undefined, "(]")) {
        return schedTime;
      }
      else if (firstJinTime && moment(firstJinTime).isBetween(finTime, schedDate, undefined, "(]")) {
        return firstJinTime;
      }
      else if (moment(schedTime).isSameOrBefore(finTime)) {
        return finTime;
      }
      else {
        return finTime;
      }
    }
    const calculateLateTime = (scheduleDate, firstJinDate, inNumber) => {

      // const firstJin = moment(firstJinDate);
      // var lateJudgeIn = moment.duration(firstJin.diff(scheduleDate));
      if (caseData.judgedInOut.length && caseData.judgedInOut[0].late && caseData.judgedInOut[0].lateTime && caseData.judgedInOut[0].late.reason &&  caseData.judgedInOut[0].late.reason == "Delayed Start") {
        return 0;
      } else {
        const firstJin = moment(firstJinDate).utcOffset(timezoneOffset).format();
        const schDate = moment(scheduleDate).format('Y-MM-DDTHH:mm:ss') + timezoneOffset;
        var lateJudgeIn = moment.duration(moment(firstJin).diff(moment(schDate)));
        const hours = parseInt(lateJudgeIn.asHours());
        const minutes = parseInt(lateJudgeIn.asMinutes()) % 60;
        if (inNumber) return (hours > 0 || minutes > 0) ? hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') : 0;
        return (hours > 0 || minutes > 0) ? (hours ? hours + ' hours ' : '') + minutes + ' minutes' : 0;
      }
    }
    // const calculateJudgeTime = (judgedInOut) => {
    //   let totalTime = 0;
    //   for (let i = 0; i < judgedInOut.length; i += 2) {
    //     const jin = moment(judgedInOut[i].date);
    //     const jout = moment(judgedInOut[i + 1].date);
    //     totalTime += moment.duration(jout.diff(jin))
    //   }
    //   return totalTime;
    // }
    const calculateLunchTime = (caseData) => {
      if (caseData.lunchOut && caseData.lunchIn) {
        const lout = moment(caseData.lunchOut.date);
        const lin = moment(caseData.lunchIn.date);
        const duration = moment.duration(lin.diff(lout));
        // const maxLunchTime = (woControls.maxLunchTime * 60000); // in milliseconds
        const maxLunchTime = (caseData.woCourtSettings && caseData.woCourtSettings.maxLunchTime) ? (caseData.woCourtSettings.maxLunchTime * 60000) : (woControls.maxLunchTime * 60000);
        // const hours = parseInt(duration.asHours());
        // const minutes = parseInt(duration.asMinutes())%60;
        // const defaultLunch = 7200000
        return duration > maxLunchTime ? maxLunchTime : duration;
      }
      return 0;
    }
    const calculateExtTime = (extTime) => {
      let totalTime = 0;
      for (let i = 0; i < extTime.length; i++) {
        let start = moment(extTime[i].date).utcOffset(timezoneOffset);
        if (i > 0) {
          var currentTimeMinute = moment(start).format('mm');
          var remainingMinute = 60 - currentTimeMinute;
          totalTime += (60000 * remainingMinute);
          start = moment(extTime[i].nextHourTime ? extTime[i - 1].nextHourTime : extTime[i - 1].extendTime ? extTime[i - 1].extendTime : extTime[i - 1].nextHourTime).utcOffset(timezoneOffset);
        }
        const end = moment(extTime[i].nextHourTime ? extTime[i].nextHourTime : extTime[i].extendTime).utcOffset(timezoneOffset);
        totalTime += moment.duration(end.diff(start))
      }
      return totalTime;
    }

    let woDisplay, totalWoPay, lunchTime, extendTime;
    const schedDate = moment.utc(caseData.deldate).format('MM-DD-YYYY') + ' ' + caseData.scheduleTime;
    let schedDateWithTZ = moment.utc(caseData.deldate).format('YYYY-MM-DD') + 'T' + caseData.scheduleTime + '' + timezoneOffset;
    let schedTimezone = moment(schedDateWithTZ).utcOffset(timezoneOffset).format('Z')
    schedDateWithTZ = moment.utc(caseData.deldate).format('YYYY-MM-DD') + 'T' + caseData.scheduleTime + '' + schedTimezone;
    const hasJin = caseData.judgedInOut && caseData.judgedInOut.length && caseData.judgedInOut[0].date;
    let hasFin = caseData.checkedIn && caseData.checkedIn.date;
    if (!hasFin && caseData.sertype == VRI) {
      hasFin = hasJin;
    }
    // if(!hasFin || !hasJin) {
    //   return {
    //   startTime: 'N/A',
    //   totalLunchTime: 'N/A',
    //   lateTime: 'N/A',
    //   releasedTime: 'N/A',
    //   endTime: 'N/A',
    //   totalWOTime: 'N/A',
    //   totalWoPay: 'N/A'
    //   };
    // }
    let foutTime = caseData.checkedOut && caseData.checkedOut.date ? moment(caseData.checkedOut.date).utcOffset(timezoneOffset) : null;
    let judgedInOut = _.sortBy(caseData.judgedInOut, ['date'], ['asc']);
    const firstJin = hasJin ? judgedInOut[0].date : null;
    // const firstJin = hasJin ? caseData.judgedInOut[0].date : null;
    let startTime = null;
    if(hasFin) {
      startTime = getStartTime(schedDateWithTZ, hasFin ? caseData.checkedIn.date : null, hasJin ? caseData.judgedInOut[0].date : null)
      startTime = moment(startTime).utcOffset(timezoneOffset);
    }

    if (!startTime) return defaultState;
    let minWoTime = 180;
    if (caseData.woCourtSettings && caseData.checkedOut.date && caseData.checkedOut.date) {
      minWoTime = caseData.woCourtSettings.minWoTime || 180;
      if (caseData.sertype == OPI) {
        minWoTime = caseData.woCourtSettings.minWoOPITime || 60;
      } else if (caseData.sertype == VRI) {
        minWoTime = caseData.woCourtSettings.minWoOPITime || 60;
      }
    } else {
      if (!woControls) {
        woControls = {}
      }
      minWoTime = woControls.minWoTime || 180;
      if (caseData.sertype == OPI) {
        minWoTime = woControls.minWoOPITime || 60;
      } else if (caseData.sertype == VRI) {
        minWoTime = woControls.minWoOPITime || 60;
      }
    }
    const minWorkOrder = minWoTime / 60; // in hours
    const releasedTime = caseData.release && caseData.release.length ? moment(caseData.release[caseData.release.length - 1].date).utcOffset(timezoneOffset).format('hh:mm A') : null;
    const releasedDateTime = caseData.release && caseData.release.length ? caseData.release[caseData.release.length - 1].date : null;

    let endTime = caseData.checkedOut && caseData.checkedOut.date ? moment(caseData.checkedOut.date).utcOffset(timezoneOffset).format('hh:mm A') : null;
    let endDateTime = caseData.checkedOut && caseData.checkedOut.date ? caseData.checkedOut.date : null;
    if (caseData.sertype == OPI || caseData.sertype == VRI) {
      if (caseData.judgedInOut && caseData.judgedInOut.length > 0) {
        var lastJudgeInOut = caseData.judgedInOut[caseData.judgedInOut.length - 1];
        if (lastJudgeInOut && lastJudgeInOut.event == 'Judge OUT') {
          endTime = moment(lastJudgeInOut.date).utcOffset(timezoneOffset).format('hh:mm A');
          foutTime = moment(lastJudgeInOut.date).utcOffset(timezoneOffset);
          endDateTime = lastJudgeInOut.date
        } else {
          endTime = null;
          foutTime = null;
          endDateTime = null;
        }
      }
    }
    const totalLunchTime = calculateLunchTime(caseData);
    const totalWOTime = !startTime ? minWorkOrder : foutTime ? moment.duration(foutTime.diff(startTime)) : 0;
    // let totalJobTime = totalWOTime ? (totalWOTime - totalLunchTime) : 0;
    let totalJobTime = totalWOTime ? (totalWOTime - totalLunchTime) / (1000 * 60 * 60) : 0;


    if (caseData.extendIC && caseData.extendIC.length) {
      // const initExtTime = moment(caseData.extendIC.date);
      // const extendedTime = moment(caseData.extendIC.nextHourTime);
      const duration = calculateExtTime(caseData.extendIC);
      const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((duration / (1000 * 60)) % 60);

      extendTime = hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0');
    }
    // const totalJobTime = totalWOTime ? totalWOTime - totalLunchTime : null;
    if (totalLunchTime) {
      const hours = Math.floor((totalLunchTime / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((totalLunchTime / (1000 * 60)) % 60);
      lunchTime = (hours > 0 || minutes > 0) ? (hours ? hours + ' hours ' : '') + minutes + ' minutes' : 0
    }

    if (totalJobTime) {
      const hours = Math.floor(totalJobTime);
      const minutes = Math.floor((totalJobTime * 60) % 60);
      woDisplay = hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0');

      totalJobTime = totalJobTime < minWorkOrder ? minWorkOrder : totalJobTime; // rounding off total WO time to minimum work order
      totalWoPay = Math.ceil(totalJobTime).toString().padStart(2, '0') + ':00'; //caseData.sertype == OSI ? Math.ceil(totalJobTime).toString().padStart(2, '0') + ':00' : woDisplay;
    }

    return {
      startTime: startTime,
      schedDate: schedDate,
      schedDateWithTZ: schedDateWithTZ,
      startTimeBillable: startTime ? moment(startTime).utcOffset(timezoneOffset).format('hh:mm A') : 'N/A',
      totalLunchTime: totalLunchTime ? lunchTime : 'N/A',
      totalLunchTimeInDuration: totalLunchTime ? totalLunchTime : 0,
      lateTime: hasJin ? calculateLateTime(schedDate, firstJin) : 'N/A',
      lateTimeInNumber: hasJin ? calculateLateTime(schedDate, firstJin, true) : 'N/A',
      releasedTime: releasedTime ? releasedTime : 'N/A',
      endTime: endTime ? endTime : 'N/A',
      totalWOTime: totalJobTime ? woDisplay : 'N/A',
      totalWoPay: totalJobTime ? totalWoPay : 'N/A',
      extendTime: extendTime ? extendTime : 'N/A',
      totalJobTime: totalJobTime,
      minWorkOrder: minWorkOrder,
      userLocale: moment(startTime).format('ZZ'),
      releasedDateTime,
      endDateTime,
    };
  }
}
 

// Dependency Injection
CalculateTimeFactory.$inject = ['$timezones', 'OPI', 'OSI', 'VRI'];
