'use strict';


export const formJudgeDirective = function (Modal, $rootScope, $q, $timeout, $clientSwitcher) {
        return {
            restrict: 'E',
            templateUrl: 'components/forms/judge.html',
            scope: {
                judge: '=ngModel',
                onSubmit: '=formOnSubmit',
                onReset: '=formOnReset'
            },
            link: function (scope, el, attrs) {
                scope.clientSettings = function () { return $clientSwitcher.getFeatureOptions('client.settings'); };
                scope.numberPattern = /^[0-9]/;
                scope.urlPattern = /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
                function initPicker() {
                    $timeout(function () {
                        $('#datePicker').datepicker({
                            startDate: new Date(),
                            orientation: "bottom left"
                            // endDate: '+20d'
                        }).on('changeDate', function (e) {
                            if (e.date) {
                                $timeout(function () {
                                    var text = moment(e.date).format('MM/DD/YYYY');
                                    $('#datePicker').val(text);
                                    scope.judge.startdate = moment(e.date).format('YYYY-MM-DD');
                                }, 0);
                            } else {
                                $timeout(function () {
                                    var text = moment(scope.judge.startdate).format('MM/DD/YYYY');
                                    $('#datePicker').val(text);
                                }, 0);
                            }
                        });

                        var text = scope.judge.startdate ? moment(scope.judge.startdate).format('MM/DD/YYYY') : '';
                        $('#datePicker').val(text);
                        if (scope.judge.startdate) {
                            $("#datePicker").datepicker("setDate", text);
                        }
                    }, 0);
                }
                initPicker();
            }
        };
    }
// Dependency Injection
formJudgeDirective.$inject = ["Modal","$rootScope","$q","$timeout","$clientSwitcher"];
