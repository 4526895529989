'use strict';

export const UploadInterpreterPicsConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard.uploadInterpreterPics', {
        url: '/uploadInterpreterPics',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/upload-interpreter-pics/upload-interpreter-pics.html', 'ff08268a'); }],
        controller: 'UploadInterpreterPicsCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/upload-interpreter-pics/upload-interpreter-pics.controller.js', 'UploadInterpreterPicsCtrl'); }]
                      },
        data: {
          ability: 'client.uploadInterpreterPics',
          permission: 'UPLOAD_INTERPRETER_PICS_READ'
        }
      });
  }
// Dependency Injection
UploadInterpreterPicsConfig.$inject = ["$stateProvider"];
