


'use strict';

export const SOSEmailNotificationFactory  = function ($resource, appConfig) {
    return $resource(appConfig.apiUrl + '/api/sos-email-information/:id/:controller', {
        id: '@_id'
    },
    {
        getSOSEmailInformation: {
          method: 'POST',
          params: {
            id: '@id',
            controller: '@controller'
          }
        },
        getFilterDetails: {
          method: 'POST',
          params: {
            id: '@id',
            controller: '@controller'
          }
        }
    });
  } 
 

// Dependency Injection
SOSEmailNotificationFactory.$inject = ['$resource', 'appConfig'];
