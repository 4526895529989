
'use strict';

export const $performTaskRemoveService  = function ($clientTask, Modal) {
  return function (cb) {
    cb = angular.isFunction(cb) ? cb : angular.noop;
    return Modal.alertActions.removeTask(function (result, record, assignResult) {
      return cb.call(this, result, record, assignResult);
    }, 'Remove Task');
  };
}
 

// Dependency Injection
$performTaskRemoveService.$inject = ['$clientTask', 'Modal'];
