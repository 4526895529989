
'use strict';

export const $performTaskAudioService  = function (SurveyModal) {
  return function(cb) {
    cb = angular.isFunction(cb) ? cb : angular.noop;
    return SurveyModal.info.listenToAudio(function (result) {
      return cb.call(this, result);
    });
  };
}
 

// Dependency Injection
$performTaskAudioService.$inject = ['SurveyModal'];
