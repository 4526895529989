'use strict';


export const formCustomerDirective = function ($clientSwitcher, $activeClient, $clientRegion, $clientOffice, $clientEmployee, $q, NgMap, $clientUser) {
    return {
      restrict: 'E',
      templateUrl: 'components/forms/customer.html',
      scope: {
        customer: '=ngModel',
        onSubmit: '=formOnSubmit',
        onReset: '=formOnReset'
      },
      link: function(scope, el, attrs) {
        
        var map;
        var DEFAULT_CENTER = [
          38.393597126079406,
          -100.39715576171875
        ];
        var DEFAULT_ZOOM = 4;
        var currentClient;
        var allOffices = [];
        var DEFAULT_CUSTOMER_TYPE = 'business';

        scope.clientStrings = function(){ return $clientSwitcher.getFeatureOptions('client.strings'); };
        scope.contactPreferences = [
          {value: 'email', label: 'Email'},
          {value: 'phone', label: 'Phone'},
          {value: 'sms', label: 'SMS'}
        ];

        scope.lengthUnits = [
          {value: 'meters', label: 'Meters', increment: 100},
          {value: 'feet', label: 'Feet', increment: 1000}
        ];

        scope.customerTypes = [
          {value: 'business', label: 'Business'},
          {value: 'individual', label: 'Individual'}
        ];
        scope.customer.type = scope.customer.type || DEFAULT_CUSTOMER_TYPE;
        scope.customer.contactType1 = "email";
        scope.customer.contactType2 = "phone";

        var loadRegions = function(client) {
          scope.regions = [];

          return $clientRegion.listAll(client)
            .then(function (regions) {
              scope.regions = regions.map(function(region) {
                return {
                  value: region._id,
                  label: region.name
                };
              });
            });
        };
        var loadOffices = function(client) {
          return $clientOffice.listAll(client)
            .then(function (offices) {
              allOffices = offices;
            });
        };
        var loadEmployees = function(client) {
          return $clientEmployee.getEmployees(client, {})
            .then(function(res) {
              if(res.status) {
                scope.employees = res.data.map(function(employee) {
                  return {
                    value: employee._id,
                    label: employee.firstName + ' ' + employee.lastName
                  };
                });
              }
              else {
                scope.employees = [];
              }
            });
        };

        scope.setCenter = function(evt) {
          map.setCenter(evt.latLng);
          scope.customer.long = evt.latLng.lng()
          scope.customer.lat = evt.latLng.lat();
        };

        var setUnit = function(unit) {
          scope.customer.eventRadiusType = unit.value;
          scope.lengthUnitIncrement = unit.increment; 
        };

        scope.setUnit = setUnit;

        var init = function() {
          scope.isLoading = true;

          if(!scope.customer.eventRadiusType) {
            setUnit(scope.lengthUnits[0]);
          } else {
             scope.lengthUnits.forEach(function(unit) {
               if(scope.customer.eventRadiusType === unit.value) {
                setUnit(unit);
               }
             });
           }

          NgMap.getMap().then(function(evtMap) {
            map = evtMap;
          });

          $activeClient(function(client) {
            currentClient = client;
            scope.clients = $clientSwitcher.getLoaded();

            $q.all([
              loadRegions(client),
              loadOffices(client),
              loadEmployees(client)
            ])
            .then(function() {
              if(scope.customer.region) {
                scope.regionChanged();
              }
              scope.loadUser(client);
            })
            .finally(function() {
              scope.isLoading = false;
              setTimeout(function() {
                google.maps.event.trigger(map, "resize");
                map.setCenter({lat: DEFAULT_CENTER[0], lng: DEFAULT_CENTER[1]})
                map.setZoom(DEFAULT_ZOOM);
              }, 1000)
            });
          });
        };
        init();

        scope.loadUser = function (client) {
          return $clientUser.list(client)
            .then(function (users) {
              if (!scope.customer.user) {
                scope.customer.user = [];
              }
              scope.users = users.map(function (user) {
                
                return {
                  value: user.userId,
                  label: user.name
                };
              });
            });
        }

        scope.valueSelected = function(user){
          scope.customer.user = user.value;
        };

        scope.regionChanged = function() {
          scope.offices = allOffices
          .filter(function(office) {
            return office.region === scope.customer.region;
          })
          .map(function (office) {
            return {
              value: office._id,
              label: office.name
            };
          });
        };

        if(scope.customer.region) {
          scope.regionChanged();
        }
      }
    };
  }
// Dependency Injection
formCustomerDirective.$inject = ["$clientSwitcher","$activeClient","$clientRegion","$clientOffice","$clientEmployee","$q","NgMap","$clientUser"];
