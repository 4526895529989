


'use strict';

export const InterpreterMatrixFactory  = function ($resource, appConfig) {
        return $resource(appConfig.apiUrl + '/api/interpreter-matrix/:id/:controller', {
            id: '@_id'
        },
            {
                getICMatrixBuckets: {
                    method: 'POST',
                    params: {
                        id: '@id',
                        controller: '@controller'
                    }
                },
                getICMatrixDetails: {
                    method: 'POST',
                    params: {
                        id: '@id',
                        controller: '@controller'
                    }
                },
                getICMatrixFilterDetails: {
                    method: 'POST',
                    params: {
                        id: '@id',
                        controller: '@controller'
                    }
                }
            });
    } 
 

// Dependency Injection
InterpreterMatrixFactory.$inject = ['$resource', 'appConfig'];
