'use strict';

angular
export const DashboardSettingsRegionsIndexCtrl = function ($rootScope, $scope, $auth, $activeClient, $clientRegion, $client) {

  $scope.loading = false;
  $scope.orderVal = 'true';
  $scope.sortingOrders = [{
    label:'A-Z',
    value: 'true'
  }, {
    label: 'Z-A',
    value: 'false'
  }];
  function reloadRegions(client) {
    $scope.loading = true;
    $scope.allowEdit = $auth.getCurrentUser().permissions.includes('SETTING_REGION_EDIT');
    return $client.getRegions(client)
      .then(function (regions) {
        $scope.regions = regions;
      })
      .finally(function () {
        $scope.loading = false;
      });
  }

  // listen for client switching
  $scope.$on('$destroy', $activeClient(function (client) {
    if(!client) return;

    reloadRegions(client);
  }));
}
// Dependency Injection
DashboardSettingsRegionsIndexCtrl.$inject = ["$rootScope","$scope","$auth","$activeClient","$clientRegion","$client"];

// angular.module('coordinateApp').controller('DashboardSettingsRegionsIndexCtrl', DashboardSettingsRegionsIndexCtrl);
