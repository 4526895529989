
'use strict';

export const BucketFactory  = function () {
  function Bucket(opts) {
    var DEFAULT_SORT_FIELD = 'created';
    opts = opts || {};
    this.records = [];
    this.name = opts.name;
    this.description = opts.description;
    this.sortBy = opts.sortBy || 'created';
    this.isDefaultSortOrderReverse = !!opts.isDefaultSortOrderReverse;
    this.displayOrder = opts.displayOrder || -1;
    this.taskCount = opts.taskCount;

    if(angular.isArray(opts.types)) {
      this.types = opts.types;
    }

    if(opts.records) {
      opts.records.forEach(this.add.bind(this));
    }
  }

  Object.defineProperties(Bucket.prototype, {
    length: {
      get: function () {
        return !!this.records ? this.records.length : 0;
      }
    }
  });

  Bucket.prototype.item = function () {
    return false;
  };

  Bucket.prototype.clear = function () {
    this.records.splice(0, this.length);
    return this;
  };

  Bucket.prototype.add = function () {
    var item = this.item.apply(this, arguments);
    if(!item) return item;

    // var isTaskAlreadyPresent = _.some(this.records, function(record) {
    //   return record._id === item._id;
    // });

    // if(isTaskAlreadyPresent) return item;

    this.records.push(item);
    return item;
  };

  Bucket.prototype.sort = function (fn) {
    this.records.sort(fn);
    return this;
  };

  return Bucket;
}
 
