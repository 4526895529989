'use strict';

angular
export const DashboardSettingsSurveyScoreIndexCtrl = function ($rootScope, $scope, $activeClient, $clientSurveyQuestion, Modal) {

  $scope.loading = false;

  function reloadQuestions(client) {
    $scope.loading = true;
    return $clientSurveyQuestion.getQuestions(client)
      .then(function (res) {
        if(res.status) {
          $scope.questions = res.data;
        }
      })
      .finally(function () {
        $scope.loading = false;
      });
  }

  // listen for client switching
  $scope.$on('$destroy', $activeClient(function (client) {
    if(!client) return;

    reloadQuestions(client);
  }));

  $scope.deleteQuestion = function(question) {
    $activeClient(function (client) {
      if(!client) {
        return;
      }

      Modal.confirm.show(
        "Confirm Delete",
        "Are you sure you want to delete <strong>"+question.text+"</strong> ?",
        "Delete", "Cancel",
        function(){
        $clientSurveyQuestion.deleteQuestion(client, question).then(function() {
          reloadQuestions(client);
        });
      });
    });
  };
}
// Dependency Injection
DashboardSettingsSurveyScoreIndexCtrl.$inject = ["$rootScope","$scope","$activeClient","$clientSurveyQuestion","Modal"];
