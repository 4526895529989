
'use strict';

export const $performTaskResolveService  = function ($clientTask, Modal) {
  return function (cb) {
    cb = angular.isFunction(cb) ? cb : angular.noop;
    return Modal.alertActions.resolveRecord(function (result, record, resolveResult) {
          return cb.call(this, result, record, resolveResult);
    }, 'Resolve Task');
  };
}
 

// Dependency Injection
$performTaskResolveService.$inject = ['$clientTask', 'Modal'];
