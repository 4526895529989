
'use strict';

export const $performTaskUpdateService  = function ($clientTask, Modal) {
  return function(cb) {
    cb = angular.isFunction(cb) ? cb : angular.noop;
    return Modal.taskActions.addAttachments(function(record, result) {
      return cb.call(this, record, result);
    });
  };
}
 

// Dependency Injection
$performTaskUpdateService.$inject = ['$clientTask', 'Modal'];
