'use strict';


 export const $permissionMatrixSourceTypeService = function ($clientPermissionTypes) {
    return function (permissionType) {
      if (!permissionType) return 'N/A';
      let typeName = _.find($clientPermissionTypes, (o) => o.value == permissionType);
      let type = 'N/A';
      switch (typeName.value) {
        case 'REPORT_BUCKET':
          type = 'Reports';
          break;
        case 'FULFILLMENT_OPERATIONS':
          type = 'Fulfillment Operations';
          break;

        default:
          type = 'Roles';
          break;
      }
      return type;
    }
  }
// Dependency Injection
$permissionMatrixSourceTypeService.$inject = ["$clientPermissionTypes"];

