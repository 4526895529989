


'use strict';

export const TaskActionsLogsManagerFactory  = function ($resource, appConfig) {
    return $resource(appConfig.apiUrl + '/api/task-actions-logs/:id/:taskId', {
      id: '@_id'
    },
    {
      getLogs: {
        method: 'GET',
        params: {          
          id: '@id'
        },
        isArray: true
      },

      getTaskLog: {
        method: 'GET',
        params: {          
          id: '@id',
          taskId: '@taskId'
        },
        isArray: true
      }
    });
  } 
 

// Dependency Injection
TaskActionsLogsManagerFactory.$inject = ['$resource', 'appConfig'];
