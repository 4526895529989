
'use strict';

export const $pipelineOperationsService  = function (PipelineOperations) {
    return {
      getPipelineDetails: function (client, filterBy) {
        return PipelineOperations.getPipelineDetail({
          id: client._id || client,
          controller: 'get-pipeline-details',
          filterBy
        }).$promise;
      },
      getPipelineDetailsNew: function (client, filterBy) {
        return PipelineOperations.getPipelineDetailsNew({
          id: client._id || client,
          controller: 'get-pipeline-details-new',
          filterBy
        }).$promise;
      },
      getPipelineIndividualCount: function (client, filterBy) {
        return PipelineOperations.getPipelineIndividualCount({
          id: client._id || client,
          controller: 'elisa-pipeline-counts',
        }).$promise;
      },
      getFilterData: function (client, filter) {
        return PipelineOperations.getFilterData({
          id: client._id || client,
          controller: 'get-pipeline-filter-detail',
          filter
        }).$promise;
      },
      pipelineCount: function (client, filter) {
        return PipelineOperations.pipelineCount({
          id: client._id || client,
          controller: 'pipeline-count',
          filter
        }).$promise;
      },
      pipelineList: function (client, filter) {
        return PipelineOperations.pipelineList({
          id: client._id || client,
          controller: 'pipeline-list',
          filter
        }).$promise;
      },


    };
  }
// Dependency Injection


// Dependency Injection
$pipelineOperationsService.$inject = ['PipelineOperations'];
