'use strict';


export const fileSizeFilter = function ($utilities) {

  var
  bpk  = 1024,
  units = [
    { size:                1, abbr:  'B', name: 'byte',       defaultPrecision: 0 },
    { size:              bpk, abbr: 'KB', name: 'kilobyte',   defaultPrecision: 2 },
    { size: Math.pow(bpk, 2), abbr: 'MB', name: 'megabyte',   defaultPrecision: 2 },
    { size: Math.pow(bpk, 3), abbr: 'GB', name: 'gigabyte',   defaultPrecision: 3 },
    { size: Math.pow(bpk, 4), abbr: 'TB', name: 'terabyte',   defaultPrecision: 3 },
    { size: Math.pow(bpk, 5), abbr: 'PB', name: 'petabyte',   defaultPrecision: 3 },
    { size: Math.pow(bpk, 6), abbr: 'EB', name: 'exabyte',    defaultPrecision: 3 },
    { size: Math.pow(bpk, 7), abbr: 'ZB', name: 'zettabyte',  defaultPrecision: 3 },
    { size: Math.pow(bpk, 8), abbr: 'YB', name: 'yottabyte',  defaultPrecision: 3 },
    { size: Math.pow(bpk, 9), abbr: 'BB', name: 'brontobyte', defaultPrecision: 3 }
  ],
  unitLen = units.length;

  return function (v, precision, longName) {
    var unit = units[unitLen - 1];
    for(var i = 0; i < unitLen; i++) {
      if(v < units[i].size) {
        unit = units[Math.max(i - 1, 0)];
        break;
      }
    }

    var
    name = !!longName ? unit.name : unit.abbr,
    abbr = unit.abbr,
    size = unit.size;

    if(!angular.isNumber(precision)) {
      precision = unit.defaultPrecision;
    }

    v = v / size;

    if(angular.isNumber(precision)) {
      v = $utilities.round(v, precision);
    }

    return v + ' ' + name;
  };
}
// Dependency Injection
fileSizeFilter.$inject = ["$utilities"];
