import './time-production-reporting.scss';
'use strict';

export const TimeProductionReportingConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard.timeProductionReporting', {
        url: '/reporting',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/time-production-reporting/time-production-reporting.html', '33daa68a'); }],
        controller: 'TimeProductionReportingCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/time-production-reporting/time-production-reporting.controller.js', 'TimeProductionReportingCtrl'); }]
                      },
        data: {
          ability: 'client.TimeProductionReporting',
          permission: 'TIME_REPORTING_READ'
        }
      });
  }
// Dependency Injection
TimeProductionReportingConfig.$inject = ["$stateProvider"];
