
'use strict';

export const $clientService  = function (Client) {
    return {
      listAll: function () {
        return Client.query({}).$promise;
      },
      userAbilities: function (client) {
        return Client.getAbilities({id: (client._id || client)}).$promise;
      },
      getRegions: function(client) {
        return Client.getRegions({id: (client._id || client)}).$promise;
      },
      getDivisions: function(client){
        return Client.getDivisions({id: (client._id || client)}).$promise;
      },
      getOffices: function(client) {
        return Client.getOffices({id: (client._id || client)}).$promise;
      },
      getBuckets: function(client) {
        return Client.getBuckets({id: (client._id || client)}).$promise;
      },
      getManagers: function(client){
        return Client.getManagers({id: (client._id || client)}).$promise;
      },
      getCampaigns: function(client){
        return Client.getCampaigns({id: (client._id || client)}).$promise;
      },
      getUserCount: function(client) {
        return Client.getUserCount({id: client._id || client}).$promise;
      },
      editClient: function(client, data) {
        return Client.editClient({
          _id: client._id || client,
          data: data
        }).$promise;
      },
      clientAbilities: function(client) {
        return Client.clientAbilities({id: client._id || client}).$promise;
      }
    };
  }


// Dependency Injection
$clientService.$inject = ['Client'];
