'use strict';

 export const setRuleValueFilter = function ($distanceToCourt, $rates, $expirations) {
        return function (value, key) {
            if (!key || !value) return value;
            let arr = [];
            if (key == 'distance') {
                arr = $distanceToCourt;
            } else if (key == 'rate') {
                arr = $rates;
            } else if (key == 'expire') {
                arr = $expirations;
            }
            if (arr && arr.length) {
                let filterVal = arr.find((obj) => obj.value == value);
                if (filterVal) {
                    return filterVal.label;
                }
            }
            return value;
        };
    }
// Dependency Injection
setRuleValueFilter.$inject = ["$distanceToCourt","$rates","$expirations"];
