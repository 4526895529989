
'use strict';

export const $serviceStatusWithBucketNameService  = function ($filter) {
        return function () {
            let status = {
                'quote_requested': {
                    label: `${$filter('humanizeString')('quote_requested')} | Pending Approval`,
                    position: 1
                },
                'quote_approved': {
                    label: `${$filter('humanizeString')('quote_approved')}`,
                    position: 2
                },
                'work_in_process': {
                    label: `${$filter('humanizeString')('work_in_process')}`,
                    position: 3
                },
                'work_completed': {
                    label: `${$filter('humanizeString')('work_completed')} | Pending Acceptance`,
                    position: 4
                },
                'work_accepted': {
                    label: `${$filter('humanizeString')('work_accepted')} | Pending Payment`,
                    position: 5
                },
                'payment': {
                    label: `${$filter('humanizeString')('payment_received')}`,
                    position: 6
                },
                'service_completed': {
                    label: `${$filter('humanizeString')('service_completed')}`,
                    position: 7
                }
            }
            return status;
        }
    }
// Dependency Injection


// Dependency Injection
$serviceStatusWithBucketNameService.$inject = ['$filter'];
