
'use strict';

export const $performSurveyTaskCreateService  = function ($clientTask, SurveyModal) {
  return function (cb) {
    cb = angular.isFunction(cb) ? cb : angular.noop;
    return SurveyModal.taskActions.createTask(function (scratch) {
      return cb.apply(this, arguments);
    }, 'Create a New Task');
  };
}
 

// Dependency Injection
$performSurveyTaskCreateService.$inject = ['$clientTask', 'SurveyModal'];
