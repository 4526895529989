
'use strict';

export const uasModelInternalDirective  = function ($parse) { // task.assignTo | task.assignToOnExpire
  return {
    restrict: 'A',
    require: ['uasModelInternal','uasTask','uasUsers'],
    controller: 'UasModelInternalCtrl as $uasModelInternal',
    link: function(scope, el, attrs, ctrls) {
      var
      ctrl = ctrls[0];

      ctrl.setTask(ctrls[1]);
      ctrl.setUsers(ctrls[2]);

      attrs.$observe('uasModelInternal', function (nv) {
        ctrl.setParser($parse(nv), scope);
      });
    }
  };
} 
 

// Dependency Injection
uasModelInternalDirective.$inject = ['$parse'];
