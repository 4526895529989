


'use strict';

export const ClientCoiVRIInfoFactory  = function ($resource, appConfig) {
        return $resource(appConfig.apiUrl + '/api/coi-vri-info/:id/:controller', {
            id: '@_id'
        },
            {
                create: {
                    method: 'POST',
                    params: {
                        controller: 'create'
                    }
                },
                coiInfo: {
                    method: 'GET',
                    params: {
                        id: '@id'
                    }
                },
                getByRecordnumber: {
                    method: 'GET',
                    params: {
                        id: '@id'
                    }
                },
                getLists: {
                    method: 'GET',
                    params: {
                        id: '@id',
                        controller: 'get-lists'
                    }
                },
                workOrderConnectionInfo: {
                    method: 'GET',
                    params: {
                        id: '@id',
                        controller: 'work-order-tasks-connection-info',
                    }
                },
            });
    } 
 

// Dependency Injection
ClientCoiVRIInfoFactory.$inject = ['$resource', 'appConfig'];
