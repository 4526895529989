'use strict';


export const offeringRuleDirective = function (Modal, $rootScope, $window, $auth, $timeout, $clientSwitcher, $clientOffice, $clientLanguages, $serviceType, $distanceToCourt, $expirations, $rates, $experienceType, $experienceLevels, $scheduledTimes, $judgeRating) {
    return {
      restrict: 'E',
      templateUrl: 'components/forms/offering-rule.html',
      scope: {
        offeringRule: '=ngModel',
        onSubmit: '=formOnSubmit',
        onReset: '=formOnReset'
      },
      link: function (scope, el, attrs) {
        const client = $clientSwitcher.getCurrentClient();
        scope.clientSettings = function () { return $clientSwitcher.getFeatureOptions('client.settings'); };
        scope.allValue = 'all';
        scope.allLocations = [];
        scope.languagesData = [];
        scope.languagesDataCount = [];
        scope.serviceType = $serviceType;
        scope.distanceToCourt = $distanceToCourt;
        scope.expirations = $expirations;
        scope.rates = $rates;
        scope.scheduledTimes = $scheduledTimes;
        scope.experienceType = $experienceType;
        scope.experienceLevels = $experienceLevels;
        scope.judgeRatings = $judgeRating;

        scope.offeringRule.locations = scope.offeringRule.locations && scope.offeringRule.locations.length ? scope.offeringRule.locations : scope.allValue;
        scope.offeringRule.languages = scope.offeringRule.languages && scope.offeringRule.languages.length ? scope.offeringRule.languages : []; //scope.allValue;
        scope.offeringRule.scheduleTime = scope.offeringRule.scheduleTime && scope.offeringRule.scheduleTime.length ? scope.offeringRule.scheduleTime : scope.allValue;
        scope.offeringRule.experienceType = scope.offeringRule.experienceType && scope.offeringRule.experienceType.length ? scope.offeringRule.experienceType : scope.allValue;
        scope.pageLimitForDD = 2000;
        scope.offeringRule.langType = '';
        scope.strSettings = function (str) {
          return {
            selectedStr: ' ',
            noSelection: 'No ' + str + ' Selected',
            allLabel: 'All ' + str
          };
        };

        scope.toggle = function (e){
          if (scope.offeringRule.isExpiration) {
            scope.offeringRule.expiration = scope.offeringRule.prevExpiration || null;
            scope.offeringRule.prevExpiration = null;
            $timeout(() => $window.document.getElementById('expirationInput').focus(), 200)
          } else {
            scope.offeringRule.prevExpiration = scope.offeringRule.expiration;
            scope.offeringRule.expiration = null;
            $timeout(() => $window.document.getElementById('prevExpirationInput').focus(), 200)
          }
        }
        scope.reloadOffices = function () {
          $clientOffice.listAll(client)
            .then(function (offices) {
              offices.forEach((office) => {
                scope.allLocations.push({
                  value: office.internalId,
                  label: office.name,
                  internalId: office.internalId
                });
              });
            });
        };

        scope.selectedLanguages = [];
        scope.onChangeLanguage = function (e) {
          if (e) {
            scope.selectedLanguages = [];
            if (scope.offeringRule && scope.offeringRule.languages && scope.offeringRule.languages != 'all') {
              scope.offeringRule.languages.forEach((langDel) => {
                let isExistIndex = scope.languagesData.findIndex((obj) => obj.value == langDel);
                if (isExistIndex >= 0) {
                  scope.selectedLanguages.push(scope.languagesData[isExistIndex]);
                }
              })
              scope.selectedLanguages = _.uniq(scope.selectedLanguages, 'value');
            }
          }
        }

        let isPageLoading = true;
        scope.reloadLanguages = function (pagination) {
          if (!pagination) {
            scope.languagesData = [];
            pagination = {
              isLangEnabled: true,
              page: 1,
              pageSize: scope.pageLimitForDD,
              searchKey: null,
              orderByValue: 'asc',
              orderByField: 'name',
              type: scope.offeringRule.langType ? scope.offeringRule.langType : undefined
            }
          }
          $clientLanguages.getLanguagesList(client, pagination)
            .then((res) => {
              if (res.status) {
                if (res.languagesList && res.languagesList.length) {
                  res.languagesList.forEach((obj) => {
                    if (obj && obj.isEnabled) {
                      scope.languagesData.push({
                        label: `${obj.name} (${obj.code})`,
                        value: obj.name,
                        code: obj.code
                      })
                    }
                  });
                  scope.languagesDataCount = res.totalRecords;
                  if (isPageLoading) {
                    scope.selectedLanguages = [];
                    if (scope.offeringRule && scope.offeringRule.languages && scope.offeringRule.languages != 'all') {
                      scope.offeringRule.languages.forEach((langDel) => {
                        let isExistIndex = scope.languagesData.findIndex((obj) => obj.value == langDel);
                        if (isExistIndex >= 0) {
                          scope.selectedLanguages.push(scope.languagesData[isExistIndex]);
                        } else {
                          let isExistLangArrIndex = scope.offeringRule.languagesDel.findIndex((obj) => obj.name == langDel);
                          if (isExistLangArrIndex >= 0) {
                            let langData = {
                              label: `${scope.offeringRule.languagesDel[isExistLangArrIndex].name} (${scope.offeringRule.languagesDel[isExistLangArrIndex].code})`,
                              value: scope.offeringRule.languagesDel[isExistLangArrIndex].name,
                              code: scope.offeringRule.languagesDel[isExistLangArrIndex].code
                            }
                            scope.selectedLanguages.unshift(langData);
                          }
                        }
                      })
                      scope.selectedLanguages = _.uniq(scope.selectedLanguages, 'value');
                    }
                    isPageLoading = false;
                  }
                  if (scope.offeringRule && scope.offeringRule.languages && scope.offeringRule.languages != 'all') {
                    if (pagination.searchKey) {
                      let reduceCount = 0;
                      scope.selectedLanguages.forEach((langDel) => {
                        let isExistIndex = scope.languagesData.findIndex((obj) => obj.value == langDel.value);
                        if (isExistIndex >= 0) {
                          ++reduceCount;
                        }
                      })
                      scope.languagesDataCount = (scope.languagesDataCount - reduceCount) + scope.selectedLanguages.length;
                    }
                    scope.languagesData = scope.selectedLanguages.concat(scope.languagesData);
                  } else {
                    scope.offeringRule.languages = _.uniq(scope.languagesData, 'code').map(item => item.value);
                  }
                  scope.languagesData = _.uniq(scope.languagesData, 'code');
                } else {
                  console.log('Language Not Found.');
                  scope.languagesDataCount = 0;
                }
              }
            })
            .catch((err) => {
              let errMessage = err.message || err;
              if (err && err.data && err.data.message) {
                errMessage = err.data.message;
              } else if (err && err.data && err.data.reason) {
                errMessage = err.data.reason;
              }
              console.log(errMessage);
              scope.languagesDataCount = 0;
            })
            .finally(function () {
              scope.ddLoading = false;
            })
        }

        scope.loadPaginateData = function (searchKey, page, type) {
          scope.ddLoading = true;
          let pagination = {
            isLangEnabled: true,
            page: page,
            pageSize: scope.pageLimitForDD
          }
          let search = {};
          switch (type) {
            case 'languages':
              if (page == 1) {
                scope.languagesData = [];
              }
              pagination.searchKey = searchKey && searchKey.length ? (searchKey).toLowerCase() : undefined;
              pagination.orderByValue = 'asc';
              pagination.orderByField = 'name';
              pagination.type = scope.offeringRule.langType
              scope.reloadLanguages(pagination);
              break;
            default:
              break;
          }
        }
        scope.onLangFilterChange = function (e, type) {
          scope.offeringRule.languages =  scope.offeringRule &&  scope.offeringRule._id ? scope.offeringRule.languages : 'all';
          scope.languagesData = [];
          let pagination = {
            isLangEnabled: true,
            page: 1,
            pageSize: scope.pageLimitForDD,
            orderByValue: 'asc',
            orderByField: 'name',
            type: type
          }
          scope.reloadLanguages(pagination)
        }
        scope.disabledDistanceToCourt = function (offeringRule) {
          if (offeringRule.serviceType == 'OPI' || offeringRule.serviceType == 'VRI') {
            offeringRule.distanceToCourt = null;
            return true;
          }
          return false;
        }

        function init() {
          scope.reloadOffices();
          scope.reloadLanguages();
        }

        scope.disabledExperienceLevels = function (offeringRule) {
          if (offeringRule.experienceType == 'all') {
              return true;
          } else if (offeringRule.experienceType != 'all') {
            if (Array.isArray(offeringRule.experienceType) && offeringRule.experienceType.length) {
              return true;
            }
          }
          return false;
        }
        scope.setting1 = {
          scrollableHeight: '250px',
          scrollable: true,
          enableSearch: true
        };

        init();

      }
    };
  }
// Dependency Injection
offeringRuleDirective.$inject = ["Modal","$rootScope","$window","$auth","$timeout","$clientSwitcher","$clientOffice","$clientLanguages","$serviceType","$distanceToCourt","$expirations","$rates","$experienceType","$experienceLevels","$scheduledTimes","$judgeRating"];
