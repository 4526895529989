'use strict';

export const InterpreterMatrixConfig = function ($stateProvider) {
    $stateProvider
      .state('app.print.interpreterMatrix', {
        url: '/interpreterMatrix',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./print/interpreter-matrix/interpreter-matrix.html', '9710b1db'); }],
        controller: 'PrintInterpreterMatrixCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./print/interpreter-matrix/interpreter-matrix.controller.js', 'PrintInterpreterMatrixCtrl'); }]
                      }
      });
  }
// Dependency Injection
InterpreterMatrixConfig.$inject = ["$stateProvider"];
