'use strict';


export const uploadFormS3Directive = function (FileUploader, $timeout, $clientInterpreterResources, $clientSwitcher, Modal) {
    return {
      restrict: 'E',
      require: 'ngModel',
      templateUrl: 'components/upload-s3/upload-s3.html',
      scope: {
        model: '=ngModel',
        uploader: '=',
        existingFiles: '=',
        isAttachmentDate: '=isAttachmentDate',
        isSingleUploader: '=',
        dateLabel: '=',
        validateFileOverwrite: '=',
        validateForFolder: '=',
        control: '='
      },
      link: function (scope, el, attrs) {
        const client = $clientSwitcher.getCurrentClient();
        scope.uploadingFiles = false;
        scope.attachmentDateLabel = scope.dateLabel || 'Attachments Date';
        function initPicker() {
          if (scope.isAttachmentDate) {
            $timeout(function () {
              $('#datePicker').daterangepicker({
                singleDatePicker: true
              }, function (start, end, label) {
                if (!start || !end) {
                  return;
                }

                $('#datePicker span').html(start.format('MM/DD/YYYY'));
                scope.model.attachmentDate = start.format();
              });

              var text = moment().format('MM/DD/YYYY');
              scope.model.attachmentDate = moment().format();
              $('#datePicker span').html(text);
            }, 0);
          }
        }
        initPicker();

        scope.clearQueue = function () {
          scope.uploader.queue = [];
        }

        scope.removeQueueItem = function (index) {
          var deleteFile = Array.from(scope.uploader.queue);
          deleteFile.splice(index, 1);
          scope.uploader.queue = deleteFile;
        }

        scope.$on("s3upload:progress", function (evt, data) {
          // console.log('Progress', data.value, scope.control, data.formControl)
          if (scope.control == data.formControl) {
            scope.uploader.queue[data.index].progress = data.value;
            scope.uploader.queue[data.index].isReady = true;
          }
          scope.$apply();
        });
        scope.$on("s3upload:success", function (evt, data) {
          // console.log('Success', data)
          if (scope.control == data.formControl) {
            scope.uploader.queue[data.index].isSuccess = true;
            scope.uploader.queue[data.index].isReady = true;
          }
          scope.$apply();
        });
        scope.$on("s3upload:error", function (evt, data) {
          // console.log('Error', data)
          if (scope.control == data.formControl) {
            scope.uploader.queue[data.index].isError = true;
            scope.uploader.queue[data.index].isReady = true;
          }
          scope.$apply();
        });
        scope.$on("s3upload:abort", function (evt, data) {
          // console.log('Abort', data)
          if (scope.control == data.formControl) {
            scope.uploader.queue[data.index].isCancel = true;
            scope.uploader.queue[data.index].isReady = true;
          }
          scope.$apply();
        });

        scope.fileNameChanged = function (ele, control) {
          if (!scope.uploader.queue || !Array.isArray(scope.uploader.queue)) {
            scope.uploader.queue = [];
          }
          _.forEach(ele.files, (obj) => {
            scope.uploader.queue = scope.uploader.queue.concat(obj);
          })
          if (scope.isSingleUploader && scope.validateFileOverwrite) {
            scope.validateForFolder.name = ele.files[0].name || '';
            $clientInterpreterResources.fileExist(client, scope.validateForFolder)
              .then((res) => {
                if (res && res.status && res.result && res.result.length > 0) {
                  // Open overwrite popup
                  Modal.confirm.showWithCancelCB("Confirm File Overwrite", "The file already exists in the selected folder. Do you want to overwrite it?", "Yes", "No", function (result) {
                    if (result) {
                      scope.model.isOverwrite = true;
                      scope.model.currentFileObjId = res.result[0]._id;
                    } else {
                      scope.model.isOverwrite = false;
                    }
                  });
                }
              })
              .catch((err) => {
                console.log(err)
              })
          }
          angular.forEach(
            angular.element("input[type='file']"),
            function (inputElem) {
              angular.element(inputElem).val(null);
            });
          scope.$apply()
        };

        scope.$watch('uploader', function (uploader) {
          if (!uploader) return;

          uploader.onAfterAddingFile = function (fileItem) {
            if (scope.validateFileOverwrite) {
              scope.validateForFolder.name = fileItem.file.name || '';
              $clientInterpreterResources.fileExist(client, scope.validateForFolder)
                .then((res) => {
                  if (res && res.status && res.result && res.result.length > 0) {
                    // Open overwrite popup
                    Modal.confirm.showWithCancelCB("Confirm File Overwrite", "The file already exists in the selected folder. Do you want to overwrite it?", "Yes", "No", function (result) {
                      if (result) {
                        scope.model.isOverwrite = true;
                        scope.model.currentFileObjId = res.result[0]._id;
                      } else {
                        scope.model.isOverwrite = false;
                      }
                    });
                  }
                })
                .catch((err) => {
                  console.log(err)
                })
            }
          };
        });
      }
    };
  }
// Dependency Injection
uploadFormS3Directive.$inject = ["FileUploader","$timeout","$clientInterpreterResources","$clientSwitcher","Modal"];
