'use strict';

angular
export const DashboardSettingsCampaignsIndexCtrl = function ($rootScope, $scope, $activeClient, $clientCampaign, $client) {

  $scope.loading = false;

  function reloadCampaigns(client) {
    $scope.loading = true;
    return $client.getCampaigns(client)
      .then(function (campaigns) {
        $scope.campaigns = campaigns;
      })
      .finally(function () {
        $scope.loading = false;
      });
  }

  // listen for client switching
  $scope.$on('$destroy', $activeClient(function (client) {
    if(!client) return;

    reloadCampaigns(client);
  }));
}
// Dependency Injection
DashboardSettingsCampaignsIndexCtrl.$inject = ["$rootScope","$scope","$activeClient","$clientCampaign","$client"];

// angular.module('coordinateApp').controller('DashboardSettingsCampaignsIndexCtrl', DashboardSettingsCampaignsIndexCtrl);
