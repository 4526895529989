'use strict';

export const elisaPipelineCountsDirective = function ($auth, $clientSwitcher, $pipelineOperations, ngToast) {
        return {
            restrict: 'E',
            templateUrl: 'app/account/dashboard/settings/postgres_data_sync/elisa_pipeline_counts/elisa_pipeline_counts.html',
            scope: {},
            link: function ($scope) {
                const currentUser = $auth.getCurrentUser();
                const currentCli = $clientSwitcher.getCurrentClient();
                function init() {
                    if ($scope.isLoading) return;

                    $scope.isLoading = true
                    $pipelineOperations.getPipelineIndividualCount(currentCli)
                        .then((result) => {
                            $scope.isLoading = false;
                            $scope.counts = result
                        })
                        .catch((error) => {
                            ngToast.create({
                                content: error.message || error,
                                className: 'danger'
                            });
                            $scope.isLoading = false;
                        })
                }

                $scope.reload = function() {
                    if ($scope.isLoading) return;
                    init()
                }
                init()
            }
        }
    }
// range filter already exists in task.service.js
elisaPipelineCountsDirective.$inject = ["$auth", "$clientSwitcher", "$pipelineOperations", "ngToast"]
