


'use strict';

export const ClientInterpreterResourcesFactory  = function ($resource, appConfig) {
    return $resource(appConfig.apiUrl + '/api/interpreter-resources/:id/:controller', {
      id: '@_id'
    },
      {
        create: {
          method: 'POST',
          params: {
            controller: 'create'
          }
        },
        getInterpreterResourcesData: {
          method: 'GET',
          params: {
            id: '@id'
          }
        },
        deleteAttachment: {
          method: 'POST',
          params: {
            controller: 'deleteAttachment'
          }
        },
        editCCMessage: {
          method: 'POST',
          params: {
            controller: 'editCCMessage'
          }
        },
        deleteCCMessage: {
          method: 'POST',
          params: {
            controller: 'deleteCCMessage'
          }
        },
        getCCMessagesData: {
          method: 'POST',
          params: {
            controller: 'getCCMessagesData'
          }
        },
        getInterpreters: {
          method: 'GET',
          params: {
            controller: 'getInterpreters'
          }
        },
        getAllRegisteredInterpreters: {
          method: 'GET',
          params: {
            controller: 'getAllRegisteredInterpreters',
            fields: '@fields'
          }
        },
        setAttachmentFavorite: {
          method: 'POST',
          params: {
            controller: 'set-attachment-favorite'
          }
        },
        deleteFolder: {
          method: 'POST',
          params: {
            controller: 'delete-folder'
          }
        },
        updateFolder: {
          method: 'POST',
          params: {
            controller: 'update-folder'
          }
        },
        setAttachmentPin: {
          method: 'POST',
          params: {
            controller: 'set-attachment-pin'
          }
        },
        downloadAttachments: {
          method: 'POST',
          params: {
          }
        },
        myResourcesCreate: {
          method: 'POST',
          params: {
            controller: 'my-resources-create'
          }
        },
        getMyResourcesRecords: {
          method: 'GET',
          params: {
            controller: 'get-my-resources-records'
          }
        },
        getUserFavorites: {
          method: 'GET',
          params: {
            controller: 'get-user-favorites'
          }
        },
        getUserPinned: {
          method: 'GET',
          params: {
            controller: 'get-user-pinned'
          }
        },
        updateFile: {
          method: 'POST',
          params: {
            controller: 'update-file'
          }
        },
        fileExist: {
          method: 'POST',
          params: {
            controller: 'file-exist'
          }
        },
        updateExistFile: {
          method: 'POST',
          params: {
            controller: 'update-exist-file'
          }
        }
      });
  }


// Dependency Injection
ClientInterpreterResourcesFactory.$inject = ['$resource', 'appConfig'];
