
'use strict';

export const $uploadS3AttachmentService  = function ($clientUpload, $http) {
    return {
      upload: function (currentClientId, uploader, scope, formControl) {
        return Promise.all(
          _.map(uploader.queue, (file, index) => {
            return new Promise((resolve, reject) => {
              return $clientUpload.generateSignedUrl(currentClientId)
                .then((res) => {
                  if (res && res.status) {
                    var progress = '';
                    var xhr = new XMLHttpRequest();
                    xhr.upload.addEventListener("progress", uploadProgress, false);
                    xhr.addEventListener("load", uploadComplete, false);
                    xhr.addEventListener("error", uploadFailed, false);
                    xhr.addEventListener("abort", uploadCanceled, false);

                    function uploadProgress(e) {
                      if (e.lengthComputable) {
                        progress = Math.round(e.loaded * 100 / e.total);
                      } else {
                        progress = 'unable to compute';
                      }
                      var msg = { type: 'progress', value: progress, index: index, formControl };
                      scope.$broadcast('s3upload:progress', msg);
                    }

                    function uploadComplete(e) {
                      var xhr = e.srcElement || e.target;
                      if (xhr.status === 200) { // successful upload
                        var msg = { type: 'success', index: index, formControl };
                        scope.$broadcast('s3upload:success', msg);
                        let obj = {
                          name: file.name,
                          mimeType: file.type,
                          size: file.size,
                          fileName: res.fileName
                        }
                        return $clientUpload.createRecord(currentClientId, obj)
                          .then((result) => {
                            if (result && result.status) {
                              // success callback
                              resolve({
                                status: true,
                                clientUpload: result.clientUpload
                              });
                            } else {
                              resolve({
                                status: false,
                                message: result
                              })
                            }
                          })
                          .catch((err) => {
                            // console.log(err)
                            let errMessage = err.message || err;
                            if (err && err.data && err.data.message) errMessage = err.data.message;
                            resolve({
                              status: false,
                              message: errMessage
                            })
                          })
                      } else {
                        var msg = { type: 'error', index: index, formControl };
                        scope.$broadcast('s3upload:error', xhr);
                        reject(xhr);
                      }
                    }
                    function uploadFailed(e) {
                      var xhr = e.srcElement || e.target;
                      var msg = { type: 'error', index: index, formControl };
                      scope.$broadcast('s3upload:error', msg);
                      reject(xhr);
                    }
                    function uploadCanceled(e) {
                      var xhr = e.srcElement || e.target;
                      var msg = { type: 'abort', index: index, formControl };
                      scope.$broadcast('s3upload:abort', msg);
                      reject(xhr);
                    }

                    xhr.open('PUT', res.url, true);
                    xhr.setRequestHeader('x-amz-server-side-encryption', 'AES256')
                    xhr.send(file);
                  }
                })
            })
          }))
      }
    };
  }
// Dependency Injection


// Dependency Injection
$uploadS3AttachmentService.$inject = ['$clientUpload', '$http'];
