
'use strict';

export const $sessionExpireCheckService  = function ($rootScope, $log, $user, $auth, $state, $timeout, $location, extendTime) {

    var
    debugEvery = -1,
    syncEvery = 300000,
    kickWhen = 5000,
    warnWhen = 60,
    running = false,
    syncing = false,
    expireOn = null,
    lastSync = null,
    syncHdl = null,
    debugHdl = null,
    remainingObj = null;

    function msRemaining() {
      if(!running || !expireOn) return Infinity;
      return expireOn.getTime() - Date.now();
    }

    function secRemaining() {
      return Math.round(msRemaining()/1000);
    }

    function isExpired() { return msRemaining() <= kickWhen; }

    function stopSyncLoop() {
      if(syncHdl) {
        $timeout.cancel(syncHdl);
        syncHdl = null;
      }
    }

    function stopDebugLoop() {
      if(debugHdl) {
        $timeout.cancel(debugHdl);
        debugHdl = null;
      }
    }

    var debugLoop = (function () {
      if(debugEvery < 0) return;
      $log.debug('time remaining:', this.tillExpired, 'warning:', this.isWarning, 'next sync:', this.tillNextSync);
      debugHdl = $timeout(debugLoop, debugEvery);
    }).bind(this);

    function syncLoop() {
      stopSyncLoop();
      stopDebugLoop();

      syncing = true;

      return $user.timeRemaining()
        .then(function (result) {
          expireOn = new Date(result.date);
          syncHdl = $timeout(syncLoop, syncEvery);
          remainingObj = result;
          if(result.seconds <= extendTime){
            return $auth.extendSession().then(syncLoop);
          }
          lastSync = new Date();
          debugLoop();
          return expireOn;
        })
        .catch(function (err) {
          $log.debug('sync err:', err);
          expireOn = new Date(); // expire now
          return expireOn;
        })
        .finally(function () {
          syncing = false;
        });
    }

    this.start = function () {
      if(running) return;
      syncLoop();
      running = true;
    };

    this.stop = function () {
      if(!running) return;
      stopSyncLoop();
      stopDebugLoop();
      expireOn = null;
      lastSync = null;
      syncing = false;
      running = false;
    };

    this.extendSession = function () {
      return $auth.extendSession().then(syncLoop);
    };

    $rootScope.$watch(isExpired, function (cv, pv) {
      if((cv !== pv) && cv) {
        // if ($auth.getCurrentUser() && $auth.getCurrentUser().name) {
        //   let remaining = JSON.stringify(remainingObj);
        //   Sentry.setTag("type", 'auto');
        //   Sentry.setTag("remaining_result", remaining);
        //   Sentry.setTag("userId", $auth.getCurrentUser()._id);
        //   Sentry.setTag("current_state_url", $state.current.url);
        //   Sentry.setTag("current_state_templateUrl", $state.current.templateUrl);
        //   var absUrl = $location.absUrl();
        //   Sentry.captureException(new Error(`${$auth.getCurrentUser().name} user logout time ${new Date()} ${absUrl}`));
        // }
        console.log('Logout in Time Remaining ')
        $auth.logout();
        $state.go('app.account.login');

        $rootScope.$emit('$authSessionExpired');
      }
    });

    Object.defineProperties(this, {
      isWarning: {
        get: function () {
          if(!running) return false;
          return secRemaining() <= warnWhen;
        }
      },
      tillExpired: { // return seconds, not ms -- prevent infinte dirty render
        get: function () { return secRemaining(); }
      },
      tillNextSync: { // return seconds, not ms -- prevent infinte dirty render
        get: function () {
          if(!running) return Infinity;
          if(!lastSync) return -1;
          return Math.round((syncEvery - (Date.now() - lastSync.getTime())) / 1000);
        }
      },
      syncing: {
        get: function () { return syncing; }
      },
      running: {
        get: function () {
          return running;
        },
        set: function (v) {
          var
          wasRunning = running,
          nowRunning = (typeof v === 'boolean') ? v : wasRunning;

          if(wasRunning && !nowRunning) {
            return this.stop();
          }

          if(!wasRunning && nowRunning) {
            setTimeout(() => {
              // return this.start();
            }, 90000);
          }
        }
      }
    });
  } 
 

// Dependency Injection
$sessionExpireCheckService.$inject = ['$rootScope', '$log', '$user', '$auth', '$state', '$timeout', '$location', 'extendTime'];
