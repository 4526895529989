'use strict';


export const navbarItemDirective = function ($q, $compile, $state, $auth, $activeClientSettings) {

  let disableLink = ['Elisa Kiosk', 'Remote Work'];
  function tplItem(item) {

    var
    target = !!item.target ? item.target : (!!item.external ? '_blank' : false),
    icon = !!item.glyph ? '<span class="'+item.glyph+'"></span> ' : '',
    caption = item.caption||item.title||'',
    tpl = '<a';

    if(!!target) {
      tpl += ' target="'+ target +'"';
    }

    if(!!item.link && !(disableLink.indexOf(item.caption) >= 0)) { // use link engine
      tpl += ' href="{{item.link}}"';
    }
    else if(!!item.state && !(disableLink.indexOf(item.caption) >= 0)) { // use state engine
      tpl += ' ui-sref="{{item.state}}"';
    }
    if ((disableLink.indexOf(item.caption) >= 0)) {
      tpl += ' href=""'
    }
    if(!!item.title) { // use link engine
      tpl += ' title="{{item.title}}"';
    }
    return tpl + 'alt="{{item.caption||item.title}}">'+icon+caption+'</a>';
  }
  // let showDisableTabs = false;
  // $activeClientSettings(function (client, settings) {
  //   if (!client || !settings) {
  //     return;
  //   }
  //   showDisableTabs = settings.showDisableTabs;
  // });

  return {
    restrict: 'A',
    scope: {
      item: '=navbarItem'
    },
    transclude: true,
    replace: true,
    template: function(elements, attrs) {
      var tag = elements[0].nodeName;
      return '<' + tag + ' ng-show="canShowItem(item)" ng-class="{active: isItemActive(item), disabled: disabledMe(item) }"></' + tag + '>';
    },
    link: function(scope, element, attrs) {

      scope.disabledMe = function(item) {
        return disableLink.indexOf(item.caption) >= 0 ? true : false;
      }
      scope.isItemActive = function(item) {
        if(!item) return false;
        return !!item.state ? $state.includes(item.state) : false;
      };

      scope.canShowItem = function(item) {
        if(!item) return false;

        // if (!showDisableTabs && disableLink.indexOf(item.caption) >= 0) return false;

        if(item.roles) { // perform role testing
          var roles = item.roles;

          if(!$auth.isLoggedIn()) {
            return false;
          }

          var
          user = $auth.getCurrentUser(),
          allowed = false;

          if(angular.isArray(roles)) {
            allowed = (roles.indexOf(user.role) !== -1);
          }
          else if(angular.isString(roles)) {
            allowed = (roles === user.role);
          }

          return allowed;
        }
        else if(angular.isFunction(item.showIf)) { // custom function
          return item.showIf();
        }

        return true;
      };

      var
      item = scope.$eval(attrs.navbarItem);

      if(!!item) {
        element.html(tplItem(item));
        $compile(element.contents())(scope);
      }
    }
  };
}
// Dependency Injection
navbarItemDirective.$inject = ["$q","$compile","$state","$auth","$activeClientSettings"];
