


'use strict';

export const $cognitoUserPoolService  = function (appConfig) {
        return function () {
            var poolData = {
                UserPoolId: appConfig.UserPoolId, // Your user pool id here
                ClientId: appConfig.ClientId, // Your client id here
            };
            var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
            return userPool;
        }
    }
 

// Dependency Injection
$cognitoUserPoolService.$inject = ['appConfig'];
