
'use strict';

export const $casesService  = function (Cases) {
        return {
            getCasesList: function (client, searchStr, paginationData) {
                return Cases.getCasesList({
                    id: client._id || client,
                    searchStr: searchStr || '',
                    page: paginationData && paginationData.page,
                    pageSize: paginationData && paginationData.pageSize
                }).$promise;
            },
            getCasesStatusCount: function (client, filter) {
                return Cases.getCasesStatusCount({
                    id: client._id || client,
                   filter
                }).$promise;
            },
            getAuditLogs: function (client, filter) {
                return Cases.getAuditLogs({
                    id: client._id || client,
                   searchKey: filter.searchKey,
                   tableName: filter.tableName,
                   startDate: filter.startDate,
                   endDate: filter.endDate,
                }).$promise;
            },
            getCasesStatusCountDiff: function(client, filter) {
                return Cases.getCasesStatusCountDiff({
                    id: client._id || client,
                   filter
                }).$promise;
            },
            forceSync: function(client, data) {
                return Cases.forceSync({
                  _id: client._id || client,
                  data
                }).$promise;
            },
            syncTable: function(client, data) {
                return Cases.syncTable({
                    _id: client._id || client,
                    data
                }).$promise;
            },
            forceCasesSync: function(client, data) {
                return Cases.forceCasesSync({
                  _id: client._id || client,
                  data
                }).$promise;
            }
        };
    }
// Dependency Injection


// Dependency Injection
$casesService.$inject = ['Cases'];
