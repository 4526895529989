import './programs.scss';
'use strict';

export const ProgramsConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard.programs', {
        url: '/programs',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/programs/programs.html', '539e534a'); }],
        controller: 'ProgramsCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/programs/programs.controller.js', 'ProgramsCtrl'); }]
                      },
        data: {
          ability: 'client.programs',
          permission: 'LAUNCH_PAGE_READ'
        }
      });
  }
// Dependency Injection
ProgramsConfig.$inject = ["$stateProvider"];
