'use strict';

export const interpretersDirective = function ($activeClientSettings,$rootScope) {
        return {
            restrict: 'E',
            templateUrl: 'app/account/dashboard/tasks/tabs/interpreters/interpreters.html',
            scope: {},
            link: function (scope) {

            }
        }
    }
// Dependency Injection
interpretersDirective.$inject = ["$activeClientSettings","$rootScope"];
