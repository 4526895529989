'use strict';

export const SuppressionListCtrl = function ($scope, $auth,  $clientSwitcher, $clientUser, $clientOffice, $awsService, Modal, ngToast, DEFAULT_DEBOUNCE) {
        $scope.data = {
            isLoading: false,
            error: null,
            result: [],
            totalCourts: 0
        }

        $scope.error = null;
        $scope.oneAtATime = true;
        $scope.filter = {
        };

        $scope.currentCli = $clientSwitcher.getCurrentClient();

        $scope.getSuppressionList = function () {
            $scope.data.isLoading = true;
            $awsService.getSuppressionList($scope.currentCli, null)
                .then((res) => {
                    if (res.status) {
                        if (res.SuppressedDestinationSummaries && res.SuppressedDestinationSummaries.length) {
                            let list = res.SuppressedDestinationSummaries.sort((a, b) => (a.LastUpdateTime > b.LastUpdateTime) ? -1 : ((b.LastUpdateTime > a.LastUpdateTime) ? 1 : 0));
                            $scope.data.result = list;
                        }
                        $scope.data.nextToken = res.NextToken
                    }
                })
                .catch(err => {
                    console.log(err)
                })
                .finally(() => {
                    $scope.data.isLoading = false;
                })
        }


        $scope.onDelete = function (e, suppressionItem) {
            e.stopPropagation();
            e.preventDefault();
            var modalTitle = 'Remove Email Suppression';
            var modalBody = "Are you sure you want to remove <strong>" + (suppressionItem.EmailAddress) + "</strong>?";
            var notificationContent = 'Email Suppression remove successfully';

            Modal.confirm.show(modalTitle, modalBody, "Yes", "No", function () {
                $awsService.removeEmailSuppression($scope.currentCli, suppressionItem.EmailAddress).then(function (result) {
                    if (result.status) {
                        ngToast.create({
                            className: 'success',
                            content: notificationContent
                        });
                        if ( $scope.data.result &&  $scope.data.result.length) {
                            let index =  $scope.data.result.findIndex((item) => item.EmailAddress === suppressionItem.EmailAddress)
                            if (index >= 0) {
                                $scope.data.result.splice(index, 1);
                            }
                        }
                    } else {
                        ngToast.create({
                            className: 'danger',
                            content: 'Error' + result.reason
                        });
                    }
                });
            });
        }

        function init() {
            // $scope.userPermissions = $auth.getCurrentUser().permissions;
            $scope.getSuppressionList()
        }
        init()


    }

SuppressionListCtrl.$inject = ["$scope", "$auth",  "$clientSwitcher", "$clientUser", "$clientOffice", "$awsService","Modal","ngToast", "DEFAULT_DEBOUNCE"]
