


'use strict';

export const OfferingRulesFactory  = function ($resource, appConfig) {
        return $resource(appConfig.apiUrl + '/api/offering-rules/:id/:controller', {
            id: '@_id'
        },
            {
                getOfferingRules: {
                    method: 'GET',
                    params: {
                        controller: 'get-offering-rules'
                    }
                },
                getRuleById: {
                    method: 'GET',
                    params: {
                        id: '@id'
                    }
                },
                create: {
                    method: 'POST',
                    params: {
                        controller: 'create'
                    }
                },
                update: {
                    method: 'POST',
                    params: {
                        controller: 'update'
                    }
                },
                toggleAccess: {
                    method: 'POST',
                    params: {
                        controller: 'toggle-access'
                    }
                },
                delete: {
                    method: 'POST',
                    params: {
                        controller: 'delete',
                    }
                },
                updateRuleOrder: {
                    method: 'POST',
                    params: {
                        controller: 'update-rule-order'
                    }
                },
            });
    } 
 

// Dependency Injection
OfferingRulesFactory.$inject = ['$resource', 'appConfig'];
