'use strict';


export const formLanguagesDirective = function (Modal, $rootScope, $q, $activeClient, $clientSwitcher, $programs) {
    return {
      restrict: 'E',
      templateUrl: 'components/forms/languages.html',
      scope: {
        language: '=ngModel',
        onSubmit: '=formOnSubmit',
        onReset: '=formOnReset'
      },
      link: function (scope, el, attrs) {
        let currentCli = null;
        scope.allPrograms = [];
        scope.contractTypeData = [{
          label: 'Common',
          value: 'COM'
        },
        {
          label: 'Uncommon',
          value: 'UNC'
        },
        {
          label: 'Spanish',
          value: 'SP'
        }];

        scope.clientSettings = function () { return $clientSwitcher.getFeatureOptions('client.settings'); };

        scope.language.programDetail = scope.language.programDetail && Array.isArray(scope.language.programDetail) ? scope.language.programDetail : [];
        scope.addProgram = function () {
          var addProgram = {
            program: null,
            type: null,
            type1: null,
            type2: null,
          };
          if (!scope.language.programDetail) {
            scope.language.programDetail = []
          }
          scope.language.programDetail.push(addProgram);
        }

        scope.removeProgram = function (index) {
          scope.language.programDetail.splice(index, 1);
        }

        scope.strSettings = function (str) {
          return {
            selectedStr: ' ',
            noSelection: 'No ' + str + ' Selected',
            allLabel: 'All ' + str
          };
        };

        function getPrograms() {
          $programs.getPrograms(currentCli)
            .then(({ programList, status }) => {
              if (status) {
                if (programList && programList.length) {
                  scope.allPrograms = programList.map((obj) => {
                    return {
                      label: obj.name,
                      value: obj._id
                    }
                  });
                } else {
                  scope.data.error = 'No program found!';
                }
              }
            })
            .catch((err) => {
              let errMessage = err.message || err;
              if (err && err.data && err.data.message) {
                errMessage = err.data.message;
              } else if (err && err.data && err.data.reason) {
                errMessage = err.data.reason;
              }
              console.log(errMessage)
            })
        }

        scope.$on('$destroy', $activeClient(function (client) {
          if (!client) return;
          currentCli = client;
          getPrograms();
        }));
      }
    };
  }
// Dependency Injection
formLanguagesDirective.$inject = ["Modal","$rootScope","$q","$activeClient","$clientSwitcher","$programs"];
