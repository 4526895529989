
'use strict';

export const $sosEmailNotificationService  = function (SOSEmailNotification) {
      return {
        getSOSEmailInformation: function(client, filter) {
          return SOSEmailNotification.getSOSEmailInformation({
            id: client._id || client,
            controller: 'get-sos-email-information',
            filter: filter
          }).$promise;
        },
        getFilterDetails: function(client, filter) {
          return SOSEmailNotification.getFilterDetails({
            id: client._id || client,
            controller: 'get-filter-details',
            filter
          }).$promise;
        }
      };
}
// Dependency Injection


// Dependency Injection
$sosEmailNotificationService.$inject = ['SOSEmailNotification'];
