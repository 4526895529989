


'use strict';

export const ClientUserNotificationsFactory  = function ($resource, appConfig) {
  return $resource(appConfig.apiUrl + '/api/user-notifications/:id/:controller', {
    id: '@id'
  },
  {
    query: {
      method: 'GET',
      isArray: true      
    },
    read: {
      method: 'POST',
      isArray: false,
      params: {
        controller: 'read'
      }
    },
    scheduledNotifications: {
      method:'GET',
      params:{
        controller:'scheduled-notifications'
      }
    }
  });
} 
 

// Dependency Injection
ClientUserNotificationsFactory.$inject = ['$resource', 'appConfig'];
