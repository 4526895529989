'use strict';

export const ProbMissedAptsConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard.prob-missed-apts', {
        url: '/prob-missed-apts',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/prob-missed-apts/prob-missed-apts.html', '832ef02a'); }],
        controller: 'DashboardProbMissedAptsCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/prob-missed-apts/prob-missed-apts.controller.js', 'DashboardProbMissedAptsCtrl'); }]
                      },
        data: {
          ability: 'client.prob-missed-apts',
          permission: 'PROBLEM_MISSED_APTS_READ'
        }
      });
  }
// Dependency Injection
ProbMissedAptsConfig.$inject = ["$stateProvider"];
