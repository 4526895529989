import './schedule-tasks.scss';
'use strict';

export const ScheduleTasksConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard.scheduleTasks', {
        url: '/schedule-tasks',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/schedule-tasks/schedule-tasks.html', '84f8b06a'); }],
        controller: 'DashboardScheduleTasksCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/schedule-tasks/schedule-tasks.controller.js', 'DashboardScheduleTasksCtrl'); }]
                      },
        data: {
          permission: 'RECURRING_TASKS_READ'
        }
      });
  }
// Dependency Injection
ScheduleTasksConfig.$inject = ["$stateProvider"];
