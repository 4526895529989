'use strict';


export const clientTableDirective = function () {
    return {
      restrict: 'E',
      templateUrl: 'components/forms/table.html',
      scope: {
        table: '=ngModel',
        onSubmit: '=formOnSubmit',
        onReset: '=formOnReset'
      },
      link: function (scope, el, attrs) {
        scope.connectionType = [
          { label: 'MYSQL', value: 'mysql' },
          { label: 'PostgreSql', value: 'postgresql' }
        ];
      }
    };
  }