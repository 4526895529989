'use strict';


export const numberFormatFilter = function () {
        return function (str) {
            // if (!str) return 'N/A';
            // let stringToArr = str.replace(/\D/g, '').match(/(\d{1})(\d{3})(\d{3})(\d{4})/);
            // return '+' + stringToArr[1] + ' (' + stringToArr[2] + ')-' + stringToArr[3] + '-' + stringToArr[4];

            if (!str) { return ''; }

            var value = str.toString().trim().replace(/^\+/, '');

            if (value.match(/[^0-9]/)) {
                return str;
            }

            var country, city, number;

            switch (value.length) {
                case 10: // +1PPP####### -> C (PPP) ###-####
                    country = 1;
                    city = value.slice(0, 3);
                    number = value.slice(3);
                    break;

                case 11: // +CPPP####### -> CCC (PP) ###-####
                    country = value[0];
                    city = value.slice(1, 4);
                    number = value.slice(4);
                    break;

                case 12: // +CCCPP####### -> CCC (PP) ###-####
                    country = value.slice(0, 3);
                    city = value.slice(3, 5);
                    number = value.slice(5);
                    break;

                default:
                    return str;
            }

            if (country == 1) {
                country = "+1";
            }

            number = number.slice(0, 3) + '-' + number.slice(3);

            return (country + " (" + city + ") " + number).trim();
        }
    }