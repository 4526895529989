'use strict';


export const $colorUtilService = function () {

  var alphaIndex = 3;

  function rgbaNumberFilter(n, index) {
    if(!angular.isNumber(n) || !angular.isNumber(index) || index < 0) {
      return false;
    }

    if(index < alphaIndex) { // r, g, b
      return n >= 0 && n <= 255;
    }
    else if(index === alphaIndex) { // a
      return n >= 0 && n <= 1;
    }

    return true;
  }

  function assertRgb (rgb) {
    if(!angular.isArray(rgb)) {
      throw 'RGB value is not an array';
    }
    if(rgb.length < 3) {
      throw 'RGB array must have 3 (or more) numbers (red[0-255], green[0-255], blue[0-255]).';
    }

    return Array.prototype.slice.call(rgb.filter(rgbaNumberFilter), 0, 3); // ensure only 3 items are sent back (copy is returned)
  }

  function rgbToRgba(rgb, a) {
    if(!rgbaNumberFilter(a, alphaIndex)) {
      throw new Error('Invalid alpha value. Must be number between 0 and 1');
    }

    return Array.prototype.concat.call(assertRgb(rgb), a);
  }

  function byte2Hex(n) {
    var nybHexString = "0123456789ABCDEF";
    return String(nybHexString.substr((n >> 4) & 0x0F,1)) + nybHexString.substr(n & 0x0F,1);
  }

  function rbgToHex (r,g,b) {
    return '#' + byte2Hex(r) + byte2Hex(g) + byte2Hex(b);
  }

  function rbgToString (r,g,b) {
    return 'rgb(' + [r,g,b].map(Math.round).join(',') + ')';
  }

  function rbgaToString (r,g,b,a) {
    return 'rgba(' + [r,g,b].map(Math.round).join(',') + ',' + a + ')';
  }

  function hslToString (h,s,l) {
    return 'hsl(' + [h,s,l].join(',') + ')';
  }

  function hslaToString (h,s,l,a) {
    return 'hsla(' + [h,s,l,a].join(',') + ')';
  }

  function hslToRgbHex (h, s, l) {
    return rbgToHex.apply(this, hslToRgb(h, s, l));
  }

  function hslToRgbString (h, s, l) {
    return rbgToString.apply(this, hslToRgb(h, s, l));
  }

  function hslaToRgbaString (h, s, l, a) {
    return rbgaToString.apply(this, rgbToRgba(hslToRgb(h, s, l), a));
  }

  // return {
  //   assertRgb:        assertRgb,
  //   rgbToRgba:        rgbToRgba,
  //   decimalToHex:     decimalToHex,
  //   rbgToHex:         rbgToHex,
  //   rbgToString:      rbgToString,
  //   rbgaToString:     rbgaToString,
  //   hslToString:      hslToString,
  //   hslaToString:     hslaToString,
  //   hslToRgbHex:      hslToRgbHex,
  //   hslToRgbString:   hslToRgbString,
  //   hslaToRgbaString: hslaToRgbaString
  // };
}