


'use strict';

export const $performTaskResolveHistoryService  = function (Modal) {
  return function (cb) {
    cb = angular.isFunction(cb) ? cb : angular.noop;
    return Modal.alertActions.viewResolveHistory(function () {
      return cb.apply(this, arguments);
    }, 'Task Resolution');
  };
}
 

// Dependency Injection
$performTaskResolveHistoryService.$inject = ['Modal'];
