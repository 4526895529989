


'use strict';

export const ClientInterpreterProfileFactory  = function ($resource, appConfig) {
        return $resource(appConfig.apiUrl + '/api/interpreter-profile/:id/:controller', {
            id: '@_id'
        },
            {
                icCreate: {
                    method: 'POST',
                    params: {
                        controller: 'create'
                    }
                },
            });
    } 
 

// Dependency Injection
ClientInterpreterProfileFactory.$inject = ['$resource', 'appConfig'];
