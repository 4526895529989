
'use strict';

export const RulesLogsManagerFactory  = function ($resource, appConfig) {
    return $resource(appConfig.apiUrl + '/api/rules-logs/:id/:rule', {
      id: '@_id'
    },
    {
      getLogs: {
        method: 'GET',
        params: {
          rule: '@rule',
          id: '@id'
        },
        isArray: false
      }
    });
  } 
 

// Dependency Injection
RulesLogsManagerFactory.$inject = ['$resource', 'appConfig'];
