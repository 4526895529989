'use strict';

export const AdHocConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard.adHoc', {
        url: '/ad-hoc',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/ad-hoc/ad-hoc.html', '3b4eb2ea'); }],
        controller: 'DashboardAdHocContactCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/ad-hoc/add-hoc-contact.controller.js', 'DashboardAdHocContactCtrl'); }]
                      },
        data: {
          ability: 'client.ranking',
          permission: 'ADHOC_READ'
        }
      });
  }
// Dependency Injection
AdHocConfig.$inject = ["$stateProvider"];
