
'use strict';

export const TaskFactory  = function ($filter, $q, $clientTask, $clientSwitcher) {

  var filterDuration = $filter('durationMS');

  function dateNorm(d) {
    if(angular.isString(d)) {
      var ms = Date.parse(d);
      if(isNaN(ms)) return false;
      return new Date(ms);
    }
    else if(angular.isNumber(d)) {
      return new Date(d);
    }
    else if(d instanceof Date) {
      return d;
    }
    return false;
  }

  function Task(opts) {
    opts = opts || {};

    var dirty = false;

    Object.defineProperty(this, 'dirty', {
      get: function () { return dirty; },
      set: function (v) { dirty = !!v; }
    });

    this.id = opts._id;
    this.bucket = opts.bucket;
    this.taskCollection = opts.taskCollection,
    this.priority = opts.priority;
    this.taskType = opts.taskType;
    this.due = dateNorm(opts.due);
    this.region = opts.region;
    this.location = opts.location;
    this.locations = Task.globalLocations;
    this.buckets = Task.globalBuckets;
    this.title = opts.title;
    this.consequence = opts.consequence;
    this.description = opts.description;
    this.attachments = opts.attachments;
    this.assignmentHistory = opts.assignmentHistory;
    this.unresolveHistory = opts.unresolveHistory;
    this.status = opts.status;
    this.resolve = opts.resolve;
    this.archived = opts.archived;
    this.archivedOn = opts.archivedOn;
    this.assignToExternal = opts.assignToExternal;
    this.assignTo = opts.assignTo;
    this.assignToEmail = opts.assignToEmail;
    this.assignToExternalOnExpire = opts.assignToExternalOnExpire;
    this.assignToOnExpire = opts.assignToOnExpire;
    this.assignToEmailOnExpire = opts.assignToEmailOnExpire;
    this.onExpirePriority = opts.onExpirePriority;
    this.onExpireDue = opts.onExpireDue;
    this.createdBy = opts.createdBy;
    this.taskState = opts.taskState;
    this.stateActivity = opts.stateActivity;
    this.coiEvents = opts.coiEvents;
    this.stateActivityEvent = opts.stateActivityEvent || [];
    this.taskSchemaType = opts.taskSchemaType;
    this.taskData = opts.taskData;
    this.taskAttributes = opts.taskAttributes;
    this.tags = opts.tags;
    this.createdOn = dateNorm(opts.created);
    this.updatedOn = dateNorm(opts.updated);
    this.updateErrors = null;
    this.customer = opts.customer;
    this.caseData = opts.caseData;
    this.woCalculations = opts.woCalculations;
    this.woFlagEvents = opts.woFlagEvents;
    this.editing = {};
    this.editedProperties = {};
    this.promiseMgr = new Task.PromiseMgr(this);
    this.caseDetail = opts.caseDetail;
    this.icProfileSignedUrl = opts.icProfileSignedUrl;
    this.editedWODetails = opts.editedWODetails;
    this.editLogisticsDetails = opts.editLogisticsDetails;
    this.woEditHistory = opts.woEditHistory;
    this.editedWODetailsCount = opts.editedWODetailsCount;
    this.editLogisticsDetailsCount = opts.editLogisticsDetailsCount;
    this.languagesData = opts.languagesData;
    this.languagesDataCount = opts.languagesDataCount;
    this.invoiceId = opts.invoiceId;
    this.paymentApprovedBy = opts.paymentApprovedBy;
    this.paymentApprovedAt = opts.paymentApprovedAt;
    this.invoicePaidAt = opts.invoicePaidAt;
    this.isPaymentApproved = opts.isPaymentApproved;
    this.isWithdraw = opts.isWithdraw;
    this.withdrawRequest = opts.withdrawRequest;

    var
    editables = [
      'taskCollection',
      'taskType',
      'priority',
      'due',
      // 'region',
      'location',
      'title',
      'description',
      'status'
    ],
    getLocationId = function (location) {
      if(!location) return false;
      return (location._id||location||false);
    },

    getLocationIndex = function (l, locations) {
      var index = -1, lid = getLocationId(l);
      if(!locations || !lid) return index;
      locations.every(function (l2, index2) {
        var l2id = getLocationId(l2);
        if(l2id === lid) index = index2;
        return index === -1;
      });
      return index;
    },

    getBucketId = function (bucket) {
      if(!bucket) return false;
      return (bucket._id||bucket||false);
    },

    getBucketIndex = function (l, buckets) {
      var index = -1, lid = getBucketId(l);
      if(!buckets || !lid) return index;
      buckets.every(function (l2, index2) {
        var l2id = getBucketId(l2);
        if(l2id === lid) index = index2;
        return index === -1;
      });
      return index;
    },

    editSetGetters = {
      default: function (property) {
        return {
          get: function () {
            if(this.isDirty(property)) {
              return this.editedProperties[property];
            }

            return this[property];
          },
          set: function (v) {
            var
            curVal = this[property],
            edited = this.isDirty(property);

            if(!edited && (curVal === v)) return; // don't mark edited/dirty

            if(edited && (curVal === v)) { // mark as clean
              dirty = false;
              delete this.editedProperties[property];
              return;
            }

            dirty = true;
            this.editedProperties[property] = v;
          }
        };
      },
      location: function (property) {
        property = property || 'location';
        var setget = editSetGetters.default(property);

        return {
          get: function ()  {
            var location = setget.get.call(this);

            if(!location || !this.locations) {
              return false;
            }

            var index = getLocationIndex(location, this.locations);
            if(index === -1) return false;

            return this.locations[index];
          },
          set: function (v) { return setget.set.call(this, v); }
        };
      },
      taskCollection: function (property) {
        property = property || 'taskCollection';
        var setget = editSetGetters.default(property);

        return {
          get: function ()  {
            var bucket = setget.get.call(this);

            if(!bucket || !this.buckets) {
              return false;
            }

            var index = getBucketIndex(bucket, this.buckets);
            if(index === -1) return false;

            return this.buckets[index];
          },
          set: function (v) { return setget.set.call(this, v); }
        };
      }
    };

    editables.forEach(function (ek) {
      var setterGetter;

      if(editSetGetters.hasOwnProperty(ek))
        setterGetter = editSetGetters[ek](ek);
      else
        setterGetter = editSetGetters.default(ek);

      Object.defineProperty(this, 'edit_' + ek, setterGetter);
    }.bind(this));

    // this.woCalculations = calculateWorkOrderTime(this.caseData);
  }

  Task.PromiseMgr = function (task, queue) {

    var chain;

    Object.defineProperties(this, {
      chain: { // create a new promise chain at this point in chain time.
        get: function () { return $q.when(chain); }
      }
    });

    this.add = function (promise) {
      if(angular.isFunction(promise)) {
        chain = chain.then(promise);
      }
      else {
        chain = chain.then(function () { return $q.when(promise); });
      }

      return chain;
    };

    this.reset = function (_queue) {
      _queue = angular.isArray(_queue) ? _queue : [];
      chain = $q.all(queue);
      return chain;
    };

    this.reset(queue);
  };

  Object.defineProperties(Task.prototype, {
    editedPropertyKeys: {
      get: function () {
        return Object.keys(this.editedProperties);
      }
    },
    numberOfEditedProperties: {
      get: function () {
        return this.editedPropertyKeys.length;
      }
    },
    msTillDue: {
      get: function () {
        if(!this.due) {
          return false;
        }
        return this.due.getTime() - Date.now();
      }
    },
    isOpen : { get: function () { return this.status === 'open'; } },
    isResolved : { get: function () { return this.status === 'resolved'; } },
    isUnresolved : { get: function () { return this.status === 'unresolved'; } },
    isArchived:  { get: function () { return !!this.archived; } },
    dateArchived:  {
      get: function () {
        return this.archivedOn;
      }
    },
    dateResolved: {
      get: function () {
        if(!this.isResolved) {
          return false;
        }

        return dateNorm(this.resolve.resolvedOn);
      }
    },
    dateUnresolved: {
      get: function () {
        if(!this.isUnresolved || !this.unresolveHistory) {
          return false;
        }

        var urHist = this.unresolveHistory;
        return dateNorm(urHist[urHist.length - 1].date);
      }
    },
    resolvedBy: {
      get: function () {
        if(!this.isResolved || (!this.resolve.user && !this.resolve.email)) {
          return false;
        }

        return this.resolve.email || this.resolve.user.name;
      }
    },
    resolveDuration: {
      get: function () {
        if(!this.isResolved || !this.resolve.resolvedOn || !this.stateActivity.length) {
          return false;
        }
        var startingTime = this.stateActivity && this.stateActivity.length ? this.stateActivity[0].date : this.createdOn;
        return filterDuration(moment(this.resolve.resolvedOn).diff(moment(startingTime)));
      }
    },
    priorityClass: {
      get: function () {
        switch(this.priority) {
          case 'high': return 'text-priority-high';
          case 'med-high': return 'text-priority-medium-high';
          case 'med-low': return 'text-priority-medium-low';
          case 'low': return 'text-priority-low';
        }
      }
    }
  });

  var prop_batchMode = (Math.random() * Date.now());

  Task.prototype.toggleEdit = function (feature) {
    this.editing[feature] = !this.editing[feature];
    return this;
  };

  Task.prototype.resetProperty = function (feature) {
    delete this.editedProperties[feature];
    this.editing[feature] = false;
    this.errorCleanup(feature);

    if(this.numberOfEditedProperties === 0) {
      this.dirty = false;
    }

    return this;
  };

  Task.prototype.errorCleanup = function (feature) {
    var errors = this.updateErrors;

    if(!errors || !errors.fieldErrors || (feature && !errors.fieldErrors.hasOwnProperty(feature)))
      return this; // abort

    delete errors.fieldErrors[feature];

    if(Object.keys(errors.fieldErrors).length === 0) { // clear all errors
      errors = null;
      this.updateErrors = null;
    }

    return this;
  };

  Task.prototype.saveProperty = function (feature, cb) {

    var batchMode = this[prop_batchMode] || false;

    // if(this[prop_batchMode]) {
    // }

    if(this.isDirty(feature)) {
      var originalVal = this[feature], newVal = this.editedProperties[feature];

      this[feature] = newVal;

      return this.promiseMgr.add(function () {

        var doReset = function () {
          this.resetProperty(feature);
          if(angular.isFunction(cb)) {
            cb();
          }
        }.bind(this);

        switch(feature) {
          case 'title':       return $clientTask.updateTitle(this, newVal).then(doReset);
          case 'description': return $clientTask.updateDesc(this, newVal).then(doReset);
          case 'priority':    return $clientTask.updatePriority(this, newVal).then(doReset);
          case 'location':    return $clientTask.updateLocation(this, newVal._id).then(doReset);
          case 'taskCollection': return $clientTask.updateCollection(this, newVal, this.taskType).then(doReset);
          case 'taskType':    return $clientTask.updateCollection(this, this.bucket.id, newVal).then(doReset);
          case 'due':         return $clientTask.updateDue(this, newVal).then(doReset);
          case 'onExpireDue': return $clientTask.updateFallbackDue(this, newVal).then(doReset);
        }
      }.bind(this))
      .catch(function(err) {

        this[feature] = originalVal;
        // this.resetProperty(feature); // possibly display field error?

        this.promiseMgr.reset();
        this.updateErrors = angular.extend(this.updateErrors||{}, err);

        console.warn('Error while saving:', err);
        return $q.reject(err);
      }.bind(this));
    }
    else {
      console.log('property is not dirty');
    }

    return this;
  };

  Task.prototype.isDirty = function (feature) {
    if(feature) {
      return this.editedProperties.hasOwnProperty(feature);
    }

    return this.dirty;
  };

  Task.prototype.saveAllProperties = function () {

    this[prop_batchMode] = true;

    this.editedPropertyKeys.forEach(function (feature) {
      this.saveProperty(feature);
    }.bind(this));

    delete this[prop_batchMode];

    return this.promiseMgr.chain;
  };

  Task.prototype.resetAllProperties = function () {

    this.editedPropertyKeys.forEach(function (feature) {
      this.resetProperty(feature);
    }.bind(this));

    return this;
  };

  Task.wrap = function (s) {
    if(s instanceof Task) {
      return s;
    }
    else if(angular.isObject(s)) {
      return new Task(s);
    }
    return false;
  };
  return Task;
}
 

// Dependency Injection
TaskFactory.$inject = ['$filter', '$q', '$clientTask', '$clientSwitcher'];
