


'use strict';

export const ClientSystemLogsFactory  = function ($resource, appConfig) {
  return $resource(appConfig.apiUrl + '/api/system-logs/:id/:controller', {
    id: '@id'
  },
  {
    getLogs: {
      method: 'GET',
      params: {
        controller: 'logs',
        id: '@id'
      },
    },
    getDistinctValues: {
      method: 'GET',
      params: {
        controller: 'distinct-values',
        id: '@id'
      },
    }
  });
} 
 

// Dependency Injection
ClientSystemLogsFactory.$inject = ['$resource', 'appConfig'];
