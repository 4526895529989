
'use strict';

export const $clientReportBucketsService  = function (ClientReportBuckets) {
    return {
      update: function (client, bucket) {
        return ClientReportBuckets.update({
          _id: client._id || client,
          data: bucket,
          bucketId: bucket._id
        }).$promise;
      },
      create: function (client, data) {
        return ClientReportBuckets.create({
          _id: client._id || client,
          data: data
        }).$promise;
      },
      getReportBuckets: function (client, filter) {
        return ClientReportBuckets.getReportBuckets({
          id: client._id || client,
          filter: filter
        }).$promise;
      },
      getReportBucket: function (client, bucketId) {
        return ClientReportBuckets.getReportBucket({
          id: client._id || client,
          controller: bucketId
        }).$promise;
      },
      delete: function (client, folderId) {
        return ClientReportBuckets.delete({
          _id: client._id || client,
          folderId
        }).$promise;
      },
      getReportsLibraryFolders: function (client, filter) {
        return ClientReportBuckets.getReportsLibraryFolders({
          id: client._id || client,
          filter: filter || {}
        }).$promise;
      },
    };
  }
// Dependency Injection


// Dependency Injection
$clientReportBucketsService.$inject = ['ClientReportBuckets'];
