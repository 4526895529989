import './training.scss';
'use strict';

export const TrainingConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard.training', {
        url: '/training',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/training/training.html', 'dfb3aeef'); }],
        controller: 'DashboardTrainingCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/training/training.controller.js', 'DashboardTrainingCtrl'); }],
                          '$userPermissions': ['$clientUser', '$clientSwitcher', function($clientUser, $clientSwitcher) {
                              return $clientUser.getUserPermissions($clientSwitcher.getCurrentClient());
                            }]
                      },
        data: {
          permission: 'TRAINING_READ'
        },
      });
  };

  //ability can be used to allow only permitted users
//   .state('app.account.dashboard.tutorial', {
//         url: '/tutorial',
//         template: ad_hoc_tutorialTemplate,
//         controller: 'DashboardTutorialCtrl',
//         data: {
//           ability: 'client.ranking'
//         }
//       }

// Dependency Injection
TrainingConfig.$inject = ["$stateProvider"];
