import './kiosk.scss';
'use strict';

export const KioskConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard.kiosk', {
        url: '/kiosk',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/kiosk/kiosk.html', 'd0a654ca'); }],
        controller: 'KioskCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/kiosk/kiosk.controller.js', 'KioskCtrl'); }]
                      },
        data: {
          ability: 'client.kiosk',
          permission: 'KIOSK_READ'
        }
      });
  }
// Dependency Injection
KioskConfig.$inject = ["$stateProvider"];
