import './auto-assigned.scss';
'use strict';
export const autoAssignedReportDirective = function ($rootScope, $timeout, $serviceType, $clientSwitcher, $auth,
        $activeClientSettings, $autoAssigned,  $autoTimezoneFormat, Modal) {
        return {
            restrict: 'E',
            templateUrl: 'app/account/dashboard/main-dashboard/work-orders/tabs/auto-assigned/auto-assigned.html',
            scope: {},
            link: function (scope) {
                const currentUser = $auth.getCurrentUser();
                const currentCli = $clientSwitcher.getCurrentClient();

                scope.serviceTypes = $serviceType;
                scope.pageData = {
                    isLoading: false,
                    error: null,
                    dataList: [],
                }
                scope.pagination = {
                    currentPage: 1,
                    page: 1,
                    total: 0,
                    pageSize: 20,
                    itemsPerPage: 20
                };

                scope.filter = {
                    searchStr: '',
                    orderBy: '_id',
                    orderByVal: 'desc',
                    startDate: moment().startOf('month').toDate(),
                    endDate: moment().endOf('month').toDate(),
                    serviceType: null,
                    deldateFilter: true
                }

                scope.applyFilter = () => {
                    scope.pagination.page = 1;
                    scope.loadAutoAssignedReport()
                }

                scope.loadAutoAssignedReport = function () {
                    if (scope.pageData.isLoading) return;
                    scope.pageData.isLoading = true;
                    scope.pageData.error = null;
                    scope.pageData.datalist = [];
                    let filter = Object.assign(
                        _.pick(scope.filter, ['searchStr', 'startDate', 'endDate', 'serviceType', 'deldateFilter']),
                        scope.filter, {
                            orderBy: scope.filter.orderByField,
                            orderByVal: scope.filter.orderByValue
                        }
                    );
                    $autoAssigned.index(currentCli, filter, scope.pagination).then((res) => {
                        scope.pageData.dataList = res.result;
                        scope.pagination.total = res.totalRecords;
                    })
                        .catch((err) => {
                            console.log(err)
                        })
                        .finally(() => {
                            scope.pageData.isLoading = false;

                        })
                }

                scope.getFormatedDelDate = function(caseDetail) {
                    if (caseDetail.deldate && caseDetail.sched && caseDetail.courtTimezone) {
                        let caseTimeDetail = $autoTimezoneFormat.getAllDetail(moment.utc(caseDetail.deldate).format('YYYY-MM-DD'), caseDetail.sched, caseDetail.courtTimezone);
                        return caseTimeDetail && caseTimeDetail.caseDisplayDateTime ?  caseTimeDetail.caseDisplayDateTime + ' ' +  caseTimeDetail.coiTimezone: '';
                    } else {
                        return ''
                    }
                }

                scope.sortRecord = function (colName) {

                }

                scope.toggle = function() {
                    // scope.filter.deldateFilter = !scope.filter.deldateFilter;
                    scope.applyFilter()
                }

                scope.showTaskEvent= function(e, record) {
                    e.preventDefault();
                    Modal.taskActions.showTaskEvent(record.coi, record, function (res) {
                        // console.log(res)
                    })();
                }
                $activeClientSettings(function (client, settings) {
                    // scope.serviceTypeScheduled = settings.serviceTypeScheduled;
                })


                function init() {
                    initDateRangePicker()
                    scope.applyFilter();
                }

                function initDateRangePicker() {
                    function cb(start, end) {
                        $timeout(function () {
                            if (!start || !end) {
                                return;
                            }
                            $('#logDateFilter span').html(start.format('MM/DD/YYYY') + ' - ' + end.format('MM/DD/YYYY'));
                            scope.filter.startDate = start.format();
                            scope.filter.endDate = end.format();
                            scope.applyFilter();
                        }, 0);
                    }

                    $('#logDateFilter').daterangepicker({
                        startDate: moment(scope.filter.startDate).format('MM/DD/YYYY'),
                        endDate: moment(scope.filter.endDate).format('MM/DD/YYYY'),
                        opens: 'left',
                        ranges: {
                            'Today': [moment().startOf('day'), moment().endOf('day')],
                            'Yesterday': [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
                            'Last 7 Days': [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],
                            'Last 30 Days': [moment().subtract(29, 'days').startOf('day'), moment().endOf('day')],
                            'This Month': [moment().startOf('month'), moment().endOf('month')],
                            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                        }
                    }, cb);

                    var text = moment(scope.filter.startDate).format('MM/DD/YYYY') + ' to ' + moment(scope.filter.endDate).format('MM/DD/YYYY');
                    $("#logDateFilter span").html(text);
                }

                init()
            }
        }
    }
// Dependency Injection
autoAssignedReportDirective.$inject = ["$rootScope","$timeout","$serviceType","$clientSwitcher","$auth","$activeClientSettings","$autoAssigned","$autoTimezoneFormat","Modal"];
