
'use strict';

export const $assessmentTypesService  = function () {
        let type = [
            {
                label: 'Annual',
                value: 'Annual'
            },
            {
                label: 'Qualifying',
                value: 'Qualifying'
            }
        ]
        return type;
    } 
 
