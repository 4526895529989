import './select-and-search.scss';
'use strict';

export const selectAndSearchDirective = function ($rootScope, $timeout) {

        var
            DEFAULT_SELECTED_ITEMS = 'Selected: ',
            DEFAULT_NO_SELECTION = 'None Selected',
            DEFAULT_ALL_ITEMS = 'All Items';

        return {
            require: 'ngModel',
            restrict: 'E',
            replace: true,
            templateUrl: 'components/select-and-search/select-and-search.html',

            scope: {
                items: '=',
                selectedPrefix: '=',
                noneSelected: '=',
                allValue: '=',
                allLabel: '=',
                search: '=',
                modelValue: '=ngModel',
                modelChanged: '&ngChange',
                selecetdItem: '=',
                hideLabel: '=',
                toggleCount:'='
            },

            link: function (scope, el, attrs, ctrls) {
                var initialValue = scope.modelValue;

                // will be triggered when root element of this directive loses focus
                scope.onBlurHandler = function() {
                    $timeout(function() {
                    if(el[0].contains(document.activeElement)) {
                        // if element that gained focus is option of this multi-select 
                        // then assign focus to the root element of this directive again
                        if(!scope.search) el[0].focus();
                    }
                    else {
                        // element that gained focus is not descendent of this multi-select so close it.
                        scope.isExpanded = false;
                    }
                    });
                };

                scope.onKeyUp = function(event) {
                    if(event.keyCode == 27){
                      scope.isExpanded = false;
                    }
                };

                scope.getAllLabel = function () {
                    return scope.allLabel || DEFAULT_ALL_ITEMS;
                };

                scope.toggleSelector = function () {
                    scope.isExpanded = !scope.isExpanded;
                };
                scope.itemVisible = function (item) {
                    if (!itemExists(item)) return false;

                    if (angular.isFunction(item.showIf)) {
                        return !!item.showIf.call(this, item);
                    }

                    return !item.hidden;
                };
                scope.itemDisabled = function (item) {

                    if (angular.isFunction(item.disableIf)) {
                        return !!item.disableIf.call(this, item);
                    }

                    return false;
                };
                scope.itemIsActive = function (item) {
                    return item.value === scope.modelValue;
                };
                scope.isTouched = function () {
                    return ctrls.$touched;
                };
                scope.isValid = function () {
                    return !!scope.required ? (!!scope.modelValue) : true;
                };

                scope.currentLabel = function updateLabel() {
                    var
                        tag = 'span',
                        allItems = scope.items || [],
                        allLabel = scope.allLabel || DEFAULT_ALL_ITEMS,
                        selPrefix = scope.selectedPrefix || DEFAULT_SELECTED_ITEMS,
                        noSelection = scope.noneSelected || DEFAULT_NO_SELECTION,
                        items = scope.enabledItems || {},
                        label;

                    if (!!scope.allValue && items[scope.allValue]) {
                        label = wrapTag(allLabel, tag);
                    } else {

                        for (var i = 0; i < allItems.length; i++) {
                            if (scope.itemIsActive(allItems[i])) {
                                label = allItems[i].label;
                            }
                        }

                        if (label) {
                            label = wrapTag(selPrefix, tag) + label;
                        }
                        else {
                            label = wrapTag(allLabel, tag);
                            // scope.modelValue = null;
                        }
                    }

                    return label;
                };

                scope.itemClicked = function (item) {
                    if (item == 'all') {
                        scope.modelValue = 'all';
                    } else {
                        scope.modelValue = item.value === scope.modelValue ? null : item.value;
                        if(scope.selecetdItem){
                            scope.selecetdItem = item;
                        }
                    }
                    scope.isExpanded = false;

                    $timeout(function () {
                        scope.modelChanged();
                    });
                };

                scope.isExpanded = false;

                function wrapTag(content, tag) {
                    return '<' + tag + '>' + content + '</' + tag + '>';
                }

                function itemExists(item) {
                    item = item || {};

                    var value = item.value || item.considerItem ? item.value : item;
                    return (scope.items || []).some(function (itm) {
                        return itm.value === value;
                    });
                }

                ctrls.$validators.multiSelect = function (nV, vV) {
                    return scope.isValid();
                };

                attrs.$observe('required', function (value) {
                    scope.required = !!value;
                });
                attrs.$observe('disabled', function (value) {
                    scope.disabled = !!value;
                });

                scope.$watch('isExpanded', function (nV) {
                    if (ctrls.$touched || !nV) return;

                    if ($rootScope.$$phase) {
                        scope.$evalAsync(ctrls.$setTouched);
                    } else {
                        scope.$apply(ctrls.$setTouched);
                    }
                });

                var removeWatch = scope.$watch(function () {
                    return (scope.items || []).map(function (item) {
                        return item && item.value ? item.value : '';
                    }).join(',');
                }, function (nv, ov) {

                    //called when data is loaded for the first time i.e array of items will have some items in it for the first time.
                    if (!ov && nv !== ov) {
                        scope.modelValue = initialValue;
                        $timeout(function () {
                            scope.modelChanged();
                        });
                        removeWatch();
                    }
                });
            }
        };
    }
// Dependency Injection
selectAndSearchDirective.$inject = ["$rootScope","$timeout"];
