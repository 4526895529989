
'use strict';

export const $clientRolesService  = function (ClientRoles) {
    return {
      update: function(client, data) {
        return ClientRoles.update({
          _id: client._id || client,
          data: {
            roleId: data._id,
            name: data.name,
            isEnabled: data.isEnabled,
            code: data.code,
            permissions: data.permissions,
            description: data.description,
            type: data.type
          }
        }).$promise;
      },
      create: function(client, data) {
        return ClientRoles.create({
          _id: client._id || client,
          name: data.name,
          isEnabled: data.isEnabled,
          code: data.code,
          permissions: data.permissions,
          description: data.description,
          type: data.type
        }).$promise;
      },
      getRoles: function(client) {
        return ClientRoles.getRoles({
          id: client._id || client
        }).$promise;
      },
      getRoleById: function(client, roleId) {
        return ClientRoles.getRoleById({
          id: client._id || client,
          controller: roleId
        }).$promise;
      },
      getRolePermissions: function(client, filter) {
        return ClientRoles.getRolePermissions({
          id: client._id || client,
          filter: filter
        }).$promise;
      }
    };
  }
// Dependency Injection


// Dependency Injection
$clientRolesService.$inject = ['ClientRoles'];
