
'use strict';

export const $OtcAnyWhereService  = function (OtcAnyWhere, $auth) {
        return {
            getAllLock: function(client){
                return OtcAnyWhere.getAllLock({
                    id: client._id || client,
                }).$promise;
            },
            getLockStatus: function(client, serno){
                return OtcAnyWhere.getLockStatus({
                    id: client._id || client,
                    serno: serno
                }).$promise;
            },
            openLock: function(client, search){
                return OtcAnyWhere.openLock({
                    id: client._id || client,
                    search: search
                }).$promise;
            },
            closeLock: function(client, search){
                return OtcAnyWhere.closeLock({
                    id: client._id || client,
                    search: search
                }).$promise;
            }
        }
    }
// Dependency Injection


// Dependency Injection
$OtcAnyWhereService.$inject = ['OtcAnyWhere', '$auth'];
