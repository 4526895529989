import './login.scss';
'use strict';

export const LoginConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.login', {
        url: '/login',
        data: {
          roles: [],
          // meta: {
          //   'title': 'Elisa',
          //   'description': 'SOSi is the largest private, family-owned and operated technology and services integrator in the aerospace, defense, and government services industry.'
          // }
        },
        templateUrl: 'app/account/login/login.html',
        controller: 'AccountLoginCtrl'
      })
      .state('app.account.forgotPassword', {
        url: '/forgotPassword',
        data: {
          roles: []
        },
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/login/forgot-password/forgot-password.html', '51628a8a'); }],
        controller: 'ForgorPasswordCtrl',
                      resolve: {
                        lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/login/forgot-password/forgot-password.controller.js', 'ForgorPasswordCtrls')}]

                      }
      })
      .state('app.account.resetPassword', {
        url: '/reset-password/:token',
        data: {
          roles: []
        },
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/login/reset-password/reset-password.html', '4e2a7f0a'); }],
        controller: 'ResetPasswordCtrl',
                      resolve: {
                        lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/login/reset-password/reset-password.controller.js', 'ResetPasswordCtrl')}]

                      }
      })
      .state('app.account.linguistcreate', {
        url: '/linguist/signup',
        data: {
          roles: []
        },
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/login/linguist/linguist-signup.html', 'e218ce23'); }],
        controller: 'LinguistSignupCtrl',
                      resolve: {
                        lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/login/linguist/linguist-signup.controller.js', 'LinguistSignupCtrl')}]

                      }
      })
      .state('app.account.changePassword', {
        url: '/changePassword',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/settings/settings.html', 'd34af44b'); }],
        controller: 'AccountSettingsCtrl',
                      resolve: {
                        lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/settings/settings.controller.js', 'AccountSettingsCtrl')}]

                      },
        data: {
          roles: []
        }
      });
  }
// Dependency Injection
LoginConfig.$inject = ["$stateProvider"];
