


'use strict';

export const IntLangsFactory  = function ($resource, appConfig) {
    return $resource(appConfig.apiUrl + '/api/intlangs/:id/:controller', {
        id: '@_id'
    },
        {
            getIntLangsList: {
                method: 'GET',
                params: {
                    controller: 'get-intlangs-list'
                }
            }
        });
} 
 

// Dependency Injection
IntLangsFactory.$inject = ['$resource', 'appConfig'];
