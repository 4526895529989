'use strict';

export const $sessionItemsService = function () {
  return  [
    { label:'ALL DAY', value:'ALL DAY'},
    { label:'AM1', value:'AM1'},
    { label:'AM2', value:'AM2'},
    { label:'PM1', value:'PM1'},
    { label:'PM2', value: 'PM2'}
  ]
}
