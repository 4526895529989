


'use strict';

export const uasUsersDirective  = function () { // task
  return {
    restrict: 'A',
    require: ['uasUsers'],
    controller: 'UasUsersCtrl as $uasUsers',
    link: function(scope, el, attrs, ctrls) {
      var ctrl = ctrls[0];
      scope.$watch(attrs.uasUsers, ctrl.bindItems.bind(ctrl));
    }
  };
} 
 
