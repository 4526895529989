'use strict';

export const assignmentStatusReportDirective = function ($activeClient, $q, $activeClientSettings, $timeout, $liveReports, $clientSwitcher) {
        return {
            restrict: 'E',
            templateUrl: 'app/account/dashboard/live-reports/tabs/assignment-status-report/assignment-status-report.html',
            scope: {
            },
            link: function ($scope) {

                const currentCli = $clientSwitcher.getCurrentClient();

                $scope.data = {
                    isLoading: true,
                    startDate: moment().startOf('month').format('YYYY-MM-DD'),
                    endDate: moment().endOf('month').format('YYYY-MM-DD')
                };

                $scope.records = [];

                function getAssignmentStatusReportData() {
                    const filter = {
                        startDate: $scope.data.startDate,
                        endDate: $scope.data.endDate
                    };

                    $liveReports.getAssignmentStatusReport(currentCli, filter)
                        .then(data => {
                            $scope.records = data.data;
                        })
                        .finally(() => $scope.data.isLoading = false);
                }

                function initDateRangePicker() {
                    function cb(start, end) {
                        $timeout(function () {
                            if (!start || !end) {
                                return;
                            }

                            $('#reportrange span').html(start.format('MM/DD/YYYY') + ' to ' + end.format('MM/DD/YYYY'));
                            $scope.data.startDate = start.format('MM/DD/YYYY');
                            $scope.data.endDate = end.format('MM/DD/YYYY');
                            getAssignmentStatusReportData();
                        }, 0);
                    }

                    $('#reportrange').daterangepicker({
                        startDate: moment($scope.data.startDate).format('MM/DD/YYYY'),
                        endDate: moment($scope.data.endDate).format('MM/DD/YYYY'),
                        opens: 'right'
                    }, cb);

                    var text = moment($scope.data.startDate).format('MM/DD/YYYY') + ' to ' + moment($scope.data.endDate).format('MM/DD/YYYY');
                    $('#reportrange span').html(text);
                    getAssignmentStatusReportData();
                }

                initDateRangePicker();

                function generateCSVData(records) {
                    if (!records.length) return;

                    records = JSON.parse(angular.toJson(records));
                    const headings = [['State', 'City', 'Language', 'Detained', 'Non-Detained', 'Total']];
                    let allData = records.map(record => {
                        record.language = record.language.replace(',', '.')
                        const recValues = Object.values(record);
                        let csvRecord = [];
                        csvRecord.push(record.state);
                        csvRecord.push(record.city);
                        csvRecord.push(record.language);
                        csvRecord.push(record.detained);
                        csvRecord.push(record.nonDetained);
                        csvRecord.push(record.total);
                        return csvRecord;
                    });
                    const data = headings.concat(allData);
                    let csvContent = "data:text/csv;charset=utf-8," + data.map(e => e.join(",")).join("\n");
                    return csvContent;
                }

                $scope.downloadReport = function () {
                    const encodedUri = encodeURI(generateCSVData($scope.records));
                    let link = document.createElement('a');
                    link.setAttribute('href', encodedUri);
                    link.setAttribute('download', `Assignment-Report-${moment().format()}.csv`);
                    link.setAttribute('target', '_blank');
                    document.body.appendChild(link);
                    link.click();
                }

                $scope.printAssignmentStatusReportData = function () {
                    sessionStorage.assignmentStatusReportData = JSON.stringify({
                        printRecords: $scope.records,
                        startDate: $scope.data.startDate,
                        endDate: $scope.data.endDate
                    });
                    window.w = window.open('/print/live-reports/monthly-reports/assignment-status-report', '_blank');
                    setTimeout(function () {
                      window.w.print();
                    }, 3000);
                }
            }
        }
    }
// Dependency Injection
assignmentStatusReportDirective.$inject = ["$activeClient","$q","$activeClientSettings","$timeout","$liveReports","$clientSwitcher"];
