
'use strict';

export const $tablesManagerService  = function (ClientTable) {
    return {
      getTables: function(client) {
        return ClientTable.getTables({
          id: client._id || client
        }).$promise;
      },
      getTableDetails: function(client, table) {
        return ClientTable.detail({
          id: client._id||client,
          table: table
        }).$promise;
      },
      createTable: function(client, tableData) {
        return ClientTable.createTable({
          _id: client._id||client,
          data: tableData
        }).$promise;
      },
      updateTable: function(client, tableId, tableData) {
        return ClientTable.updateTable({
          _id: client._id||client,
          table: tableId,
          data: tableData
        }).$promise;
      },
      deleteTable: function(client, table) {
        return ClientTable.deleteTable({
          _id: client._id||client,
          table: table._id
        }).$promise;
      }
    };
  }
// Dependency Injection


// Dependency Injection
$tablesManagerService.$inject = ['ClientTable'];
