


'use strict';

export const InterpretersWOAvailabilityFactory  = function ($resource, appConfig) {
        return $resource(appConfig.apiUrl + '/api/interpreter-availability/:id/:controller', {
            id: '@_id'
        },
            {
                getOpenWOCount: {
                    method: 'GET',
                    params: {
                        id: '@id',
                        controller: 'get-interpreter-availability-counts'
                    }
                },
                getAvailabilityDetail: {
                    method: 'GET',
                    params: {
                        id: '@id',
                        controller: 'get-interpreter-availability-detail'
                    }
                },
                getFilterRecords: {
                    method: 'POST',
                    params: {
                        controller: 'get-filter-records'
                    }
                },
                getInterpreterAvailabilityCount: {
                    method: 'POST',
                    params: {
                        controller: 'get-interpreter-availability-count'
                    }
                },
                getAvailability: {
                    method: 'POST',
                    params: {
                        controller: 'get-interpreter-availability'
                    }
                },
                getInterpreterWOAvailabilityByDate: {
                    method: 'POST',
                    params: {
                        controller: 'get-interpreter-wo-availability-by-date'
                    }
                },
                createAvailability: {
                    method: 'POST',
                    params: {
                        controller: 'create-interpreter-availability'
                    }
                }
            });
    } 
 

// Dependency Injection
InterpretersWOAvailabilityFactory.$inject = ['$resource', 'appConfig'];
