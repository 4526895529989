'use strict';


export const formSubClientDirective = function (Modal, $rootScope, $q, $auth, $clientSwitcher) {
    return {
      restrict: 'E',
      templateUrl: 'components/forms/sub-client.html',
      scope: {
        region: '=ngModel'
      },
      link: function(scope, el, attrs) {
        var
        comingSoonModal = Modal.info.comingSoon(function() {
          console.log('model finished');
        });

        scope.clientSettings = function(){ return $clientSwitcher.getFeatureOptions('client.settings'); };

        scope.onSubmit = function() {
          comingSoonModal('Save Sub-Client');
        };

        scope.onReset = function() {
          comingSoonModal('Reset Sub-Client');
        };
      }
    };
  }
// Dependency Injection
formSubClientDirective.$inject = ["Modal","$rootScope","$q","$auth","$clientSwitcher"];
