'use strict';

export const printTaskCtrl1Ctrl = function ($scope, $activeClientSettings, $clientSwitcher, TASK_STATE_PAUSED, TASK_STATE_STARTED, TASK_SCHEMA_TYPE_NORMAL, TASK_SCHEMA_TYPE_SURVEY, $filter, $timezones, CalculateWorkOrderTime,
        TASK_STATE_RELEASE_APPROVED, TASK_STATE_IC_RELEASE_REQUEST, TASK_STATE_JUDGEDOUT, TASK_STATE_RELEASE, TASK_STATE_CHECKEDOUT, TASK_STATE_OPI_FIN_PENDING_APPROVAL, TASK_STATE_VRI_FIN_PENDING_APPROVAL, TASK_STATE_JUDGEDIN,
        TASK_STATE_LUNCHOUT, TASK_STATE_EXTEND, TASK_STATE_DENY_FOUT, TASK_STATE_RELEASE_EVENT, INTERPRETER_DISPUTE, COURT_DECLINE, SOSI_DISPUTE, COURT_APPROVE, INTERPRETER_APPROVE, SOSI_ACCEPTED, TASK_STATE_CHECKEDIN, $clientTask,
        $printDate, $getImageFilePath, TASK_STATE_LUNCHIN) {
        // $scope.allTask = JSON.parse(sessionStorage.allTasks);
        $scope.search = JSON.parse(sessionStorage.search);
        $scope.model = JSON.parse(sessionStorage.model);
        $scope.TASK_STATE_PAUSED = TASK_STATE_PAUSED;
        $scope.TASK_STATE_STARTED = TASK_STATE_STARTED;
        $scope.TASK_SCHEMA_TYPE_NORMAL = TASK_SCHEMA_TYPE_NORMAL;
        $scope.TASK_SCHEMA_TYPE_SURVEY = TASK_SCHEMA_TYPE_SURVEY;
        $scope.TASK_STATE_RELEASE_APPROVED = TASK_STATE_RELEASE_APPROVED;
        $scope.TASK_STATE_IC_RELEASE_REQUEST = TASK_STATE_IC_RELEASE_REQUEST;
        $scope.INTERPRETER_DISPUTE = INTERPRETER_DISPUTE;
        $scope.COURT_DECLINE = COURT_DECLINE;
        $scope.SOSI_DISPUTE = SOSI_DISPUTE;
        $scope.COURT_APPROVE = COURT_APPROVE;
        $scope.INTERPRETER_APPROVE = INTERPRETER_APPROVE;
        $scope.SOSI_ACCEPTED = SOSI_ACCEPTED;
        $scope.TASK_STATE_LUNCHIN = TASK_STATE_LUNCHIN;
        $scope.isTaskStateTogglingSupported = false;
        const currentCli = $clientSwitcher.getCurrentClient();
        var filterDuration = $filter('durationMS');
        var currentDate = new Date();
        $scope.clientStrings = function () { return $clientSwitcher.getFeatureOptions('client.strings'); };
        $scope.logo = null;
        function init() {
            $clientTask.filterTasks(currentCli,  _.merge({}, $scope.search, $scope.model) )
              .then(function (filterTasks) {
                var tasks = filterTasks.tasks;
                $scope.allTask = tasks;
                $scope.allTask.map((task) => {
                    task.tags = [];
                    if (task.taskAttributes) {
                        if (task.taskAttributes.tags instanceof Array) {
                            task.tags = task.tags.concat(task.taskAttributes.tags.map(function (tag) {
                                return tag.key + ' | ' + tag.value;
                            }));
                        }
                        if (task.taskAttributes.regions instanceof Array) {
                            task.tags = task.tags.concat(task.taskAttributes.regions.map(function (region) {
                                return $scope.clientStrings().regionPCase + ': ' + region.name;
                            }));
                        }
                        if (task.taskAttributes.locations instanceof Array) {
                            task.tags = task.tags.concat(task.taskAttributes.locations.map(function (location) {
                                return $scope.clientStrings().officePCase + ': ' + location.name;
                            }));
                        }
                        if (task.taskAttributes.employees instanceof Array) {
                            task.tags = task.tags.concat(task.taskAttributes.employees.map(function (employee) {
                                return $scope.clientStrings().employeePCase + ': ' + employee.firstName + ' ' + employee.lastName;
                            }));
                        }
                        if (task.taskAttributes.customers instanceof Array) {
                            task.tags = task.tags.concat(task.taskAttributes.customers.map(function (customer) {
                                return $scope.clientStrings().customerPCase + ': ' + customer.name;
                            }));
                        }
                        if (task.taskAttributes.campaigns instanceof Array) {
                            task.tags = task.tags.concat(task.taskAttributes.campaigns.map(function (campaign) {
                                return $scope.clientStrings().campaignPCase + ': ' + campaign.name;
                            }));
                        }
                        if (task.taskAttributes.equipments instanceof Array) {
                            task.tags = task.tags.concat(task.taskAttributes.equipments.map(function (equipment) {
                                return $scope.clientStrings().equipmentPCase + ': ' + equipment.name;
                            }));
                        }
                    }
    
                    if (!task.woCalculations) {
                        CalculateWorkOrderTime(task.caseData)
                            .then(data => {
                                task.woCalculations = data;
                            })
                    }
    
                    if (task.caseData && task.caseData.judgedInOut.length > 0) {
                        var arrJudgeInOut = [];
                        var IN, OUT;
                        task.caseData.judgedInOut.forEach(function (judgedInOut, index) {
                            if (index % 2 == 0) {
                            } else {
                                OUT = judgedInOut;
                                arrJudgeInOut.push({
                                    'OUT': OUT
                                });
                            }
                        })
                        task.caseData.judgeICPerformance = arrJudgeInOut;
                    }
                })
              })
              .catch(function(err){
                let errMessage = err.message || err;
                if (err && err.data && err.data.message) errMessage = err.data.message;
                $scope.error = errMessage;
                return;
              });

        }

        init();

        $scope.isTaskLate = function (task) {
            if (!task.due) {
                return false;
            }

            return task.due < currentDate;
        };

        $scope.getTaskState = function (task) {
            if (!task.taskState) {
                return 'Not Started';
            }
            else if (task.taskState === TASK_STATE_STARTED) {
                return 'Started';
            }
            else if (task.taskState === TASK_STATE_PAUSED) {
                return 'Paused';
            }
        };

        $scope.resolveDuration = function (task) {
            if (task.status !== 'resolved' || !task.resolve.resolvedOn || !task.stateActivity.length) {
                return false;
            }
            var startingTime = task.stateActivity && task.stateActivity.length ? task.stateActivity[0].date : task.createdOn;
            return filterDuration(moment(task.resolve.resolvedOn).diff(moment(startingTime)));
        }

        $activeClientSettings(function (client, clientSettings) {
            $scope.isTaskStateTogglingSupported = clientSettings.supportTaskStateToggling;
            $scope.clientSettings = clientSettings || null;
            $scope.logo = null;
            if (clientSettings) {
                $getImageFilePath(currentCli, clientSettings.vanityLogoId)
                    .then((imageUrl) => {
                        $scope.logo = imageUrl;
                    })
            }
        });

        var formattedTime = function (totalTimeMS) {
            if (totalTimeMS < 60000) { //60 seconds
                return 'less than a minute';
            }

            var duration = moment.duration(totalTimeMS);
            var days = duration.days();
            var hours = duration.hours();
            var minutes = duration.minutes();

            var returnString = '';
            if (days > 0) {
                returnString += days + (days === 1 ? ' day ' : ' days ');
            }
            if (hours) {
                var paddedHours = hours >= 10 ? hours : hours;
                returnString += paddedHours + (hours === 1 ? ' hour ' : ' hours ');
            }
            if (minutes) {
                var paddedMinutes = minutes >= 10 ? minutes : minutes;
                returnString += paddedMinutes + (minutes === 1 ? ' minute ' : ' minutes ');
            }

            return returnString;
        };

        $scope.getTotalTimeWorked = function (task) {
            var totalTimeMS = 0;

            if (task.stateActivity.length) {
                for (var i = 0; i < task.stateActivity.length - 1; i++) {
                    if (task.stateActivity[i].state === TASK_STATE_STARTED && task.stateActivity[i + 1].state === TASK_STATE_PAUSED) {
                        totalTimeMS += moment(task.stateActivity[i + 1].date).diff(moment(task.stateActivity[i].date));
                        i++;
                    }
                }
            }

            //task was started and then resolved
            if (task.status === 'resolved' && task.stateActivity.length > 1 && task.stateActivity[task.stateActivity.length - 2].state === TASK_STATE_STARTED) {
                var resolvedOn = task.stateActivity[task.stateActivity.length - 1].date;
                var lastStartedOn = task.stateActivity[task.stateActivity.length - 2].date;
                totalTimeMS += moment(resolvedOn).diff(moment(lastStartedOn));
            }

            return formattedTime(totalTimeMS);
        };

        $scope.getTotalTimeOpen = function (task) {
            var totalTimeMS = 0;
            if (task.status === 'resolved') {
                totalTimeMS = moment(task.resolve.resolvedOn).diff(moment(task.createdOn));
            }
            else if (task.status === 'unresolved') {
                totalTimeMS = moment(task.due).diff(moment(task.createdOn));
            }
            return formattedTime(totalTimeMS);
        };

        $scope.getTotalTimePaused = function (task) {
            var totalTimeMS = 0;

            if (task.stateActivity.length) {
                for (var i = 0; i < task.stateActivity.length; i++) {
                    if (task.stateActivity[i].state === TASK_STATE_PAUSED && task.stateActivity[i + 1] && task.stateActivity[i + 1].state === TASK_STATE_STARTED) {
                        totalTimeMS += moment(task.stateActivity[i + 1].date).diff(moment(task.stateActivity[i].date));
                        i++;
                    }
                }
            }

            return formattedTime(totalTimeMS);
        };

        $scope.getTimeZone = function (tz) {

            const timezone = $timezones.find(x => x.label === tz);

            return timezone ? timezone : null;
        }

        $scope.getSchTime = function (time, date, courtTimezoneId) {
            if (!date) return time;
            if (courtTimezoneId) {
                let tz = $autoTimezoneFormat.getAllDetail( moment.utc(date).format('YYYY-MM-DD'), time, courtTimezoneId);
                if (tz) return `${tz.caseDisplayDateTime} ${tz.coiTimezone}`
              } 
            const newTime = moment.utc(date).format('YYYY-MM-DD') + ' ' + time;
            return moment(newTime).format('MM/DD/YYYY hh:mm A');
        }

        $scope.getFormattedTime = function (startTime) {
            if (startTime === 'N/A') return startTime;
            const time = moment(startTime).format('hh:mm A');
            return time;
        }

        $scope.isIcRequestReleaseApprovedOrNot = (activity, task) => {
            let icRequestedReleasedEvent = task.stateActivity.filter((obj) => obj.state == $scope.TASK_STATE_RELEASE_APPROVED)
            let lastEvent = icRequestedReleasedEvent[icRequestedReleasedEvent.length - 1];
            if (lastEvent && lastEvent.approved) return true;
            return false;
        }

        // Set user name
        $scope.setUser = (activity) => {
            if (activity.state == TASK_STATE_RELEASE) {
                if (activity && activity.release && activity.release.releaseType === 'judge_out') {
                    return `System`;
                }
            }
            let flagEvents = [
                $scope.INTERPRETER_DISPUTE,
                $scope.COURT_DECLINE,
                $scope.SOSI_ACCEPTED,
                $scope.SOSI_DISPUTE,
                $scope.COURT_APPROVE,
                $scope.INTERPRETER_APPROVE];

            if (activity.isAutoApproved) {
                if (flagEvents.indexOf(activity.state) > -1) {
                    if (activity && activity.isAutoApproved) {
                        return `System`;
                    }
                }
            }

            let events = ['Awaiting Interpreter Approval', 'Awaiting Court Acceptance', 'Awaiting SOSI Acceptance', TASK_STATE_JUDGEDOUT, TASK_STATE_RELEASE, TASK_STATE_CHECKEDOUT, TASK_STATE_OPI_FIN_PENDING_APPROVAL, TASK_STATE_VRI_FIN_PENDING_APPROVAL]
            if (events.indexOf(activity.state) > -1) {
                if (activity && activity.isFlagEventWithButton) {
                    return `System`;
                } else {
                    if (activity.autoJudgeOutIn) return 'System';
                    else if (activity.release && activity.release.isAuto) return 'System';
                    else if (activity.isAutoFout) return 'System';
                }
            }
            return activity.user && activity.user.name
        }

        // Set event name
        $scope.setEvent = (activity, stateActivity) => {
            if (activity && activity.isFlagEvent) return activity.eventName;
            if (stateActivity) {
                if (activity.state == TASK_STATE_CHECKEDIN) {
                    if (activity && activity.manual) {
                        return `${activity.state} (Manual${activity.isEdited ? ', Edited' : ''})`;
                    }
                }
                if (activity.state == TASK_STATE_CHECKEDOUT) {
                    var autoFOUT;
                    stateActivity.forEach((sActivity) => {
                        if (sActivity.state == TASK_STATE_JUDGEDOUT && sActivity.autoFacilityOut && sActivity.autoFacilityOut.isAutoFOut) {
                            autoFOUT = sActivity;
                        }
                    })
                    if (autoFOUT && autoFOUT.autoFacilityOut && autoFOUT.autoFacilityOut.isAutoFOut) {
                        return `${activity.state} (Auto${activity.isEdited ? ', Edited' : ''})`;
                    }
                    if (activity.isAutoFout) {
                        return `${activity.state} (Auto)`;
                    }
                    if (activity.manual || activity.kioskEarlyFout) {
                        if (activity.manual && activity.kioskEarlyFout)
                            return `${activity.state} (Manual, Kiosk Early Fout${activity.isEdited ? ', Edited' : ''})`;
                        if (activity.manual)
                            return `${activity.state} (Manual${activity.isEdited ? ', Edited' : ''})`;
                        if (activity.kioskEarlyFout)
                            return `${activity.state} (Kiosk Early Fout${activity.isEdited ? ', Edited' : ''})`;
                    };
                }
                if (activity.state == TASK_STATE_JUDGEDIN) {
                    if (activity && activity.autoJudgeOutIn) {
                        return `${activity.state} (Auto${activity.isEdited ? ', Edited' : ''})`;
                    }
                }
                if (activity.state == TASK_STATE_JUDGEDOUT) {
                    if (activity && activity.autoJudgeOutIn) {
                        return `${activity.state} (Auto${activity.isEdited ? ', Edited' : ''})`;
                    }
                }
                if (activity.state == TASK_STATE_LUNCHOUT) {
                    if (activity && activity.autoLunchOut) {
                        return `${activity.state} (Auto${activity.isEdited ? ', Edited' : ''})`;
                    }
                }
                if (activity.state == TASK_STATE_EXTEND) {
                    if (activity && activity.extend) {
                        return `Extended till ${activity.isEdited ? '(Edited)' : ''}`;
                    }
                }
                if (activity.state == TASK_STATE_RELEASE) {
                    if (activity && activity.release) {
                        if (activity.release.releaseType === 'now' && activity.release.isICAddedWOEvent) {
                            return `Released (Manual)`;
                        } else if (activity.release.releaseType === 'now' && !activity.release.isAuto) {
                            return `Released (Manual${activity.isEdited ? ', Edited' : ''})`;
                        } else if (activity.release.releaseType === 'now' && activity.release.isAuto) {
                            return `Released (Auto${activity.isEdited ? ', Edited' : ''})`;
                        } else if (activity.release.releaseType === 'judge_out') {
                            return `Released (on Judge OUT${activity.isEdited ? ', Edited' : ''})`;
                        } else if (activity.release.releaseType === 'time') {
                            return `Released (Auto${activity.isEdited ? ', Edited' : ''})`;
                        }
                    }
                }
                if (activity.state == TASK_STATE_DENY_FOUT && activity.denyFacilityOut) {
                    return `${activity.state} ${activity.isEdited ? '(Edited)' : ''}`;
                }
                if (activity.state == TASK_STATE_RELEASE_EVENT && activity.releaseEvent) {
                    if (activity.releaseEvent.releaseType === 'judge_out') {
                        return `Pending Release (Next JOUT)`;
                    }
                    if (activity.releaseEvent.releaseType === 'time') {
                        return `Pending Release (at ${moment(activity.releaseEvent.value).format('hh:mm A')})`;
                    }
                }
                if (activity.state == COURT_APPROVE) {
                    if (activity.isAutoApproved) {
                        return `${activity.state} (Auto)`;
                    }
                }
            }
            return `${activity.state}${activity.isEdited ? '(Edited)' : activity.isICAddedWOEvent ? ' (Manual)' : ''}`;
        }

        $scope.convertSecondToTime = (activity) => {
            if (activity.state == TASK_STATE_JUDGEDIN) {
                var duration, text;
                if (activity.lateTime) {
                    duration = activity.lateTime;
                    text = '(Late by ';
                } else if (activity.earlyTime) {
                    duration = activity.earlyTime;
                    text = '(Early by ';
                }
                if (duration && duration > 0) {
                    var hours = Math.floor(duration / 3600);
                    var minutes = Math.floor((duration - hours * 3600) / 60);
                    var seconds = Math.floor((duration - hours * 3600) % 10);
                    return (text +
                        hours.toString().padStart(2, '0') +
                        ':' +
                        minutes.toString().padStart(2, '0') + ')'
                    );
                }
            }

            if (activity.state == TASK_STATE_EXTEND) {
                if (activity.extend && activity.extend.nextHourTime) {
                    return `(${moment(activity.extend.nextHourTime).format('hh:mm A')})`;
                }
                if (activity.extend && activity.extend.extendTime) {
                    return `(${moment(activity.extend.extendTime).format('hh:mm A')})`;
                }
            }
        }

        $scope.eventExpend = (stateActivityEvent, activity) => {
            activity.expend = !activity.expend
            stateActivityEvent.map((stateActivity) => {
                if (activity.state == stateActivity.state && stateActivity.originalValue) {
                    stateActivity['expand' + activity.state + activity._id] = !stateActivity['expand' + activity.state + activity._id];
                }
                return stateActivity;
            })
        }

        $scope.setJudgeName = (activity, stateActivity) => {
            if (activity && activity.isFlagEvent) return 'N/A';
            if (activity && activity.isFlagEventWithButton) return 'N/A';
            if (activity.state == TASK_STATE_CHECKEDIN || activity.state == TASK_STATE_CHECKEDOUT) {
                if (activity.state == TASK_STATE_CHECKEDOUT) {
                    if (stateActivity) {
                        var autoFOUT;
                        stateActivity.forEach((sActivity) => {
                            if (sActivity.state == TASK_STATE_JUDGEDOUT && sActivity.autoFacilityOut && sActivity.autoFacilityOut.isAutoFOut) {
                                autoFOUT = sActivity;
                            }
                        })
                        if (autoFOUT && autoFOUT.autoFacilityOut && autoFOUT.autoFacilityOut.isAutoFOut) {
                            if (autoFOUT.performedJudge && autoFOUT.performedJudge.ijname) {
                                return autoFOUT.performedJudge.ijname;
                            }
                            if (autoFOUT.user) {
                                return autoFOUT.user.name || 'N/A';
                            }
                        }
                    }
                }
                return 'N/A';
            }
            if (activity.state == TASK_STATE_RELEASE) {
                if (activity && activity.release && activity.release.judge) {
                    return activity.release.judge.ijname;
                } else {
                    return 'N/A';
                }
            }
            let events = [$scope.INTERPRETER_DISPUTE, $scope.COURT_DECLINE, $scope.SOSI_DISPUTE, $scope.COURT_APPROVE, $scope.INTERPRETER_APPROVE, TASK_STATE_IC_RELEASE_REQUEST, $scope.SOSI_ACCEPTED, 'Release Approved', 'Open', 'Confirmed', 'New', 'Assigned', 'Locating', 'Cancelled', 'Interpreter No Show', 'No Interpreter'];
            if (events.indexOf(activity.state) > -1) {
                return 'N/A';
            }
            // if(activity.state == TASK_STATE_IC_RELEASE_REQUEST || activity.state == 'Release Approved'){
            //   return 'N/A';
            // }
            if (activity.state == TASK_STATE_RELEASE_EVENT && activity.releaseEvent) {
                const isCourtJudge = (currentUserData.settings.roles.findIndex(x => x.code === 'COURT_JUDGE') >= 0);
                if (!isCourtJudge) return 'N/A';
            }
            if (activity.performedJudge && activity.performedJudge.ijname) {
                return activity.performedJudge.ijname;
            }
            if (activity.user) {
                if (activity.state == $scope.TASK_STATE_LUNCHIN) return 'N/A';
                return activity.user.name || 'N/A';
            }
            return activity.message || 'N/A';
        }

        $scope.setEventComment = (activity, stateActivity) => {
            if (activity && activity.isFlagEvent) return '';
            var editComment = null;
            if (activity.originalValue) {
                editComment = activity.comment || '';
                return `${editComment ? editComment : ''}`
            }
            if (activity.state == TASK_STATE_CHECKEDIN || activity.state == 'Open') {
                return activity.comment;
            }
            if (activity.state == TASK_STATE_JUDGEDIN) {
                var comment = '';
                if (activity.late) {
                    comment = activity.late.comment ? activity.late.reason + ' | ' + activity.late.comment : activity.late.reason;
                }
                if (activity.early) {
                    comment = activity.early.comment ? activity.early.comment : '';
                }
                if (activity.performedJudge && activity.performedJudge.comment) {
                    return `${activity.performedJudge.comment} ${comment ? '<br>- ' + comment : ''}`;
                } else {
                    return `${comment ? comment : ''}`;
                }
            }
            if (activity.state == TASK_STATE_CHECKEDOUT) {
                if (activity.comment && (activity.comment != '' || activity.comment != null)) {
                    return activity.comment
                }
                var lateComment = '', icAppearedButNotUsed = '', icNotUsedDenyRelease = '', kioskLateFout, autoFOUTReason = '';
                if (activity.earlyOut) {
                    lateComment = `${activity.earlyOut.reason} ${activity.earlyOut.comment ? ' | ' + activity.earlyOut.comment : ''}`;
                }
                if (activity.icAppearedButNotUsed) {
                    icAppearedButNotUsed = activity.icAppearedButNotUsed;
                }
                if (activity.icNotUsedDenyRelease) {
                    icNotUsedDenyRelease = activity.icNotUsedDenyRelease;
                }
                if (activity.kioskLateFout && activity.kioskLateFout.comment) {
                    kioskLateFout = `Kiosk Late Facility OUT - Reason: ${activity.kioskLateFout.reason}. Comment: ${activity.kioskLateFout.comment}`;
                }
                var autoFOUT;
                stateActivity.forEach((sActivity) => {
                    if (sActivity.state == TASK_STATE_JUDGEDOUT && sActivity.autoFacilityOut && sActivity.autoFacilityOut.isAutoFOut) {
                        autoFOUT = sActivity;
                    }
                })
                if (autoFOUT && autoFOUT.autoFacilityOut && autoFOUT.autoFacilityOut.isAutoFOut) {
                    autoFOUTReason = autoFOUT.autoFacilityOut.reason ? autoFOUT.autoFacilityOut.reason : '';
                }
                return `${lateComment ? '- ' + lateComment + '<br>' : ''} ${icAppearedButNotUsed ? '- Interpreter appeared, but not Used(Release) | ' + icAppearedButNotUsed : ''} ${icNotUsedDenyRelease ? '<br>- Interpreter appeared, but not Used(Deny Release) | ' + icNotUsedDenyRelease : ''} ${editComment ? '- ' + editComment : ''} ${kioskLateFout ? kioskLateFout : ''} ${autoFOUTReason ? autoFOUTReason : ''}`;
            }
            if (activity.state == TASK_STATE_EXTEND) {
                if (activity && activity.extend) {
                    return `${activity.extend.comments + '<br>'} ${editComment ? '- ' + editComment : ''}`;
                }
            }
            // #697 - Auto Release on Next Judge Out Event then comment display
            if (activity.state == TASK_STATE_RELEASE_EVENT) {
                if (activity && activity.releaseEvent && activity.releaseEvent.comments) {
                    return `${activity.releaseEvent.comments + '<br>'} ${editComment ? '- ' + editComment : ''}`;
                }
            }
            if (activity.state == TASK_STATE_RELEASE) {
                if (activity && activity.release && activity.release.comments) {
                    return `${activity.release.comments + '<br>'} ${editComment ? '- ' + editComment : ''}`;
                }
            }
            if (activity.state == TASK_STATE_DENY_FOUT && activity.denyFacilityOut) {
                if (activity.denyFacilityOut.comments) {
                    return `${activity.denyFacilityOut.reason ? activity.denyFacilityOut.reason + ' | ' : ''} ${activity.denyFacilityOut.comments + '<br>'} ${editComment ? '- ' + editComment : ''}`;
                }
                return `${activity.denyFacilityOut.reason} ${editComment ? '<br>- ' + editComment : ''}`;
            }
            if (activity.state == TASK_STATE_IC_RELEASE_REQUEST) {
                return activity.releaseRequest && activity.releaseRequest.comment || '';
            }

            let disputeEvent = [$scope.INTERPRETER_DISPUTE, $scope.COURT_DECLINE, $scope.SOSI_DISPUTE];
            if (disputeEvent.indexOf(activity.state) > -1) {
                if (activity.disputeEvent && activity.disputeEvent.comments && activity.disputeEvent.comments.length > 0) {
                    return activity.disputeEvent.comments[0].comment || ''
                }
            }

            return `${activity.performedJudge && activity.performedJudge.comment ?  '<br>- ' + activity.performedJudge && activity.performedJudge.comment : activity.comment ? activity.comment : ''}`;
        }

        $scope.convert2Decimal = (value) => {
            return value && value !== 'N/A' ?  value.toFixed(2) : 0;
        }

        $scope.additionalLanguages = function (judgeOutRecord) {
            if (judgeOutRecord.additionalLanguages && judgeOutRecord.additionalLanguages.length > 0) {
                var additionalLanguages = '';
                judgeOutRecord.additionalLanguages.forEach((lang) => {
                    if (lang) {
                        additionalLanguages += lang + ', '
                    }
                });
                additionalLanguages = additionalLanguages.replace(/,\s*$/, "");
                return additionalLanguages;
            }
            return 'N/A'
        }

        $scope.getImageFilePath = function (upload) {
            return $getImageFilePath(currentCli, upload)
        };

        $scope.displayPrintDate = function () {
            return $printDate();
        }
    }
// Dependency Injection
printTaskCtrl1Ctrl.$inject = ["$scope","$activeClientSettings","$clientSwitcher","TASK_STATE_PAUSED","TASK_STATE_STARTED","TASK_SCHEMA_TYPE_NORMAL","TASK_SCHEMA_TYPE_SURVEY","$filter","$timezones","CalculateWorkOrderTime","TASK_STATE_RELEASE_APPROVED","TASK_STATE_IC_RELEASE_REQUEST","TASK_STATE_JUDGEDOUT","TASK_STATE_RELEASE","TASK_STATE_CHECKEDOUT","TASK_STATE_OPI_FIN_PENDING_APPROVAL","TASK_STATE_VRI_FIN_PENDING_APPROVAL","TASK_STATE_JUDGEDIN","TASK_STATE_LUNCHOUT","TASK_STATE_EXTEND","TASK_STATE_DENY_FOUT","TASK_STATE_RELEASE_EVENT","INTERPRETER_DISPUTE","COURT_DECLINE","SOSI_DISPUTE","COURT_APPROVE","INTERPRETER_APPROVE","SOSI_ACCEPTED","TASK_STATE_CHECKEDIN","$clientTask","$printDate","$getImageFilePath","TASK_STATE_LUNCHIN"];
