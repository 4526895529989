


'use strict';

export const AuthorizorFactory  = function ($rootScope, $state, $q, $auth, $clientSwitcher) {

    function checkClientAccess(ability) {
      var defer = $q.defer();

      if(!ability) {
        defer.resolve(true);
        return defer.promise;
      }

      var
      killCsLoad, killCsSwitch;

      $clientSwitcher.whenLoaded(function (all, current) {
        var recheck = function() {
          if(!$clientSwitcher.hasAbility(ability)) {
            return $state.go($auth.isLoggedIn() ? 'app.account.dashboard' : 'app.account.login');
          }
        };

        killCsLoad = $rootScope.$on('client-switcher-load', recheck);
        killCsSwitch = $rootScope.$on('client-switcher-switch', recheck);

        if($clientSwitcher.hasAbility(ability)) {
          return defer.resolve(true);
        }

        return defer.reject($auth.isLoggedIn() ? 'dashboard home' : 'no login');
      });

      var
      onceChange = $rootScope.$on('$stateChangeStart', function (event, next, nextParams) {
        killCsLoad();
        killCsSwitch();
        onceChange();
      });

      return defer.promise;
    }

    return function Authorizor() {
      var
      defer      = $q.defer(),
      next       = $rootScope.nextState,
      nextParams = $rootScope.nextStateParams,
      roles      = (!!next.data && !!next.data.roles) ? next.data.roles : false;

      if(!roles || !roles.length) {
        defer.resolve(true); // no auth needed
      }
      else {
        $auth.isLoggedInAsync(function (loggedIn) {

          if(!loggedIn) { // resolve to login page
            return defer.reject('no login');
          }

          var
          user = $auth.getCurrentUser(),
          userRole = user.role,
          permissions = user.permissions;
          // allowed = angular.isArray(roles)
          //   ? (roles.indexOf(userRole) !== -1)
          //   : (roles === userRole);
          if(next.data && !next.data.rejectPermission){
            var allowed = permissions.includes(next.data.permission);
  
            if(!allowed) { // resolve to access denied page
              return defer.reject('access denied');
            }
          }

          // resolve original state name and params
          defer.resolve(user);
        });
      }

      return defer.promise
        .then(function (lResult) {
          if(!lResult || !next.data || !next.data.ability) {
            return lResult;
          }

          return checkClientAccess(next.data.ability)
            .then(function (accessCheck) {
              return lResult;
            });
        });
    };
  } 
 

// Dependency Injection
AuthorizorFactory.$inject = ['$rootScope', '$state', '$q', '$auth', '$clientSwitcher'];
