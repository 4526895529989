
'use strict';

export const $taskReportsService  = function (TaskReportsManager) {
    return {
      stats: function(client, filter) {
        var filters = _.extend({ id: client._id || client }, filter);
        return TaskReportsManager.stats(filters).$promise;
      },
      managerStats: function(client, employees) {
        return TaskReportsManager.managerStats({
          id: client._id || client,
          employees: employees
        }).$promise;
      }
    };
  }
// Dependency Injection


// Dependency Injection
$taskReportsService.$inject = ['TaskReportsManager'];
