


'use strict';

export const InvoiceFactory  = function ($resource, appConfig) {
    return $resource(appConfig.apiUrl + '/api/invoices/:id/:controller', {
      id: '@_id'
    },
      {
        getList: {
          method: 'GET',
          params: {
            id: '@id',
            controller: ''
          }
        },

        create: {
          method: 'POST',
          params: {
            id: '@id',
            controller: 'create'
          }
        },
        update: {
          method: 'POST',
          params: {
            id: '@id',
            controller: 'update'
          }
        },
        getInvoiceDetails: {
          method: 'GET',
          params: {
            id: '@id',
            controller: ''
          }
        },
        updateInvoiceLocation: {
          method: 'POST',
          params: {
            id: '@id',
            controller: 'update-invoice-locations'
          }
        }

      });
  } 
 

// Dependency Injection
InvoiceFactory.$inject = ['$resource', 'appConfig'];
