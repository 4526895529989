
'use strict';

export const $clientSwitcherStorageService  = function ($cookieStore) {
    var COOKIE_TOKEN = 'current_client';
    return {
      clear: function () {
        $cookieStore.remove(COOKIE_TOKEN);
        return this;
      },
      getCurrent: function () {
        return $cookieStore.get(COOKIE_TOKEN);
      },
      setCurrent: function (c) {
        if(!c) return;
        $cookieStore.put(COOKIE_TOKEN, c._id);
        return this;
      }
    };
  }
 

// Dependency Injection
$clientSwitcherStorageService.$inject = ['$cookieStore'];
