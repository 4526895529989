import './connection-status.scss';
'use strict';

export const connectionStatusDirective = function ($window, $timeout, $rootScope) {
        return {
            restrict: 'E',
            templateUrl: 'app/account/dashboard/connection-status/connection-status.html',
            scope: {},
            link: function (scope) {
                let clearMessage = null;
                const initListeners = () => {
                    $rootScope.$on('ServerError', () => {
                        $timeout(() => scope.connectionStatus = 'offline');
                    })

                    $window.addEventListener("offline", function () {
                        $timeout(() => scope.connectionStatus = 'offline');
                    }, false)

                    $window.addEventListener("online", function () {
                        $timeout(() => {
                            scope.connectionStatus = 'online';
                            scope.startClearTimer();
                        })
                    })
                }

                scope.startClearTimer = () => {
                    clearMessage = $timeout(() => {
                        scope.connectionStatus = '';
                    }, 3000)
                }

                scope.cancelClearTimer = () => {
                    if (clearMessage) {
                        $timeout.cancel(clearMessage);
                    }
                }

                scope.refresh = () => {
                    $window.location.reload();
                }

                initListeners();
            }
        }
    }
// Dependency Injection
connectionStatusDirective.$inject = ["$window","$timeout","$rootScope"];
