
'use strict';

export const $clientSubClientService  = function (ClientSubClient) {
    return {
      listAll: function(client) {
        return ClientSubClient.query({ client: client._id || client }).$promise;
      }
    };
  }
// Dependency Injection


// Dependency Injection
$clientSubClientService.$inject = ['ClientSubClient'];
