'use strict';

export const roleDirective = function ($auth, $timeout, $clientSwitcher, $activeClient, $clientRoles, $permissionMatrix, $permissionMatrixSourceType) {
        return {
            restrict: 'E',
            templateUrl: 'app/account/dashboard/settings/permission-matrix/tabs/role/role.html',
            scope: {
                modelData: '=ngModel',
                tabSelected: '&'
            },
            link: function (scope) {
                const currentCli = $clientSwitcher.getCurrentClient();
                scope.client = currentCli;
                scope.arrRoles = [];
                scope.selectedRole = {};
                scope.userPermissions = [];
                scope.permissions = {
                    settingPermission: false,
                    allowUserEdit: false,
                    allowPermissionEdit: false
                };
                scope.data = {
                    isLoading: true,
                    permissionError: null,
                    userError: null,
                    assignRoleUsers: [],
                    assignRolePermissions: [],
                    permissionsType: []
                };

                var init = function () {
                    $activeClient(function (client) {
                        if (!client) {
                            return;
                        }
                        scope.client = client;
                        $timeout(function () {
                            scope.permissions.settingPermission = scope.modelData.featureTabs.includes("SETTINGS_READ");
                            scope.permissions.allowUserEdit = $auth.getCurrentUser().permissions.includes('SETTING_USER_EDIT');
                            scope.permissions.allowPermissionEdit = $auth.getCurrentUser().permissions.includes('SETTING_PERMISSION_EDIT');
                            scope.permissions.allowWorkOrderEdit = $auth.getCurrentUser().permissions.includes('EDIT_WO_BUCKET');
                        }, 100);
                        getRoles(client);
                    });
                };

                scope.setAccordian = function ($event, assignRolePermission) {
                    if ($event) {
                        $event.currentTarget.classList.toggle("active");
                        var panel = $event.target.nextElementSibling;
                        if(panel){
                            if (panel.style.display === "block") {
                                panel.style.display = "none";
                            } else {
                                panel.style.display = "block";
                            }
                        }
                    }
                    if(assignRolePermission){
                        assignRolePermission.expand = !assignRolePermission.expand;
                    }
                }
                function getRoles(client) {
                    $clientRoles.getRoles(client)
                        .then(({ data, status }) => {
                            if (status) {
                                scope.arrRoles = data;
                                scope.data.isLoading = false;
                                if (scope.modelData.selectedData && scope.modelData.selectedData._id) {
                                    setTimeout(() => {
                                        scope.onRoleSelect(scope.modelData.selectedData);
                                        var elmnt = document.getElementById(scope.modelData.selectedData._id);
                                        elmnt.scrollIntoView();
                                    }, 500)
                                }
                            }
                        })
                }

                scope.onRoleSelect = function (selectedRole) {
                    scope.data.isLoading = true;
                    resetData();
                    scope.selectedRole = selectedRole;
                    $permissionMatrix.getRoleDetails(currentCli, selectedRole._id)
                        .then(res => {
                            if (res.status) {
                                if (res.assignRolePermissions && res.assignRolePermissions.length) {
                                    // scope.data.assignRolePermissions = res.assignRolePermissions;
                                    scope.permissionsCount = res.assignRolePermissions.length || 0;
                                    scope.data.assignRolePermissions = _.chain(res.assignRolePermissions)
                                        .groupBy("type")
                                        .map((value, key) => ({ page: key, permissions: value }))
                                        .value();
                                } else {
                                    scope.data.permissionError = 'No Permissions Assigned';
                                }
                                if (res.assignRoleUsers && res.assignRoleUsers.length) {
                                    scope.data.assignRoleUsers = res.assignRoleUsers;
                                } else {
                                    scope.data.userError = 'No Roles Assigned';
                                }
                                window.scrollTo(0, 0);
                            } else {
                                scope.data.userError = res.message;
                            }
                            scope.data.isLoading = false;
                        })
                }
                scope.showUserTab = function (user) {
                    user.userId = user._id;
                    scope.tabSelected({ type: "user", data: user })
                }

                scope.sourceType = function (permissionType) {
                    return $permissionMatrixSourceType(permissionType)
                }

                function resetData() {
                    scope.data.permissionError = null;
                    scope.data.userError = null;
                    scope.data.assignRoleUsers = [];
                    scope.data.assignRolePermissions = [];
                }
                init();
            }
        }
    }
// Dependency Injection
roleDirective.$inject = ["$auth","$timeout","$clientSwitcher","$activeClient","$clientRoles","$permissionMatrix","$permissionMatrixSourceType"];
