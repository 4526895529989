'use strict';


export const $dateValueService = function () {
  return function (v) {
    if(angular.isDate(v))
      return v;

    if(angular.isNumber(v))
      return new Date(v);

    if(angular.isString(v)) {
      var ms = Date.parse(v);
      if(!isNaN(ms)) return new Date(ms);
    }

    return false;
  };
} 
 export const validateDateDirective = function ($parse, $dateValue, $clientSwitcher, $clientHolidays, $activeClientSettings) {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, el, attr, model) {
      var
      _getCompareValue = $parse(attr.validateDate),
      _getModelValue = $parse(attr.ngModel),
      _isCheckBefore = $parse(attr.vdBefore),
      _isCheckAfter = $parse(attr.vdAfter),
      beforeTag = 'date-before',
      afterTag = 'date-after',
      notOnWeekendTag = 'not-on-weekend',
      notOnHolidayTag = 'not-on-holiday',
      holidays = null,
      clientSettings = null;

      function getCompareValue() { return _getCompareValue(scope); }
      function getModelValue() { return _getModelValue(scope); }
      function assertIsBefore() {
        var
        valBefore = _isCheckBefore(scope),
        valAfter  = _isCheckAfter(scope);

        if(typeof valBefore === 'boolean') {
          return valBefore;
        }
        else if(typeof valAfter === 'boolean') {
          return !valAfter;
        }

        return false;
      }

      function init() {
        $activeClientSettings(function(client, settings) {
          $clientHolidays.list(client)
          .then(function(res) {
            if(res.status) {
              holidays = res.data;
            }
            else {
              console.error(res.reason);
            }
          })
          .catch(function(err) {
            console.error(err);
          });
          
          clientSettings = settings;

          scope.$watch(function () { return getCompareValue(); }, check);
          scope.$watch(function () { return getModelValue();   }, check);
          scope.$watch(function () { return assertIsBefore();  }, check);
        });
      }

      function check() {
        var
        assertBefore = assertIsBefore(),
        dateCompare  = $dateValue(getCompareValue()),
        dateModel    = $dateValue(getModelValue()),
        errorTag     = assertBefore ? afterTag  : beforeTag,
        otherTag     = assertBefore ? beforeTag : afterTag,
        modelMoment  = moment(dateModel);

        model.$setValidity(errorTag, true);
        model.$setValidity(otherTag, true);
        model.$setValidity(notOnHolidayTag, true);
        model.$setValidity(notOnWeekendTag, true);

        if(!dateModel) {
          return model;
        }

        // check validity for assignment on weekday
        
        var enforceOnWeekend = clientSettings.scheduleEnforcement && 
          clientSettings.scheduleEnforcement.weekends && 
          clientSettings.scheduleEnforcement.weekends.taskAssign;

        if(enforceOnWeekend && clientSettings.businessDays) {
          var selectedDay = modelMoment.isoWeekday() - 1;
          var isOnWeekday = clientSettings.businessDays[selectedDay] && clientSettings.businessDays[selectedDay].enabled;

          model.$setValidity(notOnWeekendTag, isOnWeekday);
          
          if(!isOnWeekday) {
            return model;
          }
        }

        // check validity for assignment on holidays
        var enforceOnHoliday = clientSettings.scheduleEnforcement && 
          clientSettings.scheduleEnforcement.holidays && 
          clientSettings.scheduleEnforcement.holidays.taskAssign;

        if(enforceOnHoliday && holidays && holidays.length) {
          var isOnHoliday = _.some(holidays, function(holiday) {
            var startDate = moment(holiday.start);
            var endDate = moment(holiday.end);

            return modelMoment.isBetween(startDate, endDate);
          });

          model.$setValidity(notOnHolidayTag, !isOnHoliday);
          
          if(isOnHoliday) {
            return model;
          }        
        }

        if(!dateCompare || !dateModel) {
          model.$setValidity(errorTag, true);
          return model; // no change
        }

        if(assertBefore && (dateCompare > dateModel)) {
          model.$setValidity(errorTag, false);
          return model;
        }
        else if(!assertBefore && (dateCompare < dateModel)) {
          model.$setValidity(errorTag, false);
          return model;
        }

        // mark model valid
        model.$setValidity(errorTag, true);
        return model;
      }

      init();
    }
  };
}
// Dependency Injection
validateDateDirective.$inject = ["$parse","$dateValue","$clientSwitcher","$clientHolidays","$activeClientSettings"];
