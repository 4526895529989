'use strict';

export const RankingConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard.ranking', {
        url: '/ranking',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/ranking/ranking.html', 'bcab44ca'); }],
        controller: 'DashboardRankingCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/ranking/ranking.controller.js', 'DashboardRankingCtrl'); }]
                      },
        data: {
          ability: 'client.ranking',
          permission: 'RANKING_READ'
        }
      });
  }
// Dependency Injection
RankingConfig.$inject = ["$stateProvider"];
