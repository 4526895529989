


'use strict';

export const UserFactory  = function ($resource, appConfig) {
    return $resource(appConfig.apiUrl + '/api/users/:controller', {}, {
      changePassword: {
        method: 'PUT',
        params: {
          controller:'password'
        }
      },
      timeRemaining: {
        method: 'GET',
        params: {
          controller:'time-remaining'
        }
      },
      extendSession: {
        method: 'POST',
        params: {
          controller:'extend-session'
        }
      },
      getNearByUsers: {
        method: 'POST',
        isArray: true,
        params: {
          controller: 'get-nearby-users'
        }
      },
      toggleAccess: {
        method: 'POST',
        params: {
          controller: 'toggle-access'
        }
      },
      disableUser: {
        method: 'POST',
        params: {
          controller: 'disable-user'
        }
      },
      sendMailToRole: {
        method: 'POST',
        params: {
          controller: 'send-to-role-user'
        }
      },
      getUserDevices: {
        method: 'POST',
        params: {
          clientId: '@clientId',
          controller: 'get-user-devices'
        }
      },
      deleteUserDevices: {
        method: 'DELETE',
        params: {
          clientId: '@clientId',
          controller: 'delete-user-devices'
        }
      },
	  });
  } 
 

// Dependency Injection
UserFactory.$inject = ['$resource', 'appConfig'];
