


'use strict';

export const ClientTaskFactory  = function ($resource, appConfig) {
  return $resource(appConfig.apiUrl + '/api/client-tasks/:id/:controller', {
    id: '@_id'
  },
  {
    clientTaskCreeate: {
      method: 'POST',
      params: {
        controller: 'create',
        id: '@id'
      }
    },
    clientCollections: {
      isArray: true,
      method: 'GET',
      params: {
        controller: 'collections'
        // id: 'clientId'
      }
    },
    clientCollectionTasks: {
      isArray: true,
      method: 'GET',
      params: {
        controller: 'tasks',
        // id: 'collectionId'
      }
    },
    filterTasks: {
      isArray: false,
      method: 'POST',
      params: {
        controller: 'filter-tasks',
        id: '@id'
        // id: 'collectionId'
      }
    },
    clientTaskResolve: {
      method: 'POST',
      params: {
        controller: 'task-resolve',
        id: '@id'
      }
    },
    clientTaskAssign: {
      method: 'POST',
      params: {
        controller: 'task-assign',
        id: '@id'
      }
    },
    clientTaskForward: {
      method: 'POST',
      params: {
        controller: 'task-forward',
        // id: 'taskId'
      }
    },
    forwardTaskEmail: {
      method: 'POST',
      params: {
        controller: 'forward-email',
        id: '@id'
      }
    },
    clientTaskArchive: {
      method: 'POST',
      params: {
        controller: 'task-archive',
      }
    },

    updateTitle: {
      method: 'POST',
      params: {
        controller: 'update-title'
      }
    },
    updateDesc: {
      method: 'POST',
      params: {
        controller: 'update-desc'
      }
    },
    updateDue: {
      method: 'POST',
      params: {
        controller: 'update-due'
      }
    },
    updatePriority: {
      method: 'POST',
      params: {
        controller: 'update-priority'
      }
    },
    updateFallbackDue: {
      method: 'POST',
      params: {
        controller: 'update-fallback-due'
      }
    },
    updateFallbackPriority: {
      method: 'POST',
      params: {
        controller: 'update-fallback-priority'
      }
    },
    updateLocation: {
      method: 'POST',
      params: {
        controller: 'update-location'
      }
    },
    updateCollection: {
      method: 'POST',
      params: {
        controller: 'update-collection'
      }
    },
    updateAttachments: {
      method: 'POST',
      params: {
        controller: 'update-attachments'
      }
    },
    removeAttachments: {
      method: 'POST',
      params: {
        controller: 'remove-attachments'
      }
    },
    getTaskFromToken: {
      method: 'GET',
      params: {
        controller: 'get-task',
        id: '@id'
      }
    },
    getRecord: {
      method: 'GET',
      params: {
        controller: 'get-record'
      }
    },
    updateExternalTaskStatus: {
      method: 'POST',
      params: {
        controller: 'update-external-task-status',
        id: '@id'
      }
    },
    resolveExternalTask: {
      method: 'POST',
      params: {
        controller: 'resolve-external-task',
        id: '@id'
      }
    },
    clientTaskReopen: {
      method: 'POST',
      params: {
        controller: 'task-reopen',
        id: '@id'
      }
    },
    clientTaskRemove: {
      method: 'POST',
      params: {
        controller: 'task-remove',
        id: '@id'
      }
    },
    assignedTasksCount: {
      method: 'GET',
      params: {
        controller: 'assigned-tasks'
      },
      isArray: true
    },
    taskAnalysis: {
      method: 'GET',
      params: {
        controller: 'task-analysis'
      }
    },
    taskHistory: {
      method: 'GET',
      params: {
        controller: 'task-history'
      },
      isArray: true
    },
    setState: {
      method: 'POST',
      params: {
        controller: 'set-state'
      }
    },
    taskDetail: {
      method: 'GET',
      params: {
        controller: 'detail-task'
      },
    },
    updateTaskdata: {
      method: 'POST',
      params: {
        controller: 'update-taskdata'
      }
    },
    clientCheckInFacility: {
      method: 'POST',
      params: {
        controller: 'check-in-facility',
        id: '@id'
      }
    },
    clientCheckOutFacility: {
      method: 'POST',
      params: {
        controller: 'check-out-facility',
        id: '@id'
      }
    },
    clientJudgeInFacility: {
      method: 'POST',
      params: {
        controller: 'judge-in-facility',
        id: '@id'
      }
    },
    clientJudgeOutFacility: {
      method: 'POST',
      params: {
        controller: 'judge-out-facility',
        id: '@id'
      }
    },
    clientLunchOutInFacility: {
      method: 'POST',
      params: {
        controller: 'lunch-out-in-facility',
        id: '@id'
      }
    },
    earlyFOUTDenyRelease: {
      method: 'POST',
      params: {
        id: '@id',
        controller: 'early-fout-deny-release'
      }
    },
    extendInterpreter: {
      method: 'POST',
      params: {
        id: '@id',
        controller: 'extend-interpreter'
      }
    },
    releaseInterpreter: {
      method: 'POST',
      params: {
        id: '@id',
        controller: 'release-interpreter'
      }
    },
    updateCaseData: {
      method: 'POST',
      params: {
        id: '@id',
        controller: 'update-casedata'
      }
    },
    denyFacilityOut: {
      method: 'POST',
      params: {
        id: '@id',
        controller: 'deny-facility-out'
      }
    },
    approveEvent: {
      method: 'POST',
      params: {
        id: '@id',
        controller: 'approve-event'
      }
    },
    disputeEvent: {
      method: 'POST',
      params: {
        id: '@id',
        controller: 'dispute-event'
      }
    },
    icReleaseRequestEvent: {
      method: 'POST',
      params: {
        id: '@id',
        controller: 'ic-release-request-event'
      }
    },
    updateEvents: {
      method: 'POST',
      params: {
        id: '@id',
        controller: 'update-events'
      }
    },
    removeStateActivity: {
      method: 'POST',
      params: {
        id: '@id',
        controller: 'remove-events'
      }
    },
    exportRecords: {
      method: 'GET',
      params: {
        id: '@id'
      }
    },
    workOrderTasksCount: {
      method: 'POST',
      params: {
        controller: 'work-order-tasks-count',
        id: '@id'
      }
    },
    workOrderTasksDetail: {
      method: 'POST',
      params: {
        controller: 'work-order-tasks-detail',
        id: '@id'
      }
    },

    workOrderTaskCOIDetail: {
      method: 'POST',
      params: {
        controller: 'work-order-coi-task-detail',
        id: '@id'
      }
    },
    workOrderTasksFilter: {
      method: 'POST',
      params: {
        controller: 'work-order-tasks-filter',
        id: '@id'
      }
    },
    getTaskHearingServiceTypeFilter: {
      method: 'POST',
      params: {
        controller: 'get-task-hearing-service-type',
        id: '@id'
      }
    },

    assignInterpreter: {
      method: 'POST',
      params: {
        controller: 'assign-interpreter',
        id: '@id'
      }
    },
    updateWODetails: {
      method: 'POST',
      params: {
        controller: 'update-wo-details',
        id: '@id'
      }
    },
    workOrderFilterTasks: {
      method: 'POST',
      params: {
        controller: 'work-order-filter-tasks',
        id: '@id'
      }
    },
    printWorkOrderDetail: {
      method: 'POST',
      params: {
        controller: 'print-work-order-detail',
        id: '@id'
      }
    },
    cancelWO: {
      method: 'POST',
      params: {
        controller: 'cancel-wo',
        id: '@id'
      }
    },
    updateWOStatus: {
      method: 'POST',
      params: {
        controller: 'update-wo-status',
        id: '@id'
      }
    },
    updateWOComment: {
      method: 'POST',
      params: {
        controller: 'update-wo-comment',
        id: '@id'
      }
    },
    updateWOPaymentDetails: {
      method: 'POST',
      params: {
        controller: 'update-wo-payment-details',
        id: '@id'
      }
    },
    getCOITimeReport: {
      method: 'POST',
      params: {
        controller: 'get-coi-time-report',
        id: '@id'
      }
    },
    getTaskAppliedInterpreter: {
      method: 'GET',
      params: {
        controller: 'task-applied-ic'
      }
    },
    ReqCOITimeReportDownload: {
      method: 'POST',
      params: {
        controller: 'req-download-coi-time-report',
        id: '@id'
      }
    }
  });
} 
 

// Dependency Injection
ClientTaskFactory.$inject = ['$resource', 'appConfig'];
