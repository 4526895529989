
'use strict';

export const $clientSurveyService  = function (ClientSurvey, $http, appConfig) {
    return {
      listAll: function(client, region, manager, office) {
        return ClientSurvey.query({
          id: client._id || client,
          region:  region   || false,
          manager: manager  || false,
          office:  office   || false
        }).$promise;
      },

      resolve: function(clientId, surveyId, message, markClosed) {
        return $http.post(appConfig.apiUrl + '/api/client-surveys/'+clientId+'/'+surveyId + '/resolve', {
          message: message,
          markClosed: markClosed
        });
      },

      forward: function(clientId, surveyId, forwardParams) {
        return $http.post(appConfig.apiUrl + '/api/client-surveys/'+clientId+'/'+surveyId + '/forward', forwardParams);
      }
    };
  }
// Dependency Injection


// Dependency Injection
$clientSurveyService.$inject = ['ClientSurvey', '$http', 'appConfig'];
