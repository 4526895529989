import './roles.scss';
'use strict';


export const formRolesDirective = function (Modal, $rootScope, $q, $auth, $clientSwitcher, $clientPermissions, $roleTypes) {
    return {
      restrict: 'E',
      templateUrl: 'components/forms/roles.html',
      scope: {
        roles: '=ngModel',
        onSubmit: '=formOnSubmit',
        onReset: '=formOnReset'
      },
      link: function (scope, el, attrs) {
        scope.models = {
          selected: null,
          lists: { "allGroupPermissions": [], "assignPermissions": [] },
          loading: true
        };
        scope.clientSettings = function () { return $clientSwitcher.getFeatureOptions('client.settings'); };
        scope.allPermissions = [];
        scope.roleTypes = $roleTypes;
        scope.strSettings = function (str) {
          return {
            selectedStr: ' ',
            noSelection: 'No ' + str + ' Selected',
            allLabel: 'All ' + str
          };
        };

        scope.handleChange = function () {
          scope.models.lists.allGroupPermissions = [];
          if (!scope.roles.isCustomRole) {
            scope.roles.permissions = [];
            scope.models.lists.assignPermissions = [];
            scope.roles.allGroupPermissions = [];
          } else {
            init()
          }
        }

        function init() {
          $clientPermissions.getPermissions($clientSwitcher.getCurrentClient()).then(data => {
            // scope.allPermissions = data.data.map(x => ({label: `${x.code} | ${x.name}`, value: x._id}))

            scope.models.lists.allGroupPermissions = {
              allowType: ['allParents'],
              permissions: [] //_.chain(data.data).groupBy("type").map((value, key) => ({ parent: key, child: value, type:'allParents'})).value()
            };

            scope.models.lists.assignPermissions = {
              allowType: ['allParents'],
              permissions: []
            }
            scope.roles.allGroupPermissions = scope.roles.allGroupPermissions || [];
            scope.roles.permissions = scope.roles.permissions ? scope.roles.permissions : [];
            if (data.data && data.data.length) {
              let allPermissions = [], assignPermissions = []
              data.data.map((obj, index) => {
                if ((scope.roles.permissions).indexOf(obj._id) >= 0) {
                  // scope.models.lists.assignPermissions.push(obj)
                  assignPermissions.push(obj)
                } else {
                  allPermissions.push(obj)
                  scope.allPermissionCount = data.data.length || 0;
                  // scope.models.lists.allPermissions.push(obj)
                }

                if (index + 1 == data.data.length) {
                  scope.models.lists.allGroupPermissions.permissions = _.chain(allPermissions).groupBy("type").map((value, key) =>
                    ({ parent: key, child: value, type: 'allParents', allowType: ['allParents', key] })
                  ).value();
                  scope.models.lists.assignPermissions.permissions = _.chain(assignPermissions).groupBy("type").map((value, key) => ({ parent: key, child: value, type: 'allParents', allowType: ['allParents', key] })).value();
                  scope.models.loading = false;
                }
              });
              scope.updateScope();
            }
          });
        }

        scope.updateScope = function () {
          scope.roles.permissions = [];
          scope.roles.allGroupPermissions = [];
          if (scope.models.lists.assignPermissions.permissions && scope.models.lists.assignPermissions.permissions.length) {
            (scope.models.lists.assignPermissions.permissions).map((obj) => {
              if (obj.child && obj.child.length) {
                obj.child = _.sortBy(obj.child, ['name'])
                obj.child.map(childObj => {
                  scope.roles.permissions.push(childObj._id)
                })
              }
            })
            scope.models.lists.assignPermissions.permissions = _.sortBy(scope.models.lists.assignPermissions.permissions, ['parent']);
          }
          if (scope.models.lists.allGroupPermissions.permissions && scope.models.lists.allGroupPermissions.permissions.length) {
            (scope.models.lists.allGroupPermissions.permissions).map((obj) => {
              if (obj.child && obj.child.length) {
                obj.child = _.sortBy(obj.child, ['name'])
                obj.child.map(childObj => {
                  scope.roles.allGroupPermissions.push(childObj._id)
                })
              }
            })
            scope.models.lists.allGroupPermissions.permissions = _.sortBy(scope.models.lists.allGroupPermissions.permissions, ['parent']);
          }
        }
        scope.resetUi = function () {
          scope.roles.permissions = [];
          scope.models.lists.assignPermissions = [];
          scope.models.lists.allGroupPermissions = [];
          init()
        }

        scope.setAccordion = function ($event, item) {
          if ($event) {
            var panel = $event.target.lastElementChild;
            if (!panel) panel = $event.currentTarget.lastElementChild;
            if (panel) {
              $event.currentTarget.classList.toggle("active");
              if (panel.style.display === "block") {
                panel.style.display = "none";
              } else {
                panel.style.display = "block";
              }
            }
          }
          if (item) {
            item.expand = !item.expand
          }
        }

        scope.dragChildStart = function (childObj, listName, childIndex, item) {
          // all to assign
          if (listName === 'allGroupPermissions') {
            let isTypeExist = _.findIndex(scope.models.lists.assignPermissions.permissions, function (o) { return o.parent == childObj.type; });
            let index = _.findIndex(scope.models.lists.allGroupPermissions.permissions, function (o) { return o.parent == childObj.type; });
            if (isTypeExist <= -1) {
              //create
              let itemIndex = _.findIndex(scope.models.lists.allGroupPermissions.permissions[index].child, function (item) { return item.code == childObj.code })
              scope.models.lists.assignPermissions.permissions.push({
                parent: scope.models.lists.allGroupPermissions.permissions[index].child[itemIndex].type,
                child: [scope.models.lists.allGroupPermissions.permissions[index].child[itemIndex]],
                type: 'allParents',
                allowType: ['allParents', scope.models.lists.allGroupPermissions.permissions[index].child[itemIndex].type]
              });
              if (itemIndex >= 0) scope.models.lists.allGroupPermissions.permissions[index].child.splice(itemIndex, 1);
            } else {
              if (scope.models.lists.allGroupPermissions.permissions[index].child.length === 0) {
                scope.models.lists.allGroupPermissions.permissions.splice(index, 1);
              } else if (isTypeExist >= 0 && index >= 0) {
                childIndex = _.findIndex(scope.models.lists.allGroupPermissions.permissions[index].child, function (item) { return item.code == childObj.code })
                scope.models.lists.allGroupPermissions.permissions[index].child.splice(childIndex, 1);
                scope.models.lists.assignPermissions.permissions[isTypeExist].child.push(childObj);
                scope.models.lists.assignPermissions.permissions[isTypeExist].child = _.uniq(scope.models.lists.assignPermissions.permissions[isTypeExist].child, 'code')
                if (!scope.models.lists.allGroupPermissions.permissions[index].child.length) {
                  scope.models.lists.allGroupPermissions.permissions.splice(index, 1);
                }
              }
            }
          } else { //assign to all
            let isTypeExist = _.findIndex(scope.models.lists.allGroupPermissions.permissions, function (o) { return o.parent == childObj.type; });
            let index = _.findIndex(scope.models.lists.assignPermissions.permissions, function (o) { return o.parent == childObj.type; });
            if (isTypeExist <= -1) {
              //create
              let itemIndex = _.findIndex(scope.models.lists.assignPermissions.permissions[index].child, function (item) { return item.code == childObj.code })
              scope.models.lists.allGroupPermissions.permissions.push({
                parent: scope.models.lists.assignPermissions.permissions[index].child[itemIndex].type,
                child: [scope.models.lists.assignPermissions.permissions[index].child[itemIndex]],
                type: 'allParents',
                allowType: ['allParents', scope.models.lists.assignPermissions.permissions[index].child[itemIndex].type]
              });
              if (itemIndex >= 0) scope.models.lists.assignPermissions.permissions[index].child.splice(itemIndex, 1);
            } else {
              //check length and delete if 0
              if (scope.models.lists.assignPermissions.permissions[index].child.length === 0) {
                scope.models.lists.assignPermissions.permissions.splice(index, 1);
              } else if (isTypeExist >= 0 && index >= 0) {
                childIndex = _.findIndex(scope.models.lists.assignPermissions.permissions[index].child, function (item) { return item.code == childObj.code })
                scope.models.lists.assignPermissions.permissions[index].child.splice(childIndex, 1);
                scope.models.lists.allGroupPermissions.permissions[isTypeExist].child.push(childObj);
                scope.models.lists.allGroupPermissions.permissions[isTypeExist].child = _.uniq(scope.models.lists.allGroupPermissions.permissions[isTypeExist].child, 'code')
                if (!scope.models.lists.assignPermissions.permissions[index].child.length) {
                  scope.models.lists.assignPermissions.permissions.splice(index, 1);
                }
              }
            }
          }
          scope.updateScope();
        }

        scope.dragParentEnd = function (parentObj, listName, parentIndex) {
          // all to assign
          if (listName === 'allGroupPermissions') {
            let isTypeExist = _.findIndex(scope.models.lists.assignPermissions.permissions, function (o) { return o.parent == parentObj.parent; });
            if (isTypeExist <= -1 && scope.models.lists.assignPermissions.permissions.length <= 0) {
              // Create
              // scope.models.lists.assignPermissions.permissions.push(parentObj);
              // // scope.models.lists.allGroupPermissions.permissions = _.sortBy(scope.models.lists.allGroupPermissions.permissions, ['parent']);
              // let itemIndex = _.findIndex(scope.models.lists.allGroupPermissions.permissions, function(item) { return item.parent == parentObj.parent  })
              // scope.models.lists.allGroupPermissions.permissions.splice(itemIndex, 1);
            } else {
              // Update
              let assignPermissions = [];
              scope.models.lists.assignPermissions.permissions.forEach((item) => {
                var existing = assignPermissions.filter(function (v, i) {
                  return v.parent == item.parent;
                });
                if (existing.length) {
                  var existingIndex = assignPermissions.indexOf(existing[0]);
                  assignPermissions[existingIndex].child = assignPermissions[existingIndex].child.concat(item.child);
                } else {
                  if (typeof item.child == 'string')
                    item.child = [item.child];
                  assignPermissions.push(item);
                }
              })
              scope.models.lists.assignPermissions.permissions = assignPermissions;
            }
          } else {
            let isTypeExist = _.findIndex(scope.models.lists.allGroupPermissions.permissions, function (o) { return o.parent == parentObj.parent; });
            if (isTypeExist <= -1 && scope.models.lists.allGroupPermissions.permissions.length <= 0) {
              // Create
              // scope.models.lists.allGroupPermissions.permissions.push(parentObj);
              // let itemIndex = _.findIndex(scope.models.lists.assignPermissions.permissions, function(item) { return item.parent == parentObj.parent  })
              // scope.models.lists.assignPermissions.permissions.splice(itemIndex, 1);
            } else {
              // Update
              let assignPermissions = [];
              scope.models.lists.allGroupPermissions.permissions.forEach((item) => {
                var existing = assignPermissions.filter(function (v, i) {
                  return v.parent == item.parent;
                });
                if (existing.length) {
                  var existingIndex = assignPermissions.indexOf(existing[0]);
                  assignPermissions[existingIndex].child = assignPermissions[existingIndex].child.concat(item.child);
                } else {
                  if (typeof item.child == 'string')
                    item.child = [item.child];
                  assignPermissions.push(item);
                }
              })
              scope.models.lists.allGroupPermissions.permissions = assignPermissions;
            }
          }
          scope.updateScope();
        }

        init()
      }
    };
  }
// Dependency Injection
formRolesDirective.$inject = ["Modal","$rootScope","$q","$auth","$clientSwitcher","$clientPermissions","$roleTypes"];
