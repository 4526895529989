'use strict';


export const featureTabsDirective = function ($rootScope, $state, $auth, $clientSwitcher) {

    function reloadTabs (scope) {
      var
      featureTabs = [{
        title: 'Home',
        state: 'app.main'
      },{
        'abstract': true,
        title: 'Dashboard',
        state: 'app.account.dashboard',
        show:  $auth.isLoggedIn.bind($auth)
      }];

      Array.prototype.push.apply(featureTabs, $clientSwitcher.getFeatureTabs()
        .map(function (feature) {
          // console.log(feature);
          return {
            useIncludes: true,
            state: feature.state,
            title: feature.title,
            disabled: !!feature.disabled
          };
        }));

      scope.featureTabs = featureTabs;
    }

    return {
      restrict: 'E',
      templateUrl: 'components/feature/tpls/feature-tabs.tpl.html',
      scope: {
        ctrl: '=clientSwitch'
      },
      link: function (scope, el, attrs) {
        reloadTabs(scope);

        scope.stateIncludes = $state.includes.bind($state);
        scope.stateEquals = function (state) {
          return !!$state.current && $state.current.name === state;
        };

        scope.canShowTab = function(tab) {
          var
          can = !!tab.state;

          if(can && !!tab.ability) {
            can = $clientSwitcher.hasAbility(tab.ability);
          }
          if(can && !!tab.show) {
            can = !!tab.show;

            if(can && angular.isFunction(tab.show)) {
              can = tab.show();
            }
          }

          return can;
        };

        var killCsLoad, killCsSwitch, killCsReset;

        $clientSwitcher.whenLoaded(function (all, current) {
          reloadTabs(scope);

          killCsReset = $rootScope.$on('client-switcher-reset', function (evt, all, current) { // listen for reloads too
            reloadTabs(scope);
          });

          killCsLoad = $rootScope.$on('client-switcher-load', function (evt, all, current) { // listen for reloads too
            reloadTabs(scope);
          });
          killCsSwitch = $rootScope.$on('client-switcher-switch', function (evt, client) {
            reloadTabs(scope);
          });
        });

        scope.$on('$destroy', function(){
          killCsLoad();
          killCsSwitch();
          killCsReset();
        });
      }
    };
  }
// Dependency Injection
featureTabsDirective.$inject = ["$rootScope","$state","$auth","$clientSwitcher"];
