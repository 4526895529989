
'use strict';

export const $clientCampaignService  = function (ClientCampaign) {
    return {
      listAll: function(client, includeOffices) {
        return ClientCampaign.query({
          id: client._id || client,
          includeOffices: !!includeOffices ? 1 : 0
        }).$promise;
      },

      detail: function(clientId, campaignId) {
        return ClientCampaign.detail({
          id: clientId._id||clientId,
          campaignId: campaignId
        }).$promise;
      }
    };
  }
// Dependency Injection


// Dependency Injection
$clientCampaignService.$inject = ['ClientCampaign'];
