
'use strict';

export const AllTasksBucketFactory  = function (TaskBucket) {
  function AllTasksBucket(opts) {
    TaskBucket.call(this, opts);
    this.isAllTasks = true;
  }

  AllTasksBucket.prototype = Object.create(TaskBucket.prototype);
  AllTasksBucket.prototype.constructor = AllTasksBucket;

  return AllTasksBucket;
}
 

// Dependency Injection
AllTasksBucketFactory.$inject = ['TaskBucket'];
