
'use strict';

export const cstDateFilter  = function () {
        return function (val, format) {
            if (val) {
                let date = new Date(val);
                //  let tzDate = new Date(date.getUTCFullYear(), 
                //                    date.getUTCMonth(), 
                //                    date.getUTCDate(),  
                //                    date.getUTCHours(), 
                //                    date.getUTCMinutes(), 
                //                    date.getUTCSeconds());
                if (format) {
                    return moment.utc(date).format(format)
                } else {
                    return moment.utc(date).format('MM/DD/YYYY')
                }
            }
            return val;
        };
    }
// Dependency Injection

