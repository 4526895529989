
'use strict';

export const $judgeInOutValueService  = function () {
  return function (record, type) {
      var taskDetails = record.taskDetails;
      if (taskDetails && taskDetails.caseData && taskDetails.caseData.judgedInOut && taskDetails.caseData.judgedInOut.length > 0) {
          let lastJudgeInOutEvent = taskDetails.caseData.judgedInOut[taskDetails.caseData.judgedInOut.length - 1];
          if (lastJudgeInOutEvent.event == 'Judge IN') {
              if (type == 'judgeIn') {
                  return moment(lastJudgeInOutEvent.date).format('hh:mm A');
              }
          } else if (lastJudgeInOutEvent.event == 'Judge OUT') {
              let lastSecondJudgeInOutEvent = taskDetails.caseData.judgedInOut[taskDetails.caseData.judgedInOut.length - 2];
              if (lastSecondJudgeInOutEvent.event == 'Judge IN') {
                  return moment(lastSecondJudgeInOutEvent.date).format('hh:mm A');
              }
              return moment(lastJudgeInOutEvent.date).format('hh:mm A');
          }
          return 'N/A';
      }
      return 'N/A';
  }

}

