'use strict';

export const programSupportDirective = function () {
        return {
            restrict: 'E',
            templateUrl: 'app/account/dashboard/main-dashboard/program-support/program-support.html',
            scope: {},
            link: function (scope) {

                var init = function () {
                    
                }
                init()
            }
        }
    }