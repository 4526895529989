
'use strict';

export const $performTaskScoreService  = function (SurveyModal) {
  return function(listenAudioCb, transcribeAudioCb, assignCb, resolveCb) {
    return SurveyModal.info.taskScore(listenAudioCb, transcribeAudioCb, assignCb, resolveCb);
  };
}
 

// Dependency Injection
$performTaskScoreService.$inject = ['SurveyModal'];
