
'use strict';

export const $performDueUpdateService  = function ($clientTask, Modal) {
  return function(due, cb) {
    cb = angular.isFunction(cb) ? cb : angular.noop;
    return Modal.alertActions.editDueDate(due, function(scratchPad) {
      cb(scratchPad);
    });
  };
}
 

// Dependency Injection
$performDueUpdateService.$inject = ['$clientTask', 'Modal'];
