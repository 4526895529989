
'use strict';

export const TaskBucketArchivedFactory  = function (TaskBucket) {
  function TaskBucketArchived(opts) {
    TaskBucket.call(this, opts);
    this.isArchived = true;
    this.filterOptions.splice(1, 1);

    this.filterOptions.splice(0, 0, ['archiveDate', 'Archive Date']);
    this.filter = _.merge(this.filter, {by: 'archiveDate'});
  }

  TaskBucketArchived.prototype = Object.create(TaskBucket.prototype);
  TaskBucketArchived.prototype.constructor = TaskBucketArchived;

  return TaskBucketArchived;
}
 

// Dependency Injection
TaskBucketArchivedFactory.$inject = ['TaskBucket'];
