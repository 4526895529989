'use strict';

export const SmsConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard.notificationLogs.sms', {
        url: '/sms',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/notification-logs/sms/sms.html', 'c51ac8ca'); }],
        controller: 'DashboardsmsLogsCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/notification-logs/sms/sms.controller.js', 'DashboardsmsLogsCtrl'); }]
                      },
      });
  }
// Dependency Injection
SmsConfig.$inject = ["$stateProvider"];
