import './account.scss';
'use strict';
import moment from 'moment';
export const AccountConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account', {
        abstract: true,
        data: {
          roles: ['user','admin']
        },
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/account.html', '6ee38dca'); }],
        controller: 'AccountCtrl'
      });
  }
 export const AccountCtrl = function ($scope, $activeClientSettings) {
    $scope.backgroundColor = '';
    $scope.currentYear = moment().year();
    $scope.footerLogo = '/assets/images/coordinate-logo.png';

    $scope.$on('$destroy', $activeClientSettings(function (client, settings) {
      $scope.textColor = { color: settings.textColor };
      $scope.isLoggedIn = !!client;
      if(settings){
        $scope.footerLogo = settings.footerLogo? settings.footerLogo : '/assets/images/coordinate-logo.png';
      }else{
        $scope.footerLogo = '/assets/images/coordinate-logo.png';
      }
    }));

  }
// Dependency Injection
AccountConfig.$inject = ["$stateProvider"];
AccountCtrl.$inject = ["$scope","$activeClientSettings"];
