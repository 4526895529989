import './search-select-pagination.scss';
'use strict';
export const searchSelectPaginationDirective = function ($rootScope, $timeout) {

        const
            DEFAULT_ALL_ITEMS = 'All Items',
            DEFAULT_SELECTED_ITEMS = 'Selected: ',
            DEFAULT_NO_SELECTION = 'Nothing Selected',
            DEFAULT_ITEM_DELIMITER = ', ';

        return {
            require: 'ngModel',
            restrict: 'E',
            replace: true,
            templateUrl: 'components/search-select-pagination/search-select-pagination.html',

            scope: {
                modelValue: '=ngModel',
                options: '=',
                onscroll: "&",
                pageLimit:'=',
                key: '@',
                search: '=',
                modelChanged: '&ngChange',
                totalCount: '=',
                commonSearchGlag: '=',
                onClick: '&',
                allValue: '=',
                noneSelected: '=',
                selectedPrefix: '=',
                allLabel: '=',
                isLoading: '=',
                labelName: '@',
                hideLabel:'=',
                toggleCount:'=',
                allowSingleSelect:'=',
                showOldSelected:'=',
                showSelectedFirst:'=',
                showFilterAction:'=',
                applyAction:'&',
                resetAction:'&'
            },

            link: function (scope, elem, attrs) {
                let allValues = [];
                let modelLoaded = false;
                let selectedItemLabel = [];
                let selectedItemValues = [];
                scope.currentPage = 1;
                // const pageLimit = 20;
                scope.isExpanded = false;
                scope.searchKey = '';
                scope.enabledItems = null;
                scope.totalSelected = 0;
                scope.toggleSelector = function () {
                    scope.isExpanded = !scope.isExpanded;
                };
                scope.isDisabled = false;
                setTimeout(function () { 
                    allValues = scope.options; 
                    if (!scope.totalCount) {
                        scope.totalCount = 0;
                    }
                }, 3000);
                attrs.$observe('disabled', function (value) {
                    scope.isDisabled = !!value;
                });

                scope.onBlurHandler = function () {
                    $timeout(function () {
                        if (elem[0].contains(document.activeElement)) {
                            if (!scope.search) elem[0].focus();
                        }
                        else {
                            scope.isExpanded = false;
                        }
                    });
                };

                scope._onBlurHandler = function () {
                    $timeout(function () {
                        elem[0].focus();
                    });
                };

                var notifyChange = function () {
                    $timeout(function () {
                        scope.modelChanged();
                    });
                };

                scope.searchBy = function (searchText) {
                    if (searchText.length === 0 || searchText.length >= 1) {
                        if (allValues && allValues.length === 0) {
                            allValues = scope.options;
                        }
                        scope.onscroll({
                            searchKey: searchText,
                            offset: 1
                        });
                    }
                }
                function pushModel() {
                    if (!modelLoaded || !scope.enabledItems) return;
                    if (!!scope.allValue && scope.enabledItems[scope.allValue]) { // if all value is enabled:
                        scope.modelValue = scope.allValue;
                        notifyChange();
                        return;
                    }
                    scope.modelValue = Object.keys(scope.enabledItems)
                        .reduce(function (p, c) {
                            if (scope.enabledItems[c]) {
                                p.push(c);
                                if (selectedItemValues.indexOf(c) == -1) {
                                    selectedItemValues.push(c)                   
                                    let lbl = _.filter(scope.options, (a) => a.value == c);
                                    if(lbl && lbl.length) {
                                        selectedItemLabel.push(lbl[0].label);
                                        selectedItemLabel = (_.uniq(selectedItemLabel)).sort()
                                    }
                                }
                            } else {
                                let vIndex = selectedItemValues.indexOf(c);
                                let keyIndex = _.findIndex(selectedItemLabel, (str) => {
                                    str = str.toString()
                                    return str.includes(c);
                                  });
                                
                               if (keyIndex >= 0) {
                                   selectedItemLabel.splice(keyIndex, 1);
                               }
                               if (vIndex >= 0) {
                                   selectedItemValues.splice(vIndex, 1);
                               }
                            }
                            return p;
                        }, []);
                    notifyChange();
                }

                function pullModel() {
                    scope.enabledItems = {}; // reset enabled items
                    let isModelArray = angular.isArray(scope.modelValue);
                    if (!!scope.allValue && (scope.modelValue === scope.allValue
                        || (isModelArray && scope.modelValue.indexOf(scope.allValue) > -1))) { // all is enabled:
                        scope.enabledItems[scope.allValue] = true;
                        modelLoaded = true;
                        return;
                    }
                    if (angular.isArray(scope.modelValue)) {
                        scope.modelValue
                            .forEach(function (item) {
                                scope.enabledItems[item] = true;
                            });
                    }
                    else if (angular.isString(scope.modelValue) || angular.isNumber(scope.modelValue)) {
                        scope.enabledItems[scope.modelValue] = true;
                    }
                    modelLoaded = (scope.modelValue !== undefined);
                }
                scope.supportAllValue = function () {
                    return !!scope.allValue;
                };

                scope.isSelectedAll = function () {
                    return scope.supportAllValue() && scope.enabledItems[scope.allValue];
                };

                scope.itemIsActive = function (item) {
                    return !!scope.enabledItems[item.value];
                };

                scope.itemDisabled = function (item) {
                    if (scope.isSelectedAll()) return true;
                    return false;
                };

                function wrapTag(content, tag) {
                    return '<' + tag + '>' + content + '</' + tag + '>';
                }

                function itemExists(value) {
                    let valueId = (value || {}).value || value;
                    return !(scope.options || []).every(function (itm) {
                        return itm.value !== valueId;
                    });
                }

                scope.itemVisible = function (item) {
                    if (!itemExists(item)) return false;
                    if (angular.isFunction(item.showIf)) {
                        return !!item.showIf.call(this, item);
                    }
                    return !item.hidden;
                };

                scope.currentLabel = function () {
                    let
                        tag = 'span',
                        allItems = scope.options || [],
                        allLabel = scope.allLabel || DEFAULT_ALL_ITEMS,
                        itemDelim = scope.itemDelimiter || DEFAULT_ITEM_DELIMITER,
                        selPrefix = scope.selectedPrefix || DEFAULT_SELECTED_ITEMS,
                        noSelection = scope.noneSelected || DEFAULT_NO_SELECTION,
                        items = scope.enabledItems || {},
                        label;
                        scope.totalSelected = 0;
                    if (!!scope.allValue && items[scope.allValue]) {
                        selectedItemLabel = [];
                        selectedItemValues = [];
                        label = wrapTag(allLabel, tag);
                        scope.totalSelected = scope.totalCount;
                    }
                    else {
                        let
                            indexed = allItems.reduce(function (p, c) {
                                p[c.value] = c;
                                return p;
                            }, {}),
                            itemStr = Object.keys(items)
                                .reduce(function (p, c) {
                                    if (!!indexed[c] && scope.itemVisible(indexed[c])) {
                                        p.push(indexed[c].label);
                                    }
                                    scope.totalSelected = p.length || 0;
                                    return p;
                                }, []).join(itemDelim);
                        if (itemStr) {
                            // if (selectedItemLabel.length && scope.showOldSelected) { 
                            //     label = wrapTag(selPrefix, tag) + selectedItemLabel.join(', ');
                            // } else {
                                label = wrapTag(selPrefix, tag) + itemStr;
                            // }
                        }
                        else {
                            // console.log('lbl', scope.enabledItems)
                            // if (selectedItemLabel.length && scope.showOldSelected) {  
                            //     // let a = selectedItemLabel.map(item => item.label)
                            //     label = wrapTag(selectedItemLabel.toString(), tag);
                            // } else {
                                label = wrapTag(noSelection, tag);
                            // }
                        }
                    }
                    return label;
                }

                scope.itemClicked = function (item) {
                    if (angular.isArray(scope.modelValue) && scope.modelValue.length == 0) {
                        scope.modelValue = null;
                    } else {
                        scope.modelValue = item.value === scope.modelValue ? null : item.value;
                    }
                    scope.isExpanded = false;
                    $timeout(function () {
                        scope.modelChanged();
                    });
                };

                scope.isValid = function () {
                    return !!scope.required ? (!!scope.modelValue) : true;
                };
                
                scope.$watch('enabledItems', pushModel, true);
                scope.$watch('modelValue', pullModel, true);

                scope.sortShowFirst = function(item) {
                    let selectedVal = (scope.enabledItems && Object.keys(scope.enabledItems).length) ? Object.keys(scope.enabledItems) : [];
                   if (scope.options && scope.options.length && selectedVal.length && scope.showSelectedFirst) {
                           let isSelected = selectedVal.findIndex(ci => String(ci) === String(item.value))
                           return (isSelected >= 0) ? -1 : 1;
                    } else {
                       return -1;
                   }
                }
                scope._resetAction = function () {
                    if (scope.modelValue && scope.modelValue.length) {
                        scope.modelValue = scope.allowSingleSelect ? null : [];
                        $timeout(function () {
                            scope.applyAction();
                            scope.toggleSelector();
                        }, 500)
                    }
                }
                scope._applyAction = function () {
                    scope.applyAction();
                    scope.toggleSelector();
                }

                $timeout(function () {
                    // console.log('----', angular.element(document.querySelector('.qwerty')))
                    // angular.element(document.querySelector('.qwerty')).bind('scroll', function (e) {
                    elem.find('.bind-cst-scroll').bind('scroll', function (e) {
                        var currentItem = $(this);
                        if (currentItem.scrollTop() + currentItem.innerHeight() >= currentItem[0].scrollHeight) {
                            if (scope.totalCount !== scope.options.length && !scope.isLoading) {
                                scope.onscroll({
                                    searchKey: scope.searchKey || '',
                                    offset: scope.pageLimit ? Math.round((scope.options.length / scope.pageLimit) + 1): scope.currentPage + 1
                                });
                                scope.currentPage += 1;
                            }

                        }
                    })
                }, 200)

            }
        }
    }
// Dependency Injection
searchSelectPaginationDirective.$inject = ["$rootScope","$timeout"];
