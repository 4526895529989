
'use strict';

export const $clientLanguagesService  = function (ClientLanguages) {
    return {
      update: function (client, data) {
        return ClientLanguages.update({
          _id: client._id || client,
          data: {
            languageId: data._id,
            name: data.name,
            isEnabled: data.isEnabled,
            code: data.code,
            description: data.description,
            latin: data.latin,
            type: data.type,
            type1: data.type1,
            type2: data.type2,
            programDetail: data.programDetail
          }
        }).$promise;
      },
      create: function (client, data) {
        return ClientLanguages.create({
          _id: client._id || client,
          name: data.name,
          isEnabled: data.isEnabled,
          code: data.code,
          description: data.description,
          latin: data.latin,
          type: data.type,
          type1: data.type1,
          type2: data.type2,
          programDetail: data.programDetail
        }).$promise;
      },
      getLanguages: function (client) {
        return ClientLanguages.getLanguages({
          id: client._id || client
        }).$promise;
      },
      getLanguageById: function (client, languageId) {
        return ClientLanguages.getLanguageById({
          id: client._id || client,
          controller: languageId
        }).$promise;
      },
      getLanguagesList: function (client, paginationData, tasksOnly) {
        return ClientLanguages.getLanguagesList({
          id: client._id || client,
          page: paginationData && paginationData.page,
          pageSize: paginationData && paginationData.pageSize,
          searchKey: paginationData && paginationData.searchKey,
          orderByValue: paginationData && paginationData.orderByValue,
          orderByField: paginationData && paginationData.orderByField,
          tasksOnly: tasksOnly,
          activeTotalLang: paginationData.activeTotalLang || false,
          isLangEnabled: paginationData.isLangEnabled || false,
          type: paginationData.type || undefined,
          isSyncOnly: paginationData.isSyncOnly || false
        }).$promise;
      },
      getCategoryByLangProgram: function (client, data) {
        return ClientLanguages.getProgramLangCategory({
          id: client._id || client,
          filter: data
        }).$promise;
      }
    };
  }

  

// Dependency Injection
$clientLanguagesService.$inject = ['ClientLanguages'];
