import './tasks.scss';
'use strict';

export const TasksConfig = function ($stateProvider) {
      $stateProvider
      .state('app.account.dashboard.tasks', {
        url: '/work-orders?tab&action&work_ordersk&attachment',
        params: {
          action: null,
          work_orders: null,
          attachment: null
        },
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/tasks/tasks.html', '46f7a84a'); }],
        controller: 'DashboardTasksCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/tasks/tasks.controller.js', 'DashboardTasksCtrl'); }]
                      },
        data: {
          ability: 'client.tasks',
          permission: 'TASKS_READ'
        }
      });

      // $stateProvider
      // .state('app.account.dashboard.tasks', {
      //   url: '/tasks?action&task&attachment',
      //   params: {
      //     action: null,
      //     task: null,
      //     attachment: null
      //   },
      //   templateProvider: ['$q', '$templateCache', function($q, $templateCache) {
              //     var cacheName = 'tasks_rule_window_testTemplate';
              //     return $q((resolve) => {
              //         if ($templateCache.get(cacheName)) {
              //             resolve($templateCache.get(cacheName));
              //         } else {
              //             import('./rule-window-test.html').then(template => {
              //                 $templateCache.put(cacheName, template.default);
              //                 resolve(template.default);
              //             });
              //         }
              //     });
              // }],
      //   controller: 'RuleWindowTestCtrl',
      //   data: {
      //     ability: 'client.tasks'
      //   }
      // });
  }
// Dependency Injection
TasksConfig.$inject = ["$stateProvider"];
