


'use strict';

export const PatientContactsFactory  = function ($resource, appConfig) {
        return $resource(appConfig.apiUrl + '/api/patient-contact/:id/:controller', {
            id: '@_id'
        },
            {
                getPatientContacts: {
                    method: 'GET',
                    params: {
                        id: '@id'
                    }
                }
            });
    } 
 

// Dependency Injection
PatientContactsFactory.$inject = ['$resource', 'appConfig'];
