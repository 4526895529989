'use strict';


export const formRegionDirective = function ($rootScope, $q, $auth, $clientSwitcher, $clientRegion, $clientDivision) {
    return {
      restrict: 'E',
      templateUrl: 'components/forms/region.html',
      scope: {
        region: '=ngModel',
        onSubmit: '=formOnSubmit',
        onReset: '=formOnReset'
      },
      link: function(scope, el, attrs) {
        scope.clientSettings = function(){ return $clientSwitcher.getFeatureOptions('client.settings'); };
        scope.divisions = [];

        function loadDivisions(cli) {
          scope.divisions.splice(0, scope.divisions.length);
          return $clientDivision.listAll(cli)
            .then(function (divisions) {
              scope.divisions = divisions.map(function(division) {
                return {
                  label: division.name,
                  value: division._id
                };
              });
            });
        }

        function loadClientInfo(cli) {
          if(scope.loadingInfo || !cli) return; // ignore any parallel requests
          scope.loadingInfo = true;

          return $q.all([
              loadDivisions(cli)
            ])
            .catch(function (err) {
              scope.loadingError = err;
              return err;
            })
            .finally(function() {
              scope.loadingInfo = false;
            });
        }

        var killCsLoad, killCsSwitch;

        scope.$on('$destroy', function(){
          killCsLoad();
          killCsSwitch();
        });

        $clientSwitcher.whenLoaded(function (all, current) {
          loadClientInfo(current);

          killCsLoad = $rootScope.$on('client-switcher-load', function (evt, all, current) { // listen for reloads too
            loadClientInfo(current);
          });
          killCsSwitch = $rootScope.$on('client-switcher-switch', function (evt, client) {
            loadClientInfo(client);
          });
        });
      }
    };
  }
// Dependency Injection
formRegionDirective.$inject = ["$rootScope","$q","$auth","$clientSwitcher","$clientRegion","$clientDivision"];
