'use strict';


export const taskControlsDirective = function (TASK_STATE_STARTED, TASK_STATE_PAUSED, $clientSwitcher, $autoTimezoneFormat) {
  return {
    restrict: 'E',
templateUrl: 'components/task-controls/task-controls.html',
    scope: {
      task: '=',
      allowArchive: '=', performArchive: '=',
      allowAssign: '=', performAssign: '=',
      allowResolve: '=', performResolve: '=',
      allowForward: '=',
      allowReopen: '=', performReopen: '=',
      allowTaskHistory: '=', showTaskHistory: '=',
      allowResolveHistory: '=', showResolveHistory: '=',
      allowToggleState: '=', performToggleState: '=',
      allowRemove: '=', performRemove: '=',
      performForwardTaskEmail: '=',
      allowAttachment: '=', performAttachments: '=',
      allowExport: '=', performExport: '=' ,
      allowInterpreterAssign: '=', performInterpreterAssign: '=',
      allowWoUpdateStatus: '=', performWoUpdateStatus: '=',
      allowWithdraw: '=', performWithdraw: '=',
      allowTaskEventAdd:'=',  performAddTask:'=', performExpandWo:"=",
      allowSignApprove:'=', performIcApprove:'=',
      allowRequestToWithdraw: '=', performRequestToWithdraw: '=',
    },
    link: function (scope, el, attrs) {
        scope.TASK_STATE_STARTED = TASK_STATE_STARTED;
        scope.TASK_STATE_PAUSED = TASK_STATE_PAUSED;
        scope.clientStrings = function(){ return $clientSwitcher.getFeatureOptions('client.strings'); };

        scope.isTaskFOUT = function (task) {
          const isTaskCheckedOut = task.caseData && task.caseData.checkedOut && task.caseData.checkedOut.date
          return isTaskCheckedOut ? true : false
        }

        scope.allowToWithdrawWO = function (wo) {
          let time = wo.caseData.scheduleTime, date = wo.caseData.deldate, courtTimezoneId = wo.location.timezoneid
          let deldateArr = date.split('T');
          if (courtTimezoneId) {
              let tz = $autoTimezoneFormat.getAllDetail(deldateArr[0], time, courtTimezoneId);
              wo.userLocale = tz.userLocale;
              return moment(tz.userLocale.caseDisplayDateTime).isAfter(moment())
          } else {
              return false;
          }

      }
    }
  };
}
// Dependency Injection
taskControlsDirective.$inject = ["TASK_STATE_STARTED","TASK_STATE_PAUSED","$clientSwitcher", "$autoTimezoneFormat"];
