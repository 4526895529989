
'use strict';

export const $getECOITimezoneOffsetService  = function ($timezones) {
  return function (timezone) {
    var index = _.findIndex($timezones, function (x) {
      return x.label == timezone;
    })
    return $timezones[index] ? $timezones[index].offset : moment().format('ZZ');
  }
}
 

// Dependency Injection
$getECOITimezoneOffsetService.$inject = ['$timezones'];
