'use strict';

export const communicationsDirective = function ($auth, $activeClient, $q, $clientSwitcher, $sce, Modal, $clientInterpreterResources, $clientOffice) {
        return {
            restrict: 'E',
            templateUrl: 'app/account/dashboard/main-dashboard/interpreter-resource/tabs/communications/communications.html',
            scope: {
                userPermissions: '=userPermissions',
            },
            link: function (scope) {
                let killClientWatch,
                    currentCli = $clientSwitcher.getCurrentClient(),
                    currentUser = $auth.getCurrentUser();

                scope.isInterpreter = $auth.isInterpreter() || false; // (currentUser.settings.roles.findIndex(x => x.code === 'INTERPRETER') >= 0);
                scope.permissions = {
                    hasWritePermission: scope.userPermissions.includes('IR_COMMUNICATION_CENTER_WRITE'),
                    hasKLWritePermission: scope.userPermissions.includes('IR_KNOWLEDGE_LIBRARY_WRITE'),
                    hasCommCenterRecipientsPermission: scope.userPermissions.includes('IR_COMMUNICATION_CENTER_RECIPIENTS'),
                    hasCommCenterAttachmentPermission: scope.userPermissions.includes('IR_COMMUNICATION_CENTER_ATTACHMENT')
                };
                scope.filter = {
                    interpreters: [],
                    location: []
                }
                scope.model = {
                    stats: 'all',
                    url: $sce.trustAsResourceUrl('https://my.visme.co/projects/y4yn1k1g-z4p5zzd8vz4x57n1#s1'),
                    interpreters: [],
                    location: []
                }
                scope.stats = {
                    all: 'all',
                    me: 'me'
                }
                scope.allValue = 'all';
                scope.typeDataSets = [];
                scope.locationDataSets = [];
                scope.interpretersSets = [];
                scope.communicationCenterMessage = [];
                scope.recipientsInterpreter = [];
                scope.loadingIRData = false;
                scope.interpretersLoaded = false;

                scope.pagination = {
                    currentPage: 1,
                    page: 1,
                    totalCCMessages: 0,
                    pageLimit: 10,
                    itemsPerPage: 10
                };

                scope.getSeperated = (item) => item.map(x => x.name).join(', ')

                scope.ccMessageDataFilter = {};

                let init = function () {
                    killClientWatch = $activeClient(function (current) {
                        if (!current) {
                            return;
                        }

                        currentCli = current;
                        scope.client = current;
                        scope.getCCData();
                        scope.getLocationList()
                    });
                }

                function getInterpreterResourcesData() {
                  if(scope.interpretersLoaded) return;
                    scope.loadingInterpretersData = true;

                    return $clientInterpreterResources.getAllRegisteredInterpreters(currentCli, ['user.name'])
                        .then(function (data) {
                            data.data.map(x => {
                                let findRecipient = scope.recipientsInterpreter.find((obj) => obj._id == x._id)
                                if (findRecipient) {
                                    scope.interpretersSets.push({
                                        label: x.name,
                                        value: x._id
                                    })
                                }
                            });

                            scope.interpretersSets = _.sortBy(scope.interpretersSets, ['label'])
                        })
                        .finally(function () {
                            scope.loadingInterpretersData = false;
                            scope.interpretersLoaded = true;
                        })
                }

                scope.getLocationList = function () {
                    $clientOffice.listAll(currentCli).then(function (data) {
                        _.each(data, function (off) {
                            scope.locationDataSets.push({
                                value: off._id,
                                label: off.name
                            });
                        });
                    })
                }

                scope.hasCCFilterChanged = function (newFilter){
                  return !(JSON.stringify({...newFilter,returnEveryRecipient:undefined}) === JSON.stringify({...scope.ccMessageDataFilter,returnEveryRecipient:undefined}))
                }

                scope.getCCData = function () {
                    if (scope.loadingIRData) return;

                    const returnEveryRecipient = JSON.stringify(scope.ccMessageDataFilter) === '{}';
                    const filter = {
                        type: 'COMM_CENTER_MESSAGE',
                        limit: 10,
                        page: scope.pagination.page,
                        searchValue: scope.model.searchValue,
                        recipients: scope.model.interpreters && scope.model.interpreters.length ? scope.model.interpreters : null,
                        location: scope.model.location && scope.model.location.length ? scope.model.location : null,
                        returnEveryRecipient
                    };

                    if(!scope.hasCCFilterChanged(filter)){
                      return;
                    }

                    scope.loadingIRData = true;
                    $clientInterpreterResources.getCCMessagesData(currentCli, filter)
                        .then(function (data) {
                            scope.communicationCenterMessage = data.data;
                            if(returnEveryRecipient){
                              scope.recipientsInterpreter = data.interpreters || [];
                            }
                            scope.pagination.totalCCMessages = data.numOfMessages;

                            scope.ccMessageDataFilter = filter;
                        })
                        .finally(function () {
                            scope.loadingIRData = false;
                        });
                }

                scope.loadInterpreters = function (){
                  getInterpreterResourcesData();
                }

                scope.createCommunicationCenterMessage = function () {
                    Modal.interpreterResourcesActions.createCommunicationCenterMessage(function (res) {
                        if (res) {
                            // if(scope.pagination.page === 1 ) {
                            //     scope.communicationCenterMessage.unshift(res.data);
                            // }
                            // scope.pagination.totalCCMessages += 1;
                            scope.getCCData();
                        }
                    })();
                };

                scope.editCCMessage = function (ccMessageData, messageIndex) {
                    Modal.interpreterResourcesActions.editCommunicationCenterMessage(ccMessageData, function (res) {
                        if (res) {
                            scope.loadingIRData = true;
                            scope.communicationCenterMessage[messageIndex] = res.data;
                            scope.loadingIRData = false;
                        }
                    })();
                }

                scope.showCommunicationsCenterDetails = function (record, type, index) {
                    Modal.interpreterResourcesActions.showCommunicationsCenterDetails(record, type, function (res) {
                    })();
                }

                scope.deleteCCMessage = function (messageName, messageId, index) {

                    Modal.confirm.show('Confirm Delete', 'Are you sure you want to delete Communication Center Message <strong>' + messageName + '</strong>?', 'Delete', 'Cancel', function () {
                        $clientInterpreterResources.deleteCCMessage(currentCli, messageId)
                            .then(function (data) {
                                scope.loadingIRData = true;
                                scope.communicationCenterMessage = scope.communicationCenterMessage.filter(x => x._id !== messageId);
                                scope.pagination.totalCCMessages -= 1;
                                scope.loadingIRData = false;
                                if ((scope.pagination.totalCCMessages >= scope.pagination.itemsPerPage)) {
                                    scope.pagination.page = 1;
                                    return scope.getCCData();
                                }
                            });
                    });

                }

                scope.$on('$destroy', function () {
                    if (killClientWatch) {
                        killClientWatch();
                    }
                });
                init()
            }
        }
    }
// Dependency Injection
communicationsDirective.$inject = ["$auth","$activeClient","$q","$clientSwitcher","$sce","Modal","$clientInterpreterResources","$clientOffice"];
