'use strict';

export const TimeProductionReportingLogConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard.timeProductionReportingLog', {
        url: '/reporting/log',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/time-production-reporting-log/time-production-reporting-log.html', 'b29a028a'); }],
        controller: 'TimeProductionReportingLogCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/time-production-reporting-log/time-production-reporting-log.controller.js', 'TimeProductionReportingLogCtrl'); }]
                      },
        data: {
          ability: 'client.TimeProductionReportingLog',
          permission: 'TIME_REPORTING_LOG_READ'
        }
      });
  }
// Dependency Injection
TimeProductionReportingLogConfig.$inject = ["$stateProvider"];
