
'use strict';

export const $setGetCognitoUserService  = function () {
        var cognitoUser = null;
        return {
            get: function () { return cognitoUser; },
            set: function (v) { cognitoUser = v; }
        };
    }
 
