


'use strict';

export const PermissionMatrixFactory  = function ($resource, appConfig) {
        return $resource(appConfig.apiUrl + '/api/permission-matrix/:id/:controller', {
            id: '@_id'
        },
            {
                getRoleDetails: {
                    method: 'GET',
                    params: {
                        id: '@id'
                    }
                },
                getUserRolesPermissions: {
                    method: 'GET',
                    params: {
                        id: '@id',
                        controller: 'get-user-role-permissions'
                    }
                },
                getPermissionByPage: {
                    method: 'GET',
                    params: {
                        id: '@id',
                        controller: 'get-page-permissions'
                    }
                },
            });
    } 
 

// Dependency Injection
PermissionMatrixFactory.$inject = ['$resource', 'appConfig'];
