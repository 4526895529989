'use strict';

export const mainCourtOperationsDirective = function ($rootScope, $stateParams, $timeout,  LazyLoadService ) {
                  return {
            restrict: 'E',
            templateUrl: 'app/account/dashboard/main-dashboard/court-operations/main-court-operations.html',
            scope: {
                modelData: '=ngModel',
                childTabs: '=childTabs',
                layoutDefaultTabing: '&layoutDefaultTab'
            },
            link: function(scope) {

  const lazyDirectives = [{"name":"courtOperationsNewDirective","path":import("@app/account/dashboard/main-dashboard/court-operations/tab/court-operations-new/court-operations-new.directive.js")},{"name":"courtOperationsDirective","path":import("@app/account/dashboard/main-dashboard/court-operations/tab/court-operations/court-operations.directive.js")},{"name":"judgeVriCasesDirective","path":import("@app/account/dashboard/main-dashboard/court-operations/tab/judge-vri-cases/judge-vri-cases.directive.js")}];
  scope.lazyDirectivesLoaded = false;
  Promise.all(lazyDirectives.map(directive=>LazyLoadService.loadDirective(directive.path, directive.name))).then(() => {
          $timeout(function() {
            scope.lazyDirectivesLoaded = true;

                         let selectedTab = null;
                scope.courtOpsTabs = [];
                var init = function () {
                    scope.tabs()
                }

                scope.tabSelected = function (tabName) {
                    selectedTab = tabName;
                    $rootScope.$broadcast('onTabChange', selectedTab);
                };

                scope.isTabSelected = function (tabName) {
                    return selectedTab === tabName;
                };

                scope.tabs = function () {
                    let tabs = scope.childTabs;
                    let data = scope.layoutDefaultTabing({obj:{tabs, layoutOf: 'mainCourtOperations'}})
                    scope.courtOpsTabs = data.avtiveTabs;
                    selectedTab = data.selectedTab;
                    if ($stateParams.child) {
                        selectedTab = $stateParams.child;
                      }
                }

                init()

    })
});
}
}
}
//Dependency Injection
mainCourtOperationsDirective.$inject = ["$rootScope","$stateParams", "$timeout",  "LazyLoadService"];
