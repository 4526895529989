'use strict';

export const resolveCodesTableDirective = function ($q, $performTaskAudio, $performTaskScore, $performTaskTranscribeAudio) {
        return {
            restrict: 'E',
            templateUrl: 'app/account/dashboard/tasks/tabs/organization/performance/resolve-codes/resolve-codes.html',
            link: function ($scope) {
                var DEFAULT_PAGINATION = {
                    page: 1,
                    pageSize: 10,
                    totalSize: -1
                };

                $scope.sortByPercent = function(rec){
                    return -rec.percent;
                };

                $scope.resolveCodes = [
                    // { per: "41.1", num: 1, code: "Dissatisfy", reason: "Dissatisfied Error", employee: "Mike Tony 3 | Bill Smith 2" },
                    // { per: "58", num: 5, code: "NFT", reason: "No Trouble Fount", employee: "Jim Jones 2" },
                    // { per: "62.1", num: 7, code: "CustErr", reason: "Customer Error", employee: "Harry Willis 2" },
                    // { per: "10.1", num: 2, code: "Prev Tech", reason: "Previous Technician Issue", employee: "Bill Kay 1" },
                    // { per: "45.1", num: 6, code: "RepeatProb", reason: "Repeat Problem Not Resolved", employee: "Bob Smith" },
                    // { per: "40", num: 2, code: "", reason: "", employee: "" },
                ];
                // console.log("Resolve code - ",$scope.selectedEmployee)

                $scope.resolveCodes = $scope.selectedEmployee.resolves;
                $scope.paginationResolveCode = angular.copy(DEFAULT_PAGINATION);
                $scope.paginationResolveCode.totalSize = $scope.resolveCodes ? $scope.resolveCodes.length : 0;

                // var promise = $scope.selectedEmployee.children.map(function (employee) {
                //     return {
                //         employee: employee.firstName + " " + employee.lastName,
                //         tasks: employee.tasks
                //     }
                // });
                
                // var employeeResolves = [];
                // $q.all(promise)
                // .then(function (result) {
                //     result.forEach(function (res) {
                //         if (res.tasks) {
                //             res.tasks.forEach(function (task) {
                //                 var employeeData = {};                                
                //                 employeeData.employee = res.employee
                //                 if(task.resolve && task.resolve.notes && task.resolve.notes.length > 0){
                //                     employeeData.num = task.resolve.notes.length;
                //                     employeeData.code = task.resolve.notes[task.resolve.notes.length - 1].resolveCode ? task.resolve.notes[task.resolve.notes.length - 1].resolveCode.code : '';
                //                     employeeData.reason = task.resolve.notes[task.resolve.notes.length - 1].message;
                //                 }else{
                //                     employeeData.num = '';
                //                     employeeData.code = '';
                //                     employeeData.reason = '';
                //                 }                                
                //                 employeeResolves.push(employeeData)
                //             });

                //             $scope.resolveCodes = employeeResolves;                        
                //             $scope.paginationResolveCode = angular.copy(DEFAULT_PAGINATION);
                //             $scope.paginationResolveCode.totalSize = $scope.resolveCodes ? $scope.resolveCodes.length : 0;
                //         }
                //     });
                // }); 
            }
        }
    }
// Dependency Injection
resolveCodesTableDirective.$inject = ["$q","$performTaskAudio","$performTaskScore","$performTaskTranscribeAudio"];
