'use strict';


export const formReportBucketDirective = function (Modal, $rootScope, $q, $auth, $clientSwitcher, $activeClient, $clientRoles, $clientPermissions, $defaultDateRangFilter) {
    return {
      restrict: 'E',
templateUrl: 'components/forms/report-bucket.html',
      scope: {
        reportBucket: '=ngModel',
        onSubmit: '=formOnSubmit',
        onReset: '=formOnReset',
        allPermissions: '='
      },
      link: function (scope, el, attrs) {
        var currentCli = $clientSwitcher.getCurrentClient();
        scope.client = currentCli;
        scope.allValue = 'all';
        scope.itemDelimiter = ', ';
        scope.arrRoles = [];
        scope.arrPermissions = [];
        scope.allPermissions = {};
        scope.reportBucket.allPermissions = scope.allPermissions;
        scope.arrRolePermissions = {};
        scope.uniqRoles = [];

        scope.clientSettings = function () { return $clientSwitcher.getFeatureOptions('client.settings'); };
        scope.clientStrings = function () { return $clientSwitcher.getFeatureOptions('client.strings'); };
        scope.timeframes = [
          {
            label: 'Daily',
            value: 'daily'
          },
          {
            label: 'Weekly',
            value: 'Weekly'
          },
          {
            label: 'Monthly',
            value: 'monthly'
          },
          // {
          //     label: 'Yearly',
          //     value: 'yearly'
          // }
        ];
        scope.alignments = [
          {
            label: 'Left',
            value: 'left'
          },
          {
            label: 'Right',
            value: 'right'
          },
          {
            label: 'Full',
            value: 'full'
          }
        ];

        scope.dateRangeArray = $defaultDateRangFilter() || [];
        scope.addSupportedRole = function () {
          scope.reportBucket.supportedRoles.push({
            role: null,
            permissions: []
          });
          scope.getUniqRoles(this.reportBucket.supportedRoles.length)
        }

        scope.getUniqRoles = function (index) {
          scope.uniqRoles[index - 1] = []
          if (index == 1) {
            scope.uniqRoles[index - 1] = scope.arrRoles
          } else {
            scope.uniqRoles[index - 1] = scope.arrRoles.filter(o => {
              return !this.reportBucket.supportedRoles.find(o2 => {
                return o.value == o2.role
              })
            })
          }
        }

        scope.removeSupportedRole = function (index) {
          scope.reportBucket.supportedRoles.splice(index, 1);
        }

        scope.permissionSettings = function () {
          var permissionStr = (scope.clientStrings() || {}).permissionsPCase;
          return {
            selectedStr: 'Selected ' + permissionStr + ': ',
            noSelection: 'No ' + permissionStr + ' Selected',
            allLabel: 'All ' + permissionStr
          };
        };

        scope.roleSettings = function () {
          var roleStr = (scope.clientStrings() || {}).rolePCase;
          return {
            selectedStr: 'Selected ' + roleStr + ': ',
            noSelection: 'No ' + roleStr + ' Selected',
            allLabel: 'All ' + roleStr
          };
        };

        var init = function () {
          $activeClient(function (client) {
            if (!client) {
              return;
            }
            scope.client = client;
            getRoles(client);
          });
        };

        function getRoles(client) {
          let filter = {
            type: 'REPORT_BUCKET'
          };

          $clientRoles.getRolePermissions(client, filter)
            .then(({ roles }) => {
              if (roles && roles.length) {
                scope.arrRoles = roles.map((role) => {
                  scope.allPermissions[role._id] = [];
                  scope.arrRolePermissions[role._id] = role.permissions.map((permission) => {
                    scope.allPermissions[role._id].push(permission._id)
                    permission.label = permission.name;
                    permission.value = permission._id;
                    return permission
                  });
                  return {
                    label: role.name,
                    value: role._id,
                  }
                });
                if (scope.reportBucket._id) {
                  if (scope.reportBucket.supportedRoles && scope.reportBucket.supportedRoles.length) {
                    scope.reportBucket.supportedRoles.map((obj, index) => {
                      scope.uniqRoles[index] = scope.arrRoles
                      if (scope.reportBucket.supportedRoles.length == index + 1) {
                        scope.checkDublicateRole();
                      }
                    })
                  }
                }
              }
            })
        }

        scope.checkDublicateRole = function () {
          scope.duplicateRole = _.filter(
            _.uniq(
              _.map(this.reportBucket.supportedRoles, function (item) {
                if (_.filter(scope.reportBucket.supportedRoles, { role: item.role }).length > 1) {
                  return item.role;
                }
                return false;
              })),
            function (value) { return value; });
        }

        scope.defaultSelectAllPermission = function (supportedRole, permissions) {
          if (supportedRole && supportedRole.permissions && supportedRole.permissions.length > 0 && permissions && permissions.length > 0) {
            if (supportedRole.permissions !== 'all') {
              let assginPermissions = [];
              supportedRole.permissions.forEach((permission) => {
                let checkPermission = _.find(permissions, function (per) {
                  return permission == per._id;
                })
                if (checkPermission) {
                  assginPermissions.push(checkPermission._id)
                }
              })
              supportedRole.permissions = assginPermissions;
            }
          }
          if (supportedRole && supportedRole.permissions && supportedRole.permissions.length == 0 && permissions && permissions.length > 0) {
            supportedRole.permissions = 'all';
          }
        }

        init();

      }
    };
  } 
 export const $defaultDateRangFilterService = function () {
    return function () {
      return [
        {
          label: '14 Day',
          value: '14 Day',
          valueObj: { 14: 'Day' }
        },
        {
          label: '1 Month',
          value: '1 Month',
          valueObj: { 1: 'Month' }
        },
        {
          label: '3 Month',
          value: '3 Month',
          valueObj: { 3: 'Month' }
        },
        {
          label: '6 Month',
          value: '6 Month',
          valueObj: { 6: 'Month' }
        }
      ];
    }
  }
// Dependency Injection
formReportBucketDirective.$inject = ["Modal","$rootScope","$q","$auth","$clientSwitcher","$activeClient","$clientRoles","$clientPermissions","$defaultDateRangFilter"];
