'use strict';

export const woInvoicesDirective = function ($clientSwitcher, $auth, $clientTask, $rootScope, $timeout,  LazyLoadService ) {
                  return {
            restrict: 'E',
            templateUrl: 'app/account/dashboard/main-dashboard/work-orders/tabs/wo-invoices/wo-invoices.html',
            scope: {},
            link: function(scope) {

  const lazyDirectives = [{"name":"bucketsDirective","path":import("@app/account/dashboard/tasks/tabs/buckets/buckets.directive.js")}];
  scope.lazyDirectivesLoaded = false;
  Promise.all(lazyDirectives.map(directive=>LazyLoadService.loadDirective(directive.path, directive.name))).then(() => {
          $timeout(function() {
            scope.lazyDirectivesLoaded = true;

                         const client = $clientSwitcher.getCurrentClient();
                scope.displayBuckets = [];
                scope.isReloading = false;
                function init() {
                    scope.displayBuckets = [];
                    $clientTask.getCollections(client, true)
                        .then(function (col) {
                            let completedBucket = col.filter((obj) => obj.code == "COMPLETED");
                            scope.displayBuckets = completedBucket.map((obj) => {
                                return obj._id
                            });
                        })
                }

                scope.reloadFun = function () {
                    scope.isReloading = true;
                    setTimeout(() => {
                        scope.isReloading = false;
                    }, 0);
                }
                init()

    })
});
}
}
}
//Dependency Injection
woInvoicesDirective.$inject = ["$clientSwitcher","$auth","$clientTask","$rootScope", "$timeout",  "LazyLoadService"];
