'use strict';
// factory
export const AwsService = function ($resource, appConfig) {
        return $resource(appConfig.apiUrl + '/api/aws-service/:id/:controller', {
            id: '@_id'
        },
            {
                getSuppressionList: {
                    method: 'GET',
                    params: {
                        controller: 'get-suppressed-list'
                    }
                },

                removeEmailSuppression: {
                    method: 'DELETE',
                    params: {
                        id: '@id'
                    }
                },
            })

    }


  export const $awsService = function (AwsService) {
      return {
        getSuppressionList: function (client, nextToken) {
          return AwsService.getSuppressionList({
            id: client._id || client,
            NextToken: nextToken || null
          }).$promise;
          },
          removeEmailSuppression: function (client, email) {
            return AwsService.removeEmailSuppression({
              id: client._id || client,
              controller: email
            }).$promise;


          },
        }
      }

  AwsService.$inject = ["$resource", "appConfig"]

  $awsService.$inject = ["AwsService"]
