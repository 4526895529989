
'use strict';

export const $servicePanelStatusService  = function ($filter) {
        return function (requiredKeyValue) {
            let status = ['quote_requested', 'quote_approved', 'work_in_process', 'work_completed', 'work_accepted', 'payment', 'service_completed']
            if (requiredKeyValue) {
                let keyValStatus = [];
                status.map(item => {
                    // let _item = humanizeString;
                    keyValStatus.push({ label: $filter('humanizeString')(item), value: item })
                })
                return keyValStatus
            }
            return status

        }
    } 
 

// Dependency Injection
$servicePanelStatusService.$inject = ['$filter'];
