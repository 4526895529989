


'use strict';

export const ClientFactory  = function ($resource, appConfig) {
    return $resource(appConfig.apiUrl + '/api/clients/:id/:controller', {
        id: '@_id'
      },
      {
        getAbilities: {
          method: 'GET',
          params: {
            controller: 'abilities'
          }
        },
        getRegions: {
          method: 'GET',
          params: {
            controller: 'regions'
          },
          isArray: true
        },
        getDivisions: {
          method: 'GET',
          params: {
            controller: 'divisions'
          },
          isArray: true
        },
        getOffices: {
          method: 'GET',
          params: {
            controller: 'offices'
          },
          isArray: true
        },
        getBuckets: {
          method: 'GET',
          params: {
            controller: 'buckets'
          },
          isArray: true
        },
        getManagers: {
          method: 'GET',
          params: {
            controller: 'managers'
          },
          isArray: true
        },
        getCampaigns: {
          method: 'GET',
          params: {
            controller: 'campaigns'
          },
          isArray: true
        },
        getUserCount: {
          method: 'GET',
          params: {
            controller: 'user-count'
          }
        },
        editClient: {
          method: 'POST',
          params: {
            controller: 'edit'
          }
        },
        clientAbilities: {
          method: 'GET',
          params: {
            controller: 'clientAbilities'
          }
        }
      });
  }
 

// Dependency Injection
ClientFactory.$inject = ['$resource', 'appConfig'];
