
'use strict';

export const $clientNotificationLogsService  = function ($http, ClientNotificationLogs) {
  return {
    get: function (client, notificationType, page, pageSize, search) {
      return ClientNotificationLogs.query({
          id: (client._id||client),
          notificationType: notificationType,
          page: page,
          pageSize: pageSize,
          search: search
        }).$promise;
    },
    getDistinctValues: function (client, notificationType, key) {
      return ClientNotificationLogs.getDistinctValues({
        id: (client._id||client),
        notificationType: notificationType,
        key: key
      }).$promise;
    }
  };
}
// Dependency Injection


// Dependency Injection
$clientNotificationLogsService.$inject = ['$http', 'ClientNotificationLogs'];
