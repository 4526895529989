'use strict';

export const DashboardSettingsPermissionsIndexCtrl = function ($rootScope, $scope, $activeClient, $clientPermissions, $clientPermissionTypes, $auth, DEFAULT_DEBOUNCE) {

    let currentCli = null;
    $scope.loading = false;
    $scope.allowEditPermission = false;

    $scope.search = {
      searchText: '',
      permissionType: '',
      orderVal: 'true',
      selectedPermissionsType: 'all'
    };
    $scope.sortingOrders = [{
      label: 'A-Z',
      value: 'true'
    }, {
      label: 'Z-A',
      value: 'false'
    }];

    $scope.isLoading = false;
    $scope.error = null;
    $scope.pagination = {
      currentPage: 1,
      page: 1,
      total: 0,
      pageSize: 20,
      itemsPerPage: 20
    };
    $scope.filter = {};
    $scope.totalActivePermission = 0;
    $scope.allowEditPermission = $auth.getCurrentUser().permissions.includes('SETTING_PERMISSION_EDIT');
    $scope.permissions = [];
    $scope.permissionsCount = [];
    $scope.types = $clientPermissionTypes;
    $scope.getPermissionsList = function () {
      $scope.isLoading = true;
      $scope.error = null;
      $scope.permissions = [];
      $scope.pagination.total = 0;

      $scope.filter.orderByValue = $scope.search.orderVal == 'true' ? 'asc' : 'desc';
      $scope.filter.orderByField = 'name';
      $scope.filter.page = $scope.pagination.page;
      $scope.filter.pageSize = $scope.pagination.pageSize;
      if ($scope.search.searchText) {
        $scope.filter.searchKey = $scope.search.searchText;
      }

      $clientPermissions.getPermissions(currentCli, $scope.search.selectedPermissionsType, $scope.filter)
        .then((res) => {
          if (res.status) {
            if (res.data && res.data.length) {
              $scope.permissions = res.data;
              $scope.pagination.total = res.totalRecords;
              $scope.permissionsCount = res.totalRecords;
              $scope.totalActivePermission = res.totalActivePermission;
            } else {
              $scope.error = 'No Permissions Found.';
              $scope.permissionsCount = 0;
              $scope.totalActivePermission = 0;
            }
          }
        })
        .catch((err) => {
          let errMessage = err.message || err;
          if (err && err.data && err.data.message) {
            errMessage = err.data.message;
          } else if (err && err.data && err.data.reason) {
            errMessage = err.data.reason;
          }
          $scope.error = errMessage;
          $scope.permissionsCount = 0;
          $scope.totalActivePermission = 0;
        })
        .finally(() => {
          $scope.isLoading = false;
        })
    }

    function reset() {
      $scope.pagination = {
        currentPage: 1,
        page: 1,
        total: 0,
        pageSize: 20,
        itemsPerPage: 20
      };
      $scope.filter = {};
    }

    $scope.applySearch = _.debounce(function (cv) {
      reset();
      $scope.getPermissionsList();
    }, DEFAULT_DEBOUNCE)

    $scope.onSearch = function () {
      $scope.applySearch();
    }

    $scope.onSorting = function () {
      reset();
      $scope.getPermissionsList();
    }

    $scope.onTypeChange = function () {
      reset();
      $scope.getPermissionsList();
    }

    function getPermissions(client) {
      $scope.loading = true;
      $scope.search = {
        permission: '',
        permissionType: '',
        orderVal: 'true'

      };
      $scope.types = $clientPermissionTypes;
      $clientPermissions.getPermissions(client)
        .then(({ data, status }) => {
          if (status) {
            $scope.permissions = data;
            $scope.activePermissions = data.filter(x => x.isEnabled).length;
          }
          $scope.allowEditPermission = $auth.getCurrentUser().permissions.includes('SETTING_PERMISSION_EDIT');
        })
        .finally(() => $scope.loading = false);
    }

    // listen for client switching
    $scope.$on('$destroy', $activeClient(function (client) {
      if (!client) return;
      currentCli = client;
      // getPermissions(currentCli)
      $scope.getPermissionsList()
    }));
  }
// Dependency Injection
DashboardSettingsPermissionsIndexCtrl.$inject = ["$rootScope","$scope","$activeClient","$clientPermissions","$clientPermissionTypes","$auth","DEFAULT_DEBOUNCE"];

// angular.module('coordinateApp').controller('DashboardSettingsPermissionsIndexCtrl', DashboardSettingsPermissionsIndexCtrl);
