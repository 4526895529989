


'use strict';

export const ClientUploadFactory  = function ($resource, appConfig) {
    return $resource(appConfig.apiUrl + '/api/client-uploads/:id/:uploadId/:controller', {
      id: '@_id',
      uploadId: '@_attachment'
    },
      {
        query: {
          method: 'POST',
          isArray: false,
          params: {
            controller: 'remove'
          }
        },
        generateSignedUrl: {
          method: 'GET',
          params: {
            id: '@id',
            controller: 'generate-signed-url'
          }
        },
        createRecord: {
          method: 'POST',
          params: {
            controller: 'create'
          }
        },
      });
  } 
 

// Dependency Injection
ClientUploadFactory.$inject = ['$resource', 'appConfig'];
