'use strict';

export const productionTableDirective = function ($auth) {
    return {
      restrict: 'E',
      templateUrl: 'app/account/dashboard/time-production-reporting/tables/production/production.html',
      scope: {
        employee: '=ngModel',
        MODES: '=modes',
        printMode: '=printMode',
        changeMode: '&',
        // createEntry: '&',
        updateEntry: '&',

        deleteEntries: '&',
        updateEntries: '&',
        createProdEntry: '&',    
        updateProdEntry: '&',        
        canEdit: '&',
        moveProdEntries: '&'
      },
      link: function($scope) {
        $scope.edit = function(index) {
          $scope.changeMode({employee: $scope.employee, reportIndex: index, newMode: $scope.MODES.UPDATE});
          // $scope.updateEntry({employee: $scope.employee, record: $scope.employee.reportingData[index]});
        };
        $scope.boxType = ["Full", "Half", "10 Roll", "30 CT"];
        // $scope.$watch(function() {
        //   debugger;
        //   var reportIds = $scope.employee.reportingData.map(function(report) {
        //     return report._id;
        //   });
        //   return reportIds.toString();

        // }, function() {
        //   $scope.timezone = $auth.getCurrentUser().timezone || moment.tz.guess();
        //   $scope.employee.reportingData.forEach(function(report) {
        //     report.shouldDisplayCurrency = ['Bags', 'Boxes'].indexOf(report.prodType) > -1;

        //     report.shouldDisplayProdSize = ['BagsWeighed', 'ChangeOvers'].indexOf(report.prodType) === -1;
            
        //     report.readableProdSize = report.prodSize && report.shouldDisplayProdSize ? 
        //         report.prodSize
        //           .replace(/([A-Z])/g, ' $1')
        //           .replace(/^./, function(str){ 
        //             return str.toUpperCase();
        //           }) : 
        //         'N/A';
        //   });

        // });
      }
    };
  }
// Dependency Injection
productionTableDirective.$inject = ["$auth"];
