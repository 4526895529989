
'use strict';

export const $clientSettingService  = function (ClientSetting) {
  function cliId (client) {
    return (client._id||client);
  }

  return {
    fetch: function (client) {
      return ClientSetting.query({ id: cliId(client) }).$promise;
    },
    getPostLoginMessage: function (client, email) {
      return ClientSetting.getPostLoginMessage({ id: cliId(client) }).$promise;
    },
    apply: function (client, settings) {
      return ClientSetting.apply({ client: cliId(client), settings: settings }).$promise;
    },
    replace: function (client, settings) {
      return ClientSetting.replace({ _id: cliId(client), settings: settings }).$promise;
    },
    sendPush: function(client, userId, message){
      return ClientSetting.sendPush({ client: cliId(client), userId: userId, message: message }).$promise;
    }
  };
} 
 

// Dependency Injection
$clientSettingService.$inject = ['ClientSetting'];
