
'use strict';

export const timezoneDateFilter  = function ($timezones) {
  return function (val, tz) {
    let timezone = moment().format('ZZ')
    if (tz) {
      let getTimezone = $timezones.find(x => x.value === tz || x.label === tz);
      timezone = getTimezone.offset;
    }
    return moment(val).utcOffset(timezone).format('MM/D/YYYY hh:mm A');
  };
}
 

// Dependency Injection
timezoneDateFilter.$inject = ['$timezones'];
