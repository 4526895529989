'use strict';

export const ResolveConfig = function ($stateProvider) {
    $stateProvider
      .state('app.external.resolve', {
        url: '/resolve',
        data: {
          roles: []
        },
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./external/resolve/resolve.html', '7291a84a'); }],
        controller: 'ExternalTaskResolveCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./external/resolve/resolve.controller.js', 'ExternalTaskResolveCtrl'); }]
                      }
      });
  };

// Dependency Injection
ResolveConfig.$inject = ["$stateProvider"];
