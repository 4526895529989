
'use strict';

export const $serviceJobInvoicesService  = function (serviceJobInvoices) {
        return {
            getInvoiceById: function (client, serviceId) {
                return serviceJobInvoices.getInvoiceById({
                    id: client._id || client,
                    controller: serviceId
                }).$promise;
            },
            getPendingInvoices: function (client, filter) {
                return serviceJobInvoices.getPendingInvoices({
                    id: client._id || client,
                    filter
                }).$promise;
            },
            getPaidInvoices: function (client, filter) {
                return serviceJobInvoices.getPaidInvoices({
                    id: client._id || client,
                    filter
                }).$promise;
            },
        };
    }
// Dependency Injection


// Dependency Injection
$serviceJobInvoicesService.$inject = ['serviceJobInvoices'];
