


'use strict';

export const ClientBucketFactory  = function ($resource, appConfig) {
    return $resource(appConfig.apiUrl + '/api/client-buckets/:id/:controller', {
        id: '@_id'
      },
      {
        detail: {
          method: 'GET',
          params: {
            controller: 'detail'
          }
        }
      });
  } 
 

// Dependency Injection
ClientBucketFactory.$inject = ['$resource', 'appConfig'];
