'use strict';


export const ClientContactFactory = function ($resource, appConfig) {
    return $resource(appConfig.apiUrl + '/api/client-contacts/:id/:controller', {
      id: '@_id'
    },
    {
      // ...
    });
  }
// Dependency Injection
ClientContactFactory.$inject = ["$resource","appConfig"];
