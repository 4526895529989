import './ecoi.scss';
'use strict';

export const EcoiConfig = function ($stateProvider) {
    $stateProvider
      .state('app.print.ecoi', {
        url: '/office-operations/ecoi',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./print/office-operations/ecoi/ecoi.html', '71f49dea'); }],
        controller: 'PrintECOICtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./print/office-operations/ecoi/ecoi.controller.js', 'PrintECOICtrl'); }]
                      }
      });
  }
// Dependency Injection
EcoiConfig.$inject = ["$stateProvider"];
