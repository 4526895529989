


'use strict';

export const DevicesManagerFactory  = function ($resource, appConfig) {
    return $resource(appConfig.apiUrl + '/api/devices/:id/:controller', {
      id: '@_id'
    },
    {
      saveDevice: {
        method: 'POST',
        params: {
          controller: 'create'
        }
      },
      updateDevice: {
        method: 'POST',
        params: {
          controller: 'update'
        }
      },
      getDevices: {
        method: 'GET',
        params: {
          controller: '',
          id: '@id'
        },
        isArray: true
      },
      getDeviceDetails: {
        method: 'GET',
        params: {
          controller: 'details',
          id: '@id'
        }
      },
      toggleState: {
        method: 'POST',
        params: {
          controller: 'toggle-state',
        }
      },
      deleteDevice: {
        method: 'POST',
        params: {
          controller: 'delete',
        }
      }
    });
  } 
 

// Dependency Injection
DevicesManagerFactory.$inject = ['$resource', 'appConfig'];
