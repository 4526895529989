
'use strict';

export const $performTaskRejectService  = function ($clientTask, Modal) {
  return function (cb) {
    cb = angular.isFunction(cb) ? cb : angular.noop;

    return function () { // TODO!!!!
      Modal.info.comingSoon().call(this, 'Task Rejection');
    };
  };
}
 

// Dependency Injection
$performTaskRejectService.$inject = ['$clientTask', 'Modal'];
