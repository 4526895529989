'use strict';

export const userDirective = function ($auth, $timeout, $clientSwitcher, $activeClient, $clientUser, $permissionMatrix, $permissionMatrixSourceType) {
    return {
      restrict: 'E',
      templateUrl: 'app/account/dashboard/settings/permission-matrix/tabs/user/user.html',
      scope: {
        modelData: '=ngModel',
        tabSelected: '&'
      },
      link: function (scope) {
        const currentCli = $clientSwitcher.getCurrentClient();
        scope.client = currentCli;
        scope.arrUsers = [];
        scope.selectedUser = {};
        scope.permissions = {
          settingPermission: false,
          allowUserEdit: false,
          allowRoleEdit: false,
          allowPermissionEdit: false
        };
        scope.data = {
          isLoading: true,
          permissionError: null,
          rolesError: null,
          screenError: null,
          assignRoles: [],
          assignPermissions: [],
          assignScreen: [],
          permissionsTypes: []
        };

        var init = function () {
          $activeClient(function (client) {
            if (!client) {
              return;
            }
            scope.client = client;
            $timeout(function () {
              scope.permissions.settingPermission = scope.modelData.featureTabs.includes("SETTINGS_READ");
              scope.permissions.allowRoleEdit = $auth.getCurrentUser().permissions.includes('SETTING_ROLE_EDIT');
              scope.permissions.allowPermissionEdit = $auth.getCurrentUser().permissions.includes('SETTING_PERMISSION_EDIT');
              scope.permissions.allowWorkOrderEdit = $auth.getCurrentUser().permissions.includes('EDIT_WO_BUCKET');
              getUsers(client);
            }, 100);
          });
        };

        function getUsers(client) {
          scope.data.isLoading = true;
          $clientUser.list(client)
            .then(function (data) {
              scope.arrUsers = data;
              scope.data.isLoading = false;
              if (scope.modelData.selectedData && scope.modelData.selectedData.userId) {
                setTimeout(() => {
                scope.onUserSelect(scope.modelData.selectedData);
                var elmnt = document.getElementById(scope.modelData.selectedData.userId);
                if (elmnt) elmnt.scrollIntoView();
                },500)
              }
            });
        }

        scope.onUserSelect = function (selectedUser) {
          scope.data.isLoading = true;
          resetData();
          scope.selectedUser = selectedUser;
          $permissionMatrix.getUserRolesPermissions(scope.client, selectedUser.userId)
            .then((res) => {
              if (res.status) {
                if (res.assignRoles && res.assignRoles.roles && res.assignRoles.roles.length) {
                  scope.data.assignRoles = res.assignRoles.roles;
                } else {
                  scope.data.rolesError = 'No Roles Assigned';
                }
                scope.assignPermissionsCount = res.assignPermissions.length || 0;
                if (res.assignPermissions && res.assignPermissions.length) {
                  // scope.data.assignPermissions = res.assignPermissions;
                  scope.data.assignPermissions = _.chain(res.assignPermissions)
                    .groupBy("type")
                    .map((value, key) => ({ page: key, permissions: value }))
                    .value();
                } else {
                  scope.data.permissionError = 'No Permissions Assigned';
                }
                if (res.userPermissions && res.userPermissions.length) {
                  _.each($clientSwitcher.getFeatureTabs(), function (tab) {
                    if (!tab.disabled && res.userPermissions.includes(tab.permission)) {
                      scope.data.assignScreen.push(tab)
                    }
                  });
                } else {
                  scope.data.screenError = 'No Page Assigned';
                }
                if (scope.data.assignScreen && !scope.data.assignScreen.length) {
                  scope.data.screenError = 'No Page Assigned';
                }
                window.scrollTo(0, 0);
                scope.data.isLoading = false;
              } else {
                scope.data.userError = res.message;
                scope.data.isLoading = false;
              }
            })
        }

        scope.showRoleTab = function (role) {
          scope.tabSelected({ type: "role", data: role })
        }

        scope.showPageTab = function (page) {
          scope.tabSelected({ type: "pages", data: page })
        }

        scope.sourceType = function (permissionType) {
          return $permissionMatrixSourceType(permissionType);
        }

        function resetData() {
          scope.data.permissionError = null;
          scope.data.userError = null;
          scope.data.rolesError = null;
          scope.data.screenError = null;
          scope.data.assignRoles = [];
          scope.data.assignPermissions = [];
          scope.data.assignScreen = [];
        }

        scope.setAccordian = function ($event, assignPermission) {
          if ($event) {
              $event.currentTarget.classList.toggle("active");
              var panel = $event.target.nextElementSibling;
              if(panel){
                if (panel.style.display === "block") {
                    panel.style.display = "none";
                } else {
                    panel.style.display = "block";
                }
              }
          }
          if(assignPermission){
            assignPermission.expand = !assignPermission.expand;
          }
      }
        init()
      }
    }
  }
// Dependency Injection
userDirective.$inject = ["$auth","$timeout","$clientSwitcher","$activeClient","$clientUser","$permissionMatrix","$permissionMatrixSourceType"];
