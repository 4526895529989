'use strict';

export const DailyReportConfig = function ($stateProvider) {
    $stateProvider
      .state('app.print.dailyReport', {
        url: '/dailyReport',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./print/live-reports/daily-report/daily-report.html', 'af3bcbfd'); }],
        controller: 'PrintDailyReportCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./print/live-reports/daily-report/daily-report.controller.js', 'PrintDailyReportCtrl'); }]
                      }
      });
  }
// Dependency Injection
DailyReportConfig.$inject = ["$stateProvider"];
