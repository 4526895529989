


'use strict';

export const ClientTimeProductionReportingFactory  = function ($resource, appConfig) {
    return $resource(appConfig.apiUrl + '/api/time-production-reporting/:id/:controller', {
      id: '@_id'
    },
    {
      getRawRecords: {
        method: 'GET',
        params: {
          id: '@id'
        }
      },
      getRecords: {
        method: 'GET',
        params: {
          id: '@id'
        }
      },
      exportRecords: {
        method: 'GET',
        params: {
          id: '@id'
        }
      },
      getReportingData: {
        method: 'GET',
        params: {
          id: '@id'
        }
      },
      deleteRecords: {
        method: 'POST',
        params: {
          id: '@id',
          controller: 'delete'
        }
      },
      createRecords: {
        method: 'POST',
        params: {
          id: '@id',
          controller: 'create'
        }
      },
      updateRecords: {
        method: 'POST',
        params: {
          id: '@id',
          controller: 'update'
        }
      },
      getWeekOvertimeReport: {
        method: 'GET',
        params: {
          id: '@id'
        }
      },
      moveProdRecords: {
        method: 'POST',
        params: {
          id: '@id',
          controller: 'move-prod-records'
        }
      },
      updateShiftDetailsRecord: {
        method: 'POST',
        params: {
          id: '@id',
          controller: 'update-shift-details'
        }
      }
    });
  } 
 

// Dependency Injection
ClientTimeProductionReportingFactory.$inject = ['$resource', 'appConfig'];
