'use strict';


export const presetTimeDirective = function ($clientSwitcher, $activeClientSettings, $interval) {
  return {
    restrict: 'E',
    require: 'ngModel',
    templateUrl: 'components/util/preset-time.html',
    scope: {
      model: '=ngModel',
      presetOffsets: '=',
      relativeNowDate: '='
    },
    controller: ['$scope', function ($scope) {

      var defaultOffsets = [
        { offset: 1800,     label: '30 Min' },
        { offset: 3600,     label: '1 Hr' },
        { offset: 7200,     label: '2 Hrs' },
        { offset: 10800,    label: '3 Hrs' },
        { offset: 86400,    label: '1 Day' },
        { offset: 172800,   label: '2 Days' },
        { offset: 259200,   label: '3 Days' }
      ],
      defaultLength = defaultOffsets.length,
      defaultOffsetsUsed = false;

      if(!$scope.model && $scope.relativeNowDate){
        $scope.model = new Date($scope.relativeNowDate.getTime() + (60*60*1000));
      }

      var clientSettings, cobdOffset, cobwOffset, cobmOffset;
      var withModayAsFirstDayofWeek = function(day) {
        var returnVal = day  - 1;
        return returnVal < 0 ? returnVal + 7 : returnVal;
      };

      var extractTime = function(date) {
        var dateAtMidnightMS = new Date(date.getTime()).setHours(0, 0, 0, 0);
        return date.getTime() - dateAtMidnightMS;
      };

      var setCOBValues = function(settings) {

        if(!settings || !settings.businessDays) {
          return;
        }

        var currentDate = new Date();
        var currentDay = withModayAsFirstDayofWeek(currentDate.getDay());
        var weekDays = {
          length: 7
        };

        var cobdTimings = settings.businessDays[currentDay];

        //close of business day
        if(cobdTimings.enabled) {
          var cobd = cobdTimings.max * 60;
          cobdOffset = cobd - (extractTime(currentDate) / 1000);
          
        }
        

        //close of business week
        var lastMomentOfWeek = moment().endOf('isoweek'); //sunday of this week
        for (var i = weekDays.length - 1 ; i >= currentDay; i--) {
          var dayTimings = settings.businessDays[i];

          if(dayTimings.enabled) {
            var cobw = lastMomentOfWeek.hours(dayTimings.max/60).minute(dayTimings.max%60).seconds(0);
            cobwOffset = (cobw._d.getTime() - currentDate.getTime())/1000;
            break;
          }
          else {
            lastMomentOfWeek.subtract(1, 'day');
          }
        }


        //close of business month
        var lastMomentOfMonth = moment().endOf('month');
        
        for(var interationCount = 0; interationCount < 7 && lastMomentOfMonth.date() >= currentDate.getDate(); interationCount++) {
          var day = withModayAsFirstDayofWeek(lastMomentOfMonth._d.getDay());
          var dayTimings = settings.businessDays[day];

          if(dayTimings.enabled) {
            var cobm = lastMomentOfMonth.hours(dayTimings.max/60).minute(dayTimings.max%60).seconds(0);
            cobmOffset = (cobm._d.getTime() - new Date().getTime()) / 1000;
            break;
          }
          else {
            lastMomentOfMonth.subtract(1, 'day');
          }
        }

        cobdOffset = Math.round(cobdOffset);
        cobwOffset = Math.round(cobwOffset);
        cobmOffset = Math.round(cobmOffset);        

        var cobDay = {label: 'COB', offset: cobdOffset};
        if(defaultOffsets.length === defaultLength) {
          defaultOffsets.push(cobDay);
          defaultOffsets.push({label: 'COBW', offset: cobwOffset});
          defaultOffsets.push({label: 'COBM', offset: cobmOffset});
        }
        else {
          defaultOffsets.forEach(function(defaultOffset) {
            switch(defaultOffset.label) {
              case 'COB':
                defaultOffset.offset = cobdOffset;
                break;
              case 'COBW':
                defaultOffset.offset = cobwOffset;
                break;
              case 'COBM':
                defaultOffset.offset = cobmOffset;
                break;
            }
          });
        }
      };

      defaultOffsetsUsed = !angular.isArray($scope.presetOffsets);
      $scope.presetOffsets = angular.isArray($scope.presetOffsets) ? $scope.presetOffsets : defaultOffsets;

      $scope.selectPreset = function (preset) {
        var now = Date.now();

        if($scope.relativeNowDate && preset.label !== 'COB' && preset.label !== 'COBW' && preset.label !== 'COBM') {
          if($scope.relativeNowDate instanceof Date) {
            now = $scope.relativeNowDate.getTime();
          }else if(angular.isString($scope.relativeNowDate)) {
            now = Date.parse($scope.relativeNowDate);
          }else if(angular.isNumber($scope.relativeNowDate)) {
            now = $scope.relativeNowDate;
          }
        }

        $scope.model = new Date(now + (preset.offset * 1000));
      };

      $scope.getTooltipText = function(preset) {
        switch(preset.label) {
          case 'COB': 
            return 'Close of Business day';
          case 'COBW':
            return 'Close of Business week';
          case 'COBM':
            return 'Close of Business month';
          default: 
            return '';
        }
      };

      if(defaultOffsetsUsed) {
        
        $activeClientSettings(function (client, settings) {
          clientSettings = settings;
          setCOBValues(settings);
        });

        $interval(function() {
          setCOBValues(clientSettings);
          $scope.presetOffsets = defaultOffsets;
        }, 60000); 

      }

    }]
  };
}
// Dependency Injection
presetTimeDirective.$inject = ["$clientSwitcher","$activeClientSettings","$interval"];
