'use strict';


export const formEquipmentDirective = function ($clientSwitcher, $activeClient, $clientRegion, $clientOffice, $clientEmployee, $clientCustomer, $q, NgMap) {
    return {
      restrict: 'E',
      templateUrl: 'components/forms/equipment.html',
      scope: {
        equipment: '=ngModel',
        onSubmit: '=formOnSubmit',
        onReset: '=formOnReset'
      },
      link: function(scope, el, attrs) {

        var map;
        var currentClient;
        var allOffices = [];
        var DEFAULT_EQUIPMENT_TYPE = 'atm';
        var DEFAULT_CENTER = [
          38.393597126079406,
          -100.39715576171875
        ];
        var DEFAULT_ZOOM = 4;

        scope.clientStrings = function(){ return $clientSwitcher.getFeatureOptions('client.strings'); };
        scope.equipmentTypes = [
          {value: 'atm', label: 'ATM'},
          {value: 'vault', label: 'Vault'},
          {value: 'vehicle', label: 'Vehicle'},
          {value: 'specialty', label: 'Specialty'},
        ];
        scope.equipment.type = scope.equipment.type || DEFAULT_EQUIPMENT_TYPE;

        scope.lengthUnits = [
          {value: 'meters', label: 'Meters', increment: 100},
          {value: 'feet', label: 'Feet', increment: 1000}
        ];

        var loadRegions = function(client) {
          return $clientRegion.listAll(client)
            .then(function (regions) {
              scope.regions = regions.map(function(region) {
                return {
                  value: region._id,
                  label: region.name
                };
              });
            });
        };
        var loadOffices = function(client) {
          return $clientOffice.listAll(client)
            .then(function (offices) {
              allOffices = offices;
            });
        };
        var loadEmployees = function(client) {
          return $clientEmployee.getEmployees(client, {})
            .then(function(res) {
              if(res.status) {
                scope.employees = res.data.map(function(employee) {
                  return {
                    value: employee._id,
                    label: employee.firstName + ' ' + employee.lastName
                  };
                });
              }
              else {
                scope.employees = [];
              }
            });
        };
        var loadCustomers = function(client) {
          return $clientCustomer.getCustomers(client)
          .then(function(res) {
            if(res.status) {
              scope.customers = res.data.map(function(customer) {
                return {
                  value: customer._id,
                  label: customer.name
                };
              });
            }
            else {
              scope.customers = [];
            }
          });
        };

        scope.setCenter = function(evt) {
          map.setCenter(evt.latLng);
          scope.equipment.long = evt.latLng.lng()
          scope.equipment.lat = evt.latLng.lat();
        };

        var setUnit = function(unit) {
          scope.equipment.eventRadiusType = unit.value;
          scope.lengthUnitIncrement = unit.increment; 
        };

        scope.setUnit = setUnit;

        var init = function() {
          scope.isLoading = true;

           if(!scope.equipment.eventRadiusType) {
            setUnit(scope.lengthUnits[0]);
           }
           else {
             scope.lengthUnits.forEach(function(unit) {
               if(scope.equipment.eventRadiusType === unit.value) {
                setUnit(unit);
               }
             });
           }

          NgMap.getMap().then(function(evtMap) {
            map = evtMap;
          });

          $activeClient(function(client) {
            currentClient = client;
            scope.clients = $clientSwitcher.getLoaded();

            $q.all([
              loadRegions(client),
              loadOffices(client),
              loadEmployees(client),
              loadCustomers(client)
            ])
            .then(function() {
              if(scope.equipment.region) {
                scope.regionChanged();
              }
            })
            .finally(function() {
              scope.isLoading = false;

              setTimeout(function() {
                google.maps.event.trigger(map, "resize");
                map.setCenter({lat: DEFAULT_CENTER[0], lng: DEFAULT_CENTER[1]})
                map.setZoom(DEFAULT_ZOOM);
              }, 1000)
            });
          });
        };
        init();

        scope.regionChanged = function() {
          scope.offices = allOffices
          .filter(function(office) {
            return office.region === scope.equipment.region;
          })
          .map(function (office) {
            return {
              value: office._id,
              label: office.name
            };
          });
        };
      }
    };
  }
// Dependency Injection
formEquipmentDirective.$inject = ["$clientSwitcher","$activeClient","$clientRegion","$clientOffice","$clientEmployee","$clientCustomer","$q","NgMap"];
