'use strict';

export const ResourceOpsDashboardConfig = function ($stateProvider) {
        $stateProvider
            .state('app.print.resourceOpsDashboard', {
                url: '/resourceOpsDashboard?step',
                params: {
                    step: null
                },
                templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./print/resource-ops-dashboard/resource-ops-dashboard.html', 'ba71462a'); }],
                controller: 'PrintResourceOpsDashboardCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./print/resource-ops-dashboard/resource-ops-dashboard.controller.js', 'PrintResourceOpsDashboardCtrl'); }]
                      }
            });
    }
// Dependency Injection
ResourceOpsDashboardConfig.$inject = ["$stateProvider"];
