
'use strict';

export const $clientCustomerService  = function (ClientCustomer) {
    return {
      getCustomers: function(client, searchText) {
        return ClientCustomer.getCustomers({
          id: client._id || client,
          search: searchText
        }).$promise;
      },
      detail: function(client, customer) {
        return ClientCustomer.detail({
          id: client._id||client,
          customer: customer
        }).$promise;
      },
      createCustomer: function(client, customerData) {
        return ClientCustomer.createCustomer({
          _id: client._id||client,
          data: customerData
        }).$promise;
      },
      updateCustomer: function(client, customerId, customerData) {
        return ClientCustomer.updateCustomer({
          _id: client._id||client,
          customer: customerId,
          data: customerData
        }).$promise;
      },
      deleteCustomer: function(client, customer) {
        return ClientCustomer.deleteCustomer({
          _id: client._id||client,
          customer: customer._id
        }).$promise;
      },
      getLogs: function(client, page, pageSize) {
        return ClientCustomer.getLogs({
          id: client._id || client,
          page: page,
          pageSize: pageSize
        }).$promise;
      },
      getUserCustomer: function(client, userId) {
        return ClientCustomer.getUserCustomer({
          id: client._id || client,
          userid: userId
        }).$promise;
      },
    };
  }
// Dependency Injection


// Dependency Injection
$clientCustomerService.$inject = ['ClientCustomer'];
