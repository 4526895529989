
'use strict';

export const $programsService  = function (Programs) {
        return {
            getPrograms: function (client) {
                return Programs.getPrograms({
                    id: client._id || client
                }).$promise;
            },
            getProgramById: function (client, ruleId) {
                return Programs.getProgramById({
                    id: client._id || client,
                    controller: ruleId
                }).$promise;
            },
            create: function (client, data) {
                return Programs.create({
                    _id: client._id || client,
                    data
                }).$promise;
            },
            update: function (client, data) {
                return Programs.update({
                    _id: client._id || client,
                    data
                }).$promise;
            },
            delete: function (client, programId) {
                return Programs.delete({
                    _id: client._id || client,
                    programId
                }).$promise;
            }
        };
    }
// Dependency Injection


// Dependency Injection
$programsService.$inject = ['Programs'];
