
'use strict';

export const $getImageFilePathService  = function ($attachment, appConfig) {
        return function (client, uploadId) {
            return $attachment.download(client._id || client, uploadId)
                .then((res) => {
                    if (res && res.status && res.url) {
                        return res.url;
                    } else {
                        let downloadUrl = appConfig.apiUrl + '/attachment/' + (client._id || client) + '/' + uploadId;
                        return downloadUrl + '?preview=0';
                    }
                })
                .catch((err) => {
                    console.log(err)
                });
        }
    }
// Dependency Injection


// Dependency Injection
$getImageFilePathService.$inject = ['$attachment', 'appConfig'];
