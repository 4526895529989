'use strict';

export const calendarDirective = function ($activeClientSettings,$rootScope) {
        return {
            restrict: 'E',
            templateUrl: 'app/account/dashboard/tasks/tabs/calendar/calendar.html',
            scope: {},
            link: function (scope) {
                scope.allTask = $rootScope.allTasks;
                // Display calender
                scope.canDisplayTab = function (tabName) {
                    return scope.supportedTabs && scope.supportedTabs.indexOf(tabName) > -1;
                };

                $activeClientSettings(function (client, settings) {
                    if (!client || !settings) {
                        return;
                    }
                    scope.supportedTabs = settings.supportedTabs || [];
                });
                // Display calender
            }
        }
    }
// Dependency Injection
calendarDirective.$inject = ["$activeClientSettings","$rootScope"];
