'use strict';


export const myToggleSwitchDirective = function ($interval, $taskReports, $clientSwitcher, $compile) {
    return {
      restrict: 'A',
      templateUrl: 'components/toggle-switch/toggle-switch.html',
      scope: {
        model: '=ngModel',
        toggleField: '=toggleField',
        onToggle: '=onToggle',
        num: '=num'
      },
      link: function(scope, el, attrs) { 
        // generates toggle-switch even if no html exists (i.e. instantiate via code)
        $(el).bootstrapToggle({
          on: 'Enabled',
          off: 'Disabled',
          onstyle: 'success',
          offstyle: 'danger'
        });        

        var setSwitch = function() {
          if(scope.model[scope.toggleField]) {
            $(el).bootstrapToggle('on');
          } else {
            $(el).bootstrapToggle('off');
          }
        };

        el['0'].id = scope.num;
        console.log(el);      
        var clickHandler = function(event) {
          console.log(event.target.id);
          scope.model[scope.toggleField] = !scope.model[scope.toggleField];
          setSwitch();
          if(scope.onToggle) {
            scope.onToggle(scope.model);
          }
          return true;
        };

        setSwitch();
        // el['0']

        $('input#1').click(event, clickHandler);
        // $('span').click(event, clickHandler);
      }
    };
  }
// Dependency Injection
myToggleSwitchDirective.$inject = ["$interval","$taskReports","$clientSwitcher","$compile"];
