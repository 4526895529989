
'use strict';

export const $activeClientService  = function ($q, $rootScope, $clientSwitcher) {
    var
      lastClient = false,
      callScope = $rootScope,
      rootEvent = 'client-activate';

    function identityId(client) {
      if (!client) {
        return false;
      }
      return client._id || false; /// wait for fully loaded client object
    }

    function compareClient(a, b) {
      var
        aid = identityId(a),
        bid = identityId(b);

      if (aid > bid || !aid && bid) return 1;
      if (aid < bid || aid && !bid) return -1;

      return 0;
    }

    function setActiveClient(client) {
      if (compareClient(lastClient, client) === 0) { // no change
        return;
      }

      if (!client) {
        lastClient = false;
        $rootScope.$broadcast(rootEvent, lastClient);
        return;
      }
      else {
        $clientSwitcher.whenLoaded(function () {
          lastClient = client;
          $rootScope.$broadcast(rootEvent, lastClient);
        });
      }
    }

    function notifyCallback(callback, client, scope) {
      return callback.call(scope || callback, client);
    }

    $rootScope.$watch($clientSwitcher.getCurrentClient.bind($clientSwitcher), setActiveClient);

    return function (cb) {
      if (!angular.isFunction(cb)) {
        return false;
      }

      cb.call(callScope, lastClient); // call immediate with last client

      return $rootScope.$on(rootEvent, function (evt, client) {
        cb.call(callScope, client);
      });
    };
  }
 

// Dependency Injection
$activeClientService.$inject = ['$q', '$rootScope', '$clientSwitcher'];
