


'use strict';

export const ClientOfficeFactory  = function ($resource, appConfig) {
    return $resource(appConfig.apiUrl + '/api/client-offices/:id/:controller', {
      id: '@_id'
    },
    {
      query: {
        method: 'GET',
        isArray: true,
        cache: true
      },
      detail: {
        method: 'GET',
        params: {
          controller: 'detail'
        }
      },
      isContactUnique: {
        method: 'GET',
        params: {
          controller: 'is-contact-unique'
        }
      },
      getLogs: {
        method: 'GET',
        params: {
          controller: 'logs'
        }
      },
      delete: {
        method: 'POST',
        params: {
          controller: 'delete'
        }
      },
      ivrSync: {
        method: 'GET',
        params: {
          controller: 'ivr-sync'
        }
      },
      associatedEmployeesLocation: {
        method: 'GET',
        params: {
          controller: 'associated-employees-location'
        }
      },
      getLocationLatLong: {
        method: 'GET',
        params: {
          controller: 'getLocationLatLong'
        }
      },
      getOfficeWoControls: {
        method: 'GET',
        params: {
          controller: 'get-office-wo-controls'
        }
      },
      getBusinessHours: {
        method: 'GET',
        params: {
          controller: 'get-business-hours'
        }
      },
      getCodesDetails: {
        method: 'GET',
        params: {
          controller: 'get-codes-details'
        }
      },
      updateCourtResource: {
        method: 'POST',
        params: {
          id: '@id',
          controller: 'update-court-resource'
        }
      },
      getOfficeList: {
        method: 'GET',
        params: {
            controller: 'get-office-list'
        }
      },
      getOfficeByInternalId: {
        method: 'GET',
        params: {
            controller: 'get-by-internalid'
        },
        cache: true
      },
      getOfficeByReplocId: {
        method: 'GET',
        params: {
            controller: 'get-by-replocid'
        }
      },
      getActiveOfficeList : {
        method: 'GET',
        params: {
          controller: 'get-active-office-list'
        }
      }
    });
  }


// Dependency Injection
ClientOfficeFactory.$inject = ['$resource', 'appConfig'];
