'use strict';


export const bucketActionsDirective = function (Modal, $clientSurvey) {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      client:'=client',
      record:'=record',
      bucket:'=bucket',
      reloadDataFn: '=reloadfn',

      noCall: '=noCall',
      noListenAudio: '=noListenAudio',
      noTranscribeAudio: '=noTranscribeAudio',
      noScore: '=noScore',
      noAssign: '=noAssign',
      noResolveHistory: '=noResolveHistory',
      noResolve: '=noResolve'
    },
    templateUrl: 'components/bucket/action.tpl.html',
    link: function (scope, el, attrs) {

      scope.reloadData = function () {
        if(angular.isFunction(scope.reloadDataFn)) {
          scope.reloadDataFn();
        }
      };

      scope.modalCall            = Modal.alertActions.callRecord(function (result) {
        // console.log('finished:', arguments);
      });
      scope.modalListenToAudio   = Modal.alertActions.listenToAudio(function (result) {
        // console.log('finished:', arguments);
      });
      scope.modalTranscribeAudio = Modal.alertActions.transcribeAudio(function (result) {
        // console.log('finished:', arguments);
      });
      scope.modalResolveHistory  = Modal.alertActions.viewResolveHistory(function (result) {
        // console.log('finished:', arguments);
      });
      scope.modalScore           = Modal.alertActions.scoreRecord(function (result, record) {
        if(result.openAudio) {
          scope.modalListenToAudio(record);
        }
        else if(result.assignRecord) {
          scope.modalAssign(record);
        }
        else if(result.resolveRecord && record.resolvable) {
          scope.modalResolve(record.raw);
        }
      });
      scope.modalAssign          = Modal.alertActions.forwardRecord(function (result, record) {
        return $clientSurvey.forward(scope.client._id, record._id, result).then(scope.reloadData).then(scope.reloadData);
      });
      scope.modalResolve         = Modal.alertActions.resolveRecord(function (result, record) {
        return $clientSurvey.resolve(scope.client._id, record._id, result.message, !!result.markClosed).then(scope.reloadData);
      });

      function updateButtonStates() {
        var
        bucket = scope.bucket,
        record = scope.record;

        if(!bucket || !record) {
          return;
        }

        scope.showCall               = true;
        scope.showListenAudio        = true;
        scope.showTranscribeAudio    = true; // disabled/unimplemented
        scope.showScore              = true;
        scope.showAssign             = true;
        scope.showResolveHistory     = true;
        scope.showResolve            = true;

        scope.disableCall            = false;
        scope.disableListenAudio     = false;
        scope.disableTranscribeAudio = false;
        scope.disableScore           = false;
        scope.disableAssign          = false;
        scope.disableResolveHistory  = false;
        scope.disableResolve         = false;

        if(bucket.positiveFeedback) {
          scope.showResolve        = false;
          scope.showResolveHistory = false;
        }
        if(bucket.negativeFeedback) {
          scope.showResolveHistory = false;
        }
        if(bucket.resolvedContainer) {
          scope.showResolve        = false;
          scope.showResolveHistory = true;
          scope.showAssign         = false;
        }
        if(bucket.resolvable) {
          scope.showResolve = true;
        }

        if(!record.raw.recording.length) {
          scope.disableListenAudio = true;
          scope.disableTranscribeAudio = false; // should be true
        }

        scope.disableCall = true;

        if(scope.noCall)            scope.showCall = false;
        if(scope.noListenAudio)     scope.showListenAudio = false;
        if(scope.noTranscribeAudio) scope.showTranscribeAudio = false;
        if(scope.noScore)           scope.showScore = false;
        if(scope.noAssign)          scope.showAssign = false;
        if(scope.noResolveHistory)  scope.showResolveHistory = false;
        if(scope.noResolve)         scope.showResolve = false;
      }

      scope.$watch('bucket', updateButtonStates);
      scope.$watch('record', updateButtonStates);
    }
  };
}
// Dependency Injection
bucketActionsDirective.$inject = ["Modal","$clientSurvey"];
