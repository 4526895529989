'use strict';

export const PrintConfig = function ($stateProvider) {
    $stateProvider
      .state('app.print', {
        url: '/print',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./print/print.html', '22b63a4a'); }],
        controller: 'printCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./print/print.controller.js', 'printCtrl'); }]
                      }
      });
  }
// Dependency Injection
PrintConfig.$inject = ["$stateProvider"];
