
'use strict';

export const $clientPermissionsService  = function (ClientPermissions) {
    return {
      update: function(client, data) {
        return ClientPermissions.update({
          _id: client._id || client,
          data: {
            permissionId: data._id,
            name: data.name,
            isEnabled: data.isEnabled,
            code: data.code,
            type: data.type,
            description: data.description
          }
        }).$promise;
      },
      create: function(client, data) {
        return ClientPermissions.create({
          _id: client._id || client,
          name: data.name,
          isEnabled: data.isEnabled,
          code: data.code,
          type: data.type,
          description: data.description
        }).$promise;
      },
      getPermissions: function(client, type, filter) {
        return ClientPermissions.getPermissions({
          id: client._id || client,
          type: type || 'all',
          page: filter && filter.page,
          pageSize: filter && filter.pageSize,
          searchKey: filter && filter.searchKey,
          orderByValue: filter && filter.orderByValue,
          orderByField: filter && filter.orderByField,
        }).$promise;
      },
      getPermissionById: function(client, permissionId) {
        return ClientPermissions.getPermissionById({
          id: client._id || client,
          controller: permissionId
        }).$promise;
      }
    };
  } 
 

// Dependency Injection
$clientPermissionsService.$inject = ['ClientPermissions'];
