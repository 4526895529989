'use strict';

export const JudgesConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard.settings.judges', {
        url: '/judges',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/judges/judges.html', 'f652d82a'); }],
        controller: 'DashboardSettingsJudgesCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/judges/judges.controller.js', 'DashboardSettingsJudgesCtrl'); }]
                      }
      })
      .state('app.account.dashboard.settings.judges.index', {
        url: '/index',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/judges/index/index.html', '27d1ad0a'); }],
        controller: 'DashboardSettingsJudgesIndexCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/judges/index/index.controller.js', 'DashboardSettingsJudgesIndexCtrl'); }]
                      }
      })
      .state('app.account.dashboard.settings.judges.create', {
        url: '/create',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/judges/create/create.html', 'fd50cd6a'); }],
        controller: 'DashboardSettingsJudgesCreateCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/judges/create/create.controller.js', 'DashboardSettingsJudgesCreateCtrl'); }]
                      }
    })
    .state('app.account.dashboard.settings.judges.update', {
        url: '/update/:id',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/judges/update/update.html', 'bca67ca'); }],
        controller: 'DashboardSettingsJudgesUpdateCtrl',
        resolve: {
            lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/judges/update/update.controller.js', 'DashboardSettingsJudgesUpdateCtrl'); }],
            '$selectedJudge': ['$stateParams', '$judges', '$clientSwitcher', function($stateParams, $judges, $clientSwitcher) {
                return $judges.getJudgeById($clientSwitcher.getCurrentClient(), $stateParams.id);
              }]
          },

    });
  }
// Dependency Injection
JudgesConfig.$inject = ["$stateProvider"];
