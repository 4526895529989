'use strict';

export const auditLogsDirective = function ($auth, $clientSwitcher, $cases, $window, ngToast) {
        return {
            restrict: 'E',
            templateUrl: 'app/account/dashboard/settings/postgres_data_sync/audit_logs/audit_logs.html',
            scope: {},
            link: function ($scope) {
                const currentUser = $auth.getCurrentUser();
                const currentCli = $clientSwitcher.getCurrentClient();

                $scope.tableNames = [
                    { label: 'Cases', value: 'cases' },
                    { label: 'Interpreters', value: 'interpreters' },
                    { label: 'Coordinators', value: 'coordinators' },
                    { label: 'Judges', value: 'judge' },
                    { label: 'Interpreter Langs', value: 'intlangs' },
                    { label: 'Languages', value: 'languages' },
                    { label: 'Locations', value: 'locations' },
                    { label: 'Nationalities', value: 'nationalities' },
                    { label: 'SOS Notes', value: 'sosnots' },
                    { label: 'ICA Tracking', value: 'icatracking' },
                    { label: 'DQS', value: 'dqs' },
                    { label: 'Lists', value: 'lists' },
                    { label: 'Telephonics', value: 'telephonics' },
                ];

                $scope.searchKey = '';
                $scope.data = {
                    isLoading: false,
                    startDate: null, //moment().subtract(7, 'days').startOf('day').format('YYYY-MM-DD hh:mm A'),
                    endDate: null, //moment().endOf('day').format('YYYY-MM-DD hh:mm A')
                    tableName: null,
                    logs: [],
                    error: null
                };

                $scope.loadAuditLogs = function () {
                    if (!$scope.searchKey || !$scope.data.tableName || !$scope.data.startDate || !$scope.data.endDate) {
                         $scope.data.logs = [];
                         return
                    }
                    if ($scope.data.isLoading) return;
                    let filter = {
                        searchKey: $scope.searchKey,
                        tableName: $scope.data.tableName,
                        startDate: $scope.data.startDate,
                        endDate: $scope.data.endDate
                    }
                    $scope.data.isLoading = true;
                    $cases.getAuditLogs(currentCli, filter)
                    .then((res) => {
                        $scope.data.isLoading = false;
                        $scope.data.logs = res.result
                    })
                    .catch(err => {
                        ngToast.create({
                            content: err.message || err,
                            className: 'danger'
                        });
                        $scope.data.isLoading = false;
                    })
                };
                function initCasesDateRangePicker() {
                    $('#daterange').daterangepicker({
                        autoUpdateInput: false,
                        opens: 'left',
                        timePicker: false,
                        // locale: {
                        //     applyLabel: 'Sync Data'
                        // }
                    })
                        .on('apply.daterangepicker', function (ev, picker) {
                            let start = picker.startDate, end = picker.endDate;
                            $('#daterange span').html(start.format('MM/DD/YYYY') + ' to ' + end.format('MM/DD/YYYY'));
                            $scope.data.startDate = start.format('YYYY-MM-DD');
                            $scope.data.endDate = end.format('YYYY-MM-DD');
                            if ($scope.data.startDate && $scope.data.endDate) {
                                $scope.loadAuditLogs()
                            }

                        })
                        .on('cancel.daterangepicker', function (ev, picker) {
                            $(this).val('');
                            $scope.data.startDate = null;
                            $scope.data.endDate = null;
                        })
                }

                initCasesDateRangePicker()
            }
        }
    }
// Dependency Injection
auditLogsDirective.$inject = ["$auth","$clientSwitcher","$cases","$window","ngToast"];
