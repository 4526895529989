
'use strict';

export const $intLangsService  = function (IntLangs) {
    return {
        getIntLangsList: function (client, paginationData, search) {
            return IntLangs.getIntLangsList({
                id: client._id || client,
                page: paginationData && paginationData.page,
                pageSize: paginationData && paginationData.pageSize,
                search: search ? search : ''
            }).$promise;
        }
    };
}
// Dependency Injection


// Dependency Injection
$intLangsService.$inject = ['IntLangs'];
