


'use strict';

export const ListsFactory  = function ($resource, appConfig) {
        return $resource(appConfig.apiUrl + '/api/lists/:id/:controller', {
            id: '@_id'
        },
            {
                getLists: {
                    method: 'GET',
                    params: {
                        controller: 'get-lists'
                    }
                },
            });
    } 
 

// Dependency Injection
ListsFactory.$inject = ['$resource', 'appConfig'];
