import './project-history.scss';


'use strict';

export const projectHistoryDirective  = function ($rootScope, $state, $stateParams, $auth, $timeout, $clientSwitcher, $activeClient, $servicePanel, Modal, $Linguist, socket, $serviceStatusWithBucketName) {
        return {
            restrict: 'E',
            templateUrl: 'app/account/services/projects/project-history/project-history.html',
            scope: {

            },
            link: function (scope, element, attrs) {
                var killClientWatch,
                    currentCli = $clientSwitcher.getCurrentClient();
                // var currentUser = $auth.getCurrentUser();
                let openServiceStatusBucket = $stateParams && $stateParams.status ? $stateParams.status : null;
                scope.serviceStatusWithBucketName = $serviceStatusWithBucketName();
                scope.data = {
                    isLoading: false,
                    error: null,
                    buckets: null,
                    bucketKeys: [],
                    services: $Linguist.getServiceLanguages(),
                    counts: {}
                }
                scope.services = [{
                    id: 'on_site_interpretation',
                    name: 'On Site Interpretation',
                    image: 'assets/images/on_site_trans.svg',
                    uiSref: 'app.account.services.servicePanel.onSiteInterpretation'
                },
                {
                    id: 'telephonic_interpretation',
                    name: 'Telephonic Interpretation',
                    image: 'assets/images/telephonic.svg',
                    uiSref: 'app.account.services.servicePanel.telephonicInterpretation'
                },
                {
                    id: 'localization',
                    name: 'Localization',
                    image: 'assets/images/localization.svg',
                    uiSref: 'app.account.services.servicePanel.localization'
                },
                {
                    id: 'translation',
                    name: 'Translation',
                    image: 'assets/images/translation.svg',
                    uiSref: 'app.account.services.servicePanel.translation'
                },
                {
                    id: 'transcription',
                    name: 'Transcription',
                    image: 'assets/images/transcription.svg',
                    uiSref: 'app.account.services.servicePanel.transcription'
                },
                {
                    id: 'captioning',
                    name: 'Captioning',
                    image: 'assets/images/captioning.svg',
                    uiSref: 'app.account.services.servicePanel.captioning'
                }];
                scope.filter = {
                    search: '',
                    startDate: moment().startOf('month').format('YYYY-MM-DD'),
                    endDate: moment().endOf('month').format('YYYY-MM-DD'),
                    service: 'all'
                }
                scope.hasPermission = {};
                function initDateRangePicker() {
                    function cb(start, end) {
                        $timeout(function () {
                            if (!start || !end) {
                                return;
                            }
                            $('#reportrange span').html(start.format('MM/DD/YYYY') + ' to ' + end.format('MM/DD/YYYY'));
                            scope.filter.startDate = start.format('YYYY-MM-DD');
                            scope.filter.endDate = end.format('YYYY-MM-DD');
                            // on change call
                            scope.loadBuckets();
                            if (scope.user.isUserServicePortal) {
                                scope.getTypeCount();
                            }
                        }, 0);
                    }

                    $('#reportrange').daterangepicker({
                        startDate: moment(scope.filter.startDate),
                        endDate: moment(scope.filter.endDate),
                        opens: 'right'
                    }, cb);

                    var text = moment(scope.filter.startDate).format('MM/DD/YYYY') + ' to ' + moment(scope.filter.endDate).format('MM/DD/YYYY');
                    $('#reportrange span').html(text);
                }

                var init = function () {
                    killClientWatch = $activeClient(function (current) {
                        if (!current) {
                            return;
                        }
                        initDateRangePicker();
                        scope.user = $auth.getCurrentUser();
                        currentCli = current;
                        scope.client = current;
                        scope.hasPermission.changeStatus = scope.user.permissions.includes('UPDATE_SERVICE_JOB_STATUS') || false;
                        scope.hasPermission.quoteServiceJob = scope.user.permissions.includes('QUOTE_SERVICE_JOB') || false;
                        scope.hasPermission.acceptedServiceJob = scope.user.permissions.includes('SERVICE_JOB_ACCEPTED') || false;
                        scope.hasPermission.editServiceJobAttachment = scope.user.permissions.includes('EDIT_SERVICE_JOB_ATTACHMENT') || false;
                        if (scope.user.isUserServicePortal) {
                            scope.getTypeCount();
                        }
                        scope.loadBuckets();

                        // setTimeout(() => {
                        //     socket.forward('refresh', scope);
                        // }, 1000)
                        // scope.$on('socket:refresh', function (ev, data) {
                        //     console.log('data received on', ev, data);
                        //     console.log('socket update')
                        //     if (data) {
                        //         scope.getBucket(data);
                        //     } else {
                        //         scope.loadBuckets();
                        //     }
                        // });
                    });
                }

                scope.getTypeCount = function () {
                    $servicePanel.getServiceCount(currentCli, scope.filter).then((res) => {
                        if (res.status) {
                            scope.data.counts = res.result;
                        }
                    }, err => {
                        console.log(err)
                    })
                }

                scope.loadBuckets = function () {
                    if (scope.data.isLoading) return;
                    scope.data.isLoading = true;
                    scope.data.bucketKeys = [];
                    $servicePanel.getServiceStatus(scope.client, scope.filter)
                        .then((res) => {
                            if (res.status) {
                                scope.data.buckets = res.result;
                                scope.data.bucketKeys =  Object.keys(scope.data.buckets);
                                scope.data.bucketKeys.sort((a, b) => `${scope.serviceStatusWithBucketName[a].position}`.localeCompare(`${scope.serviceStatusWithBucketName[b].position}`, undefined, { numeric: true }))
                                if (openServiceStatusBucket && scope.data.buckets[openServiceStatusBucket]) {
                                    scope.data.buckets[openServiceStatusBucket].expand = true;
                                    scope.getBucket(openServiceStatusBucket)
                                }
                            } else {
                                scope.data.buckets = {}
                            }
                            scope.data.isLoading = false;
                        }, err => {
                            let errMessage = err.message || err;
                            if (err && err.data && err.data.message) errMessage = err.data.message;
                            scope.data.error = errMessage;
                            scope.data.isLoading = false;
                        })
                }

                scope.getBucket = function (bucketKey) {
                    if (scope.data.buckets[bucketKey].isLoading) return;
                    scope.data.buckets[bucketKey].isLoading = true;
                    scope.data.buckets[bucketKey].error = null;
                    if (!scope.data.buckets[bucketKey].paginate) {
                        scope.data.buckets[bucketKey].paginate = {
                            currentPage: 1,
                            page: 1,
                            total: 0,
                            pageSize: 20,
                            itemsPerPage: 20
                        }
                    }
                    if (!scope.data.buckets[bucketKey].sorting) {
                        scope.data.buckets[bucketKey].sorting = {
                            orderByValue: '-1',
                            orderByField: 'createdAt'
                        }
                    }
                    let limit = scope.data.buckets[bucketKey].paginate.itemsPerPage;
                    let offset = (scope.data.buckets[bucketKey].paginate.page - 1) * scope.data.buckets[bucketKey].paginate.itemsPerPage;
                    let orderByValue = scope.data.buckets[bucketKey].sorting.orderByValue;
                    let orderByField = scope.data.buckets[bucketKey].sorting.orderByField;
                    let filters = Object.assign({ bucketKey, limit, offset, orderByValue, orderByField }, scope.filter)
                    $servicePanel.getServiceByStatus(scope.client, filters)
                        .then((res) => {
                            if (res.status) {
                                scope.data.buckets[bucketKey].records = res.records || [];
                                scope.data.buckets[bucketKey].paginate.total = res.counts;
                                scope.data.buckets[bucketKey].isLoading = false;
                                // res.records.map((obj) => {
                                //     if (obj && obj._id) {
                                //         // console.log(socket)
                                //         socket.emit('subscribe', obj._id);
                                //     }
                                // })
                            }
                        }, err => {
                            let errMessage = err.message || err;
                            if (err && err.data && err.data.message) errMessage = err.data.message;
                            scope.data.buckets[bucketKey].error = errMessage;
                            scope.data.buckets[bucketKey].isLoading = false;
                        })
                }

                scope.loadStatusData = function (bucketKey) {
                    if (scope.data.buckets[bucketKey].paginate) {
                        scope.data.buckets[bucketKey].paginate.page = 1;
                    }
                    scope.getBucket(bucketKey)
                }

                scope.sortRecord = function (bucketName, colName) {
                    if (scope.data.buckets[bucketName].sorting.orderByField == colName && scope.data.buckets[bucketName].sorting.orderByValue == '-1') {
                        scope.data.buckets[bucketName].sorting.orderByValue = '1';
                    } else {
                        scope.data.buckets[bucketName].sorting.orderByValue = '-1'
                    }
                    scope.data.buckets[bucketName].sorting[colName] = scope.data.buckets[bucketName].sorting.orderByValue == '1' ? false : true

                    scope.data.buckets[bucketName].sorting.orderByField = colName;

                    scope.data.buckets[bucketName].paginate.page = 1;
                    scope.getBucket(bucketName);
                }

                scope.convertArrToStringLanguages = function (langs) {
                    let language = []
                    langs.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
                    langs.map((item) => {
                        if (item && item.name) {
                            language.push(item.name);
                        }
                    })
                    return language.join(', ');
                }

                scope.changeStatusModal = function (bucketItem) {
                    openServiceStatusBucket = bucketItem.status;
                    Modal.servicePanel.serviceStatus({ bucketItem }, function (result) {
                        if (result.status) {
                            scope.loadBuckets()
                        }
                    })()
                }

                scope.$on('$destroy', function () {
                    if (killClientWatch) {
                        killClientWatch();
                    }
                });

                scope.showServiceJobAttachment = function (record) {
                    Modal.servicePanel.showServiceJobAttachment(record, function (res) {
                    })();
                }

                scope.addServiceJobAttachment = function (record) {
                    Modal.servicePanel.addServiceJobAttachment(record, function (res) {
                        if (res && res.status) {
                            scope.getBucket(record.status);
                        }
                    })();
                }

                scope.quoteRequested = function (record, title) {
                    Modal.servicePanel.quoteRequested(record, title, false, function (res) {
                        if (res.status) {
                            if (res.result && res.result.status && record.status == res.result.status) {
                                scope.getBucket(res.result.status);
                            } else {
                                scope.loadBuckets();
                            }
                        }
                    })();
                }

                scope.acceptWorkAction = function (record, title) {
                    Modal.servicePanel.acceptWorkAction(record, title, 'work_accepted', function (res) {
                        if (res.status) {
                            if (res.result && res.result.status && record.status == res.result.status) {
                                scope.getBucket(res.result.status);
                            } else {
                                scope.loadBuckets();
                            }
                        }
                    })();
                }

                scope.showEventBtn = function (bucketKey) {
                    if (['quote_requested'].indexOf(bucketKey) >= 0) {
                        return true;
                    }
                    return false;
                }

                scope.setWidth = function (name) {
                    return ['Transcription', 'Captioning'].indexOf(name) >= 0;
                }

                scope.showServiceDetail = function (serviceType) {
                    scope.filter.service = serviceType;
                    scope.loadBuckets();
                }

                init();
            }
        }
    } 
 

// Dependency Injection
projectHistoryDirective.$inject = ['$rootScope', '$state', '$stateParams', '$auth', '$timeout', '$clientSwitcher', '$activeClient', '$servicePanel', 'Modal', '$Linguist', 'socket', '$serviceStatusWithBucketName'];

// angular.module('coordinateApp').directive('projectHistory', projectHistoryDirective);
