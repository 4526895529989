


'use strict';

export const ClientReportBucketsFactory  = function ($resource, appConfig) {
    return $resource(appConfig.apiUrl + '/api/report-buckets/:id/:controller', {
      id: '@_id'
    },
      {
        create: {
          method: 'POST',
          params: {
            controller: 'create'
          }
        },
        getReportBuckets: {
          method: 'GET',
          params: {
            id: '@id'
          }
        },
        getReportBucket: {
          method: 'GET',
          params: {
            id: '@id'
          }
        },
        update: {
          method: 'POST',
          params: {
            controller: 'update'
          }
        },
        delete: {
          method: 'POST',
          params: {
            controller: 'delete'
          }
        },
        getReportsLibraryFolders: {
          method: 'GET',
          params: {
            controller: 'get-reports-library-folders'
          }
        },
      });
  } 
 

// Dependency Injection
ClientReportBucketsFactory.$inject = ['$resource', 'appConfig'];
