


'use strict';

export const scoresTableDirective  = function ($q, $performTaskAudio, $performTaskScore, $performTaskTranscribeAudio) {
    return {
      restrict: 'E',
      templateUrl: 'app/account/dashboard/tasks/tabs/organization/performance/scores/scores.html',
      link: function ($scope) {
        // console.log("Scores - ",$scope.selectedEmployee)
        var DEFAULT_PAGINATION = {
          page: 1,
          pageSize: 10,
          totalSize: -1
        };
        $scope.textColorScores = {
          No: '#FFFFFF',
          Yes: '#FFFFFF',
          Unsure: '#FFFFFF',
          Outstanding: '#FFFFFF',
          'Very Good': '#FFFFFF',
          'Just Ok': '',
          'Very Poor': '#FFFFFF'
        }
        $scope.questions = $scope.selectedEmployee.questions;
        if ($scope.selectedEmployee.manager) {
          var promise = $scope.selectedEmployee.children.map(function (employee) {
            return {
              employee: employee.firstName + " " + employee.lastName,
              tasks: employee.tasks
            }
          })
          var employeeScoresDetails = [];
          $q.all(promise)
          .then(function (result) {
            result.forEach(function (res) {
              if (res.tasks) {
                res.tasks.forEach(function (task) {
                  var employeeData = {};
                  employeeData.date = task.created;
                  employeeData.score = task.taskData.data.score;
                  employeeData.sentiment = task.taskData.verbatim.rating;
                  employeeData.status = task.status;
                  employeeData.employee = res.employee
                  employeeData.task = task;
                  for (var i = 0; i < task.taskData.data.questions.length; i++) {
                    employeeData['q' + (i + 1)] = task.taskData.data.questions[i];
                  }
                  employeeScoresDetails.push(employeeData)
                })
              }
            })
            $scope.scores = employeeScoresDetails;
            if($scope.selectedEmployee.pagenation){
              $scope.paginationScores = angular.copy(DEFAULT_PAGINATION);
              $scope.paginationScores.totalSize = $scope.scores ? $scope.scores.length : 0;
            }else{
              $scope.paginationScores = angular.copy(DEFAULT_PAGINATION);
              $scope.paginationScores.pageSize = $scope.scores ? $scope.scores.length : 0;
              $scope.paginationScores.totalSize = $scope.scores ? $scope.scores.length : 0;
            }
          })
        } else {
          if ($scope.selectedEmployee.tasks && $scope.selectedEmployee.tasks.length > 0) {
            var employeeScoresDetails = [];
            $scope.selectedEmployee.tasks.forEach(function (task) {
              var employeeData = {};
              employeeData.date = task.created;
              employeeData.score = task.taskData.data.score;
              employeeData.sentiment = task.taskData.verbatim.rating;
              employeeData.status = task.status;
              employeeData.employee = $scope.selectedEmployee.firstName + " " + $scope.selectedEmployee.lastName;
              employeeData.task = task;
              for (var i = 0; i < task.taskData.data.questions.length; i++) {
                employeeData['q' + (i + 1)] = task.taskData.data.questions[i];
              }
              employeeScoresDetails.push(employeeData)
            })
            console.log($scope.selectedEmployee.tasks)
            console.log(employeeScoresDetails);
            $scope.scores = employeeScoresDetails;
          }
          else {
            $scope.scores = [
              
            ];
          }
          if($scope.selectedEmployee.pagenation){
            $scope.paginationScores = angular.copy(DEFAULT_PAGINATION);
            $scope.paginationScores.totalSize = $scope.scores ? $scope.scores.length : 0;
          }else{
            $scope.paginationScores = angular.copy(DEFAULT_PAGINATION);
            $scope.paginationScores.pageSize = $scope.scores ? $scope.scores.length : 0;
            $scope.paginationScores.totalSize = $scope.scores ? $scope.scores.length : 0;
          }
        }

        $scope.isValidNumber = function (value) {
          return value && !isNaN(+value);
        };

        $scope.listenTaskAudio = $performTaskAudio(function (result) {
          // console.log('modal listen to audio result:', result);
        });

        $scope.showTaskScore = $performTaskScore(function (result) {
          // console.log('modal listen task audio result:', result);
        },
          function (result, scratchPad, task) {
            // console.log('modal transcribe task audio result:', result, scratchPad, task);
          },
          $scope.onAssign,
          $scope.onResolve
        );

        $scope.performTranscribeAudio = $performTaskTranscribeAudio(function (result, scratchPad, task) {
          // console.log('modal transcribe task audio result:', result, scratchPad, task);
        });

        $scope.searchEmployee = function(rec){
          if(!$scope.model.searchEmployeeStr || $scope.model.searchEmployeeStr == ''){
            return true;
          }else{
            return (rec.employee).toLowerCase().indexOf($scope.model.searchEmployeeStr.toLowerCase()) >= 0; 
          }          
        }

      }
    };
  } 
 

// Dependency Injection
scoresTableDirective.$inject = ['$q', '$performTaskAudio', '$performTaskScore', '$performTaskTranscribeAudio'];
