


'use strict';

export const ClientLanguagesFactory  = function ($resource, appConfig) {
    return $resource(appConfig.apiUrl + '/api/languages/:id/:controller', {
      id: '@_id'
    },
      {
        create: {
          method: 'POST',
          params: {
            controller: 'create'
          }
        },
        getLanguages: {
          method: 'GET',
          params: {
            id: '@id'
          }
        },
        getLanguageById: {
          method: 'GET',
          params: {
            id: '@id'
          }
        },
        update: {
          method: 'POST',
          params: {
            controller: 'update'
          }
        },
        getLanguagesList: {
          method: 'GET',
          params: {
            controller: 'get-languages-list'
          }
        },
        getProgramLangCategory: {
          method: 'GET',
          params: {
            controller: 'get-program-lang-category',
          }
        }
      });
  } 
 

// Dependency Injection
ClientLanguagesFactory.$inject = ['$resource', 'appConfig'];
