


'use strict';

export const NotificationManagementFactory  = function ($resource, appConfig) {
        return $resource(appConfig.apiUrl + '/api/notification-management/:id/:controller', {
            id: '@_id'
        },
            {
                getNotificationEvents: {
                    method: 'GET',
                    params: {
                        controller: 'get-notification-events'
                    }
                },
                create: {
                    method: 'POST',
                    params: {
                        controller: 'create'
                    }
                },
                update: {
                    method: 'POST',
                    params: {
                        controller: 'update'
                    }
                },
                delete: {
                    method: 'POST',
                    params: {
                        controller: 'delete',
                    }
                }
            });
    } 
 

// Dependency Injection
NotificationManagementFactory.$inject = ['$resource', 'appConfig'];
