'use strict';


export const sentimentDirective = function (Modal) {
        return {
            restrict: 'E',
            templateUrl: 'components/forms/survey/sentiment.html',
            scope: {
                AddSentiment: '=ngModel',
                onSubmit: '=formOnSubmit',
                onReset: '=formOnReset',
                eventLabel: '=event',
                sentiments: '=saveData',
                sentimentType: '=sentimentType'
            },
            link: function (scope, el, attrs) {
                scope.addSentiment = function () {
                    scope.AddSentiment.subtype.push({
                        subtypeName: ''
                    })
                }
                scope.deleteSentimentSubType = function (index) {
                    var sentimentName = scope.AddSentiment.subtype[index];

                    Modal.confirm.show(
                        "Confirm Delete",
                        "Are you sure you want to delete sentiment <strong>" + sentimentName.subtypeName + "</strong> ?",
                        "Delete", "Cancel",
                        function () {
                            scope.AddSentiment.subtype.splice(index, 1);
                            if (scope.AddSentiment.subtype.length === 0) {
                                scope.AddSentiment.subtype.push({
                                    subtypeName: ''
                                })
                            }
                        });
                };
                scope.reset = function () {
                    scope.onReset();
                };
            }
        }
    }
// Dependency Injection
sentimentDirective.$inject = ["Modal"];
