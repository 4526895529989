
'use strict';

export const $clientDivisionService  = function (ClientDivision) {
    return {
      listAll: function(client) {
        return ClientDivision.query({
          id: client._id || client
        }).$promise;
      },

      detail: function(clientId, divisionId) {
        return ClientDivision.detail({
          id: clientId._id||clientId,
          divisionId: divisionId
        }).$promise;
      },

      create: function(clientId, division) {
        return ClientDivision.create({
          id: clientId._id||clientId,
          data: division
        }).$promise;
      },

      update: function(clientId, division) {
        return ClientDivision.update({
          _id: clientId._id||clientId,
          division: division._id,
          data: division
        }).$promise;
      }
    };
  }
// Dependency Injection


// Dependency Injection
$clientDivisionService.$inject = ['ClientDivision'];
