'use strict';

export const EmployeesConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard.settings.employees', {
        url: '/employees',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/employees/employees.html', '4e9b734a'); }],
        controller: 'DashboardSettingsEmployeesCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/employees/employees.controller.js', 'DashboardSettingsEmployeesCtrl'); }]
                      },
      })
      .state('app.account.dashboard.settings.employees.index', {
        url: '/employees',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/employees/index/index.html', '616086ea'); }],
        controller: 'DashboardSettingsEmployeesIndexCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/employees/index/index.controller.js', 'DashboardSettingsEmployeesIndexCtrl'); }]
                      }
      })
      .state('app.account.dashboard.settings.employees.update', {
        url: '/update/:id',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/employees/update/update.html', 'a24494aa'); }],
        controller: 'DashboardSettingsEmployeesUpdateCtrl',
        resolve: {
          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/employees/update/update.controller.js', 'DashboardSettingsEmployeesUpdateCtrl'); }],
          '$selectedEmployee': ['$stateParams','$clientEmployee', '$clientSwitcher', function ($stateParams, $clientEmployee, $clientSwitcher) {
            return $clientEmployee.detail($clientSwitcher.getCurrentClient(), $stateParams.id).then(function(res) {
              if(res.status) {
                var employee = res.data;

                employee.regions = employee.regions.map(function(region) {
                  return region._id;
                });

                employee.locations = employee.locations.map(function(region) {
                  return region._id;
                });

                employee.homeLocation = employee.homeLocation && employee.homeLocation._id;

                return employee;
              }
              else {
                throw new Error(res.reason);
              }
            });
          }]
        }
      })
      .state('app.account.dashboard.settings.employees.create', {
        url: '/create',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/employees/create/create.html', '1fbd6aca'); }],
        controller: 'DashboardSettingsEmployeesCreateCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/employees/create/create.controller.js', 'DashboardSettingsEmployeesCreateCtrl'); }]
                      },
      })
      .state('app.account.dashboard.settings.employees.upload', {
        url: '/upload',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/employees/upload/upload.html', 'c3e4b3aa'); }],
        controller: 'DashboardSettingsEmployeesUploadCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/employees/upload/upload.controller.js', 'DashboardSettingsEmployeesUploadCtrl'); }]
                      },
      });
  }
// Dependency Injection
EmployeesConfig.$inject = ["$stateProvider"];
