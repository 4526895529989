
'use strict';

export const $servicePanelService  = function (servicePanel) {
        return {
            getServiceCount: function (client, filter) {
                if (filter) {
                    filter = JSON.parse(JSON.stringify(filter))
                }
                return servicePanel.getServiceCount({
                    id: client._id || client,
                    filter
                }).$promise;
            },
            getServiceDetail: function (client, filter) {
                if (filter) {
                    filter = JSON.parse(JSON.stringify(filter))
                }
                return servicePanel.getServiceDetail({
                    id: client._id || client,
                    filter: filter || {}
                }).$promise;
            },
            getServiceById: function (client, serviceId) {
                return servicePanel.getServiceById({
                    id: client._id || client,
                    controller: serviceId
                }).$promise;
            },
            getServiceStatus: function (client, filter) {
                return servicePanel.getServiceStatus({
                    id: client._id || client,
                    filter
                }).$promise;
            },
            getServiceByStatus: function (client, filter) {
                return servicePanel.getServiceByStatus({
                    id: client._id || client,
                    filter
                }).$promise;
            },
            create: function (client, data) {
                return servicePanel.create({
                    _id: client._id || client,
                    data
                }).$promise;
            },
            update: function (client, data) {
                return servicePanel.update({
                    _id: client._id || client,
                    data
                }).$promise;
            },
            updateStatus: function (client, data) {
                return servicePanel.updateStatus({
                    _id: client._id || client,
                    data
                }).$promise;
            },
            removeAttachment: function (client, serviceId, attachments) {
                return servicePanel.removeAttachments({
                    _id: client._id || client,
                    serviceId,
                    attachments,
                }).$promise;
            },
            compileQuote: function (client, data) {
                return servicePanel.compileQuote({
                    _id: client._id || client,
                    data
                }).$promise;
            },
        };
    }
// Dependency Injection


// Dependency Injection
$servicePanelService.$inject = ['servicePanel'];
