


'use strict';

export const TaskCreateRulesFactory  = function ($resource, appConfig) {
    return $resource(appConfig.apiUrl + '/api/task-create-rules/:id/:controller', {
      id: '@_id'
    },
    {
      index: {
        method: 'GET',
        params: {
          controller: ''
        }
      },
      create: {
        method: 'POST',
        params: {
          controller: 'create'
        }
      },
      update: {
        method: 'POST',
        params: {
          controller: 'update'
        }
      },
      delete: {
        method: 'POST',
        params: {
          controller: 'delete'
        }
      },
      execute: {
        method: 'POST',
        params: {
          controller: 'execute'
        }
      },
      getLogs: {
        method: 'GET',
        params: {
          controller: 'logs'
        }
      },
      toggle: {
        method: 'POST',
        params: {
          controller: 'toggle'
        }
      }
    });
  } 
 

// Dependency Injection
TaskCreateRulesFactory.$inject = ['$resource', 'appConfig'];
