


'use strict';

export const SosnotsFactory  = function ($resource, appConfig) {
    return $resource(appConfig.apiUrl + '/api/sosnots/:id/:controller', {
        id: '@_id'
    },
        {
            getSosnotsList: {
                method: 'GET',
                params: {
                    controller: 'get-sosnots-list'
                }
            },
            getSosnotsDetails: {
                method: 'POST',
                params: {
                    id: '@id',
                    controller: 'get-sosnots-details'
                }
            }
        });
} 
 

// Dependency Injection
SosnotsFactory.$inject = ['$resource', 'appConfig'];
