
export const UsersSessionFactory = function ($resource, appConfig) {
        return $resource(appConfig.apiUrl + '/api/users-session/:id/:controller', {
            id: '@_id'
        },
            {
                getUsersSession: {
                    method: 'GET',
                    params: {
                        id: '@id'
                    }
                },
                deleteUserSession: {
                    method: 'POST',
                    params: {
                        id: '@id',
                        controller: 'delete-user-session'
                    }
                }
            });
    } 
 export const $usersSessionService = function (UsersSession) {
        return {
            getUsersSession: function (client, filter, page, pageSize) {
                return UsersSession.getUsersSession({
                    id: client._id || client,
                    filter: filter || {},
                    page,
                    pageSize
                }).$promise;
            },
            deleteUserSession: function (client, token) {
                return UsersSession.deleteUserSession({
                    id: client._id || client,
                    token
                }).$promise;
            }
        };
    }
// Dependency Injection
UsersSessionFactory.$inject = ["$resource","appConfig"];
$usersSessionService.$inject = ["UsersSession"];
