'use strict';

export const InvoicesConfig = function ($stateProvider) {
        $stateProvider
            .state('app.account.dashboard.invoices', {
                url: '/invoices',
                params: {
                    status: null,
                },
                data: {
                    ability: 'client.serviceInvoices',
                    permission: 'VIEW_SERVICE_INVOICES'
                },
                templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/services/invoices/invoices.html', 'fd3645aa'); }],
                controller: 'ServiceInvoicesCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/services/invoices/invoices.controller.js', 'ServiceInvoicesCtrl'); }]
                      }
            })
            .state('app.account.dashboard.invoiceDetail', {
                url: '/invoices/:id',
                data: {
                    ability: 'client.serviceProjects',
                    permission: 'VIEW_SERVICE_PROJECTS'
                },
                templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/services/invoices/invoice-detail/invoice-detail.html', '4ecaa805'); }],
                controller: 'ServiceInvoiceDetailCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/services/invoices/invoice-detail/invoice-detail.controller.js', 'ServiceInvoiceDetailCtrl'); }]
                      }
            })
    }
// Dependency Injection
InvoicesConfig.$inject = ["$stateProvider"];
