'use strict';

export const DashboardSettingsTablesIndexCtrl = function ($state, $scope, $clientSwitcher, Modal, $tablesManager, ngToast, $interval) {
	$scope.client = $clientSwitcher.getCurrentClient();
	$scope.clientName = $scope.client.name;
	$scope.tables = [];

	var getTables = function() {
		$scope.loading = true;

		$tablesManager.getTables($scope.client._id)
		.then(function(res) {
			if(res.status) {
				$scope.tables = res.data;
			}
		})
		.finally(function() {
			$scope.loading = false;
		});
	};

	$scope.deleteTable = function(table) {
		Modal.confirm.show('Remove Table', 'Are you sure you want to remove <b>' + table.title + '</b>?', 'Yes', 'No', function(){
			$tablesManager.deleteTable($scope.client, table).then(function(data){
				if(data.status){
					$scope.tables.splice( $scope.tables.indexOf(table) , 1);
				}else{
					$scope.message = data.reason;
				}
			});
		});
	};

	$scope.editTable = function(table) {
		$state.go('app.account.dashboard.settings.tables.update', {id: table._id});
	};

	getTables();
}
// Dependency Injection
DashboardSettingsTablesIndexCtrl.$inject = ["$state","$scope","$clientSwitcher","Modal","$tablesManager","ngToast","$interval"];

// angular.module('coordinateApp').controller('DashboardSettingsTablesIndexCtrl', DashboardSettingsTablesIndexCtrl);
