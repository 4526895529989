export const clientDeviceDirective = function () {
  return {
    restrict: 'E',
    require: 'ngModel',
    replace: true,
    templateUrl: 'app/account/dashboard/settings/devices/index/device.directive.html',
    scope: {
      device: '=ngModel',
      delete: '=onDelete',
      toggle: '=onToggle',
      edit: '=onEdit'
    },
    link: function (scope, el, attrs) {
      scope.expandDevice = function(device) {
      	device.expand = !device.expand;
      };
    }
  };
}