import './services.scss';
'use strict';

export const ServicesConfig = function ($stateProvider, $urlRouterProvider) {
    $urlRouterProvider.otherwise('/services');
    $stateProvider
      .state('app.account.services', {
        // abstract: true,
        url: '/services',
        data: {
          roles: [],
          // meta: {
          //   'title': 'Elisa',
          //   'description': 'SOSi One services provides powerful web and mobile application to enable instant access to interpreters and other services in hundreds of languages and locations.'
          // }
        },
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/services/services.html', '4590ee2a'); }],
        controller: 'ServicesCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/services/services.controller.js', 'ServicesCtrl'); }]
                      }
      })
      .state('app.account.services.index', {
        url: '',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/services/index/index.html', '1b136bab'); }],
        controller: 'ServiceIndexCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/services/index/index.controller.js', 'ServiceIndexCtrl'); }]
                      },
        data: {

        }
      })
      .state('app.account.services.dashboard', {
        url: '/dashboard',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/services/dashboard/serviceDashboard.html', '4bbfc5f7'); }],
        controller: 'ServiceDashboardCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/services/dashboard/serviceDashboard.controller.js', 'ServiceDashboardCtrl'); }]
                      },
        params: {
          status: null,
        },
        data: {
          roles: ['user'],
          ability: 'client.serviceList',
          permission: 'VIEW_SERVICE_PANEL'
        }
      })
  }
// Dependency Injection
ServicesConfig.$inject = ["$stateProvider","$urlRouterProvider"];
