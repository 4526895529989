


'use strict';

export const ClientSubClientFactory  = function ($resource, appConfig) {
    return $resource(appConfig.apiUrl + '/api/client-subs/:id/:controller', {
      id: '@_id'
    },
    {
      // ...
    });
  } 
 

// Dependency Injection
ClientSubClientFactory.$inject = ['$resource', 'appConfig'];
