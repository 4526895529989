
'use strict';

export const $performTaskTranscribeAudioService  = function (SurveyModal) {
  return function(cb) {
    cb = angular.isFunction(cb) ? cb : angular.noop;
    return SurveyModal.taskActions.transcribeTaskAudio(function (result, scratchPad, task) {
      return cb.call(this, result, scratchPad, task);
    });
  };
}
 

// Dependency Injection
$performTaskTranscribeAudioService.$inject = ['SurveyModal'];
