'use strict';


export const $convertDateService = function ($auth) {

	function process(date, tz) {
		var timezone = tz || $auth.getCurrentUser().timezone || moment.tz.guess();
		var momentDate = null;

		if(!moment.isMoment(date)) {
			momentDate = moment(date);
		}

		var dateObject = momentDate.toObject();
		
		if(momentDate.toDate().toString().indexOf(timezone) > 0){
			return momentDate.toObject();
		}else{
			return moment.tz(timezone).set(dateObject).format();
		}
	}

	function getDateObject(date, tz) {		
		var timezone = tz || $auth.getCurrentUser().timezone || moment.tz.guess();
		var momentDate = null;

		if(!moment.isMoment(date)) {
			momentDate = moment(date);
		}
		
		if(momentDate.toDate().toString().indexOf(timezone) > 0){
			return momentDate.toObject();
		}else{
			var newDate = moment.tz(momentDate, timezone).toObject();
			// console.log('Converted', moment(date).format('MM/dd/yyyy hh:mm a'), 'To', moment(newDate).format('MM/dd/yyyy hh:mm a'));
			return newDate;
		}		
	}

  return {
		process: process,
		getDateObject: getDateObject
  };
}
// Dependency Injection
$convertDateService.$inject = ["$auth"];
