
'use strict';

export const formErrorInputDirective  = function ($parse) {
  return {
    restrict: 'A',
    require: '^formErrors',
    templateUrl: 'components/util/form-errors-input.html',
    scope: {}, // isolate scope
    link: function (scope, el, attr, ctrl) {
      var fieldKey = null;

      attr.$observe('formErrorInput', function (key) { fieldKey = key; });
      Object.defineProperties(scope, {
        fieldValue: {
          get: function () {
            if(!fieldKey||!angular.isObject(ctrl.fieldErrors))
              return null;

            if(!ctrl.fieldErrors.hasOwnProperty(fieldKey))
              return null;

            return ctrl.fieldErrors[fieldKey]
          }
        }
      });

      scope.isArray  = function () { return angular.isArray(scope.fieldValue); };
      scope.isString = function () { return angular.isString(scope.fieldValue); };
    }
  };
}
// Dependency Injection


// Dependency Injection
formErrorInputDirective.$inject = ['$parse'];
