'use strict';


export const $stringService = function () {

  var caseInsensitiveProtoFn = String.prototype.toLowerCase;

  String.prototype.capitalize = function(){
      return this.toLowerCase().replace( /\b\w/g, function (m) {
          return m.toUpperCase();
      });
  };
  
  function validScope (v) {
    return v !== null && v !== undefined;
  }

  function asString (v) {
    if(angular.isString(v)) {
      return v;
    }

    return String(v);
  }

  function compareCaseSensitive (a, b) {
    a = asString (a);
    b = asString (b);

    if(a > b) return  1;
    if(a < b) return -1;
    return 0;
  }

  function compareCaseInsensitive (a, b) {
    return compareCaseSensitive (
      validScope(a) ? caseInsensitiveProtoFn.call(a) : a,
      validScope(b) ? caseInsensitiveProtoFn.call(b) : b
    );
  }

  function inStringCaseSensitive (haystack, needle, startIndex) {
    startIndex = (angular.isNumber(startIndex) && startIndex >= -1) ? startIndex : -1;
    return asString(haystack).indexOf(asString(needle)) > startIndex;
  }

  function inStringCaseInsensitive (haystack, needle, startIndex) {
    return inStringCaseSensitive (
      validScope(haystack) ? caseInsensitiveProtoFn.call(haystack) : haystack,
      validScope(needle)   ? caseInsensitiveProtoFn.call(needle)   : needle,
      startIndex
    );
  }

  return {
    asString: asString,
    compareCaseSensitive: compareCaseSensitive,
    compareCaseInsensitive: compareCaseInsensitive,
    inStringCaseSensitive: inStringCaseSensitive,
    inStringCaseInsensitive: inStringCaseInsensitive
  };
}