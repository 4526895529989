'use strict';


 export const DashboardSettingsOfficesIndexCtrl = function ($rootScope, $scope, $activeClient, $clientOffice, $client, Modal, $activeClientSettings, $auth, DEFAULT_DEBOUNCE) {

    var currentUser = $auth.getCurrentUser();
    $scope.loading = false;
    $scope.regions = [];
    $scope.search = {
      locationName: '',
      selectedRegion: '',
      orderVal: 'true'
    };

    $scope.sortingOrders = [{
      label: 'A-Z',
      value: 'true'
    }, {
      label: 'Z-A',
      value: 'false'
    }];

    $scope.offices = [];
    $scope.officeDataCount = 0;
    $scope.isLoading = false;
    $scope.error = null;
    $scope.pagination = {
      currentPage: 1,
      page: 1,
      total: 0,
      pageSize: 20,
      itemsPerPage: 20
    };

    $scope.filter = {};
    $scope.totalActiveOffice = 0;

    $scope.loadRegions = function () {
      $scope.regions = [];
      $scope.allowEdit = $auth.getCurrentUser().permissions.includes('SETTING_REPORTING_COURT_EDIT');
      $scope.offices.forEach(function (office) {
        var isRegionAlreadyAdded = false;

        for (var i = 0; i < $scope.regions.length; i++) {
          var region = $scope.regions[i];
          if (region.value === office.region._id) {
            isRegionAlreadyAdded = true;
            break;
          }
        }

        if (!isRegionAlreadyAdded && (office && office.region && office.region.name)) {
          $scope.regions.push({
            label: office.region.name,
            value: office.region._id
          });
        }

        if ($scope.regions && $scope.regions.length) {
          $scope.regions.splice(0, 0, {
            label: 'All',
            value: ''
          });
        }
      });
    };


    $scope.getOfficeList = function () {
      if ($scope.isLoading) return;
      $scope.isLoading = true;
      $scope.error = null;
      $scope.offices = [];
      $scope.pagination.total = 0;

      $scope.filter.orderByValue = $scope.search.orderVal == 'true' ? 'asc' : 'desc';
      $scope.filter.orderByField = 'name';
      $scope.filter.page = $scope.pagination.page;
      $scope.filter.pageSize = $scope.pagination.pageSize;
      if ($scope.search.locationName) {
        $scope.filter.searchKey = $scope.search.locationName;
      }
      // $scope.filter.isIncludeActive = true;
      $scope.filter.selectedRegion = $scope.search.selectedRegion;
      $clientOffice.getOfficeList($scope.client, $scope.filter)
        .then((res) => {
          if (res.status) {
            if (res.officeList && res.officeList.length) {
              $scope.offices = res.officeList;
              $scope.pagination.total = res.totalRecords;
              $scope.officeDataCount = res.totalRecords;
              $scope.totalActiveOffice = res.totalActiveOffice;
            } else {
              $scope.error = 'No Record Found.';
              $scope.officeDataCount = 0;
              $scope.totalActiveOffice = 0;
            }
            $scope.loadRegions();
          }
        })
        .catch((err) => {
          let errMessage = err.message || err;
          if (err && err.data && err.data.message) {
            errMessage = err.data.message;
          } else if (err && err.data && err.data.reason) {
            errMessage = err.data.reason;
          }
          $scope.error = errMessage;
          $scope.officeDataCount = 0;
          $scope.totalActiveOffice = 0;
        })
        .finally(() => {
          $scope.isLoading = false;
          $scope.data.isLoading = false;
        })
    }

    function reset() {
      $scope.pagination = {
        currentPage: 1,
        page: 1,
        total: 0,
        pageSize: 20,
        itemsPerPage: 20
      };
      $scope.filter = {};
    }

    $scope.applySearch = _.debounce(function (cv) {
      reset();
      $scope.getOfficeList();
    }, DEFAULT_DEBOUNCE)

    $scope.onSearch = function () {
      $scope.applySearch();
    }

    $scope.onSorting = function () {
      reset();
      $scope.getOfficeList();
    }

    $scope.onRegionChange = function (e) {
      if (e) {
        reset();
        $scope.getOfficeList();
      }
    }

    function reloadOffices(client) {
      $scope.loading = true;
      return $client.getOffices(client)
        .then(function (offices) {
          if (currentUser.settings.allowRegions.length > 0) {
            var userOffices = []
            offices.forEach((office) => {
              if (currentUser.settings.allowRegions.indexOf(office.region._id) > -1) {
                userOffices.push(office)
              }
            })
            $scope.offices = userOffices;
          } else {
            $scope.offices = offices;
          }
          $scope.activeOffices = 0;
          $scope.offices.forEach(function (o) {
            if (o.isEnabled) $scope.activeOffices++;
          });

          $scope.loadRegions();
        })
        .finally(function () {
          $scope.loading = false;
        });
    }

    // listen for client switching
    $scope.$on('$destroy', $activeClient(function (client) {
      if (!client) return;
      $scope.client = client;
      $scope.getOfficeList();
    }));

    // Delete office
    $scope.deleteOffice = function (office) {
      // $activeClient(function (client) {
      //   if(!client) {
      //     return;
      //   }
      Modal.confirm.show(
        "Confirm Delete",
        "Are you sure you want to delete <strong>" + office.name + "</strong>",
        "Delete", "Cancel",
        function () {
          $clientOffice.deleteOffice($scope.client, office).then(function () {
            $scope.getOfficeList();
          });
        });
      // });
    };

    $scope.ivrSync = function () {
      $activeClient(function (client) {
        if (!client) {
          return;
        }

        Modal.confirm.show(
          "Confirm Sync",
          "Are you sure you want to sync all the active locations with IVR again ?",
          "Yes", "No",
          function () {
            $clientOffice.ivrSync(client).then(function () {

            });
          });
      });
    }

    $activeClientSettings(function (client, settings) {
      $scope.supportsIVR = settings.supportsIVR;
    })
  }
// Dependency Injection
DashboardSettingsOfficesIndexCtrl.$inject = ["$rootScope","$scope","$activeClient","$clientOffice","$client","Modal","$activeClientSettings","$auth","DEFAULT_DEBOUNCE"];

// angular.module('coordinateApp').controller('DashboardSettingsOfficesIndexCtrl', DashboardSettingsOfficesIndexCtrl);
