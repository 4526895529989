'use strict';

export const RulesConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard.settings.rules', {
        url: '/rules',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/rules/rules.html', '370b4c4a'); }],
        controller: 'DashboardSettingsRulesCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/rules/rules.controller.js', 'DashboardSettingsRulesCtrl'); }]
                      },
        data: {
          ability: 'manage.client.users'
        }
      })
      .state('app.account.dashboard.settings.rules.index', {
        url: '/index',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/rules/index/index.html', 'f273be2a'); }],
        controller: 'DashboardSettingsRulesIndexCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/rules/index/index.controller.js', 'DashboardSettingsRulesIndexCtrl'); }]
                      },
        data: {
          ability: 'manage.client.users'
        }
      })
      .state('app.account.dashboard.settings.rules.create', {
        url: '/create',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/rules/create/create.html', 'eb8070a'); }],
        controller: 'DashboardSettingsRulesCreateCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/rules/create/create.controller.js', 'DashboardSettingsRulesCreateCtrl'); }]
                      },
        data: {
          ability: 'manage.client.users'
        }
      })
      .state('app.account.dashboard.settings.rules.update', {
        url: '/update/:id',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/rules/update/update.html', '886897ea'); }],
        controller: 'DashboardSettingsRulesUpdateCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/rules/update/update.controller.js', 'DashboardSettingsRulesUpdateCtrl'); }],
                          '$selectedRule': ['$stateParams','$rulesManager', '$clientSwitcher', function ($stateParams, $rulesManager, $clientSwitcher) {
                            let rule =  $rulesManager.getRuleDetails($clientSwitcher.getCurrentClient(), $stateParams.id);
                            return Object.assign(rule, {langType: ''})
                          }]
                      },
        data: {
          ability: 'manage.client.users'
        },
      })
      .state('app.account.dashboard.settings.rules.logs', {
        url: '/logs',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/rules/logs/logs.html', 'd3cadf2a'); }],
        controller: 'DashboardSettingsRulesExecutionLogsCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/rules/logs/logs.controller.js', 'DashboardSettingsRulesExecutionLogsCtrl'); }]
                      },
        data: {
          ability: 'manage.client.users'
        }
      });
  }
// Dependency Injection
RulesConfig.$inject = ["$stateProvider"];
