'use strict';

export const PermissionsConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard.settings.permissions', {
        url: '/permissions',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/permissions/permissions.html', '98aec44a'); }],
        controller: 'DashboardSettingsPermissionsCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/permissions/permissions.controller.js', 'DashboardSettingsPermissionsCtrl'); }]
                      }
      })
      .state('app.account.dashboard.settings.permissions.index', {
        url: '/index',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/permissions/index/index.html', 'd0b7954a'); }],
        controller: 'DashboardSettingsPermissionsIndexCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/permissions/index/index.controller.js', 'DashboardSettingsPermissionsIndexCtrl'); }]
                      }
      })
      .state('app.account.dashboard.settings.permissions.create', {
        url: '/create',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/permissions/create/create.html', '6e090faa'); }],
        controller: 'DashboardSettingsPermissionsCreateCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/permissions/create/create.controller.js', 'DashboardSettingsPermissionsCreateCtrl'); }]
                      }
    })
      .state('app.account.dashboard.settings.permissions.update', {
        url: '/update/:id',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/permissions/update/update.html', '34c3b00a'); }],
        controller: 'DashboardSettingsPermissionsUpdateCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/permissions/update/update.controller.js', 'DashboardSettingsPermissionsUpdateCtrl'); }],
                          '$selectedPermission': ['$stateParams', '$clientPermissions', '$clientSwitcher', function($stateParams, $clientPermissions, $clientSwitcher) {
                            return $clientPermissions.getPermissionById($clientSwitcher.getCurrentClient(), $stateParams.id);
                            }]
                      },
    });
  }
// Dependency Injection
PermissionsConfig.$inject = ["$stateProvider"];
