
'use strict';

export const $clientKnowledgeLibraryService  = function (ClientKnowledgeLibrary) {
    return {
      create: function (client, data) {
        return ClientKnowledgeLibrary.create({
          _id: client._id || client,
          name: data.name
        }).$promise;
      },
      update: function (client, data) {
        return ClientKnowledgeLibrary.update({
          _id: client._id || client,
          data
        }).$promise;
      },
      delete: function (client, folderId) {
        return ClientKnowledgeLibrary.delete({
          _id: client._id || client,
          folderId
        }).$promise;
      },
      getAll: function (client, filter) {
        return ClientKnowledgeLibrary.getAll({
          id: client._id || client,
          filter
        }).$promise;
      }
    };
  }
// Dependency Injection


// Dependency Injection
$clientKnowledgeLibraryService.$inject = ['ClientKnowledgeLibrary'];
