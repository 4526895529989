
'use strict';

export const tooltipDirective  = function ($compile) {
        return {
          restrict: 'A',
          link: function(scope, element, attrs) {
            element.tooltip(); // Initialize tooltip
      
            scope.$watch(attrs.tooltipDirective, function(newVal) {
              if (newVal) {
                element.tooltip('destroy').tooltip(); // Reinitialize tooltip when content changes
              }
            });
          }
        };
      } 
 

// Dependency Injection
tooltipDirective.$inject = ['$compile'];
