import './office-information.scss';
'use strict';

export const OfficeInformationConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard.officeInformation', {
        url: '/office-information',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/office-information/office-information.html', 'f8d2ce4a'); }],
        controller: 'DashboardOfficeInformationCtrl',
        resolve: {
            lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/office-information/office-information.controller.js', 'DashboardOfficeInformationCtrl'); }],
            '$userPermissions': ['$auth', function($auth) {
              return $auth.getCurrentUser();
            }]
        },
        data: {
          permission: 'REPORTING_COURT_INFO_READ'
        },
      });
  }
// Dependency Injection
OfficeInformationConfig.$inject = ["$stateProvider"];
