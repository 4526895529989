'use strict';

export const SupportConfig = function ($stateProvider) {
    $stateProvider
      .state('app.support', {
        url: '/support',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./support/support.html', '547ce0ca'); }],
        controller: 'SupportCtrl'
      });
  }
// Dependency Injection
SupportConfig.$inject = ["$stateProvider"];
