'use strict';

export const DashboardSettingsManagersUpdateCtrl = function ($scope, $state, $selectedManager, $clientSwitcher) {

    $scope.masterManager = angular.copy($selectedManager);

    $scope.onSubmit = function (form) {

      var
      currentCli = $clientSwitcher.getCurrentClient();

      if(form.$invalid || !currentCli) return;

      $scope.copyManager.$save({id: currentCli._id, controller: $scope.copyManager._id})
        .then(function (result) {
          $scope.masterManager = result;
          $scope.copyManager = angular.copy($scope.masterManager);

          $state.go('app.account.dashboard.settings.managers');
          return result;
        })
        .catch(function (result) {
          console.log('got back error:', result);
          return result;
        });
    };
    ($scope.onReset = function (form) {
      $scope.copyManager = angular.copy($scope.masterManager);
      if(form) form.$setUntouched();
    })();
  }
// Dependency Injection
DashboardSettingsManagersUpdateCtrl.$inject = ["$scope","$state","$selectedManager","$clientSwitcher"];
