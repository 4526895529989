


'use strict';

export const MobileAppManagerFactory  = function ($resource, appConfig) {
    return $resource(appConfig.apiUrl + '/api/app/:id/:controller', {
      id: '@_id'
    },
    {
      save: {
        method: 'POST',
        params: {
          controller: 'version'
        }
      },
      getApps: {
        method: 'GET',
        params: {
          controller: '',
          id: '@id'
        },
        isArray: true
      }
    });
  } 
 

// Dependency Injection
MobileAppManagerFactory.$inject = ['$resource', 'appConfig'];
