import './rule.scss';
export const clientRuleDirective = function (
  $clientSwitcher, $rulesLogsManager) {
  return {
    restrict: 'E',
    require: 'ngModel',
    replace: true,
    templateUrl: 'app/account/dashboard/settings/rules/index/rule.html',
    scope: {
      rule: '=ngModel',
      edit: '=onEdit',
      delete: '=onDelete',
      toggle: '=onToggle'
    },
    link: function (scope, el, attrs) {
      var rule = scope.rule;
      rule.activeDate = null;

      if(rule.startFrom) {
        rule.startFrom = moment(rule.startFrom).format('MM/DD/YYYY');
      }
      if(rule.stopOn) {
        rule.stopOn = moment(rule.stopOn).format('MM/DD/YYYY');
      }

      scope.taskActionLabels = {
        'create': 'Create Task',
        // 'reassign': 'Reassign Task',
        // 'resovlve': 'Resolve Task',
        // 'update': 'update task'
      };

      scope.taskPriorityLabels = {
        'high': 'High',
        'med-high': 'Meduim-High',
        'med-low': 'Medium-Low',
        'low': 'Low'
      };

      scope.expandRule = function(rule){
        rule.expand = !rule.expand;

        if(rule.expand){
          scope.downloadLogs(rule);
        }
      };

      scope.downloadLogs = function(rule){
        $rulesLogsManager.getLogs(rule).then(function(data){
          scope.ruleLogs = data.records;
        });
      };

      scope.clientStrings = function(){ return $clientSwitcher.getFeatureOptions('client.strings'); };

      scope.days = [
        {label: 'Sun', value: 0},
        {label: 'Mon', value: 1},
        {label: 'Tue', value: 2},
        {label: 'Wed', value: 3},
        {label: 'Thu', value: 4},
        {label: 'Fri', value: 5},
        {label: 'Sat', value: 6}
      ];

      scope.isDaySelected = function(day) {
        return rule.selectedDays.indexOf(day.value) > -1;
      };

      var days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
      function pad(num) {
          return ('0'+num).slice(-2);
      }
      function hhmm(minutes) {
        var hours = Math.floor(minutes/60);
        minutes = minutes%60;
        var time = hours >= 12 && minutes >= 0 ? ' PM': ' AM';
        hours = hours > 12 ? hours - 12 : hours;
        return pad(hours)+':'+pad(minutes) + time;
      }

      scope.ruleString = '';

      scope.ruleString = (scope.rule.title ? 'Rule ' + scope.rule.title : 'This rule') + ' will run between ' + hhmm(scope.rule.fromTime) + ' and ' + hhmm(scope.rule.fromTime + scope.rule.timeOffset) + ' starting from ' + scope.rule.startFrom;

      if(scope.repeat === 'days'){
        if(scope.rule.selectedDays.length > 0){
          scope.rule.selectedDays = _.sortBy(scope.rule.selectedDays);
          switch(scope.rule.mode) {
            case 'every': 
              scope.ruleString += ' on ' + ' ' + _.map(scope.rule.selectedDays, function(val){ return days[val]; }) + ' of every week';
              break;
            case 'every-other':
              scope.ruleString += ' on ' + ' ' + _.map(scope.rule.selectedDays, function(val){ return days[val]; }) + ' of every other week';
              break;
            case 'first-of-month':
              scope.ruleString += ' on First' + ' ' + _.map(scope.rule.selectedDays, function(val){ return days[val]; }) + ' of every month';
              break;
          }
        }
      }else if(scope.rule.selectedDates && scope.rule.selectedDates.length > 0){
        scope.ruleString += ' on ' + (scope.rule.selectedDates.length > 1 ? 'dates' : 'date') + ' ' + scope.rule.selectedDates[0];
        if(scope.rule.selectedDates.length > 1) {
          scope.ruleString += ' to '+scope.rule.selectedDates[scope.rule.selectedDates.length - 1];
        }

        switch(scope.rule.mode) {
          case 'every': 
          case 'first-of-month':
            scope.ruleString += ' of every month';
            break;
          case 'every-other':
            scope.ruleString += ' of every other month';
            break;
        }
      }
      scope.ruleString += ' till ' + scope.rule.stopOn;

      scope.timeRange = hhmm(scope.rule.fromTime) + ' to ' + hhmm(scope.rule.fromTime + scope.rule.timeOffset);
      
      scope.daysRange = function() {
        var value = '';
        var totalDaysSelected = scope.rule.selectedDays.length;

        _.each(scope.rule.selectedDays, function(dayIndex, index) {
          value += days[dayIndex];
          if(index !== totalDaysSelected - 1) {
            value += ', ';
          }
        });

        return value;
      };

      scope.getDays = function(minutes){
        var days = (minutes/60) / 24;
        if(days > 1){
          return days + " Days";
        }else{
          return days + " Day";
        }
      };

      if(scope.rule.selectedDates && scope.rule.selectedDates.length > 0){
        scope.dateRange = scope.rule.selectedDates[0] + ' to ' + scope.rule.selectedDates[rule.selectedDates.length - 1];
      }      
    }
  };
}
// Dependency Injection
clientRuleDirective.$inject = ["$clientSwitcher","$rulesLogsManager"];
