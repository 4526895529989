'use strict';

export const analyticsDirective = function () {
        return {
            restrict: 'E',
            templateUrl: 'app/account/dashboard/main-dashboard/sosi-one-reporting/tabs/analytics/analytics.html',
            scope: {  },
            link: function (scope) {
               
                var init = function () {
                    
                }                  
                init()
            }
        }
    }