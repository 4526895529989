
'use strict';

export const $interpreterAssessmentsService  = function (InterpreterAssessments) {
        return {
            icAssessmentList: function (client, vendorId) {
                return InterpreterAssessments.icAssessmentList({
                    id: client._id || client,
                    vendorId
                }).$promise;
            },
            create: function (client, data) {
                return InterpreterAssessments.create({
                    _id: client._id || client,
                    data
                }).$promise;
            },
            delete: function (client, certificateId) {
                return InterpreterAssessments.delete({
                    id: client._id || client,
                    controller: certificateId
                }).$promise;
            }
        };
    }
// Dependency Injection


// Dependency Injection
$interpreterAssessmentsService.$inject = ['InterpreterAssessments'];
