import './task-calendar.scss';
'use strict';


export const taskCalendarDirective = function (Task, moment, calendarConfig, $clientSwitcher, $activeClient, TaskBucket, $q, $clientTask, ngToast, $timeout, LazyLoadService) {
    return {
      restrict: 'E',
      require: 'ngModel',
      templateUrl: 'components/task-calendar/task-calendar.html',
      scope: {
        allTask: "=ngModel"
      },
      link: function (scope) {

        var client,
          killClientWatch;

        scope.client = $clientSwitcher.getCurrentClient();
        scope.loading = false;
        scope.status = {
          calender: true
        };
        calendarConfig.dateFormats.time = "hh:mm a"
        //These variables MUST be set as a minimum for the calendar to work
        scope.calanderData = {
          calendarView: 'month',
          viewDate: new Date(),
          cellIsOpen: false
        }
        scope.groupEvents = function (cell) {
          cell.groups = _.groupBy(cell.events, 'type');
        };

        scope.rangeSelected = function (startDate, endDate) {
          scope.firstDateClicked = startDate;
          scope.lastDateClicked = endDate;
        };

        function dateNorm(d) {
          if (angular.isString(d)) {
            var ms = Date.parse(d);
            if (isNaN(ms)) return false;
            return new Date(ms);
          }
          else if (angular.isNumber(d)) {
            return new Date(d);
          }
          else if (d instanceof Date) {
            return d;
          }
          return false;
        }

        scope.forwardTaskEmail = function(task, selectedUser) {
          if(!selectedUser.forwardTo && !selectedUser.forwardToEmail) {
            return;
          }

          return $clientTask.forwardTaskEmail(task, selectedUser)
          .then(function(res) {
            if(res.status) {
              ngToast.create({
                content: 'Task Mail sent successfully',
                className: 'success'
              });
            }
            else {
              ngToast.create({
                content: res.reason,
                className: 'danger'
              });
              console.error(res.reason);
            }
          });
        };

        scope.eventClicked = function (event) {
          scope.task = new TaskBucket({
            name: 'Selected',
            description: 'All active tasks available in non-grouped view.',
            expand: true,
            sortBy: 'due',
            isDefaultSortOrderReverse: true
          });
          scope.task.records = [];
          if (event.task.status == "pending") {
            scope.task.isPending = true;
          } else if (event.task.status == "unresolved") {
            scope.task.isUnresolved = true;
          }
          scope.task.records.push(new Task(event.task));
        };

        scope.eventEdited = function (event) {
          console.log("Edite");
        };

        scope.eventDeleted = function (event) {
          console.log("Delete");
        };

        scope.eventTimesChanged = function (event) {
          console.log("Dropped");
        };

        scope.timespanClicked = function (date, cell) {
          if (scope.calanderData.calendarView === 'month') {
            if ((scope.calanderData.cellIsOpen && moment(date).startOf('day').isSame(moment(scope.calanderData.viewDate).startOf('day'))) || cell.events.length === 0 || !cell.inMonth) {
              scope.calanderData.cellIsOpen = false;
            } else {
              scope.calanderData.cellIsOpen = true;
              scope.calanderData.viewDate = date;
            }
          }
        };

        scope.cellClick = function () {
          scope.calanderData.cellIsOpen = false;
          scope.task = {};
        }

        scope.reloadCollections = function () {
          var filteredTasks = [];
          var currentDate = new Date();
          scope.summaryPColor = {
            'high': { color: '#FF0000', tooltip: 'High' },
            'med-high': { color: '#FF6600', tooltip: 'Medium High' },
            'med-low': { color: '#FFFF00', tooltip: 'Medium Low' },
            'low': { color: '#009900', tooltip: 'Low' }
          }
          // Filter task
          filteredTasks = scope.allTask.filter(function (task) {
            return ['resolved', 'archived'].indexOf(task.status) === -1;
          })

          // Grouping due date
          var dueGroupedTaskObj = _.groupBy(filteredTasks, function (task) {
            return moment(task.due).format('L');
          });

          var calenderTask = [];
          _.forEach(dueGroupedTaskObj, function (tasks, dueDate) {
            tasks.forEach(function (task) {
              calenderTask.push({
                title: task.title,
                type: task.priority,
                color: {
                  primary: scope.summaryPColor[task.priority].color,
                  secondary: "#d1e8ff"
                },
                startsAt: moment(task.due).toDate(),
                endsAt: moment(task.due).toDate(),
                toolTip: scope.summaryPColor[task.priority].tooltip,
                task: task
              });
            })
          });
          console.log(calenderTask)
          scope.events = calenderTask;
        }

        scope.applyOptions = function (options) {
          if (options && !options.hasOwnProperty('locations')) {
            options.locations = [];
          }
          scope.taskOpts = options;
        };

        scope.reloadCollectionUpdate = function () {
          return scope.reloadCollections();
        };

        var init = function () {
          scope.reloadCollections();
          scope.$on('$destroy', function () {
            if (killClientWatch) {
              killClientWatch();
            }
          });
          killClientWatch = $activeClient(function (current) {
            if (!current) {
              return;
            }
            client = current;
            scope.applyOptions($clientSwitcher.getFeatureOptions('client.tasks', client));
          });
        };
        init();
      }
    };
  }
// Dependency Injection
taskCalendarDirective.$inject = ["Task","moment","calendarConfig","$clientSwitcher","$activeClient","TaskBucket","$q","$clientTask","ngToast", "$timeout", "LazyLoadService"];
