
'use strict';

export const $serveySentimentService  = function (SurveySentiment) {
        return {
            getSentiments: function (client) {
                return SurveySentiment.getSentiments({
                    id: client._id || client
                }).$promise;
            },
            getSentiment: function (client, sentiment) {
                return SurveySentiment.getSentiment({
                    id: client._id || client,
                    sentiment: sentiment._id || sentiment
                }).$promise;
            },
            createSentiment: function (client, data) {
                return SurveySentiment.createSentiment({
                    _id: client._id || client,
                    sentiment: data
                }).$promise;
            },
            updateSentiment: function(client, data) {
                return SurveySentiment.updateSentiment({
                  _id: client._id||client,
                  sentiment: data
                }).$promise;
              },
            deleteSentiment: function (client, sentiment) {
                return SurveySentiment.deleteSentiment({
                    _id: client._id || client,
                    sentiment: sentiment._id || sentiment
                }).$promise;
            }
        }
    }
// Dependency Injection


// Dependency Injection
$serveySentimentService.$inject = ['SurveySentiment'];
