'use strict';

export const pipelineOpsDirective = function ($activeClientSettings,$rootScope) {
        return {
            restrict: 'E',
            templateUrl: 'app/account/dashboard/tasks/tabs/pipeline-ops/pipeline-ops.html',
            scope: {},
            link: function (scope) {

            }
        }
    }
// Dependency Injection
pipelineOpsDirective.$inject = ["$activeClientSettings","$rootScope"];
