


'use strict';

export const ClientHolidaysFactory  = function ($resource, appConfig) {
    return $resource(appConfig.apiUrl + '/api/client-holidays/:id/:controller', {
      id: '@_id'
    },
    {
      list: {
        method: 'GET',
        params: {
          controller: ''
        }
      },
      create: {
        method: 'POST',
        params: {
          controller: 'create'
        }
      },
      update: {
        method: 'POST',
        params: {
          controller: 'update'
        }
      },
      delete: {
        method: 'POST',
        params: {
          controller: 'delete'
        }
      },

      federalHolidays: {
        method: 'GET',
        cache: true,
        params: {
            id: '@id',
            controller: 'federal-holidays'
        }
    },
    });
  }


// Dependency Injection
ClientHolidaysFactory.$inject = ['$resource', 'appConfig'];
