
'use strict';

export const TaskBucketFactory  = function (Bucket, Task, $timeout, $string, TASK_SCHEMA_TYPE_SURVEY, $activeClientSettings, CalculateWorkOrderTime) {

  function TaskBucket(opts) {
    Bucket.call(this, opts);
    opts = opts || {};

    this.types = [];

    this.id = opts._id;

    this.expand = !!opts.expand;

    this.filter = {
      by: 'filter',
      query: undefined
    };

    this.filterOptions = [
      ['filter', 'Filter'],
      ['type', 'Type'],
      ['priority', 'Priority'],
      ['assignedTo', 'Assigned To'],
      ['createdBy', 'Created By'],
      ['location', 'Location'],
      ['due', 'Due Date'],
      ['internal', 'Internal User'],
      ['external', 'External User'],
      ['tags', 'Attributes'],
    ];

    var callback = function(client, settings) {
      var clientSupportsResolveCodes = settings.supportedTaskAttributes && settings.supportedTaskAttributes.indexOf('resolveCodes') > -1;

      if(clientSupportsResolveCodes) {
        this.filterOptions.push(['resolveCode', 'Resolve Codes']);
      }
    };
    $activeClientSettings(callback.bind(this));

    // if(opts.types) {
    //   opts.types.forEach(this.addType.bind(this));
    //   this.filterOptions.unshift(['type', 'Type']);
    // }
    // else {
    //   this.filter.by = 'priority';
    // }
  }

  TaskBucket.prototype = Object.create(Bucket.prototype);
  TaskBucket.prototype.constructor = TaskBucket;

  TaskBucket.prototype.item = function (t) {
    return Task.wrap(t);
  };

  TaskBucket.prototype.filterRecords = function (opts) {

    function dateMatch(day, match) {
      return moment(day).isSame(match, 'day');
    }

    return function (task) {
      if(!opts.by) {
        return true;
      }

      var showAll = (opts.query===undefined||opts.query===null||opts.query==='');

      switch(opts.by) {
        case 'type':
          return task.taskType ? (showAll ? true : task.taskType === opts.query) : false;
        case 'status':
          return task.status ? (showAll ? true : task.status === opts.query) : false;
        case 'priority':
          return task.priority ? (showAll ? true : task.priority === opts.query) : false;
        case 'assignedTo':

          return showAll ? true : (task.assignTo ? task.assignTo._id === opts.query : (task.assignToEmail ? task.assignToEmail === opts.query : false));

        case 'createdBy':
          return task.createdBy ? (showAll ? true : task.createdBy._id === opts.query) : false;
        case 'location':
          return task.location ? (showAll ? true : task.location._id === opts.query) : false;
        case 'due':
          return showAll ? true : (task.due ? dateMatch(task.due, opts.query) : false);
        case 'resolvedDate':
          return showAll ? true : (task.dateResolved ? dateMatch(task.dateResolved, opts.query) : false);
        case 'internal':
          return task.assignTo ? (showAll ? true : task.assignTo._id === opts.query) : false;
        case 'external':
          return task.assignToEmail ? (showAll ? true : task.assignToEmail === opts.query) : false;
        case 'tags':
          return showAll ? true : task.tags && task.tags.indexOf(opts.query) > -1;
        case 'archiveDate': {
          if(showAll) {
            return true;
          }
          if(!task.archivedOn) {
            return false;
          }

          var dayMS = 86400000;
          var maxDifferenceMS = opts.query * dayMS;
          var diffMS = moment().diff(moment(task.archivedOn));

          return diffMS < maxDifferenceMS;
        }
        case 'resolveCode': {
          var hasResolveCode = function(task, resolveCode) {

            for(var i=0; i<task.resolve.notes.length; i++) {
              var resolveNote = task.resolve.notes[i];

              if(resolveNote.resolveCode) {

                if(showAll || resolveNote.resolveCode._id === resolveCode) {
                  return true;
                }
              }
            }

            return false;
          };

          return hasResolveCode(task, opts.query);
        }
        case 'resolvedBy': {
          if(showAll) {
            return true;
          }
          if(task.status !== 'resolved') {
            return false;
          }

          return task.resolve.user ? task.resolve.user._id === opts.query : task.resolve.email === opts.query;
        }
      }

      return true;
    };
  };

  TaskBucket.prototype.addType = function (name) {
    var nextId = this.types.length;
    this.types.push([name, name]);
    return nextId;
  };

  TaskBucket.prototype.blinkRecord = function (task) {
    if(!task instanceof Task) {
      return;
    }

    if(!this.expand) { // open up
      this.expand = true;
    }

    task.blinking = true;
    task.expand = true;

    // CalculateWorkOrderTime(task.caseData)
    //   .then(data => {
    //     task.woCalculations = data;
    //   })

    $timeout(function () {
      delete task.blinking;
    }, 5000);

    return this;
  };

  return TaskBucket;
}
// Dependency Injection



// Dependency Injection
TaskBucketFactory.$inject = ['Bucket', 'Task', '$timeout', '$string', 'TASK_SCHEMA_TYPE_SURVEY', '$activeClientSettings', 'CalculateWorkOrderTime'];
