'use strict';

export const WorkflowConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard.workflow', {
        url: '/workflow',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/workflow/workflow.html', 'd88080cf'); }],
        controller: 'DashboardWorkflowCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/workflow/workflow.controller.js', 'DashboardWorkflowCtrl'); }]
                      },
        data: {
          ability: 'client.workflow',
          permission: 'WORKFLOW_READ'
        }
      });
  }
// Dependency Injection
WorkflowConfig.$inject = ["$stateProvider"];
