import './notification-settings.scss';
'use strict';


export const notificationSettingsDirective = function ($rootScope, $auth, $clientUser, $activeClient, $clientSwitcher) {
    return {
      	restrict: 'E',
      	templateUrl: 'components/notification-settings/notification-settings.html',
      	scope: {
			settings: '=ngModel',
		
			user: '=',
        	showTasksICreate: '=',
        	showTasksIAssign: '=',
        	showTasksInMyOrg: '=',
			showTimeWorkReporting: '=',
			
			tabTextColor: '=',
			tableClass: '=',
			tabContainerColSize: '=tabContainerColSize',
		},
		link: function (scope, elem, attrs) {
			var defaultTab = 'myTasks';
			var selectedTab = defaultTab;
			var user = null;
			var currentClient = null;
			
			scope.tabContainerColSize = scope.tabContainerColSize && scope.tabContainerColSize < 12 ? scope.tabContainerColSize : 6;
			scope.tabContainerClass = 'col-sm-'+scope.tabContainerColSize;
			scope.outOfOfficeContainerClass = 'col-sm-' + (12 - scope.tabContainerColSize);
			scope.clientStrings = function(){ return $clientSwitcher.getFeatureOptions('client.strings'); };
			
			scope.allSelected = {
				myTasks: {
					weekendNotify: false,
					outOfOfficeNotify: false
				}
			};
			scope.settingsLoaded = false;
			scope.allValue = 'all';
			scope.datePickerOptions = {
				minDate: new Date(),
				dateFormat: 'MM/dd/yyyy'
			};
			scope.disabledItemTooltip = 'Required by System';	
			//if max time is changed then also set its value in maxTimeRemaining in user_settings.model.js (in Schema.static)
			scope.times = [
				{label: '30 Minutes', value: 30},
				{label: '1 hour', value: 60},
				{label: '2 hours', value: 120},
				{label: '3 hours', value: 180},
				{label: '6 hours', value: 360},
				{label: '12 hours', value: 720},
				{label: '24 hours', value: 1440},
				{label: '48 hours', value: 2880}
			];
		
			scope.reminders = [
				{label: 'Remind Every 24 Hours', value: 1440},
				{label: 'Remind Every 48 Hours', value: 2880}
			];

			function checkAllSelection() {
				var firstSelection = {
					weekendNotify: null,
					outOfOfficeNotify: null
				};
				var areAllSame = {
					weekendNotify: true,
					outOfOfficeNotify: true
				};
				
				_.forEach(scope.settings.myTasks, function(value) {
					if(firstSelection.weekendNotify === null) {
						firstSelection = {
							weekendNotify: value.weekendNotify,
							outOfOfficeNotify: value.outOfOfficeNotify
						};
						return;
					}

					if(areAllSame.weekendNotify) {
						if(!value.weekendNotify.length) {
							areAllSame.weekendNotify = false;
							return;
						}

						var isWeekendSelectionSame = firstSelection.weekendNotify.join(',') === value.weekendNotify.join(',');
						areAllSame.weekendNotify = areAllSame.weekendNotify && isWeekendSelectionSame;
					}
					if(areAllSame.outOfOfficeNotify) {
						if(!value.outOfOfficeNotify.length) {
							areAllSame.outOfOfficeNotify = false;
							return;
						}

						var isOutOfOfficeSelectionSame = firstSelection.outOfOfficeNotify.join(',') === value.outOfOfficeNotify.join(',');
						areAllSame.outOfOfficeNotify = areAllSame.outOfOfficeNotify && isOutOfOfficeSelectionSame;
					}
				});

				scope.allSelected.myTasks.weekendNotify = areAllSame.weekendNotify;
				scope.allSelected.myTasks.outOfOfficeNotify = areAllSame.outOfOfficeNotify;
			}
				
			function init() {
				//edit user provides userId
				var userId = user.userId || user._id;

				if(userId){
					$clientUser.getContactUsers(currentClient, userId)
					.then(function(res) {
						if(res.status) {
							scope.contactUsers = res.data.map(function(user) {
								return {
									label: user.name,
									value: user._id
								};
							});
	
							checkAllSelection();
						}
						else {
							console.log(res.reason);
						}
					});
				}
			}

			scope.notifySelectionChanged = function(category, settingsType, notifyType) {
				if(scope.allSelected[category][notifyType]) {
					var keys = _.keys(scope.settings[category]);
					var value = scope.settings[category][settingsType][notifyType];
					
					keys
					.filter(function(key) {
						return key !== settingsType;
					})
					.forEach(function(key) {
						scope.settings[category][key][notifyType] = value;
					});
				}
			};

			scope.selectTab = function(tab) {
				selectedTab = tab;
			};

			scope.isTabSelected = function(tab) {
				return selectedTab === tab;
			};

			scope.getInlineCSS = function(tabName) {
				var isTabActive = scope.isTabSelected(tabName);
				return isTabActive ? {color: 'black'} : {color: scope.tabTextColor || $rootScope.textColor};
			};

			scope.getEmailIcon = function(category, key) {
				if(scope.settings && scope.settings[category] && scope.settings[category][key]){
					if(scope.settings[category][key].email){
						return '/assets/images/icon_email.png';
					}else if(scope.settings[category][key].allowChangeEmail === false){
						return '/assets/images/icon_na_disabled.png';
					}else{
						return '/assets/images/icon_email_disabled.png';
					}
				} else{
					return '/assets/images/icon_email_disabled.png';
				}
			};
		
			scope.getNotificationIcon = function(category, key) {
				if(scope.settings && scope.settings[category] && scope.settings[category][key]){
					if(scope.settings[category][key].notification){
						return '/assets/images/icon_notification.png';
					}else if(scope.settings[category][key].allowChangeNotification === false){
						return '/assets/images/icon_na_disabled.png';
					}else{
						return '/assets/images/icon_notification_disabled.png';
					}
				}else{
					return '/assets/images/icon_notification_disabled.png';
				}
			};

			scope.getSMSIcon = function(category, key) {
				// return '/assets/images/icon_na_disabled.png';
				if(scope.settings && scope.settings[category] && scope.settings[category][key]){
					if(scope.settings[category][key].sms){
						return '/assets/images/icon_sms.png';
					}else if(scope.settings[category][key].allowChangeSMS === false){
						return '/assets/images/icon_na_disabled.png';
					}else{
						return '/assets/images/icon_sms_disabled.png';
					}
				} else{
					return '/assets/images/icon_sms_disabled.png';
				}
			};

			scope.toggleEmail = function(category, key) {
				if(!scope.settings[category][key]){
					scope.settings[category][key] = {
						email: false,
						notification: false,
						sms: false
					};
				}
				if(scope.settings[category][key].allowChangeEmail !== false){
					scope.settings[category][key].email = !scope.settings[category][key].email;
				}
			};

			scope.toggleNotification = function(category, key) {
				if(!scope.settings[category][key]){
					scope.settings[category][key] = {
						email: false,
						notification: false,
						sms: false
					};
				}
				if(scope.settings[category][key].allowChangeNotification !== false){
					scope.settings[category][key].notification = !scope.settings[category][key].notification;
				}      
			};

			scope.toggleSMS = function(category, key) {
				if(!scope.settings[category][key]){
					scope.settings[category][key] = {
						email: false,
						notification: false,
						sms: false
					};
				}
				if(scope.settings[category][key].allowChangeSMS !== false){
					scope.settings[category][key].sms = !scope.settings[category][key].sms;
				}      
			};

			scope.userStatusChanged = function() {
				if(!scope.settings.isOutOfOffice) {
					scope.settings.outOfOfficeTillDate = null;
				}
			};

			scope.nowDate = function () {
				var
				now = Date.now(),
				nextMinute = now % 60000;
			
				return now - nextMinute;
			};

			$activeClient(function(client) {
				if(!client) {
					return;
				}

				currentClient = client;
				user = scope.user || $auth.getCurrentUser();

				init();
			});
		}
    };
}
// Dependency Injection
notificationSettingsDirective.$inject = ["$rootScope","$auth","$clientUser","$activeClient","$clientSwitcher"];
