
'use strict';

export const $clientUserService  = function (ClientUser) {
    return {
      list: function(client) {
        return ClientUser.query({ clientId: client._id }).$promise;
      },

      detail: function(clientId, userId) {
        return ClientUser.detail({
          clientId: clientId._id||clientId,
          userId: userId
        }).$promise;
      },

      possibleParents: function(clientId, userId) {
        return ClientUser.possibleParents({
          clientId: clientId._id||clientId,
          userId: userId
        }).$promise;
      },

      getUserChainOfCommand: function(clientId, store, bucket) {
        return ClientUser.chainOfCommand({
          clientId: clientId._id||clientId,
          location: !!store ? (store._id||store) : null,
          bucket: !!bucket ? (bucket._id||bucket) : null
        }).$promise;
      },

      getUserLocations: function(clientId) {
        return ClientUser.locations({
          clientId: clientId._id||clientId
        }).$promise;
      },

      supportOrgNotifications: function(client, user) {
        return ClientUser.supportOrgNotifications({
          clientId: client._id||client,
          user: user._id || user
        }).$promise;
      },

      isEmailUnique: function(client, email, userId) {
        return ClientUser.isEmailUnique({
          clientId: client._id || client,
          email: email,
          user: userId
        }).$promise;
      },

      isInternalIdUnique: function(client, internalId, userId) {
        return ClientUser.isInternalIdUnique({
          clientId: client._id || client,
          internalId: internalId,
          user: userId
        }).$promise;
      },

      getLinkedEmployees: function(client, user) {
        return ClientUser.getLinkedEmployees({
          clientId: client._id||client,
          user: user._id || user
        }).$promise;
      },

      getContactUsers: function(client, user) {
        return ClientUser.getContactUsers({
          clientId: client._id||client,
          user: user._id || user
        }).$promise;
      },
      getUserPermissions: function(client) {
        return ClientUser.getUserPermissions({
          clientId: client._id || client
        }).$promise;
      },
      getUsersByRoles: function(client, roles, isActive) {
        return ClientUser.getUsersByRoles({
          clientId: client._id || client,
          roles,
          isActive: isActive || false
      }).$promise;
      },
      updateAccessibility: function (client, accessibility) {
        return ClientUser.updateAccessibility({
          clientId: client._id || client,
          accessibility
        }).$promise;
      },
      getUsersList: function(client, filter) {
        return ClientUser.getUsersList({
          clientId: client._id || client,
          filter
        }).$promise;
      },
      getNotLoggedInUser: function(client, filter) {
        return ClientUser.getNotLoggedInUser({
          clientId: client._id || client,
          filter
        }).$promise;
      },
      getDisabeldUsersList: function(client, filter) {
        return ClientUser.getDisabeldUsersList({
          clientId: client._id || client,
          filter
        }).$promise;
      },
      getInterpreterUsersList: function(client, filter) {
        return ClientUser.getInterpreterUsersList({
          clientId: client._id || client,
          filter
        }).$promise;
      },
      getNotLoggedInUserReport: function (client) {
        return ClientUser.getNotLoggedInUserReport({
          clientId: client._id || client,
        }).$promise;
      },
      sendWelcomMail: function(client, data) {
        return ClientUser.sendWelcomMail({
          clientId: client._id || client,
          data
        }).$promise;
      },
      removeUser: function(client, userId) {
        return ClientUser.removeUser({
          clientId: client._id || client,
          controller: userId
        }).$promise;
      }
    };
  }
// Dependency Injection


// Dependency Injection
$clientUserService.$inject = ['ClientUser'];
