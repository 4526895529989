


'use strict';

export const ClientDeviceEventsManagerFactory  = function ($resource, appConfig) {
    return $resource(appConfig.apiUrl + '/api/device-events/:id/:controller', {
      id: '@_id'
    },
    {
      createEvent: {
        method: 'POST',
        params: {
          controller: 'create'
        }
      },
      getEvents: {
        method: 'GET',
        params: {
          controller: '',
          id: '@id'
        },
        isArray: true
      },
      deleteEvent: {
        method: 'POST',
        params: {
          controller: 'delete'
        }
      }
    });
  } 
 

// Dependency Injection
ClientDeviceEventsManagerFactory.$inject = ['$resource', 'appConfig'];
