
'use strict';

export const $clientExternalUsersService  = function ($q, $authStorage, $http, $window, ClientExternalUsers) {
  return {
    list: function (client, searchText) {
      return ClientExternalUsers.query({ 
        id: (client._id||client),
        search: searchText
      }).$promise;
    },
    add: function(client, email) {
      return ClientExternalUsers.add({ id: (client._id||client), email: email }).$promise;
    },
    delete: function(client, email) {
      return ClientExternalUsers.delete({ id: (client._id||client), email: email }).$promise;
    }
  };
}
// Dependency Injection


// Dependency Injection
$clientExternalUsersService.$inject = ['$q', '$authStorage', '$http', '$window', 'ClientExternalUsers'];
