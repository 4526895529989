'use strict';

export const AssignmentsConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard.assignments', {
        url: '/assignments',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/assignments/assignments.html', '29f4c48a'); }],
        controller: 'DashboardAssignmentsCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/assignments/assignments.controller.js', 'DashboardAssignmentsCtrl'); }]
                      },
        data: {
          ability: 'client.assignments',
          permission: 'ASSIGNMENTS_READ'
        }
      });
  }
// Dependency Injection
AssignmentsConfig.$inject = ["$stateProvider"];
