


'use strict';

export const ClientResolveCodesFactory  = function ($resource, appConfig) {
    return $resource(appConfig.apiUrl + '/api/resolve-codes/:id/:controller', {
      id: '@_id'
    },
    {
      saveResolveCode: {
        method: 'POST',
        params: {
          controller: 'create'
        }
      },
      updateResolveCode: {
        method: 'POST',
        params: {
          controller: 'update'
        }
      },
      activate: {
        method: 'POST',
        params: {
          controller: 'activate'
        }
      },
      suspend: {
        method: 'POST',
        params: {
          controller: 'suspend'
        }
      },
      getResolveCodes: {
        method: 'GET',
        params: {
          controller: '',
          id: '@id'
        },
        isArray: true
      },
      getResolveCode: {
        method: 'GET',
        params: {
          id: '@id'
        }
      }
    });
  } 
 

// Dependency Injection
ClientResolveCodesFactory.$inject = ['$resource', 'appConfig'];
