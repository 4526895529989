'use strict';


export const DashboardSettingsBucketsIndexCtrl = function ($rootScope, $scope, $activeClient, $clientBucket, Modal, $client, $auth) {

  $scope.loading = false;
  $scope.permissions = {
    allowDeleteWorkOrder: false,
    allowCreateWorkOrder: false
  }
  $scope.orderVal = 'true';
  $scope.sortingOrders = [{
    label:'A-Z',
    value: 'true'
  }, {
    label: 'Z-A',
    value: 'false'
  }];
  function reloadBuckets(client) {
    delete $scope.buckets;
    $scope.loading = true;
    return $client.getBuckets(client)
      .then(function (buckets) {
        $scope.buckets = buckets;
      })
      .finally(function () {
        $scope.loading = false;
      });
  }

  // listen for client switching
  $scope.$on('$destroy', $activeClient(function (client) {
    delete $scope.removeBucket;

    if(!client) return;

    reloadBuckets(client);

    $scope.permissions.allowDeleteWorkOrder = $auth.getCurrentUser().permissions.includes('DELETE_WO_BUCKET');
    $scope.permissions.allowCreateWorkOrder = $auth.getCurrentUser().permissions.includes('EDIT_WO_BUCKET');
    $scope.removeBucket = function (bucket) {
      var message = "Are you sure you want to delete :name:? Deleting :name: will prevent it from being used in the system any further. Any tasks associated to :name: will remain unchanged in the system.";
      message = message.replace(new RegExp(":name:", 'g'), bucket.name);

      Modal.confirm.show("Confirm Delete", message, "Delete", "Cancel", function(){
        $clientBucket.remove(client, bucket._id)
        .then(function() {
            return reloadBuckets(client);
        });
      });
    };
  }));
}
// Dependency Injection
DashboardSettingsBucketsIndexCtrl.$inject = ["$rootScope","$scope","$activeClient","$clientBucket","Modal","$client","$auth"];

// angular.module('coordinateApp').controller('DashboardSettingsBucketsIndexCtrl', DashboardSettingsBucketsIndexCtrl);
