
'use strict';

export const $sosnotsService  = function (Sosnots) {
    return {
        getSosnotsList: function (client, paginationData) {
            return Sosnots.getSosnotsList({
                id: client._id || client,
                page: paginationData && paginationData.page,
                pageSize: paginationData && paginationData.pageSize,
                recordNo: paginationData.recordNo ? paginationData.recordNo : undefined
            }).$promise;
        },
        getSosnotsDetails: function (client, filter) {
            return Sosnots.getSosnotsDetails({
                id: client._id || client,
                filterBy: filter
            }).$promise;
        },
    };
}
// Dependency Injection


// Dependency Injection
$sosnotsService.$inject = ['Sosnots'];
