'use strict';

export const paymentHistoryDirective = function () {
        return {
            restrict: 'E',
            templateUrl: 'app/account/dashboard/main-dashboard/work-orders/tabs/payment-history/payment-history.html',
            scope: {  },
            link: function (scope) {
                var init = function () {
                }
                init()
            }
        }
    }