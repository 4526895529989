
'use strict';

export const $clientCoiVRIInfoService  = function (ClientCoiVRIInfo) {
        return {
            create: function (client, data) {
                return ClientCoiVRIInfo.create({
                    _id: client._id || client,
                    data
                }).$promise;
            },
            coiInfo: function (client, coi) {
                return ClientCoiVRIInfo.coiInfo({
                    id: client._id || client,
                    controller: coi
                }).$promise;
            },
            getByRecordnumber: function (client, recordNumber) {
                return ClientCoiVRIInfo.getByRecordnumber({
                    id: client._id || client,
                    controller: 'get-recordnumber',
                    recordNumber
                }).$promise;
            },
            getLists: function (client, paginationData, filter) {
                return ClientCoiVRIInfo.getLists({
                    id: client._id || client,
                    page: paginationData && paginationData.page,
                    pageSize: paginationData && paginationData.pageSize,
                    filter
                }).$promise;
            },
            workOrderConnectionInfo: function (client, coi, recordNumber, ij, serviceType) {
                return ClientCoiVRIInfo.workOrderConnectionInfo({
                    id: client._id || client,
                    coi,
                    recordNumber, 
                    ij, 
                    serviceType
                }).$promise;
            }
        };
    }
// Dependency Injection


// Dependency Injection
$clientCoiVRIInfoService.$inject = ['ClientCoiVRIInfo'];
