


'use strict';

export const PipelineOperationsFactory  = function ($resource, appConfig) {
    return $resource(appConfig.apiUrl + '/api/pipeline-operations/:id/:controller', {
      id: '@_id'
    },
      {
        getPipelineDetail: {
          method: 'GET',
          params: {
            id: '@id'
          }
        },
        getPipelineDetailsNew: {
          method: 'GET',
          params: {
            id: '@id'
          }
        },
        getPipelineIndividualCount: {
          method: 'GET',
          params: {
            id: '@id'
          }
        },
        getFilterData: {
          method: 'POST',
          params: {
            id: '@id',
            controller: '@controller'
          }
        },
        pipelineCount: {
          method: 'POST',
          params: {
            id: '@id',
            controller: '@controller'
          }
        },
        pipelineList: {
          method: 'POST',
          params: {
            id: '@id',
            controller: '@controller'
          }
        }
      });
  } 
 

// Dependency Injection
PipelineOperationsFactory.$inject = ['$resource', 'appConfig'];
