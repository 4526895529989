'use strict';

export const DashboardSettingsJudgesIndexCtrl = function ($rootScope, $scope, $clientSwitcher, $activeClient, $judges, Modal, ngToast, $auth, DEFAULT_DEBOUNCE) {

    let currentCli = $clientSwitcher.getCurrentClient();;
    $scope.isLoading = false;
    $scope.pagination = {
      currentPage: 1,
      page: 1,
      total: 0,
      pageSize: 20,
      itemsPerPage: 20
    };
    $scope.judgesData = [];
    $scope.search = {
      orderVal: 'true',
      searchText: null
    };
    $scope.sortingOrders = [{
      label: 'A-Z',
      value: 'true'
    }, {
      label: 'Z-A',
      value: 'false'
    }];
    $scope.totalActiveJudges = 0;
    $scope.allowEdit = $auth.getCurrentUser().permissions.includes('SETTING_JUDGES_EDIT');
    $scope.getJudgesList = function () {
      $scope.isLoading = true;
      $scope.error = null;
      $scope.judgesData = [];
      $scope.pagination.total = 0;
      $scope.totalActiveJudges = 0;

      $judges.getJudgesList(currentCli, $scope.pagination, $scope.search)
        .then((res) => {
          if (res.status) {
            if (res.judgesList && res.judgesList.length) {
              $scope.judgesData = res.judgesList;
              $scope.pagination.total = res.totalRecords;
              $scope.judgesDataCount = res.totalRecords;
              $scope.totalActiveJudges = res.totalActiveJudges
            } else {
              $scope.error = 'No Record Found.';
              $scope.judgesDataCount = 0;
            }
          }
        })
        .catch((err) => {
          let errMessage = err.message || err;
          if (err && err.data && err.data.message) {
            errMessage = err.data.message;
          } else if (err && err.data && err.data.reason) {
            errMessage = err.data.reason;
          }
          $scope.error = errMessage;
          $scope.judgesDataCount = 0;
        })
        .finally(() => {
          $scope.isLoading = false;
        })
    }

    $scope.toggleAccess = function (member) {
      var modalTitle = (member.isEnabled ? 'Disable' : 'Enable') + ' judge';
      var modalBody = "Are you sure you want to " + (member.isEnabled ? 'disable' : 'enable') + " <strong>" + (member.name) + "</strong> ?";
      var notificationContent = "Judge " + (member.isEnabled ? 'disabled' : 'enabled') + ' successfully';

      Modal.confirm.show(modalTitle, modalBody, "Yes", "No", function () {
        $judges.toggleAccess(member._id, currentCli).then(function (result) {
          if (result.status) {
            ngToast.create({
              className: 'success',
              content: notificationContent
            });

            //set the new value after server confirmation
            member.isEnabled = !member.isEnabled;
          } else {
            ngToast.create({
              className: 'danger',
              content: 'Error' + result.reason
            });
          }
        });
      });
    };


    $scope.applySearch = _.debounce(function (cv) {
      $scope.pagination = {
        currentPage: 1,
        page: 1,
        total: 0,
        pageSize: 20,
        itemsPerPage: 20
      };
      $scope.getJudgesList();
    }, DEFAULT_DEBOUNCE)

    $scope.onSearch = function () {
      $scope.applySearch();
    }

    $scope.onSorting = function () {
      $scope.pagination = {
        currentPage: 1,
        page: 1,
        total: 0,
        pageSize: 20,
        itemsPerPage: 20
      };
      $scope.getJudgesList();
    }

    // listen for client switching
    $scope.$on('$destroy', $activeClient(function (client) {
      if (!client) return;
      // currentCli = client;
      $scope.getJudgesList();
    }));
  }
// Dependency Injection
DashboardSettingsJudgesIndexCtrl.$inject = ["$rootScope","$scope","$clientSwitcher","$activeClient","$judges","Modal","ngToast","$auth","DEFAULT_DEBOUNCE"];

// angular.module('coordinateApp').controller('DashboardSettingsJudgesIndexCtrl', DashboardSettingsJudgesIndexCtrl);
