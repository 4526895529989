'use strict';

export const notificationManagementDirective = function ($clientRoles, $activeClient, $attributes, $events, $roleTypes, $eventTypes, $auth) {
        return {
            restrict: 'E',
            templateUrl: 'app/account/settings/notification_setting/notification/notification.html',
            scope: {
                notificationSettings: '=ngModel',
                onSubmit: '=onSubmit',
                isUpdate: '=isUpdate',
                notificationEventsList: '=notificationEventsList',
                allRoles: '=allRoles'
            },
            link: function (scope, element, attrs) {
                // scope.notificationSettings = JSON.parse(JSON.stringify(scope.notificationEventSettings))
                // scope.allRoles = [];
                scope.events = $events;
                scope.attributes = $attributes;
                scope.roleTypes = $roleTypes;
                scope.eventTypes = $eventTypes;
                scope.strSettings = function (str) {
                    return {
                        selectedStr: ' ',
                        noSelection: 'No ' + str + ' Selected',
                        allLabel: 'All ' + str
                    };
                };
                scope.hasPermission = {}
                scope.hasPermission.allowAddNotificationEvent = $auth.getCurrentUser().permissions.includes('SETTING_ADD_NOTIFICATION_EVENT');
                scope.hasPermission.allowEditNotificationEvent = $auth.getCurrentUser().permissions.includes('SETTING_EDIT_NOTIFICATION_EVENT');

                scope.notificationSettings.events.map((obj) => {
                    obj.showIf = function () {
                        if (scope.notificationSettings.eventType) {
                            return (obj.type == scope.notificationSettings.eventType);
                        } else {
                            return true;
                        }
                    }
                })
                scope.onRoleChange = function(index) {
                    if (!scope.isInterpreterRole(scope.notificationSettings.roles[index]) && !scope.notificationSettings._id) {
                        scope.notificationSettings.roles[index].allowEmail = false;
                        scope.notificationSettings.roles[index].allowPush = false;
                    }
                }
                scope.addRole = function () {
                    var addRole = {
                        roleId: null,
                        roleType: null,
                        isEnabled: true,
                        onlyWOAssociatedUser: false,
                        subject: null,
                        attributes: [{
                            name: null,
                            variables: [{
                                name: null
                            }],
                            attributesArr: $attributes && $attributes.length ? JSON.parse(JSON.stringify($attributes)) : $attributes
                        }]
                    };
                    if (!scope.notificationSettings.roles) {
                        scope.notificationSettings.roles = [];
                    }
                    scope.notificationSettings.roles.push(addRole);
                }

                scope.removeRole = function (index) {
                    scope.notificationSettings.roles.splice(index, 1);
                }

                scope.addAttribute = function (role) {
                    var addAttribute = {
                        name: null,
                        variables: [{
                            name: null
                        }],
                        attributesArr: []
                    };
                    if (!role.attributes) {
                        role.attributes = [];
                    }

                    let usedAttributes = [];
                    role.attributes.forEach((obj) => { usedAttributes.push(obj.name) });
                    if (usedAttributes) {
                        $attributes.forEach((e) => {
                            if (usedAttributes.indexOf(e.value) < 0) {
                                addAttribute.attributesArr.push(e);
                            }
                        })
                    } else {
                        addAttribute.attributesArr = $attributes && $attributes.length ? JSON.parse(JSON.stringify($attributes)) : $attributes;
                    }
                    role.attributes.push(addAttribute);
                }

                scope.removeAttribute = function (role, index) {
                    role.attributes.splice(index, 1);
                    updateAttributeArr(role);
                }

                scope.addVariable = function (attribute) {
                    var addVariable = {
                        name: null,
                        variablesArr: JSON.parse(JSON.stringify(attribute.variablesArr))
                    };
                    if (!attribute.variables) {
                        attribute.variables = [];
                    }
                    attribute.variables.push(addVariable);
                    scope.onVariableChange(attribute)
                }

                // function loadRoles(cli) {
                //     $clientRoles.getRoles(cli)
                //         .then(({ data }) => {
                //             scope.allRoles = [];
                //             data.forEach(({ name, _id, code, isEnabled }) => {
                //                 if (isEnabled) {
                //                     scope.allRoles.push({ label: name, value: _id, code })
                //                 }
                //             });
                //         })
                // }

                scope.variableArr = function (attribute, role) {
                    if (Array.isArray(attribute.variablesArr) && attribute.variablesArr.length == 0) {
                        attribute.variablesArr = [];
                    }
                    let findIndex = scope.attributes.findIndex((obj) => obj.value == attribute.name);
                    if (findIndex >= 0) {
                        attribute.variablesArr = scope.attributes[findIndex].child;
                    }

                    updateAttributeArr(role);
                }

                function updateAttributeArr(role) {
                    let usedAttributes = [];
                    role.attributes.forEach((obj) => { usedAttributes.push(obj.name) });
                    role.attributes.map((obj) => {
                        obj.attributesArr = $attributes && $attributes.length ? JSON.parse(JSON.stringify($attributes)) : $attributes;
                        usedAttributes.forEach((Att) => {
                            obj.attributesArr.forEach((e, i) => {
                                if (obj.name != e.value) {
                                    if (e.value == Att) {
                                        obj.attributesArr.splice(i, 1)
                                    }
                                }
                            })
                            obj.variables.map((e, i) => {
                                e.variablesArr = obj.variablesArr && obj.variablesArr.length ? JSON.parse(JSON.stringify(obj.variablesArr)) : obj.variablesArr;
                                return e;
                            })
                        })
                        return obj;
                    });
                    // return role;
                }

                scope.onVariableChange = function (attribute, role) {
                    let usedVariables = [];
                    attribute.variables.forEach((obj) => {
                        if (obj.name) {
                            usedVariables.push(obj.name)
                        }
                    });
                    attribute.variables.map((obj) => {
                        let findIndex = scope.attributes.findIndex((obj) => obj.value == attribute.name);
                        if (findIndex >= 0) {
                            obj.variablesArr = JSON.parse(JSON.stringify(scope.attributes[findIndex].child));
                        }
                        usedVariables.forEach((Att) => {
                            obj.variablesArr.forEach((e, i) => {
                                if (obj.name != e.value) {
                                    if (e.value == Att) {
                                        obj.variablesArr.splice(i, 1)
                                    }
                                }
                            })
                        })
                        return obj;
                    });
                }

                scope.removeVariable = function (attribute, variables, index) {
                    variables.splice(index, 1);
                    scope.onVariableChange(attribute)
                }

                scope.isInterpreterRole = function(item) {
                    let el = scope.allRoles.filter(el => el.code == "INTERPRETER" && el.value == item.roleId);
                    return !!(el && el.length)
                }
                scope.$on('$destroy', $activeClient(function (client) {
                    if (!client) {
                        return;
                    }

                    scope.client = client;
                    // Load settings
                    // loadRoles(client);
                }));
            }
        }
    }
// Dependency Injection
notificationManagementDirective.$inject = ["$clientRoles","$activeClient","$attributes","$events","$roleTypes","$eventTypes","$auth"];
