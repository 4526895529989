'use strict';

angular
export const DashboardSettingsEquipmentsIndexCtrl = function ($rootScope, $scope, $activeClient, $clientEquipment, Modal) {

  $scope.loading = false;

  function reloadEquipments(client) {
    $scope.loading = true;
    return $clientEquipment.getEquipments(client)
      .then(function (res) {
        if(res.status) {
          $scope.equipments = res.data;
        }
      })
      .finally(function () {
        $scope.loading = false;
      });
  }

  // listen for client switching
  $scope.$on('$destroy', $activeClient(function (client) {
    if(!client) return;

    reloadEquipments(client);
  }));

  $scope.deleteEquipment = function(equipment) {
    $activeClient(function (client) {
      if(!client) {
        return;
      }

      Modal.confirm.show(
        "Confirm Delete",
        "Are you sure you want to delete <strong>"+equipment.name+"</strong>?",
        "Delete", "Cancel",
        function(){
        $clientEquipment.deleteEquipment(client, equipment).then(function() {
          reloadEquipments(client);
        });
      });
    });
  };
}
// Dependency Injection
DashboardSettingsEquipmentsIndexCtrl.$inject = ["$rootScope","$scope","$activeClient","$clientEquipment","Modal"];

// angular.module('coordinateApp').controller('DashboardSettingsEquipmentsIndexCtrl', DashboardSettingsEquipmentsIndexCtrl);
