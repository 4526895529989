'use strict';

export const InvoicePrintConfig = function ($stateProvider) {
    $stateProvider
      .state('app.print.invoice', {
        url: '/invoice/:id',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./print/services/invoice-print/invoice-print.html', 'd115294a'); }],
        controller: 'PrintInvoicePrintCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./print/services/invoice-print/invoice-print.controller.js', 'PrintInvoicePrintCtrl'); }]
                      }
      });
  }
// Dependency Injection
InvoicePrintConfig.$inject = ["$stateProvider"];
