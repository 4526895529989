'use strict';


export const formCaptioningDirective = function (Modal, $rootScope, $q, $auth, $clientSwitcher, $clientLanguages, ngToast, $servicePanel) {
        return {
            restrict: 'E',
            templateUrl: 'components/forms/captioning.html',
            scope: {
                captioning: '=ngModel',
                onSubmit: '=formOnSubmit',
                onReset: '=formOnReset',
                onUpdateDetail: '=onUpdateDetail'
            },
            link: function (scope, el, attrs) {
                // scope.clientSettings = function () { return $clientSwitcher.getFeatureOptions('client.settings'); };
                const client = $clientSwitcher.getCurrentClient();
                // console.log('------', scope.captioning)
                scope.data = {
                    openPicker: false
                };
                scope.hasPermission = {};
                scope.currentUser = $auth.getCurrentUser();
                scope.hasPermission.changeAttachment = scope.currentUser.permissions.includes('EDIT_SERVICE_JOB_ATTACHMENT') || false;
                scope.captioning.uploader = [];
                // scope.captioning.deadline = scope.captioning.deadline ? new Date(scope.captioning.deadline) : new Date();
                let timing = scope.captioning._id && scope.captioning.deadline ? new Date(scope.captioning.deadline) : null;
                scope.captioning.selectedDate = timing;
                scope.captioning.selectedTime = timing;

                scope.ddSettings = function (type) {
                    var officeStr = type;
                    return {
                        selectedStr: 'Selected ' + officeStr + ': ',
                        noSelection: 'No ' + officeStr + ' Selected',
                        allLabel: 'All ' + officeStr
                    };
                };
                function getLanguages() {
                    scope.activeLanguages = [];
                    $clientLanguages.getLanguages(client)
                        .then(({ data, status }) => {
                            if (status) {
                                data.forEach((obj) => {
                                    if (obj && obj.isEnabled) {
                                        scope.activeLanguages.push({
                                            label: `${obj.name} (${obj.code})`,
                                            value: obj._id
                                        })
                                    }
                                })
                            }
                        })
                }

                scope.onRemoveAttachment = function(attachment) {
                    $servicePanel.removeAttachment(client,scope.captioning._id, attachment)
                    .then(res => {
                        if (res.status) {
                            ngToast.create({
                                content: res.message,
                                className: 'success'
                            });
                            let i = scope.captioning.attachments.findIndex(item => item._id == attachment)
                            if (i >= 0) {
                                scope.captioning.attachments.splice(i, 1)
                            }
                        }
                    },err => {
                        console.log(err)
                        ngToast.create({
                            content: err.message || 'something went wrong, please try after sometime',
                            className: 'danger'
                        });
                    })
                }

                scope.setDateForTime = function() {
                    scope.captioning.selectedTime = scope.captioning.selectedDate;
                }
                getLanguages();
            }
        };
    }
// Dependency Injection
formCaptioningDirective.$inject = ["Modal","$rootScope","$q","$auth","$clientSwitcher","$clientLanguages","ngToast","$servicePanel"];
