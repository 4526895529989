
'use strict';

export const $clientRequestInterpreterService  = function (ClientRequestInterpreter) {
    return {
      create: function (client, data) {
        return ClientRequestInterpreter.create({
          _id: client._id || client,
          data: data
        }).$promise;
      },
      update: function (client, requestData) {
        return ClientRequestInterpreter.update({
          _id: client._id || client,
          data: requestData
        }).$promise;
      },
      cancel: function (client, record) {
        return ClientRequestInterpreter.cancel({
          id: (client._id || client),
          requestInterPreterId: record._id
        }).$promise;
      },
      requestInterpreter: function(client, filter) {
        return ClientRequestInterpreter.requestInterpreter({
            id: client._id || client,
            filter: filter || {}
        }).$promise;
      }
    };
  }
// Dependency Injection


// Dependency Injection
$clientRequestInterpreterService.$inject = ['ClientRequestInterpreter'];
