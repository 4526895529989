'use strict';

export const Survey_scoreConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard.settings.surveyScore', {
        url: '/survey-score',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/survey_score/survey_score.html', '2cbf780a'); }],
        controller: 'DashboardSettingsSurveyQuestionCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/survey_score/survey_score.controller.js', 'DashboardSettingsSurveyQuestionCtrl'); }]
                      }
      })
      .state('app.account.dashboard.settings.surveyScore.index', {
        url: '/survey-score',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/survey_score/index/index.html', '3066900a'); }],
        controller: 'DashboardSettingsSurveyQuestionIndexCtrl'
      })
      .state('app.account.dashboard.settings.surveyScore.update', {
        url: '/update/:id',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/survey_score/update/update.html', '8d240aca'); }],
        controller: 'DashboardSettingsSurveyQuestionUpdateCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/survey_score/update/update.controller.js', 'DashboardSettingsSurveyQuestionUpdateCtrl'); }],
                          '$selectedQuestion': ['$stateParams','$clientSurveyQuestion', '$clientSwitcher', function ($stateParams, $clientSurveyQuestion, $clientSwitcher) {
                            return $clientSurveyQuestion.getQuestion($clientSwitcher.getCurrentClient(), $stateParams.id).then(function(res) {
                              if(res.status) {
                                return res.data;
                              }
                              else {
                                throw new Error(res.reason);
                              }
                            });
                          }]
                      },

      })
      .state('app.account.dashboard.settings.surveyScore.create', {
        url: '/create',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/survey_score/create/create.html', '7eaa706a'); }],
        controller: 'DashboardSettingsSurveyQuestionCreateCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/survey_score/create/create.controller.js', 'DashboardSettingsSurveyQuestionCreateCtrl'); }],
                          '$count': ['$clientSurveyQuestion', '$clientSwitcher', function ($clientSurveyQuestion, $clientSwitcher) {
                            return $clientSurveyQuestion.getQuestions($clientSwitcher.getCurrentClient()).then(function(res) {
                              if(res.status) {
                                return res.data.length;
                              }
                              else {
                                throw new Error(res.reason);
                              }
                            });
                          }]
                      },
      })
      .state('app.account.dashboard.settings.surveyScore.sentiments', {
        url: '/survey-sentiments',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/survey_score/sentiments/sentiments.html', 'cae6856a'); }],
        controller: 'DashboardSettingsSurveyScoreSentimentsCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/survey_score/sentiments/sentiments.controller.js', 'DashboardSettingsSurveyScoreSentimentsCtrl'); }]
                      }
      });
  }
// Dependency Injection
Survey_scoreConfig.$inject = ["$stateProvider"];
