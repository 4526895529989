'use strict';

angular
export const DashboardSettingsManagersIndexCtrl = function ($rootScope, $scope, $activeClient, $clientManager, $client, Modal) {

  $scope.loading = false;

  function reloadManagers(client) {
    delete $scope.managers;
    $scope.loading = true;
    return $client.getManagers(client)
      .then(function (managers) {
        $scope.managers = managers;
      })
      .finally(function () {
        $scope.loading = false;
      });
  }

  // listen for client switching
  $scope.$on('$destroy', $activeClient(function (client) {
    delete $scope.removeManager;

    if(!client) return;

    reloadManagers(client);

    $scope.removeManager = function (manager) {
      Modal.confirm.show(
        "Confirm Delete",
        "Are you sure you want to delete manager <strong>"+manager.name+"</strong>?",
        "Delete", "Cancel",
        function(){
          $clientManager.remove(client, manager._id)
          .then(function() {
            return reloadManagers(client);
          });
        });
    };
  }));
}
// Dependency Injection
DashboardSettingsManagersIndexCtrl.$inject = ["$rootScope","$scope","$activeClient","$clientManager","$client","Modal"];
