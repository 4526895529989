'use strict';


export const surveyQuestionDirective = function ($clientQueryBuilder, $clientSwitcher, Modal, $tablesManager, $timeout, LazyLoadService) {
    return {
      restrict: 'E',
      templateUrl: 'components/forms/survey/survey_question.html',
      scope: {
        question: '=ngModel',
        onSubmit: '=formOnSubmit',
        onReset: '=formOnReset'
      },
      link: function (scope, el, attrs) {
        scope.minicolorsLoaded = false;
        LazyLoadService.loadMinicolors().then(() => {
          $timeout(function() {
            scope.minicolorsLoaded = true;
          });
        }).catch(error => {
          console.error( error);
        });
				scope.client = $clientSwitcher.getCurrentClient();

        scope.addAnswer = function(question) {
					question.answers.push({
							value: '',
							score: '',
							label: '',
							color: ''
					});
        };

        scope.deleteAnswer = function(index) {
          var answer = scope.question.answers[index];

          Modal.confirm.show(
            "Confirm Delete",
            "Are you sure you want to delete answer <strong>"+answer.value+"</strong> ?",
            "Delete", "Cancel",
            function(){
              scope.question.answers.splice(index, 1);
          });
        };

        scope.customSettings = {
					control: 'brightness',
					theme: 'bootstrap',
					position: 'top left'
        };

        scope.tables = [];
        $tablesManager.getTables(scope.client._id)
        .then(function(res) {
          if(res.status) {
            scope.tables = res.data.map(function(table) {
              return {
                label: table.title,
                value: table.title
              };
            });
          }
        });

        scope.fetchColumnNames = function(question) {
					$clientQueryBuilder.getColumnNames(scope.client, question.table).then(function(res) {
						if(res.status) {
							question.fields = res.data.map(function(columnName) {
								return {
                  label: columnName,
                  value: columnName
                };
							});
						}
					});
        };

        scope.reset = function() {
          scope.onReset();
          fetchColumns();
        };

        var fetchColumns = function() {
          if(scope.question.table) {
            scope.fetchColumnNames(scope.question);
          }
        };
        fetchColumns();
      }
    };
  }
// Dependency Injection
surveyQuestionDirective.$inject = ["$clientQueryBuilder","$clientSwitcher","Modal","$tablesManager", "$timeout", "LazyLoadService"];
