
'use strict';

export const languageArrayFilterByPrintFilter  = function () {
        return function (val) {
            let label = '';
            if (val && Array.isArray(val) && val.length) {
                val.map(function (item) {
                    let number = item.substring(0, item.indexOf('$'))
                    if (val && val.length == 1) {
                        label = item.substring(item.indexOf('$') + 1);
                    } else {
                        label = `${label} ${item.substring(item.indexOf('$') + 1)} \n`
                    }
                })
                return label;
            } else {
                return val;
            }
        };
    }
// Dependency Injection

