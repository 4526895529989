
'use strict';

export const $clientQueryBuilderService  = function (ClientQueryBuilder) {
    return {
      getColumnNames: function(client, tableName, connectionType) {
        return ClientQueryBuilder.getColumnNames({
          id: client._id || client,
          controller: 'columns',
          table: tableName,
          connectionType: connectionType || 'mysql'
        }).$promise;
      },
      getRecords: function(client, query, connectionType) {
        return ClientQueryBuilder.getRecords({
          id: client._id || client,
          controller: 'records',
          query: query,
          connectionType: connectionType || 'mysql'
        }).$promise;
      },
      getRecordsWithPagination: function(client, query, paginationData) {
        return ClientQueryBuilder.getRecords({
          id: client._id || client,
          controller: 'records-with-pagination',
          query: query,
          page: paginationData && paginationData.page,
          pageSize: paginationData && paginationData.pageSize
        }).$promise;
      }
    };
  }
// Dependency Injection


// Dependency Injection
$clientQueryBuilderService.$inject = ['ClientQueryBuilder'];
