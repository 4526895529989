


'use strict';

export const InterpreterScheduledNTFactory  = function ($resource, appConfig) {
        return $resource(appConfig.apiUrl + '/api/interpreter-schedule-notification/:id/:controller', {
            id: '@_id'
        },
            {
                index: {
                    method: 'GET',
                    params: {
                        controller: ''
                    }
                },
            });
    } 
 

// Dependency Injection
InterpreterScheduledNTFactory.$inject = ['$resource', 'appConfig'];
