


'use strict';

export const ServicePortalFactory  = function ($resource, appConfig) {
    return $resource(appConfig.apiUrl + '/api/service-user/:id/:controller', {
      id: '@_id'
    },
      {
        getPendingApprovalList: {
          method: 'GET',
          params: {
            controller: 'pending-list'
          }
        },
        getPendingApprovalListCount: {
          method: 'GET',
          params: {
            controller: 'pending-list-count'
          }
        },
        create: {
          method: 'POST',
          params: {}
        },

        verifyEmail: {
          method: 'POST',
          params: {
            controller: 'verify-user'
          }
        },
        toggleUser: {
          method: 'POST',
          params: {
            controller: 'user-toggle'
          }
        }
      });
  } 
 

// Dependency Injection
ServicePortalFactory.$inject = ['$resource', 'appConfig'];
