
'use strict';

export const $dqsReportsService  = function (DQSReports) {
      return {
        getList: function (client, paginationData, vendorid) {
          return DQSReports.getList({
                id: client._id || client,
                vendorid: vendorid,
                page: paginationData && paginationData.page,
                pageSize: paginationData && paginationData.pageSize,
            }).$promise;
        },
        getReports: function (client, filter, paginationData) {
          return DQSReports.getReports({
              id: client._id || client,
              filter: filter,
              page: paginationData && paginationData.page,
              pageSize: paginationData && paginationData.pageSize
          }).$promise;
        },

        getReportFilters: function(client, filter) {
          return DQSReports.getReportFilters({
            id: client._id || client,
            filter: filter,
          }).$promise;
        }
      };
}
// Dependency Injection


// Dependency Injection
$dqsReportsService.$inject = ['DQSReports'];
