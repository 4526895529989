export const $commonPrintService = function () {
  return {
      timeFormat: function (judgeRecord) {
          if (judgeRecord) {
              return moment(judgeRecord.date).format('hh:mm A')
          } else {
              return 'N/A';
          }
      },

      judgeNameValue: function (judgeRecord, record) {
          if (judgeRecord && judgeRecord.user) {
              if (judgeRecord.judge && judgeRecord.judge.ijname) {
                  return judgeRecord.judge.ijname;
              }
              return judgeRecord.user.name
          } else {
              return record.ijname;
          }
      },

      additionalLanguages: function (judgeOutRecord) {
          if (judgeOutRecord.additionalLanguages && judgeOutRecord.additionalLanguages.length > 0) {
              var additionalLanguages = '';
              judgeOutRecord.additionalLanguages.forEach((lang) => {
                  if (lang) {
                      additionalLanguages += lang + ', '
                  }
              });
              additionalLanguages = additionalLanguages.replace(/,\s*$/, "");
              return additionalLanguages;
          }
          return 'N/A'
      },

      manipulateArray: function (coiRecord) {
          var workOrderRecords;
          workOrderRecords = coiRecord.map(function (woRecord) {
              if (woRecord && woRecord.taskDetails && woRecord.taskDetails.caseData) {
                  var mainJudgedInOutObj = JSON.stringify(woRecord.taskDetails.caseData.judgedInOut);
                  var judgedInOuts = JSON.parse(mainJudgedInOutObj);
                  if (judgedInOuts.length % 2 != 0) {
                      judgedInOuts.push("N/A")
                  }
                  var arrJudgeInOut = [];
                  var IN, OUT;
                  judgedInOuts.forEach(function (judgedInOut, index) {
                      if (index % 2 == 0) {
                          IN = judgedInOut;
                      } else {
                          OUT = judgedInOut;
                          arrJudgeInOut.push({
                              'IN': IN,
                              'OUT': OUT
                          });
                      }
                  })
                  woRecord.judgedInOuts = arrJudgeInOut;
              }
              return woRecord;
          })
          return (workOrderRecords);
      },

      manipulateINOUT: function (woRecord) {
          if (woRecord && woRecord.taskDetails && woRecord.taskDetails.caseData) {
              var mainJudgedInOutObj = JSON.stringify(woRecord.taskDetails.caseData.judgedInOut);
              var judgedInOuts = JSON.parse(mainJudgedInOutObj);
              if (judgedInOuts.length % 2 != 0) {
                  judgedInOuts.push("N/A")
              }
              var arrJudgeInOut = [];
              var IN, OUT;
              judgedInOuts.forEach(function (judgedInOut, index) {
                  if (index % 2 == 0) {
                      IN = judgedInOut;
                  } else {
                      OUT = judgedInOut;
                      arrJudgeInOut.push({
                          'IN': IN,
                          'OUT': OUT
                      });
                  }
              })
              woRecord.judgedInOuts = arrJudgeInOut;
          }
          return woRecord;
      }
  };
}
