
'use strict';

export const $serviceCommentsService  = function (serviceComments) {
        return {
            getList: function (client, paginationData, filter) {
                return serviceComments.getList({
                    id: client._id || client,
                    page: paginationData && paginationData.page,
                    pageSize: paginationData && paginationData.pageSize,
                    filter
                }).$promise;
            },
            create: function (client, data) {
                return serviceComments.create({
                    _id: client._id || client,
                    data
                }).$promise;
            },

        };
    }
// Dependency Injection


// Dependency Injection
$serviceCommentsService.$inject = ['serviceComments'];
