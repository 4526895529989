'use strict';

export const AccountLoginCtrl = function ($q, $scope, $rootScope, $state, $auth, $location, $clientSwitcher, $activeClientSettings, $mobileApp, ngToast, Modal, CLIENT_ID, $clientSetting, $showError, $user, $afterCognitoAuthLogin) {
    var
      defaultState = 'app.account.dashboard.programs';
    $scope.apps = [];
    $scope.user = {};
    $scope.errors = {};
    $scope.showPassword = false;
    $scope.isLoading = false;
    $scope.isStateChanging = false;

    $scope.redirectState = function (state) {
      state = state || 'app.account.dashboard.tasks';
      // console.log('LOGIN: redirecting to:', state);
      let isServiceUse = $auth.isServicePortalUser();
      if (isServiceUse && JSON.parse(isServiceUse)) {
        state = 'app.account.services';
      }
      $scope.isStateChanging = true;
      $state.go(state);
    };

    $scope.findFeatureTabState = function (id) {
      var fopts = $clientSwitcher.getFeatureOptions(id);

      // console.log('LOGIN: feature options:', id, fopts);

      return !fopts || !fopts.tabState ? defaultState : fopts.tabState;
    };

    $scope.getDefaultState = function () {

      var
        defer = $q.defer();

      $scope.$on('$destroy', $activeClientSettings(function (client, settings) {

        var
          nextState = defaultState;

        if (!client || !settings) {
          return; //  defer.reject(new Error('Invalid client or settings after logging in.'))
        }

        if (settings.defaultTab) {
          nextState = $scope.findFeatureTabState(settings.defaultTab);
        }

        defer.resolve(nextState);
      }));

      return defer.promise;
    };
    function systemNotificationPopup() {
      // Get client settings
      let notic = localStorage.getItem('pre-notice');
      let modalSettings = {
        dismissable: false,
        keyboard: true,
        autoClose: true
      }
      if (!notic) {
        $clientSetting.getPostLoginMessage(CLIENT_ID)
          .then(function (systemNotification) {
            // if login message available then display popup
            if (systemNotification && systemNotification.message) {
              let message = systemNotification.message;

              localStorage.setItem('pre-notice', message);
              Modal.confirm.showWithOutCancelBtn("Notice", message, "Continue", function (cb) {
                if (cb) {
                  // loginWithUser();
                }
              }, modalSettings, 'btn-success');
            } else {
              // loginWithUser();
              localStorage.setItem('pre-notice', null);
            }
          })
          .catch((err) => {
            $scope.errors.other = err.message;
          })
      } else {
        if (notic && notic !== "null" && notic.length) {
          Modal.confirm.showWithOutCancelBtn("Notice", notic, "Continue", function (cb) {
            if (cb) {
              // loginWithUser();
            }
          }, modalSettings, 'btn-success');
        }
      }
    }
    systemNotificationPopup()

    $scope.login = function (form) {
      $scope.submitted = true;

      if (!form.$valid) {
        return;
      }
      localStorage.setItem('isServicePortalUser', false)
      loginWithUser();
      // Get client settings
      // $clientSetting.getPostLoginMessage(CLIENT_ID)
      //   .then(function (systemNotification) {
      //     // if login message available then display popup
      //     if (systemNotification && systemNotification.message) {
      //       let message = systemNotification.message;
      //       Modal.confirm.showWithCancelCB("Notice", message, "Continue", "Cancel", function (cb) {
      //         if (cb) {
      //           loginWithUser();
      //         }
      //       }, 'btn-success');
      //     } else {
      //       loginWithUser();
      //     }
      //   })
      //   .catch((err) => {
      //     $scope.errors.other = err.message;
      //   })
    };

    function loginWithUser() {
      $scope.isLoading = true;
      $auth.login($scope.user)
        .then(function (user) {

          // Sentry.setTag("type", 'manual');
          // Sentry.setTag("userId", user._id);
          // var absUrl = $location.absUrl();
          // Sentry.captureException(new Error(`${user.name} user login time ${new Date()} url ${absUrl}`));

          if ($rootScope.onLogonState !== undefined) { // restore previous state
            var
              state = $rootScope.onLogonState,
              params = $rootScope.onLogonStateParams;

            delete $rootScope.onLogonState;
            delete $rootScope.onLogonStateParams;

            return $state.go(state, params);
          }
          let successObj = {
            status: true,
            email: $scope.user.email || ''
          }
          $afterCognitoAuthLogin(successObj);
          return $scope.getDefaultState()
            .then($scope.redirectState);
        })
        .catch(function (err) {
          console.warn('error===',err)
          let errObj = {
            status: false,
            email: $scope.user.email || ''
          }
          let errorMessage = $showError(err, true);
          let isDisableErrorMessage = errorMessage && typeof errorMessage == 'string' ? errorMessage.includes('Your user has been disabled due to excessive failed login attempts. Please contact system administrator to enable your user now..') : false;
          if (errorMessage == 'Password attempts exceeded')  {
            errorMessage = 'Too many wrong password attempts. Please try again after some time.';
          }
          if (isDisableErrorMessage && $scope.user && $scope.user.email) {
            // Disable User
            console.warn('disable user at login faild', err, errorMessage)
            $user.disableUser($scope.user.email, CLIENT_ID);
            errObj.isUserDisable = true;
          }
          $scope.errors.other = errorMessage;
          errObj.message = errorMessage;
          // $afterCognitoAuthLogin(errObj);
        })
        .finally(() => {
          $scope.isLoading = false;
          $scope.$apply();
        })
    }

    $auth.isLoggedInAsync(function (loggedIn) { // forward immediately to success state if already logged in
      // console.log('LOGIN: loggedIn:', loggedIn);
      if (loggedIn) {
        $scope.getDefaultState().then($scope.redirectState);
      }
      mobileAppData();
    });

    function mobileAppData() {
      localStorage.setItem('app-data', null);
      localStorage.setItem('i-app-data', null);
      let appsData = localStorage.getItem('app-data') && localStorage.getItem('app-data') !== 'null' ? JSON.parse(localStorage.getItem('app-data')) : null;
      let ippsData = localStorage.getItem('i-app-data') && localStorage.getItem('i-app-data') !== 'null' ? JSON.parse(localStorage.getItem('i-app-data')) : null;
      if (appsData && appsData.constructor === Object && Object.keys(appsData).length) {
        $scope.apps = appsData;
        $scope.iosApp = ippsData ? ippsData : null;
        // const app = result.filter(item => item.platform == 'Android');
        // $scope.apps = app && app.length ? app[0] : app;
        // const iApp = result.filter(item => item.platform == 'iOS');
        //  $scope.iosApp = iApp && iApp.length ? iApp[0] : iApp;
      } else {
        $mobileApp.getAllApps().then(function (result) {
          const app = result.filter(item => item.platform == 'Android');
          $scope.apps = app && app.length ? app[0] : app;
          localStorage.setItem('app-data', JSON.stringify({ downloadLink: $scope.apps.downloadLink }));

          const iApp = result.filter(item => item.platform == 'iOS');
          $scope.iosApp = iApp && iApp.length ? iApp[0] : iApp;
          if ($scope.iosApp && $scope.iosApp.downloadLink) {
            localStorage.setItem('i-app-data', JSON.stringify({ downloadLink: $scope.iosApp.downloadLink }));
          }
        })
      }
    }

    $scope.downloadFile = (type) => {
      if (type === 'apk') {
        let link = document.createElement("a");
        link.download = "SOSi-ONEMobile";
        link.href = $scope.apps.downloadLink;
        link.setAttribute('target', '_blank');
        link.click();
      } else if (type == 'ipa') {
        if (!$scope.iosApp || !$scope.iosApp.downloadLink) {
          ngToast.create({
            content: 'Coming soon!',
            className: 'warning'
          });
        }
        let link = document.createElement("a");
        link.download = "SOSi-ONEMobile";
        link.href = $scope.iosApp.downloadLink;
        link.setAttribute('target', '_blank');
        link.click();
      }
      else {
        ngToast.create({
          content: 'Coming soon!',
          className: 'warning'
        });
      }
    }
  }

// Dependency Injection
AccountLoginCtrl.$inject = ["$q","$scope","$rootScope","$state","$auth","$location","$clientSwitcher","$activeClientSettings","$mobileApp","ngToast","Modal","CLIENT_ID","$clientSetting","$showError","$user","$afterCognitoAuthLogin"];

// angular.module('coordinateApp').controller('AccountLoginCtrl', AccountLoginCtrl);
