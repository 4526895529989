
'use strict';

export const $getWOTaskCommonService  = function (TASK_STATE_CHECKEDIN, TASK_STATE_CHECKEDOUT, TASK_STATE_JUDGEDIN, TASK_STATE_JUDGEDOUT, TASK_STATE_EXTEND, TASK_STATE_RELEASE_EVENT,
        TASK_STATE_RELEASE, TASK_STATE_DENY_FOUT, TASK_STATE_IC_RELEASE_REQUEST, INTERPRETER_DISPUTE, COURT_DECLINE, SOSI_DISPUTE) {
        return {
            getTaskEventComments: function (activity, stateActivity) {
                if (activity && activity.isFlagEvent) return '';
                var editComment = null;
                if (activity.originalValue) {
                    editComment = activity.comment || '';
                    return `${editComment ? editComment : ''}`
                }
                if (activity.state == TASK_STATE_CHECKEDIN || activity.state == 'Open') {
                    return activity.comment;
                }
                if (activity.state == TASK_STATE_JUDGEDIN) {
                    var comment = '';
                    if (activity.late) {
                        comment = activity.late.comment ? activity.late.reason + ' | ' + activity.late.comment : activity.late.reason;
                    }
                    if (activity.early) {
                        comment = activity.early.comment ? activity.early.comment : '';
                    }
                    if (activity.performedJudge && activity.performedJudge.comment) {
                        return `${activity.performedJudge.comment} ${comment ? '<br>- ' + comment : ''}`;
                    } else {
                        return `${comment ? comment : ''}`;
                    }
                }
                if (activity.state == TASK_STATE_CHECKEDOUT) {
                    if (activity.comment && (activity.comment != '' || activity.comment != null)) {
                        return activity.comment
                    }
                    var lateComment = '', icAppearedButNotUsed = '', icNotUsedDenyRelease = '', kioskLateFout, autoFOUTReason = '';
                    if (activity.earlyOut) {
                        lateComment = `${activity.earlyOut.reason} ${activity.earlyOut.comment ? ' | ' + activity.earlyOut.comment : ''}`;
                    }
                    if (activity.icAppearedButNotUsed) {
                        icAppearedButNotUsed = activity.icAppearedButNotUsed;
                    }
                    if (activity.icNotUsedDenyRelease) {
                        icNotUsedDenyRelease = activity.icNotUsedDenyRelease;
                    }
                    if (activity.kioskLateFout && activity.kioskLateFout.comment) {
                        kioskLateFout = `Kiosk Late Facility OUT - Reason: ${activity.kioskLateFout.reason}. Comment: ${activity.kioskLateFout.comment}`;
                    }
                    var autoFOUT;
                    stateActivity.forEach((sActivity) => {
                        if (sActivity.state == TASK_STATE_JUDGEDOUT && sActivity.autoFacilityOut && sActivity.autoFacilityOut.isAutoFOut) {
                            autoFOUT = sActivity;
                        }
                    })
                    if (autoFOUT && autoFOUT.autoFacilityOut && autoFOUT.autoFacilityOut.isAutoFOut) {
                        autoFOUTReason = autoFOUT.autoFacilityOut.reason ? autoFOUT.autoFacilityOut.reason : '';
                    }
                    return `${lateComment ? '- ' + lateComment + '<br>' : ''} ${icAppearedButNotUsed ? '- Interpreter appeared, but not Used(Release) | ' + icAppearedButNotUsed : ''} ${icNotUsedDenyRelease ? '<br>- Interpreter appeared, but not Used(Deny Release) | ' + icNotUsedDenyRelease : ''} ${editComment ? '- ' + editComment : ''} ${kioskLateFout ? kioskLateFout : ''} ${autoFOUTReason ? autoFOUTReason : ''}`;
                }
                if (activity.state == TASK_STATE_EXTEND) {
                    if (activity && activity.extend) {
                        return `${activity.extend.comments + '<br>'} ${editComment ? '- ' + editComment : ''}`;
                    }
                }
                // #697 - Auto Release on Next Judge Out Event then comment display
                if (activity.state == TASK_STATE_RELEASE_EVENT) {
                    if (activity && activity.releaseEvent && activity.releaseEvent.comments) {
                        return `${activity.releaseEvent.comments + '<br>'} ${editComment ? '- ' + editComment : ''}`;
                    }
                }
                if (activity.state == TASK_STATE_RELEASE) {
                    if (activity && activity.release && activity.release.comments) {
                        return `${activity.release.comments + '<br>'} ${editComment ? '- ' + editComment : ''}`;
                    }
                }
                if (activity.state == TASK_STATE_DENY_FOUT && activity.denyFacilityOut) {
                    if (activity.denyFacilityOut.comments) {
                        return `${activity.denyFacilityOut.reason ? activity.denyFacilityOut.reason + ' | ' : ''} ${activity.denyFacilityOut.comments + '<br>'} ${editComment ? '- ' + editComment : ''}`;
                    }
                    return `${activity.denyFacilityOut.reason} ${editComment ? '<br>- ' + editComment : ''}`;
                }
                if (activity.state == TASK_STATE_IC_RELEASE_REQUEST) {
                    return activity.releaseRequest && activity.releaseRequest.comment || '';
                }

                let disputeEvent = [INTERPRETER_DISPUTE, COURT_DECLINE, SOSI_DISPUTE];
                if (disputeEvent.indexOf(activity.state) > -1) {
                    if (activity.disputeEvent && activity.disputeEvent.comments && activity.disputeEvent.comments.length > 0) {
                        return activity.disputeEvent.comments[0].comment || ''
                    }
                }

                return `${activity.performedJudge && activity.performedJudge.comment ? '<br>- ' + activity.performedJudge && activity.performedJudge.comment : activity.comment ? activity.comment : ''}`;

            }
        }
    }
// Dependency Injection


// Dependency Injection
$getWOTaskCommonService.$inject = ['TASK_STATE_CHECKEDIN', 'TASK_STATE_CHECKEDOUT', 'TASK_STATE_JUDGEDIN', 'TASK_STATE_JUDGEDOUT', 'TASK_STATE_EXTEND', 'TASK_STATE_RELEASE_EVENT', 'TASK_STATE_RELEASE', 'TASK_STATE_DENY_FOUT', 'TASK_STATE_IC_RELEASE_REQUEST', 'INTERPRETER_DISPUTE', 'COURT_DECLINE', 'SOSI_DISPUTE'];
