


'use strict';

export const commentsTableDirective  = function ($q, $performTaskAudio, $performTaskScore, $performTaskTranscribeAudio) {
        return {
            restrict: 'E',
            templateUrl: 'app/account/dashboard/tasks/tabs/organization/performance/comments/comments.html',
            link: function ($scope) {
                var DEFAULT_PAGINATION = {
                    page: 1,
                    pageSize: 10,
                    totalSize: -1
                };

                $scope.comments = [
                    // { sentiment: "negative", comment: "This is the comment text", employee: "Bob smith" },
                    // { sentiment: "negative", comment: "This is the comment text", employee: "Jim Jones" },
                    // { sentiment: "mixed", comment: "This is the comment text", employee: "Harry Willis" },
                    // { sentiment: "positive", comment: "This is the comment text", employee: "Mike Tony" },
                    // { sentiment: "exceptional", comment: "This is the comment text", employee: "Mike" },
                ];

                var promise = $scope.selectedEmployee.children.map(function (employee) {
                    return {
                        employee: employee.firstName + " " + employee.lastName,
                        tasks: employee.tasks
                    }
                });

                var employeeComments = [];
                $q.all(promise)
                    .then(function (result) {
                        result.forEach(function (res) {
                            if (res.tasks) {
                                res.tasks.forEach(function (task) {
                                    var employeeData = {};
                                    employeeData.sentiment = task.taskData.verbatim.rating;
                                    employeeData.employee = res.employee
                                    employeeData.comment = task.taskData.verbatim.transcription;
                                    employeeComments.push(employeeData)
                                });

                                $scope.comments = employeeComments;
                                $scope.paginationComment = angular.copy(DEFAULT_PAGINATION);
                                $scope.paginationComment.totalSize = $scope.comments ? $scope.comments.length : 0;
                            }
                        });
                    });
                $scope.searchEmployee = function (rec) {
                    if (!$scope.model.searchEmployeeStr || $scope.model.searchEmployeeStr == '') {
                        return true;
                    } else {
                        return (rec.employee).toLowerCase().indexOf($scope.model.searchEmployeeStr.toLowerCase()) >= 0;
                    }
                }
            }
        }
    } 
 

// Dependency Injection
commentsTableDirective.$inject = ['$q', '$performTaskAudio', '$performTaskScore', '$performTaskTranscribeAudio'];
