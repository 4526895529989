


'use strict';

export const servicePanelFactory  = function ($resource, appConfig) {
        return $resource(appConfig.apiUrl + '/api/services/:id/:controller', {
            id: '@_id'
        },
            {
                getServiceCount: {
                    method: 'GET',
                    params: {
                        controller: 'get-service-count',
                        id: '@id'
                    }
                },
                getServiceDetail: {
                    method: 'GET',
                    params: {
                        controller: 'get-service-detail',
                        id: '@id'
                    }
                },
                getServiceById: {
                    method: 'GET',
                    params: {
                        id: '@id'
                    }
                },
                getServiceStatus: {
                    method: 'GET',
                    params: {
                        controller: 'get-service-status',
                        id: '@id'
                    }
                },
                getServiceByStatus: {
                    method: 'GET',
                    params: {
                        controller: 'get-service-by-status',
                        id: '@id'
                    }
                },
                create: {
                    method: 'POST',
                    // params: {
                    //     controller: 'create'
                    // }
                },
                update: {
                    method: 'POST',
                    params: {
                        controller: 'update'
                    }
                },
                updateStatus: {
                    method: 'POST',
                    params: {
                        controller: 'update-status'
                    }
                },
                removeAttachments: {
                    method: 'POST',
                    params: {
                        controller: 'remove-attachment'
                    }
                },
                compileQuote: {
                    method: 'POST',
                    params: {
                        controller: 'compile-quote'
                    }
                },
            });
    } 
 

// Dependency Injection
servicePanelFactory.$inject = ['$resource', 'appConfig'];
