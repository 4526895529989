
'use strict';

export const $externalTaskService  = function ($q, ExternalTask) {
  return {
    getTaskFromToken: function(token) {
      return ExternalTask.getTaskFromToken({
        id: token 
      }).$promise;
    },
    getRecord: function(token) {
      return ExternalTask.getRecord({
        id: token
      }).$promise;
    },
    updateExternalTaskStatus: function(token, message, uploads, status) {
      return ExternalTask.updateExternalTaskStatus({
        id: token,
        message: message,
        uploads: uploads,
        status: status
      }).$promise;
    },
    resolveExternalTask: function(token, message, markClosed, leaveOpen, uploads) {
      return ExternalTask.resolveExternalTask({
        id: token,
        message: message,
        markClosed: markClosed,
        leaveOpen: leaveOpen || !markClosed,
        uploads: uploads
      }).$promise;
    },
    submitContactForm: function(token, message, uploads) {
      return ExternalTask.contactForm({
        id: token,
        message: message,
        uploads: uploads
      }).$promise;
    }
  };
}
// Dependency Injection


// Dependency Injection
$externalTaskService.$inject = ['$q', 'ExternalTask'];
