'use strict';


export const RulesManagerFactory = function ($resource, appConfig) {
    return $resource(appConfig.apiUrl + '/api/rules/:id/:controller', {
      id: '@_id'
    },
    {
      saveRule: {
        method: 'POST',
        params: {
          controller: 'create'
        }
      },
      updateRule: {
        method: 'POST',
        params: {
          controller: 'update'
        }
      },
      deleteRule: {
        method: 'POST',
        params: {
          controller: 'delete'
        }
      },
      getRules: {
        method: 'GET',
        params: {
          controller: '',
          id: '@id'
        },
        isArray: true
      },
      getRuleDetails: {
        method: 'GET',
        params: {
          controller: 'details',
          id: '@id'
        }
      },
      toggleRuleState: {
        method: 'POST',
        params: {
          controller: 'toggle-state'
        }
      }
    });
  } 
 export const $rulesManagerService = function (RulesManager) {
    return {
      saveRule: function(client, ruleData) {
        return RulesManager.saveRule({
          _id: client._id || client,
          ruleData: ruleData
        }).$promise;
      },
      deleteRule: function(client, ruleData) {
        return RulesManager.deleteRule({
          _id: client._id || client,
          ruleId: ruleData._id
        }).$promise;
      },
      updateRule: function(client, rule, data) {
        return RulesManager.updateRule({
          _id: client._id || client,
          rule: rule,
          data: data
        }).$promise;
      },
      getRules: function(client) {
        return RulesManager.getRules({
          id: client._id || client
        }).$promise;
      },
      getRuleDetails: function(client, ruleId) {
        return RulesManager.getRuleDetails({
          id: client._id || client,
          rule: ruleId
        }).$promise;
      },
      toggleRuleState: function(client, ruleData) {
        return RulesManager.toggleRuleState({
          _id: client._id || client,
          ruleId: ruleData._id
        }).$promise;
      }
    };
  }
// Dependency Injection
RulesManagerFactory.$inject = ["$resource","appConfig"];
$rulesManagerService.$inject = ["RulesManager"];
