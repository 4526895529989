'use strict';

export const TaskCreateRulesConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard.task-create-rules', {
        url: '/task-create-rules',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/task-create-rules/task-create-rules.html', 'e2a052ca'); }],
        controller: 'DashboardTaskCreateRulesCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/task-create-rules/task-create-rules.controlller.js', 'DashboardTaskCreateRulesCtrl'); }]
                      },
        data: {
          permission: 'AUTO_TASKING_READ'
        }
      });
  }
// Dependency Injection
TaskCreateRulesConfig.$inject = ["$stateProvider"];
