
'use strict';

export const $fundingEventsService  = function (FunddingEvents) {
    return {

      getFunddingList: function (client, filter, paginationData) {
        return FunddingEvents.getFunddingList({
          id: client._id || client,
          filter: filter,
          page: paginationData && paginationData.page,
          pageSize: paginationData && paginationData.pageLimit
        }).$promise;
      },

      create: function (client, data) {
        return FunddingEvents.create({
          id: client._id || client,
          data,
        }).$promise;
      },
      update: function (client, data) {
        return FunddingEvents.update({
          id: client._id || client,
          data,
        }).$promise;
      },

    };
  }
// Dependency Injection


// Dependency Injection
$fundingEventsService.$inject = ['FunddingEvents'];
