
'use strict';

export const $liveReportsService  = function (LiveReports, $http) {
      return {
        filterCourtOperationsDetails: function (client, filter, paginationData) {
          return LiveReports.filterCourtOperations({
              id: client._id || client,
              filterBy: filter,
              page: paginationData && paginationData.page,
              pageSize: paginationData && paginationData.pageSize
          }).$promise;
        },
        reportingCourtLinkToHrgCourt: function(client, filter) {
          return LiveReports.reportingCourtLinkToHrgCourt({
            id: client._id || client,
            controller: 'reporting-court-link-to-hrgCourt',
            filterBy: filter
          }).$promise;
        },
        getLinkFilterDetails: function(client, filter) {
          return LiveReports.getLinkFilterDetails({
            id: client._id || client,
            controller: 'link-filter-details',
            filterBy: filter
          }).$promise;
        },
        downloadReport: function(client, filter) {
          return LiveReports.downloadReport({
            id: client._id || client,
            controller: 'download-report',
            filterBy: filter
          }).$promise;
        },
        getAssignmentReportData: function(client, filter) {
          return LiveReports.getAssignmentReportData({
            id: client._id || client,
            controller: 'get-assignment-report-data',
            filter: filter
          }).$promise;
        },
        getDailyReportData: function(client, filter) {
          return LiveReports.getDailyReportData({
            id: client._id || client,
            controller: 'get-daily-report-data',
            filter: filter
          }).$promise;
        },
        getAssignmentStatusReport: function(client, filter) {
          return LiveReports.getAssignmentStatusReport({
            id: client._id || client,
            controller: 'get-assignment-status-report',
            filter: filter
          }).$promise;
        },
        getFilterData: function(client, filter) {
          return LiveReports.getFilterData({
            id: client._id || client,
            controller: 'get-filter-data',
            filter
          }).$promise;
        },
        downloadDailyReport: function (client, filter) {
          return $http({
            url: '/api/live-reports/' + (client._id || client) + '/download-daily-report',
            method: "POST",
            params: {
              filterBy: JSON.stringify(filter),
              timeOffset: new Date().getTimezoneOffset()
            },
            responseType: "arraybuffer"
          })
            .success(function (data, status, headers, config) {
              if (data.status === false) {
                return data;
              }

              saveAs(new Blob([data],{type:"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}), `DailyReport-${filter.date}.xlsx`);
              // let csvContent = "data:text/xlsx;charset=utf-8," + data;
              // const encodedUri = encodeURI(csvContent);
              // let link = document.createElement('a');
              // link.setAttribute('href', encodedUri);
              // link.setAttribute('download', `DailyReport-${filter.date}.xlsx`);
              // link.setAttribute('target', '_blank');
              // document.body.appendChild(link);

              // link.click();
            })
            .error(function (data, status, headers, config) {
              // alert('Error while downloading the tasks');
            });
        },
        downloadAssignmentReport: function (client, filter) {
          return $http({
            url: '/api/live-reports/' + (client._id || client) + '/download-assignment-report',
            method: "POST",
            params: {
              filterBy: JSON.stringify(filter),
              timeOffset: new Date().getTimezoneOffset()
            },
            responseType: "arraybuffer"
          })
            .success(function (data, status, headers, config) {
              console.log(data)
              if (data.status === false) {
                return data;
              }

              saveAs(new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), `AssignmentReport-${moment().format('MM/DD/YYYY HH:mm:ss')}.xlsx`);
            })
            .error(function (data, status, headers, config) {
              // alert('Error while downloading the tasks');
            });
        }
      };
}
// Dependency Injection


// Dependency Injection
$liveReportsService.$inject = ['LiveReports', '$http'];
