


'use strict';

export const icaTrackingFactory  = function ($resource, appConfig) {
        return $resource(appConfig.apiUrl + '/api/ica-tracking/:id/:controller', {
            id: '@_id'
        },
            {
                getList: {
                    method: 'GET',
                    params: {
                        controller: 'icatracking-list'
                    }
                },
                getInterpreterRates: {
                    method: 'GET',
                    params: {
                        controller: 'get-interpreter-rates'
                    }
                },
                getInterpreterTravelRates: {
                    method: 'GET',
                    params: {
                        controller: 'get-interpreter-travel-rates'
                    }
                }
            });
    } 
 

// Dependency Injection
icaTrackingFactory.$inject = ['$resource', 'appConfig'];
