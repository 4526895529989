


'use strict';

export const clientContactCoordinateFactory  = function ($resource, appConfig) {
    return $resource(appConfig.apiUrl + '/api/client-contact-coordinate/:controller', {      
    },
    {
      index: {
        method: 'POST',
        params: {
          controller: ''
        },
        isArray: false
      },
      records: {
      	method: 'POST',
        params: {
          controller: 'records'
        },
        isArray: false
      },
      customerRecords: {
        method: 'POST',
        params: {
          controller: 'customer-records'
        },
        isArray: false
      }
    });
  } 
 

// Dependency Injection
clientContactCoordinateFactory.$inject = ['$resource', 'appConfig'];
