


'use strict';

export const ClientPGQueryBuilderFactory  = function ($resource, appConfig) {
    return $resource(appConfig.apiUrl + '/api/pg-query-builder/:id/:controller', {
      id: '@_id'
    },
    {
      getRecords: {
        method: 'GET',
        params: {
          id: '@id'
        }
      }
    });
  } 
 

// Dependency Injection
ClientPGQueryBuilderFactory.$inject = ['$resource', 'appConfig'];
