
'use strict';

export const $syncHistoryService  = function (SyncHistory) {
    return {
      getLogs: function (client, filter) {
        return SyncHistory.getHistory({
          id: client._id || client,
          tableName: filter.tableName || undefined,
          coi: filter.coi || undefined,
          internalId: filter.internalId || undefined,
          vendorId: filter.vendorid || undefined,
          recordnumber: filter.recordnumber || undefined
        }).$promise;
      },
    };
  }
// Dependency Injection


// Dependency Injection
$syncHistoryService.$inject = ['SyncHistory'];
