
'use strict';

export const $getTZTimeListService  = function ($getTZOffset) {
        return {
            get: function (deldate, sched, timezone) {
                let ecoiTimezone = $getTZOffset(timezone);
                let _deldate =  moment.utc(deldate).format('YYYY-MM-DD');
                let ecoiTimezoneOffset = null;
                if (ecoiTimezone && ecoiTimezone.tzName) {
                    ecoiTimezoneOffset = moment(_deldate).tz(ecoiTimezone.tzName).format('ZZ');
                } else {
                    ecoiTimezoneOffset = moment(_deldate).tz(timezone).format('ZZ');
                }

                let ecoiDate = moment.utc(deldate).format('YYYY-MM-DD') + 'T' + sched + '' + ecoiTimezoneOffset;
                 // console.log('ecoiDate', ecoiDate)
                let utcDate = moment.utc(ecoiDate).format()
                // console.log('utcDate', utcDate)
                const defaultTZDisplay = ['EDT', 'CDT', 'MDT', 'MST', 'PDT', 'ADT'];
                // const defaultTZDisplay = ['EST5EDT', 'CST6CDT', 'MST7MDT', 'PST8PDT'];

                const tzs = [
                    { tz: 'EDT', tzName: "America/New_York", label: "Eastern Daylight Time", tzFormat: 'EST5EDT' },
                    { tz: 'CDT', tzName: "America/Chicago", label: "Central Daylight Time", tzFormat: 'CST6CDT' },
                    // { tz: 'MST', tzName: "America/Denver", label: "Mountain Standard Time", tzFormat: 'MST7MDT' },
                    // { tz: 'MDT', tzName: "America/Phoenix", label: "Mountain Daylight Time", tzFormat: 'MST7MDT' },
                    { tz: 'MDT', tzName: "America/Denver", label: "Mountain Daylight Time", tzFormat: "America/Denver", offset: "-0600" },
                    { tz: 'MST', tzName: "America/Phoenix", label: "Mountain Standard Time", tzFormat: "America/Phoenix", offset: "-0700" },
                    { tz: 'PDT', tzName: "America/Los_Angeles", label: "Pacific Daylight Time", tzFormat: 'PST8PDT' },
                    { tz: 'ADT', tzName: "America/Anchorage", label: "Atlantic Daylight Time", tzFormat: 'America/Anchorage' },
                    // { tz: "HDT", tzName: "Asia/Vladivostok", label: "Hawaii-Aleutian Daylight Time",offset: "-0900", timezone: "HDT" },
                    { tz: "HST", tzName: "US/Hawaii", label: "Hawaii-Aleutian Standard Time", offset: "-1000", timezone: "HST" },
                ];
                let list = [];
                tzs.map(timezoneObj => {
                    let time = moment.tz(utcDate, timezoneObj.tzName).format('hh:mm A')
                    // let time = moment(utcDate).utcOffset(timezoneObj.offset).format('hh:mm A')
                    let obj = {
                        time: ['MDT', 'MST'].indexOf(timezoneObj.tz) >= 0 ? moment(utcDate).utcOffset(timezoneObj.offset).format('hh:mm A') : time,
                        tz: ['MDT', 'MST'].indexOf(timezoneObj.tz) >= 0
                            ? timezoneObj.tz //moment.tz(timezoneObj.tzName).format('z')
                            : moment.tz(utcDate, timezoneObj.tzName).format('z') //moment.tz(timezoneObj.tzName).format('z'),//timezoneObj.tz,
                    }
                    list.push(obj)

                })
                return list
            }
        }

    }
// Dependency Injection


// Dependency Injection
$getTZTimeListService.$inject = ['$getTZOffset'];
