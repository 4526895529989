
'use strict';

export const $cognitoService  = function ($cognitoUserPool, $state, $cognitoUser, $setGetCognitoUser, $http, $authStorage, appConfig) {
        return {
            authenticateUser: function (user) {
                return new Promise((resolve, reject) => {
                    try {
                        let userPool = $cognitoUserPool();
                        var authenticationData = {
                            Username: user.email,
                            Password: user.password
                        };

                        var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
                            authenticationData
                        );
                        authenticationDetails.validationData = {
                            isServicePortalPage: (!!user.isServicePortal).toString()
                        };

                        var userData = {
                            Username: user.email,
                            Pool: userPool
                        };
                        var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
                        cognitoUser.authenticateUser(authenticationDetails, {
                            onSuccess: function (result) {
                                // console.log(result);
                                $setGetCognitoUser.set({ cognitoUser });
                                resolve(result);
                            },
                            onFailure: function (err) {
                                // console.log(err);
                                reject(err);
                            },
                            newPasswordRequired: function (userAttributes, requiredAttributes) {
                                // User was signed up by an admin and must provide new
                                // password and required attributes, if any, to complete
                                // authentication.

                                // the api doesn't accept this field back
                                delete userAttributes.email_verified;

                                $setGetCognitoUser.set({ cognitoUser, userAttributes, isForcePassChange: true });
                                $state.go('app.account.changePassword')
                            }
                        });
                    } catch (error) {
                        reject(error);
                    }
                })
            },
            forceChangePassword: function (newPassword) {
                return new Promise((resolve, reject) => {
                    let cognitoUserDetails = $setGetCognitoUser.get();
                    cognitoUserDetails.cognitoUser.completeNewPasswordChallenge(newPassword, cognitoUserDetails.userAttributes, {
                        onSuccess: function (result) {
                            // console.log(result)
                            resolve(result);
                        },
                        onFailure: function (err) {
                            // console.log(err);
                            reject(err);
                        }
                    });
                })
            },
            changePassword: function (oldPassword, newPassword) {
                return new Promise((resolve, reject) => {
                    $cognitoUser()
                        .then((cognitoUser) => {
                            cognitoUser.changePassword(oldPassword, newPassword, function (err, result) {
                                if (err) {
                                    // console.log('Error: ', err)
                                    reject(err);
                                    return;
                                }
                                // console.log('call result: ' + result);
                                resolve(result);
                            });
                        })
                        .catch((err) => {
                            // console.log('Catch Error: ', err)
                            reject(err);
                        })
                })
            },
            forgotPassword: function (userName) {
                return new Promise((resolve, reject) => {
                    $cognitoUser(userName)
                        .then((cognitoUser) => {
                            cognitoUser.forgotPassword({
                                onSuccess: function (data) {
                                    // successfully initiated reset password request
                                    // console.log('CodeDeliveryData from forgotPassword: ', data);
                                    resolve(data);
                                },
                                onFailure: function (err) {
                                    // console.log(err);
                                    reject(err);
                                }
                            });
                        })
                        .catch((err) => {
                            // console.log('Catch Error: ', err)
                            reject(err);
                        })
                })
            },
            confirmPassword: function (userName, verificationCode, newPassword) {
                return new Promise((resolve, reject) => {
                    $cognitoUser(userName)
                        .then((cognitoUser) => {
                            cognitoUser.confirmPassword(verificationCode, newPassword, {
                                onSuccess(data) {
                                    // console.log('Password confirmed!', data);
                                    resolve(data);
                                },
                                onFailure(err) {
                                    // console.log('Password not confirmed!', err);
                                    reject(err);
                                },
                            });
                        })
                        .catch((err) => {
                            // console.log('Catch Error: ', err)
                            reject(err);
                        })
                })
            },
            signUp: function (body) {
                return new Promise((resolve, reject) => {
                    let { email, password, name, internalId, roles } = body
                    let userPool = $cognitoUserPool();
                    // console.log(userPool)
                    var attributeList = [];
                    var attributeEmail = new AmazonCognitoIdentity.CognitoUserAttribute({ Name: 'email', Value: email });
                    // var attributeName = new AmazonCognitoIdentity.CognitoUserAttribute({ Name: 'name', Value: name });
                    // var attributeInternalId = new AmazonCognitoIdentity.CognitoUserAttribute({ Name: 'internalId', Value: internalId });
                    // var attributeRoles = new AmazonCognitoIdentity.CognitoUserAttribute({ Name: 'roles', Value: roles });

                    attributeList.push(attributeEmail);
                    // attributeList.push(attributeName);
                    // attributeList.push(attributeInternalId);
                    // attributeList.push(attributeRoles);
                    userPool.signUp(email, password, attributeList, null, function (err, result) {
                        if (err) {
                            reject(err)
                            return;
                        }
                        var cognitoUser = result.user;
                        console.log('user name is ' + cognitoUser.getUsername());
                        resolve(result);
                    });
                })
            },
            globalSignOut: function () {
                return new Promise((resolve, reject) => {
                    if ($authStorage.getToken()) {
                        return $http({
                            url: `${appConfig.logoutApiUrl}/logout`,
                            method: "POST",
                            headers: {
                                Authorization: $authStorage.getToken()
                            }
                        })
                            .success(function (data, status, headers, config) {
                                if (data && data.status) {
                                    $cognitoUser()
                                        .then((cognitoUser) => {
                                            if (cognitoUser) {
                                                cognitoUser.signOut();
                                                resolve();
                                            } else {
                                                reject('User not found!');
                                            }
                                        })
                                        .catch((err) => {
                                            // console.log('Catch Error: ', err)
                                            reject(err);
                                        })
                                } else {
                                    reject('User Logout Fail!');
                                }
                            })
                            .error(function (data, status, headers, config) {
                                // alert('Error while downloading the tasks');
                                reject('User Logout Fail!');
                            });
                    } else {
                        $cognitoUser()
                            .then((cognitoUser) => {
                                if (cognitoUser) {
                                    cognitoUser.signOut();
                                    resolve();
                                } else {
                                    reject('User not found!');
                                }
                            })
                            .catch((err) => {
                                // console.log('Catch Error: ', err)
                                reject(err);
                            })
                    }
                })
            },
            getUserConcurrentSession: function (email) {
                return new Promise((resolve, reject) => {
                    if ($authStorage.getToken()) {
                        return $http({
                            url: `${appConfig.logoutApiUrl}/get-user-concurrent-session?email=${email}`,
                            method: "GET",
                            headers: {
                                Authorization: $authStorage.getToken()
                            }
                        })
                            .success(function (data, status, headers, config) {
                                if (data && data.status) {
                                    resolve(data.useDetails);
                                } else {
                                    reject('User details not found!');
                                }
                            })
                            .error(function (data, status, headers, config) {
                                reject('User details not found!');
                            });
                    } else {
                        reject('User details not found!');
                    }
                })
            },
            clearUserConcurrentSession: function (email) {
                console.log(email, JSON.stringify({ email }))
                return new Promise((resolve, reject) => {
                    if ($authStorage.getToken()) {
                        return $http({
                            url: `${appConfig.logoutApiUrl}/clear-user-concurrent-session`,
                            method: "POST",
                            headers: {
                                Authorization: $authStorage.getToken()
                            },
                            params: {
                                email
                            }
                        })
                            .success(function (data, status, headers, config) {
                                if (data && data.status) {
                                    resolve(data.status);
                                } else {
                                    reject('User details not found!');
                                }
                            })
                            .error(function (data, status, headers, config) {
                                reject('User details not found!');
                            });
                    } else {
                        reject('User details not found!');
                    }
                })
            }
        };
    }
// Dependency Injection


// Dependency Injection
$cognitoService.$inject = ['$cognitoUserPool', '$state', '$cognitoUser', '$setGetCognitoUser', '$http', '$authStorage', 'appConfig'];
