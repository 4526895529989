


'use strict';

export const AutoAssignedFactory  = function ($resource, appConfig) {
        return $resource(appConfig.apiUrl + '/api/auto-assigned/:id/:controller', {
            id: '@_id'
        },
            {
                index: {
                    method: 'GET',
                    params: {
                        controller: ''
                    }
                },
                getAssignedCount: {
                    method: 'GET',
                    params: {
                        controller: 'assigned-count'
                    }
                },
                getTaskCOIEvents: {
                    method: 'GET',
                    params: {
                        controller: 'coi-task-event'
                    }
                }
            });
    } 
 

// Dependency Injection
AutoAssignedFactory.$inject = ['$resource', 'appConfig'];
