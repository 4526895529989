
'use strict';

export const $performTaskHistoryService  = function (Modal) {
  return function (cb) {
    cb = angular.isFunction(cb) ? cb : angular.noop;
    return Modal.alertActions.viewTaskHistory(function () {
      return cb.apply(this, arguments);
    }, 'Task History');
  };
}
 

// Dependency Injection
$performTaskHistoryService.$inject = ['Modal'];
