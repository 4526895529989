
'use strict';

export const $permissionMatrixService  = function (PermissionMatrix) {
        return {
            getRoleDetails: function (client, roleId) {
                return PermissionMatrix.getRoleDetails({
                    id: client._id || client,
                    controller: roleId
                }).$promise;
            },
            getUserRolesPermissions: function (client, userId) {
                return PermissionMatrix.getUserRolesPermissions({
                    id: client._id || client,
                    userId: userId
                }).$promise;
            },
            getPermissionByPage: function (client, page, type) {
                return PermissionMatrix.getPermissionByPage({
                    id: client._id || client,
                    page, type
                }).$promise;
            },
        };
    }
// Dependency Injection


// Dependency Injection
$permissionMatrixService.$inject = ['PermissionMatrix'];
