'use strict';

export const NationalityConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard.settings.nationality', {
        url: '/nationality',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/nationality/nationality.html', '9ed9374a'); }],
        controller: 'DashboardSettingsNationalityCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/nationality/nationality.controller.js', 'DashboardSettingsNationalityCtrl'); }]
                      }
      })
      .state('app.account.dashboard.settings.nationality.index', {
        url: '/index',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/nationality/index/index.html', '49ea5c0a'); }],
        controller: 'DashboardSettingsNationalityIndexCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/nationality/index/index.controller.js', 'DashboardSettingsNationalityIndexCtrl'); }]
                      }
      })
      .state('app.account.dashboard.settings.nationality.create', {
        url: '/create',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/nationality/create/create.html', '8513c6a'); }],
        controller: 'DashboardSettingsNationalityCreateCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/nationality/create/create.controller.js', 'DashboardSettingsNationalityCreateCtrl'); }]
                      }
    })
    .state('app.account.dashboard.settings.nationality.update', {
        url: '/update/:id',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/nationality/update/update.html', '16cad6ca'); }],
        controller: 'DashboardSettingsNationalityUpdateCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/nationality/update/update.controller.js', 'DashboardSettingsNationalityUpdateCtrl'); }],
                          '$selectedNationality': ['$stateParams', '$nationalities', '$clientSwitcher', function($stateParams, $nationalities, $clientSwitcher) {
                              return $nationalities.getById($clientSwitcher.getCurrentClient(), $stateParams.id);
                            }]
                      },

    });
  }
// Dependency Injection
NationalityConfig.$inject = ["$stateProvider"];
