import './remote-work.scss';
'use strict';

export const RemoteWorkConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard.remoteWork', {
        url: '/remote-work',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/remote-work/remote-work.html', 'dc43068a'); }],
        controller: 'DashboardRemoteWorkCtrl',
        resolve: {
            lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/remote-work/remote-work.controller.js', 'DashboardRemoteWorkCtrl'); }],
            '$userPermissions': ['$auth', function($auth) {
              return $auth.getCurrentUser();
            }]
        },
        data: {
          ability: 'client.remoteWork',
          permission: 'REMOTE_WORK_READ'
        },
      });
  }
// Dependency Injection
RemoteWorkConfig.$inject = ["$stateProvider"];
