'use strict';

angular
export const DashboardSettingsInventorysIndexCtrl = function ($rootScope, $scope, $activeClient, $clientInventory, Modal) {

  $scope.loading = false;

  function reloadInventorys(client) {
    $scope.loading = true;
    return $clientInventory.getInventorys(client)
      .then(function (res) {
        if(res.status) {
          $scope.inventorys = res.data;
        }
      })
      .finally(function () {
        $scope.loading = false;
      });
  }

  // listen for client switching
  $scope.$on('$destroy', $activeClient(function (client) {
    if(!client) return;

    reloadInventorys(client);
  }));

  $scope.deleteInventory = function(inventory) {
    $activeClient(function (client) {
      if(!client) {
        return;
      }

      Modal.confirm.show(
        "Confirm Delete",
        "Are you sure you want to delete <strong>"+inventory.partName+"</strong>?",
        "Delete", "Cancel",
        function(){
        $clientInventory.deleteInventory(client, inventory).then(function() {
            reloadInventorys(client);
        });
      });
    });
  };
}
// Dependency Injection
DashboardSettingsInventorysIndexCtrl.$inject = ["$rootScope","$scope","$activeClient","$clientInventory","Modal"];

// angular.module('coordinateApp').controller('DashboardSettingsInventorysIndexCtrl', DashboardSettingsInventorysIndexCtrl);
