


'use strict';

export const OfficeOperationsFactory  = function ($resource, appConfig) {
        return $resource(appConfig.apiUrl + '/api/office-operations/:id/:controller', {
            id: '@_id'
        },
            {
                filterCourtOperations: {
                    method: 'POST',
                    params: {
                        id: '@id',
                        controller: 'filter-court-operations'
                    }
                },
                getLinkFilterDetails: {
                    method: 'GET',
                    params: {
                        id: '@id',
                    }
                },
                reportingCourtLinkToHrgCourt: {
                    method: 'GET',
                    params: {
                        id: '@id',
                    }
                },
                checkInterpreterOnWO: {
                    method: 'GET',
                    params: {
                        id: '@id',
                    }
                },
                checkInterpreterOnWOById: {
                    method: 'GET',
                    params: {
                        id: '@id',
                    }
                },
                checkECOIOrNot: {
                    method: 'GET',
                    params: {
                        id: '@id',
                    }
                },
                getInterpreterLangs: {
                    method: 'GET',
                    params: {
                        id: '@id',
                    }
                },
                getRecordForReportingCourt: {
                    method: 'GET',
                    params: {
                        id: '@id',
                    }
                },
                getRequestInterpreterMatch: {
                    method: 'POST',
                    params: {
                        id: '@id',
                        controller: 'get-request-interpreter-match'
                    }
                },
                getEcoiDetails: {
                    method: 'POST',
                    params: {
                      id: '@id',
                      controller: '@controller'
                    }
                },
                getEcoiDetailsNew: {
                    method: 'POST',
                    params: {
                      id: '@id',
                      controller: '@controller'
                    }
                },
                getEcoiById: {
                    method: 'GET',
                    params: {
                        id: '@id'
                    }
                },
                downloadEcoiDetails: {
                  method: 'POST',
                  params: {
                    id: '@id',
                    controller: 'download-ecoi-details'
                  }
                }
            });
    } 
 

// Dependency Injection
OfficeOperationsFactory.$inject = ['$resource', 'appConfig'];
