


'use strict';

export const interpreterDocumentFactory  = function ($resource, appConfig) {
        return $resource(appConfig.apiUrl + '/api/interpreter-document/:id/:controller', {
            id: '@_id'
        },
            {
                getDocument: {
                    method: 'get',
                    params: {
                        id: '@id',
                    }
                },
                create: {
                    method: 'post',
                    params: {
                        id: '@id',
                        controller: 'create'
                    }
                },
                update: {
                    method: 'post',
                    params: {
                        id: '@id',
                        controller: 'update'
                    }
                }, 
                removeComments: {
                    method: 'post',
                    params: {
                        id: '@id',
                        controller: 'remove-comment-attachment'
                    }
                }
            });
    } 
 

// Dependency Injection
interpreterDocumentFactory.$inject = ['$resource', 'appConfig'];
