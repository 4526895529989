


'use strict';

export const OpenWorkOrdersFactory  = function ($resource, appConfig) {
        return $resource(appConfig.apiUrl + '/api/open-work-orders/:id/:controller', {
            id: '@_id'
        },
            {
                getOpenWOCount: {
                    method: 'GET',
                    params: {
                        id: '@id',
                        controller: 'get-open-work-order-counts'
                    }
                },
                getSystemAppliedCount: {
                    method: 'GET',
                    params: {
                        id: '@id',
                        controller: 'get-applied-success-count'
                    }
                },
                getWithdrawCount: {
                    method: 'GET',
                    params: {
                        id: '@id',
                        controller: 'get-withdraw-count'
                    }
                },
                getUnavailableInterpreter: {
                    method: 'GET',
                    params: {
                        id: '@id',
                        controller: 'get-unavailable-interpreter'
                    }
                },
                getInterpreterScheduledCount: {
                    method: 'POST',
                    params: {
                        controller: 'interpreter-scheduled'
                    }
                },
                getScheduledDetail: {
                    method: 'POST',
                    params: {
                        controller: 'interpreter-scheduled-details'
                    }
                },
                getOpenWorkOrderCount: {
                    method: 'POST',
                    params: {
                        controller: 'get-open-work-order-count1'
                    }
                },
                
                getOpenWO: {
                    method: 'POST',
                    params: {
                        controller: 'get-open-work-order'
                    }
                },
                create: {
                    method: 'POST',
                    params: {
                        controller: 'create'
                    }
                },
                update: {
                    method: 'POST',
                    params: {
                        controller: 'update'
                    }
                },
                getFilterRecords: {
                    method: 'POST',
                    params: {
                        controller: 'get-filter-records'
                    }
                },
                assignedWOWithdraw: {
                    method: 'POST',
                    params: {
                        controller: 'assign-wo-withdraw'
                    }
                },
                getOpenWONew: {
                    method: 'POST',
                    params: {
                        controller: 'get-open-work-order-new'
                    }
                },
                interpreterListOfferingRule: {
                    method: 'POST',
                    params: {
                        controller: 'interpreter-list-offering-rule'
                    }
                },
                openWOAssignInterpreter: {
                    method: 'POST',
                    params: {
                        controller: 'open-wo-assign-interpreter'
                    }
                },
                openWOConfirmInterpreter: {
                    method: 'POST',
                    params: {
                        controller: 'open-wo-confirm-interpreter'
                    }
                },
                getCoiTaskIds: {
                    method: 'GET',
                    params: {
                        id: '@id',
                        controller: 'get-coi-task'
                    }
                },
                sendDigestMail: {
                    method: 'POST',
                    params: {
                        controller: 'send-digest-mail'
                    }
                },
                getOpenWOId: {
                    method: 'POST',
                    params: {
                        controller: 'get-open-wo-id'
                    }
                },
                reqICToWithdrawCOI: {
                    method: 'GET',
                    params: {
                        id: '@id',
                        controller: 'ic-withdraw-request'
                    }
                }
            });
    } 
 

// Dependency Injection
OpenWorkOrdersFactory.$inject = ['$resource', 'appConfig'];
