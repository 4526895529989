


'use strict';

export const LoadSpinnerCtrlFactory  = function () {
  function LoadSpinnerCtrl (active) {
    Object.defineProperty(this, 'active', {
      get: function () {
        return !!active;
      },
      set: function (v) {
        active = !!v;
      }
    });
  }

  LoadSpinnerCtrl.prototype.toggle = function () {
    this.active = !this.active;
    return this;
  };

  return LoadSpinnerCtrl;
} 
 
