


'use strict';

export const SurveySentimentFactory  = function ($resource, appConfig) {
        return $resource(appConfig.apiUrl + '/api/client-survey-sentiment/:id/:controller', {
            id: '@_id'
        }, {
                getSentiments: {
                    method: 'GET',
                    params: {
                        controller: ''
                    }
                },
                getSentiment: {
                    method: 'GET',
                    params: {
                        controller: 'detail'
                    }
                },
                createSentiment: {
                    method: 'POST',
                    params: {
                        controller: 'create'
                    }
                },
                updateSentiment: {
                    method: 'POST',
                    params: {
                        controller: 'update'
                    }
                },
                deleteSentiment: {
                    method: 'POST',
                    params: {
                        controller: 'delete'
                    }
                }
            })
    } 
 

// Dependency Injection
SurveySentimentFactory.$inject = ['$resource', 'appConfig'];
