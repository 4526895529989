


'use strict';

export const WOForecastingFactory  = function ($resource, appConfig) {
        return $resource(appConfig.apiUrl + '/api/wo-forecasting/:id/:controller', {
            id: '@_id'
        },
            {
                index: {
                    method: 'get',
                    params: {
                        id: '@id',
                    }
                },
                
                getScheduled: {
                    method: 'get',
                    params: {
                        id: '@id',
                        controller: 'get-scheduled'
                    }
                },
                create: {
                    method: 'post',
                    params: {
                        id: '@id',
                        controller: 'create'
                    }
                },
                createMultiple: {
                    method: 'post',
                    params: {
                        id: '@id',
                        controller: 'create-bulk'
                    }
                },
                updateCount: {
                    method: 'post',
                    params: {
                        id: '@id',
                        controller: 'update-count'
                    }
                },
                createBulkWOForecast: {
                    method: 'post',
                    params: {
                        id: '@id',
                        controller: 'create-bulk-wo-forecast'
                    }
                },
                getBulkWOForecast: {
                    method: 'get',
                    params: {
                        id: '@id',
                        controller: 'get-bulk-wo-forecast'
                    }
                }
            });
    } 
 

// Dependency Injection
WOForecastingFactory.$inject = ['$resource', 'appConfig'];
