
'use strict';

export const $invoiceService  = function (Invoice) {
    return {

      getList: function (client, filter, paginationData) {
        return Invoice.getList({
          id: client._id || client,
          filter: filter,
          page: paginationData && paginationData.page,
          pageSize: paginationData && paginationData.pageSize
        }).$promise;
      },

      create: function (client, data) {
        return Invoice.create({
          id: client._id || client,
          data,
        }).$promise;
      },
      update: function (client, data) {
        return Invoice.update({
          id: client._id || client,
          data,
        }).$promise;
      },
      getInvoiceDetails: function (client, invoiceId) {
        return Invoice.getInvoiceDetails({
          id: client._id || client,
          controller: invoiceId
        }).$promise;
      },
      updateInvoiceLocation: function (client, data) {
        return Invoice.updateInvoiceLocation({
          id: client._id || client,
          data
        }).$promise;
      }
    };
  }
// Dependency Injection


// Dependency Injection
$invoiceService.$inject = ['Invoice'];
