
'use strict';

export const $clientEquipmentService  = function (ClientEquipment) {
    return {
      getEquipments: function(client) {
        return ClientEquipment.getEquipments({
          id: client._id || client
        }).$promise;
      },
      detail: function(client, equipment) {
        return ClientEquipment.detail({
          id: client._id||client,
          equipment: equipment
        }).$promise;
      },
      createEquipment: function(client, equipmentData) {
        return ClientEquipment.createEquipment({
          _id: client._id||client,
          data: equipmentData
        }).$promise;
      },
      updateEquipment: function(client, equipmentId, equipmentData) {
        return ClientEquipment.updateEquipment({
          _id: client._id||client,
          equipment: equipmentId,
          data: equipmentData
        }).$promise;
      },
      deleteEquipment: function(client, equipment) {
        return ClientEquipment.deleteEquipment({
          _id: client._id||client,
          equipment: equipment._id
        }).$promise;
      }
    };
  }
// Dependency Injection


// Dependency Injection
$clientEquipmentService.$inject = ['ClientEquipment'];
