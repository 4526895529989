
'use strict';

export const $convertSecondToTimeService  = function (){
  return function(durationInSecond){
    var hours = 0, minutes = 0, seconds = 0;
    if (durationInSecond && durationInSecond > 0) {
      hours = Math.floor(durationInSecond / 3600);
      minutes = Math.floor((durationInSecond - hours * 3600) / 60);
      seconds = Math.floor((durationInSecond - hours * 3600) % 10);
    }
    return {
      hours: hours.toString().padStart(2, '0'),
      minutes: minutes.toString().padStart(2, '0'),
      seconds: seconds.toString().padStart(2, '0')
    }
  }
}
 
