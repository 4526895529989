


'use strict';

export const RulesExecutionLogsManagerFactory  = function ($resource, appConfig) {
    return $resource(appConfig.apiUrl + '/api/rules-execution-logs/:id/:controller', {
      id: '@_id'
    },
    {
      getLogs: {
        method: 'GET',
        params: {
          controller: '',
          id: '@id'
        },
        isArray: true
      }
    });
  } 
 

// Dependency Injection
RulesExecutionLogsManagerFactory.$inject = ['$resource', 'appConfig'];
