


'use strict';

export const ScheduleTasksManagerFactory  = function ($resource, appConfig) {
    return $resource(appConfig.apiUrl + '/api/schedule-tasks/:id/:controller', {
      id: '@_id'
    },
    {
      saveSchedule: {
        method: 'POST',
        params: {
          controller: 'create'
        }
      },
      updateSchedule: {
        method: 'POST',
        params: {
          controller: 'update'
        }
      },
      deleteSchedule: {
        method: 'POST',
        params: {
          controller: 'delete'
        }
      },
      getSchedules: {
        method: 'GET',
        params: {
          controller: '',
          id: '@id'
        }
      },
      toggleSchedule: {
        method: 'POST',
        params: {
          controller: 'toggle',
        }
      }
    });
  } 
 

// Dependency Injection
ScheduleTasksManagerFactory.$inject = ['$resource', 'appConfig'];
