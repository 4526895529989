import './multi-select.scss';
'use strict';


//
// MultiSelect Directive
// @author Hans Doller
// http://codepen.io/kryo2k/pen/qErJBQ
//
export const multiSelectDirective = function ($rootScope, $timeout) {

  var
  DEFAULT_ALL_ITEMS = 'All Items',
  DEFAULT_SELECTED_ITEMS = 'Selected: ',
  DEFAULT_NO_SELECTION = 'Nothing Selected',
  DEFAULT_ITEM_DELIMITER = ', ';

  return {
    require: 'ngModel',
    restrict: 'E',
    replace: true,
    templateUrl: 'components/multi-select/multi-select.html',

    scope: {
      items: '=',
      itemDelimiter: '=',
      allValue: '=',
      allLabel: '=',
      selectedPrefix: '=',
      noneSelected: '=',
      modelValue: '=ngModel',
      modelChanged: '&ngChange',
      search: '=',
      hideLabel: '=',
      toggleCount:'='
    },

    link: function(scope, el, attrs, ctrls) {
      scope.totalSelected = 0;
      scope.getAllLabel = function () {
        return scope.allLabel || DEFAULT_ALL_ITEMS;
      };
      scope.toggleSelector = function() {
        scope.isExpanded = !scope.isExpanded;
        if(scope.isExpanded) {
          // if the multi-select is just expanded then assign focus to the root element of this directive
          el[0].focus();
        }
      };

      // will be triggered when root element of this directive loses focus
      scope.onBlurHandler = function() {
        $timeout(function() {
          if(el[0].contains(document.activeElement)) {
            // if element that gained focus is option of this multi-select
            // then assign focus to the root element of this directive again
            if(!scope.search) el[0].focus();
          }
          else {
            // element that gained focus is not descendent of this multi-select so close it.
            scope.isExpanded = false;
          }
        });
      };

      scope.onKeyUp = function(event) {
        if(event.keyCode == 27){
          scope.isExpanded = false;
        }
      };

      scope.isSelectedAll = function() {
        return scope.supportAllValue() && scope.enabledItems[scope.allValue];
      };
      scope.supportAllValue = function() {
        return !!scope.allValue;
      };
      scope.itemVisible = function(item) {
        if(!itemExists(item)) return false;

        if(angular.isFunction(item.showIf)) {
          return !!item.showIf.call(this, item);
        }

        return !item.hidden;
      };
      scope.itemDisabled = function(item) {
        if(scope.isSelectedAll()) return true;

        if(angular.isFunction(item.disableIf)) {
          return !!item.disableIf.call(this, item);
        }

        return false;
      };

      attrs.$observe('disabled', function (value) {
        scope.disabled = !!value;
      });

      scope.itemIsActive = function(item) {
        return !!scope.enabledItems[item.value];
      };
      scope.isTouched = function() {
        return ctrls.$touched;
      };
      scope.isValid = function() {
        return !!scope.required ? (
          !!scope.modelValue && !!scope.modelValue.length
        ) : true;
      };

      scope.currentLabel = function updateLabel() {
        var
        tag = 'span',
        allItems = scope.items||[],
        allLabel = scope.allLabel || DEFAULT_ALL_ITEMS,
        itemDelim = scope.itemDelimiter|| DEFAULT_ITEM_DELIMITER,
        selPrefix = scope.selectedPrefix || DEFAULT_SELECTED_ITEMS,
        noSelection = scope.noneSelected || DEFAULT_NO_SELECTION,
        items = scope.enabledItems||{},
        label;
        scope.totalSelected =0;
        if(!!scope.allValue && items[scope.allValue]) {
          label = wrapTag(allLabel, tag);
          scope.totalSelected = scope.items && scope.items.length ? scope.items.length : 0;
        }
        else {
          var
          indexed = allItems.reduce(function(p, c) {
            p[c.value] = c;
            return p;
          }, {}),
          itemStr = Object.keys(items)
            .reduce(function (p, c) {
              if(!!indexed[c] && scope.itemVisible(indexed[c])) {
                p.push(indexed[c].label);
              }
              scope.totalSelected = p.length || 0 ;
              return p;
            }, []).join(itemDelim);

          if(itemStr) {
            label = wrapTag(selPrefix, tag) + itemStr;
          }
          else {
            label = wrapTag(noSelection, tag);
          }
        }

        return label;
      }

      scope.isExpanded = false;
      scope.enabledItems = null;

      var
      modelLoaded = false;

      function wrapTag(content, tag) {
        return '<' + tag + '>' + content + '</' + tag + '>';
      }

      function itemExists(value) {
        var valueId = (value||{}).value||value;
        return !(scope.items||[]).every(function (itm) {
          return itm.value !== valueId;
        });
      }

      var notifyChange = function(e) {
        $timeout(function() {
          scope.modelChanged(e);
        });
      };

      function pushModel(e) {
        if(!modelLoaded || !scope.enabledItems) return;

        if(!!scope.allValue && scope.enabledItems[scope.allValue]) { // if all value is enabled:
          scope.modelValue = scope.allValue;

          notifyChange(e);
          return;
        }

        scope.modelValue = Object.keys(scope.enabledItems)
          .reduce(function (p, c) {

            if(scope.enabledItems[c]) {
              p.push(c);
            }

            return p;
          }, []);

        notifyChange(e);
      }

      function pullModel() {
        scope.enabledItems = {}; // reset enabled items

        var isModelArray = angular.isArray(scope.modelValue);

        if(!!scope.allValue && (scope.modelValue === scope.allValue
           || ( isModelArray && scope.modelValue.indexOf(scope.allValue) > -1)) ) { // all is enabled:
          scope.enabledItems[scope.allValue] = true;
          modelLoaded = true;
          return;
        }

        if(angular.isArray(scope.modelValue)) {
          scope.modelValue
            .forEach(function (item) {
              scope.enabledItems[item] = true;
            });
        }
        else if(angular.isString(scope.modelValue)) {
          scope.enabledItems[scope.modelValue] = true;
        }

        modelLoaded = (scope.modelValue !== undefined);
      }

      ctrls.$validators.multiSelect = function(nV, vV) {
        return scope.isValid();
      };

      attrs.$observe('required', function(value) {
        scope.required = !!value;
      });

      scope.$watch('isExpanded', function(nV) {
        if(ctrls.$touched || !nV) return;

        if ($rootScope.$$phase) {
          scope.$evalAsync(ctrls.$setTouched);
        } else {
          scope.$apply(ctrls.$setTouched);
        }
      });

      scope.$watch('enabledItems', pushModel, true);
      scope.$watch('modelValue', pullModel, true);
    }
  };
}

multiSelectDirective.$inject = ['$rootScope', '$timeout']
