
'use strict';

export const selectionAvailabilityFilter  = function () {
        return function (arr, selectedDateData, toggle) {
            // console.log(toggle)
            // return arr
            if (toggle.am && toggle.pm) {
                return arr
            } else if (toggle.am) {
                return arr.filter(item => {
                    if (item.timeType == 'am' || moment(item.availableFrom).format('a') == 'am') return item;
                })
            } else if (toggle.pm) {
                return arr.filter(item => {
                    if (item.timeType == 'pm' || moment(item.availableFrom).format('a') == 'pm') return item;
                })
            } else {
                return arr
            }
        };
    }

// Dependency Injection

