
'use strict';

export const $printCoiCotiService  = function (OSI, OPI, VRI) {
        return {
            print: function (record) {
                sessionStorage.coiRecord = JSON.stringify({
                    record: record,
                    individual: true
                });
                if (record.sertype == OSI) {
                    window.w = window.open('/print/office-operations/ecoi', '_blank');
                } else if(record.sertype == OPI) {
                    window.w = window.open('/print/office-operations/coti', '_blank');
                } 
                else if(record.sertype == VRI){
                    window.w = window.open('/print/office-operations/covi', '_blank');
                }
                window.w.coiRecord = {
                    record: record,
                    individual: true
                };
                // setTimeout(function () {
                //     window.w.print();
                // }, 2000);
            },
        }
    }
// Dependency Injection


// Dependency Injection
$printCoiCotiService.$inject = ['OSI', 'OPI', 'VRI'];
