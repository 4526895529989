
'use strict';

export const $interpreterDocumentService  = function (interpreterDocument) {
        return {
            getDocument: function (client, filter) {
                return interpreterDocument.getDocument({
                    id: client._id || client,
                    filter
                }).$promise;
            },
            create: function (client, data) {
                return interpreterDocument.create({
                    id: client._id || client,
                    data
                }).$promise;
            },
            update: function (client, data) {
                return interpreterDocument.update({
                    id: client._id || client,
                    data
                }).$promise;
            },
            remove:function(client, documentId) {
                return interpreterDocument.remove({
                    id: client._id || client,
                    controller: documentId
                }).$promise;
            },
            removeComments: function(client, data) {
                return interpreterDocument.removeComments({
                    id: client._id || client,
                    documentId: data.documentId,
                    commentId: data.commentId,
                    attachmentId: data.attachmentId
                }).$promise;
            }
        };
    }
// Dependency Injection


// Dependency Injection
$interpreterDocumentService.$inject = ['interpreterDocument'];
