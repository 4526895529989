
'use strict';

export const $clientOfficeService  = function (ClientOffice) {
    return {
      listAll: function(client) {
        return ClientOffice.query({
          id: client._id || client
        }).$promise;
      },

      detail: function(clientId, officeId) {
        return ClientOffice.detail({
          id: clientId._id||clientId,
          officeId: officeId
        }).$promise;
      },

      getLogs: function(client, page, pageSize) {
        return ClientOffice.getLogs({
          id: client._id || client,
          page: page,
          pageSize: pageSize
        }).$promise;
      },

      deleteOffice: function(client, office) {
        return ClientOffice.delete({
          _id: client._id || client,
          office: office._id || office
        }).$promise;
      },
      isContactUnique: function(client, office, number) {
        return ClientOffice.isContactUnique({
          id: client._id || client,
          office: office ? office._id || office : null,
          number: number
        }).$promise;
      },

      ivrSync: function(client){
        return ClientOffice.ivrSync({
          id: client._id
        }).$promise;
      },
      associatedEmployeesLocation: function(clientId, officeId){
        return ClientOffice.associatedEmployeesLocation({
          id: clientId._id||clientId,
          officeId: officeId
        }).$promise;
      },
      getLocationLatLong: function(client, address) {
        return ClientOffice.getLocationLatLong({
          id: client._id || client,
          address: address
        }).$promise;
      },
      getOfficeWoControls: function(client, officeCode) {
        return ClientOffice.getOfficeWoControls({
          id: client._id || client,
          officeCode: officeCode 
        }).$promise;
      },
      getBusinessHours: function (client, officeCode) {
        return ClientOffice.getBusinessHours({
          id: client._id || client,
          officeCode: officeCode 
        }).$promise;
      },
      getCodesDetails: function (client, officeCode) {
        return ClientOffice.getCodesDetails({
          id: client._id || client,
          officeCode: officeCode 
        }).$promise;
      },
      updateCourtResource: function (client, office) {
        return ClientOffice.updateCourtResource({
            id: client._id || client,
            office: office
        }).$promise;
      },
      getOfficeList: function (client, filter) {
        return ClientOffice.getOfficeList({
          id: client._id || client,
          page: filter && filter.page,
          pageSize: filter && filter.pageSize,
          searchKey: filter && filter.searchKey,
          orderByValue: filter && filter.orderByValue,
          orderByField: filter && filter.orderByField,
          isIncludeActive: filter && filter.isIncludeActive,
          selectedRegion: filter && filter.selectedRegion || null
        }).$promise;
      },
      getOfficeByInternalId: function(client, internalId, search) {
        return ClientOffice.getOfficeByInternalId({
          id: client._id || client,
          internalId: internalId,
          search: search || null
        }).$promise;
      },
      getOfficeByReplocId: function(client, internalId) {
        return ClientOffice.getOfficeByReplocId({
          id: client._id || client,
          internalId: internalId
        }).$promise;
      },
      getActiveOfficeList: function(client) {
        return ClientOffice.getActiveOfficeList({
          id: client._id || client,
        }).$promise;
      }
    };
  }
// Dependency Injection


// Dependency Injection
$clientOfficeService.$inject = ['ClientOffice'];
