'use strict';


export const formManagerEmployeesDirective = function (Modal, $rootScope, $q, $auth, $clientSwitcher, $clientManager, $clientOffice) {
    return {
      restrict: 'E',
      templateUrl: 'components/forms/manager-employees.html',
      scope: {
        employees: '=ngModel',
        onSubmit: '=formOnSubmit',
        onReset: '=formOnReset'
      },
      link: function(scope, el, attrs) {

        function createEmployee(props) {
          props =  props || {};
          return {
            name:       props.name       || null,
            employeeId: props.employeeId || null,
            email:      props.email      || null,
            phone:      props.phone      || null,
            _id:        props._id        || null
          };
        }

        scope.addEmployee = function() {
          scope.cancelAddUpdateEmployee();
          scope.addingEmployee = true;
          scope.tempEmployee = createEmployee();
        };

        scope.removeEmployee = function(index) {
          scope.cancelAddUpdateEmployee();
          if(!scope.employees) {
            return;
          }

          scope.employees.splice(index, 1);
        };

        scope.updateEmployee = function(index) {
          scope.cancelAddUpdateEmployee();
          if(!scope.employees) {
            return;
          }

          var employee = scope.employees[index];

          scope.updatingEmployee = !!employee;
          scope.updatingIndex = index;
          scope.tempEmployee = createEmployee(employee);
        };

        scope.cancelAddUpdateEmployee = function() {
          delete scope.addingEmployee;
          delete scope.updatingEmployee;
          delete scope.updatingIndex;
          delete scope.tempEmployee;
        };

        scope.pushEmployee = function(form) {
          if(!form || !form.$valid) {
            return;
          }

          if(scope.employees === undefined) {
            scope.employees = [];
          }

          if(scope.updatingEmployee) {
            scope.employees[scope.updatingIndex] = scope.tempEmployee;
          }
          else if(scope.addingEmployee) {
            scope.employees.push(scope.tempEmployee);
          }

          scope.cancelAddUpdateEmployee();
        };

        scope.clientStrings = function(){ return $clientSwitcher.getFeatureOptions('client.strings'); };
      }
    };
  }
// Dependency Injection
formManagerEmployeesDirective.$inject = ["Modal","$rootScope","$q","$auth","$clientSwitcher","$clientManager","$clientOffice"];
