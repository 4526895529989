'use strict';


export const AccountClientsCtrl = function ($scope, $clientSwitcher, Modal, $http, $auth, $state, $q, $client, $activeClient, $stateParams, ngToast, appConfig) {
    var currentUser = $auth.getCurrentUser();
    if(['dhaval@jumpbyte.com', 'mike@mikeborek.com', 'kryo2k@gmail.com'].indexOf(currentUser.email) === -1) {
      $state.go('app.error.accessdenied');
    }

    var getClientDetails = function() {
      $scope.isLoading = true;
      var clients = $clientSwitcher.getLoaded();

      if(clients && clients.length) {
        $q.all(clients.map(function(client) {
          return $client.getUserCount(client);
        }))
        .then(function(responses) {

          if($stateParams.client) {
            for(var i in clients) {
              var client = clients[i];
              if($stateParams.client === client._id) {
                $clientSwitcher.setCurrentClient(client);
                return $state.go('app.account.dashboard.settings.index');
              }
            }
          }

          $scope.clients = clients.map(function(client, index) {
            client.usersCount = responses[index].data;
            return client;
          });

          $scope.isLoading = false;
        });
      }
      else {
        $scope.isLoading = false;
      }
    };

    $scope.getClientGlyph = function (cli) {
      var aL = $clientSwitcher.getAccessLevel(cli);
      switch(true) {
        case $clientSwitcher.isOwner(aL):    return 'glyphicon-star';
        case $clientSwitcher.isOperator(aL): return 'glyphicon-star-empty';
        case $clientSwitcher.isLimited(aL):  return 'glyphicon-eye-close';
      }
    };

    $scope.createClient = function() {
      Modal.clientActions.createClient(function(newClient) {
        $http.post(appConfig.apiUrl  + '/api/clients/create', newClient).then(function(res) {
          if(res.data.status) {
            $state.go('app.account.clients', {client: res.data.data._id});
            window.location.reload();
          }
          else {
            ngToast.create({
              content: res.data.reason,
              className: 'danger'
            });
            console.error(res.data.reason);
          }
        }, function(err) {
          console.error(err);
        });
      })();
    };

    $scope.deleteClient = function(client) {
      Modal.confirm.show("Confirm Delete", "Are you sure you want to delete client <strong>"+client.name+"</strong>", "Delete", "Cancel", function(){
        $http.post(appConfig.apiUrl + '/api/clients/delete', {client: client._id})
        .then(function(res) {
          if(res.data.status) {
            window.location.reload();
          }
          else {
            console.error(res.data.reason);
          }
        }, function(err) {
          console.error(err);
        });
      });
    };

    $scope.editClient = function(client) {
      var clientData = {
        name: client.name,
        abbreviation: client.abbreviation
      };

      Modal.clientActions.editClient(function(clientData) {
        $client.editClient(client._id, clientData).then(function(res) {
          if(res.status) {
            window.location.reload();
          }
          else {
            ngToast.create({
              content: res.reason,
              className: 'danger'
            });
            console.error(res.reason);
          }
        }, function(err) {
          console.error(err);
        });
      })(clientData);

    };

    $scope.$on('$destroy', $activeClient(function (client) {
      if(!client) {
          return;
      }
      getClientDetails()
    }));
}
// Dependency Injection
AccountClientsCtrl.$inject = ["$scope","$clientSwitcher","Modal","$http","$auth","$state","$q","$client","$activeClient","$stateParams","ngToast","appConfig"];

// angular.module('coordinateApp').controller('AccountClientsCtrl', AccountClientsCtrl);
