


'use strict';

export const ClientFeatureFactory  = function ($resource, appConfig) {
    return $resource(appConfig.apiUrl + '/api/client-features/:id/:controller', {
      id: '@_id'
    },
    {
      // ...
    });
  } 
 

// Dependency Injection
ClientFeatureFactory.$inject = ['$resource', 'appConfig'];
