'use strict';


export const DevicesConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard.settings.devices', {
        url: '/devices',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/devices/devices.html', 'f1500f4a'); }],
        data: {
          ability: 'manage.client.users'
        },
        controller: 'DashboardSettingsDevicesCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/devices/devices.controller.js', 'DashboardSettingsDevicesCtrl'); }]
                      },
      })
      .state('app.account.dashboard.settings.devices.index', {
        url: '/index',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/devices/index/index.html', '58c48faa'); }],
        data: {
          ability: 'manage.client.users'
        },
        controller: 'DashboardSettingsDevicesIndexCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/devices/index/index.controller.js', 'DashboardSettingsDevicesIndexCtrl'); }]
                      },
      })
      .state('app.account.dashboard.settings.devices.create', {
        url: '/create',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/devices/create/create.html', '4c83388a'); }],
        data: {
          ability: 'manage.client.users'
        },
        controller: 'DashboardSettingsDevicesCreateCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/devices/create/create.controller.js', 'DashboardSettingsDevicesCreateCtrl'); }]
                      },
      })
      .state('app.account.dashboard.settings.devices.update', {
        url: '/update/:id',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/devices/create/create.html', 'dcea49ab'); }],
        controller: 'DashboardSettingsDevicesUpdateCtrl',
        resolve: {
            lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/devices/update/update.controller.js', 'DashboardSettingsDevicesUpdateCtrl'); }],
            '$selectedDevice': ['$stateParams','$devicesManager', '$clientSwitcher', function ($stateParams, $devicesManager, $clientSwitcher) {
              return $devicesManager.getDeviceDetails($clientSwitcher.getCurrentClient(), $stateParams.id);
            }]
        },
        data: {
          ability: 'manage.client.users'
        },
        resolve: {
        }
      })
      .state('app.account.dashboard.settings.devices.events', {
        url: '/events',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/devices/events/events.html', '508c4a4a'); }],
        controller: 'DashboardSettingsDevicesEventsCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/devices/events/events.controller.js', 'DashboardSettingsDevicesEventsCtrl'); }]
                      },
        data: {
          ability: 'manage.client.users'
        }
      });
  }
// Dependency Injection
DevicesConfig.$inject = ["$stateProvider"];
