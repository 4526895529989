


'use strict';

export const IcDegreesOrCertificatesFactory  = function ($resource, appConfig) {
        return $resource(appConfig.apiUrl + '/api/ic-degrees-certificates/:id/:controller', {
            id: '@_id'
        },
            {
                createOrUpdate: {
                    method: 'POST',
                    params: {
                        controller: 'createOrUpdate'
                    }
                },
                icDegreeOrCertificateList: {
                    method: 'GET',
                    params: {
                        controller: 'ic-degree-certificate'
                    }
                },
                delete: {
                    method: 'DELETE'
                },
                createOrUpdateComment: {
                    method: 'POST',
                    params: {
                        controller: 'create-or-update-comment'
                    }
                },
                deleteComment: {
                    method: 'POST',
                    params: {
                        controller: 'delete-comment'
                    }
                },
                addAttachments: {
                    method: 'POST',
                    params: {
                        controller: 'add-attachments'
                    }
                },
                deleteAttachment: {
                    method: 'POST',
                    params: {
                        controller: 'delete-attachment'
                    }
                }
            });
    } 
 

// Dependency Injection
IcDegreesOrCertificatesFactory.$inject = ['$resource', 'appConfig'];
