
'use strict';

export const createRuleDirective  = function ($rootScope, $q, $auth, $clientSwitcher, $rulesManager, $clientOffice, $clientExternalUsers, $clientTask, $clientUser, $devicesManager, $clientDeviceEventsManager, ngToast, $timeout) {
    return {
      restrict: 'E',
      templateUrl: 'components/forms/create-rule.html',
      scope: {
        rule: '=ngModel',
        onSubmit: '=formOnSubmit',
        allValue: '=allValue'
      },
      link: function(scope, el, attrs) {
        var lastClient = false;
        scope.model = scope.rule;
        
        if(!scope.model.window) {
          scope.model = _.merge(scope.model, {
              window: {
                start: moment().format('MM/DD/YYYY'),
                stop: moment().format('MM/DD/YYYY'),
                selectedDays: [],
                selectedDates: [],
                executionTime: {
                  start: 0,
                  stop: 0
                },
                repeatMode: '',
                repeat: ''
            }
          });
        }

        scope.dueDateIn = {
          eventInsideRange: {
            assignTo: 'hours',
            assignToOnExpire: 'hours',
            devices: []
          },
          eventOutsideRange: {
            assignTo: 'hours',
            assignToOnExpire: 'hours',
            devices: []
          }
        };

        scope.slider = {
          hours: {
            max: 1440, //23:55 in minutes
            step: 15
          },
          days: {
            max: 43200,
            step: 1440
          }
        };

        scope.defaultStatus = 'active';
        scope.statuses = [
          {label: 'Active', value: 'active'},
          {label: 'Suspended', value: 'suspended'}
        ];

        scope.relativeDates = {
          hours: [
            {label: '30 Min', value: 30},
            {label: '1 Hrs', value: 60},
            {label: '2 Hrs', value: 120},
            {label: '3 Hrs', value: 180},
            {label: '24 Hrs', value: 1440}
          ],
          days: [
            {label: '1 Day', value: 1440},
            {label: '2 Days', value: 2880},
            {label: '3 Days', value: 4320},
            {label: '1 Week', value: 10080},
            {label: '2 Weeks', value: 20160}
          ]
        };

        scope.defaultEventInsideRangeAction = 'no-action';
        scope.defaultEventOutsideRangeAction = 'no-action';
        scope.eventInsideRangeActions = [          
          {value: 'create', label: 'Create Task'},
          {value: 'no-action', label: 'No Action'},
          {value: 'update-log', label: 'Update Log'}
        ];

        scope.defaultEventInsideRangePriority = 'high';
        scope.taskPriorities = [
          {value: 'high',    label: 'High'},
          {value: 'med-high',label: 'Medium-high'},
          {value: 'med-low', label: 'Medium-low'},
          {value: 'low',     label: 'Low'}
        ];

        scope.ruleDateOptions = {
          startingDay: 1
        };

        scope.assignableUsers = [];
        
        scope.ruleDateOpened = false;
        scope.ruleDateFormat = 'yyyy/MM/dd';
        scope.ruleDateStepH  = 1;
        scope.ruleDateStepM  = 1;
        scope.ruleDateAMPM   = true;

        scope.collectionTypes = {
          'criteria': [],
          'eventInsideRange': []
        };

        scope.defaulteventInsideRangeTaskCollection = undefined;

        scope.getNotified = !!scope.model.notifyBeforeExpire;
        
        scope.notifyBeforeOptions = [
          {label: '1 Day', value: 1440},
          {label: '2 Days', value: 2880},
          {label: '3 Days', value: 4320},
          {label: '1 Week', value: 10080}
        ];

        scope.isRuleWindowValid = true; //checks whether the rule window is valid or not

        scope.nowDate = function () {
          var
          now = Date.now(),
          nextMinute = now % 60000;
          return now - nextMinute;
        };

        scope.clearDate = function(field) {
          if(scope.model[field]) {
            delete scope.model[field];
          }
        };

        scope.ruleDateDisabled = function (date, mode) {
          var now = Date.now(), offset = (now % 8.64e7);
          return date <= (now - offset);
        };

        scope.presetOffsets = null; // use defaults

        scope.clientStrings = function(){ return $clientSwitcher.getFeatureOptions('client.strings'); };

        scope.reloadOffices = function (client) {
          return $clientOffice.listAll(client)
            .then(function (offices) {
              scope.offices = offices.map(function (office) {
                return {
                  label: office.name,
                  value: office._id
                };
              });
            });
        };

        scope.reloadCollections = function (client) {
          scope.collections = false;
          return $clientTask.getCollections(client, true)
            .then(function (collections) {
              scope.collections = collections.map(function (collection) {
                if(collection.name === 'Safe Violations') {
                  scope.defaulteventInsideRangeTaskCollection = collection._id;
                }

                return {
                  label: collection.name,
                  value: collection._id,
                  taskTypes: collection.types.map(function(taskType) {
                    return {
                      label: taskType,
                      value: taskType
                    };
                  })
                };
                // return [collection._id, collection.name, collection.types.map(function (v) {
                //   return [v, v];
                // })];
              });

              if(scope.model && scope.model.taskCollection) {
                scope.setCurrentCollection(scope.model.taskCollection);
              }
            });
        };

        scope.fetchAvailableUsers = function() {
          if(!scope.model.location || !scope.model.taskDetails.eventInsideRange.taskCollection) {
            return;
          } else {
            scope.reloadAvailableUsers(lastClient);
          }
        };

        scope.possibleAssignableUsers = {
          eventInsideRange: [],
          eventOutsideRange: []
        };
        scope.assignableUsers = {
          eventInsideRange: [],
          eventOutsideRange: []
        };
        scope.reloadAvailableUsers = function (client, key) {
          if(!scope.model.taskDetails[key] || !scope.model.taskDetails[key].taskCollection || !scope.model.location) {
            return;
          }

          return $clientUser.getUserChainOfCommand(client, scope.model.location, scope.model.taskDetails[key].taskCollection)
            .then(function (chainOfCommand) {
              scope.assignableUsers[key] = chainOfCommand;
              var chainOfCommandCopy = angular.copy(chainOfCommand);
              scope.possibleAssignableUsers[key] = chainOfCommandCopy.delegates;

              scope.possibleAssignableUsers[key] = scope.possibleAssignableUsers[key].map(function(user) {
                return {
                  label: user.name,
                  value: user.userId
                };
              });
              
              return scope.possibleAssignableUsers[key];

            });
        };

        scope.setCurrentCollection = function (toCollection, key) {
          var
          prevType = false,
          hasModel = !!scope.model;

          delete scope.collectionTypes[key];

          if(hasModel) {
            prevType = scope.model.taskDetails[key].taskType;
          }

          var collection;

          scope.collections.every(function (col) {
            if(col.value !== toCollection) {
              return true;
            }
            collection = col;
            return false;
          });

          if(!collection) {
            if(hasModel) {
              delete scope.model.taskDetails[key].taskType;
            }

            return;
          }

          scope.collectionTypes[key] = collection.taskTypes;

          scope.reloadAvailableUsers(lastClient._id, key);

          if(key === 'criteria') {
           scope.alertItemsTaskTypes = [];
           scope.alertItemsTaskTypes = scope.collectionTypes[key].map(function (collectionType) {
              return {
                value:  collectionType,
                label:  collectionType
               };
            });
          } else if(hasModel) {
            var
            setTo = collection.taskTypes[0].value;

            if(prevType) {
              scope.collectionTypes[key].every(function (typeDef) {
                if(typeDef.value === prevType) {
                  setTo = typeDef.value;
                  return false;
                }

                return true;
              });
            }

            scope.model.taskDetails[key].taskType = setTo;
          }
        };

        scope.locationChanged = function() {
          scope.fetchAvailableUsers();
          scope.fetchAvailableDevice();
        };

        var allDevices = [];
        var reloadDevices = function(client) {
          $devicesManager.getDevices(client).then(function(devices) {
              allDevices = devices;

              scope.dependentDevices = devices.map(function(device) {
                return {
                  label: device.name,
                  value: device._id,
                  showIf: function() {
                    return device.location._id === scope.model.location;
                  }
                };
              });

              if(scope.model.location) {
                scope.fetchAvailableDevice();
              }
          });
        };

        scope.devices = [];
        scope.fetchAvailableDevice = function() {
          scope.devices = allDevices
          .map(function(device) {
            return {
              label: device.name,
              value:  device._id,
              showIf: function() {
                return device.location._id.toString() === scope.model.location.toString();
              }
            };
          });
        };

        var reloadEvent = function(client) {
          $clientDeviceEventsManager.getEvents(client).then(function(events) {
              scope.events = events.map(function(event) {
                return {
                  label: event.name,
                  value: event.name
                };
              });
          });
        };

        scope.reload = function (client) {
          lastClient = client;
          return $q.all([
            scope.reloadOffices(client),
            scope.reloadCollections(client),
            scope.reloadAvailableUsers(client),
            reloadDevices(client),
            reloadEvent(client)
          ]);
        };

        scope.$watch('model', function (nV) {
          if(!nV || !nV.taskDetails) {
            return;
          }

          if(nV.status === undefined && scope.defaultStatus) {
            nV.status = scope.defaultStatus;
          }

          // if(nV.taskDetails.criteria.taskStatus === undefined && scope.defaultTaskStatus) {
          //   nV.taskDetails.criteria.taskStatuses = scope.defaultTaskStatus;
          // }

          // if(nV.taskDetails.taskPresent.action === undefined && scope.defaultTaskPresentAction) {
          //   nV.taskDetails.taskPresent.action = scope.defaulttaskPresentAction;
          // }

          // if(nV.taskDetails.taskPresent.priority === undefined && scope.defaultTaskPresentPriority) {
          //   nV.taskDetails.taskPresent.priority = scope.defaultTaskPresentPriority;
          // }

          if(nV.taskDetails.eventInsideRange.priority === undefined && scope.defaultEventInsideRangePriority) {
            nV.taskDetails.eventInsideRange.priority = scope.defaultEventInsideRangePriority;
          }

          if(nV.taskDetails.eventInsideRange.action === undefined && scope.defaultEventInsideRangeAction) {
            nV.taskDetails.eventInsideRange.action = scope.defaultEventInsideRangeAction;
          }

          if(nV.taskDetails.eventOutsideRange.action === undefined && scope.defaultEventOutsideRangeAction) {
            nV.taskDetails.eventOutsideRange.action = scope.defaultEventOutsideRangeAction;
          }

          if(nV.taskDetails.eventInsideRange.taskCollection === undefined && scope.defaulteventInsideRangeTaskCollection) {
            nV.taskDetails.eventInsideRange.taskCollection = scope.defaulteventInsideRangeTaskCollection;
          }

        });

        $clientSwitcher.whenLoaded(function (all, current) { // listen for client switching
          var
          killCsLoad,
          killCsSwitch;

          scope.reload(current);

          killCsLoad = $rootScope.$on('client-switcher-load', function (evt, all, current) { // listen for reloads too
            scope.reload(current);
          });
          killCsSwitch = $rootScope.$on('client-switcher-switch', function (evt, client) {
            scope.reload(client);
          });

          scope.$on('$destroy', function(){
            killCsLoad();
            killCsSwitch();
          });
        });

        scope.extra = {
          eventInsideRange: {
            userEmail: ''
          },
          eventOutsideRange: {
            userEmail: ''
          }
        };        
        scope.addExternalUser = function(key) {
          if(scope.model.taskDetails[key].notifyUsers.external.indexOf(scope.extra[key].userEmail) === -1) {
            scope.model.taskDetails[key].notifyUsers.external.push(scope.extra[key].userEmail);
            scope.extra[key].userEmail = '';
          }
        };

        scope.removeExternalUser = function(key, index) {
          scope.model.taskDetails[key].notifyUsers.external.splice(index, 1);
        };

        var today = new Date(),
        locale = 'en-us';
        scope.thisMonth = today.toLocaleString(locale, { month: 'long' });

        scope.dateRange = {
          date: {} 
        };
        scope.selectedDateRange = '';

        var setNotifyUsersList = function() {
          ['eventInsideRange', 'eventOutsideRange'].forEach(function(key) {
            if(scope.model.taskDetails[key].notifyUsers.internal === scope.allValue || scope.model.taskDetails[key].notifyUsers.internal.indexOf(scope.allValue) > -1) {
              scope.model.taskDetails[key].notifyUsers.allInternal = true;
              scope.model.taskDetails[key].notifyUsers.internal = [];
            } else {
              scope.model.taskDetails[key].notifyUsers.allInternal = false;
            }
          });
        };

        var setTimeWindowData = function() {
          //executionTime are in Minutes
          scope.model.fromTime = scope.rule.window.executionTime.start;
          var timeOffset = 0;

          for (var i = scope.model.fromTime; i !== scope.rule.window.executionTime.stop; i++) {
            if(i === 1435) {
              i = 0;
            }
            timeOffset++;
          }

          scope.model.timeOffset = timeOffset;
        };

        var setAdditionalDevices = function() {
          //required because the multi-select keeps the values in model even though their labels are not displayed ( based on showIf() )
          ['eventInsideRange', 'eventOutsideRange'].forEach(function(key) {
            if(scope.model.taskDetails[key].action === 'create' && scope.model.taskDetails[key].devices) {
              scope.model.taskDetails[key].devices = _.filter(scope.model.taskDetails[key].devices, function(device) {
                
                for (var i = scope.dependentDevices.length - 1; i >= 0; i--) {
                  if(device === scope.dependentDevices[i].value && scope.dependentDevices[i].showIf()) { //device is visible
                    return true;
                  }
                }

                return false;
              });
            }
          });
        };

        scope.saveRule = function() {
          setNotifyUsersList();
          setTimeWindowData();

          setAdditionalDevices();

          scope.model.startFrom = new Date(moment(scope.rule.window.start)._d.setHours(0, 0, 0, 0));
          scope.model.stopOn = new Date(moment(scope.rule.window.stop)._d.setHours(23, 59, 59, 0));
          scope.model.repeat = scope.rule.window.repeat;
          scope.model.mode = scope.rule.window.repeatMode;
          scope.model.selectedDays = scope.rule.window.selectedDays;
          scope.model.selectedDates = scope.rule.window.selectedDates;
          if(scope.rule.createdBy){
            scope.model.createdBy = scope.rule.createdBy._id;
          }          

          scope.model.client = lastClient._id;
          scope.model.isRuleActive = scope.model.status === 'active';

          if(scope.model.window) {
            delete scope.model.window;
          }
          scope.onSubmit(scope.model);
        };

        //related to edit mode
        scope.$on('eventInsideRange', function(ngRepeatFinishedEvent) {
          if(scope.model.taskDetails.eventInsideRange && scope.model.taskDetails.eventInsideRange.taskCollection) {
            scope.setCurrentCollection(scope.model.taskDetails.eventInsideRange.taskCollection, 'eventInsideRange');
          }
        });

        scope.$on('eventOutsideRange', function(ngRepeatFinishedEvent) {
          if(scope.model.taskDetails.eventOutsideRange && scope.model.taskDetails.eventOutsideRange.taskCollection) {
            scope.setCurrentCollection(scope.model.taskDetails.eventOutsideRange.taskCollection, 'eventOutsideRange');
          }
        });

        if(scope.model.taskDetails.eventInsideRange.dueOffset) {
          scope.dueDateIn.eventInsideRange.assignTo = (scope.model.taskDetails.eventInsideRange.dueOffset > 1400) ? 'days' : 'hours';
        }
        if(scope.model.taskDetails.eventInsideRange.onExpireDueOffset) {
          scope.dueDateIn.eventInsideRange.assignToOnExpire = (scope.model.taskDetails.eventInsideRange.onExpireDueOffset > 1400) ? 'days' : 'hours';
        }

        if(scope.model.taskDetails.eventOutsideRange.dueOffset) {
          scope.dueDateIn.eventOutsideRange.assignTo = (scope.model.taskDetails.eventOutsideRange.dueOffset > 1400) ? 'days' : 'hours';
        }
        if(scope.model.taskDetails.eventOutsideRange.dueOffset) {
          scope.dueDateIn.eventOutsideRange.assignToOnExpire = (scope.model.taskDetails.eventOutsideRange.onExpireDueOffset > 1400) ? 'days' : 'hours';
        } 
      }
    };
}
// Dependency Injection


// Dependency Injection
createRuleDirective.$inject = ['$rootScope', '$q', '$auth', '$clientSwitcher', '$rulesManager', '$clientOffice', '$clientExternalUsers', '$clientTask', '$clientUser', '$devicesManager', '$clientDeviceEventsManager', 'ngToast', '$timeout'];
