export const staticParamsFactory = function () {
  let userParams = null;
  function setParams(params) {
      userParams = params;
  }

  function getParams() {
      return userParams;
  }
  return {
      setParams, getParams
  }
}
