
'use strict';

export const $clientRegionService  = function (ClientRegion) {
    return {
      listAll: function(client) {
        return ClientRegion.query({
          id: client._id || client
        }).$promise;
      },

      detail: function(clientId, regionId) {
        return ClientRegion.detail({
          id: clientId._id||clientId,
          regionId: regionId
        }).$promise;
      }
    };
  }
// Dependency Injection


// Dependency Injection
$clientRegionService.$inject = ['ClientRegion'];
