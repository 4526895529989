'use strict';

export const sosiOneReportingDirective = function ($stateParams, $rootScope, $timeout,  LazyLoadService ) {
              return {
      restrict: 'E',
      templateUrl: 'app/account/dashboard/main-dashboard/sosi-one-reporting/sosi-one-reporting.html',
      scope: {
        modelData: '=ngModel',
        childTabs: '=childTabs',
        layoutDefaultTabing: '&layoutDefaultTab'
      },
      link: function(scope) {

  const lazyDirectives = [{"name":"reportsLibraryDirective","path":import("@app/account/dashboard/main-dashboard/sosi-one-reporting/tabs/reports-library/reports-library.directive.js")}];
  scope.lazyDirectivesLoaded = false;
  Promise.all(lazyDirectives.map(directive=>LazyLoadService.loadDirective(directive.path, directive.name))).then(() => {
          $timeout(function() {
            scope.lazyDirectivesLoaded = true;

                 let selectedTab = null;
        scope.liveReportsTabs = [];
        scope.IcTabs = [];
        var init = function () {
          scope.tabs()
        }

        scope.tabs = function () {
          let tabs = scope.childTabs;
          let liveReportsTabs = tabs.find(function (item) {
            if (item.subChild && item.subChild.length) return item.subChild;
          });
          scope.liveReportsTabs = liveReportsTabs || [];
          let data = scope.layoutDefaultTabing({ obj: { tabs, layoutOf: 'SOSIONEReporting' } })
          scope.IcTabs = data.avtiveTabs;
          selectedTab = data.selectedTab;

          if ($stateParams.child) {
            selectedTab = $stateParams.child;
          }
        };

        scope.tabSelected = function (tabName) {
          selectedTab = 'test';
          setTimeout(() => {
            selectedTab = tabName;
            $rootScope.$broadcast('onTabChange', selectedTab);
            scope.$apply()
          }, 0);
        };

        scope.isTabSelected = function (tabName) {
          return selectedTab === tabName;
        };

        init()

  })
});
}
}
}
//Dependency Injection
sosiOneReportingDirective.$inject = ["$stateParams","$rootScope", "$timeout",  "LazyLoadService"];
