'use strict';

export const screenDirective = function ($auth, $timeout, $clientSwitcher, $client, $clientUser, $activeClient, $permissionMatrix, $permissionMatrixSourceType, $clientPermissionTypes) {
    return {
      restrict: 'E',
      templateUrl: 'app/account/dashboard/settings/permission-matrix/tabs/screen/screen.html',
      scope: {
        modelData: '=ngModel',
        tabSelected: '&'
      },
      link: function (scope) {
        const currentCli = $clientSwitcher.getCurrentClient();
        scope.clientStrings = function () { return $clientSwitcher.getFeatureOptions('client.strings'); };
        scope.data = {
          isLoading: true,
          pagesArr: [],
          pageUsers: [],
          pageRoles: [],
          pagePermissions: [],
          pageRoleError: null,
          pageUserError: null,
          pagePermissionError: null,
          permissionsTypes: []
        };
        scope.userPermissions = [];
        scope.permissions = {
          settingPermission: false,
          allowUserEdit: false,
          allowRoleEdit: false,
          allowPermissionEdit: false
        }
        function init() {
          scope.data.isLoading = true;
          $activeClient(function (client) {
            if (!client) {
              return;
            }
            scope.client = client;
            $timeout(function () {
              scope.permissions.settingPermission = scope.modelData.featureTabs.includes("SETTINGS_READ");
              scope.permissions.allowUserEdit = $auth.getCurrentUser().permissions.includes('SETTING_USER_EDIT');
              scope.permissions.allowRoleEdit = $auth.getCurrentUser().permissions.includes('SETTING_ROLE_EDIT');
              scope.permissions.allowPermissionEdit = $auth.getCurrentUser().permissions.includes('SETTING_PERMISSION_EDIT');
            }, 100);

            if (client) {
              $clientUser.getUserPermissions(client)
                .then(data => {
                  scope.userPermissions = data.permissions;
                })
                .then(() => getClientAbilities(client));
            }
          });
        }
        function getClientAbilities(client) {
          // _.each($clientSwitcher.getFeatureTabs(), function (tab) {
          //   if (!tab.disabled && scope.userPermissions.includes(tab.permission)) {
          //     scope.data.pagesArr.push(tab)
          //   }
          // });
          // console.log(scope.data.pagesArr, $clientPermissionTypes)
          // scope.data.pagesArr = $clientPermissionTypes;
            _.each($clientPermissionTypes, function (tab) {
            if (!tab.disabled && scope.userPermissions.includes(tab.permission)) {
              scope.data.pagesArr.push(tab)
            }
          });

          scope.data.isLoading = false;
          if (scope.modelData.selectedData && scope.modelData.selectedData.permission) {
            setTimeout(() => {
              scope.modelData.selectedData.permission = scope.modelData.selectedData.permission
              scope.onPageSelect(scope.modelData.selectedData);
              var elmnt = document.getElementById(scope.modelData.selectedData.permission);
              elmnt.scrollIntoView();
            }, 500)
          }
        }

        scope.onPageSelect = function (selectedPage) {
          scope.data.selectedPage = selectedPage
          scope.data.isLoading = true;
          resetData();
          $permissionMatrix.getPermissionByPage(scope.client, selectedPage.permission, selectedPage.value)
            .then((data) => {
              if (data.status) {
                if (data && data.pageRoles && data.pageRoles.length) {
                  scope.data.pageRoles = data.pageRoles
                } else {
                  scope.data.pageRoleError = 'No Roles Assigned'
                }

                if (data && data.pageUsers && data.pageUsers.length) {
                  scope.data.pageUsers = data.pageUsers
                } else {
                  scope.data.pageUserError = 'No Pages Assigned'
                }
                scope.pagePermissionsCount = data.pagePermissions.length || 0;
                if (data && data.pagePermissions && data.pagePermissions.length) {
                  scope.data.pagePermissions = data.pagePermissions
                  // scope.data.pagePermissions = _.chain(data.pagePermissions)
                  //   .groupBy("type")
                  //   .map((value, key) => ({ page: key, permissions: value }))
                  //   .value();
                } else {
                  scope.data.pagePermissionError = 'No Permissions Assigned'
                }
              }
            })
            .finally(function () {
              scope.data.isLoading = false;
            });
        }

        scope.showUser = function (user) {
          user.userId = user._id;
          scope.tabSelected({ type: "user", data: user })
        }

        scope.showRole = function (role) {
          scope.tabSelected({ type: "role", data: role })
        }

        scope.sourceType = function (permissionType) {
          return $permissionMatrixSourceType(permissionType)
        }
        function resetData() {
          scope.data.pageUserError = null;
          scope.data.pageRoleError = null;
          scope.data.pagePermissionError = null
          scope.data.pageRoles = [];
          scope.data.pageUsers = [];
          scope.data.pagePermissions = [];
        }

        scope.setAccordian = function ($event, pagePermission) {
          if ($event) {
              $event.currentTarget.classList.toggle("active");
              var panel = $event.target.nextElementSibling;
              if(panel){
                if (panel.style.display === "block") {
                    panel.style.display = "none";
                } else {
                    panel.style.display = "block";
                }
              }
          }
          if(pagePermission){
            pagePermission.expand = !pagePermission.expand;
          }
        }
        init()
      }
    }
  }
// Dependency Injection
screenDirective.$inject = ["$auth","$timeout","$clientSwitcher","$client","$clientUser","$activeClient","$permissionMatrix","$permissionMatrixSourceType","$clientPermissionTypes"];
