import './interpreter-next-day-scheduled.scss';
'use strict';
export const interpreterNextDayScheduledDirective = function ( $clientSwitcher, $auth) {
        return {
            restrict: 'E',

            templateUrl: 'app/account/dashboard/main-dashboard/work-orders/tabs/interpreter-next-day-scheduled/interpreter-next-day-scheduled.html',
            scope: {},
            link: function (scope) {
                const currentUser = $auth.getCurrentUser();
                const currentCli = $clientSwitcher.getCurrentClient();

                function init() {
                    
                }
                init()
            }
        }
    }
// Dependency Injection
interpreterNextDayScheduledDirective.$inject = ["$clientSwitcher","$auth"];
