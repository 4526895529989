'use strict';


export const formManagerDirective = function (Modal, $rootScope, $q, $auth, $clientSwitcher, $clientManager, $clientOffice) {
    return {
      restrict: 'E',
      templateUrl: 'components/forms/manager.html',
      scope: {
        manager: '=ngModel',
        onSubmit: '=formOnSubmit',
        onReset: '=formOnReset'
      },
      link: function(scope, el, attrs) {

        scope.allValue = 'all';
        scope.itemDelimiter = ', ';
        scope.loadingInfo = false;

        scope.mgrOfficeItems = [];

        function loadOffices(cli) {
          scope.mgrOfficeItems.splice(0, scope.mgrOfficeItems.length);
          return $clientOffice.listAll(cli)
            .then(function (offices) {
              offices.forEach(function (office) {
                scope.mgrOfficeItems.push({value: office._id, label: office.name});
              });
              return offices;
            });
        }

        function loadClientInfo(cli) {
          if(scope.loadingInfo || !cli) return; // ignore any parallel requests
          scope.loadingInfo = true;

          return $q.all([
              loadOffices(cli)
            ])
            .catch(function (err) {
              scope.loadingError = err;
              return err;
            })
            .finally(function() {
              scope.loadingInfo = false;
            });
        }

        var killCsLoad, killCsSwitch;

        $clientSwitcher.whenLoaded(function (all, current) {
          loadClientInfo(current);

          killCsLoad = $rootScope.$on('client-switcher-load', function (evt, all, current) { // listen for reloads too
            loadClientInfo(current);
          });
          killCsSwitch = $rootScope.$on('client-switcher-switch', function (evt, client) {
            loadClientInfo(client);
          });
        });

        scope.$on('$destroy', function(){
          killCsLoad();
          killCsSwitch();
        });

        scope.clientStrings = function(){ return $clientSwitcher.getFeatureOptions('client.strings'); };
        scope.officeSettings = function() {
          var officeStr = (scope.clientStrings()||{}).officesPCase;
          return {
            selectedStr: 'Selected '+officeStr+': ',
            noSelection: 'No '+officeStr+' Selected',
            allLabel:    'All '+ officeStr
          };
        };
      }
    };
  }
// Dependency Injection
formManagerDirective.$inject = ["Modal","$rootScope","$q","$auth","$clientSwitcher","$clientManager","$clientOffice"];
