'use strict';

angular
export const DashboardSettingsMobileAppsIndexCtrl = function ($http, $scope, $activeClient, $clientSwitcher, $mobileAppManager) {
  var client = $clientSwitcher.getCurrentClient();
  $scope.clientName = client.name;
  $scope.apps = [];
  $scope.loading = true;

  $mobileAppManager.getApps(client._id).then(function(result) {
    $scope.apps = result;
  })
  .finally(function() {
    $scope.loading = false;
  });
}
// Dependency Injection
DashboardSettingsMobileAppsIndexCtrl.$inject = ["$http","$scope","$activeClient","$clientSwitcher","$mobileAppManager"];

// angular.module('coordinateApp').controller('DashboardSettingsMobileAppsIndexCtrl', DashboardSettingsMobileAppsIndexCtrl);
