
'use strict';

export const $degreeOrCertificateTypeService  = function () {
        let type = [
            {
                label: 'Degree',
                value: 'Degree'
            },
            {
                label: 'Certification',
                value: 'Certification'
            },
            {
                label: 'Award',
                value: 'Award'
            },
            {
                label: 'Other',
                value: 'Other'
            }
        ]
        return type;
    } 
 
