
'use strict';

export const $clientEmployeeService  = function (ClientEmployee, $http, $auth) {
    return {
      getEmployees: function(client, search) {
        return ClientEmployee.getEmployees({
          id: client._id || client,
          searchText: search && search.query,
          onlyUserEmployees: search && search.onlyUserEmployees
        }).$promise;
      },
      detail: function(client, employee) {
        return ClientEmployee.detail({
          id: client._id||client,
          employee: employee
        }).$promise;
      },
      createEmployee: function(client, employeeData) {
        return ClientEmployee.createEmployee({
          _id: client._id||client,
          data: employeeData
        }).$promise;
      },
      updateEmployee: function(client, employeeId, employeeData) {
        return ClientEmployee.updateEmployee({
          _id: client._id||client,
          employee: employeeId,
          data: employeeData
        }).$promise;
      },
      deleteEmployee: function(client, employee) {
        return ClientEmployee.deleteEmployee({
          _id: client._id||client,
          employee: employee._id
        }).$promise;
      },
      getUserTree: function(client) {
        return ClientEmployee.getUserTree({
          id: client._id||client
        }).$promise;
      },
      getLogs: function(client, page, pageSize, onlyErrorLogs) {
        return ClientEmployee.getLogs({
          id: client._id || client,
          page: page,
          pageSize: pageSize,
          onlyErrorLogs: onlyErrorLogs
        }).$promise;
      },
      getChildrenEmployees: function(client, parent) {
        return ClientEmployee.getChildrenEmployees({
          id: client._id || client,
          parent: (parent && parent._id) || parent
        }).$promise;
      },
      getEmployeeStats: function(client, user) {
        return ClientEmployee.getEmployeeStats({
          id: client._id || client
        }).$promise;
      },
      getEmployeePerformance: function(client, employee, filter) {
        return ClientEmployee.getEmployeePerformance({
          id: client._id || client,
          employee: employee._id || employee,
          filter : filter
        }).$promise;
      },
      getGrandParentChildren: function(client, user) {
        return ClientEmployee.getGrandParentChildren({
          id: client._id || client,
          user: user._id || user 
        }).$promise;
      },
      getRemovedEmployees: function(client, search){
        return ClientEmployee.getRemovedEmployees({
          id: client._id || client,
          searchText: search.query,
          onlyUserEmployees: search.onlyUserEmployees
        }).$promise;
      },
      makeEmployeeAvailable: function(client, employee) {
        return ClientEmployee.makeAvailable({
          _id: client._id || client,
          employee: employee ? (employee._id || employee) : null
        }).$promise;
      },
      getAssociatedEmployee: function(client, user, shareTree) {
        return ClientEmployee.associatedEmployee({
          id: client._id || client,
          user: user ? (user._id || user) : null,
          shareTree: shareTree? shareTree : 'false'
        }).$promise;
      },
      toggleEmployee: function(client, employee) {
        return ClientEmployee.toggleEmployee({
          _id: client._id || client,
          employee: employee._id || employee
        }).$promise;
      },
      userNotifications: function(client, employee){
        return ClientEmployee.userNotifications({
          _id: client._id || client,
          employee: employee._id || employee
        }).$promise;
      },
      ivrSync: function(client){
        return ClientEmployee.ivrSync({
          id: client._id
        }).$promise;
      },
      exportPerformanceSurveryRecords: function(client, employee, filter){
        return $http({
          url: '/api/client-employees/'+ (client._id || client) +'/export-performance-survey-records', 
          method: "GET",
          params: {
            id: client._id || client,
            employee: employee._id || employee,
            filter : filter
          }
        })
        .success(function(data, status, headers, config) {
          var element = angular.element('<a></a>');

          var blob = new Blob([data], { type: "application/octet-stream" });
          var objectUrl = URL.createObjectURL(blob);

          element.attr({
            href: objectUrl,
            target: '_blank',
            download: 'performance_report_' + moment(filter.startDate).format('MMDDYYYY') + '_' + moment(filter.endDate).format('MMDDYYYY') + ".csv"
          });
          element[0].click();
        })
        .error(function(data, status, headers, config) {
          alert('Error while downloading the survey performance report');
        });
      }
    };
  }
// Dependency Injection


// Dependency Injection
$clientEmployeeService.$inject = ['ClientEmployee', '$http', '$auth'];
