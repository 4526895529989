'use strict';

export const ClientsConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.clients', {
        url: '/my/clients',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/clients/clients.html', '38b5500a'); }],
        controller: 'ClientAdminCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/clients/clents.controller.js', 'ClientAdminCtrl'); }]
                      },
      })
  }
// Dependency Injection
ClientsConfig.$inject = ["$stateProvider"];
