


'use strict';

export const ClientReportBucketsUploadFactory  = function ($resource, appConfig) {
    return $resource(appConfig.apiUrl + '/api/report-buckets-upload/:id/:controller', {
      id: '@_id'
    },
      {
        create: {
          method: 'POST',
          params: {
            controller: 'create'
          }
        },
        getBucketUpload: {
          method: 'GET',
          params: {
            id: '@id'
          }
        },
        reportBuckets: {
          method: 'POST',
          params: {
            controller: 'report-buckets'
          }
        },
        removeAttachments: {
          method: 'POST',
          params: {
            controller: 'remove-attachments'
          }
        },
        getReportsFolderDetails: {
          method: 'POST',
          params: {
            controller: 'get-reports-folder-details'
          }
        },
        createFilesOrFolder: {
          method: 'POST',
          params: {
            controller: 'create-files-folders'
          }
        },
        deleteAttachment: {
          method: 'POST',
          params: {
            controller: 'delete-attachment'
          }
        },
        deleteFolder: {
          method: 'POST',
          params: {
            controller: 'delete-folder'
          }
        },
        updateFolder: {
          method: 'POST',
          params: {
            controller: 'update-folder'
          }
        },
        updateExistFile: {
          method: 'POST',
          params: {
            controller: 'update-exist-file'
          }
        }
      });
  } 
 

// Dependency Injection
ClientReportBucketsUploadFactory.$inject = ['$resource', 'appConfig'];
