'use strict';

export const interpreterLanguagesDirective = function ($rootScope, $q, $clientSwitcher, ngToast, $activeClient, $intLangs) {
        return {
            restrict: 'E',
            templateUrl: 'app/account/dashboard/main-dashboard/interpreter-resource/tabs/profile/tabs/languages/ic-languages.html',
            scope: {
                interpreterDetails: '=interpreterDetails',
                isInterpreter: '=isInterpreter',
                icLanguages: '=icLanguages',
                permissions: '=userPermissions'
            },
            link: function ($scope) {
                let killClientWatch, currentCli = $clientSwitcher.getCurrentClient();
                $scope.intLangsList = [];
                $scope.InterpreterName = `${$scope.interpreterDetails.firstname} ${$scope.interpreterDetails.middlename ? $scope.interpreterDetails.middlename : ''} ${$scope.interpreterDetails.lastname}`;
                $scope.permissionsObj = {}

                function init() {
                    killClientWatch = $activeClient(function (client) {
                        if (!client) {
                            return;
                        }
                        currentCli = client;
                        loadInterpreterLanguages()
                    });
                }

                function loadInterpreterLanguages() {
                    if ($scope.isLoading) return;
                    $scope.error = null;
                    $scope.intLangsData = [];
                    $scope.total = 0;
                    $scope.isLoading = true;
                    $intLangs.getIntLangsList(currentCli, $scope.pagination, $scope.interpreterDetails.vendorid)
                        .then((res) => {
                            if (res.status) {
                                if (res.intLangsList && res.intLangsList.length) {
                                    $scope.intLangsData = res.intLangsList;
                                    $scope.total = res.totalRecords;
                                } else {
                                    $scope.error = 'No Record Found.';
                                }
                            }
                        })
                        .catch((err) => {
                            let errMessage = err.message || err;
                            if (err && err.data && err.data.message) {
                                errMessage = err.data.message;
                            } else if (err && err.data && err.data.reason) {
                                errMessage = err.data.reason;
                            }
                            $scope.error = errMessage;
                        })
                        .finally(() => {
                            $scope.isLoading = false;
                        })

                }

                $scope.$on('$destroy', function () {
                    if (killClientWatch) {
                        killClientWatch();
                    }
                });
                init();
            }
        }
    }
// Dependency Injection
interpreterLanguagesDirective.$inject = ["$rootScope","$q","$clientSwitcher","ngToast","$activeClient","$intLangs"];
