'use strict';

export const DqsReportsConfig = function ($stateProvider) {
    $stateProvider
      .state('app.print.dqsReports', {
        url: '/dqs-reports',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./print/dqs-reports/dqs-reports.html', '9b20dcca'); }],
        controller: 'PrintDQSReportsCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./print/dqs-reports/dqs-reports.controller.js', 'PrintDQSReportsCtrl'); }]
                      }
      });
  }
// Dependency Injection
DqsReportsConfig.$inject = ["$stateProvider"];
