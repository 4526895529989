'use strict';

export const RolesConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard.settings.roles', {
        url: '/roles',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/roles/roles.html', 'e8ba074a'); }],
        controller: 'DashboardSettingsRolesCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/roles/roles.controller.js', 'DashboardSettingsRolesCtrl'); }]
                      }
      })
      .state('app.account.dashboard.settings.roles.index', {
        url: '/roles',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/roles/index/index.html', 'c6c145ea'); }],
        controller: 'DashboardSettingsRolesIndexCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/roles/index/index.controller.js', 'DashboardSettingsRolesIndexCtrl'); }]
                      }
      })
      .state('app.account.dashboard.settings.roles.create', {
        url: '/create',
        templateProvider: ['$q', '$templateCache', function($q, $templateCache) {
                  var cacheName = 'accountdashboardsettingsrolesrolescreate_createTemplate';
                  return $q((resolve) => {
                      if ($templateCache.get(cacheName)) {
                          resolve($templateCache.get(cacheName));
                      } else {
                          import('./create/create.html').then(template => {
                              $templateCache.put(cacheName, template.default);
                              resolve(template.default);
                          }).catch(e=>{
                            console.log('error importing template', e)
                          })
                      }
                  });
              }],
        controller: 'DashboardSettingsRolesCreateCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/roles/create/create.controller.js', 'DashboardSettingsRolesCreateCtrl'); }]
                      }
    })
    .state('app.account.dashboard.settings.roles.update', {
        url: '/update/:id',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/roles/update/update.html', 'e2d113aa'); }],
        controller: 'DashboardSettingsRolesUpdateCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/roles/update/update.controller.js', 'DashboardSettingsRolesUpdateCtrl'); }],
                          '$selectedRole': ['$stateParams', '$clientRoles', '$clientSwitcher', function($stateParams, $clientRoles, $clientSwitcher) {
                            return $clientRoles.getRoleById($clientSwitcher.getCurrentClient(), $stateParams.id);
                          }]
                      },

    });
  }
// Dependency Injection
RolesConfig.$inject = ["$stateProvider"];
