
'use strict';

export const replaceWorkFilter  = function () {
        return function (str) {
            if (!str) { return str; }
            return str.replace('Work', '');
        };
    }
// Dependency Injection

