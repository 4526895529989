import './satisfaction.scss';
'use strict';

export const SatisfactionConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard.satisfaction', {
        url: '/satisfaction?action&survey',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/satisfaction/satisfaction.html', 'f96ee2ea'); }],
        controller: 'DashboardSatisfactionCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) {
                            return LazyLoadService.loadChartLibraries().then(function() {
                              return LazyLoadService.loadController('./account/dashboard/satisfaction/satisfaction.controller.js', 'DashboardSatisfactionCtrl');
                            })
                          }]
                      },
        data: {
          ability: 'client.satisfaction',
          permission: 'SATISFACTION_READ'
        }
      });
  }
// Dependency Injection
SatisfactionConfig.$inject = ["$stateProvider"];
