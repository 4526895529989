
'use strict';

export const $authStorageService  = function ($cookieStore) {
    var COOKIE_TOKEN = 'token';
    var COOKIE_ACCESS_TOKEN = 'accessToken';
    var COOKIE_REFRESH_TOKEN = 'refreshToken';

    return {
      hasToken: function() {
        return !!this.getToken();
      },
      clearToken: function() {
        $cookieStore.remove(COOKIE_TOKEN);
        $cookieStore.remove(COOKIE_ACCESS_TOKEN);
        $cookieStore.remove(COOKIE_REFRESH_TOKEN);
        return this;
      },
      getToken: function() {
        return $cookieStore.get(COOKIE_TOKEN);
      },
      setToken: function(v) {
        $cookieStore.put(COOKIE_TOKEN, v);
        return this;
      },
      getAccessToken: function() {
        return $cookieStore.get(COOKIE_ACCESS_TOKEN);
      },
      setAccessToken: function(v) {
        $cookieStore.put(COOKIE_ACCESS_TOKEN, v);
        return this;
      },
      getRefreshToken: function() {
        return $cookieStore.get(COOKIE_REFRESH_TOKEN);
      },
      setRefreshToken: function(v) {
        $cookieStore.put(COOKIE_REFRESH_TOKEN, v);
        return this;
      }
    };
  } 
 

// Dependency Injection
$authStorageService.$inject = ['$cookieStore'];
