import './contact.scss';
'use strict';

export const ContactConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard.contacts', {
        url: '/contact',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/contact/contact.html', '6b7c484a'); }],
        controller: 'DashboardContactCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/contact/contact.controller.js', 'DashboardContactCtrl'); }]
                      },
        data: {
          ability: 'client.ranking',
          permission: 'CONTACTS_READ'
        }
      });
  }
// Dependency Injection
ContactConfig.$inject = ["$stateProvider"];
