import './history.scss';
'use strict';

export const HistoryConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard.history', {
        url: '/history',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/task-history/history.html', '75ed03f8'); }],
        controller: 'DashboardTaskHistoryCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) {
                            return LazyLoadService.loadDirective('./account/dashboard/tasks/bucket-task.directive.js', 'bucketTaskDirective')
                            .then(() => {
                              return LazyLoadService.loadController('./account/dashboard/task-history/history.controller.js', 'DashboardTaskHistoryCtrl');
                            })
                          }]
                      },
        data: {
          ability: 'client.history',
          permission: 'TASK_HISTORY_READ'
        }
      });
  }
// Dependency Injection
HistoryConfig.$inject = ["$stateProvider"];
