
'use strict';

export const $devicesManagerService  = function (DevicesManager) {
    return {
      create: function(client, deviceData) {
        return DevicesManager.saveDevice({
          _id: client._id || client,
          location: deviceData.location,
          deviceId: deviceData.deviceId,
          enabled: deviceData.enabled,
          name: deviceData.name,
          link: deviceData.link
        }).$promise;
      },
      updateDevice: function(client, device, data) {
        return DevicesManager.updateDevice({
          _id: client._id || client,
          device: device,
          data: data
        }).$promise;
      },
      getDevices: function(client) {
        return DevicesManager.getDevices({
          id: client._id || client
        }).$promise;
      },
      getDeviceDetails: function(client, deviceId) {
        return DevicesManager.getDeviceDetails({
          id: client._id || client,
          device: deviceId
        }).$promise;
      },
      toggleState: function(client, device) {
        return DevicesManager.toggleState({
          _id: client._id || client,
          deviceId: device.deviceId
        }).$promise;
      },
      deleteDevice: function(client, device) {
        return DevicesManager.deleteDevice({
          _id: client._id || client,
          deviceId: device.deviceId
        }).$promise;
      }
    };
  }
// Dependency Injection


// Dependency Injection
$devicesManagerService.$inject = ['DevicesManager'];
