
'use strict';

export const $monthlyUserAccessLogsService  = function ($http, MonthlyUserAccessLogs) {
    return {
      getActiveLogs: function (client, search) {
        return MonthlyUserAccessLogs.getActiveLogs({
          id: (client._id || client),
          search: search
        }).$promise;
      },
      downloadActiveUserLogs: function (client, search) {
        return $http({
          url: '/api/monthly-user-access-logs/' + (client._id || client) + '/download-active-user-logs',
          method: "POST",
          params: {
            search: JSON.stringify(search),
            timeOffset: new Date().getTimezoneOffset()
          },
          responseType: "arraybuffer"
        })
          .success(function (data, status, headers, config) {
            if (data.status === false) {
              return data;
            }
            saveAs(new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), `Active_User_Logs.xlsx`);
          })
          .error(function (data, status, headers, config) {
            // alert('Error while downloading the tasks');
          });
      },
    };
  }
// Dependency Injection


// Dependency Injection
$monthlyUserAccessLogsService.$inject = ['$http', 'MonthlyUserAccessLogs'];
