
'use strict';

export const TaskBucketResolvedFactory  = function (TaskBucket) {
  function TaskBucketResolved(opts) {
    TaskBucket.call(this, opts);
    this.isResolved = true;
    this.filterOptions.splice(1, 1, ['resolvedBy', 'Resolved By']);
    this.filterOptions.splice(2, 0, ['resolvedDate', 'Resolved Date']);
  }

  TaskBucketResolved.prototype = Object.create(TaskBucket.prototype);
  TaskBucketResolved.prototype.constructor = TaskBucketResolved;

  return TaskBucketResolved;
}
 

// Dependency Injection
TaskBucketResolvedFactory.$inject = ['TaskBucket'];
