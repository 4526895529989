
'use strict';

export const $performTaskArchiveService  = function ($clientTask, Modal) {
  return function (cb) {
    cb = angular.isFunction(cb) ? cb : angular.noop;
    return Modal.alertActions.archiveRecord(function (record) {
      return $clientTask.archiveTask(record)
        .then(function (archiveResult) {
          return cb.call(this, record, archiveResult);
        });
    }, 'Archive Task');
  };
}
 

// Dependency Injection
$performTaskArchiveService.$inject = ['$clientTask', 'Modal'];
