'use strict';


export const formServiceUserDirective = function ($rootScope, $q, $auth, $clientSwitcher, $clientUser,
        $client, $clientUserSetting, $passwordStrengthValidator, $states, $timezones
    ) {
        return {
            restrict: 'E',
            templateUrl: 'components/forms/service-user.html',
            scope: {
                user: '=ngModel',
                onSubmit: '=formOnSubmit',
                requirePassword: '=formRequirePassword',
                onReset: '=formOnReset',
                isEdit:'=isEdit'
            },
            link: function (scope, el, attrs) {
                scope.client = $clientSwitcher.getCurrentClient();;
                scope.loadingInfo = false;
                scope.loading = true;
                scope.model = {

                }
                scope.states = _.sortBy($states, 'label');
                scope.timezones = $timezones;
                scope.sameAsContact = function () {
                    if (scope.user && scope.user.sameAsContactAdd) {
                        scope.user.billingAddress = Object.assign({
                            firstName: scope.user.firstName,
                            lastName: scope.user.lastName,
                            email: scope.user.email,
                            company: scope.user.internalId,
                            phoneNumber: scope.user.phoneNumber,
                            phoneExt: scope.user.phoneExt,
                            timezone: scope.user.timezone,
                            internalId: scope.user.internalId,
                        }, scope.user.address)
                        scope.user.billingInfo = scope.user.billingAddress;
                    } else {
                        scope.user.billingAddress = {}
                    }
                }

                scope.validateEmail = function () {
                    if (!scope.user.email || scope.formUser.email.$invalid) {
                        if (scope.formUser && scope.formUser.email && scope.formUser.email.$setValidity) {
                            scope.formUser.email.$setValidity('unique', true);
                        }
                        return;
                    }

                    $clientUser.isEmailUnique(scope.client, scope.user.email, scope.user.userId)
                        .then(function (res) {
                            if (res.status && scope.formUser && scope.formUser.email && scope.formUser.email.$setValidity) {
                                scope.formUser.email.$setValidity('unique', res.data);
                            }
                            else {
                                if (scope.formUser && scope.formUser.email && scope.formUser.email.$setValidity) {
                                    scope.formUser.email.$setValidity('unique', false);
                                    // console.error(res.reason);
                                }
                            }
                        });
                };

                scope.passwordStrengthValidator = (value, type) => {
                    if (type == 'duress') {
                        scope.duressPasswordStrength = $passwordStrengthValidator(value);
                        scope.passwordStrength = null;
                    } else {
                        scope.duressPasswordStrength = null;
                        scope.passwordStrength = $passwordStrengthValidator(value);
                    }
                }

                scope.passwordStrengthValidator = (value, type) => {
                    if (type == 'duress') {
                        scope.duressPasswordStrength = $passwordStrengthValidator(value);
                        scope.passwordStrength = null;
                    } else {
                        scope.duressPasswordStrength = null;
                        scope.passwordStrength = $passwordStrengthValidator(value);
                    }
                }
            }
        };
    }
// Dependency Injection
formServiceUserDirective.$inject = ["$rootScope","$q","$auth","$clientSwitcher","$clientUser","$client","$clientUserSetting","$passwordStrengthValidator","$states","$timezones"];
