


'use strict';

export const DQSReportsFactory  = function ($resource, appConfig) {
    return $resource(appConfig.apiUrl + '/api/dqs-report/:id/:controller', {
        id: '@_id'
    },
    {
        getList: {
          method: 'GET',
          params: {
              controller: 'dqs-list'
          }
        }, 
        getReports: {
            method: 'POST',
            params: {
                id: '@id',
                controller: ''
            }
        },

        getReportFilters: {
          method: 'POST',
          params: {
            id: '@id',
            controller: 'get-report-filters'
          }
        }
    });
  } 
 

// Dependency Injection
DQSReportsFactory.$inject = ['$resource', 'appConfig'];
