
'use strict';

export const $clientTrainingManagerService  = function (ClientTrainingManager) {
    return {
      createTrainingRecord: function(client, trainingData) {
        return ClientTrainingManager.createTrainingRecord({
          _id: client._id || client,
          data: trainingData
        }).$promise;
      },
      updateTrainingRecord: function(client, trainingId, trainingData) {
        return ClientTrainingManager.updateTrainingRecord({
          _id: client._id || client,
          training: trainingId,
          data: trainingData
        }).$promise;
      },
      deleteTrainingRecord: function(client, training) {
        return ClientTrainingManager.deleteTrainingRecord({
          _id: client._id || client,
          training: training._id
        }).$promise;
      },
      getTrainingRecords: function(client) {
        return ClientTrainingManager.getTrainingRecords({
          id: client._id || client
        }).$promise;
      }
    };
  }
// Dependency Injection


// Dependency Injection
$clientTrainingManagerService.$inject = ['ClientTrainingManager'];
