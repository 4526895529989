import './otc-any-where.scss';
'use strict';

export const OtcAnyWhereConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard.otcAnyWhere', {
        url: '/otcanywhere',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/otc-any-where/otc-any-where.html', '163215ca'); }],
        controller: 'OtcAnyWhereCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/otc-any-where/otc-any-where.controller.js', 'OtcAnyWhereCtrl'); }]
                      },
        data: {
          ability: 'client.OtcAnyWhere',
          permission: 'OTC_ANYWHERE_READ'
        }
      });
  }
// Dependency Injection
OtcAnyWhereConfig.$inject = ["$stateProvider"];
