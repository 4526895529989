
'use strict';

export const $lateTimeService  = function ($timezones) {
  return function (record) {
    var index = $timezones.findIndex(function (x) {
      return x.label === record.timezonename;
    })
    var timezoneOffset = $timezones[index] ? $timezones[index].offset : moment().format('ZZ');
    var startTime = moment().utcOffset(timezoneOffset).format();
    var endTime = moment(record.sched, "HH:mm:ss").format('Y-MM-DDTHH:mm:ss') + timezoneOffset;
    var duration = moment(startTime).diff(moment(endTime)) / 1000;
    if (duration > 0) {
      return duration;
    }
    return 0;
  }
}


// Dependency Injection
$lateTimeService.$inject = ['$timezones'];
