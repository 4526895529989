
'use strict';

export const $clientDeviceEventsManagerService  = function (ClientDeviceEventsManager) {
    return {
      createEvent: function(client, eventName) {
        return ClientDeviceEventsManager.createEvent({
          _id: client._id || client,
          name: eventName
        }).$promise;
      },
      deleteEvent: function(client, event) {
        return ClientDeviceEventsManager.deleteEvent({
          _id: client._id || client,
          event: event._id
        }).$promise;
      },
      getEvents: function(client) {
        return ClientDeviceEventsManager.getEvents({
          id: client._id || client
        }).$promise;
      }
    };
  }
// Dependency Injection


// Dependency Injection
$clientDeviceEventsManagerService.$inject = ['ClientDeviceEventsManager'];
