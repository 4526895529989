


'use strict';

export const JudgesFactory  = function ($resource, appConfig) {
        return $resource(appConfig.apiUrl + '/api/judges/:id/:controller', {
            id: '@_id'
        },
            {
                getJudgesList: {
                    method: 'GET',
                    params: {
                        controller: 'get-judges-list'
                    }
                },
                create: {
                    method: 'POST',
                    params: {
                        controller: 'create'
                    }
                },
                getActiveList:{
                    method: 'GET',
                    params: {
                        controller: 'active-list'
                    }
                    
                },
                getJudgeById: {
                    method: 'GET',
                    params: {
                        id: '@id'
                    }
                },
                update: {
                    method: 'POST',
                    params: {
                        controller: 'update'
                    }
                },
                toggleAccess: {
                    method: 'POST',
                    params: {
                        controller: 'toggle-access'
                    }
                }
            });
    } 
 

// Dependency Injection
JudgesFactory.$inject = ['$resource', 'appConfig'];
