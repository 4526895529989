'use strict';

export const EquipmentsConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard.settings.equipments', {
        url: '/equipments',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/equipments/equipments.html', '70a7474a'); }],
        controller: 'DashboardSettingsEquipmentsCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/equipments/equipments.controller.js', 'DashboardSettingsEquipmentsCtrl'); }]
                      },
      })
      .state('app.account.dashboard.settings.equipments.index', {
        url: '/equipments',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/equipments/index/index.html', '5957e64a'); }],
        controller: 'DashboardSettingsEquipmentsIndexCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/equipments/index/index.controller.js', 'DashboardSettingsEquipmentsIndexCtrl'); }]
                      }
      })
      .state('app.account.dashboard.settings.equipments.update', {
        url: '/update/:id',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/equipments/update/update.html', '66bc410a'); }],
        controller: 'DashboardSettingsEquipmentsUpdateCtrl',
        resolve: {
            lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/equipments/update/update.controller.js', 'DashboardSettingsEquipmentsUpdateCtrl'); }],
              '$selectedEquipment': ['$stateParams','$clientEquipment', '$clientSwitcher', function ($stateParams, $clientEquipment, $clientSwitcher) {
                return $clientEquipment.detail($clientSwitcher.getCurrentClient(), $stateParams.id).then(function(res) {
                  if(res.status) {
                    return res.data;
                  }
                  else {
                    throw new Error(res.reason);
                  }
                });
              }]
        },
      })
      .state('app.account.dashboard.settings.equipments.create', {
        url: '/create',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/equipments/create/create.html', 'a001a0aa'); }],
        controller: 'DashboardSettingsEquipmentsCreateCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/equipments/create/create.controller.js', 'DashboardSettingsEquipmentsCreateCtrl'); }]
                      },
      });
  }
// Dependency Injection
EquipmentsConfig.$inject = ["$stateProvider"];
