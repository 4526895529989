
'use strict';

export const $officeOperationsService  = function (OfficeOperations, $http) {
        return {
            filterCourtOperationsDetails: function (client, filter, paginationData) {
                return OfficeOperations.filterCourtOperations({
                    id: client._id || client,
                    filterBy: filter,
                    page: paginationData && paginationData.page,
                    pageSize: paginationData && paginationData.pageSize
                }).$promise;
            },
            getLinkFilterDetails: function (client, filter) {
                return OfficeOperations.getLinkFilterDetails({
                    id: client._id || client,
                    controller: 'link-filter-details',
                    filterBy: filter
                }).$promise;
            },
            reportingCourtLinkToHrgCourt: function (client, filter) {
                return OfficeOperations.reportingCourtLinkToHrgCourt({
                    id: client._id || client,
                    controller: 'reporting-court-link-to-hrgCourt',
                    filterBy: filter
                }).$promise;
            },
            checkInterpreterOnWO: function (client, dataSets) {
                return OfficeOperations.checkInterpreterOnWO({
                    id: client._id || client,
                    dataSets: dataSets,
                    controller: 'check-interpreter-on-wo',
                }).$promise;
            },
            checkInterpreterOnWOById: function (client, ecoi, dataSets) {
                return OfficeOperations.checkInterpreterOnWOById({
                    id: client._id || client,
                    ecoi,
                    dataSets: dataSets,
                    controller: 'check-interpreter-on-wo-by-id',
                }).$promise;
            },
            checkECOIOrNot: function (client, filter) {
                return OfficeOperations.checkECOIOrNot({
                    id: client._id || client,
                    controller: 'check-ecoi-or-not',
                    filterBy: filter
                }).$promise;
            },
            getInterpreterLangs: function (client, filter) {
                return OfficeOperations.getInterpreterLangs({
                    id: client._id || client,
                    controller: 'get-interpreter-langs',
                    filterBy: filter
                }).$promise;
            },
            getRecordForReportingCourt: function (client, filter) {
                return OfficeOperations.getRecordForReportingCourt({
                    id: client._id || client,
                    controller: 'get-record-for-reporting-court',
                    filterBy: filter
                }).$promise;
            },
            getRequestInterpreterMatch: function (client, filter) {
                return OfficeOperations.getRequestInterpreterMatch({
                    id: client._id || client,
                    filterBy: filter
                }).$promise;
            },
            getEcoiDetails: function(client, filter, paginationData) {
                return OfficeOperations.getEcoiDetails({
                  id: client._id || client,
                  controller: 'get-ecoi-details',
                  filterBy: filter,
                  page: paginationData && paginationData.page,
                  pageSize: paginationData && paginationData.pageSize
                }).$promise;
            },
            getEcoiDetailsNew: function(client, filter, paginationData) {
                return OfficeOperations.getEcoiDetailsNew({
                  id: client._id || client,
                  controller: 'get-ecoi-details-new',
                  filterBy: filter,
                  page: paginationData && paginationData.page,
                  pageSize: paginationData && paginationData.pageSize
                }).$promise;
            },
            getEcoiById: function(client, ecoi) {
                return OfficeOperations.getEcoiById({
                  id: client._id || client,
                  controller: 'get-ecoi-detail-by-id',
                  ecoi
                }).$promise;
            },
            downloadEcoiDetails: function (client, filter) {
                return $http({
                    url: '/api/office-operations/' + (client._id || client) + '/download-ecoi-details',
                    method: "POST",
                    params: {
                        filterBy: JSON.stringify(filter),
                        timeOffset: new Date().getTimezoneOffset()
                    },
                    responseType: "arraybuffer"
                })
                    .success(function (data, status, headers, config) {
                        if (data.status === false) {
                            return data;
                        }

                        saveAs(new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), `FulfillmentOperationsReport-${filter.startDate}-${filter.endDate}.xlsx`);
                    })
                    .error(function (data, status, headers, config) {
                        // alert('Error while downloading the tasks');
                    });
            },
            downloadInterpreterWO: function (client, filter) {
                return $http({
                    url: '/api/office-operations/' + (client._id || client) + '/download-interpreter-wo',
                    method: "POST",
                    params: {
                        dataSets: JSON.stringify(filter)
                    },
                    responseType: "arraybuffer"
                })
                    .success(function (data, status, headers, config) {
                        if (data.status === false) {
                            return data;
                        }

                        saveAs(new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), `FulfillmentOperationsReport.xlsx`);
                    })
                    .error(function (data, status, headers, config) {
                        // alert('Error while downloading the tasks');
                    });
            },
        };
    } 
 

// Dependency Injection
$officeOperationsService.$inject = ['OfficeOperations', '$http'];
