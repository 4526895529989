import './modal.scss';
'use strict';


export const SurveyModalFactory = function ($rootScope, $uibModal, $auth, $filter, $window, $authStorage, $clientTask, AudioPlayList, FileUploader, $clientSwitcher, $serveySentiment) {

    var
    dateFilter = $filter('date'),
    client = $clientSwitcher.getCurrentClient();

    /**
     * Opens a modal
     * @param  {Object} scope      - an object to be merged with modal's scope
     * @param  {String} modalClass - (optional) class(es) to be applied to the modal
     * @return {Object}            - the instance $modal.open() returns
     */
    function openModal(scope, modalClass) {
      var modalScope = $rootScope.$new();
      scope = scope || {};
      modalClass = modalClass || 'modal-default';

      if(!scope.controller) {
        scope.controller = [function () {
        }];
      }

      angular.extend(modalScope, scope);

      return $uibModal.open({
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('../components/modal/survey/modal.html', '7b20289f'); }],
        windowTemplateUrl: 'components/modal/survey/modal-window.html',
        windowClass: modalClass,
        scope: modalScope
      });
    }

    function recordPlaylist(record) {
      if(!record || !record.raw) {
        return false;
      }

      var recordings = (angular.isArray(record.raw.recording) ? record.raw.recording : []);

      return new AudioPlayList({
        tracks: recordings.map(function (r) {
          r.title = 'Recording From ' + dateFilter(r.created, 'short');
          return r;
        })
      });
    }

    function taskAudioPlaylist(record) {
      if(!record || !record.attachments || !record.attachments.length) {
        return false;
      }

      var recordings = _.filter(record.attachments, function(attachment) {
        return attachment.upload.mimeType.indexOf('audio') > -1;
      });

      recordings = recordings.map(function(recording) {
        var r = {};
        r.src = '/api/client-uploads/'+(client._id||client)+'/'+recording.upload._id + '?access_token='+$authStorage.getToken();
        r.type = recording.upload.mimeType;
        r.transcription = '';
        r.created = recording.upload.createdOn;
        r.title = recording.upload.name;
        return r;
      });

      return new AudioPlayList({
        tracks: recordings
      });
    }

    function getAllSentiment() {
      return $serveySentiment.getSentiments(client)
        .then(function (res) {
          if (res.status) {
            return res.data;
          }else{
            return res
          }
        })
    }

    function updateSentiment(sentimentObject) {
      $serveySentiment.updateSentiment(client, sentimentObject)
        .then(function (result) {
        })
    }

    Array.prototype.diff = function (a) {
      return this.filter(function (i) {
        return a.indexOf(i) === -1;
      });
    };
    // Public API here
    return {

      /* Confirmation modals */
      confirm: {

        show: function(title, message, confirmButton, cancelButton, cb){
            var modal = openModal({
              modal: {
                dismissable: true,
                title: title,
                html: message,
                buttons: [{
                  classes: 'btn-danger',
                  text: confirmButton,
                  click: function(e) {
                    cb();
                    modal.dismiss(e);
                  }
                }, {
                  classes: 'btn-default',
                  text: cancelButton,
                  click: function(e) {
                    modal.dismiss(e);
                  }
                }]
              }
            }, 'modal-danger');
        },

        /**
         * Create a function to open a delete confirmation modal (ex. ng-click='myModalFn(name, arg1, arg2...)')
         * @param  {Function} del - callback, ran when delete is confirmed
         * @return {Function}     - the function to open the modal (ex. myModalFn)
         */
        delete: function(del) {
          del = del || angular.noop;

          /**
           * Open a delete confirmation modal
           * @param  {String} name   - name or info to show on modal
           * @param  {All}           - any additional args are passed staight to del callback
           */
          return function() {
            var args = Array.prototype.slice.call(arguments),
                name = args.shift(),
                deleteModal;

            deleteModal = openModal({
              modal: {
                dismissable: true,
                title: 'Confirm Delete',
                html: '<p>Are you sure you want to delete <strong>' + name + '</strong> ?</p>',
                buttons: [{
                  classes: 'btn-danger',
                  text: 'Delete',
                  click: function(e) {
                    deleteModal.close(e);
                  }
                }, {
                  classes: 'btn-default',
                  text: 'Cancel',
                  click: function(e) {
                    deleteModal.dismiss(e);
                  }
                }]
              }
            }, 'modal-danger');

            deleteModal.result.then(function(event) {
              del.apply(event, args);
            });
          };
        }
      },
      info: {
        comingSoon: function(finish) {
          finish = finish || angular.noop;
          return function () {
            var args = Array.prototype.slice.call(arguments),
                feature = args.shift(),
                cls  = args.shift(),
                infoModal;

            infoModal = openModal({
              modal: {
                dismissable: true,
                title: feature||'Information',
                html: '<p>This feature'+(!!feature ? ' ('+feature+')': '')+' is still in development.</p>',
                buttons: [{
                  classes: 'btn-default',
                  text: 'Close',
                  click: function(e) {
                    infoModal.dismiss(e);
                  }
                }]
              }
            }, cls||'modal-danger');

            infoModal.result.then(function(event) {
              finish.call(event);
            });
          };
        },
        previewUpload: function (finish) {
          finish = finish || angular.noop;
          return function (client, uploadList, startingIndex) {

            var
            // downloadUrl = '/api/client-uploads/'+(client._id||client)+'/'+upload[startingIndex]._id + '?access_token='+$authStorage.getToken(),
            scratchPad = {
            },
            modalWin = openModal({
              modal: {
                dismissable: true,
                title: 'Attachments', //upload,name
                template: 'components/modal/survey/tpl/preview-upload.html',
                buttons: [{
                  classes: 'btn-primary',
                  text: 'Download'
                }]
              },
              controller: ['$scope', '$timeout' ,'appConfig', function ($scope, $timeout, appConfig) {

                if(uploadList.length < 2) {
                  $scope.hasMultipleAttachments = false;
                } else {
                  $scope.hasMultipleAttachments = true;
                }

                $scope.currentIndex = startingIndex;
                $scope.next = function() {
                  $timeout(function() {
                    $scope.currentIndex = ($scope.currentIndex + 1) % uploadList.length;
                  }, 50);
                };

                $scope.previous = function() {
                  $timeout(function() {
                    if($scope.currentIndex - 1 === -1) {
                      $scope.currentIndex = uploadList.length - 1;
                    }
                    else {
                      $scope.currentIndex--;
                    }
                  }, 50);
                };

                $scope.isFirst = function(){
                  return $scope.currentIndex === 0;
                };

                $scope.isLast = function(){
                  return $scope.currentIndex === (uploadList.length - 1);
                };

                $scope.$watch(function() { return $scope.currentIndex; }, function(newValue) {
                  var newUpload = uploadList[newValue];
                  $scope.upload = newUpload;
                  $scope.uploadName = newUpload.name;
                  $scope.downloadUrl  = appConfig.apiUrl + '/attachment/'+(client._id||client)+'/'+newUpload._id;
                  $scope.scratchPad = scratchPad;
                  $scope.previewUrl = $scope.downloadUrl+'?preview=0';

                  if(newUpload.mimeType.indexOf('video') > -1) {
                    $scope.type='video';
                  }
                  else if(newUpload.mimeType.indexOf('audio') > -1)  {
                    $scope.type = 'audio';
                  }
                  else {
                    $scope.type = newUpload.mimeType;
                  }
                });

                $scope.buttonClick = function(buttonText) {
                    $window.location = $scope.downloadUrl;
                    modalWin.dismiss();
                };
              }]
            }, 'modal-info');

            modalWin.result.then(function (event) {
              finish.call(event, scratchPad);
            });
          };
        },

        listenToAudio: function(finish) {
          finish = finish || angular.noop;
          return function (task) {

            var listenAudioModal = openModal({
              controller: ['$scope', function ($scope) {
                $scope.playlist = taskAudioPlaylist(task);
              }],
              modal: {
                dismissable: true,
                title: 'Audio Recording',
                template: 'components/modal/survey/tpl/task-audio-player.html',
                buttons: [{
                  classes: 'btn-default',
                  text: 'Close',
                  click: function(e) {
                    listenAudioModal.close(e);
                  }
                }]
              }
            }, 'modal-success');

            listenAudioModal.result.then(function(event) {
              finish.call(this, event);
            });
          };
        },

        taskScore: function(listenAudioCb, transcribeAudioCb, assignCb, resolveCb) {
          return function (task) {

            openModal({
              controller: ['$scope', '$clientSwitcher', '$performTaskResolve', '$performTaskAssign', '$performTaskTranscribeAudio', '$performTaskAudio', 'TASK_SCHEMA_TYPE_NORMAL', 
              function ($scope, $clientSwitcher, $performTaskResolve, $performTaskAssign, $performTaskTranscribeAudio, $performTaskAudio, TASK_SCHEMA_TYPE_NORMAL) {

                $scope.task = task;
                $scope.taskStatus = task.status.charAt(0).toUpperCase() + task.status.substr(1);
                $scope.clientStrings = function(){ return $clientSwitcher.getFeatureOptions('client.strings'); };
                $scope.taskOpts = $clientSwitcher.getFeatureOptions('client.tasks', $clientSwitcher.getCurrentClient());

                $scope.listenTaskAudio = $performTaskAudio(function (result) {
                  if(angular.isFunction(listenAudioCb)) {
                    listenAudioCb.call($scope, result);
                  }
                });
                
                $scope.modalTranscribeTaskAudio = $performTaskTranscribeAudio(function(result, scratchPad, task) {
                  if(angular.isFunction(transcribeAudioCb)) {
                    transcribeAudioCb.call($scope, result, scratchPad, task);
                  }
                });

                $scope.performResolve;
                if($scope.task.taskSchemaType === TASK_SCHEMA_TYPE_NORMAL) {
                  $scope.performResolve = $performTaskResolve(function (result, record) {
                    if(angular.isFunction(resolveCb)) {
                      resolveCb.call($scope, result, record);
                    }
                  });
                }
                else {
                  $scope.performResolve = $performTaskResolve(function (result, record) {
                    if(angular.isFunction(resolveCb)) {
                      resolveCb.call($scope, result, record);
                    }
                  });
                }

                $scope.performAssign = $performTaskAssign(function (result, record) {
                  if(angular.isFunction(assignCb)) {
                    assignCb.call($scope, result, record);
                  }
                });

                $scope.isValidNumber = function(value) {
                  return value && !isNaN(+value); 
                };
              }],
              modal: {
                dismissable: true,
                title: 'Score Record',
                template: 'components/modal/survey/tpl/task-score.html',
              }
            }, task.taskData && task.taskData.data && task.taskData.data.failed ? 'modal-danger' : 'modal-success');
          };
        }
      },
      select: {
        taskCollection: function (finish, opts) {
          finish = finish || angular.noop;
          opts = opts || {};

          function objectId(o) {
            if(!o) return false;
            return o._id || o;
          }


          return function (task, title) {
            if(!task) return;

            var
            scratchPad = {
              collection: null,
              type:       null
            },
            modal = openModal({
              controller: ['$scope','$clientSwitcher','$clientTask', function ($scope, $clientSwitcher, $clientTask) {

                $scope.scratchPad = scratchPad;

                function findCollectionIndex(collection) {
                  var found = -1, id = objectId(collection);
                  if(!$scope.collections || !id) return found;
                  $scope.collections.every(function (col, index) {
                    if(objectId(col) === id) found = index;
                    return found === -1;
                  });
                  return found;
                }

                function findTypeIndex(collection, type) {
                  var found = -1;
                  if(!collection || !collection.types) return found;
                  collection.types.every(function(t, index) {
                    if(t === type) found = index;
                    return found === -1;
                  });
                  return found;
                }

                $scope.reloadCollections = function () {

                  delete $scope.collections;

                  return $clientTask.getCollections($clientSwitcher.getCurrentClientId(), true)
                    .then(function (collections) {
                      $scope.collections = collections;
                      return collections;
                    });
                };

                $scope.resetFromTask = function () {
                  if(!$scope.collections) return;
                  var
                  collection = $scope.collections[findCollectionIndex(task.taskCollection)],
                  typeIndex  = findTypeIndex(collection, task.taskType);

                  if(!collection) return;

                  scratchPad.collection = collection;
                  scratchPad.type       = collection.types[typeIndex];
                };

                // pull current value from task
                $scope.reloadCollections().then($scope.resetFromTask);
              }],
              modal: {
                dismissable: true,
                title: title||'Select Task Bucket',
                template: 'components/modal/survey/tpl/bucket-select.html',
                buttons: [{
                  classes: 'btn-danger',
                  text: 'Cancel',
                  click: function() {
                    modal.dismiss();
                  }
                }, {
                  classes: 'btn-primary',
                  text: 'Select',
                  click: function(e) {
                    $clientTask.updateCollection(objectId(task), objectId(scratchPad.collection), scratchPad.type).then(function () {
                      return modal.close(scratchPad);
                    })
                    .catch(function (err) {
                      scratchPad.updateErrors = err;
                      return err;
                    });
                  }
                }]
              }
            }, 'modal-success')

            modal.result.then(finish);
          };
        }
      },
      taskActions: {

        createTask: function (finish, title) {
          finish = finish || angular.noop;

          return function (client, bucket) {
            var
            scratchPad = {
              task: {
              }
            };

            if(bucket) {
              scratchPad.task.taskCollection = bucket.id;
            }

            var
            modal = openModal({
              controller: ['$scope', '$clientTask', '$q', function ($scope, $clientTask, $q) {
                $scope.newTask = angular.copy(scratchPad.task);
                $scope.isSubmitting = false;

                $scope.uploadFiles = function () {

                  var
                  task = $scope.newTask,
                  uploader = !!task ? task.uploader : false,
                  results = [],
                  errors = [];

                  if(uploader && uploader.queue && uploader.queue.length) {
                    var defer = $q.defer();

                    uploader.onSuccessItem = function(fileItem, response, status, headers) {
                      Array.prototype.push.apply(results, response);
                    };

                    uploader.onErrorItem = function(fileItem, response, status, headers) {
                      errors.push(new Error('Failed to upload file: ' + fileItem.file.name));
                    };

                    uploader.onCompleteAll = function() {
                      if(errors.length > 0) { // fail on any errors
                        defer.reject(errors);
                        return;
                      }

                      defer.resolve(results);
                    };

                    uploader.uploadAll();

                    return defer.promise;
                  }

                  return $q.when(false);
                };

                $scope.readTask = function () {
                  var task = $scope.newTask;
                  return Object.keys(task)
                    .filter(function (key) { // filter out certain keys
                      // return ['uploader'].indexOf(key) === -1;
                      return key !== 'uploader';
                    })
                    .reduce(function (p, key) {
                      p[key] = task[key];
                      return p;
                    }, {});
                };

                $scope.submitTask = function () {
                  $scope.formError = null;

                  if($scope.newTask.onExpireDue && $scope.newTask.due > $scope.newTask.onExpireDue){
                    $scope.message = "Please set the fallback due date and time ahead of the primary due date and time."
                    return;
                  }
                  $scope.message = "";
                  $scope.isSubmitting = true;

                  return $scope.uploadFiles()
                    .then(function (uploaded) {
                      if(uploaded && uploaded.length) { // add these to the model so when it creates the task, it will associate these items.
                        $scope.newTask.uploads = uploaded.map(function (upload) {
                          return upload._id;
                        });
                      }

                      return $clientTask.createTask(client, $scope.readTask()) // prevents uploader from going over the wire.
                        .then(function (result) {
                          scratchPad.task = $scope.newTask;
                          modal.close(scratchPad);
                          return result;
                        });
                    })
                    .catch(function (err) {
                      console.log('error:', err.stack || err);
                      $scope.formError = err;
                    })
                    .finally(function () {
                      $scope.isSubmitting = false;
                    });
                };
              }],
              modal: {
                dismissable: true,
                title: title||'Create Task',
                template: 'components/modal/survey/tpl/task-create.html'
              }
            }, 'modal-success');

            modal.result.then(function(event) {
              finish.call(event, scratchPad);
            });
          };
        },

        addAttachments: function(finish) {
          var finish = finish || angular.noop;

          return function (task) {
            if(!task) return;

            var
            callModal = openModal({

              controller: ['$scope', '$clientSwitcher', '$clientTask', '$authStorage', '$q', 'ngToast', function ($scope, $clientSwitcher, $clientTask, $authStorage, $q, ngToast) {

                $scope.currentClient = $clientSwitcher.getCurrentClient();

                task.uploader = new FileUploader({
                  url: appConfig.apiUrl + '/api/client-uploads/'+$scope.currentClient._id+'?access_token='+$authStorage.getToken(),
                  alias: 'upload',
                  filters: [
                    {
                      name: 'extensionFilter',
                      fn: function(item) {
                        var extension = item.name.substr(item.name.lastIndexOf('.')+1);
                        var invalidExtensions = ['exe', 'bin'];
                        if(invalidExtensions.indexOf(extension) > -1) {
                          ngToast.create({
                            className: 'danger',
                            content: 'Files with extensions '+invalidExtensions+' are not allowed'
                          });
                          return false;
                        } else {
                          return true;
                        }
                      }
                    }
                  ]
                });

                $scope.uploadFiles = function () {
                  var
                  uploader = !!task ? task.uploader : false,
                  results = [],
                  errors = [];

                  if(uploader && uploader.queue && uploader.queue.length) {
                    var defer = $q.defer();

                    uploader.onSuccessItem = function(fileItem, response, status, headers) {
                      Array.prototype.push.apply(results, response);
                    };

                    uploader.onErrorItem = function(fileItem, response, status, headers) {
                      errors.push(new Error('Failed to upload file: ' + fileItem.file.name));
                    };

                    uploader.onCompleteAll = function() {
                      if(errors.length > 0) { // fail on any errors
                        defer.reject(errors);
                        return;
                      }

                      defer.resolve(results);
                    };

                    uploader.uploadAll();

                    return defer.promise;
                  }
                };

                $scope.task = task;


                $scope.buttonClick = function(buttonText) {
                  return $scope.uploadFiles()
                  .then(function (uploaded) {
                    if(uploaded && uploaded.length) { // add these to the model so when it creates the task, it will associate these items.
                      task.uploads = uploaded.map(function (upload) {
                        return upload._id;
                      });

                      $clientTask.updateAttachments(_.omit(task, ['uploader', 'bucket']))
                      .then(function(result) {
                        callModal.close();
                        $rootScope.$broadcast('taskUpdated');
                        finish.call(this);
                      })
                      .catch(function(err) {
                        console.log(err);
                        //incomplete
                      });
                    }
                  });
                };
              }],

              modal: {
                dismissable: false,
                title: 'Add Attachments',
                template: 'components/modal/survey/tpl/add-attachments.html',

                buttons: [{
                  classes: 'btn-primary',
                  text: 'Add',
                },{
                  classes: 'btn-default',
                  text: 'Close',
                  click: function(e) {
                    callModal.close(e);
                  }
                }]
              }
            }, 'modal-success');
          };
        },
        transcribeTaskAudio: function(finish) {
          finish = finish || angular.noop;
          return function (task) {
            var isVerbatimValid = task.taskData.verbatim && task.taskData.verbatim.isValid;
            var theme = 'modal-';
            if(isVerbatimValid) {
              switch(task.taskData.verbatim.rating) {
                case 'positive': 
                  theme += 'success';
                  break;
                case 'negative': 
                  theme += 'danger';
                  break;
                case 'mixed': 
                  theme += 'warning';
                  break;
              }
            }
            else {
              theme += 'success';
            }

            var scratchPad = {};
            var transcribeAudioModal = openModal({
              controller: ['$scope', '$clientTask', 'ngToast', '$activeClientSettings', 'TASK_SCHEMA_TYPE_SURVEY', '$serveySentiment', function($scope, $clientTask, ngToast, $activeClientSettings, TASK_SCHEMA_TYPE_SURVEY, $serveySentiment) {
                //adjust according to task structure
                var client = $clientSwitcher.getCurrentClient();
                $activeClientSettings(function (client, settings) {
                  $scope.supportedTaskTypes = false;
                  if(settings.supportedTaskTypes && settings.supportedTaskTypes.indexOf(TASK_SCHEMA_TYPE_SURVEY) > -1){
                    $scope.supportedTaskTypes = true;
                  }
                });
                $scope.applyOptions = function (options) {
                  if(options && !options.hasOwnProperty('locations')) {
                    options.locations = [];
                  }
                  $scope.taskOpts = options.constrOpts;
                };
                $scope.applyOptions($clientSwitcher.getFeatureOptions('client.tasks', client));

                $scope.scratchPad = scratchPad;
                $scope.task = task;
                $scope.playlist = taskAudioPlaylist(task);
                $scope.isVerbatimValid = isVerbatimValid;
                $scope.transcriptionTextModel = $scope.isVerbatimValid ? $scope.task.taskData.verbatim.transcription : scratchPad.transcription;
                $scope.allValue = 'all';
                var diffPositiveSubtype;
                var diffNegativeSubtype;
                var positiveSentimentObject = {
                  subtype: []
                }
                var negativeSentimentObject = {
                  subtype: []
                }
                getAllSentiment().then(function (res) {
                  var positiveSubtype = [];
                  var negativeSubtype = [];
                  res.forEach(function (sentimentRec) {
                    if (task.taskData.verbatim.positiveSentiments) {
                      if (sentimentRec.name == 'Positive') {
                        positiveSentimentObject.name = sentimentRec.name;
                        positiveSentimentObject._id = sentimentRec._id;
                        $scope.positiveSentiments = sentimentRec.subtype.map(function (subTypeRec) {
                          positiveSentimentObject.subtype.push({ subtypeName: subTypeRec.subtypeName });
                          positiveSubtype.push(subTypeRec.subtypeName);
                          return {
                            value: subTypeRec.subtypeName,
                            label: subTypeRec.subtypeName
                          }
                        })
                        var positiveSentiments = task.taskData.verbatim.positiveSentiments.map(function (sentmnts) { return sentmnts.trim() });
                        diffPositiveSubtype = positiveSentiments.diff(positiveSubtype)
                      }
                    }
                    if (task.taskData.verbatim.negativeSentiments) {
                      if (sentimentRec.name == 'Negative') {
                        negativeSentimentObject.name = sentimentRec.name;
                        negativeSentimentObject._id = sentimentRec._id;
                        $scope.negativeSentiments = sentimentRec.subtype.map(function (subTypeRec) {
                          negativeSentimentObject.subtype.push({ subtypeName: subTypeRec.subtypeName });
                          negativeSubtype.push(subTypeRec.subtypeName);
                          return {
                            label: subTypeRec.subtypeName,
                            value: subTypeRec.subtypeName
                          }
                        })
                        var negativeSentiments = task.taskData.verbatim.negativeSentiments.map(function (sentmnts) { return sentmnts.trim() });
                        diffNegativeSubtype = negativeSentiments.diff(negativeSubtype)
                      }
                    }
                  })
                  if (diffPositiveSubtype && diffPositiveSubtype.length > 0) {
                    diffPositiveSubtype.forEach(function (sentimentName) {
                      positiveSentimentObject.subtype.push({ subtypeName: sentimentName });
                      $scope.positiveSentiments.push({
                        value: sentimentName,
                        label: sentimentName
                      })
                    })
                    updateSentiment(positiveSentimentObject)
                  }

                  if (diffNegativeSubtype && diffNegativeSubtype.length > 0) {
                    diffNegativeSubtype.forEach(function (sentimentName) {
                      negativeSentimentObject.subtype.push({ subtypeName: sentimentName });
                      $scope.negativeSentiments.push({
                        value: sentimentName,
                        label: sentimentName
                      })
                    })
                    updateSentiment(negativeSentimentObject)
                  }
                })
                .finally(function () {
                  $scope.loading = false;
                });

                if($scope.playlist && $scope.playlist.tracks && $scope.playlist.tracks.length) { // set to the first track automatically
                  $scope.playlist.tracksName = $scope.playlist.tracks.map(function(trackRecord) {
                    return {
                      label: trackRecord.title,
                      value: trackRecord
                    };
                  });
                  if($scope.playlist.tracksName.length > 1){
                    scratchPad.track = $scope.playlist.tracksName[0];
                  }else{
                    scratchPad.track = $scope.playlist.tracksName[0].value;
                  }
                }

                $scope.cancel = function() {
                  transcribeAudioModal.dismiss();
                };

                $scope.submit = function() {
                  if(task.taskData.verbatim.positiveSentiments === $scope.allValue) {
                    task.taskData.verbatim.positiveSentiments = $scope.positiveSentiments
                      .map(function(positiveSentiments) {
                        return positiveSentiments.value;
                      });
                  }

                  if(task.taskData.verbatim.negativeSentiments === $scope.allValue) {
                    task.taskData.verbatim.negativeSentiments = $scope.negativeSentiments
                      .map(function(negativeSentiments) {
                        return negativeSentiments.value;
                      });
                  }

                  $clientTask.updateTaskdata(task, task.taskData).then(function(res){
                    if(!res.status){
                      ngToast.create({
                        content: res.reason,
                        className: 'danger'
                      });
                    }else{
                      ngToast.create({
                        content: 'Update successfully',
                        className: 'success'
                      });
                    }
                    transcribeAudioModal.dismiss();
                  });
                }

                $scope.$watch(function() {return $scope.transcriptionTextModel; }, function(nv) {
                  if(isVerbatimValid) {
                    task.taskData.verbatim.transcription = nv;
                  }
                  else {
                    scratchPad.transcription = nv;
                  }
                });

                $scope.$watch('scratchPad.track', function (nV) {
                  if(nV) {
                    scratchPad.transcription = nV.transcription;
                    $scope.transcriptionTextModel = $scope.isVerbatimValid ? $scope.task.taskData.verbatim.transcription : scratchPad.transcription;
                  }
                });
              }],
              modal: {
                dismissable: true,
                title: 'Transcription',
                template: 'components/modal/survey/tpl/task-transcribe.html',
                // buttons: [
                //   {
                //     classes: 'btn-primary',
                //     text: 'Save',
                //     click: function(e) {
                //       transcribeAudioModal.close(e);
                //     }
                //   },
                //   {
                //     classes: 'btn-default',
                //     text: 'Close',
                //     click: function(e) {
                //       transcribeAudioModal.dismiss(e);
                //     }
                //   }
                // ]
              }
            }, theme);

            transcribeAudioModal.result.then(function(event) {
              finish.call(this, event, scratchPad, task);
            });
          };
        }
      },
      alertActions: {
        recordDetail: function (finish) {
          finish = finish || angular.noop;
          return function (record, bucket, lastClient) {
            if(!record) return;

            // console.log('record:', record);

            var
            callModal = openModal({

              controller: ['$scope', '$clientSwitcher', '$clientUser', function ($scope, $clientSwitcher, $clientUser) {
                $scope.clientStrings = function(){ return $clientSwitcher.getFeatureOptions('client.strings'); };
              }],

              modal: {
                dismissable: false,
                title: 'Record Details',
                template: 'components/modal/survey/tpl/record-focused.html',

                buttons: [{
                  classes: 'btn-default',
                  text: 'Close',
                  click: function(e) {
                    callModal.close(e);
                  }
                }]
              },
              record: record,
              bucket: bucket,
              client: lastClient
            }, 'modal-success');

            callModal.result.then(function () {
              finish.call(this, record);
            }, function () {
              finish.call(this, record);
            });
          };
        },
        callRecord: function (finish) {
          finish = finish || angular.noop;
          return function (record) {
            if(!record) return;

            var
            userName = record.raw.customer.name,
            userPhoneNumber = record.raw.customer.telephone,
            callModal = openModal({
              modal: {
                dismissable: true,
                title: 'Call Record: ' + userName,
                html: '<div class="text-center"><img src="assets/images/telephone_ani.gif" /></div><p class="text-center">Calling '+userPhoneNumber+', please wait..</p>',
                buttons: [{
                  classes: 'btn-default',
                  text: 'Close',
                  click: function(e) {
                    callModal.dismiss(e);
                  }
                }]
              }
            }, 'modal-success');

            callModal.result.then(function(event) {
              finish.apply(event, args);
            });
          };
        },
        listenToAudio: function (finish) {
          finish = finish || angular.noop;

          return function (record) {
            if(!record) return;

            var
            audioListenModal = openModal({
              controller: ['$scope', function ($scope) {
                $scope.playlist = recordPlaylist(record);
              }],
              modal: {
                dismissable: true,
                title: 'Audio Recording: ' + record.raw.customer.name,
                template: 'components/modal/survey/tpl/audio-player.html',
                buttons: [{
                  classes: 'btn-default',
                  text: 'Close',
                  click: function(e) {
                    audioListenModal.dismiss(e);
                  }
                }]
              }
            }, 'modal-success');

            audioListenModal.result.then(function(event) {
              finish.apply(event, args);
            });
          };
        },
        scoreRecord: function (finish) {
          finish = finish || angular.noop;
          return function (record, bucket, client) {
            if(!record) return;

            var
            scratchPad = {
            },
            scoreModal = openModal({
              controller: ['$scope', '$clientSwitcher', function ($scope, $clientSwitcher) {
                $scope.entryQuestionnaire = record.raw.data;
                $scope.entryRecord        = record.raw;
                $scope.surveyQA           = record.raw.data.questions;

                $scope.clientStrings = function(){ return $clientSwitcher.getFeatureOptions('client.strings'); };

                function shutdownDlg() {
                  scoreModal.close(scratchPad);
                }

                $scope.listenToAudio = function () {
                  scratchPad.openAudio = true;
                  shutdownDlg();
                };
                $scope.recordForward = function () {
                  scratchPad.assignRecord = true;
                  shutdownDlg();
                };
                $scope.recordResolve = function () {
                  scratchPad.resolveRecord = true;
                  shutdownDlg();
                };
              }],
              modal: {
                dismissable: true,
                title: 'Score Record',
                template: 'components/modal/survey/tpl/record-score.html'
              },
              record: record,
              bucket: bucket,
              client: client
            }, 'modal-success');

            scoreModal.result.then(function(event) {
              finish.call(event, scratchPad, record);
            });
          };
        },
        transcribeAudio: function (finish) {
          finish = finish || angular.noop;
          return function (record) {
            if(!record) return;

            var
            scratchPad = {},
            modal = openModal({
              user: $auth.getCurrentUser(),
              controller: ['$scope', function ($scope) {
                $scope.scratchPad = scratchPad;
                $scope.playlist = recordPlaylist(record);

                if($scope.playlist.length > 0) { // set to the first track automatically
                  scratchPad.track = $scope.playlist.tracks[0];
                }

                $scope.$watch('scratchPad.track', function (nV) {
                  scratchPad.transcription = nV.transcription;
                });
              }],
              modal: {
                dismissable: true,
                title: 'Transcribe Audio: ' + record.raw.customer.name,
                template: 'components/modal/survey/tpl/transcribe.html',
                buttons: [{
                  classes: 'btn-primary',
                  text: 'Save',
                  click: function(e) {
                    modal.close(e);
                  }
                }, {
                  classes: 'btn-default',
                  text: 'Cancel',
                  click: function(e) {
                    modal.dismiss(e);
                  }
                }]
              }
            }, 'modal-success');

            modal.result.then(function(event) {
              finish.call(event, scratchPad, record);
            });
          };
        },
        forwardRecord: function (finish) {
          finish = finish || angular.noop;
          return function (record) {
            if(!record) return;
            var
            scratchPad,
            forwardModal = openModal({
              user: $auth.getCurrentUser(),
              controller: ['$scope', '$clientSwitcher', '$clientUser', function ($scope, $clientSwitcher, $clientUser) {
                $scope.entryRecord = record.raw;
                $scope.currentUser = $auth.getCurrentUser();
                $scope.forward = scratchPad = {
                  comment: '',
                  originalRecord: record.raw
                };
                $scope.clientStrings = function(){ return $clientSwitcher.getFeatureOptions('client.strings'); };

                ($scope.reloadChainOfCommand = function reloadChainOfCommand() {
                  return $clientUser.getUserChainOfCommand(record.raw.client)
                    .then(function (forwardables) {

                      var
                      hasEscalates   = (!!forwardables.escalates && !!forwardables.escalates.length),
                      hasDelegates   = (!!forwardables.delegates && !!forwardables.delegates.length),
                      labelNotSet    = '-- Not Set --',
                      labelSelectOne = '(select one)';

                      if(hasEscalates) {
                        forwardables.escalates.push({
                          _id: undefined,
                          name: !!hasDelegates ? labelNotSet : labelSelectOne
                        });
                      }
                      if(hasDelegates) {
                        forwardables.delegates.push({
                          _id: undefined,
                          name: !!hasEscalates ? labelNotSet : labelSelectOne
                        });
                      }

                      $scope.forwardables = forwardables;
                      return forwardables;
                    })
                    .catch(function (err) {
                      console.log('forwardable error:', err);
                      return err;
                    });
                })();
              }],
              modal: {
                dismissable: true,
                title: 'Assign Record',
                template: 'components/modal/survey/tpl/record-forward.html',
                buttons: [{
                  classes: 'btn-primary',
                  text: 'Send',
                  click: function(e) {
                    forwardModal.close(e);
                  }
                }, {
                  classes: 'btn-default',
                  text: 'Cancel',
                  click: function(e) {
                    forwardModal.dismiss(e);
                  }
                }]
              }
            }, 'modal-success');

            forwardModal.result.then(function(event) {
              delete scratchPad.originalRecord;
              finish.call(event, scratchPad, record.raw);
            });
          };
        },
        removeTask: function(finish, title){
          finish = finish || angular.noop;
          return function (record) {
            if(!record) return;
            var modal = openModal({
              task: record,
              modal: {
                dismissable: true,
                title: title||'Remove Task',
                template: 'components/modal/survey/tpl/record-remove.html',
                buttons: [{
                  classes: 'btn-default',
                  text: 'Leave as is',
                  click: function(e) {
                    modal.dismiss(e);
                  }
                },{
                  classes: 'btn-danger',
                  text: 'Remove',
                  click: function(e) {
                    $clientTask.removeTask(record).then(function(){
                      modal.close(e);
                    });                    
                  }
                }]
              }
            }, 'modal-success');

            modal.result.then(function(event) {
              finish.call(event, record);
            });
          };
        },
        reopenTask: function(finish, title){
          finish = finish || angular.noop;

          return function (task) {
            if(!task) return;

            function pushScratchPad(sp, to) {
              if(!sp || !to) {
                return false;
              }

              var
              assignTo      = sp.assignTo,
              assignToEmail = sp.assignToEmail,
              due           = sp.due,
              comment       = sp.comment;

              if(sp.assignedToMe) {
                assignTo = $auth.getCurrentUser()._id;
              }
              else if(sp.delegateTo) {
                assignTo = sp.delegateTo;
              }
              else if(sp.escalateTo) {
                assignTo = sp.escalateTo;
              }

              to.assignTo      = assignTo;
              to.assignToEmail = assignToEmail;
              to.due           = due;
              to.comment       = comment;

              return to;
            }

            var
            scratchPad = {
              priority: task.priority,
              due: !!task.due ? new Date(task.due) : new Date(),
              assignTo: (!!task.assignTo ? task.assignTo._id : null),
              assignToEmail: (!!task.assignToEmail ? task.assignToEmail : null),
              assignToOnExpire: (!!task.assignToOnExpire ? task.assignToOnExpire._id : null),
              assignToEmailOnExpire: (!!task.assignToEmailOnExpire ? task.assignToEmailOnExpire : null),
              onExpireDue: !!task.onExpireDue ? new Date(task.onExpireDue) : new Date(),
              onExpirePriority: (!!task.onExpirePriority ? task.onExpirePriority : null)
            },
            assignResult,
            assignModal = openModal({
              controller: ['$scope', '$clientSwitcher', '$clientUser','$clientTask', '$q', '$clientExternalUsers', 'ngToast', function ($scope, $clientSwitcher, $clientUser, $clientTask, $q, $clientExternalUsers, ngToast) {
                $scope.currentUser = $auth.getCurrentUser();
                $scope.currentClient = $clientSwitcher.getCurrentClient();
                $scope.scratchPad = scratchPad;
                $scope.entryTask = task;

                $scope.slider = {
                  max: 1435,
                  dueValueInMin: 0,
                  fallbackDueValueInMin: 0
                };

                $scope.$watch(function() { return $scope.scratchPad.due; }, function(nv) {
                  if($scope.scratchPad.due) {
                    $scope.slider.dueValueInMin = $scope.scratchPad.due.getHours()*60 + $scope.scratchPad.due.getMinutes();
                  }
                });

                $scope.$watch(function() { return $scope.slider.dueValueInMin; }, function(nv) {
                  if($scope.scratchPad.due) {
                    $scope.scratchPad.due = new Date($scope.scratchPad.due.setHours($scope.slider.dueValueInMin / 60, $scope.slider.dueValueInMin % 60));
                  }
                });

                //create relation between fallbackDueValueInMin and model.onExpireDue
                $scope.$watch(function() { return $scope.scratchPad.onExpireDue; }, function(nv) {
                  if($scope.scratchPad.onExpireDue) {
                    $scope.slider.fallbackDueValueInMin = $scope.scratchPad.onExpireDue.getHours()*60 + $scope.scratchPad.onExpireDue.getMinutes();
                  }
                });

                $scope.$watch(function() { return $scope.slider.fallbackDueValueInMin; }, function(nv) {
                  if($scope.scratchPad.onExpireDue) {
                    $scope.scratchPad.onExpireDue = new Date($scope.scratchPad.onExpireDue.setHours($scope.slider.fallbackDueValueInMin / 60, $scope.slider.fallbackDueValueInMin % 60));
                  }
                });

                $scope.taskDateFormat = 'MM/dd/yyyy';
                $scope.taskDateStepH  = 1;
                $scope.taskDateStepM  = 1;
                $scope.taskDateAMPM   = true;
                $scope.nowDate = function () {
                  var
                  now = Date.now(),
                  nextMinute = now % 60000;
                  return now - nextMinute;
                };
                $scope.taskDateDisabled = function (date, mode) {
                  var now = Date.now(), offset = (now % 8.64e7);
                  return date <= (now - offset);
                };

                $scope.buttonClick = function(buttonText) {
                  $scope.message = '';

                  if(!scratchPad.due) {
                    $scope.message = 'Please set the Due date.';
                    return;
                  }

                  // Fallback Due has to be greater than Primary Due
                  if(scratchPad.onExpireDue && scratchPad.due > scratchPad.onExpireDue){
                    $scope.message = 'Please set the fallback due date and time ahead of the primary due date and time.';
                    return;
                  }

                  return $scope.uploadFiles()
                    .then(function (uploaded) {
                      if(uploaded && uploaded.length) { // add these to the model so when it creates the task, it will associate these items.
                        task.uploads = uploaded.map(function (upload) {
                          return upload._id;
                        });
                      }

                      // omit is done as task contains bucket.records which is again the task itself therefore making it a circular structure
                      return $clientTask.reopenTask(
                        _.omit(task, ['bucket']),
                        scratchPad.priority,
                        scratchPad.assignTo,
                        scratchPad.assignToEmail,
                        scratchPad.due,
                        scratchPad.onExpirePriority,
                        scratchPad.assignToOnExpire,
                        scratchPad.assignToEmailOnExpire,
                        scratchPad.onExpireDue,
                        scratchPad.comment
                      ) // prevents uploader from going over the wire.
                      .then(function (result) {
                        assignModal.close();
                        $rootScope.$broadcast('taskAssigned');
                        assignResult = result;
                      })
                      .catch(function(err) {
                        assignResult = err;
                      });
                    })
                    .catch(function (err) {
                      console.log('error:', err.stack || err);
                      $scope.formError = err;
                    })
                    .finally(function () {
                      $scope.isSubmitting = false;
                    });
                };

                $scope.uploader = new FileUploader({
                  url: appConfig.apiUrl + '/api/client-uploads/'+$scope.currentClient._id+'?access_token='+$authStorage.getToken(),
                  alias: 'upload',
                  filters: [
                    {
                      name: 'extensionFilter',
                      fn: function(item) {
                        var extension = item.name.substr(item.name.lastIndexOf('.')+1);
                        var invalidExtensions = ['exe', 'bin'];
                        if(invalidExtensions.indexOf(extension) > -1) {
                          ngToast.create({
                            className: 'danger',
                            content: 'Files with extensions '+invalidExtensions+' are not allowed'
                          });
                          return false;
                        } else {
                          return true;
                        }
                      }
                    }
                  ]
                });

                $scope.allPriorities = [
                  ['high',     'High'],
                  ['med-high', 'Medium High'],
                  ['med-low',  'Medium Low'],
                  ['low',      'Low']
                ];

                this.uploadFiles = function () {

                  var
                  task = $scope.entryTask,
                  uploader = !!task ? $scope.uploader : false,
                  results  = [],
                  errors   = [];

                  if(uploader && uploader.queue && uploader.queue.length) {
                    var defer = $q.defer();

                    uploader.onSuccessItem = function(fileItem, response, status, headers) {
                      Array.prototype.push.apply(results, response);
                    };

                    uploader.onErrorItem = function(fileItem, response, status, headers) {
                      errors.push(new Error('Failed to upload file: ' + fileItem.file.name));
                    };

                    uploader.onCompleteAll = function() {
                      if(errors.length > 0) { // fail on any errors
                        defer.reject(errors);
                        return;
                      }

                      defer.resolve(results);
                    };

                    uploader.uploadAll();

                    return defer.promise;
                  }

                  return $q.when(false);
                };

                $scope.uploadFiles = this.uploadFiles;

                ($scope.reloadAvailableUsers = function (store, bucket) {
                  delete $scope.assignableUsers;
                  delete $scope.externalUsers;

                  return $clientExternalUsers.list($scope.currentClient).then(function(data){
                    if(data.data){
                      $scope.externalUsers = _.map(data.data, 'email');
                    }

                    $clientUser.getUserChainOfCommand($scope.currentClient, store, bucket)
                    .then(function (chainOfCommand) {
                      $scope.assignableUsers = chainOfCommand;
                      return $scope.assignableUsers;
                    })
                  });                  
                })(task.location, task.taskCollection.id);

                $scope.clientStrings = function(){
                  return $clientSwitcher.getFeatureOptions('client.strings');
                };

                $scope.taskOptions = function () {
                  return $clientSwitcher.getFeatureOptions('client.tasks');
                };

                $scope.$watch(function () {
                  var model = $scope.scratchPad;

                  if(!model.assignTo && !model.assignToEmail) {
                    return true;
                  }

                  return false;
                }, function (assignEmpty) {
                  if(assignEmpty) {
                    delete $scope.scratchPad.assignToOnExpire;
                    delete $scope.scratchPad.assignToEmailOnExpire;
                  }
                });

                $scope.$watch(function () {
                  var model = $scope.scratchPad;

                  if(!model.assignToOnExpire && !model.assignToEmailOnExpire) {
                    return true;
                  }

                  return false;
                }, function (fallbackEmpty) {
                  if(fallbackEmpty) {
                    delete $scope.scratchPad.onExpireDue;
                    delete $scope.scratchPad.onExpirePriority;
                  }
                });
              }],
              modal: {
                dismissable: true,
                title: title||'Reopen Task',
                template: 'components/modal/survey/tpl/record-reopen.html',
                buttons: [{
                  classes: 'btn-primary',
                  text: 'Reopen',
                }, {
                  classes: 'btn-default',
                  text: 'Cancel',
                  click: function(e) {
                    assignModal.dismiss(e);
                  }
                }]
              }
            }, 'modal-success');

            assignModal.result.then(function(event) {
              finish.call(event, scratchPad, task, assignResult);
            });
          };
        },
        assignTask: function (finish, title) {

          finish = finish || angular.noop;

          return function (task) {
            if(!task) return;

            function pushScratchPad(sp, to) {
              if(!sp || !to) {
                return false;
              }

              var
              assignTo      = sp.assignTo,
              assignToEmail = sp.assignToEmail,
              due           = sp.due,
              comment       = sp.comment;

              if(sp.assignedToMe) {
                assignTo = $auth.getCurrentUser()._id;
              }
              else if(sp.delegateTo) {
                assignTo = sp.delegateTo;
              }
              else if(sp.escalateTo) {
                assignTo = sp.escalateTo;
              }

              to.assignTo      = assignTo;
              to.assignToEmail = assignToEmail;
              to.due           = due;
              to.comment       = comment;

              return to;
            }

            var
            scratchPad = {
              priority: task.priority,
              due: !!task.due ? new Date(task.due) : null,
              assignTo: (!!task.assignTo ? task.assignTo._id : null),
              assignToEmail: (!!task.assignToEmail ? task.assignToEmail : null),
              assignToOnExpire: (!!task.assignToOnExpire ? task.assignToOnExpire._id : null),
              assignToEmailOnExpire: (!!task.assignToEmailOnExpire ? task.assignToEmailOnExpire : null),
              onExpireDue: !!task.onExpireDue ? new Date(task.onExpireDue) : new Date(),
              onExpirePriority: (!!task.onExpirePriority ? task.onExpirePriority : null)
            },
            assignResult,
            assignModal = openModal({
              controller: ['$scope', '$clientSwitcher', '$clientUser','$clientTask', '$q', '$clientExternalUsers', 'ngToast', function ($scope, $clientSwitcher, $clientUser, $clientTask, $q, $clientExternalUsers, ngToast) {
                $scope.currentUser = $auth.getCurrentUser();
                $scope.currentClient = $clientSwitcher.getCurrentClient();
                $scope.scratchPad = scratchPad;
                $scope.entryTask = task;

                $scope.slider = {
                  max: 1435,
                  dueValueInMin: 0,
                  fallbackDueValueInMin: 0
                };

                $scope.$watch(function() { return $scope.scratchPad.due; }, function(nv) {
                  if($scope.scratchPad.due) {
                    $scope.slider.dueValueInMin = $scope.scratchPad.due.getHours()*60 + $scope.scratchPad.due.getMinutes();
                  }
                });

                $scope.$watch(function() { return $scope.slider.dueValueInMin; }, function(nv) {
                  if($scope.scratchPad.due) {
                    $scope.scratchPad.due = new Date($scope.scratchPad.due.setHours($scope.slider.dueValueInMin / 60, $scope.slider.dueValueInMin % 60));
                  }
                });

                //create relation between fallbackDueValueInMin and model.onExpireDue
                $scope.$watch(function() { return $scope.scratchPad.onExpireDue; }, function(nv) {
                  if($scope.scratchPad.onExpireDue) {
                    $scope.slider.fallbackDueValueInMin = $scope.scratchPad.onExpireDue.getHours()*60 + $scope.scratchPad.onExpireDue.getMinutes();
                  }
                });

                $scope.$watch(function() { return $scope.slider.fallbackDueValueInMin; }, function(nv) {
                  if($scope.scratchPad.onExpireDue) {
                    $scope.scratchPad.onExpireDue = new Date($scope.scratchPad.onExpireDue.setHours($scope.slider.fallbackDueValueInMin / 60, $scope.slider.fallbackDueValueInMin % 60));
                  }
                });

                $scope.taskDateFormat = 'MM/dd/yyyy';
                $scope.taskDateStepH  = 1;
                $scope.taskDateStepM  = 1;
                $scope.taskDateAMPM   = true;
                $scope.nowDate = function () {
                  var
                  now = Date.now(),
                  nextMinute = now % 60000;
                  return now - nextMinute;
                };
                $scope.taskDateDisabled = function (date, mode) {
                  var now = Date.now(), offset = (now % 8.64e7);
                  return date <= (now - offset);
                };

                $scope.buttonClick = function(buttonText) {
                  $scope.message = '';

                  if(!scratchPad.due) {
                    $scope.message = 'Please set the Due date.';
                    return;
                  }

                  // Fallback Due has to be greater than Primary Due
                  if(scratchPad.onExpireDue && scratchPad.due > scratchPad.onExpireDue){
                    $scope.message = 'Please set the fallback due date and time ahead of the primary due date and time.';
                    return;
                  }

                  return $scope.uploadFiles()
                    .then(function (uploaded) {
                      if(uploaded && uploaded.length) { // add these to the model so when it creates the task, it will associate these items.
                        task.uploads = uploaded.map(function (upload) {
                          return upload._id;
                        });
                      }

                      // omit is done as task contains bucket.records which is again the task itself therefore making it a circular structure
                      return $clientTask.assignTask(
                        _.omit(task, ['bucket']),
                        scratchPad.priority,
                        scratchPad.assignTo,
                        scratchPad.assignToEmail,
                        scratchPad.due,
                        scratchPad.onExpirePriority,
                        scratchPad.assignToOnExpire,
                        scratchPad.assignToEmailOnExpire,
                        scratchPad.onExpireDue,
                        scratchPad.comment
                      ) // prevents uploader from going over the wire.
                      .then(function (result) {
                        assignModal.close();
                        $rootScope.$broadcast('taskAssigned');
                        assignResult = result;
                      })
                      .catch(function(err) {
                        assignResult = err;
                      });
                    })
                    .catch(function (err) {
                      console.log('error:', err.stack || err);
                      $scope.formError = err;
                    })
                    .finally(function () {
                      $scope.isSubmitting = false;
                    });
                };

                $scope.uploader = new FileUploader({
                  url: appConfig.apiUrl + '/api/client-uploads/'+$scope.currentClient._id+'?access_token='+$authStorage.getToken(),
                  alias: 'upload',
                  filters: [
                    {
                      name: 'extensionFilter',
                      fn: function(item) {
                        var extension = item.name.substr(item.name.lastIndexOf('.')+1);
                        var invalidExtensions = ['exe', 'bin'];
                        if(invalidExtensions.indexOf(extension) > -1) {
                          ngToast.create({
                            className: 'danger',
                            content: 'Files with extensions '+invalidExtensions+' are not allowed'
                          });
                          return false;
                        } else {
                          return true;
                        }
                      }
                    }
                  ]
                });

                $scope.allPriorities = [
                  ['high',     'High'],
                  ['med-high', 'Medium High'],
                  ['med-low',  'Medium Low'],
                  ['low',      'Low']
                ];

                this.uploadFiles = function () {

                  var
                  task = $scope.entryTask,
                  uploader = !!task ? $scope.uploader : false,
                  results  = [],
                  errors   = [];

                  if(uploader && uploader.queue && uploader.queue.length) {
                    var defer = $q.defer();

                    uploader.onSuccessItem = function(fileItem, response, status, headers) {
                      Array.prototype.push.apply(results, response);
                    };

                    uploader.onErrorItem = function(fileItem, response, status, headers) {
                      errors.push(new Error('Failed to upload file: ' + fileItem.file.name));
                    };

                    uploader.onCompleteAll = function() {
                      if(errors.length > 0) { // fail on any errors
                        defer.reject(errors);
                        return;
                      }

                      defer.resolve(results);
                    };

                    uploader.uploadAll();

                    return defer.promise;
                  }

                  return $q.when(false);
                };

                $scope.uploadFiles = this.uploadFiles;

                ($scope.reloadAvailableUsers = function (store, bucket) {
                  delete $scope.assignableUsers;
                  delete $scope.externalUsers;

                  return $clientExternalUsers.list($scope.currentClient).then(function(data){
                    if(data.data){
                      $scope.externalUsers = _.map(data.data, 'email');
                    }

                    $clientUser.getUserChainOfCommand($scope.currentClient, store, bucket)
                    .then(function (chainOfCommand) {
                      $scope.assignableUsers = chainOfCommand;
                      return $scope.assignableUsers;
                    })
                  });                  
                })(task.location, task.taskCollection.id);

                $scope.clientStrings = function(){
                  return $clientSwitcher.getFeatureOptions('client.strings');
                };

                $scope.taskOptions = function () {
                  return $clientSwitcher.getFeatureOptions('client.tasks');
                };

                $scope.$watch(function () {
                  var model = $scope.scratchPad;

                  if(!model.assignTo && !model.assignToEmail) {
                    return true;
                  }

                  return false;
                }, function (assignEmpty) {
                  if(assignEmpty) {
                    delete $scope.scratchPad.assignToOnExpire;
                    delete $scope.scratchPad.assignToEmailOnExpire;
                  }
                });

                $scope.$watch(function () {
                  var model = $scope.scratchPad;

                  if(!model.assignToOnExpire && !model.assignToEmailOnExpire) {
                    return true;
                  }

                  return false;
                }, function (fallbackEmpty) {
                  if(fallbackEmpty) {
                    delete $scope.scratchPad.onExpireDue;
                    delete $scope.scratchPad.onExpirePriority;
                  }
                });
              }],
              modal: {
                dismissable: true,
                title: title||'Assign Task',
                template: 'components/modal/survey/tpl/record-assign.html',
                buttons: [{
                  classes: 'btn-primary',
                  text: 'Assign',
                }, {
                  classes: 'btn-default',
                  text: 'Cancel',
                  click: function(e) {
                    assignModal.dismiss(e);
                  }
                }]
              }
            }, 'modal-success');

            assignModal.result.then(function(event) {
              finish.call(event, scratchPad, task, assignResult);
            });
          };
        },
        resolveRecord: function (finish, title) {
          finish = finish || angular.noop;
          return function (record) {
            if(!record) return;

            var
            scratchPad = {
              date: new Date,
              message: null,
              resolveCode: '',
              previousNotes: record.resolve.notes
            },
            resolveResult,
            callModal = openModal({
              controller: ['$scope', '$clientSwitcher', '$clientUser','$clientTask', '$q', 'ngToast', '$clientResolveCodes', '$activeClientSettings', 'TASK_STATE_PAUSED', 'TASK_STATE_STARTED',
              function ($scope, $clientSwitcher, $clientUser, $clientTask, $q, ngToast, $clientResolveCodes, $activeClientSettings, TASK_STATE_PAUSED, TASK_STATE_STARTED) {
                $scope.currentUser = $auth.getCurrentUser();
                $scope.currentClient = $clientSwitcher.getCurrentClient();
                $scope.entryTask = record;
                var DEFAULT_CLIENT_CRITERIA = {
                  startBeforeResolve: false,
                  minStartTime: 5
                };
                var clientResolveCriteria;

                $scope.uploader = new FileUploader({
                  url: appConfig.apiUrl + '/api/client-uploads/'+$scope.currentClient._id+'?access_token='+$authStorage.getToken(),
                  alias: 'upload',
                  filters: [
                    {
                      name: 'extensionFilter',
                      fn: function(item) {
                        var extension = item.name.substr(item.name.lastIndexOf('.')+1);
                        var invalidExtensions = ['exe', 'bin'];
                        if(invalidExtensions.indexOf(extension) > -1) {
                          ngToast.create({
                            className: 'danger',
                            content: 'Files with extensions '+invalidExtensions+' are not allowed'
                          });
                          return false;
                        } else {
                          return true;
                        }
                      }
                    }
                  ]
                });

                $scope.resolveCodes = [];
                $clientResolveCodes.getResolveCodes($scope.currentClient).then(function(response) {
                  $scope.resolveCodes = response;
                });

                this.uploadFiles = function () {

                  var
                  task = $scope.entryTask,
                  uploader = !!task ? $scope.uploader : false,
                  results = [],
                  errors = [];

                  if(uploader && uploader.queue && uploader.queue.length) {
                    var defer = $q.defer();

                    uploader.onSuccessItem = function(fileItem, response, status, headers) {
                      Array.prototype.push.apply(results, response);
                    };

                    uploader.onErrorItem = function(fileItem, response, status, headers) {
                      errors.push(new Error('Failed to upload file: ' + fileItem.file.name));
                    };

                    uploader.onCompleteAll = function() {
                      if(errors.length > 0) { // fail on any errors
                        defer.reject(errors);
                        return;
                      }

                      defer.resolve(results);
                    };

                    uploader.uploadAll();

                    return defer.promise;
                  }

                  return $q.when(false);
                };

                $activeClientSettings(function(client, clientSettings) {
                  clientResolveCriteria = clientSettings.resolveCriteria || DEFAULT_CLIENT_CRITERIA;
                });

                $scope.uploadFiles = this.uploadFiles;

                var getTotalTimeStartedMS = function() {
                  var totalTimeStartedMS = 0;
                  
                  if(record.stateActivity && record.stateActivity.length) {
                    for (var i=0; i<record.stateActivity.length-1; i++) {
                      if(record.stateActivity[i].state === TASK_STATE_STARTED && record.stateActivity[i+1].state === TASK_STATE_PAUSED) {
                        totalTimeStartedMS += moment(record.stateActivity[i+1].date).diff(moment(record.stateActivity[i].date));
                        i++;
                      }
                    }

                    // if task is currently in 'started' state then add that time in totalTimeStartedMS
                    if(record.stateActivity[record.stateActivity.length - 1].state === TASK_STATE_STARTED) {
                      var lastStartedAt = new Date(record.stateActivity[record.stateActivity.length - 1].date);
                      totalTimeStartedMS += Date.now() - lastStartedAt.getTime();
                    }
                  }
                  
                  return totalTimeStartedMS;
                };

                $scope.buttonClick = function(buttontext) {
                  var markClosed = false;
                  var leaveOpen = false;

                  if(buttontext === 'Fully Completed') {
                    markClosed = true;
                  } else if(buttontext === 'Complete Note'){
                    leaveOpen = true;
                  }

                  if(markClosed && clientResolveCriteria.startBeforeResolve && record.status !== 'pending') {
                    if(!record.stateActivity.length) {
                      return ngToast.create({
                        className: 'danger',
                        content: 'You are required to start the task before you can complete it. Please press the start button to start the task'
                      });
                    }

                    if(clientResolveCriteria.minStartTime) {
                      var totalTimeStartedMS = getTotalTimeStartedMS();

                      if(totalTimeStartedMS < clientResolveCriteria.minStartTime*60000) {
                        var difference = clientResolveCriteria.minStartTime*60000 - totalTimeStartedMS;
                        difference /= 1000;

                        var remainingTime = {
                          getString: function() {
                            var min = this.minutes < 10 ? '0'+this.minutes : this.minutes;
                            var sec = this.seconds < 10 ? '0'+this.seconds : this.seconds;
                            return min+':'+sec;
                          }
                        };
                        remainingTime.minutes = Math.floor(difference/60);
                        remainingTime.seconds = Math.floor(difference%60);

                        return ngToast.create({
                          className: 'danger',
                          content: 'The task has not reached the minimum working time to be completed. There\'s still '+remainingTime.getString()+' amount of time remaining before the task can be completed'
                        });
                      } 
                    }
                  }


                  return $scope.uploadFiles()
                    .then(function (uploaded) {
                      if(uploaded && uploaded.length) { // add these to the model so when it creates the task, it will associate these items.
                        record.uploads = uploaded.map(function (upload) {
                          return upload._id;
                        });
                      }

                      //omit is done as task contains bucket.records which is again the task itself therefore making it a circular structure
                      return $clientTask.resolveTask(_.omit(record, ['bucket']), scratchPad.message, markClosed, leaveOpen, scratchPad.resolveCode) // prevents uploader from going over the wire.
                        .then(function (result) {
                          callModal.close();
                          $rootScope.$broadcast('taskResolved');
                          resolveResult = result;
                        })
                        .catch(function(err) {
                          resolveResult = err;
                        });
                    })
                    .catch(function (err) {
                      console.log('error:', err.stack || err);
                      $scope.formError = err;
                    })
                    .finally(function () {
                      $scope.isSubmitting = false;
                    });
                };
              }],
              modal: {
                dismissable: true,
                title: 'Complete Record',
                template: 'components/modal/survey/tpl/record-resolve.html',
                buttons: [{
                  classes: 'btn-danger',
                  text: 'Close',
                  click: function(e) {
                    callModal.dismiss(e);
                  }
                },{
                  classes: 'btn-primary',
                  text: 'Complete Note'
                },{
                  classes: 'btn-success',
                  text: 'Fully Completed'
                }]
              },
              user: $auth.getCurrentUser(),
              resolve: scratchPad
            }, 'modal-success');

            callModal.result.then(function(event) {
              finish.call(event, scratchPad, record, resolveResult);
            });
          };
        },
        viewResolveHistory: function (finish) {
          finish = finish || angular.noop;
          return function (record) {
            if(!record) return;
            var
            scratchPad = {
              date: new Date,
              message: null,
              previousNotes: record.resolve.notes
            },
            callModal = openModal({
              modal: {
                dismissable: true,
                title: 'Resolution History',
                template: 'components/modal/survey/tpl/record-resolved-history.html',
                buttons: []
              },
              resolve: scratchPad
            }, 'modal-success');

            callModal.result.then(function(event) {
              finish.call(event, scratchPad, record);
            });
          };
        },
        viewTaskHistory: function (finish) {
          finish = finish || angular.noop;
          return function (record) {
            if(!record) return;
            var
            scratchPad = {
              date: new Date,
              message: null,
              history: [],
              loading: true,
              task: record
            },
            callModal = openModal({
              controller: ['$scope', '$q', 'ngToast', '$taskActionsLogsManager', '$clientSwitcher', function ($scope, $q, ngToast, $taskActionsLogsManager, $clientSwitcher) {
                var clientId = $clientSwitcher.getCurrentClient()._id;
                var taskId = $scope.resolve.task.id;
                
                var colors = ['#efefef', '#eaf3f9', '#f4eaf9', '#eaf9f9', '#fff'];
                var historyColor = {'': '#fff0f0'};
                $scope.clientStrings = function(){ return $clientSwitcher.getFeatureOptions('client.strings'); };
                $taskActionsLogsManager.getTaskLog(clientId, taskId).then(function(data){
                  $scope.resolve.history = data;
                  $scope.resolve.loading = false;
                });

                $scope.getActionLog = function(state){
                  var str = undefined;
                  if(state.action == 'partially-resolved' || state.action == 'resolve-note'){
                    str = $scope.clientStrings().resolvePCase + ' Note';                    
                  }else if(state.action == 'edited'){
                    str = 'Task Updated';                    
                  }else if(state.action == 'unresolved'){
                    str = 'Task ' + $scope.clientStrings().unResolvedPCase;               
                  }

                  if(str && state.extra){
                    if(!state.extra.startsWith(str)){
                      str += ' : ' + state.extra;
                    }
                    if(state.extra.indexOf(":") > 0){
                      str = state.extra;
                    }                    
                  }else if(state.extra){
                    str = state.extra;
                  }else{
                    str = state.action;
                  }

                  return str;
                }

                $scope.getBackground = function(state){
                  if(state.user && !historyColor[state.user] && colors.length > 0){
                    historyColor[state.user] = colors.pop();                    
                  }
                  return historyColor[state.user || ''];
                }
              }],              
              modal: {
                dismissable: true,
                title: 'Task History',
                template: 'components/modal/survey/tpl/record-history.html',
                buttons: []
              },
              resolve: scratchPad
            }, 'modal-success');            

            callModal.result.then(function(event) {
              finish.call(event, scratchPad, record);
            });
          };
        },
        archiveRecord: function (finish, title) {
          finish = finish || angular.noop;
          return function (record) {
            if(!record) return;
            var modal = openModal({
              task: record,
              modal: {
                dismissable: true,
                title: title||'Archive Task',
                template: 'components/modal/survey/tpl/task-archive.html',
                buttons: [{
                  classes: 'btn-default',
                  text: 'Leave as is',
                  click: function(e) {
                    modal.dismiss(e);
                  }
                },{
                  classes: 'btn-danger',
                  text: 'Archive',
                  click: function(e) {
                    modal.close(e);
                  }
                }]
              }
            }, 'modal-success');

            modal.result.then(function(event) {
              finish.call(event, record);
            });
          };
        },
        performActionOnExternalTask: function(finish, record, title, button, nextStatus, theme) {
          finish = finish || angular.noop;

          return function () {
            var
            scratchPad = {
              date: new Date,
              message: null,
            },
            callModal = openModal({
              controller: ['$scope', '$q', 'ngToast', function ($scope, $q, ngToast) {
                $scope.title = title //used in perform-action.html
                $scope.user = {name: record.associatedEmail};
                $scope.isUpdatingStatus = false;

                $scope.uploader = new FileUploader({
                  url: appConfig.apiUrl + '/api/client-uploads/'+record.client+'?token='+record.token,
                  alias: 'upload',
                  filters: [
                    {
                      name: 'extensionFilter',
                      fn: function(item) {
                        var extension = item.name.substr(item.name.lastIndexOf('.')+1);
                        var invalidExtensions = ['exe', 'bin'];
                        if(invalidExtensions.indexOf(extension) > -1) {
                          ngToast.create({
                            className: 'danger',
                            content: 'Files with extensions '+invalidExtensions+' are not allowed'
                          });
                          return false;
                        } else {
                          return true;
                        }
                      }
                    }
                  ]
                });

                this.uploadFiles = function () {

                  var
                  uploader = $scope.uploader,
                  results = [],
                  errors = [];

                  if(uploader && uploader.queue && uploader.queue.length) {
                    var defer = $q.defer();

                    uploader.onSuccessItem = function(fileItem, response, status, headers) {
                      Array.prototype.push.apply(results, response);
                    };

                    uploader.onErrorItem = function(fileItem, response, status, headers) {
                      errors.push(new Error('Failed to upload file: ' + fileItem.file.name));
                    };

                    uploader.onCompleteAll = function() {
                      if(errors.length > 0) { // fail on any errors
                        defer.reject(errors);
                        return;
                      }

                      defer.resolve(results);
                    };

                    uploader.uploadAll();

                    return defer.promise;
                  }

                  return $q.when(false);
                };

                $scope.uploadFiles = this.uploadFiles;

                $scope.buttonClick = function(buttontext) {
                  var markClosed = false;
                  var leaveOpen = false;
                  $scope.isUpdatingStatus = true;

                  if(buttontext === 'Fully Resolved') {
                    markClosed = true;
                  } else if(buttontext === 'Leave Open'){
                    leaveOpen = true;
                  }

                  return $scope.uploadFiles()
                    .then(function (uploaded) {
                      var uploads = [];
                      if(uploaded && uploaded.length) { // add these to the model so when it creates the task, it will associate these items.
                        uploads = uploaded.map(function (upload) {
                          return upload._id;
                        });
                      }

                      finish.call(event, scratchPad.message, uploads).then(function(result) {
                        if(result.status) {
                          $scope.isUpdatingStatus = false;
                          
                          if(nextStatus) { //submitting contact form doesn't send any next status
                            record.status = nextStatus;
                          }

                          callModal.dismiss();
                        }
                      });
                    })
                    .catch(function (err) {
                      console.log('error:', err.stack || err);
                      $scope.formError = err;
                    })
                    .finally(function () {
                      $scope.isSubmitting = false;
                    });
                };
              }],
              modal: {
                dismissable: true,
                title: title||'External Task',
                template: 'components/modal/survey/tpl/perform-action.html',
                buttons: [button]
              },
              resolve: scratchPad
            }, theme);
          };
        },
        resolveExternalTask: function (finish, record, title) {
          finish = finish || angular.noop;
          return function (task) {
            if(!task) return;

            var
            scratchPad = {
              date: new Date,
              message: null,
              previousNotes: task.resolve.notes
            },
            callModal = openModal({
              controller: ['$scope', '$externalTask', '$q', 'ngToast', function ($scope, $externalTask, $q, ngToast) {
                $scope.entryTask = task;
                $scope.user = {name: record.associatedEmail};

                $scope.uploader = new FileUploader({
                  url: appConfig.apiUrl + '/api/client-uploads/'+record.client+'?token='+record.token,
                  alias: 'upload',
                  filters: [
                    {
                      name: 'extensionFilter',
                      fn: function(item) {
                        var extension = item.name.substr(item.name.lastIndexOf('.')+1);
                        var invalidExtensions = ['exe', 'bin'];
                        if(invalidExtensions.indexOf(extension) > -1) {
                          ngToast.create({
                            className: 'danger',
                            content: 'Files with extensions '+invalidExtensions+' are not allowed'
                          });
                          return false;
                        } else {
                          return true;
                        }
                      }
                    }
                  ]
                });

                this.uploadFiles = function () {

                  var
                  uploader = $scope.uploader,
                  results = [],
                  errors = [];

                  if(uploader && uploader.queue && uploader.queue.length) {
                    var defer = $q.defer();

                    uploader.onSuccessItem = function(fileItem, response, status, headers) {
                      Array.prototype.push.apply(results, response);
                    };

                    uploader.onErrorItem = function(fileItem, response, status, headers) {
                      errors.push(new Error('Failed to upload file: ' + fileItem.file.name));
                    };

                    uploader.onCompleteAll = function() {
                      if(errors.length > 0) { // fail on any errors
                        defer.reject(errors);
                        return;
                      }

                      defer.resolve(results);
                    };

                    uploader.uploadAll();

                    return defer.promise;
                  }

                  return $q.when(false);
                };

                $scope.uploadFiles = this.uploadFiles;

                $scope.buttonClick = function(buttontext) {
                  var markClosed = false;
                  var leaveOpen = false;

                  if(buttontext === 'Fully Resolved') {
                    markClosed = true;
                  } else if(buttontext === 'Resolve Note'){
                    leaveOpen = true;
                  }

                  return $scope.uploadFiles()
                    .then(function (uploaded) {
                      var uploads = [];
                      if(uploaded && uploaded.length) { // add these to the model so when it creates the task, it will associate these items.
                        uploads = uploaded.map(function (upload) {
                          return upload._id;
                        });
                      }

                      callModal.dismiss();
                      return $externalTask.resolveExternalTask(record.token, scratchPad.message, markClosed, leaveOpen, uploads) // prevents uploader from going over the wire.
                      .then(function (result) {
                        if(result.status) {
                          record.status = result.newStatus;
                        }
                        callModal.close();
                      })
                      .catch(function (err) {
                        console.log('error:', err.stack || err);
                        $scope.formError = err;
                      })
                      .finally(function () {
                        $scope.isSubmitting = false;
                        finish.call(event);
                      });
                    })
                    .catch(function (err) {
                      console.log('error:', err.stack || err);
                      $scope.formError = err;
                    })
                    .finally(function () {
                      $scope.isSubmitting = false;
                    });
                };
              }],
              modal: {
                dismissable: true,
                title: title||'Resolve Task',
                template: 'components/modal/survey/tpl/record-resolve.html',
                buttons: [{
                  classes: 'btn-danger',
                  text: 'Close',
                  click: function(e) {
                    callModal.dismiss(e);
                  }
                },{
                  classes: 'btn-primary',
                  text: 'Resolve Note'
                },{
                  classes: 'btn-success',
                  text: 'Fully Resolved'
                }]
              },
              resolve: scratchPad
            }, 'modal-success');
          };
        },
        editDueDate: function(due, finish, title) {
          finish = finish || angular.noop;
          var
          scratchPad = {
            date: due ? due : 'Please Select'
          },
          callModal = openModal({
            controller: ['$scope', function ($scope) {
              $scope.scratchPad = scratchPad;

              $scope.slider = {
                maxMinutes: 1435,
                dueValueInMinutes: 1100
              };

              // create relation between dueValueInMin and scratchPad.date
              $scope.$watch(function() { return $scope.scratchPad.date; }, function(nv) {
                if($scope.scratchPad.date && typeof $scope.scratchPad.date !== 'string') {
                  $scope.slider.dueValueInMinutes = $scope.scratchPad.date.getHours()*60 + $scope.scratchPad.date.getMinutes();
                }
              });

              $scope.$watch(function() { return $scope.slider.dueValueInMinutes; }, function(nv) {
                if($scope.scratchPad.date && typeof $scope.scratchPad.date !== 'string') {
                  $scope.scratchPad.date = new Date($scope.scratchPad.date.setHours($scope.slider.dueValueInMinutes / 60, $scope.slider.dueValueInMinutes % 60));
                }
              });

              $scope.taskDateFormat = 'MM/dd/yyyy';
              $scope.taskDateStepH  = 1;
              $scope.taskDateStepM  = 1;
              $scope.taskDateAMPM   = true;
              $scope.nowDate = function () {
                var
                now = Date.now(),
                nextMinute = now % 60000;
                return now - nextMinute;
              };
              $scope.taskDateDisabled = function (date, mode) {
                var now = Date.now(), offset = (now % 8.64e7);
                return date <= (now - offset);
              };

              $scope.clearDueDate = function (){
                if($scope.scratchPad && !$scope.scratchPad.date) return;
                delete $scope.scratchPad.date;
              };

              $scope.presetOffsets = null; // use defaults
            }],
            modal: {
              dismissable: true,
              title: title||'Edit Due Date',
              template: 'components/modal/survey/tpl/edit-due.html',
              buttons: [{
                classes: 'btn-default',
                text: 'Cancel',
                click: function(e) {
                  callModal.dismiss(e);
                }
              },{
                classes: 'btn-success',
                text: 'Edit',
                click: function(e) {
                  callModal.close(e);
                }
              }]
            },
            resolve: scratchPad
          }, 'modal-success');

          callModal.result.then(function(event) {
            finish.call(event, scratchPad);
          });
        }
      },
      ruleActions: {
        updateRule: function (finish) {
          finish = finish || angular.noop;
          return function (rule) {
            if(!rule) return;
            var modal = openModal({
              controller: ['$scope', '$clientSwitcher', '$rulesManager', function ($scope, $clientSwitcher, $rulesManager) {
                $scope.allValue = 'all';
                $scope.rule = rule;

                var lastClient = $clientSwitcher.getCurrentClient()._id;

                $scope.updateRule = function() {
                  $rulesManager.updateRule(lastClient, $scope.rule).then(function() {
                    modal.close(e);
                  });
                };
              }],
              modal: {
                dismissable: true,
                title: 'Edit Rule',
                template: 'components/modal/survey/tpl/edit-rule.html'
              }
            }, 'modal-success');

            modal.result.then(function(event) {
              finish.call(event, rule);
            });
          };
        }
      }
    };
  }
// Dependency Injection
SurveyModalFactory.$inject = ["$rootScope","$uibModal","$auth","$filter","$window","$authStorage","$clientTask","AudioPlayList","FileUploader","$clientSwitcher","$serveySentiment"];
