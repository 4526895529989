'use strict';

export const leaveTableDirective = function ($auth) {
    return {
      restrict: 'E',
      templateUrl: 'app/account/dashboard/time-production-reporting/tables/leave/leave.html',
      scope: {
        employee: '=ngModel',
        MODES: '=modes',
        printMode: '=printMode',
        changeMode: '&',
        createEntry: '&',
        updateEntry: '&',
        deleteEntry: '&',

        deleteEntries: '&',
        updateEntries: '&',
        
        canEdit: '&',
      },
      link: function($scope) {
        $scope.timezone = $auth.getCurrentUser().timezone || moment.tz.guess();
      }
    };
  }
// Dependency Injection
leaveTableDirective.$inject = ["$auth"];
