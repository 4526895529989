import './coti.scss';
'use strict';

export const CotiConfig = function ($stateProvider) {
    $stateProvider
      .state('app.print.coti', {
        url: '/office-operations/coti',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./print/office-operations/coti/coti.html', '36a406ca'); }],
        controller: 'PrintCOTICtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./print/office-operations/coti/coti.controller.js', 'PrintCOTICtrl'); }]
                      }
      });
  }
// Dependency Injection
CotiConfig.$inject = ["$stateProvider"];
