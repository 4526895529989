'use strict';


export const programDirective = function ($auth, Modal, $rootScope, $q, $timeout, $clientSwitcher, $timezones, $years, $servicePanel, ngToast) {
        return {
            restrict: 'E',
            templateUrl: 'components/forms/program.html',
            scope: {
                program: '=ngModel',
                onSubmit: '=formOnSubmit',
                onReset: '=formOnReset'
            },
            link: function (scope, el, attrs) {
                const client = $clientSwitcher.getCurrentClient();
                scope.clientSettings = function () { return $clientSwitcher.getFeatureOptions('client.settings'); };
                scope.dateOptions = {
                    openPicker: false,
                    openTimePicker: false
                };
                scope.program.startDate = new Date(scope.program.startDate);
                scope.years = $years;
                scope.strSettings = function (str) {
                    return {
                        selectedStr: ' ',
                        noSelection: 'No ' + str + ' Selected',
                        allLabel: 'All ' + str
                    };
                };
            }
        }
    }
// Dependency Injection
programDirective.$inject = ["$auth","Modal","$rootScope","$q","$timeout","$clientSwitcher","$timezones","$years","$servicePanel","ngToast"];
