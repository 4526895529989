
'use strict';

export const $performTaskBucketSelectService  = function ($clientTask, Modal) {
  return function (cb) {
    cb = angular.isFunction(cb) ? cb : angular.noop;
    return Modal.select.taskCollection(function (record) {
      cb.apply(this);
    }, {
      // options go here:
    });
  };
}
 

// Dependency Injection
$performTaskBucketSelectService.$inject = ['$clientTask', 'Modal'];
