
'use strict';

export const $notificationManagementService  = function (NotificationManagement) {
        return {
            getNotificationEvents: function (client, filter) {
                return NotificationManagement.getNotificationEvents({
                    id: client._id || client,
                    filter
                }).$promise;
            },
            create: function (client, data) {
                return NotificationManagement.create({
                    _id: client._id || client,
                    data
                }).$promise;
            },
            update: function (client, data) {
                return NotificationManagement.update({
                    _id: client._id || client,
                    data
                }).$promise;
            },
            delete: function (client, eventId) {
                return NotificationManagement.delete({
                    _id: client._id || client,
                    eventId
                }).$promise;
            }
        };
    }
// Dependency Injection


// Dependency Injection
$notificationManagementService.$inject = ['NotificationManagement'];
