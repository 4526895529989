'use strict';

export const DashboardSettingsNationalityIndexCtrl = function ($rootScope, $scope, $auth, $activeClient, $nationalities) {

    let currentCli = null;
    $scope.loading = false;

    $scope.search = {
      orderVal: 'asc'
    };
    $scope.sortingOrders = [{
      label: 'A-Z',
      value: 'asc'
    }, {
      label: 'Z-A',
      value: 'desc'
    }]
    $scope.pagination = {
      currentPage: 1,
      page: 1,
      total: 0,
      pageSize: 20,
      itemsPerPage: 20
    };

    $scope.error = null;
    $scope.nationalitiesData = [];
    $scope.pagination.total = 0;

    $scope.applyFilter = function () {
      $scope.pagination.page = 1;
      $scope.getNationalities();
    }
    $scope.getNationalities = function () {
      if ($scope.loading) return;
      $scope.loading = true;
      $scope.allowEdit = $auth.getCurrentUser().permissions.includes('SETTING_NATIONALITY_EDIT');
      $scope.error = null;

      $nationalities.getNationalitiesList(currentCli, $scope.pagination, $scope.search)
        .then((res) => {
          if (res.status) {
            if (res.status) {
              $scope.nationalitiesData = res.nationalitiesList;
              $scope.pagination.total = res.totalRecords;
              $scope.nationalitiesDataCount = res.totalRecords;
            } else {
              $scope.error = 'No Record Found.';
              $scope.nationalitiesDataCount = 0;
            }
          }
        })
        .catch((err) => {
          let errMessage = err.message || err;
          if (err && err.data && err.data.message) {
            errMessage = err.data.message;
          } else if (err && err.data && err.data.reason) {
            errMessage = err.data.reason;
          }
          $scope.error = errMessage;
          $scope.nationalitiesDataCount = 0;
        })
        .finally(() => {
          $scope.loading = false;
        })
    }

    // listen for client switching
    $scope.$on('$destroy', $activeClient(function (client) {
      if (!client) return;
      currentCli = client;
      $scope.getNationalities()
    }));
  }
// Dependency Injection
DashboardSettingsNationalityIndexCtrl.$inject = ["$rootScope","$scope","$auth","$activeClient","$nationalities"];

// angular.module('coordinateApp').controller('DashboardSettingsNationalityIndexCtrl', DashboardSettingsNationalityIndexCtrl);
