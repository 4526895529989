'use strict';

export const ResolveCodeConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard.settings.resolveCodes', {
        url: '/resolve-codes',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/resolve_codes/resolve-codes.html', '764776ca'); }],
        controller: 'DashboardSettingsResolveCodesCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/resolve_codes/resolve-codes.controller.js', 'DashboardSettingsResolveCodesCtrl'); }]
                      }
      })
      .state('app.account.dashboard.settings.resolveCodes.index', {
        url: '/resolve-codes',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/resolve_codes/index/index.html', '3d0bb68b'); }],
        controller: 'DashboardSettingsResolveCodesIndexCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/resolve_codes/index/index.controller.js', 'DashboardSettingsResolveCodesIndexCtrl'); }]
                      }
      })
      .state('app.account.dashboard.settings.resolveCodes.create', {
        url: '/create',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/resolve_codes/create/create.html', '954dceb'); }],
        controller: 'DashboardSettingsResolveCodesCreateCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/resolve_codes/create/create.controller.js', 'DashboardSettingsResolveCodesCreateCtrl'); }]
                      }
      })
      .state('app.account.dashboard.settings.resolveCodes.update', {
        url: '/update/:id',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/resolve_codes/update/update.html', '7fd9278b'); }],
        controller: 'DashboardSettingsResolveCodesUpdateCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/resolve_codes/update/update.controller.js', 'DashboardSettingsResolveCodesUpdateCtrl'); }],
                          '$selectedResolveCode': ['$stateParams','$clientResolveCodes', '$clientSwitcher', function ($stateParams, $clientResolveCodes, $clientSwitcher) {
                            return $clientResolveCodes.getResolveCode($clientSwitcher.getCurrentClient(), $stateParams.id);
                          }]
                      },
      });
  }
// Dependency Injection
ResolveCodeConfig.$inject = ["$stateProvider"];
