
export const ngDropdownMultiselectDirective = function ($filter, $document, $compile, $parse, $window) {
        return {
            restrict: 'AE',
            scope: {
                name: '@',
                selectedModel: '=',
                options: '=',
                extraSettings: '=',
                events: '=',
                searchFilter: '=?',
                translationTexts: '=',
                prefixText: '=',
                groupBy: '@',
                onscroll: "&",
                pageLimit: '=',
                totalCount: '=',
                isLoading: '=',
                valueField: '=',
                keyField: '=',
            },
            template: function (element, attrs) {
                var checkboxes = attrs.checkboxes ? true : false;
                var groups = attrs.groupBy ? true : false;
                let className = "my-dd-dropdown-" + attrs.name;
                let countLbl = "Showing records {{options.length ? 1 : 0}} to {{options.length}} of {{totalCount}}";
                var template = '<div class="multiselect-parent btn-group dropdown-multiselect w-100">';
                template += '<button type="button" class="dropdown-toggle w-100" ng-class="settings.buttonClasses" style="display: flex;justify-content: space-between; align-items: center;" ng-click="toggleDropdown()"><span>{{getButtonText()}}</span><span class="caret"></span></button>';
                template += '<ul class="' + className + ' dropdown-menu dropdown-menu-form " ng-style="{display: open ? \'block\' : \'none\', height : settings.scrollable ? settings.scrollableHeight : \'auto\' }" style="overflow-y: auto;"   >';
                template += '<li class="pagination-text d-flex align-item-flex-end">' + countLbl + '</li>';
                template += '<li style="display: flex;justify-content: space-between; align-items: center;"><a ng-hide="!settings.showCheckAll || settings.selectionLimit > 0" data-ng-click="selectAll()"><span class="glyphicon glyphicon-ok"></span>  {{texts.checkAll}}</a> <a ng-show="settings.showUncheckAll" data-ng-click="deselectAll();"><span class="glyphicon glyphicon-remove"></span>   {{texts.uncheckAll}}</a></li>';
                // template += '<li ><a ng-show="settings.showUncheckAll" data-ng-click="deselectAll();"><span class="glyphicon glyphicon-remove"></span>   {{texts.uncheckAll}}</a></li>';
                template += '<li ng-hide="(!settings.showCheckAll || settings.selectionLimit > 0) && !settings.showUncheckAll" class="divider"></li>';
                template += '<li ng-show="settings.enableSearch"><div class="dropdown-header"><input type="text" class="form-control" style="width: 100%;" ng-model="searchFilter" placeholder="{{texts.searchPlaceholder}}" /></li>';
                template += '<li ng-show="settings.enableSearch" class="divider"></li>';

                if (groups) {
                    template += '<li ng-repeat-start="option in orderedItems | filter: searchFilter" ng-show="getPropertyForObject(option, settings.groupBy) !== getPropertyForObject(orderedItems[$index - 1], settings.groupBy)" role="presentation" class="dropdown-header">{{ getGroupTitle(getPropertyForObject(option, settings.groupBy)) }}</li>';
                    template += '<li ng-repeat-end role="presentation">';
                } else {
                    template += '<li role="presentation" ng-repeat="option in options | filter: searchFilter" ng-class="{\'item-selected\': isChecked(getPropertyForObject(option,settings.idProp))}">';
                }

                template += '<a role="menuitem" tabindex="-1" ng-click="setSelectedItem(getPropertyForObject(option,settings.idProp))">';

                if (checkboxes) {
                    template += '<div class="checkbox"><label><input class="checkboxInput" type="checkbox" ng-click="checkboxClick($event, getPropertyForObject(option,settings.idProp))" ng-checked="isChecked(getPropertyForObject(option,settings.idProp))" /> {{getPropertyForObject(option, settings.displayProp)}}</label></div></a>';
                } else {
                    template += '<span data-ng-class="{\'glyphicon glyphicon-ok\': isChecked(getPropertyForObject(option,settings.idProp))}"></span> {{getPropertyForObject(option, settings.displayProp)}}</a>';
                }

                template += '</li>';
                template += '<li class="divider" ng-show="settings.selectionLimit > 1"></li>';
                template += '<li role="presentation" ng-show="settings.selectionLimit > 1"><a role="menuitem">{{selectedModel.length}} {{texts.selectionOf}} {{settings.selectionLimit}} {{texts.selectionCount}}</a></li>';
                template += '<div ng-show="isLoading" class="align-content-center"><img alt="Elisa Loader" src="assets/images/ellipsis.gif" style="height: 25px; width: auto;" /></div>';
                template += '</ul>';
                template += '</div>';
                element.html(template);
            },
            link: function (scope, $element, $attrs) {
                setTimeout(() => {
                    const className = '.my-dd-dropdown-' + $attrs.name;
                    scope.currentPage = 1;
                    scope.open = false;

                    var $dropdownTrigger = $element.children()[0];
                    // console.log(scope.options)
                    setTimeout(() => {
                        if (scope.selectedModel == 'all' && scope.options && scope.options.length) {
                            scope.selectedModel = []
                            scope.selectAll()
                        }
                        $element.find(className).on('scroll', function (e) {
                            scope.checkScroll()
                        });
                    }, 500)

                    scope.toggleDropdown = function () {
                        scope.open = !scope.open;
                    };
                    scope.checkScroll = function () {
                        var element = document.querySelector(className);
                        // console.log(element)
                        var scrollPosition = element.scrollTop + element.offsetHeight;
                        var scrollHeight = element.scrollHeight;
                        if (scrollPosition >= scrollHeight) {
                            // console.log(scope.totalCount, scope.options.length)
                            if (scope.totalCount > scope.options.length && !scope.isLoading) {
                                scope.onscroll({
                                    searchKey: scope.searchFilter || '',
                                    offset: scope.pageLimit ? Math.round((scope.options.length / scope.pageLimit) + 1) : scope.currentPage + 1
                                });
                                scope.currentPage += 1;
                            }
                        }
                    }
                    scope.checkboxClick = function ($event, id) {
                        scope.setSelectedItem(id);
                        $event.stopImmediatePropagation();
                    };

                    scope.externalEvents = {
                        onItemSelect: angular.noop,
                        onItemDeselect: angular.noop,
                        onSelectAll: angular.noop,
                        onDeselectAll: angular.noop,
                        onInitDone: angular.noop,
                        onMaxSelectionReached: angular.noop
                    };

                    scope.settings = {
                        dynamicTitle: true,
                        scrollable: false,
                        scrollableHeight: '300px',
                        closeOnBlur: true,
                        displayProp: 'label',
                        idProp: scope.valueField || 'value',
                        externalIdProp: scope.valueField || 'value',
                        enableSearch: false,
                        selectionLimit: 0,
                        showCheckAll: true,
                        showUncheckAll: true,
                        closeOnSelect: false,
                        buttonClasses: 'btn btn-default',
                        closeOnDeselect: false,
                        groupBy: $attrs.groupBy || undefined,
                        groupByTextProvider: null,
                        smartButtonMaxItems: 0,
                        smartButtonTextConverter: angular.noop
                    };

                    scope.texts = {
                        checkAll: 'Check All',
                        uncheckAll: 'Uncheck All',
                        selectionCount: 'checked',
                        selectionOf: '/',
                        searchPlaceholder: 'Search...',
                        buttonDefaultText: 'Select',
                        dynamicButtonTextSuffix: 'checked',
                        // prefixText: ''
                    };

                    scope.searchFilter = scope.searchFilter || '';

                    if (angular.isDefined(scope.settings.groupBy)) {
                        scope.$watch('options', function (newValue) {
                            if (angular.isDefined(newValue)) {
                                scope.orderedItems = $filter('orderBy')(newValue, scope.settings.groupBy);
                            }
                        });
                    }

                    angular.extend(scope.settings, scope.extraSettings || []);
                    angular.extend(scope.externalEvents, scope.events || []);
                    angular.extend(scope.texts, scope.translationTexts);
                    // console.log(scope.texts)
                    scope.singleSelection = scope.settings.selectionLimit === 1;

                    function getFindObj(id) {
                        var findObj = {};

                        if (scope.settings.externalIdProp === '') {
                            findObj[scope.settings.idProp] = id;
                        } else {
                            findObj[scope.settings.externalIdProp] = id;
                        }

                        return findObj;
                    }

                    function clearObject(object) {
                        for (var prop in object) {
                            delete object[prop];
                        }
                    }

                    if (scope.singleSelection) {
                        if (angular.isArray(scope.selectedModel) && scope.selectedModel.length === 0) {
                            // clearObject(scope.selectedModel);
                            scope.selectedModel = [];
                        }
                    }

                    if (scope.settings.closeOnBlur) {
                        $document.on('click', function (e) {
                            var target = e.target.parentElement;
                            var parentFound = false;

                            while (angular.isDefined(target) && target !== null && !parentFound) {
                                if (_.contains(target.className.split(' '), 'multiselect-parent') && !parentFound) {
                                    if (target === $dropdownTrigger) {
                                        parentFound = true;
                                    }
                                }
                                target = target.parentElement;
                            }
                            if (!parentFound) {
                                scope.$apply(function () {
                                    scope.open = false;
                                });
                            }
                        });
                    }

                    scope.getGroupTitle = function (groupValue) {
                        if (scope.settings.groupByTextProvider !== null) {
                            return scope.settings.groupByTextProvider(groupValue);
                        }

                        return groupValue;
                    };

                    scope.getButtonText = function () {
                        if (scope.settings.dynamicTitle && (scope.selectedModel.length > 0 || (angular.isObject(scope.selectedModel) && _.keys(scope.selectedModel).length > 0))) {
                            if (scope.settings.smartButtonMaxItems > 0) {
                                var itemsText = [];

                                angular.forEach(scope.options, function (optionItem) {
                                    if (scope.isChecked(scope.getPropertyForObject(optionItem, scope.settings.idProp))) {
                                        var displayText = scope.getPropertyForObject(optionItem, scope.settings.displayProp);
                                        var converterResponse = scope.settings.smartButtonTextConverter(displayText, optionItem);

                                        itemsText.push(converterResponse ? converterResponse : displayText);
                                    }
                                });
                                if (scope.selectedModel.length > scope.settings.smartButtonMaxItems) {
                                    itemsText = itemsText.slice(0, scope.settings.smartButtonMaxItems);
                                    itemsText.push('...');
                                }
                                return itemsText.join(', ');
                            } else {
                                var totalSelected;

                                if (scope.singleSelection) {
                                    totalSelected = (scope.selectedModel !== null && angular.isDefined(scope.selectedModel[scope.settings.idProp])) ? 1 : 0;
                                } else {
                                    totalSelected = angular.isDefined(scope.selectedModel) ? scope.selectedModel.length : 0;
                                }

                                if (totalSelected === 0) {
                                    return scope.texts.buttonDefaultText;
                                } else {
                                    return totalSelected + ' ' + scope.texts.dynamicButtonTextSuffix;
                                }
                            }
                        } else {
                            return scope.texts.buttonDefaultText;
                        }
                    };

                    scope.getPropertyForObject = function (object, property) {
                        if (angular.isDefined(object) && object.hasOwnProperty(property)) {
                            return object[property];
                        }
                        return '';
                    };

                    scope.selectAll = function () {
                        scope.deselectAll(false);
                        scope.externalEvents.onSelectAll();

                        angular.forEach(scope.options, function (value) {
                            scope.setSelectedItem(value[scope.settings.idProp], true);
                        });
                    };

                    scope.deselectAll = function (sendEvent) {
                        sendEvent = sendEvent || true;

                        if (sendEvent) {
                            scope.externalEvents.onDeselectAll();
                        }

                        if (scope.singleSelection) {
                            clearObject(scope.selectedModel);
                        } else {
                            if (scope.selectedModel && scope.selectedModel.length && Array.isArray(scope.selectedModel)) {
                                scope.selectedModel.splice(0, scope.selectedModel.length);
                            } else {
                                scope.selectedModel = []
                            }
                        }
                    };

                    scope.setSelectedItem = function (id, dontRemove) {
                        // var findObj = getFindObj(id);
                        // var finalObj = null;

                        // if (scope.settings.externalIdProp === '') {
                        // //     finalObj = _.find(scope.options, findObj);
                        // // } else {
                        // //     finalObj = findObj;
                        // }

                        if (scope.singleSelection) {
                            // clearObject(scope.selectedModel);
                            // angular.extend(scope.selectedModel, finalObj);
                            scope.externalEvents.onItemSelect(id);
                            if (scope.settings.closeOnSelect) scope.open = false;

                            return;
                        }

                        dontRemove = dontRemove || false;
                        let itemIndex = scope.selectedModel.indexOf(id);
                        var exists = itemIndex !== -1; //_.findIndex(scope.selectedModel, id) !== -1;

                        if (!dontRemove && exists) {
                            scope.selectedModel.splice(itemIndex, 1);
                            scope.externalEvents.onItemDeselect(id);
                        } else if (!exists && (scope.settings.selectionLimit === 0 || scope.selectedModel.length < scope.settings.selectionLimit)) {
                            if (! Array.isArray(scope.selectedModel)) {
                                scope.selectedModel = []
                            }
                            scope.selectedModel.push(id);
                            scope.externalEvents.onItemSelect(id);
                        }
                        // console.warn( scope.externalEvents)
                        if (scope.settings.closeOnSelect) scope.open = false;
                    };

                    scope.isChecked = function (id) {
                        if (scope.singleSelection) {
                            return scope.selectedModel !== null && angular.isDefined(scope.selectedModel[scope.settings.idProp]) && scope.selectedModel[scope.settings.idProp] === getFindObj(id)[scope.settings.idProp];
                        }

                        return scope.selectedModel.indexOf(id) !== -1; //_.findIndex(scope.selectedModel, getFindObj(id)) !== -1;
                    };
                    scope.externalEvents.onInitDone();
                })
            }
        };
    }
    
/*
   <div>
    <div ng-dropdown-multiselect=""  name="dd2"
    options="example14data" selected-model="example14model" checkboxes="true" extra-settings="setting1"></div>

    <div ng-dropdown-multiselect="" options="example14data" selected-model="example14model" extra-settings="setting2"></div>

    <div ng-dropdown-multiselect="" name="dd1"
    options="example14data" selected-model="example14model" checkboxes="true" extra-settings="setting1"></div>


         // scope.example14model = [];
        // scope.setting1 = {
        //   scrollableHeight: '250px',
        //   scrollable: true,
        //   enableSearch: true
        // };

        // scope.setting2 = {
        //   scrollableHeight: '350px',
        //   scrollable: true,
        //   enableSearch: false
        // };

        // scope.example14data = [{
        //   "label": "Alabama",
        //   "id": "AL"
        // },];
        // scope.example2settings = {
        //   displayProp: 'id'
        // };
</div> */
// Dependency Injection
ngDropdownMultiselectDirective.$inject = ["$filter","$document","$compile","$parse","$window"];
