
'use strict';

export const $judgesService  = function (Judges) {
        return {
            getActiveList: function (client, paginationData, filter) {

                return Judges.getActiveList({
                    id: client._id || client,
                    page: paginationData && paginationData.page,
                    pageSize: paginationData && paginationData.pageSize,
                    filter
                }).$promise;
            },
            getJudgesList: function (client, paginationData, filter) {
                return Judges.getJudgesList({
                    id: client._id || client,
                    page: paginationData && paginationData.page,
                    pageSize: paginationData && paginationData.pageSize,
                    filter
                }).$promise;
            },
            create: function (client, data) {
                return Judges.create({
                    _id: client._id || client,
                    data
                }).$promise;
            },
            update: function (client, data) {
                return Judges.update({
                    _id: client._id || client,
                    data
                }).$promise;
            },
            getJudgeById: function (client, judgeId) {
                return Judges.getJudgeById({
                    id: client._id || client,
                    controller: judgeId
                }).$promise;
            },
            toggleAccess: function (judgeId, client) {
                return Judges.toggleAccess({
                    judge: judgeId,
                    _id: client._id || client
                }).$promise;
            }
        };
    }
// Dependency Injection


// Dependency Injection
$judgesService.$inject = ['Judges'];
