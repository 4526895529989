


'use strict';

export const ExternalTaskFactory  = function ($resource, appConfig) {
  return $resource(appConfig.apiUrl + '/api/ext/:id/:controller', {
    id: '@_id'
  },
  {
    getTaskFromToken: {
      method: 'GET',
      params: {
        controller: 'get-task',
        id: '@id'
      }
    },
    getRecord: {
      method: 'GET',
      params: {
        controller: 'get-record'
      }
    },
    updateExternalTaskStatus: {
      method: 'POST',
      params: {
        controller: 'update-external-task-status',
        id: '@id'
      }
    },
    resolveExternalTask: {
      method: 'POST',
      params: {
        controller: 'resolve-external-task',
        id: '@id'
      }
    },
    contactForm: {
      method: 'POST',
      params: {
        controller: 'contact-form',
        id: '@id'
      }
    }
  });
} 
 

// Dependency Injection
ExternalTaskFactory.$inject = ['$resource', 'appConfig'];
