import './travel.scss';
'use strict';

export const travelTableDirective = function ($auth) {
    return {
      restrict: 'E',
      templateUrl: 'app/account/dashboard/time-production-reporting/tables/travel/travel.html',
      scope: {
        employee: '=ngModel',
        MODES: '=modes',
        destinations: '=',
        printMode: '=printMode',
        changeMode: '&',
        createEntry: '&',
        updateEntry: '&',
        deleteEntry: '&',
        
        deleteEntries: '&',
        updateEntries: '&',

        canEdit: '&',
        shiftDetailsEntry: '&'
      },
      link: function(scope) {
        scope.reportTypes = ['StartTravel', 'DelayTravel', 'EndTravel'];
        scope.timezone = $auth.getCurrentUser().timezone || moment.tz.guess();

        var calculateTime =  function(t) {
          var cd = 24 * 60 * 60 * 1000,
            ch = 60 * 60 * 1000,
            d = Math.floor(t / cd),
            h = Math.floor((t - d * cd) / ch),
            m = Math.round((t - d * cd - h * ch) / 60000),
            time = '',
            pad = function (n) { return n < 10 ? '0' + n : n; };
          if (m === 60) {
            h++;
            m = 0;
          }
          if (h === 24) {
            d++;
            h = 0;
          }
          if (d > 0) {
            time += d + " day(s) ";
          }
          return time + [pad(h), pad(m)].join(':');
        }
      
        scope.$watch('destinations', function() {
          scope.destinationAlertItems = scope.destinations.map(function(destination) {
            return {
              label: destination.name,
              value: destination.internalId
            };
          });
        });

        scope.$watch(function () {
          return JSON.stringify(scope.employee.reportingData);
        }, function () {
          scope.employee.reportingData
            .forEach(function (report) {
              report.totalDurationFragments = calculateTime(report.totalDuration)
            });
        })

        scope.onDestinationChanged = function(destinationInternalId, report) {
          var selectedDestination = _.find(scope.destinations, function(destination) {
            return destinationInternalId === destination.internalId;
          });

          if(!selectedDestination) {
            return;
          }

          scope.reportTypes.forEach(function(reportType) {
            var reportEntry = report[reportType];

            if(reportEntry) {
              var record = Object.assign({}, reportEntry);

              record.destLocId = destinationInternalId;
              record.destination = selectedDestination.name;

              scope.updateEntry({
                employee: scope.employee,
                record: record,
                report: report
              });

              // update ui
              report.destLocId = destinationInternalId;
              report.destination = selectedDestination.name;
            }
          });
        };
      }
    };
  }
// Dependency Injection
travelTableDirective.$inject = ["$auth"];
