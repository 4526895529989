'use strict';

angular
export const DashboardSettingsIndexCtrl = function ($rootScope, $scope, $state, $activeClient, Modal, $clientSetting, $attachment, $uploadS3Attachment,
  $activeClientSettings, FileUploader, ngToast, $q, $authStorage, FileItem, ClientSetting, $clientUser, $database, $clientTask, $clientOffice, $client, plugins, appConfig, $timeout, LazyLoadService) {
  $scope.minicolorsLoaded = false;
  LazyLoadService.loadMinicolors().then(() => {
    $timeout(function() {
      $scope.minicolorsLoaded = true;
    });
  }).catch(error => {
    console.error( error);
  });
  $scope.file = {
    changed: false,
    croppedImage: '',
    uploadedImage: ''
  };

  $scope.httpMethods = [{label: 'GET', value: 'GET'}, {label: 'POST', value: 'POST'}];

  $scope.uploader = null;
  $scope.collections = [];
  $scope.offices = [];
  $scope.setLogo = null;
  //cleanup config
  $scope.supportsCleanup = false;
  var DEFAULT_CLEANUP = {
    frequency: null
  };

  var DEFAULT_CLEANUP_COLLECTION = {
    name: null,
    fieldName: null,
    days: null
  };

  //daily report config
  $scope.supportsDailyReport = false;
  var DEFAULT_DAILY_REPORT_CONFIG = {
    dataFrom: 'today',
    sendOn: moment().hour(23).minute(59).toDate(),
    sendToUsers: []
  };
  $scope.dailyReportChanged = function(isSelected) {
    $scope.settings.dailyReportConfig = isSelected ? DEFAULT_DAILY_REPORT_CONFIG : null;
  };

  function loadSubordinateUsers() {
    return $clientUser.getUserChainOfCommand($scope.client)
    .then(function (chainOfCommand) {
      chainOfCommand.me.userId = chainOfCommand.me._id;
      chainOfCommand.delegates.push(chainOfCommand.me);
      var delegates = _.sortBy(chainOfCommand.delegates, 'name');
      $scope.alertItemUsers = delegates
      .map(function(delegateUser) {
        return {
          value: delegateUser.userId,
          label: delegateUser.name
        };
      });
    });
  }

  $scope.statisticSettings = function () {
    var statisticStr = ($scope.clientStrings() || {}).taskPCase;
    return {
      selectedStr: 'Selected ' + statisticStr + ' Stats: ',
      noSelection: 'No ' + statisticStr + ' Stats Selected',
      allLabel: 'All Stats'
    };
  };

  $scope.attributeSettings = function () {
    var attributeStr = ($scope.clientStrings() || {}).taskPCase;
    return {
      selectedStr: 'Selected ' + attributeStr + ' Attributes: ',
      noSelection: 'No ' + attributeStr + ' Attributes Selected',
      allLabel: 'All Attributes'
    };
  };

  //business day config
  $scope.setBusinessDays = [
    {label: 'Mon',    value: 0},
    {label: 'Tue',   value: 1},
    {label: 'Wed', value: 2},
    {label: 'Thu',  value: 3},
    {label: 'Fri',    value: 4},
    {label: 'Sat',  value: 5},
    {label: 'Sun',    value: 6}
  ];

  //supported task type config
  $scope.taskTypes = [
    {
      label: 'Normal',
      value: 'normal',
      disableIf: function() {
        return true;
      }
    },
    {label: 'Survey', value: 'survey'},
    {label: 'Case', value: 'case'}
  ];
  var DEFAULT_TASK_TYPES = ['normal'];

  //supported task control config
  $scope.taskControls = [
    {label: $scope.clientStrings().bucketsPCase, value: 'bucket'},
    {label: $scope.clientStrings().regionsPCase, value: 'region'},
    {label: $scope.clientStrings().officesPCase, value: 'location'},
    {label: 'Interpreter', value: 'interpreter'},
    {label: 'Language', value: 'language'},
    {label: 'Service Type', value: 'serviceType'},
    // {label: $scope.clientStrings().campaignsPCase, value: 'campaign'},
    // {label: 'Employee', value: 'employee'},
    // {label: 'Assignee', value: 'assignee'},
    // {label: $scope.clientStrings().customersPCase, value: 'customer'}
  ];
  $scope.allTaskControls = ['bucket', 'region', 'location', 'interpreter', 'serviceType'];
  var DEFAULT_TASK_CONTROLS = ['bucket', 'region', 'location'];
  // $scope.allTaskControls = ['bucket', 'region', 'location', 'campaign', 'employee', 'customer'];
  // var DEFAULT_TASK_CONTROLS = ['bucket', 'region', 'location', 'employee'];


  // default task filter config
  var DEFAULT_TASK_FILTERS = {
    showTaskBuckets: true,
    includeArchived: false,
    showMyAssignedTasks: false,
    showMyCreatedTasks: false,
    showResolved:    false,
    showPending:     true,
    showUnresolved:  true,
    showExternal: false,
    showInFacility: true,
    showWithJudge: true,
    showStandby: true,
    showLunchOut: true,
    showReleased: true,
    showExtended: true,
    showCompleted: true,
    showICReleaseRequest: true,
    showICNoShow: true
  };

  //schedule enforcement config
  var DEFAULT_SCHEDULE_ENFORCEMENT = {
    weekends: {
      taskAssign: false,
      taskNotifications: false
    },

    holidays: {
      taskAssign: false,
      taskNotifications: false
    }
  };

  // task stats config
  $scope.taskStats = [
    {label: 'Total Tasks',   value: 'total_tasks'},
    {label: $scope.clientStrings().resolvePCase + ' Rate',  value: 'resolve_rate'},
    {label: 'MTTR (days)',   value: 'mttr'},
    {label: 'ATL (days)',    value: 'atl'},
    {label: 'Reassign Rate', value: 'reassign_rate'},
    {label: 'ATTS (days)',   value: 'atts', disableIf: function() {
      return !$scope.settings.supportTaskStateToggling;
    }},
  ];
  var DEFAULT_TASK_STATS = [
    'total_tasks',
    'resolve_rate',
    'mttr',
    'atl',
    'reassign_rate',
    'atts'
  ];
  $scope.allTaskStats = DEFAULT_TASK_STATS;


  $scope.onTaskStateToggleChange = function() {
    if(!$scope.settings.supportTaskStateToggling) {
      // Start/Pause feature is required to generate data based on which ATTS is computed.
      var indexOfATTS = $scope.settings.supportedTaskStats.indexOf('atts');
      if(indexOfATTS > -1) {
        $scope.settings.supportedTaskStats.splice(indexOfATTS, 1);
        ngToast.create({
          className: 'danger',
          content: 'Client cannot support ATTS Chart without Task Start/Pause Feature'
        });
      }

      $scope.settings.resolveCriteria.startBeforeResolve = false;
      $scope.settings.resolveCriteria.minStartTime = 0;
    }
  };

  // supported task attributes config
  $scope.taskAttributes = [
    {
      label: $scope.clientStrings().regionsPCase,
      value: 'regions',
      disableIf: function() {
        var selectedTaskAttributes = $scope.settings.supportedTaskAttributes;
        return selectedTaskAttributes.indexOf('campaigns') > -1 ||
               selectedTaskAttributes.indexOf('locations') > -1;
      }
    },
    {label: 'Resolve Codes', value: 'resolveCodes'},
    {label: $scope.clientStrings().officesPCase, value: 'locations'},
    {label: $scope.clientStrings().campaignsPCase, value: 'campaigns'},
    {label: $scope.clientStrings().employeesPCase, value: 'employees'},
    {label: $scope.clientStrings().customersPCase, value: 'customers'},
    {label: $scope.clientStrings().equipmentsPCase, value: 'equipments'},
    {label: $scope.clientStrings().inventorysPCase, value: 'inventorys'},
    {label: 'Report Buckets', value: 'reportBuckets'},
    {label: $scope.clientStrings().logsPCase, value: 'logs'},
    { label: $scope.clientStrings().rolesPCase, value: 'roles' },
    { label: $scope.clientStrings().permissionsPCase, value: 'permissions' },
    { label: $scope.clientStrings().languagesPCase, value: 'languages' },
    { label: 'Permission Matrix', value: 'permissionMatrix' },
    { label: 'User Sessions', value: 'usersSession' },
    { label: 'Postgres Data Sync', value: 'postgresDataSync' },
    { label: 'Nationalities', value: 'nationalities' },
    { label: 'Judges', value: 'judges' },
    { label: 'Offering Management', value: 'offeringManagement' },
    { label: 'Program', value: 'program' },
    { label: 'Notification Management', value: 'notificationManagement' },
    { label: 'Interpreter Court Distance', value: 'interpreterCourtDistances' },
    { label: 'Email Suppression List', value: 'suppressionList' },
  ];
  $scope.allTaskAttributes = $scope.taskAttributes.map(function(taskAttribute) {
    return taskAttribute.value;
  });
  var DEFAULT_TASK_ATTRIBUTES = $scope.allTaskAttributes;


  //resolve criteria config
  var DEFAULT_RESOLVE_CRITERIA = {
    startBeforeResolve: false,
    minStartTime: 5
  };


  //supported ui tab config
  $scope.uiTabs = [
    {
      label: $scope.clientStrings().homeRequisitionsPCase,
      value: 'requisitions'
    }, {
      label: $scope.clientStrings().homePipelineOpsPCase,
      value: 'pipelineOps'
    }, {
      label: $scope.clientStrings().homeBucketsPCase,
      value: 'buckets'
    }, {
      label: $scope.clientStrings().homeInterpretersPCase,
      value: 'interpreters'
    }, {
      label: $scope.clientStrings().homeReportsPCase,
      value: 'reports'
    }, {
      label: $scope.clientStrings().homeFinancialPCase,
      value: 'financial'
    }, {
      label: $scope.clientStrings().homeOrganizationPCase,
      value: 'organization',
      disableIf: function () {
        return !$scope.settings.supportedTaskAttributes ||
          $scope.settings.supportedTaskAttributes.indexOf('employees') === -1;
      }
    }, {
      label: $scope.clientStrings().homeCalendarPCase,
      value: 'calendar'
    }
  ];

  //supported footer logo config
  $scope.footerLogo = [{
    label: 'Black',
    value: '/assets/images/coordinate-logo-black.png'
  },{
    label: 'White',
    value: '/assets/images/coordinate-logo.png'
  },{
    label: 'Blue',
    value: '/assets/images/coordinate-logo-blue.png'
  }];

  var DEFAULT_TABS = [];

  function onClientReadyChange(client) {
    $scope.client = client;
    $scope.settings = angular.copy(client);
    $scope.settings.plugins = plugins.list($scope);
    $scope.clientRoles = [{
      label: 'Owner',
      value: 1
    },{
      label: 'Operator',
      value: 2
    },{
      label: 'Limited',
      value: 3
    }];

    $scope.onCleanupFlagChanged = function(supportsCleanup) {
      $scope.settings.cleanupConfig = supportsCleanup ? DEFAULT_CLEANUP : null;
      $scope.settings.cleanupCollection = [];
    };

    $activeClientSettings(function (client, settings) {
      _.extend( $scope.settings, settings );

      if(!$scope.settings) {
        $scope.settings= {};
      }

      if(!$scope.settings.businessDays) {
        $scope.settings.businessDays = {
          0: {enabled: false, min:540 , max: 1020}, //monday
          1: {enabled: false, min:540 , max: 1020}, //tuesday
          2: {enabled: false, min:540 , max: 1020}, //wednesday
          3: {enabled: false, min:540 , max: 1020}, //thursday
          4: {enabled: false, min:540 , max: 1020}, //friday
          5: {enabled: false, min:540 , max: 1020}, //saturday
          6: {enabled: false, min:540 , max: 1020}, //sunday
        };
      }

      $scope.settings.cleanupConfig = settings.cleanupConfig;
      $scope.supportsCleanup = !!$scope.settings.cleanupConfig;
      $scope.settings.supportedTabs = settings.supportedTabs || DEFAULT_TABS;
      $scope.settings.supportedTaskControls = settings.supportedTaskControls || DEFAULT_TASK_CONTROLS;
      // $scope.settings.taskFilters = settings.taskFilters || DEFAULT_TASK_FILTERS;
      $scope.settings.supportedTaskStats = settings.supportedTaskStats || DEFAULT_TASK_STATS;
      $scope.settings.supportedTaskAttributes = settings.supportedTaskAttributes || DEFAULT_TASK_ATTRIBUTES;
      $scope.settings.resolveCriteria = settings.resolveCriteria || DEFAULT_RESOLVE_CRITERIA;
      $scope.settings.supportedTaskTypes = settings.supportedTaskTypes || DEFAULT_TASK_TYPES;
      $scope.settings.mailServerConfig = settings.mailServerConfig || {};
      $scope.settings.IVRConfig = settings.IVRConfig || {};
      $scope.settings.supportsIVR = settings.supportsIVR;
      $scope.settings.scheduleEnforcement = settings.scheduleEnforcement || DEFAULT_SCHEDULE_ENFORCEMENT;
      $scope.settings.supportsDailyReport = !!settings.dailyReportConfig;
      $scope.settings.OTCConfig = settings.OTCConfig || {};
      $scope.settings.supportsOTC = settings.supportsOTC;
      $scope.settings.autoTask = settings.autoTask;
      $scope.settings.autoFacilityOut = !!settings.autoFacilityOut;
      $scope.settings.autoRelease = !!settings.autoRelease;
      $scope.settings.autoNoShow = !!settings.autoNoShow;
      $scope.settings.autoFINEmailNotification = !!settings.autoFINEmailNotification;
      $scope.settings.autoInterpreterApproval = !!settings.autoInterpreterApproval;
      $scope.settings.autoDailyWOReport = !! settings.autoDailyWOReport;
      $scope.settings.coiReminderEmail = !!settings.coiReminderEmail;
      if ($scope.settings.coiReminderEmail) {
        $scope.settings.coiReminder = settings.coiReminder
      }

      $scope.$watch(function() {
        return $scope.settings.supportedTaskAttributes.toString();
      }, function() {
        var selectedTaskAttributes = $scope.settings.supportedTaskAttributes;
        if((selectedTaskAttributes.indexOf('locations') > -1 || selectedTaskAttributes.indexOf('campaigns') > -1) && selectedTaskAttributes.indexOf('regions') === -1) {
          selectedTaskAttributes.push('regions');
        }

        //remove organization tab if employee is unselected
        var indexOfOrganization = $scope.settings.supportedTabs.indexOf('organization');
        if(indexOfOrganization > -1 && selectedTaskAttributes.indexOf('employees') === -1) {
          $scope.settings.supportedTabs.splice(indexOfOrganization, 1);
          ngToast.create({
            className: 'danger',
            content: 'Client cannot support Organization tab without Employees attribute'
          });
        }
      });

      $scope.settings.backgroundColor = settings.backgroundColor || '#5B9BD5';

      $scope.settings.textColor = settings.textColor || '#FFFFFF';
      if (client || settings) {
        loadSubordinateUsers();
        reloadCollections();
        reloadOffices()
      }
    });
  }

  $scope.customSettings = {
    control: 'brightness',
    theme: 'bootstrap',
    position: 'top left'
  };

  function getClientAbilities(client) {
    $client.clientAbilities(client)
      .then(data => {
        $scope.settings.plugins.forEach(function(plugin) {
          var clientPlugin = data.data.options[plugin.id];
          if(clientPlugin) {
            plugin.isEnabled = true;
            // Set as default owner account role in accessLevel for card #690 in SOSI1-DEV
            plugin.accessLevel = 1; //clientPlugin.requireLevel;
          }
          else {
            plugin.isEnabled = false;
          }
        });
      });
  }

  // listen for client switching
  $scope.$on('$destroy', $activeClient(function (client) {
    delete $scope.saveSettings;

    if(!client) {
      return;
    }
    onClientReadyChange(client);
    $scope.currentClientId = client._id;

    getClientAbilities(client);

    // $scope.settings.plugins.forEach(function(plugin) {
    //   var clientPlugin = client.features.options[plugin.id];
    //   if(clientPlugin) {
    //     plugin.isEnabled = true;
    //     plugin.accessLevel = clientPlugin.requireLevel;
    //   }
    //   else {
    //     plugin.isEnabled = false;
    //   }
    // });

    $scope.saveSettings = function (form) {
      if(!form.$valid) {
        return;
      }

      //convert base64 string to js file object
      // var b64 = $scope.file.croppedImage.split(',');
      // var file = new File([window.atob(b64[1])], client.name+'.png', {type: 'image/png'});

      //converting js file object to FileItem object
      // $scope.uploader.queue.push(new FileItem($scope.uploader, file, null));

      $scope.uploadFiles().then(function(results) {
        var saveObject = _.pick($scope.settings,
          'vanityLogo',
          'vanityLogoId',
          'push',
          'footerLogo',
          'backgroundColor',
          'textColor',
          'businessDays',
          'supportedTabs',
          'supportedTaskTypes',
          'supportedTaskControls',
          // 'taskFilters',
          'supportedTaskStats',
          'supportedTaskAttributes',
          'supportTaskStateToggling',
          'resolveCriteria',
          'siteDomain',
          'mailServerConfig',
          'plugins',
          'supportsIVR',
          'IVRConfig',
          'cleanupConfig',
          'scheduleEnforcement',
          'checkQueuedNotificationInterval',
          'dailyReportConfig',
          'cleanupCollection',
          'supportsOTC',
          'OTCConfig',
          'autoTask',
          'supportsMail',
          'autoFacilityOut',
          'autoRelease',
          'autoNoShow',
          'autoFINEmailNotification',
          'autoInterpreterApproval',
          'autoDailyWOReport'
        );

        if(results && results.length > 0){
          saveObject.vanityLogo = '/uploads/' + results[0].path;
          saveObject.vanityLogoId = results[0]._id;
        }

        // Save settings
        return ClientSetting.apply({id: client._id}, saveObject).$promise
        .then(function () {
          window.location.reload();
        })
        .finally(function () {
          $scope.loading = false;
        });
      });
    };
  }));

  var handleFileSelect = function(uploader) {
    var reader = new FileReader();
    reader.onload = function (evt) {
      $scope.$apply(function($scope){
        $scope.file.uploadedImage=evt.target.result;
      });
    };
    reader.readAsDataURL(uploader.queue[0]);
  };

  $scope.fileNameChanged = function (ele) {
    $scope.file.changed = true;
    $scope.uploader.queue = [];
    $scope.uploader.queue = ele.files;
    handleFileSelect($scope.uploader);
  };


  $scope.uploader = new FileUploader({
    url: appConfig.apiUrl + '/api/client-uploads/' + $scope.currentClientId,
    headers : {
        'Authorization': 'Bearer ' + $authStorage.getToken()
    },
    alias: 'upload',
    filters: [
      {
        name: 'extensionFilter',
        fn: function(item) {
          var extension = item.name.substr(item.name.lastIndexOf('.')+1);
          var invalidExtensions = ['exe', 'bin'];
          if(invalidExtensions.indexOf(extension) > -1) {
            ngToast.create({
              className: 'danger',
              content: 'Files with extensions '+invalidExtensions+' are not allowed'
            });
            return false;
          } else {
            return true;
          }
        }
      }
    ]
  });

  $scope.uploadFiles = function () {
    // var
    // uploader = $scope.uploader,
    // results = [],
    // errors = [];

    // if(uploader && uploader.queue && uploader.queue.length) {
    //   var defer = $q.defer();

    //   uploader.onSuccessItem = function(fileItem, response) {
    //     Array.prototype.push.apply(results, response);
    //   };

    //   uploader.onErrorItem = function(fileItem) {
    //     errors.push(new Error('Failed to upload file: ' + fileItem.file.name));
    //   };

    //   uploader.onCompleteAll = function() {
    //     if(errors.length > 0) { // fail on any errors
    //       defer.reject(errors);
    //       return;
    //     }

    //     defer.resolve(results);
    //   };

    //   uploader.uploadAll();

    //   return defer.promise;
    // }
    if ($scope.uploader && $scope.uploader.queue && $scope.uploader.queue.length) {
      var defer = $q.defer();
      $uploadS3Attachment.upload(($scope.client._id || $scope.client), $scope.uploader, $scope, 'control1')
        .then((res) => {
          if (res && res.length && res[0].status) {
            defer.resolve([res[0].clientUpload]);
          } else {
            defer.reject(res.message || res);
          }
        })
        .catch((err) => {
          defer.reject(err);
        })
      return defer.promise;
    }

    return $q.when(false);
  };

  // $scope.cropImage = function() {
  //   $scope.file.changed = false;

  //   $scope.settings.settings.vanityLogo = $scope.file.croppedImage;
  // };

  // $scope.cancelCropping = function() {
  //   $scope.file.changed = false;
  // };

  $scope.getLogoUrl = function () {
    if ($scope.settings && $scope.settings.vanityLogo) {
      // let downloadUrl  = '/attachment/'+($scope.client._id || $scope.client)+'/'+$scope.settings.vanityLogoId;
      // return downloadUrl+'?preview=0';
      // return $scope.settings.vanityLogo;
      $attachment.download(($scope.client._id || $scope.client), $scope.settings.vanityLogoId)
        .then((res) => {
          if (res && res.status && res.url) {
            $scope.setLogo = res.url
          } else {
            let downloadUrl = appConfig.apiUrl + '/attachment/' + ($scope.client._id || $scope.client) + '/' + $scope.settings.vanityLogoId;
            $scope.setLogo = downloadUrl + '?preview=0';
          }
        })
        .catch((err) => {
          console.log(err)
        });
    } else {
      return '';
    }
  };
  setTimeout(() => {
    $scope.getLogoUrl();
  }, 1000);

  $scope.stopPropagation = function($event) {
    $event.preventDefault();
  };

  $scope.addCollection = function(){
    var addCollection = {
      name: 0,
      fieldName: 0,
      days: 0
    };
    if(!$scope.settings.cleanupCollection){
      $scope.settings.cleanupCollection = []
    }
    $scope.settings.cleanupCollection.push(addCollection);
  }

  $scope.removeCollection = function(index){
    $scope.settings.cleanupCollection.splice(index, 1);
  }

  $scope.removeCOIReminder = function(e) {
    if (!$scope.settings.coiReminderEmail) {
      $scope.settings.coiReminder = {
        osi:null, opi: null, vri: null,
        interpreterReminder: false,
        coordinatorReminder: false
      }
    }
  }

  function reloadCollections() {
    $scope.collections = [];
    return $clientTask.getCollections($scope.client, true)
      .then(function (collections) {
        $scope.collections = collections.map(function (collection) {
          return {
            label: collection.name,
            value: collection._id,
            taskTypes: collection.types.map(function (taskType) {
              return {
                label: taskType,
                value: taskType
              };
            })
          };
        });
      });
  };

  function reloadOffices() {
    return $clientOffice.listAll($scope.client)
      .then(function (offices) {
        $scope.offices = offices.map(function (office) {
          return {
            value: office._id,
            label: office.name
          };
        });
      });
  };

}

// Dependency Injection
DashboardSettingsIndexCtrl.$inject = ["$rootScope","$scope","$state","$activeClient","Modal","$clientSetting","$attachment","$uploadS3Attachment","$activeClientSettings","FileUploader","ngToast","$q","$authStorage","FileItem","ClientSetting","$clientUser","$database","$clientTask","$clientOffice","$client","plugins","appConfig", "$timeout", "LazyLoadService"];

// angular.module('coordinateApp').controller('DashboardSettingsIndexCtrl', DashboardSettingsIndexCtrl);
