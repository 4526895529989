'use strict';

export const workOrdersDirective = function ($rootScope, $state, $stateParams, ngToast, $timeout, LazyLoadService) {
    return {
      restrict: 'E',
      templateUrl: 'app/account/dashboard/main-dashboard/work-orders/work-orders.html',
      scope: {
        modelData: '=ngModel',
        childTabs: '=childTabs',
        layoutDefaultTabing: '&layoutDefaultTab'
      },
      link: function(scope) {

  const lazyDirectives = [{"name":"openWorkOrdersDirective","path":import("@app/account/dashboard/main-dashboard/interpreter-resource/tabs/open-work-orders/open-work-orders.directive.js")},{"name":"woForecastingDirective","path":import("@app/account/dashboard/main-dashboard/work-orders/tabs/wo-forecasting/wo-forecasting.directive.js")},{"name":"bucketsDirective","path":import("@app/account/dashboard/tasks/tabs/buckets/buckets.directive.js")},{"name":"sosEmailInformationDirective","path":import("@app/account/dashboard/main-dashboard/work-orders/tabs/sos-email-information/sos-email-information.directive.js")},{"name":"dashboardNewDirective","path":import("@app/account/dashboard/main-dashboard/work-orders/tabs/dashboard-new/dashboard-new.directive.js")},{"name":"reportingDashboardDirective","path":import("@app/account/dashboard/main-dashboard/sosi-one-reporting/tabs/dashboard/dashboard.directive.js")},{"name":"interpreterWorkReportDirective","path":import("@app/account/dashboard/main-dashboard/work-orders/tabs/interpreter-work-report/interpreter-work-report.directive.js")}];
  scope.lazyDirectivesLoaded = false;
  Promise.all(lazyDirectives.map(directive=>LazyLoadService.loadDirective(directive.path, directive.name))).then(() => {
          $timeout(function() {
            scope.lazyDirectivesLoaded = true;

                 let selectedTab = null;
        scope.IcTabs = [];
        var init = function () {
          scope.tabs()
        }

        scope.tabs = function () {
          let tabs = scope.childTabs;

          let data = scope.layoutDefaultTabing({obj:{tabs, layoutOf: 'workOrders'}})
          scope.IcTabs = data.avtiveTabs;
          selectedTab = data.selectedTab;

          if (scope.modelData && scope.modelData.params && scope.modelData.params.child) {
            selectedTab = scope.modelData.params.child
          }
          // if ($stateParams.child ) {
          //   selectedTab = $stateParams.child;
          //   if ($stateParams.child == 'work_orders' && !scope.modelData.userPermissions.includes('WORK_ORDER_TAB')) {
          //     selectedTab = '';
          //   }
          // }
          if ($stateParams.work_orders) {
            if (scope.modelData.userPermissions && scope.modelData.userPermissions.length && scope.modelData.userPermissions.includes('WORK_ORDER_TAB')) {
              selectedTab = 'wo_status';
            } else {
              ngToast.create({
                content: 'Permission Denied!',
                className: 'danger'
              });
            }
          } else if($stateParams.child){
            selectedTab = $stateParams.child;
          }
        }

        scope.tabSelected = function (tabName) {
          selectedTab = tabName;
          $rootScope.$broadcast('onTabChange', selectedTab);
        };

        scope.isTabSelected = function (tabName) {
          return selectedTab === tabName;
        };

        init()

  })
});
}
}
}
//Dependency Injection
workOrdersDirective.$inject = ["$rootScope","$state","$stateParams","ngToast", "$timeout", "LazyLoadService"];
