
'use strict';

export const $cognitoUserService  = function ($cognitoUserPool) {
        return function (userName) {
            let userPool = $cognitoUserPool();
            let cognitoUser = userPool.getCurrentUser();
            return new Promise((resolve, reject) => {
                if (cognitoUser) {
                    cognitoUser.getSession(function (err, result) {
                        if (err) return reject(err)
                        if (result) {
                            cognitoUser.signInUserSession = result;
                            resolve(cognitoUser)
                        }
                    })
                } else {
                    if (userName) {
                        var userData = {
                            Username: userName,
                            Pool: userPool
                        };
                        cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
                        resolve(cognitoUser);
                    } else {
                        reject('User not found!');
                    }
                }
            });
        }
    }
 

// Dependency Injection
$cognitoUserService.$inject = ['$cognitoUserPool'];
