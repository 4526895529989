'use strict';


export const numericonlyDirective = function () {  
    return {  
        require: 'ngModel',  
        link: function (scope, element, attr, ngModelCtrl) {  
            function fromUser(text) {  
                var transformedInput = text.replace(/[^0-9 ]/g, '').trim();  
                if (transformedInput !== text) {  
                    ngModelCtrl.$setViewValue(transformedInput);  
                    ngModelCtrl.$render();  
                }  
                return transformedInput;   
            }  
            ngModelCtrl.$parsers.push(fromUser);  
        }  
    };  
}