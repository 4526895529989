'use strict';

export const ServiceDetailCtrl = function ($rootScope, $scope, $activeClient, $auth, $clientSwitcher, $servicePanel, ngToast, $uploadS3Attachment, $serviceComments) {

        // $scope.data = {
        //     newComment: '',
        //     userProfile: 'SOSi',
        //     isLoadingComments: false,
        //     isLoading: false,
        //     error: null
        // };
        // $scope.masterService = {};
        // let killClientWatch, currentCli = $clientSwitcher.getCurrentClient();

        // $scope.commentPagination = {
        //     currentPage: 1,
        //     page: 1,
        //     total: 0,
        //     pageSize: 20,
        //     itemsPerPage: 20
        // };
        // $scope.serviceStatusList = ['quote_requested', 'quote_approved', 'work_in_process', 'work_completed', 'work_accepted', 'pending_payment', 'service_completed']

        // // $scope.commentPagination.total = $selectedService.totalComments

        // $scope.statusActivity = []
        // function init() {
        //     $scope.data.isLoading = true;
        //     $scope.masterService.isLoading = true;
        //     killClientWatch = $activeClient(function (client) {
        //         if (!client) {
        //             $scope.data.isLoading = false;
        //             return;
        //         }
        //         currentCli = client;
        //         let userPermissions = $auth.getCurrentUser();
        //         $scope.data.userProfile = userPermissions.name;
        //         $scope.data.userPermissions = userPermissions.permissions || [];

        //         $scope.masterService = $selectedService.status ? angular.copy($selectedService.service) : {};
        //         if (!$selectedService.status) {
        //             $scope.data.isLoading = false;
        //             $scope.data.error = $selectedService.message
        //         } else {
        //             $scope.data.error = null;
        //             $scope.data.serviceRefId = $scope.masterService._id || null;
        //             $scope.setStatusActivity();
        //             $scope.loadComments()
        //         }
        //         // $scope.masterService.isLoading = false;
        //     });

        // }

        // $scope.$on('$destroy', function () {
        //     if (killClientWatch) {
        //         killClientWatch();
        //     }
        // });

        // $scope.setStatusActivity = function () {
        //     let statusActivity = _.groupBy($scope.masterService.statusActivity, 'status');
        //     $scope.statusActivity = Object.keys(statusActivity) || [];
        // }
        // $scope.getService = function () {
        //     $scope.masterService.isLoading = true;
        //     $scope.data.isLoading = true;
        //     $servicePanel.getServiceById(currentCli, $scope.masterService._id).then((res) => {
        //         console.log(res)
        //         if (res.status) {
        //             $scope.masterService = angular.copy(res.service);
        //             $scope.masterService.userComments = [];
        //             $scope.masterService.isLoading = false;
        //             $scope.masterService.uploader = [];
        //             $scope.loadComments();
        //         } else {

        //         }
        //     }, err => {
        //         console.log(err)
        //         // $state.go('app.account.services.servicePanel.index');
        //     })
        // }
        // $scope.updateDetail = function () {
        //     $servicePanel.update(currentCli, $scope.masterService)
        //         .then(function (result) {
        //             if (result && result.status) {
        //                 ngToast.create({
        //                     className: 'success',
        //                     content: 'New Transcription Service Requested Successfully!'
        //                 });
        //                 // $state.go('app.account.services.servicePanel.index');
        //                 $scope.getService()
        //             }
        //             $scope.masterService.isLoading = false;
        //         })
        //         .catch((err) => {
        //             let errMessage = err.message || err;
        //             if (err && err.data && err.data.message) {
        //                 errMessage = err.data.message;
        //             } else if (err && err.data && err.data.reason) {
        //                 errMessage = err.data.reason;
        //             }
        //             ngToast.create({
        //                 className: 'danger',
        //                 content: errMessage
        //             });
        //             $scope.masterService.isLoading = false;
        //         })
        // }
        // $scope.submitNewComment = function () {
        //     if ($scope.data.isLoadingComments) return;
        //     $scope.data.isLoadingComments = true;
        //     $serviceComments.create(currentCli, $scope.data)
        //         .then(res => {
        //             $scope.data.isLoadingComments = false;
        //             $scope.data.newComment = '';
        //             $scope.masterService.userComments = [res].concat($scope.masterService.userComments || [])
        //             $scope.commentPagination.total = $scope.commentPagination.total + 1;
        //             if ($scope.masterService.userComments.length > $scope.commentPagination.pageSize) {
        //                 $scope.masterService.userComments = $scope.masterService.userComments.slice(0, $scope.commentPagination.pageSize)
        //             }
        //         }, err => {
        //             $scope.data.isLoadingComments = false;
        //             console.log(err)
        //         })
        // }
        // $scope.onUpdate = function (form) {
        //     // console.log(form)
        //     if (form.$invalid || !currentCli || $scope.data.isLoadingonsite) return;
        //     $scope.masterService.isLoading = true;
        //     // console.log($scope.masterService)
        //     if ($scope.masterService.uploader && $scope.masterService.uploader.queue && $scope.masterService.uploader.queue.length) {
        //         $uploadS3Attachment.upload(currentCli, $scope.masterService.uploader, $scope, 'control1')
        //             .then((imageRes) => {
        //                 $scope.masterService.newAttachments = [];
        //                 if (imageRes && imageRes.length) {
        //                     imageRes.forEach(function (upload) {
        //                         if (upload.status) {
        //                             $scope.masterService.newAttachments.push(upload.clientUpload._id);
        //                         }
        //                     });
        //                     angular.forEach(
        //                         angular.element("input[type='file']"),
        //                         function (inputElem) {
        //                             angular.element(inputElem).val(null);
        //                         });
        //                     $scope.updateDetail();
        //                 }
        //             })

        //             .catch((err) => {
        //                 let errMessage = err.message || err;
        //                 if (err && err.data && err.data.message) {
        //                     errMessage = err.data.message;
        //                 } else if (err && err.data && err.data.reason) {
        //                     errMessage = err.data.reason;
        //                 }
        //                 ngToast.create({
        //                     className: 'danger',
        //                     content: errMessage
        //                 });
        //                 $scope.masterService.isLoading = false;
        //             })
        //     } else {
        //         $scope.updateDetail();
        //     }
        // };

        // ($scope.onReset = function (form) {
        //     angular.forEach(
        //         angular.element("input[type='file']"),
        //         function (inputElem) {
        //             angular.element(inputElem).val(null);
        //         });
        //     $scope.masterService = angular.copy($scope.masterService);
        //     if (form) form.$setUntouched();
        // })();

        // $scope.loadComments = function () {
        //     if (!$scope.masterService._id) return;
        //     $scope.commentsLoading = true;
        //     $serviceComments.getList(currentCli, $scope.commentPagination, { serviceId: $scope.masterService._id }).then(res => {
        //         $scope.masterService.userComments = res.result;
        //         $scope.commentPagination.total = res.totalRecords;
        //         $scope.commentsLoading = false;
        //         $scope.data.isLoading = false;
        //     }, err => {
        //         console.log(err)
        //         $scope.commentsLoading = false;
        //         $scope.data.isLoading = false;
        //     })
        // }
        // init()
    }
 export const humanizeStringFilter = function () {
        return function (str) {
            if (!str) return str;
            var i, frags = str && str.length ? str.split('_') : [str]; //str.split('_');
            if (frags && frags.length) {
                for (i = 0; i < frags.length; i++) {
                    frags[i] = frags && frags[i] ? frags[i].charAt(0).toUpperCase() + frags[i].slice(1) : frags;
                }
                return frags.join(' ');
            } else {
                return str
            }
        }
    }
 export const getFirstLatterFilter = function () {
        return function (str) {
            let names = str && str.length ? str.split(' ') : [str];
            return names.length >= 2 ? names[0].charAt(0) + '' + names[1].charAt(0) : str.charAt(0);
        }
    }
// Dependency Injection
ServiceDetailCtrl.$inject = ["$rootScope","$scope","$activeClient","$auth","$clientSwitcher","$servicePanel","ngToast","$uploadS3Attachment","$serviceComments"];
