
'use strict';

export const $openWorkOrdersService  = function (OpenWorkOrders) {
        return {
            getOpenWOCount: function (client, filter) {
                return OpenWorkOrders.getOpenWOCount({
                    id: client._id || client,
                    filterBy: filter
                }).$promise;
            },
            getSystemAppliedCount: function (client, filter) {
                return OpenWorkOrders.getSystemAppliedCount({
                    id: client._id || client,
                    filterBy: filter
                }).$promise;
            },
            getWithdrawCount:  function (client, filter) {
                return OpenWorkOrders.getWithdrawCount({
                    id: client._id || client,
                    filter
                }).$promise;
            },
            getUnavailableInterpreter: function(client, filter) {
                return OpenWorkOrders.getUnavailableInterpreter({
                    id: client._id || client,
                    filter: filter
                }).$promise;
            },
            getInterpreterScheduledCount: function (client, filter) {
                return OpenWorkOrders.getInterpreterScheduledCount({
                    _id: client._id || client,
                    filterBy: filter
                }).$promise;
            },
            getScheduledDetail: function (client, filter) {
                return OpenWorkOrders.getScheduledDetail({
                    _id: client._id || client,
                    filterBy: filter
                }).$promise;
            },
            getOpenWorkOrderCount: function(client, filter) {
                return OpenWorkOrders.getOpenWorkOrderCount({
                    _id: client._id || client,
                    filter
                }).$promise;
            },
            getOpenWO: function (client, filter) {
                return OpenWorkOrders.getOpenWO({
                    _id: client._id || client,
                    filterBy: filter
                }).$promise;
            },
            create: function (client, data) {
                return OpenWorkOrders.create({
                    _id: client._id || client,
                    data: data
                }).$promise;
            },
            update: function (client, data) {
                return OpenWorkOrders.update({
                    _id: client._id || client,
                    data: data
                }).$promise;
            },
            getFilterRecords: function (client, filter) {
                return OpenWorkOrders.getFilterRecords({
                    _id: client._id || client,
                    filterBy: filter
                }).$promise;
            },
            assignedWOWithdraw: function (client, data) {
                return OpenWorkOrders.assignedWOWithdraw({
                    _id: client._id || client,
                    data: data
                }).$promise;
            },
            getOpenWONew: function (client, filter) {
                return OpenWorkOrders.getOpenWONew({
                    _id: client._id || client,
                    filterBy: filter
                }).$promise;
            },
            interpreterListOfferingRule: function (client, filter) {
                return OpenWorkOrders.interpreterListOfferingRule({
                    _id: client._id || client,
                    filter
                }).$promise;
            },
            openWOAssignInterpreter: function (client, data) {
                return OpenWorkOrders.openWOAssignInterpreter({
                    _id: client._id || client,
                    data
                }).$promise;
            },
            openWOConfirmInterpreter: function (client, data) {
                return OpenWorkOrders.openWOConfirmInterpreter({
                    _id: client._id || client,
                    data
                }).$promise;
            },
            getCoiTaskIds: function(client, cois) {
                return OpenWorkOrders.getCoiTaskIds({
                    id: client._id || client,
                    cois
                }).$promise;
            },
            sendDigestMail: function(client, data) {
                return OpenWorkOrders.sendDigestMail({
                    _id: client._id || client,
                    data
                }).$promise;
            },
            getOpenWOId: function(client, interpreterID, coi) {
                return OpenWorkOrders.getOpenWOId({
                    _id: client._id || client,
                    coi, interpreterID
                }).$promise;
            },
            reqICToWithdrawCOI: function(client, interpreterID, coi) {
                return OpenWorkOrders.reqICToWithdrawCOI({
                    id: client._id || client,
                    coi, 
                    interpreterID
                }).$promise;
            },
        };
    }
// Dependency Injection


// Dependency Injection
$openWorkOrdersService.$inject = ['OpenWorkOrders'];
