


'use strict';

export const MobileAppFactory  = function ($resource, appConfig) {
    return $resource(appConfig.apiUrl + '/api/get-mobile-app/:controller', {},
    {
        getAllApps: {
        method: 'GET',
        params: {
          controller: 'getAllApps'
        },
        isArray: true
      }
    });
  } 
 

// Dependency Injection
MobileAppFactory.$inject = ['$resource', 'appConfig'];
