import './recruiting.scss';
'use strict';

export const recruitingDirective = function () {
        return {
            restrict: 'E',
            templateUrl: 'app/account/dashboard/main-dashboard/pipline-operations/tabs/recruiting/recruiting.html',
            scope: {  },
            link: function (scope) {
                scope.data = {
                    candidateExpand: true,
                    organizationExpand: true
                }
                var init = function () {
                }
                init()
            }
        }
    }