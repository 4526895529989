


'use strict';

export const $officeOperationColumnFilterService  = function () {
  return function (title) {
      let filtertableCol = {
          printEcoi: false,
          facilityIn: false,
          facilityOut: false,
          ecoi: false,
          jin: false,
          jout: false,
          lin: false,
          lout: false,
          late: false
      }
      // filtertableCol = _.mapValues(filtertableCol, () => false);
      switch (title) {
          case 'Late':
                  filtertableCol.printEcoi = false;
                  filtertableCol.facilityIn = false;
                  filtertableCol.facilityOut = false;
                  filtertableCol.ecoi = false;
                  filtertableCol.jin = false;
                  filtertableCol.jout = false;
                  filtertableCol.lin = false;
                  filtertableCol.lout = false;
                  filtertableCol.late = true;
                  return filtertableCol;
          case 'Released':
          case 'Extended':
              filtertableCol.printEcoi = true;
              filtertableCol.ecoi = true;
              filtertableCol.jin = true;
              filtertableCol.jout = true;
              filtertableCol.facilityIn = true;
              return filtertableCol;
          case 'Confirmed':
              filtertableCol.late = true;
              return filtertableCol;
          case 'In Facility':
              filtertableCol.printEcoi = true;
              filtertableCol.facilityIn = true;
              filtertableCol.ecoi = true;
              filtertableCol.jin = true;
              filtertableCol.jout = true;
              filtertableCol.lin = true;
              filtertableCol.lout = true;
              filtertableCol.late = true;
              return filtertableCol;
          case 'Completed':
              filtertableCol.printEcoi = true;
              filtertableCol.facilityIn = true;
              filtertableCol.facilityOut = true;
              filtertableCol.late = true;
              filtertableCol.ecoi = true;
              filtertableCol.jin = true;
              filtertableCol.jout = true;
              filtertableCol.lin = true;
              filtertableCol.lout = true;
              return filtertableCol;
          default:
              filtertableCol = _.mapValues(filtertableCol, () => true);
              filtertableCol.jin = false;
              filtertableCol.jout = false;
              filtertableCol.lin = false;
              filtertableCol.lout = false;
              return filtertableCol;
      }
  }

}

