import './work.scss';
'use strict';

export const workTableDirective = function ($auth) {
    return {
      restrict: 'E',
      templateUrl: 'app/account/dashboard/time-production-reporting/tables/work/work.html',
      scope: {
        employee: '=ngModel',
        MODES: '=modes',
        printMode: '=printMode',
        changeMode: '&',
        createEntry: '&',
        updateEntry: '&',
        deleteEntry: '&',

        deleteEntries: '&',
        updateEntries: '&',
        workOvertimeReport: '&',
        canEdit: '&',
        shiftDetailsEntry: '&'
      },
      link: function($scope) {
        $scope.timezone = $auth.getCurrentUser().timezone || moment.tz.guess();
        $scope.minDate = $scope.minDate ? null : new Date();
        $scope.formats = ['MM/dd/yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
        $scope.format = $scope.formats[0];
        $scope.currentDate = moment().format();
        $scope.popup1 = {
          opened: false
        };
        $scope.open1 = function() {
          $scope.popup1.opened = true;
        };

        $scope.dateOptions = {
          formatYear: 'yy',
          startingDay: 1
        };
        var getHashCode = function(str) {
          var hash = 0;

          if (str.length === 0) {
            return hash;
          }

          for (var i = 0; i < str.length; i++) {
            var chr   = str.charCodeAt(i);
            hash  = ((hash << 5) - hash) + chr;
            hash |= 0; // Convert to 32bit integer
          }

          return hash;
        };

        var padNum = function(num) {
          return (num < 10 ? '0' : '') + num;
        };

        $scope.$watch(function() {
          var modes = $scope.employee.modes || [];
          var reportingData = JSON.stringify($scope.employee.reportingData);
          
          return getHashCode(modes.toString() + ' ' + reportingData);
        }, function() {
          $scope.employee.reportingData
          .filter(function(report) {
            if(report.COShift && report.COShift.reportTime && report.COShift.shiftDate) {
              var reportTimeDate = moment(report.COShift.reportTime).format('MM-DD-YYYY');
              var shiftDate = moment(report.COShift.shiftDate).format('MM-DD-YYYY');

              // report.hasDifferentShiftDate = reportTimeDate !== shiftDate && report.COShift.shift != 3;
            }

            return report.totalDuration;
          })
          .forEach(function(report) {
            var totalDurationMin = report.totalDuration / 60000;
            var hours = Math.floor(totalDurationMin / 60);
            var mins = Math.floor(totalDurationMin % 60);
            // Change in Red Logic | Only color Red if total time > 12 hours (remove any logic related to Shift)
            report.hasDifferentShiftDate = padNum(hours) > 12;
            report.totalDurationFragments = {
              hours: padNum(hours),
              minutes: padNum(mins),
            };
          });
        });

        $scope.hasShiftOutError = function(report){
          var COShift = report.COShift ? report.COShift.reportTime : '';
          var breaks2 = report.breaks[2] ? report.breaks[2].reportTime : '';
          var CILunch = report.CILunch ? report.CILunch.reportTime : '';
          var COLunch = report.COLunch ? report.COLunch.reportTime : '';
          var breaks3 = report.breaks[3] ? report.breaks[3].reportTime : '';
          if(COShift < breaks2){
            return "Clock Out can't be before Last Break";
          }else if(COShift < CILunch){
            return "Clock Out can't be before Lunch Break";
          }else if(COShift < COLunch){
            return "Clock Out can't be before Lunch Break";
          }else if(COShift < breaks3){
            return "Clock Out can't be before Last Break";
          }
        }
      }
    };
  }
// Dependency Injection
workTableDirective.$inject = ["$auth"];
