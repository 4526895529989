'use strict';

export const contractDocsDirective = function () {
        return {
            restrict: 'E',
            templateUrl: 'app/account/dashboard/main-dashboard/interpreter-resource/tabs/contract-docs/contract-docs.html',
            scope: {  },
            link: function (scope) {
                var init = function () {
                }
                init()
            }
        }
    }