import './main-dashboard.scss';
'use strict';

export const MainDashboardConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard.mainDashboard', {
        url: '/main-dashboard?view&work_orders&attachment&ic',
        params: {
          view: null,
          child: null,
          work_orders: null,
          attachment: null,
          sub_child: null,
          ic: null,
          search: null
        },
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/main-dashboard/main-dashboard.html', '797412aa'); }],
        controller: 'MainDashboardCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/main-dashboard/main-dashboard.controller.js', 'MainDashboardCtrl'); }]
                      },
        data: {
          ability: 'client.mainDashboard',
          permission: 'MAIN_DASHBOARD_READ'
        }
      })
      .state('app.account.dashboard.woInvoices', {
        url: '/main-dashboard/wo-invoices/:id',
        data: {
          ability: 'client.mainDashboard',
          permission: 'WO_INVOICES_TAB'
        },
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/main-dashboard/work-orders/tabs/wo-invoices/invoice-detail/invoice-detail.html', 'eaf1dcca'); }],
        controller: 'WOInvoiceDetailCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/main-dashboard/work-orders/tabs/wo-invoices/invoice-detail/invoice-detail.controller.js', 'WOInvoiceDetailCtrl'); }]
                      }
      });
  }

// Dependency Injection
MainDashboardConfig.$inject = ["$stateProvider"];
