'use strict';


export const $pageTitleService = function () {
    var
    title = 'Elisa';

    return {
      title: function() {
        return title;
      },
      setTitle: function(newTitle) {
        title = newTitle;
      }
    };
  }