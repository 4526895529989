'use strict';

export const NotificationsManagementConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard.settings.notifications-management', {
        url: '/notification-management',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/notifications-management/notifications-management.html', '92829a6a'); }],
        controller: 'DashboardSettingsNotificationManageCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/notifications-management/notifications-management.controller.js', 'DashboardSettingsNotificationManageCtrl'); }]
                      },
        data: {
          ability: 'manage.client.users'
        }
      })
      .state('app.account.dashboard.settings.notifications-management.index', {
        url: '/notifications-management/index',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/notifications-management/index/index.html', 'cdbb74a'); }],
        controller: 'DashboardSettingsNotificationManageIndexCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/notifications-management/index/index.controller.js', 'DashboardSettingsNotificationManageIndexCtrl'); }]
                      },
        data: {
          ability: 'manage.client.users'
        }
      })
  }
// Dependency Injection
NotificationsManagementConfig.$inject = ["$stateProvider"];
