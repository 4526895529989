'use strict';

export const AssignmentStatusReportConfig = function ($stateProvider) {
    $stateProvider
      .state('app.print.assignment-status-report', {
        url: '/live-reports/monthly-reports/assignment-status-report',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./print/live-reports/monthly-reports/assignment-status-report/assignment-status-report.html', 'a22b3e88'); }],
        controller: 'PrintAssignmentStatusReportCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./print/live-reports/monthly-reports/assignment-status-report/assignment-status-report.controller.js', 'PrintAssignmentStatusReportCtrl'); }]
                      }
      });
  }
// Dependency Injection
AssignmentStatusReportConfig.$inject = ["$stateProvider"];
