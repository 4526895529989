


'use strict';

export const CourtOperationsFactory  = function ($resource, appConfig) {
        return $resource(appConfig.apiUrl + '/api/court_operations/:id/:controller', {
            id: '@_id'
        },
            {
                courtOpsCases: {
                    method: 'POST',
                    params: {
                        id: '@id',
                        controller: 'court-ops-cases'
                    }
                },
                filterCourtOps: {
                    method: 'POST',
                    params: {
                        id: '@id',
                        controller: 'filter-court-ops'
                    }
                },
                getCasesDetails: {
                    method: 'GET',
                    params: {
                        id: '@id'
                    }
                },
                courtOpsCasesCount: {
                    method: 'POST',
                    params: {
                        id: '@id',
                        controller: 'dashboard-count'
                    }
                },
                _courtOpsCases: {
                    method: 'POST',
                    params: {
                        id: '@id',
                        controller: 'court-ops-cases-new'
                    }
                },
                judgeCases: {
                    method: 'POST',
                    params: {
                        id: '@id',
                        controller: 'judge-wos'
                    }
                },
                judgeCasesFilter: {
                    method: 'POST',
                    params: {
                        id: '@id',
                        controller: 'judge-wos-filter'
                    }
                },
            });
    } 
 

// Dependency Injection
CourtOperationsFactory.$inject = ['$resource', 'appConfig'];
