'use strict';

export const headerMenuDirective = function ($rootScope, $clientSwitcher, $clientUser, $state, $stateParams, $auth, socket, $layoutItems, $activeClientSettings) {
        return {
            restrict: 'E',
            templateUrl: 'app/account/dashboard/main-dashboard/header/header.html',
            scope: {},
            link: function ($scope) {

                $scope.filter = {
                    ui: '',
                    childTab: ''
                };
                $scope.data = {
                    userPermissions: [],
                    userSettings: {},
                    isLoading: true
                };
                $scope.childLayoutItem = {}
                $scope.layoutItems = []
                $scope.$on('$destroy', $activeClientSettings(function (client, settings) {
                    $scope.clientSettings = settings || null;
                    if (client) {
                        $scope.getCurrentUser = $auth.getCurrentUser;
                        $scope.authUser =  $auth.getCurrentUser();
                        $scope.data.userPermissions = $scope.authUser.permissions;
                        $scope.data.userSettings = $scope.authUser.settings;
                        setTimeout(() => {
                            init();
                        }, 0);
                        // $clientUser.getUserPermissions(client)
                        //     .then(data => {
                        //         $scope.data.userPermissions = data.permissions;
                        //         $scope.getCurrentUser = $auth.getCurrentUser;
                        //         $scope.authUser =  $auth.getCurrentUser();
                        //         $scope.data.userSettings = $scope.authUser.settings;

                        //     })
                        //     .then(() => init());
                    }
                }));

                var init = function () {
                    $scope.hasMaindashBoardPermissions = $scope.data && $scope.data.userPermissions && $scope.data.userPermissions.length ? $scope.data.userPermissions.includes('MAIN_DASHBOARD_READ') : [];
                    let pages = $layoutItems($scope.data.userPermissions, $scope.authUser, $scope.clientSettings);
                    $scope.layoutItems = [];
                    pages.map((tab) => {
                        if (tab.permissions) {
                            $scope.layoutItems.push(tab);
                            $scope.childLayoutItem[tab.value] = tab.child;
                            if (!$scope.filter.ui && $scope.data) {
                                if (tab.value == $scope.data.userSettings.defaultDashboardView && !$stateParams.view && !$stateParams.work_orders) {
                                    $scope.filter.ui = $scope.data.userSettings.defaultDashboardView;
                                    $scope.filter.childTab = $scope.data.userSettings.defaultDashboardTabs[$scope.filter.ui];
                                } else if ($stateParams.view == tab.value) {
                                    $scope.filter.ui = tab.value;
                                    $scope.filter.childTab = $scope.data.userSettings.defaultDashboardTabs[$scope.filter.ui];
                                } else if($stateParams.ic && $scope.data.userPermissions && $scope.data.userPermissions.length && $scope.data.userPermissions.includes('PROFILE_TAB')) {
                                    $scope.filter.ui = 'piplineOperations';
                                } else if (($stateParams.work_orders && $scope.data && $scope.data.userPermissions && $scope.data.userPermissions.length && $scope.data.userPermissions.includes('WORK_ORDER_READ'))) { // || ($stateParams.view == 'workOrders')
                                    $scope.filter.ui = 'workOrders';
                                }
                            }
                        }
                    })
                    if ($scope.layoutItems.length && !$scope.filter.ui && !$stateParams.work_orders && !$stateParams.view) {
                        $scope.filter.ui = $scope.layoutItems[0].value
                    }
                    if ($scope.filter.ui == 'programSupport') {
                        $scope.filter.ui = $scope.layoutItems[0].value
                    }
                    setTimeout(() => {
                        $scope.data.isLoading = false;
                        $scope.$apply();
                    }, 1000)
                }

                $scope.tabSelected = function (tabName, selectedData) {
                    selectedTab = tabName;
                    $scope.data.selectedData = selectedData
                };

                $scope.onLayoutChange = function (currentView) {
                    $state.go($state.current.name, { task: null, view: null }, { notify: false });
                }

                $scope.switchTab = function (arg) {
                    $scope.data.params = arg
                    var url = $state.href($state.current.name, { task: arg.taskId, view: arg.view }, { notify: false });
                    return url
                }

                $scope.layoutDefaultTab = function (obj) {
                    let avtiveTabs = [];
                    let selectedTab = null;
                    let defaultActive = null;
                    obj.tabs.map((tab, index) => {
                        if (tab.permissions) {
                            avtiveTabs.push(tab);
                            if (!selectedTab) {
                                if ($scope.data.userSettings.defaultDashboardTabs && $scope.data.userSettings.defaultDashboardTabs[obj.layoutOf] == tab.value) {
                                    selectedTab = tab.value
                                }
                                if (!tab.isDisabled && !defaultActive) {
                                    defaultActive = tab.value;
                                }
                            }
                        }
                    })
                    if (avtiveTabs.length && !selectedTab) {
                        selectedTab = defaultActive || avtiveTabs[0].value
                    }
                    return {
                        avtiveTabs,
                        selectedTab
                    }
                }

                $scope.menuClick = function (event, parent, childTab, sub_child) {
                    let notifyPage = false
                    if (event) {
                        setTimeout(() => {
                            if (event.currentTarget.offsetParent && $(event.currentTarget.offsetParent.offsetParent).hasClass('open')) {
                                $(event.currentTarget.offsetParent.offsetParent).removeClass("open")
                            }
                        }, 1000)
                    } else {
                        notifyPage = ($scope.prevState !== 'app.account.login') ? true : false;
                    }
                    $scope.filter.ui = parent;
                    $scope.filter.childTab = childTab;
                    $scope.filter.sub_child = $stateParams.sub_child;

                    if ($state.current.name !== 'app.account.dashboard.mainDashboard') {
                        if (childTab) {
                            if (sub_child) {
                                $state.go('app.account.dashboard.mainDashboard', { view: parent, child: childTab, sub_child: sub_child, work_orders: null, ic:null }, { notify: true });
                            } else {
                                $state.go('app.account.dashboard.mainDashboard', { view: parent, child: childTab, sub_child: null, work_orders: null, ic:null }, { notify: true });
                            }
                        } else {
                            $state.go('app.account.dashboard.mainDashboard', { view: parent, child: null, sub_child: null, work_orders: null, ic:null }, { notify: notifyPage });
                        }
                    }
                    $rootScope.$broadcast('onMenuChange', { childTab: childTab, ui: parent, sub_child });
                }

                $scope.$on('onTabChange', function (e, childItem, sub) {
                    $scope.filter.childTab = childItem;
                    $scope.filter.sub_child = sub || null;
                })
                $scope.$watch(function () { return $state.current.name; }, function (cv, lv) {
                    $scope.prevState = lv;
                    if (cv !== 'app.account.dashboard.mainDashboard' && cv !== 'app.account.login') {
                        $scope.filter.ui = null;
                        $scope.filter.childTab = null;
                    } else {
                        return init()
                    }
                })

                $scope.$watch(function () { return $stateParams.view;}, function (cv, lv) {
                    // console.log('1.', cv)
                    // console.log('2.', lv)
                    // debugger
                    if (cv == lv) return;
                    if (cv) {
                        $scope.filter.ui = cv
                    }
                })
                // // toggle navbar hover
                setTimeout(function () {
                    $('.navbar .dropdown')
                        // .on('focus', function(el){
                        .keypress(function (el) {
                            if (el.which == 13) {
                                $(el.currentTarget).toggleClass("open");
                                if ($(el.currentTarget.previousElementSibling).hasClass("open")) {
                                    $(el.currentTarget.previousElementSibling).removeClass("open")
                                }
                            }
                        })
                        // $('li.dropdown')
                        // .on('focusout', function (el) {
                        //     // debugger
                        //     console.log(($(el.target).text()).trim(), $(el))
                        //     if (($(el.target).text()).trim() === ($(el.currentTarget.children[1].children[el.currentTarget.children[1].childElementCount - 1]).text()).trim()) {
                        //         $(el.currentTarget).toggleClass("open");
                        //     } else {
                        //         if ($(el.target.nextElementSibling).hasClass('open')) {
                        //             $(el.target.nextElementSibling).removeClass("open")
                        //         }
                        //         if ($(el.target.previousElementSibling).hasClass('open')) {
                        //             $(el.target.previousElementSibling).removeClass("open")
                        //         }
                        //     }
                        // });
                }, 2000)
                // init()
            }
        }
    }
// Dependency Injection
headerMenuDirective.$inject = ["$rootScope","$clientSwitcher","$clientUser","$state","$stateParams","$auth","socket","$layoutItems","$activeClientSettings"];
