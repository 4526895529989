


'use strict';

export const ClientSettingFactory  = function ($resource, appConfig) {
  return $resource(appConfig.apiUrl + '/api/client-settings/:id/:controller', {
    id: '@_id'
  },
  {
    query: {
      method: 'GET',
      isArray: false,
      params: {
        controller: ''
      },
      cache: true
    },
    getPostLoginMessage: {
      method: 'GET',
      params: {
        controller: 'get-post-login-msg'
      }
    },
    apply: {
      method: 'POST',
      params: {
        controller: 'update'
      }
    },
    replace: {
      method: 'POST',
      params: {
        controller: 'replace'
      }
    }
  });
}


// Dependency Injection
ClientSettingFactory.$inject = ['$resource', 'appConfig'];
