


'use strict';

export const ClientCustomerFactory  = function ($resource, appConfig) {
    return $resource(appConfig.apiUrl + '/api/client-customer/:id/:controller', {
      id: '@_id'
    },
    {
      detail: {
        method: 'GET',
        params: {
          controller: 'details'
        }
      },
      getCustomers: {
        method: 'GET',
        params: {
          controller: ''
        } 
      },
      createCustomer: {
          method: 'POST',
          params: {
              controller: 'create'
          }
      },
      updateCustomer: {
          method: 'POST',
          params: {
              controller: 'update'
          }
      },
      deleteCustomer: {
          method: 'POST',
          params: {
              controller: 'delete'
          }
      },
      getLogs: {
        method: 'GET',
        params: {
          controller: 'logs'
        }
      },
      getUserCustomer: {
        method: 'GET',
        params: {
          controller: 'customer'
        }
      }
    });
  } 
 

// Dependency Injection
ClientCustomerFactory.$inject = ['$resource', 'appConfig'];
