


'use strict';

export const SyncHistoryFactory  = function ($resource, appConfig) {
    return $resource(appConfig.apiUrl + '/api/sync-history/:id/:controller', {
      id: '@_id'
    },
      {
        getHistory: {
          method: 'POST',
          params: {
            id: '@id',
            controller: 'get-sync-logs'
          }
        },
      });
  } 
 

// Dependency Injection
SyncHistoryFactory.$inject = ['$resource', 'appConfig'];
