'use strict';

export const PostgresDataSyncCasesCtrl = function ($scope, $clientSwitcher, $cases, $timeout, ngToast, $activeClientSettings, Modal, DEFAULT_DEBOUNCE) {
        const currentCli = $clientSwitcher.getCurrentClient();
        $scope.casesData = [];
        $scope.casesDataCount = 0;
        $scope.isLoading = false;
        $scope.error = null;
        $scope.pagination = {
            currentPage: 1,
            page: 1,
            total: 0,
            pageSize: 20,
            itemsPerPage: 20
        };
        $scope.data = {
            isLoading: true,
            showDatePullFilter: false,
            showCasesDatePullFilter: false,
            startDate: null, //moment().subtract(7, 'days').startOf('day').format('YYYY-MM-DD hh:mm A'),
            endDate: null, //moment().endOf('day').format('YYYY-MM-DD hh:mm A')
            casesStartDate: null, //moment().subtract(7, 'days').startOf('day').format('YYYY-MM-DD hh:mm A'),
            casesEndDate: null//moment().endOf('day').format('YYYY-MM-DD hh:mm A')
        };

        $scope.filter = {
            searchStr: ''
        }
        $activeClientSettings(function (client, settings) {
            if (!client || !settings) {
                return;
            }
            $scope.forcePostgresDataSync = settings.forcePostgresDataSync;
        });

        function initDateRangePicker() {
            $('#reportrange').daterangepicker({
                autoUpdateInput: false,

                // startDate: new Date($scope.data.startDate ), //moment($scope.data.startDate).format('MM/DD/YYYY hh:mm A'),
                // endDate: new Date($scope.data.endDate), //moment($scope.data.endDate).format('MM/DD/YYYY hh:mm A'),
                opens: 'left',
                timePicker: true,
                locale: {
                    applyLabel: 'Sync Data'
                }
            })
                .on('apply.daterangepicker', function (ev, picker) {
                    let start = picker.startDate, end = picker.endDate;
                    if (moment(end).diff(moment(start), 'day') > 30) {
                        ngToast.create({
                            content: 'Please Select date with in 30 days only!',
                            className: 'danger'
                        });
                        $scope.$apply()
                    } else {
                        $(this).val(picker.startDate.format('MM/DD/YYYY') + ' to ' + picker.endDate.format('MM/DD/YYYY'));
                        // $('#reportrange span').html(start.format('MM/DD/YYYY') + ' to ' + end.format('MM/DD/YYYY'));
                        $scope.data.startDate = start.format('YYYY-MM-DD HH:mm');
                        $scope.data.endDate = end.format('YYYY-MM-DD HH:mm');

                        if ($scope.data.startDate && $scope.data.endDate) {
                            Modal.confirm.show('Confirm Sync', `Are you sure, You want to force cases sync between ${$scope.data.startDate} to ${$scope.data.endDate}?`, 'Yes', 'No', function () {
                                $scope.forceSync();
                            });
                        }
                    }
                })
                .on('cancel.daterangepicker', function (ev, picker) {
                    $(this).val('');
                    $scope.data.startDate = null;
                    $scope.data.endDate = null;
                })
            // var text = moment($scope.data.startDate).format('MM/DD/YYYY') + ' to ' + moment($scope.data.endDate).format('MM/DD/YYYY');
            // $('#reportrange span').html(text);

        }

        function initCasesDateRangePicker() {
            $('#casesrange').daterangepicker({
                autoUpdateInput: false,
                opens: 'left',
                timePicker: false,
                locale: {
                    applyLabel: 'Sync Data'
                }
            })
                .on('apply.daterangepicker', function (ev, picker) {
                    let start = picker.startDate, end = picker.endDate;
                    if (moment(end).diff(moment(start), 'day') >= 180) {
                        ngToast.create({
                            content: 'Please Select date with in 180 days only!',
                            className: 'danger'
                        });
                        $scope.$apply()
                    } else {
                        $(this).val(picker.startDate.format('MM/DD/YYYY') + ' to ' + picker.endDate.format('MM/DD/YYYY'));
                        // $('#casesrange span').html(start.format('MM/DD/YYYY') + ' to ' + end.format('MM/DD/YYYY'));
                        $scope.data.casesStartDate = start.format('YYYY-MM-DD');
                        $scope.data.casesEndDate = end.format('YYYY-MM-DD');
                        if ($scope.data.casesStartDate && $scope.data.casesEndDate) {
                            Modal.confirm.show('Confirm Cases Sync', `Are you sure, You want to force sync WO between ${$scope.data.casesStartDate} to ${$scope.data.casesEndDate}?`, 'Yes', 'No', function () {
                                $scope.forceCasesSync();
                            });
                        }
                    }
                })
                .on('cancel.daterangepicker', function (ev, picker) {
                    $(this).val('');
                    $scope.data.casesStartDate = null;
                    $scope.data.casesEndDate = null;
                })
            // var text = moment($scope.data.casesStartDate).format('MM/DD/YYYY') + ' to ' + moment($scope.data.casesEndDate).format('MM/DD/YYYY');
            // $('#casesrange span').html(text);
        }

        function init() {
            $scope.getCasesList();
            $timeout(function () {
                initDateRangePicker()
                initCasesDateRangePicker();
            }, 500)
        }

        $scope.onFilter = _.debounce(function (cv, lv) {
            $scope.pagination.page = 1;
            $scope.getCasesList()
        }, 0)

        $scope.filterChange = function () {
            $scope.onFilter()
        }
        $scope.getCasesList = function () {
            $scope.isLoading = true;
            $scope.error = null;
            $scope.casesData = [];
            $scope.pagination.total = 0;

            $cases.getCasesList(currentCli, $scope.filter.searchStr, $scope.pagination)
                .then((res) => {
                    if (res.status) {
                        if (res.casesList && res.casesList.length) {
                            $scope.casesData = res.casesList;
                            $scope.pagination.total = res.totalRecords;
                            $scope.casesDataCount = res.totalRecords;
                        } else {
                            $scope.error = 'No Record Found.';
                            $scope.casesDataCount = 0;
                        }
                    }
                })
                .catch((err) => {
                    let errMessage = err.message || err;
                    if (err && err.data && err.data.message) {
                        errMessage = err.data.message;
                    } else if (err && err.data && err.data.reason) {
                        errMessage = err.data.reason;
                    }
                    $scope.error = errMessage;
                    $scope.casesDataCount = 0;
                })
                .finally(() => {
                    $scope.isLoading = false;
                })
        }

        $scope.serviceType = function (cases) {
            if (cases.vtc) {
                return 'VRI';
            } else if (cases.inperson == 'true' || cases.inperson == true) {
                return 'OSI'
            } else {
                return 'OPI'
            }
        }

        $scope.forceSync = function () {
            this.data.isLoading = true;
            let startDate = moment($scope.data.startDate).format('YYYY-MM-DD HH:mm');
            let endDate = moment($scope.data.endDate).format('YYYY-MM-DD HH:mm')
            $cases.forceSync(currentCli, { table: 'cases', startDate, endDate })
                .then((res) => {
                    $scope.data.isLoading = false;
                    if (res && res.status) {
                        ngToast.create({
                            content: res.message,
                            className: 'success'
                        });
                        $scope.data.showDatePullFilter = false;
                        //   $scope.data.startDate = null;
                        //   $scope.data.endDate = null;
                        $scope.getCasesList()
                    } else {
                        ngToast.create({
                            content: res.message,
                            className: 'danger'
                        });
                    }
                })
                .catch((err) => {
                    let errMessage = err.message || err;
                    if (err && err.data && err.data.message) {
                        errMessage = err.data.message;
                    } else if (err && err.data && err.data.reason) {
                        errMessage = err.data.reason;
                    }
                    // $scope.error = errMessage;
                    ngToast.create({
                        content: errMessage,
                        className: 'danger'
                    });
                })
                .finally(() => {
                    $scope.data.isLoading = false;
                })
        }

        $scope.forceCasesSync = function () {
            this.data.isLoading = true;
            let startDate = moment($scope.data.casesStartDate).format('YYYY-MM-DD');
            let endDate = moment($scope.data.casesEndDate).format('YYYY-MM-DD')
            $cases.forceCasesSync(currentCli, { table: 'cases', startDate, endDate })
                .then((res) => {
                    $scope.data.isLoading = false;
                    if (res && res.status) {
                        ngToast.create({
                            content: res.message,
                            className: 'success'
                        });
                        $scope.data.showCasesDatePullFilter = false;
                        $scope.getCasesList();
                    } else {
                        ngToast.create({
                            content: res.message,
                            className: 'danger'
                        });
                    }
                })
                .catch((err) => {
                    let errMessage = err.message || err;
                    if (err && err.data && err.data.message) {
                        errMessage = err.data.message;
                    } else if (err && err.data && err.data.reason) {
                        errMessage = err.data.reason;
                    }
                    // $scope.error = errMessage;
                    ngToast.create({
                        content: errMessage,
                        className: 'danger'
                    });
                })
                .finally(() => {
                    $scope.data.isLoading = false;
                })
        }

        $scope.getSyncHistory = function (ele) {
            if ($scope.isModalShown) return;
            let filter = {
                tableName: 'cases',
                coi: ele.coi,
                recordnumber: ele.recordnumber
            }
            let title = `Cases Sync History | ${ele.coi || ''} | ${ele.recordnumber}`
            Modal.commonApp.syncDifference(title, filter, 'coi',ele, function (res) {
                console.log(res)
                $scope.isModalShown = false;
            })();
        }
        init();
    }
// Dependency Injection
PostgresDataSyncCasesCtrl.$inject = ["$scope","$clientSwitcher","$cases","$timeout","ngToast","$activeClientSettings","Modal","DEFAULT_DEBOUNCE"];

// angular.module('coordinateApp').controller('PostgresDataSyncCasesCtrl', PostgresDataSyncCasesCtrl);
