
'use strict';

export const $performToggleStateService  = function ($clientTask, Modal, TASK_STATE_STARTED, TASK_STATE_PAUSED) {
  return function(cb) {
    return function(task) {
      if(!task) {
        return;
      }

      if(task.taskState === TASK_STATE_STARTED) {
        Modal.alertActions.recordPause(function(scratchPad){
          $clientTask.setState(task, TASK_STATE_PAUSED, scratchPad.message).then(function(res) {
            cb();
          });
        })(task);
      }
      else {
        $clientTask.setState(task, TASK_STATE_STARTED).then(function(res) {
          cb();
        });
      }
    };
  };
}
 

// Dependency Injection
$performToggleStateService.$inject = ['$clientTask', 'Modal', 'TASK_STATE_STARTED', 'TASK_STATE_PAUSED'];
