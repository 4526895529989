


'use strict';

export const $autoTimezoneFormatService  = function () {
        return {
            getAllDetail: function(deldate, sched, courttimezoneid) {
                if (!courttimezoneid) {
                    let formatedSched =  moment(sched, 'HH:mm:ss').format('HH:mm'),
                    caseDeldateTime = deldate +  ' ' + moment(formatedSched, 'HH:mm').format('hh:mm A'),
                    caseDisplayDateTime= moment(deldate +  ' ' + formatedSched , 'YYYY-MM-DD HH:mm').format('MM/DD/YYYY hh:mm A');
                    return {
                        sched:  formatedSched,
                        formatedSchedTime: moment(formatedSched, 'HH:mm').format('hh:mm A'),
                        ampm: moment(formatedSched, 'HH:mm').format('a'),
                        date: deldate,
                        caseDeldateTime,
                        coiTimezone: null,
                        coiTimezoneOffset: null,
                        courttimezoneid: null,
                        utcDate: moment.utc(caseDisplayDateTime).format('YYYY-MM-DD hh:mm A z'),
                        caseDisplayDateTime,

                    }
                } else {
                    let
                    coiTimezoneOffset =  moment(deldate +  ' ' + sched , 'YYYY-MM-DD HH:mm').tz(courttimezoneid).format('ZZ'),
                    coiTimezone =  moment(deldate +  ' ' + sched , 'YYYY-MM-DD HH:mm').tz(courttimezoneid).format('z'),
                    coiTimezoneVal =  moment(deldate +  ' ' + sched , 'YYYY-MM-DD HH:mm').tz(courttimezoneid).format('Z'),
                    formatedSched =  moment(sched, 'HH:mm:ss').format('HH:mm') ,
                    caseDisplayDateTime= moment(deldate +  ' ' + formatedSched , 'YYYY-MM-DD HH:mm').format('MM/DD/YYYY hh:mm A'),
                    caseDeldateTime = deldate +  ' ' + moment(formatedSched, 'HH:mm').format('hh:mm A')  + ' '+ coiTimezone,
                    // caseDeldateTimeWithOffset = deldate +  ' ' + moment(formatedSched, 'HH:mm').format('hh:mm A')  + ' '+ coiTimezoneOffset,
                    caseDeldateTimeWithOffset = deldate +  'T' + sched  + '' + coiTimezoneVal,
                    timeZoneDeldate = moment(caseDeldateTimeWithOffset).tz(courttimezoneid).format();
                    let utcDate = moment.utc(timeZoneDeldate).format();
                    return {
                        sched: formatedSched,
                        formatedSchedTime: moment(formatedSched, 'HH:mm').format('hh:mm A'),
                        ampm: moment(formatedSched, 'HH:mm').format('a'),
                        date: deldate,
                        caseDeldateTime,
                        coiTimezone,
                        coiTimezoneOffset,
                        courttimezoneid,
                        utcDate: moment.utc(timeZoneDeldate).format('YYYY-MM-DD hh:mm A z'),
                        caseDisplayDateTime,
                        timeZoneDeldate,
                        userLocale: {
                            sched:  moment(utcDate).format('HH:mm'),
                            formatedSchedTime: moment(utcDate).format('hh:mm A'),
                            ampm: moment(utcDate).format('a'),
                            caseDeldateTime: moment(utcDate).format('YYYY-MM-DD hh:mm A ZZ'),
                            date: moment(utcDate).format('YYYY-MM-DD'),
                            datetime: moment(utcDate).format('YYYY-MM-DD hh:mm A ZZ'),
                            caseDisplayDateTime: moment(utcDate).format('MM/DD/YYYY hh:mm A'),
                            tzCode: moment(utcDate).tz(moment.tz.guess()).format('zz'),
                            currentTime: moment(utcDate).format(),
                        }
                    }
                }
            }
        }
    }
 
