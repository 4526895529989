
'use strict';

export const TaskBucketPendingFactory  = function (TaskBucket) {
  function TaskBucketPending(opts) {
    TaskBucket.call(this, opts);
    this.isPending = true;
    this.filterOptions.splice(1, 1);
  }

  TaskBucketPending.prototype = Object.create(TaskBucket.prototype);
  TaskBucketPending.prototype.constructor = TaskBucketPending;

  return TaskBucketPending;
}
 

// Dependency Injection
TaskBucketPendingFactory.$inject = ['TaskBucket'];
