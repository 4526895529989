'use strict';

export const Report_bucketsConfig = function ($stateProvider) {
        $stateProvider
            .state('app.account.dashboard.settings.reportBuckets', {
                url: '/report-buckets',
                templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/report_buckets/report_buckets.html', '6e6f2caa'); }],
                controller: 'DashboardSettingsReportBucketsCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/report_buckets/report_buckets.controller.js', 'DashboardSettingsReportBucketsCtrl'); }]
                      }
            })
            .state('app.account.dashboard.settings.reportBuckets.index', {
                url: '/report-buckets',
                templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/report_buckets/index/index.html', 'c74b15ca'); }],
                controller: 'DashboardSettingsReportBucketsIndexCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/report_buckets/index/index.controller.js', 'DashboardSettingsReportBucketsIndexCtrl'); }]
                      }
            })
            .state('app.account.dashboard.settings.reportBuckets.create', {
                url: '/create',
                templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/report_buckets/create/create.html', '577eb02a'); }],
                controller: 'DashboardSettingsReportBucketsCreateCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/report_buckets/create/create.controller.js', 'DashboardSettingsReportBucketsCreateCtrl'); }]
                      }
            })
            .state('app.account.dashboard.settings.reportBuckets.update', {
                url: '/update/:id',
                templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/report_buckets/update/update.html', '1e39508a'); }],
                controller: 'DashboardSettingsReportBucketsUpdateCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/report_buckets/update/update.controller.js', 'DashboardSettingsReportBucketsUpdateCtrl'); }],
                          '$selectedReportBucket': ['$stateParams', '$clientReportBuckets', '$clientSwitcher', function ($stateParams, $clientReportBuckets, $clientSwitcher) {
                              return $clientReportBuckets.getReportBucket($clientSwitcher.getCurrentClient(), $stateParams.id);
                          }]
                      },
            });
    }
// Dependency Injection
Report_bucketsConfig.$inject = ["$stateProvider"];
