'use strict';


export const ServiceUserProjectFactory = function ($resource, appConfig) {
        return $resource(appConfig.apiUrl + '/api/service_user_projects/:id/:controller', {
            id: '@_id'
        },
            {
                getProjects: {
                    method: 'GET',
                    params: {
                        id: '@id'
                    }
                },
                getServiceProjects: {
                    method: 'GET',
                    params: {
                        id: '@id',
                        controller: 'get-projects'
                    }
                },
                create: {
                    method: 'POST',
                    params: {
                        controller: 'create'
                    }
                },
                update: {
                    method: 'POST',
                    params: {
                        controller: 'update'
                    }
                },
                removeAttachments: {
                    method: 'POST',
                    params: {
                        controller: 'remove-attachments'
                    }
                }
            });
    } 
 export const $ServiceUserProjectService = function (ServiceUserProject) {
        return {
            getProjects: function (client, userId) {
                return ServiceUserProject.getProjects({
                    id: client._id || client,
                    controller: userId
                }).$promise;
            },
            getServiceProjects: function (client) {
                return ServiceUserProject.getServiceProjects({
                    id: client._id || client
                }).$promise;
            },
            create: function (client, data) {
                return ServiceUserProject.create({
                    _id: client._id || client,
                    data
                }).$promise;
            },
            update: function (client, data) {
                return ServiceUserProject.update({
                    _id: client._id || client,
                    data
                }).$promise;
            },
            removeAttachments: function (client, data) {
                return ServiceUserProject.removeAttachments({
                    _id: client._id || client,
                    data
                }).$promise;
            }
        };
    }
// Dependency Injection
ServiceUserProjectFactory.$inject = ["$resource","appConfig"];
$ServiceUserProjectService.$inject = ["ServiceUserProject"];
