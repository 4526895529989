'use strict';

export const LogsConfig = function ($stateProvider) {
        $stateProvider
            .state('app.account.dashboard.settings.logs', {
                url: '/logs',
                templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/logs/logs.html', 'eace5e8a'); }],
                controller: 'DashboardSettingsLogsCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/logs/logs.controller.js', 'DashboardSettingsLogsCtrl'); }]
                      }
            })
            .state('app.account.dashboard.settings.logs.index', {
                url: '/logs',
                templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/logs/index/index.html', 'fe8ad24a'); }],
                controller: 'DashboardSettingsLogsIndexCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/logs/index/index.controller.js', 'DashboardSettingsLogsIndexCtrl'); }]
                      }
            })
            // .state('app.account.dashboard.settings.reportBuckets.create', {
            //     url: '/create',
            //     templateProvider: ['$q', '$templateCache', function($q, $templateCache) {
              //     var cacheName = 'accountdashboardsettingslogslogscreate_createTemplate';
              //     return $q((resolve) => {
              //         if ($templateCache.get(cacheName)) {
              //             resolve($templateCache.get(cacheName));
              //         } else {
              //             import('./../report_buckets/create/create.html').then(template => {
              //                 $templateCache.put(cacheName, template.default);
              //                 resolve(template.default);
              //             });
              //         }
              //     });
              // }],
            //     controller: 'DashboardSettingsReportBucketsCreateCtrl'
            // })
            // .state('app.account.dashboard.settings.reportBuckets.update', {
            //     url: '/update/:id',
            //     templateProvider: ['$q', '$templateCache', function($q, $templateCache) {
              //     var cacheName = 'accountdashboardsettingslogslogsupdate_updateTemplate';
              //     return $q((resolve) => {
              //         if ($templateCache.get(cacheName)) {
              //             resolve($templateCache.get(cacheName));
              //         } else {
              //             import('./../report_buckets/update/update.html').then(template => {
              //                 $templateCache.put(cacheName, template.default);
              //                 resolve(template.default);
              //             });
              //         }
              //     });
              // }],
            //     controller: 'DashboardSettingsReportBucketsUpdateCtrl',
            //     resolve: {
            //         '$selectedReportBucket': ['$stateParams', '$clientReportBuckets', '$clientSwitcher', function ($stateParams, $clientReportBuckets, $clientSwitcher) {
            //             return $clientReportBuckets.getReportBucket($clientSwitcher.getCurrentClient(), $stateParams.id);
            //         }]
            //     }
            // });
    }
// Dependency Injection
LogsConfig.$inject = ["$stateProvider"];
