
'use strict';

export const $clientUserNotificationsService  = function ($q, $authStorage, ClientUpload, $http, $window, ClientUserNotifications) {
  return {
    get: function (client) {
      return ClientUserNotifications.query({ id: (client._id||client) }).$promise;
    },
    read: function(client, noteId) {
      return ClientUserNotifications.read({ id: (client._id||client), notificationId: noteId, source: 'WEB'}).$promise;
    },
    scheduledNotifications: function(client,pageNo, pageSize){
      return ClientUserNotifications.scheduledNotifications({ id: (client._id||client),pageNo: pageNo, pageSize: pageSize}).$promise;
    }
  };
}
// Dependency Injection


// Dependency Injection
$clientUserNotificationsService.$inject = ['$q', '$authStorage', 'ClientUpload', '$http', '$window', 'ClientUserNotifications'];
