'use strict';

export const UsersConfig = function ($stateProvider) {
    $stateProvider
      .state('app.admin.users', {
        url: '/users',
        data: {
          roles: ['admin']
        },
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./admin/users/users.html', 'fcfeb345'); }],
        controller: 'AdminUsersCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./admin/users/users.controller.js', 'AdminUsersCtrl'); }]
                      }
      })
      .state('app.admin.users.create', {
        url: '/create',
        data: {
          roles: ['admin']
        },
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./admin/users/create/create.html', '119513a5'); }],
        controller: 'AdminUsersCreateCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./admin/users/create/create.controller.js', 'AdminUsersCreateCtrl'); }]
                      }
      });
  }
// Dependency Injection
UsersConfig.$inject = ["$stateProvider"];
