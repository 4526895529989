
'use strict';

export const $clientTimeProductionReportingService  = function (ClientTimeProductionReporting, $http, $auth) {
    return {
      getRawRecords: function(client, filter, paginationData) {
        return ClientTimeProductionReporting.getRawRecords({
          id: client._id || client,
          controller: 'raw-records',
          page: paginationData && paginationData.page,
          filter: filter,
          pageSize: paginationData && paginationData.pageSize
        }).$promise;
      },
      getRecords: function(client, search) {
        return ClientTimeProductionReporting.getRecords({
          id: client._id || client,
          controller: 'records',
          search: search
        }).$promise;
      },
      exportRecords: function(client, search) {
        return $http({
          url: '/api/time-production-reporting/'+ (client._id || client) +'/export-records', 
          method: "GET",
          params: {
            search: JSON.stringify(search),
            timeOffset: new Date().getTimezoneOffset()
          }
        })
        .success(function(data, status, headers, config) {
          if(data.status === false) {
            return console.log(data.reason);
          }

          function base64toBlob(base64Data, contentType) {
            contentType = contentType || '';
            var sliceSize = 1024;
            var byteCharacters = atob(base64Data);
            var bytesLength = byteCharacters.length;
            var slicesCount = Math.ceil(bytesLength / sliceSize);
            var byteArrays = new Array(slicesCount);
        
            for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
                var begin = sliceIndex * sliceSize;
                var end = Math.min(begin + sliceSize, bytesLength);
        
                var bytes = new Array(end - begin);
                for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
                    bytes[i] = byteCharacters[offset].charCodeAt(0);
                }
                byteArrays[sliceIndex] = new Uint8Array(bytes);
            }
            return new Blob(byteArrays, { type: contentType });
          }

          var blob = base64toBlob(data, 'attachment/zip');
          var element = angular.element('<a></a>');
          element.attr({
            href: URL.createObjectURL(blob),
            target: '_blank',
            download: 'reports_' + moment(search.startDate).format('MMDDYYYY') + '_' + moment(search.endDate).format('MMDDYYYY') + '.zip'
          });
          element[0].click();

          // var element = angular.element('<a></a>');
          // element.attr({
          //   href: 'data:attachment/zip;base64,' + data,
          //   target: '_blank',
          //   download: 'reports_' + moment(search.startDate).format('MMDDYYYY') + '_' + moment(search.endDate).format('MMDDYYYY') + '.zip'
          // });
          // element[0].click();
        })
        .error(function(data, status, headers, config) {
          alert('Error while downloading the tasks');
        });
      },
      getReportingData: function(client, group, type, search) {
        //add client's time offset so that server can group records properly
        var timezone = $auth.getCurrentUser().timezone || moment.tz.guess();
        
        search.timeOffset = moment.tz(timezone)._offset;

        return ClientTimeProductionReporting.getReportingData({
          id: client._id || client,
          controller: group.id || group,
          type: type,
          search: search
        }).$promise;
      },
      deleteRecords: function(client, reportingIds, shiftId) {
        return ClientTimeProductionReporting.deleteRecords({
          id: client._id || client,
          records: reportingIds,
          shiftId: shiftId || ''
        }).$promise;
      },
      createRecords: function(client, createRecords) {
        return ClientTimeProductionReporting.createRecords({
          id: client._id || client,
          records: createRecords
        }).$promise;
      },
      updateRecords: function(client, updateRecords, createRecords) {
        return ClientTimeProductionReporting.updateRecords({
          id: client._id || client,
          updateRecords: updateRecords,
          createRecords: createRecords
        }).$promise;
      },
      getWeekOvertimeReport: function(client, employeeId, search) {
        //add client's time offset so that server can group records properly
        var timezone = $auth.getCurrentUser().timezone || moment.tz.guess();
        
        search.timeOffset = moment.tz(timezone)._offset;

        return ClientTimeProductionReporting.getWeekOvertimeReport({
          id: client._id || client,
          controller: 'week-overtime-reports',
          empId: employeeId,
          search: search
        }).$promise;
      },
      moveProdRecords: function(client, updateRecords) {
        return ClientTimeProductionReporting.moveProdRecords({
          id: client._id || client,
          updateRecords: updateRecords
        }).$promise;
      },
      updateShiftDetailsRecord: function(client, updateRecords) {
        return ClientTimeProductionReporting.updateShiftDetailsRecord({
          id: client._id || client,
          updateRecords: updateRecords
        }).$promise;
      }
    };
  }
// Dependency Injection


// Dependency Injection
$clientTimeProductionReportingService.$inject = ['ClientTimeProductionReporting', '$http', '$auth'];
