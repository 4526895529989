import './patient-contact.scss';
'use strict';

export const PatientContactConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard.patientContact', {
        url: '/patient-contacts',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/patient-contact/patient-contact.html', 'd5515a4a'); }],
        controller: 'PatientContactCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/patient-contact/patient-contact.controller.js', 'PatientContactCtrl'); }]
                      },
        data: {
          ability: 'client.PatientContact',
          permission: 'PATIENT_CONTACT_READ'
        }
      });
  }
// Dependency Injection
PatientContactConfig.$inject = ["$stateProvider"];
