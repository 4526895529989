import './employee-task-performance-charts.scss';
'use strict';


export const employeeTaskPerformanceDirective = function ($timeout, $taskReports, $activeClientSettings, $clientSwitcher) {
        return {
            restrict: 'E',
            templateUrl: 'components/employee-task-performance-charts/employee-task-performance-charts.html',
            scope: {
                employeeAnalysisData: '=',
                reloadStats: '=',
            },
            link: function(scope, el, attrs) {
                scope.analysisData = scope.employeeAnalysisData.analysisData;
                scope.loading = true;
                scope.clientStrings = function(){ return $clientSwitcher.getFeatureOptions('client.strings'); };
                function loadCharts() {
                    scope.loading = true;
                    var
                        baseChart = {
                            data: [],
                            label: [],
                            option: [],
                            color: []
                        },
                        pendingToResolveChart = _.cloneDeep(baseChart),
                        newTranscriptsChart = _.cloneDeep(baseChart),
                        followupsChart = _.cloneDeep(baseChart),
                        colors = {
                            positive: '#598137',
                            negative: '#C33738',
                            mixed: '#CB804A',
                            exceptional: '#385396'
                        },
                        pendingToResolveTotal = 0,
                        newTranscriptsTotal = 0,
                        followupsTotal = 0;
                    _.map(scope.analysisData.pendingToResolve, function(value, key) {
                        pendingToResolveTotal += value;
                        pendingToResolveChart.data.push(value);
                        pendingToResolveChart.label.push(key);
                        pendingToResolveChart.color.push(colors[key]);
                    });
                    scope.pendingToResolveChart = pendingToResolveChart;

                    _.map(scope.analysisData.noSentiments, function(value, key) {
                        newTranscriptsTotal += value;
                        newTranscriptsChart.data.push(value);
                        newTranscriptsChart.label.push(key);
                        newTranscriptsChart.color.push(colors[key]);
                    });
                    scope.newTranscriptsChart = newTranscriptsChart;
                    
                    _.map(scope.analysisData.noSentiments, function(value, key) {
                        followupsTotal += value;
                        followupsChart.data.push(value);
                        followupsChart.label.push(key);
                        followupsChart.color.push(colors[key]);
                    });
                    scope.followupsChart = followupsChart;
                    
                    scope.roundCharts = [
                        {
                            id: 'newTranscripts',
                            type: 'doughnut',
                            label: 'New Transcripts',
                            value: followupsTotal,
                            color: '#2D74B6',
                            chart: newTranscriptsChart,
                            footer: '',
                            enabled: true
                        },
                        {
                            id: 'followups',
                            type: 'doughnut',
                            label: 'Followups',
                            value: newTranscriptsTotal,
                            color: '#6F2D9F',
                            chart: followupsChart,
                            footer: '',
                            enabled: true
                        },
                        {
                            id: 'resolved',
                            type: 'bar',
                            label: scope.clientStrings().resolvedPCase,
                            tooltip: scope.clientStrings().resolvedPCase,
                            value: scope.analysisData.resolved,
                            color: '#7F7F7F',

                            labels: [scope.clientStrings().resolvedPCase],
                            data: [scope.analysisData.resolved],
                            footer: "",
                            series: 'Priority',
                            datasetOverride: {
                                backgroundColor: ['#7F7F7F'],
                                type: 'bar',
                                fill: false
                            },
                            options: {
                                tooltipTemplate: "1",
                                showTooltips: true,
                                responsive: true,
                                maintainAspectRatio: true,
                                scales: {
                                    xAxes: [{
                                        display: false,
                                        min: 0,
                                        max: 100,
                                    }],
                                    yAxes: [{
                                        display: false,
                                        barPercentage: 0.8,
                                        ticks: {
                                            min: 0,
                                            stepSize: 20
                                        }
                                    }]
                                },
                                tooltips: {
                                    mode: 'label',
                                    callbacks: {
                                        afterTitle: function() {
                                            return "";
                                        },
                                        label: function() {
                                            return "";
                                        }
                                    }
                                }
                            },
                            enabled: true
                        },
                        {
                            id: 'pendingToResolve',
                            type: 'doughnut',
                            label: 'Pending To Resolve',
                            value: pendingToResolveTotal,
                            color: '#009900',
                            chart: pendingToResolveChart,
                            footer: '',
                            enabled: true
                        },
                    ]
                    scope.ResolvePerformanceCharts = [
                        {
                            id: 'avgTaskCreateToAssignTime',
                            type: 'doughnut',
                            label: 'Average Time To Assign',
                            value: scope.analysisData.avgTaskCreateToAssignTime,
                            color: '#2D74B6',
                            enabled: true
                        },
                        {
                            id: 'avgTaskSurveyDateToResolveTime',
                            type: 'doughnut',
                            label: 'Average Time To ' + scope.clientStrings().resolvePCase,
                            value: scope.analysisData.avgTaskSurveyDateToResolveTime,
                            color: '#2D74B6',
                            enabled: true
                        },
                        {
                            id: 'resolveTasksRateWithIn24hr',
                            type: 'doughnut',
                            label: scope.clientStrings().resolvedPCase + ' w/n 24 Hours',
                            value: scope.analysisData.resolveTasksRateWithIn24hr ? scope.analysisData.resolveTasksRateWithIn24hr + '%' : undefined,
                            color: changeColor(scope.analysisData.resolveTasksRateWithIn24hr),
                            enabled: true
                        },
                        {
                            id: 'perResolveTasksRate',
                            type: 'doughnut',
                            label: scope.clientStrings().resolvedPCase + ' w/n 72 Hours',
                            value: scope.analysisData.resolveTasksRateWithIn72hr ? scope.analysisData.resolveTasksRateWithIn72hr + '%' : undefined,
                            color: changeColor(scope.analysisData.resolveTasksRateWithIn72hr),
                            enabled: true
                        }]
                    scope.drawChart = true;
                    scope.loading = false;
                }

                function changeColor(value){
                    if(value < 33){
                        // red
                        return '#fe1600';
                    }else if(value > 33 && value < 67){
                        // orange
                        return 'orange';
                    }else if(value > 67){
                        // green
                        return '#009900';
                    }
                    return '#7F7F7F';
                }
                scope.$watch(function() { return scope.reloadStats; }, function() {
                    loadCharts();
                });
            }
        }
    }
// Dependency Injection
employeeTaskPerformanceDirective.$inject = ["$timeout","$taskReports","$activeClientSettings","$clientSwitcher"];
