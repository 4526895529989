'use strict';


export const taskCreateRuleDirective = function (Modal, $clientQueryBuilder, $clientSwitcher, $clientOffice, $clientExternalUsers,
  $clientUser, $clientTask, $q, $timeout, ngToast) {
    return {
      restrict: 'E',
      templateUrl: 'components/forms/task-create-rule.html',
      scope: {
        rule: '=ngModel',
        tables: '=',
        onSubmit: '='
      },
      link: function(scope, el, attrs) {
        scope.data = {
          loadingColumnNames: false
        };
        scope.tableItems = scope.tables.map(function(table) {
          return {
            label: table.title,
            value: table.title
          };
        });

        scope.tags = scope.rule.taskDetails.tags || [ {key: '', field: ''} ];
        scope.attributes = scope.rule.taskDetails.attributes || [{key: '', field: ''}];

        scope.offices = [];
        scope.users = [];
        scope.collections = [];
        scope.due = {
          dueDateIn: 'hours',
          onExpireDueDateIn: 'hours'
        };
        scope.slider = {
          hours: {
            max: 1440, //23:55 in minutes
            step: 15
          },
          days: {
            max: 43200,
            step: 1440
          }
        };
        scope.repeatOptions = [
          { label: 'Never', value: '' }, 
          { label: 'Days', value: 'days' },
          { label: 'Dates', value: 'dates' }
        ];
        scope.attributeItems = [
          {label: 'Location', value: 'location'},
          {label: 'Employee', value: 'employee'},
          {label: 'Customer', value: 'customer'}
        ];
        scope.repeatModes = [
          { label: 'Every', value: 'every' },
          { label: 'Every Other', value: 'every-other' },
          { label: 'First of Month', value: 'first-of-month' }
        ];
        scope.weekDays = [
          { label: 'Mon', value: 0 },
          { label: 'Tue', value: 1 },
          { label: 'Wed', value: 2 },
          { label: 'Thurs', value: 3 },
          { label: 'Fri', value: 4 },
          { label: 'Sat', value: 5 },
          { label: 'Sun', value: 6 }
        ];
        scope.selected = {
          dateRange: ''
        };
        var activeStatus = 'active';
        scope.statuses = [
            {label: 'Active',value: activeStatus},
            {label: 'Suspended',value: 'suspended'},
        ];
        var defaultStatus = activeStatus;
        scope.rule.status = (scope.rule.isActive === undefined) ? defaultStatus : (scope.rule.isActive ? 'active' : 'suspended');

        scope.relativeDates = {
          hours: [
            {label: '30 Min', value: 30},
            {label: '1 Hrs', value: 60},
            {label: '2 Hrs', value: 120},
            {label: '3 Hrs', value: 180},
            {label: '24 Hrs', value: 1440}
          ],
          days: [
            {label: '1 Day', value: 1440},
            {label: '2 Days', value: 2880},
            {label: '3 Days', value: 4320},
            {label: '1 Week', value: 10080},
            {label: '2 Weeks', value: 20160}
          ]
        };

        //supported task type config
        scope.taskTypes = [
          {
            label: 'Normal', 
            value: 'normal'
          },
          {label: 'Survey', value: 'survey'},
          {label: 'Case', value: 'case'}
        ];
        var DEFAULT_TASK_TYPES = 'survey';
        scope.rule.taskDetails.supportedTaskType = scope.rule.taskDetails.supportedTaskType || DEFAULT_TASK_TYPES;

        scope.shouldDisplayDue = function(forFallback) {
          var shouldDisplay = false;

          if(forFallback) {
            shouldDisplay = scope.rule.taskDetails && (scope.rule.taskDetails.assignToOnExpire || scope.rule.taskDetails.assignToEmailOnExpire);
            if(!shouldDisplay && scope.rule.taskDetails.onExpireDueOffset) {
              delete scope.rule.taskDetails.onExpireDueOffset;
            }
          }
          else {
            shouldDisplay = scope.rule.taskDetails && (scope.rule.taskDetails.assignTo || scope.rule.taskDetails.assignToEmail);
            if(!shouldDisplay && scope.rule.taskDetails.dueOffset) {
              delete scope.rule.taskDetails.dueOffset;
            }
          }

          return shouldDisplay;
        };
        
        scope.priorities = [
          {value: 'high',    label: 'High'},
          {value: 'med-high',label: 'Medium-high'},
          {value: 'med-low', label: 'Medium-low'},
          {value: 'low',     label: 'Low'}
        ];
        
        scope.client = $clientSwitcher.getCurrentClient();

        var fixHelper = function(e, ui) {
          ui.children().each(function() {
            $(this).width($(this).width());
          });
          return ui;
        };

        scope.sortableOptions = {
          helper: fixHelper,
        };

        scope.reloadOffices = function (client) {
          delete scope.offices;
          return $clientOffice.listAll(client)
            .then(function (offices) {
              scope.offices = offices.map(function (office) {
                return {
                  label: office.name,
                  value: office._id
                };
              });
            });
        };

        scope.reloadUsers = function (client, store, bucket) {
          delete scope.users;

          return $clientUser.getUserChainOfCommand(client, store, bucket)
            .then(function (chainOfCommand) {
              scope.users = chainOfCommand;
              return scope.users;
            });
        };

        scope.reloadCollections = function (client) {
          scope.collections = [];

          return $clientTask.getCollections(client, true)
            .then(function (collections) {
              scope.collections = collections.map(function (collection) {
                return {
                  label: collection.name,
                  value: collection._id,
                  taskTypes: collection.types.map(function(taskType) {
                    return {
                      label: taskType,
                      value: taskType
                    };
                  })
                };
              });

              if(scope.rule.taskDetails && scope.rule.taskDetails.taskCollection) {
                scope.setCurrentCollection(scope.rule.taskDetails.taskCollection);
              }
            });
        };

        scope.setCurrentCollection = function (toCollection) {

          var
          prevType = false,
          hasModel = !!scope.rule.taskDetails;

          delete scope.collectionTypes;

          if(hasModel) {
            prevType = scope.rule.taskDetails.taskType;
          }

          var collection;

          scope.collections.every(function (col) {
            if(col.value !== toCollection) return true;
            collection = col;
            return false;
          });

          if(!collection) {
            if(hasModel) {
              delete scope.rule.taskDetails.taskType;
            }

            return;
          }

          scope.collectionTypes = collection.taskTypes;

          if(hasModel) {
            var
            setTo = collection.taskTypes[0].value;

            if(prevType) {
              scope.collectionTypes.every(function (typeDef) {
                if(typeDef[0] === prevType) {
                  setTo = typeDef.value;
                  return false;
                }

                return true;
              });
            }

            scope.rule.taskDetails.taskType = setTo;
          }
        };

        scope.reload = function (client) {
          return $q.all([
            scope.reloadCollections(client),
            scope.reloadUsers(client),
            scope.reloadOffices(client)
          ]);
        };

        scope.taskDateOptions = {
          startingDay: 1
        };
        scope.taskDateOpened = false;
        scope.taskDateFormat = 'MM/dd/yyyy';
        scope.taskDateStepH  = 1;
        scope.taskDateStepM  = 1;
        scope.taskDateAMPM   = true;
              
        scope.nowDate = function () {
          var
          now = Date.now(),
          nextMinute = now % 60000;
          return now - nextMinute;
        };
        scope.taskDateDisabled = function (date, mode) {
          var now = Date.now(), offset = (now % 8.64e7);
          return date <= (now - offset);
        };

        scope.clearDueDate = function (){
          if(!scope.rule.taskDetails) return;
          delete scope.rule.taskDetails.due;
        };

        scope.clearOnExpireDueDate = function (){
          if(!scope.rule.taskDetails) return;
          delete scope.rule.taskDetails.onExpireDue;
        };

        scope.getRecords = function() {
          var query = 'SELECT * FROM '+scope.rule.table+' WHERE '+scope.rule.query;
          // var previewFields = [];
          // scope.tables.map(function(table) {
          //   if(table.name === scope.rule.table) {
          //     previewFields = previewFields.concat(table.previewFields);
          //   }
          // });
          var type = _.find(scope.tables, function(table) {
            return table.title == scope.rule.table;
          });
          var connectionType = type ? type.connectionType : '';
          Modal.taskCreateRuleActions.previewQueryResult()(query, connectionType);
        };

        var fetchColumns = function() {
          if(scope.rule.table){
            scope.data.loadingColumnNames = true;
            var type = _.find(scope.tables, function(table) {
              return table.title == scope.rule.table;
            });
            var connectionType = type ? type.connectionType : '';
            scope.rule.tableId = type ? type._id : '';
            $clientQueryBuilder.getColumnNames(scope.client, scope.rule.table, connectionType).then(function(res) {
              scope.data.loadingColumnNames = false;
              
              if(res.status) {
                scope.data.columnNames = [];
                scope.data.columnItems = [];
  
                res.data.forEach(function(columnName) {
                  scope.data.columnItems.push({
                    label: columnName,
                    value: columnName
                  });
  
                  scope.data.columnNames.push({
                    name: columnName
                  });
                });
              }
            });
          }
        };
        scope.tableChanged = function() {
          fetchColumns();
        };

        scope.addAttribute = function() {
          scope.attributes.push({
            key: '',
            field: ''
          });
        };

        scope.deleteAttribute = function(index) {
          var attributeObj = scope.attributes[index];
          
          if(attributeObj.defaultModelKey) {
            delete scope.rule.taskDetails[attributeObj.defaultModelKey];
          }
          scope.attributes.splice(index, 1);
        };

        scope.attributeChanged = function(attribute) {
          var defaultOptions = { // all defaultModelKey will be added to taskDetails obj
            'location': {
              defaultOptions: scope.offices,
              defaultModelKey: 'defaultLocation',
              isMandatory: true
            }
          };

          if(attribute.defaultModelKey) { //delete if any existing defaultKey was set by this attribute
            delete scope.rule.taskDetails[attribute.defaultModelKey];
          }
          delete attribute.defaultOptions;
          delete attribute.defaultModelKey;
          delete attribute.isMandatory;

          var attributeDefaultOptions = defaultOptions[attribute.key];
          if(attributeDefaultOptions) {
            attribute.defaultOptions = attributeDefaultOptions.defaultOptions;
            attribute.defaultModelKey = attributeDefaultOptions.defaultModelKey;
            attribute.isMandatory = attributeDefaultOptions.isMandatory;
          }
        };

        scope.addTag = function() {
          scope.tags.push({
            key: '',
            field: ''
          });
        };
        scope.deleteTag = function(deleteIndex) {
          scope.tags.splice(deleteIndex, 1);
        };

        scope.toggleDay = function (day) {
          var index = scope.rule.window.selectedDays.indexOf(day.value);
          if (index > -1) {
              scope.rule.window.selectedDays.splice(index, 1);
          }
          else {
              scope.rule.window.selectedDays.push(day.value);
          }
        };

        scope.isDaySelected = function (day) {
          return scope.rule.window && scope.rule.window.selectedDays && scope.rule.window.selectedDays.indexOf(day.value) > -1;
        };

        $timeout(function(){
          $('#scheduler-execution-date').daterangepicker({
              autoApply: true,
              minDate: moment().startOf('month'),
              maxDate: moment().endOf('month'),
          }, dateRangePickerCallback);

          var picker = $('#scheduler-execution-date').data('daterangepicker');
          if(scope.rule.window.repeat === 'dates') {
              var dates = scope.rule.window.selectedDates;
              var startDate = moment().date(dates[0]);
              var endDate = moment().date(dates[dates.length - 1]);
              picker.setStartDate(startDate);
              picker.setEndDate(endDate);
              dateRangePickerCallback(startDate, endDate);
          }
          picker.container.find('.range_inputs').hide();
          picker.container.find('.calendar.left').hide();
        }, 1000);

        var dateRangePickerCallback = function (start, stop) {
          $timeout(function() {
            scope.rule.window.selectedDates = [];
            for (var i = start._d.getDate(); i <= stop._d.getDate(); i++) {
                scope.rule.window.selectedDates.push(i);
            }

            scope.selected.dateRange = moment(start).format('DD');
            if (scope.selected.dateRange !== moment(stop).format('DD')) {
                scope.selected.dateRange += ' to ' + moment(stop).format('DD');
            }
          }, 100);
        };

        scope.submitRule = function() {
          var validTags = _.filter(scope.tags, function(tag) {
            return !!tag.key && !!tag.field;
          });
          if(validTags && validTags.length) {
            scope.rule.taskDetails.tags = validTags;
          }

          var validAttributes = _.filter(scope.attributes, function(attr){
            return !!attr.key && !!attr.field;
          });
          if(validAttributes && validAttributes.length){
            scope.rule.taskDetails.attributes = validAttributes;
          }

          scope.rule.isActive = scope.rule.status === 'active';

          if(!scope.rule.taskDetails.defaultLocation) {
            return ngToast.create({
              className: 'danger',
              content: '<strong>defaultLocation</strong> (attributes) must be selected'
            });
          }

          scope.onSubmit();
        };

        scope.reload(scope.client)
        .then(function() {
          if(scope.rule.taskDetails.attributes && scope.rule.taskDetails.attributes.length) {
            scope.rule.taskDetails.attributes.map(function(attribute) {
              scope.attributeChanged(attribute);
            });
          }
        });
        fetchColumns();
      }
    };
  }
// Dependency Injection
taskCreateRuleDirective.$inject = ["Modal","$clientQueryBuilder","$clientSwitcher","$clientOffice","$clientExternalUsers","$clientUser","$clientTask","$q","$timeout","ngToast"];
