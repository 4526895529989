
function loadScript(src) {
  return new Promise((resolve, reject) => {
      const existingScript = document.querySelector(`script[src="${src}"]`);

      if (existingScript) {

          if (existingScript.getAttribute('data-loaded') === 'true') {
              resolve();
          } else {

              existingScript.addEventListener('load', () => resolve());
              existingScript.addEventListener('error', (error) => reject(error));
          }
          return;
      }


      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = src;


      script.onload = function() {
          script.setAttribute('data-loaded', 'true');
          resolve();
      };


      script.onerror = function(error) {
          console.error(`Failed to load script ${src}:`, error);
          reject(error);
      };


      document.body.appendChild(script);
  });
}

export default loadScript;
