
'use strict';

export const $interpreterMatrixService  = function (InterpreterMatrix) {
        return {
            getICMatrixBuckets: function (client, filterBy) {
                return InterpreterMatrix.getICMatrixBuckets({
                    id: client._id || client,
                    controller: 'get-ic-matrix-buckets',
                    filterBy
                }).$promise;
            },
            getICMatrixDetails: function (client, filterBy) {
                return InterpreterMatrix.getICMatrixDetails({
                    id: client._id || client,
                    controller: 'get-ic-matrix-details',
                    filterBy
                }).$promise;
            },
            getICMatrixFilterDetails: function (client, filter) {
                return InterpreterMatrix.getICMatrixFilterDetails({
                    id: client._id || client,
                    controller: 'get-ic-matrix-filter-detail',
                    filter
                }).$promise;
            }
        };
    }
// Dependency Injection


// Dependency Injection
$interpreterMatrixService.$inject = ['InterpreterMatrix'];
