'use strict';

export const CustomersConfig = function ($stateProvider) {
  //   $stateProvider
  //     .state('app.account.dashboard.settings.customers', {
  //       url: '/customers',
  //       templateProvider: ['$q', '$templateCache', function($q, $templateCache) {
              //     var cacheName = 'customers_customersTemplate';
              //     return $q((resolve) => {
              //         if ($templateCache.get(cacheName)) {
              //             resolve($templateCache.get(cacheName));
              //         } else {
              //             import('./customers.html').then(template => {
              //                 $templateCache.put(cacheName, template.default);
              //                 resolve(template.default);
              //             });
              //         }
              //     });
              // }],
  //       controller: 'DashboardSettingsCustomersCtrl',
  //     })
  //     .state('app.account.dashboard.settings.customers.index', {
  //       url: '/customers',
  //       templateProvider: ['$q', '$templateCache', function($q, $templateCache) {
              //     var cacheName = 'accountdashboardsettingscustomerscustomersindex_indexTemplate';
              //     return $q((resolve) => {
              //         if ($templateCache.get(cacheName)) {
              //             resolve($templateCache.get(cacheName));
              //         } else {
              //             import('./index/index.html').then(template => {
              //                 $templateCache.put(cacheName, template.default);
              //                 resolve(template.default);
              //             });
              //         }
              //     });
              // }],
  //       controller: 'DashboardSettingsCustomersIndexCtrl'
  //     })
  //     .state('app.account.dashboard.settings.customers.update', {
  //       url: '/update/:id',
  //       templateProvider: ['$q', '$templateCache', function($q, $templateCache) {
              //     var cacheName = 'accountdashboardsettingscustomerscustomersupdate_updateTemplate';
              //     return $q((resolve) => {
              //         if ($templateCache.get(cacheName)) {
              //             resolve($templateCache.get(cacheName));
              //         } else {
              //             import('./update/update.html').then(template => {
              //                 $templateCache.put(cacheName, template.default);
              //                 resolve(template.default);
              //             });
              //         }
              //     });
              // }],
  //       controller: 'DashboardSettingsCustomersUpdateCtrl',
  //       resolve: {
  //         '$selectedCustomer': ['$stateParams','$clientCustomer', '$clientSwitcher', function ($stateParams, $clientCustomer, $clientSwitcher) {
  //           return $clientCustomer.detail($clientSwitcher.getCurrentClient(), $stateParams.id).then(function(res) {
  //             if(res.status) {
  //               return res.data;
  //             }
  //             else {
  //               throw new Error(res.reason);
  //             }
  //           });
  //         }]
  //       }
  //     })
  //     .state('app.account.dashboard.settings.customers.create', {
  //       url: '/create',
  //       templateProvider: ['$q', '$templateCache', function($q, $templateCache) {
              //     var cacheName = 'accountdashboardsettingscustomerscustomerscreate_createTemplate';
              //     return $q((resolve) => {
              //         if ($templateCache.get(cacheName)) {
              //             resolve($templateCache.get(cacheName));
              //         } else {
              //             import('./create/create.html').then(template => {
              //                 $templateCache.put(cacheName, template.default);
              //                 resolve(template.default);
              //             });
              //         }
              //     });
              // }],
  //       controller: 'DashboardSettingsCustomersCreateCtrl',
  //     })
  //     .state('app.account.dashboard.settings.customers.upload', {
  //       url: '/upload',
  //       templateProvider: ['$q', '$templateCache', function($q, $templateCache) {
              //     var cacheName = 'upload_uploadTemplate';
              //     return $q((resolve) => {
              //         if ($templateCache.get(cacheName)) {
              //             resolve($templateCache.get(cacheName));
              //         } else {
              //             import('./upload/upload.html').then(template => {
              //                 $templateCache.put(cacheName, template.default);
              //                 resolve(template.default);
              //             });
              //         }
              //     });
              // }],
  //       controller: 'DashboardSettingsCustomersUploadCtrl',
  //     });
  }
// Dependency Injection
CustomersConfig.$inject = ["$stateProvider"];
