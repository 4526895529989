


'use strict';

export const $attachmentService  = function ($http, appConfig) {
        return {
            download: function (clientId, uploadId, isViewAttachment) {
                return $http.get(appConfig.apiUrl + '/attachment/' + clientId + '/download-with-url/' + uploadId, {
                    ignoreAuth: true,
                    params: {
                        isViewAttachment: isViewAttachment || false
                    }
                })
                    .then((res) => {
                        if (res && res.data.status && res.data.url) {
                            return {
                                status: true,
                                url: res.data.url
                            }
                        } else {
                            return {
                                status: false
                            }
                        }
                    })
                    .catch((err) => {
                        console.log('Attachment Err ***', err);
                        let errMessage = err.message || err;
                        if (err && err.data && err.data.message) errMessage = err.data.message;
                        return {
                            status: false,
                            message: errMessage
                        }
                    });
            }
        };
    } 
 

// Dependency Injection
$attachmentService.$inject = ['$http', 'appConfig'];
