
'use strict';

export const $icDegreesOrCertificatesService  = function (IcDegreesOrCertificates) {
        return {
            icDegreeOrCertificateList: function (client, vendorId) {
                return IcDegreesOrCertificates.icDegreeOrCertificateList({
                    id: client._id || client,
                    vendorId
                }).$promise;
            },
            createOrUpdate: function (client, data) {
                return IcDegreesOrCertificates.createOrUpdate({
                    _id: client._id || client,
                    data
                }).$promise;
            },
            delete: function (client, certificateId) {
                return IcDegreesOrCertificates.delete({
                    id: client._id || client,
                    controller: certificateId
                }).$promise;
            },
            createOrUpdateComment: function (client, data) {
                return IcDegreesOrCertificates.createOrUpdateComment({
                    _id: client._id || client,
                    data
                }).$promise;
            },
            deleteComment: function (client, data) {
                return IcDegreesOrCertificates.deleteComment({
                    _id: client._id || client,
                    data
                }).$promise;
            },
            addAttachments: function (client, data) {
                return IcDegreesOrCertificates.addAttachments({
                    _id: client._id || client,
                    data
                }).$promise;
            },
            deleteAttachment: function (client, data) {
                return IcDegreesOrCertificates.deleteAttachment({
                    _id: client._id || client,
                    data
                }).$promise;
            }
        };
    }
// Dependency Injection


// Dependency Injection
$icDegreesOrCertificatesService.$inject = ['IcDegreesOrCertificates'];
