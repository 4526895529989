
'use strict';

export const $performTaskAssignService  = function ($clientTask, Modal) {
  return function (cb) {
    cb = angular.isFunction(cb) ? cb : angular.noop;
    return Modal.alertActions.assignTask(function (result, record, assignResult) {
      return cb.call(this, result, record, assignResult);
    }, 'Assign Task');
  };
}
 

// Dependency Injection
$performTaskAssignService.$inject = ['$clientTask', 'Modal'];
