
'use strict';

export const $clientUploadService  = function ($q, $authStorage, ClientUpload, $http, $window) {
    return {
      listAll: function (client) {
        return ClientUpload.query({ id: (client._id || client) }).$promise;
      },
      download: function (client, uploadId) {
        $window.location = appConfig.apiUrl + '/api/client-uploads/' + (client._id || client) + '/' + uploadId + '?access_token=' + $authStorage.getToken();
      },
      delete: function (client, attachment) {
        return ClientUpload.remove({ id: (client._id || client), attachment: (attachment._id || attachment) }).$promise;
      },
      generateSignedUrl: function (client) {
        return ClientUpload.generateSignedUrl({ id: (client._id || client) }).$promise;
      },
      createRecord: function (client, data) {
        return ClientUpload.createRecord({
          _id: (client._id || client),
          data: data
        }).$promise;
      }
    };
  } 
 

// Dependency Injection
$clientUploadService.$inject = ['$q', '$authStorage', 'ClientUpload', '$http', '$window'];
