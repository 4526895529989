
'use strict';

export const $scheduleTasksManagerService  = function (ScheduleTasksManager) {
    return {
      createSchedule: function(client, scheduleData) {
        return ScheduleTasksManager.saveSchedule({
          _id: client._id || client,
          schedule: scheduleData
        }).$promise;
      },
      updateSchedule: function(client, schedule, data) {
        return ScheduleTasksManager.updateSchedule({
          _id: client._id || client,
          schedule: schedule,
          data: data
        }).$promise;
      },
      getSchedules: function(client) {
        return ScheduleTasksManager.getSchedules({
          id: client._id || client
        }).$promise;
      },
      deleteSchedule: function(client, schedule) {
        return ScheduleTasksManager.deleteSchedule({
          _id: client._id || client,
          schedule: schedule._id
        }).$promise;
      },
      toggleSchedule: function(client, schedule) {
        return ScheduleTasksManager.toggleSchedule({
          _id: client._id || client,
          schedule: schedule._id
        }).$promise;
      },
    };
  }
// Dependency Injection


// Dependency Injection
$scheduleTasksManagerService.$inject = ['ScheduleTasksManager'];
