
'use strict';

export const loadSpinnerDirective  = function () {
  return {
    restrict: 'E',
    templateUrl: 'components/util/load-spinner.html',
    controller: ['$scope', 'LoadSpinnerCtrl', function ($scope, LoadSpinnerCtrl) {
      $scope.activeCtrl = new LoadSpinnerCtrl(false);
    }],
    scope: {
      isActive: '=isActive'
    },
    link: function (scope, el, attrs) {
      scope.$watch('isActive', function (nV) {
        if(nV === undefined) return;
        scope.activeCtrl.active = nV;
      });
    }
  };
}
