
'use strict';

export const $databaseService  = function (Database,$http) {
        return {
            listAllDatabaseCollection: function () {
                return Database.databaseCollection().$promise;
            },
            exportCollectionRecord: function (client) {
                return $http({
                    url: '/api/admin-database-collection/'+ (client._id || client) +'/export-collection-record', 
                    method: "GET"
                  })
                  .success(function(data, status, headers, config) {
                    if(data.status === false) {
                      return console.log(data.reason);
                    }
          
                    var element = angular.element('<a></a>');
                    element.attr({
                      href: 'data:attachment/zip;base64,' + data,
                      target: '_blank',
                      download: 'Collection' + moment().format('MMDDYYYY') + '_' + moment().format('MMDDYYYY') + '.zip'
                    });
                    element[0].click();
                  })
                  .error(function(data, status, headers, config) {
                    alert('Error while downloading the tasks');
                  });
            }
        }
    }
// Dependency Injection


// Dependency Injection
$databaseService.$inject = ['Database', '$http'];
