


'use strict';

export const ClientEquipmentFactory  = function ($resource, appConfig) {
    return $resource(appConfig.apiUrl + '/api/client-equipment/:id/:controller', {
      id: '@_id'
    },
    {
      detail: {
        method: 'GET',
        params: {
          controller: 'details'
        }
      },
      getEquipments: {
        method: 'GET',
        params: {
          controller: ''
        } 
      },
      createEquipment: {
          method: 'POST',
          params: {
              controller: 'create'
          }
      },
      updateEquipment: {
          method: 'POST',
          params: {
              controller: 'update'
          }
      },
      deleteEquipment: {
          method: 'POST',
          params: {
              controller: 'delete'
          }
      }
    });
  } 
 

// Dependency Injection
ClientEquipmentFactory.$inject = ['$resource', 'appConfig'];
