'use strict';

export const OfficesConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard.settings.offices', {
        url: '/offices',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/offices/offices.html', '6edb93ca'); }],
        controller: 'DashboardSettingsOfficesCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/offices/offices.controller.js', 'DashboardSettingsOfficesCtrl'); }]
                      },
        data: {
          ability: 'manage.client.offices'
        }
      })
      .state('app.account.dashboard.settings.offices.index', {
        url: '/offices',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/offices/index/index.html', 'b2678dea'); }],
        controller: 'DashboardSettingsOfficesIndexCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/offices/index/index.controller.js', 'DashboardSettingsOfficesIndexCtrl'); }]
                      },
        data: {
          ability: 'manage.client.offices'
        }
      })
      .state('app.account.dashboard.settings.offices.create', {
        url: '/create',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/offices/create/create.html', 'cea231ca'); }],
        controller: 'DashboardSettingsOfficesCreateCtrl',
                      resolve: {
                          lazyLoad: ['$q', '$ocLazyLoad', 'LazyLoadService', function($q, $ocLazyLoad, LazyLoadService) {
                            return $q(function(resolve) {
                              LazyLoadService.loadGoogleMaps().then(()=>{
                                Promise.all([
                                    import('./create/create.controller.js'), // This imports and expects the controller to register itself
                                    import('../../../../../components/forms/office.directive.js')
                                  ]).then(function(modules) {
                                  const [controllerMod] = modules
                                  $ocLazyLoad.load({name: 'coordinateApp'});
                                  resolve(controllerMod.DashboardSettingsOfficesCreateCtrl)
                                }).catch(error => console.error('Failed to load dependencies', error));
                              })
                            });
                        }],
                      },
        data: {
          ability: 'manage.client.offices'
        }
      })
      .state('app.account.dashboard.settings.offices.update', {
        url: '/update/:id',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/offices/update/update.html', '51295baa'); }],
        controller: 'DashboardSettingsOfficesUpdateCtrl',
                      resolve:  {
                        lazyLoad: ['$q', '$ocLazyLoad', 'LazyLoadService', function($q, $ocLazyLoad, LazyLoadService) {
                          return $q(function(resolve) {
                            LazyLoadService.loadGoogleMaps().then(()=>{

                              Promise.all([
                                  import('./update/update.controller.js'), // This imports and expects the controller to register itself

                                  import('../../../../../components/forms/office.directive.js')
                                ]).then(function(modules) {
                                  const [controllerMod] = modules
                                  $ocLazyLoad.load({name: 'coordinateApp'});
                                  resolve(controllerMod.DashboardSettingsOfficesUpdateCtrl)
                              }).catch(error => console.error('Failed to load dependencies', error));
                          });
                        });
                      }],
                        '$selectedOffice': ['$stateParams', '$clientOffice', '$clientSwitcher', function($stateParams, $clientOffice, $clientSwitcher) {
                            return $clientOffice.detail($clientSwitcher.getCurrentClient(), $stateParams.id);
                        }]
                    },
        data: {
          ability: 'manage.client.offices'
        }
      })
      .state('app.account.dashboard.settings.offices.upload', {
        url: '/upload',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/offices/upload/upload.html', '72c97aaa'); }],
        controller: 'DashboardSettingsOfficesUploadCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/offices/upload/upload.controller.js', 'DashboardSettingsOfficesUploadCtrl'); }]
                      },
      });
  }
// Dependency Injection
OfficesConfig.$inject = ["$stateProvider"];
