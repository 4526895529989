
'use strict';

export const formErrorMessagesDirective  = function () {
  return {
    restrict: 'A',
    require: '^formErrors',
    templateUrl: 'components/util/form-errors-messages.html',
    scope: {}, // isolate scope
    link: function (scope, el, attr, ctrl) {
      Object.defineProperties(scope, {
        formValue: {
          get: function () {
            if(!ctrl.formErrors)
              return null;

            return ctrl.formErrors;
          }
        }
      });

      scope.isArray  = function () { return angular.isArray(scope.formValue); };
      scope.isString = function () { return angular.isString(scope.formValue); };
    }
  };
}
 
