'use strict';


export const durationMSFilter = function ($utilities) {
  return function (v, unitMax) {
    if(v === undefined||
       v===false||
       v===null) return '---';

    var
    remainder = parseFloat(v),
    unitLabel = {
      day:  'd',
      hour: 'hr',
      min:  'min',
      sec:  'sec',
      ms:   'ms'
    },
    unitSize = {
      day:  8.64e7,
      hour: 3.6e6,
      min:  60000,
      sec:  1000,
      ms:   1
    };

    unitMax  = $utilities.clamp($utilities.asNumber(unitMax, 2), 1, null, 0);

    return ['day', 'hour', 'min', 'sec'].reduce(function (units, unit) {
      var size = unitSize[unit], label = unitLabel[unit];
      if((remainder < size && !units.length) || units.length >= unitMax) return units;

      var
      len = Math.floor(remainder / size);

      // if(len === 0 && label !== 'sec') {
      //   return units;
      // }

      // subtract this from remainder
      remainder -= len * size;

      //add leading zero
      if(label !== 'day' && len < 10) {
        len = '0'+len;
      }

      // add to unit output
      units.push(len + label);

      return units;
    }, []).join(' ');
  };
}
// Dependency Injection
durationMSFilter.$inject = ["$utilities"];
