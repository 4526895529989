import './user_notification.scss';
'use strict';

export const userNotificationDirective = function ($rootScope, $auth, $clientUser, $activeClient, $clientSwitcher, $userNotification, WO_Event, System_Event, DEFAULT_DEBOUNCE, ngToast, Service_Portal_Event, Service_Event) {
        return {
            restrict: 'E',
            templateUrl: 'app/account/settings/notification_setting/user_notification/user_notification.html',
            scope: {
                settings: '=ngModel',
            },
            link: function (scope, elem, attrs) {
                let killClientWatch;
                let currentCli = $clientSwitcher.getCurrentClient();
                const currentUser = $auth.getCurrentUser();
                if (currentUser) {
                    scope.isServicePortalUser = currentUser.settings.roles.findIndex(x => x.code === "SERVICE_PORTAL") >= 0;
                }
                scope.formData = {};
                scope.woEventKey = WO_Event;
                scope.systemEventKey = System_Event;
                scope.servicePortalEventKey = Service_Portal_Event;
                scope.serviceEventKey = Service_Event;
                scope.updating = false;
                scope.isInterpreterUser = false;
                scope.keys = Object.keys;
                scope.eventTypes = {
                    [WO_Event]: [],
                    [System_Event]: [],
                    [Service_Portal_Event]: [],
                    [Service_Event]: []
                };
                scope.dataList = {
                    [WO_Event]: [],
                    [System_Event]: [],
                    [Service_Portal_Event]: [],
                    [Service_Event]: []
                };
                scope.error = null;
                scope.isLoading = false;
                scope.search = {
                    searchText: '',
                    orderVal: 1,
                    event: null
                }
                scope.tabs = [{
                    label: 'wo_event',
                    value: WO_Event,
                    position: 1,
                    isShowServicePortalUser: false
                }, {
                    label: 'system_event',
                    value: System_Event,
                    position: 2,
                    isShowServicePortalUser: false
                }, {
                    label: 'service_portal_event',
                    value: Service_Portal_Event,
                    position: 3,
                    isShowServicePortalUser: true
                },
                {
                    label: 'service_event',
                    value: Service_Event,
                    position: 4,
                    isShowServicePortalUser: false
                }];
                scope.pagination = {
                    currentPage: 1,
                    page: 1,
                    total: 0,
                    pageLimit: 20,
                };

                scope.sortingOrders = [{
                    label: 'A-Z',
                    value: '1'
                }, {
                    label: 'Z-A',
                    value: '-1'
                }];
                scope.tabSelected = function (tabName, selectedData) {
                    scope.selectedTab = tabName;
                    //change tab get list of event list
                    scope.pagination.page = 1;
                    scope.search = {
                        searchText: '',
                        orderVal: 1,
                        event: null
                    }
                    scope.error = null;
                    scope.loadEvent()
                };

                scope.isTabSelected = function (tabName) {
                    return scope.selectedTab === tabName;
                };

                scope.loadEvent = function () {
                    if ((scope.dataList && scope.dataList[scope.selectedTab] && scope.dataList[scope.selectedTab].length)) return
                    if (scope.isLoading) return;
                    scope.isLoading = true;
                    scope.error = null;
                    let filter = {
                        // search: scope.search.searchText,
                        type: scope.selectedTab,
                        // orderByVal: scope.search.orderVal,
                        event: scope.search.event
                    }
                    $userNotification.getList(currentCli, scope.pagination, filter)
                        .then((res) => {
                            if (res.status) {
                                if (res.userAccessNotifications && res.userAccessNotifications.length) {
                                    scope.dataList[scope.selectedTab] = res.userAccessNotifications;
                                    scope.formData[scope.selectedTab] = JSON.parse(JSON.stringify(scope.dataList[scope.selectedTab]))
                                    scope.pagination.total = res.managementNtCount;
                                    scope.eventTypes[scope.selectedTab] = res.managementNt;
                                    scope.isInterpreterUser = res.isInterpreterUser;
                                } else {
                                    scope.error = 'No Event Found.';
                                }
                            }
                            scope.isLoading = false;
                        })
                        .catch((err) => {
                            console.log(err)
                            scope.dataList[scope.selectedTab] = [];
                            scope.pagination.total = 0;
                            scope.error = 'No Event Found.';
                            scope.isLoading = false;
                        })
                        .finally(() => {

                        })
                }

                scope.applySearch = _.debounce(function (cv) {
                    scope.applyFilter();
                }, DEFAULT_DEBOUNCE)

                scope.onSearch = function () {
                    scope.applySearch();
                }

                scope.applyFilter = function () {
                    scope.pagination.page = 1;
                    scope.loadEvent()
                }

                function init() {
                    killClientWatch = $activeClient(function (current) {
                        if (!current) {
                            return;
                        }
                        currentCli = current;
                        scope.client = current;
                        scope.selectedTab = WO_Event;
                        if (scope.isServicePortalUser) {
                            scope.tabs = scope.tabs.filter((obj) => obj.isShowServicePortalUser);
                            scope.selectedTab = Service_Portal_Event;
                        }
                        scope.loadEvent();
                    });
                }

                scope.updateNotifications = function (selectedTabEventData) {
                    scope.updating = true;
                    let type = scope.selectedTab == WO_Event ? 'wo_event' : 
                        scope.selectedTab == System_Event ? 'system_event' : 
                        scope.selectedTab == Service_Event ? 'service_event' : 
                        scope.selectedTab == Service_Portal_Event ?'service_portal_event' : null;
                    $userNotification.update(currentCli, selectedTabEventData, type).then(res => {
                        if (res.status) {
                            ngToast.create({
                                content: 'Notification Setting Update Successfully',
                                className: 'success'
                            });
                        } else {
                            ngToast.create({
                                content: res.message || 'Somthingwent wrong, please try again!',
                                className: 'danger'
                            });
                        }
                    })
                        .catch(error => {
                            let errMessage = error.message || error;
                            if (error && error.data && error.data.message) errMessage = error.data.message;
                            ngToast.create({
                                content: errMessage,
                                className: 'danger'
                            });
                        })
                        .finally(() => {
                            scope.updating = false;
                        })
                }

                scope.$on('$destroy', function () {
                    if (killClientWatch) {
                        killClientWatch();
                    }
                });
                init()
            }
        }
    }
// Dependency Injection
userNotificationDirective.$inject = ["$rootScope","$auth","$clientUser","$activeClient","$clientSwitcher","$userNotification","WO_Event","System_Event","DEFAULT_DEBOUNCE","ngToast","Service_Portal_Event","Service_Event"];
