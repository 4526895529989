
'use strict';

export const $clientManagerService  = function (ClientManager) {
    return {
      listAll: function(client, includeOffices) {
        return ClientManager.query({
          id: client._id || client,
          includeOffices: !!includeOffices ? 1 : 0
        }).$promise;
      },

      detail: function(clientId, managerId) {
        return ClientManager.detail({
          id: clientId._id||clientId,
          managerId: managerId
        }).$promise;
      },

      remove: function(clientId, managerId) {
        return ClientManager.remove({
          id: clientId._id||clientId,
          controller: managerId
        }).$promise;
      }
    };
  }
// Dependency Injection


// Dependency Injection
$clientManagerService.$inject = ['ClientManager'];
