import './external.scss';
'use strict';

export const ExternalConfig = function ($stateProvider) {
    $stateProvider
      .state('app.external', {
        url: '/ext/:token',
        data: {
          roles: []
        },
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./external/external.html', '5ca7c48a'); }],
        controller: 'ExternalTaskCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./external/external.controller.js', 'ExternalTaskCtrl'); }]
                      }
      });
  };

// Dependency Injection
ExternalConfig.$inject = ["$stateProvider"];
