'use strict';

export const financialDirective = function ($activeClientSettings,$rootScope, $clientDailyWoReports, $clientSwitcher, $window, $authStorage, appConfig) {
        return {
            restrict: 'E',
            templateUrl: 'app/account/dashboard/tasks/tabs/financial/financial.html',
            scope: {},
            link: function (scope) {
                const currentCli = $clientSwitcher.getCurrentClient();

                scope.fileList = [];
                scope.loadingData = true

                scope.pagination = {
                    currentPage: 1,
                    page: 1,
                    totalFiles: 0,
                    pageLimit: 10,
                    itemsPerPage: 10
                  };

                scope.getFilePath = function (attachment) {
                    return appConfig.apiUrl + '/api/client-uploads/' + (currentCli._id || currentCli) + '/' + attachment._id + '?preview=1&access_token=' + $authStorage.getToken();
                };
        
                scope.showAttachment = function (attachment, index) {
                    scope.downloadUrl = appConfig.apiUrl + '/attachment/' + (currentCli._id || currentCli) + '/' + attachment._id;
                    $window.location = scope.downloadUrl;
                };

                scope.getData = () => {
                    scope.loadingData = true;
                    const filter = {
                        limit: 10,
                        page: scope.pagination.page
                    };

                    $clientDailyWoReports.getWoDailyReports(currentCli, filter)
                        .then(data => {
                            if(data.status) {
                                scope.fileList = data.data;
                                scope.pagination.totalFiles = data.numOfFiles;
                            }
                        })
                        .finally(() => {
                        scope.loadingData = false;

                        })
                }
                scope.getData();

            }
        }
    }
// Dependency Injection
financialDirective.$inject = ["$activeClientSettings","$rootScope","$clientDailyWoReports","$clientSwitcher","$window","$authStorage","appConfig"];
