'use strict';

export const DashboardSettingsReportBucketsIndexCtrl = function ($rootScope, $auth, $scope, $activeClient, $clientReportBuckets) {

        var currentCli;
        $scope.loading = false;
        $scope.search ={
            orderVal: 'true'
        };
        $scope.sortingOrders = [{
            label:'A-Z',
            value: 'true'
          }, {
            label: 'Z-A',
            value: 'false'
        }]
        function reloadReportBuckets(client) {
            $scope.loading = true;
            $scope.allowEdit = $auth.getCurrentUser().permissions.includes('SETTING_REPORT_BUCKET_EDIT');
            return $clientReportBuckets.getReportBuckets(client)
                .then(function (reportBuckets) {
                    if(reportBuckets.status){
                        $scope.reportBuckets = reportBuckets.data;
                        $scope.activeBuckets = 0;
                        $scope.reportBuckets.forEach(function(o){
                          if(o.isEnabled) $scope.activeBuckets++;
                        });
                    }
                })
                .finally(function () {
                    $scope.loading = false;
                });
        }

        // listen for client switching
        $scope.$on('$destroy', $activeClient(function (client) {
            if (!client) return;
            currentCli = client;
            reloadReportBuckets(currentCli);
        }));
    }
// Dependency Injection
DashboardSettingsReportBucketsIndexCtrl.$inject = ["$rootScope","$auth","$scope","$activeClient","$clientReportBuckets"];

// angular.module('coordinateApp').controller('DashboardSettingsReportBucketsIndexCtrl', DashboardSettingsReportBucketsIndexCtrl);
