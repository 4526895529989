'use strict';

export const invoicePanelDirective = function ($auth, $stateParams, $timeout, $serviceJobInvoices, $activeClient, $clientSwitcher, $Linguist, Modal) {
        return {
            restrict: 'E',
            templateUrl: 'app/account/services/invoices/invoices-panel/invoices-panel.html',
            scope: {
            },
            link: function (scope, element, attrs) {
                let killClientWatch, currentCli = $clientSwitcher.getCurrentClient();
                scope.pendingInvoicesObj = {
                    isLoading: false,
                    error: null,
                    paginate: {
                        currentPage: 1,
                        page: 1,
                        total: 0,
                        pageSize: 20
                    },
                    sorting: {
                        orderByValue: '-1',
                        orderByField: 'createdAt'
                    },
                    invoicesList: []
                }

                scope.paidInvoicesObj = {
                    isLoading: false,
                    error: null,
                    paginate: {
                        currentPage: 1,
                        page: 1,
                        total: 0,
                        pageSize: 20
                    },
                    sorting: {
                        orderByValue: '-1',
                        orderByField: 'createdAt'
                    },
                    invoicesList: []
                }
                scope.services = $Linguist.getServiceLanguages();
                scope.filter = {
                    startDate: moment().startOf('month').format('YYYY-MM-DD'),
                    endDate: moment().endOf('month').format('YYYY-MM-DD'),
                    service: 'all'
                };
                scope.hasPermission = {};

                function initDateRangePicker() {
                    function cb(start, end) {
                        $timeout(function () {
                            if (!start || !end) {
                                return;
                            }
                            $('#reportrange span').html(start.format('MM/DD/YYYY') + ' to ' + end.format('MM/DD/YYYY'));
                            scope.filter.startDate = start.format('YYYY-MM-DD');
                            scope.filter.endDate = end.format('YYYY-MM-DD');
                            // on change call
                            scope.onServiceChange()
                        }, 0);
                    }

                    $('#reportrange').daterangepicker({
                        startDate: moment(scope.filter.startDate),
                        endDate: moment(scope.filter.endDate),
                        opens: 'right'
                    }, cb);

                    var text = moment(scope.filter.startDate).format('MM/DD/YYYY') + ' to ' + moment(scope.filter.endDate).format('MM/DD/YYYY');
                    $('#reportrange span').html(text);
                }


                scope.getPendingInvoices = function () {
                    if (scope.pendingInvoicesObj.isLoading) return;
                    scope.pendingInvoicesObj.isLoading = true;
                    scope.pendingInvoicesObj.error = null;
                    let limit = scope.pendingInvoicesObj.paginate.pageSize;
                    let offset = (scope.pendingInvoicesObj.paginate.page - 1) * scope.pendingInvoicesObj.paginate.pageSize;
                    let orderByValue = scope.pendingInvoicesObj.sorting.orderByValue;
                    let orderByField = scope.pendingInvoicesObj.sorting.orderByField;
                    let filters = Object.assign({ limit, offset, orderByValue, orderByField }, scope.filter)
                    scope.pendingInvoicesObj.invoicesList = [];
                    $serviceJobInvoices.getPendingInvoices(currentCli, filters)
                        .then((res) => {
                            if (res && res.status) {
                                if (res.invoices && res.invoices.length) {
                                    scope.pendingInvoicesObj.invoicesList = res.invoices || [];
                                } else {
                                    scope.pendingInvoicesObj.error = 'No invoices found!';
                                }
                                scope.pendingInvoicesObj.paginate.total = res.counts;
                            } else {
                                scope.pendingInvoicesObj.error = res.message || res.reason;
                            }
                        }).catch((err) => {
                            let errMessage = err.message || err;
                            if (err && err.data && err.data.message) {
                                errMessage = err.data.message;
                            } else if (err && err.data && err.data.reason) {
                                errMessage = err.data.reason;
                            }
                            scope.pendingInvoicesObj.error = errMessage;
                        })
                        .finally(() => {
                            scope.pendingInvoicesObj.isLoading = false;
                        })
                }

                scope.getPaidInvoices = function () {
                    if (scope.paidInvoicesObj.isLoading) return;
                    scope.paidInvoicesObj.isLoading = true;
                    scope.paidInvoicesObj.error = null;
                    let limit = scope.paidInvoicesObj.paginate.pageSize;
                    let offset = (scope.paidInvoicesObj.paginate.page - 1) * scope.paidInvoicesObj.paginate.pageSize;
                    let orderByValue = scope.paidInvoicesObj.sorting.orderByValue;
                    let orderByField = scope.paidInvoicesObj.sorting.orderByField;
                    let filters = Object.assign({ limit, offset, orderByValue, orderByField }, scope.filter)
                    scope.paidInvoicesObj.invoicesList = [];
                    $serviceJobInvoices.getPaidInvoices(currentCli, filters)
                        .then((res) => {
                            if (res && res.status) {
                                if (res.invoices && res.invoices.length) {
                                    scope.paidInvoicesObj.invoicesList = res.invoices || [];
                                } else {
                                    scope.paidInvoicesObj.error = 'No invoices found!';
                                }
                                scope.paidInvoicesObj.paginate.total = res.counts;
                            } else {
                                scope.paidInvoicesObj.error = res.message || res.reason;
                            }
                        }).catch((err) => {
                            let errMessage = err.message || err;
                            if (err && err.data && err.data.message) {
                                errMessage = err.data.message;
                            } else if (err && err.data && err.data.reason) {
                                errMessage = err.data.reason;
                            }
                            scope.paidInvoicesObj.error = errMessage;
                        })
                        .finally(() => {
                            scope.paidInvoicesObj.isLoading = false;
                        })
                }

                scope.sortRecord = function (colName, isPaidInvoices) {
                    let objName = 'pendingInvoicesObj';
                    if (isPaidInvoices) {
                        objName = 'paidInvoicesObj';
                    }
                    if (scope[objName].sorting.orderByField == colName && scope[objName].sorting.orderByValue == '-1') {
                        scope[objName].sorting.orderByValue = '1';
                    } else {
                        scope[objName].sorting.orderByValue = '-1'
                    }
                    scope[objName].sorting[colName] = scope[objName].sorting.orderByValue == '1' ? false : true

                    scope[objName].sorting.orderByField = colName;

                    scope[objName].paginate.page = 1;
                    if (isPaidInvoices) {
                        scope.getPaidInvoices();
                    } else {
                        scope.getPendingInvoices();
                    }
                }

                scope.onServiceChange = function () {
                    scope.getPendingInvoices();
                    scope.getPaidInvoices();
                }

                var init = function () {
                    killClientWatch = $activeClient(function (current) {
                        if (!current) {
                            return;
                        }
                        currentCli = current;
                        scope.user = $auth.getCurrentUser();
                        scope.hasPermission.viewInvoices = scope.user.permissions.includes('VIEW_SERVICE_INVOICES') || false;
                        $timeout(() => {
                            initDateRangePicker();
                        }, 200)

                        scope.getPendingInvoices();
                        scope.getPaidInvoices();
                    });
                }

                scope.$on('$destroy', function () {
                    if (killClientWatch) {
                        killClientWatch();
                    }
                });

                init();
            }
        }
    }
// Dependency Injection
invoicePanelDirective.$inject = ["$auth","$stateParams","$timeout","$serviceJobInvoices","$activeClient","$clientSwitcher","$Linguist","Modal"];
