'use strict';

export const SettingsConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard.settings', {
        url: '/settings',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/settings.html', '5babb7ca'); }],
        controller: 'DashboardSettingsCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/settings.controller.js', 'DashboardSettingsCtrl'); }]
                      },
        data: {
          ability: 'update.settings',
          permission: 'SETTINGS_READ'
        }
      })
      .state('app.account.dashboard.settings.index', {
        url: '',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/index/index.html', 'bf8e284f'); }],
        controller: 'DashboardSettingsIndexCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/index/index.controller.js', 'DashboardSettingsIndexCtrl'); }]
                      },
        data: {
          ability: 'update.settings',
          permission: 'SETTINGS_READ'
        }
      })
      .state('app.account.dashboard.settings.push', {
        url: '/push',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/push/push.html', '5772196f'); }],
        controller: 'DashboardSettingsPushCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/push/push.controller.js', 'DashboardSettingsPushCtrl'); }]
                      },
        data: {
          ability: 'update.settings'
        }
      })
      .state('app.account.dashboard.settings.notifications', {
        url: '/notifications',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/notifications/notifications.html', 'd34d8d0a'); }],
        controller: 'DashboardSettingsNotificationsCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/notifications/notifications.controller.js', 'DashboardSettingsNotificationsCtrl'); }]
                      },
        data: {
          ability: 'update.settings'
        }
      })
      // .state('app.account.dashboard.settings.holidays', {
      //   url: '/holidays',
      //   templateProvider: ['$q', '$templateCache', function($q, $templateCache) {
              //     var cacheName = 'holidays_holidaysTemplate';
              //     return $q((resolve) => {
              //         if ($templateCache.get(cacheName)) {
              //             resolve($templateCache.get(cacheName));
              //         } else {
              //             import('./holidays/holidays.html').then(template => {
              //                 $templateCache.put(cacheName, template.default);
              //                 resolve(template.default);
              //             });
              //         }
              //     });
              // }],
      //   controller: 'DashboardSettingsHolidaysCtrl',
      //   data: {
      //     ability: 'update.settings'
      //   }
      // })
      .state('app.account.dashboard.settings.timeReportingLogs', {
        url: '/time-reporting-logs',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/time-reporting-logs/index.html', '47cefd8'); }],
        controller: 'DashboardSettingsTimeReportingLogsCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/time-reporting-logs/index.controller.js', 'DashboardSettingsTimeReportingLogsCtrl'); }]
                      },
        data: {
          ability: 'client.TimeProductionReporting'
        }
      })
      .state('app.account.dashboard.settings.scheduled-notifications', {
        url: '/scheduled-notifications',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/scheduled-notifications/index.html', 'a7843e94'); }],
        controller: 'ScheduledNotificationCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/scheduled-notifications/index.js', 'ScheduledNotificationCtrl'); }]
                      },
        data: {
          ability: 'update.settings'
        }
      })
      // .state('app.account.dashboard.settings.email', {
      //   url: '/email',
      //   templateProvider: ['$q', '$templateCache', function($q, $templateCache) {
              //     var cacheName = 'accountdashboardsettingssettingsemail_emailTemplate';
              //     return $q((resolve) => {
              //         if ($templateCache.get(cacheName)) {
              //             resolve($templateCache.get(cacheName));
              //         } else {
              //             import('./email/email.html').then(template => {
              //                 $templateCache.put(cacheName, template.default);
              //                 resolve(template.default);
              //             });
              //         }
              //     });
              // }],
      //   controller: 'DashboardSettingsEmailCtrl',
      //   data: {
      //     ability: 'update.settings'
      //   }
      // })
      ;
  }
// Dependency Injection
SettingsConfig.$inject = ["$stateProvider"];
