
'use strict';

export const $assessmentPartsService  = function () {
        let parts = [
            {
                label: '1',
                value: 1
            },
            {
                label: '2',
                value: 2
            },
            {
                label: '3',
                value: 3
            }
        ]
        return parts;
    } 
 
