'use strict';

export const DatabaseConfig = function ($stateProvider) {
        $stateProvider
            .state('app.account.clients.database', {
                url: '',
                templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/clients/database/database.html', '527b29ca'); }],
                controller: 'DatabaseCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/clients/database/database.controller.js', 'DatabaseCtrl'); }]
                      }
            });
    }
// Dependency Injection
DatabaseConfig.$inject = ["$stateProvider"];
