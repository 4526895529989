'use strict';


export const myTimeFilter = function () {
	return function (value, twelveHourFormat) {
        var h = parseInt(value / 60);
        var m = parseInt(value % 60);
        
        var wasHourAboveTwelve = (h >= 12);
        if(twelveHourFormat) {
        	h = (h > 12) ? h-12 : h;
        }

        var dStr = '';
        if(h >= 24) {
            var dayCount = Math.floor(h/24);
            dStr = dayCount + ' ' + ((dayCount > 1) ? 'days' : 'day');
            h %= 24;
        }
        var hStr = (h > 0) ? ((h < 10) ? '0'+h : h) : '00'; //add leading zero
        var mStr = (m > 0) ? ((m < 10) ? '0'+m : m) : '00';
        var glue = (hStr && mStr) ? ':' : '';

        var timeString = dStr + ' ' + hStr + glue + mStr;

        if(twelveHourFormat) {
    		timeString += wasHourAboveTwelve ? ' PM' : ' AM';
        }

        return timeString;
    };
}