'use strict';


export const surveyTaskControlsDirective = function (TASK_STATE_STARTED, TASK_STATE_PAUSED) {
  return {
    restrict: 'E',
templateUrl: 'components/task-controls/survey/task-controls.html',
    scope: {
      task: '=',
      allowArchive: '=', performArchive: '=',
      allowAssign: '=', performAssign: '=',
      allowResolve: '=', performResolve: '=',
      allowReopen: '=', performReopen: '=',
      allowTaskHistory: '=', showTaskHistory: '=',
      allowResolveHistory: '=', showResolveHistory: '=',
      allowToggleState: '=', performToggleState: '=',
      allowRemove: '=', performRemove: '=',
      listenTaskAudio: '=',
      showTaskScore: '=',
      performTranscribeAudio: '=',
      performForwardTaskEmail: '='
    },
    link: function (scope, el, attrs) {
        scope.TASK_STATE_STARTED = TASK_STATE_STARTED;
        scope.TASK_STATE_PAUSED = TASK_STATE_PAUSED;
        scope.clientStrings = function(){ return $clientSwitcher.getFeatureOptions('client.strings'); };
    }
  };
}
// Dependency Injection
surveyTaskControlsDirective.$inject = ["TASK_STATE_STARTED","TASK_STATE_PAUSED"];
