


'use strict';

export const NationalitiesFactory  = function ($resource, appConfig) {
    return $resource(appConfig.apiUrl + '/api/nationalities/:id/:controller', {
      id: '@_id'
    },
      {
        getNationalitiesList: {
          method: 'GET',
          params: {
            controller: 'get-nationalities-list'
          }
        },
        getById: {
          method: 'GET',
          params: {
            id: '@id'
          }
        },
        create: {
          method: 'POST',
          params: {
            controller: 'create'
          }
        },
        update: {
          method: 'POST',
          params: {
            controller: 'update'
          }
        },
      });
  } 
 

// Dependency Injection
NationalitiesFactory.$inject = ['$resource', 'appConfig'];
