'use strict';

export const SosEmailInformationConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard.sosEmailInformation', {
        url: '/sos-email-information',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/sos-email-information/sos-email-information.html', '5662d96f'); }],
        controller: 'DashboardSosEmailInformationCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/sos-email-information/sos-email-information.controller.js', 'DashboardSosEmailInformationCtrl'); }],
                          '$userPermissions': ['$auth', function($auth) {
                            return $auth.getCurrentUser();
                          }]
                      },
        data: {
          permission: 'SOS_EMAIL_INFORMATION_READ'
        },
      });
  }
// Dependency Injection
SosEmailInformationConfig.$inject = ["$stateProvider"];
