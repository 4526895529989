import './dashboard.scss';
'use strict';

export const DashboardConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard', {
        url: '/my/dashboard',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/dashboard.html', '7cefbb4a'); }],
        controller: 'AccountDashboardCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/dashboard.controller.js', 'AccountDashboardCtrl'); }]
                      },
        data: {
          permission: 'DASHBOARD_READ'
        
        }
      });
  }
// Dependency Injection
DashboardConfig.$inject = ["$stateProvider"];
