
'use strict';

export const $degreeListService  = function () {
        let degree = [
            { label: 'Associate', value: 'Associate' },
            { label: 'Bachelors', value: 'Bachelors' },
            { label: 'Masters', value: 'Masters' },
            { label: 'PHD', value: 'PHD' },
            { label: 'Certificate', value: 'Certificate' }
        ];
        return degree;
    } 
 
