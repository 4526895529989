


'use strict';

export const ClientPermissionsFactory  = function ($resource, appConfig) {
    return $resource(appConfig.apiUrl + '/api/permissions/:id/:controller', {
      id: '@_id'
    },
      {
        create: {
          method: 'POST',
          params: {
            controller: 'create'
          }
        },
        getPermissions: {
          method: 'GET',
          params: {
            id: '@id'
          }
        },
        getPermissionById: {
          method: 'GET',
          params: {
            id: '@id'
          }
        },
        update: {
          method: 'POST',
          params: {
            controller: 'update'
          }
        }
      });
  } 
 

// Dependency Injection
ClientPermissionsFactory.$inject = ['$resource', 'appConfig'];
