'use strict';

export const BucketsConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard.settings.buckets', {
        url: '/buckets',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/buckets/buckets.html', '504d5bca'); }],
        controller: 'DashboardSettingsBucketsCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/buckets/buckets.controller.js', 'DashboardSettingsBucketsCtrl'); }]
                      },
        data: {
          ability: 'manage.client.buckets'
        }
      })
      .state('app.account.dashboard.settings.buckets.index', {
        url: '/buckets',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/buckets/index/index.html', 'f71ed98a'); }],
        controller: 'DashboardSettingsBucketsIndexCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/buckets/index/index.controller.js', 'DashboardSettingsBucketsIndexCtrl'); }]
                      },
        data: {
          ability: 'manage.client.buckets'
        }
      })
      .state('app.account.dashboard.settings.buckets.create', {
        url: '/create',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/buckets/create/create.html', 'd49b19ea'); }],
        controller: 'DashboardSettingsBucketsCreateCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/buckets/create/create.controller.js', 'DashboardSettingsBucketsCreateCtrl'); }]
                      },
        data: {
          ability: 'manage.client.buckets'
        }
      })
      .state('app.account.dashboard.settings.buckets.update', {
        url: '/update/:id',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/buckets/update/update.html', 'e314b44a'); }],
        controller: 'DashboardSettingsBucketsUpdateCtrl',
        resolve: {
            lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/buckets/update/update.controller.js', 'DashboardSettingsBucketsUpdateCtrl'); }],
            '$selectedBucket': ['$stateParams', '$clientBucket', '$clientSwitcher', function ($stateParams, $clientBucket, $clientSwitcher) {
              return $clientBucket.detail($clientSwitcher.getCurrentClient(), $stateParams.id);
            }]
        },
        data: {
          ability: 'manage.client.buckets'
        },
      });
  }
// Dependency Injection
BucketsConfig.$inject = ["$stateProvider"];
