
'use strict';

export const $taskCreateRulesService  = function (TaskCreateRules) {
    return {
      index: function(client) {
        return TaskCreateRules.index({
          id: client._id || client,
        }).$promise;
      },
      create: function(client, ruleData) {
        return TaskCreateRules.create({
          _id: client._id || client,
          ruleData: ruleData
        }).$promise;
      },
      update: function(client, ruleData) {
        return TaskCreateRules.update({
          _id: client._id || client,
          rule: ruleData._id,
          data: ruleData
        }).$promise;
      },
      delete: function(client, ruleId) {
        return TaskCreateRules.delete({
          _id: client._id || client,
          rule: ruleId
        }).$promise;
      },
      execute: function(client, rule) {
        return TaskCreateRules.execute({
          _id: client._id || client,
          rule: rule._id || rule
        }).$promise;
      },
      getLogs: function(client, rule, pageNumber, pageSize, searchText) {
        return TaskCreateRules.getLogs({
          id: client._id || client,
          rule: rule._id || rule,
          pageNumber: pageNumber,
          pageSize: pageSize,
          search: searchText
        }).$promise;
      },
      toggle: function(client, ruleId) {
        return TaskCreateRules.toggle({
          _id: client._id || client,
          rule: ruleId
        }).$promise;
      }
    };
  }
// Dependency Injection


// Dependency Injection
$taskCreateRulesService.$inject = ['TaskCreateRules'];
