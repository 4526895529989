
'use strict';

export const $clientUserSettingService  = function (ClientUserSetting) {
  function cliId (client) {
    return (client._id||client);
  }

  return {
    fetch: function (client, userId) {
      return ClientUserSetting.query({ id: cliId(client) , userId: userId}).$promise;
    },
    apply: function (client, settings) {
      return ClientUserSetting.apply({ id: cliId(client), settings: settings }).$promise;
    },
    replace: function (client, settings) {
      return ClientUserSetting.replace({ id: cliId(client), settings: settings }).$promise;
    },
    defaultSettings: function(client){
      return ClientUserSetting.defaultSettings({ id: cliId(client) }).$promise;
    }
  };
}
// Dependency Injection


// Dependency Injection
$clientUserSettingService.$inject = ['ClientUserSetting'];
