'use strict';

var MIN_CHARS_FOR_SERACH = 3;


export const UasUsersCtrl = function () {
  var arrUser = null;

  Object.defineProperties(this, {
    length: {
      get: function () { return (arrUser||[]).length; }
    },
    items: {
      get: function () { return arrUser||[]; }
    }
  });

  this.bindItems = function (arr) {

    if(angular.isObject(arr) && (arr.hasOwnProperty('escalates') || arr.hasOwnProperty('delegates'))) {
      var
      userMap = function (usr) { if(!usr) return null; return [usr.userId, usr.name, usr]; },
      escalates = (arr.escalates||[]).map(userMap),
      delegates = (arr.delegates||[]).map(userMap),
      me = arr.me || {},
      me        = [[me._id, '>> ' + me.name + ' <<', me]];
      arr = [].concat(escalates, me, delegates);
    }

    if(!angular.isArray(arr))
      return this;

    arrUser = arr;
    return this;
  };

  this.unbindItems = function () {
    arrUser = null;
    return this;
  };
}
 export const uasUsersDirective = function () { // task
  return {
    restrict: 'A',
    require: ['uasUsers'],
    controller: 'UasUsersCtrl as $uasUsers',
    link: function(scope, el, attrs, ctrls) {
      var ctrl = ctrls[0];
      scope.$watch(attrs.uasUsers, ctrl.bindItems.bind(ctrl));
    }
  };
}
 export const UasExternalUsersCtrl = function (){
  var arrUser = null;

  Object.defineProperties(this, {
    length: {
      get: function () { return (arrUser||[]).length; }
    },
    items: {
      get: function () { return arrUser||[]; }
    },
    users: {
      get: function ()  { return arrUser; },
      set: function (v) { arrUser = v; }
    }
  });

  this.setUsers = function (users) {
    users = users || [];
    return this;
  };

  this.bindItems = function (arr) {
    arrUser = arr;
    return this;
  };

  this.unbindItems = function () {
    arrUser = null;
    return this;
  };
}
 export const uasExternalUsersDirective = function (){
  return {
    restrict: 'A',
    require: ['uasExternalUsers'],
    controller: 'UasExternalUsersCtrl as $uasExternalUsers',
    link: function(scope, el, attrs, ctrls) {
      // var ctrl = ctrls[0];
      // scope.$watch(attrs.uasExternalUsers, ctrl.bindItems.bind(ctrl));
    }
  };
}
 export const UasTaskCtrl = function () {
  var task = null;

  Object.defineProperties(this, {
    task: {
      get: function ()  { return task; },
      set: function (v) { task = v; }
    }
  });

  this.setTask = function (m) {
    task = m||null;
    return this;
  };
}
 export const uasTaskDirective = function () { // task
  return {
    restrict: 'A',
    require: ['uasTask'],
    controller: 'UasTaskCtrl as $uasTask',
    link: function(scope, el, attrs, ctrls) {
      var
      ctrl = ctrls[0];
    }
  };
}
 export const UasModelInternalCtrl = function () {
  var
  users = null,
  task = null,
  getter  = null,
  setter  = null,
  context = null;

  Object.defineProperties(this, {
    $users: {
      get: function ()  { return users; },
      set: function (v) { return this.setUsers(v); }
    },
    $hasUsers: {
      get: function () { return !!users && users.length > 0; }
    },
    $task: {
      get: function ()  { return task; },
      set: function (v) { return this.setTask(v); }
    },
    $hasTask: {
      get: function () { return !!task; }
    },
    value: {
      get: function ()  {
        if(!getter || !context)
          return null;

        return getter(context);
      },
      set: function (v) {
        if(!this.$hasUsers || !setter || !context)
          return;

        var
        userArr = users.items,
        found = false;

        userArr.every(function (usr, index) {
          var value = usr[0];
          if(value === v) {
            setter(context, value);
            found = true;
          }

          return !found;
        });

        if(!found) { // set field value to null
          setter(context, null);
        }
      }
    }
  });

  this.setParser = function (parser, ctx) {
    getter  = parser;
    setter  = parser.assign;
    context = ctx;
    return this;
  };

  this.setTask = function (v) {
    task = v||null;
    return this;
  };

  this.setUsers = function (v) {
    users = v||null;
    return this;
  };

  this.clear = function () {
    if(!setter || !context)
      return;

    setter(context, null);
  };
}
 export const uasModelInternalDirective = function ($parse) { // task.assignTo | task.assignToOnExpire
  return {
    restrict: 'A',
    require: ['uasModelInternal','uasTask','uasUsers'],
    controller: 'UasModelInternalCtrl as $uasModelInternal',
    link: function(scope, el, attrs, ctrls) {
      var
      ctrl = ctrls[0];

      ctrl.setTask(ctrls[1]);
      ctrl.setUsers(ctrls[2]);

      attrs.$observe('uasModelInternal', function (nv) {
        ctrl.setParser($parse(nv), scope);
      });
    }
  };
}
 export const UasModelExternalCtrl = function () {
  var
  users = null,
  task = null,
  getter  = null,
  setter  = null,
  context = null;

  Object.defineProperties(this, {
    $users: {
      get: function ()  { return users; },
      set: function (v) { return this.setUsers(v); }
    },
    $hasUsers: {
      get: function () { return !!users && users.length > 0; }
    },
    $task: {
      get: function ()  { return task; },
      set: function (v) { return this.setTask(v); }
    },
    $hasTask: {
      get: function () { return !!task; }
    },
    value: {
      get: function ()  {
        if(!getter || !context)
          return null;

        return getter(context);
      },
      set: function (v) {
        if(!setter || !context)
          return;

        if(this.$hasUsers) {
          var
          userArr = users.items,
          found = false;

          userArr.every(function (usr, index) {
            if(usr === v) {
              setter(context, usr);
              found = true;
            }

            return !found;
          });

          if(!found) { // set field value to null
            setter(context, null);
          }
        } else {
          setter(context, v);
        }
      }
    }
  });

  this.setParser = function (parser, ctx) {
    getter  = parser;
    setter  = parser.assign;
    context = ctx;
    return this;
  };

  this.setTask = function (v) {
    task = v||null;
    return this;
  };

  this.setUsers = function (v) {
    users = v||null;
    return this;
  };

  this.clear = function () {
    if(!setter || !context)
      return;

    setter(context, null);
  };
}
 export const uasModelExternalDirective = function ($parse) { // task.assignToEmail | task.assignToEmailOnExpire
  return {
    restrict: 'A',
    require: ['uasModelExternal','uasTask', 'uasExternalUsers'],
    controller: 'UasModelExternalCtrl as $uasModelExternal',
    link: function (scope, el, attrs, ctrls) {
      var
      ctrl = ctrls[0];
      ctrl.setTask(ctrls[1]);
      ctrl.setUsers(ctrls[2]);

      attrs.$observe('uasModelExternal', function (nv) {
        ctrl.setParser($parse(nv), scope);
      });
    }
  };
}
 export const UasCtrl = function ($scope, $clientExternalUsers, $clientSwitcher) {
  var
  defaultOptions = {
    labelHeader: 'User Assignment Selector',
    labelExternal: 'E-mail'
  },
  options       = angular.copy(defaultOptions),
  internalUser  = true,
  task          = null,
  modelInternal = null,
  modelExternal = null,
  client = $clientSwitcher.getCurrentClient();

  Object.defineProperties(this, {
    $options: {
      get: function () { return options; }
    },
    $isExternal: {
      get: function ()  { return !internalUser; },
      set: function (v) {
        internalUser = !v;
        this.cleanupAssignments();
      }
    },
    $isInternal: {
      get: function ()  { return internalUser; },
      set: function (v) {
        internalUser = !!v;
        this.cleanupAssignments();
      }
    },
    task: {
      get: function ()  {
        if(!task)
          return null;

        return task;
      }
    },
    internalUsers: {
      get: function ()  {
        if(!modelInternal || !modelInternal.$hasUsers)
          return [];

        return modelInternal.$users.items;
      }
    },
    externalUsers: {
      get: function() {
        if(!modelExternal || !modelExternal.$hasUsers)
          return [];

        return modelExternal.$users.items;
      }
    },
    internalValue: {
      get: function ()  {
        if(!modelInternal) return null;
        return modelInternal.value;
      },
      set: function (v) {
        if(!modelInternal) return;
        modelInternal.value = v;
      }
    },
    externalValue: {
      get: function ()  {
        if(!modelExternal) return null;
        return modelExternal.value;
      },
      set: function (v) {
        if(!modelExternal || !v) return;
        modelExternal.value = v;
      }
    },
    value: {
      get: function () {
        if(internalUser) {
          return modelInternal.value;
        }

        return modelExternal.value;
      }
    }
  });

  this.emailSelected = function(email){
    if(modelExternal.$users.items.indexOf(email) < 0){
      modelExternal.$users.items.push(email);
      this.externalValue = email;
    }
  };

  this.getExternalUsers = function(email){
    if(!email || email.length < MIN_CHARS_FOR_SERACH) {
      return [];
    }

    var newList = modelExternal.$users.items.slice();
    if (email && newList.indexOf(email) === -1) {
      newList.unshift(email);
    }
    return newList;
  };

  this.loadExternalUsers = function(text) {
    return $clientExternalUsers.list(client, text)
    .then(function(res) {
      if(res.status) {
        var emails = res.data.map(function(externalUser) {
          return externalUser.email;
        });

        modelExternal.$users.bindItems(emails);
      }
    });
  };

  this.cleanupAssignments = function () {
    if(internalUser && modelExternal) {
      modelExternal.clear();
    }
    else if(!internalUser && modelInternal) {
      modelInternal.clear();
    }

    return this;
  };

  this.setOptions = function (o, keepInstance) {
    if(!angular.isObject(o)) return this;
    options = !keepInstance ? angular.extend(defaultOptions, o) : o;
    return this;
  };

  this.setTask = function (m) {
    task = m||null;
    return this;
  };

  this.setModelInternal = function (m) {
    modelInternal = m||null;
    return this;
  };

  this.setModelExternal = function (m) {
    modelExternal = m||null;
    return this;
  };

  var
  first = true,
  dereg = $scope.$watch(function () {
    if(!first) return false;

    var
    hasInternal = !!this.internalValue,
    hasExternal = !!this.externalValue;

    if(hasInternal) {
      this.$isInternal = true;
      return false;
    }

    if(hasExternal) {
      this.$isExternal = true;
      return false;
    }

    return true;
  }.bind(this), function (nv) {
    first = nv;

    if(!nv) dereg();
  });
}
 export const userAssignSelectorDirective = function ($parse) {
  return {
    restrict: 'E',
    require: ['userAssignSelector','uasTask','uasModelExternal','uasModelInternal'],
    templateUrl: 'components/util/user-assign-selector.html',
    replace: true,
    controller: 'UasCtrl as $uas',
    scope: {}, // needs an isolated scope
    link: function (scope, el, attrs, ctrls) {
      var ctrl = ctrls[0];

      ctrl.setTask(ctrls[1]);
      ctrl.setModelExternal(ctrls[2]);
      ctrl.setModelInternal(ctrls[3]);

      scope.$watch(attrs.uasOptions, function (nv, pv) {
        ctrl.setOptions(nv);
      });
    }
  };
}
// Dependency Injection
uasModelInternalDirective.$inject = ["$parse"];
uasModelExternalDirective.$inject = ["$parse"];
UasCtrl.$inject = ["$scope","$clientExternalUsers","$clientSwitcher"];
userAssignSelectorDirective.$inject = ["$parse"];
