


'use strict';

export const ClientDivisionFactory  = function ($resource, appConfig) {
    return $resource(appConfig.apiUrl + '/api/client-divisions/:id/:controller', {
      id: '@_id'
    },
    {
      detail: {
        method: 'GET',
        params: {
          controller: 'detail'
        }
      },
      create: {
        method: 'POST',
        params: {
          controller: 'create'
        }
      },
      update: {
        method: 'POST',
        params: {
          controller: 'update'
        }
      },
    });
  } 
 

// Dependency Injection
ClientDivisionFactory.$inject = ['$resource', 'appConfig'];
