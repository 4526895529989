
'use strict';

export const $clientDailyWoReportsService  = function (ClientDailyWoReports) {
    return {
      getWoDailyReports: function(client, filter) {
        return ClientDailyWoReports.getWoDailyReports({
          id: client._id || client,
          limit: filter.limit,
          page: filter.page
        }).$promise;
      }
    };
  }
// Dependency Injection


// Dependency Injection
$clientDailyWoReportsService.$inject = ['ClientDailyWoReports'];
