
'use strict';

export const $performForwardTaskEmailService  = function ($clientTask, Modal) {
  return function(cb) {
    cb = angular.isFunction(cb) ? cb : angular.noop;
    return Modal.taskActions.forwardTaskEmail(cb);
  };
}
 

// Dependency Injection
$performForwardTaskEmailService.$inject = ['$clientTask', 'Modal'];
