
'use strict';

export const priorityHumanFilter  = function () {
  return function (v) {
    switch(v) {
      case 'high':     return 'High';
      case 'med-high': return 'Medium High';
      case 'med-low':  return 'Medium Low';
      case 'low':      return 'Low';
    }
  };
}
 
