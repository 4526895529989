
'use strict';

export const $autoAssignedService  = function (AutoAssigned) {
        return {
            index: function (client, filter, paginationData) {
                return AutoAssigned.index({
                    id: client._id || client,
                    searchStr: filter.searchStr || '',
                    startDate: filter.startDate || '',
                    endDate: filter.endDate || '',
                    serviceType: filter.serviceType || null,
                    deldateFilter: filter.deldateFilter,
                    page: paginationData && paginationData.page,
                    pageSize: paginationData && paginationData.pageSize
                }).$promise;
            },
            getAssignedCount: function (client, filter) {
                return AutoAssigned.getAssignedCount({
                    id: client._id || client,
                    searchStr: filter.searchStr || '',
                    startDate: filter.startDate || '',
                    endDate: filter.endDate || '',
                    serviceType: filter.serviceType || null,
                    deldateFilter: filter.deldateFilter
                }).$promise;
            },
            getTaskCOIEvents: function(client, coi) {
                return AutoAssigned.getTaskCOIEvents({
                    id: client._id || client,
                    coi
                }).$promise;
            }
        };
    }
// Dependency Injection


// Dependency Injection
$autoAssignedService.$inject = ['AutoAssigned'];
