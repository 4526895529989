


'use strict';

export const serviceCommentsFactory  = function ($resource, appConfig) {
        return $resource(appConfig.apiUrl + '/api/service-comments/:id/:controller', {
            id: '@_id'
        },
            {   
                getList: {
                    method: 'GET',
                    // params: {
                    //     controller: ''
                    // }
                },
                create: {
                    method: 'POST',
                    // params: {
                    //     controller: 'create'
                    // }
                },
               
            });
    } 
 

// Dependency Injection
serviceCommentsFactory.$inject = ['$resource', 'appConfig'];
