
'use strict';

export const $clientSurveyQuestionService  = function (ClientSurveyQuestion) {
    return {
      getQuestions: function(client) {
        return ClientSurveyQuestion.getQuestions({
          id: client._id || client
        }).$promise;
      },
      getQuestion: function(client, question) {
        return ClientSurveyQuestion.getQuestion({
          id: client._id || client,
          question: question._id || question
        }).$promise;
      },
      createQuestion: function(client, data) {
        return ClientSurveyQuestion.createQuestion({
          _id: client._id||client,
          question: data
        }).$promise;
      },
      updateQuestion: function(client, data) {
        return ClientSurveyQuestion.updateQuestion({
          _id: client._id||client,
          question: data
        }).$promise;
      },
      deleteQuestion: function(client, question) {
        return ClientSurveyQuestion.deleteQuestion({
          _id: client._id||client,
          question: question._id || question
        }).$promise;
      }
    };
  }
// Dependency Injection


// Dependency Injection
$clientSurveyQuestionService.$inject = ['ClientSurveyQuestion'];
