'use strict';

export const inArrayFilter = function () {
    return function (items, letter) {
      if (letter) {
        var filtered = [];
        var addOffice;
        for (var i = 0; i < items.length; i++) {
          if (items[i].name.toLowerCase().includes(letter.toLowerCase())) filtered.push(items[i]);
          if (items[i].internalId && items[i].internalId.toLowerCase().includes(letter.toLowerCase())) filtered.push(items[i]);
          if (items[i].city && items[i].city.toLowerCase().includes(letter.toLowerCase())) filtered.push(items[i]);
          if (items[i].state && items[i].state.toLowerCase().includes(letter.toLowerCase())) filtered.push(items[i]);
          if (items[i].contactNumbers && items[i].contactNumbers.length > 0) {
            addOffice = false;
            for (var j = 0; j < items[i].contactNumbers.length; j++) {
              if (items[i].contactNumbers[j].number.includes(letter)) addOffice = true;
            }
            if (addOffice) filtered.push(items[i]);
          }
        }
        return _.uniq(filtered, 'internalId');
      } else {
        return items;
      }
    };
  }
