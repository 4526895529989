
'use strict';

export const $WOForecastingService  = function (WOForecasting) {
        return {
            index: function (client, filter) {
                return WOForecasting.index({
                    id: client._id || client,
                    filter
                }).$promise;
            },
            getScheduled: function (client, filter) {
                return WOForecasting.getScheduled({
                    id: client._id || client,
                    filter
                }).$promise;
            },
            create: function (client, data) {
                return WOForecasting.create({
                    id: client._id || client,
                    data
                }).$promise;
            },
            createMultiple: function (client, data) {
                return WOForecasting.createMultiple({
                    id: client._id || client,
                    data
                }).$promise;
            },
            updateCount: function(client, data) {
                return WOForecasting.updateCount({
                    id: client._id || client,
                    data
                }).$promise;
            },
            remove: function (client, data) {
                return WOForecasting.remove({
                    id: client._id || client,
                    controller: data._id,
                    date: data.date,
                    isBulkWOForecast: data.isBulkWOForecast,
                    bulkWOForecastingId: data.bulkWOForecastingId
                }).$promise;
            },
            createBulkWOForecast: function (client, data) {
                return WOForecasting.createBulkWOForecast({
                    id: client._id || client,
                    data
                }).$promise;
            },
            getBulkWOForecast: function (client, filter) {
                return WOForecasting.getBulkWOForecast({
                    id: client._id || client,
                    filter
                }).$promise;
            }
        };
    }
// Dependency Injection


// Dependency Injection
$WOForecastingService.$inject = ['WOForecasting'];
