'use strict';

export const NotificationLogsConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard.notificationLogs', {
        url: '/notification-logs',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/notification-logs/notification-logs.html', '175cd0a'); }],
        controller: 'DashboardNotificationLogsCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/notification-logs/notification-logs.controller.js', 'DashboardNotificationLogsCtrl'); }]
                      },
        data: {
          permission: 'NOTIFICATION_LOG_READ'
        }
      });
  }
// Dependency Injection
NotificationLogsConfig.$inject = ["$stateProvider"];
