


'use strict';

export const formErrorsDirective  = function ($parse) {
  return {
    restrict: 'A',
    link: function (scope, el, attr, ctrl) {
      var parseVal = angular.noop;
      attr.$observe('formErrors', function (wvar) { parseVal = $parse(wvar); });
      attr.$observe('feKeyForm',  function (key)  { ctrl.setFormKey(key); });
      attr.$observe('feKeyField', function (key)  { ctrl.setFieldKey(key); });
      scope.$watch(function () { return parseVal(scope); }, function (nv) { ctrl.updateObject(nv); });
    },
    controllerAs: '$formErrors',
    controller: [function () {
      var
      obj = null,
      keyForm = 'formErrors',
      keyField = 'fieldErrors';

      Object.defineProperties(this, {
        formErrors: {  get: function () { if(!obj) return null; return obj[keyForm]; } },
        fieldErrors: { get: function () { if(!obj) return null; return obj[keyField]; } }
      });

      this.updateObject = function (o) { obj = o; };
      this.setFormKey   = function (key) { keyForm = key; };
      this.setFieldKey  = function (key) { keyField = key; };
      this.hasFieldError = function(key) {
        var fieldErrs = this.fieldErrors;
        return (angular.isObject(fieldErrs) && fieldErrs.hasOwnProperty(key));
      };
      this.clearAllFormErrors = function () {
        if(!angular.isObject(obj) || !obj.hasOwnProperty(keyForm)) return;
        delete obj[keyForm];
      };
      this.clearAllFieldErrors = function () {
        if(!angular.isObject(obj) || !obj.hasOwnProperty(keyField)) return;
        delete obj[keyField];
      };
      this.clearFieldError = function (key) {
        var fieldErrs = this.fieldErrors;
        if(key && fieldErrs) delete fieldErrs[key];
      };
    }]
  };
}
 

// Dependency Injection
formErrorsDirective.$inject = ['$parse'];
