'use strict';

export const EmailConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard.notificationLogs.email', {
        url: '/email',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/notification-logs/email/email.html', '132154a'); }],
        controller: 'DashboardEmailNotificationLogsCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/notification-logs/email/email.controller.js', 'DashboardEmailNotificationLogsCtrl'); }]
                      },
      });
  }
// Dependency Injection
EmailConfig.$inject = ["$stateProvider"];
