


'use strict';

export const ClientCOINotesFactory  = function ($resource, appConfig) {
    return $resource(appConfig.apiUrl + '/api/coi-notes/:id/:controller', {
      id: '@_id'
    },
      {
        getCOINotes: {
          method: 'GET',
          params: {
            id: '@id'
          }
        },
        create: {
          method: 'POST',
          params: {
            id: '@id',
            controller: 'create'
          }
        },
        update: {
          method: 'POST',
          params: {
            controller: 'update'
          }
        },
        delete: {
          method: 'POST',
          params: {
            controller: 'delete'
          }
        },
      });
  } 
 

// Dependency Injection
ClientCOINotesFactory.$inject = ['$resource', 'appConfig'];
