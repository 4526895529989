


'use strict';

export const OtcAnyWhereFactory  = function ($resource, appConfig) {
        return $resource(appConfig.apiUrl + '/api/otc-any-where/:id/:controller', {
            id: '@_id'
        },
            {
                getAllLock: {
                    method: 'GET',
                    params: {
                        id: '@id',
                        controller: 'getAllLock'
                    }
                },
                getLockStatus: {
                    method: 'GET',
                    params: {
                        id: '@id',
                        controller: 'getLockStatus'
                    }
                },
                openLock: {
                    method: 'GET',
                    params: {
                        id: '@id',
                        controller: 'openLock'
                    }
                },
                closeLock: {
                    method: 'GET',
                    params: {
                        id: '@id',
                        controller: 'closeLock'
                    }
                }
            })
    } 
 

// Dependency Injection
OtcAnyWhereFactory.$inject = ['$resource', 'appConfig'];
