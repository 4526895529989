
'use strict';

export const $userService  = function (User) {
    return {
      get: function(clientId) {
        return User.get({client: clientId});
      },
      timeRemaining: function() {
        return User.timeRemaining().$promise;
      },
      changePassword: function(oldPassword, newPassword) {
        return User.changePassword({}, {
          oldPassword: oldPassword,
          newPassword: newPassword
        }).$promise;
      },
      extendSession: function () {
        return User.extendSession().$promise;
      },
      getNearByUsers: function(client, lat, long, distance, task) {
        return User.getNearByUsers({
          clientId: client._id || client,
          latitude: lat,
          longitude: long,
          distance: distance,
          location: task.location._id,
          bucket: task.bucket.id
        }).$promise;
      },
      toggleAccess: function(userId, client) {
        return User.toggleAccess({
          user: userId,
          client: client._id || client
        }).$promise;
      },
      disableUser: function(email, client) {
        return User.disableUser({
          email,
          client: client._id || client
        }).$promise;
      },
      sendMailToRole: function( data) {
        return User.sendMailToRole({
          data
        }).$promise;
      },
      getUserDevices: function(client, userId) {
        return User.getUserDevices({
          clientId: client._id || client,
          userId
        }).$promise;
      },
      deleteUserDevices: function(client, userId) {
        return User.deleteUserDevices({
          clientId: client._id || client,
          userId
        }).$promise;
      }
    };
  }
// Dependency Injection


// Dependency Injection
$userService.$inject = ['User'];
