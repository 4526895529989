
'use strict';

export const $clientCOINotesService  = function (ClientCOINotes) {
    return {
      getCOINotes: function (client, coi) {
        return ClientCOINotes.getCOINotes({
          id: client._id || client,
          controller: coi
        }).$promise;
      },
      create: function (client, data) {
        return ClientCOINotes.create({
          id: client._id || client,
          data: data
        }).$promise;
      },
      update: function (client, data) {
        return ClientCOINotes.update({
          _id: client._id || client,
          data
        }).$promise;
      },
      delete: function (client, note) {
        return ClientCOINotes.delete({
          _id: client._id || client,
          noteId: note._id
        }).$promise;
      },
    };
  }
// Dependency Injection


// Dependency Injection
$clientCOINotesService.$inject = ['ClientCOINotes'];
