


'use strict';

export const ClientRequestInterpreterFactory  = function ($resource, appConfig) {
    return $resource(appConfig.apiUrl + '/api/request-interpreter/:id/:controller', {
      id: '@_id'
    },
      {
        create: {
          method: 'POST',
          params: {
            controller: 'create'
          }
        },
        update: {
          method: 'POST',
          params: {
            controller: 'update'
          }
        },
        cancel: {
          method: 'DELETE',
          params: {
            id: '@id',
            controller: 'cancel'
          }
        },
        requestInterpreter: {
          method: 'POST',
          params: {
              id: '@id',
              controller: 'request-interpreter'
          }
        }
      });
  } 
 

// Dependency Injection
ClientRequestInterpreterFactory.$inject = ['$resource', 'appConfig'];
