
'use strict';

export const $clientFeatureService  = function (ClientFeature) {
    return {
      listAll: function(client) {
        return ClientFeature.query({ client: client._id || client }).$promise;
      }
    };
  }
// Dependency Injection


// Dependency Injection
$clientFeatureService.$inject = ['ClientFeature'];
