'use strict';

export const ProgramConfig = function ($stateProvider) {
        $stateProvider
            .state('app.account.dashboard.settings.program', {
                url: '/program',
                templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/program/program.html', '2901a84a'); }],
                controller: 'DashboardSettingsProgramCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/program/program.controller.js', 'DashboardSettingsProgramCtrl'); }]
                      },
                data: {
                    ability: 'manage.client.users'
                }
            })
            .state('app.account.dashboard.settings.program.index', {
                url: '/index',
                templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/program/index/index.html', 'c145d6ca'); }],
                controller: 'DashboardSettingsProgramIndexCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/program/index/index.controller.js', 'DashboardSettingsProgramIndexCtrl'); }]
                      },
                data: {
                    ability: 'manage.client.users'
                }
            })
            .state('app.account.dashboard.settings.program.create', {
                url: '/create',
                templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/program/create/create.html', 'bb2db12a'); }],
                controller: 'DashboardSettingsProgramCreateCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/program/create/create.controller.js', 'DashboardSettingsProgramCreateCtrl'); }]
                      },
                data: {
                    ability: 'manage.client.users'
                }
            })
            .state('app.account.dashboard.settings.program.update', {
                url: '/update/:id',
                templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/program/update/update.html', '81e8518a'); }],
                controller: 'DashboardSettingsProgramUpdateCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/program/update/update.controller.js', 'DashboardSettingsProgramUpdateCtrl'); }],
                          '$selectedProgram': ['$stateParams', '$programs', '$clientSwitcher', function ($stateParams, $programs, $clientSwitcher) {
                              return $programs.getProgramById($clientSwitcher.getCurrentClient(), $stateParams.id);
                          }]
                      },
                data: {
                    ability: 'manage.client.users'
                },
            });
    }
// Dependency Injection
ProgramConfig.$inject = ["$stateProvider"];
