
'use strict';

export const $interpreterScheduledNTService  = function (InterpreterScheduledNT) {
        return {
            index: function (client, filter, paginationData) {
                return InterpreterScheduledNT.index({
                    id: client._id || client,
                    startDate: filter.startDate || '',
                    endDate: filter.endDate || '',
                    page: paginationData && paginationData.page,
                    pageSize: paginationData && paginationData.pageSize
                }).$promise;
            }
        };
    }
// Dependency Injection


// Dependency Injection
$interpreterScheduledNTService.$inject = ['InterpreterScheduledNT'];
