
'use strict';

export const $clientSystemLogsService  = function ($http, ClientSystemLogs) {
  return {
    getLogs: function (client, search) {
      return ClientSystemLogs.getLogs({
          id: (client._id||client),
          search: search
        }).$promise;
    },
    getDistinctValues: function (client) {
      return ClientSystemLogs.getDistinctValues({
        id: (client._id||client)
      }).$promise;
    }
  };
}
// Dependency Injection


// Dependency Injection
$clientSystemLogsService.$inject = ['$http', 'ClientSystemLogs'];
