
'use strict';

export const fileDropzoneDirective  = function () {
  return {
    restrict: 'A',
    scope: {
      file: '=',
      fileName: '=',
      onFileDrop: '='
    },
    link: function(scope, element, attrs) {
      var checkSize, isTypeValid, processDragOverOrEnter, validMimeTypes, processDragOut;
      processDragOut = function(event){
        if(event.currentTarget.className.indexOf('dropzone')){
          event.currentTarget.style.background = '';
        }
      };
      processDragOverOrEnter = function (event) {
          if (event != null) {
              event.preventDefault();
          }
          (event.originalEvent || event).dataTransfer.effectAllowed = 'move';
          if(event.currentTarget.className.indexOf('dropzone')){
            event.currentTarget.style.background = '#e6e6e6';
          }
          return false;
      };
      validMimeTypes = attrs.fileDropzone;
      checkSize = function(size) {
        var _ref;
        if (((_ref = attrs.maxFileSize) === (void 0) || _ref === '') || (size / 1024) / 1024 < attrs.maxFileSize) {
          return true;
        } else {
          alert("File must be smaller than " + attrs.maxFileSize + " MB");
          return false;
        }
      };
      isTypeValid = function(type) {
        if ((validMimeTypes === (void 0) || validMimeTypes === '') || validMimeTypes.indexOf(type) > -1) {
          return true;
        } else {
          alert("Invalid file type.  File must be one of following types " + validMimeTypes);
          return false;
        }
      };

      element.bind('dragover', processDragOverOrEnter);
      element.bind('dragenter', processDragOverOrEnter);
      element.bind('dragleave', processDragOut);
      return element.bind('drop', function(event) {
        var files, name, reader, size, type;
        if (event != null) {
          event.preventDefault();
        }
        reader = new FileReader();
        reader.onload = function(evt) {
          if (checkSize(size) && isTypeValid(type)) {
            return scope.$apply(function() {
              scope.file = evt.target.result;
              if (angular.isString(scope.fileName)) {
                return scope.fileName = name;
              }
            });
          }
        };
        var dataTransfer = event.dataTransfer || event.originalEvent.dataTransfer;
        files = dataTransfer.files;
        // name = file.name;
        // type = file.type;
        // size = file.size;
        // reader.readAsDataURL(file);
        scope.onFileDrop(scope.$parent.task, files);
        // console.log('file dropped on task', scope.$parent.task ,name, type, size);
        event.currentTarget.style.background = '';
        return false;
      });
    }
  };
}
 
