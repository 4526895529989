'use strict';

export const DashboardSettingsManagersCtrl = function ($scope, $http) {
    $scope.managerTabs = [{
      title: function(){
        return 'Create ' + $scope.clientStrings().managerPCase;
      },
      state: 'app.account.dashboard.settings.managers.create',
      ability: 'manage.client.managers'
    }];

    $scope.isSettingsManagerHome = function() {
      return $scope.stateEquals('app.account.dashboard.settings.managers')
          || $scope.stateEquals('app.account.dashboard.settings.managers.index');
    };
  }
// Dependency Injection
DashboardSettingsManagersCtrl.$inject = ["$scope","$http"];
