'use strict';


export const formInventoryDirective = function ($clientSwitcher, $activeClient, $clientOffice, $q) {
        return {
            restrict: 'E',
            templateUrl: 'components/forms/inventory.html',
            scope: {
                inventory: '=ngModel',
                onSubmit: '=formOnSubmit',
                onReset: '=formOnReset',
                uploader: '='
            },
            link: function (scope, el, attrs) {

                var currentClient;
                scope.file = {
                    changed: false,
                    croppedImage: '',
                    uploadedImage: ''
                };
                scope.clientStrings = function () { return $clientSwitcher.getFeatureOptions('client.strings'); };
                var loadOffices = function (client) {
                    return $clientOffice.listAll(client)
                        .then(function (offices) {
                            scope.offices = offices
                                .map(function (office) {
                                    return {
                                        value: office._id,
                                        label: office.name
                                    };
                                });
                        });
                };
                var init = function () {
                    scope.isLoading = true;

                    $activeClient(function (client) {
                        currentClient = client;
                        scope.clients = $clientSwitcher.getLoaded();
                        scope.currentClientId = client._id;
                        $q.all([
                            loadOffices(client),
                        ])
                            .then(function () {

                            })
                            .finally(function () {
                                scope.isLoading = false;
                            });
                    });
                };
                init()

                scope.getLogoUrl = function () {
                    if (scope.inventory && scope.inventory.picture) {
                        return scope.inventory.picture;
                    } else {
                        return '';
                    }
                };

                var handleFileSelect = function (file) {
                    var reader = new FileReader();
                    reader.onload = function (evt) {
                        scope.$apply(function ($scope) {
                            scope.file.uploadedImage = evt.target.result;
                        });
                    };
                    reader.readAsDataURL(file);
                };

                scope.fileNameChanged = function (ele) {
                    scope.file.changed = true;
                    var file = ele.files[0];
                    handleFileSelect(file);
                };
            }
        }
    }
// Dependency Injection
formInventoryDirective.$inject = ["$clientSwitcher","$activeClient","$clientOffice","$q"];
