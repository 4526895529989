'use strict';

export const ProjectsConfig = function ($stateProvider) {
        $stateProvider
            .state('app.account.dashboard.projects', {
                url: '/projects',
                params: {
                    status: null,
                },
                data: {
                    ability: 'client.serviceProjects',
                    permission: 'VIEW_SERVICE_PROJECTS'
                },
                templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/services/projects/projects.html', '8b49692a'); }],
                controller: 'ServiceProjectsCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/services/projects/projects.controller.js', 'ServiceProjectsCtrl'); }]
                      }
            })
            .state('app.account.dashboard.projectDetail', {
                url: '/projects/:id',
                data: {
                    ability: 'client.serviceProjects',
                    permission: 'VIEW_SERVICE_PROJECTS'
                },
                templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/services/projects/project-detail/project-detail.html', 'b3d535ca'); }],
                controller: 'ServiceProjectDetailCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/services/projects/project-detail/project-detail.controller.js', 'ServiceProjectDetailCtrl'); }]
                      }
            })
    }
// Dependency Injection
ProjectsConfig.$inject = ["$stateProvider"];
