import './interpreter-court-distances.scss';
'use strict';

export const InterpreterCourtDistancesConfig = function ($stateProvider) {
        $stateProvider
        .state('app.account.dashboard.settings.interpreter-court-distances', {
            url: '/interpreter-court-distances',
            templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/interpreter-court-distances/interpreter-court-distances.html', 'd1584cca'); }],
            controller: 'InterpreterCourtDistancesCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/interpreter-court-distances/interpreter-court-distances.controller.js', 'InterpreterCourtDistancesCtrl'); }]
                      },
            data: {
                permission: 'SETTING_INTERPRETER_COURT_DISTANCE'
              },
            //   resolve: {
            //     '$userPermissions': ['$auth', function($auth) {
            //       return $auth.getCurrentUser();
            //     }]
            //   }
          })
    }
// Dependency Injection
InterpreterCourtDistancesConfig.$inject = ["$stateProvider"];
