
'use strict';

export const $ServiceProjectCommentService  = function (ServiceProjectComment) {
        return {
            getProjectComments: function (client, projectId) {
                return ServiceProjectComment.getProjectComments({
                    id: client._id || client,
                    controller: projectId
                }).$promise;
            },

            create: function (client, data) {
                return ServiceProjectComment.create({
                    _id: client._id || client,
                    data
                }).$promise;
            },
        };
    }
// Dependency Injection


// Dependency Injection
$ServiceProjectCommentService.$inject = ['ServiceProjectComment'];
