'use strict';

export const TaskOrgPerformanceConfig = function ($stateProvider) {
    $stateProvider
      .state('app.print.taskOrgPerformance', {
        url: '/taskOrgPerformance?empId',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./print/task-org-performance/task-org-performance.html', '8274684a'); }],
        controller: 'printTaskOrgPerformanceCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) {
                            return LazyLoadService.loadChartLibraries().then(function() {
                              return LazyLoadService.loadController('./print/task-org-performance/task-org-performance.controller.js', 'printTaskOrgPerformanceCtrl');
                            })
                          }]
                      }
      });
  }
// Dependency Injection
TaskOrgPerformanceConfig.$inject = ["$stateProvider"];
