
'use strict';

export const $clientBucketService  = function (ClientBucket) {
    return {
      listAll: function(client, includeOffices) {
        return ClientBucket.query({
          id: client._id || client
        }).$promise;
      },

      detail: function(clientId, bucketId) {
        return ClientBucket.detail({
          id: clientId._id||clientId,
          bucketId: bucketId
        }).$promise;
      },

      remove: function(clientId, bucketId) {
        return ClientBucket.remove({
          id: clientId._id||clientId,
          controller: bucketId
        }).$promise;
      }
    };
  }
// Dependency Injection


// Dependency Injection
$clientBucketService.$inject = ['ClientBucket'];
