
'use strict';

export const $assessmentYearsService  = function () {
        let currentYear = parseInt(moment().format('YYYY'));
        let prevFiveYear = currentYear - 5;
        let years = [];
        for (let i = prevFiveYear; i <= (currentYear + 1); i++) {
            years.push({
                label: (i).toString(),
                value: i
            })
        }
        return years;
    }
// Dependency Injection

