
'use strict';

export const $stateFilterService  = function () {
  return function (currentState) {
      let AllFields = [
          { label: 'FIN', value: 'checkedIn' },
          { label: 'JIN', value: 'judgeIn' },
          { label: 'JOUT', value: 'judgeOut' },
          { label: 'LOUT', value: 'lunchOut' },
          { label: 'LIN', value: 'lunchIn' },
          { label: 'ROUT', value: 'release' },
          { label: 'FOUT', value: 'checkedOut' },
          { label: 'LATE', value: 'late' },
      ];
      let selectedType = null;
      let fields;
      switch (currentState) {
          case 'Confirmed':
          case 'Pending':
          case 'Not Arrived':
              fields = [];
              break;
          case 'Late':
              selectedType = 'late';
              fields = ['LATE'];
              break;
          case 'In Facility':
              selectedType = 'checkedIn';
              fields = ['FIN', 'JIN', 'JOUT'];
              break;
          case 'With Judge':
              selectedType = 'judgeIn';
              fields = ['FIN', 'JIN', 'JOUT', 'LOUT', 'LIN'];
              break;
          case 'At Lunch':
              selectedType = 'lunchOut';
              fields = ['FIN', 'JIN', 'JOUT', 'LOUT', 'LIN'];
              break;
          case 'Standby':
              selectedType = 'judgeOut';
              fields = ['FIN', 'JIN', 'JOUT', 'LOUT', 'LIN'];
              break;
          case 'Extended':
              selectedType = 'judgeOut';
              fields = ['FIN', 'JIN', 'JOUT'];
              break;
          case 'Released':
              selectedType = 'release'
              fields = ['FIN', 'JIN', 'JOUT', 'ROUT'];
              break;
          case 'Completed':
          case 'Facility Out':
              selectedType = 'checkedOut'
              fields = ['FIN', 'JIN', 'JOUT', 'LOUT', 'LIN', 'ROUT', 'FOUT'];
              break;
          default:
              fields = [];
              break;
      };
      let field = AllFields.filter((obj) => {
          if (fields.includes(obj.label)) return obj
      });
      return [field, selectedType];
  }
}

