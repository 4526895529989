import './service-panel.scss';
'use strict';

export const ServicePanelConfig = function ($stateProvider) {
        $stateProvider
            .state('app.account.services.servicePanel', {
                url: '/service-panel',
                data: {
                    roles: []
                },
                templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/services/service-panel/service-panel.html', 'f763564a'); }],
                controller: 'ServicePanelCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/services/service-panel/service-panel.controller.js', 'ServicePanelCtrl'); }]
                      }
            })
            .state('app.account.services.servicePanel.index', {
                url: '',
                data: {
                    roles: []
                },
                templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/services/service-panel/index/index.html', '4659cd45'); }],
                controller: 'ServicePanelIndexCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/services/service-panel/index/index.controller.js', 'ServicePanelIndexCtrl'); }]
                      }
            })
            .state('app.account.services.servicePanel.onSiteInterpretation', {
                url: '/on-site',
                data: {
                    roles: []
                },
                templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/services/service-panel/on-site-interpretation/on-site-interpretation.html', '4179a7aa'); }],
                controller: 'OnSiteInterpretationCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/services/service-panel/on-site-interpretation/on-site-interpretation.controller.js', 'OnSiteInterpretationCtrl'); }]
                      }
            })
            .state('app.account.services.servicePanel.telephonicInterpretation', {
                url: '/telephonic-interpretation',
                data: {
                    roles: []
                },
                templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/services/service-panel/telephonic-interpretation/telephonic-interpretation.html', 'd7c5b10a'); }],
                controller: 'TelephonicInterpretationCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/services/service-panel/telephonic-interpretation/telephonic-interpretation.controller.js', 'TelephonicInterpretationCtrl'); }]
                      }
            })
            .state('app.account.services.servicePanel.transcription', {
                url: '/transcription',
                data: {
                    roles: []
                },
                templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/services/service-panel/transcription/transcription.html', '4344b00a'); }],
                controller: 'TranscriptionCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/services/service-panel/transcription/transcription.controller.js', 'TranscriptionCtrl'); }]
                      }
            })
            .state('app.account.services.servicePanel.translation', {
                url: '/translation',
                data: {
                    roles: []
                },
                templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/services/service-panel/translation/translation.html', 'e0aa4c4a'); }],
                controller: 'TranslationCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/services/service-panel/translation/translation.controller.js', 'TranslationCtrl'); }]
                      }
            })
            .state('app.account.services.servicePanel.localization', {
                url: '/localization',
                data: {
                    roles: []
                },
                templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/services/service-panel/localization/localization.html', '86cbfbca'); }],
                controller: 'LocalizationCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/services/service-panel/localization/localization.controller.js', 'LocalizationCtrl'); }]
                      }
            })
            .state('app.account.services.servicePanel.captioning', {
                url: '/captioning',
                data: {
                    roles: []
                },
                templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/services/service-panel/captioning/captioning.html', '2edcfdaa'); }],
                controller: 'CaptioningCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/services/service-panel/captioning/captioning.controller.js', 'CaptioningCtrl'); }]
                      }
            })
            .state('app.account.services.servicePanel.serviceDetail', {
                url: '/service/:id',
                templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/services/service-panel/service-detail/service-detail.html', '6c86d54a'); }],
                controller: 'ServiceDetailCtrl',
                resolve: {
                    lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/services/service-panel/service-detail/service-detail.controller.js', 'ServiceDetailCtrl'); }]
                //     '$selectedService': ['$stateParams', '$servicePanel', '$clientSwitcher', function ($stateParams, $servicePanel, $clientSwitcher) {
                //         return $servicePanel.getServiceById($clientSwitcher.getCurrentClient(), $stateParams.id);
                //     }]
                }
            })
            .state('app.account.services.servicePanel.serviceInvoice', {
                url: '/invoice/:id',
                templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/services/service-panel/service-invoice/service-invoice.html', '6c45fbca'); }],
                controller: 'ServiceInvoiceCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/services/service-panel/service-invoice/service-invoice.controller.js', 'ServiceInvoiceCtrl'); }]
                      },
            })
    }
// Dependency Injection
ServicePanelConfig.$inject = ["$stateProvider"];
