'use strict';


export const formPermissionsDirective = function (Modal, $rootScope, $q, $auth, $clientSwitcher, $clientPermissionTypes) {
    return {
      restrict: 'E',
      templateUrl: 'components/forms/permissions.html',
      scope: {
        permissions: '=ngModel',
        onSubmit: '=formOnSubmit',
        onReset: '=formOnReset'
      },
      link: function(scope, el, attrs) {
        scope.clientSettings = function(){ return $clientSwitcher.getFeatureOptions('client.settings'); };
        scope.types = $clientPermissionTypes;
      }
    };
  }
// Dependency Injection
formPermissionsDirective.$inject = ["Modal","$rootScope","$q","$auth","$clientSwitcher","$clientPermissionTypes"];
