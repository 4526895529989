'use strict';

export const interpreterResourceDirective = function ($rootScope, $stateParams, ngToast, $timeout, LazyLoadService) {
        return {
            restrict: 'E',
            templateUrl: 'app/account/dashboard/main-dashboard/interpreter-resource/interpreter-resource.html',
            scope: {
                modelData: '=ngModel',
                childTabs: '=childTabs',
                layoutDefaultTabing: '&layoutDefaultTab'
            },
            link: function(scope) {

  const lazyDirectives = [{"name":"openWorkOrdersDirective","path":import("@app/account/dashboard/main-dashboard/interpreter-resource/tabs/open-work-orders/open-work-orders.directive.js")},{"name":"interpreterHomeDirective","path":import("@app/account/dashboard/main-dashboard/interpreter-resource/tabs/interpreter-home/interpreter-home.directive.js")},{"name":"knowledgeLibraryDirective","path":import("@app/account/dashboard/main-dashboard/interpreter-resource/tabs/knowledge-library/knowledge-library.directive.js")},{"name":"profileDirective","path":import("@app/account/dashboard/main-dashboard/interpreter-resource/tabs/profile/profile.directive.js")},{"name":"myResourcesDirective","path":import("@app/account/dashboard/main-dashboard/interpreter-resource/tabs/my-resources/my-resources.directive.js")}];
  scope.lazyDirectivesLoaded = false;
  Promise.all(lazyDirectives.map(directive=>LazyLoadService.loadDirective(directive.path, directive.name))).then(() => {
          $timeout(function() {
            scope.lazyDirectivesLoaded = true;

                         let selectedTab = null;
                scope.IcTabs = [];
                var init = function () {
                    scope.tabs()
                }

                scope.tabSelected = function (tabName) {
                    selectedTab = tabName;
                    $rootScope.$broadcast('onTabChange', selectedTab);
                };

                scope.isTabSelected = function (tabName) {
                    return selectedTab === tabName;
                };

                scope.tabs = function () {
                    let tabs = scope.childTabs;
                    let data = scope.layoutDefaultTabing({obj:{tabs, layoutOf: 'interpreterResource'}})
                    scope.IcTabs = data.avtiveTabs;
                    selectedTab = data.selectedTab;

                    if ($stateParams.work_orders) {
                        if (scope.modelData.userPermissions && scope.modelData.userPermissions.includes('INTERPRETER_RESOURCES_MY_WORK_ORDER_TAB')) {
                          selectedTab = 'work_history';
                        } else {
                          ngToast.create({
                            content: 'Permission Denied!',
                            className: 'danger'
                          });
                        }
                    }
                    if ($stateParams.child) {
                        selectedTab = $stateParams.child;
                    }
                }

                init()

    })
});
}
}
}
//Dependency Injection
interpreterResourceDirective.$inject = ["$rootScope","$stateParams","ngToast", "$timeout", "LazyLoadService"];
