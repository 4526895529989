'use strict';

export const ClientsConfig = function ($stateProvider) {
    $stateProvider
      .state('app.admin.clients', {
        url: '/clients',
        data: {
          roles: ['admin']
        },
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./admin/clients/clients.html', 'ac03f245'); }],
        controller: 'AdminClientsCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./admin/clients/clients.controller.js', 'AdminClientsCtrl'); }]
                      }
      });
  }
// Dependency Injection
ClientsConfig.$inject = ["$stateProvider"];
