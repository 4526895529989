
'use strict';

export const $downloadAttachmentService  = function ($attachment, ngToast) {
        return {
            download: function (clientId, uploadId) {
                return $attachment.download(clientId, uploadId)
                .then((res) => {
                    if (res && res.status && res.url) {
                        window.open(res.url, '_blank');
                    } else {
                        ngToast.create({
                            content: res.message || 'Download Fail.',
                            className: 'danger'
                        }); 
                    }
                })
                .catch((err) => {
                    ngToast.create({
                        content: err.message || 'Download Fail.',
                        className: 'danger'
                    });
                });
            }
        };
    }
// Dependency Injection


// Dependency Injection
$downloadAttachmentService.$inject = ['$attachment', 'ngToast'];
