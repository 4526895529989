import './create-rule.scss';


'use strict';

export const onFinishRenderDirective  = function (){
  return {
         restrict: 'A',
         link: function(scope, element, attrs) { 
             if (scope.$last === true) {
                scope.$emit(attrs.onFinishRender);
             }
         }
     };
  } 
 
