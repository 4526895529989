'use strict';

export const TimeReportingConfig = function ($stateProvider) {
    $stateProvider
      .state('app.print.timeReporting', {
        url: '/timeReorting?type',
        params: {
          type: null
        },
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./print/time-reporting/time-reporting.html', 'cce5de6a'); }],
        controller: 'TimeReportingCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./print/time-reporting/time-reporting.controller.js', 'TimeReportingCtrl'); }]
                      }
      });
  }
// Dependency Injection
TimeReportingConfig.$inject = ["$stateProvider"];
