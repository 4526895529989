'use strict';

export const FulfillmentOpsDashboardConfig = function ($stateProvider) {
    $stateProvider
      .state('app.print.fulfillmentDashboard', {
        url: '/fulfillment-operations/dashboard',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./print/fulfillment-operations/dashboard/fulfillment-ops-dashboard.html', '5a606384'); }],
        controller: 'PrintFulfillmentDashboardCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./print/fulfillment-operations/dashboard/fulfillment-ops-dashboard.controller.js', 'PrintFulfillmentDashboardCtrl'); }]
                      }
      });
  }
// Dependency Injection
FulfillmentOpsDashboardConfig.$inject = ["$stateProvider"];
