'use strict';

export const suppressionListConfig = function ($stateProvider) {

        $stateProvider
        .state('app.account.dashboard.settings.suppression-list', {
            url: '/suppression-list',
            templateUrl: 'app/account/dashboard/settings/suppression-list/suppression-list.html',
            controller: 'SuppressionListCtrl',
            data: {
                permission: 'SETTING_INTERPRETER_COURT_DISTANCE'
              },
            //   resolve: {
            //     '$userPermissions': ['$auth', function($auth) {
            //       return $auth.getCurrentUser();
            //     }]
            //   }
          })
    }



suppressionListConfig.$inject = ['$stateProvider']

