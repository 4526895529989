'use strict';


export const uploadFormDirective = function (FileUploader, $timeout, $clientInterpreterResources, $clientSwitcher, Modal) {
  return {
    restrict: 'E',
    require: 'ngModel',
    templateUrl: 'components/upload/upload.html',
    scope: {
      model: '=ngModel',
      uploader: '=',
      existingFiles: '=',
      isAttachmentDate: '=isAttachmentDate',
      isSingleUploader: '=',
      dateLabel: '=',
      validateFileOverwrite: '=',
      validateForFolder: '='
    },
    link: function (scope, el, attrs) {
      const client = $clientSwitcher.getCurrentClient();
      scope.uploadingFiles = false;
      scope.attachmentDateLabel = scope.dateLabel || 'Attachments Date';
      function initPicker() {
        if(scope.isAttachmentDate){
          $timeout(function () {
              $('#datePicker').daterangepicker({
                  singleDatePicker: true
              }, function (start, end, label) {
                  if (!start || !end) {
                      return;
                  }
  
                  $('#datePicker span').html(start.format('MM/DD/YYYY'));
                  scope.model.attachmentDate = start.format();
              });
  
              var text = moment().format('MM/DD/YYYY');
              scope.model.attachmentDate = moment().format();
              $('#datePicker span').html(text);
          }, 0);
        }
      }
      initPicker();

      scope.$watch('uploader', function (uploader) {
        if(!uploader) return;

        // console.info('uploader', uploader);

        // uploader.onWhenAddingFileFailed = function(item /*{File|FileLikeObject}*/, filter, options) {
        //   console.info('onWhenAddingFileFailed', item, filter, options);
        // };
        uploader.onAfterAddingFile = function (fileItem) {
          if (scope.validateFileOverwrite) {
            scope.validateForFolder.name = fileItem.file.name || '';
            $clientInterpreterResources.fileExist(client, scope.validateForFolder)
              .then((res) => {
                if (res && res.status && res.result && res.result.length > 0) {
                  // Open overwrite popup
                  Modal.confirm.showWithCancelCB("Confirm File Overwrite", "The file already exists in the selected folder. Do you want to overwrite it?", "Yes", "No", function (result) {
                    if (result) {
                      scope.model.isOverwrite = true;
                      scope.model.currentFileObjId = res.result[0]._id;
                    } else {
                      scope.model.isOverwrite = false;
                    }
                  });
                }
              })
              .catch((err) => {
                console.log(err)
              })
          }
        };
        // uploader.onAfterAddingAll = function(addedFileItems) {
        //   console.info('onAfterAddingAll', addedFileItems);
        // };
        // uploader.onBeforeUploadItem = function(item) {
        //   console.info('onBeforeUploadItem', item);
        // };
        // uploader.onProgressItem = function(fileItem, progress) {
        //   console.info('onProgressItem', fileItem, progress);
        // };
        // uploader.onProgressAll = function(progress) {
        //   console.info('onProgressAll', progress);
        // };
        // uploader.onSuccessItem = function(fileItem, response, status, headers) {
        //   console.info('onSuccessItem', fileItem, response, status, headers);
        // };
        // uploader.onErrorItem = function(fileItem, response, status, headers) {
        //     console.info('onErrorItem', fileItem, response, status, headers);
        // };
        // uploader.onCancelItem = function(fileItem, response, status, headers) {
        //   console.info('onCancelItem', fileItem, response, status, headers);
        // };
        // uploader.onCompleteItem = function(fileItem, response, status, headers) {
        //   console.info('onCompleteItem', fileItem, response, status, headers);
        // };
        // uploader.onCompleteAll = function() {
        //   console.info('onCompleteAll');
        // };
      });
    }
  };
}
// Dependency Injection
uploadFormDirective.$inject = ["FileUploader","$timeout","$clientInterpreterResources","$clientSwitcher","Modal"];
