'use strict';

export const PushConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard.notificationLogs.push', {
        url: '/push',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/notification-logs/push/push.html', 'a368ac2a'); }],
        controller: 'DashboardPushNotificationLogsCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/notification-logs/push/push.controller.js', 'DashboardPushNotificationLogsCtrl'); }]
                      },
      });
  }
// Dependency Injection
PushConfig.$inject = ["$stateProvider"];
