'use strict';

export const LiveReportsConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard.live-reports', {
        url: '/live-report',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/live-reports/live-reports.html', '5036c7aa'); }],
        controller: 'LiveReportsCtrl',
        resolve: {
            lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/live-reports/live-reports.controller.js', 'LiveReportsCtrl'); }],
            '$userPermissions': ['$clientUser', '$clientSwitcher', function($clientUser, $clientSwitcher) {
              return $clientUser.getUserPermissions($clientSwitcher.getCurrentClient());
            }]
        },
        data: {
          ability: 'client.live-reports',
          permission: 'LIVE_REPORTS_READ'
        },
      });
  }
// Dependency Injection
LiveReportsConfig.$inject = ["$stateProvider"];
