
'use strict';

export const $coordinatorsService  = function (Coordinators) {
    return {
        getCoordinatorList: function (client, paginationData, fields) {
            return Coordinators.getCoordinatorList({
                id: client._id || client,
                page: paginationData && paginationData.page,
                pageSize: paginationData && paginationData.pageSize,
                fields
            }).$promise;
        }
    };
}
// Dependency Injection


// Dependency Injection
$coordinatorsService.$inject = ['Coordinators'];
