'use strict';
export const interpreterAvailableWosDirective = function ($rootScope, $clientSwitcher, $auth, $activeClientSettings, $clientInterpreter, Modal, ngToast) {
        return {
            restrict: 'E',
            templateUrl: 'app/account/dashboard/main-dashboard/work-orders/tabs/interpreter-available-wos/interpreter-available-wos.html',
            scope: {
                nextDay: '=nextDay',
            },
            link: function (scope) {
                const currentUser = $auth.getCurrentUser();
                const currentCli = $clientSwitcher.getCurrentClient();
                scope.pageData = {
                    isLoading: false,
                    error: null,
                    dataList: [],
                    startDate: null,
                    endDate: null
                }
                scope.pagination = {
                    currentPage: 1,
                    page: 1,
                    total: 0,
                    pageSize: 20,
                    itemsPerPage: 20
                };

                scope.filter = {
                    searchStr: '',
                    // startDate: moment().startOf('month').format('YYYY-MM-DD'),
                    // endDate: moment().endOf('month').format('YYYY-MM-DD'),
                    selectedInterpreters: []
                }
                scope.lastLoadedFilter = {};
                scope.applyFilter = () => {
                    scope.pagination.page = 1;
                    scope.loadDataList()
                }

                scope.interpreterList = []
                scope.allInterpreterList= []

                scope.selectedLabl = function (count) {
                    return {
                        allLabel: `${count ? count : ''} Selected`
                    };
                };
                scope.loadDataList = function () {
                    if (scope.pageData.isLoading) return;
                    let filter = {
                        page: scope.pagination.page,
                        pageSize: scope.pagination.pageSize,
                        venderId: scope.filter.selectedInterpreters && scope.filter.selectedInterpreters.length && scope.filter.selectedInterpreters !== 'all' ? scope.filter.selectedInterpreters : undefined,
                        nextDay: scope.nextDay || false,
                        includeIcList: false
                      }
                    if(JSON.stringify(filter) === JSON.stringify(scope.lastLoadedFilter)) return;

                    scope.pageData.isLoading = true;
                    scope.pageData.error = null;
                    scope.pageData.datalist = [];
                    scope.pageData.message = null;

                    $clientInterpreter.interpreterAvailableWO(currentCli, filter)
                    .then((res) => {
                        if (res.status) {
                            scope.pageData.dataList = res.result;
                            scope.pagination.total = res.totalInterpreter;
                            if (filter.page == 1 && !filter.venderId) {
                                scope.pageData.startDate = res.startDate;
                                scope.pageData.endDate = res.endDate;
                            }

                        } else {
                            scope.pageData.message = res.message || 'No Data Found!'
                        }
                        scope.pageData.isLoading = false;
                        scope.lastLoadedFilter = filter;
                    })
                        .catch((err) => {
                            console.log(err)
                            scope.pageData.message = err.message || err;
                            scope.pageData.isLoading = false;
                        })
                        .finally(() => {})
                }


                scope.loadIcList = function() {
                  if(scope.icListLoaded) return;
                  scope.isIcListLoading = true;
                  let filter = {
                    page: scope.pagination.page,
                    pageSize: scope.pagination.pageSize,
                    venderId: scope.filter.selectedInterpreters && scope.filter.selectedInterpreters.length && scope.filter.selectedInterpreters !== 'all' ? scope.filter.selectedInterpreters : undefined,
                    nextDay: scope.nextDay || false,
                    includeIcListOnly: true
                  }


                  $clientInterpreter.interpreterAvailableWO(currentCli, filter)
                  .then((res) => {
                      if (res.status) {
                        scope.interpreterList = res.icList,
                        scope.allInterpreterList = res.icList;
                        scope.icListLoaded = true
                      }
                      scope.isIcListLoading = false;
                    })
                    .catch((err) => {
                      console.log(err)
                      scope.pageData.message = err.message || err;
                      scope.pageData.isLoading = false;
                  })
                }

                scope.filterIcSearch = function(searchKey, offset) {
                  console.log('filterIcSearch', scope.pageData.allInterpreterList, searchKey)
                    if (searchKey && searchKey.length) {
                        let str = searchKey.toLowerCase();
                        scope.interpreterList = scope.allInterpreterList.filter((el) => el.label.includes(str))
                    } else {
                        scope.interpreterList = scope.allInterpreterList
                    }
                }
                scope.loadActiveInterpreters = () => {

                }
                $activeClientSettings(function (client, settings) {
                    // scope.serviceTypeScheduled = settings.serviceTypeScheduled;
                })
                function init() {
                    scope.applyFilter();
                    scope.loadActiveInterpreters()
                }

                scope.showWO = function (wos) {
                    if (wos && wos.length) {
                        Modal.openWorkOrders.showListOfAvailableWO(wos, function (status) { })()
                    }
                }

                scope.sendReminderToUser = function(record) {
                    if ( record.sendPushReminder) return;
                    record.sendPushReminder = true;
                    $clientInterpreter.sendNextDayPushReminder(currentCli, {
                        vendorId:record.interpreterId,
                        totalWOAvl: record.total
                    })
                    .then(() => {
                        record.sendPushReminder = false;
                        ngToast.create({
                            content: 'Push notification sent successfully',
                            className: 'success'
                          });
                    })
                    .catch((error) => {
                        console.log(error);
                        record.sendPushReminder = false;
                        ngToast.create({
                            content: error.message || 'Something went wrong! try after sometime',
                            className: 'danger'
                          });
                    })
                }

                scope.sendNTToAllIC = function () {
                    if (scope.pageData.isLoadingSendAll) return;
                    scope.pageData.isLoadingSendAll = true;
                    scope.pageData.error = null;
                    scope.pageData.datalist = [];
                    scope.pageData.message = null;
                    let filter = {
                        toSendAll: true,
                        nextDay: true,
                        venderId: scope.filter.selectedInterpreters && scope.filter.selectedInterpreters.length && scope.filter.selectedInterpreters !== 'all' ? scope.filter.selectedInterpreters : undefined,
                    }
                    $clientInterpreter.interpreterAvailableWO(currentCli, filter)
                        .then((res) => {
                            ngToast.create({
                                content: 'System started sending notification',
                                className: 'success'
                            });
                            scope.pageData.isLoadingSendAll = false;
                        })
                        .catch((err) => {
                            console.log(err)
                            ngToast.create({
                                content: err.message || 'Something went wrong! try after sometime',
                                className: 'danger'
                              });
                            scope.pageData.message = err.message || err;
                            scope.pageData.isLoadingSendAll = false;
                        })
                }
                init()
            }
        }
    }
// Dependency Injection
interpreterAvailableWosDirective.$inject = ["$rootScope","$clientSwitcher","$auth","$activeClientSettings","$clientInterpreter","Modal","ngToast"];
