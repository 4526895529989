
'use strict';

export const languageArrayFilter  = function ($sce, $compile) {
        return function (val) {
            let label = '';
            if (val && Array.isArray(val) && val.length) {
                val.map(function (item) {
                    let number = item.substring(0, item.indexOf('$'));
                    if (Number(number) && Number(number) >= 2 && Number(number) <= 4) {
                        label = `${label} <div class="cursor-pointer tooltip" uib-tooltip="${item.substring(item.indexOf('$') + 1)} (${number})" title="${item.substring(item.indexOf('$') + 1)} (${number})" tooltip-append-to-body="true" tooltip-directive> <div class="lang_colored_item"  ><div class="dot_width"><span class="dot dot_yellow"></span></div><div class="dot_text"><span> ${item.substring(item.indexOf('$') + 1)} </span></div></div></div>`;
                    } else if (Number(number) && Number(number) >= 5 && Number(number) <= 8) {
                        label = `${label} <div class="cursor-pointer tooltip" uib-tooltip="${item.substring(item.indexOf('$') + 1)} (${number})" title="${item.substring(item.indexOf('$') + 1)} (${number})" tooltip-append-to-body="true" tooltip-directive> <div class="lang_colored_item"  ><div class="dot_width"><span class="dot dot_green"></span></div><div class="dot_text"><span> ${item.substring(item.indexOf('$') + 1)} </span></div></div></div>`;
                    } else if (Number(number) == 9.1) {
                        label = `${label} <div class="cursor-pointer tooltip" uib-tooltip="${item.substring(item.indexOf('$') + 1)} (${number})" title="${item.substring(item.indexOf('$') + 1)} (${number})" tooltip-append-to-body="true" tooltip-directive> <div class="lang_colored_item"  ><div class="dot_width"><span class="dot dot_grey"></span></div><div class="dot_text"><span> ${item.substring(item.indexOf('$') + 1)} </span></div></div></div>`;
                    } else if (Number(number) && Number(number) >= 9.2 && Number(number) <= 9.7) {
                        label = `${label} <div class="cursor-pointer tooltip" uib-tooltip="${item.substring(item.indexOf('$') + 1)} (${number})" title="${item.substring(item.indexOf('$') + 1)} (${number})" tooltip-append-to-body="true" tooltip-directive> <div class="lang_colored_item"  ><div class="dot_width"><span class="dot dot_red"></span></div><div class="dot_text"><span> ${item.substring(item.indexOf('$') + 1)} </span></div></div></div>`;
                    } else if (Number(number) == 9.8) {
                        label = `${label} <div class="cursor-pointer tooltip" uib-tooltip="${item.substring(item.indexOf('$') + 1)} (${number})" title="${item.substring(item.indexOf('$') + 1)} (${number})" tooltip-append-to-body="true" tooltip-directive> <div class="lang_colored_item"  ><div class="dot_width"><span class="dot dot_yellow"></span></div><div class="dot_text"><span> ${item.substring(item.indexOf('$') + 1)} </span></div></div></div>`;
                    } else {
                        label = `${label} <div class="cursor-pointer tooltip" uib-tooltip="${item.substring(item.indexOf('$') + 1)} (${number})" title="${item.substring(item.indexOf('$') + 1)} (${number})" tooltip-append-to-body="true" tooltip-directive> <div class="lang_colored_item"  ><div class="dot_width"><span class="dot dot_grey"></span></div><div class="dot_text"><span> ${item.substring(item.indexOf('$') + 1)} </span></div></div></div>`;
                    }
                })
                return $sce.trustAsHtml(label);
            } else {
                return val;
            }
        };
    } 
 

// Dependency Injection
languageArrayFilter.$inject = ['$sce', '$compile'];
