'use strict';

export const interpreterDocumentDirective = function ($auth, $q, $clientSwitcher, ngToast, $activeClient, $interpreterDocument, Modal, $rootScope) {
        return {
            restrict: 'E',
            templateUrl: 'app/account/dashboard/main-dashboard/interpreter-resource/tabs/profile/tabs/document/document.html',
            scope: {
                interpreterDetails: '=interpreterDetails',
                isInterpreter: '=isInterpreter',
                permissions: '=userPermissions',
            },
            link: function ($scope) {
                let killClientWatch, currentCli = $clientSwitcher.getCurrentClient();
                $scope.documents = [];
                $scope.data = {
                    isLoading: false,
                    error: null,
                    orderType: 'desc',
                    orderByField: '_id',
                    documents: []
                }
                $scope.sorting = {
                    key: '_id',
                    value: false
                }
                $scope.InterpreterName = `${$scope.interpreterDetails.firstname} ${$scope.interpreterDetails.middlename ? $scope.interpreterDetails.middlename : ''} ${$scope.interpreterDetails.lastname}`
                $scope.moment = moment;
                $scope.toggleRow = function () {
                    $scope.selected = !$scope.selected;
                };

                $scope.permissions = {}
                if ($scope.permissions &&  $scope.permissions.length) {
                    $scope.permissions = {
                        hasCreateDocument: $scope.permissions.includes('CREATE_RESOURCES_DOCUMENTS'),
                        hasEditDocument: $scope.permissions.includes('EDIT_RESOURCES_DOCUMENTS'),
                        hasDeleteDocument: $scope.permissions.includes('DELETE_RESOURCES_DOCUMENTS'),
                        hasAddComment: $scope.permissions.includes('ADD_RESOURCES_DOCUMENTS_COMMENT'),
                        hasEditComment: $scope.permissions.includes('EDIT_RESOURCES_DOCUMENTS_COMMENT'),
                        hasRemoveComment: $scope.permissions.includes('DELETE_RESOURCES_DOCUMENTS_COMMENT'),
                        hasAddAttachment: $scope.permissions.includes('ADD_RESOURCES_DOCUMENTS_ATTACHMENT'),
                        hasRemoveAttachment: $scope.permissions.includes('DELETE_RESOURCES_DOCUMENTS_ATTACHMENT'),
                    }
                }

                $scope.isSelected = function (i) {
                    return $scope.selected;
                };

                function init() {
                    killClientWatch = $activeClient(function (client) {
                        if (!client) {
                            return;
                        }
                        currentCli = client;
                        loadDocument(false);
                    });
                }
                function loadDocument(isUpdateCount) {
                    $scope.data.isLoading = true;
                    let filter = {
                        vendorId: $scope.interpreterDetails.vendorid,
                    }
                    $interpreterDocument.getDocument(currentCli, filter)
                        .then((res) => {
                            if (res.status) {
                                $scope.documents = res.result;
                            }
                        })
                        .catch((err) => {
                            let errMessage = err.message || err;
                            if (err && err.data && err.data.message) errMessage = err.data.message;
                            $scope.data.error = errMessage;
                        })
                        .finally(() => {
                            $scope.data.isLoading = false;
                            if (isUpdateCount) {
                                broadcastCount();
                            }
                        })
                }

                function broadcastCount() {
                    let count = $scope.documents.length;
                    $rootScope.$broadcast('profileTabs', 'documents', count);
                }

                $scope.addNew = function () {
                    Modal.resources.createDocument($scope.interpreterDetails, null, (res) => {
                        if (res) {
                            loadDocument(true);
                        }
                    })()
                }

                $scope.editDocument = function (document) {
                    Modal.resources.createDocument($scope.interpreterDetails, document, (res) => {
                        if (res) {
                            loadDocument(false);
                        }
                    })();
                }
                $scope.addNewFile = function (e, document) {
                    e.stopPropagation();
                    let config = {
                        isSingleUploader: false,
                        title: `Add attachments | ${$scope.InterpreterName}`
                    }
                    Modal.attachments.addAttachments(config, function (res) {
                        if (res.status) {
                            let obj = {
                                documentId: document._id,
                                attachments: res.newAttachmentIds
                            }
                            updateDocument(obj, document, 'Attachment')
                        }
                    })();
                }
                function updateDocument(obj, document, actionFrom) {
                    $interpreterDocument.update(currentCli, obj)
                        .then(function (res) {
                            if (res.status) {
                                ngToast.create({
                                    className: 'success',
                                    content: `${actionFrom} added successfully.`
                                });
                                document.comments = res.result.comments;
                                document.attachments = res.result.attachments;
                            } else {
                                ngToast.create({
                                    className: 'danger',
                                    content: res.message
                                });
                            }
                        })
                        .catch((err) => {
                            let errMessage = err.message || err;
                            ngToast.create({
                                className: 'danger',
                                content: errMessage || err
                            });
                        })
                }
                $scope.addEditComments = function (e, document, documentIndex, comment) {
                    // console.log('comment')
                    e.stopPropagation();
                    Modal.resources.addOrUpdateComment(comment, `${comment ? 'Update' : 'Add'} Comment | ${$scope.InterpreterName}`, function (res) {
                        if (res) {
                            let obj = {
                                documentId: document._id,
                                comment: res
                            }
                            updateDocument(obj, document, 'Comment');
                        }
                    })()
                }

                $scope.remove = function (e, document) {
                    e.preventDefault()
                    Modal.confirm.show('Confirm Delete', 'Are you sure you want to delete <strong>' + document.title + '</strong>?', 'Delete', 'Cancel', function () {
                        $interpreterDocument.remove(currentCli, document._id)
                            .then(function (data) {
                                loadDocument(true);
                            });
                    });
                }

                $scope.removeComments = function (e, document, comment) {
                    e.preventDefault()
                    Modal.confirm.show('Confirm Delete', 'Are you sure you want to delete <strong>' + comment.comment + '</strong>?', 'Delete', 'Cancel', function () {
                        $interpreterDocument.removeComments(currentCli, { documentId: document._id, commentId: comment._id })
                            .then(function (res) {
                                if (res && res.status) {
                                    document.comments = res.result.comments || document.comments;
                                    ngToast.create({
                                        className: 'success',
                                        content: 'Comment Deleted Successfully'
                                    });
                                }
                            })
                            .catch((err) => {
                                let errMessage = err.message || err;
                                ngToast.create({
                                    className: 'danger',
                                    content: errMessage || err
                                });
                            });
                    });
                }

                $scope.removeAttachment = function (attachmentId, document) {
                    Modal.confirm.show('Confirm Delete', 'Are you sure you want to delete attchment?', 'Delete', 'Cancel', function () {
                        $interpreterDocument.removeComments(currentCli, { documentId: document._id, attachmentId })
                            .then(function (res) {
                                if (res && res.status) {
                                    document.attachments = res.result.attachments || document.attachments;
                                    ngToast.create({
                                        className: 'success',
                                        content: 'Attachment Deleted Successfully'
                                    });
                                }
                            })
                            .catch((err) => {
                                let errMessage = err.message || err;
                                ngToast.create({
                                    className: 'danger',
                                    content: errMessage || err
                                });
                            });
                    });
                }
                $scope.$on('$destroy', function () {
                    if (killClientWatch) {
                        killClientWatch();
                    }
                });

                $scope._sorting = function (fieldName) {
                    if ($scope.data && $scope.documents && $scope.documents.length <= 0) return;
                    // $scope.documents = []
                    if ($scope.data.orderByField == fieldName && $scope.data.orderType == 'desc') {
                        $scope.data.orderType = 'asc';
                    } else {
                        $scope.data.orderType = 'desc'
                    }
                    $scope.data.orderByField = fieldName;
                    $scope.sorting = {
                        key: fieldName,
                        [fieldName]: $scope.data.orderType == 'asc' ? false : true
                    }
                    if ($scope.sorting[fieldName]) {
                        $scope.documents.sort((a, b) => `${b[fieldName]}`.localeCompare(`${a[fieldName]}`, undefined, { numeric: true }))
                    } else {
                        $scope.documents.sort((a, b) => `${a[fieldName]}`.localeCompare(`${b[fieldName]}`, undefined, { numeric: true }))
                    }
                }
                init()
            }
        }
    }
// Dependency Injection
interpreterDocumentDirective.$inject = ["$auth","$q","$clientSwitcher","ngToast","$activeClient","$interpreterDocument","Modal","$rootScope"];
