'use strict';

export const DashboardSettingsOffManageIndexCtrl = function ($state, $scope, $clientSwitcher, $activeClient, $offeringRules, $auth, ngToast, Modal) {
        let currentCli = null;
        $scope.loading = false;
        $scope.hasPermission = {};
        $scope.allOfferingRules = [];
        $scope.sortingOrders = [{
            label: 'A-Z',
            value: 'true'
        }, {
            label: 'Z-A',
            value: 'false'
        }];
        $scope.data = {
            isLoading: false,
            error: null
        }
        $scope.search = {
            ruleSearch: '',
            orderVal: 'true'
        };
        $scope.objectKey = Object.keys;
        $scope.serviceTypeCount = {};
        $scope.bucketData = {};
        $scope.offeringRuleLists = {};

        $scope.models = {
            selected: null
        };

        function sortKeys(obj_1) {
            var key = Object.keys(obj_1)
                .sort(function order(key1, key2) {
                    if (key1 < key2) return -1;
                    else if (key1 > key2) return +1;
                    else return 0;
                });

            // Taking the object in 'temp' object
            // and deleting the original object.
            var temp = {};

            for (var i = 0; i < key.length; i++) {
                temp[key[i]] = obj_1[key[i]];
                delete obj_1[key[i]];
            }

            // Copying the object from 'temp' to
            // 'original object'.
            for (var i = 0; i < key.length; i++) {
                obj_1[key[i]] = temp[key[i]];
            }
            return obj_1;
        }

        function getOfferingRules(isPageLoading) {
            if (isPageLoading) $scope.data.isLoading = true;
            $offeringRules.getOfferingRules(currentCli)
                .then(({ offeringList, status }) => {
                    if (status) {
                        if (offeringList && offeringList.length) {
                            $scope.allOfferingRules = offeringList;
                            $scope.activeOfferingRules = offeringList.filter(x => x.isEnabled).length;
                            let serviceTypeGrp = _.groupBy(offeringList, 'serviceType');
                            let offeringRuleLists = {};
                            Object.keys(serviceTypeGrp).map(function (serviceType) {
                                $scope.serviceTypeCount[serviceType] = serviceTypeGrp[serviceType].length;
                                offeringRuleLists[serviceType] = {};
                                let locationGrp = _.groupBy(serviceTypeGrp[serviceType], 'locations');
                                Object.keys(locationGrp).map(function (key) {
                                    locationGrp[key].map((loc) => {
                                        loc.dndType = `${key || 'All'}_${serviceType}`;
                                        return loc;
                                    })
                                })
                                offeringRuleLists[serviceType] = locationGrp //sortKeys(locationGrp);
                            });
                            $scope.offeringRuleLists = offeringRuleLists;
                        } else {
                            $scope.data.error = 'No rule found!';
                        }
                    }
                    $scope.hasPermission.allowEditOfferingRule = $auth.getCurrentUser().permissions.includes('SETTING_OFFERING_MANAGEMENT_RULE_EDIT');
                    $scope.hasPermission.allowDeleteOfferingRule = $auth.getCurrentUser().permissions.includes('SETTING_OFFERING_MANAGEMENT_RULE_DELETE');
                })
                .catch((err) => {
                    let errMessage = err.message || err;
                    if (err && err.data && err.data.message) {
                        errMessage = err.data.message;
                    } else if (err && err.data && err.data.reason) {
                        errMessage = err.data.reason;
                    }
                    $scope.data.error = errMessage;
                })
                .finally(() => $scope.data.isLoading = false);
        }

        // listen for client switching
        $scope.$on('$destroy', $activeClient(function (client) {
            if (!client) return;
            currentCli = client;
            getOfferingRules(true);
        }));

        $scope.convertArrayToString = (value, text) => {
            if (value && value.length) {
                return value.join(', ');
            }
            return `All ${text}`
        }

        $scope.toggleAccess = function (offeringRule) {
            var modalTitle = (offeringRule.isEnabled ? 'Disable' : 'Enable') + ' rule';
            var modalBody = "Are you sure you want to " + (offeringRule.isEnabled ? 'disable' : 'enable') + " <strong>" + (offeringRule.name) + "</strong> ?";
            var notificationContent = "Rule " + (offeringRule.isEnabled ? 'disabled' : 'enabled') + ' successfully';

            Modal.confirm.show(modalTitle, modalBody, "Yes", "No", function () {
                $offeringRules.toggleAccess(offeringRule._id, currentCli).then(function (result) {
                    if (result.status) {
                        ngToast.create({
                            className: 'success',
                            content: notificationContent
                        });

                        //set the new value after server confirmation
                        offeringRule.isEnabled = !offeringRule.isEnabled;
                        getOfferingRules(false);
                    } else {
                        ngToast.create({
                            className: 'danger',
                            content: 'Error' + result.reason
                        });
                    }
                });
            });
        };

        $scope.onDelete = function (offeringRule) {
            var modalTitle = 'Delete rule';
            var modalBody = "Are you sure you want to delete <strong>" + (offeringRule.name) + "</strong> ?";
            var notificationContent = 'Rule deleted successfully';

            Modal.confirm.show(modalTitle, modalBody, "Yes", "No", function () {
                $offeringRules.delete(currentCli, offeringRule._id).then(function (result) {
                    if (result.status) {
                        ngToast.create({
                            className: 'success',
                            content: notificationContent
                        });
                        getOfferingRules(false);
                    } else {
                        ngToast.create({
                            className: 'danger',
                            content: 'Error' + result.reason
                        });
                    }
                });
            });
        }

        $scope.onOrderSave = function (locationRules) {
            let updatedRules = [];
            locationRules.forEach((obj, i) => {
                if (obj && obj._id) {
                    let rule = {
                        _id: obj._id,
                        executionOrder: i + 1
                    }
                    updatedRules.push(rule);
                }
            });
            $offeringRules.updateRuleOrder(currentCli, updatedRules).then(function (result) {
                if (result.status) {
                    ngToast.create({
                        className: 'success',
                        content: 'Execution Order Updated.'
                    });
                } else {
                    ngToast.create({
                        className: 'danger',
                        content: 'Error' + result.message
                    });
                }
            });
        }

        $scope.dndAllowType = function (location, serviceType) {
            location = location || 'All';
            return [`${location}_${serviceType}`];
        }
    }

// Dependency Injection
DashboardSettingsOffManageIndexCtrl.$inject = ["$state","$scope","$clientSwitcher","$activeClient","$offeringRules","$auth","ngToast","Modal"];

// angular.module('coordinateApp').controller('DashboardSettingsOffManageIndexCtrl', DashboardSettingsOffManageIndexCtrl);
