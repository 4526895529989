'use strict';
export const autoAssignedCountDirective = function ($rootScope, $state, $stateParams, $clientLanguages, $timeout, $serviceType, $clientSwitcher, $auth,
        $activeClientSettings, $autoAssigned, $autoTimezoneFormat) {
        return {
            restrict: 'E',
            templateUrl: 'app/account/dashboard/main-dashboard/work-orders/tabs/auto-assigned-count/auto-assigned-count.html',
            scope: {
                filter: '=filter'
            },
            link: function (scope) {
                const currentUser = $auth.getCurrentUser();
                const currentCli = $clientSwitcher.getCurrentClient();

                scope.pageData = {
                    isLoading: false,
                    error: null,
                    totalAutoAssigned: 0,
                    totalCases: 0,
                    totalAssignedConfirmedCases: 0,
                    topLangCount:  {count:0, langname: ''},
                    serviceTypeCount: [{sertype: 'OSI', count: 0}, {sertype: 'OPI', count: 0}, {sertype: 'VRI', count: 0}]
                }

                function init() {
                    scope.applyFilter();
                }

                scope.applyFilter = () => {
                    scope.loadAutoAssignedCount()
                }

                scope.loadAutoAssignedCount = function () {
                    if (scope.pageData.isLoading) return;
                    scope.pageData.isLoading = true;
                    scope.pageData.error = null;
                    scope.pageData.totalAutoAssigned = 0;
                    scope.pageData.totalAssignedConfirmedCases = 0;
                    scope.pageData.serviceTypeCount = [{sertype: 'OSI', count: 0}, {sertype: 'OPI', count: 0}, {sertype: 'VRI', count: 0}];
                    scope.pageData.topLangCount = {count:0, langname: ''}
                    scope.pageData.totalCases = 0
                    let filter = _.pick(scope.filter, ['searchStr', 'startDate', 'endDate', 'serviceType', 'deldateFilter']);

                    $autoAssigned.getAssignedCount(currentCli, filter).then((res) => {
                        scope.pageData.totalAutoAssigned = res.totalAutoAssigned || 0;
                        scope.pageData.totalCases = res.totalCases || 0;
                        scope.pageData.totalAssignedConfirmedCases = res.totalAssignedConfirmedCases || 0;
                        scope.pageData.topLangCount = res.topLanguage || 0;
                        if (res.serviceTypeCount && res.serviceTypeCount.length) {
                            scope.pageData.serviceTypeCount = scope.pageData.serviceTypeCount.map(defaultItem => {
                                let newItem = res.serviceTypeCount.find(newItem => newItem.sertype === defaultItem.sertype);
                                if (newItem) {
                                    defaultItem.count = newItem.count
                                }
                                return defaultItem
                            });
                        }
                    })
                        .catch((err) => {
                            console.log(err)
                        })
                        .finally(() => {
                            scope.pageData.isLoading = false;

                        })
                }
                init()
            }
        }
    }
// Dependency Injection
autoAssignedCountDirective.$inject = ["$rootScope","$state","$stateParams","$clientLanguages","$timeout","$serviceType","$clientSwitcher","$auth","$activeClientSettings","$autoAssigned","$autoTimezoneFormat"];
