import './acessibility_setting/accessibility-setting.scss';
'use strict';

export const SettingsConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.settings', {
        url: '/my/settings',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/settings/settings.html', '34a2ceb'); }],
        controller: 'AccountSettingsCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/settings/settings.controller.js', 'AccountSettingsCtrl'); }]
                      },
        data: {
          rejectPermission: true
        }
      })
      .state('app.account.notificationSettings', {
        url: '/my/notification-settings',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/settings/notification_setting/notification-setting.html', '6da6356a'); }],
        controller: 'UserMenuSettingsNotificationsCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/settings/notification_setting/notification-setting.controller.js', 'UserMenuSettingsNotificationsCtrl'); }]
                      },
        data: {
          rejectPermission: true
        }
      })
      .state('app.account.accessibilitySettings', {
        url: '/my/accessibility-settings',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/settings/acessibility_setting/accessibility-setting.html', 'c68c3e49'); }],
        controller: 'UserMenuSettingsAccessibilitiesCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/settings/acessibility_setting/acessibility-setting.controller.js', 'UserMenuSettingsAccessibilitiesCtrl'); }]
                      },
        data: {
          rejectPermission: true
        }
      });
  }
// Dependency Injection
SettingsConfig.$inject = ["$stateProvider"];
