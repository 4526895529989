


'use strict';

export const ForgotPasswordFactory  = function ($resource, appConfig) {
  return $resource(appConfig.apiUrl + '/password/:controller', 
  {    
  },
  {
    forgot: {
      method: 'POST',
      isArray: false,
      params: {
        controller: 'forgot',
        value: '@value'
      }
    },
    reset: {
      method: 'POST',
      isArray: false,
      params: {
        controller: 'reset',
        value: '@value'
      }
    }
  });
} 
 

// Dependency Injection
ForgotPasswordFactory.$inject = ['$resource', 'appConfig'];
