'use strict';


export const AudioTrackFactory = function () {

  function AudioTrack (opts) {
    opts = opts || {};

    Object.defineProperties(this, {
      title: {
        get: function () {
          return opts.title;
        }
      },
      src: {
        get: function () {
          return opts.src || opts.source;
        }
      },
      type: {
        get: function () {
          return opts.type || opts.format;
        }
      },
      transcription: {
        get: function () {
          return opts.transcription;
        }
      }
    });
  }

  AudioTrack.wrap = function (o) {
    if(o instanceof AudioTrack) {
      return o;
    }

    return new AudioTrack(o);
  };

  return AudioTrack;
} 
 export const AudioPlayListFactory = function (AudioTrack) {

  function AudioPlayList (opts) {
    this.tracks = [];

    if(!opts) return;

    if(angular.isArray(opts.tracks)) {
      this.push.apply(this, opts.tracks);
    }
  }

  AudioPlayList.wrap = function (o) {
    if(o instanceof AudioPlayList) {
      return o;
    }

    return new AudioPlayList(o);
  };

  Object.defineProperties(AudioPlayList.prototype, {
    length: {
      get: function () {
        return this.tracks.length;
      }
    }
  });

  AudioPlayList.prototype.push = function () {
    Array.prototype.push.apply(this.tracks, Array.prototype.slice.call(arguments).map(AudioTrack.wrap));
    return this;
  };

  AudioPlayList.prototype.splice = function () {
    Array.prototype.splice.apply(this.tracks, Array.prototype.slice.call(arguments, 0, 2));
    return this;
  };

  return AudioPlayList;
} 
 export const audioPlayerDirective = function () {
  return {
    restrict: 'E',
templateUrl: 'components/audio/audio-player.html',
    scope: {
      playlist: '=apPlaylist'
    },
    controller: ['$scope', function ($scope) {
      $scope.isCurrentTrack = function (player, index) {
        if(!player || !$scope.playlist || !$scope.playlist.length) return false;
        return player.currentTrack === (index + 1);
      };
      $scope.isPlayingTrack = function (player, index) {
        return $scope.isCurrentTrack(player, index) && player.playing;
      };
    }]
  };
} 
 export const audioPlayerControlsDirective = function () {
  return {
    restrict: 'E',
templateUrl: 'components/audio/audio-controls.html',
    scope: {
      player:     '=apMediaPlayer',
      trackIndex: '=apTrackIndex',
    },
    controller: ['$scope', function ($scope) {
      $scope.isCurrentTrack = function () {
        if(!$scope.player || !angular.isNumber($scope.trackIndex)) {
          return false;
        }

        return $scope.player.currentTrack === ($scope.trackIndex + 1);
      };

      $scope.isPlayingTrack = function (player, index) {
        return $scope.isCurrentTrack(player, index) && $scope.player.playing;
      };
    }]
  };
} 
 export const audioDurationDirective = function () {
  return {
    restrict: 'E',
templateUrl: 'components/audio/audio-duration.html',
    scope: {
      player:     '=apMediaPlayer'
    }
  };
}
// Dependency Injection
AudioPlayListFactory.$inject = ["AudioTrack"];
