'use strict';

export const reportsDirective = function ($activeClient, $clientReportBuckets, $q, $rootScope, ngToast, $clientSwitcher, $timeout, $clientRegion, $clientOffice, $clientReportBucketsUpload, $clientQueryBuilder,  LazyLoadService ) {
                  return {
            restrict: 'E',
            templateUrl: 'app/account/dashboard/tasks/tabs/reports/reports.html',
            scope: {
                isTaskReport: "=isTaskReport"
            },
            link: function($scope) {

  const lazyDirectives = [{"name":"reportBucketsDirective","path":import("@app/account/dashboard/tasks/tabs/reports/report-buckets/report-buckets.directive.js")}];
  $scope.lazyDirectivesLoaded = false;
  Promise.all(lazyDirectives.map(directive=>LazyLoadService.loadDirective(directive.path, directive.name))).then(() => {
          $timeout(function() {
            $scope.lazyDirectivesLoaded = true;

                         var currentCli = $clientSwitcher.getCurrentClient(),
                    killClientWatch,
                    allRegions = [], allLocations = [];
                $scope.isLoading = false;
                $scope.alertItemsRegion = []; // Region as location for SOSI client
                $scope.alertItemsOffice = []; // Office/Location as courts for SOSI client
                $scope.allValue = 'all';
                $scope.clientStrings = function () {
                    return $clientSwitcher.getFeatureOptions('client.strings');
                };
                $scope.isDateHide = {
                    hide: false
                };
                $scope.model = {
                    startDate: moment().startOf('month').toDate(),
                    endDate: moment().endOf('month').toDate(),
                    regions: $scope.allValue,
                    offices: $scope.allValue,
                    employees: $scope.allValue,
                    interpreterDataSets: []
                };
                $scope.textColor = { color: $rootScope.textColor };

                function initDateRangePicker() {
                    function cb(start, end) {
                        $timeout(function () {
                            if (!start || !end) {
                                return;
                            }

                            $('#reportrange span').html(start.format('MM/DD/YYYY') + ' to ' + end.format('MM/DD/YYYY'));
                            $scope.model.startDate = start.toDate();
                            $scope.model.endDate = end.toDate();
                            reloadReportBuckets();
                            getInterpreter();
                        }, 0);
                    }

                    $('#reportrange').daterangepicker({
                        startDate: moment($scope.model.startDate),
                        endDate: moment($scope.model.endDate),
                        opens: 'right'
                    }, cb);

                    var text = moment($scope.model.startDate).format('MM/DD/YYYY') + ' to ' + moment($scope.model.endDate).format('MM/DD/YYYY');
                    $('#reportrange span').html(text);
                }

                function reloadReportBuckets() {
                    $scope.leftBuckets = [];
                    $scope.rightBuckets = [];
                    $scope.fullBuckets = [];
                    $scope.isLoading = true;
                    let bucketPermissions = null;
                    return $clientReportBucketsUpload.reportBuckets(currentCli, {
                        startDate: $scope.model.startDate,
                        endDate: $scope.model.endDate
                    })
                        .then(function (reportBuckets) {
                            if (reportBuckets.status) {
                                bucketPermissions = reportBuckets.reportBucketRolesInfo.map(x =>
                                    ({
                                        id: x._id,
                                        permissions: (x.supportedRoles.map(y => y.permissions.map(z => z.code))).flat()
                                    }));
                                $scope.reportBuckets = reportBuckets.data;
                                function doesPermissionExist(type, allBucketPermissions, index) {
                                    const permissionObj = allBucketPermissions[index].permissions;
                                    return permissionObj.includes(type);
                                }
                                reportBuckets.data = reportBuckets.data.map(bucket => {
                                    const permissionIndex = bucketPermissions.findIndex(singleBucket => bucket._id === singleBucket.id);

                                    if (permissionIndex !== -1) {
                                        bucket.allowReportBucketView = doesPermissionExist('REPORT_BUCKETS_VIEW', bucketPermissions, permissionIndex);
                                        bucket.allowCreateFiles = doesPermissionExist('REPORT_BUCKETS_CREATE_FILES', bucketPermissions, permissionIndex);
                                        bucket.allowDeleteFiles = doesPermissionExist('REPORT_BUCKETS_DELETE_FILES', bucketPermissions, permissionIndex);
                                        bucket.allowDownloadFiles = doesPermissionExist('REPORT_BUCKETS_DOWNLOAD_FILES', bucketPermissions, permissionIndex);
                                    }
                                    return bucket;
                                })

                                reportBuckets.data.forEach(function (bucket) {
                                    bucket.bucketInterpreter = $scope.allValue;
                                    bucket.bucketOffices = $scope.allValue;
                                    bucket.bucketCOI = $scope.allValue;
                                    // if (bucket.timeframe == 'daily') {
                                    //     bucket.timeframeRange = {
                                    //         startDate: moment().startOf('day').toDate(),
                                    //         endDate: moment().endOf('day').toDate()
                                    //     };
                                    // } else if (bucket.timeframe == 'monthly') {
                                    //     bucket.timeframeRange = {
                                    //         startDate: moment().startOf('month').toDate(),
                                    //         endDate: moment().endOf('month').toDate()
                                    //     };
                                    // }
                                    bucket.pageData = {};
                                    bucket.pageData.page = 1;
                                    bucket.pageData.pageSize = 20;
                                    bucket.pageData.totalCount = bucket.bucketUploadRecords;
                                    bucket.interpreterDataSets = _.sortBy(_.uniq(bucket.interpreterDataSets, 'label'), ['label']);
                                    bucket.officeDataSets = _.sortBy(_.uniq($scope.alertItemsOffice, 'label'), ['label']);
                                    bucket.bucketRecords = bucket.totalAttachmentsCount;
                                    $scope.fullBuckets.push(bucket);
                                    // if (bucket.alignment == "left") $scope.leftBuckets.push(bucket);
                                    // else if (bucket.alignment == "right") $scope.rightBuckets.push(bucket);
                                    // else if (bucket.alignment == "full") $scope.fullBuckets.push(bucket);
                                })
                            }
                        })
                        .finally(function () {
                            $scope.isLoading = false;
                        });
                }

                $scope.reloadBuckets = function () {
                    reloadReportBuckets();
                    getInterpreter();
                }

                // Region as location for SOSI client
                var fetchRegions = $clientRegion.listAll(currentCli)
                    .then(function (regions) {
                        allRegions = regions.slice();

                        $scope.alertItemsRegion = regions.map(function (region) {
                            return {
                                label: region.name,
                                value: region._id
                            };
                        });
                    });

                // Office/Location as courts for SOSI client
                function fetchOffices() {
                    $clientOffice.listAll(currentCli)
                        .then(function (offices) {
                            allLocations = offices.slice();

                            $scope.alertItemsOffice = offices.map(function (location) {
                                return {
                                    label: location.name,
                                    value: location._id,
                                    internalId: location.internalId
                                };
                            });
                        });
                }

                var init = function () {
                    $scope.$on('$destroy', function () {
                        if (killClientWatch) {
                            killClientWatch();
                        }
                    });

                    killClientWatch = $activeClient(function (current) {
                        if (!current) {
                            return;
                        }

                        currentCli = current;
                        setTimeout(initDateRangePicker);
                        reloadReportBuckets();
                        getInterpreter();
                        fetchOffices();
                        // $q.all([
                        //     fetchRegions,
                        //     fetchOffices
                        // ])
                        //     .then(function (data) {
                        //     })
                    });
                };

                function getInterpreter() {
                    var connectionType = 'postgresql',
                        startDate = moment($scope.model.startDate).format('YYYY-MM-DD'),
                        endDate = moment($scope.model.endDate).format('YYYY-MM-DD');
                    // var query = "SELECT interprername FROM public.sosiecoi where deldate between '" + startDate + "' and '" + endDate + "' GROUP BY interprername ORDER BY interprername ASC";
                    var query = "SELECT vendorid, concat(lastname, ', ', firstname) as interpreter FROM vinterpreters WHERE vendorid <= '600000' ORDER BY interpreter";
                    $scope.model.interpreterDataSets = [];
                    $clientQueryBuilder.getRecords(currentCli, query, connectionType).then(function (res) {
                        if (res.status) {
                            if (res.data.length) {
                                $scope.model.interpreterDataSets = res.data.map(function (interpreter, index) {
                                    return {
                                        label: interpreter.interpreter + ' | ' + interpreter.vendorid,
                                        value: interpreter.vendorid.toString(),
                                        name: interpreter.interpreter
                                    };
                                });
                            }
                        }
                        else {
                            $scope.data.error = res.reason;
                        }
                    });
                }

                init();

    })
});
}
}
}
//Dependency Injection
reportsDirective.$inject = ["$activeClient","$clientReportBuckets","$q","$rootScope","ngToast","$clientSwitcher","$timeout","$clientRegion","$clientOffice","$clientReportBucketsUpload","$clientQueryBuilder",  "LazyLoadService"];
