


'use strict';

export const LiveReportsFactory  = function ($resource, appConfig) {
    return $resource(appConfig.apiUrl + '/api/live-reports/:id/:controller', {
        id: '@_id'
    },
    {
        filterCourtOperations: {
            method: 'POST',
            params: {
                id: '@id',
                controller: 'filter-court-operations'
            }
        },
        reportingCourtLinkToHrgCourt: {
          method: 'GET',
          params: {
            id: '@id',
          }
        },
        getLinkFilterDetails: {
          method: 'GET',
          params: {
            id: '@id',
          }
        },
        downloadReport: {
          method: 'POST',
          params: {
            id: '@id',
            controller: 'download-report'
          }
        },
        getAssignmentReportData: {
          method: 'GET',
          params: {
            id: '@id',
            controller: '@controller'
          }
        },
        getDailyReportData: {
          method: 'GET',
          params: {
            id: '@id',
            controller: '@controller'
          }
        },
        getAssignmentStatusReport: {
          method: 'GET',
          params: {
            id: '@id',
            controller: '@controller'
          }
        },
        getFilterData: {
          method: 'POST',
          params: {
            id: '@id',
            controller: '@controller'
          }
        },
        downloadDailyReport: {
          method: 'POST',
          params: {
            id: '@id',
            controller: 'download-daily-report'
          }
        },
        downloadAssignmentReport: {
          method: 'POST',
          params: {
            id: '@id',
            controller: 'download-assignment-report'
          }
        }
    });
  } 
 

// Dependency Injection
LiveReportsFactory.$inject = ['$resource', 'appConfig'];
