
'use strict';

export const $courtOperationsService  = function (CourtOperations, $http) {
        return {
            courtOpsCases: function (client, filter, paginationData) {
                return CourtOperations.courtOpsCases({
                    id: client._id || client,
                    filterBy: filter,
                    page: paginationData && paginationData.page,
                    pageSize: paginationData && paginationData.pageSize
                }).$promise;
            },
            _courtOpsCases: function (client, filter, paginationData) {
                return CourtOperations._courtOpsCases({
                    id: client._id || client,
                    filterBy: filter,
                    page: paginationData && paginationData.page,
                    pageSize: paginationData && paginationData.pageSize
                }).$promise;
            },
            filterCourtOps: function (client, filter) {
                return CourtOperations.filterCourtOps({
                    id: client._id || client,
                    filterBy: filter
                }).$promise;
            },
            getCasesDetails: function (client, ecoi, dataSets) {
                return CourtOperations.getCasesDetails({
                    id: client._id || client,
                    ecoi,
                    dataSets: dataSets,
                    controller: 'cases-detail',
                }).$promise;
            },
            courtOpsCasesCount: function(client, filter) {
                return CourtOperations.courtOpsCasesCount({
                    id: client._id || client,
                    filterBy: filter || {}
                }).$promise;
            },
            judgeCases: function (client, filter, paginationData) {
                return CourtOperations.judgeCases({
                    id: client._id || client,
                    filter: filter,
                    page: paginationData && paginationData.page,
                    pageSize: paginationData && paginationData.pageSize
                }).$promise;
            },
            judgeCasesFilter: function (client, filter) {
                return CourtOperations.judgeCasesFilter({
                    id: client._id || client,
                    filter: filter,
                }).$promise;
            },
            getCOVICOTIFile: function(client, coi, recordNumber){      
                return $http({
                   url: '/api/court_operations/'+ (client._id || client) +'/covi-coti-pdf/'+ coi + '/' +recordNumber, 
                   method: "POST",
                   responseType: "arraybuffer"
                 });
             },
        };
    }
// Dependency Injection


// Dependency Injection
$courtOperationsService.$inject = ['CourtOperations', '$http'];
