


'use strict';

export const ClientRegionFactory  = function ($resource, appConfig) {
    return $resource(appConfig.apiUrl + '/api/client-regions/:id/:controller', {
      id: '@_id'
    },
    {
      query: {
        method: 'GET',
        isArray: true,
        cache: true
      },
      detail: {
        method: 'GET',
        params: {
          controller: 'detail'
        }
      }
    });
  }


// Dependency Injection
ClientRegionFactory.$inject = ['$resource', 'appConfig'];
