


'use strict';

export const ClientUserFactory  = function ($resource, appConfig) {
    return $resource(appConfig.apiUrl + '/api/client-users/:clientId/:controller', {
      clientId: '@_id'
    },
    {
      detail: {
        method: 'GET',
        params: {
          clientId: '@clientId',
          controller: 'detail'
        }
      },

      possibleParents: {
        method: 'GET',
        isArray: true,
        params: {
          clientId: '@clientId',
          controller: 'parentIndex'
        }
      },

      chainOfCommand: {
        method: 'GET',
        params: {
          clientId: '@clientId',
          controller: 'chainOfCommand'
        }
      },

      locations: {
        method: 'GET',
        isArray: true,
        params: {
          clientId: '@clientId',
          controller: 'locations'
        }
      },

      supportOrgNotifications: {
        method: 'GET',
        params: {
          clientId: '@clientId',
          controller: 'support-org-notifications'
        }
      }, 

      isEmailUnique: {
        method: 'GET',
        params: {
          clientID: '@clientId',
          controller: 'is-email-unique'
        }
      },

      isInternalIdUnique: {
        method: 'GET',
        params: {
          clientID: '@clientId',
          controller: 'is-internal-id-unique'
        }
      },

      getLinkedEmployees: {
        method: 'GET',
        params: {
          clientId: '@clientId',
          controller: 'linked-employees'
        }
      },

      getContactUsers: {
        method: 'GET',
        params: {
          clientId: '@clientId',
          controller: 'contact-users'
        }
      },
      getUserPermissions: {
        method: 'GET',
        params: {
          clientId: '@clientId',
          controller: 'get-user-permissions'
        }
      },

      getUsersByRoles: {
        method: 'POST',
        params: {
          clientId: '@clientId',
          controller: 'get-users-by-roles'
        }
      },
      updateAccessibility: {
        method: 'POST',
        params: {
          clientId: '@clientId',
          controller: 'update-accessibility'
        } 
      },
      getUsersList: {
        method: 'GET',
        params: {
          clientId: '@clientId',
          controller: 'get-users-list'
        }
      },
      getNotLoggedInUser: {
        method: 'GET',
        params: {
          clientId: '@clientId',
          controller: 'not-logged-in'
        }
      },
      getNotLoggedInUserReport: {
        method: 'GET',
        params: {
          clientId: '@clientId',
          controller: 'not-logged-in-report'
        }
      },
      getDisabeldUsersList: {
        method: 'GET',
        params: {
          clientId: '@clientId',
          controller: 'get-disabled-users-list'
        }
      },
      getInterpreterUsersList: {
        method: 'GET',
        params: {
          clientId: '@clientId',
          controller: 'get-interpreter-user-list'
        }
      },
      sendWelcomMail: {
        method: 'POST',
        params: {
          clientId: '@clientId',
          controller: 'send-welcom-mail'
        }
      },
      removeUser: {
        method: 'DELETE',
        params: {
          clientId: '@clientId',
          controller: '@controller',
        }
      }
    });
  } 
 

// Dependency Injection
ClientUserFactory.$inject = ['$resource', 'appConfig'];
