
export const CollectionRecordFactory = function ($timeout) {
  function CollectionRecord (raw) {
    this.raw = raw;
    this.showDetails = false;
    this.resolvable = false;
    this.rawIdProperty = '_id';
  }

  Object.defineProperties(CollectionRecord.prototype, {
    id: {
      get: function () {
        var def = null;
        if(this.raw) return this.raw[this.rawIdProperty] || def;
        return def;
      }
    }
  });

  CollectionRecord.prototype.toggleDetailsVisible = function () {
    this.showDetails = !this.showDetails;
    return this;
  };

  CollectionRecord.prototype.isResolvable = function () {
    return this.resolvable;
  };

  CollectionRecord.prototype.blink = function () {

    if(this.$blinkHdlr) {
      $timeout.cancel(this.$blinkHdlr);
    }

    this.blinking = true;
    // console.log('blinking record', this);

    this.$blinkHdlr = $timeout((function () {
      this.blinking = false;
      // console.log('un-blinking record.', this);
    }).bind(this), 5000);
  };

  CollectionRecord.wrap = function (raw) {
    if(!!raw && raw instanceof CollectionRecord) return raw;
    return new CollectionRecord(raw);
  };
  return CollectionRecord;
} 
 export const CollectionColumnFactory = function ($log, $compile) {
  function CollectionColumn (spec) {
    this._methods = {};
    spec = spec || {};
    this.scope = spec.scope;
    this.methods = spec.methods;
    this.label = spec.label;
    this.id = spec.id;
    this.property = spec.property;
    this.liveUpdate = spec.liveUpdate;
    this.liveUpdateInterval = spec.liveUpdateInterval;
    this.index = spec.index;
    this.align = spec.align;
    this.flex = spec.flex;
    this.renderer = spec.renderer;
    this.sort = spec.sort;
    this.sortDir = spec.sortDir;
    this.sortable = (spec.sortable === undefined ? true : spec.sortable);
  }

  Object.defineProperties(CollectionColumn.prototype, {
    classes: {
      get: function () {
        var
        classes = {
        };

        if(!!this.align) {
          classes['align-' + this.align] = true;
        }

        if(!!this.flex) {
          classes['flex-' + this.flex] = true;
        }

        if(!!this.sort) {
          classes['sorted-by'] = true;
        }

        return classes;
      }
    },
    scope: {
      get: function () {
        return this._scope;
      },
      set: function (v) {
        this._scope = v;
      }
    },
    methods: {
      get: function () {
        return this._methods;
      },
      set: function (v) {
        if(!angular.isObject(v)) return;
        this._methods = v;
      }
    },
    sortable: {
      get: function () {
        return this._sortable;
      },
      set: function (v) {
        this._sortable = !!v;
      }
    },
    sort: {
      get: function () {
        return this._sort;
      },
      set: function (v) {
        if(angular.isFunction(v) || (v === true || v === false))
          this._sort = v;
      }
    },
    sortDir: {
      get: function () {
        return this._sortDir || 1;
      },
      set: function (v) {
        if(v !== -1 && v !== 1) return;
        this._sortDir = v;
      }
    },
    liveUpdate: {
      get: function () {
        return !!this._liveUpdate;
      },
      set: function (v) {
        this._liveUpdate = !!v;
      }
    },
    liveUpdateInterval: {
      get: function () {
        return this._liveUpdateInterval || 1000;
      },
      set: function (v) {
        if(!angular.isNumber(v)) return;
        this._liveUpdateInterval = v;
      }
    },
    renderer: {
      get: function () {
        return this._renderer;
      },
      set: function (v) {
        if(!angular.isFunction(v)) return;
        this._renderer = v;
      }
    },
    align: {
      get: function () {
        return this._align;
      },
      set: function (v) {
        if( !v || ['left','center','right'].indexOf(String(v).toLowerCase()) === -1 )
          return false;

        this._align = v;
      }
    },
    flex: {
      get: function () {
        return this._flex;
      },
      set: function (v) {
        if( !v || isNaN(v) )
          return false;

        this._flex = v;
      }
    },
    label: {
      get: function () {
        return this._label;
      },
      set: function (v) {
        if(!v) {
          this._label = '';
          return;
        }

        if(!angular.isString(v))
          return;

        this._label = v;
      }
    },
    index: {
      get: function () {
        return this._index;
      },
      set: function (v) {
        if(isNaN(v)) return;
        this._index = v;
      }
    },
    property: {
      get: function () {
        return this._property;
      },
      set: function (v) {
        this._property = v;
      }
    }
  });

  CollectionColumn.prototype.getDeepProperty = function(obj, property) {

    var
    val, delimiter = '.';

    if(!angular.isObject(obj) || !property) // invalid search
      return val;

    if(property.indexOf(delimiter) > 0) { // see if property is using 'deep' search  obj.ns1[.ns2...]
      var
      namespaces = property.split(delimiter),
      nsTotal = namespaces.length,
      curNs = 0, root = obj;

      for(;;) {
        root = root[namespaces[curNs]];

        if(root === undefined) // invalid ns property.
          break;

        if(curNs >= (nsTotal - 1)) { // end of query check:
          val = root;
          break;
        }

        if(angular.isObject(root)) { // next iteration
          curNs++;
          continue;
        }
        else // invalid type of deep searchable.
          break;
      }
    }
    else {
      val = obj[property];
    }

    return val;
  };

  CollectionColumn.prototype.render = function(record) {
    var
    raw = record.raw,
    recColData;

    if(angular.isArray(raw) && !isNaN(this.index)) { // use index
      recColData = raw[this.index];
    }
    else if(angular.isObject(raw) && !!this.property) { // use property
      recColData = this.getDeepProperty(raw, this.property);
    }

    if(angular.isFunction(this.renderer)) {
      recColData = this.renderer(recColData, record, this);
    }

    // always wrap in the following div:
    return '<div class="grid-cell-contents text-ellipsis">' + (recColData||'---') + '</div>';
  };

  CollectionColumn.wrap = function (spec) {
    spec = spec || {};
    if(!!spec && spec instanceof CollectionColumn) return spec;
    return new CollectionColumn(spec);
  };
  return CollectionColumn;
} 
 export const CollectionFactory = function (eventEmitter, CollectionColumn, CollectionRecord, $utilities) {
  function Collection(columns, records, rowDetailRenderer, resolvable) {
    this._records = [];
    this._columns = [];

    this.addColumns(columns);
    this.addRecords(records);
    this.rowDetailRenderer = rowDetailRenderer;
    this.resolvable = !!resolvable;
  }

  Object.defineProperties(Collection.prototype, {
    columns: {
      get: function () {
        return this._columns;
      }
    },
    pageSize: {
      get: function () {
        return this._pageSize || 10;
      },
      set: function (v) {
        if(isNaN(v) || v < 1) return;
        this._pageSize = v;
      }
    },
    page: {
      get: function () {
        return this._page || 1;
      },
      set: function (v) {
        if(isNaN(v) || v < 1) return;
        this._page = v;
      }
    },
    records: {
      get: function () {
        return this._records;
      }
    },
    sortedRecords: {
      get: function () {
        return Collection.paginateRecords(
          this._records
            .slice()
            .sort(this.recordSorter()),
          this.page,
          this.pageSize,
          true
        );
      }
    },
    rowDetailRenderer: {
      get: $utilities.getter('_rowDetailRenderer', $utilities.fallbackNull),
      set: $utilities.setterFunction('_rowDetailRenderer', true)
    },
    length: {
      get: function() {
        return this.records.length;
      }
    }
  });

  // add event emitter support
  eventEmitter.inject(Collection);

  Collection.calculatePaginationOffset = function(page, pageSize) {
    if(!angular.isNumber(page) || !angular.isNumber(pageSize)) {
      return false;
    }

    return [(page-1)*pageSize, pageSize+((page-1)*pageSize)];
  };

  Collection.paginateRecords = function(arr, page, pageSize) {
    if(!angular.isArray(arr)) return [];

    page = page || 1;

    var
    offset = Collection.calculatePaginationOffset(page, pageSize);

    if(!offset) return arr;

    return arr.slice(offset[0], offset[1]);

  };

  Collection.prototype.findRecordPage = function (record) {
    if(!record) return this;

    var
    index = this._records
      .slice()
      .sort(this.recordSorter())
      .indexOf(record);

    if(index === -1) {
      return -1;
    }

    return Math.floor((index + 1) / this.pageSize) + 1;
  };

  Collection.prototype.ensureRecordVisible = function (record) {
    var
    recordPage = this.findRecordPage(record);

    if(recordPage === -1) {
      return this;
    }

    if(this.page !== recordPage) {
      this.page = recordPage;
    }

    return this;
  };

  Collection.prototype.toggleShowRecordDetail = function (record) {
    if(!record) return this;
    record.toggleDetailsVisible();
    return this;
  };

  Collection.prototype.toggleSorter = function (column, event) {
    var
    sorters = this.getSorters(), // existing sorters
    hasCtrl = !!event && event.ctrlKey,
    isUnsortedColumn = sorters.every(function (sorter) {
      if(sorter.column.property) {
        return column.property !== sorter.column.property;
      }

      return column.index !== sorter.column.index;
    });

    if(isUnsortedColumn) {
      if(!hasCtrl && sorters.length > 0) // clear existing sorters
        sorters.forEach(function (sorter) { sorter.column.sort = false });

      column.sort = true;
    }
    else {
      if(hasCtrl) {
        column.sort = false;
        return;
      }

      column.sortDir *= -1;
    }
  };

  Collection.prototype.getSorters = function () {

    var
    defaultSorter = function(a, b, direction) {
      var ascending = (direction === 1);
      if(a > b) return ascending ?  1 : -1;
      if(a < b) return ascending ? -1 :  1;
      return 0;
    }

    return this._columns.reduce(function (p, col, index) {
      if(!!col.sort) p.push({
        column: col,
        columnIndex: index,
        sorter: angular.isFunction(col.sort) ? col.sort : defaultSorter,
        sortDir: col.sortDir
      });

      return p;
    }, []);
  };

  Collection.prototype.recordSorter = function () {
    var
    sorters = this.getSorters();

    return function (a, b) {
      if(sorters.length === 0) return 0; // leave as is
      var sort = 0;

      sorters.every(function (sorter) {
        var
        key  = !!sorter.column.property ? sorter.column.property : sorter.column.index,
        valA = $utilities.objectFind(a.raw, key, null),
        valB = $utilities.objectFind(b.raw, key, null);

        sort = sorter.sorter(valA, valB, sorter.sortDir);

        return sort === 0;
      });

      return sort;
    };
  };

  Collection.prototype.clearColumns = function () {
    this._columns.splice(0, this._columns.length);
    this.emit('update');
    this.emit('clear-columns');
  };

  Collection.prototype.removeColumn = function (index) {
    this._columns.splice(index, 1);
    this.emit('update');
    this.emit('remove-column', index);
  };

  Collection.prototype.insertColumn = function (spec, index) {
    var column = CollectionColumn.wrap(spec);
    this._columns.splice(index, 0, column);
    this.emit('update');
    this.emit('insert-column', column, index);
  };

  Collection.prototype.addColumn = function (spec) {
    var column = CollectionColumn.wrap(spec);
    this._columns.push(column);
    this.emit('update');
    this.emit('add-column', column);
  };

  Collection.prototype.addColumns = function (columns) {
    if(!columns || !angular.isArray(columns)) return false;
    this.emit('before-add-columns', columns);
    columns.forEach(this.addColumn.bind(this));
    this.emit('update');
    this.emit('after-add-columns', this._columns);
    return this;
  };

  Collection.prototype.clearRecords = function () {
    this._records.splice(0, this._records.length);
    this.emit('update');
    this.emit('clear');
  };

  Collection.prototype.bindRecord = function (record) {
    record.resolvable = this.resolvable;
    return this;
  };

  Collection.prototype.insertRecord = function (record, index) {
    this._records.splice(index, 0, CollectionRecord.wrap(record));
    this.emit('update');
    this.emit('insert', record, index);
    return this.bindRecord(this._records[index]);
  };

  Collection.prototype.addRecord = function (record) {
    this._records.push(CollectionRecord.wrap(record));
    if(arguments.length === 1 || isNaN(arguments[1])) this.emit('update');
    this.emit('add', record);
    return this.bindRecord(this._records[this._records.length-1]);
  };

  Collection.prototype.addRecords = function (records) {
    if(!records || !angular.isArray(records)) return false;
    this.emit('before-add-multiple', records);
    records.forEach(this.addRecord.bind(this));
    this.emit('update');
    this.emit('after-add-multiple', this._records);
    return this;
  };

  Collection.prototype.recordIndexById = function (id) {
    var
    recordIndex = -1;
    this._records.every(function (rec, index) {

      if(rec.id === id)
        recordIndex = index;

      return recordIndex === -1;
    });

    return recordIndex;
  };

  Collection.prototype.findById = function (recordId) {
    var recordIndex = this.recordIndexById(recordId);
    return recordIndex === -1 ? false : this._records[recordIndex];
  };

  return Collection;
} 
 export const gridCellDirective = function ($compile, $timeout) {
  return {
    replace: true,
    restrict: 'E',
    scope: {
      record: '=',
      column: '='
    },
    link: function (scope, el, attrs) {

      var lTimeoutHandle;

      function recompile() {
        if(!scope.column || !scope.record) return;

        el.empty();
        el.append($compile(scope.column.render(scope.record))(scope));

        if(scope.column.liveUpdate) {
          if(lTimeoutHandle) {
            $timeout.cancel(lTimeoutHandle);
          }

          lTimeoutHandle = $timeout(recompile, scope.column.liveUpdateInterval || 1000);
        }
      }

      scope.$watch('column', recompile);
      scope.$watch('record', recompile);
      recompile();
    }
  };
} 
 export const gridDirective = function () {
  return {
    restrict: 'E',
templateUrl: 'components/grid/grid-template.html',
    scope: {
      collection: '=',
      maxDisplayPages: '='
    }
  };
}
// Dependency Injection
CollectionRecordFactory.$inject = ["$timeout"];
CollectionColumnFactory.$inject = ["$log","$compile"];
CollectionFactory.$inject = ["eventEmitter","CollectionColumn","CollectionRecord","$utilities"];
gridCellDirective.$inject = ["$compile","$timeout"];
