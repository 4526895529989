'use strict';

export const DashboardSettingsManagersCreateCtrl = function ($state, $scope, $clientSwitcher, ClientManager) {

    var masterManager = {
      // "name":"Test Manager X1",
      // "phone1":"501-555-1230",
      // "phone2":null,
      // "email":"managerX1@coordinate.com",
      // "employees":[
      //   {
      //     "name":"Test Employee A1E1",
      //     "employeeId":"0001",
      //     "email":"test0001@coordinate.com",
      //     "phone":"5551234567"
      //   },
      //   {
      //     "name":"Test Employee A1E2",
      //     "employeeId":"0002",
      //     "email":"test0002@coordinate.com",
      //     "phone":"5551234568"
      //   },
      //   {
      //     "name":"Test Employee A1E3",
      //     "employeeId":"0003",
      //     "email":"test0003@coordinate.com",
      //     "phone":"5551234569"
      //   }
      // ],
      offices: [],
      employees: []
    };

    $scope.onSubmit = function (form) {

      var
      currentCli = $clientSwitcher.getCurrentClient();

      if(form.$invalid || !currentCli) return;

      var manager = new ClientManager($scope.newManager);

      manager.$save({id: currentCli._id})
        .then(function (result) {
          $state.go('app.account.dashboard.settings.managers');
          return result;
        })
        .catch(function (result) {
          console.log('error:', result);
          return result;
        });
    };

    ($scope.onReset = function (form) {
      $scope.newManager = angular.copy(masterManager);
      if(form) form.$setUntouched();
    })();
  }
// Dependency Injection
DashboardSettingsManagersCreateCtrl.$inject = ["$state","$scope","$clientSwitcher","ClientManager"];
