'use strict';


export const NavbarCtrl = function ($state, $scope, $rootScope, $auth, $clientSwitcher, $activeClientSettings, $sessionExpireCheck, $clientUserNotifications, TASK_SEARCH_CONFIG_KEY, $clientUser, $removeAbility, $client, $authStorage, ngToast, $usersSession, $location, $attachment, appConfig) {
    var defaultState = null;
    $scope.clientSettings = null;
    $scope.menuLeft = [/*{
      'caption': 'Support',
      'title': 'Contact our support',
      'state': 'app.support',
      'glyph': 'glyphicon glyphicon-earphone'
    },*/{
      'state': 'app.admin',
      'glyph': 'glyphicon glyphicon-star',
      'roles': ['admin']
    }];

    $scope.menuRight = [{
      'caption': 'Log-in',
      'title': 'Log-in to your account',
      'state': 'app.account.login',
      'glyph': 'glyphicon glyphicon-log-in',
      'showIf': function() {
        // return !$auth.isLoggedIn();
        return false;
      }
    }];

    var defaultTabs = [{
      'caption': 'Change Password',
      'title': 'Change your password',
      'state': 'app.account.settings',
      'glyph': 'glyphicon glyphicon-user'
    }];

    var superAdminTabs = [
      // {
      //   'caption': 'Client Admin',
      //   'title': 'Manage Clients',
      //   'state': 'app.account.clients',
      //   'glyph': 'glyphicon glyphicon-user'
      // }
    ];

    $scope.clients          = $clientSwitcher.getLoaded();
    $scope.isCollapsed      = true;
    $scope.isLoggedIn       = $auth.isLoggedIn;
    $scope.isAdmin          = $auth.isAdmin;
    $scope.getCurrentUser   = $auth.getCurrentUser;
    $scope.userPermissions  = [];
    $scope.userLoggedIn = false;
    $scope.extendSession = $sessionExpireCheck.extendSession.bind($auth);
    $scope.isSessionWarning = function () { return $sessionExpireCheck.isWarning; };

    $scope.getCurrentClient = $clientSwitcher.getCurrentClient.bind($clientSwitcher);
    // $scope.setCurrentClient = $clientSwitcher.setCurrentClient.bind($clientSwitcher);

    $scope.setLogo = null;
    $scope.getImageFilePath = function (upload) {
      $attachment.download(($scope.clients._id || $scope.clients), upload)
        .then((res) => {
          if (res && res.status && res.url) {
            $scope.setLogo = res.url
          } else {
            $scope.setLogo = appConfig.apiUrl + '/attachment/' + ($scope.clients._id || $scope.clients) + '/' + upload;
          }
        })
        .catch((err) => {
          console.log(err)
        });
    };

    $scope.$on('$destroy', $activeClientSettings(function (client, settings) {
      if(settings.defaultTab) {
        var featureOptions = $clientSwitcher.getFeatureOptions(settings.defaultTab);

        defaultState = featureOptions && featureOptions.tabState;
      }
      if (!!client) {
        setTimeout(function (){
          $scope.userLoggedIn =  !!client;
          $scope.$apply();
        }, 1500)
      } else {
        $scope.userLoggedIn =  !!client;
      }

      $scope.clientSettings = settings || null;
      if(client) {
        $scope.clients = client;
        let userPermissions = $auth.getCurrentUser();
        $scope.userPermissions = userPermissions.permissions || [];
        // Load image
        $scope.getImageFilePath($scope.clientSettings.vanityLogoId)
        // setTimeout(() => {
        //   createUserMenu();
        // }, 0);
        // $clientUser.getUserPermissions(client)
        // .then(data => {
        //   $scope.userPermissions = data.permissions;
        // })
        // .then(() => createUserMenu());
      }
    }));

    var isSuperAdmin = function() {
      var allowUserEmails = [
        'dhaval@jumpbyte.com',
        'mike@mikeborek.com',
        'mike@coorworks.com',
        'kryo2k@gmail.com'
      ];
      return allowUserEmails.indexOf($scope.getCurrentUser().email) > -1;
    };

    $scope.getMenuStyle = function(menuPosition) {
      var styleObj = {
        // 'font-size': '20px',
        'color': $rootScope.textColor
      };

      if(menuPosition) {
        styleObj['text-align'] = menuPosition;
      }

      return styleObj;
    };

    $scope.getClientGlyph = function (cli) {
      var aL = $clientSwitcher.getAccessLevel(cli);
      switch(true) {
        case $clientSwitcher.isOwner(aL):    return 'glyphicon-star';
        case $clientSwitcher.isOperator(aL): return 'glyphicon-star-empty';
        case $clientSwitcher.isLimited(aL):  return 'glyphicon-eye-close';
      }
    };

    $scope.changeClient = function(client) {
      localStorage.removeItem(TASK_SEARCH_CONFIG_KEY);
      $scope.setCurrentClient(client);
      // setTimeout(function(){ window.location = $scope.clientSettings.siteDomain }, 1000);
      window.location.reload();
    };

    $scope.logout = function () {
      var clientId = $scope.clients;
      if (!clientId) clientId = $clientSwitcher.getCurrentClient() || $clientSwitcherStorage.getCurrent();
      $usersSession.deleteUserSession(clientId, $authStorage.getToken())
        .then((res) => {
          if (res.status) {
            // if ($auth.getCurrentUser() && $auth.getCurrentUser().name) {
            //   Sentry.setTag("type", 'manual');
            //   Sentry.setTag("userId", $auth.getCurrentUser()._id);
            //   var absUrl = $location.absUrl();
            //   Sentry.captureException(new Error(`${$auth.getCurrentUser().name} user logout time ${new Date()} ${absUrl}`));
            // }
            console.log('Click on Logout')
            let isServicePortal = localStorage.getItem('isServicePortalUser')
            $auth.logout()
              .then(() => {
                if (isServicePortal && JSON.parse(isServicePortal)) {
                  $state.go('app.account.services', {}, { reload: true });
                } else {
                  $state.go('app.account.login');
                }
              })
              .catch((err) => {
                let errMessage = err.message || err;
                if (err && err.data && err.data.message) errMessage = err.data.message;
                ngToast.create({
                  content: errMessage,
                  className: 'danger'
                });
              })
          } else {
            ngToast.create({
              content: 'User Logout Fail!',
              className: 'danger'
            });
          }
        })
        .catch((err) => {
          let errMessage = err.message || err;
          if (err && err.data && err.data.message) errMessage = err.data.message;
          ngToast.create({
            content: errMessage,
            className: 'danger'
          });
        })
    };

    var createDashboardMenu = function() {
      $scope.dashboardMenu = [];
      let accessLevel = 1;
      const currCli = $clientSwitcher.getCurrentClient();
      $client.clientAbilities(currCli)
        .then(data => {
          delete data.data.options['client.strings'];
          const hasAccessLevel = !isNaN(accessLevel);
          const feat = data.data;
          // Remove not use ability
          $removeAbility.forEach((ability) => {
            delete data.data.options[ability];
          })
          $scope.clientAbilityItems = Object.keys(feat.options || {})
            .filter(function (o) {
              var options = feat.options[o];
              return options.actAsTab && options.allowTab && (!hasAccessLevel || options.requireLevel >= accessLevel);
            })
            .sort(function (a, b) {
              var
                aSort = feat.options[a].tabSort,
                bSort = feat.options[b].tabSort;
              return aSort - bSort;
            })
            .map(function (o) {
              var options = feat.options[o];
              return {
                id: o,
                title: options.tabTitle,
                state: options.tabState,
                icon: options.tabIcon,
                disabled: !!options.tabDisabled,
                permission: options.tabPermission ? options.tabPermission : 'NONE',
                tabData: options.tabData || {}
              };
            })
            let currentUser = $scope.getCurrentUser();
          _.each($scope.clientAbilityItems, function (tab) {
            if (!tab.disabled && $scope.userPermissions && $scope.userPermissions.length && $scope.userPermissions.includes(tab.permission)) {
              // Remove not use ability

              if ($removeAbility.indexOf(tab.id) <= -1) {
                if (currentUser && !currentUser.isUserServicePortal) {
                  if (tab.tabData && !tab.tabData.isServicePortal && !tab.tabData.isAdminPortal) {
                    $scope.dashboardMenu.push({
                      'caption': tab.title,
                      'state': tab.state,
                      'glyph': tab.icon || 'glyphicon glyphicon-cog'
                    });
                  } else if (tab.tabData && tab.tabData.isServicePortal && tab.tabData.isAdminPortal) {
                    $scope.dashboardMenu.push({
                      'caption': tab.title,
                      'state': tab.state,
                      'glyph': tab.icon || 'glyphicon glyphicon-cog'
                    });
                  }
                } else {
                  if (tab.tabData && tab.tabData.isServicePortal) {
                    $scope.dashboardMenu.push({
                      'caption': tab.title,
                      'state': tab.state,
                      'glyph': tab.icon || 'glyphicon glyphicon-cog'
                    });
                  }
                }
              }
            }
          });

          $scope.dashboardMenu = _.uniq($scope.dashboardMenu, 'caption')
        });
      // _.each($clientSwitcher.getFeatureTabs(), function(tab) {
      //   if(!tab.disabled && $scope.userPermissions.includes(tab.permission)) {
      //     // Remove not use ability
      //     if($removeAbility.indexOf(tab.id) <= -1){
      //       $scope.dashboardMenu.push({
      //         'caption': tab.title,
      //         'state': tab.state,
      //         'glyph': tab.icon || 'glyphicon glyphicon-cog'
      //       });
      //     }
      //   }
      // });
    };

    var createUserMenu = function() {
      $scope.userMenu = [];

      _.each(defaultTabs, function(defaultTab) {
        $scope.userMenu.push(defaultTab);
      });


      if(isSuperAdmin()) {
        _.each(superAdminTabs, function(superAdminTab) {
          $scope.userMenu.push(superAdminTab);
        });
      }

      // if($clientSwitcher.hasAbility('update.user.notifications')) {
      // if (!$auth.isInterpreter()) {
        $scope.userMenu.push({
          'caption': 'Notifications',
          'title': 'Change your Client User Notifications settings',
          'state': 'app.account.notificationSettings',
          'glyph': 'glyphicon glyphicon-bell'
        });
      // }
      // }

      $scope.userMenu.push({
        'caption': 'Accessibility Settings',
        'title': 'Change your Client User UI settings',
        'state': 'app.account.accessibilitySettings',
        'glyph': 'glyphicon glyphicon-th'
      });

      createDashboardMenu();
    };

    var loadUserNotifications = function(){
      if(!$scope.getCurrentClient()) {
        return;
      }

      $clientUserNotifications.get($scope.getCurrentClient()).then(function(data){
        $scope.notifications = data.map(function(notification) {
          notification.isReminderNotification = notification.message.toLowerCase().indexOf('reminder: task') === 0;

          notification.taskTitle = notification.message.substring(notification.message.indexOf(':') + 1);

          var actionSeparator = notification.isReminderNotification ? ' is' : ' was';

          notification.taskAction = notification.taskTitle.substring(notification.taskTitle.indexOf(actionSeparator));
          notification.taskAction = notification.taskAction.substring(actionSeparator.length).trim(); //length of string 'was'

          notification.taskTitle = notification.taskTitle.substring(0, notification.taskTitle.indexOf(' at')).trim();
          if(notification.isReminderNotification) {
            notification.taskTitle = notification.taskTitle.substring("Task".length);
          }

          return notification;
        });
      });
    };

    // if($auth.isLoggedIn()){
    //   loadUserNotifications();
    // }

    $scope.openNotification = function(notification){
      $clientUserNotifications.read($scope.getCurrentClient(), notification._id).then(function(){
        $scope.notifications.splice( $scope.notifications.indexOf(notification), 1);
        $state.go('app.account.dashboard.tasks', {task: notification.taskId}, {reload: true});
      });
    };

    $scope.clearAllNotifications = function(){
      $clientUserNotifications.read($scope.getCurrentClient(), undefined).then(function(){
        $scope.notifications = [];
      });
    };

    $scope.redirectToDefaultState = function () {
      var state = defaultState || 'app.account.dashboard.tasks';
      let user = $scope.getCurrentUser();
      if (user && user.isUserServicePortal) {
        state = 'app.account.services.servicePanel';
      }

      $state.go(state);
    };

    //this controller executes before user logs in so user menu is created without any knowledge of abilities
    //hence we need to execute it again when user logs in
    $scope.$watch(function($scope) { return $scope.clientSettings; } , function(newIsLoggedInValue) {
      if(newIsLoggedInValue) {
        createUserMenu();

        loadUserNotifications();
      }
    });

    // $scope.getImageFilePath = function (upload) {
    //   let downloadUrl  = '/attachment/'+($scope.clients._id || $scope.clients)+'/'+upload;
    //   return downloadUrl+'?preview=0';
    // };

    $scope.$watch(function () {
      return $state.$current.name
    }, function (newVal, oldVal) {
      // if (newVal == oldVal) return;
      setTimeout(() => {
        $scope.hideMenu = (newVal == 'app.account.dashboard.programs') ? false : true;
        $scope.$apply()
      }, 500)
    })
  }
// Dependency Injection
NavbarCtrl.$inject = ["$state","$scope","$rootScope","$auth","$clientSwitcher","$activeClientSettings","$sessionExpireCheck","$clientUserNotifications","TASK_SEARCH_CONFIG_KEY","$clientUser","$removeAbility","$client","$authStorage","ngToast","$usersSession","$location","$attachment","appConfig"];
