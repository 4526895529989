
'use strict';

export const $clientInterpreterProfileService  = function (ClientInterpreterProfile) {
        return {
            create: function (client, icProfileData) {
                return ClientInterpreterProfile.icCreate({
                    _id: client._id || client,
                    data: icProfileData
                }).$promise;
            },
        };
    }
// Dependency Injection


// Dependency Injection
$clientInterpreterProfileService.$inject = ['ClientInterpreterProfile'];
