import './interpreter-scheduled-notification.scss';
'use strict';
export const interpreterScheduledNotificationDirective = function ($rootScope, $timeout, $clientSwitcher, $auth,
        $activeClientSettings, $interpreterScheduledNT,  $autoTimezoneFormat, Modal) {
        return {
            restrict: 'E',
            templateUrl: 'app/account/dashboard/main-dashboard/work-orders/tabs/interpreter-scheduled-notification/interpreter-scheduled-notification.html',
            scope: {},
            link: function (scope) {
                const currentUser = $auth.getCurrentUser();
                const currentCli = $clientSwitcher.getCurrentClient();

                scope.pageData = {
                    isLoading: false,
                    error: null,
                    dataList: [],
                }
                scope.pagination = {
                    currentPage: 1,
                    page: 1,
                    total: 0,
                    pageSize: 20,
                    itemsPerPage: 20
                };

                scope.filter = {
                    searchStr: '',
                    startDate: moment().startOf('month').format('YYYY-MM-DD'),
                    endDate: moment().endOf('month').format('YYYY-MM-DD'),
                }

                scope.applyFilter = () => {
                    scope.pagination.page = 1;
                    scope.loadDataList()
                }

                scope.loadDataList = function () {
                    if (scope.pageData.isLoading) return;
                    scope.pageData.isLoading = true;
                    scope.pageData.error = null;
                    scope.pageData.datalist = [];
                    let filter =_.pick(scope.filter, [ 'startDate', 'endDate']);
                    $interpreterScheduledNT.index(currentCli, filter, scope.pagination).then((res) => {
                        scope.pageData.dataList = res.result;
                        scope.pagination.total = res.totalRecords;
                    })
                        .catch((err) => {
                            console.log(err)
                        })
                        .finally(() => {
                            scope.pageData.isLoading = false;

                        })
                }
                $activeClientSettings(function (client, settings) {
                    // scope.serviceTypeScheduled = settings.serviceTypeScheduled;
                })


                function init() {
                    initDateRangePicker()
                    scope.applyFilter();
                }

                function initDateRangePicker() {
                    function cb(start, end) {
                        $timeout(function () {
                            if (!start || !end) {
                                return;
                            }
                            $('#logDateFilter span').html(start.format('MM/DD/YYYY') + ' - ' + end.format('MM/DD/YYYY'));
                            scope.filter.startDate = start.format('YYYY-MM-DD');
                            scope.filter.endDate = end.format('YYYY-MM-DD');
                            scope.applyFilter();
                        }, 0);
                    }

                    $('#logDateFilter').daterangepicker({
                        startDate: moment(scope.filter.startDate).format('MM/DD/YYYY'),
                        endDate: moment(scope.filter.endDate).format('MM/DD/YYYY'),
                        opens: 'left',
                        ranges: {
                            'Today': [moment().startOf('day'), moment().endOf('day')],
                            'Yesterday': [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
                            'Last 7 Days': [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],
                            'Last 30 Days': [moment().subtract(29, 'days').startOf('day'), moment().endOf('day')],
                            'This Month': [moment().startOf('month'), moment().endOf('month')],
                            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                        }
                    }, cb);

                    var text = moment(scope.filter.startDate).format('MM/DD/YYYY') + ' to ' + moment(scope.filter.endDate).format('MM/DD/YYYY');
                    $("#logDateFilter span").html(text);
                }

                init()
            }
        }
    }
// Dependency Injection
interpreterScheduledNotificationDirective.$inject = ["$rootScope","$timeout","$clientSwitcher","$auth","$activeClientSettings","$interpreterScheduledNT","$autoTimezoneFormat","Modal"];
