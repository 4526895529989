
'use strict';

export const $clientContactCoordinateService  = function (clientContactCoordinate) {
    return {      
      filter: function(filter) {
        return clientContactCoordinate.index(filter).$promise;
      },
      filterRecords: function(filter) {
        return clientContactCoordinate.records(filter).$promise;
      },
      getCustomerRecords: function(filter) {
        return clientContactCoordinate.customerRecords(filter).$promise;
      }
    };
  }
// Dependency Injection


// Dependency Injection
$clientContactCoordinateService.$inject = ['clientContactCoordinate'];
