'use strict';

export const TaskConfig = function ($stateProvider) {
    $stateProvider
      .state('app.print.task', {
        url: '/task',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./print/task/task.html', '24ffc44a'); }],
        controller: 'printTaskCtrl1'
      })
      .state('app.print.wo-invoice', {
        url: '/wo-invoice/:id',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./print/task/wo-invoice-print/wo-invoice-print.html', '79fc24ea'); }],
        controller: 'PrintWOInvoicePrintCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./print/task/wo-invoice-print/wo-invoice-print.controller.js', 'PrintWOInvoicePrintCtrl'); }]
                      }
      });
  }
// Dependency Injection
TaskConfig.$inject = ["$stateProvider"];
