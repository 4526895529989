'use strict';

export const DashboardSettingsLanguagesIndexCtrl = function ($rootScope, $scope, $auth, $activeClient, $clientLanguages, DEFAULT_DEBOUNCE, $languageTypes) {

    let currentCli = null;
    $scope.loading = false;
    $scope.search = {
      searchText: '',
      orderVal: 'true'
    };
    $scope.sortingOrders = [{
      label: 'A-Z',
      value: 'true'
    }, {
      label: 'Z-A',
      value: 'false'
    }]
    $scope.langTypes = $languageTypes;
    $scope.languagesData = [];
    $scope.languagesDataCount = 0;
    $scope.isLoading = false;
    $scope.error = null;
    $scope.pagination = {
      currentPage: 1,
      page: 1,
      total: 0,
      pageSize: 20,
      itemsPerPage: 20
    };
    $scope.filter = {};
    $scope.totalActiveLanguage = 0;
    $scope.allowEdit = $auth.getCurrentUser().permissions.includes('SETTING_LANGUAGE_EDIT');

    $scope.getLanguagesList = function () {
      if ($scope.isLoading) return;
      $scope.isLoading = true;
      $scope.error = null;
      $scope.languagesData = [];
      // $scope.totalActiveLanguage = 0;
      $scope.pagination.total = 0;
      $scope.filter.orderByValue = $scope.search.orderVal == 'true' ? 'asc' : 'desc';
      $scope.filter.orderByField = 'name';
      $scope.filter.page = $scope.pagination.page;
      $scope.filter.pageSize = $scope.pagination.pageSize;
      $scope.filter.activeTotalLang = true;
      $scope.filter.type = $scope.search.langType || '';
      if ($scope.search.searchText) {
        $scope.filter.searchKey = $scope.search.searchText;
      }
      $clientLanguages.getLanguagesList(currentCli, $scope.filter)
        .then((res) => {
          if (res.status) {
            if (res.languagesList && res.languagesList.length) {
              $scope.languagesData = res.languagesList;
              $scope.pagination.total = res.totalRecords;
              $scope.languagesDataCount = res.totalRecords;
              $scope.totalActiveLanguage = res.totalActiveLanguage;
            } else {
              $scope.error = 'No Languages Found.';
              $scope.languagesDataCount = 0;
              $scope.totalActiveLanguage = 0;
            }
          }
        })
        .catch((err) => {
          let errMessage = err.message || err;
          if (err && err.data && err.data.message) {
            errMessage = err.data.message;
          } else if (err && err.data && err.data.reason) {
            errMessage = err.data.reason;
          }
          $scope.error = errMessage;
          $scope.languagesDataCount = 0;
          $scope.totalActiveLanguage = 0;
        })
        .finally(() => {
          $scope.isLoading = false;
        })
    }

    function reset() {
      $scope.pagination = {
        currentPage: 1,
        page: 1,
        total: 0,
        pageSize: 20,
        itemsPerPage: 20
      };
      $scope.filter = {};
    }

    $scope.applySearch = _.debounce(function (cv) {
      reset();
      $scope.getLanguagesList();
    }, DEFAULT_DEBOUNCE)

    $scope.onSearch = function () {
      $scope.applySearch();
    }

    $scope.onSorting = function () {
      reset();
      $scope.getLanguagesList();
    }

    function getLanguages(client) {
      $scope.loading = true;
      $scope.allowEdit = $auth.getCurrentUser().permissions.includes('SETTING_LANGUAGE_EDIT');
      $clientLanguages.getLanguages(client)
        .then(({ data, status }) => {
          if (status) {
            $scope.languages = data;
            $scope.activeLanguages = data.filter(x => x.isEnabled).length;
          }
        })
        .finally(() => $scope.loading = false)
    }

    // listen for client switching
    $scope.$on('$destroy', $activeClient(function (client) {
      if (!client) return;
      currentCli = client;
      // getLanguages(currentCli)
      $scope.getLanguagesList();
    }));

    $scope.languageType = function (type) {
      if (!type) return type;
      const typeObj = $languageTypes.find((obj) => obj.value == type);
      if (typeObj) return typeObj.label;
      return type;
    }
  }
// Dependency Injection
DashboardSettingsLanguagesIndexCtrl.$inject = ["$rootScope","$scope","$auth","$activeClient","$clientLanguages","DEFAULT_DEBOUNCE","$languageTypes"];

// angular.module('coordinateApp').controller('DashboardSettingsLanguagesIndexCtrl', DashboardSettingsLanguagesIndexCtrl);
