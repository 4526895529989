'use strict';


export const ruleWindowDirective = function ($timeout) {
  return {
    restrict: 'E',
    templateUrl: 'components/util/rule-window.html',
    scope: {
      model: '=ngModel',
      valid: '=isValid'
    }, // isolate scope
    link: function (scope, el, attr) {
      var basicStructure = {
          window: {
            start: scope.model.startFrom ? moment(scope.model.startFrom).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY'),
            stop: scope.model.stopOn ? moment(scope.model.stopOn).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY'),
            selectedDays: [],
            selectedDates: [],
            executionTime: {
              start: scope.model.fromTime || 0,
              stop: scope.model.fromTime + scope.model.timeOffset || 0
            },
            repeatMode: scope.model.mode || ''
        }
      };

      scope.model = _.merge(scope.model, basicStructure);
      
      scope.repeatOptions = [
        {label: 'Never', value: '', considerItem: true}, 
        {label: 'Days', value: 'days'}, 
        {label: 'Dates', value: 'dates'}
      ];
      scope.repeat = '';

      scope.ruleString = '';
      scope.weekDays = [        
        {label: 'Mon', value: 0},
        {label: 'Tue', value: 1},
        {label: 'Wed', value: 2},
        {label: 'Thu', value: 3},
        {label: 'Fri', value: 4},
        {label: 'Sat', value: 5},
        {label: 'Sun', value: 6}
      ];
      var days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

      scope.errorMessage = '';

      function pad(num) {
          return ('0'+num).slice(-2);
      }
      function hhmm(minutes) {
        var hours = Math.floor(minutes/60);
        minutes = minutes%60;
        var time = hours >= 12 && minutes >= 0 ? ' PM': ' AM';
        hours = hours > 12 ? hours - 12 : hours;
        return pad(hours)+':'+pad(minutes) + time;
      }

      scope.toggleDay = function(day) {
        var index = scope.model.window.selectedDays.indexOf(day.value);
        if(index > -1) {
          scope.model.window.selectedDays.splice(index, 1);
        } 
        else {
          scope.model.window.selectedDays.push(day.value);
        }
      };

      scope.isDaySelected = function(day) {
        return scope.model.window && scope.model.window.selectedDays && scope.model.window.selectedDays.indexOf(day.value) > -1;
      };

      //adjust the max value of start date-picker or min value of stop date-picker
      // function dateChanged(dateType, dateValue) {
      //   if(dateType === 'startDate') {

      //     $('#stopdatepicker').daterangepicker({            
      //       singleDatePicker: true,
      //       minDate: moment().toDate()
      //     }, function(start, end, label) {
      //       $timeout(function() {
      //         scope.model.window.stop = moment(start._d).format('MM/DD/YYYY');
      //         dateChanged('stopDate', start);
      //       }, 0);
      //     });    

      //   } else {

      //     $('#startdatepicker').daterangepicker({
      //       maxDate: dateValue,
      //       singleDatePicker: true,
      //       minDate: moment().toDate()
      //     }, function(start, end, label) {
      //       $timeout(function() {
      //         scope.model.window.start = moment(start._d).format('MM/DD/YYYY');

      //         dateChanged('startDate', start);
      //       }, 0);
      //     });

      //   }
      // }

      $('#startdatepicker').daterangepicker({
        singleDatePicker: true,
        minDate: moment().toDate()
      }, function(start, end, label) {
        $timeout(function() {
          scope.model.window.start = moment(start._d).format('MM/DD/YYYY');

          // dateChanged('startDate', start);
        }, 0);
      });

      //setup stop-date-picker
      $('#stopdatepicker').daterangepicker({
        singleDatePicker: true,
        minDate: moment().toDate()
      }, function(start, end, label) {
        $timeout(function() {
          scope.model.window.stop = moment(start._d).format('MM/DD/YYYY');
          // dateChanged('stopDate', start);
        }, 0);
      });

      $('#select-dates').daterangepicker({
        autoApply: true
      }, function(start, stop) {

        scope.model.window.selectedDates = [];
        for(var i = start._d.getDate(); i<= stop._d.getDate(); i++) {
          scope.model.window.selectedDates.push(i);
        }

        $timeout(function() {
          scope.selectedDateRange = moment(start).format('MM/DD/YYYY');
          if(scope.selectedDateRange !== moment(stop).format('MM/DD/YYYY')) {
            scope.selectedDateRange += ' to ' + moment(stop).format('MM/DD/YYYY');
          }
        }, 10);
      });

      var picker = $('#select-dates').data('daterangepicker');
      picker.container.find('.range_inputs').hide();
      picker.container.find('.calendar.left').hide();

      scope.repeatModes = [
        {label: 'Every', value: 'every'},
        {label: 'Every Other', value: 'every-other'},
        {label: 'First of Month', value: 'first-of-month'}
      ];      

      scope.$watch(function(){ return JSON.stringify(scope.model); }, function(nv){
        scope.errorMessage = '';

        var val = scope.model.window;

        if(!val) {
          return;
        }

        scope.ruleString = (scope.model.title ? scope.model.title + ' rule' : 'This rule') + ' will run between ' + hhmm(val.executionTime.start) + ' to ' + hhmm(val.executionTime.stop) + ' starting from ' + val.start;
        if(val.repeat === 'days'){
          if(val.selectedDays.length > 0){
            val.selectedDays = _.sortBy(val.selectedDays);
            switch(val.repeatMode) {
              case 'every': 
                scope.ruleString += ' on ' + ' ' + _.map(val.selectedDays, function(val){ return days[val]; }) + ' of every week';
                break;
              case 'every-other':
                scope.ruleString += ' on ' + ' ' + _.map(val.selectedDays, function(val){ return days[val]; }) + ' of every other week';
                break;
              case 'first-of-month':
                scope.ruleString += ' on First' + ' ' + _.map(val.selectedDays, function(val){ return days[val]; }) + ' of every month';
                break;
            }
          }
        }else if(val.repeat === 'dates'){
          if(val.selectedDates.length > 0){
            scope.ruleString += ' on ' + (val.selectedDates.length > 1 ? 'dates' : 'date') + ' ' + val.selectedDates[0];
            if(val.selectedDates.length > 1) {
              scope.ruleString += ' to '+val.selectedDates[val.selectedDates.length - 1];
            }

            switch(val.repeatMode) {
              case 'every': 
              case 'first-of-month':
                scope.ruleString += ' of every month';
                break;
              case 'every-other':
                scope.ruleString += ' of every other month';
                break;
            }
          }
        }
        scope.ruleString += ' till ' + val.stop;

        var times = scope.model.window.executionTime;
        if(times.start <= times.stop && times.stop - times.start < 15) {
          scope.errorMessage = 'Rule should run for at least 15 Minutes';
        }

        //if there are no error needed to display then the rule window is valid
        scope.valid = scope.errorMessage === '';
      });

      if(scope.model.window && scope.model.window.start) {
        $('#select-dates').data('daterangepicker').setStartDate(moment(scope.model.window.start).subtract(1, 'month'));
        $('#select-dates').data('daterangepicker').setEndDate(moment(scope.model.window.start).subtract(1, 'month'));
      } 

      if(scope.model.window.repeat === 'dates') {
        var start = new Date(new Date().setDate(scope.model.selectedDates[0]));
        var stop = new Date(new Date().setDate(scope.model.selectedDates[scope.model.selectedDates.length - 1]));
        
        //init calendar with rules's date
        $('#select-dates').data('daterangepicker').setStartDate(start);
        $('#select-dates').data('daterangepicker').setEndDate(stop);

        //init time range displayed in text field
        scope.selectedDateRange = moment(start).format('MM/DD/YYYY');
        if(scope.selectedDateRange !== moment(stop).format('MM/DD/YYYY')) {
          scope.selectedDateRange += ' to ' + moment(stop).format('MM/DD/YYYY');
        }
      }
    }
  };
}
// Dependency Injection
ruleWindowDirective.$inject = ["$timeout"];
