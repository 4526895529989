'use strict';

angular
export const ChartDataFactory = function ($colorUtil) {
  var
  defaultColors = [
    '#F24444',
    '#F28A44',
    '#F2AD44',
    '#F2D044',
    '#F1F244',
    '#B4E13F',
    '#3AD040',
    '#3AA9D0',
    '#3A76D0',
    '#553AD0',
    '#A13AD0',
    '#D93D95'
  ];

  function ChartData (opts) {
    var
    data   = [],
    colors = [],
    labels = [];

    this.configure = function (options) {
      options = options || {};

      if(options.data) {
        this.load(options.data, options.colors, options.labels);
      }

      return this;
    };

    this.clear = function () {
      data.splice(0,   data.length);
      colors.splice(0, colors.length);
      labels.splice(0, labels.length);
      return this;
    };

    this.load = function (d, c, l) {
      if(!angular.isArray(d)) {
        return this;
      }

      Array.prototype.push.apply(data, d);
      Array.prototype.push.apply(colors, (angular.isArray(c)?c:defaultColors).slice(0, d.length));
      Array.prototype.push.apply(labels, (angular.isArray(l)?l:[]).slice(0, d.length));

      return this;
    };

    Object.defineProperties(this, {
      colors: {
        get: function () {
          return colors;
        },
        set: function (c) {
          colors = c;
        }
      },
      labels: {
        get: function () {
          return labels;
        },
        set: function (l) {
          labels = l;
        }
      },
      data: {
        get: function () {
          return data;
        }
      },
      length: {
        get: function () {
          return data.length;
        }
      }
    });

    this.configure(opts);
  }

  return ChartData;
}
 export const DashboardTasksWidgetCtrl = function ($scope, ChartData) {

  $scope.enabled = true;

  $scope.taskViewTypes = [
    [0, 'By Regions'],
    [1, 'By Offices'],
    [2, 'By Task Bucket'],
    [3, 'By Priority']
  ];
  $scope.taskResponsibility = [
    [0, 'Assigned to Me'],
    [1, 'Assigned to Subordinates'],
    [2, 'Assigned by Me']
  ]
  $scope.taskStatus = [
    [0, 'Assigned'],
    [1, 'In Process'],
    [2, 'Unresolved'],
    [3, 'Resolved']
  ];

  $scope.view = {
    taskview:       0,
    responsibility: null,
    status:         null,
  };

  $scope.summaryItems = [{
    title: 'View 1',
    chart: new ChartData({
      data:   [5,            2,            3,            1,          ],
      labels: ['View 1 A', 'View 1 B', 'View 1 C', 'View 1 D']
    })
  },{
    title: 'View 2',
    chart: new ChartData({
      data:   [1,            3,            5           ],
      labels: ['View 2 A', 'View 2 B', 'View 2 C']
    })
  },{
    title: 'View 3',
    chart: new ChartData({
      data:   [3,            2,            6           ],
      labels: ['View 3 A', 'View 3 B', 'View 3 C']
    })
  },{
    title: 'View 4',
    chart: new ChartData({
      data:   [1,            1,            2           ],
      labels: ['View 4 A', 'View 4 B', 'View 4 C']
    })
  },{
    title: 'View 5',
    chart: new ChartData({
      data:   [8,            4,            2           ],
      labels: ['View 5 A', 'View 5 B', 'View 5 C']
    })
  }];

}
 export const dashboardTasksDirective = function () {
  return {
    templateUrl: 'app/account/dashboard/tasks/tasks.widget.html',
    controller: 'DashboardTasksWidgetCtrl',
    scope: {
    }
  };
}
// Dependency Injection
ChartDataFactory.$inject = ["$colorUtil"];
DashboardTasksWidgetCtrl.$inject = ["$scope","ChartData"];
