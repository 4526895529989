import './user_approval/user_approval.scss';
'use strict';

export const UsersConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard.settings.users', {
        url: '/users',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/users/users.html', '35519a0a'); }],
        controller: 'DashboardSettingsUsersCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/users/users.controller.js', 'DashboardSettingsUsersCtrl'); }]
                      },
        data: {
          ability: 'manage.client.users'
        }
      })
      .state('app.account.dashboard.settings.users.index', {
        url: '/users',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/users/index/index.html', 'c536a18a'); }],
        controller: 'DashboardSettingsUsersIndexCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/users/index/index.controller.js', 'DashboardSettingsUsersIndexCtrl'); }]
                      },
        data: {
          ability: 'manage.client.users'
        }
      })
      .state('app.account.dashboard.settings.users.create', {
        url: '/create',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/users/create/create.html', '87c4e1ea'); }],
        controller: 'DashboardSettingsUsersCreateCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/users/create/create.controller.js', 'DashboardSettingsUsersCreateCtrl'); }]
                      },
        data: {
          ability: 'manage.client.users'
        }
      })
      .state('app.account.dashboard.settings.users.update', {
        url: '/update/:id',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/users/update/update.html', '963e7c4a'); }],
        controller: 'DashboardSettingsUsersUpdateCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/users/update/update.controller.js', 'DashboardSettingsUsersUpdateCtrl'); }],
                          '$selectedUser': ['$stateParams','$clientUser', '$clientSwitcher', function ($stateParams, $clientUser, $clientSwitcher) {
                            return $clientUser.detail($clientSwitcher.getCurrentClient(), $stateParams.id);
                          }]
                      },
        data: {
          ability: 'manage.client.users'
        },
      })
      .state('app.account.dashboard.settings.users.updateserviceportal', {
        url: '/serviceportal/:id',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/users/service_portal/service_portal.html', '78bec56a'); }],
        controller: 'DashboardSettingsUsersServicePortalUpdateCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/users/service_portal/service_portal.controller.js', 'DashboardSettingsUsersServicePortalUpdateCtrl'); }],
                          '$selectedUser': ['$stateParams','$clientUser', '$clientSwitcher', function ($stateParams, $clientUser, $clientSwitcher) {
                            return $clientUser.detail($clientSwitcher.getCurrentClient(), $stateParams.id);
                          }]
                      },
        data: {
          ability: 'manage.client.users'
        },
      })
      .state('app.account.dashboard.settings.users.locations', {
        url: '/locations',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/users/locations/locations.html', '9c40fe0a'); }],
        controller: 'DashboardSettingsUsersLocationsCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/users/locations/locations.controller.js', 'DashboardSettingsUsersLocationsCtrl'); }]
                      },
        data: {
          ability: 'manage.client.users'
        }
      })
      .state('app.account.dashboard.settings.users.external', {
        url: '/external',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/users/external/external.html', '644f860a'); }],
        controller: 'DashboardSettingsUsersExternalCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/users/external/external.controller.js', 'DashboardSettingsUsersExternalCtrl'); }]
                      },
        data: {
          ability: 'manage.client.users'
        }
      })
      .state('app.account.dashboard.settings.users.activeLog', {
        url: '/active_log',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/users/active_log/active_log.html', '6992df4a'); }],
        controller: 'DashboardSettingsUsersActiveLogCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/users/active_log/active_log.controller.js', 'DashboardSettingsUsersActiveLogCtrl'); }]
                      },
        data: {
          ability: 'manage.client.users'
        }
      })
      .state('app.account.dashboard.settings.users.approval', {
        url: '/user_approval',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/users/user_approval/user_approval.html', 'c3557b0a'); }],
        controller: 'UserApprovalCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/users/user_approval/user_approval.controller.js', 'UserApprovalCtrl'); }]
                      },
        data: {
          ability: 'manage.client.users'
        }
      })
      .state('app.account.dashboard.settings.users.linguistUser', {
        url: '/linguist_user',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/users/linguist_user/linguist_user.html', '559d6c4a'); }],
        controller: 'LinguistUserCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/users/linguist_user/linguist_user.controller.js', 'LinguistUserCtrl'); }]
                      },
        data: {
          ability: 'manage.client.users'
        }
      })
      .state('app.account.dashboard.settings.users.contactUsers', {
        url: '/contact-users',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/users/contact_users/contact_users.html', 'bca6dd0a'); }],
        controller: 'ContactUsersCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/users/contact_users/contact_users.controller.js', 'ContactUsersCtrl'); }]
                      },
        data: {
          ability: 'manage.client.users'
        }
      })
      .state('app.account.dashboard.settings.users.notloggedInUsers', {
        url: '/in-active-interpreter-users',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/settings/users/not_loggedin/not_logged_in_users.html', '5b73d588'); }],
        controller: 'notloggedInUsersCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/settings/users/not_loggedin/not_logged_in_users.controller.js', 'notloggedInUsersCtrl'); }]
                      },
        data: {
          ability: 'manage.client.users'
        }
      });
  }
// Dependency Injection
UsersConfig.$inject = ["$stateProvider"];
