'use strict';

angular
export const DashboardSettingsEmployeesIndexCtrl = function ($rootScope, $scope, $activeClient, $clientEmployee, Modal, treeConfig, ngToast, $activeClientSettings) {

  $scope.loading = false;
  $scope.fullTree = [];
  var currentClient;
  $scope.searchObj = {
    query: ''
  };
  $scope.stats = null;
  $scope.isInSearchMode = false;

  treeConfig.defaultCollapsed = true;

  var setNotifyList = function(employee) {
    if(employee.linkedUsers && employee.linkedUsers.length) {
      employee.notifyUsersList = employee.linkedUsers.map(function(user) {
        return user ? user.name : '';
      }).join(', ');
    }
  };

  $scope.initTree = function() {
    $scope.loading = true;
    $scope.fullTree = [];

    $clientEmployee.getEmployeeStats(currentClient)
    .then(function(res) {
      if(res.status) {
       $scope.stats = res.data;
      }
    })
    .catch(function(err) {
      console.log(err);
    });

    getChildrenEmployees(currentClient)
    .then(function(res) {
      if(res.status) {
        // console.log(res)
        res.data.forEach(function(employee) {
          setNotifyList(employee);
        });

        $scope.fullTree = res.data;

        $scope.getRemovedEmployees();
      }
      else {
        console.error(res.reason);
      }
    })
    .finally(function() {
      $scope.loading = false;
    });
  };

  $scope.toggleNode = function(node, toggleFunc) {
    var self = this;

    if(node.hasOwnProperty('children')) {
      return toggleFunc(self);
    }

    node.isLoading = true;

    getChildrenEmployees(currentClient, node)
    .then(function(res) {
      if(res.status) {
        res.data.forEach(function(employee) {
          setNotifyList(employee);
        });

        node.children = res.data;
        toggleFunc(self);
      }
      else {
        console.error(res.reason);
      }
    })
    .finally(function() {
      node.isLoading = false;
    });
  };

  function getChildrenEmployees(client, parentNode) {
    return $clientEmployee.getChildrenEmployees(client, parentNode)
  }

  // listen for client switching
  $scope.$on('$destroy', $activeClient(function (client) {
    if(!client) return;

    currentClient = client;
    $scope.initTree();
  }));

  $scope.notifications = function(employee){
    $activeClient(function (client) {
      if(!client) {
        return;
      }

      $clientEmployee.userNotifications(client, employee).then(function(data) {

        var employeeStr = "";

        if(data.data.length > 0){
          employeeStr = "These users will get notifications (email/push) depending on their preferences set.<br><br><ul class='list-group'>";
          data.data.forEach(function(emp){
            employeeStr += "<li class='list-group-item'>" + emp.name + " (" + emp.email + " )" + "</li>";
          });

          employeeStr = employeeStr + "</ul>";
        }else{
          employeeStr = "No Associated Employees Found";
        }

        Modal.confirm.show(
          "Associated Users",
          employeeStr,
          "Ok", "Cancel",
          function(){
        });
      });
    });
  };

  $scope.deleteEmployee = function(employee) {
    $activeClient(function (client) {
      if(!client) {
        return;
      }

      Modal.confirm.show(
        "Confirm Delete",
        "Are you sure you want to delete <strong>"+employee.firstName+" "+employee.lastName+"</strong>",
        "Delete", "Cancel",
        function(){
        $clientEmployee.deleteEmployee(client, employee).then(function() {
          $scope.initTree(client);
        });
      });
    });
  };

  $scope.search = function() {
    $scope.isInSearchMode = !!$scope.searchObj.query || $scope.searchObj.onlyUserEmployees;

    if($scope.isInSearchMode) {
      $scope.loading = true;

      $clientEmployee.getEmployees(currentClient, $scope.searchObj)
      .then(function(res) {

        res.data.forEach(function(employee) {
          setNotifyList(employee);
        });

        $scope.fullTree = res.data;

        $scope.getRemovedEmployees();
      })
      .catch(function(err) {
        console.log(err);
      })
      .finally(function() {
        $scope.loading = false;
      });
    }
    else {
      $scope.initTree();
    }
  };

  $scope.getRemovedEmployees = function(){
    $scope.isInSearchMode = !!$scope.searchObj.query || $scope.searchObj.onlyUserEmployees;

    //if($scope.isInSearchMode) {
      $scope.loading = true;

      $clientEmployee.getRemovedEmployees(currentClient, $scope.searchObj)
      .then(function(res) {
        res.data.forEach(function(employee) {
          setNotifyList(employee);
        });

        $scope.removedEmployees = res.data;
      })
      .catch(function(err) {
        console.log(err);
      })
      .finally(function() {
        $scope.loading = false;
      });
    // }
  }

  $scope.makeAvailable = function (employee) {
    $scope.loading = true;

    if (!employee._id) { //create employee mode so we cant call api as _id is not available
      return;
    }

    if (employee.isEnabled) {
      Modal.confirm.show('Suspend Employee', 'Do you want to Suspend <b>' + (employee.firstName || '') + " " + (employee.lastName || '') + '</b>?', 'Yes', 'No', function () {
        $clientEmployee.makeEmployeeAvailable(currentClient, employee)
          .then(function (res) {
            if (res.status) {
              return $scope.getRemovedEmployees();
            }
            else {
              ngToast.create({
                content: res.reason,
                className: 'danger'
              });
              return console.log(res.reason);
            }
          })
          .catch(function (err) {
            console.log(err);
            ngToast.create({
              content: err.message,
              className: 'danger'
            });
          })
          .finally(function () {
            $scope.loading = false;
          });
      });
    }
  };

  $scope.toggleUserEmployees = function() {
    $scope.searchObj.onlyUserEmployees = !$scope.searchObj.onlyUserEmployees;
    $scope.search();
  };

  $scope.ivrSync = function(){
    $activeClient(function (client) {
      if(!client) {
        return;
      }

      Modal.confirm.show(
        "Confirm Sync",
        "Are you sure you want to sync all the active employees with IVR again ?",
        "Yes", "No",
        function(){
        $clientEmployee.ivrSync(client).then(function() {

        });
      });
    });
  }

  $activeClientSettings(function (client, settings) {
    $scope.supportsIVR = settings.supportsIVR;
  })
}
// Dependency Injection
DashboardSettingsEmployeesIndexCtrl.$inject = ["$rootScope","$scope","$activeClient","$clientEmployee","Modal","treeConfig","ngToast","$activeClientSettings"];

// angular.module('coordinateApp').controller('DashboardSettingsEmployeesIndexCtrl', DashboardSettingsEmployeesIndexCtrl);
