
'use strict';

export const $checkBusinessHoursService  = function ($timezones) {
  return function (record, clientBusinessDays) {
    var index = $timezones.findIndex(function (x) {
      return x.label === record.timezonename;
    })
    var timezoneOffset = $timezones[index] ? $timezones[index].offset : moment().format('ZZ');
    var startTime = moment().startOf('day').utcOffset(timezoneOffset).format();
    var endTime = moment().utcOffset(timezoneOffset).format();
    var duration = moment(endTime).diff(moment(startTime)) / 1000;
    if (duration > 0) {
      var timeDiff = Math.floor(duration / 60);
      var dateIndex = moment().day();
      var businessDay = clientBusinessDays[dateIndex - 1];
      if (businessDay.enabled) {
        if (timeDiff > businessDay.min && timeDiff < businessDay.max) {
          return true;
        }
      } else {
        return false
      }
    }
    return false;
  }
}


// Dependency Injection
$checkBusinessHoursService.$inject = ['$timezones'];
