


'use strict';

export const MonthlyUserAccessLogsFactory  = function ($resource, appConfig) {
    return $resource(appConfig.apiUrl + '/api/monthly-user-access-logs/:id/:controller', {
      id: '@id'
    },
      {
        getActiveLogs: {
          method: 'POST',
          params: {
            controller: 'get-active-logs',
          },
        },
        downloadActiveUserLogs: {
          method: 'POST',
          params: {
            id: '@id',
            controller: 'download-active-user-logs'
          }
        },
      });
  } 
 

// Dependency Injection
MonthlyUserAccessLogsFactory.$inject = ['$resource', 'appConfig'];
