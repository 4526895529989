


'use strict';

export const ClientInterpreterFactory  = function ($resource, appConfig) {
        return $resource(appConfig.apiUrl + '/api/interpreter/:id/:controller', {
            id: '@_id'
        },
            {
                getInterpreterInfo: {
                    method: 'GET',
                    params: {
                        id: '@id',
                        controller: ''
                    }
                },
                getAllInterpreters: {
                    method: 'POST',
                    params: {
                        id: '@id',
                        controller: 'get-all-interpreters'
                    }
                },

                getInterpreterUsers: {
                    method: 'GET',
                    params: {
                        id: '@id',
                        controller: 'get-interpreter-users-list'
                    }
                },
                getSignedUrl: {
                    method: 'GET',
                    params: {
                        id: '@id',
                        controller: 'get-signed-url'
                    }
                },
                interpreterWorkReport: {
                    method: 'GET',
                    params: {
                        id: '@id',
                        controller: 'get-interpreter-work-report'
                    }
                },
                icBulkUploadingImg: {
                    method: 'POST',
                    params: {
                        id: '@id',
                        controller: 'ic-bulk-uploading-pics'
                    }
                },
                getResourceFilter: {
                    method: 'POST',
                    params: {
                        id: '@id',
                        controller: 'get-resource-ic-filters'
                    }
                },
                getInterpreterListInElisa: {
                    method: 'GET',
                    params: {
                        id: '@id',
                        controller: 'get-interpreters-list'
                    }
                },
                updateInterpreterDetail: {
                    method: 'POST',
                    params: {
                        id: '@id',
                        controller: 'update-interpreter-detail'
                    }
                },
                getInterpreterTabCount: {
                    method: 'GET',
                    params: {
                        id: '@id',
                        controller: 'interpreter-tab-count'
                    }
                },
                getInterpretersByVendorId: {
                    method: 'POST',
                    params: {
                        id: '@id',
                        controller: 'get-interpreter-by-vendorid'
                    }
                },
                getHomeCourtInterpreter: {
                    method: 'GET',
                    params: {
                        id: '@id',
                        controller: 'get-home-court-interpreter'
                    }
                },
                getRTWMatchCourtInterpreter: {
                    method: 'GET',
                    params: {
                        id: '@id',
                        controller: 'get-location-match-court-interpreter'
                    }
                },
                getFTIMatchCourtInterpreter: {
                    method: 'GET',
                    params: {
                        id: '@id',
                        controller: 'get-fti-location-match-court-interpreter'
                    }
                },
                getRTWLangInterpreter: {
                    method: 'GET',
                    params: {
                        id: '@id',
                        controller: 'get-all-rtw-lang-interpreter'
                    }
                },
                createInterpreterUser: {
                    method: 'POST',
                    params: {
                        id: '@id',
                        controller: 'create-interpreter-user'
                    }
                },
                interpreterAvailableWO: {
                    method: 'POST',
                    params: {
                        id: '@id',
                        controller: 'interpreter-available-wos'
                    }
                },
                sendNextDayPushReminder: {
                    method: 'POST',
                    params: {
                        id: '@id',
                        controller: 'send-next-day-push'
                    }
                }
            });
    } 
 

// Dependency Injection
ClientInterpreterFactory.$inject = ['$resource', 'appConfig'];
