import './bucket.scss';
'use strict';


export const formBucketDirective = function (Modal, $rootScope, $q, $auth, $clientSwitcher, $clientBucket, $clientOffice, $clientRoles, $activeClient, $clientPermissions) {
    return {
      restrict: 'E',
      templateUrl: 'components/forms/bucket.html',
      scope: {
        bucket: '=ngModel',
        onSubmit: '=formOnSubmit',
        onReset: '=formOnReset',
        allPermissions: '='
      },
      link: function (scope, el, attrs) {
        var currentCli = $clientSwitcher.getCurrentClient();
        scope.client = currentCli;
        scope.allValue = 'all';
        scope.itemDelimiter = ', ';
        scope.editMode = {};
        scope.arrRoles = [];
        scope.arrPermissions = [];
        scope.allPermissions = {};
        scope.bucket.allPermissions = scope.allPermissions;
        scope.arrRolePermissions = {};
        scope.uniqRoles = [];

        scope.clientStrings = function () {
          return $clientSwitcher.getFeatureOptions('client.strings');
        };
        scope.typeSettings = function () {
          var typeStr = (scope.clientStrings() || {}).typesPCase;
          return {
            selectedStr: 'Selected ' + typeStr + ': ',
            noSelection: 'No ' + typeStr + ' Selected',
            allLabel: 'All ' + typeStr
          };
        };
        scope.newType = "";
        scope.addType = function () {
          if (this.newType.trim() == "") return;
          this.bucket.types.push(this.newType);
          this.newType = "";
        },

          scope.removeType = function (index) {
            Modal.confirm.show("Delete Task Type", "Are you sure you want to delete <strong>" + this.bucket.types[index] + "</strong>?", "Yes", "No", function () {
              this.bucket.types.splice(index, 1);
            }.bind(this));
          }

        scope.isEditable = function (index) {
          return scope.editMode[index];
        }
        scope.toggleEdit = function (index) {
          if (scope.editMode[index]) {
            delete scope.editMode[index];
          } else {
            scope.editMode[index] = true;
          }
        }

        scope.updateType = function (obj, index) {
          this.bucket.types[index] = obj.type;
        }

        scope.addSupportedRole = function () {
          this.bucket.supportedRoles.push({
            role: null,
            permissions: []
          });
          scope.getUniqRoles(this.bucket.supportedRoles.length)
        }

        scope.getUniqRoles = function (index) {
          scope.uniqRoles[index - 1] = []
          if (index == 1) {
            scope.uniqRoles[index - 1] = scope.arrRoles
          } else {
            scope.uniqRoles[index - 1] = scope.arrRoles.filter(o => {
              return !this.bucket.supportedRoles.find(o2 => {
                return o.value == o2.role
              })
            })
          }
        }

        scope.checkDublicateRole = function () {
          scope.duplicateRole = _.filter(
            _.uniq(
              _.map(this.bucket.supportedRoles, function (item) {
                if (_.filter(scope.bucket.supportedRoles, { role: item.role }).length > 1) {
                  return item.role;
                }
                return false;
              })),
            function (value) { return value; });
        }

        scope.removeSupportedRole = function (index) {
          this.bucket.supportedRoles.splice(index, 1);
          // Modal.confirm.show("Delete Role", "Are you sure you want to delete <strong>Role</strong>?", "Yes", "No", function () {
          // }.bind(this));
        }

        scope.permissionSettings = function () {
          var permissionStr = (scope.clientStrings() || {}).permissionsPCase;
          return {
            selectedStr: 'Selected ' + permissionStr + ': ',
            noSelection: 'No ' + permissionStr + ' Selected',
            allLabel: 'All ' + permissionStr
          };
        };

        scope.roleSettings = function () {
          var roleStr = (scope.clientStrings() || {}).rolePCase;
          return {
            selectedStr: 'Selected ' + roleStr + ': ',
            noSelection: 'No ' + roleStr + ' Selected',
            allLabel: 'All ' + roleStr
          };
        };

        var init = function () {
          $activeClient(function (client) {
            if (!client) {
              return;
            }
            scope.client = client;
            getRoles(client);
            // getPermissions(client);
          });
        };

        function getRoles(client) {
          let filter = {
            type: 'FULFILLMENT_OPERATIONS'
          };

          $clientRoles.getRolePermissions(client, filter)
            .then(({ roles }) => {
              if (roles && roles.length) {
                scope.arrRoles = roles.map((role) => {
                  scope.allPermissions[role._id] = [];
                  scope.arrRolePermissions[role._id] = role.permissions.map((permission) => {
                    scope.allPermissions[role._id].push(permission._id)
                    permission.label = permission.name;
                    permission.value = permission._id;
                    return permission
                  });
                  return {
                    label: role.name,
                    value: role._id,
                  }
                });
                if (scope.bucket._id) {
                  if (scope.bucket.supportedRoles && scope.bucket.supportedRoles.length) {
                    scope.bucket.supportedRoles.map((obj, index) => {
                      scope.uniqRoles[index] = scope.arrRoles
                      if (scope.bucket.supportedRoles.length == index + 1) {
                        scope.checkDublicateRole();
                      }
                    })
                  }
                }
              }
            })
        }

        function getPermissions(client) {
          $clientPermissions.getPermissions(client, 'FULFILLMENT_OPERATIONS')
            .then(({ data, status }) => {
              if (status) {
                scope.arrPermissions = data.map((permission) => {
                  scope.allPermissions.push(permission._id)
                  return {
                    label: permission.name,
                    value: permission._id
                  }
                })
              }
            })
        }

        scope.defaultSelectAllPermission = function (supportedRole, permissions) {
          if (supportedRole && supportedRole.permissions && supportedRole.permissions.length > 0 && permissions && permissions.length > 0) {
            if (supportedRole.permissions !== 'all') {
              let assginPermissions = [];
              supportedRole.permissions.forEach((permission) => {
                let checkPermission = _.find(permissions, function (per) {
                  return permission == per._id;
                })
                if (checkPermission) {
                  assginPermissions.push(checkPermission._id)
                }
              })
              supportedRole.permissions = assginPermissions;
            }
          }
          if (supportedRole && supportedRole.permissions && supportedRole.permissions.length == 0 && permissions && permissions.length > 0) {
            supportedRole.permissions = 'all';
          }
        }

        init();
      }
    };
  }
// Dependency Injection
formBucketDirective.$inject = ["Modal","$rootScope","$q","$auth","$clientSwitcher","$clientBucket","$clientOffice","$clientRoles","$activeClient","$clientPermissions"];
