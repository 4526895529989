
'use strict';

export const $icaTrackingService  = function (icaTracking) {
        return {
            getList: function (client, paginationData, vendorid) {
                return icaTracking.getList({
                    id: client._id || client,
                    page: paginationData && paginationData.page,
                    pageSize: paginationData && paginationData.pageSize,
                    vendorid: vendorid || undefined
                }).$promise;
            },
            getInterpreterRates: function (client, vendorId) {
                return icaTracking.getInterpreterRates({
                    id: client._id || client,
                    vendorId
                }).$promise;
            },
            getInterpreterTravelRates: function (client, vendorId) {
                return icaTracking.getInterpreterTravelRates({
                    id: client._id || client,
                    vendorId
                }).$promise;
            }
        };
    }
// Dependency Injection


// Dependency Injection
$icaTrackingService.$inject = ['icaTracking'];
