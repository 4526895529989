'use strict';


export const showAttachmentsDirective = function (FileUploader, $timeout, $clientInterpreterResources, $clientSwitcher, $ServiceUserProject, $downloadAttachment,  $authStorage, $window, Modal) {
    return {
      restrict: 'E',
      templateUrl: 'components/show-attachments/show-attachments.html',
      scope: {
        items: '=',
        onRemove: '=formOnRemove',
        outerData: '=outerData',
        hideEmptyAttachment: '=hideEmptyAttachment'
      },
      link: function (scope, el, attrs) {
        scope.hideEmptyAttachment = (scope.hideEmptyAttachment && angular.isDefined(scope.hideEmptyAttachment))
        const client = $clientSwitcher.getCurrentClient();
        scope.getFilePath = function (attachment) {
          if (attachment && attachment._id) {
            if (['image/jpeg', 'image/png', 'image/gif'].indexOf(attachment.mimeType) >= 0 && attachment.signedUrl) {
              // console.log(attachment.signedUrl)
              if(attachment.signedUrl) {
                return attachment.signedUrl;
              }
              return appConfig.apiUrl + '/api/client-uploads/' + (client._id || client) + '/' + attachment._id + '?preview=1&access_token=' + $authStorage.getToken();
            } else {
              // console.log('/api/client-uploads/' + (client._id || client) + '/' + attachment._id + '?preview=1&access_token=' + $authStorage.getToken())
              return appConfig.apiUrl + '/api/client-uploads/' + (client._id || client) + '/' + attachment._id + '?preview=1&access_token=' + $authStorage.getToken();
            }
          }
        };
        
        scope.download = function (attachment) {
          $downloadAttachment.download((client._id || client), attachment._id);
        };
    
        scope.getExtension = function (attachment) {
          var extensionLength = 3;
          var longExtensions = ['jpeg'];
    
          var attachmentName = attachment.name;
          var extension = attachmentName ? attachmentName.substring(attachmentName.lastIndexOf('.') + 1) : '';
    
          _.some(longExtensions, function (longExtension) {
            if (extension.startsWith(longExtension)) {
              extensionLength = longExtension.length;
              return true;
            } else {
              return false;
            }
          });
    
          return extension.substr(0, extensionLength).toUpperCase();
        };

        scope.showAttachment = function(attachment) {
          var
          modalPreview = Modal.info.previewUpload(function (result) {
            console.log('modal preview result:', result);
          });

          var uploads = [];
          var currerntUploadIndex;
          _.each(scope.items, function(taskAttachment, index) {
            uploads.push(taskAttachment);
            if(attachment._id === taskAttachment._id) {
              currerntUploadIndex = index;
            }
          });

          modalPreview($clientSwitcher.getCurrentClient(), uploads, currerntUploadIndex);
        }
      }
    };
  }
// Dependency Injection
showAttachmentsDirective.$inject = ["FileUploader","$timeout","$clientInterpreterResources","$clientSwitcher","$ServiceUserProject","$downloadAttachment","$authStorage","$window","Modal"];
