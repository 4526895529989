
'use strict';

export const uasModelExternalDirective  = function ($parse) { // task.assignToEmail | task.assignToEmailOnExpire
  return {
    restrict: 'A',
    require: ['uasModelExternal','uasTask', 'uasExternalUsers'],
    controller: 'UasModelExternalCtrl as $uasModelExternal',
    link: function (scope, el, attrs, ctrls) {      
      var
      ctrl = ctrls[0];
      ctrl.setTask(ctrls[1]);
      ctrl.setUsers(ctrls[2]);

      attrs.$observe('uasModelExternal', function (nv) {
        ctrl.setParser($parse(nv), scope);
      });
    }
  };
} 
 

// Dependency Injection
uasModelExternalDirective.$inject = ['$parse'];
