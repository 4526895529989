


'use strict';

export const serviceJobInvoicesFactory  = function ($resource, appConfig) {
        return $resource(appConfig.apiUrl + '/api/services-job-invoices/:id/:controller', {
            id: '@_id'
        },
            {
                getInvoiceById: {
                    method: 'GET',
                    params: {
                        id: '@id'
                    }
                },
                getPendingInvoices: {
                    method: 'GET',
                    params: {
                        controller: 'get-pending-invoices',
                        id: '@id'
                    }
                },
                getPaidInvoices: {
                    method: 'GET',
                    params: {
                        controller: 'get-paid-invoices',
                        id: '@id'
                    }
                },
            });
    } 
 

// Dependency Injection
serviceJobInvoicesFactory.$inject = ['$resource', 'appConfig'];
