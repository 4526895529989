import './interpreter-resources.scss';
'use strict';

export const InterpreterResourcesConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard.interpreterResources', {
        url: '/interpreter-resources',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/interpreter-resources/interpreter-resources.html', '65945cca'); }],
        controller: 'DashboardInterpreterResourcesCtrl',
        resolve: {
            lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/interpreter-resources/interpreter-resources.controller.js', 'DashboardInterpreterResourcesCtrl'); }],
            '$userPermissions': ['$clientUser', '$clientSwitcher', function($clientUser, $clientSwitcher) {
                return $clientUser.getUserPermissions($clientSwitcher.getCurrentClient());
              }]
        },
        data: {
          permission: 'INTERPRETER_RESOURCES_CENTER_READ'
        },
      });
  }
// Dependency Injection
InterpreterResourcesConfig.$inject = ["$stateProvider"];
