
'use strict';

export const $patientContactsService  = function (PatientContacts) {
        return {
            getPatientContacts: function (client, filter, paginationData) {
                return PatientContacts.getPatientContacts({
                    id: client._id || client,
                    controller: 'get-patient-contacts',
                    filter: filter,
                    page: paginationData && paginationData.page,
                    pageSize: paginationData && paginationData.pageSize
                }).$promise;
            }
        };
    }
// Dependency Injection


// Dependency Injection
$patientContactsService.$inject = ['PatientContacts'];
