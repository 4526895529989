const configENV = angular.module('configENV', [])
  .constant('appConfig', {
  "apiUrl": "",
  "baseUrl": "/",
  "enableDebug": false,
  "UserPoolId": "us-east-1_tLsnwMCZb",
  "ClientId": "7ve5f94cnj8toerpfh3n8cb1gk",
  "logoutApiUrl": "https://30ds5uykdh.execute-api.us-east-1.amazonaws.com/prod"
})
  .constant('envConfig', {
  "env": "production"
});

export default configENV;
