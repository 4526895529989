'use strict';

angular
export const DashboardSettingsResolveCodesIndexCtrl = function ($rootScope, $scope, $activeClient, $clientResolveCodes) {

  var currentCli;
  $scope.loading = false;

  function reloadResolveCodes(client) {
    $scope.loading = true;
    return $clientResolveCodes.getResolveCodes(client, true)
      .then(function (resolveCodes) {
        $scope.resolveCodes = resolveCodes;
      })
      .finally(function () {
        $scope.loading = false;
      });
  }

  $scope.toggleSuspend = function(resolveCode) {
    var toggleFunction = resolveCode.isSuspended ? $clientResolveCodes.activate : $clientResolveCodes.suspend;
    return toggleFunction(currentCli, resolveCode)
      .then(function () {
        resolveCode.isSuspended = !resolveCode.isSuspended;
      }, function(err) {
        console.log(err);
      });
  };

  $scope.getTooltipText = function(resolveCode) {
    return resolveCode.isSuspended ? 'Active' : 'Suspend';
  };

  // listen for client switching
  $scope.$on('$destroy', $activeClient(function (client) {
    if(!client) return;
    currentCli = client;
    reloadResolveCodes(currentCli);
  }));
}
// Dependency Injection
DashboardSettingsResolveCodesIndexCtrl.$inject = ["$rootScope","$scope","$activeClient","$clientResolveCodes"];

// angular.module('coordinateApp').controller('DashboardSettingsResolveCodesIndexCtrl', DashboardSettingsResolveCodesIndexCtrl);
