
'use strict';

export const $clientHolidaysService  = function (ClientHolidays) {
    return {
      list: function(client) {
        return ClientHolidays.list({
          id: client._id || client
        }).$promise;
      },

      create: function(clientId, holiday) {
        return ClientHolidays.create({
          _id: clientId._id||clientId,
          data: holiday
        }).$promise;
      },

      update: function(clientId, holiday) {
        return ClientHolidays.update({
          _id: clientId._id||clientId,
          holiday: holiday._id || holiday,
          data: holiday
        }).$promise;
      },

      delete: function(clientId, holiday) {
        return ClientHolidays.delete({
          _id: clientId._id||clientId,
          holiday: holiday._id || holiday
        }).$promise;
      },

      federalHolidays: function(client, startDate) {
        return ClientHolidays.federalHolidays({
            id: client._id || client,
            startDate
        }).$promise;
    },
    };
  }
// Dependency Injection


// Dependency Injection
$clientHolidaysService.$inject = ['ClientHolidays'];
