'use strict';

export const ReportsConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.dashboard.reports', {
        url: '/reports',
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/dashboard/reports/reports.html', 'dad9ff4a'); }],
        controller: 'ReportsCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/dashboard/reports/reports.controller.js', 'ReportsCtrl'); }]
                      },
        data: {
          ability: 'client.Reports',
          permission: 'REPORTS_READ'
        }
      });
  }
// Dependency Injection
ReportsConfig.$inject = ["$stateProvider"];
