
'use strict';

export const $performTaskCreateService  = function ($clientTask, Modal) {
  return function (cb) {
    cb = angular.isFunction(cb) ? cb : angular.noop;
    return Modal.taskActions.createTask(function (scratch) {
      return cb.apply(this, arguments);
    }, 'Create a New Task');
  };
}
 

// Dependency Injection
$performTaskCreateService.$inject = ['$clientTask', 'Modal'];
