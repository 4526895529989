'use strict';


export const formNationalityDirective = function (Modal, $rootScope, $q, $auth, $clientSwitcher) {
    return {
      restrict: 'E',
      templateUrl: 'components/forms/nationality.html',
      scope: {
        nationality: '=ngModel',
        onSubmit: '=formOnSubmit',
        onReset: '=formOnReset'
      },
      link: function(scope, el, attrs) {
        
        scope.clientSettings = function() { return $clientSwitcher.getFeatureOptions('client.settings'); };
      }
    };
  }
// Dependency Injection
formNationalityDirective.$inject = ["Modal","$rootScope","$q","$auth","$clientSwitcher"];
