


'use strict';

export const ClientUserSettingFactory  = function ($resource, appConfig) {
  return $resource(appConfig.apiUrl + '/api/client-user-settings/:id/:controller', {
    id: '@_id'
  },
  {
    query: {
      method: 'GET',
      isArray: false,
      params: {
        controller: ''
      }
    },
    defaultSettings: {
      method: 'GET',
      isArray: false,
      params: {
        controller: 'default'
      }
    },
    apply: {
      method: 'POST',
      isArray: false,
      params: {
        controller: 'update',
        id: '@id'
      }
    },
    replace: {
      method: 'POST',
      isArray: false,
      params: {
        controller: 'replace',
        id: '@id'
      }
    }
  });
} 
 

// Dependency Injection
ClientUserSettingFactory.$inject = ['$resource', 'appConfig'];
