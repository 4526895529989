
'use strict';

export const $forgotPasswordService  = function ($q, ForgotPassword) {
  return {
    makeRequest: function (email) {
      return ForgotPassword.forgot({ value: email }).$promise;
    },
    resetPassword: function(token, password) {
      return ForgotPassword.reset({value: token, password: password}).$promise;
    }
  };
}
// Dependency Injection


// Dependency Injection
$forgotPasswordService.$inject = ['$q', 'ForgotPassword'];
