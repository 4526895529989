'use strict';

export const SigninConfig = function ($stateProvider) {
    $stateProvider
      .state('app.account.services.signin', {
        url: '/signin?email&token',
        data: {
          roles: []
        },
        templateProvider: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadTemplate('./account/services/signin/signin.html', 'c9a0e0ea'); }],
        controller: 'ServiceSigninCtrl',
                      resolve: {
                          lazyLoad: ['LazyLoadService', function(LazyLoadService) { return LazyLoadService.loadController('./account/services/signin/signin.controller.js', 'ServiceSigninCtrl'); }]
                      }
      })
  }
// Dependency Injection
SigninConfig.$inject = ["$stateProvider"];
